export const SET_LOADING = '[APP] SET LOADING';
export const SET_LANGUAGE = '[APP] SET LANGUAGE';
export const SET_COUNTRY_CODE ='[APP] SET COUNTRY CODE';
export const GET_TRANSLATION = '[APP] GET TRANSLATION';
export const SET_TRANSLATION = '[APP] SET TRANSLATION';
export const SET_STATS_DATA = '[APP] SET_STATS_DATA';
export const SET_TENET_STATUS = '[APP] SET_TENET_STATUS';
export const SET_THEME = '[APP] SET THEME';
export const SET_SPONSORSLISTS = '[APP] SET_SPONSORSLISTS';


