import { filter, uniqBy, map, orderBy, forEach } from 'lodash';
import { LIVE_CASINO_CATEGORIES, LIVE_CASINO_EVOLUTION_GAMES, VIRTUAL_SPORTS } from '../../config';
import * as Actions from '../actions/actionTypes';
import { Translate } from '../../localization';

const initialState = {
    casinoGames: [],
    casinoCategories: [],
    filteredCasinoGames: [],
    loadingCasinoGames: true,
    activeCategory: 'All',
    currentGameData: {},
    liveCasinoGames: [],
    filteredLiveCasinoGames: [],
    liveCasinoCategories: [],
    loadingLiveCasinoGames: true,
    searchStarted: false,
    noSearchResults: false,
    activeCategoryLive: 'All',
    currentLiveGameData: {},
    virtualSports: [],
    filteredVirtualSports: [],
    virtualSportsCategories: [],
    loadingVirtualSports: true,
    virtualSportsSearchStarted: false,
    noVirtualSearchResult: false,
    virtualSportsActiveCategory: 'All'
};

const casinoReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.SET_CASINO_GAMES: {
            let games = action.games;
            let categories = uniqBy(map(games, 'category'));
            categories =
                uniqBy(
                    map(categories, (category) => {
                        const splitedCategory = category.split('/')
                        return { value: splitedCategory[1] }
                    }), 'value')
            categories.unshift({ value: 'All' })

            return {
                ...state,
                casinoGames: games,
                casinoCategories: categories,
                loadingCasinoGames: false,
            };
        }

        case Actions.SET_LIVE_CASINO_GAMES: {
            let games = action.games;

            forEach(games, (game) => {
                if (game.category === 'CASINO/LIVECASINO/ROULETTE') {
                    game.sort = 1;
                } else if (game.category === 'CASINO/LIVECASINO/BLACKJACK') {
                    game.sort = 2;
                } else if (game.category === 'CASINO/LIVECASINO/BACCARAT') {
                    game.sort = 3;
                } else {
                    game.sort = 4;
                }
            });
            games = orderBy(games, ['sort'], 'asc');


            let liveCasinoCategories = uniqBy(map(games, 'category'));
            liveCasinoCategories =
                uniqBy(
                    map(liveCasinoCategories, (category) => {
                        const splitedCategory = category.split('/');
                        return {
                            value: splitedCategory[2],
                            name: splitedCategory[2]
                        };
                    }), 'value');
            liveCasinoCategories.unshift({
                value: 'All',
                name: Translate.all
            });

            return {
                ...state,
                liveCasinoGames: games,
                liveCasinoCategories: liveCasinoCategories,
                loadingLiveCasinoGames: false,
            };
        }

        case Actions.SET_CASINO_ACTIVE_CATEGORY: {
            let { casinoGames } = state;

            if (action.category === 'All') {
                return {
                    ...state,
                    casinoGames: casinoGames,
                    filteredCasinoGames: [],
                    activeCategory: action.category,
                };
            } else {
                let filteredGames = filter(casinoGames, (game) => {
                    return game.category.split('/')[1] === action.category;
                });
                return {
                    ...state,
                    filteredCasinoGames: filteredGames,
                    activeCategory: action.category,
                };
            }
        }

        case Actions.SET_LIVE_CASINO_ACTIVE_CATEGORY: {
            let { liveCasinoGames } = state;

            if (action.category === 'All') {
                return {
                    ...state,
                    liveCasinoGames: liveCasinoGames,
                    filteredLiveCasinoGames: [],
                    activeCategoryLive: action.category,
                };
            } else {
                let filteredGames = filter(liveCasinoGames, (game) => {
                    return game.category.split('/')[2] === action.category;
                });
                return {
                    ...state,
                    filteredLiveCasinoGames: filteredGames,
                    activeCategoryLive: action.category,
                };
            }
        }

        case Actions.SET_CASINO_SEARCH_STARTED: {
            return {
                ...state,
                searchStarted: action.value,
                noSearchResults: false,
                filteredCasinoGames: [],
            };
        }

        case Actions.SET_LIVE_CASINO_SEARCH_STARTED: {
            return {
                ...state,
                searchStarted: action.value,
                filteredLiveCasinoGames: [],
            };
        }

        case Actions.SET_CASINO_SEARCH: {
            let { casinoGames } = state;

            let filteredGames = filter(casinoGames, (game) => {
                let lowercaseGameName = game.name.toLowerCase();
                let lowercaseSearchVal = action.value.toLowerCase();
                return lowercaseGameName.includes(lowercaseSearchVal);
            });

            if (filteredGames.length > 0) {
                return {
                    ...state,
                    filteredCasinoGames: filteredGames,
                    noSearchResults: false,
                };
            } else {
                return {
                    ...state,
                    filteredCasinoGames: [],
                    noSearchResults: true,
                };
            }
        }

        case Actions.SET_LIVE_CASINO_SEARCH: {
            let { liveCasinoGames } = state;

            let filteredGames = filter(liveCasinoGames, (game) => {
                let lowercaseGameName = game.name.toLowerCase();
                let lowercaseSearchVal = action.value.toLowerCase();
                return lowercaseGameName.includes(lowercaseSearchVal);
            });

            if (filteredGames.length > 0) {
                return {
                    ...state,
                    filteredLiveCasinoGames: filteredGames,
                    noSearchResults: false,
                };
            } else {
                return {
                    ...state,
                    filteredLiveCasinoGames: [],
                    noSearchResults: true,
                };
            }
        }

        case Actions.SET_GAME_DATA: {
            return {
                ...state,
                currentGameData: action.data,
            };
        }

        case Actions.SET_LIVE_GAME_DATA: {
            return {
                ...state,
                currentLiveGameData: action.data,
            };
        }

        case Actions.CLEAR_CASINO_GAMES: {
            return {
                ...state,
                casinoGames: [],
                casinoCategories: [],
                filteredCasinoGames: [],
                loadingCasinoGames: true,
                searchStarted: false,
                noSearchResults: false,
                activeCategory: 'All',
                currentGameData: {},
            };
        }

        case Actions.CLEAR_LIVE_CASINO_GAMES: {
            return {
                ...state,
                liveCasinoGames: [],
                filteredLiveCasinoGames: [],
                liveCasinoCategories: [],
                loadingLiveCasinoGames: true,
                searchStarted: false,
                noSearchResults: false,
                activeCategoryLive: 'All',
                currentLiveGameData: {},
            };
        }

        case Actions.SET_VIRTUAL_SPORTS: {
            let sports = action.sports;

            forEach(sports, (sport) => {
                if (sport.category === 'CASINO/LIVECASINO/ROULETTE') {
                    sport.sort = 1;
                } else if (sport.category === 'CASINO/LIVECASINO/BLACKJACK') {
                    sport.sort = 2;
                    // } else if (sport.category === 'CASINO/LIVECASINO/BACCARAT') {
                    //     sport.sort = 3;
                } else {
                    sport.sort = 3;
                }
            });
            sports = orderBy(sports, ['sort'], 'asc');

            let virtualSportsCategories = VIRTUAL_SPORTS;
            return {
                ...state,
                virtualSports: sports,
                virtualSportsCategories: virtualSportsCategories,
                loadingVirtualSports: false,
            };
        }

        case Actions.SET_VIRTUAL_SPORTS_CATEGORY: {
            let { virtualSportsActiveCategory, virtualSports } = state;

            if (action.category === 'All') {
                return {
                    ...state,
                    virtualSportsActiveCategory: virtualSportsActiveCategory,
                    filteredVirtualSports: [],
                    virtualSportsActiveCategory: action.category,
                };
            } else {
                let filteredSports = filter(virtualSports, (sport) => {
                    return sport.category.split('/')[1] === action.category.split('/')[1];
                });
                return {
                    ...state,
                    filteredVirtualSports: filteredSports,
                    virtualSportsActiveCategory: action.category,
                };
            }
        }

        case Actions.CLEAR_VIRTUAL_SPORTS: {
            return {
                ...state,
                virtualSports: [],
                filteredVirtualSports: [],
                virtualSportsCategories: [],
                loadingVirtualSports: true,
                virtualSportsSearchStarted: false,
                noVirtualSearchResult: false,
                virtualSportsActiveCategory: 'All',
                currentVirtualSports: {},
            };
        }

        case Actions.SET_VIRTUAL_SPORTS_SEARCH: {
            let { virtualSports } = state;

            let filteredSports = filter(virtualSports, (sport) => {
                let lowercaseGameName = sport.name.toLowerCase();
                let lowercaseSearchVal = action.value.toLowerCase();
                return lowercaseGameName.includes(lowercaseSearchVal);
            });

            if (filteredSports.length > 0) {
                return {
                    ...state,
                    filteredVirtualSports: filteredSports,
                    noVirtualSearchResult: false,
                };
            } else {
                return {
                    ...state,
                    filteredVirtualSports: [],
                    noVirtualSearchResult: true,
                };
            }
        }

        case Actions.SET_VIRTUAL_SEARCH_STARTED: {
            return {
                ...state,
                virtualSportsSearchStarted: action.value
            }
        }

        default:
            return state;
    }
};

export default casinoReducer;
