const BASE_API = process.env.REACT_APP_API_URL; 
const API_URL = process.env.REACT_APP_API_URL + '/';
const CASINO_URL = process.env.REACT_APP_API_URL;

export const apiConfig = {
    routes: {
        login: API_URL + 'login',
        signup: API_URL + 'signup',
        getotp: API_URL + 'get-otp',
        verifyOtp :API_URL + 'verify-otp',
        changePassword: API_URL + 'change-password',
        forgetPassword: API_URL +'forgot-password',
        user: API_URL + 'players',
        cashback: API_URL + 'cashback',
        deposit: BASE_API + '/create-payment',
        withdraw : BASE_API +'/withdraw-payment',
        placeBet: API_URL + 'create-betslip',
        createTempBetslip :API_URL + 'create-temp-betslip',
        getTempBetslip: API_URL + 'get_betslip_by_coupon_id',
        getBetslips: API_URL + 'betslip-list',
        getSingleBetslip: API_URL + 'bet-list',
        getLastBetslip: API_URL + 'last-bet-slip',
        getBetLimits: API_URL + 'get-bet-limits',
        getTransactions: API_URL + 'bets/transactions',
        transactionStatus: API_URL + 'get_transaction_status',
        getCasinoTransactions: API_URL + 'casino-transactions',
        getLiveCasinoTransactions: API_URL + 'live-casino-transactions',
        bonusTransactions: API_URL+'bonus-transactions',
        refreshToken: API_URL + 'refresh',
        getCasinoGames: CASINO_URL + '/game/list_custom',
        initCasinoUser: API_URL + 'casino/initiate-user',
        tenetCasinoStatus: API_URL+ 'tenant_casino_status',
        getGameData: API_URL + 'casino/game',
        // getLiveCasinoGames: CASINO_URL + 'live-casino-game-list/',
        getLiveCasinoGames: CASINO_URL + '/game/list_custom',
        getLiveGameData: CASINO_URL + 'single-game',
        placeLiveBet: API_URL + 'place-live-bets',

        getDeviceLocation: 'https://get.geojs.io/v1/ip/country.json',
        
        getCashoutData: API_URL + 'betslip/get_cashout_amount',
        processCashout: API_URL + 'betslip/process_cashout_amount',
        casinoAPI: CASINO_URL + '/casino/single-game/',
        getLiveStreamData: API_URL + 'live-stream-events',
        getBetslipPayout: API_URL + 'pay-betslip-amount',

        // Virtual sports
        getVirtualSports: CASINO_URL + '/game/list_custom',


        searchEvent: API_URL + 'search_events',
        getSportsCount: API_URL + 'live_sport_data',
        cancelBet:  API_URL + 'process_cancel_status',
        checkCancellationBetStatus:  API_URL + 'get_cancel_status',
        getTotalStackReports:API_URL + 'daily_sales',
        getStatsScore: 'https://api.statscore.com/v2/booked-events?client_id=549&product=prematchcenter&lang=en&mapped_status=mapped&events_details=no',
        getPrematchCount: 'https://fvbg10pe89.execute-api.us-east-1.amazonaws.com/prod',
        getSponsorList:BASE_API + '/sponsorship-balance'
    },
};
