import React, { Suspense, lazy, useState, useEffect } from 'react';
import { isMobileOnly } from 'react-device-detect';
import LoadingIcon from './Components/Common/LoadingIcon';
import * as AWS from 'aws-sdk';
import Amplify from 'aws-amplify';
import Util from './helper/Util';
import { useDispatch } from 'react-redux';
import { setOddType } from './store/actions/user.actions';

const DesktopRoutes = lazy(() => import('./Routes/desktopRoutes'));
const MobileRoutes = lazy(() => import('./Routes/mobileRoutes'));

const awsmobile = {
  "aws_appsync_graphqlEndpoint": Util.getValues(process.env.REACT_APP_APPSYNC_POINT),
  "aws_appsync_region": process.env.REACT_APP_AWS_REGION,
  "aws_appsync_authenticationType": "API_KEY",
  "aws_appsync_apiKey": Util.getValues(process.env.REACT_APP_APPSYNC_KEY),
};

Amplify.configure(awsmobile);

// console.log('ssssssssssssssssssss ', Util.getValues(process.env.REACT_APP_MEGATRON) + ' +/+ ' + Util.getValues("tyr3gu3uatU2xSRm50RytmUEw2eFdTWGdYR2NlVFFOa2kxSEY3L2p0SC9nVWMwYg==8xso26uwdx"))

AWS.config.update({
  region: process.env.REACT_APP_AWS_REGION,
  endpoint: `dynamodb.${process.env.REACT_APP_AWS_REGION}.amazonaws.com`,
  accessKeyId: Util.getValues(process.env.REACT_APP_MEGATRON),
//   secretAccessKey: Util.getValues(process.env.REACT_APP_IRONHIDE),
  secretAccessKey: Util.getValues("tyr3gu3uatU2xSRm50RytmUEw2eFdTWGdYR2NlVFFOa2kxSEY3L2p0SC9nVWMwYg==8xso26uwdx")
});

const dynamodb = new AWS.DynamoDB();
const dynamoClient = new AWS.DynamoDB.DocumentClient();

function App(props) {
    const dispatch = useDispatch()
    useEffect(() => {
        const oddType = localStorage.getItem('oddType') || 'decimal'
        dispatch(setOddType(oddType))
    }, [])
    return (
        <div className='main-wrap'>
            <Suspense fallback={<LoadingIcon theme='dark centered' />}>
                {isMobileOnly ? <MobileRoutes isMobileOnly={isMobileOnly} {...props} /> : <DesktopRoutes isMobileOnly={isMobileOnly} {...props} />}
            </Suspense>
        </div>

    );
}

export default App;
export { dynamoClient };
