import { toastr } from 'react-redux-toastr';
import Util from '../../helper/Util';
import jwtService from '../../services/jwtService';
import * as Actions from './actionTypes';
import { getFavorites } from './favorites.actions';
import { depositPollingInterval, depositPollingTimer } from '../../config';
import { dynamoClient } from '../../App';
import { userBalanceUpdate } from '../../dynamo/myAccountParams';
import { isMobileOnly } from 'react-device-detect';

let getUserInterval = null;

//Register user
export const submitSignUp = (obj) => {
    return (dispatch ,getState) => {
        let language = getState().general.language; 
        let userData = {
            email: obj.inputEmail,
            firstName:obj.inputName,
            lastName:obj.inputSurname,

            phone_number: obj.inputPhoneNo,
            password: obj.inputPassword,
            confirm_password: obj.inputConfirmPassword,
            currency: obj.currencyName,
            moncash: obj.moncashNumber,
            agent_code: obj.agentCode,
            country_code: obj.code,
            referral_code: obj.referral_code,
        };
        jwtService
            .signup(userData ,language)
            .then((user) => {
                 toastr.success('','signin successfull');
                 dispatch(userdata(user.user_data))
                 dispatch(submitLogin(obj.inputUsername,obj.inputPassword))
                 dispatch({ type: Actions.SIGNUP_ERROR, signupError: 'success' });
                 localStorage.removeItem('refCode');
                 localStorage.removeItem('firstRefCodeHit');
                 setTimeout(() => {
                        dispatch({ type: Actions.SIGNUP_ERROR, signupError: '' });
                    }, 1000);
            })
            .catch((error) => {
                toastr.error('', error.response.data.message);
                dispatch({ type: Actions.SIGNUP_ERROR, signupError: error.response.data.message });
                setTimeout(() => {
                        dispatch({ type: Actions.SIGNUP_ERROR, signupError: '' });
                    }, 1000);
            });
    };
};

export const openOtpForm = (val) => {
      return (dispatch) =>{
          dispatch({type: Actions.OPEN_OTP_FORM_SIGNUP , data : val});
      }     
}

export const submitSignInOtp = (otp) => {
    return (dispatch ,getState) =>{
        let language = getState().general.language; 
        let data = getState().user.user_data;
        const userData =  JSON.parse(data);
        const username = userData.email;
        const password  = userData.password;
        jwtService.verifyOtp(otp ,'signup', data, language ).then((user)=>{
          toastr.success("","signin successfull");
          dispatch(submitLogin(username,password,false,false));
          dispatch({ type: Actions.SIGNUP_ERROR, signupError: 'successfull' });
          dispatch(openOtpForm(false))
          localStorage.removeItem('refCode');
          localStorage.removeItem('firstRefCodeHit');
          setTimeout(() => {
                 dispatch({ type: Actions.SIGNUP_ERROR, signupError: '' });
             }, 1000);

        })
        .catch((error)=>{
               toastr.error('',error?.response?.data?.message);
               dispatch({ type: Actions.SIGNUP_ERROR, signupError: error.response.data.message });
               setTimeout(() => {
                       dispatch({ type: Actions.SIGNUP_ERROR, signupError: '' });
                   }, 1000);
        })
    }
}

export const getOtp = (obj) => {
    return (dispatch ,getState) => {
        let language = getState().general.language;
        let userData = {
            email: obj.inputEmail,
            first_name:obj.inputName,
            last_name:obj.inputSurname,
            language:language,
            phone_number: obj.inputPhoneNo,
            password: obj.inputPassword,
            confirm_password: obj.inputConfirmPassword,
            currency: obj.currencyName,
            moncash: obj.moncashNumber,
            agent_code: obj.agentCode,
            country_code: obj.code,
            sponsorship_code: obj.sponsorship_code,
            type:'signup'
        };
        dispatch(setUserDetails(userData.phone_number,userData.password))
        jwtService.getOtp( userData).then((user)=>{
            toastr.success("",'Otp send Successfully');
            dispatch(openOtpLogin(true));
            dispatch(userdata(user.user_data))
            dispatch({ type:Actions.GET_OTP_ERROR , getOtpError:'sucess'})
            setTimeout(() => {
                        dispatch({ type: Actions.GET_OTP_ERROR, getOtpError: '' });
                    }, 1000);
         })
        .catch((error) => {
            dispatch({type:Actions.GET_OTP_ERROR, getOtpError:error?.response?.data?.message})
            toastr.error("", error?.response?.data?.message);
            setTimeout(() => {
                    dispatch({ type: Actions.GET_OTP_ERROR, getOtpError: '' });
                }, 1000);
        })
    }
}
export const setUserDetails = (phone,password) =>{
    return (dispatch) =>{
        dispatch({type: Actions.SET_USER_DETAILS , details: {phone,password}})
    }
} 
export const getOtpWithDraw = (params) => {
    return (dispatch ,getState) => {
        let language = getState().general.language;
         const {type, amount , moncashNumber, country_code,is_sponsorship_withdrawal} = params
         if(is_sponsorship_withdrawal){
          dispatch({type: Actions.SET_AMOUNT_MONCASHNUMBER , amount: amount, moncashNumber: moncashNumber,is_sponsorship_withdrawal,withdrawal_id:params.withdrawal_id})
         }
         else{
          dispatch({type: Actions.SET_AMOUNT_MONCASHNUMBER , amount: amount, moncashNumber: moncashNumber,is_sponsorship_withdrawal})
         }


        jwtService.getOtpWithDraw(type, language ,moncashNumber,country_code).then((user)=>{
            toastr.success("",'Otp send Successfully');
            dispatch(openOtpWithdrawForm(true));
            dispatch(userdata(user.user_data))
            dispatch({ type:Actions.GET_OTP_ERROR , getOtpError:'sucess'})
            setTimeout(() => {
                        dispatch({ type: Actions.GET_OTP_ERROR, getOtpError: '' });
                    }, 1000);
         })
        .catch((error) => {
            dispatch({type:Actions.GET_OTP_ERROR, getOtpError:error?.response?.data?.message})
            toastr.error("", error?.response?.data?.message);
             setTimeout(() => {
                        dispatch({ type: Actions.GET_OTP_ERROR, getOtpError: '' });
                    }, 1000);
        })
    }
}

export const submitWithDrawOtp = (otp) => {
    return (dispatch , getState) => {
          let language =  getState().general.language;
          let data = getState().user.user_data;
          let amount = getState().user.amount;
          let moncashNumber = getState().user.moncashNumber;
          let is_sponsorship_withdrawal = getState().user.is_sponsorship_withdrawal;
          let params ={'value':amount,'moncash_number':moncashNumber,is_sponsorship_withdrawal}
          if(is_sponsorship_withdrawal){
            let withdrawal_id = getState().user.withdrawal_id;
            params.withdrawal_id=withdrawal_id
          }

          jwtService.verifyOtp(otp , 'withdraw' , data ,language).then((user)=>{
               dispatch(setWithDrawSuccess(true));
               dispatch(openOtpWithdrawForm(false));
               dispatch({type:Actions.GET_WITHDRAW_OTP_ERROR ,getWithOtpError:'success'});
               jwtService.withdraw(params).then((res)=>{
                toastr.success(res?.message);
                dispatch(getUser(null));
                })
                .catch((error)=>{
                    toastr.error(error.message)
                    console.log(error);
                })
                setTimeout(() => {
                        dispatch({ type: Actions.GET_WITHDRAW_OTP_ERROR, getWithOtpError: '' });
                    }, 1000);
          })
          .catch((error) => {
               dispatch({type:Actions.GET_WITHDRAW_OTP_ERROR ,getWithOtpError: error?.response?.data?.message});
               toastr.error("", error?.response?.data?.message);
               setTimeout(() => {
                        dispatch({ type: Actions.GET_WITHDRAW_OTP_ERROR, getWithOtpError: '' });
                    }, 1000);
          })
    }
}

export const setWithDrawSuccess = (val) =>{
    return (dispatch) =>{
        dispatch({type:Actions.SUCCESS_WITHDRAW, val});
    }
}
export const openOtpWithdrawForm = (val) => {
    return (dispatch ) => {
       dispatch({type:Actions.OPEN_OTP_FORM_WITHDRAW , val: val})
    }
}

export const openOtpLogin = (data) => {
    return (dispatch, getState) =>{
        dispatch({ type:Actions.OPEN_OTP_FORM_LOGIN , data: data })
    }
}

export const submitForgetOtp = (otp) => {
   return (dispatch , getState) =>{
        let language = getState().general.language; 
        let data = getState().user.user_data;
        let phone = getState().user.setUserDetails.phone;
        let password = getState().user.setUserDetails.password
        jwtService.verifyOtp(otp ,'forgot-password', data, language ).then((res)=>{
               dispatch(openOtpLogin(false));
               jwtService.forgetPassword(phone,password,language).then((data)=>{
                  toastr.success("",'password change successfully');
               }).catch((err)=>{
                   toastr.error("",err.response.data.message)
               })
                dispatch({type:Actions.SET_FORGET_ERROR , getForgetError:'success'})
                setTimeout(() => {
                        dispatch({ type: Actions.SET_FORGET_ERROR, getForgetError: '' });
                    }, 1000);
              
        })
        .catch((error)=>{
               toastr.error("",error.response.data.message);
               dispatch({type:Actions.SET_FORGET_ERROR , getForgetError:error.response.data.message})
                setTimeout(() => {
                        dispatch({ type: Actions.SET_FORGET_ERROR, getForgetError: '' });
                    }, 1000);

        })
   }
}

const userdata = (data) => {
    return (dispatch) =>{
        dispatch({type:Actions.USER_DATA, data: data});
    }
}

// Login user
export const submitLogin = (username, password, rememberMe,isSpecialAgent) => {
    return (dispatch , getState) => {
         let language = getState().general.language; 
        jwtService
            .signInWithUsernameAndPassword(username, password, rememberMe, language,isSpecialAgent)
            .then((user) => {
                localStorage.setItem('user_id', user.pk);
                jwtService.getUser(null, language).then((user) => {
                    dispatch({ type: Actions.SET_USER, user });
                    dispatch(getFavorites());
                    
                });
                if (isMobileOnly)  window.location.href = ('/');
                else  window.location.href = ('/d');
                return dispatch({ type: Actions.LOGIN_SUCCESS });
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    dispatch(setLoginError(error.response.data?.non_field_errors && error.response.data?.non_field_errors[0]));
                }
            });
    };
};

// Set login error
export const setLoginError = (error) => {
    return (dispatch) => {
        dispatch({
            type: Actions.LOGIN_ERROR,
            loginError: error,
        });
    };
};

// Change user password
export const changePassword = (oldPassword, newPassword) => {
    return (dispatch ,getState) => {
         let language = getState().general.language; 
        jwtService
            .changeUserPassword(oldPassword, newPassword ,language)
            .then((response) => {
                dispatch(setChangePasswordSuccess(true));
                toastr.success('', 'Password was changed successfully!');
            })
            .catch((error) => {
                if (error && error.response && error.response.status && error.response.status === 401) {
                    Util.handleRepeatedLogin(error.response);
                } else {
                    dispatch(setChangePasswordError(error.response.data.msg));
                }
            });
    };
};

//Deposit
export const depositAmount = (amount) => {
    return (dispatch) => {
        //  const oauthPopup =  window.open();
        jwtService
            .deposit(amount)
            .then((data) => {
                 const url = data.url;
                //  oauthPopup.location = url;
                window.location.href = url
                sessionStorage.setItem('depositPollingTimer', new Date().getTime() + depositPollingTimer);
                dispatch(setDepositSuccess(true));
                dispatch(setDepositError('success'));
                dispatch(depositPolling());
                setTimeout(() => {
                        dispatch(setDepositError(''));
                    }, 1000);
            })
            .catch((error) => {
                 dispatch(setDepositError('failed'));
                 toastr.error("",'something went wrong');
                 setTimeout(() => {
                        dispatch(setDepositError(''));
                    }, 1000);
            });
    };
};

export const setDepositSuccess = (val) =>{
    return (dispatch) =>{
        dispatch({type:Actions.SUCCESS_DEPOSIT, val});
    }
}

export const setDepositError = (error) => {
    return (dispatch) => {
      dispatch({ type:Actions.DEPOSIT_AMOUNT , error:error });
    }
}

export const resetDepositAmount = () => {
    return (dispatch) => {
        dispatch({ type: Actions.RESET_DEPOSIT_AMOUNT });
    };
};

// Set change password success
export const setChangePasswordSuccess = (value) => {
    return (dispatch) => {
        dispatch({ type: Actions.CHANGE_PASSWORD_SUCCESS, value });
    };
};

// Set change password error
export const setChangePasswordError = (error) => {
    return (dispatch) => {
        dispatch({ type: Actions.CHANGE_PASSWORD_ERROR, error });
    };
};

// Logout user
export const logoutUser = () => {
    return (dispatch) => {
        jwtService.logout();
        dispatch({ type: Actions.SET_FAVORITES, events: []});
        dispatch({type: Actions.SET_FAVORITES_LIVE, events: []});
        dispatch({ type: Actions.LOGOUT_USER });
    };
};

// Set user object data
export const setUser = (user) => {
    return (dispatch) => {
        dispatch({ type: Actions.SET_USER, user });
    };
};

// Get user object data
export const getUser = (balance_read) => {
    return (dispatch , getState) => {
          let language = getState().general.language; 
        jwtService.getUser(balance_read , language).then((user) => {
            dispatch(setUser(user));
        });
    };
};

export const getCashbackData = () => {
    return (dispatch) => {
        const userId = localStorage.getItem('user_id');
        jwtService.getCashbackData(userId).then((response) => {
            dispatch(setCashback(response));
        });
    };
};

export const setCashback = (data) => {
    return (dispatch) => {
        dispatch({ type: Actions.SET_CASHBACK_DATA , data });
    };
};

export const getCashbackSucess = () => {
    return (dispatch) => {
        const userId = localStorage.getItem('user_id');
        jwtService.setCashback(parseInt(userId)).then((response) => {
            dispatch(setCashbackSucess(response));

        })
        .catch((error) => {
            console.error(error);
        });

    };
};

export const setCashbackSucess = (data) => {
    return (dispatch) => {
        dispatch({ type: Actions.SET_CASHBACK_SUCESS , data });
        toastr.success('', data.message);
        dispatch(getUser());
    };
};

export const setOddType = (oddType) => {
    return (dispatch) => {
        dispatch({ type: Actions.SET_ODD_TYPE , oddType });
    };
};

export const depositPolling = () => {
    return (dispatch, getState) => {
        const user_id = localStorage.getItem('user_id');
        getUserInterval = setInterval(() => {
            dynamoClient.query(userBalanceUpdate(+user_id), (error, result) => {
                if (error) {
                    console.log(error);
                } else {
                    console.log(result)
                    if(result?.Items[ 0 ]?.is_bal_updated){ // for the first time Items will be empty list
                        dispatch(getUser(true));
                    }
                }
            });
            if (sessionStorage.getItem('depositPollingTimer') <= new Date().getTime()) {
                clearInterval(getUserInterval);
            }
        }, depositPollingInterval); // 1 min
    };
};

export const checkForPreviousDeposit = () => {
    return (dispatch) => {
        if (sessionStorage.getItem('depositPollingTimer') && sessionStorage.getItem('depositPollingTimer') > new Date().getTime()){
            dispatch(depositPolling());
        }
    };
};

export const getTransactionStatus = (transactionId) => {
    return (dispatch) => {
        jwtService.getTransactionStatus(transactionId).then((res)=>{
          dispatch(setTransactionDetail(res));
          dispatch({type:Actions.SET_TRANSACTION_LOADING , val : false});
        })
        .catch((error)=>{
            dispatch({type:Actions.SET_TRANSACTIONS_STATUS_ERROR , val:"something went wrong"})
            dispatch({type:Actions.SET_TRANSACTION_LOADING , val : false});
        })
    }
}

const setTransactionDetail = (data) =>{
    return (dispatch) => {
        dispatch({type:Actions.SET_TRANSACTION_DETAIL , data : data})
    }
}