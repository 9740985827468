export const LIVE_MARKET_DATA = {
    "id_2221^live_match^7": {
        "name_en": "Money Line",
        "name_aa": "Money Line",
        "name_de": "sieg",
        "name_nl": "Money Line",
        "name_pl": "Money Line",
        "name_ro": "Money Line",
        "name_ru": "Money Line",
        "name_tr": "Money Line",
        "name_se": "Money Line",
        "name_el": "Money Line",
        "name_es": "Money Line",
        "name_hr": "Pobjednik",
        "name_fr": "Money Line"
    },
    "id_64^live_match^7": {
        "name_en": "Who scores Xth point, including overtime",
        "name_aa": "Who scores Xth point, including overtime",
        "name_de": "Who scores Xth point, including overtime",
        "name_nl": "Wie scoort Xste punt, inclusief extratijd",
        "name_pl": "Who scores Xth point, including overtime",
        "name_ro": "Who scores Xth point, including overtime",
        "name_ru": "Who scores Xth point, including overtime",
        "name_tr": "Uzatmalar dahil,X. sayıyı önce hangi takım atacak",
        "name_se": "Who scores Xth point, including overtime",
        "name_el": "Who scores Xth point, including overtime",
        "name_es": "Who scores Xth point, including overtime",
        "name_hr": "Tko će osvojiti X-ti poen, uključujući produžetke",
        "name_fr": "Qui va marquer le xième point, prolongations incluses"
    },
    "id_2303^live_match^7": {
        "name_en": "Set Tie Break Winner",
        "name_aa": "Set Tie Break Winner",
        "name_de": "satz Tie Break Winner",
        "name_nl": "Set Wedstrijdtiebreak Winnaar",
        "name_pl": "Set Tie Break Winner",
        "name_ro": "Set Tie Break Winner",
        "name_ru": "Set Tie Break Winner",
        "name_tr": "Set Tie Break Winner",
        "name_se": "Set Tie Break Winner",
        "name_el": "Set Tie Break Winner",
        "name_es": "Set Tie Break Winner",
        "name_hr": "Set Tie Break Pobjednik",
        "name_fr": "Set Tie Break Winner"
    },
    "id_872^live_match^8": {
        "name_en": "Total 180s",
        "name_aa": "Total 180s",
        "name_de": "Gesamtergebnis 180er",
        "name_nl": "Totaal 180s",
        "name_pl": "Total 180s",
        "name_ro": "Total 180s",
        "name_ru": "Total 180s",
        "name_tr": "Toplam 180'ler",
        "name_se": "Total 180s",
        "name_el": "Total 180s",
        "name_es": "Total 180s",
        "name_hr": "Ukupno 180-ki",
        "name_fr": "Nombre total de 180"
    },
    "id_976^live_match^7": {
        "name_en": "Draw No Bet",
        "name_aa": "Draw No Bet",
        "name_de": "Unentschieden keine Wette",
        "name_nl": "Gelijkspel, geen weddenschap",
        "name_pl": "Draw No Bet",
        "name_ro": "Draw No Bet",
        "name_ru": "Draw No Bet",
        "name_tr": "Beraberlik dışı bahis",
        "name_se": "Draw No Bet",
        "name_el": "Draw No Bet",
        "name_es": "Draw No Bet",
        "name_hr": "Ishod bez neodlučenog",
        "name_fr": "Remboursé si match nul"
    },
    "id_88^live_match^8": {
        "name_en": "Total number of games in set 5",
        "name_aa": "Total number of games in set 5",
        "name_de": "Anzahl an Spielen im 5. Satz",
        "name_nl": "Totaal aantal games in set 5",
        "name_pl": "Total number of games in set 5",
        "name_ro": "Total number of games in set 5",
        "name_ru": "Total number of games in set 5",
        "name_tr": "5. Setteki toplam oyun sayısı",
        "name_se": "Total number of games in set 5",
        "name_el": "Total number of games in set 5",
        "name_es": "Total number of games in set 5",
        "name_hr": "Ukupan broj gemova u 5. setu",
        "name_fr": "Nombre total de jeux dans le 5e set"
    },
    "id_2344^live_match^8": {
        "name_en": "Next Minute Throw In [from]-[to]",
        "name_aa": "Next Minute Throw In [from]-[to]",
        "name_de": "nächste Minute einwürfe [from]-[to]",
        "name_nl": "Volgende Minute Throw In [from]-[to]",
        "name_pl": "Next Minute Throw In [from]-[to]",
        "name_ro": "Next Minute Throw In [from]-[to]",
        "name_ru": "Next Minute Throw In [from]-[to]",
        "name_tr": "Next Minute Throw In [from]-[to]",
        "name_se": "Next Minute Throw In [from]-[to]",
        "name_el": "Next Minute Throw In [from]-[to]",
        "name_es": "Next Minute Throw In [from]-[to]",
        "name_hr": "Minuti sljedeceg ubacivanja sa strane [from]-[to]",
        "name_fr": "Next Minute Throw In [from]-[to]"
    },
    "id_2097^live_match^6": {
        "name_en": "10 Minute Winner 3-Way",
        "name_aa": "10 Minute Winner 3-Way",
        "name_de": "10 Minute Sieger 3-Weg",
        "name_nl": "10 minuten Winnaar 3 opties",
        "name_pl": "10 Minute Winner 3-Way",
        "name_ro": "10 Minute Winner 3-Way",
        "name_ru": "10 Minute Winner 3-Way",
        "name_tr": "10 Minute Winner 3-Way",
        "name_se": "10 Minute Winner 3-Way",
        "name_el": "10 Minute Winner 3-Way",
        "name_es": "10 Minute Winner 3-Way",
        "name_hr": "Pobjednik prvih 10 minuta",
        "name_fr": "10 Minute Winner 3-Way"
    },
    "id_2307^live_match^8": {
        "name_en": "Set Tie Break Score after 6 Points",
        "name_aa": "Set Tie Break Score after 6 Points",
        "name_de": "Set Tie Break Score after 6 Points",
        "name_nl": "Set Wedstrijdtiebreak Scoren na 6 punten",
        "name_pl": "Set Tie Break Score after 6 Points",
        "name_ro": "Set Tie Break Score after 6 Points",
        "name_ru": "Set Tie Break Score after 6 Points",
        "name_tr": "Set Tie Break Score after 6 Points",
        "name_se": "Set Tie Break Score after 6 Points",
        "name_el": "Set Tie Break Score after 6 Points",
        "name_es": "Set Tie Break Score after 6 Points",
        "name_hr": "Set Tie Break rezultat poslije 6 Poena",
        "name_fr": "Set Tie Break Score after 6 Points"
    },
    "id_2232^live_match^6": {
        "name_en": "Inning Winner",
        "name_aa": "Inning Winner",
        "name_de": "Inning Sieger",
        "name_nl": "Inning Winnaar",
        "name_pl": "Inning Winner",
        "name_ro": "Inning Winner",
        "name_ru": "Inning Winner",
        "name_tr": "Inning Winner",
        "name_se": "Inning Winner",
        "name_el": "Inning Winner",
        "name_es": "Inning Winner",
        "name_hr": "Pobjednik Ininga",
        "name_fr": "Inning Winner"
    },
    "id_59^live_match^8": {
        "name_en": "Odd/Even for third period",
        "name_aa": "Odd/Even for third period",
        "name_de": "Gerade/Ungerade 3. Abschnitt",
        "name_nl": "Oneven/Even voor derde periode",
        "name_pl": "Odd/Even for third period",
        "name_ro": "Odd/Even for third period",
        "name_ru": "Odd/Even for third period",
        "name_tr": "Üçüncü periyot için Tek/Çift",
        "name_se": "Odd/Even for third period",
        "name_el": "Odd/Even for third period",
        "name_es": "Odd/Even for third period",
        "name_hr": "Nepar/Par za treći period",
        "name_fr": "Pair/Impair pour la troisième période"
    },
    "id_2057^live_match^8": {
        "name_en": "4th Quarter Double Chance",
        "name_aa": "4th Quarter Double Chance",
        "name_de": "4. Viertel Doppelte chance",
        "name_nl": "4th kwart Dubbele Chance",
        "name_pl": "4th Quarter Double Chance",
        "name_ro": "4th Quarter Double Chance",
        "name_ru": "4th Quarter Double Chance",
        "name_tr": "4th Quarter Double Chance",
        "name_se": "4th Quarter Double Chance",
        "name_el": "4th Quarter Double Chance",
        "name_es": "4th Quarter Double Chance",
        "name_hr": "4 Cetvrtina Doupla šansa",
        "name_fr": "4th Quarter Double Chance"
    },
    "id_2159^live_match^8": {
        "name_en": "Game Lead After",
        "name_aa": "Game Lead After",
        "name_de": "In führung nach (Spielen)",
        "name_nl": "Game Aan de leiding na",
        "name_pl": "Game Lead After",
        "name_ro": "Game Lead After",
        "name_ru": "Game Lead After",
        "name_tr": "Game Lead After",
        "name_se": "Game Lead After",
        "name_el": "Game Lead After",
        "name_es": "Game Lead After",
        "name_hr": "Igra vodi poslije",
        "name_fr": "Game Lead After"
    },
    "id_2233^live_match^6": {
        "name_en": "Inning Most Hits",
        "name_aa": "Inning Most Hits",
        "name_de": "Inning Most Hits",
        "name_nl": "Inning Meeste Hits",
        "name_pl": "Inning Most Hits",
        "name_ro": "Inning Most Hits",
        "name_ru": "Inning Most Hits",
        "name_tr": "Inning Most Hits",
        "name_se": "Inning Most Hits",
        "name_el": "Inning Most Hits",
        "name_es": "Inning Most Hits",
        "name_hr": "Ining sa najvise udaraca",
        "name_fr": "Inning Most Hits"
    },
    "id_2082^live_match^8": {
        "name_en": "Set Correct Score Group",
        "name_aa": "Set Correct Score Group",
        "name_de": "satz-genaues ergebnis- Gruppe",
        "name_nl": "Set Correcte Scoren Group",
        "name_pl": "Set Correct Score Group",
        "name_ro": "Set Correct Score Group",
        "name_ru": "Set Correct Score Group",
        "name_tr": "Set Correct Score Group",
        "name_se": "Set Correct Score Group",
        "name_el": "Set Correct Score Group",
        "name_es": "Set Correct Score Group",
        "name_hr": "Set Tačan rezultat Group",
        "name_fr": "Set Correct Score Group"
    },
    "id_2330^live_match^7": {
        "name_en": "Match Tie Break - First Mini-Break",
        "name_aa": "Match Tie Break - First Mini-Break",
        "name_de": "spiel Tie Break - 1. Mini-Break",
        "name_nl": "Wedstrijd Wedstrijdtiebreak - 1e Mini-Break",
        "name_pl": "Match Tie Break - First Mini-Break",
        "name_ro": "Match Tie Break - First Mini-Break",
        "name_ru": "Match Tie Break - First Mini-Break",
        "name_tr": "Match Tie Break - First Mini-Break",
        "name_se": "Match Tie Break - First Mini-Break",
        "name_el": "Match Tie Break - First Mini-Break",
        "name_es": "Match Tie Break - First Mini-Break",
        "name_hr": "Utakmica Tie Break - Prvi Mini-Break",
        "name_fr": "Match Tie Break - First Mini-Break"
    },
    "id_37^live_match^7": {
        "name_en": "Which team will win the match, including overtime?",
        "name_aa": "Which team will win the match, including overtime?",
        "name_de": "Wer gewinnt (inkl. VL)?",
        "name_nl": "Welk team zal de match -inclusief extra tijd- winnen?",
        "name_pl": "Which team will win the match, including overtime?",
        "name_ro": "Which team will win the match, including overtime?",
        "name_ru": "Which team will win the match, including overtime?",
        "name_tr": "Uzatmalar dahil maçı hangi takım kazanır?",
        "name_se": "Which team will win the match, including overtime?",
        "name_el": "Which team will win the match, including overtime?",
        "name_es": "Which team will win the match, including overtime?",
        "name_hr": "Koja momčad će pobjediti, uključujući produžetke",
        "name_fr": "Quelle équipe gagnera le match, prolongations incluses ?"
    },
    "id_58^live_match^8": {
        "name_en": "Odd/Even for second period",
        "name_aa": "Odd/Even for second period",
        "name_de": "Gerade/Ungerade 2. Abschnitt",
        "name_nl": "Oneven/Even voor tweede periode",
        "name_pl": "Odd/Even for second period",
        "name_ro": "Odd/Even for second period",
        "name_ru": "Odd/Even for second period",
        "name_tr": "İkinci periyot için Tek/Çift",
        "name_se": "Odd/Even for second period",
        "name_el": "Odd/Even for second period",
        "name_es": "Odd/Even for second period",
        "name_hr": "Nepar/Par za drugi period",
        "name_fr": "Pair/Impair pour la seconde période"
    },
    "id_32^live_match^8": {
        "name_en": "Goals away team",
        "name_aa": "Goals away team",
        "name_de": "Tore Auswärtsmannschaft",
        "name_nl": "Doelpunten uitteam",
        "name_pl": "Goals away team",
        "name_ro": "Goals away team",
        "name_ru": "Goals away team",
        "name_tr": "Deplasman takımı golleri",
        "name_se": "Goals away team",
        "name_el": "Goals away team",
        "name_es": "Goals away team",
        "name_hr": "Golovi Gost",
        "name_fr": "Buts équipe visiteuse"
    },
    "id_2300^live_match^8": {
        "name_en": "Total Tie Breaks in Match",
        "name_aa": "Total Tie Breaks in Match",
        "name_de": "Gesamtzahl Tie-Breaks im Match",
        "name_nl": "Totaal aantal tiebreaks in wedstrijd",
        "name_pl": "Total Tie Breaks in Match",
        "name_ro": "Total Tie Breaks in Match",
        "name_ru": "Total Tie Breaks in Match",
        "name_tr": "Total Tie Breaks in Match",
        "name_se": "Total Tie Breaks in Match",
        "name_el": "Total Tie Breaks in Match",
        "name_es": "Total Tie Breaks in Match",
        "name_hr": "Kraj Tie Breaks in Utakmica",
        "name_fr": "Total Tie Breaks in Match"
    },
    "id_2230^live_match^8": {
        "name_en": "test",
        "name_aa": "test",
        "name_de": "test",
        "name_nl": "test",
        "name_pl": "test",
        "name_ro": "test",
        "name_ru": "test",
        "name_tr": "test",
        "name_se": "test",
        "name_el": "test",
        "name_es": "test",
        "name_hr": "test",
        "name_fr": "test"
    },
    "id_2181^live_match^8": {
        "name_en": "Awayteam Total Scoring Shots",
        "name_aa": "Awayteam Total Scoring Shots",
        "name_de": "Auswärtsmannschaft - Spiel - Gesamtzahl erfolgreicher Schüsse",
        "name_nl": "Uitteam - Totaal aantal scorende schoten tijdens de wedstrijd",
        "name_pl": "Awayteam Total Scoring Shots",
        "name_ro": "Awayteam Total Scoring Shots",
        "name_ru": "Awayteam Total Scoring Shots",
        "name_tr": "Awayteam Total Scoring Shots",
        "name_se": "Awayteam Total Scoring Shots",
        "name_el": "Awayteam Total Scoring Shots",
        "name_es": "Awayteam Total Scoring Shots",
        "name_hr": "Tim 2 Kraj Scoring Shots",
        "name_fr": "Awayteam Total Scoring Shots"
    },
    "id_2333^live_match^8": {
        "name_en": "Awayteam Offsides",
        "name_aa": "Awayteam Offsides",
        "name_de": "Auswärtsmannschaft Abseits",
        "name_nl": "Uitteam Buitenspel",
        "name_pl": "Awayteam Offsides",
        "name_ro": "Awayteam Offsides",
        "name_ru": "Awayteam Offsides",
        "name_tr": "Awayteam Offsides",
        "name_se": "Awayteam Offsides",
        "name_el": "Awayteam Offsides",
        "name_es": "Awayteam Offsides",
        "name_hr": "Tim 2 Offsides",
        "name_fr": "Awayteam Offsides"
    },
    "id_2104^live_match^8": {
        "name_en": "Will Go To Shootout?",
        "name_aa": "Will Go To Shootout?",
        "name_de": "Wird das Spiel zum Shoot-Out kommen?",
        "name_nl": "Gaat de wedstrijd naar shootout?",
        "name_pl": "Will Go To Shootout?",
        "name_ro": "Will Go To Shootout?",
        "name_ru": "Will Go To Shootout?",
        "name_tr": "Will Go To Shootout?",
        "name_se": "Will Go To Shootout?",
        "name_el": "Will Go To Shootout?",
        "name_es": "Will Go To Shootout?",
        "name_hr": "Hoce li biti penala",
        "name_fr": "Will Go To Shootout?"
    },
    "id_349^live_match^7": {
        "name_en": "Asian handicap for sixth period",
        "name_aa": "Asian handicap for sixth period",
        "name_de": "Asian Handicap für 6. Abschnitt",
        "name_nl": "Asian handicap voor zesde period",
        "name_pl": "Asian handicap for sixth period",
        "name_ro": "Asian handicap for sixth period",
        "name_ru": "Asian handicap for sixth period",
        "name_tr": "Altıncı periyor için asya handikapı",
        "name_se": "Asian handicap for sixth period",
        "name_el": "Asian handicap for sixth period",
        "name_es": "Asian handicap for sixth period",
        "name_hr": "Azijski hendikep za šesti period",
        "name_fr": "Handicap asiatique pour la sixième période"
    },
    "id_38^live_match^7": {
        "name_en": "Asian handicap for whole match, including overtime",
        "name_aa": "Asian handicap for whole match, including overtime",
        "name_de": "Asian Handicap gesamtes Spiel (inkl. VL)",
        "name_nl": "Asian handicap voot volledige match, inclusief extra tijd",
        "name_pl": "Asian handicap for whole match, including overtime",
        "name_ro": "Asian handicap for whole match, including overtime",
        "name_ru": "Asian handicap for whole match, including overtime",
        "name_tr": "Uzatmalar dahil, tüm maç için Asya Handikabı",
        "name_se": "Asian handicap for whole match, including overtime",
        "name_el": "Asian handicap for whole match, including overtime",
        "name_es": "Asian handicap for whole match, including overtime",
        "name_hr": "Azijski hendikep za cijelu utakmicu, uključujući produžetke",
        "name_fr": "Handicap asiatique pour le match entier, prolongations incluses"
    },
    "id_2017^live_match^8": {
        "name_en": "4th Quarter - Total awayteam",
        "name_aa": "4th Quarter - Total awayteam",
        "name_de": "4. Viertel - Gesamthl Auswärtsmannschaft",
        "name_nl": "4th kwart - totaal Uitteam",
        "name_pl": "4th Quarter - Total awayteam",
        "name_ro": "4th Quarter - Total awayteam",
        "name_ru": "4th Quarter - Total awayteam",
        "name_tr": "4th Quarter - Total awayteam",
        "name_se": "4th Quarter - Total awayteam",
        "name_el": "4th Quarter - Total awayteam",
        "name_es": "4th Quarter - Total awayteam",
        "name_hr": "4 Cetvrtina - Kraj Tim 2",
        "name_fr": "4th Quarter - Total awayteam"
    },
    "id_512^live_match^8": {
        "name_en": "Tiebreak in match",
        "name_aa": "Tiebreak in match",
        "name_de": "Tiebreak im Spiel",
        "name_nl": "Tiebreak in Wedstrijd",
        "name_pl": "Tiebreak in match",
        "name_ro": "Tiebreak in match",
        "name_ru": "Tiebreak in match",
        "name_tr": "Maçtaki Tiebreak",
        "name_se": "Tiebreak in match",
        "name_el": "Tiebreak in match",
        "name_es": "Tiebreak in match",
        "name_hr": "Tiebreak u meču",
        "name_fr": "Tie-break dans le match"
    },
    "id_35^live_match^8": {
        "name_en": "Asian total first half",
        "name_aa": "Asian total first half",
        "name_de": "Asian Total erste Halbzeit",
        "name_nl": "Asian totaal eerste helft",
        "name_pl": "Asian total first half",
        "name_ro": "Asian total first half",
        "name_ru": "Asian total first half",
        "name_tr": "Asya toplam ilk yarı",
        "name_se": "Asian total first half",
        "name_el": "Asian total first half",
        "name_es": "Asian total first half",
        "name_hr": "Azijski Total Prvo Poluvrijeme",
        "name_fr": "Asiatique total première Mi-temps"
    },
    "id_2025^live_match^8": {
        "name_en": "4th Quarter Race to points",
        "name_aa": "4th Quarter Race to points",
        "name_de": "4. Viertel Race to points",
        "name_nl": "4th kwart Race naar punten",
        "name_pl": "4th Quarter Race to points",
        "name_ro": "4th Quarter Race to points",
        "name_ru": "4th Quarter Race to points",
        "name_tr": "4th Quarter Race to points",
        "name_se": "4th Quarter Race to points",
        "name_el": "4th Quarter Race to points",
        "name_es": "4th Quarter Race to points",
        "name_hr": "4 Cetvrtina trka do poena",
        "name_fr": "4th Quarter Race to points"
    },
    "id_2254^live_match^8": {
        "name_en": "A run scored - Awayteam Inning",
        "name_aa": "A run scored - Awayteam Inning",
        "name_de": "A run scored - Awayteam Inning",
        "name_nl": "A run scored - Awayteam Inning",
        "name_pl": "A run scored - Awayteam Inning",
        "name_ro": "A run scored - Awayteam Inning",
        "name_ru": "A run scored - Awayteam Inning",
        "name_tr": "A run scored - Awayteam Inning",
        "name_se": "A run scored - Awayteam Inning",
        "name_el": "A run scored - Awayteam Inning",
        "name_es": "A run scored - Awayteam Inning",
        "name_hr": "A run scored - Tim 2Inningom",
        "name_fr": "A run scored - Awayteam Inning"
    },
    "id_2151^live_match^8": {
        "name_en": "2nd Half Race to",
        "name_aa": "2nd Half Race to",
        "name_de": "2. Spielhälfte - Zuerst X",
        "name_nl": "2e Helft Race naar",
        "name_pl": "2nd Half Race to",
        "name_ro": "2nd Half Race to",
        "name_ru": "2nd Half Race to",
        "name_tr": "2nd Half Race to",
        "name_se": "2nd Half Race to",
        "name_el": "2nd Half Race to",
        "name_es": "2nd Half Race to",
        "name_hr": "2. Poluvrijeme trka do",
        "name_fr": "2nd Half Race to"
    },
    "id_0^live_match^2": {
        "name_en": "3way",
        "name_aa": "3way",
        "name_de": "3 Weg",
        "name_nl": "3opties",
        "name_pl": "3way",
        "name_ro": "3way",
        "name_ru": "3way",
        "name_tr": "Maç Sonucu",
        "name_se": "3way",
        "name_el": "3way",
        "name_es": "3way",
        "name_hr": "1X2",
        "name_fr": "1x2"
    },
    "id_1372^live_match^6": {
        "name_en": "2nd Half - 3way, including overtime",
        "name_aa": "2nd Half - 3way, including overtime",
        "name_de": "2. Halbzeit - 3Weg, inklusive Verlängerung",
        "name_nl": "2de Helft - 3way, inclusief overtime",
        "name_pl": "2nd Half - 3way, including overtime",
        "name_ro": "2nd Half - 3way, including overtime",
        "name_ru": "2nd Half - 3way, including overtime",
        "name_tr": "İkinci Yarı - 3 Yollu,Uzatmalar Dahil",
        "name_se": "2nd Half - 3way, including overtime",
        "name_el": "2nd Half - 3way, including overtime",
        "name_es": "2nd Half - 3way, including overtime",
        "name_hr": "2. Poluvrijeme - 1X2, uklj. produžetke",
        "name_fr": "2e Mi-temps - 1x2 prolongations incluses"
    },
    "id_2326^live_match^8": {
        "name_en": "Set Total Breaks of Serve",
        "name_aa": "Set Total Breaks of Serve",
        "name_de": "satz Gesamtzahl Breaks",
        "name_nl": "Set Totaal aantal servicebreaks",
        "name_pl": "Set Total Breaks of Serve",
        "name_ro": "Set Total Breaks of Serve",
        "name_ru": "Set Total Breaks of Serve",
        "name_tr": "Set Total Breaks of Serve",
        "name_se": "Set Total Breaks of Serve",
        "name_el": "Set Total Breaks of Serve",
        "name_es": "Set Total Breaks of Serve",
        "name_hr": "Set Kraj brejkova",
        "name_fr": "Set Total Breaks of Serve"
    },
    "id_518^live_match^8": {
        "name_en": "Game X to deuce",
        "name_aa": "Game X to deuce",
        "name_de": "spiel X to deuce",
        "name_nl": "Game X wordt een deuce",
        "name_pl": "Game X to deuce",
        "name_ro": "Game X to deuce",
        "name_ru": "Game X to deuce",
        "name_tr": "X numaralı oyun berabere",
        "name_se": "Game X to deuce",
        "name_el": "Game X to deuce",
        "name_es": "Game X to deuce",
        "name_hr": "X. gem za izjednačenje",
        "name_fr": "Jeu X qui va à égalité"
    },
    "id_1663^live_match^2": {
        "name_en": "1X2 60 min",
        "name_aa": "1X2 60 min",
        "name_de": "1X2 60 min",
        "name_nl": "1X2 60 min",
        "name_pl": "1X2 60 min",
        "name_ro": "1X2 60 min",
        "name_ru": "1X2 60 min",
        "name_tr": "1X2 60 min",
        "name_se": "1X2 60 min",
        "name_el": "1X2 60 min",
        "name_es": "1X2 60 min",
        "name_hr": "1X2 60 min",
        "name_fr": "1X2 60 min"
    },
    "id_2110^live_match^8": {
        "name_en": "When Will Game End?",
        "name_aa": "When Will Game End?",
        "name_de": "When Will Game End?",
        "name_nl": "Wanneer eindigt de wedstrijd",
        "name_pl": "When Will Game End?",
        "name_ro": "When Will Game End?",
        "name_ru": "When Will Game End?",
        "name_tr": "When Will Game End?",
        "name_se": "When Will Game End?",
        "name_el": "When Will Game End?",
        "name_es": "When Will Game End?",
        "name_hr": "Kada ce se zavrsiti?",
        "name_fr": "When Will Game End?"
    },
    "id_2332^live_match^8": {
        "name_en": "Hometeam Offsides",
        "name_aa": "Hometeam Offsides",
        "name_de": "heimmannschaft Abseits",
        "name_nl": "Thuisteam Buitenspel",
        "name_pl": "Hometeam Offsides",
        "name_ro": "Hometeam Offsides",
        "name_ru": "Hometeam Offsides",
        "name_tr": "Hometeam Offsides",
        "name_se": "Hometeam Offsides",
        "name_el": "Hometeam Offsides",
        "name_es": "Hometeam Offsides",
        "name_hr": "Tim 1 Offsides",
        "name_fr": "Hometeam Offsides"
    },
    "id_33^live_match^8": {
        "name_en": "Asian total",
        "name_aa": "Asian total",
        "name_de": "Asian Gesamthl",
        "name_nl": "Asian totaal",
        "name_pl": "Asian total",
        "name_ro": "Asian total",
        "name_ru": "Asian total",
        "name_tr": "Asya toplam",
        "name_se": "Asian total",
        "name_el": "Asian total",
        "name_es": "Asian total",
        "name_hr": "Azijski ukupno",
        "name_fr": "Asiatique total"
    },
    "id_2186^live_match^8": {
        "name_en": "Quarter Total Scoring Shots",
        "name_aa": "Quarter Total Scoring Shots",
        "name_de": "Viertel – Gesamtzahl erfolgreicher Schüsse",
        "name_nl": "Kwart - Totaal aantal scorende schoten",
        "name_pl": "Quarter Total Scoring Shots",
        "name_ro": "Quarter Total Scoring Shots",
        "name_ru": "Quarter Total Scoring Shots",
        "name_tr": "Quarter Total Scoring Shots",
        "name_se": "Quarter Total Scoring Shots",
        "name_el": "Quarter Total Scoring Shots",
        "name_es": "Quarter Total Scoring Shots",
        "name_hr": "Cetvrtina Kraj Scoring Shots",
        "name_fr": "Quarter Total Scoring Shots"
    },
    "id_2128^live_match^8": {
        "name_en": "Period Correct Score",
        "name_aa": "Period Correct Score",
        "name_de": "drittel genaues erzielt",
        "name_nl": "Periode Correcte Scoren",
        "name_pl": "Period Correct Score",
        "name_ro": "Period Correct Score",
        "name_ru": "Period Correct Score",
        "name_tr": "Period Correct Score",
        "name_se": "Period Correct Score",
        "name_el": "Period Correct Score",
        "name_es": "Period Correct Score",
        "name_hr": "Period Tačan rezultat",
        "name_fr": "Period Correct Score"
    },
    "id_1672^live_match^8": {
        "name_en": "away team to score in both halves",
        "name_aa": "away team to score in both halves",
        "name_de": "Auswärtsmannschaft zu spielstand in spielhälften",
        "name_nl": "Uitteam Scoren in Beide halves",
        "name_pl": "away team to score in both halves",
        "name_ro": "away team to score in both halves",
        "name_ru": "away team to score in both halves",
        "name_tr": "away team to score in both halves",
        "name_se": "away team to score in both halves",
        "name_el": "away team to score in both halves",
        "name_es": "away team to score in both halves",
        "name_hr": "Tim 2 Postići gol u oba poluvremena",
        "name_fr": "away team to score in both halves"
    },
    "id_34^live_match^7": {
        "name_en": "Asian Handicap",
        "name_aa": "Asian Handicap",
        "name_de": "Asian Handicap",
        "name_nl": "Aziatische handicap",
        "name_pl": "Asian Handicap",
        "name_ro": "Asian Handicap",
        "name_ru": "Asian Handicap",
        "name_tr": "Asya Handikabı",
        "name_se": "Asian Handicap",
        "name_el": "Asian Handicap",
        "name_es": "Asian Handicap",
        "name_hr": "Azijski Hendikep",
        "name_fr": "Handicap asiatique"
    },
    "id_16^live_match^7": {
        "name_en": "Draw No Bet",
        "name_aa": "Draw No Bet",
        "name_de": "Unentschieden keine Wette",
        "name_nl": "Gelijkspel, geen weddenschap",
        "name_pl": "Draw No Bet",
        "name_ro": "Draw No Bet",
        "name_ru": "Draw No Bet",
        "name_tr": "Beraberlikte iade",
        "name_se": "Draw No Bet",
        "name_el": "Draw No Bet",
        "name_es": "Draw No Bet",
        "name_hr": "Ishod bez neodlučenog",
        "name_fr": "Remboursé si match nul"
    },
    "id_2022^live_match^8": {
        "name_en": "1st Quarter Race to points",
        "name_aa": "1st Quarter Race to points",
        "name_de": "1. Viertel Race to points",
        "name_nl": "1e kwart Race naar punten",
        "name_pl": "1st Quarter Race to points",
        "name_ro": "1st Quarter Race to points",
        "name_ru": "1st Quarter Race to points",
        "name_tr": "1st Quarter Race to points",
        "name_se": "1st Quarter Race to points",
        "name_el": "1st Quarter Race to points",
        "name_es": "1st Quarter Race to points",
        "name_hr": "1 Cetvrtina trka do poena",
        "name_fr": "1st Quarter Race to points"
    },
    "id_101^live_match^6": {
        "name_en": "Handicap for first half",
        "name_aa": "Handicap for first half",
        "name_de": "Handicap 1. Halbzeit",
        "name_nl": "Handicap voor eerste helft",
        "name_pl": "Handicap for first half",
        "name_ro": "Handicap for first half",
        "name_ru": "Handicap for first half",
        "name_tr": "İlk yarı için handikap",
        "name_se": "Handicap for first half",
        "name_el": "Handicap for first half",
        "name_es": "Handicap for first half",
        "name_hr": "Hendikep za prvo poluvrijeme",
        "name_fr": "Handicap pour la première mi-temps"
    },
    "id_2259^live_match^8": {
        "name_en": "Money Line and Total",
        "name_aa": "Money Line and Total",
        "name_de": "sieg Und Gesamtzahl",
        "name_nl": "Money Line en Totaal",
        "name_pl": "Money Line and Total",
        "name_ro": "Money Line and Total",
        "name_ru": "Money Line and Total",
        "name_tr": "Money Line and Total",
        "name_se": "Money Line and Total",
        "name_el": "Money Line and Total",
        "name_es": "Money Line and Total",
        "name_hr": "Money Line i  Kraj",
        "name_fr": "Money Line and Total"
    },
    "id_2353^live_match^8": {
        "name_en": "Awayteam Goals (Bands)",
        "name_aa": "Awayteam Goals (Bands)",
        "name_de": "Auswärtsmannschaft Tore (Bands)",
        "name_nl": "Uitteam doelpunts (Bands)",
        "name_pl": "Awayteam Goals (Bands)",
        "name_ro": "Awayteam Goals (Bands)",
        "name_ru": "Awayteam Goals (Bands)",
        "name_tr": "Awayteam Goals (Bands)",
        "name_se": "Awayteam Goals (Bands)",
        "name_el": "Awayteam Goals (Bands)",
        "name_es": "Awayteam Goals (Bands)",
        "name_hr": "Tim 2 Golova (Bands)",
        "name_fr": "Awayteam Goals (Bands)"
    },
    "id_2125^live_match^8": {
        "name_en": "Period Lead After",
        "name_aa": "Period Lead After",
        "name_de": "drittel Lead After",
        "name_nl": "Aan de leiding na Periode",
        "name_pl": "Period Lead After",
        "name_ro": "Period Lead After",
        "name_ru": "Period Lead After",
        "name_tr": "Period Lead After",
        "name_se": "Period Lead After",
        "name_el": "Period Lead After",
        "name_es": "Period Lead After",
        "name_hr": "Period Lead After",
        "name_fr": "Period Lead After"
    },
    "id_2255^live_match^8": {
        "name_en": "Hit - Hometeam Inning",
        "name_aa": "Hit - Hometeam Inning",
        "name_de": "Hit - heimmannschaft Inning",
        "name_nl": "Hit - Thuisteam Inning",
        "name_pl": "Hit - Hometeam Inning",
        "name_ro": "Hit - Hometeam Inning",
        "name_ru": "Hit - Hometeam Inning",
        "name_tr": "Hit - Hometeam Inning",
        "name_se": "Hit - Hometeam Inning",
        "name_el": "Hit - Hometeam Inning",
        "name_es": "Hit - Hometeam Inning",
        "name_hr": "Hit - Tim 1Inningom",
        "name_fr": "Hit - Hometeam Inning"
    },
    "id_956^live_match^6": {
        "name_en": "3way for second period",
        "name_aa": "3way for second period",
        "name_de": "3Weg für zweiten Abschnitt",
        "name_nl": "3way voor tweede period",
        "name_pl": "3way for second period",
        "name_ro": "3way for second period",
        "name_ru": "3way for second period",
        "name_tr": "İkinci periyot için 3 yol",
        "name_se": "3way for second period",
        "name_el": "3way for second period",
        "name_es": "3way for second period",
        "name_hr": "1X2 za drugi period",
        "name_fr": "1x2 pour la deuxième période"
    },
    "id_2199^live_match^8": {
        "name_en": "Next Score",
        "name_aa": "Next Score",
        "name_de": "nächste erzielt",
        "name_nl": "Volgende Scoren",
        "name_pl": "Next Score",
        "name_ro": "Next Score",
        "name_ru": "Next Score",
        "name_tr": "Next Score",
        "name_se": "Next Score",
        "name_el": "Next Score",
        "name_es": "Next Score",
        "name_hr": "Sljedeći gol",
        "name_fr": "Next Score"
    },
    "id_2331^live_match^8": {
        "name_en": "Penalties Taken in Shootout",
        "name_aa": "Penalties Taken in Shootout",
        "name_de": "Penalties Taken in Shootout",
        "name_nl": "Penalties Taken in Shootout",
        "name_pl": "Penalties Taken in Shootout",
        "name_ro": "Penalties Taken in Shootout",
        "name_ru": "Penalties Taken in Shootout",
        "name_tr": "Penalties Taken in Shootout",
        "name_se": "Penalties Taken in Shootout",
        "name_el": "Penalties Taken in Shootout",
        "name_es": "Penalties Taken in Shootout",
        "name_hr": "Postize penal",
        "name_fr": "Penalties Taken in Shootout"
    },
    "id_2320^live_match^8": {
        "name_en": "Player1 Total Breaks of Serve",
        "name_aa": "Player1 Total Breaks of Serve",
        "name_de": "spieler 1 Gesamtzahl Breaks",
        "name_nl": "Speler 1 Totaal aantal servicebreaks",
        "name_pl": "Player1 Total Breaks of Serve",
        "name_ro": "Player1 Total Breaks of Serve",
        "name_ru": "Player1 Total Breaks of Serve",
        "name_tr": "Player1 Total Breaks of Serve",
        "name_se": "Player1 Total Breaks of Serve",
        "name_el": "Player1 Total Breaks of Serve",
        "name_es": "Player1 Total Breaks of Serve",
        "name_hr": "Igrač 1 ukupno brekova",
        "name_fr": "Player1 Total Breaks of Serve"
    },
    "id_2219^live_match^8": {
        "name_en": "Score After 2 Sets",
        "name_aa": "Score After 2 Sets",
        "name_de": "erzielt After 2 satzs",
        "name_nl": "Score na 2 Sets",
        "name_pl": "Score After 2 Sets",
        "name_ro": "Score After 2 Sets",
        "name_ru": "Score After 2 Sets",
        "name_tr": "Score After 2 Sets",
        "name_se": "Score After 2 Sets",
        "name_el": "Score After 2 Sets",
        "name_es": "Score After 2 Sets",
        "name_hr": "Rezultat poslije 2 seta",
        "name_fr": "Score After 2 Sets"
    },
    "id_2103^live_match^8": {
        "name_en": "Either Team To Score 3 Successive Goals",
        "name_aa": "Either Team To Score 3 Successive Goals",
        "name_de": "Either Team zu spielstand 3 Successive Tore",
        "name_nl": "Een Team Scoren 3 Successive doelpunts",
        "name_pl": "Either Team To Score 3 Successive Goals",
        "name_ro": "Either Team To Score 3 Successive Goals",
        "name_ru": "Either Team To Score 3 Successive Goals",
        "name_tr": "Either Team To Score 3 Successive Goals",
        "name_se": "Either Team To Score 3 Successive Goals",
        "name_el": "Either Team To Score 3 Successive Goals",
        "name_es": "Either Team To Score 3 Successive Goals",
        "name_hr": "Niko ne postize 3 gola za redom",
        "name_fr": "Either Team To Score 3 Successive Goals"
    },
    "id_1312^live_match^8": {
        "name_en": "1st Half - Total tries",
        "name_aa": "1st Half - Total tries",
        "name_de": "1. HZ - Anzahl Versuche",
        "name_nl": "1ste Helft - Totaal tries",
        "name_pl": "1st Half - Total tries",
        "name_ro": "1st Half - Total tries",
        "name_ru": "1st Half - Total tries",
        "name_tr": "1. Yarı - Toplam denemeler",
        "name_se": "1st Half - Total tries",
        "name_el": "1st Half - Total tries",
        "name_es": "1st Half - Total tries",
        "name_hr": "Prvo Poluvrijeme - Ukupno polaganja",
        "name_fr": "1ère Mi-temps - Total essais"
    },
    "id_2198^live_match^8": {
        "name_en": "TriBet",
        "name_aa": "TriBet",
        "name_de": "TriBet",
        "name_nl": "TriBet",
        "name_pl": "TriBet",
        "name_ro": "TriBet",
        "name_ru": "TriBet",
        "name_tr": "TriBet",
        "name_se": "TriBet",
        "name_el": "TriBet",
        "name_es": "TriBet",
        "name_hr": "TriBet",
        "name_fr": "TriBet"
    },
    "id_1523^live_match^8": {
        "name_en": "Double chance (1X - 12 - X2) and Totals",
        "name_aa": "Double chance (1X - 12 - X2) and Totals",
        "name_de": "Doppelte Chance (1X - 12 - X2) und Totals",
        "name_nl": "Dubbele kans (1X - 12 - X2) en Totalen",
        "name_pl": "Double chance (1X - 12 - X2) and Totals",
        "name_ro": "Double chance (1X - 12 - X2) and Totals",
        "name_ru": "Double chance (1X - 12 - X2) and Totals",
        "name_tr": "Çifte şans ( 1X - 12 -X2) ve Toplamlar",
        "name_se": "Double chance (1X - 12 - X2) and Totals",
        "name_el": "Double chance (1X - 12 - X2) and Totals",
        "name_es": "Double chance (1X - 12 - X2) and Totals",
        "name_hr": "Dvostruka Prilika (1X - 12 - X2) i total",
        "name_fr": "Double chance (1X - 12 - X2) et Totaux"
    },
    "id_2301^live_match^8": {
        "name_en": "Most Aces",
        "name_aa": "Most Aces",
        "name_de": "Meiste Asse",
        "name_nl": "Meeste Aces",
        "name_pl": "Most Aces",
        "name_ro": "Most Aces",
        "name_ru": "Most Aces",
        "name_tr": "Most Aces",
        "name_se": "Most Aces",
        "name_el": "Most Aces",
        "name_es": "Most Aces",
        "name_hr": "Najvise aseva",
        "name_fr": "Most Aces"
    },
    "id_904^live_match^7": {
        "name_en": "Who will win end [endNr] in set [setNr]",
        "name_aa": "Who will win end [endNr] in set [setNr]",
        "name_de": "Wer gewinnt End [endNr] in Satz [setNr]",
        "name_nl": "Wie zal einde [endNr] in set [setNr]",
        "name_pl": "Who will win end [endNr] in set [setNr]",
        "name_ro": "Who will win end [endNr] in set [setNr]",
        "name_ru": "Who will win end [endNr] in set [setNr]",
        "name_tr": "[setNr] sette hangi takım bitirecek [endNr]",
        "name_se": "Who will win end [endNr] in set [setNr]",
        "name_el": "Who will win end [endNr] in set [setNr]",
        "name_es": "Who will win end [endNr] in set [setNr]",
        "name_hr": "Tko će osvojiti kraj [endNr] u setu [setNr]",
        "name_fr": "Qui gagnera la fin [endNr] dans le set [setNr]"
    },
    "id_2101^live_match^8": {
        "name_en": "Awayteam To Score Next Penalty",
        "name_aa": "Awayteam To Score Next Penalty",
        "name_de": "Auswärtsmannschaft zu spielstand nächste Elfmeter",
        "name_nl": "Uitteam Scoren Volgende Strafschoppen",
        "name_pl": "Awayteam To Score Next Penalty",
        "name_ro": "Awayteam To Score Next Penalty",
        "name_ru": "Awayteam To Score Next Penalty",
        "name_tr": "Awayteam To Score Next Penalty",
        "name_se": "Awayteam To Score Next Penalty",
        "name_el": "Awayteam To Score Next Penalty",
        "name_es": "Awayteam To Score Next Penalty",
        "name_hr": "Tim 2 Postize Sljedeći Penalt",
        "name_fr": "Awayteam To Score Next Penalty"
    },
    "id_870^live_match^6": {
        "name_en": "Most 180s",
        "name_aa": "Most 180s",
        "name_de": "Meiste 180er",
        "name_nl": "Meeste 180s",
        "name_pl": "Most 180s",
        "name_ro": "Most 180s",
        "name_ru": "Most 180s",
        "name_tr": "En çok 180'ler",
        "name_se": "Most 180s",
        "name_el": "Most 180s",
        "name_es": "Most 180s",
        "name_hr": "Najviše 180-ki",
        "name_fr": "Le plus de 180"
    },
    "id_230^live_match^8": {
        "name_en": "How will the match be decided?",
        "name_aa": "How will the match be decided?",
        "name_de": "Womit wird das Spiel entschieden?",
        "name_nl": "Hoe zal de match beslist worden?",
        "name_pl": "How will the match be decided?",
        "name_ro": "How will the match be decided?",
        "name_ru": "How will the match be decided?",
        "name_tr": "Maç galibi nasıl belirlenecek?",
        "name_se": "How will the match be decided?",
        "name_el": "How will the match be decided?",
        "name_es": "How will the match be decided?",
        "name_hr": "Kako će meč biti odlučen?",
        "name_fr": "Comment le match va-t-il se conclure ?"
    },
    "id_2173^live_match^8": {
        "name_en": "Match Behinds",
        "name_aa": "Match Behinds",
        "name_de": "spiel Behinds",
        "name_nl": "Wedstrijd achterstands",
        "name_pl": "Match Behinds",
        "name_ro": "Match Behinds",
        "name_ru": "Match Behinds",
        "name_tr": "Match Behinds",
        "name_se": "Match Behinds",
        "name_el": "Match Behinds",
        "name_es": "Match Behinds",
        "name_hr": "Utakmica Behinds",
        "name_fr": "Match Behinds"
    },
    "id_2223^live_match^8": {
        "name_en": "Total Runs hometeam",
        "name_aa": "Total Runs hometeam",
        "name_de": "Gesamthl Runs heimmannschaft",
        "name_nl": "Totaal aantal run Thuisteam",
        "name_pl": "Total Runs hometeam",
        "name_ro": "Total Runs hometeam",
        "name_ru": "Total Runs hometeam",
        "name_tr": "Total Runs hometeam",
        "name_se": "Total Runs hometeam",
        "name_el": "Total Runs hometeam",
        "name_es": "Total Runs hometeam",
        "name_hr": "Kraj Runs Tim 1",
        "name_fr": "Total Runs hometeam"
    },
    "id_2229^live_match^6": {
        "name_en": "Most Hits",
        "name_aa": "Most Hits",
        "name_de": "Die meisten Hits",
        "name_nl": "Meeste Hits",
        "name_pl": "Most Hits",
        "name_ro": "Most Hits",
        "name_ru": "Most Hits",
        "name_tr": "Most Hits",
        "name_se": "Most Hits",
        "name_el": "Most Hits",
        "name_es": "Most Hits",
        "name_hr": "Najvise udaraca",
        "name_fr": "Most Hits"
    },
    "id_2349^live_match^8": {
        "name_en": "Hometeam Time of goal",
        "name_aa": "Hometeam Time of goal",
        "name_de": "heimmannschaft Zeit des Tor",
        "name_nl": "Thuisteam Tijdstip van doelpunt",
        "name_pl": "Hometeam Time of goal",
        "name_ro": "Hometeam Time of goal",
        "name_ru": "Hometeam Time of goal",
        "name_tr": "Hometeam Time of goal",
        "name_se": "Hometeam Time of goal",
        "name_el": "Hometeam Time of goal",
        "name_es": "Hometeam Time of goal",
        "name_hr": "Tim 1 vrijeme gola",
        "name_fr": "Hometeam Time of goal"
    },
    "id_116^live_match^8": {
        "name_en": "Overtime Correct score flex",
        "name_aa": "Overtime Correct score flex",
        "name_de": "Verlängerung genaues Ergebnis",
        "name_nl": "Extra tijd Correcte score flex",
        "name_pl": "Overtime Correct score flex",
        "name_ro": "Overtime Correct score flex",
        "name_ru": "Overtime Correct score flex",
        "name_tr": "Uzatmalar Doğru Skor Aralığı",
        "name_se": "Overtime Correct score flex",
        "name_el": "Overtime Correct score flex",
        "name_es": "Overtime Correct score flex",
        "name_hr": "Fleksibilan Točan rezultat u produžecima",
        "name_fr": "Prolongations Score correct flexible"
    },
    "id_2161^live_match^8": {
        "name_en": "Player 2 Totals",
        "name_aa": "Player 2 Totals",
        "name_de": "Spieler 2 - Gesamt",
        "name_nl": "Speler 2 Totaals",
        "name_pl": "Player 2 Totals",
        "name_ro": "Player 2 Totals",
        "name_ru": "Player 2 Totals",
        "name_tr": "Player 2 Totals",
        "name_se": "Player 2 Totals",
        "name_el": "Player 2 Totals",
        "name_es": "Player 2 Totals",
        "name_hr": "Igrač  2 Kraj",
        "name_fr": "Player 2 Totals"
    },
    "id_2135^live_match^8": {
        "name_en": "Both Teams to Score Goals",
        "name_aa": "Both Teams to Score Goals",
        "name_de": "beide Teams zu spielstand Tore",
        "name_nl": "Beide teams Scoren doelpunts",
        "name_pl": "Both Teams to Score Goals",
        "name_ro": "Both Teams to Score Goals",
        "name_ru": "Both Teams to Score Goals",
        "name_tr": "Both Teams to Score Goals",
        "name_se": "Both Teams to Score Goals",
        "name_el": "Both Teams to Score Goals",
        "name_es": "Both Teams to Score Goals",
        "name_hr": "Oba daju gol",
        "name_fr": "Both Teams to Score Goals"
    },
    "id_2240^live_match^7": {
        "name_en": "7 Innings Run Line",
        "name_aa": "7 Innings Run Line",
        "name_de": "7 Innings handicap",
        "name_nl": "7 Innings Handicap",
        "name_pl": "7 Innings Run Line",
        "name_ro": "7 Innings Run Line",
        "name_ru": "7 Innings Run Line",
        "name_tr": "7 Innings Run Line",
        "name_se": "7 Innings Run Line",
        "name_el": "7 Innings Run Line",
        "name_es": "7 Innings Run Line",
        "name_hr": "7 Inningom Run Line",
        "name_fr": "7 Innings Run Line"
    },
    "id_2227^live_match^8": {
        "name_en": "Total Hits hometeam",
        "name_aa": "Total Hits hometeam",
        "name_de": "Gesamthl Hits heimmannschaft",
        "name_nl": "Totaal Hits Thuisteam",
        "name_pl": "Total Hits hometeam",
        "name_ro": "Total Hits hometeam",
        "name_ru": "Total Hits hometeam",
        "name_tr": "Total Hits hometeam",
        "name_se": "Total Hits hometeam",
        "name_el": "Total Hits hometeam",
        "name_es": "Total Hits hometeam",
        "name_hr": "Kraj Hits Tim 1",
        "name_fr": "Total Hits hometeam"
    },
    "id_2337^live_match^8": {
        "name_en": "Awayteam Shots",
        "name_aa": "Awayteam Shots",
        "name_de": "Auswärtsmannschaft Schüsse",
        "name_nl": "Uitteam schoten",
        "name_pl": "Awayteam Shots",
        "name_ro": "Awayteam Shots",
        "name_ru": "Awayteam Shots",
        "name_tr": "Awayteam Shots",
        "name_se": "Awayteam Shots",
        "name_el": "Awayteam Shots",
        "name_es": "Awayteam Shots",
        "name_hr": "Tim 2 udaraca",
        "name_fr": "Awayteam Shots"
    },
    "id_69^live_match^8": {
        "name_en": "Odd/Even for fifth period",
        "name_aa": "Odd/Even for fifth period",
        "name_de": "Gerade/Ungerade 5. Abschnitt",
        "name_nl": "Oneven/Even voor vijfde periode",
        "name_pl": "Odd/Even for fifth period",
        "name_ro": "Odd/Even for fifth period",
        "name_ru": "Odd/Even for fifth period",
        "name_tr": "Beşinci Periyot için Tek/Çift",
        "name_se": "Odd/Even for fifth period",
        "name_el": "Odd/Even for fifth period",
        "name_es": "Odd/Even for fifth period",
        "name_hr": "Nepar/Par za peti period",
        "name_fr": "Pair/Impair pour la cinquième période"
    },
    "id_2071^live_match^8": {
        "name_en": "Set Winning Margin",
        "name_aa": "Set Winning Margin",
        "name_de": "satz Siegesvorsprung",
        "name_nl": "Set Winstmarge",
        "name_pl": "Set Winning Margin",
        "name_ro": "Set Winning Margin",
        "name_ru": "Set Winning Margin",
        "name_tr": "Set Winning Margin",
        "name_se": "Set Winning Margin",
        "name_el": "Set Winning Margin",
        "name_es": "Set Winning Margin",
        "name_hr": "Set Pobjeda Razlikom",
        "name_fr": "Set Winning Margin"
    },
    "id_2245^live_match^8": {
        "name_en": "Race To (Hits)",
        "name_aa": "Race To (Hits)",
        "name_de": "Zuerst  (Hits)",
        "name_nl": "Race naar (Hits)",
        "name_pl": "Race To (Hits)",
        "name_ro": "Race To (Hits)",
        "name_ru": "Race To (Hits)",
        "name_tr": "Race To (Hits)",
        "name_se": "Race To (Hits)",
        "name_el": "Race To (Hits)",
        "name_es": "Race To (Hits)",
        "name_hr": "Trka do (Hits)",
        "name_fr": "Race To (Hits)"
    },
    "id_19^live_match^8": {
        "name_en": "Final Result (in sets - best of 5)",
        "name_aa": "Final Result (in sets - best of 5)",
        "name_de": "Endergebnis (in Sätzen - Best of 5)",
        "name_nl": "Definitief resultaat  In aantal set - best of 5)",
        "name_pl": "Final Result (in sets - best of 5)",
        "name_ro": "Final Result (in sets - best of 5)",
        "name_ru": "Final Result (in sets - best of 5)",
        "name_tr": "Nihai Sonuç (setlerde - 5 set üzerinden)",
        "name_se": "Final Result (in sets - best of 5)",
        "name_el": "Final Result (in sets - best of 5)",
        "name_es": "Final Result (in sets - best of 5)",
        "name_hr": "Finalni rezultat (u setovima - najbolji u 5)",
        "name_fr": "Résultat Final (en sets - au meilleur des 5 manches)"
    },
    "id_29^live_match^8": {
        "name_en": "Correct score first half flex",
        "name_aa": "Correct score first half flex",
        "name_de": "Endstand flexibel 1. Halbzeit",
        "name_nl": "Correcte score eerste helft flex",
        "name_pl": "Correct score first half flex",
        "name_ro": "Correct score first half flex",
        "name_ru": "Correct score first half flex",
        "name_tr": "İlk yarı doğru skor aralığı",
        "name_se": "Correct score first half flex",
        "name_el": "Correct score first half flex",
        "name_es": "Correct score first half flex",
        "name_hr": "Točan rezultat prvo poluvrijeme flex",
        "name_fr": "Score correct première mi-temps flexible"
    },
    "id_2237^live_match^7": {
        "name_en": "5 Innings Run Line",
        "name_aa": "5 Innings Run Line",
        "name_de": "5 Innings handicap",
        "name_nl": "5 Innings Handicap",
        "name_pl": "5 Innings Run Line",
        "name_ro": "5 Innings Run Line",
        "name_ru": "5 Innings Run Line",
        "name_tr": "5 Innings Run Line",
        "name_se": "5 Innings Run Line",
        "name_el": "5 Innings Run Line",
        "name_es": "5 Innings Run Line",
        "name_hr": "5 Inningom Run Line",
        "name_fr": "5 Innings Run Line"
    },
    "id_2323^live_match^8": {
        "name_en": "Player2 Total Double Faults",
        "name_aa": "Player2 Total Double Faults",
        "name_de": "Spieler 2 Gesamtzahl Doppelfehler",
        "name_nl": "Speler 2 Totaal aantal dubbele fouten",
        "name_pl": "Player2 Total Double Faults",
        "name_ro": "Player2 Total Double Faults",
        "name_ru": "Player2 Total Double Faults",
        "name_tr": "Player2 Total Double Faults",
        "name_se": "Player2 Total Double Faults",
        "name_el": "Player2 Total Double Faults",
        "name_es": "Player2 Total Double Faults",
        "name_hr": "Igrač 2 ukupno duplih greski",
        "name_fr": "Player2 Total Double Faults"
    },
    "id_2007^live_match^8": {
        "name_en": "Match Corners",
        "name_aa": "Match Corners",
        "name_de": "spiel ecken",
        "name_nl": "Wedstrijd Hoekschoppen",
        "name_pl": "Match Corners",
        "name_ro": "Match Corners",
        "name_ru": "Match Corners",
        "name_tr": "Match Corners",
        "name_se": "Match Corners",
        "name_el": "Match Corners",
        "name_es": "Match Corners",
        "name_hr": "Utakmica korneri",
        "name_fr": "Match Corners"
    },
    "id_2027^live_match^8": {
        "name_en": "2nd Half 3-Way Total",
        "name_aa": "2nd Half 3-Way Total",
        "name_de": "2. Halfen 3-Way Gesamthl",
        "name_nl": "2e Helft 3 opties totaal",
        "name_pl": "2nd Half 3-Way Total",
        "name_ro": "2nd Half 3-Way Total",
        "name_ru": "2nd Half 3-Way Total",
        "name_tr": "2nd Half 3-Way Total",
        "name_se": "2nd Half 3-Way Total",
        "name_el": "2nd Half 3-Way Total",
        "name_es": "2nd Half 3-Way Total",
        "name_hr": "2nd Poluvrijeme 3-Way Kraj",
        "name_fr": "2nd Half 3-Way Total"
    },
    "id_898^live_match^6": {
        "name_en": "Who will win the [setNr!] set (1-x-2)?",
        "name_aa": "Who will win the [setNr!] set (1-x-2)?",
        "name_de": "Wer gewinnt den [setNr!] Satz (1-X-2)?",
        "name_nl": "Wie zal de [setNr!] set (1-x-2)?",
        "name_pl": "Who will win the [setNr!] set (1-x-2)?",
        "name_ro": "Who will win the [setNr!] set (1-x-2)?",
        "name_ru": "Who will win the [setNr!] set (1-x-2)?",
        "name_tr": "[setNr!] seti hangi takım (1-x-2) kazanacak",
        "name_se": "Who will win the [setNr!] set (1-x-2)?",
        "name_el": "Who will win the [setNr!] set (1-x-2)?",
        "name_es": "Who will win the [setNr!] set (1-x-2)?",
        "name_hr": "Tko će osvojiti [setNr!] set (1-x-2)?",
        "name_fr": "Qui gagnera le [setNr!] set (1-x-2) ?"
    },
    "id_2340^live_match^8": {
        "name_en": "Awayteam Penalty Shootout Miss/Score?",
        "name_aa": "Awayteam Penalty Shootout Miss/Score?",
        "name_de": "Auswärtsmannschaft Elfmeter Shootout Miss/erzielt?",
        "name_nl": "Uitteam Strafschoppen Shootout Miss/Scoren?",
        "name_pl": "Awayteam Penalty Shootout Miss/Score?",
        "name_ro": "Awayteam Penalty Shootout Miss/Score?",
        "name_ru": "Awayteam Penalty Shootout Miss/Score?",
        "name_tr": "Awayteam Penalty Shootout Miss/Score?",
        "name_se": "Awayteam Penalty Shootout Miss/Score?",
        "name_el": "Awayteam Penalty Shootout Miss/Score?",
        "name_es": "Awayteam Penalty Shootout Miss/Score?",
        "name_hr": "Tim 2 Penali postize/nepostize ?",
        "name_fr": "Awayteam Penalty Shootout Miss/Score?"
    },
    "id_2306^live_match^8": {
        "name_en": "Set Tie Break Lead after 6 Points",
        "name_aa": "Set Tie Break Lead after 6 Points",
        "name_de": "Set Tie Break Lead after 6 Points",
        "name_nl": "Set Tie Break Lead after 6 Points",
        "name_pl": "Set Tie Break Lead after 6 Points",
        "name_ro": "Set Tie Break Lead after 6 Points",
        "name_ru": "Set Tie Break Lead after 6 Points",
        "name_tr": "Set Tie Break Lead after 6 Points",
        "name_se": "Set Tie Break Lead after 6 Points",
        "name_el": "Set Tie Break Lead after 6 Points",
        "name_es": "Set Tie Break Lead after 6 Points",
        "name_hr": "Set Tie Break vodi poslije 6 Poena",
        "name_fr": "Set Tie Break Lead after 6 Points"
    },
    "id_46^live_match^7": {
        "name_en": "Draw no Bet for second period",
        "name_aa": "Draw no Bet for second period",
        "name_de": "Sieger - Kein Unentschieden 2. Abschnitt",
        "name_nl": "Draw no Bet voor tweede periode",
        "name_pl": "Draw no Bet for second period",
        "name_ro": "Draw no Bet for second period",
        "name_ru": "Draw no Bet for second period",
        "name_tr": "Beraberlikte iade ikinci periyot",
        "name_se": "Draw no Bet for second period",
        "name_el": "Draw no Bet for second period",
        "name_es": "Draw no Bet for second period",
        "name_hr": "Ishod bez neodlučenog za drugi period",
        "name_fr": "Remboursé si Match nul pour la deuxième période"
    },
    "id_1669^live_match^8": {
        "name_en": "home team clean sheet",
        "name_aa": "home team clean sheet",
        "name_de": "heimmannschaft kein gegentor",
        "name_nl": "Thuisteam de nul houden",
        "name_pl": "home team clean sheet",
        "name_ro": "home team clean sheet",
        "name_ru": "home team clean sheet",
        "name_tr": "home team clean sheet",
        "name_se": "home team clean sheet",
        "name_el": "home team clean sheet",
        "name_es": "home team clean sheet",
        "name_hr": "Tim 1 clean sheet",
        "name_fr": "home team clean sheet"
    },
    "id_2043^live_match^8": {
        "name_en": "Match Shots on Target",
        "name_aa": "Match Shots on Target",
        "name_de": "Spiel - Schüsse auf das Tor",
        "name_nl": "Wedstrijd schoten op doel",
        "name_pl": "Match Shots on Target",
        "name_ro": "Match Shots on Target",
        "name_ru": "Match Shots on Target",
        "name_tr": "Match Shots on Target",
        "name_se": "Match Shots on Target",
        "name_el": "Match Shots on Target",
        "name_es": "Match Shots on Target",
        "name_hr": "Utakmica udarci u okvir gola",
        "name_fr": "Match Shots on Target"
    },
    "id_1032^live_match^8": {
        "name_en": "Exact number of goals",
        "name_aa": "Exact number of goals",
        "name_de": "Genaue Anzahl Tore",
        "name_nl": "Exact aantal doelpunten",
        "name_pl": "Exact number of goals",
        "name_ro": "Exact number of goals",
        "name_ru": "Exact number of goals",
        "name_tr": "Kesin gol sayısı",
        "name_se": "Exact number of goals",
        "name_el": "Exact number of goals",
        "name_es": "Exact number of goals",
        "name_hr": "Točan broj golova",
        "name_fr": "Nombre exact de buts"
    },
    "id_2238^live_match^7": {
        "name_en": "5 Innings Money Line",
        "name_aa": "5 Innings Money Line",
        "name_de": "5 Innings sieg",
        "name_nl": "5 Innings Money Line",
        "name_pl": "5 Innings Money Line",
        "name_ro": "5 Innings Money Line",
        "name_ru": "5 Innings Money Line",
        "name_tr": "5 Innings Money Line",
        "name_se": "5 Innings Money Line",
        "name_el": "5 Innings Money Line",
        "name_es": "5 Innings Money Line",
        "name_hr": "5 Inningom Money Line",
        "name_fr": "5 Innings Money Line"
    },
    "id_974^live_match^8": {
        "name_en": "Odd/Even",
        "name_aa": "Odd/Even",
        "name_de": "Gerade/Ungerade",
        "name_nl": "Even/oneven",
        "name_pl": "Odd/Even",
        "name_ro": "Odd/Even",
        "name_ru": "Odd/Even",
        "name_tr": "Tek/Çift",
        "name_se": "Odd/Even",
        "name_el": "Odd/Even",
        "name_es": "Odd/Even",
        "name_hr": "Nepar/Par u regularnom vremenu",
        "name_fr": "Pair/Impair"
    },
    "id_87^live_match^8": {
        "name_en": "Total number of games in set 4",
        "name_aa": "Total number of games in set 4",
        "name_de": "Anzahl an Spielen im 4. Satz",
        "name_nl": "Totaal aantal games in set 4",
        "name_pl": "Total number of games in set 4",
        "name_ro": "Total number of games in set 4",
        "name_ru": "Total number of games in set 4",
        "name_tr": "4. Setteki toplam oyun sayısı",
        "name_se": "Total number of games in set 4",
        "name_el": "Total number of games in set 4",
        "name_es": "Total number of games in set 4",
        "name_hr": "Ukupan broj gemova u 4. setu",
        "name_fr": "Nombre total de jeux dans le 4e set"
    },
    "id_880^live_match^8": {
        "name_en": "Total awayteam including overtime",
        "name_aa": "Total awayteam including overtime",
        "name_de": "Total Auswärtsmannschaft inkl. Verlängerung",
        "name_nl": "Aantal doelpunten inclusief extra tijd",
        "name_pl": "Total awayteam including overtime",
        "name_ro": "Total awayteam including overtime",
        "name_ru": "Total awayteam including overtime",
        "name_tr": "Uzatmalar dahil deplasman takımı toplamı",
        "name_se": "Total awayteam including overtime",
        "name_el": "Total awayteam including overtime",
        "name_es": "Total awayteam including overtime",
        "name_hr": "Total gosti uključujući produžetke",
        "name_fr": "Total équipe visiteuse prolongations incluses"
    },
    "id_2006^live_match^7": {
        "name_en": "To Win the Trophy",
        "name_aa": "To Win the Trophy",
        "name_de": "zu gewinnen the Trophy",
        "name_nl": "Wint the Trophy",
        "name_pl": "To Win the Trophy",
        "name_ro": "To Win the Trophy",
        "name_ru": "To Win the Trophy",
        "name_tr": "To Win the Trophy",
        "name_se": "To Win the Trophy",
        "name_el": "To Win the Trophy",
        "name_es": "To Win the Trophy",
        "name_hr": "Da osvoji naslov",
        "name_fr": "To Win the Trophy"
    },
    "id_295^live_match^8": {
        "name_en": "2nd Half - Total",
        "name_aa": "2nd Half - Total",
        "name_de": "2. Halbzeit - Total",
        "name_nl": "2de Helft - Totaal",
        "name_pl": "2nd Half - Total",
        "name_ro": "2nd Half - Total",
        "name_ru": "2nd Half - Total",
        "name_tr": "İkinci yarı - Toplam",
        "name_se": "2nd Half - Total",
        "name_el": "2nd Half - Total",
        "name_es": "2nd Half - Total",
        "name_hr": "2. Poluvrijeme - Total",
        "name_fr": "2e Mi-temps - Total"
    },
    "id_2152^live_match^8": {
        "name_en": "Race to",
        "name_aa": "Race to",
        "name_de": "Zuerst",
        "name_nl": "Race naar",
        "name_pl": "Race to",
        "name_ro": "Race to",
        "name_ru": "Race to",
        "name_tr": "Race to",
        "name_se": "Race to",
        "name_el": "Race to",
        "name_es": "Race to",
        "name_hr": "Trka do",
        "name_fr": "Race to"
    },
    "id_74^live_match^7": {
        "name_en": "Which team wins race to X points, fourth set?",
        "name_aa": "Which team wins race to X points, fourth set?",
        "name_de": "Wer erreicht zuerst x Punkte im 4. Satz?",
        "name_nl": "Welk team wint de race tot X punten, vierde set ?",
        "name_pl": "Which team wins race to X points, fourth set?",
        "name_ro": "Which team wins race to X points, fourth set?",
        "name_ru": "Which team wins race to X points, fourth set?",
        "name_tr": "Dördüncü sette X sayıya hangi takım önce ulaşır?",
        "name_se": "Which team wins race to X points, fourth set?",
        "name_el": "Which team wins race to X points, fourth set?",
        "name_es": "Which team wins race to X points, fourth set?",
        "name_hr": "Koji tim će prvi doći do X poena, četvrti set?",
        "name_fr": "Quelle équipe atteindra en premier X points, quatrième set ?"
    },
    "id_2137^live_match^8": {
        "name_en": "2nd Half Odd/Even",
        "name_aa": "2nd Half Odd/Even",
        "name_de": "2. Halfen Ungerada/gerade",
        "name_nl": "2e Helft even/oneven",
        "name_pl": "2nd Half Odd/Even",
        "name_ro": "2nd Half Odd/Even",
        "name_ru": "2nd Half Odd/Even",
        "name_tr": "2nd Half Odd/Even",
        "name_se": "2nd Half Odd/Even",
        "name_el": "2nd Half Odd/Even",
        "name_es": "2nd Half Odd/Even",
        "name_hr": "2 Poluvrijeme nepar/par",
        "name_fr": "2nd Half Odd/Even"
    },
    "id_47^live_match^8": {
        "name_en": "Total for second period",
        "name_aa": "Total for second period",
        "name_de": "Anzahl Punkte 2. Abschnitt",
        "name_nl": "Totaal voor tweede periode",
        "name_pl": "Total for second period",
        "name_ro": "Total for second period",
        "name_ru": "Total for second period",
        "name_tr": "İkinci periyodun toplamı",
        "name_se": "Total for second period",
        "name_el": "Total for second period",
        "name_es": "Total for second period",
        "name_hr": "Total za drugi period",
        "name_fr": "Total pour la seconde période"
    },
    "id_2347^live_match^8": {
        "name_en": "Next Minute Goal [from]-[to]",
        "name_aa": "Next Minute Goal [from]-[to]",
        "name_de": "nächste Minute Tor [from]-[to]",
        "name_nl": "Volgende Minute doelpunt [from]-[to]",
        "name_pl": "Next Minute Goal [from]-[to]",
        "name_ro": "Next Minute Goal [from]-[to]",
        "name_ru": "Next Minute Goal [from]-[to]",
        "name_tr": "Next Minute Goal [from]-[to]",
        "name_se": "Next Minute Goal [from]-[to]",
        "name_el": "Next Minute Goal [from]-[to]",
        "name_es": "Next Minute Goal [from]-[to]",
        "name_hr": "Minuti sljedeceg gola [from]-[to]",
        "name_fr": "Next Minute Goal [from]-[to]"
    },
    "id_2036^live_match^8": {
        "name_en": "Total [total] Goal Kicks in 10 minutes interval [from]-[to]",
        "name_aa": "Total [total] Goal Kicks in 10 minutes interval [from]-[to]",
        "name_de": "Total [total] Goal Kicks in 10 minutes interval [from]-[to]",
        "name_nl": "Total [total] Goal Kicks in 10 minutes interval [from]-[to]",
        "name_pl": "Total [total] Goal Kicks in 10 minutes interval [from]-[to]",
        "name_ro": "Total [total] Goal Kicks in 10 minutes interval [from]-[to]",
        "name_ru": "Total [total] Goal Kicks in 10 minutes interval [from]-[to]",
        "name_tr": "Total [total] Goal Kicks in 10 minutes interval [from]-[to]",
        "name_se": "Total [total] Goal Kicks in 10 minutes interval [from]-[to]",
        "name_el": "Total [total] Goal Kicks in 10 minutes interval [from]-[to]",
        "name_es": "Total [total] Goal Kicks in 10 minutes interval [from]-[to]",
        "name_hr": "Kraj [total] Udarac sa gola u 10 min. intervalu [from]-[to]",
        "name_fr": "Total [total] Goal Kicks in 10 minutes interval [from]-[to]"
    },
    "id_922^live_match^7": {
        "name_en": "Match Game Handicap",
        "name_aa": "Match Game Handicap",
        "name_de": "spiel spiel Handicap",
        "name_nl": "Wedstrijd Game Handicap",
        "name_pl": "Match Game Handicap",
        "name_ro": "Match Game Handicap",
        "name_ru": "Match Game Handicap",
        "name_tr": "Maç oyunu handikapı",
        "name_se": "Match Game Handicap",
        "name_el": "Match Game Handicap",
        "name_es": "Match Game Handicap",
        "name_hr": "Gem Hendikep",
        "name_fr": "Handicap Jeu du match"
    },
    "id_39^live_match^8": {
        "name_en": "Total for whole match, including overtime",
        "name_aa": "Total for whole match, including overtime",
        "name_de": "Anzahl an Punkten inkl. Verlängerung",
        "name_nl": "Totaal voor volledige match, inclusief extra tijd",
        "name_pl": "Total for whole match, including overtime",
        "name_ro": "Total for whole match, including overtime",
        "name_ru": "Total for whole match, including overtime",
        "name_tr": "Uzatmalar dahil tüm maçın toplamı",
        "name_se": "Total for whole match, including overtime",
        "name_el": "Total for whole match, including overtime",
        "name_es": "Total for whole match, including overtime",
        "name_hr": "Total za meč, uključujući produžetke",
        "name_fr": "Total pour le match entier, incluant les prolongations"
    },
    "id_2304^live_match^7": {
        "name_en": "Match Tie-Break Winner",
        "name_aa": "Match Tie-Break Winner",
        "name_de": "spiel Tie-Break Winner",
        "name_nl": "Wedstrijd Tie-Break Winnaar",
        "name_pl": "Match Tie-Break Winner",
        "name_ro": "Match Tie-Break Winner",
        "name_ru": "Match Tie-Break Winner",
        "name_tr": "Match Tie-Break Winner",
        "name_se": "Match Tie-Break Winner",
        "name_el": "Match Tie-Break Winner",
        "name_es": "Match Tie-Break Winner",
        "name_hr": "Utakmica Tie-Break Pobjednik",
        "name_fr": "Match Tie-Break Winner"
    },
    "id_106^live_match^7": {
        "name_en": "Which team will win the match, including overtime and penalties?",
        "name_aa": "Which team will win the match, including overtime and penalties?",
        "name_de": "Wer team (inkl. VL und Elfmeterschießen)?",
        "name_nl": "Welk team zal de match -inclusief extra tijd en penalties- winnen?",
        "name_pl": "Which team will win the match, including overtime and penalties?",
        "name_ro": "Which team will win the match, including overtime and penalties?",
        "name_ru": "Which team will win the match, including overtime and penalties?",
        "name_tr": "Uzatmalar ve penaltılar dahil maçı kim kazanır?",
        "name_se": "Which team will win the match, including overtime and penalties?",
        "name_el": "Which team will win the match, including overtime and penalties?",
        "name_es": "Which team will win the match, including overtime and penalties?",
        "name_hr": "Koji tim će pobijediti, uključujući produžetak i penale?",
        "name_fr": "Quelle équipe gagnera le match, prolongations et tirs au but inclus ?"
    },
    "id_958^live_match^6": {
        "name_en": "3way for third period",
        "name_aa": "3way for third period",
        "name_de": "3Weg für dritten Abschnitt",
        "name_nl": "3way voor derde period",
        "name_pl": "3way for third period",
        "name_ro": "3way for third period",
        "name_ru": "3way for third period",
        "name_tr": "üçüncü periyot için 3 yol",
        "name_se": "3way for third period",
        "name_el": "3way for third period",
        "name_es": "3way for third period",
        "name_hr": "1X2 za treći period",
        "name_fr": "1x2 pour la troisième période"
    },
    "id_1484^live_match^8": {
        "name_en": "Total [total] bookings in 10 minutes interval [from]-[to]",
        "name_aa": "Total [total] bookings in 10 minutes interval [from]-[to]",
        "name_de": "Total [total] bookings in 10 minutes interval [from]-[to]",
        "name_nl": "Totaal [total] kaarten binnen de komende 10 minuten [from]-[to]",
        "name_pl": "Total [total] bookings in 10 minutes interval [from]-[to]",
        "name_ro": "Total [total] bookings in 10 minutes interval [from]-[to]",
        "name_ru": "Total [total] bookings in 10 minutes interval [from]-[to]",
        "name_tr": "[total] 10 dakikalık aralıkta toplam karı sayısı [from]-[to]",
        "name_se": "Total [total] bookings in 10 minutes interval [from]-[to]",
        "name_el": "Total [total] bookings in 10 minutes interval [from]-[to]",
        "name_es": "Total [total] bookings in 10 minutes interval [from]-[to]",
        "name_hr": "Kraj [total] kartoni u desetominutnom intervalu [from]-[to]",
        "name_fr": "Total [total] Cartons dans l'intervalle de 10 minutes [from]-[to]"
    },
    "id_2109^live_match^8": {
        "name_en": "Match Goals",
        "name_aa": "Match Goals",
        "name_de": "spiel Tore",
        "name_nl": "Wedstrijd doelpunts",
        "name_pl": "Match Goals",
        "name_ro": "Match Goals",
        "name_ru": "Match Goals",
        "name_tr": "Match Goals",
        "name_se": "Match Goals",
        "name_el": "Match Goals",
        "name_es": "Match Goals",
        "name_hr": "Utakmica Golova",
        "name_fr": "Match Goals"
    },
    "id_45^live_match^8": {
        "name_en": "Odd/Even for first period",
        "name_aa": "Odd/Even for first period",
        "name_de": "Gerade/Ungerade 1. Abschnitt",
        "name_nl": "Oneven/Even voor eerste periode",
        "name_pl": "Odd/Even for first period",
        "name_ro": "Odd/Even for first period",
        "name_ru": "Odd/Even for first period",
        "name_tr": "İlk periyot için Tek/Çift",
        "name_se": "Odd/Even for first period",
        "name_el": "Odd/Even for first period",
        "name_es": "Odd/Even for first period",
        "name_hr": "Nepar/Par za prvi period",
        "name_fr": "Pair/Impair pour la première période"
    },
    "id_1671^live_match^8": {
        "name_en": "home team to score in both halves",
        "name_aa": "home team to score in both halves",
        "name_de": "heimmannschaft zu spielstand in spielhälften",
        "name_nl": "Thuisteam Scoren in Beide halves",
        "name_pl": "home team to score in both halves",
        "name_ro": "home team to score in both halves",
        "name_ru": "home team to score in both halves",
        "name_tr": "home team to score in both halves",
        "name_se": "home team to score in both halves",
        "name_el": "home team to score in both halves",
        "name_es": "home team to score in both halves",
        "name_hr": "Tim 1 Postići gol u oba poluvremena",
        "name_fr": "home team to score in both halves"
    },
    "id_2130^live_match^8": {
        "name_en": "Correct Score After 3 Periods",
        "name_aa": "Correct Score After 3 Periods",
        "name_de": "genaues erzielt After 3 drittel s",
        "name_nl": "Correcte Score na 3 Periode",
        "name_pl": "Correct Score After 3 Periods",
        "name_ro": "Correct Score After 3 Periods",
        "name_ru": "Correct Score After 3 Periods",
        "name_tr": "Correct Score After 3 Periods",
        "name_se": "Correct Score After 3 Periods",
        "name_el": "Correct Score After 3 Periods",
        "name_es": "Correct Score After 3 Periods",
        "name_hr": "Tačan rezultat poslije 3. periodas",
        "name_fr": "Correct Score After 3 Periods"
    },
    "id_86^live_match^8": {
        "name_en": "Total number of games in set 3",
        "name_aa": "Total number of games in set 3",
        "name_de": "Anzahl an Spielen im 3. Satz",
        "name_nl": "Totaal aantal games in set 3",
        "name_pl": "Total number of games in set 3",
        "name_ro": "Total number of games in set 3",
        "name_ru": "Total number of games in set 3",
        "name_tr": "3. Setteki toplam oyun sayısı",
        "name_se": "Total number of games in set 3",
        "name_el": "Total number of games in set 3",
        "name_es": "Total number of games in set 3",
        "name_hr": "Ukupan broj gemova u 3. setu",
        "name_fr": "Nombre total de jeux dans le 3e set"
    },
    "id_2251^live_match^8": {
        "name_en": "Back to Back Hits in the Next Half Inning - Hometeam Inning",
        "name_aa": "Back to Back Hits in the Next Half Inning - Hometeam Inning",
        "name_de": "Back to Back Hits in the Next Half Inning - Hometeam Inning",
        "name_nl": "Back to Back Hits in the Next Half Inning - Hometeam Inning",
        "name_pl": "Back to Back Hits in the Next Half Inning - Hometeam Inning",
        "name_ro": "Back to Back Hits in the Next Half Inning - Hometeam Inning",
        "name_ru": "Back to Back Hits in the Next Half Inning - Hometeam Inning",
        "name_tr": "Back to Back Hits in the Next Half Inning - Hometeam Inning",
        "name_se": "Back to Back Hits in the Next Half Inning - Hometeam Inning",
        "name_el": "Back to Back Hits in the Next Half Inning - Hometeam Inning",
        "name_es": "Back to Back Hits in the Next Half Inning - Hometeam Inning",
        "name_hr": "Back to Back Hits in the Next Half Inning - Hometeam Inning",
        "name_fr": "Back to Back Hits in the Next Half Inning - Hometeam Inning"
    },
    "id_2064^live_match^6": {
        "name_en": "Winning Margin 3-Way",
        "name_aa": "Winning Margin 3-Way",
        "name_de": "Siegesvorsprung 3-Way",
        "name_nl": "Winstmarge (3 opties)",
        "name_pl": "Winning Margin 3-Way",
        "name_ro": "Winning Margin 3-Way",
        "name_ru": "Winning Margin 3-Way",
        "name_tr": "Winning Margin 3-Way",
        "name_se": "Winning Margin 3-Way",
        "name_el": "Winning Margin 3-Way",
        "name_es": "Winning Margin 3-Way",
        "name_hr": "Pobjeda Razlikom 3-Way",
        "name_fr": "Winning Margin 3-Way"
    },
    "id_2020^live_match^8": {
        "name_en": "1st Half Race to points",
        "name_aa": "1st Half Race to points",
        "name_de": "1. Spielhälfte - Zuerst X (Punkte)",
        "name_nl": "1e Helft Race naar punten",
        "name_pl": "1st Half Race to points",
        "name_ro": "1st Half Race to points",
        "name_ru": "1st Half Race to points",
        "name_tr": "1st Half Race to points",
        "name_se": "1st Half Race to points",
        "name_el": "1st Half Race to points",
        "name_es": "1st Half Race to points",
        "name_hr": "1.  Poluvrijeme trka do poena",
        "name_fr": "1st Half Race to points"
    },
    "id_72^live_match^7": {
        "name_en": "Which team wins race to X points, second set?",
        "name_aa": "Which team wins race to X points, second set?",
        "name_de": "Wer erreicht zuerst x Punkte im 2. Satz?",
        "name_nl": "Welk team wint de race tot X punten, tweede set?",
        "name_pl": "Which team wins race to X points, second set?",
        "name_ro": "Which team wins race to X points, second set?",
        "name_ru": "Which team wins race to X points, second set?",
        "name_tr": "İkinci sette X sayıya hangi takım önce ulaşır?",
        "name_se": "Which team wins race to X points, second set?",
        "name_el": "Which team wins race to X points, second set?",
        "name_es": "Which team wins race to X points, second set?",
        "name_hr": "Koji tim će prvi doći do X poena, drugi set?",
        "name_fr": "Quelle équipe atteindra en premier X points, deuxième set ?"
    },
    "id_2218^live_match^8": {
        "name_en": "Final Result (in sets - best of 3)",
        "name_aa": "Final Result (in sets - best of 3)",
        "name_de": "Final Result (in sets - best of 3)",
        "name_nl": "Definitief resultaat (In aantal set - best of 3)",
        "name_pl": "Final Result (in sets - best of 3)",
        "name_ro": "Final Result (in sets - best of 3)",
        "name_ru": "Final Result (in sets - best of 3)",
        "name_tr": "Final Result (in sets - best of 3)",
        "name_se": "Final Result (in sets - best of 3)",
        "name_el": "Final Result (in sets - best of 3)",
        "name_es": "Final Result (in sets - best of 3)",
        "name_hr": "Final Rezultati (u setovima - best of 3)",
        "name_fr": "Final Result (in sets - best of 3)"
    },
    "id_2175^live_match^8": {
        "name_en": "Match Scoring Shots",
        "name_aa": "Match Scoring Shots",
        "name_de": "spiel Scoring Shots",
        "name_nl": "Wedstrijd Scorende schoten",
        "name_pl": "Match Scoring Shots",
        "name_ro": "Match Scoring Shots",
        "name_ru": "Match Scoring Shots",
        "name_tr": "Match Scoring Shots",
        "name_se": "Match Scoring Shots",
        "name_el": "Match Scoring Shots",
        "name_es": "Match Scoring Shots",
        "name_hr": "Utakmica odlucujuci udarci",
        "name_fr": "Match Scoring Shots"
    },
    "id_2338^live_match^8": {
        "name_en": "Next Set Piece interval [from]-[to]",
        "name_aa": "Next Set Piece interval [from]-[to]",
        "name_de": "Next Set Piece interval [from]-[to]",
        "name_nl": "Next Set Piece interval [from]-[to]",
        "name_pl": "Next Set Piece interval [from]-[to]",
        "name_ro": "Next Set Piece interval [from]-[to]",
        "name_ru": "Next Set Piece interval [from]-[to]",
        "name_tr": "Next Set Piece interval [from]-[to]",
        "name_se": "Next Set Piece interval [from]-[to]",
        "name_el": "Next Set Piece interval [from]-[to]",
        "name_es": "Next Set Piece interval [from]-[to]",
        "name_hr": "Sljedeći Set Piece interval [from]-[to]",
        "name_fr": "Next Set Piece interval [from]-[to]"
    },
    "id_10^live_match^7": {
        "name_en": "Which player will win the match?",
        "name_aa": "Which player will win the match?",
        "name_de": "Wer gewinnt das Spiel?",
        "name_nl": "Welke speler zal de wedstrijd winnen?",
        "name_pl": "Which player will win the match?",
        "name_ro": "Which player will win the match?",
        "name_ru": "Which player will win the match?",
        "name_tr": "Maçı hangi oyuncu kazanır?",
        "name_se": "Which player will win the match?",
        "name_el": "Which player will win the match?",
        "name_es": "Which player will win the match?",
        "name_hr": "Koji igrač će pobjediti  u meču?",
        "name_fr": "Quel joueur gagnera le match ?"
    },
    "id_2102^live_match^8": {
        "name_en": "Both Teams to Score at Least",
        "name_aa": "Both Teams to Score at Least",
        "name_de": "beide Teams zu spielstand at Least",
        "name_nl": "Beide teams Scoren at Least",
        "name_pl": "Both Teams to Score at Least",
        "name_ro": "Both Teams to Score at Least",
        "name_ru": "Both Teams to Score at Least",
        "name_tr": "Both Teams to Score at Least",
        "name_se": "Both Teams to Score at Least",
        "name_el": "Both Teams to Score at Least",
        "name_es": "Both Teams to Score at Least",
        "name_hr": "Oba tima daju gol",
        "name_fr": "Both Teams to Score at Least"
    },
    "id_1529^live_match^8": {
        "name_en": "First Half - Matchbet and Totals [total]",
        "name_aa": "First Half - Matchbet and Totals [total]",
        "name_de": "1. Halfen - spiel betUnd Gesamthls [total]",
        "name_nl": "Eerste Helft - Matchbet en totaals [total]",
        "name_pl": "First Half - Matchbet and Totals [total]",
        "name_ro": "First Half - Matchbet and Totals [total]",
        "name_ru": "First Half - Matchbet and Totals [total]",
        "name_tr": "İlk Yarı - Maç bahisi ve toplamlar [total]",
        "name_se": "First Half - Matchbet and Totals [total]",
        "name_el": "First Half - Matchbet and Totals [total]",
        "name_es": "First Half - Matchbet and Totals [total]",
        "name_hr": "1. Poluvrijeme - Oklada na ishod i Kraj [total]",
        "name_fr": "Première Mi-temps - Pari sur match et Totaux [total]"
    },
    "id_2253^live_match^8": {
        "name_en": "A run scored - Hometeam Inning",
        "name_aa": "A run scored - Hometeam Inning",
        "name_de": "A run scored - Hometeam Inning",
        "name_nl": "A run scored - Hometeam Inning",
        "name_pl": "A run scored - Hometeam Inning",
        "name_ro": "A run scored - Hometeam Inning",
        "name_ru": "A run scored - Hometeam Inning",
        "name_tr": "A run scored - Hometeam Inning",
        "name_se": "A run scored - Hometeam Inning",
        "name_el": "A run scored - Hometeam Inning",
        "name_es": "A run scored - Hometeam Inning",
        "name_hr": "A run scored - Tim 1Inningom",
        "name_fr": "A run scored - Hometeam Inning"
    },
    "id_204^live_match^8": {
        "name_en": "Total for seventh period",
        "name_aa": "Total for seventh period",
        "name_de": "Total für 7. Abschnitt",
        "name_nl": "Totaal voor zevende periode",
        "name_pl": "Total for seventh period",
        "name_ro": "Total for seventh period",
        "name_ru": "Total for seventh period",
        "name_tr": "Yedinci periyodun toplamı",
        "name_se": "Total for seventh period",
        "name_el": "Total for seventh period",
        "name_es": "Total for seventh period",
        "name_hr": "Total za sedmi period",
        "name_fr": "Total pour la septième période"
    },
    "id_1044^live_match^8": {
        "name_en": "Double chance for [periodNr!] period",
        "name_aa": "Double chance for [periodNr!] period",
        "name_de": "Doppelte Chance für den [periodNr!] Abschnitt",
        "name_nl": "Doucle Chance voor [periodNr!] periode",
        "name_pl": "Double chance for [periodNr!] period",
        "name_ro": "Double chance for [periodNr!] period",
        "name_ru": "Double chance for [periodNr!] period",
        "name_tr": "[periodNr!] Periyodu için doğru skor",
        "name_se": "Double chance for [periodNr!] period",
        "name_el": "Double chance for [periodNr!] period",
        "name_es": "Double chance for [periodNr!] period",
        "name_hr": "Dvostruka prilika za [periodNr!] period",
        "name_fr": "Double chance pour la [periodNr!] période"
    },
    "id_2111^live_match^8": {
        "name_en": "Winning Margin",
        "name_aa": "Winning Margin",
        "name_de": "Siegesvorsprung",
        "name_nl": "Winstmarge",
        "name_pl": "Winning Margin",
        "name_ro": "Winning Margin",
        "name_ru": "Winning Margin",
        "name_tr": "Winning Margin",
        "name_se": "Winning Margin",
        "name_el": "Winning Margin",
        "name_es": "Winning Margin",
        "name_hr": "Pobjeda sa razlikom",
        "name_fr": "Winning Margin"
    },
    "id_1244^live_match^7": {
        "name_en": "Race to [frames] frames",
        "name_aa": "Race to [frames] frames",
        "name_de": "Wer erreicht zuerst [frames] Frames?",
        "name_nl": "Race naar [frames] frames",
        "name_pl": "Race to [frames] frames",
        "name_ro": "Race to [frames] frames",
        "name_ru": "Race to [frames] frames",
        "name_tr": "[frames] Frame'e yarış",
        "name_se": "Race to [frames] frames",
        "name_el": "Race to [frames] frames",
        "name_es": "Race to [frames] frames",
        "name_hr": "Utrka do [frames] frameova",
        "name_fr": "Atteindre en premier [frames] manches"
    },
    "id_2088^live_match^8": {
        "name_en": "Set Score After 6 Games",
        "name_aa": "Set Score After 6 Games",
        "name_de": "satz erzielt After 6 spiel s",
        "name_nl": "Set Score na 6 Games",
        "name_pl": "Set Score After 6 Games",
        "name_ro": "Set Score After 6 Games",
        "name_ru": "Set Score After 6 Games",
        "name_tr": "Set Score After 6 Games",
        "name_se": "Set Score After 6 Games",
        "name_el": "Set Score After 6 Games",
        "name_es": "Set Score After 6 Games",
        "name_hr": "rezultat setova poslije 6 gema",
        "name_fr": "Set Score After 6 Games"
    },
    "id_2084^live_match^7": {
        "name_en": "1st Point Winner Game",
        "name_aa": "1st Point Winner Game",
        "name_de": "1. punkte Sieger spiel",
        "name_nl": "1e Point Winnaar Game",
        "name_pl": "1st Point Winner Game",
        "name_ro": "1st Point Winner Game",
        "name_ru": "1st Point Winner Game",
        "name_tr": "1st Point Winner Game",
        "name_se": "1st Point Winner Game",
        "name_el": "1st Point Winner Game",
        "name_es": "1st Point Winner Game",
        "name_hr": "Pobjednik 1. gema",
        "name_fr": "1st Point Winner Game"
    },
    "id_183^live_match^8": {
        "name_en": "Matchbet and Totals",
        "name_aa": "Matchbet and Totals",
        "name_de": "3 Weg und Totals",
        "name_nl": "Matchbet en Totals",
        "name_pl": "Matchbet and Totals",
        "name_ro": "Matchbet and Totals",
        "name_ru": "Matchbet and Totals",
        "name_tr": "Maç Bahisi ve Toplamlar",
        "name_se": "Matchbet and Totals",
        "name_el": "Matchbet and Totals",
        "name_es": "Matchbet and Totals",
        "name_hr": "Oklada na ishod i totale",
        "name_fr": "Pari sur match et Totaux"
    },
    "id_2157^live_match^8": {
        "name_en": "Game Correct Score",
        "name_aa": "Game Correct Score",
        "name_de": "Genaues Satzergebnis",
        "name_nl": "Game Correcte Scoren",
        "name_pl": "Game Correct Score",
        "name_ro": "Game Correct Score",
        "name_ru": "Game Correct Score",
        "name_tr": "Game Correct Score",
        "name_se": "Game Correct Score",
        "name_el": "Game Correct Score",
        "name_es": "Game Correct Score",
        "name_hr": "IgraTačan rezultat",
        "name_fr": "Game Correct Score"
    },
    "id_2234^live_match^8": {
        "name_en": "test1",
        "name_aa": "test1",
        "name_de": "test1",
        "name_nl": "test1",
        "name_pl": "test1",
        "name_ro": "test1",
        "name_ru": "test1",
        "name_tr": "test1",
        "name_se": "test1",
        "name_el": "test1",
        "name_es": "test1",
        "name_hr": "test1",
        "name_fr": "test1"
    },
    "id_2220^live_match^8": {
        "name_en": "Score After 3 Sets",
        "name_aa": "Score After 3 Sets",
        "name_de": "erzielt After 3 satzs",
        "name_nl": "Score na 3 Sets",
        "name_pl": "Score After 3 Sets",
        "name_ro": "Score After 3 Sets",
        "name_ru": "Score After 3 Sets",
        "name_tr": "Score After 3 Sets",
        "name_se": "Score After 3 Sets",
        "name_el": "Score After 3 Sets",
        "name_es": "Score After 3 Sets",
        "name_hr": "Rezultat poslije 3 seta",
        "name_fr": "Score After 3 Sets"
    },
    "id_2054^live_match^8": {
        "name_en": "1st Quarter Double Chance",
        "name_aa": "1st Quarter Double Chance",
        "name_de": "1. Viertel Doppelte chance",
        "name_nl": "1e kwart Dubbele Chance",
        "name_pl": "1st Quarter Double Chance",
        "name_ro": "1st Quarter Double Chance",
        "name_ru": "1st Quarter Double Chance",
        "name_tr": "1st Quarter Double Chance",
        "name_se": "1st Quarter Double Chance",
        "name_el": "1st Quarter Double Chance",
        "name_es": "1st Quarter Double Chance",
        "name_hr": "1 Cetvrtina Doupla šansa",
        "name_fr": "1st Quarter Double Chance"
    },
    "id_928^live_match^8": {
        "name_en": "Total [total] for [setNr!] set",
        "name_aa": "Total [total] for [setNr!] set",
        "name_de": "Total [total] für den [setNr!] Satz",
        "name_nl": "Totaal [total] voor [setNr!] set",
        "name_pl": "Total [total] for [setNr!] set",
        "name_ro": "Total [total] for [setNr!] set",
        "name_ru": "Total [total] for [setNr!] set",
        "name_tr": "[setNr!] numaralı setteki toplam [total]",
        "name_se": "Total [total] for [setNr!] set",
        "name_el": "Total [total] for [setNr!] set",
        "name_es": "Total [total] for [setNr!] set",
        "name_hr": "Total [total] za [setNr!]. set",
        "name_fr": "Total [total] pour le [setNr!] set"
    },
    "id_2241^live_match^8": {
        "name_en": "7 Innings Total Runs",
        "name_aa": "7 Innings Total Runs",
        "name_de": "7 Innings Gesamthl Runs",
        "name_nl": "7 Innings Totaal Runs",
        "name_pl": "7 Innings Total Runs",
        "name_ro": "7 Innings Total Runs",
        "name_ru": "7 Innings Total Runs",
        "name_tr": "7 Innings Total Runs",
        "name_se": "7 Innings Total Runs",
        "name_el": "7 Innings Total Runs",
        "name_es": "7 Innings Total Runs",
        "name_hr": "7 Inningom Kraj Runs",
        "name_fr": "7 Innings Total Runs"
    },
    "id_2190^live_match^8": {
        "name_en": "2nd Half - Total Goals awayteam",
        "name_aa": "2nd Half - Total Goals awayteam",
        "name_de": "2. Halfen - Gesamthl Tore Auswärtsmannschaft",
        "name_nl": "2e Helft - Totaal doelpunts Uitteam",
        "name_pl": "2nd Half - Total Goals awayteam",
        "name_ro": "2nd Half - Total Goals awayteam",
        "name_ru": "2nd Half - Total Goals awayteam",
        "name_tr": "2nd Half - Total Goals awayteam",
        "name_se": "2nd Half - Total Goals awayteam",
        "name_el": "2nd Half - Total Goals awayteam",
        "name_es": "2nd Half - Total Goals awayteam",
        "name_hr": "2nd Poluvrijeme - Kraj Golova Tim 2",
        "name_fr": "2nd Half - Total Goals awayteam"
    },
    "id_2100^live_match^8": {
        "name_en": "Hometeam To Score Next Penalty",
        "name_aa": "Hometeam To Score Next Penalty",
        "name_de": "heimmannschaft zu spielstand nächste Elfmeter",
        "name_nl": "Thuisteam Scoren Volgende Strafschoppen",
        "name_pl": "Hometeam To Score Next Penalty",
        "name_ro": "Hometeam To Score Next Penalty",
        "name_ru": "Hometeam To Score Next Penalty",
        "name_tr": "Hometeam To Score Next Penalty",
        "name_se": "Hometeam To Score Next Penalty",
        "name_el": "Hometeam To Score Next Penalty",
        "name_es": "Hometeam To Score Next Penalty",
        "name_hr": "Tim 1 Postize Sljedeći Penalt",
        "name_fr": "Hometeam To Score Next Penalty"
    },
    "id_2302^live_match^8": {
        "name_en": "Most Double Faults",
        "name_aa": "Most Double Faults",
        "name_de": "Most Doppelte Faults",
        "name_nl": "Meeste Dubbele Faults",
        "name_pl": "Most Double Faults",
        "name_ro": "Most Double Faults",
        "name_ru": "Most Double Faults",
        "name_tr": "Most Double Faults",
        "name_se": "Most Double Faults",
        "name_el": "Most Double Faults",
        "name_es": "Most Double Faults",
        "name_hr": "Najvise duplih greski",
        "name_fr": "Most Double Faults"
    },
    "id_2162^live_match^8": {
        "name_en": "Double Result (1st game / match)",
        "name_aa": "Double Result (1st game / match)",
        "name_de": "Doppelte Ergebnis (1. spiel / spiel )",
        "name_nl": "Dubbele Resultaat (1e game / Wedstrijd )",
        "name_pl": "Double Result (1st game / match)",
        "name_ro": "Double Result (1st game / match)",
        "name_ru": "Double Result (1st game / match)",
        "name_tr": "Double Result (1st game / match)",
        "name_se": "Double Result (1st game / match)",
        "name_el": "Double Result (1st game / match)",
        "name_es": "Double Result (1st game / match)",
        "name_hr": "Doupla Rezultirati(1st Igra/ Utakmica)",
        "name_fr": "Double Result (1st game / match)"
    },
    "id_1230^live_match^8": {
        "name_en": "[frame!] frame - Highest break",
        "name_aa": "[frame!] frame - Highest break",
        "name_de": "[frame!] Frame - Höchster Break",
        "name_nl": "[frame!] frame - Hoogste break",
        "name_pl": "[frame!] frame - Highest break",
        "name_ro": "[frame!] frame - Highest break",
        "name_ru": "[frame!] frame - Highest break",
        "name_tr": "[frame!] Çerçeve - En yüksek kırma",
        "name_se": "[frame!] frame - Highest break",
        "name_el": "[frame!] frame - Highest break",
        "name_es": "[frame!] frame - Highest break",
        "name_hr": "[frame!] frameova - Najveći break",
        "name_fr": "[frame!] Manche - Plus grand break"
    },
    "id_103^live_match^7": {
        "name_en": "Which team will win the set?",
        "name_aa": "Which team will win the set?",
        "name_de": "Wer team den Satz?",
        "name_nl": "Welk team zal de set winnen?",
        "name_pl": "Which team will win the set?",
        "name_ro": "Which team will win the set?",
        "name_ru": "Which team will win the set?",
        "name_tr": "Seti hangi takım kazanır?",
        "name_se": "Which team will win the set?",
        "name_el": "Which team will win the set?",
        "name_es": "Which team will win the set?",
        "name_hr": "Koji tim će osvojiti set?",
        "name_fr": "Quelle équipe gagnera le set ?"
    },
    "id_2146^live_match^8": {
        "name_en": "1st Half TriBet",
        "name_aa": "1st Half TriBet",
        "name_de": "1. Halfen TriBet",
        "name_nl": "1e Helft TriBet",
        "name_pl": "1st Half TriBet",
        "name_ro": "1st Half TriBet",
        "name_ru": "1st Half TriBet",
        "name_tr": "1st Half TriBet",
        "name_se": "1st Half TriBet",
        "name_el": "1st Half TriBet",
        "name_es": "1st Half TriBet",
        "name_hr": "1. Poluvrijeme TriBet",
        "name_fr": "1st Half TriBet"
    },
    "id_1208^live_match^8": {
        "name_en": "[frame!] frame - Last points scored",
        "name_aa": "[frame!] frame - Last points scored",
        "name_de": "[frame!] Frame - Letzte Punkte",
        "name_nl": "[frame!] frame - Laatste punten gescoord",
        "name_pl": "[frame!] frame - Last points scored",
        "name_ro": "[frame!] frame - Last points scored",
        "name_ru": "[frame!] frame - Last points scored",
        "name_tr": "[frame!] Çerçeve - Son puanlar sayıldı",
        "name_se": "[frame!] frame - Last points scored",
        "name_el": "[frame!] frame - Last points scored",
        "name_es": "[frame!] frame - Last points scored",
        "name_hr": "[frame!] frameova - Posljednji postignut poen",
        "name_fr": "[frame!] Manche - Derniers points marqués"
    },
    "id_347^live_match^8": {
        "name_en": "Odd/Even for seventh period",
        "name_aa": "Odd/Even for seventh period",
        "name_de": "Gerade/Ungerade 7. Abschnitt",
        "name_nl": "Oneven/Even voor zevende periode",
        "name_pl": "Odd/Even for seventh period",
        "name_ro": "Odd/Even for seventh period",
        "name_ru": "Odd/Even for seventh period",
        "name_tr": "Yedinci periyod için Tek/Çift",
        "name_se": "Odd/Even for seventh period",
        "name_el": "Odd/Even for seventh period",
        "name_es": "Odd/Even for seventh period",
        "name_hr": "Nepar/Par za sedmi period",
        "name_fr": "Pair/Impair pour la septième période"
    },
    "id_2141^live_match^8": {
        "name_en": "2nd Half Race to",
        "name_aa": "2nd Half Race to",
        "name_de": "2. Spielhälfte - Zuerst X",
        "name_nl": "2e Helft Race naar",
        "name_pl": "2nd Half Race to",
        "name_ro": "2nd Half Race to",
        "name_ru": "2nd Half Race to",
        "name_tr": "2nd Half Race to",
        "name_se": "2nd Half Race to",
        "name_el": "2nd Half Race to",
        "name_es": "2nd Half Race to",
        "name_hr": "2. Poluvrijeme prvi stize do",
        "name_fr": "2nd Half Race to"
    },
    "id_1064^live_match^8": {
        "name_en": "Total away team [total] for [periodNr!] period",
        "name_aa": "Total away team [total] for [periodNr!] period",
        "name_de": "Gesamt Auswärtsmannschaft [total] im [periodNr!] Abschnitt",
        "name_nl": "Total uitploeg [total] voor [periodNr!] periode",
        "name_pl": "Total away team [total] for [periodNr!] period",
        "name_ro": "Total away team [total] for [periodNr!] period",
        "name_ru": "Total away team [total] for [periodNr!] period",
        "name_tr": "[periodNr!] numaralı periyod için deplasman takımı toplamı [total]",
        "name_se": "Total away team [total] for [periodNr!] period",
        "name_el": "Total away team [total] for [periodNr!] period",
        "name_es": "Total away team [total] for [periodNr!] period",
        "name_hr": "Total gostujući tim [total] za [periodNr!] period",
        "name_fr": "Total équipe visiteuse [total] pour la [periodNr!] période"
    },
    "id_2073^live_match^8": {
        "name_en": "Set 2 Correct Score",
        "name_aa": "Set 2 Correct Score",
        "name_de": "satz 2 genaues erzielt",
        "name_nl": "Set 2 Correcte Scoren",
        "name_pl": "Set 2 Correct Score",
        "name_ro": "Set 2 Correct Score",
        "name_ru": "Set 2 Correct Score",
        "name_tr": "Set 2 Correct Score",
        "name_se": "Set 2 Correct Score",
        "name_el": "Set 2 Correct Score",
        "name_es": "Set 2 Correct Score",
        "name_hr": "Set 2 Tačan rezultat",
        "name_fr": "Set 2 Correct Score"
    },
    "id_0^live_match^4": {
        "name_en": "Handicap",
        "name_aa": "Handicap",
        "name_de": "Handicap",
        "name_nl": "Handicap",
        "name_pl": "Handicap",
        "name_ro": "Handicap",
        "name_ru": "Handicap",
        "name_tr": "Handikap",
        "name_se": "Handicap",
        "name_el": "Handicap",
        "name_es": "Handicap",
        "name_hr": "Hendikep",
        "name_fr": "Handicap"
    },
    "id_2330^live_match^8": {
        "name_en": "Awayteam Penalties Converted in Shootout",
        "name_aa": "Awayteam Penalties Converted in Shootout",
        "name_de": "Awayteam Penalties Converted in Shootout",
        "name_nl": "Awayteam Penalties Converted in Shootout",
        "name_pl": "Awayteam Penalties Converted in Shootout",
        "name_ro": "Awayteam Penalties Converted in Shootout",
        "name_ru": "Awayteam Penalties Converted in Shootout",
        "name_tr": "Awayteam Penalties Converted in Shootout",
        "name_se": "Awayteam Penalties Converted in Shootout",
        "name_el": "Awayteam Penalties Converted in Shootout",
        "name_es": "Awayteam Penalties Converted in Shootout",
        "name_hr": "Tim 2 postignutih penala",
        "name_fr": "Awayteam Penalties Converted in Shootout"
    },
    "id_2074^live_match^8": {
        "name_en": "Set 3 Correct Score",
        "name_aa": "Set 3 Correct Score",
        "name_de": "satz 3 genaues erzielt",
        "name_nl": "Set 3 Correcte Scoren",
        "name_pl": "Set 3 Correct Score",
        "name_ro": "Set 3 Correct Score",
        "name_ru": "Set 3 Correct Score",
        "name_tr": "Set 3 Correct Score",
        "name_se": "Set 3 Correct Score",
        "name_el": "Set 3 Correct Score",
        "name_es": "Set 3 Correct Score",
        "name_hr": "Set 3 Tačan rezultat",
        "name_fr": "Set 3 Correct Score"
    },
    "id_2208^live_match^8": {
        "name_en": "Match Goals (Bands)",
        "name_aa": "Match Goals (Bands)",
        "name_de": "spiel Tore (Bands)",
        "name_nl": "Wedstrijd doelpunts (Bands)",
        "name_pl": "Match Goals (Bands)",
        "name_ro": "Match Goals (Bands)",
        "name_ru": "Match Goals (Bands)",
        "name_tr": "Match Goals (Bands)",
        "name_se": "Match Goals (Bands)",
        "name_el": "Match Goals (Bands)",
        "name_es": "Match Goals (Bands)",
        "name_hr": "Utakmica Golova (Bands)",
        "name_fr": "Match Goals (Bands)"
    },
    "id_359^live_match^7": {
        "name_en": "Who scores Xth point seventh set",
        "name_aa": "Who scores Xth point seventh set",
        "name_de": "Wer erzielt den x. Punkt im 7. Satz?",
        "name_nl": "Wie scoort het Xste punt zevende set",
        "name_pl": "Who scores Xth point seventh set",
        "name_ro": "Who scores Xth point seventh set",
        "name_ru": "Who scores Xth point seventh set",
        "name_tr": "Yedinci sette X. sayıyı kim kazanır?",
        "name_se": "Who scores Xth point seventh set",
        "name_el": "Who scores Xth point seventh set",
        "name_es": "Who scores Xth point seventh set",
        "name_hr": "Tko će osvojiti X-ti poen u sedmom setu",
        "name_fr": "Qui va marquer le xième point septième set"
    },
    "id_2206^live_match^8": {
        "name_en": "Quarter - Score 1st and Win",
        "name_aa": "Quarter - Score 1st and Win",
        "name_de": "Viertel - erzielt 1.Und Win",
        "name_nl": "kwart - Scoren 1e and Win",
        "name_pl": "Quarter - Score 1st and Win",
        "name_ro": "Quarter - Score 1st and Win",
        "name_ru": "Quarter - Score 1st and Win",
        "name_tr": "Quarter - Score 1st and Win",
        "name_se": "Quarter - Score 1st and Win",
        "name_el": "Quarter - Score 1st and Win",
        "name_es": "Quarter - Score 1st and Win",
        "name_hr": "Cetvrtina - prvi gol i pobjeda",
        "name_fr": "Quarter - Score 1st and Win"
    },
    "id_2059^live_match^8": {
        "name_en": "1st Half Winning Margin",
        "name_aa": "1st Half Winning Margin",
        "name_de": "1. Halfen Siegesvorsprung",
        "name_nl": "1e Helft Winstmarge",
        "name_pl": "1st Half Winning Margin",
        "name_ro": "1st Half Winning Margin",
        "name_ru": "1st Half Winning Margin",
        "name_tr": "1st Half Winning Margin",
        "name_se": "1st Half Winning Margin",
        "name_el": "1st Half Winning Margin",
        "name_es": "1st Half Winning Margin",
        "name_hr": "1. Poluvrijeme Pobjeda Razlikom",
        "name_fr": "1st Half Winning Margin"
    },
    "id_2185^live_match^8": {
        "name_en": "Quarter Total Goals",
        "name_aa": "Quarter Total Goals",
        "name_de": "Viertel Gesamthl Tore",
        "name_nl": "kwart Totaal doelpunts",
        "name_pl": "Quarter Total Goals",
        "name_ro": "Quarter Total Goals",
        "name_ru": "Quarter Total Goals",
        "name_tr": "Quarter Total Goals",
        "name_se": "Quarter Total Goals",
        "name_el": "Quarter Total Goals",
        "name_es": "Quarter Total Goals",
        "name_hr": "Cetvrtina Kraj Golova",
        "name_fr": "Quarter Total Goals"
    },
    "id_50^live_match^8": {
        "name_en": "Total for third period",
        "name_aa": "Total for third period",
        "name_de": "Anzahl Punkte 3. Abschnitt",
        "name_nl": "Totaal voor derde periode",
        "name_pl": "Total for third period",
        "name_ro": "Total for third period",
        "name_ru": "Total for third period",
        "name_tr": "Üçüncü periyodun toplamı",
        "name_se": "Total for third period",
        "name_el": "Total for third period",
        "name_es": "Total for third period",
        "name_hr": "Total za treći period",
        "name_fr": "Total pour la troisième période"
    },
    "id_89^live_match^7": {
        "name_en": "Who wins game X?",
        "name_aa": "Who wins game X?",
        "name_de": "Wer gewinnt das X. Spiel?",
        "name_nl": "Wie wint de X de game?",
        "name_pl": "Who wins game X?",
        "name_ro": "Who wins game X?",
        "name_ru": "Who wins game X?",
        "name_tr": "X oyununu kim kazanır?",
        "name_se": "Who wins game X?",
        "name_el": "Who wins game X?",
        "name_es": "Who wins game X?",
        "name_hr": "Tko osvaja X. gem?",
        "name_fr": "Qui gagnera le jeu X ?"
    },
    "id_31^live_match^8": {
        "name_en": "Odd/Even",
        "name_aa": "Odd/Even",
        "name_de": "Gerade/Ungerade",
        "name_nl": "Even/oneven",
        "name_pl": "Odd/Even",
        "name_ro": "Odd/Even",
        "name_ru": "Odd/Even",
        "name_tr": "Tek/Çift",
        "name_se": "Odd/Even",
        "name_el": "Odd/Even",
        "name_es": "Odd/Even",
        "name_hr": "Neparan/Paran",
        "name_fr": "Pair/Impair"
    },
    "id_102^live_match^7": {
        "name_en": "Which team will win the match?",
        "name_aa": "Which team will win the match?",
        "name_de": "Wer team das Spiel?",
        "name_nl": "Wie zal de match winnen?",
        "name_pl": "Which team will win the match?",
        "name_ro": "Which team will win the match?",
        "name_ru": "Which team will win the match?",
        "name_tr": "Maçı hangi takım kazanır?",
        "name_se": "Which team will win the match?",
        "name_el": "Which team will win the match?",
        "name_es": "Which team will win the match?",
        "name_hr": "Koji tim će pobijediti?",
        "name_fr": "Quelle équipe gagnera le match ?"
    },
    "id_2164^live_match^8": {
        "name_en": "Player 1 To Win 2 Sets",
        "name_aa": "Player 1 To Win 2 Sets",
        "name_de": "Player 1 zu gewinnen 2 satzs",
        "name_nl": "Speler 1 Wint 2 Sets",
        "name_pl": "Player 1 To Win 2 Sets",
        "name_ro": "Player 1 To Win 2 Sets",
        "name_ru": "Player 1 To Win 2 Sets",
        "name_tr": "Player 1 To Win 2 Sets",
        "name_se": "Player 1 To Win 2 Sets",
        "name_el": "Player 1 To Win 2 Sets",
        "name_es": "Player 1 To Win 2 Sets",
        "name_hr": "Igrač  1 dobija 2 Seta",
        "name_fr": "Player 1 To Win 2 Sets"
    },
    "id_2339^live_match^8": {
        "name_en": "Hometeam Penalty Shootout Miss/Score?",
        "name_aa": "Hometeam Penalty Shootout Miss/Score?",
        "name_de": "Hometeam Penalty Shootout Miss/Score?",
        "name_nl": "Hometeam Penalty Shootout Miss/Score?",
        "name_pl": "Hometeam Penalty Shootout Miss/Score?",
        "name_ro": "Hometeam Penalty Shootout Miss/Score?",
        "name_ru": "Hometeam Penalty Shootout Miss/Score?",
        "name_tr": "Hometeam Penalty Shootout Miss/Score?",
        "name_se": "Hometeam Penalty Shootout Miss/Score?",
        "name_el": "Hometeam Penalty Shootout Miss/Score?",
        "name_es": "Hometeam Penalty Shootout Miss/Score?",
        "name_hr": "Tim 1 Penali postize/nepostize ?",
        "name_fr": "Hometeam Penalty Shootout Miss/Score?"
    },
    "id_2348^live_match^8": {
        "name_en": "Time of goal",
        "name_aa": "Time of goal",
        "name_de": "Zeit des Tor",
        "name_nl": "Tijdstip van doelpunt",
        "name_pl": "Time of goal",
        "name_ro": "Time of goal",
        "name_ru": "Time of goal",
        "name_tr": "Time of goal",
        "name_se": "Time of goal",
        "name_el": "Time of goal",
        "name_es": "Time of goal",
        "name_hr": "vrijeme gola",
        "name_fr": "Time of goal"
    },
    "id_2042^live_match^8": {
        "name_en": "Match Offsides",
        "name_aa": "Match Offsides",
        "name_de": "Spiel - Abseits",
        "name_nl": "Wedstrijd Offsides",
        "name_pl": "Match Offsides",
        "name_ro": "Match Offsides",
        "name_ru": "Match Offsides",
        "name_tr": "Match Offsides",
        "name_se": "Match Offsides",
        "name_el": "Match Offsides",
        "name_es": "Match Offsides",
        "name_hr": "Utakmica Ofsajdi",
        "name_fr": "Match Offsides"
    },
    "id_1216^live_match^8": {
        "name_en": "[frame!] frame - Will there be a foul",
        "name_aa": "[frame!] frame - Will there be a foul",
        "name_de": "[frame!] Frame - Wird es ein Foul geben",
        "name_nl": "[frame!] frame - Zal er een fout zijn?",
        "name_pl": "[frame!] frame - Will there be a foul",
        "name_ro": "[frame!] frame - Will there be a foul",
        "name_ru": "[frame!] frame - Will there be a foul",
        "name_tr": "[frame!] Çerçeve - Bir faul olacak mı?",
        "name_se": "[frame!] frame - Will there be a foul",
        "name_el": "[frame!] frame - Will there be a foul",
        "name_es": "[frame!] frame - Will there be a foul",
        "name_hr": "[frame!] frameova - Hoće li biti faula?",
        "name_fr": "[frame!] Manche - Y aura-t-il une faute ?"
    },
    "id_878^live_match^8": {
        "name_en": "Total hometeam including overtime",
        "name_aa": "Total hometeam including overtime",
        "name_de": "Total Heimmannschaft inkl. Verlängerung",
        "name_nl": "Aantal doelpunten thuisploeg inclusief extra tijd",
        "name_pl": "Total hometeam including overtime",
        "name_ro": "Total hometeam including overtime",
        "name_ru": "Total hometeam including overtime",
        "name_tr": "Uzatmalar dahil ev sahibi toplamı",
        "name_se": "Total hometeam including overtime",
        "name_el": "Total hometeam including overtime",
        "name_es": "Total hometeam including overtime",
        "name_hr": "Total domaći uključujući produžetke",
        "name_fr": "Total équipe à domicile incluant les prolongations"
    },
    "id_2018^live_match^8": {
        "name_en": "2nd Half - Total hometeam",
        "name_aa": "2nd Half - Total hometeam",
        "name_de": "2. Halfen - Gesamthl heimmannschaft",
        "name_nl": "2e Helft - totaal Thuisteam",
        "name_pl": "2nd Half - Total hometeam",
        "name_ro": "2nd Half - Total hometeam",
        "name_ru": "2nd Half - Total hometeam",
        "name_tr": "2nd Half - Total hometeam",
        "name_se": "2nd Half - Total hometeam",
        "name_el": "2nd Half - Total hometeam",
        "name_es": "2nd Half - Total hometeam",
        "name_hr": "2. Poluvrijeme - Kraj Tim 1",
        "name_fr": "2nd Half - Total hometeam"
    },
    "id_2124^live_match^8": {
        "name_en": "Match Odd/Even",
        "name_aa": "Match Odd/Even",
        "name_de": "spiel Ungerada/gerade",
        "name_nl": "Wedstrijd even/oneven",
        "name_pl": "Match Odd/Even",
        "name_ro": "Match Odd/Even",
        "name_ru": "Match Odd/Even",
        "name_tr": "Match Odd/Even",
        "name_se": "Match Odd/Even",
        "name_el": "Match Odd/Even",
        "name_es": "Match Odd/Even",
        "name_hr": "Utakmica nepar/par",
        "name_fr": "Match Odd/Even"
    },
    "id_2026^live_match^8": {
        "name_en": "1st Half 3-Way Total",
        "name_aa": "1st Half 3-Way Total",
        "name_de": "1. Halfen 3-Way Gesamthl",
        "name_nl": "1e Helft 3 opties totaal",
        "name_pl": "1st Half 3-Way Total",
        "name_ro": "1st Half 3-Way Total",
        "name_ru": "1st Half 3-Way Total",
        "name_tr": "1st Half 3-Way Total",
        "name_se": "1st Half 3-Way Total",
        "name_el": "1st Half 3-Way Total",
        "name_es": "1st Half 3-Way Total",
        "name_hr": "1st Poluvrijeme 3-Way Kraj",
        "name_fr": "1st Half 3-Way Total"
    },
    "id_0^live_match^5": {
        "name_en": "Total",
        "name_aa": "Total",
        "name_de": "Gesamtzahl",
        "name_nl": "Totaal",
        "name_pl": "Total",
        "name_ro": "Total",
        "name_ru": "Total",
        "name_tr": "Toplam",
        "name_se": "Total",
        "name_el": "Total",
        "name_es": "Total",
        "name_hr": "Ukupno",
        "name_fr": "Total"
    },
    "id_2264^live_match^8": {
        "name_en": "Inning Total Hits",
        "name_aa": "Inning Total Hits",
        "name_de": "Inning Gesamthl Hits",
        "name_nl": "Inning Totaal Hits",
        "name_pl": "Inning Total Hits",
        "name_ro": "Inning Total Hits",
        "name_ru": "Inning Total Hits",
        "name_tr": "Inning Total Hits",
        "name_se": "Inning Total Hits",
        "name_el": "Inning Total Hits",
        "name_es": "Inning Total Hits",
        "name_hr": "Inningom  Kraj Hits",
        "name_fr": "Inning Total Hits"
    },
    "id_2148^live_match^8": {
        "name_en": "TriBet",
        "name_aa": "TriBet",
        "name_de": "TriBet",
        "name_nl": "TriBet",
        "name_pl": "TriBet",
        "name_ro": "TriBet",
        "name_ru": "TriBet",
        "name_tr": "TriBet",
        "name_se": "TriBet",
        "name_el": "TriBet",
        "name_es": "TriBet",
        "name_hr": "TriBet",
        "name_fr": "TriBet"
    },
    "id_2145^live_match^8": {
        "name_en": "Winning Margin",
        "name_aa": "Winning Margin",
        "name_de": "Siegesvorsprung",
        "name_nl": "Winstmarge",
        "name_pl": "Winning Margin",
        "name_ro": "Winning Margin",
        "name_ru": "Winning Margin",
        "name_tr": "Winning Margin",
        "name_se": "Winning Margin",
        "name_el": "Winning Margin",
        "name_es": "Winning Margin",
        "name_hr": "Pobjeda Razlikom",
        "name_fr": "Winning Margin"
    },
    "id_1382^live_match^8": {
        "name_en": "Total [total] in 10 minutes interval [from]-[to]",
        "name_aa": "Total [total] in 10 minutes interval [from]-[to]",
        "name_de": "Total [total] in 10 minutes interval [from]-[to]",
        "name_nl": "Total [total] in 10 minutes interval [from]-[to]",
        "name_pl": "Total [total] in 10 minutes interval [from]-[to]",
        "name_ro": "Total [total] in 10 minutes interval [from]-[to]",
        "name_ru": "Total [total] in 10 minutes interval [from]-[to]",
        "name_tr": "[total] 10 dakikalı aralıkta toplamlar [from]-[to]",
        "name_se": "Total [total] in 10 minutes interval [from]-[to]",
        "name_el": "Total [total] in 10 minutes interval [from]-[to]",
        "name_es": "Total [total] in 10 minutes interval [from]-[to]",
        "name_hr": "Kraj [total] u desetominutnom intervalu [from]-[to]",
        "name_fr": "Total [total] dans l'intervalle de 10 minutes [from]-[to]"
    },
    "id_2081^live_match^8": {
        "name_en": "Set Correct Score Any Player",
        "name_aa": "Set Correct Score Any Player",
        "name_de": "satz-genaues ergebnis- Beliebiger Spieler",
        "name_nl": "Set Correcte Scoren Any Speler",
        "name_pl": "Set Correct Score Any Player",
        "name_ro": "Set Correct Score Any Player",
        "name_ru": "Set Correct Score Any Player",
        "name_tr": "Set Correct Score Any Player",
        "name_se": "Set Correct Score Any Player",
        "name_el": "Set Correct Score Any Player",
        "name_es": "Set Correct Score Any Player",
        "name_hr": "Set Tačan rezultat Any Igrača",
        "name_fr": "Set Correct Score Any Player"
    },
    "id_1667^live_match^8": {
        "name_en": "home team to score in 2nd half",
        "name_aa": "home team to score in 2nd half",
        "name_de": "heimmannschaft zu spielstand in 2. Halfen",
        "name_nl": "Thuisteam Scoren in 2e Helft",
        "name_pl": "home team to score in 2nd half",
        "name_ro": "home team to score in 2nd half",
        "name_ru": "home team to score in 2nd half",
        "name_tr": "home team to score in 2nd half",
        "name_se": "home team to score in 2nd half",
        "name_el": "home team to score in 2nd half",
        "name_es": "home team to score in 2nd half",
        "name_hr": "Tim 1 Postići in 2nd Poluvrijeme",
        "name_fr": "home team to score in 2nd half"
    },
    "id_357^live_match^7": {
        "name_en": "Which team wins race to X points, seventh set?",
        "name_aa": "Which team wins race to X points, seventh set?",
        "name_de": "Wer erreicht zuerst x Punkte im 7. Satz?",
        "name_nl": "Welk team wint race naar X aantal punten, zevende set?",
        "name_pl": "Which team wins race to X points, seventh set?",
        "name_ro": "Which team wins race to X points, seventh set?",
        "name_ru": "Which team wins race to X points, seventh set?",
        "name_tr": "Yedinci sette X sayıya hangi takım önce ulaşır?",
        "name_se": "Which team wins race to X points, seventh set?",
        "name_el": "Which team wins race to X points, seventh set?",
        "name_es": "Which team wins race to X points, seventh set?",
        "name_hr": "Koji tim će prvi doći do X poena, sedmi set?",
        "name_fr": "Quelle équipe atteindra en premier X points, septième set ?"
    },
    "id_84^live_match^8": {
        "name_en": "Total number of games in set 1",
        "name_aa": "Total number of games in set 1",
        "name_de": "Anzahl an Spielen im 1. Satz",
        "name_nl": "Totaal aantal games in set 1",
        "name_pl": "Total number of games in set 1",
        "name_ro": "Total number of games in set 1",
        "name_ru": "Total number of games in set 1",
        "name_tr": "1. Setteki toplam oyun sayısı",
        "name_se": "Total number of games in set 1",
        "name_el": "Total number of games in set 1",
        "name_es": "Total number of games in set 1",
        "name_hr": "Ukupan broj gemova u 1. setu",
        "name_fr": "Nombre total de jeux dans le 1er set"
    },
    "id_1298^live_match^8": {
        "name_en": "Total tries awayteam",
        "name_aa": "Total tries awayteam",
        "name_de": "Anzahl Versuche Auswärtsmannschaft",
        "name_nl": "Totaal tries uitploeg",
        "name_pl": "Total tries awayteam",
        "name_ro": "Total tries awayteam",
        "name_ru": "Total tries awayteam",
        "name_tr": "Deplasman takımının toplam denemeleri",
        "name_se": "Total tries awayteam",
        "name_el": "Total tries awayteam",
        "name_es": "Total tries awayteam",
        "name_hr": "Ukupno polaganja gosti",
        "name_fr": "Total d'essais équipe visiteuse"
    },
    "id_2209^live_match^8": {
        "name_en": "Match Total 5-Way",
        "name_aa": "Match Total 5-Way",
        "name_de": "spiel Gesamthl 5-Way",
        "name_nl": "Wedstrijd Totaal 5-opties",
        "name_pl": "Match Total 5-Way",
        "name_ro": "Match Total 5-Way",
        "name_ru": "Match Total 5-Way",
        "name_tr": "Match Total 5-Way",
        "name_se": "Match Total 5-Way",
        "name_el": "Match Total 5-Way",
        "name_es": "Match Total 5-Way",
        "name_hr": "Utakmica Kraj 5-Way",
        "name_fr": "Match Total 5-Way"
    },
    "id_2324^live_match^8": {
        "name_en": "Set Player1 Breaks of Serve",
        "name_aa": "Set Player1 Breaks of Serve",
        "name_de": "Satz -Spieler 1 -Breaks",
        "name_nl": "Set Speler1 servicebreaks",
        "name_pl": "Set Player1 Breaks of Serve",
        "name_ro": "Set Player1 Breaks of Serve",
        "name_ru": "Set Player1 Breaks of Serve",
        "name_tr": "Set Player1 Breaks of Serve",
        "name_se": "Set Player1 Breaks of Serve",
        "name_el": "Set Player1 Breaks of Serve",
        "name_es": "Set Player1 Breaks of Serve",
        "name_hr": "Set Igrač 1 brejkova",
        "name_fr": "Set Player1 Breaks of Serve"
    },
    "id_1658^live_match^2": {
        "name_en": "1X2 10 min",
        "name_aa": "1X2 10 min",
        "name_de": "1X2 10 min",
        "name_nl": "1X2 10 min",
        "name_pl": "1X2 10 min",
        "name_ro": "1X2 10 min",
        "name_ru": "1X2 10 min",
        "name_tr": "1X2 10 min",
        "name_se": "1X2 10 min",
        "name_el": "1X2 10 min",
        "name_es": "1X2 10 min",
        "name_hr": "1X2 10 min",
        "name_fr": "1X2 10 min"
    },
    "id_1662^live_match^2": {
        "name_en": "1X2 50 min",
        "name_aa": "1X2 50 min",
        "name_de": "1X2 50 min",
        "name_nl": "1X2 50 min",
        "name_pl": "1X2 50 min",
        "name_ro": "1X2 50 min",
        "name_ru": "1X2 50 min",
        "name_tr": "1X2 50 min",
        "name_se": "1X2 50 min",
        "name_el": "1X2 50 min",
        "name_es": "1X2 50 min",
        "name_hr": "1X2 50 min",
        "name_fr": "1X2 50 min"
    },
    "id_2048^live_match^8": {
        "name_en": "Extra Time - Halftime/fulltime",
        "name_aa": "Extra Time - Halftime/fulltime",
        "name_de": "Extra Zeit - Halbzeit/Endergebnis",
        "name_nl": "Verlenging - Rust/volledige",
        "name_pl": "Extra Time - Halftime/fulltime",
        "name_ro": "Extra Time - Halftime/fulltime",
        "name_ru": "Extra Time - Halftime/fulltime",
        "name_tr": "Extra Time - Halftime/fulltime",
        "name_se": "Extra Time - Halftime/fulltime",
        "name_el": "Extra Time - Halftime/fulltime",
        "name_es": "Extra Time - Halftime/fulltime",
        "name_hr": "produzeci - Poluvrijeme/fulltime",
        "name_fr": "Extra Time - Halftime/fulltime"
    },
    "id_2047^live_match^8": {
        "name_en": "Extra Time Corners",
        "name_aa": "Extra Time Corners",
        "name_de": "Extra Zeit ecken",
        "name_nl": "Verlenging - Hoekschoppen",
        "name_pl": "Extra Time Corners",
        "name_ro": "Extra Time Corners",
        "name_ru": "Extra Time Corners",
        "name_tr": "Extra Time Corners",
        "name_se": "Extra Time Corners",
        "name_el": "Extra Time Corners",
        "name_es": "Extra Time Corners",
        "name_hr": "produzeci Korneri",
        "name_fr": "Extra Time Corners"
    },
    "id_2153^live_match^8": {
        "name_en": "Winning Margin",
        "name_aa": "Winning Margin",
        "name_de": "Siegesvorsprung",
        "name_nl": "Winstmarge",
        "name_pl": "Winning Margin",
        "name_ro": "Winning Margin",
        "name_ru": "Winning Margin",
        "name_tr": "Winning Margin",
        "name_se": "Winning Margin",
        "name_el": "Winning Margin",
        "name_es": "Winning Margin",
        "name_hr": "Pobjeda Razlikom",
        "name_fr": "Winning Margin"
    },
    "id_2188^live_match^8": {
        "name_en": "1st Half - Total Goals awayteam",
        "name_aa": "1st Half - Total Goals awayteam",
        "name_de": "1. Halfen - Gesamthl Tore Auswärtsmannschaft",
        "name_nl": "1e Helft - Totaal doelpunts Uitteam",
        "name_pl": "1st Half - Total Goals awayteam",
        "name_ro": "1st Half - Total Goals awayteam",
        "name_ru": "1st Half - Total Goals awayteam",
        "name_tr": "1st Half - Total Goals awayteam",
        "name_se": "1st Half - Total Goals awayteam",
        "name_el": "1st Half - Total Goals awayteam",
        "name_es": "1st Half - Total Goals awayteam",
        "name_hr": "1st Poluvrijeme - Kraj Golova Tim 2",
        "name_fr": "1st Half - Total Goals awayteam"
    },
    "id_2014^live_match^8": {
        "name_en": "3rd Quarter - Total hometeam",
        "name_aa": "3rd Quarter - Total hometeam",
        "name_de": "3. Viertel - Gesamthl heimmannschaft",
        "name_nl": "3e kwart - totaal Thuisteam",
        "name_pl": "3rd Quarter - Total hometeam",
        "name_ro": "3rd Quarter - Total hometeam",
        "name_ru": "3rd Quarter - Total hometeam",
        "name_tr": "3rd Quarter - Total hometeam",
        "name_se": "3rd Quarter - Total hometeam",
        "name_el": "3rd Quarter - Total hometeam",
        "name_es": "3rd Quarter - Total hometeam",
        "name_hr": "3 Cetvrtina - Kraj Tim 1",
        "name_fr": "3rd Quarter - Total hometeam"
    },
    "id_2015^live_match^8": {
        "name_en": "3rd Quarter - Total awayteam",
        "name_aa": "3rd Quarter - Total awayteam",
        "name_de": "3. Viertel - Gesamthl Auswärtsmannschaft",
        "name_nl": "3e kwart - totaal Uitteam",
        "name_pl": "3rd Quarter - Total awayteam",
        "name_ro": "3rd Quarter - Total awayteam",
        "name_ru": "3rd Quarter - Total awayteam",
        "name_tr": "3rd Quarter - Total awayteam",
        "name_se": "3rd Quarter - Total awayteam",
        "name_el": "3rd Quarter - Total awayteam",
        "name_es": "3rd Quarter - Total awayteam",
        "name_hr": "3 Cetvrtina - Kraj Tim 2",
        "name_fr": "3rd Quarter - Total awayteam"
    },
    "id_2095^live_match^7": {
        "name_en": "Point Betting",
        "name_aa": "Point Betting",
        "name_de": "Punktwetten",
        "name_nl": "Puntwedden",
        "name_pl": "Point Betting",
        "name_ro": "Point Betting",
        "name_ru": "Point Betting",
        "name_tr": "Point Betting",
        "name_se": "Point Betting",
        "name_el": "Point Betting",
        "name_es": "Point Betting",
        "name_hr": "Igra na poene",
        "name_fr": "Point Betting"
    },
    "id_2261^live_match^8": {
        "name_en": "Match Correct Score",
        "name_aa": "Match Correct Score",
        "name_de": "Genaues Ergebnis",
        "name_nl": "Wedstrijd Correcte Scoren",
        "name_pl": "Match Correct Score",
        "name_ro": "Match Correct Score",
        "name_ru": "Match Correct Score",
        "name_tr": "Match Correct Score",
        "name_se": "Match Correct Score",
        "name_el": "Match Correct Score",
        "name_es": "Match Correct Score",
        "name_hr": "Utakmica Tačan rezultat",
        "name_fr": "Match Correct Score"
    },
    "id_43^live_match^8": {
        "name_en": "Total for first period",
        "name_aa": "Total for first period",
        "name_de": "Anzahl Punkte 1. Abschnitt",
        "name_nl": "Totaal voor eerste periode",
        "name_pl": "Total for first period",
        "name_ro": "Total for first period",
        "name_ru": "Total for first period",
        "name_tr": "Birinci periyodun toplamı",
        "name_se": "Total for first period",
        "name_el": "Total for first period",
        "name_es": "Total for first period",
        "name_hr": "Total za prvi period",
        "name_fr": "Total pour la première période"
    },
    "id_80^live_match^7": {
        "name_en": "Who scores Xth point fourth set",
        "name_aa": "Who scores Xth point fourth set",
        "name_de": "Wer erzielt den x. Punkt im 4. Satz?",
        "name_nl": "Wie scoort het Xde punt vierde set",
        "name_pl": "Who scores Xth point fourth set",
        "name_ro": "Who scores Xth point fourth set",
        "name_ru": "Who scores Xth point fourth set",
        "name_tr": "Dördüncü sette X. sayıyı kim kazanır?",
        "name_se": "Who scores Xth point fourth set",
        "name_el": "Who scores Xth point fourth set",
        "name_es": "Who scores Xth point fourth set",
        "name_hr": "Tko će osvojiti X-ti poen u četvrtom setu",
        "name_fr": "Qui va marquer le xième point quatrième set"
    },
    "id_341^live_match^8": {
        "name_en": "Final Result (in sets - best of 7)",
        "name_aa": "Final Result (in sets - best of 7)",
        "name_de": "Endergebnis (in Sätzen - Best of 7)",
        "name_nl": "Eindresultaat (in sets - best of 7)",
        "name_pl": "Final Result (in sets - best of 7)",
        "name_ro": "Final Result (in sets - best of 7)",
        "name_ru": "Final Result (in sets - best of 7)",
        "name_tr": "Maç Sonucu (setlerde - en iyi 7)",
        "name_se": "Final Result (in sets - best of 7)",
        "name_el": "Final Result (in sets - best of 7)",
        "name_es": "Final Result (in sets - best of 7)",
        "name_hr": "Finalni rezultat (u setovima - najbolji u 7)",
        "name_fr": "Résultat Final (en sets - au meilleur des 7)"
    },
    "id_2156^live_match^8": {
        "name_en": "Game Winning Margin",
        "name_aa": "Game Winning Margin",
        "name_de": "spiel Siegesvorsprung",
        "name_nl": "Game Winstmarge",
        "name_pl": "Game Winning Margin",
        "name_ro": "Game Winning Margin",
        "name_ru": "Game Winning Margin",
        "name_tr": "Game Winning Margin",
        "name_se": "Game Winning Margin",
        "name_el": "Game Winning Margin",
        "name_es": "Game Winning Margin",
        "name_hr": "IgraPobjeda Razlikom",
        "name_fr": "Game Winning Margin"
    },
    "id_2138^live_match^8": {
        "name_en": "Hometeam Odd/Even",
        "name_aa": "Hometeam Odd/Even",
        "name_de": "heimmannschaft Ungerada/gerade",
        "name_nl": "Thuisteam even/oneven",
        "name_pl": "Hometeam Odd/Even",
        "name_ro": "Hometeam Odd/Even",
        "name_ru": "Hometeam Odd/Even",
        "name_tr": "Hometeam Odd/Even",
        "name_se": "Hometeam Odd/Even",
        "name_el": "Hometeam Odd/Even",
        "name_es": "Hometeam Odd/Even",
        "name_hr": "Tim 1 nepar/par",
        "name_fr": "Hometeam Odd/Even"
    },
    "id_35^live_match^21": {
        "name_en": "Halftime - Total",
        "name_aa": "Halftime - Total",
        "name_de": "Halbzeit - Gesamthl",
        "name_nl": "Rust - Totaal",
        "name_pl": "Halftime - Total",
        "name_ro": "Halftime - Total",
        "name_ru": "Halftime - Total",
        "name_tr": "Halftime - Total",
        "name_se": "Halftime - Total",
        "name_el": "Halftime - Total",
        "name_es": "Halftime - Total",
        "name_hr": "Poluvrijeme - kraj",
        "name_fr": "Halftime - Total"
    },
    "id_96^live_match^8": {
        "name_en": "Score of game X",
        "name_aa": "Score of game X",
        "name_de": "Endstand X. Spiel",
        "name_nl": "Score van game X",
        "name_pl": "Score of game X",
        "name_ro": "Score of game X",
        "name_ru": "Score of game X",
        "name_tr": "X. oyunun skoru [gameNr]",
        "name_se": "Score of game X",
        "name_el": "Score of game X",
        "name_es": "Score of game X",
        "name_hr": "Rezultat X. gema",
        "name_fr": "Score du jeu X"
    },
    "id_2247^live_match^6": {
        "name_en": "3-Way Handicap",
        "name_aa": "3-Way Handicap",
        "name_de": "3-Weg Handicap",
        "name_nl": "3 opties Handicap",
        "name_pl": "3-Way Handicap",
        "name_ro": "3-Way Handicap",
        "name_ru": "3-Way Handicap",
        "name_tr": "3-Way Handicap",
        "name_se": "3-Way Handicap",
        "name_el": "3-Way Handicap",
        "name_es": "3-Way Handicap",
        "name_hr": "3-Way Hendikep",
        "name_fr": "3-Way Handicap"
    },
    "id_2024^live_match^8": {
        "name_en": "3rd Quarter Race to points",
        "name_aa": "3rd Quarter Race to points",
        "name_de": "3. Viertel Race to points",
        "name_nl": "3e kwart Race naar punten",
        "name_pl": "3rd Quarter Race to points",
        "name_ro": "3rd Quarter Race to points",
        "name_ru": "3rd Quarter Race to points",
        "name_tr": "3rd Quarter Race to points",
        "name_se": "3rd Quarter Race to points",
        "name_el": "3rd Quarter Race to points",
        "name_es": "3rd Quarter Race to points",
        "name_hr": "3 Cetvrtina trka do poena",
        "name_fr": "3rd Quarter Race to points"
    },
    "id_2216^live_match^8": {
        "name_en": "Winning Margin 5-Way Alt 2",
        "name_aa": "Winning Margin 5-Way Alt 2",
        "name_de": "Siegesvorsprung 5-Way Alt 2",
        "name_nl": "Winstmarge 5-opties Alt 2",
        "name_pl": "Winning Margin 5-Way Alt 2",
        "name_ro": "Winning Margin 5-Way Alt 2",
        "name_ru": "Winning Margin 5-Way Alt 2",
        "name_tr": "Winning Margin 5-Way Alt 2",
        "name_se": "Winning Margin 5-Way Alt 2",
        "name_el": "Winning Margin 5-Way Alt 2",
        "name_es": "Winning Margin 5-Way Alt 2",
        "name_hr": "Pobjeda Razlikom 5-Way Alt 2",
        "name_fr": "Winning Margin 5-Way Alt 2"
    },
    "id_353^live_match^7": {
        "name_en": "Who scores Xth point sixth set",
        "name_aa": "Who scores Xth point sixth set",
        "name_de": "Wer erzielt den x. Punkt im 6. Satz?",
        "name_nl": "Wie scoort het Xste punt zesde set",
        "name_pl": "Who scores Xth point sixth set",
        "name_ro": "Who scores Xth point sixth set",
        "name_ru": "Who scores Xth point sixth set",
        "name_tr": "Altıncı sette X. sayıyı kim kazanır?",
        "name_se": "Who scores Xth point sixth set",
        "name_el": "Who scores Xth point sixth set",
        "name_es": "Who scores Xth point sixth set",
        "name_hr": "Tko će osvojiti X-ti poen u šestom setu",
        "name_fr": "Qui va marquer le xième point sixième set"
    },
    "id_2263^live_match^8": {
        "name_en": "Inning Score",
        "name_aa": "Inning Score",
        "name_de": "Punktzahl innings",
        "name_nl": "Inning Score",
        "name_pl": "Inning Score",
        "name_ro": "Inning Score",
        "name_ru": "Inning Score",
        "name_tr": "Inning Score",
        "name_se": "Inning Score",
        "name_el": "Inning Score",
        "name_es": "Inning Score",
        "name_hr": "Inningom  gol",
        "name_fr": "Inning Score"
    },
    "id_2171^live_match^8": {
        "name_en": "Game to End in a Tie",
        "name_aa": "Game to End in a Tie",
        "name_de": "spiel to End in a Tie",
        "name_nl": "Wedstrijd eindigt in gelijkspel",
        "name_pl": "Game to End in a Tie",
        "name_ro": "Game to End in a Tie",
        "name_ru": "Game to End in a Tie",
        "name_tr": "Game to End in a Tie",
        "name_se": "Game to End in a Tie",
        "name_el": "Game to End in a Tie",
        "name_es": "Game to End in a Tie",
        "name_hr": "Nerjeseno",
        "name_fr": "Game to End in a Tie"
    },
    "id_52^live_match^7": {
        "name_en": "Draw no Bet for fourth period",
        "name_aa": "Draw no Bet for fourth period",
        "name_de": "Sieger - Kein Unentschieden 4. Abschnitt",
        "name_nl": "Draw no Bet voor vierde periode",
        "name_pl": "Draw no Bet for fourth period",
        "name_ro": "Draw no Bet for fourth period",
        "name_ru": "Draw no Bet for fourth period",
        "name_tr": "Beraberlikte iade dördüncü periyot",
        "name_se": "Draw no Bet for fourth period",
        "name_el": "Draw no Bet for fourth period",
        "name_es": "Draw no Bet for fourth period",
        "name_hr": "Ishod bez neodlučenog za četvrti period",
        "name_fr": "Remboursé si Match nul pour la quatrième période"
    },
    "id_2127^live_match^8": {
        "name_en": "Period Extra Points",
        "name_aa": "Period Extra Points",
        "name_de": "drittel Extra Points",
        "name_nl": "Periode Extra punten",
        "name_pl": "Period Extra Points",
        "name_ro": "Period Extra Points",
        "name_ru": "Period Extra Points",
        "name_tr": "Period Extra Points",
        "name_se": "Period Extra Points",
        "name_el": "Period Extra Points",
        "name_es": "Period Extra Points",
        "name_hr": "Period Extra Poeni",
        "name_fr": "Period Extra Points"
    },
    "id_120^live_match^6": {
        "name_en": "Cornerbet",
        "name_aa": "Cornerbet",
        "name_de": "Team mit den meisten Eckbällen",
        "name_nl": "Hoekschoppenbet",
        "name_pl": "Cornerbet",
        "name_ro": "Cornerbet",
        "name_ru": "Cornerbet",
        "name_tr": "Korner Bahsi",
        "name_se": "Cornerbet",
        "name_el": "Cornerbet",
        "name_es": "Cornerbet",
        "name_hr": "Udarci iz kuta",
        "name_fr": "Pari sur les Corners"
    },
    "id_2182^live_match^8": {
        "name_en": "Hometeam Total Behinds",
        "name_aa": "Hometeam Total Behinds",
        "name_de": "Heimmannschaft - Gesamtzahl Behinds",
        "name_nl": "Thuisteam - Totaal aantal behinds",
        "name_pl": "Hometeam Total Behinds",
        "name_ro": "Hometeam Total Behinds",
        "name_ru": "Hometeam Total Behinds",
        "name_tr": "Hometeam Total Behinds",
        "name_se": "Hometeam Total Behinds",
        "name_el": "Hometeam Total Behinds",
        "name_es": "Hometeam Total Behinds",
        "name_hr": "Tim 1 Kraj Behinds",
        "name_fr": "Hometeam Total Behinds"
    },
    "id_2033^live_match^8": {
        "name_en": "1st Quarter Result and Total",
        "name_aa": "1st Quarter Result and Total",
        "name_de": "1. Viertel Ergebnis Und Gesamthl",
        "name_nl": "1e kwart Resultaat and totaal",
        "name_pl": "1st Quarter Result and Total",
        "name_ro": "1st Quarter Result and Total",
        "name_ru": "1st Quarter Result and Total",
        "name_tr": "1st Quarter Result and Total",
        "name_se": "1st Quarter Result and Total",
        "name_el": "1st Quarter Result and Total",
        "name_es": "1st Quarter Result and Total",
        "name_hr": "1 Cetvrtina Rezultiratiand Kraj",
        "name_fr": "1st Quarter Result and Total"
    },
    "id_2351^live_match^8": {
        "name_en": "Extra Time Both Teams to Score",
        "name_aa": "Extra Time Both Teams to Score",
        "name_de": "Extra zeit beide Teams zu spielstand",
        "name_nl": "Extra tijd Beide teams Scoren",
        "name_pl": "Extra Time Both Teams to Score",
        "name_ro": "Extra Time Both Teams to Score",
        "name_ru": "Extra Time Both Teams to Score",
        "name_tr": "Extra Time Both Teams to Score",
        "name_se": "Extra Time Both Teams to Score",
        "name_el": "Extra Time Both Teams to Score",
        "name_es": "Extra Time Both Teams to Score",
        "name_hr": "Oba daju gol u produzetku",
        "name_fr": "Extra Time Both Teams to Score"
    },
    "id_2165^live_match^8": {
        "name_en": "Player 2 To Win 2 Sets",
        "name_aa": "Player 2 To Win 2 Sets",
        "name_de": "Player 2 zu gewinnen 2 satzs",
        "name_nl": "Speler 2 Wint 2 Sets",
        "name_pl": "Player 2 To Win 2 Sets",
        "name_ro": "Player 2 To Win 2 Sets",
        "name_ru": "Player 2 To Win 2 Sets",
        "name_tr": "Player 2 To Win 2 Sets",
        "name_se": "Player 2 To Win 2 Sets",
        "name_el": "Player 2 To Win 2 Sets",
        "name_es": "Player 2 To Win 2 Sets",
        "name_hr": "Igrač  2 dobija 2 Seta",
        "name_fr": "Player 2 To Win 2 Sets"
    },
    "id_1326^live_match^8": {
        "name_en": "Which team wins race to X",
        "name_aa": "Which team wins race to X",
        "name_de": "Welche Mannschaft erreicht zuerst X",
        "name_nl": "Which team wins race to X",
        "name_pl": "Which team wins race to X",
        "name_ro": "Which team wins race to X",
        "name_ru": "Which team wins race to X",
        "name_tr": "Hangi takım X sayısına kadar ralli kazanır",
        "name_se": "Which team wins race to X",
        "name_el": "Which team wins race to X",
        "name_es": "Which team wins race to X",
        "name_hr": "Koji tim će prvi osvojiti X poena",
        "name_fr": "Quelle équipe va atteindre x en premier"
    },
    "id_1660^live_match^2": {
        "name_en": "1X2 30 min",
        "name_aa": "1X2 30 min",
        "name_de": "1X2 30 min",
        "name_nl": "1X2 30 min",
        "name_pl": "1X2 30 min",
        "name_ro": "1X2 30 min",
        "name_ru": "1X2 30 min",
        "name_tr": "1X2 30 min",
        "name_se": "1X2 30 min",
        "name_el": "1X2 30 min",
        "name_es": "1X2 30 min",
        "name_hr": "1X2 30 min",
        "name_fr": "1X2 30 min"
    },
    "id_2019^live_match^8": {
        "name_en": "2nd Half - Total awayteam",
        "name_aa": "2nd Half - Total awayteam",
        "name_de": "2. Halfen - Gesamthl Auswärtsmannschaft",
        "name_nl": "2e Helft - totaal Uitteam",
        "name_pl": "2nd Half - Total awayteam",
        "name_ro": "2nd Half - Total awayteam",
        "name_ru": "2nd Half - Total awayteam",
        "name_tr": "2nd Half - Total awayteam",
        "name_se": "2nd Half - Total awayteam",
        "name_el": "2nd Half - Total awayteam",
        "name_es": "2nd Half - Total awayteam",
        "name_hr": "2. Poluvrijeme - Kraj Tim 2",
        "name_fr": "2nd Half - Total awayteam"
    },
    "id_2155^live_match^8": {
        "name_en": "Result and Total",
        "name_aa": "Result and Total",
        "name_de": "ErgebnisUnd Gesamthl",
        "name_nl": "Resultaat and Totaal",
        "name_pl": "Result and Total",
        "name_ro": "Result and Total",
        "name_ru": "Result and Total",
        "name_tr": "Result and Total",
        "name_se": "Result and Total",
        "name_el": "Result and Total",
        "name_es": "Result and Total",
        "name_hr": "Rezultat i ukupno",
        "name_fr": "Result and Total"
    },
    "id_2204^live_match^7": {
        "name_en": "Quarter - First Goal",
        "name_aa": "Quarter - First Goal",
        "name_de": "Viertel - 1. Tor",
        "name_nl": "kwart - 1e doelpunt",
        "name_pl": "Quarter - First Goal",
        "name_ro": "Quarter - First Goal",
        "name_ru": "Quarter - First Goal",
        "name_tr": "Quarter - First Goal",
        "name_se": "Quarter - First Goal",
        "name_el": "Quarter - First Goal",
        "name_es": "Quarter - First Goal",
        "name_hr": "Cetvrtina - First gola",
        "name_fr": "Quarter - First Goal"
    },
    "id_7^live_match^7": {
        "name_en": "Total (OT only!)",
        "name_aa": "Total (OT only!)",
        "name_de": "Total (nur Verl.)",
        "name_nl": "Totaal (enkel ET!)",
        "name_pl": "Total (OT only!)",
        "name_ro": "Total (OT only!)",
        "name_ru": "Total (OT only!)",
        "name_tr": "Toplam (Sadece uzatmalar!)",
        "name_se": "Total (OT only!)",
        "name_el": "Total (OT only!)",
        "name_es": "Total (OT only!)",
        "name_hr": "Total (samo produžeci!)",
        "name_fr": "Total (prolong. seulement !)"
    },
    "id_289^live_match^7": {
        "name_en": "2nd Half - Draw No Bet",
        "name_aa": "2nd Half - Draw No Bet",
        "name_de": "2. Halbzeit - Unentschieden keine Wette",
        "name_nl": "2de Helft - Draw No Bet",
        "name_pl": "2nd Half - Draw No Bet",
        "name_ro": "2nd Half - Draw No Bet",
        "name_ru": "2nd Half - Draw No Bet",
        "name_tr": "2. yarı - Beraberlikte iade",
        "name_se": "2nd Half - Draw No Bet",
        "name_el": "2nd Half - Draw No Bet",
        "name_es": "2nd Half - Draw No Bet",
        "name_hr": "2. Poluvrijeme - Ishod bez neodlučenog",
        "name_fr": "2e Mi-temps - Remboursé si match nul"
    },
    "id_2174^live_match^8": {
        "name_en": "Match Goals",
        "name_aa": "Match Goals",
        "name_de": "spiel Tore",
        "name_nl": "Wedstrijd doelpunts",
        "name_pl": "Match Goals",
        "name_ro": "Match Goals",
        "name_ru": "Match Goals",
        "name_tr": "Match Goals",
        "name_se": "Match Goals",
        "name_el": "Match Goals",
        "name_es": "Match Goals",
        "name_hr": "Utakmica Golova",
        "name_fr": "Match Goals"
    },
    "id_1580^live_match^6": {
        "name_en": "Highest scoring team in either half",
        "name_aa": "Highest scoring team in either half",
        "name_de": "Team mit den meisten Punkten in beiden Hälften",
        "name_nl": "Team met hoogste Scoren in gelijk welke helft",
        "name_pl": "Highest scoring team in either half",
        "name_ro": "Highest scoring team in either half",
        "name_ru": "Highest scoring team in either half",
        "name_tr": "Highest scoring team in either half",
        "name_se": "Highest scoring team in either half",
        "name_el": "Highest scoring team in either half",
        "name_es": "Highest scoring team in either half",
        "name_hr": "Tim s najboljim rezultatom u bilo kom poluvremenu",
        "name_fr": "Equipe qui marque le plus dans une des mi-temps"
    },
    "id_2004^live_match^8": {
        "name_en": "Next goal method",
        "name_aa": "Next goal method",
        "name_de": "nächste Tor methode",
        "name_nl": "Volgende manier van scoren",
        "name_pl": "Next goal method",
        "name_ro": "Next goal method",
        "name_ru": "Next goal method",
        "name_tr": "Next goal method",
        "name_se": "Next goal method",
        "name_el": "Next goal method",
        "name_es": "Next goal method",
        "name_hr": "Nacin sledeceg gola",
        "name_fr": "Next goal method"
    },
    "id_2080^live_match^8": {
        "name_en": "Set Lead After",
        "name_aa": "Set Lead After",
        "name_de": "Satz - In führung nach",
        "name_nl": "Set- Aan de leiding na",
        "name_pl": "Set Lead After",
        "name_ro": "Set Lead After",
        "name_ru": "Set Lead After",
        "name_tr": "Set Lead After",
        "name_se": "Set Lead After",
        "name_el": "Set Lead After",
        "name_es": "Set Lead After",
        "name_hr": "Vodi u setovima poslije",
        "name_fr": "Set Lead After"
    },
    "id_2352^live_match^7": {
        "name_en": "Team to Take Last Penalty in Shootout",
        "name_aa": "Team to Take Last Penalty in Shootout",
        "name_de": "Team to Take Last Penalty in Shootout",
        "name_nl": "Team to Take Last Penalty in Shootout",
        "name_pl": "Team to Take Last Penalty in Shootout",
        "name_ro": "Team to Take Last Penalty in Shootout",
        "name_ru": "Team to Take Last Penalty in Shootout",
        "name_tr": "Team to Take Last Penalty in Shootout",
        "name_se": "Team to Take Last Penalty in Shootout",
        "name_el": "Team to Take Last Penalty in Shootout",
        "name_es": "Team to Take Last Penalty in Shootout",
        "name_hr": "Poslednji penal",
        "name_fr": "Team to Take Last Penalty in Shootout"
    },
    "id_2191^live_match^8": {
        "name_en": "Total Goals hometeam for Quarter",
        "name_aa": "Total Goals hometeam for Quarter",
        "name_de": "Gesamthl Tore heimmannschaft Für Viertel",
        "name_nl": "Totaal doelpunts Thuisteam voor kwart",
        "name_pl": "Total Goals hometeam for Quarter",
        "name_ro": "Total Goals hometeam for Quarter",
        "name_ru": "Total Goals hometeam for Quarter",
        "name_tr": "Total Goals hometeam for Quarter",
        "name_se": "Total Goals hometeam for Quarter",
        "name_el": "Total Goals hometeam for Quarter",
        "name_es": "Total Goals hometeam for Quarter",
        "name_hr": "Kraj Golova Tim 1 za Cetvrtinu",
        "name_fr": "Total Goals hometeam for Quarter"
    },
    "id_2213^live_match^8": {
        "name_en": "Quarter Winning Margin",
        "name_aa": "Quarter Winning Margin",
        "name_de": "Viertel Siegesvorsprung",
        "name_nl": "kwart Winstmarge",
        "name_pl": "Quarter Winning Margin",
        "name_ro": "Quarter Winning Margin",
        "name_ru": "Quarter Winning Margin",
        "name_tr": "Quarter Winning Margin",
        "name_se": "Quarter Winning Margin",
        "name_el": "Quarter Winning Margin",
        "name_es": "Quarter Winning Margin",
        "name_hr": "Cetvrtina Pobjeda Razlikom",
        "name_fr": "Quarter Winning Margin"
    },
    "id_2121^live_match^8": {
        "name_en": "Set to Break Serve",
        "name_aa": "Set to Break Serve",
        "name_de": "Satz gelingt das Break",
        "name_nl": "Set - Breekt de service",
        "name_pl": "Set to Break Serve",
        "name_ro": "Set to Break Serve",
        "name_ru": "Set to Break Serve",
        "name_tr": "Set to Break Serve",
        "name_se": "Set to Break Serve",
        "name_el": "Set to Break Serve",
        "name_es": "Set to Break Serve",
        "name_hr": "brejk u setu",
        "name_fr": "Set to Break Serve"
    },
    "id_303^live_match^8": {
        "name_en": "2nd Half - Both teams to score",
        "name_aa": "2nd Half - Both teams to score",
        "name_de": "2. Halbzeit - Beide Teams treffen",
        "name_nl": "2de Helft - Beide teams scoren",
        "name_pl": "2nd Half - Both teams to score",
        "name_ro": "2nd Half - Both teams to score",
        "name_ru": "2nd Half - Both teams to score",
        "name_tr": "2.yarı - İki takım da gol atar",
        "name_se": "2nd Half - Both teams to score",
        "name_el": "2nd Half - Both teams to score",
        "name_es": "2nd Half - Both teams to score",
        "name_hr": "2. Poluvrijeme - Oba daju gol",
        "name_fr": "2e Mi-temps - Les deux équipes qui marquent"
    },
    "id_2058^live_match^8": {
        "name_en": "Double Result",
        "name_aa": "Double Result",
        "name_de": "Doppelte Ergebnis",
        "name_nl": "Dubbele Resultaat",
        "name_pl": "Double Result",
        "name_ro": "Double Result",
        "name_ru": "Double Result",
        "name_tr": "Double Result",
        "name_se": "Double Result",
        "name_el": "Double Result",
        "name_es": "Double Result",
        "name_hr": "Doupla Rezultirati",
        "name_fr": "Double Result"
    },
    "id_143^live_match^8": {
        "name_en": "Total awayteam",
        "name_aa": "Total awayteam",
        "name_de": "Total Auswärtsmannschaft",
        "name_nl": "Total uitploeg",
        "name_pl": "Total awayteam",
        "name_ro": "Total awayteam",
        "name_ru": "Total awayteam",
        "name_tr": "Toplam Deplasman Takımı",
        "name_se": "Total awayteam",
        "name_el": "Total awayteam",
        "name_es": "Total awayteam",
        "name_hr": "Ukupno gost",
        "name_fr": "Total équipe visiteuse"
    },
    "id_2203^live_match^7": {
        "name_en": "To Win Quarter",
        "name_aa": "To Win Quarter",
        "name_de": "zu gewinnen Viertel",
        "name_nl": "Wint kwart",
        "name_pl": "To Win Quarter",
        "name_ro": "To Win Quarter",
        "name_ru": "To Win Quarter",
        "name_tr": "To Win Quarter",
        "name_se": "To Win Quarter",
        "name_el": "To Win Quarter",
        "name_es": "To Win Quarter",
        "name_hr": "Da pobijediti cetvrtinu",
        "name_fr": "To Win Quarter"
    },
    "id_20^live_match^6": {
        "name_en": "Halftime - Who wins the rest?",
        "name_aa": "Halftime - Who wins the rest?",
        "name_de": "Halbzeit - Wer gewinnt die verbleibende Spielzeit?",
        "name_nl": "Rust - wie wint er in de resterende speeltijd.",
        "name_pl": "Halftime - Who wins the rest?",
        "name_ro": "Halftime - Who wins the rest?",
        "name_ru": "Halftime - Who wins the rest?",
        "name_tr": "İlk Yarı - Kalan kısmı kim kazanır?",
        "name_se": "Halftime - Who wins the rest?",
        "name_el": "Halftime - Who wins the rest?",
        "name_es": "Halftime - Who wins the rest?",
        "name_hr": "Poluvrijeme - Tko dobiva ostatak meča?",
        "name_fr": "Mi-Temps - Qui gagne le reste ?"
    },
    "id_21^live_match^7": {
        "name_en": "Halftime - Total",
        "name_aa": "Halftime - Total",
        "name_de": "Halbzeit - Total",
        "name_nl": "rust - Totaal",
        "name_pl": "Halftime - Total",
        "name_ro": "Halftime - Total",
        "name_ru": "Halftime - Total",
        "name_tr": "İlk Yarı - Toplam",
        "name_se": "Halftime - Total",
        "name_el": "Halftime - Total",
        "name_es": "Halftime - Total",
        "name_hr": "Poluvrijeme - Total",
        "name_fr": "Mi-temps - Total"
    },
    "id_71^live_match^7": {
        "name_en": "Which team wins race to X points, first set?",
        "name_aa": "Which team wins race to X points, first set?",
        "name_de": "Wer erreicht zuerst x Punkte im 1. Satz?",
        "name_nl": "Welk team wint de race tot X punten, eerste set ?",
        "name_pl": "Which team wins race to X points, first set?",
        "name_ro": "Which team wins race to X points, first set?",
        "name_ru": "Which team wins race to X points, first set?",
        "name_tr": "Birinci sette X sayıya hangi takım önce ulaşır?",
        "name_se": "Which team wins race to X points, first set?",
        "name_el": "Which team wins race to X points, first set?",
        "name_es": "Which team wins race to X points, first set?",
        "name_hr": "Koji tim će prvi doći do X poena, prvi set?",
        "name_fr": "Quelle équipe atteindra en premier X points, premier set ?"
    },
    "id_2163^live_match^8": {
        "name_en": "Five Setter?",
        "name_aa": "Five Setter?",
        "name_de": "Five satzter?",
        "name_nl": "5 Setter?",
        "name_pl": "Five Setter?",
        "name_ro": "Five Setter?",
        "name_ru": "Five Setter?",
        "name_tr": "Five Setter?",
        "name_se": "Five Setter?",
        "name_el": "Five Setter?",
        "name_es": "Five Setter?",
        "name_hr": "Pet setova?",
        "name_fr": "Five Setter?"
    },
    "id_2228^live_match^8": {
        "name_en": "Total Hits awayteam",
        "name_aa": "Total Hits awayteam",
        "name_de": "Gesamthl Hits Auswärtsmannschaft",
        "name_nl": "Totaal Hits Uitteam",
        "name_pl": "Total Hits awayteam",
        "name_ro": "Total Hits awayteam",
        "name_ru": "Total Hits awayteam",
        "name_tr": "Total Hits awayteam",
        "name_se": "Total Hits awayteam",
        "name_el": "Total Hits awayteam",
        "name_es": "Total Hits awayteam",
        "name_hr": "Kraj Hits Tim 2",
        "name_fr": "Total Hits awayteam"
    },
    "id_2262^live_match^8": {
        "name_en": "Inning Total Runs",
        "name_aa": "Inning Total Runs",
        "name_de": "Inning Gesamthl Runs",
        "name_nl": "Inning Totaal Runs",
        "name_pl": "Inning Total Runs",
        "name_ro": "Inning Total Runs",
        "name_ru": "Inning Total Runs",
        "name_tr": "Inning Total Runs",
        "name_se": "Inning Total Runs",
        "name_el": "Inning Total Runs",
        "name_es": "Inning Total Runs",
        "name_hr": "Inningom  Kraj Runs",
        "name_fr": "Inning Total Runs"
    },
    "id_2012^live_match^8": {
        "name_en": "2nd Quarter - Total hometeam",
        "name_aa": "2nd Quarter - Total hometeam",
        "name_de": "2. Viertel - Gesamthl heimmannschaft",
        "name_nl": "2e kwart - totaal Thuisteam",
        "name_pl": "2nd Quarter - Total hometeam",
        "name_ro": "2nd Quarter - Total hometeam",
        "name_ru": "2nd Quarter - Total hometeam",
        "name_tr": "2nd Quarter - Total hometeam",
        "name_se": "2nd Quarter - Total hometeam",
        "name_el": "2nd Quarter - Total hometeam",
        "name_es": "2nd Quarter - Total hometeam",
        "name_hr": "2 Cetvrtina - Kraj Tim 1",
        "name_fr": "2nd Quarter - Total hometeam"
    },
    "id_918^live_match^8": {
        "name_en": "Double Result (1st set / match)",
        "name_aa": "Double Result (1st set / match)",
        "name_de": "Doppeltes Ergebnis (1. Satz / Spiel)",
        "name_nl": "Dubbel Resultaat (1ste set / match)",
        "name_pl": "Double Result (1st set / match)",
        "name_ro": "Double Result (1st set / match)",
        "name_ru": "Double Result (1st set / match)",
        "name_tr": "Çifte Sonuç (1. set / maç)",
        "name_se": "Double Result (1st set / match)",
        "name_el": "Double Result (1st set / match)",
        "name_es": "Double Result (1st set / match)",
        "name_hr": "Dvostruki Rezultat (1. set / meč)",
        "name_fr": "Résultat double (1er set / match)"
    },
    "id_54^live_match^7": {
        "name_en": "Asian handicap for fourth period",
        "name_aa": "Asian handicap for fourth period",
        "name_de": "Asian Handicap für 4. Abschnitt",
        "name_nl": "Asian handicap voor vierde periode",
        "name_pl": "Asian handicap for fourth period",
        "name_ro": "Asian handicap for fourth period",
        "name_ru": "Asian handicap for fourth period",
        "name_tr": "Dördüncü periyot için Asya Handikabı",
        "name_se": "Asian handicap for fourth period",
        "name_el": "Asian handicap for fourth period",
        "name_es": "Asian handicap for fourth period",
        "name_hr": "Azijski hendikep za četvrti period",
        "name_fr": "Handicap asiatique pour la quatrième période"
    },
    "id_1040^live_match^8": {
        "name_en": "Matchbet and both teams to score",
        "name_aa": "Matchbet and both teams to score",
        "name_de": "Matchbet und beide Teams treffen",
        "name_nl": "Matchbet en beide teams scoren",
        "name_pl": "Matchbet and both teams to score",
        "name_ro": "Matchbet and both teams to score",
        "name_ru": "Matchbet and both teams to score",
        "name_tr": "Maç bahsi ve iki takım da skor yapar",
        "name_se": "Matchbet and both teams to score",
        "name_el": "Matchbet and both teams to score",
        "name_es": "Matchbet and both teams to score",
        "name_hr": "Oklada na ishod i oba tima daju gol",
        "name_fr": "Pari sur match et les deux équipes marquent"
    },
    "id_2001^live_match^8": {
        "name_en": "Last corner",
        "name_aa": "Last corner",
        "name_de": "Letzter Eckball",
        "name_nl": "Laatste Hoekschoppen",
        "name_pl": "Last corner",
        "name_ro": "Last corner",
        "name_ru": "Last corner",
        "name_tr": "Last corner",
        "name_se": "Last corner",
        "name_el": "Last corner",
        "name_es": "Last corner",
        "name_hr": "poslednji korner",
        "name_fr": "Last corner"
    },
    "id_2070^live_match^8": {
        "name_en": "Set Lead After",
        "name_aa": "Set Lead After",
        "name_de": "Satz - In führung nach",
        "name_nl": "Set- Aan de leiding na",
        "name_pl": "Set Lead After",
        "name_ro": "Set Lead After",
        "name_ru": "Set Lead After",
        "name_tr": "Set Lead After",
        "name_se": "Set Lead After",
        "name_el": "Set Lead After",
        "name_es": "Set Lead After",
        "name_hr": "Vodi u setovima poslije",
        "name_fr": "Set Lead After"
    },
    "id_2184^live_match^8": {
        "name_en": "Quarter Total Behinds",
        "name_aa": "Quarter Total Behinds",
        "name_de": "Viertel Gesamthl Behinds",
        "name_nl": "kwart Totaal achterstands",
        "name_pl": "Quarter Total Behinds",
        "name_ro": "Quarter Total Behinds",
        "name_ru": "Quarter Total Behinds",
        "name_tr": "Quarter Total Behinds",
        "name_se": "Quarter Total Behinds",
        "name_el": "Quarter Total Behinds",
        "name_es": "Quarter Total Behinds",
        "name_hr": "Cetvrtina Kraj Behinds",
        "name_fr": "Quarter Total Behinds"
    },
    "id_351^live_match^7": {
        "name_en": "Asian handicap for seventh period",
        "name_aa": "Asian handicap for seventh period",
        "name_de": "Asian Handicap für 7. Abschnitt",
        "name_nl": "Asian handicap voor zevende period",
        "name_pl": "Asian handicap for seventh period",
        "name_ro": "Asian handicap for seventh period",
        "name_ru": "Asian handicap for seventh period",
        "name_tr": "Yedinci periyot için asya handikapı",
        "name_se": "Asian handicap for seventh period",
        "name_el": "Asian handicap for seventh period",
        "name_es": "Asian handicap for seventh period",
        "name_hr": "Azijski hendikep za sedmi period",
        "name_fr": "Handicap asiatique pour la septième période"
    },
    "id_2038^live_match^4": {
        "name_en": "Corner Handicap",
        "name_aa": "Corner Handicap",
        "name_de": "Ecken im Handicap",
        "name_nl": "Hoekschoppen Handicap",
        "name_pl": "Corner Handicap",
        "name_ro": "Corner Handicap",
        "name_ru": "Corner Handicap",
        "name_tr": "Corner Handicap",
        "name_se": "Corner Handicap",
        "name_el": "Corner Handicap",
        "name_es": "Corner Handicap",
        "name_hr": "Kornera hendikep",
        "name_fr": "Corner Handicap"
    },
    "id_2113^live_match^8": {
        "name_en": "Total Sets",
        "name_aa": "Total Sets",
        "name_de": "Gesamthl satzs",
        "name_nl": "Totaal Sets",
        "name_pl": "Total Sets",
        "name_ro": "Total Sets",
        "name_ru": "Total Sets",
        "name_tr": "Total Sets",
        "name_se": "Total Sets",
        "name_el": "Total Sets",
        "name_es": "Total Sets",
        "name_hr": "Ukupno Setova",
        "name_fr": "Total Sets"
    },
    "id_2250^live_match^8": {
        "name_en": "Back to Back Hits in the Current Half Inning - Awayteam Inning",
        "name_aa": "Back to Back Hits in the Current Half Inning - Awayteam Inning",
        "name_de": "Back to Back Hits in the Current Half Inning - Awayteam Inning",
        "name_nl": "Back to Back Hits in the Current Half Inning - Awayteam Inning",
        "name_pl": "Back to Back Hits in the Current Half Inning - Awayteam Inning",
        "name_ro": "Back to Back Hits in the Current Half Inning - Awayteam Inning",
        "name_ru": "Back to Back Hits in the Current Half Inning - Awayteam Inning",
        "name_tr": "Back to Back Hits in the Current Half Inning - Awayteam Inning",
        "name_se": "Back to Back Hits in the Current Half Inning - Awayteam Inning",
        "name_el": "Back to Back Hits in the Current Half Inning - Awayteam Inning",
        "name_es": "Back to Back Hits in the Current Half Inning - Awayteam Inning",
        "name_hr": "Back to Back Hits in the Current Half Inning - Awayteam Inning",
        "name_fr": "Back to Back Hits in the Current Half Inning - Awayteam Inning"
    },
    "id_1324^live_match^7": {
        "name_en": "Race to [games] games of set [periodNr]",
        "name_aa": "Race to [games] games of set [periodNr]",
        "name_de": "Wer erreicht zuerst [games] Game in Satz [set]",
        "name_nl": "Race to [games] games of set [set]",
        "name_pl": "Race to [games] games of set [periodNr]",
        "name_ro": "Race to [games] games of set [periodNr]",
        "name_ru": "Race to [games] games of set [periodNr]",
        "name_tr": "[set] numaralı Set, [games] numaralı Oyun'una kadar Ralliler",
        "name_se": "Race to [games] games of set [periodNr]",
        "name_el": "Race to [games] games of set [periodNr]",
        "name_es": "Race to [games] games of set [periodNr]",
        "name_hr": "Utrka do [games] gemova seta [set]",
        "name_fr": "Atteindre en premier [games] jeux du set [set]"
    },
    "id_2032^live_match^8": {
        "name_en": "1st Half Result and Total",
        "name_aa": "1st Half Result and Total",
        "name_de": "1. Halfen ErgebnisUnd Gesamthl",
        "name_nl": "1e Helft Resultaat and totaal",
        "name_pl": "1st Half Result and Total",
        "name_ro": "1st Half Result and Total",
        "name_ru": "1st Half Result and Total",
        "name_tr": "1st Half Result and Total",
        "name_se": "1st Half Result and Total",
        "name_el": "1st Half Result and Total",
        "name_es": "1st Half Result and Total",
        "name_hr": "1st Poluvrijeme Rezultiratiand Kraj",
        "name_fr": "1st Half Result and Total"
    },
    "id_2322^live_match^8": {
        "name_en": "Player1 Total Double Faults",
        "name_aa": "Player1 Total Double Faults",
        "name_de": "Spieler 1 Gesamtzahl Doppelfehler",
        "name_nl": "Speler 1 Totaal aantal dubbele fouten",
        "name_pl": "Player1 Total Double Faults",
        "name_ro": "Player1 Total Double Faults",
        "name_ru": "Player1 Total Double Faults",
        "name_tr": "Player1 Total Double Faults",
        "name_se": "Player1 Total Double Faults",
        "name_el": "Player1 Total Double Faults",
        "name_es": "Player1 Total Double Faults",
        "name_hr": "Igrač 1 ukupno duplih greski",
        "name_fr": "Player1 Total Double Faults"
    },
    "id_2312^live_match^8": {
        "name_en": "Match Result and Both Players to Win a Set",
        "name_aa": "Match Result and Both Players to Win a Set",
        "name_de": "spiel ErgebnisUnd beide Players zu gewinnen a satz",
        "name_nl": "Wedstrijd Resultaat and Beide Spelers Wint a Set",
        "name_pl": "Match Result and Both Players to Win a Set",
        "name_ro": "Match Result and Both Players to Win a Set",
        "name_ru": "Match Result and Both Players to Win a Set",
        "name_tr": "Match Result and Both Players to Win a Set",
        "name_se": "Match Result and Both Players to Win a Set",
        "name_el": "Match Result and Both Players to Win a Set",
        "name_es": "Match Result and Both Players to Win a Set",
        "name_hr": "Rezultat i oba igraca osvajaju bar po set",
        "name_fr": "Match Result and Both Players to Win a Set"
    },
    "id_14^live_match^6": {
        "name_en": "Next goal (OT only!)",
        "name_aa": "Next goal (OT only!)",
        "name_de": "Nächstes Tor (nur V)",
        "name_nl": "Volgende goal (enkel ET!)",
        "name_pl": "Next goal (OT only!)",
        "name_ro": "Next goal (OT only!)",
        "name_ru": "Next goal (OT only!)",
        "name_tr": "Sonraki gol (Sadece uzatmalar!)",
        "name_se": "Next goal (OT only!)",
        "name_el": "Next goal (OT only!)",
        "name_es": "Next goal (OT only!)",
        "name_hr": "Sljedeći gol (samo produžeci!)",
        "name_fr": "Prochain but (Prolong. seulement !)"
    },
    "id_2099^live_match^8": {
        "name_en": "Awayteam Totals",
        "name_aa": "Awayteam Totals",
        "name_de": "Auswärtsmannschaft Gesamthls",
        "name_nl": "Awayteam Totals",
        "name_pl": "Awayteam Totals",
        "name_ro": "Awayteam Totals",
        "name_ru": "Awayteam Totals",
        "name_tr": "Awayteam Totals",
        "name_se": "Awayteam Totals",
        "name_el": "Awayteam Totals",
        "name_es": "Awayteam Totals",
        "name_hr": "Tim 2 Kraj ukupno",
        "name_fr": "Awayteam Totals"
    },
    "id_44^live_match^7": {
        "name_en": "Asian handicap for first period",
        "name_aa": "Asian handicap for first period",
        "name_de": "Asian Handicap für 1. Abschnitt",
        "name_nl": "Asian handicap voor eerste periode",
        "name_pl": "Asian handicap for first period",
        "name_ro": "Asian handicap for first period",
        "name_ru": "Asian handicap for first period",
        "name_tr": "İlk periyot için Asya Handikabı",
        "name_se": "Asian handicap for first period",
        "name_el": "Asian handicap for first period",
        "name_es": "Asian handicap for first period",
        "name_hr": "Azijski hendikep za prvi period",
        "name_fr": "Handicap asiatique pour la première période"
    },
    "id_2345^live_match^8": {
        "name_en": "Next Minute Free Kick [from]-[to]",
        "name_aa": "Next Minute Free Kick [from]-[to]",
        "name_de": "nächste Minute Freistoß   [from]-[to]",
        "name_nl": "Volgende Minute Vrije trap [from]-[to]",
        "name_pl": "Next Minute Free Kick [from]-[to]",
        "name_ro": "Next Minute Free Kick [from]-[to]",
        "name_ru": "Next Minute Free Kick [from]-[to]",
        "name_tr": "Next Minute Free Kick [from]-[to]",
        "name_se": "Next Minute Free Kick [from]-[to]",
        "name_el": "Next Minute Free Kick [from]-[to]",
        "name_es": "Next Minute Free Kick [from]-[to]",
        "name_hr": "Minuti sljedeceg slobodnog udarca [from]-[do]",
        "name_fr": "Next Minute Free Kick [from]-[to]"
    },
    "id_4^live_match^6": {
        "name_en": "Who wins the rest of the match?",
        "name_aa": "Who wins the rest of the match?",
        "name_de": "Wer gewinnt die verbleibende Spielzeit?",
        "name_nl": "wie wint de rest van de wedstrijd?",
        "name_pl": "Who wins the rest of the match?",
        "name_ro": "Who wins the rest of the match?",
        "name_ru": "Who wins the rest of the match?",
        "name_tr": "Maçın kalan kısmını kim kazanır?",
        "name_se": "Who wins the rest of the match?",
        "name_el": "Who wins the rest of the match?",
        "name_es": "Who wins the rest of the match?",
        "name_hr": "Tko dobiva ostatak meča?",
        "name_fr": "Qui va gagner le reste du match ?"
    },
    "id_520^live_match^8": {
        "name_en": "Correct score in set Tennis",
        "name_aa": "Correct score in set Tennis",
        "name_de": "Genaues Ergebnis in Sätzen, Tennis",
        "name_nl": "Correcte score in set Tennis",
        "name_pl": "Correct score in set Tennis",
        "name_ro": "Correct score in set Tennis",
        "name_ru": "Correct score in set Tennis",
        "name_tr": "Tenis setin doğru skoru",
        "name_se": "Correct score in set Tennis",
        "name_el": "Correct score in set Tennis",
        "name_es": "Correct score in set Tennis",
        "name_hr": "Točan Rezultat u setu Tenis",
        "name_fr": "Score correct pour les sets Tennis"
    },
    "id_2133^live_match^8": {
        "name_en": "Hometeam To Score Goals",
        "name_aa": "Hometeam To Score Goals",
        "name_de": "heimmannschaft zu spielstand Tore",
        "name_nl": "Thuisteam Scoren doelpunts",
        "name_pl": "Hometeam To Score Goals",
        "name_ro": "Hometeam To Score Goals",
        "name_ru": "Hometeam To Score Goals",
        "name_tr": "Hometeam To Score Goals",
        "name_se": "Hometeam To Score Goals",
        "name_el": "Hometeam To Score Goals",
        "name_es": "Hometeam To Score Goals",
        "name_hr": "Tim 1 Postize Golova",
        "name_fr": "Hometeam To Score Goals"
    },
    "id_2195^live_match^8": {
        "name_en": "Total Scoring Shots hometeam for Quarter",
        "name_aa": "Total Scoring Shots hometeam for Quarter",
        "name_de": "Gesamthl Scoring Shots heimmannschaft Für Viertel",
        "name_nl": "Totaal Scorende schoten Thuisteam voor kwart",
        "name_pl": "Total Scoring Shots hometeam for Quarter",
        "name_ro": "Total Scoring Shots hometeam for Quarter",
        "name_ru": "Total Scoring Shots hometeam for Quarter",
        "name_tr": "Total Scoring Shots hometeam for Quarter",
        "name_se": "Total Scoring Shots hometeam for Quarter",
        "name_el": "Total Scoring Shots hometeam for Quarter",
        "name_es": "Total Scoring Shots hometeam for Quarter",
        "name_hr": "Kraj Scoring Shots Tim 1 for Cetvrtina",
        "name_fr": "Total Scoring Shots hometeam for Quarter"
    },
    "id_2069^live_match^8": {
        "name_en": "Set Extra Points",
        "name_aa": "Set Extra Points",
        "name_de": "satz Extra Points",
        "name_nl": "Set Extra punten",
        "name_pl": "Set Extra Points",
        "name_ro": "Set Extra Points",
        "name_ru": "Set Extra Points",
        "name_tr": "Set Extra Points",
        "name_se": "Set Extra Points",
        "name_el": "Set Extra Points",
        "name_es": "Set Extra Points",
        "name_hr": "Extra poeni u setu",
        "name_fr": "Set Extra Points"
    },
    "id_325^live_match^8": {
        "name_en": "Halftime - Double chance (1X - 12 - X2)",
        "name_aa": "Halftime - Double chance (1X - 12 - X2)",
        "name_de": "Halbzeit - doppelte Chance (1X - 12 - 2X)",
        "name_nl": "Halftime - Dubbele Kans (1X - 12 - X2)",
        "name_pl": "Halftime - Double chance (1X - 12 - X2)",
        "name_ro": "Halftime - Double chance (1X - 12 - X2)",
        "name_ru": "Halftime - Double chance (1X - 12 - X2)",
        "name_tr": "İlk Yarı - Çifte şans (1X - 12 - x2)",
        "name_se": "Halftime - Double chance (1X - 12 - X2)",
        "name_el": "Halftime - Double chance (1X - 12 - X2)",
        "name_es": "Halftime - Double chance (1X - 12 - X2)",
        "name_hr": "Poluvrijeme - Dvostruka prilika (1X - 12 - X2)",
        "name_fr": "Mi-temps - Double chance (1X - 12 - X2)"
    },
    "id_1366^live_match^7": {
        "name_en": "2nd Half - Draw no Bet, including overtime",
        "name_aa": "2nd Half - Draw no Bet, including overtime",
        "name_de": "2. Halbzeit - Unentschieden keine Wette. inklusive Verlängerung",
        "name_nl": "2de Helft - Gelijkspel geen Weddenschap, inclusief overtime",
        "name_pl": "2nd Half - Draw no Bet, including overtime",
        "name_ro": "2nd Half - Draw no Bet, including overtime",
        "name_ru": "2nd Half - Draw no Bet, including overtime",
        "name_tr": "2. yarı - Beraberlikte iade, uzatmalar dahil",
        "name_se": "2nd Half - Draw no Bet, including overtime",
        "name_el": "2nd Half - Draw no Bet, including overtime",
        "name_es": "2nd Half - Draw no Bet, including overtime",
        "name_hr": "2. Poluvrijeme - Ishod bez neodlučenog, uklj. produžetke",
        "name_fr": "2e Mi-temps - Remboursé si match nul, prolongations incluses"
    },
    "id_2039^live_match^8": {
        "name_en": "Time of Next Goal",
        "name_aa": "Time of Next Goal",
        "name_de": "Zeit des nächste Tor",
        "name_nl": "Tijdstip van volgende doelpunt",
        "name_pl": "Time of Next Goal",
        "name_ro": "Time of Next Goal",
        "name_ru": "Time of Next Goal",
        "name_tr": "Time of Next Goal",
        "name_se": "Time of Next Goal",
        "name_el": "Time of Next Goal",
        "name_es": "Time of Next Goal",
        "name_hr": "Vrijeme Sljedećeg Gola",
        "name_fr": "Time of Next Goal"
    },
    "id_2202^live_match^7": {
        "name_en": "To Win Half",
        "name_aa": "To Win Half",
        "name_de": "zu gewinnen Halfen",
        "name_nl": "Wint Helft",
        "name_pl": "To Win Half",
        "name_ro": "To Win Half",
        "name_ru": "To Win Half",
        "name_tr": "To Win Half",
        "name_se": "To Win Half",
        "name_el": "To Win Half",
        "name_es": "To Win Half",
        "name_hr": "Da pobijediti Poluvrijeme",
        "name_fr": "To Win Half"
    },
    "id_914^live_match^8": {
        "name_en": "Player 1 to win a set",
        "name_aa": "Player 1 to win a set",
        "name_de": "Spieler 1 gewinnt einen Satz",
        "name_nl": "Speler 1 wint een set",
        "name_pl": "Player 1 to win a set",
        "name_ro": "Player 1 to win a set",
        "name_ru": "Player 1 to win a set",
        "name_tr": "Player 1 set kazanır",
        "name_se": "Player 1 to win a set",
        "name_el": "Player 1 to win a set",
        "name_es": "Player 1 to win a set",
        "name_hr": "Igrač 1 osvaja set",
        "name_fr": "Joueur 1 va gagner un set"
    },
    "id_2139^live_match^8": {
        "name_en": "Awayteam Odd/Even",
        "name_aa": "Awayteam Odd/Even",
        "name_de": "Auswärtsmannschaft Ungerada/gerade",
        "name_nl": "Uitteam even/oneven",
        "name_pl": "Awayteam Odd/Even",
        "name_ro": "Awayteam Odd/Even",
        "name_ru": "Awayteam Odd/Even",
        "name_tr": "Awayteam Odd/Even",
        "name_se": "Awayteam Odd/Even",
        "name_el": "Awayteam Odd/Even",
        "name_es": "Awayteam Odd/Even",
        "name_hr": "Tim 2 nepar/par",
        "name_fr": "Awayteam Odd/Even"
    },
    "id_2212^live_match^8": {
        "name_en": "Half Winning Margin 9-Way",
        "name_aa": "Half Winning Margin 9-Way",
        "name_de": "Halfen Siegesvorsprung 9-Way",
        "name_nl": "Helft Winstmarge 9-opties",
        "name_pl": "Half Winning Margin 9-Way",
        "name_ro": "Half Winning Margin 9-Way",
        "name_ru": "Half Winning Margin 9-Way",
        "name_tr": "Half Winning Margin 9-Way",
        "name_se": "Half Winning Margin 9-Way",
        "name_el": "Half Winning Margin 9-Way",
        "name_es": "Half Winning Margin 9-Way",
        "name_hr": "Poluvrijeme Pobjeda Razlikom9-Way",
        "name_fr": "Half Winning Margin 9-Way"
    },
    "id_2118^live_match^6": {
        "name_en": "Next Two Games",
        "name_aa": "Next Two Games",
        "name_de": "nächste Two spiel s",
        "name_nl": "Volgende twee games",
        "name_pl": "Next Two Games",
        "name_ro": "Next Two Games",
        "name_ru": "Next Two Games",
        "name_tr": "Next Two Games",
        "name_se": "Next Two Games",
        "name_el": "Next Two Games",
        "name_es": "Next Two Games",
        "name_hr": "Sljedeća dva gema",
        "name_fr": "Next Two Games"
    },
    "id_2044^live_match^8": {
        "name_en": "Match Shots",
        "name_aa": "Match Shots",
        "name_de": "Spiel - Schüsse",
        "name_nl": "Wedstrijd schoten",
        "name_pl": "Match Shots",
        "name_ro": "Match Shots",
        "name_ru": "Match Shots",
        "name_tr": "Match Shots",
        "name_se": "Match Shots",
        "name_el": "Match Shots",
        "name_es": "Match Shots",
        "name_hr": "Utakmica udarci",
        "name_fr": "Match Shots"
    },
    "id_75^live_match^7": {
        "name_en": "Which team wins race to X points, fifth set?",
        "name_aa": "Which team wins race to X points, fifth set?",
        "name_de": "Wer erreicht zuerst x Punkte im 5. Satz?",
        "name_nl": "Welk team wint de race tot X punten, vijfde set?",
        "name_pl": "Which team wins race to X points, fifth set?",
        "name_ro": "Which team wins race to X points, fifth set?",
        "name_ru": "Which team wins race to X points, fifth set?",
        "name_tr": "Beşinci sette X sayıya hangi takım önce ulaşır?",
        "name_se": "Which team wins race to X points, fifth set?",
        "name_el": "Which team wins race to X points, fifth set?",
        "name_es": "Which team wins race to X points, fifth set?",
        "name_hr": "Koji tim će prvi doći do X poena, peti set?",
        "name_fr": "Quelle équipe atteindra en premier X points, cinquième set ?"
    },
    "id_2214^live_match^8": {
        "name_en": "Winning Margin 5-Way",
        "name_aa": "Winning Margin 5-Way",
        "name_de": "Siegesvorsprung 5-Way",
        "name_nl": "Winstmarge 5-opties",
        "name_pl": "Winning Margin 5-Way",
        "name_ro": "Winning Margin 5-Way",
        "name_ru": "Winning Margin 5-Way",
        "name_tr": "Winning Margin 5-Way",
        "name_se": "Winning Margin 5-Way",
        "name_el": "Winning Margin 5-Way",
        "name_es": "Winning Margin 5-Way",
        "name_hr": "Pobjeda Razlikom 5-Way",
        "name_fr": "Winning Margin 5-Way"
    },
    "id_355^live_match^7": {
        "name_en": "Which team wins race to X points, sixth set?",
        "name_aa": "Which team wins race to X points, sixth set?",
        "name_de": "Wer erreicht zuerst x Punkte im 6. Satz?",
        "name_nl": "Welk team wint race naar X aantal punten, zesde set?",
        "name_pl": "Which team wins race to X points, sixth set?",
        "name_ro": "Which team wins race to X points, sixth set?",
        "name_ru": "Which team wins race to X points, sixth set?",
        "name_tr": "Altıncı sette X sayıya hangi takım önce ulaşır?",
        "name_se": "Which team wins race to X points, sixth set?",
        "name_el": "Which team wins race to X points, sixth set?",
        "name_es": "Which team wins race to X points, sixth set?",
        "name_hr": "Koji tim će prvi doći do X poena, šesti set?",
        "name_fr": "Quelle équipe atteindra en premier X points, sixième set ?"
    },
    "id_70^live_match^8": {
        "name_en": "Total for fifth period",
        "name_aa": "Total for fifth period",
        "name_de": "Anzahl Punkte 5. Abschnitt",
        "name_nl": "Totaal voor vijfde periode",
        "name_pl": "Total for fifth period",
        "name_ro": "Total for fifth period",
        "name_ru": "Total for fifth period",
        "name_tr": "Beşinci periyodun toplamı",
        "name_se": "Total for fifth period",
        "name_el": "Total for fifth period",
        "name_es": "Total for fifth period",
        "name_hr": "Total za peti period",
        "name_fr": "Total pour la cinquième période"
    },
    "id_2342^live_match^8": {
        "name_en": "Next Minute Penalty Awarded [from]-[to]",
        "name_aa": "Next Minute Penalty Awarded [from]-[to]",
        "name_de": "nächste Minute Elfmeter Awarded [from]-[to]",
        "name_nl": "Volgende Minute Strafschoppen Awarded [from]-[to]",
        "name_pl": "Next Minute Penalty Awarded [from]-[to]",
        "name_ro": "Next Minute Penalty Awarded [from]-[to]",
        "name_ru": "Next Minute Penalty Awarded [from]-[to]",
        "name_tr": "Next Minute Penalty Awarded [from]-[to]",
        "name_se": "Next Minute Penalty Awarded [from]-[to]",
        "name_el": "Next Minute Penalty Awarded [from]-[to]",
        "name_es": "Next Minute Penalty Awarded [from]-[to]",
        "name_hr": "Minuti sljedeceg penala [from]-[to]",
        "name_fr": "Next Minute Penalty Awarded [from]-[to]"
    },
    "id_2244^live_match^8": {
        "name_en": "Race to (Runs)",
        "name_aa": "Race to (Runs)",
        "name_de": "Zuerst (Runs)",
        "name_nl": "Race naar (Runs)",
        "name_pl": "Race to (Runs)",
        "name_ro": "Race to (Runs)",
        "name_ru": "Race to (Runs)",
        "name_tr": "Race to (Runs)",
        "name_se": "Race to (Runs)",
        "name_el": "Race to (Runs)",
        "name_es": "Race to (Runs)",
        "name_hr": "Trka do (Runs)",
        "name_fr": "Race to (Runs)"
    },
    "id_60^live_match^8": {
        "name_en": "Odd/Even for fourth period",
        "name_aa": "Odd/Even for fourth period",
        "name_de": "Gerade/Ungerade 4. Abschnitt",
        "name_nl": "Oneven/Even voor vierde periode",
        "name_pl": "Odd/Even for fourth period",
        "name_ro": "Odd/Even for fourth period",
        "name_ru": "Odd/Even for fourth period",
        "name_tr": "Dördüncü periyot için Tek/Çift",
        "name_se": "Odd/Even for fourth period",
        "name_el": "Odd/Even for fourth period",
        "name_es": "Odd/Even for fourth period",
        "name_hr": "Nepar/Par za četvrti period",
        "name_fr": "Pair/Impair pour la quatrième période"
    },
    "id_1368^live_match^7": {
        "name_en": "2nd Half - Asian handicap, including overtime",
        "name_aa": "2nd Half - Asian handicap, including overtime",
        "name_de": "2. Halbzeit - Asiatisches Handicap inklusive Verlängerung",
        "name_nl": "2de Helft - Asian handicap, inclusief overtime",
        "name_pl": "2nd Half - Asian handicap, including overtime",
        "name_ro": "2nd Half - Asian handicap, including overtime",
        "name_ru": "2nd Half - Asian handicap, including overtime",
        "name_tr": "2. yarı - Asya handikapı,uzatmalar dahil",
        "name_se": "2nd Half - Asian handicap, including overtime",
        "name_el": "2nd Half - Asian handicap, including overtime",
        "name_es": "2nd Half - Asian handicap, including overtime",
        "name_hr": "2. Poluvrijeme - Azijski hendikep, uklj. produžetke",
        "name_fr": "2e Mi-temps - Handicap asiatique, prolongations incluses"
    },
    "id_28^live_match^8": {
        "name_en": "Goals home team",
        "name_aa": "Goals home team",
        "name_de": "Tore Gastgeber",
        "name_nl": "Doelpunten thuisploeg",
        "name_pl": "Goals home team",
        "name_ro": "Goals home team",
        "name_ru": "Goals home team",
        "name_tr": "Ev sahibi takım golleri",
        "name_se": "Goals home team",
        "name_el": "Goals home team",
        "name_es": "Goals home team",
        "name_hr": "Golovi Domaćin",
        "name_fr": "Buts équipe à domicile"
    },
    "id_2119^live_match^8": {
        "name_en": "Next Two Games - Either Game to Deuce",
        "name_aa": "Next Two Games - Either Game to Deuce",
        "name_de": "nächste Two spiel s - Either spiel to Deuce",
        "name_nl": "Volgende Two Games - Een Game to Deuce",
        "name_pl": "Next Two Games - Either Game to Deuce",
        "name_ro": "Next Two Games - Either Game to Deuce",
        "name_ru": "Next Two Games - Either Game to Deuce",
        "name_tr": "Next Two Games - Either Game to Deuce",
        "name_se": "Next Two Games - Either Game to Deuce",
        "name_el": "Next Two Games - Either Game to Deuce",
        "name_es": "Next Two Games - Either Game to Deuce",
        "name_hr": "Sljedeća 2 gema - barem 1 deuce",
        "name_fr": "Next Two Games - Either Game to Deuce"
    },
    "id_63^live_match^8": {
        "name_en": "Will there be overtime?",
        "name_aa": "Will there be overtime?",
        "name_de": "Wird es eine Verlängerung geben?",
        "name_nl": "Zal er extra tijd zijn ?",
        "name_pl": "Will there be overtime?",
        "name_ro": "Will there be overtime?",
        "name_ru": "Will there be overtime?",
        "name_tr": "Uzatmaya gider mi?",
        "name_se": "Will there be overtime?",
        "name_el": "Will there be overtime?",
        "name_es": "Will there be overtime?",
        "name_hr": "Da li će biti produžetaka?",
        "name_fr": "Y aura-t-il des prolongations ?"
    },
    "id_2045^live_match^8": {
        "name_en": "Extra Time - 1st Half Goals",
        "name_aa": "Extra Time - 1st Half Goals",
        "name_de": "Extra Zeit - 1. Halfen Tore",
        "name_nl": "Verlenging - 1e Helft doelpunten",
        "name_pl": "Extra Time - 1st Half Goals",
        "name_ro": "Extra Time - 1st Half Goals",
        "name_ru": "Extra Time - 1st Half Goals",
        "name_tr": "Extra Time - 1st Half Goals",
        "name_se": "Extra Time - 1st Half Goals",
        "name_el": "Extra Time - 1st Half Goals",
        "name_es": "Extra Time - 1st Half Goals",
        "name_hr": "Produzeci - 1. Poluvrijeme Golova",
        "name_fr": "Extra Time - 1st Half Goals"
    },
    "id_2107^live_match^8": {
        "name_en": "Team to Score Last in Regulation",
        "name_aa": "Team to Score Last in Regulation",
        "name_de": "Team to Score Last in Regulation",
        "name_nl": "Team to Score Last in Regulation",
        "name_pl": "Team to Score Last in Regulation",
        "name_ro": "Team to Score Last in Regulation",
        "name_ru": "Team to Score Last in Regulation",
        "name_tr": "Team to Score Last in Regulation",
        "name_se": "Team to Score Last in Regulation",
        "name_el": "Team to Score Last in Regulation",
        "name_es": "Team to Score Last in Regulation",
        "name_hr": "Poslijednji gol na mecu (90 min)",
        "name_fr": "Team to Score Last in Regulation"
    },
    "id_930^live_match^7": {
        "name_en": "Asian handicap [handicap] for [setNr!] set",
        "name_aa": "Asian handicap [handicap] for [setNr!] set",
        "name_de": "Asian Handicap [handicap] für [setNr!] Satz",
        "name_nl": "Asian handicap [handicap] voor [setNr!] set",
        "name_pl": "Asian handicap [handicap] for [setNr!] set",
        "name_ro": "Asian handicap [handicap] for [setNr!] set",
        "name_ru": "Asian handicap [handicap] for [setNr!] set",
        "name_tr": "[setNr!] seti için asya handikapı [handicap]",
        "name_se": "Asian handicap [handicap] for [setNr!] set",
        "name_el": "Asian handicap [handicap] for [setNr!] set",
        "name_es": "Asian handicap [handicap] for [setNr!] set",
        "name_hr": "Azijski Hendikep [handicap] za [setNr!] set",
        "name_fr": "Handicap asiatique [handicap] pour le [setNr!] set"
    },
    "id_2166^live_match^8": {
        "name_en": "Straight Sets Winner?",
        "name_aa": "Straight Sets Winner?",
        "name_de": "Gewinnt ohne Satzverlust",
        "name_nl": "Wint in straight sets",
        "name_pl": "Straight Sets Winner?",
        "name_ro": "Straight Sets Winner?",
        "name_ru": "Straight Sets Winner?",
        "name_tr": "Straight Sets Winner?",
        "name_se": "Straight Sets Winner?",
        "name_el": "Straight Sets Winner?",
        "name_es": "Straight Sets Winner?",
        "name_hr": "Pobjednik?",
        "name_fr": "Straight Sets Winner?"
    },
    "id_1202^live_match^7": {
        "name_en": "[frame!] frame - Player to pot first ball",
        "name_aa": "[frame!] frame - Player to pot first ball",
        "name_de": "[frame!] Frame - Spieler, der die erste Kugel versenkt",
        "name_nl": "[frame!] frame - Speler die eerste bal pot",
        "name_pl": "[frame!] frame - Player to pot first ball",
        "name_ro": "[frame!] frame - Player to pot first ball",
        "name_ru": "[frame!] frame - Player to pot first ball",
        "name_tr": "[frame!] frame  - İlk topa vuracak oyuncu",
        "name_se": "[frame!] frame - Player to pot first ball",
        "name_el": "[frame!] frame - Player to pot first ball",
        "name_es": "[frame!] frame - Player to pot first ball",
        "name_hr": "[frame!] frameova - Igrač ubacuje prvu kuglu",
        "name_fr": "[frame!] manche - Joueur qui va empocher la première boule"
    },
    "id_1066^live_match^8": {
        "name_en": "Correct score for [periodNr!] period",
        "name_aa": "Correct score for [periodNr!] period",
        "name_de": "Genaues Ergebnis für den [periodNr!] Abschnitt",
        "name_nl": "Correcte score voor [periodNr!] periode",
        "name_pl": "Correct score for [periodNr!] period",
        "name_ro": "Correct score for [periodNr!] period",
        "name_ru": "Correct score for [periodNr!] period",
        "name_tr": "[periodNr!] numaralı periyodun doğru skoru",
        "name_se": "Correct score for [periodNr!] period",
        "name_el": "Correct score for [periodNr!] period",
        "name_es": "Correct score for [periodNr!] period",
        "name_hr": "Točan rezultat u [periodNr!] periodu",
        "name_fr": "Score correct pour la [periodNr!] période"
    },
    "id_2063^live_match^8": {
        "name_en": "Winning Margin",
        "name_aa": "Winning Margin",
        "name_de": "Siegesvorsprung",
        "name_nl": "Winstmarge",
        "name_pl": "Winning Margin",
        "name_ro": "Winning Margin",
        "name_ru": "Winning Margin",
        "name_tr": "Winning Margin",
        "name_se": "Winning Margin",
        "name_el": "Winning Margin",
        "name_es": "Winning Margin",
        "name_hr": "Pobjeda Razlikom",
        "name_fr": "Winning Margin"
    },
    "id_2037^live_match^8": {
        "name_en": "Total [total] Throw-Ins in 10 minutes interval [from]-[to]",
        "name_aa": "Total [total] Throw-Ins in 10 minutes interval [from]-[to]",
        "name_de": "Total [total] Throw-Ins in 10 minutes interval [from]-[to]",
        "name_nl": "Total [total] Throw-Ins in 10 minutes interval [from]-[to]",
        "name_pl": "Total [total] Throw-Ins in 10 minutes interval [from]-[to]",
        "name_ro": "Total [total] Throw-Ins in 10 minutes interval [from]-[to]",
        "name_ru": "Total [total] Throw-Ins in 10 minutes interval [from]-[to]",
        "name_tr": "Total [total] Throw-Ins in 10 minutes interval [from]-[to]",
        "name_se": "Total [total] Throw-Ins in 10 minutes interval [from]-[to]",
        "name_el": "Total [total] Throw-Ins in 10 minutes interval [from]-[to]",
        "name_es": "Total [total] Throw-Ins in 10 minutes interval [from]-[to]",
        "name_hr": "Kraj [total] ubacivanja sa strane u 10 min. intervalu[from]-[to]",
        "name_fr": "Total [total] Throw-Ins in 10 minutes interval [from]-[to]"
    },
    "id_22^live_match^6": {
        "name_en": "Halftime - 3way",
        "name_aa": "Halftime - 3way",
        "name_de": "3 Weg - Halbzeit",
        "name_nl": "3 weg - rust",
        "name_pl": "Halftime - 3way",
        "name_ro": "Halftime - 3way",
        "name_ru": "Halftime - 3way",
        "name_tr": "İlk Yarı - İlk Yarı sonucu",
        "name_se": "Halftime - 3way",
        "name_el": "Halftime - 3way",
        "name_es": "Halftime - 3way",
        "name_hr": "Poluvrijeme - 1X2",
        "name_fr": "Mi-temps - 1x2"
    },
    "id_2083^live_match^8": {
        "name_en": "Match Result and Total Games",
        "name_aa": "Match Result and Total Games",
        "name_de": "spiel ErgebnisUnd Gesamthl spiel s",
        "name_nl": "Wedstrijd Resultaat and Totaal Games",
        "name_pl": "Match Result and Total Games",
        "name_ro": "Match Result and Total Games",
        "name_ru": "Match Result and Total Games",
        "name_tr": "Match Result and Total Games",
        "name_se": "Match Result and Total Games",
        "name_el": "Match Result and Total Games",
        "name_es": "Match Result and Total Games",
        "name_hr": "Utakmica Rezultat i ukupno gemova",
        "name_fr": "Match Result and Total Games"
    },
    "id_18^live_match^8": {
        "name_en": "Final Result (in sets - best of 3)",
        "name_aa": "Final Result (in sets - best of 3)",
        "name_de": "Endergebnis (in Sätzen - Best of 3)",
        "name_nl": "Definitief resultaat (In aantal set - best of 3)",
        "name_pl": "Final Result (in sets - best of 3)",
        "name_ro": "Final Result (in sets - best of 3)",
        "name_ru": "Final Result (in sets - best of 3)",
        "name_tr": "Nihai Sonuç (setlerde - 3 set üzerinden)",
        "name_se": "Final Result (in sets - best of 3)",
        "name_el": "Final Result (in sets - best of 3)",
        "name_es": "Final Result (in sets - best of 3)",
        "name_hr": "Finalni rezultat (u setovima - najbolji u 3)",
        "name_fr": "Résultat Final (en sets - au meilleur des 3 manches)"
    },
    "id_2126^live_match^8": {
        "name_en": "Period Winning Margin",
        "name_aa": "Period Winning Margin",
        "name_de": "drittel Siegesvorsprung",
        "name_nl": "Periode Winstmarge",
        "name_pl": "Period Winning Margin",
        "name_ro": "Period Winning Margin",
        "name_ru": "Period Winning Margin",
        "name_tr": "Period Winning Margin",
        "name_se": "Period Winning Margin",
        "name_el": "Period Winning Margin",
        "name_es": "Period Winning Margin",
        "name_hr": "Period Pobjeda Razlikom",
        "name_fr": "Period Winning Margin"
    },
    "id_94^live_match^8": {
        "name_en": "Odd/Even number of games",
        "name_aa": "Odd/Even number of games",
        "name_de": "Gerade/Ungerade Anzahl an Spielen",
        "name_nl": "Oneven/Even aantal games",
        "name_pl": "Odd/Even number of games",
        "name_ro": "Odd/Even number of games",
        "name_ru": "Odd/Even number of games",
        "name_tr": "Oyun sayısı Tek/Çift",
        "name_se": "Odd/Even number of games",
        "name_el": "Odd/Even number of games",
        "name_es": "Odd/Even number of games",
        "name_hr": "Nepar/Par za broj gemova",
        "name_fr": "Pair/Impair nombre de jeux"
    },
    "id_27^live_match^8": {
        "name_en": "Double chance (1X - 12 - X2)",
        "name_aa": "Double chance (1X - 12 - X2)",
        "name_de": "Doppelte Chance (1X-12-X2)",
        "name_nl": "Dubbele chance (1X - 12 - X2 )",
        "name_pl": "Double chance (1X - 12 - X2)",
        "name_ro": "Double chance (1X - 12 - X2)",
        "name_ru": "Double chance (1X - 12 - X2)",
        "name_tr": "Çifte şans (1X - 12 - X2)",
        "name_se": "Double chance (1X - 12 - X2)",
        "name_el": "Double chance (1X - 12 - X2)",
        "name_es": "Double chance (1X - 12 - X2)",
        "name_hr": "Dvostruka Prilika (1X - 12 - X2)",
        "name_fr": "Double chance (1X - 12 - X2)"
    },
    "id_30^live_match^8": {
        "name_en": "Both teams to score",
        "name_aa": "Both teams to score",
        "name_de": "Beide Mannschaften treffen",
        "name_nl": "Beide teams scoren",
        "name_pl": "Both teams to score",
        "name_ro": "Both teams to score",
        "name_ru": "Both teams to score",
        "name_tr": "Karşılıklı gol",
        "name_se": "Both teams to score",
        "name_el": "Both teams to score",
        "name_es": "Both teams to score",
        "name_hr": "Oba daju gol",
        "name_fr": "Les deux équipes qui marquent"
    },
    "id_2142^live_match^8": {
        "name_en": "1st Half Winning Margin",
        "name_aa": "1st Half Winning Margin",
        "name_de": "1. Halfen Siegesvorsprung",
        "name_nl": "1e Helft Winstmarge",
        "name_pl": "1st Half Winning Margin",
        "name_ro": "1st Half Winning Margin",
        "name_ru": "1st Half Winning Margin",
        "name_tr": "1st Half Winning Margin",
        "name_se": "1st Half Winning Margin",
        "name_el": "1st Half Winning Margin",
        "name_es": "1st Half Winning Margin",
        "name_hr": "1. Poluvrijeme Pobjeda Razlikom",
        "name_fr": "1st Half Winning Margin"
    },
    "id_2331^live_match^7": {
        "name_en": "Next Break of Serve",
        "name_aa": "Next Break of Serve",
        "name_de": "nächste breaks",
        "name_nl": "Volgende Break of Serve",
        "name_pl": "Next Break of Serve",
        "name_ro": "Next Break of Serve",
        "name_ru": "Next Break of Serve",
        "name_tr": "Next Break of Serve",
        "name_se": "Next Break of Serve",
        "name_el": "Next Break of Serve",
        "name_es": "Next Break of Serve",
        "name_hr": "Sljedeći Break na servisu",
        "name_fr": "Next Break of Serve"
    },
    "id_2158^live_match^8": {
        "name_en": "Game Extra Points",
        "name_aa": "Game Extra Points",
        "name_de": "Spiel Extra Points",
        "name_nl": "Game Extra punten",
        "name_pl": "Game Extra Points",
        "name_ro": "Game Extra Points",
        "name_ru": "Game Extra Points",
        "name_tr": "Game Extra Points",
        "name_se": "Game Extra Points",
        "name_el": "Game Extra Points",
        "name_es": "Game Extra Points",
        "name_hr": "Igra Extra poeni",
        "name_fr": "Game Extra Points"
    },
    "id_2131^live_match^7": {
        "name_en": "Next Goal",
        "name_aa": "Next Goal",
        "name_de": "nächste Tor",
        "name_nl": "Volgend doelpunt",
        "name_pl": "Next Goal",
        "name_ro": "Next Goal",
        "name_ru": "Next Goal",
        "name_tr": "Next Goal",
        "name_se": "Next Goal",
        "name_el": "Next Goal",
        "name_es": "Next Goal",
        "name_hr": "Sljedeći Gol",
        "name_fr": "Next Goal"
    },
    "id_2092^live_match^8": {
        "name_en": "Player 1 To Win in Straight Sets",
        "name_aa": "Player 1 To Win in Straight Sets",
        "name_de": "Player 1 zu gewinnen in Straight satzs",
        "name_nl": "Speler 1 Wint in Straight Sets",
        "name_pl": "Player 1 To Win in Straight Sets",
        "name_ro": "Player 1 To Win in Straight Sets",
        "name_ru": "Player 1 To Win in Straight Sets",
        "name_tr": "Player 1 To Win in Straight Sets",
        "name_se": "Player 1 To Win in Straight Sets",
        "name_el": "Player 1 To Win in Straight Sets",
        "name_es": "Player 1 To Win in Straight Sets",
        "name_hr": "Igrač 1 dobija setove za redom",
        "name_fr": "Player 1 To Win in Straight Sets"
    },
    "id_2134^live_match^8": {
        "name_en": "Awayteam To Score Goals",
        "name_aa": "Awayteam To Score Goals",
        "name_de": "Auswärtsmannschaft zu spielstand Tore",
        "name_nl": "Uitteam Scoren doelpunts",
        "name_pl": "Awayteam To Score Goals",
        "name_ro": "Awayteam To Score Goals",
        "name_ru": "Awayteam To Score Goals",
        "name_tr": "Awayteam To Score Goals",
        "name_se": "Awayteam To Score Goals",
        "name_el": "Awayteam To Score Goals",
        "name_es": "Awayteam To Score Goals",
        "name_hr": "Tim 2 Postize Golova",
        "name_fr": "Awayteam To Score Goals"
    },
    "id_17^live_match^6": {
        "name_en": "Who wins the period?",
        "name_aa": "Who wins the period?",
        "name_de": "Wer gewinnt den Abschnitt?",
        "name_nl": "Wie wint de periode?",
        "name_pl": "Who wins the period?",
        "name_ro": "Who wins the period?",
        "name_ru": "Who wins the period?",
        "name_tr": "Periyodu kim kazanır?",
        "name_se": "Who wins the period?",
        "name_el": "Who wins the period?",
        "name_es": "Who wins the period?",
        "name_hr": "Tko dobiva period?",
        "name_fr": "Qui va gagner la période ?"
    },
    "id_2040^live_match^8": {
        "name_en": "Time of Next Goal - Hometeam",
        "name_aa": "Time of Next Goal - Hometeam",
        "name_de": "Zeit des nächste Tor - heimmannschaft",
        "name_nl": "Tijdstip van volgende doelpunt - Thuisteam",
        "name_pl": "Time of Next Goal - Hometeam",
        "name_ro": "Time of Next Goal - Hometeam",
        "name_ru": "Time of Next Goal - Hometeam",
        "name_tr": "Time of Next Goal - Hometeam",
        "name_se": "Time of Next Goal - Hometeam",
        "name_el": "Time of Next Goal - Hometeam",
        "name_es": "Time of Next Goal - Hometeam",
        "name_hr": "Vrijeme Sljedećeg Gola- Tim 1",
        "name_fr": "Time of Next Goal - Hometeam"
    },
    "id_1224^live_match^8": {
        "name_en": "[frame!] frame - Any player with a break 100+",
        "name_aa": "[frame!] frame - Any player with a break 100+",
        "name_de": "[frame!] Frame - Irgendein Spieler mit einem Break 100+",
        "name_nl": "[frame!] frame - Speler met een break 100+",
        "name_pl": "[frame!] frame - Any player with a break 100+",
        "name_ro": "[frame!] frame - Any player with a break 100+",
        "name_ru": "[frame!] frame - Any player with a break 100+",
        "name_tr": "[frame!] Çerçeve - Herhangi bir oyuncu kırarak 100+",
        "name_se": "[frame!] frame - Any player with a break 100+",
        "name_el": "[frame!] frame - Any player with a break 100+",
        "name_es": "[frame!] frame - Any player with a break 100+",
        "name_hr": "[frame!] frameova - Hoće li neki igrač napraviti break 100+",
        "name_fr": "[frame!] Manche - Un des joueurs avec un break de 100 ou +"
    },
    "id_2313^live_match^7": {
        "name_en": "Match Tie Break Handicap",
        "name_aa": "Match Tie Break Handicap",
        "name_de": "spiel Tie Break Handicap",
        "name_nl": "Wedstrijd Wedstrijdtiebreak Handicap",
        "name_pl": "Match Tie Break Handicap",
        "name_ro": "Match Tie Break Handicap",
        "name_ru": "Match Tie Break Handicap",
        "name_tr": "Match Tie Break Handicap",
        "name_se": "Match Tie Break Handicap",
        "name_el": "Match Tie Break Handicap",
        "name_es": "Match Tie Break Handicap",
        "name_hr": "Utakmica Tie Break Hendikep",
        "name_fr": "Match Tie Break Handicap"
    },
    "id_76^live_match^7": {
        "name_en": "Asian handicap for fifth period",
        "name_aa": "Asian handicap for fifth period",
        "name_de": "Asian Handicap für 5. Abschnitt",
        "name_nl": "Asian handicap voor vijfde periode",
        "name_pl": "Asian handicap for fifth period",
        "name_ro": "Asian handicap for fifth period",
        "name_ru": "Asian handicap for fifth period",
        "name_tr": "5. periyot için Asya handikabı",
        "name_se": "Asian handicap for fifth period",
        "name_el": "Asian handicap for fifth period",
        "name_es": "Asian handicap for fifth period",
        "name_hr": "Azijski Hendikep za peti period",
        "name_fr": "Handicap asiatique pour la cinquième période"
    },
    "id_2194^live_match^8": {
        "name_en": "Total Behinds awayteam for Quarter",
        "name_aa": "Total Behinds awayteam for Quarter",
        "name_de": "Gesamthl Behinds Auswärtsmannschaft Für Viertel",
        "name_nl": "Totaal achterstands Uitteam voor kwart",
        "name_pl": "Total Behinds awayteam for Quarter",
        "name_ro": "Total Behinds awayteam for Quarter",
        "name_ru": "Total Behinds awayteam for Quarter",
        "name_tr": "Total Behinds awayteam for Quarter",
        "name_se": "Total Behinds awayteam for Quarter",
        "name_el": "Total Behinds awayteam for Quarter",
        "name_es": "Total Behinds awayteam for Quarter",
        "name_hr": "Kraj Behinds Tim 2 for Cetvrtina",
        "name_fr": "Total Behinds awayteam for Quarter"
    },
    "id_2242^live_match^7": {
        "name_en": "Match Run Line",
        "name_aa": "Match Run Line",
        "name_de": "spiel handicap",
        "name_nl": "Wedstrijd Handicap",
        "name_pl": "Match Run Line",
        "name_ro": "Match Run Line",
        "name_ru": "Match Run Line",
        "name_tr": "Match Run Line",
        "name_se": "Match Run Line",
        "name_el": "Match Run Line",
        "name_es": "Match Run Line",
        "name_hr": "Utakmica Run Line",
        "name_fr": "Match Run Line"
    },
    "id_874^live_match^8": {
        "name_en": "Total 180s of Player 1",
        "name_aa": "Total 180s of Player 1",
        "name_de": "Gesamtergebnis 180er für Spieler 1",
        "name_nl": "Totaal 180s van Speler 1",
        "name_pl": "Total 180s of Player 1",
        "name_ro": "Total 180s of Player 1",
        "name_ru": "Total 180s of Player 1",
        "name_tr": "Player 1 Toplam 180'ler",
        "name_se": "Total 180s of Player 1",
        "name_el": "Total 180s of Player 1",
        "name_es": "Total 180s of Player 1",
        "name_hr": "Ukupno 180-ki Igrača 1",
        "name_fr": "Nombre total de 180 du Joueur 1"
    },
    "id_73^live_match^7": {
        "name_en": "Which team wins race to X points, third set?",
        "name_aa": "Which team wins race to X points, third set?",
        "name_de": "Wer erreicht zuerst x Punkte im 3. Satz?",
        "name_nl": "Welk team wint de race tot X punten, derde set ?",
        "name_pl": "Which team wins race to X points, third set?",
        "name_ro": "Which team wins race to X points, third set?",
        "name_ru": "Which team wins race to X points, third set?",
        "name_tr": "Üçüncü sette X sayıya hangi takım önce ulaşır?",
        "name_se": "Which team wins race to X points, third set?",
        "name_el": "Which team wins race to X points, third set?",
        "name_es": "Which team wins race to X points, third set?",
        "name_hr": "Koji tim će prvi doći do X poena, treći set?",
        "name_fr": "Quelle équipe atteindra en premier X points, troisième set ?"
    },
    "id_117^live_match^8": {
        "name_en": "Overtime first half Correct score flex",
        "name_aa": "Overtime first half Correct score flex",
        "name_de": "Verlängerung erste Halbzeit genaues Ergebnis",
        "name_nl": "Extra tijd eerste helft Correcte score flex",
        "name_pl": "Overtime first half Correct score flex",
        "name_ro": "Overtime first half Correct score flex",
        "name_ru": "Overtime first half Correct score flex",
        "name_tr": "Uzatmalar İlk Devre Doğru Skor Aralığı",
        "name_se": "Overtime first half Correct score flex",
        "name_el": "Overtime first half Correct score flex",
        "name_es": "Overtime first half Correct score flex",
        "name_hr": "Fleksibilan Točan rezultat u prvom produžetku",
        "name_fr": "1ère Mi-temps des prolongations Score correct flexible"
    },
    "id_2068^live_match^6": {
        "name_en": "4th Quarter Margin of Victory",
        "name_aa": "4th Quarter Margin of Victory",
        "name_de": "4. Viertel Siegesvorsprung",
        "name_nl": "4th kwart Winstmarge",
        "name_pl": "4th Quarter Margin of Victory",
        "name_ro": "4th Quarter Margin of Victory",
        "name_ru": "4th Quarter Margin of Victory",
        "name_tr": "4th Quarter Margin of Victory",
        "name_se": "4th Quarter Margin of Victory",
        "name_el": "4th Quarter Margin of Victory",
        "name_es": "4th Quarter Margin of Victory",
        "name_hr": "Pobjednik 4. cetvrtine sa razlikom",
        "name_fr": "4th Quarter Margin of Victory"
    },
    "id_2226^live_match^8": {
        "name_en": "To Go to Extra Innings",
        "name_aa": "To Go to Extra Innings",
        "name_de": "To Go to Extra Innings",
        "name_nl": "To Go to Extra Innings",
        "name_pl": "To Go to Extra Innings",
        "name_ro": "To Go to Extra Innings",
        "name_ru": "To Go to Extra Innings",
        "name_tr": "To Go to Extra Innings",
        "name_se": "To Go to Extra Innings",
        "name_el": "To Go to Extra Innings",
        "name_es": "To Go to Extra Innings",
        "name_hr": "Extra ining?",
        "name_fr": "To Go to Extra Innings"
    },
    "id_960^live_match^6": {
        "name_en": "3way for fourth period",
        "name_aa": "3way for fourth period",
        "name_de": "3Weg für vierten Abschnitt",
        "name_nl": "3way voor vierde period",
        "name_pl": "3way for fourth period",
        "name_ro": "3way for fourth period",
        "name_ru": "3way for fourth period",
        "name_tr": "Dördüncü periyot için 3 yol",
        "name_se": "3way for fourth period",
        "name_el": "3way for fourth period",
        "name_es": "3way for fourth period",
        "name_hr": "1X2 za četvrti period",
        "name_fr": "1x2 pour la quatrième période"
    },
    "id_924^live_match^7": {
        "name_en": "Game Handicap for set [setNr]",
        "name_aa": "Game Handicap for set [setNr]",
        "name_de": "Handicap Game für Satz [setNr]",
        "name_nl": "Game Handicap voor set [setNr]",
        "name_pl": "Game Handicap for set [setNr]",
        "name_ro": "Game Handicap for set [setNr]",
        "name_ru": "Game Handicap for set [setNr]",
        "name_tr": "[setNr] set için oyun handikapı",
        "name_se": "Game Handicap for set [setNr]",
        "name_el": "Game Handicap for set [setNr]",
        "name_es": "Game Handicap for set [setNr]",
        "name_hr": "Gem Hedikep za set [setNr]",
        "name_fr": "Handicap Jeu pour le set [setNr]"
    },
    "id_2034^live_match^8": {
        "name_en": "Match Result and Total",
        "name_aa": "Match Result and Total",
        "name_de": "spiel ErgebnisUnd Gesamthl",
        "name_nl": "Wedstrijd Resultaat and totaal",
        "name_pl": "Match Result and Total",
        "name_ro": "Match Result and Total",
        "name_ru": "Match Result and Total",
        "name_tr": "Match Result and Total",
        "name_se": "Match Result and Total",
        "name_el": "Match Result and Total",
        "name_es": "Match Result and Total",
        "name_hr": "Utakmica Rezultiratiand Kraj",
        "name_fr": "Match Result and Total"
    },
    "id_2011^live_match^8": {
        "name_en": "1st Quarter - Total awayteam",
        "name_aa": "1st Quarter - Total awayteam",
        "name_de": "1. Viertel - Gesamthl Auswärtsmannschaft",
        "name_nl": "1e kwart - totaal Uitteam",
        "name_pl": "1st Quarter - Total awayteam",
        "name_ro": "1st Quarter - Total awayteam",
        "name_ru": "1st Quarter - Total awayteam",
        "name_tr": "1st Quarter - Total awayteam",
        "name_se": "1st Quarter - Total awayteam",
        "name_el": "1st Quarter - Total awayteam",
        "name_es": "1st Quarter - Total awayteam",
        "name_hr": "1 Cetvrtina - Kraj Tim 2",
        "name_fr": "1st Quarter - Total awayteam"
    },
    "id_2248^live_match^8": {
        "name_en": "Total Runs 3-Way",
        "name_aa": "Total Runs 3-Way",
        "name_de": "Gesamtzahl Runs 3-Weg",
        "name_nl": "Totaal Runs 3 opties",
        "name_pl": "Total Runs 3-Way",
        "name_ro": "Total Runs 3-Way",
        "name_ru": "Total Runs 3-Way",
        "name_tr": "Total Runs 3-Way",
        "name_se": "Total Runs 3-Way",
        "name_el": "Total Runs 3-Way",
        "name_es": "Total Runs 3-Way",
        "name_hr": "Kraj Runs 3-Way",
        "name_fr": "Total Runs 3-Way"
    },
    "id_142^live_match^8": {
        "name_en": "Total hometeam",
        "name_aa": "Total hometeam",
        "name_de": "Total Heimmannschaft",
        "name_nl": "Totaal thuisploeg",
        "name_pl": "Total hometeam",
        "name_ro": "Total hometeam",
        "name_ru": "Total hometeam",
        "name_tr": "Toplam ev sahibi",
        "name_se": "Total hometeam",
        "name_el": "Total hometeam",
        "name_es": "Total hometeam",
        "name_hr": "Total domaćin",
        "name_fr": "Total équipe à domicile"
    },
    "id_1156^live_match^8": {
        "name_en": "Soccer Penalty shootout - Correct score",
        "name_aa": "Soccer Penalty shootout - Correct score",
        "name_de": "Fußball Elfmeterschießen - Genaues Ergebnis",
        "name_nl": "Voetbal Strafschoppenreeks - Correcte Score",
        "name_pl": "Soccer Penalty shootout - Correct score",
        "name_ro": "Soccer Penalty shootout - Correct score",
        "name_ru": "Soccer Penalty shootout - Correct score",
        "name_tr": "Futbol Penaltı Atışları - Doğru Skor",
        "name_se": "Soccer Penalty shootout - Correct score",
        "name_el": "Soccer Penalty shootout - Correct score",
        "name_es": "Soccer Penalty shootout - Correct score",
        "name_hr": "Nogomet Izvođenje penala - Točan Rezultat",
        "name_fr": "Football Séance de tirs au but - Score exact"
    },
    "id_2066^live_match^6": {
        "name_en": "2nd Quarter Margin of Victory",
        "name_aa": "2nd Quarter Margin of Victory",
        "name_de": "2. Viertel Siegesvorsprung",
        "name_nl": "2e kwart Winstmarge",
        "name_pl": "2nd Quarter Margin of Victory",
        "name_ro": "2nd Quarter Margin of Victory",
        "name_ru": "2nd Quarter Margin of Victory",
        "name_tr": "2nd Quarter Margin of Victory",
        "name_se": "2nd Quarter Margin of Victory",
        "name_el": "2nd Quarter Margin of Victory",
        "name_es": "2nd Quarter Margin of Victory",
        "name_hr": "Pobjednik 2. cetvrtine sa razlikom",
        "name_fr": "2nd Quarter Margin of Victory"
    },
    "id_26^live_match^8": {
        "name_en": "Correct score",
        "name_aa": "Correct score",
        "name_de": "Genaues Ergebnis",
        "name_nl": "Juiste uitslag",
        "name_pl": "Correct score",
        "name_ro": "Correct score",
        "name_ru": "Correct score",
        "name_tr": "Doğru skor",
        "name_se": "Correct score",
        "name_el": "Correct score",
        "name_es": "Correct score",
        "name_hr": "Točan rezultat",
        "name_fr": "Score correct"
    },
    "id_2364^live_match^8": {
        "name_en": "Combo 1X2 & O/U & GG/NG",
        "name_aa": "Combo 1X2 & O/U & GG/NG",
        "name_de": "Combo 1X2 & O/U & GG/NG",
        "name_nl": "Combo 1X2 & O/U & GG/NG",
        "name_pl": "Combo 1X2 & O/U & GG/NG",
        "name_ro": "Combo 1X2 & O/U & GG/NG",
        "name_ru": "Combo 1X2 & O/U & GG/NG",
        "name_tr": "Combo 1X2 & O/U & GG/NG",
        "name_se": "Combo 1X2 & O/U & GG/NG",
        "name_el": "Combo 1X2 & O/U & GG/NG",
        "name_es": "Combo 1X2 & O/U & GG/NG",
        "name_hr": "Combo 1X2 & O/U & GG/NG",
        "name_fr": "Combo 1X2 & O/U & GG/NG"
    },
    "id_2096^live_match^8": {
        "name_en": "10 Minute Team To Score",
        "name_aa": "10 Minute Team To Score",
        "name_de": "10 Minuten- Team erzielt ein Tor",
        "name_nl": "10 Minuten- Team dat scoort",
        "name_pl": "10 Minute Team To Score",
        "name_ro": "10 Minute Team To Score",
        "name_ru": "10 Minute Team To Score",
        "name_tr": "10 Minute Team To Score",
        "name_se": "10 Minute Team To Score",
        "name_el": "10 Minute Team To Score",
        "name_es": "10 Minute Team To Score",
        "name_hr": "10 tim postize gol",
        "name_fr": "10 Minute Team To Score"
    },
    "id_2341^live_match^8": {
        "name_en": "Next Minute Card [from]-[to]",
        "name_aa": "Next Minute Card [from]-[to]",
        "name_de": "nächste Minute Karte[from]-[to]",
        "name_nl": "Volgende Minuut Kaarten [from]-[to]",
        "name_pl": "Next Minute Card [from]-[to]",
        "name_ro": "Next Minute Card [from]-[to]",
        "name_ru": "Next Minute Card [from]-[to]",
        "name_tr": "Next Minute Card [from]-[to]",
        "name_se": "Next Minute Card [from]-[to]",
        "name_el": "Next Minute Card [from]-[to]",
        "name_es": "Next Minute Card [from]-[to]",
        "name_hr": "Minuti sljedeceg kartona [from]-[to]",
        "name_fr": "Next Minute Card [from]-[to]"
    },
    "id_228^live_match^7": {
        "name_en": "Who advances to next round?",
        "name_aa": "Who advances to next round?",
        "name_de": "Wer kommt in die nächste Runde?",
        "name_nl": "Wie gaat door naar de volgende ronde?",
        "name_pl": "Who advances to next round?",
        "name_ro": "Who advances to next round?",
        "name_ru": "Who advances to next round?",
        "name_tr": "Üst tura kim çıkar?",
        "name_se": "Who advances to next round?",
        "name_el": "Who advances to next round?",
        "name_es": "Who advances to next round?",
        "name_hr": "Tko prolazi u narednu rundu?",
        "name_fr": "Qui accèdera au prochain tour ?"
    },
    "id_49^live_match^7": {
        "name_en": "Draw No Bet for third period",
        "name_aa": "Draw No Bet for third period",
        "name_de": "Sieger - Kein Unentschieden 3. Abschnitt",
        "name_nl": "Draw no Bet voor derde periode",
        "name_pl": "Draw No Bet for third period",
        "name_ro": "Draw No Bet for third period",
        "name_ru": "Draw No Bet for third period",
        "name_tr": "Beraberlikte iade üçüncü periyot",
        "name_se": "Draw No Bet for third period",
        "name_el": "Draw No Bet for third period",
        "name_es": "Draw No Bet for third period",
        "name_hr": "Ishod bez neodlučenog za treći period",
        "name_fr": "Remboursé si Match nul pour la troisième période"
    },
    "id_1218^live_match^8": {
        "name_en": "[frame!] frame - Any player with a break 50+",
        "name_aa": "[frame!] frame - Any player with a break 50+",
        "name_de": "[frame!] Frame - Irgendein Spieler mit einem Break 50+",
        "name_nl": "[frame!] frame - Speler met een break 50+",
        "name_pl": "[frame!] frame - Any player with a break 50+",
        "name_ro": "[frame!] frame - Any player with a break 50+",
        "name_ru": "[frame!] frame - Any player with a break 50+",
        "name_tr": "[frame!] Çerçeve - Herhangi bir oyuncu kırarak 50+",
        "name_se": "[frame!] frame - Any player with a break 50+",
        "name_el": "[frame!] frame - Any player with a break 50+",
        "name_es": "[frame!] frame - Any player with a break 50+",
        "name_hr": "[frame!] frameova - Hoće li neki igrač napraviti break 50+",
        "name_fr": "[frame!] Manche - Un des joueurs avec un break de 50 ou +"
    },
    "id_287^live_match^8": {
        "name_en": "2nd Half - Double chance (1X - 12 - X2)",
        "name_aa": "2nd Half - Double chance (1X - 12 - X2)",
        "name_de": "2. Halbzeit - Doppelte Chance (1X - 12 - X2)",
        "name_nl": "2de Helft - Double chance (1X - 12 - X2)",
        "name_pl": "2nd Half - Double chance (1X - 12 - X2)",
        "name_ro": "2nd Half - Double chance (1X - 12 - X2)",
        "name_ru": "2nd Half - Double chance (1X - 12 - X2)",
        "name_tr": "2.yarı - Çifte şans ( 1X - 12 - X2)",
        "name_se": "2nd Half - Double chance (1X - 12 - X2)",
        "name_el": "2nd Half - Double chance (1X - 12 - X2)",
        "name_es": "2nd Half - Double chance (1X - 12 - X2)",
        "name_hr": "2. Poluvrijeme - Dvostruka Prilika (1X - 12 - X2)",
        "name_fr": "2e Mi-temps - Double chance (1X - 12 - X2)"
    },
    "id_1506^live_match^8": {
        "name_en": "Which team wins race to X corners",
        "name_aa": "Which team wins race to X corners",
        "name_de": "Which team wins race to X corners",
        "name_nl": "Welk team wint race naar X Hoekschoppen",
        "name_pl": "Which team wins race to X corners",
        "name_ro": "Which team wins race to X corners",
        "name_ru": "Which team wins race to X corners",
        "name_tr": "X kornere ilk önce hangi takım ulaşır",
        "name_se": "Which team wins race to X corners",
        "name_el": "Which team wins race to X corners",
        "name_es": "Which team wins race to X corners",
        "name_hr": "Koji tim će prvi doći do X udarca iz kuta",
        "name_fr": "Quelle équipe va atteindre x Corners en premier"
    },
    "id_1276^live_match^8": {
        "name_en": "Total number of games of Player 1",
        "name_aa": "Total number of games of Player 1",
        "name_de": "Anzahl Spiele für Spieler 1",
        "name_nl": "Totaal aantal games van Speler 1",
        "name_pl": "Total number of games of Player 1",
        "name_ro": "Total number of games of Player 1",
        "name_ru": "Total number of games of Player 1",
        "name_tr": "Player 1'in toplam oyun sayısı",
        "name_se": "Total number of games of Player 1",
        "name_el": "Total number of games of Player 1",
        "name_es": "Total number of games of Player 1",
        "name_hr": "Ukupan broj gemova za 1. igrača",
        "name_fr": "Nombre total de jeux du Joueur 1"
    },
    "id_2256^live_match^8": {
        "name_en": "Hit - Awayteam Inning",
        "name_aa": "Hit - Awayteam Inning",
        "name_de": "Hit - Auswärtsmannschaft Inning",
        "name_nl": "Hit - Uitteam Inning",
        "name_pl": "Hit - Awayteam Inning",
        "name_ro": "Hit - Awayteam Inning",
        "name_ru": "Hit - Awayteam Inning",
        "name_tr": "Hit - Awayteam Inning",
        "name_se": "Hit - Awayteam Inning",
        "name_el": "Hit - Awayteam Inning",
        "name_es": "Hit - Awayteam Inning",
        "name_hr": "Hit - Tim 2Inningom",
        "name_fr": "Hit - Awayteam Inning"
    },
    "id_2217^live_match^8": {
        "name_en": "Correct Score After 4 Periods",
        "name_aa": "Correct Score After 4 Periods",
        "name_de": "genaues erzielt After 4 drittel s",
        "name_nl": "Correcte Score na 4 Periods",
        "name_pl": "Correct Score After 4 Periods",
        "name_ro": "Correct Score After 4 Periods",
        "name_ru": "Correct Score After 4 Periods",
        "name_tr": "Correct Score After 4 Periods",
        "name_se": "Correct Score After 4 Periods",
        "name_el": "Correct Score After 4 Periods",
        "name_es": "Correct Score After 4 Periods",
        "name_hr": "Tačan rezultat poslije 4 perioda",
        "name_fr": "Correct Score After 4 Periods"
    },
    "id_916^live_match^8": {
        "name_en": "Player 2 to win a set",
        "name_aa": "Player 2 to win a set",
        "name_de": "Spieler 2 gewinnt einen Satz",
        "name_nl": "Speler 2 wint een set",
        "name_pl": "Player 2 to win a set",
        "name_ro": "Player 2 to win a set",
        "name_ru": "Player 2 to win a set",
        "name_tr": "Player 2 set kazanır",
        "name_se": "Player 2 to win a set",
        "name_el": "Player 2 to win a set",
        "name_es": "Player 2 to win a set",
        "name_hr": "Igrač 2 osvaja set",
        "name_fr": "Joueur 2 va gagner un set"
    },
    "id_2193^live_match^8": {
        "name_en": "Total Behinds hometeam for Quarter",
        "name_aa": "Total Behinds hometeam for Quarter",
        "name_de": "Gesamthl Behinds heimmannschaft Für Viertel",
        "name_nl": "Totaal achterstands Thuisteam voor kwart",
        "name_pl": "Total Behinds hometeam for Quarter",
        "name_ro": "Total Behinds hometeam for Quarter",
        "name_ru": "Total Behinds hometeam for Quarter",
        "name_tr": "Total Behinds hometeam for Quarter",
        "name_se": "Total Behinds hometeam for Quarter",
        "name_el": "Total Behinds hometeam for Quarter",
        "name_es": "Total Behinds hometeam for Quarter",
        "name_hr": "Kraj Behinds Tim 1 for Cetvrtina",
        "name_fr": "Total Behinds hometeam for Quarter"
    },
    "id_2115^live_match^8": {
        "name_en": "Game to Have Break Point",
        "name_aa": "Game to Have Break Point",
        "name_de": "Spiel, um Break Point zu haben",
        "name_nl": "Spel om breekpunt te hebben",
        "name_pl": "Game to Have Break Point",
        "name_ro": "Game to Have Break Point",
        "name_ru": "Game to Have Break Point",
        "name_tr": "Game to Have Break Point",
        "name_se": "Game to Have Break Point",
        "name_el": "Game to Have Break Point",
        "name_es": "Game to Have Break Point",
        "name_hr": "Hoce li biti brek poena",
        "name_fr": "Game to Have Break Point"
    },
    "id_2046^live_match^8": {
        "name_en": "Extra Time - 1st Half Corners",
        "name_aa": "Extra Time - 1st Half Corners",
        "name_de": "Extra Zeit - 1. Halfen ecken",
        "name_nl": "Verlenging - 1e Helft Hoekschoppen",
        "name_pl": "Extra Time - 1st Half Corners",
        "name_ro": "Extra Time - 1st Half Corners",
        "name_ru": "Extra Time - 1st Half Corners",
        "name_tr": "Extra Time - 1st Half Corners",
        "name_se": "Extra Time - 1st Half Corners",
        "name_el": "Extra Time - 1st Half Corners",
        "name_es": "Extra Time - 1st Half Corners",
        "name_hr": "Produzeci - 1. Poluvrijeme kornera",
        "name_fr": "Extra Time - 1st Half Corners"
    },
    "id_2136^live_match^8": {
        "name_en": "Either Team to Score Goals",
        "name_aa": "Either Team to Score Goals",
        "name_de": "Either Team zu spielstand Tore",
        "name_nl": "Een Team Scoren doelpunts",
        "name_pl": "Either Team to Score Goals",
        "name_ro": "Either Team to Score Goals",
        "name_ru": "Either Team to Score Goals",
        "name_tr": "Either Team to Score Goals",
        "name_se": "Either Team to Score Goals",
        "name_el": "Either Team to Score Goals",
        "name_es": "Either Team to Score Goals",
        "name_hr": "Bilo ko postize gol",
        "name_fr": "Either Team to Score Goals"
    },
    "id_1370^live_match^8": {
        "name_en": "2nd Half - Total, including overtime",
        "name_aa": "2nd Half - Total, including overtime",
        "name_de": "2nd Half - Total, inklusive Nachspielzeit",
        "name_nl": "2de Helft - Total, inclusief overtime",
        "name_pl": "2nd Half - Total, including overtime",
        "name_ro": "2nd Half - Total, including overtime",
        "name_ru": "2nd Half - Total, including overtime",
        "name_tr": "2. yarı - Toplam, uzatmalar dahil",
        "name_se": "2nd Half - Total, including overtime",
        "name_el": "2nd Half - Total, including overtime",
        "name_es": "2nd Half - Total, including overtime",
        "name_hr": "2. Poluvrijeme - Total, uklj. produžetke",
        "name_fr": "2e Mi-temps - Total, prolongations incluses"
    },
    "id_2053^live_match^6": {
        "name_en": "Handicap for fourth quarter",
        "name_aa": "Handicap for fourth quarter",
        "name_de": "Handicap Für 4. Viertel",
        "name_nl": "Handicap voor fourth kwart",
        "name_pl": "Handicap for fourth quarter",
        "name_ro": "Handicap for fourth quarter",
        "name_ru": "Handicap for fourth quarter",
        "name_tr": "Handicap for fourth quarter",
        "name_se": "Handicap for fourth quarter",
        "name_el": "Handicap for fourth quarter",
        "name_es": "Handicap for fourth quarter",
        "name_hr": "4 Cetvrtina Hendikep",
        "name_fr": "Handicap for fourth quarter"
    },
    "id_1278^live_match^8": {
        "name_en": "Total number of games of Player 2",
        "name_aa": "Total number of games of Player 2",
        "name_de": "Anzahl Spiele für Spieler 2",
        "name_nl": "Totaal aantal games van Speler 2",
        "name_pl": "Total number of games of Player 2",
        "name_ro": "Total number of games of Player 2",
        "name_ru": "Total number of games of Player 2",
        "name_tr": "Player 2'nin toplam oyun sayısı",
        "name_se": "Total number of games of Player 2",
        "name_el": "Total number of games of Player 2",
        "name_es": "Total number of games of Player 2",
        "name_hr": "Ukupan broj gemova za 2. igrača",
        "name_fr": "Nombre total de jeux du Joueur 2"
    },
    "id_11^live_match^7": {
        "name_en": "Which player will win the set?",
        "name_aa": "Which player will win the set?",
        "name_de": "Wer spieler den Satz?",
        "name_nl": "Welke speler wint de set?",
        "name_pl": "Which player will win the set?",
        "name_ro": "Which player will win the set?",
        "name_ru": "Which player will win the set?",
        "name_tr": "Seti hangi oyuncu kazanır?",
        "name_se": "Which player will win the set?",
        "name_el": "Which player will win the set?",
        "name_es": "Which player will win the set?",
        "name_hr": "Koji igrač će osvojiti set?",
        "name_fr": "Quel joueur gagnera le set ?"
    },
    "id_876^live_match^8": {
        "name_en": "Total 180s of Player 2",
        "name_aa": "Total 180s of Player 2",
        "name_de": "Gesamtergebnis 180er für Spieler 2",
        "name_nl": "Totaal 180s van Speler 2",
        "name_pl": "Total 180s of Player 2",
        "name_ro": "Total 180s of Player 2",
        "name_ru": "Total 180s of Player 2",
        "name_tr": "Player 2 Toplam 180'ler",
        "name_se": "Total 180s of Player 2",
        "name_el": "Total 180s of Player 2",
        "name_es": "Total 180s of Player 2",
        "name_hr": "Ukupno 180-ki Igrača 2",
        "name_fr": "Nombre total de 180 du Joueur 2"
    },
    "id_2000^live_match^8": {
        "name_en": "Game Won In Extra Time?",
        "name_aa": "Game Won In Extra Time?",
        "name_de": "Spiel in der Verlängerung gewonnen?",
        "name_nl": "Wedstrijd gewonnen in verlenging?",
        "name_pl": "Game Won In Extra Time?",
        "name_ro": "Game Won In Extra Time?",
        "name_ru": "Game Won In Extra Time?",
        "name_tr": "Game Won In Extra Time?",
        "name_se": "Game Won In Extra Time?",
        "name_el": "Game Won In Extra Time?",
        "name_es": "Game Won In Extra Time?",
        "name_hr": "Pobjeda u produzecima?",
        "name_fr": "Game Won In Extra Time?"
    },
    "id_2087^live_match^8": {
        "name_en": "Set Score After 4 Games",
        "name_aa": "Set Score After 4 Games",
        "name_de": "satz erzielt After 4 spiel s",
        "name_nl": "Set Score na 4 Games",
        "name_pl": "Set Score After 4 Games",
        "name_ro": "Set Score After 4 Games",
        "name_ru": "Set Score After 4 Games",
        "name_tr": "Set Score After 4 Games",
        "name_se": "Set Score After 4 Games",
        "name_el": "Set Score After 4 Games",
        "name_es": "Set Score After 4 Games",
        "name_hr": "rezultat setova poslije 4 gema",
        "name_fr": "Set Score After 4 Games"
    },
    "id_153^live_match^8": {
        "name_en": "Total Bookings",
        "name_aa": "Total Bookings",
        "name_de": "Kartenanzahl",
        "name_nl": "Totaal Kaarten",
        "name_pl": "Total Bookings",
        "name_ro": "Total Bookings",
        "name_ru": "Total Bookings",
        "name_tr": "Toplam Kartlar",
        "name_se": "Total Bookings",
        "name_el": "Total Bookings",
        "name_es": "Total Bookings",
        "name_hr": "Ukupno Kartona",
        "name_fr": "Total Cartons"
    },
    "id_2211^live_match^8": {
        "name_en": "Half Winning Margin 5-Way",
        "name_aa": "Half Winning Margin 5-Way",
        "name_de": "Halfen Siegesvorsprung 5-Way",
        "name_nl": "Helft Winstmarge 5-opties",
        "name_pl": "Half Winning Margin 5-Way",
        "name_ro": "Half Winning Margin 5-Way",
        "name_ru": "Half Winning Margin 5-Way",
        "name_tr": "Half Winning Margin 5-Way",
        "name_se": "Half Winning Margin 5-Way",
        "name_el": "Half Winning Margin 5-Way",
        "name_es": "Half Winning Margin 5-Way",
        "name_hr": "Poluvrijeme Pobjeda Razlikom5-Way",
        "name_fr": "Half Winning Margin 5-Way"
    },
    "id_2343^live_match^8": {
        "name_en": "Next Minute Corner [from]-[to]",
        "name_aa": "Next Minute Corner [from]-[to]",
        "name_de": "nächste Minute eckball [from]-[to]",
        "name_nl": "Volgende Minute Hoekschoppen[from]-[to]",
        "name_pl": "Next Minute Corner [from]-[to]",
        "name_ro": "Next Minute Corner [from]-[to]",
        "name_ru": "Next Minute Corner [from]-[to]",
        "name_tr": "Next Minute Corner [from]-[to]",
        "name_se": "Next Minute Corner [from]-[to]",
        "name_el": "Next Minute Corner [from]-[to]",
        "name_es": "Next Minute Corner [from]-[to]",
        "name_hr": "Minuti sljedeceg kornera [from]-[to]",
        "name_fr": "Next Minute Corner [from]-[to]"
    },
    "id_2030^live_match^8": {
        "name_en": "3rd Quarter 3-Way Total",
        "name_aa": "3rd Quarter 3-Way Total",
        "name_de": "3. Viertel 3-Way Gesamthl",
        "name_nl": "3e kwart 3 opties totaal",
        "name_pl": "3rd Quarter 3-Way Total",
        "name_ro": "3rd Quarter 3-Way Total",
        "name_ru": "3rd Quarter 3-Way Total",
        "name_tr": "3rd Quarter 3-Way Total",
        "name_se": "3rd Quarter 3-Way Total",
        "name_el": "3rd Quarter 3-Way Total",
        "name_es": "3rd Quarter 3-Way Total",
        "name_hr": "3 Cetvrtina 3-Way Kraj",
        "name_fr": "3rd Quarter 3-Way Total"
    },
    "id_2308^live_match^7": {
        "name_en": "Set Tie Break Handicap",
        "name_aa": "Set Tie Break Handicap",
        "name_de": "satz Tie Break Handicap",
        "name_nl": "Set Wedstrijdtiebreak Handicap",
        "name_pl": "Set Tie Break Handicap",
        "name_ro": "Set Tie Break Handicap",
        "name_ru": "Set Tie Break Handicap",
        "name_tr": "Set Tie Break Handicap",
        "name_se": "Set Tie Break Handicap",
        "name_el": "Set Tie Break Handicap",
        "name_es": "Set Tie Break Handicap",
        "name_hr": "Set Tie Break Hendikep",
        "name_fr": "Set Tie Break Handicap"
    },
    "id_2114^live_match^8": {
        "name_en": "Game Score After 3 Points",
        "name_aa": "Game Score After 3 Points",
        "name_de": "spiel erzielt After 3 Points",
        "name_nl": "Game Score After 3 Points",
        "name_pl": "Game Score After 3 Points",
        "name_ro": "Game Score After 3 Points",
        "name_ru": "Game Score After 3 Points",
        "name_tr": "Game Score After 3 Points",
        "name_se": "Game Score After 3 Points",
        "name_el": "Game Score After 3 Points",
        "name_es": "Game Score After 3 Points",
        "name_hr": "rezultat posliije 3 poena",
        "name_fr": "Game Score After 3 Points"
    },
    "id_2169^live_match^7": {
        "name_en": "To Win Match",
        "name_aa": "To Win Match",
        "name_de": "zu gewinnen spiel",
        "name_nl": "Wint Wedstrijd",
        "name_pl": "To Win Match",
        "name_ro": "To Win Match",
        "name_ru": "To Win Match",
        "name_tr": "To Win Match",
        "name_se": "To Win Match",
        "name_el": "To Win Match",
        "name_es": "To Win Match",
        "name_hr": "To pobijediti Utakmicu",
        "name_fr": "To Win Match"
    },
    "id_2170^live_match^7": {
        "name_en": "Next Goal",
        "name_aa": "Next Goal",
        "name_de": "nächste Tor",
        "name_nl": "Volgend doelpunt",
        "name_pl": "Next Goal",
        "name_ro": "Next Goal",
        "name_ru": "Next Goal",
        "name_tr": "Next Goal",
        "name_se": "Next Goal",
        "name_el": "Next Goal",
        "name_es": "Next Goal",
        "name_hr": "Sljedeći Gol",
        "name_fr": "Next Goal"
    },
    "id_83^live_match^8": {
        "name_en": "Total number of games",
        "name_aa": "Total number of games",
        "name_de": "Gesamtzahl Spiele",
        "name_nl": "Totaal aantal games",
        "name_pl": "Total number of games",
        "name_ro": "Total number of games",
        "name_ru": "Total number of games",
        "name_tr": "Toplam oyun sayısı",
        "name_se": "Total number of games",
        "name_el": "Total number of games",
        "name_es": "Total number of games",
        "name_hr": "Ukupan broj gemova",
        "name_fr": "Nombre total de jeux"
    },
    "id_2189^live_match^8": {
        "name_en": "2nd Half - Total Goals hometeam",
        "name_aa": "2nd Half - Total Goals hometeam",
        "name_de": "2. Halfen - Gesamthl Tore heimmannschaft",
        "name_nl": "2e Helft - Totaal doelpunts Thuisteam",
        "name_pl": "2nd Half - Total Goals hometeam",
        "name_ro": "2nd Half - Total Goals hometeam",
        "name_ru": "2nd Half - Total Goals hometeam",
        "name_tr": "2nd Half - Total Goals hometeam",
        "name_se": "2nd Half - Total Goals hometeam",
        "name_el": "2nd Half - Total Goals hometeam",
        "name_es": "2nd Half - Total Goals hometeam",
        "name_hr": "2nd Poluvrijeme - Kraj Golova Tim 1",
        "name_fr": "2nd Half - Total Goals hometeam"
    },
    "id_107^live_match^6": {
        "name_en": "Halftime - Next goal",
        "name_aa": "Halftime - Next goal",
        "name_de": "Halbzeit - nächstes Tor",
        "name_nl": "Halftime - volgende goal",
        "name_pl": "Halftime - Next goal",
        "name_ro": "Halftime - Next goal",
        "name_ru": "Halftime - Next goal",
        "name_tr": "İlk Yarı - Sonraki gol",
        "name_se": "Halftime - Next goal",
        "name_el": "Halftime - Next goal",
        "name_es": "Halftime - Next goal",
        "name_hr": "Poluvrijeme - Sljedeći Gol",
        "name_fr": "Mi-temps - Prochain but"
    },
    "id_291^live_match^6": {
        "name_en": "2nd Half - Handicap",
        "name_aa": "2nd Half - Handicap",
        "name_de": "2. Halbzeit - Handicap",
        "name_nl": "2de Helft - Handicap",
        "name_pl": "2nd Half - Handicap",
        "name_ro": "2nd Half - Handicap",
        "name_ru": "2nd Half - Handicap",
        "name_tr": "İkinci Yarı - Handikap",
        "name_se": "2nd Half - Handicap",
        "name_el": "2nd Half - Handicap",
        "name_es": "2nd Half - Handicap",
        "name_hr": "2. Poluvrijeme - Hendikep",
        "name_fr": "2e Mi-temps - Handicap"
    },
    "id_2072^live_match^8": {
        "name_en": "Set 1 Correct Score",
        "name_aa": "Set 1 Correct Score",
        "name_de": "satz 1 genaues erzielt",
        "name_nl": "Set 1 Correcte Scoren",
        "name_pl": "Set 1 Correct Score",
        "name_ro": "Set 1 Correct Score",
        "name_ru": "Set 1 Correct Score",
        "name_tr": "Set 1 Correct Score",
        "name_se": "Set 1 Correct Score",
        "name_el": "Set 1 Correct Score",
        "name_es": "Set 1 Correct Score",
        "name_hr": "Set 1 Tačan rezultat",
        "name_fr": "Set 1 Correct Score"
    },
    "id_2235^live_match^7": {
        "name_en": "3 Innings Run Line",
        "name_aa": "3 Innings Run Line",
        "name_de": "3 Innings handicap",
        "name_nl": "3 Innings Handicap",
        "name_pl": "3 Innings Run Line",
        "name_ro": "3 Innings Run Line",
        "name_ru": "3 Innings Run Line",
        "name_tr": "3 Innings Run Line",
        "name_se": "3 Innings Run Line",
        "name_el": "3 Innings Run Line",
        "name_es": "3 Innings Run Line",
        "name_hr": "3 Inningom Run Line",
        "name_fr": "3 Innings Run Line"
    },
    "id_1320^live_match^8": {
        "name_en": "1st Half - Which team wins race to X points",
        "name_aa": "1st Half - Which team wins race to X points",
        "name_de": "1. HZ - Welche Mannschaft erreicht zuerst X Punkte",
        "name_nl": "1ste Helft - Welk team wint de race naar X punten",
        "name_pl": "1st Half - Which team wins race to X points",
        "name_ro": "1st Half - Which team wins race to X points",
        "name_ru": "1st Half - Which team wins race to X points",
        "name_tr": "1. Yarı - Hangi takım X sayısı ile kazandı",
        "name_se": "1st Half - Which team wins race to X points",
        "name_el": "1st Half - Which team wins race to X points",
        "name_es": "1st Half - Which team wins race to X points",
        "name_hr": "Prvo Poluvrijeme - Koji tim će prvi osvojiti X poena",
        "name_fr": "1ère Mi-temps - Quelle équipe ateindra X points en premier"
    },
    "id_2183^live_match^8": {
        "name_en": "Awayteam Total Behinds",
        "name_aa": "Awayteam Total Behinds",
        "name_de": "Auswärtsmannschaft - Gesamthl Behinds",
        "name_nl": "Uitteam - Totaal aantal behinds",
        "name_pl": "Awayteam Total Behinds",
        "name_ro": "Awayteam Total Behinds",
        "name_ru": "Awayteam Total Behinds",
        "name_tr": "Awayteam Total Behinds",
        "name_se": "Awayteam Total Behinds",
        "name_el": "Awayteam Total Behinds",
        "name_es": "Awayteam Total Behinds",
        "name_hr": "Tim 2 Kraj Behinds",
        "name_fr": "Awayteam Total Behinds"
    },
    "id_2143^live_match^8": {
        "name_en": "2nd Half Winning Margin",
        "name_aa": "2nd Half Winning Margin",
        "name_de": "2. Halfen Siegesvorsprung",
        "name_nl": "2e Helft Winstmarge",
        "name_pl": "2nd Half Winning Margin",
        "name_ro": "2nd Half Winning Margin",
        "name_ru": "2nd Half Winning Margin",
        "name_tr": "2nd Half Winning Margin",
        "name_se": "2nd Half Winning Margin",
        "name_el": "2nd Half Winning Margin",
        "name_es": "2nd Half Winning Margin",
        "name_hr": "2. Poluvrijeme Pobjeda Razlikom",
        "name_fr": "2nd Half Winning Margin"
    },
    "id_2325^live_match^8": {
        "name_en": "Set Player2 Breaks of Serve",
        "name_aa": "Set Player2 Breaks of Serve",
        "name_de": "Satz -Spieler 2 -Breaks",
        "name_nl": "Set Speler2 servicebreaks",
        "name_pl": "Set Player2 Breaks of Serve",
        "name_ro": "Set Player2 Breaks of Serve",
        "name_ru": "Set Player2 Breaks of Serve",
        "name_tr": "Set Player2 Breaks of Serve",
        "name_se": "Set Player2 Breaks of Serve",
        "name_el": "Set Player2 Breaks of Serve",
        "name_es": "Set Player2 Breaks of Serve",
        "name_hr": "Set Igrač 2 brejkova",
        "name_fr": "Set Player2 Breaks of Serve"
    },
    "id_285^live_match^6": {
        "name_en": "2nd Half - 3way",
        "name_aa": "2nd Half - 3way",
        "name_de": "2. Halbzeit - 3-Weg",
        "name_nl": "2de Helft - 3way",
        "name_pl": "2nd Half - 3way",
        "name_ro": "2nd Half - 3way",
        "name_ru": "2nd Half - 3way",
        "name_tr": "İkinci Yarı - 3 Yollu",
        "name_se": "2nd Half - 3way",
        "name_el": "2nd Half - 3way",
        "name_es": "2nd Half - 3way",
        "name_hr": "2. Poluvrijeme - 1X2",
        "name_fr": "2e Mi-temps - 1x2"
    },
    "id_6^live_match^6": {
        "name_en": "3-way (OT only!)",
        "name_aa": "3-way (OT only!)",
        "name_de": "3-Weg (nur VL)",
        "name_nl": "3-way (enkel ET!)",
        "name_pl": "3-way (OT only!)",
        "name_ro": "3-way (OT only!)",
        "name_ru": "3-way (OT only!)",
        "name_tr": "3 Yollu (Sadece uzatmalar!)",
        "name_se": "3-way (OT only!)",
        "name_el": "3-way (OT only!)",
        "name_es": "3-way (OT only!)",
        "name_hr": "1X2 (samo produžeci!)",
        "name_fr": "1x2 (Prolong. seulement !)"
    },
    "id_2222^live_match^8": {
        "name_en": "Total Runs",
        "name_aa": "Total Runs",
        "name_de": "Gesamthl Runs",
        "name_nl": "Totaal aantal run",
        "name_pl": "Total Runs",
        "name_ro": "Total Runs",
        "name_ru": "Total Runs",
        "name_tr": "Total Runs",
        "name_se": "Total Runs",
        "name_el": "Total Runs",
        "name_es": "Total Runs",
        "name_hr": "Kraj Runs",
        "name_fr": "Total Runs"
    },
    "id_1527^live_match^8": {
        "name_en": "1st Half - Both teams to score",
        "name_aa": "1st Half - Both teams to score",
        "name_de": "1. Halbzeit - Beide Teams treffen",
        "name_nl": "1ste Helft - Beide teams scoren",
        "name_pl": "1st Half - Both teams to score",
        "name_ro": "1st Half - Both teams to score",
        "name_ru": "1st Half - Both teams to score",
        "name_tr": "1.yarı - İki takım da gol atar",
        "name_se": "1st Half - Both teams to score",
        "name_el": "1st Half - Both teams to score",
        "name_es": "1st Half - Both teams to score",
        "name_hr": "1. Poluvrijeme - Oba daju gol",
        "name_fr": "1ère Mi-temps - Les deux équipes qui marquent"
    },
    "id_2117^live_match^8": {
        "name_en": "Player 2 To Win a Set",
        "name_aa": "Player 2 To Win a Set",
        "name_de": "Spieler 2 gewinnt einen Satz",
        "name_nl": "Speler 2 wint een set",
        "name_pl": "Player 2 To Win a Set",
        "name_ro": "Player 2 To Win a Set",
        "name_ru": "Player 2 To Win a Set",
        "name_tr": "Player 2 To Win a Set",
        "name_se": "Player 2 To Win a Set",
        "name_el": "Player 2 To Win a Set",
        "name_es": "Player 2 To Win a Set",
        "name_hr": "Igrač 21 osvaja set",
        "name_fr": "Player 2 To Win a Set"
    },
    "id_2201^live_match^8": {
        "name_en": "2nd Half - Total awayteam",
        "name_aa": "2nd Half - Total awayteam",
        "name_de": "2. Halfen - Gesamthl Auswärtsmannschaft",
        "name_nl": "2e Helft - Totaal Uitteam",
        "name_pl": "2nd Half - Total awayteam",
        "name_ro": "2nd Half - Total awayteam",
        "name_ru": "2nd Half - Total awayteam",
        "name_tr": "2nd Half - Total awayteam",
        "name_se": "2nd Half - Total awayteam",
        "name_el": "2nd Half - Total awayteam",
        "name_es": "2nd Half - Total awayteam",
        "name_hr": "2nd Poluvrijeme - Kraj Tim 2",
        "name_fr": "2nd Half - Total awayteam"
    },
    "id_2122^live_match^8": {
        "name_en": "Player 1 Totals",
        "name_aa": "Player 1 Totals",
        "name_de": "Player 1 Gesamthls",
        "name_nl": "Speler 1 Totaals",
        "name_pl": "Player 1 Totals",
        "name_ro": "Player 1 Totals",
        "name_ru": "Player 1 Totals",
        "name_tr": "Player 1 Totals",
        "name_se": "Player 1 Totals",
        "name_el": "Player 1 Totals",
        "name_es": "Player 1 Totals",
        "name_hr": "Igrač  1 ukupno",
        "name_fr": "Player 1 Totals"
    },
    "id_1659^live_match^2": {
        "name_en": "1X2 20 min",
        "name_aa": "1X2 20 min",
        "name_de": "1X2 20 min",
        "name_nl": "1X2 20 min",
        "name_pl": "1X2 20 min",
        "name_ro": "1X2 20 min",
        "name_ru": "1X2 20 min",
        "name_tr": "1X2 20 min",
        "name_se": "1X2 20 min",
        "name_el": "1X2 20 min",
        "name_es": "1X2 20 min",
        "name_hr": "1X2 20 min",
        "name_fr": "1X2 20 min"
    },
    "id_2116^live_match^8": {
        "name_en": "Player 1 To Win a Set",
        "name_aa": "Player 1 To Win a Set",
        "name_de": "Spieler 1 gewinnt einen Satz",
        "name_nl": "Speler 1 wint een set",
        "name_pl": "Player 1 To Win a Set",
        "name_ro": "Player 1 To Win a Set",
        "name_ru": "Player 1 To Win a Set",
        "name_tr": "Player 1 To Win a Set",
        "name_se": "Player 1 To Win a Set",
        "name_el": "Player 1 To Win a Set",
        "name_es": "Player 1 To Win a Set",
        "name_hr": "Igrač  1 osvaja set",
        "name_fr": "Player 1 To Win a Set"
    },
    "id_2179^live_match^8": {
        "name_en": "Awayteam Total Goals",
        "name_aa": "Awayteam Total Goals",
        "name_de": "Auswärtsmannschaft Gesamthl Tore",
        "name_nl": "Uitteam Totaal doelpunts",
        "name_pl": "Awayteam Total Goals",
        "name_ro": "Awayteam Total Goals",
        "name_ru": "Awayteam Total Goals",
        "name_tr": "Awayteam Total Goals",
        "name_se": "Awayteam Total Goals",
        "name_el": "Awayteam Total Goals",
        "name_es": "Awayteam Total Goals",
        "name_hr": "Tim 2 Ukupno golova",
        "name_fr": "Awayteam Total Goals"
    },
    "id_2147^live_match^8": {
        "name_en": "2nd Half TriBet",
        "name_aa": "2nd Half TriBet",
        "name_de": "2. Halfen TriBet",
        "name_nl": "2e Helft TriBet",
        "name_pl": "2nd Half TriBet",
        "name_ro": "2nd Half TriBet",
        "name_ru": "2nd Half TriBet",
        "name_tr": "2nd Half TriBet",
        "name_se": "2nd Half TriBet",
        "name_el": "2nd Half TriBet",
        "name_es": "2nd Half TriBet",
        "name_hr": "2. Poluvrijeme TriBet",
        "name_fr": "2nd Half TriBet"
    },
    "id_2002^live_match^6": {
        "name_en": "Last Team to Score",
        "name_aa": "Last Team to Score",
        "name_de": "Letztes Tor erzielendes Team",
        "name_nl": "Laatste team dat scoort",
        "name_pl": "Last Team to Score",
        "name_ro": "Last Team to Score",
        "name_ru": "Last Team to Score",
        "name_tr": "Last Team to Score",
        "name_se": "Last Team to Score",
        "name_el": "Last Team to Score",
        "name_es": "Last Team to Score",
        "name_hr": "Zadnji daje gol",
        "name_fr": "Last Team to Score"
    },
    "id_2236^live_match^8": {
        "name_en": "3 Innings Total Runs",
        "name_aa": "3 Innings Total Runs",
        "name_de": "3 Innings Gesamthl Runs",
        "name_nl": "3 Innings Totaal Runs",
        "name_pl": "3 Innings Total Runs",
        "name_ro": "3 Innings Total Runs",
        "name_ru": "3 Innings Total Runs",
        "name_tr": "3 Innings Total Runs",
        "name_se": "3 Innings Total Runs",
        "name_el": "3 Innings Total Runs",
        "name_es": "3 Innings Total Runs",
        "name_hr": "3 Inningom Kraj Runs",
        "name_fr": "3 Innings Total Runs"
    },
    "id_13^live_match^6": {
        "name_en": "Next goal",
        "name_aa": "Next goal",
        "name_de": "Nächstes Tor",
        "name_nl": "Volgende doelpunt",
        "name_pl": "Next goal",
        "name_ro": "Next goal",
        "name_ru": "Next goal",
        "name_tr": "Sonraki gol",
        "name_se": "Next goal",
        "name_el": "Next goal",
        "name_es": "Next goal",
        "name_hr": "Sljedeći gol",
        "name_fr": "Prochain but"
    },
    "id_115^live_match^6": {
        "name_en": "Overtime first half 3way",
        "name_aa": "Overtime first half 3way",
        "name_de": "Erste Hälfte in der Verlängerung 3 Weg",
        "name_nl": "Extra tijd eerste helft 3way",
        "name_pl": "Overtime first half 3way",
        "name_ro": "Overtime first half 3way",
        "name_ru": "Overtime first half 3way",
        "name_tr": "Uzatmalar İlk Yarı 3 yollu",
        "name_se": "Overtime first half 3way",
        "name_el": "Overtime first half 3way",
        "name_es": "Overtime first half 3way",
        "name_hr": "Prvi produžetak 1X2",
        "name_fr": "1ère MT Prolongations 1x2"
    },
    "id_233^live_match^8": {
        "name_en": "1st Half - Total awayteam",
        "name_aa": "1st Half - Total awayteam",
        "name_de": "Erste Halbzeit Total Auswärtsmannschaft",
        "name_nl": "1ste Helft - Total uitploeg",
        "name_pl": "1st Half - Total awayteam",
        "name_ro": "1st Half - Total awayteam",
        "name_ru": "1st Half - Total awayteam",
        "name_tr": "İlk yarı - Deplasman Takımı için toplam",
        "name_se": "1st Half - Total awayteam",
        "name_el": "1st Half - Total awayteam",
        "name_es": "1st Half - Total awayteam",
        "name_hr": "1. Poluvrijeme - Ukupno gosti",
        "name_fr": "1ère Mi-temps - Total équipe visiteuse"
    },
    "id_2321^live_match^8": {
        "name_en": "Player2 Total Breaks of Serve",
        "name_aa": "Player2 Total Breaks of Serve",
        "name_de": "spieler 2 Gesamtzahl Breaks",
        "name_nl": "Speler 2 Totaal aantal servicebreaks",
        "name_pl": "Player2 Total Breaks of Serve",
        "name_ro": "Player2 Total Breaks of Serve",
        "name_ru": "Player2 Total Breaks of Serve",
        "name_tr": "Player2 Total Breaks of Serve",
        "name_se": "Player2 Total Breaks of Serve",
        "name_el": "Player2 Total Breaks of Serve",
        "name_es": "Player2 Total Breaks of Serve",
        "name_hr": "Igrač 2 ukupno brekova",
        "name_fr": "Player2 Total Breaks of Serve"
    },
    "id_2332^live_match^7": {
        "name_en": "Set Tie Break - First Mini-Break",
        "name_aa": "Set Tie Break - First Mini-Break",
        "name_de": "satz Tie Break - 1. Mini-Break",
        "name_nl": "Set Wedstrijdtiebreak - 1e Mini-Break",
        "name_pl": "Set Tie Break - First Mini-Break",
        "name_ro": "Set Tie Break - First Mini-Break",
        "name_ru": "Set Tie Break - First Mini-Break",
        "name_tr": "Set Tie Break - First Mini-Break",
        "name_se": "Set Tie Break - First Mini-Break",
        "name_el": "Set Tie Break - First Mini-Break",
        "name_es": "Set Tie Break - First Mini-Break",
        "name_hr": "Set Tie Break - First Mini-Break",
        "name_fr": "Set Tie Break - First Mini-Break"
    },
    "id_2067^live_match^6": {
        "name_en": "3rd Quarter Margin of Victory",
        "name_aa": "3rd Quarter Margin of Victory",
        "name_de": "3. Viertel Siegesvorsprung",
        "name_nl": "3e kwart Winstmarge",
        "name_pl": "3rd Quarter Margin of Victory",
        "name_ro": "3rd Quarter Margin of Victory",
        "name_ru": "3rd Quarter Margin of Victory",
        "name_tr": "3rd Quarter Margin of Victory",
        "name_se": "3rd Quarter Margin of Victory",
        "name_el": "3rd Quarter Margin of Victory",
        "name_es": "3rd Quarter Margin of Victory",
        "name_hr": "Pobjednik 3. cetvrtine sa razlikom",
        "name_fr": "3rd Quarter Margin of Victory"
    },
    "id_2335^live_match^8": {
        "name_en": "Awayteam Shots on Target",
        "name_aa": "Awayteam Shots on Target",
        "name_de": "Auswärtsmannschaft-Schüsse auf das Tor",
        "name_nl": "Uitteam- Schoten op doel",
        "name_pl": "Awayteam Shots on Target",
        "name_ro": "Awayteam Shots on Target",
        "name_ru": "Awayteam Shots on Target",
        "name_tr": "Awayteam Shots on Target",
        "name_se": "Awayteam Shots on Target",
        "name_el": "Awayteam Shots on Target",
        "name_es": "Awayteam Shots on Target",
        "name_hr": "Tim 2 udaraca u okvir",
        "name_fr": "Awayteam Shots on Target"
    },
    "id_1206^live_match^8": {
        "name_en": "[frame!] frame - First colour potted",
        "name_aa": "[frame!] frame - First colour potted",
        "name_de": "[frame!] Frame - Erste eingelochte Farbe",
        "name_nl": "[frame!] frame - Eerste kleur die gepot wordt.",
        "name_pl": "[frame!] frame - First colour potted",
        "name_ro": "[frame!] frame - First colour potted",
        "name_ru": "[frame!] frame - First colour potted",
        "name_tr": "[frame!] Çerçeve - İlk renk potada",
        "name_se": "[frame!] frame - First colour potted",
        "name_el": "[frame!] frame - First colour potted",
        "name_es": "[frame!] frame - First colour potted",
        "name_hr": "[frame!] frameova - Prva ubačena boja",
        "name_fr": "[frame!] Manche - Première couleur empochée"
    },
    "id_2008^live_match^6": {
        "name_en": "1st Half Corners",
        "name_aa": "1st Half Corners",
        "name_de": "1. Halbzeit - Ecken",
        "name_nl": "1e helft - Hoekschoppen",
        "name_pl": "1st Half Corners",
        "name_ro": "1st Half Corners",
        "name_ru": "1st Half Corners",
        "name_tr": "1st Half Corners",
        "name_se": "1st Half Corners",
        "name_el": "1st Half Corners",
        "name_es": "1st Half Corners",
        "name_hr": "1 Poluvrijeme Kornera",
        "name_fr": "1st Half Corners"
    },
    "id_2172^live_match^8": {
        "name_en": "Double Result",
        "name_aa": "Double Result",
        "name_de": "Doppelte Ergebnis",
        "name_nl": "Dubbele Resultaat",
        "name_pl": "Double Result",
        "name_ro": "Double Result",
        "name_ru": "Double Result",
        "name_tr": "Double Result",
        "name_se": "Double Result",
        "name_el": "Double Result",
        "name_es": "Double Result",
        "name_hr": "Dupli Rezultat",
        "name_fr": "Double Result"
    },
    "id_2314^live_match^8": {
        "name_en": "Match Tie Break Lead after 6 Points",
        "name_aa": "Match Tie Break Lead after 6 Points",
        "name_de": "Match Tie Break Lead after 6 Points",
        "name_nl": "Wedstrijd Wedstrijdtiebreak aan de leiding na 6 punten",
        "name_pl": "Match Tie Break Lead after 6 Points",
        "name_ro": "Match Tie Break Lead after 6 Points",
        "name_ru": "Match Tie Break Lead after 6 Points",
        "name_tr": "Match Tie Break Lead after 6 Points",
        "name_se": "Match Tie Break Lead after 6 Points",
        "name_el": "Match Tie Break Lead after 6 Points",
        "name_es": "Match Tie Break Lead after 6 Points",
        "name_hr": "Tie Break vodi poslije 6 Poena",
        "name_fr": "Match Tie Break Lead after 6 Points"
    },
    "id_2085^live_match^8": {
        "name_en": "Game Total Points",
        "name_aa": "Game Total Points",
        "name_de": "spiel Gesamthl Points",
        "name_nl": "Game Totaal punten",
        "name_pl": "Game Total Points",
        "name_ro": "Game Total Points",
        "name_ru": "Game Total Points",
        "name_tr": "Game Total Points",
        "name_se": "Game Total Points",
        "name_el": "Game Total Points",
        "name_es": "Game Total Points",
        "name_hr": "Ukupno poena",
        "name_fr": "Game Total Points"
    },
    "id_2197^live_match^8": {
        "name_en": "Both Teams To Score Points",
        "name_aa": "Both Teams To Score Points",
        "name_de": "beide Teams zu spielstand Points",
        "name_nl": "Beide teams Scoren punten",
        "name_pl": "Both Teams To Score Points",
        "name_ro": "Both Teams To Score Points",
        "name_ru": "Both Teams To Score Points",
        "name_tr": "Both Teams To Score Points",
        "name_se": "Both Teams To Score Points",
        "name_el": "Both Teams To Score Points",
        "name_es": "Both Teams To Score Points",
        "name_hr": "Oba tima postizu poene",
        "name_fr": "Both Teams To Score Points"
    },
    "id_2035^live_match^8": {
        "name_en": "Total [total] Free Kicks in 10 minutes interval [from]-[to]",
        "name_aa": "Total [total] Free Kicks in 10 minutes interval [from]-[to]",
        "name_de": "Total [total] Free Kicks in 10 minutes interval [from]-[to]",
        "name_nl": "Total [total] Free Kicks in 10 minutes interval [from]-[to]",
        "name_pl": "Total [total] Free Kicks in 10 minutes interval [from]-[to]",
        "name_ro": "Total [total] Free Kicks in 10 minutes interval [from]-[to]",
        "name_ru": "Total [total] Free Kicks in 10 minutes interval [from]-[to]",
        "name_tr": "Total [total] Free Kicks in 10 minutes interval [from]-[to]",
        "name_se": "Total [total] Free Kicks in 10 minutes interval [from]-[to]",
        "name_el": "Total [total] Free Kicks in 10 minutes interval [from]-[to]",
        "name_es": "Total [total] Free Kicks in 10 minutes interval [from]-[to]",
        "name_hr": "Kraj [total] slobodni udarci u 10 min. intervalu [from]-[to]",
        "name_fr": "Total [total] Free Kicks in 10 minutes interval [from]-[to]"
    },
    "id_51^live_match^7": {
        "name_en": "Asian handicap for third period",
        "name_aa": "Asian handicap for third period",
        "name_de": "Asian Handicap für 3. Abschnitt",
        "name_nl": "Asian handicap voor derde periode",
        "name_pl": "Asian handicap for third period",
        "name_ro": "Asian handicap for third period",
        "name_ru": "Asian handicap for third period",
        "name_tr": "Üçüncü periyot için Asya Handikabı",
        "name_se": "Asian handicap for third period",
        "name_el": "Asian handicap for third period",
        "name_es": "Asian handicap for third period",
        "name_hr": "Azijski hendikep za treći period",
        "name_fr": "Handicap asiatique pour la troisième période"
    },
    "id_1296^live_match^8": {
        "name_en": "Total tries hometeam",
        "name_aa": "Total tries hometeam",
        "name_de": "Anzahl Versuche Heimmannschaft",
        "name_nl": "Totaal tries thuisploeg",
        "name_pl": "Total tries hometeam",
        "name_ro": "Total tries hometeam",
        "name_ru": "Total tries hometeam",
        "name_tr": "Ev sahibinin toplam denemeleri",
        "name_se": "Total tries hometeam",
        "name_el": "Total tries hometeam",
        "name_es": "Total tries hometeam",
        "name_hr": "Ukupno polaganja domaći",
        "name_fr": "Total d'essais équipe domicile"
    },
    "id_2311^live_match^7": {
        "name_en": "Double Fault Handicap",
        "name_aa": "Double Fault Handicap",
        "name_de": "Doppelte Fault Handicap",
        "name_nl": "Dubbele Fault Handicap",
        "name_pl": "Double Fault Handicap",
        "name_ro": "Double Fault Handicap",
        "name_ru": "Double Fault Handicap",
        "name_tr": "Double Fault Handicap",
        "name_se": "Double Fault Handicap",
        "name_el": "Double Fault Handicap",
        "name_es": "Double Fault Handicap",
        "name_hr": "Dupla greska Hendikep",
        "name_fr": "Double Fault Handicap"
    },
    "id_53^live_match^8": {
        "name_en": "Total for fourth period",
        "name_aa": "Total for fourth period",
        "name_de": "Anzahl Punkte 4. Abschnitt",
        "name_nl": "Totaal voor vierde periode",
        "name_pl": "Total for fourth period",
        "name_ro": "Total for fourth period",
        "name_ru": "Total for fourth period",
        "name_tr": "Dördüncü periyodun toplamı",
        "name_se": "Total for fourth period",
        "name_el": "Total for fourth period",
        "name_es": "Total for fourth period",
        "name_hr": "Total za četvrti period",
        "name_fr": "Total pour la quatrième période"
    },
    "id_1210^live_match^7": {
        "name_en": "[frame!] frame - Race to 30",
        "name_aa": "[frame!] frame - Race to 30",
        "name_de": "[frame!] Frame - Wer erreicht zuerst 30?",
        "name_nl": "[frame!] frame - Race naar 30",
        "name_pl": "[frame!] frame - Race to 30",
        "name_ro": "[frame!] frame - Race to 30",
        "name_ru": "[frame!] frame - Race to 30",
        "name_tr": "[frame!] frame - 30'a yarış",
        "name_se": "[frame!] frame - Race to 30",
        "name_el": "[frame!] frame - Race to 30",
        "name_es": "[frame!] frame - Race to 30",
        "name_hr": "[frame!] frameova - Utrka do 30",
        "name_fr": "[frame!] manche - Atteindre en premier 30"
    },
    "id_2177^live_match^8": {
        "name_en": "2nd Half Goals",
        "name_aa": "2nd Half Goals",
        "name_de": "Tore in der 2. Halbzeit",
        "name_nl": "2e Helft doelpunts",
        "name_pl": "2nd Half Goals",
        "name_ro": "2nd Half Goals",
        "name_ru": "2nd Half Goals",
        "name_tr": "2nd Half Goals",
        "name_se": "2nd Half Goals",
        "name_el": "2nd Half Goals",
        "name_es": "2nd Half Goals",
        "name_hr": "2. Poluvrijeme Golova",
        "name_fr": "2nd Half Goals"
    },
    "id_126^live_match^8": {
        "name_en": "Total Corners",
        "name_aa": "Total Corners",
        "name_de": "Eckballanzahl",
        "name_nl": "Totaal Hoekschoppen",
        "name_pl": "Total Corners",
        "name_ro": "Total Corners",
        "name_ru": "Total Corners",
        "name_tr": "Toplam Kornerler",
        "name_se": "Total Corners",
        "name_el": "Total Corners",
        "name_es": "Total Corners",
        "name_hr": "Ukupno Udaraca iz kuta",
        "name_fr": "Total Corners"
    },
    "id_2005^live_match^8": {
        "name_en": "Method of Qualification",
        "name_aa": "Method of Qualification",
        "name_de": "Methode der Qualifikation",
        "name_nl": "Methode van kwalificatie",
        "name_pl": "Method of Qualification",
        "name_ro": "Method of Qualification",
        "name_ru": "Method of Qualification",
        "name_tr": "Method of Qualification",
        "name_se": "Method of Qualification",
        "name_el": "Method of Qualification",
        "name_es": "Method of Qualification",
        "name_hr": "Metod kvalifikacije",
        "name_fr": "Method of Qualification"
    },
    "id_2207^live_match^8": {
        "name_en": "Margin (Bands)",
        "name_aa": "Margin (Bands)",
        "name_de": "Margin (Bands)",
        "name_nl": "Margin (Bands)",
        "name_pl": "Margin (Bands)",
        "name_ro": "Margin (Bands)",
        "name_ru": "Margin (Bands)",
        "name_tr": "Margin (Bands)",
        "name_se": "Margin (Bands)",
        "name_el": "Margin (Bands)",
        "name_es": "Margin (Bands)",
        "name_hr": "Razlikom(Bands)",
        "name_fr": "Margin (Bands)"
    },
    "id_1668^live_match^8": {
        "name_en": "away team to score in 2nd half",
        "name_aa": "away team to score in 2nd half",
        "name_de": "Auswärtsmannschaft zu spielstand in 2. Halfen",
        "name_nl": "Uitteam Scoren in 2e Helft",
        "name_pl": "away team to score in 2nd half",
        "name_ro": "away team to score in 2nd half",
        "name_ru": "away team to score in 2nd half",
        "name_tr": "away team to score in 2nd half",
        "name_se": "away team to score in 2nd half",
        "name_el": "away team to score in 2nd half",
        "name_es": "away team to score in 2nd half",
        "name_hr": "Tim 2 Postići in 2nd Poluvrijeme",
        "name_fr": "away team to score in 2nd half"
    },
    "id_1294^live_match^8": {
        "name_en": "Total tries",
        "name_aa": "Total tries",
        "name_de": "Anzahl Versuche",
        "name_nl": "Totaal tries",
        "name_pl": "Total tries",
        "name_ro": "Total tries",
        "name_ru": "Total tries",
        "name_tr": "Toplam denemeler",
        "name_se": "Total tries",
        "name_el": "Total tries",
        "name_es": "Total tries",
        "name_hr": "Ukupno polaganja",
        "name_fr": "Total d'essais"
    },
    "id_81^live_match^7": {
        "name_en": "Who scores Xth point fifth set",
        "name_aa": "Who scores Xth point fifth set",
        "name_de": "Wer erzielt den x. Punkt im 5. Satz?",
        "name_nl": "Wie scoort het Xde punt vijfde set",
        "name_pl": "Who scores Xth point fifth set",
        "name_ro": "Who scores Xth point fifth set",
        "name_ru": "Who scores Xth point fifth set",
        "name_tr": "Beşinci sette X. sayıyı kim kazanır?",
        "name_se": "Who scores Xth point fifth set",
        "name_el": "Who scores Xth point fifth set",
        "name_es": "Who scores Xth point fifth set",
        "name_hr": "Tko će osvojiti X-ti poen u petom setu",
        "name_fr": "Qui va marquer le xième point cinquième set"
    },
    "id_2350^live_match^8": {
        "name_en": "Awayteam Time of goal",
        "name_aa": "Awayteam Time of goal",
        "name_de": "Auswärtsmannschaft Zeit des Tor",
        "name_nl": "Uitteam Tijdstip van doelpunt",
        "name_pl": "Awayteam Time of goal",
        "name_ro": "Awayteam Time of goal",
        "name_ru": "Awayteam Time of goal",
        "name_tr": "Awayteam Time of goal",
        "name_se": "Awayteam Time of goal",
        "name_el": "Awayteam Time of goal",
        "name_es": "Awayteam Time of goal",
        "name_hr": "Tim 2 vrijeme gola",
        "name_fr": "Awayteam Time of goal"
    },
    "id_486^live_match^7": {
        "name_en": "Who will win the frame?",
        "name_aa": "Who will win the frame?",
        "name_de": "Wer gewinnt den Frame?",
        "name_nl": "Wie zal er de frame winnen",
        "name_pl": "Who will win the frame?",
        "name_ro": "Who will win the frame?",
        "name_ru": "Who will win the frame?",
        "name_tr": "Frame'i kim kazanacak?",
        "name_se": "Who will win the frame?",
        "name_el": "Who will win the frame?",
        "name_es": "Who will win the frame?",
        "name_hr": "Tko će osvojiti frame",
        "name_fr": "Qui gagnera la manche ?"
    },
    "id_2178^live_match^8": {
        "name_en": "Hometeam Total Goals",
        "name_aa": "Hometeam Total Goals",
        "name_de": "heimmannschaft Gesamthl Tore",
        "name_nl": "Thuisteam Totaal doelpunts",
        "name_pl": "Hometeam Total Goals",
        "name_ro": "Hometeam Total Goals",
        "name_ru": "Hometeam Total Goals",
        "name_tr": "Hometeam Total Goals",
        "name_se": "Hometeam Total Goals",
        "name_el": "Hometeam Total Goals",
        "name_es": "Hometeam Total Goals",
        "name_hr": "Tim 1 Ukupno golova",
        "name_fr": "Hometeam Total Goals"
    },
    "id_2093^live_match^8": {
        "name_en": "Player 2 To Win from Behind",
        "name_aa": "Player 2 To Win from Behind",
        "name_de": "Player 2 zu gewinnen from Behind",
        "name_nl": "Speler 2 Wint na achterstand",
        "name_pl": "Player 2 To Win from Behind",
        "name_ro": "Player 2 To Win from Behind",
        "name_ru": "Player 2 To Win from Behind",
        "name_tr": "Player 2 To Win from Behind",
        "name_se": "Player 2 To Win from Behind",
        "name_el": "Player 2 To Win from Behind",
        "name_es": "Player 2 To Win from Behind",
        "name_hr": "Player 2 To Win from Behind",
        "name_fr": "Player 2 To Win from Behind"
    },
    "id_2028^live_match^8": {
        "name_en": "1st Quarter 3-Way Total",
        "name_aa": "1st Quarter 3-Way Total",
        "name_de": "1. Viertel 3-Way Gesamthl",
        "name_nl": "1e kwart 3 opties totaal",
        "name_pl": "1st Quarter 3-Way Total",
        "name_ro": "1st Quarter 3-Way Total",
        "name_ru": "1st Quarter 3-Way Total",
        "name_tr": "1st Quarter 3-Way Total",
        "name_se": "1st Quarter 3-Way Total",
        "name_el": "1st Quarter 3-Way Total",
        "name_es": "1st Quarter 3-Way Total",
        "name_hr": "1 Cetvrtina 3-Way Kraj",
        "name_fr": "1st Quarter 3-Way Total"
    },
    "id_2086^live_match^8": {
        "name_en": "Set Score After 2 Games",
        "name_aa": "Set Score After 2 Games",
        "name_de": "satz erzielt After 2 spiel s",
        "name_nl": "Set Score na 2 Games",
        "name_pl": "Set Score After 2 Games",
        "name_ro": "Set Score After 2 Games",
        "name_ru": "Set Score After 2 Games",
        "name_tr": "Set Score After 2 Games",
        "name_se": "Set Score After 2 Games",
        "name_el": "Set Score After 2 Games",
        "name_es": "Set Score After 2 Games",
        "name_hr": "rezultat setova poslije 2 gema",
        "name_fr": "Set Score After 2 Games"
    },
    "id_2076^live_match^8": {
        "name_en": "Set 5 Correct Score",
        "name_aa": "Set 5 Correct Score",
        "name_de": "satz 5 genaues erzielt",
        "name_nl": "Set 5 Correcte Scoren",
        "name_pl": "Set 5 Correct Score",
        "name_ro": "Set 5 Correct Score",
        "name_ru": "Set 5 Correct Score",
        "name_tr": "Set 5 Correct Score",
        "name_se": "Set 5 Correct Score",
        "name_el": "Set 5 Correct Score",
        "name_es": "Set 5 Correct Score",
        "name_hr": "Set 5 Tačan rezultat",
        "name_fr": "Set 5 Correct Score"
    },
    "id_2305^live_match^8": {
        "name_en": "Set Tie Break Score",
        "name_aa": "Set Tie Break Score",
        "name_de": "satz Tie Break erzielt",
        "name_nl": "Set Wedstrijdtiebreak Scoren",
        "name_pl": "Set Tie Break Score",
        "name_ro": "Set Tie Break Score",
        "name_ru": "Set Tie Break Score",
        "name_tr": "Set Tie Break Score",
        "name_se": "Set Tie Break Score",
        "name_el": "Set Tie Break Score",
        "name_es": "Set Tie Break Score",
        "name_hr": "Set Tie Break  rezultat",
        "name_fr": "Set Tie Break Score"
    },
    "id_2041^live_match^8": {
        "name_en": "Time of Next Goal - Awayteam",
        "name_aa": "Time of Next Goal - Awayteam",
        "name_de": "Zeit des nächste Tor - Auswärtsmannschaft",
        "name_nl": "Tijdstip van volgende doelpunt - Uitteam",
        "name_pl": "Time of Next Goal - Awayteam",
        "name_ro": "Time of Next Goal - Awayteam",
        "name_ru": "Time of Next Goal - Awayteam",
        "name_tr": "Time of Next Goal - Awayteam",
        "name_se": "Time of Next Goal - Awayteam",
        "name_el": "Time of Next Goal - Awayteam",
        "name_es": "Time of Next Goal - Awayteam",
        "name_hr": "Vrijeme Sljedećeg Gola- Tim 2",
        "name_fr": "Time of Next Goal - Awayteam"
    },
    "id_77^live_match^7": {
        "name_en": "Who scores Xth point first set",
        "name_aa": "Who scores Xth point first set",
        "name_de": "Wer erzielt den x. Punkt im 1. Satz?",
        "name_nl": "Wie scoort het Xde punt eerste set",
        "name_pl": "Who scores Xth point first set",
        "name_ro": "Who scores Xth point first set",
        "name_ru": "Who scores Xth point first set",
        "name_tr": "Birinci sette X. sayıyı kim kazanır?",
        "name_se": "Who scores Xth point first set",
        "name_el": "Who scores Xth point first set",
        "name_es": "Who scores Xth point first set",
        "name_hr": "Tko će osvojiti X-ti poen u prvom setu",
        "name_fr": "Qui va marquer le xième point premier set"
    },
    "id_2318^live_match^8": {
        "name_en": "Player1 Total Aces",
        "name_aa": "Player1 Total Aces",
        "name_de": "Spieler1- Gesamtzahl Asse",
        "name_nl": "Speler1- Totaal aantal Aces",
        "name_pl": "Player1 Total Aces",
        "name_ro": "Player1 Total Aces",
        "name_ru": "Player1 Total Aces",
        "name_tr": "Player1 Total Aces",
        "name_se": "Player1 Total Aces",
        "name_el": "Player1 Total Aces",
        "name_es": "Player1 Total Aces",
        "name_hr": "Igrač 1 ukupno aces",
        "name_fr": "Player1 Total Aces"
    },
    "id_48^live_match^7": {
        "name_en": "Asian handicap for second period",
        "name_aa": "Asian handicap for second period",
        "name_de": "Asian Handicap für 2. Abschnitt",
        "name_nl": "Asian handicap voor tweede periode",
        "name_pl": "Asian handicap for second period",
        "name_ro": "Asian handicap for second period",
        "name_ru": "Asian handicap for second period",
        "name_tr": "İkinci periyot için Asya Handikabı",
        "name_se": "Asian handicap for second period",
        "name_el": "Asian handicap for second period",
        "name_es": "Asian handicap for second period",
        "name_hr": "Azijski hendikep za drugi period",
        "name_fr": "Handicap asiatique pour la deuxième période"
    },
    "id_232^live_match^8": {
        "name_en": "1st Half - Total hometeam",
        "name_aa": "1st Half - Total hometeam",
        "name_de": "1. Halbzeit - Total Heimmannschaft",
        "name_nl": "1ste Helft - Total thuisploeg",
        "name_pl": "1st Half - Total hometeam",
        "name_ro": "1st Half - Total hometeam",
        "name_ru": "1st Half - Total hometeam",
        "name_tr": "İlk yarı - Ev Sahibi için toplam",
        "name_se": "1st Half - Total hometeam",
        "name_el": "1st Half - Total hometeam",
        "name_es": "1st Half - Total hometeam",
        "name_hr": "1. Poluvrijeme - Ukupno domaći",
        "name_fr": "1ère Mi-temps - Total équipe à domicile"
    },
    "id_2317^live_match^8": {
        "name_en": "Match Tie Break - Total Points",
        "name_aa": "Match Tie Break - Total Points",
        "name_de": "spiel Tie Break - Gesamthl Points",
        "name_nl": "Puntwedden – Wedstrijdtiebreak",
        "name_pl": "Match Tie Break - Total Points",
        "name_ro": "Match Tie Break - Total Points",
        "name_ru": "Match Tie Break - Total Points",
        "name_tr": "Match Tie Break - Total Points",
        "name_se": "Match Tie Break - Total Points",
        "name_el": "Match Tie Break - Total Points",
        "name_es": "Match Tie Break - Total Points",
        "name_hr": "Tie break i ukupno poena",
        "name_fr": "Match Tie Break - Total Points"
    },
    "id_2310^live_match^7": {
        "name_en": "Aces Handicap",
        "name_aa": "Aces Handicap",
        "name_de": "Aces Handicap",
        "name_nl": "Aces Handicap",
        "name_pl": "Aces Handicap",
        "name_ro": "Aces Handicap",
        "name_ru": "Aces Handicap",
        "name_tr": "Aces Handicap",
        "name_se": "Aces Handicap",
        "name_el": "Aces Handicap",
        "name_es": "Aces Handicap",
        "name_hr": "Hendikep As",
        "name_fr": "Aces Handicap"
    },
    "id_2094^live_match^8": {
        "name_en": "Player 2 To Win in Straight Sets",
        "name_aa": "Player 2 To Win in Straight Sets",
        "name_de": "Player 2 zu gewinnen in Straight satzs",
        "name_nl": "Speler 2 Wint in Straight Sets",
        "name_pl": "Player 2 To Win in Straight Sets",
        "name_ro": "Player 2 To Win in Straight Sets",
        "name_ru": "Player 2 To Win in Straight Sets",
        "name_tr": "Player 2 To Win in Straight Sets",
        "name_se": "Player 2 To Win in Straight Sets",
        "name_el": "Player 2 To Win in Straight Sets",
        "name_es": "Player 2 To Win in Straight Sets",
        "name_hr": "Igrač 2 dobija setove za redom",
        "name_fr": "Player 2 To Win in Straight Sets"
    },
    "id_2246^live_match^8": {
        "name_en": "Lead After (Innings)",
        "name_aa": "Lead After (Innings)",
        "name_de": "In führung nach(Innings)",
        "name_nl": "Aan de leiding na (Innings)",
        "name_pl": "Lead After (Innings)",
        "name_ro": "Lead After (Innings)",
        "name_ru": "Lead After (Innings)",
        "name_tr": "Lead After (Innings)",
        "name_se": "Lead After (Innings)",
        "name_el": "Lead After (Innings)",
        "name_es": "Lead After (Innings)",
        "name_hr": "Lead After (Inningom s)",
        "name_fr": "Lead After (Innings)"
    },
    "id_2132^live_match^7": {
        "name_en": "Asian handicap second half",
        "name_aa": "Asian handicap second half",
        "name_de": "Asian handicap 2. Halfen",
        "name_nl": "Asian handicap 2e helft",
        "name_pl": "Asian handicap second half",
        "name_ro": "Asian handicap second half",
        "name_ru": "Asian handicap second half",
        "name_tr": "Asian handicap second half",
        "name_se": "Asian handicap second half",
        "name_el": "Asian handicap second half",
        "name_es": "Asian handicap second half",
        "name_hr": "Pobjednik sa Hendikepom 2. poluvrijeme",
        "name_fr": "Asian handicap second half"
    },
    "id_2031^live_match^8": {
        "name_en": "4th Quarter 3-Way Total",
        "name_aa": "4th Quarter 3-Way Total",
        "name_de": "4. Viertel 3-Way Gesamthl",
        "name_nl": "4th kwart 3 opties totaal",
        "name_pl": "4th Quarter 3-Way Total",
        "name_ro": "4th Quarter 3-Way Total",
        "name_ru": "4th Quarter 3-Way Total",
        "name_tr": "4th Quarter 3-Way Total",
        "name_se": "4th Quarter 3-Way Total",
        "name_el": "4th Quarter 3-Way Total",
        "name_es": "4th Quarter 3-Way Total",
        "name_hr": "4 Cetvrtina 3-Way Kraj",
        "name_fr": "4th Quarter 3-Way Total"
    },
    "id_1446^live_match^8": {
        "name_en": "Total [total] Corners in 10 minutes interval [from]-[to]",
        "name_aa": "Total [total] Corners in 10 minutes interval [from]-[to]",
        "name_de": "Total [total] Corners in 10 minutes interval [from]-[to]",
        "name_nl": "Total [total] Corners in 10 minuten interval [from]-[to]",
        "name_pl": "Total [total] Corners in 10 minutes interval [from]-[to]",
        "name_ro": "Total [total] Corners in 10 minutes interval [from]-[to]",
        "name_ru": "Total [total] Corners in 10 minutes interval [from]-[to]",
        "name_tr": "[total] 10 dakikalık aralıkta korner sayısı [from]-[to]",
        "name_se": "Total [total] Corners in 10 minutes interval [from]-[to]",
        "name_el": "Total [total] Corners in 10 minutes interval [from]-[to]",
        "name_es": "Total [total] Corners in 10 minutes interval [from]-[to]",
        "name_hr": "Kraj [total] Udarci iz kuta u desetominutnom intervalu [from]-[to]",
        "name_fr": "Total [total] Corners dans l'intervalle de 10 minutes [from]-[to]"
    },
    "id_1670^live_match^8": {
        "name_en": "away team clean sheet",
        "name_aa": "away team clean sheet",
        "name_de": "Auswärtsmannschaft kein gegentor",
        "name_nl": "Uitteam de nul houden",
        "name_pl": "away team clean sheet",
        "name_ro": "away team clean sheet",
        "name_ru": "away team clean sheet",
        "name_tr": "away team clean sheet",
        "name_se": "away team clean sheet",
        "name_el": "away team clean sheet",
        "name_es": "away team clean sheet",
        "name_hr": "Tim 2 clean sheet",
        "name_fr": "away team clean sheet"
    },
    "id_2021^live_match^8": {
        "name_en": "2nd Half Race to points",
        "name_aa": "2nd Half Race to points",
        "name_de": "2. Spielhälfte - Zuerst X (Punkte)",
        "name_nl": "2e Helft Race naar punten",
        "name_pl": "2nd Half Race to points",
        "name_ro": "2nd Half Race to points",
        "name_ru": "2nd Half Race to points",
        "name_tr": "2nd Half Race to points",
        "name_se": "2nd Half Race to points",
        "name_el": "2nd Half Race to points",
        "name_es": "2nd Half Race to points",
        "name_hr": "2.  Poluvrijeme trka do poena",
        "name_fr": "2nd Half Race to points"
    },
    "id_2009^live_match^6": {
        "name_en": "2nd Half Corners",
        "name_aa": "2nd Half Corners",
        "name_de": "2. Halbzeit - Ecken",
        "name_nl": "2e Helft Hoekschoppen",
        "name_pl": "2nd Half Corners",
        "name_ro": "2nd Half Corners",
        "name_ru": "2nd Half Corners",
        "name_tr": "2nd Half Corners",
        "name_se": "2nd Half Corners",
        "name_el": "2nd Half Corners",
        "name_es": "2nd Half Corners",
        "name_hr": "2 Poluvrijeme Kornera",
        "name_fr": "2nd Half Corners"
    },
    "id_2061^live_match^8": {
        "name_en": "2nd Quarter Winning Margin",
        "name_aa": "2nd Quarter Winning Margin",
        "name_de": "2. Viertel Siegesvorsprung",
        "name_nl": "2e kwart Winstmarge",
        "name_pl": "2nd Quarter Winning Margin",
        "name_ro": "2nd Quarter Winning Margin",
        "name_ru": "2nd Quarter Winning Margin",
        "name_tr": "2nd Quarter Winning Margin",
        "name_se": "2nd Quarter Winning Margin",
        "name_el": "2nd Quarter Winning Margin",
        "name_es": "2nd Quarter Winning Margin",
        "name_hr": "2. Cetvrtina Pobjeda Razlikom",
        "name_fr": "2nd Quarter Winning Margin"
    },
    "id_1661^live_match^2": {
        "name_en": "1X2 40 min",
        "name_aa": "1X2 40 min",
        "name_de": "1X2 40 min",
        "name_nl": "1X2 40 min",
        "name_pl": "1X2 40 min",
        "name_ro": "1X2 40 min",
        "name_ru": "1X2 40 min",
        "name_tr": "1X2 40 min",
        "name_se": "1X2 40 min",
        "name_el": "1X2 40 min",
        "name_es": "1X2 40 min",
        "name_hr": "1X2 40 min",
        "name_fr": "1X2 40 min"
    },
    "id_2023^live_match^8": {
        "name_en": "2nd Quarter Race to points",
        "name_aa": "2nd Quarter Race to points",
        "name_de": "2. Viertel Race to points",
        "name_nl": "2e kwart Race naar punten",
        "name_pl": "2nd Quarter Race to points",
        "name_ro": "2nd Quarter Race to points",
        "name_ru": "2nd Quarter Race to points",
        "name_tr": "2nd Quarter Race to points",
        "name_se": "2nd Quarter Race to points",
        "name_el": "2nd Quarter Race to points",
        "name_es": "2nd Quarter Race to points",
        "name_hr": "2 Cetvrtinatrka do poena",
        "name_fr": "2nd Quarter Race to points"
    },
    "id_1204^live_match^8": {
        "name_en": "[frame!] frame - Player to pot last ball",
        "name_aa": "[frame!] frame - Player to pot last ball",
        "name_de": "[frame!] frame - Player to pot last ball",
        "name_nl": "[frame!] frame - Player to pot last ball",
        "name_pl": "[frame!] frame - Player to pot last ball",
        "name_ro": "[frame!] frame - Player to pot last ball",
        "name_ru": "[frame!] frame - Player to pot last ball",
        "name_tr": "[frame!] frame - Player to pot last ball",
        "name_se": "[frame!] frame - Player to pot last ball",
        "name_el": "[frame!] frame - Player to pot last ball",
        "name_es": "[frame!] frame - Player to pot last ball",
        "name_hr": "[frame!] frame - Player to pot last ball",
        "name_fr": "[frame!] frame - Player to pot last ball"
    },
    "id_2060^live_match^8": {
        "name_en": "1st Quarter Winning Margin",
        "name_aa": "1st Quarter Winning Margin",
        "name_de": "1. Viertel Siegesvorsprung",
        "name_nl": "1e kwart Winstmarge",
        "name_pl": "1st Quarter Winning Margin",
        "name_ro": "1st Quarter Winning Margin",
        "name_ru": "1st Quarter Winning Margin",
        "name_tr": "1st Quarter Winning Margin",
        "name_se": "1st Quarter Winning Margin",
        "name_el": "1st Quarter Winning Margin",
        "name_es": "1st Quarter Winning Margin",
        "name_hr": "1. Cetvrtina Pobjeda Razlikom",
        "name_fr": "1st Quarter Winning Margin"
    },
    "id_2196^live_match^8": {
        "name_en": "Total Scoring Shots awayteam for Quarter",
        "name_aa": "Total Scoring Shots awayteam for Quarter",
        "name_de": "Gesamthl Scoring Shots Auswärtsmannschaft Für Viertel",
        "name_nl": "Totaal Scorende schoten Uitteam voor kwart",
        "name_pl": "Total Scoring Shots awayteam for Quarter",
        "name_ro": "Total Scoring Shots awayteam for Quarter",
        "name_ru": "Total Scoring Shots awayteam for Quarter",
        "name_tr": "Total Scoring Shots awayteam for Quarter",
        "name_se": "Total Scoring Shots awayteam for Quarter",
        "name_el": "Total Scoring Shots awayteam for Quarter",
        "name_es": "Total Scoring Shots awayteam for Quarter",
        "name_hr": "Kraj Scoring Shots Tim 2 for Cetvrtina",
        "name_fr": "Total Scoring Shots awayteam for Quarter"
    },
    "id_2029^live_match^8": {
        "name_en": "2nd Quarter 3-Way Total",
        "name_aa": "2nd Quarter 3-Way Total",
        "name_de": "2. Viertel 3-Way Gesamthl",
        "name_nl": "2e kwart 3 opties totaal",
        "name_pl": "2nd Quarter 3-Way Total",
        "name_ro": "2nd Quarter 3-Way Total",
        "name_ru": "2nd Quarter 3-Way Total",
        "name_tr": "2nd Quarter 3-Way Total",
        "name_se": "2nd Quarter 3-Way Total",
        "name_el": "2nd Quarter 3-Way Total",
        "name_es": "2nd Quarter 3-Way Total",
        "name_hr": "2 Cetvrtina 3-Way Kraj",
        "name_fr": "2nd Quarter 3-Way Total"
    },
    "id_2327^live_match^8": {
        "name_en": "Total Breaks of Serve in Match",
        "name_aa": "Total Breaks of Serve in Match",
        "name_de": "Gesamtzahl Breaks im Match",
        "name_nl": "Totaal service breaks in Wedstrijd",
        "name_pl": "Total Breaks of Serve in Match",
        "name_ro": "Total Breaks of Serve in Match",
        "name_ru": "Total Breaks of Serve in Match",
        "name_tr": "Total Breaks of Serve in Match",
        "name_se": "Total Breaks of Serve in Match",
        "name_el": "Total Breaks of Serve in Match",
        "name_es": "Total Breaks of Serve in Match",
        "name_hr": "Kraj Breaks of Serve in Utakmica",
        "name_fr": "Total Breaks of Serve in Match"
    },
    "id_41^live_match^7": {
        "name_en": "Draw No Bet for first period",
        "name_aa": "Draw No Bet for first period",
        "name_de": "Sieger - Kein Unentschieden 1. Abschnitt",
        "name_nl": "Draw no Bet voor eerste periode",
        "name_pl": "Draw No Bet for first period",
        "name_ro": "Draw No Bet for first period",
        "name_ru": "Draw No Bet for first period",
        "name_tr": "Beraberlikte iade ilk periyot",
        "name_se": "Draw No Bet for first period",
        "name_el": "Draw No Bet for first period",
        "name_es": "Draw No Bet for first period",
        "name_hr": "Ishod bez neodlučenog za prvi period",
        "name_fr": "Remboursé si Match nul pour la première période"
    },
    "id_2098^live_match^8": {
        "name_en": "Hometeam Totals",
        "name_aa": "Hometeam Totals",
        "name_de": "heimmannschaft Gesamthls",
        "name_nl": "Thuisteam Totaals",
        "name_pl": "Hometeam Totals",
        "name_ro": "Hometeam Totals",
        "name_ru": "Hometeam Totals",
        "name_tr": "Hometeam Totals",
        "name_se": "Hometeam Totals",
        "name_el": "Hometeam Totals",
        "name_es": "Hometeam Totals",
        "name_hr": "Tim 1 Kraj ukupno",
        "name_fr": "Hometeam Totals"
    },
    "id_2210^live_match^8": {
        "name_en": "Hometeam Goals (Bands)",
        "name_aa": "Hometeam Goals (Bands)",
        "name_de": "heimmannschaft Tore (Bands)",
        "name_nl": "Thuisteam doelpunts (Bands)",
        "name_pl": "Hometeam Goals (Bands)",
        "name_ro": "Hometeam Goals (Bands)",
        "name_ru": "Hometeam Goals (Bands)",
        "name_tr": "Hometeam Goals (Bands)",
        "name_se": "Hometeam Goals (Bands)",
        "name_el": "Hometeam Goals (Bands)",
        "name_es": "Hometeam Goals (Bands)",
        "name_hr": "Tim 1 Golova (Bands)",
        "name_fr": "Hometeam Goals (Bands)"
    },
    "id_2150^live_match^8": {
        "name_en": "1st Half Race to",
        "name_aa": "1st Half Race to",
        "name_de": "1. Spielhälfte - Zuerst X",
        "name_nl": "1e Helft Race naar",
        "name_pl": "1st Half Race to",
        "name_ro": "1st Half Race to",
        "name_ru": "1st Half Race to",
        "name_tr": "1st Half Race to",
        "name_se": "1st Half Race to",
        "name_el": "1st Half Race to",
        "name_es": "1st Half Race to",
        "name_hr": "1. Poluvrijeme trka do",
        "name_fr": "1st Half Race to"
    },
    "id_202^live_match^8": {
        "name_en": "Total for sixth period",
        "name_aa": "Total for sixth period",
        "name_de": "Total für 6. Abschnitt",
        "name_nl": "Totaal voor zesde periode",
        "name_pl": "Total for sixth period",
        "name_ro": "Total for sixth period",
        "name_ru": "Total for sixth period",
        "name_tr": "Altıncı periyodun toplamı",
        "name_se": "Total for sixth period",
        "name_el": "Total for sixth period",
        "name_es": "Total for sixth period",
        "name_hr": "Total za šesti period",
        "name_fr": "Total pour la sixième période"
    },
    "id_237^live_match^8": {
        "name_en": "Halftime/fulltime",
        "name_aa": "Halftime/fulltime",
        "name_de": "Halbzeit/reg. Spielzeit",
        "name_nl": "Rust/volledige",
        "name_pl": "Halftime/fulltime",
        "name_ro": "Halftime/fulltime",
        "name_ru": "Halftime/fulltime",
        "name_tr": "İlk Yarı / Maç Sonu",
        "name_se": "Halftime/fulltime",
        "name_el": "Halftime/fulltime",
        "name_es": "Halftime/fulltime",
        "name_hr": "Poluvrijeme/kraj",
        "name_fr": "Mi-temps/fin de match"
    },
    "id_2187^live_match^8": {
        "name_en": "1st Half - Total Goals hometeam",
        "name_aa": "1st Half - Total Goals hometeam",
        "name_de": "1. Halfen - Gesamthl Tore heimmannschaft",
        "name_nl": "1e Helft - Totaal doelpunts Thuisteam",
        "name_pl": "1st Half - Total Goals hometeam",
        "name_ro": "1st Half - Total Goals hometeam",
        "name_ru": "1st Half - Total Goals hometeam",
        "name_tr": "1st Half - Total Goals hometeam",
        "name_se": "1st Half - Total Goals hometeam",
        "name_el": "1st Half - Total Goals hometeam",
        "name_es": "1st Half - Total Goals hometeam",
        "name_hr": "1st Poluvrijeme - Kraj Golova Tim 1",
        "name_fr": "1st Half - Total Goals hometeam"
    },
    "id_2140^live_match^8": {
        "name_en": "1st Half Race to",
        "name_aa": "1st Half Race to",
        "name_de": "1. Spielhälfte - Zuerst X",
        "name_nl": "1e Helft Race naar",
        "name_pl": "1st Half Race to",
        "name_ro": "1st Half Race to",
        "name_ru": "1st Half Race to",
        "name_tr": "1st Half Race to",
        "name_se": "1st Half Race to",
        "name_el": "1st Half Race to",
        "name_es": "1st Half Race to",
        "name_hr": "1. Poluvrijeme prvi stize do",
        "name_fr": "1st Half Race to"
    },
    "id_2239^live_match^8": {
        "name_en": "5 Innings Total Runs",
        "name_aa": "5 Innings Total Runs",
        "name_de": "5 Innings Gesamthl Runs",
        "name_nl": "5 Innings Totaal Runs",
        "name_pl": "5 Innings Total Runs",
        "name_ro": "5 Innings Total Runs",
        "name_ru": "5 Innings Total Runs",
        "name_tr": "5 Innings Total Runs",
        "name_se": "5 Innings Total Runs",
        "name_el": "5 Innings Total Runs",
        "name_es": "5 Innings Total Runs",
        "name_hr": "5 Inningom Kraj Runs",
        "name_fr": "5 Innings Total Runs"
    },
    "id_345^live_match^8": {
        "name_en": "Odd/Even for sixth period",
        "name_aa": "Odd/Even for sixth period",
        "name_de": "Gerade/Ungerade 6. Abschnitt",
        "name_nl": "Oneven/Even voor zesde periode",
        "name_pl": "Odd/Even for sixth period",
        "name_ro": "Odd/Even for sixth period",
        "name_ru": "Odd/Even for sixth period",
        "name_tr": "altıncı periyod için Tek/Çift",
        "name_se": "Odd/Even for sixth period",
        "name_el": "Odd/Even for sixth period",
        "name_es": "Odd/Even for sixth period",
        "name_hr": "Nepar/Par za šesti period",
        "name_fr": "Pair/Impair pour la sixième période"
    },
    "id_2309^live_match^8": {
        "name_en": "Set Tie Break - Total Points",
        "name_aa": "Set Tie Break - Total Points",
        "name_de": "satz Tie Break - Gesamthl Points",
        "name_nl": "Set Wedstrijdtiebreak - Totaal punten",
        "name_pl": "Set Tie Break - Total Points",
        "name_ro": "Set Tie Break - Total Points",
        "name_ru": "Set Tie Break - Total Points",
        "name_tr": "Set Tie Break - Total Points",
        "name_se": "Set Tie Break - Total Points",
        "name_el": "Set Tie Break - Total Points",
        "name_es": "Set Tie Break - Total Points",
        "name_hr": "Set Tie Break - Kraj Poena",
        "name_fr": "Set Tie Break - Total Points"
    },
    "id_2336^live_match^8": {
        "name_en": "Hometeam Shots",
        "name_aa": "Hometeam Shots",
        "name_de": "heimmannschaft Schüsse",
        "name_nl": "Thuisteam schoten",
        "name_pl": "Hometeam Shots",
        "name_ro": "Hometeam Shots",
        "name_ru": "Hometeam Shots",
        "name_tr": "Hometeam Shots",
        "name_se": "Hometeam Shots",
        "name_el": "Hometeam Shots",
        "name_es": "Hometeam Shots",
        "name_hr": "Tim 1 udaraca",
        "name_fr": "Hometeam Shots"
    },
    "id_2180^live_match^8": {
        "name_en": "Hometeam Total Scoring Shots",
        "name_aa": "Hometeam Total Scoring Shots",
        "name_de": "Heimmannschaft - Spiel - Gesamtzahl erfolgreicher Schüsse",
        "name_nl": "Thuisteam - Totaal aantal scorende schoten tijdens de wedstrijd",
        "name_pl": "Hometeam Total Scoring Shots",
        "name_ro": "Hometeam Total Scoring Shots",
        "name_ru": "Hometeam Total Scoring Shots",
        "name_tr": "Hometeam Total Scoring Shots",
        "name_se": "Hometeam Total Scoring Shots",
        "name_el": "Hometeam Total Scoring Shots",
        "name_es": "Hometeam Total Scoring Shots",
        "name_hr": "Tim 1 Kraj Scoring Shots",
        "name_fr": "Hometeam Total Scoring Shots"
    },
    "id_2105^live_match^8": {
        "name_en": "Which Team Wins Race To Goals",
        "name_aa": "Which Team Wins Race To Goals",
        "name_de": "Which Team Wins Race To Goals",
        "name_nl": "Which Team Wins Race To Goals",
        "name_pl": "Which Team Wins Race To Goals",
        "name_ro": "Which Team Wins Race To Goals",
        "name_ru": "Which Team Wins Race To Goals",
        "name_tr": "Which Team Wins Race To Goals",
        "name_se": "Which Team Wins Race To Goals",
        "name_el": "Which Team Wins Race To Goals",
        "name_es": "Which Team Wins Race To Goals",
        "name_hr": "Ko prvi stize do",
        "name_fr": "Which Team Wins Race To Goals"
    },
    "id_2055^live_match^8": {
        "name_en": "2nd Quarter Double Chance",
        "name_aa": "2nd Quarter Double Chance",
        "name_de": "2. Viertel Doppelte chance",
        "name_nl": "2e kwart Dubbele Chance",
        "name_pl": "2nd Quarter Double Chance",
        "name_ro": "2nd Quarter Double Chance",
        "name_ru": "2nd Quarter Double Chance",
        "name_tr": "2nd Quarter Double Chance",
        "name_se": "2nd Quarter Double Chance",
        "name_el": "2nd Quarter Double Chance",
        "name_es": "2nd Quarter Double Chance",
        "name_hr": "2 Cetvrtina Doupla šansa",
        "name_fr": "2nd Quarter Double Chance"
    },
    "id_1108^live_match^8": {
        "name_en": "Correct score, including overtime and penalties",
        "name_aa": "Correct score, including overtime and penalties",
        "name_de": "Genaues Ergebnis, inkl. Verlängerung und Penalties",
        "name_nl": "Correcte score, inclusief extra time en strafschoppen",
        "name_pl": "Correct score, including overtime and penalties",
        "name_ro": "Correct score, including overtime and penalties",
        "name_ru": "Correct score, including overtime and penalties",
        "name_tr": "Doğru skor, uzatmalar ve penaltılar dahil",
        "name_se": "Correct score, including overtime and penalties",
        "name_el": "Correct score, including overtime and penalties",
        "name_es": "Correct score, including overtime and penalties",
        "name_hr": "Točan rezultat, uključujući produžetke i penale",
        "name_fr": "Score correct, prolongations et tirs au but inclus"
    },
    "id_2243^live_match^8": {
        "name_en": "Both Teams to Score X Runs",
        "name_aa": "Both Teams to Score X Runs",
        "name_de": "beide Teams zu spielstand X Runs",
        "name_nl": "Beide teams Scoren X Runs",
        "name_pl": "Both Teams to Score X Runs",
        "name_ro": "Both Teams to Score X Runs",
        "name_ru": "Both Teams to Score X Runs",
        "name_tr": "Both Teams to Score X Runs",
        "name_se": "Both Teams to Score X Runs",
        "name_el": "Both Teams to Score X Runs",
        "name_es": "Both Teams to Score X Runs",
        "name_hr": "oba tima Postići X Runs",
        "name_fr": "Both Teams to Score X Runs"
    },
    "id_2089^live_match^8": {
        "name_en": "Game Score After 2 Points",
        "name_aa": "Game Score After 2 Points",
        "name_de": "spiel erzielt After 2 Points",
        "name_nl": "Game Scoren na 2 punten",
        "name_pl": "Game Score After 2 Points",
        "name_ro": "Game Score After 2 Points",
        "name_ru": "Game Score After 2 Points",
        "name_tr": "Game Score After 2 Points",
        "name_se": "Game Score After 2 Points",
        "name_el": "Game Score After 2 Points",
        "name_es": "Game Score After 2 Points",
        "name_hr": "rezulat poslije 2 poena",
        "name_fr": "Game Score After 2 Points"
    },
    "id_2160^live_match^8": {
        "name_en": "Player 1 Totals",
        "name_aa": "Player 1 Totals",
        "name_de": "Player 1 Gesamthls",
        "name_nl": "Speler 1 Totaals",
        "name_pl": "Player 1 Totals",
        "name_ro": "Player 1 Totals",
        "name_ru": "Player 1 Totals",
        "name_tr": "Player 1 Totals",
        "name_se": "Player 1 Totals",
        "name_el": "Player 1 Totals",
        "name_es": "Player 1 Totals",
        "name_hr": "Igrač  1 Kraj",
        "name_fr": "Player 1 Totals"
    },
    "id_2062^live_match^8": {
        "name_en": "3rd Quarter Winning Margin",
        "name_aa": "3rd Quarter Winning Margin",
        "name_de": "3. Viertel Siegesvorsprung",
        "name_nl": "3e kwart Winstmarge",
        "name_pl": "3rd Quarter Winning Margin",
        "name_ro": "3rd Quarter Winning Margin",
        "name_ru": "3rd Quarter Winning Margin",
        "name_tr": "3rd Quarter Winning Margin",
        "name_se": "3rd Quarter Winning Margin",
        "name_el": "3rd Quarter Winning Margin",
        "name_es": "3rd Quarter Winning Margin",
        "name_hr": "3. Cetvrtina Pobjeda Razlikom",
        "name_fr": "3rd Quarter Winning Margin"
    },
    "id_2154^live_match^7": {
        "name_en": "Asian Handicap Points",
        "name_aa": "Asian Handicap Points",
        "name_de": "Asian Handicap Punkte",
        "name_nl": "Asian Handicap punten",
        "name_pl": "Asian Handicap Points",
        "name_ro": "Asian Handicap Points",
        "name_ru": "Asian Handicap Points",
        "name_tr": "Asian Handicap Points",
        "name_se": "Asian Handicap Points",
        "name_el": "Asian Handicap Points",
        "name_es": "Asian Handicap Points",
        "name_hr": "Pobjednik sa Hendikepom poena",
        "name_fr": "Asian Handicap Points"
    },
    "id_79^live_match^7": {
        "name_en": "Who scores Xth point third set",
        "name_aa": "Who scores Xth point third set",
        "name_de": "Wer erzielt den x. Punkt im 3. Satz?",
        "name_nl": "Wie scoort het Xde punt derde set",
        "name_pl": "Who scores Xth point third set",
        "name_ro": "Who scores Xth point third set",
        "name_ru": "Who scores Xth point third set",
        "name_tr": "Üçüncü sette X. sayıyı kim kazanır?",
        "name_se": "Who scores Xth point third set",
        "name_el": "Who scores Xth point third set",
        "name_es": "Who scores Xth point third set",
        "name_hr": "Tko će osvojiti X-ti poen u trećem setu",
        "name_fr": "Qui va marquer le xième point troisième set"
    },
    "id_2225^live_match^8": {
        "name_en": "Total Hits",
        "name_aa": "Total Hits",
        "name_de": "Gesamthl Hits",
        "name_nl": "Totaal Hits",
        "name_pl": "Total Hits",
        "name_ro": "Total Hits",
        "name_ru": "Total Hits",
        "name_tr": "Total Hits",
        "name_se": "Total Hits",
        "name_el": "Total Hits",
        "name_es": "Total Hits",
        "name_hr": "Kraj udaraca",
        "name_fr": "Total Hits"
    },
    "id_1062^live_match^8": {
        "name_en": "Total home team [total] for [periodNr!] period",
        "name_aa": "Total home team [total] for [periodNr!] period",
        "name_de": "Gesamthl heimmannschaft [total] Für [periodNr!] drittel",
        "name_nl": "totaal thuisploeg [total] voor [periodNr!] periode",
        "name_pl": "Total home team [total] for [periodNr!] period",
        "name_ro": "Total home team [total] for [periodNr!] period",
        "name_ru": "Total home team [total] for [periodNr!] period",
        "name_tr": "[periodNr!] numaralı periyotta ev sahibi takım toplamı [total]",
        "name_se": "Total home team [total] for [periodNr!] period",
        "name_el": "Total home team [total] for [periodNr!] period",
        "name_es": "Total home team [total] for [periodNr!] period",
        "name_hr": "Kraj domaći tim [total] za [periodNr!] period",
        "name_fr": "Total équipe domicile [total] pour la période [periodNr!]"
    },
    "id_2315^live_match^8": {
        "name_en": "Match Tie Break Score after 6 Points",
        "name_aa": "Match Tie Break Score after 6 Points",
        "name_de": "Match Tie Break Score after 6 Points",
        "name_nl": "Wedstrijd Wedstrijdtiebreak Scoren na 6 punten",
        "name_pl": "Match Tie Break Score after 6 Points",
        "name_ro": "Match Tie Break Score after 6 Points",
        "name_ru": "Match Tie Break Score after 6 Points",
        "name_tr": "Match Tie Break Score after 6 Points",
        "name_se": "Match Tie Break Score after 6 Points",
        "name_el": "Match Tie Break Score after 6 Points",
        "name_es": "Match Tie Break Score after 6 Points",
        "name_hr": "Tie Break rezultat poslije 6 Poena",
        "name_fr": "Match Tie Break Score after 6 Points"
    },
    "id_2050^live_match^6": {
        "name_en": "Handicap for first quarter",
        "name_aa": "Handicap for first quarter",
        "name_de": "Handicap Für 1. Viertel",
        "name_nl": "Handicap voor 1. kwart",
        "name_pl": "Handicap for first quarter",
        "name_ro": "Handicap for first quarter",
        "name_ru": "Handicap for first quarter",
        "name_tr": "Handicap for first quarter",
        "name_se": "Handicap for first quarter",
        "name_el": "Handicap for first quarter",
        "name_es": "Handicap for first quarter",
        "name_hr": "1 Cetvrtina Hendikep",
        "name_fr": "Handicap for first quarter"
    },
    "id_2257^live_match^8": {
        "name_en": "Any Batter to Reach Base - Hometeam Inning",
        "name_aa": "Any Batter to Reach Base - Hometeam Inning",
        "name_de": "Any Batter to Reach Base - Hometeam Inning",
        "name_nl": "Any Batter to Reach Base - Hometeam Inning",
        "name_pl": "Any Batter to Reach Base - Hometeam Inning",
        "name_ro": "Any Batter to Reach Base - Hometeam Inning",
        "name_ru": "Any Batter to Reach Base - Hometeam Inning",
        "name_tr": "Any Batter to Reach Base - Hometeam Inning",
        "name_se": "Any Batter to Reach Base - Hometeam Inning",
        "name_el": "Any Batter to Reach Base - Hometeam Inning",
        "name_es": "Any Batter to Reach Base - Hometeam Inning",
        "name_hr": "Any Batter to Reach Base - Tim 1Inningom",
        "name_fr": "Any Batter to Reach Base - Hometeam Inning"
    },
    "id_2319^live_match^8": {
        "name_en": "Player2 Total Aces",
        "name_aa": "Player2 Total Aces",
        "name_de": "Spieler2- Gesamtzahl Asse",
        "name_nl": "Speler2- Totaal aantal Aces",
        "name_pl": "Player2 Total Aces",
        "name_ro": "Player2 Total Aces",
        "name_ru": "Player2 Total Aces",
        "name_tr": "Player2 Total Aces",
        "name_se": "Player2 Total Aces",
        "name_el": "Player2 Total Aces",
        "name_es": "Player2 Total Aces",
        "name_hr": "Igrač 2 ukupno aces",
        "name_fr": "Player2 Total Aces"
    },
    "id_2013^live_match^8": {
        "name_en": "2nd Quarter - Total awayteam",
        "name_aa": "2nd Quarter - Total awayteam",
        "name_de": "2. Viertel - Gesamthl Auswärtsmannschaft",
        "name_nl": "2e kwart - totaal Uitteam",
        "name_pl": "2nd Quarter - Total awayteam",
        "name_ro": "2nd Quarter - Total awayteam",
        "name_ru": "2nd Quarter - Total awayteam",
        "name_tr": "2nd Quarter - Total awayteam",
        "name_se": "2nd Quarter - Total awayteam",
        "name_el": "2nd Quarter - Total awayteam",
        "name_es": "2nd Quarter - Total awayteam",
        "name_hr": "2 Cetvrtina - Kraj Tim 2",
        "name_fr": "2nd Quarter - Total awayteam"
    },
    "id_2200^live_match^8": {
        "name_en": "2nd Half - Total hometeam",
        "name_aa": "2nd Half - Total hometeam",
        "name_de": "2. Halfen - Gesamthl heimmannschaft",
        "name_nl": "2e Helft - Totaal Thuisteam",
        "name_pl": "2nd Half - Total hometeam",
        "name_ro": "2nd Half - Total hometeam",
        "name_ru": "2nd Half - Total hometeam",
        "name_tr": "2nd Half - Total hometeam",
        "name_se": "2nd Half - Total hometeam",
        "name_el": "2nd Half - Total hometeam",
        "name_es": "2nd Half - Total hometeam",
        "name_hr": "2nd Poluvrijeme - Kraj Tim 1",
        "name_fr": "2nd Half - Total hometeam"
    },
    "id_1510^live_match^8": {
        "name_en": "Who takes the [corner!] corner",
        "name_aa": "Who takes the [corner!] corner",
        "name_de": "Wer nimmt die [corner!] eckball",
        "name_nl": "Wie neemt de [corner!] hoekschoppen",
        "name_pl": "Who takes the [corner!] corner",
        "name_ro": "Who takes the [corner!] corner",
        "name_ru": "Who takes the [corner!] corner",
        "name_tr": "[corner!] korneri kim kullanacak",
        "name_se": "Who takes the [corner!] corner",
        "name_el": "Who takes the [corner!] corner",
        "name_es": "Who takes the [corner!] corner",
        "name_hr": "Tko izvodi [corner!] udarac iz kuta",
        "name_fr": "Qui va avoir le [corner!] corner"
    },
    "id_2205^live_match^8": {
        "name_en": "Quarter - First Scoring Play",
        "name_aa": "Quarter - First Scoring Play",
        "name_de": "Viertel - 1. Punktgewinns",
        "name_nl": "kwart - 1e Eerste manier van scoren",
        "name_pl": "Quarter - First Scoring Play",
        "name_ro": "Quarter - First Scoring Play",
        "name_ru": "Quarter - First Scoring Play",
        "name_tr": "Quarter - First Scoring Play",
        "name_se": "Quarter - First Scoring Play",
        "name_el": "Quarter - First Scoring Play",
        "name_es": "Quarter - First Scoring Play",
        "name_hr": "Cetvrtina - prvi postize",
        "name_fr": "Quarter - First Scoring Play"
    },
    "id_85^live_match^8": {
        "name_en": "Total number of games in set 2",
        "name_aa": "Total number of games in set 2",
        "name_de": "Anzahl an Spielen im 2. Satz",
        "name_nl": "Totaal aantal games in set 2",
        "name_pl": "Total number of games in set 2",
        "name_ro": "Total number of games in set 2",
        "name_ru": "Total number of games in set 2",
        "name_tr": "2. Setteki toplam oyun sayısı",
        "name_se": "Total number of games in set 2",
        "name_el": "Total number of games in set 2",
        "name_es": "Total number of games in set 2",
        "name_hr": "Ukupan broj gemova u 2. setu",
        "name_fr": "Nombre total de jeux dans le 2e set"
    },
    "id_2149^live_match^8": {
        "name_en": "Alternative Winning Margin",
        "name_aa": "Alternative Winning Margin",
        "name_de": "Alternative Siegesvorsprung",
        "name_nl": "Extra Winstmarge",
        "name_pl": "Alternative Winning Margin",
        "name_ro": "Alternative Winning Margin",
        "name_ru": "Alternative Winning Margin",
        "name_tr": "Alternative Winning Margin",
        "name_se": "Alternative Winning Margin",
        "name_el": "Alternative Winning Margin",
        "name_es": "Alternative Winning Margin",
        "name_hr": "Alternative Pobjeda Razlikom",
        "name_fr": "Alternative Winning Margin"
    },
    "id_2328^live_match^8": {
        "name_en": "Game Server to Win to 0/15/30",
        "name_aa": "Game Server to Win to 0/15/30",
        "name_de": "Aufschlagspiel- Aufschläger gewinnt zu 0/15/30",
        "name_nl": "Game- Server wint tegen 0/15/30",
        "name_pl": "Game Server to Win to 0/15/30",
        "name_ro": "Game Server to Win to 0/15/30",
        "name_ru": "Game Server to Win to 0/15/30",
        "name_tr": "Game Server to Win to 0/15/30",
        "name_se": "Game Server to Win to 0/15/30",
        "name_el": "Game Server to Win to 0/15/30",
        "name_es": "Game Server to Win to 0/15/30",
        "name_hr": "Da osvoji game sa 0/15/30",
        "name_fr": "Game Server to Win to 0/15/30"
    },
    "id_2355^live_match^8": {
        "name_en": "Match Winner",
        "name_aa": "Match Winner",
        "name_de": "spiel Winner",
        "name_nl": "Wedstrijd Winnaar",
        "name_pl": "Match Winner",
        "name_ro": "Match Winner",
        "name_ru": "Match Winner",
        "name_tr": "Match Winner",
        "name_se": "Match Winner",
        "name_el": "Match Winner",
        "name_es": "Match Winner",
        "name_hr": "Pobjednik meca",
        "name_fr": "Match Winner"
    },
    "id_61^live_match^7": {
        "name_en": "Draw No Bet first half",
        "name_aa": "Draw No Bet first half",
        "name_de": "Sieger - Kein Unentschieden 1. Halbzeit",
        "name_nl": "Draw No Bet voor eerste helft",
        "name_pl": "Draw No Bet first half",
        "name_ro": "Draw No Bet first half",
        "name_ru": "Draw No Bet first half",
        "name_tr": "Beraberlikte iade ilk yarı",
        "name_se": "Draw No Bet first half",
        "name_el": "Draw No Bet first half",
        "name_es": "Draw No Bet first half",
        "name_hr": "Ishod bez neodlučenog prvo poluvrijeme",
        "name_fr": "Remboursé si Match nul première mi-temps"
    },
    "id_2051^live_match^6": {
        "name_en": "Handicap for second quarter",
        "name_aa": "Handicap for second quarter",
        "name_de": "Handicap Für 2. Viertel",
        "name_nl": "Handicap voor 2e kwart",
        "name_pl": "Handicap for second quarter",
        "name_ro": "Handicap for second quarter",
        "name_ru": "Handicap for second quarter",
        "name_tr": "Handicap for second quarter",
        "name_se": "Handicap for second quarter",
        "name_el": "Handicap for second quarter",
        "name_es": "Handicap for second quarter",
        "name_hr": "2 Cetvrtina Hendikep",
        "name_fr": "Handicap for second quarter"
    },
    "id_2176^live_match^8": {
        "name_en": "1st Half Goals",
        "name_aa": "1st Half Goals",
        "name_de": "Tore in 1. Halbzeit",
        "name_nl": "1e Helft doelpunts",
        "name_pl": "1st Half Goals",
        "name_ro": "1st Half Goals",
        "name_ru": "1st Half Goals",
        "name_tr": "1st Half Goals",
        "name_se": "1st Half Goals",
        "name_el": "1st Half Goals",
        "name_es": "1st Half Goals",
        "name_hr": "1. Poluvrijeme Golova",
        "name_fr": "1st Half Goals"
    },
    "id_2224^live_match^8": {
        "name_en": "Total Runs awayteam",
        "name_aa": "Total Runs awayteam",
        "name_de": "Gesamthl Runs Auswärtsmannschaft",
        "name_nl": "Totaal aantal run Uitteam",
        "name_pl": "Total Runs awayteam",
        "name_ro": "Total Runs awayteam",
        "name_ru": "Total Runs awayteam",
        "name_tr": "Total Runs awayteam",
        "name_se": "Total Runs awayteam",
        "name_el": "Total Runs awayteam",
        "name_es": "Total Runs awayteam",
        "name_hr": "Kraj Runs Tim 2",
        "name_fr": "Total Runs awayteam"
    },
    "id_36^live_match^7": {
        "name_en": "Asian handicap first half",
        "name_aa": "Asian handicap first half",
        "name_de": "Asian Handicap erste Halbzeit",
        "name_nl": "Aziatische handicap rust",
        "name_pl": "Asian handicap first half",
        "name_ro": "Asian handicap first half",
        "name_ru": "Asian handicap first half",
        "name_tr": "Asya handikabı ilk yarı",
        "name_se": "Asian handicap first half",
        "name_el": "Asian handicap first half",
        "name_es": "Asian handicap first half",
        "name_hr": "Azijski Hendikep prvo poluvrijeme",
        "name_fr": "Handicap asiatique première mi-temps"
    },
    "id_954^live_match^6": {
        "name_en": "3way for first period",
        "name_aa": "3way for first period",
        "name_de": "3Weg für ersten Abschnitt",
        "name_nl": "3way voor eerste period",
        "name_pl": "3way for first period",
        "name_ro": "3way for first period",
        "name_ru": "3way for first period",
        "name_tr": "İlk periyot için 3 yol",
        "name_se": "3way for first period",
        "name_el": "3way for first period",
        "name_es": "3way for first period",
        "name_hr": "1X2 za prvi period",
        "name_fr": "1x2 pour la première période"
    },
    "id_2065^live_match^6": {
        "name_en": "1st Quarter Margin of Victory",
        "name_aa": "1st Quarter Margin of Victory",
        "name_de": "1. Viertel Siegesvorsprung",
        "name_nl": "1e kwart Winstmarge",
        "name_pl": "1st Quarter Margin of Victory",
        "name_ro": "1st Quarter Margin of Victory",
        "name_ru": "1st Quarter Margin of Victory",
        "name_tr": "1st Quarter Margin of Victory",
        "name_se": "1st Quarter Margin of Victory",
        "name_el": "1st Quarter Margin of Victory",
        "name_es": "1st Quarter Margin of Victory",
        "name_hr": "Pobjednik 1. cetvrtine sa razlikom",
        "name_fr": "1st Quarter Margin of Victory"
    },
    "id_2016^live_match^8": {
        "name_en": "4th Quarter - Total hometeam",
        "name_aa": "4th Quarter - Total hometeam",
        "name_de": "4. Viertel - Gesamthl heimmannschaft",
        "name_nl": "4th kwart - totaal Thuisteam",
        "name_pl": "4th Quarter - Total hometeam",
        "name_ro": "4th Quarter - Total hometeam",
        "name_ru": "4th Quarter - Total hometeam",
        "name_tr": "4th Quarter - Total hometeam",
        "name_se": "4th Quarter - Total hometeam",
        "name_el": "4th Quarter - Total hometeam",
        "name_es": "4th Quarter - Total hometeam",
        "name_hr": "4 Cetvrtina - Kraj Tim 1",
        "name_fr": "4th Quarter - Total hometeam"
    },
    "id_2168^live_match^8": {
        "name_en": "Double Result (3rd set / match)",
        "name_aa": "Double Result (3rd set / match)",
        "name_de": "Doppelte Ergebnis (3. satz / spiel )",
        "name_nl": "Dubbele Resultaat (3e set / Wedstrijd )",
        "name_pl": "Double Result (3rd set / match)",
        "name_ro": "Double Result (3rd set / match)",
        "name_ru": "Double Result (3rd set / match)",
        "name_tr": "Double Result (3rd set / match)",
        "name_se": "Double Result (3rd set / match)",
        "name_el": "Double Result (3rd set / match)",
        "name_es": "Double Result (3rd set / match)",
        "name_hr": "Dupli Rezultat (3. set / kraj)",
        "name_fr": "Double Result (3rd set / match)"
    },
    "id_1344^live_match^8": {
        "name_en": "2nd Half - Odd/Even, including overtime",
        "name_aa": "2nd Half - Odd/Even, including overtime",
        "name_de": "2. Halbzeit - Ungerade / Gerade, inklusive Nachspielzeit",
        "name_nl": "2de Helft - Oneven/Even, inclusief overtime",
        "name_pl": "2nd Half - Odd/Even, including overtime",
        "name_ro": "2nd Half - Odd/Even, including overtime",
        "name_ru": "2nd Half - Odd/Even, including overtime",
        "name_tr": "2. yarı - Tek/Çift, Uzatmalar dahil",
        "name_se": "2nd Half - Odd/Even, including overtime",
        "name_el": "2nd Half - Odd/Even, including overtime",
        "name_es": "2nd Half - Odd/Even, including overtime",
        "name_hr": "2. Poluvrijeme - Par/Nepar, uklj. produžetke",
        "name_fr": "Pair/Impair, prolongations incluses"
    },
    "id_2129^live_match^8": {
        "name_en": "Correct Score After 2 Periods",
        "name_aa": "Correct Score After 2 Periods",
        "name_de": "genaues erzielt After 2 drittel s",
        "name_nl": "Correcte Score na 2 Periode",
        "name_pl": "Correct Score After 2 Periods",
        "name_ro": "Correct Score After 2 Periods",
        "name_ru": "Correct Score After 2 Periods",
        "name_tr": "Correct Score After 2 Periods",
        "name_se": "Correct Score After 2 Periods",
        "name_el": "Correct Score After 2 Periods",
        "name_es": "Correct Score After 2 Periods",
        "name_hr": "Tačan rezultat poslije 2. perioda",
        "name_fr": "Correct Score After 2 Periods"
    },
    "id_2123^live_match^8": {
        "name_en": "Player 2 Totals",
        "name_aa": "Player 2 Totals",
        "name_de": "Spieler 2 - Gesamt",
        "name_nl": "Speler 2 Totaals",
        "name_pl": "Player 2 Totals",
        "name_ro": "Player 2 Totals",
        "name_ru": "Player 2 Totals",
        "name_tr": "Player 2 Totals",
        "name_se": "Player 2 Totals",
        "name_el": "Player 2 Totals",
        "name_es": "Player 2 Totals",
        "name_hr": "Igrač  2 ukupno",
        "name_fr": "Player 2 Totals"
    },
    "id_2120^live_match^8": {
        "name_en": "Go The Distance?",
        "name_aa": "Go The Distance?",
        "name_de": "Geht das Spiel über die volle Länge?",
        "name_nl": "Wedstrijd gaat naar 6-6 in de laatst mogelijke set",
        "name_pl": "Go The Distance?",
        "name_ro": "Go The Distance?",
        "name_ru": "Go The Distance?",
        "name_tr": "Go The Distance?",
        "name_se": "Go The Distance?",
        "name_el": "Go The Distance?",
        "name_es": "Go The Distance?",
        "name_hr": "Go The Distance?",
        "name_fr": "Go The Distance?"
    },
    "id_1533^live_match^8": {
        "name_en": "Will the [penalty!] penalty be scored",
        "name_aa": "Will the [penalty!] penalty be scored",
        "name_de": "Will the [penalty!] Elfmeter be scored",
        "name_nl": "Zal de strafschop [penalty!] gescoord worden?",
        "name_pl": "Will the [penalty!] penalty be scored",
        "name_ro": "Will the [penalty!] penalty be scored",
        "name_ru": "Will the [penalty!] penalty be scored",
        "name_tr": "[penalty!] penaltı atışı gole dönüşecek mi",
        "name_se": "Will the [penalty!] penalty be scored",
        "name_el": "Will the [penalty!] penalty be scored",
        "name_es": "Will the [penalty!] penalty be scored",
        "name_hr": "Hoće li [penalty!] penal biti uspješno realiziran",
        "name_fr": "Est-ce que le [penalty!] penalty sera marqué"
    },
    "id_2056^live_match^8": {
        "name_en": "3rd Quarter Double Chance",
        "name_aa": "3rd Quarter Double Chance",
        "name_de": "3. Viertel Doppelte chance",
        "name_nl": "3e kwart Dubbele Chance",
        "name_pl": "3rd Quarter Double Chance",
        "name_ro": "3rd Quarter Double Chance",
        "name_ru": "3rd Quarter Double Chance",
        "name_tr": "3rd Quarter Double Chance",
        "name_se": "3rd Quarter Double Chance",
        "name_el": "3rd Quarter Double Chance",
        "name_es": "3rd Quarter Double Chance",
        "name_hr": "3 Cetvrtina Doupla šansa",
        "name_fr": "3rd Quarter Double Chance"
    },
    "id_2346^live_match^8": {
        "name_en": "Next Minute Goal Kick [from]-[to]",
        "name_aa": "Next Minute Goal Kick [from]-[to]",
        "name_de": "nächste Minute Abstoß [from]-[to]",
        "name_nl": "Volgende Minute doelpunt Kick [from]-[to]",
        "name_pl": "Next Minute Goal Kick [from]-[to]",
        "name_ro": "Next Minute Goal Kick [from]-[to]",
        "name_ru": "Next Minute Goal Kick [from]-[to]",
        "name_tr": "Next Minute Goal Kick [from]-[to]",
        "name_se": "Next Minute Goal Kick [from]-[to]",
        "name_el": "Next Minute Goal Kick [from]-[to]",
        "name_es": "Next Minute Goal Kick [from]-[to]",
        "name_hr": "Minuti sljedeceg udarca [from]-[to]",
        "name_fr": "Next Minute Goal Kick [from]-[to]"
    },
    "id_2167^live_match^8": {
        "name_en": "Double Result (2nd set / match)",
        "name_aa": "Double Result (2nd set / match)",
        "name_de": "Doppelte Ergebnis (2. satz / spiel )",
        "name_nl": "Dubbele Resultaat (2e set / Wedstrijd )",
        "name_pl": "Double Result (2nd set / match)",
        "name_ro": "Double Result (2nd set / match)",
        "name_ru": "Double Result (2nd set / match)",
        "name_tr": "Double Result (2nd set / match)",
        "name_se": "Double Result (2nd set / match)",
        "name_el": "Double Result (2nd set / match)",
        "name_es": "Double Result (2nd set / match)",
        "name_hr": "Dupli Rezultat (2. set / kraj)",
        "name_fr": "Double Result (2nd set / match)"
    },
    "id_2326^live_match^7": {
        "name_en": "To Finish 3rd",
        "name_aa": "To Finish 3rd",
        "name_de": "Endet 3.",
        "name_nl": "Eindigt 3e",
        "name_pl": "To Finish 3rd",
        "name_ro": "To Finish 3rd",
        "name_ru": "To Finish 3rd",
        "name_tr": "To Finish 3rd",
        "name_se": "To Finish 3rd",
        "name_el": "To Finish 3rd",
        "name_es": "To Finish 3rd",
        "name_hr": "Da zavrsi 3.",
        "name_fr": "To Finish 3rd"
    },
    "id_2252^live_match^8": {
        "name_en": "Back to Back Hits in the Next Half Inning - Awayteam Inning",
        "name_aa": "Back to Back Hits in the Next Half Inning - Awayteam Inning",
        "name_de": "Back to Back Hits in the Next Half Inning - Awayteam Inning",
        "name_nl": "Back to Back Hits in the Next Half Inning - Awayteam Inning",
        "name_pl": "Back to Back Hits in the Next Half Inning - Awayteam Inning",
        "name_ro": "Back to Back Hits in the Next Half Inning - Awayteam Inning",
        "name_ru": "Back to Back Hits in the Next Half Inning - Awayteam Inning",
        "name_tr": "Back to Back Hits in the Next Half Inning - Awayteam Inning",
        "name_se": "Back to Back Hits in the Next Half Inning - Awayteam Inning",
        "name_el": "Back to Back Hits in the Next Half Inning - Awayteam Inning",
        "name_es": "Back to Back Hits in the Next Half Inning - Awayteam Inning",
        "name_hr": "Back to Back Hits in the Next Half Inning - Awayteam Inning",
        "name_fr": "Back to Back Hits in the Next Half Inning - Awayteam Inning"
    },
    "id_2260^live_match^8": {
        "name_en": "Winning Margin",
        "name_aa": "Winning Margin",
        "name_de": "Siegesvorsprung",
        "name_nl": "Winstmarge",
        "name_pl": "Winning Margin",
        "name_ro": "Winning Margin",
        "name_ru": "Winning Margin",
        "name_tr": "Winning Margin",
        "name_se": "Winning Margin",
        "name_el": "Winning Margin",
        "name_es": "Winning Margin",
        "name_hr": "Pobjeda sa razlikom",
        "name_fr": "Winning Margin"
    },
    "id_78^live_match^8": {
        "name_en": "Who scores Xth point second set",
        "name_aa": "Who scores Xth point second set",
        "name_de": "Who scores Xth point second set",
        "name_nl": "Who scores Xth point second set",
        "name_pl": "Who scores Xth point second set",
        "name_ro": "Who scores Xth point second set",
        "name_ru": "Who scores Xth point second set",
        "name_tr": "Who scores Xth point second set",
        "name_se": "Who scores Xth point second set",
        "name_el": "Who scores Xth point second set",
        "name_es": "Who scores Xth point second set",
        "name_hr": "Ko osvaja X poen drugog seta",
        "name_fr": "Who scores Xth point second set"
    },
    "id_1665^live_match^2": {
        "name_en": "1X2 80 min",
        "name_aa": "1X2 80 min",
        "name_de": "1X2 80 min",
        "name_nl": "1X2 80 min",
        "name_pl": "1X2 80 min",
        "name_ro": "1X2 80 min",
        "name_ru": "1X2 80 min",
        "name_tr": "1X2 80 min",
        "name_se": "1X2 80 min",
        "name_el": "1X2 80 min",
        "name_es": "1X2 80 min",
        "name_hr": "1X2 80 min",
        "name_fr": "1X2 80 min"
    },
    "id_119^live_match^8": {
        "name_en": "Highest Scoring Half",
        "name_aa": "Highest Scoring Half",
        "name_de": "Halbzeit mit den meisten Toren",
        "name_nl": "Helft met meeste doelpunten",
        "name_pl": "Highest Scoring Half",
        "name_ro": "Highest Scoring Half",
        "name_ru": "Highest Scoring Half",
        "name_tr": "En Fazla Skor Olan Devre",
        "name_se": "Highest Scoring Half",
        "name_el": "Highest Scoring Half",
        "name_es": "Highest Scoring Half",
        "name_hr": "Poluvrijeme sa najviše golova",
        "name_fr": "Mi-temps la plus prolifique au score"
    },
    "id_1214^live_match^8": {
        "name_en": "[frame!] frame - Odd/even number of points",
        "name_aa": "[frame!] frame - Odd/even number of points",
        "name_de": "[frame!] Frame - Gerade/ungerade Anzahl Punkte",
        "name_nl": "[frame!] frame - Oneven/Even aantal punten",
        "name_pl": "[frame!] frame - Odd/even number of points",
        "name_ro": "[frame!] frame - Odd/even number of points",
        "name_ru": "[frame!] frame - Odd/even number of points",
        "name_tr": "[frame!] Çerçeve - Tek/Çift puanlar toplamı",
        "name_se": "[frame!] frame - Odd/even number of points",
        "name_el": "[frame!] frame - Odd/even number of points",
        "name_es": "[frame!] frame - Odd/even number of points",
        "name_hr": "[frame!] frameova - Paran/Neparan broj poena",
        "name_fr": "[frame!] Manche - Pair/Impair nombre de points"
    },
    "id_2090^live_match^8": {
        "name_en": "Game Score After 4 Points",
        "name_aa": "Game Score After 4 Points",
        "name_de": "spiel erzielt After 4 Points",
        "name_nl": "Game Score na 4 punten",
        "name_pl": "Game Score After 4 Points",
        "name_ro": "Game Score After 4 Points",
        "name_ru": "Game Score After 4 Points",
        "name_tr": "Game Score After 4 Points",
        "name_se": "Game Score After 4 Points",
        "name_el": "Game Score After 4 Points",
        "name_es": "Game Score After 4 Points",
        "name_hr": "rezulat poslije 4 poena",
        "name_fr": "Game Score After 4 Points"
    },
    "id_78^live_match^7": {
        "name_en": "Who scores Xth point second set",
        "name_aa": "Who scores Xth point second set",
        "name_de": "Wer erzielt den x. Punkt im 2. Satz?",
        "name_nl": "Wie scoort het Xde punt tweede set",
        "name_pl": "Who scores Xth point second set",
        "name_ro": "Who scores Xth point second set",
        "name_ru": "Who scores Xth point second set",
        "name_tr": "Ikinci sette X. sayıyı kim kazanır?",
        "name_se": "Who scores Xth point second set",
        "name_el": "Who scores Xth point second set",
        "name_es": "Who scores Xth point second set",
        "name_hr": "Tko će osvojiti X-ti poen u drugom setu",
        "name_fr": "Qui va marquer le xième point deuxième set"
    },
    "id_62^live_match^8": {
        "name_en": "Odd/Even for first half",
        "name_aa": "Odd/Even for first half",
        "name_de": "Gerade/Ungerade 1. Halbzeit",
        "name_nl": "Oneven/Even voor eerste helft",
        "name_pl": "Odd/Even for first half",
        "name_ro": "Odd/Even for first half",
        "name_ru": "Odd/Even for first half",
        "name_tr": "İlk yarı için Tek/Çift",
        "name_se": "Odd/Even for first half",
        "name_el": "Odd/Even for first half",
        "name_es": "Odd/Even for first half",
        "name_hr": "Nepar/Par za prvo poluvrijeme",
        "name_fr": "Pair/Impair pour la première mi-temps"
    },
    "id_2334^live_match^8": {
        "name_en": "Hometeam Shots on Target",
        "name_aa": "Hometeam Shots on Target",
        "name_de": "Heimmannschaft- Schüsse auf das Tor",
        "name_nl": "Thuisteam- Schoten op doel",
        "name_pl": "Hometeam Shots on Target",
        "name_ro": "Hometeam Shots on Target",
        "name_ru": "Hometeam Shots on Target",
        "name_tr": "Hometeam Shots on Target",
        "name_se": "Hometeam Shots on Target",
        "name_el": "Hometeam Shots on Target",
        "name_es": "Hometeam Shots on Target",
        "name_hr": "Tim 1 udaraca u okvir",
        "name_fr": "Hometeam Shots on Target"
    },
    "id_1086^live_match^8": {
        "name_en": "Highest scoring period",
        "name_aa": "Highest scoring period",
        "name_de": "Abschnitt mit den meisten Treffern",
        "name_nl": "Period met hoogste score",
        "name_pl": "Highest scoring period",
        "name_ro": "Highest scoring period",
        "name_ru": "Highest scoring period",
        "name_tr": "En yüksek skorlu periyod",
        "name_se": "Highest scoring period",
        "name_el": "Highest scoring period",
        "name_es": "Highest scoring period",
        "name_hr": "Period s najviše golova",
        "name_fr": "Période la plus prolifique au score"
    },
    "id_2329^live_match^8": {
        "name_en": "Game Server to Win to 0/15",
        "name_aa": "Game Server to Win to 0/15",
        "name_de": "Aufschlagspiel- Aufschläger gewinnt zu 0/15",
        "name_nl": "Game- Server wint tegen 0/15",
        "name_pl": "Game Server to Win to 0/15",
        "name_ro": "Game Server to Win to 0/15",
        "name_ru": "Game Server to Win to 0/15",
        "name_tr": "Game Server to Win to 0/15",
        "name_se": "Game Server to Win to 0/15",
        "name_el": "Game Server to Win to 0/15",
        "name_es": "Game Server to Win to 0/15",
        "name_hr": "Da osvoji game sa 0/15",
        "name_fr": "Game Server to Win to 0/15"
    },
    "id_986^live_match^7": {
        "name_en": "Match winner, including super over",
        "name_aa": "Match winner, including super over",
        "name_de": "Spielsieger, inkl. Super Over",
        "name_nl": "Matchwinnaar, inclusief super over",
        "name_pl": "Match winner, including super over",
        "name_ro": "Match winner, including super over",
        "name_ru": "Match winner, including super over",
        "name_tr": "Oyunu kazanan, super over dahil",
        "name_se": "Match winner, including super over",
        "name_el": "Match winner, including super over",
        "name_es": "Match winner, including super over",
        "name_hr": "Pobjednik meča, uključujući super over",
        "name_fr": "Vainqueur du match, over éliminatoire inclus"
    },
    "id_2075^live_match^8": {
        "name_en": "Set 4 Correct Score",
        "name_aa": "Set 4 Correct Score",
        "name_de": "satz 4 genaues erzielt",
        "name_nl": "Set 4 Correcte Scoren",
        "name_pl": "Set 4 Correct Score",
        "name_ro": "Set 4 Correct Score",
        "name_ru": "Set 4 Correct Score",
        "name_tr": "Set 4 Correct Score",
        "name_se": "Set 4 Correct Score",
        "name_el": "Set 4 Correct Score",
        "name_es": "Set 4 Correct Score",
        "name_hr": "Set 4 Tačan rezultat",
        "name_fr": "Set 4 Correct Score"
    },
    "id_1664^live_match^2": {
        "name_en": "1X2 70 min",
        "name_aa": "1X2 70 min",
        "name_de": "1X2 70 min",
        "name_nl": "1X2 70 min",
        "name_pl": "1X2 70 min",
        "name_ro": "1X2 70 min",
        "name_ru": "1X2 70 min",
        "name_tr": "1X2 70 min",
        "name_se": "1X2 70 min",
        "name_el": "1X2 70 min",
        "name_es": "1X2 70 min",
        "name_hr": "1X2 70 min",
        "name_fr": "1X2 70 min"
    },
    "id_2052^live_match^6": {
        "name_en": "Handicap for third quarter",
        "name_aa": "Handicap for third quarter",
        "name_de": "Handicap Für 3. Viertel",
        "name_nl": "Handicap voor 3e kwart",
        "name_pl": "Handicap for third quarter",
        "name_ro": "Handicap for third quarter",
        "name_ru": "Handicap for third quarter",
        "name_tr": "Handicap for third quarter",
        "name_se": "Handicap for third quarter",
        "name_el": "Handicap for third quarter",
        "name_es": "Handicap for third quarter",
        "name_hr": "3 Cetvrtina Hendikep",
        "name_fr": "Handicap for third quarter"
    },
    "id_2249^live_match^8": {
        "name_en": "Back to Back Hits in the Current Half Inning - Hometeam Inning",
        "name_aa": "Back to Back Hits in the Current Half Inning - Hometeam Inning",
        "name_de": "Back to Back Hits in the Current Half Inning - Hometeam Inning",
        "name_nl": "Back to Back Hits in the Current Half Inning - Hometeam Inning",
        "name_pl": "Back to Back Hits in the Current Half Inning - Hometeam Inning",
        "name_ro": "Back to Back Hits in the Current Half Inning - Hometeam Inning",
        "name_ru": "Back to Back Hits in the Current Half Inning - Hometeam Inning",
        "name_tr": "Back to Back Hits in the Current Half Inning - Hometeam Inning",
        "name_se": "Back to Back Hits in the Current Half Inning - Hometeam Inning",
        "name_el": "Back to Back Hits in the Current Half Inning - Hometeam Inning",
        "name_es": "Back to Back Hits in the Current Half Inning - Hometeam Inning",
        "name_hr": "Back to Back Hits in the Current Half Inning - Hometeam Inning",
        "name_fr": "Back to Back Hits in the Current Half Inning - Hometeam Inning"
    },
    "id_2258^live_match^8": {
        "name_en": "Any Batter to Reach Base - Awayteam Inning",
        "name_aa": "Any Batter to Reach Base - Awayteam Inning",
        "name_de": "Any Batter to Reach Base - Awayteam Inning",
        "name_nl": "Any Batter to Reach Base - Awayteam Inning",
        "name_pl": "Any Batter to Reach Base - Awayteam Inning",
        "name_ro": "Any Batter to Reach Base - Awayteam Inning",
        "name_ru": "Any Batter to Reach Base - Awayteam Inning",
        "name_tr": "Any Batter to Reach Base - Awayteam Inning",
        "name_se": "Any Batter to Reach Base - Awayteam Inning",
        "name_el": "Any Batter to Reach Base - Awayteam Inning",
        "name_es": "Any Batter to Reach Base - Awayteam Inning",
        "name_hr": "Any Batter to Reach Base - Tim 2Inningom",
        "name_fr": "Any Batter to Reach Base - Awayteam Inning"
    },
    "id_2192^live_match^8": {
        "name_en": "Total Goals awayteam for Quarter",
        "name_aa": "Total Goals awayteam for Quarter",
        "name_de": "Gesamthl Tore Auswärtsmannschaft Für Viertel",
        "name_nl": "Totaal doelpunts Uitteam voor kwart",
        "name_pl": "Total Goals awayteam for Quarter",
        "name_ro": "Total Goals awayteam for Quarter",
        "name_ru": "Total Goals awayteam for Quarter",
        "name_tr": "Total Goals awayteam for Quarter",
        "name_se": "Total Goals awayteam for Quarter",
        "name_el": "Total Goals awayteam for Quarter",
        "name_es": "Total Goals awayteam for Quarter",
        "name_hr": "Kraj Golova Tim 2 za Cetvrtinu",
        "name_fr": "Total Goals awayteam for Quarter"
    },
    "id_2010^live_match^8": {
        "name_en": "1st Quarter - Total hometeam",
        "name_aa": "1st Quarter - Total hometeam",
        "name_de": "1. Viertel - Gesamthl heimmannschaft",
        "name_nl": "1e kwart - totaal Thuisteam",
        "name_pl": "1st Quarter - Total hometeam",
        "name_ro": "1st Quarter - Total hometeam",
        "name_ru": "1st Quarter - Total hometeam",
        "name_tr": "1st Quarter - Total hometeam",
        "name_se": "1st Quarter - Total hometeam",
        "name_el": "1st Quarter - Total hometeam",
        "name_es": "1st Quarter - Total hometeam",
        "name_hr": "1 Cetvrtina - Kraj Tim 1",
        "name_fr": "1st Quarter - Total hometeam"
    },
    "id_2354^live_match^8": {
        "name_en": "Correct score",
        "name_aa": "Correct score",
        "name_de": "Genaues Ergebnis",
        "name_nl": "Juiste uitslag",
        "name_pl": "Correct score",
        "name_ro": "Correct score",
        "name_ru": "Correct score",
        "name_tr": "Correct score",
        "name_se": "Correct score",
        "name_el": "Correct score",
        "name_es": "Correct score",
        "name_hr": "Tacan Rezultat",
        "name_fr": "Correct score"
    },
    "id_910^live_match^8": {
        "name_en": "Total [total] for end [endNr] of the [setNr!] set",
        "name_aa": "Total [total] for end [endNr] of the [setNr!] set",
        "name_de": "Gesamthl [total] Für end [endNr] of the [setNr!] satz",
        "name_nl": "Totaal [total] voor einde [endNr] van set",
        "name_pl": "Total [total] for end [endNr] of the [setNr!] set",
        "name_ro": "Total [total] for end [endNr] of the [setNr!] set",
        "name_ru": "Total [total] for end [endNr] of the [setNr!] set",
        "name_tr": "[setNr!] numaralı setin [endNr] numaralı end'i için Toplam [total]",
        "name_se": "Total [total] for end [endNr] of the [setNr!] set",
        "name_el": "Total [total] for end [endNr] of the [setNr!] set",
        "name_es": "Total [total] for end [endNr] of the [setNr!] set",
        "name_hr": "ukupno [total] za kraj [endNr] u [setNr!]. setu",
        "name_fr": "Total [total] pour la manche [endNr] du [setNr!] set"
    },
    "id_5^live_match^7": {
        "name_en": "Which team will win the penalty shootout?",
        "name_aa": "Which team will win the penalty shootout?",
        "name_de": "Wer gewinnt das Elfmeterschießen?",
        "name_nl": "Welk team zal de penalty shootout winnen ?",
        "name_pl": "Which team will win the penalty shootout?",
        "name_ro": "Which team will win the penalty shootout?",
        "name_ru": "Which team will win the penalty shootout?",
        "name_tr": "Penaltı atışlarını hangi takım kazanır?",
        "name_se": "Which team will win the penalty shootout?",
        "name_el": "Which team will win the penalty shootout?",
        "name_es": "Which team will win the penalty shootout?",
        "name_hr": "Koja momčad će pobijediti u izvođenju jedanaesteraca?",
        "name_fr": "Quelle équipe gagnera la séance de tirs aux buts ?"
    },
    "id_1673^live_match^8": {
        "name_en": "Game Won After Penalties?",
        "name_aa": "Game Won After Penalties?",
        "name_de": "spiel Won After Penalties?",
        "name_nl": "Spel Won na Strafschoppen?",
        "name_pl": "Game Won After Penalties?",
        "name_ro": "Game Won After Penalties?",
        "name_ru": "Game Won After Penalties?",
        "name_tr": "Game Won After Penalties?",
        "name_se": "Game Won After Penalties?",
        "name_el": "Game Won After Penalties?",
        "name_es": "Game Won After Penalties?",
        "name_hr": "Pobjeda poslije penala?",
        "name_fr": "Game Won After Penalties?"
    },
    "id_2316^live_match^8": {
        "name_en": "Match Tie-Break Score",
        "name_aa": "Match Tie-Break Score",
        "name_de": "spiel Tie-Break ergebnis",
        "name_nl": "Wedstrijd Tie-Break Scoren",
        "name_pl": "Match Tie-Break Score",
        "name_ro": "Match Tie-Break Score",
        "name_ru": "Match Tie-Break Score",
        "name_tr": "Match Tie-Break Score",
        "name_se": "Match Tie-Break Score",
        "name_el": "Match Tie-Break Score",
        "name_es": "Match Tie-Break Score",
        "name_hr": "Tie break rezultat",
        "name_fr": "Match Tie-Break Score"
    },
    "id_2215^live_match^8": {
        "name_en": "Winning Margin 5-Way Alt 1",
        "name_aa": "Winning Margin 5-Way Alt 1",
        "name_de": "Siegesvorsprung 5-Way Alt 1",
        "name_nl": "Winstmarge 5-opties Alt 1",
        "name_pl": "Winning Margin 5-Way Alt 1",
        "name_ro": "Winning Margin 5-Way Alt 1",
        "name_ru": "Winning Margin 5-Way Alt 1",
        "name_tr": "Winning Margin 5-Way Alt 1",
        "name_se": "Winning Margin 5-Way Alt 1",
        "name_el": "Winning Margin 5-Way Alt 1",
        "name_es": "Winning Margin 5-Way Alt 1",
        "name_hr": "Pobjeda Razlikom 5-Way Alt 1",
        "name_fr": "Winning Margin 5-Way Alt 1"
    },
    "id_2091^live_match^8": {
        "name_en": "Player 1 To Win from Behind",
        "name_aa": "Player 1 To Win from Behind",
        "name_de": "Player 1 zu gewinnen from Behind",
        "name_nl": "Speler 1 Wint na achterstand",
        "name_pl": "Player 1 To Win from Behind",
        "name_ro": "Player 1 To Win from Behind",
        "name_ru": "Player 1 To Win from Behind",
        "name_tr": "Player 1 To Win from Behind",
        "name_se": "Player 1 To Win from Behind",
        "name_el": "Player 1 To Win from Behind",
        "name_es": "Player 1 To Win from Behind",
        "name_hr": "Igrač 1 da preokrene",
        "name_fr": "Player 1 To Win from Behind"
    }
}

export const PREMATCH_MARKET_DATA = {
    "id_203^prematch": {
        "name_en": "{!setnr} set - game handicap",
        "name_aa": "{!setnr} set - game handicap",
        "name_de": "{!setnr} Satz - Spiel Handicap",
        "name_nl": "{setnr} set - game handicap",
        "name_pl": "{!setnr} set - game handicap",
        "name_ro": "{!setnr} set - game handicap",
        "name_ru": "{!setnr} set - game handicap",
        "name_tr": "{!setnr}. set - oyun handikapı",
        "name_se": "{!setnr} set - game handicap",
        "name_el": "{!setnr} set - game handicap",
        "name_es": "{!setnr} set - game handicap",
        "name_hr": "{!setnr} set - gem hendikep",
        "name_fr": "{!setnr} set - jeux handicap"
    },
    "id_1122^prematch": {
        "name_en": "{$competitor2} total home runs (incl. extra innings)",
        "name_aa": "{$competitor2} total home runs (incl. extra innings)",
        "name_de": "{$competitor2} Home Runs Gesamt (inkl. Extra Innings)",
        "name_nl": "Totaal aantal homeruns {$competitor2} (incl. extra innings)",
        "name_pl": "{$competitor2} total home runs (incl. extra innings)",
        "name_ro": "{$competitor2} total home runs (incl. extra innings)",
        "name_ru": "{$competitor2} total home runs (incl. extra innings)",
        "name_tr": "{$competitor2} total home runs (incl. extra innings)",
        "name_se": "{$competitor2} total home runs (incl. extra innings)",
        "name_el": "{$competitor2} total home runs (incl. extra innings)",
        "name_es": "{$competitor2} total home runs (incl. extra innings)",
        "name_hr": "{$competitor2} total home runs (incl. extra innings)",
        "name_fr": "{$competitor2} nombre de coup de circuits (homeruns) (prolongations incluses)"
    },
    "id_56^prematch": {
        "name_en": "{$competitor1} to score in both halves",
        "name_aa": "{$competitor1} to score in both halves",
        "name_de": "{$competitor1} wird beide Hälften treffen",
        "name_nl": "{$competitor1} scoort in beide helften",
        "name_pl": "{$competitor1} to score in both halves",
        "name_ro": "{$competitor1} to score in both halves",
        "name_ru": "{$competitor1} to score in both halves",
        "name_tr": "{$competitor1} her iki devrede de gol bulur",
        "name_se": "{$competitor1} to score in both halves",
        "name_el": "{$competitor1} to score in both halves",
        "name_es": "{$competitor1} to score in both halves",
        "name_hr": "{$competitor1} daje gol u oba poluvremena",
        "name_fr": "{$competitor1} qui marque dans les deux périodes"
    },
    "id_45^prematch": {
        "name_en": "Correct score",
        "name_aa": "Correct score",
        "name_de": "Genaues Ergebnis",
        "name_nl": "Juiste uitslag",
        "name_pl": "Correct score",
        "name_ro": "Correct score",
        "name_ru": "Correct score",
        "name_tr": "Maç sonucu",
        "name_se": "Correct score",
        "name_el": "Correct score",
        "name_es": "Correct score",
        "name_hr": "Točan rezultat",
        "name_fr": "Score correct"
    },
    "id_538^prematch": {
        "name_en": "Head2head (1x2)",
        "name_aa": "Head2head (1x2)",
        "name_de": "Head2head (1x2)",
        "name_nl": "Head2head (1x2)",
        "name_pl": "Head2head (1x2)",
        "name_ro": "Head2head (1x2)",
        "name_ru": "Head2head (1x2)",
        "name_tr": "Head to head (1x2)",
        "name_se": "Head2head (1x2)",
        "name_el": "Head2head (1x2)",
        "name_es": "Head2head (1x2)",
        "name_hr": "Međusobni omjer (1x2)",
        "name_fr": "Face à face (1x2)"
    },
    "id_143^prematch": {
        "name_en": "{$competitor1} exact bookings",
        "name_aa": "{$competitor1} exact bookings",
        "name_de": "{$competitor1} Genaue Anzahl Karten",
        "name_nl": "Exact aantal kaarten {$competitor1}",
        "name_pl": "{$competitor1} exact bookings",
        "name_ro": "{$competitor1} exact bookings",
        "name_ru": "{$competitor1} exact bookings",
        "name_tr": "{$competitor1} doğru kart sayısı",
        "name_se": "{$competitor1} exact bookings",
        "name_el": "{$competitor1} exact bookings",
        "name_es": "{$competitor1} exact bookings",
        "name_hr": "{$competitor1} Točno kartona",
        "name_fr": "{$competitor1} nombre exact de cartons"
    },
    "id_1120^prematch": {
        "name_en": "Total home runs (incl. extra innings)",
        "name_aa": "Total home runs (incl. extra innings)",
        "name_de": "Home Runs Gesamt (inkl. Extra Innings)",
        "name_nl": "Totaal aantal homeruns (incl. extra innings)",
        "name_pl": "Total home runs (incl. extra innings)",
        "name_ro": "Total home runs (incl. extra innings)",
        "name_ru": "Total home runs (incl. extra innings)",
        "name_tr": "Total home runs (incl. extra innings)",
        "name_se": "Total home runs (incl. extra innings)",
        "name_el": "Total home runs (incl. extra innings)",
        "name_es": "Total home runs (incl. extra innings)",
        "name_hr": "Total home runs (incl. extra innings)",
        "name_fr": "Nombre de coup de circuits (homeruns) (prolongations incluses)"
    },
    "id_26^prematch": {
        "name_en": "Odd/even",
        "name_aa": "Odd/even",
        "name_de": "Gerade/ungerade",
        "name_nl": "Even/oneven",
        "name_pl": "Odd/even",
        "name_ro": "Odd/even",
        "name_ru": "Odd/even",
        "name_tr": "tek/çift",
        "name_se": "Odd/even",
        "name_el": "Odd/even",
        "name_es": "Odd/even",
        "name_hr": "Par/Nepar",
        "name_fr": "Pair/Impair"
    },
    "id_412^prematch": {
        "name_en": "Total (incl. overtime and penalties)",
        "name_aa": "Total (incl. overtime and penalties)",
        "name_de": "Total (inkl. Verlängerung und Elfmeterschießen)",
        "name_nl": "Totaal (verlenging en strafschoppen inbegrepen)",
        "name_pl": "Total (incl. overtime and penalties)",
        "name_ro": "Total (incl. overtime and penalties)",
        "name_ru": "Total (incl. overtime and penalties)",
        "name_tr": "Toplam gol (uzatmalar ve penaltılar dahil)",
        "name_se": "Total (incl. overtime and penalties)",
        "name_el": "Total (incl. overtime and penalties)",
        "name_es": "Total (incl. overtime and penalties)",
        "name_hr": "Total (uklj. produžetke i penale)",
        "name_fr": "Total (inclus prolongations et séance de tirs au but)"
    },
    "id_176^prematch": {
        "name_en": "1st half - corner handicap",
        "name_aa": "1st half - corner handicap",
        "name_de": "1. Halbzeit - Ecken Handicap",
        "name_nl": "Eerste helft - hoekschop handicap",
        "name_pl": "1st half - corner handicap",
        "name_ro": "1st half - corner handicap",
        "name_ru": "1st half - corner handicap",
        "name_tr": "İlk yarı - korner handikap",
        "name_se": "1st half - corner handicap",
        "name_el": "1st half - corner handicap",
        "name_es": "1st half - corner handicap",
        "name_hr": "1. Poluvrijeme - Udarci iz kuta hendikep",
        "name_fr": "1ère mi-temps - corners handicap"
    },
    "id_482^prematch": {
        "name_en": "1st half - try 1x2",
        "name_aa": "1st half - try 1x2",
        "name_de": "1. Halbzeit - Versuch 1x2",
        "name_nl": "Eerste helft - try 1x2",
        "name_pl": "1st half - try 1x2",
        "name_ro": "1st half - try 1x2",
        "name_ru": "1st half - try 1x2",
        "name_tr": "İlk yarı - try 1x2",
        "name_se": "1st half - try 1x2",
        "name_el": "1st half - try 1x2",
        "name_es": "1st half - try 1x2",
        "name_hr": "1. poluvrijeme - polaganje 1x2",
        "name_fr": "1ère mi-temps - Essais 1x2"
    },
    "id_48^prematch": {
        "name_en": "{$competitor1} to win both halves",
        "name_aa": "{$competitor1} to win both halves",
        "name_de": "{$competitor1} wird beide Hälften gewinnen",
        "name_nl": "{$competitor1} wint beide helften",
        "name_pl": "{$competitor1} to win both halves",
        "name_ro": "{$competitor1} to win both halves",
        "name_ru": "{$competitor1} to win both halves",
        "name_tr": "{$competitor1} her iki devreyi de kazanma",
        "name_se": "{$competitor1} to win both halves",
        "name_el": "{$competitor1} to win both halves",
        "name_es": "{$competitor1} to win both halves",
        "name_hr": "{$competitor1} pobijeđuje u oba poluvremena",
        "name_fr": "{$competitor1} gagne les deux périodes"
    },
    "id_59^prematch": {
        "name_en": "Both halves under {total}",
        "name_aa": "Both halves under {total}",
        "name_de": "Beide Hälften unter {total}",
        "name_nl": "Beide helften minder dan {total}",
        "name_pl": "Both halves under {total}",
        "name_ro": "Both halves under {total}",
        "name_ru": "Both halves under {total}",
        "name_tr": "Her iki yarı {total} altı",
        "name_se": "Both halves under {total}",
        "name_el": "Both halves under {total}",
        "name_es": "Both halves under {total}",
        "name_hr": "Oba poluvremena manje od {total}",
        "name_fr": "Les deux mi-temps Moins de {total}"
    },
    "id_157^prematch": {
        "name_en": "1st half - {$competitor2} exact bookings",
        "name_aa": "1st half - {$competitor2} exact bookings",
        "name_de": "1. Halbzeit -  {$competitor2} Genaue Anzahl Karten",
        "name_nl": "Eerste helft - exact aantal kaarten {$competitor2}",
        "name_pl": "1st half - {$competitor2} exact bookings",
        "name_ro": "1st half - {$competitor2} exact bookings",
        "name_ru": "1st half - {$competitor2} exact bookings",
        "name_tr": "İlk yarı - {$competitor2} doğru kart sayısı",
        "name_se": "1st half - {$competitor2} exact bookings",
        "name_el": "1st half - {$competitor2} exact bookings",
        "name_es": "1st half - {$competitor2} exact bookings",
        "name_hr": "1. Poluvrijeme - {$competitor2} Točno kartona",
        "name_fr": "1ère mi-temps - {$competitor2} nombre exact de cartons"
    },
    "id_316^prematch": {
        "name_en": "{!setnr} set - draw no bet",
        "name_aa": "{!setnr} set - draw no bet",
        "name_de": "{!setnr} Satz - unentschieden, keine Wette",
        "name_nl": "{setnr} set - draw no bet",
        "name_pl": "{!setnr} set - draw no bet",
        "name_ro": "{!setnr} set - draw no bet",
        "name_ru": "{!setnr} set - draw no bet",
        "name_tr": "{!setnr}. set - beraberlikte iade",
        "name_se": "{!setnr} set - draw no bet",
        "name_el": "{!setnr} set - draw no bet",
        "name_es": "{!setnr} set - draw no bet",
        "name_hr": "{!setnr} set - ishod bez neodlučenog",
        "name_fr": "{!setnr} set - remboursé si match nul"
    },
    "id_16350000^prematch": {
        "name_en": "How many corners will be taken in 2nd half ?",
        "name_aa": "How many corners will be taken in 2nd half ?",
        "name_de": "How many corners will be taken in 2nd half ?",
        "name_nl": "How many corners will be taken in 2nd half ?",
        "name_pl": "How many corners will be taken in 2nd half ?",
        "name_ro": "How many corners will be taken in 2nd half ?",
        "name_ru": "How many corners will be taken in 2nd half ?",
        "name_tr": "How many corners will be taken in 2nd half ?",
        "name_se": "How many corners will be taken in 2nd half ?",
        "name_el": "How many corners will be taken in 2nd half ?",
        "name_es": "How many corners will be taken in 2nd half ?",
        "name_hr": "How many corners will be taken in 2nd half ?",
        "name_fr": "How many corners will be taken in 2nd half ?"
    },
    "id_619^prematch": {
        "name_en": "1st half - {$competitor2} odd/even",
        "name_aa": "1st half - {$competitor2} odd/even",
        "name_de": "1. Halbzeit - {$competitor2} Gerade/ungerade",
        "name_nl": "Eerste helft - {$competitor2} even/oneven",
        "name_pl": "1st half - {$competitor2} odd/even",
        "name_ro": "1st half - {$competitor2} odd/even",
        "name_ru": "1st half - {$competitor2} odd/even",
        "name_tr": "İlk yarı - {$competitor2} tek/çift",
        "name_se": "1st half - {$competitor2} odd/even",
        "name_el": "1st half - {$competitor2} odd/even",
        "name_es": "1st half - {$competitor2} odd/even",
        "name_hr": "1. poluvrijeme - {$competitor2} par/nepar",
        "name_fr": "1ère mi-temps - {$competitor2} pair/impair"
    },
    "id_90^prematch": {
        "name_en": "2nd half - total",
        "name_aa": "2nd half - total",
        "name_de": "2. Halbzeit - Total",
        "name_nl": "2e helft - totaal",
        "name_pl": "2nd half - total",
        "name_ro": "2nd half - total",
        "name_ru": "2nd half - total",
        "name_tr": "İkinci yarı - toplam gol",
        "name_se": "2nd half - total",
        "name_el": "2nd half - total",
        "name_es": "2nd half - total",
        "name_hr": "2. Poluvrijeme  - ukupno",
        "name_fr": "2ème mi-temps - total"
    },
    "id_245^prematch": {
        "name_en": "{!gamenr} game - winner",
        "name_aa": "{!gamenr} game - winner",
        "name_de": "{!gamenr} Spiel - Gewinner",
        "name_nl": "{gamenr} game - winnaar",
        "name_pl": "{!gamenr} game - winner",
        "name_ro": "{!gamenr} game - winner",
        "name_ru": "{!gamenr} game - winner",
        "name_tr": "{!gamenr}. oyun - kazanan",
        "name_se": "{!gamenr} game - winner",
        "name_el": "{!gamenr} game - winner",
        "name_es": "{!gamenr} game - winner",
        "name_hr": "{!gamenr} gem - pobjednik",
        "name_fr": "{!gamenr} jeu - vainqueur"
    },
    "id_276^prematch": {
        "name_en": "Innings 1 to 5 - total",
        "name_aa": "Innings 1 to 5 - total",
        "name_de": "Innings 1 bis 5 - Total",
        "name_nl": "Inning 1 tot 5 - totaal",
        "name_pl": "Innings 1 to 5 - total",
        "name_ro": "Innings 1 to 5 - total",
        "name_ru": "Innings 1 to 5 - total",
        "name_tr": "Devreler 1 den 5 e - toplam",
        "name_se": "Innings 1 to 5 - total",
        "name_el": "Innings 1 to 5 - total",
        "name_es": "Innings 1 to 5 - total",
        "name_hr": "Od 1. do 5. inninga - total",
        "name_fr": "Manches 1 à 5 - total"
    },
    "id_314^prematch": {
        "name_en": "Total sets",
        "name_aa": "Total sets",
        "name_de": "Gesamtanzahl Sätze",
        "name_nl": "Totaal aantal sets",
        "name_pl": "Total sets",
        "name_ro": "Total sets",
        "name_ru": "Total sets",
        "name_tr": "Toplam set",
        "name_se": "Total sets",
        "name_el": "Total sets",
        "name_es": "Total sets",
        "name_hr": "Ukupno setova",
        "name_fr": "Total sets"
    },
    "id_854^prematch": {
        "name_en": "{$competitor1} or over {total}",
        "name_aa": "{$competitor1} or over {total}",
        "name_de": "{$competitor1} oder über {total}",
        "name_nl": "{$competitor1} of boven {total}",
        "name_pl": "{$competitor1} or over {total}",
        "name_ro": "{$competitor1} or over {total}",
        "name_ru": "{$competitor1} or over {total}",
        "name_tr": "{$competitor1} ya da üst {total}",
        "name_se": "{$competitor1} or over {total}",
        "name_el": "{$competitor1} or over {total}",
        "name_es": "{$competitor1} or over {total}",
        "name_hr": "{$competitor1} ili over {total}",
        "name_fr": "{$competitor1} ou Plus de {total}"
    },
    "id_287^prematch": {
        "name_en": "{!inningnr} inning - 1x2",
        "name_aa": "{!inningnr} inning - 1x2",
        "name_de": "{!inningnr} Inning - 1x2",
        "name_nl": "{inningnr} inning - 1x2",
        "name_pl": "{!inningnr} inning - 1x2",
        "name_ro": "{!inningnr} inning - 1x2",
        "name_ru": "{!inningnr} inning - 1x2",
        "name_tr": "{!inningnr}. devre - 1x2",
        "name_se": "{!inningnr} inning - 1x2",
        "name_el": "{!inningnr} inning - 1x2",
        "name_es": "{!inningnr} inning - 1x2",
        "name_hr": "{!inningnr} inning - 1x2",
        "name_fr": "{!inningnr} manche - 1x2"
    },
    "id_148^prematch": {
        "name_en": "{$competitor2} sending off",
        "name_aa": "{$competitor2} sending off",
        "name_de": "{$competitor2} Platzverweise",
        "name_nl": "Uitsluitingen {$competitor2}",
        "name_pl": "{$competitor2} sending off",
        "name_ro": "{$competitor2} sending off",
        "name_ru": "{$competitor2} sending off",
        "name_tr": "{$competitor2} takımında oyundan atılan olur",
        "name_se": "{$competitor2} sending off",
        "name_el": "{$competitor2} sending off",
        "name_es": "{$competitor2} sending off",
        "name_hr": "{$competitor2} isključenja",
        "name_fr": "{$competitor2} expulsion"
    },
    "id_963^prematch": {
        "name_en": "{$competitor2} total maximum consecutive points",
        "name_aa": "{$competitor2} total maximum consecutive points",
        "name_de": "{$competitor2} Insgesamt maximal aufeinanderfolgende Punkte",
        "name_nl": "{$competitor2} totaal aantal maximum opeenvolgende punten",
        "name_pl": "{$competitor2} total maximum consecutive points",
        "name_ro": "{$competitor2} total maximum consecutive points",
        "name_ru": "{$competitor2} total maximum consecutive points",
        "name_tr": "{$competitor2} total maximum consecutive points",
        "name_se": "{$competitor2} total maximum consecutive points",
        "name_el": "{$competitor2} total maximum consecutive points",
        "name_es": "{$competitor2} total maximum consecutive points",
        "name_hr": "{$competitor2} total maximum consecutive points",
        "name_fr": "{$competitor2} nombre maximum de points marqués consécutivement"
    },
    "id_612^prematch": {
        "name_en": "Draw no bet (incl. overtime)",
        "name_aa": "Draw no bet (incl. overtime)",
        "name_de": "unentschieden, keine Wette (inkl. Verlängerung)",
        "name_nl": "Draw no bet (verlenging inbegrepen)",
        "name_pl": "Draw no bet (incl. overtime)",
        "name_ro": "Draw no bet (incl. overtime)",
        "name_ru": "Draw no bet (incl. overtime)",
        "name_tr": "Beraberlikte iade (uzatmalar dahil)",
        "name_se": "Draw no bet (incl. overtime)",
        "name_el": "Draw no bet (incl. overtime)",
        "name_es": "Draw no bet (incl. overtime)",
        "name_hr": "Ishod bez neodlučenog (uklj. produžetke)",
        "name_fr": "Remboursé si match nul (inclus prolongations)"
    },
    "id_460^prematch": {
        "name_en": "{!periodnr} period - handicap",
        "name_aa": "{!periodnr} period - handicap",
        "name_de": "{!periodnr} Periode - Handicap",
        "name_nl": "{periodnr} periode - handicap",
        "name_pl": "{!periodnr} period - handicap",
        "name_ro": "{!periodnr} period - handicap",
        "name_ru": "{!periodnr} period - handicap",
        "name_tr": "{!periodnr}. periyot - handikap",
        "name_se": "{!periodnr} period - handicap",
        "name_el": "{!periodnr} period - handicap",
        "name_es": "{!periodnr} period - handicap",
        "name_hr": "{!periodnr} period - hendikep",
        "name_fr": "{!periodnr} période - handicap"
    },
    "id_487^prematch": {
        "name_en": "1st half - total tries",
        "name_aa": "1st half - total tries",
        "name_de": "1. Halbzeit - Total Versuche",
        "name_nl": "Eerste helft - totaal aantal tries",
        "name_pl": "1st half - total tries",
        "name_ro": "1st half - total tries",
        "name_ru": "1st half - total tries",
        "name_tr": "İlk yarı - toplam try",
        "name_se": "1st half - total tries",
        "name_el": "1st half - total tries",
        "name_es": "1st half - total tries",
        "name_hr": "1. poluvrijeme - ukupno polaganja",
        "name_fr": "1ère mi-temps - total d'essais"
    },
    "id_232^prematch": {
        "name_en": "2nd half - total (incl. overtime)",
        "name_aa": "2nd half - total (incl. overtime)",
        "name_de": "2. Halbzeit - Total (inkl. Verlängerung)",
        "name_nl": "Tweede helft - totaal (verlenging inbegrepen)",
        "name_pl": "2nd half - total (incl. overtime)",
        "name_ro": "2nd half - total (incl. overtime)",
        "name_ru": "2nd half - total (incl. overtime)",
        "name_tr": "İkinci yarı - toplam gol (uzatmalar dahil)",
        "name_se": "2nd half - total (incl. overtime)",
        "name_el": "2nd half - total (incl. overtime)",
        "name_es": "2nd half - total (incl. overtime)",
        "name_hr": "2. poluvrijeme - total (uklj. produžetke)",
        "name_fr": "2ème mi-temps - total (inclus prolongations)"
    },
    "id_20940000^prematch": {
        "name_en": "2nd Half Result - Corner",
        "name_aa": "2nd Half Result - Corner",
        "name_de": "2nd Half Result - Corner",
        "name_nl": "2nd Half Result - Corner",
        "name_pl": "2nd Half Result - Corner",
        "name_ro": "2nd Half Result - Corner",
        "name_ru": "2nd Half Result - Corner",
        "name_tr": "2nd Half Result - Corner",
        "name_se": "2nd Half Result - Corner",
        "name_el": "2nd Half Result - Corner",
        "name_es": "2nd Half Result - Corner",
        "name_hr": "2nd Half Result - Corner",
        "name_fr": "2nd Half Result - Corner"
    },
    "id_271^prematch": {
        "name_en": "Team with highest scoring inning",
        "name_aa": "Team with highest scoring inning",
        "name_de": "Welches Team gewinnt das Inning mit den meisten Punkten",
        "name_nl": "Team met de inning met de hoogste score",
        "name_pl": "Team with highest scoring inning",
        "name_ro": "Team with highest scoring inning",
        "name_ru": "Team with highest scoring inning",
        "name_tr": "En çok skorun olduğu devreyi kazanan takım",
        "name_se": "Team with highest scoring inning",
        "name_el": "Team with highest scoring inning",
        "name_es": "Team with highest scoring inning",
        "name_hr": "Tim s inningom s najviše poena",
        "name_fr": "Equipe qui marque le plus de points dans une manche"
    },
    "id_432^prematch": {
        "name_en": "Highest scoring period",
        "name_aa": "Highest scoring period",
        "name_de": "Periode mit den meisten Toren",
        "name_nl": "Periode met de hoogste score",
        "name_pl": "Highest scoring period",
        "name_ro": "Highest scoring period",
        "name_ru": "Highest scoring period",
        "name_tr": "En çok sayı olan periyot",
        "name_se": "Highest scoring period",
        "name_el": "Highest scoring period",
        "name_es": "Highest scoring period",
        "name_hr": "Period s najviše poena",
        "name_fr": "Période la plus prolifique au score"
    },
    "id_910^prematch": {
        "name_en": "Winning method",
        "name_aa": "Winning method",
        "name_de": "Winning method",
        "name_nl": "Winstmethode",
        "name_pl": "Winning method",
        "name_ro": "Winning method",
        "name_ru": "Winning method",
        "name_tr": "Winning method",
        "name_se": "Winning method",
        "name_el": "Winning method",
        "name_es": "Winning method",
        "name_hr": "Način dolaska do pobjede",
        "name_fr": "Winning method"
    },
    "id_142^prematch": {
        "name_en": "Exact bookings",
        "name_aa": "Exact bookings",
        "name_de": "Genaue Anzahl Karten",
        "name_nl": "Exact aantal kaarten",
        "name_pl": "Exact bookings",
        "name_ro": "Exact bookings",
        "name_ru": "Exact bookings",
        "name_tr": "Doğru kart sayısı",
        "name_se": "Exact bookings",
        "name_el": "Exact bookings",
        "name_es": "Exact bookings",
        "name_hr": "Točno kartona",
        "name_fr": "Nombre exact de cartons"
    },
    "id_302^prematch": {
        "name_en": "{!quarternr} quarter - draw no bet",
        "name_aa": "{!quarternr} quarter - draw no bet",
        "name_de": "{!quarternr} Viertel - unentschieden, keine Wette",
        "name_nl": "{quarternr} kwart - draw no bet",
        "name_pl": "{!quarternr} quarter - draw no bet",
        "name_ro": "{!quarternr} quarter - draw no bet",
        "name_ru": "{!quarternr} quarter - draw no bet",
        "name_tr": "{!quarternr}. çeyrek - beraberlikte iade",
        "name_se": "{!quarternr} quarter - draw no bet",
        "name_el": "{!quarternr} quarter - draw no bet",
        "name_es": "{!quarternr} quarter - draw no bet",
        "name_hr": "{!quarternr} četvrtina - ishod bez neodlučenog",
        "name_fr": "{!quarternr} quart-temps - remboursé si match nul"
    },
    "id_430000^prematch": {
        "name_en": "Who Will Kick Off The Match",
        "name_aa": "Who Will Kick Off The Match",
        "name_de": "Who Will Kick Off The Match",
        "name_nl": "Who Will Kick Off The Match",
        "name_pl": "Who Will Kick Off The Match",
        "name_ro": "Who Will Kick Off The Match",
        "name_ru": "Who Will Kick Off The Match",
        "name_tr": "Who Will Kick Off The Match",
        "name_se": "Who Will Kick Off The Match",
        "name_el": "Who Will Kick Off The Match",
        "name_es": "Who Will Kick Off The Match",
        "name_hr": "Who Will Kick Off The Match",
        "name_fr": "Who Will Kick Off The Match"
    },
    "id_410^prematch": {
        "name_en": "Handicap (incl. overtime and penalties)",
        "name_aa": "Handicap (incl. overtime and penalties)",
        "name_de": "Handicap (inkl. Verlängerung und Elfmeterschießen)",
        "name_nl": "Handicap (verlenging en strafschoppen inbegrepen)",
        "name_pl": "Handicap (incl. overtime and penalties)",
        "name_ro": "Handicap (incl. overtime and penalties)",
        "name_ru": "Handicap (incl. overtime and penalties)",
        "name_tr": "Handikap (uzatmalar ve penaltılar dahil)",
        "name_se": "Handicap (incl. overtime and penalties)",
        "name_el": "Handicap (incl. overtime and penalties)",
        "name_es": "Handicap (incl. overtime and penalties)",
        "name_hr": "Hendikep (uklj. produžetke i penale)",
        "name_fr": "Handicap (inclus prolongations et séance de tirs au but)"
    },
    "id_879^prematch": {
        "name_en": "{$competitor2} to win",
        "name_aa": "{$competitor2} to win",
        "name_de": "{$competitor2} wird gewinnen",
        "name_nl": "{$competitor2} wint",
        "name_pl": "{$competitor2} to win",
        "name_ro": "{$competitor2} to win",
        "name_ru": "{$competitor2} to win",
        "name_tr": "{$competitor2} kazanan",
        "name_se": "{$competitor2} to win",
        "name_el": "{$competitor2} to win",
        "name_es": "{$competitor2} to win",
        "name_hr": "{$competitor2} pobijeđuje",
        "name_fr": "{$competitor2} gagne"
    },
    "id_10^prematch": {
        "name_en": "Double chance",
        "name_aa": "Double chance",
        "name_de": "Doppelte Chance",
        "name_nl": "Dubbele kans",
        "name_pl": "Double chance",
        "name_ro": "Double chance",
        "name_ru": "Double chance",
        "name_tr": "Çifte Şans",
        "name_se": "Double chance",
        "name_el": "Double chance",
        "name_es": "Double chance",
        "name_hr": "Dvostruka prilika",
        "name_fr": "Double chance"
    },
    "id_1046^prematch": {
        "name_en": "Innings 1 to 5 - total hits",
        "name_aa": "Innings 1 to 5 - total hits",
        "name_de": "Inning 1 bis 5 - Total Hits",
        "name_nl": "Innings 1 tot 5 - totaal aantal slagen",
        "name_pl": "Innings 1 to 5 - total hits",
        "name_ro": "Innings 1 to 5 - total hits",
        "name_ru": "Innings 1 to 5 - total hits",
        "name_tr": "Innings 1 to 5 - total hits",
        "name_se": "Innings 1 to 5 - total hits",
        "name_el": "Innings 1 to 5 - total hits",
        "name_es": "Innings 1 to 5 - total hits",
        "name_hr": "Innings 1 to 5 - total hits",
        "name_fr": "Manches 1 à 5 - nombre de bonnes balles"
    },
    "id_219^prematch": {
        "name_en": "Winner (incl. overtime)",
        "name_aa": "Winner (incl. overtime)",
        "name_de": "Gewinner (inkl. Verlängerung)",
        "name_nl": "Winnaar (verlenging inbegrepen)",
        "name_pl": "Winner (incl. overtime)",
        "name_ro": "Winner (incl. overtime)",
        "name_ru": "Winner (incl. overtime)",
        "name_tr": "Kazanan (uzatmalar dahil)",
        "name_se": "Winner (incl. overtime)",
        "name_el": "Winner (incl. overtime)",
        "name_es": "Winner (incl. overtime)",
        "name_hr": "Pobjednik (uklj. produžetke)",
        "name_fr": "Vainqueur (inclus prolongations)"
    },
    "id_346^prematch": {
        "name_en": "{!inningnr} innings - {$competitor2} run range",
        "name_aa": "{!inningnr} innings - {$competitor2} run range",
        "name_de": "{!inningnr} Inning - {$competitor2} Laufbereich",
        "name_nl": "{inningnr} innings - run range {$competitor2}",
        "name_pl": "{!inningnr} innings - {$competitor2} run range",
        "name_ro": "{!inningnr} innings - {$competitor2} run range",
        "name_ru": "{!inningnr} innings - {$competitor2} run range",
        "name_tr": "{!inningnr}. devre - {$competitor2} koşu aralığı",
        "name_se": "{!inningnr} innings - {$competitor2} run range",
        "name_el": "{!inningnr} innings - {$competitor2} run range",
        "name_es": "{!inningnr} innings - {$competitor2} run range",
        "name_hr": "{!inningnr} inning - {$competitor2} opseg runova",
        "name_fr": "{!inningnr} manche - {$competitor2} intervalles nombre de runs"
    },
    "id_204^prematch": {
        "name_en": "{!setnr} set - total games",
        "name_aa": "{!setnr} set - total games",
        "name_de": "{!setnr} Satz - Gesamtanzahl Spiele",
        "name_nl": "{setnr} set - totaal aantal games",
        "name_pl": "{!setnr} set - total games",
        "name_ro": "{!setnr} set - total games",
        "name_ru": "{!setnr} set - total games",
        "name_tr": "{!setnr}. set - toplam oyun",
        "name_se": "{!setnr} set - total games",
        "name_el": "{!setnr} set - total games",
        "name_es": "{!setnr} set - total games",
        "name_hr": "{!setnr} set - ukupno gemova",
        "name_fr": "{!setnr} set - total jeux"
    },
    "id_27^prematch": {
        "name_en": "{$competitor1} odd/even",
        "name_aa": "{$competitor1} odd/even",
        "name_de": "{$competitor1} Gerade/ungerade",
        "name_nl": "{$competitor1} even/oneven",
        "name_pl": "{$competitor1} odd/even",
        "name_ro": "{$competitor1} odd/even",
        "name_ru": "{$competitor1} odd/even",
        "name_tr": "{$competitor1} tek/çift",
        "name_se": "{$competitor1} odd/even",
        "name_el": "{$competitor1} odd/even",
        "name_es": "{$competitor1} odd/even",
        "name_hr": "{$competitor1} par/nepar",
        "name_fr": "{$competitor1} Pair/Impair"
    },
    "id_355^prematch": {
        "name_en": "{!inningnr} innings overs 0 to {overnr} - {$competitor2} run range",
        "name_aa": "{!inningnr} innings overs 0 to {overnr} - {$competitor2} run range",
        "name_de": "{!inningnr} Inning Overs null zu {overnr} - {$competitor2} Laufbereich",
        "name_nl": "{inningnr} innings overs 0 tot {overnr} - run range {$competitor2}",
        "name_pl": "{!inningnr} innings overs 0 to {overnr} - {$competitor2} run range",
        "name_ro": "{!inningnr} innings overs 0 to {overnr} - {$competitor2} run range",
        "name_ru": "{!inningnr} innings overs 0 to {overnr} - {$competitor2} run range",
        "name_tr": "{!inningnr}. devre 0 a {overnr}. over -{$competitor2} koşu aralığı",
        "name_se": "{!inningnr} innings overs 0 to {overnr} - {$competitor2} run range",
        "name_el": "{!inningnr} innings overs 0 to {overnr} - {$competitor2} run range",
        "name_es": "{!inningnr} innings overs 0 to {overnr} - {$competitor2} run range",
        "name_hr": "{!inningnr} inning - over od 0 do {overnr} - {$competitor2} opseg runova",
        "name_fr": "{!inningnr} manche Séries de lancers 0 à {overnr} - {$competitor2} intervalles nombre de runs"
    },
    "id_20970000^prematch": {
        "name_en": "Race to 7 Corners {ck}",
        "name_aa": "Race to 7 Corners {ck}",
        "name_de": "Race to 7 Corners {ck}",
        "name_nl": "Race to 7 Corners {ck}",
        "name_pl": "Race to 7 Corners {ck}",
        "name_ro": "Race to 7 Corners {ck}",
        "name_ru": "Race to 7 Corners {ck}",
        "name_tr": "Race to 7 Corners {ck}",
        "name_se": "Race to 7 Corners {ck}",
        "name_el": "Race to 7 Corners {ck}",
        "name_es": "Race to 7 Corners {ck}",
        "name_hr": "Race to 7 Corners {ck}",
        "name_fr": "Race to 7 Corners {ck}"
    },
    "id_474^prematch": {
        "name_en": "Try double chance",
        "name_aa": "Try double chance",
        "name_de": "Versuch Doppelte Chance",
        "name_nl": "Try dubbele kans",
        "name_pl": "Try double chance",
        "name_ro": "Try double chance",
        "name_ru": "Try double chance",
        "name_tr": "Çifte şans dene",
        "name_se": "Try double chance",
        "name_el": "Try double chance",
        "name_es": "Try double chance",
        "name_hr": "Polaganje dvostruka prilika",
        "name_fr": "Essais double chance"
    },
    "id_542^prematch": {
        "name_en": "1st half - double chance & both teams to score",
        "name_aa": "1st half - double chance & both teams to score",
        "name_de": "1. Halbzeit - Doppelte Chance & Beide Mannschaften treffen",
        "name_nl": "Eerste helft - dubbele kans & beide teams scoren",
        "name_pl": "1st half - double chance & both teams to score",
        "name_ro": "1st half - double chance & both teams to score",
        "name_ru": "1st half - double chance & both teams to score",
        "name_tr": "İlk yarı - çifte şans & her iki takımda gol atar",
        "name_se": "1st half - double chance & both teams to score",
        "name_el": "1st half - double chance & both teams to score",
        "name_es": "1st half - double chance & both teams to score",
        "name_hr": "1. poluvrijeme - dvostruka prilika i oba daju gol",
        "name_fr": "1ère mi-temps - double chance & les deux équipes qui marquent"
    },
    "id_60^prematch": {
        "name_en": "1st half - 1x2",
        "name_aa": "1st half - 1x2",
        "name_de": "1. Halbzeit - 1x2",
        "name_nl": "1e helft - 1x2",
        "name_pl": "1st half - 1x2",
        "name_ro": "1st half - 1x2",
        "name_ru": "1st half - 1x2",
        "name_tr": "İlk yarı - 1x2",
        "name_se": "1st half - 1x2",
        "name_el": "1st half - 1x2",
        "name_es": "1st half - 1x2",
        "name_hr": "1. Poluvrijeme - 1x2",
        "name_fr": "1ère mi-temps - 1x2"
    },
    "id_14^prematch": {
        "name_en": "Handicap {hcp}",
        "name_aa": "Handicap {hcp}",
        "name_de": "Handicap {hcp}",
        "name_nl": "Handicap {hcp}",
        "name_pl": "Handicap {hcp}",
        "name_ro": "Handicap {hcp}",
        "name_ru": "Handicap {hcp}",
        "name_tr": "Handikap {hcp}",
        "name_se": "Handicap {hcp}",
        "name_el": "Handicap {hcp}",
        "name_es": "Handicap {hcp}",
        "name_hr": "Hendikep",
        "name_fr": "Handicap {hcp}"
    },
    "id_541^prematch": {
        "name_en": "Double chance (match) & 2nd half both teams score",
        "name_aa": "Double chance (match) & 2nd half both teams score",
        "name_de": "Doppelte Chance (Spiel) & 2. Halbzeit Beide Mannschaften treffen",
        "name_nl": "Dubbele kans (match) & beide teams scoren in tweede helft",
        "name_pl": "Double chance (match) & 2nd half both teams score",
        "name_ro": "Double chance (match) & 2nd half both teams score",
        "name_ru": "Double chance (match) & 2nd half both teams score",
        "name_tr": "Çifte şans (maç) & ikinci yarı her iki takımda gol atar",
        "name_se": "Double chance (match) & 2nd half both teams score",
        "name_el": "Double chance (match) & 2nd half both teams score",
        "name_es": "Double chance (match) & 2nd half both teams score",
        "name_hr": "Dvostruka prilika (meč) i oba daju gol u 2. poluvremenu",
        "name_fr": "Double chance (match) & 2ème mi-temps les deux équipes qui marquent"
    },
    "id_147^prematch": {
        "name_en": "{$competitor1} sending off",
        "name_aa": "{$competitor1} sending off",
        "name_de": "{$competitor1} Platzverweise",
        "name_nl": "Uitsluitingen {$competitor1}",
        "name_pl": "{$competitor1} sending off",
        "name_ro": "{$competitor1} sending off",
        "name_ru": "{$competitor1} sending off",
        "name_tr": "{$competitor1} takımında oyundan atılan olur",
        "name_se": "{$competitor1} sending off",
        "name_el": "{$competitor1} sending off",
        "name_es": "{$competitor1} sending off",
        "name_hr": "{$competitor1} isključenja",
        "name_fr": "{$competitor1} expulsion"
    },
    "id_608^prematch": {
        "name_en": "{!inningnr} innings - odd/even",
        "name_aa": "{!inningnr} innings - odd/even",
        "name_de": "{!inningnr} Inning  Gerade/ungerade",
        "name_nl": "{inningnr} innings - even/oneven",
        "name_pl": "{!inningnr} innings - odd/even",
        "name_ro": "{!inningnr} innings - odd/even",
        "name_ru": "{!inningnr} innings - odd/even",
        "name_tr": "{!inningnr}. devre - tek/çift",
        "name_se": "{!inningnr} innings - odd/even",
        "name_el": "{!inningnr} innings - odd/even",
        "name_es": "{!inningnr} innings - odd/even",
        "name_hr": "{!inningnr} inning -  par/nepar",
        "name_fr": "{!inningnr} manche - pair/impair"
    },
    "id_261^prematch": {
        "name_en": "{$competitor2} total (incl. extra innings)",
        "name_aa": "{$competitor2} total (incl. extra innings)",
        "name_de": "{$competitor2} Total (inkl. Extra Innings)",
        "name_nl": "{$competitor2} totaal (extra innings inbegrepen)",
        "name_pl": "{$competitor2} total (incl. extra innings)",
        "name_ro": "{$competitor2} total (incl. extra innings)",
        "name_ru": "{$competitor2} total (incl. extra innings)",
        "name_tr": "{$competitor2} toplam (uzatmalar dahil)",
        "name_se": "{$competitor2} total (incl. extra innings)",
        "name_el": "{$competitor2} total (incl. extra innings)",
        "name_es": "{$competitor2} total (incl. extra innings)",
        "name_hr": "{$competitor2} total (uklj. produžetke)",
        "name_fr": "{$competitor2} total (inclus manches supplémentaires)"
    },
    "id_272^prematch": {
        "name_en": "Highest scoring inning",
        "name_aa": "Highest scoring inning",
        "name_de": "Inning mit den meisten Punkten",
        "name_nl": "Inning met de hoogste score",
        "name_pl": "Highest scoring inning",
        "name_ro": "Highest scoring inning",
        "name_ru": "Highest scoring inning",
        "name_tr": "En çok skorun olduğu devre",
        "name_se": "Highest scoring inning",
        "name_el": "Highest scoring inning",
        "name_es": "Highest scoring inning",
        "name_hr": "Inning s najviše poena",
        "name_fr": "Manche avec le plus de points marqués"
    },
    "id_340^prematch": {
        "name_en": "Winner (incl. super over)",
        "name_aa": "Winner (incl. super over)",
        "name_de": "Gewinner (inkl. Super over)",
        "name_nl": "Winnaar (super over inbegrepen)",
        "name_pl": "Winner (incl. super over)",
        "name_ro": "Winner (incl. super over)",
        "name_ru": "Winner (incl. super over)",
        "name_tr": "Kazanan (super over dahil)",
        "name_se": "Winner (incl. super over)",
        "name_el": "Winner (incl. super over)",
        "name_es": "Winner (incl. super over)",
        "name_hr": "Pobjednik (uklj. super over)",
        "name_fr": "Vainqueur (inclus le Super Over)"
    },
    "id_315^prematch": {
        "name_en": "{!setnr} set - 1x2",
        "name_aa": "{!setnr} set - 1x2",
        "name_de": "{!setnr} Satz - 1x2",
        "name_nl": "{setnr} set - 1x2",
        "name_pl": "{!setnr} set - 1x2",
        "name_ro": "{!setnr} set - 1x2",
        "name_ru": "{!setnr} set - 1x2",
        "name_tr": "{!setnr}. set - 1x2",
        "name_se": "{!setnr} set - 1x2",
        "name_el": "{!setnr} set - 1x2",
        "name_es": "{!setnr} set - 1x2",
        "name_hr": "{!setnr} set - 1x2",
        "name_fr": "{!setnr} set - 1x2"
    },
    "id_93^prematch": {
        "name_en": "2nd half - exact goals",
        "name_aa": "2nd half - exact goals",
        "name_de": "2. Halbzeit - Genaue Anzahl Tore",
        "name_nl": "2e helft - exact aantal doelpunten",
        "name_pl": "2nd half - exact goals",
        "name_ro": "2nd half - exact goals",
        "name_ru": "2nd half - exact goals",
        "name_tr": "ikinci yarı - doğru skor",
        "name_se": "2nd half - exact goals",
        "name_el": "2nd half - exact goals",
        "name_es": "2nd half - exact goals",
        "name_hr": "2. poluvrijeme - točno golova",
        "name_fr": "2ème mi-temps - nombre exact de buts"
    },
    "id_92^prematch": {
        "name_en": "2nd half - {$competitor2} total",
        "name_aa": "2nd half - {$competitor2} total",
        "name_de": "2. Halbzeit - {$competitor2} Total",
        "name_nl": "2e helft - {$competitor2} totaal",
        "name_pl": "2nd half - {$competitor2} total",
        "name_ro": "2nd half - {$competitor2} total",
        "name_ru": "2nd half - {$competitor2} total",
        "name_tr": "İkinci yarı - {$competitor2} toplam gol",
        "name_se": "2nd half - {$competitor2} total",
        "name_el": "2nd half - {$competitor2} total",
        "name_es": "2nd half - {$competitor2} total",
        "name_hr": "2. poluvrijeme - {$competitor2} ukupno golova",
        "name_fr": "2ème mi-temps - {$competitor2} total"
    },
    "id_228^prematch": {
        "name_en": "{$competitor2} total (incl. overtime)",
        "name_aa": "{$competitor2} total (incl. overtime)",
        "name_de": "{$competitor2} Total (inkl. Verlängerung)",
        "name_nl": "{$competitor2} totaal (verlenging inbegrepen)",
        "name_pl": "{$competitor2} total (incl. overtime)",
        "name_ro": "{$competitor2} total (incl. overtime)",
        "name_ru": "{$competitor2} total (incl. overtime)",
        "name_tr": "{$competitor2} toplam gol (uzatmalar dahil)",
        "name_se": "{$competitor2} total (incl. overtime)",
        "name_el": "{$competitor2} total (incl. overtime)",
        "name_es": "{$competitor2} total (incl. overtime)",
        "name_hr": "{$competitor2} total (uklj. produžetke)",
        "name_fr": "{$competitor2} total (inclus prolongations)"
    },
    "id_881^prematch": {
        "name_en": "Any team to win",
        "name_aa": "Any team to win",
        "name_de": "Eines der Teams gewinnt",
        "name_nl": "We krijgen een winnaar",
        "name_pl": "Any team to win",
        "name_ro": "Any team to win",
        "name_ru": "Any team to win",
        "name_tr": "Kazanan herhangi bir takım",
        "name_se": "Any team to win",
        "name_el": "Any team to win",
        "name_es": "Any team to win",
        "name_hr": "Bilo tko pobijeđuje",
        "name_fr": "Une des équipes gagne"
    },
    "id_967^prematch": {
        "name_en": "Any team to lead by {points}",
        "name_aa": "Any team to lead by {points}",
        "name_de": "Führt ein Team mit {points}",
        "name_nl": "Willekeurig team staat {points} punten voor",
        "name_pl": "Any team to lead by {points}",
        "name_ro": "Any team to lead by {points}",
        "name_ru": "Any team to lead by {points}",
        "name_tr": "Any team to lead by {points}",
        "name_se": "Any team to lead by {points}",
        "name_el": "Any team to lead by {points}",
        "name_es": "Any team to lead by {points}",
        "name_hr": "Any team to lead by {points}",
        "name_fr": "Une des équipes de mener de {points}"
    },
    "id_853^prematch": {
        "name_en": "{$competitor2} to win exactly 2 sets",
        "name_aa": "{$competitor2} to win exactly 2 sets",
        "name_de": "{$competitor2} wird genau zwei Sätze gewinnen",
        "name_nl": "{$competitor2} wint precies 2 sets",
        "name_pl": "{$competitor2} to win exactly 2 sets",
        "name_ro": "{$competitor2} to win exactly 2 sets",
        "name_ru": "{$competitor2} to win exactly 2 sets",
        "name_tr": "{$competitor2} sadece 2 set kazanır",
        "name_se": "{$competitor2} to win exactly 2 sets",
        "name_el": "{$competitor2} to win exactly 2 sets",
        "name_es": "{$competitor2} to win exactly 2 sets",
        "name_hr": "{$competitor2} osvaja točno 2 seta",
        "name_fr": "{$competitor2} gagnera exactement 2 sets"
    },
    "id_170^prematch": {
        "name_en": "{$competitor1} corner range",
        "name_aa": "{$competitor1} corner range",
        "name_de": "{$competitor1} Anzahlbereich Ecken",
        "name_nl": "Hoekschoppen range {$competitor1}",
        "name_pl": "{$competitor1} corner range",
        "name_ro": "{$competitor1} corner range",
        "name_ru": "{$competitor1} corner range",
        "name_tr": "{$competitor1} korner aralığı",
        "name_se": "{$competitor1} corner range",
        "name_el": "{$competitor1} corner range",
        "name_es": "{$competitor1} corner range",
        "name_hr": "{$competitor1} Udarci iz kuta - opseg",
        "name_fr": "{$competitor1} intervalles nombre de corners"
    },
    "id_81^prematch": {
        "name_en": "1st half - correct score",
        "name_aa": "1st half - correct score",
        "name_de": "1. Halbzeit- Genaues Ergebnis",
        "name_nl": "1e helft - juiste uitslag",
        "name_pl": "1st half - correct score",
        "name_ro": "1st half - correct score",
        "name_ru": "1st half - correct score",
        "name_tr": "İlk yarı -  skor",
        "name_se": "1st half - correct score",
        "name_el": "1st half - correct score",
        "name_es": "1st half - correct score",
        "name_hr": "1. Poluvrijeme - Točan rezultat",
        "name_fr": "1ère mi-temps - score correct"
    },
    "id_371^prematch": {
        "name_en": "{!setnr} set - leg handicap",
        "name_aa": "{!setnr} set - leg handicap",
        "name_de": "{!setnr} Satz - Runden Handicap",
        "name_nl": "{setnr} set - leg handicap",
        "name_pl": "{!setnr} set - leg handicap",
        "name_ro": "{!setnr} set - leg handicap",
        "name_ru": "{!setnr} set - leg handicap",
        "name_tr": "{!setnr}. set - ayak handikabı",
        "name_se": "{!setnr} set - leg handicap",
        "name_el": "{!setnr} set - leg handicap",
        "name_es": "{!setnr} set - leg handicap",
        "name_hr": "{!setnr} set - leg hendikep",
        "name_fr": "{!setnr} set - manche handicap"
    },
    "id_479^prematch": {
        "name_en": "{$competitor1} total tries",
        "name_aa": "{$competitor1} total tries",
        "name_de": "{$competitor1} Total Versuche",
        "name_nl": "Totaal aantal tries {$competitor1}",
        "name_pl": "{$competitor1} total tries",
        "name_ro": "{$competitor1} total tries",
        "name_ru": "{$competitor1} total tries",
        "name_tr": "{$competitor1} toplam try",
        "name_se": "{$competitor1} total tries",
        "name_el": "{$competitor1} total tries",
        "name_es": "{$competitor1} total tries",
        "name_hr": "{$competitor1} ukupno polaganja",
        "name_fr": "{$competitor1} total d'essais"
    },
    "id_737^prematch": {
        "name_en": "Maximum consecutive runs by either team",
        "name_aa": "Maximum consecutive runs by either team",
        "name_de": "Maximale aufeinanderfolgende Läufe von beiden Teams",
        "name_nl": "Maximaal aantal opeenvolgende runs",
        "name_pl": "Maximum consecutive runs by either team",
        "name_ro": "Maximum consecutive runs by either team",
        "name_ru": "Maximum consecutive runs by either team",
        "name_tr": "Maksimum ardarda koşu",
        "name_se": "Maximum consecutive runs by either team",
        "name_el": "Maximum consecutive runs by either team",
        "name_es": "Maximum consecutive runs by either team",
        "name_hr": "Maksimalno uzastopnih runova bilo kog tima",
        "name_fr": "Maximum de runs consécutifs par une des équipes"
    },
    "id_931^prematch": {
        "name_en": "Total touchdowns (incl. overtime)",
        "name_aa": "Total touchdowns (incl. overtime)",
        "name_de": "Touchdowns Gesamt (inkl. Verlängerung)",
        "name_nl": "Totaal aantal touchdowns (incl. verlenging)",
        "name_pl": "Total touchdowns (incl. overtime)",
        "name_ro": "Total touchdowns (incl. overtime)",
        "name_ru": "Total touchdowns (incl. overtime)",
        "name_tr": "Total touchdowns (incl. overtime)",
        "name_se": "Total touchdowns (incl. overtime)",
        "name_el": "Total touchdowns (incl. overtime)",
        "name_es": "Total touchdowns (incl. overtime)",
        "name_hr": "Total touchdowns (incl. overtime)",
        "name_fr": "Nombre d'éssais (touchdowns)(prolongations incluses)"
    },
    "id_756^prematch": {
        "name_en": "{!quarternr} quarter - {$competitor1} total",
        "name_aa": "{!quarternr} quarter - {$competitor1} total",
        "name_de": "{!quarternr} Viertel - {$competitor1} Total",
        "name_nl": "{quarternr} kwart - {$competitor1} totaal",
        "name_pl": "{!quarternr} quarter - {$competitor1} total",
        "name_ro": "{!quarternr} quarter - {$competitor1} total",
        "name_ru": "{!quarternr} quarter - {$competitor1} total",
        "name_tr": "{!quarternr} çeyrek - {$competitor1} toplam",
        "name_se": "{!quarternr} quarter - {$competitor1} total",
        "name_el": "{!quarternr} quarter - {$competitor1} total",
        "name_es": "{!quarternr} quarter - {$competitor1} total",
        "name_hr": "{!quarternr} četvrtina - {$competitor1} total",
        "name_fr": "{!quarternr} quart-temps - total {$competitor1}"
    },
    "id_295^prematch": {
        "name_en": "2nd half - odd/even (incl. overtime)",
        "name_aa": "2nd half - odd/even (incl. overtime)",
        "name_de": "2. Halbzeit - Gerade/ungerade (inkl. Extra Innings)",
        "name_nl": "Tweede helft - even/oneven (verlenging inbegrepen)",
        "name_pl": "2nd half - odd/even (incl. overtime)",
        "name_ro": "2nd half - odd/even (incl. overtime)",
        "name_ru": "2nd half - odd/even (incl. overtime)",
        "name_tr": "İkinci yarı - tek/çift (uzatma dahil)",
        "name_se": "2nd half - odd/even (incl. overtime)",
        "name_el": "2nd half - odd/even (incl. overtime)",
        "name_es": "2nd half - odd/even (incl. overtime)",
        "name_hr": "2. poluvrijeme - par/nepar (uklj. produžetke)",
        "name_fr": "2ème mi-temps - pair/impair (inclus prolongations)"
    },
    "id_189^prematch": {
        "name_en": "Total games",
        "name_aa": "Total games",
        "name_de": "Gesamtanzahl Spiele",
        "name_nl": "Totaal aantal games",
        "name_pl": "Total games",
        "name_ro": "Total games",
        "name_ru": "Total games",
        "name_tr": "Maç sonu toplam oyun",
        "name_se": "Total games",
        "name_el": "Total games",
        "name_es": "Total games",
        "name_hr": "Ukupno gemova",
        "name_fr": "Total jeux"
    },
    "id_757^prematch": {
        "name_en": "{!quarternr} quarter - {$competitor2} total",
        "name_aa": "{!quarternr} quarter - {$competitor2} total",
        "name_de": "{!quarternr} Viertel - {$competitor2} Total",
        "name_nl": "{quarternr} kwart - {$competitor2} totaal",
        "name_pl": "{!quarternr} quarter - {$competitor2} total",
        "name_ro": "{!quarternr} quarter - {$competitor2} total",
        "name_ru": "{!quarternr} quarter - {$competitor2} total",
        "name_tr": "{!quarternr} çeyrek - {$competitor2} toplam",
        "name_se": "{!quarternr} quarter - {$competitor2} total",
        "name_el": "{!quarternr} quarter - {$competitor2} total",
        "name_es": "{!quarternr} quarter - {$competitor2} total",
        "name_hr": "{!quarternr} četvrtina - {$competitor2} total",
        "name_fr": "{!quarternr} quart-temps - total {$competitor2}"
    },
    "id_738^prematch": {
        "name_en": "When will the match be decided",
        "name_aa": "When will the match be decided",
        "name_de": "Wann wird das Spiel entschieden werden",
        "name_nl": "Wanneer wordt de wedstrijd beslist",
        "name_pl": "When will the match be decided",
        "name_ro": "When will the match be decided",
        "name_ru": "When will the match be decided",
        "name_tr": "Maç ne zaman belli olacak",
        "name_se": "When will the match be decided",
        "name_el": "When will the match be decided",
        "name_es": "When will the match be decided",
        "name_hr": "Kada će meč biti odlučen",
        "name_fr": "Quand le match sera-t-il conclu ?"
    },
    "id_550^prematch": {
        "name_en": "{$competitor2} multigoals",
        "name_aa": "{$competitor2} multigoals",
        "name_de": "{$competitor2} Gesamtzahl der Tore im Spiel",
        "name_nl": "{$competitor2} multigoals",
        "name_pl": "{$competitor2} multigoals",
        "name_ro": "{$competitor2} multigoals",
        "name_ru": "{$competitor2} multigoals",
        "name_tr": "{$competitor2} toplam gol",
        "name_se": "{$competitor2} multigoals",
        "name_el": "{$competitor2} multigoals",
        "name_es": "{$competitor2} multigoals",
        "name_hr": "{$competitor2} multigolovi",
        "name_fr": "{$competitor2} Multi buts"
    },
    "id_28^prematch": {
        "name_en": "{$competitor2} odd/even",
        "name_aa": "{$competitor2} odd/even",
        "name_de": "{$competitor2} Gerade/ungerade",
        "name_nl": "{$competitor2} even/oneven",
        "name_pl": "{$competitor2} odd/even",
        "name_ro": "{$competitor2} odd/even",
        "name_ru": "{$competitor2} odd/even",
        "name_tr": "{$competitor2} tek/çift",
        "name_se": "{$competitor2} odd/even",
        "name_el": "{$competitor2} odd/even",
        "name_es": "{$competitor2} odd/even",
        "name_hr": "{$competitor2} par/nepar",
        "name_fr": "{$competitor2} Pair/Impair"
    },
    "id_198^prematch": {
        "name_en": "Odd/even games",
        "name_aa": "Odd/even games",
        "name_de": "Gerade/ungerade Spiele",
        "name_nl": "Even/oneven aantal games",
        "name_pl": "Odd/even games",
        "name_ro": "Odd/even games",
        "name_ru": "Odd/even games",
        "name_tr": "Toplam oyun tek/çift",
        "name_se": "Odd/even games",
        "name_el": "Odd/even games",
        "name_es": "Odd/even games",
        "name_hr": "Par/nepar setova",
        "name_fr": "Pair/impair jeux"
    },
    "id_613^prematch": {
        "name_en": "{!quarternr} quarter - draw no bet (incl. overtime)",
        "name_aa": "{!quarternr} quarter - draw no bet (incl. overtime)",
        "name_de": "{!quarternr} Viertel - unentschieden, keine Wette (inkl. Verlängerung)",
        "name_nl": "{quarternr} kwart - draw no bet (verlenging inbegrepen)",
        "name_pl": "{!quarternr} quarter - draw no bet (incl. overtime)",
        "name_ro": "{!quarternr} quarter - draw no bet (incl. overtime)",
        "name_ru": "{!quarternr} quarter - draw no bet (incl. overtime)",
        "name_tr": "{!quarternr}. çeyrek - beraberlikte iade (uzatmalar dahil)",
        "name_se": "{!quarternr} quarter - draw no bet (incl. overtime)",
        "name_el": "{!quarternr} quarter - draw no bet (incl. overtime)",
        "name_es": "{!quarternr} quarter - draw no bet (incl. overtime)",
        "name_hr": "{!quarternr} četvrtina - ishod bez neodlučenog (uklj. produžetke)",
        "name_fr": "{!quarternr} quart-temps - remboursé si match nul (inclus prolongations)"
    },
    "id_20^prematch": {
        "name_en": "{$competitor2} total",
        "name_aa": "{$competitor2} total",
        "name_de": "{$competitor2} Total",
        "name_nl": "{$competitor2} totaal",
        "name_pl": "{$competitor2} total",
        "name_ro": "{$competitor2} total",
        "name_ru": "{$competitor2} total",
        "name_tr": "{$competitor2} toplam gol",
        "name_se": "{$competitor2} total",
        "name_el": "{$competitor2} total",
        "name_es": "{$competitor2} total",
        "name_hr": "{$competitor2} ukupno golova",
        "name_fr": "{$competitor2} total"
    },
    "id_25^prematch": {
        "name_en": "Goal range",
        "name_aa": "Goal range",
        "name_de": "Tordifferenz",
        "name_nl": "Aantal doelpunten",
        "name_pl": "Goal range",
        "name_ro": "Goal range",
        "name_ru": "Goal range",
        "name_tr": "Gol aralığı",
        "name_se": "Goal range",
        "name_el": "Goal range",
        "name_es": "Goal range",
        "name_hr": "Opseg golova",
        "name_fr": "Intervalles but"
    },
    "id_319^prematch": {
        "name_en": "{!setnr} set - {$competitor2} total",
        "name_aa": "{!setnr} set - {$competitor2} total",
        "name_de": "{!setnr} Satz - {$competitor2} Total",
        "name_nl": "{setnr} set - {$competitor2} totaal",
        "name_pl": "{!setnr} set - {$competitor2} total",
        "name_ro": "{!setnr} set - {$competitor2} total",
        "name_ru": "{!setnr} set - {$competitor2} total",
        "name_tr": "{!setnr}. set - {$competitor2}  toplam",
        "name_se": "{!setnr} set - {$competitor2} total",
        "name_el": "{!setnr} set - {$competitor2} total",
        "name_es": "{!setnr} set - {$competitor2} total",
        "name_hr": "{!setnr} set - {$competitor2} total",
        "name_fr": "{!setnr} set - {$competitor2} total"
    },
    "id_535^prematch": {
        "name_en": "Short term free text market",
        "name_aa": "Short term free text market",
        "name_de": "Short term free text market",
        "name_nl": "Short term free text market",
        "name_pl": "Short term free text market",
        "name_ro": "Short term free text market",
        "name_ru": "Short term free text market",
        "name_tr": "Short term free text market",
        "name_se": "Short term free text market",
        "name_el": "Short term free text market",
        "name_es": "Short term free text market",
        "name_hr": "Short term free text market",
        "name_fr": "Short term free text market"
    },
    "id_857^prematch": {
        "name_en": "Draw or under {total}",
        "name_aa": "Draw or under {total}",
        "name_de": "Gleichstand oder unter {total}",
        "name_nl": "Gelijkspel of onder {total}",
        "name_pl": "Draw or under {total}",
        "name_ro": "Draw or under {total}",
        "name_ru": "Draw or under {total}",
        "name_tr": "Berabere ya da alt {total}",
        "name_se": "Draw or under {total}",
        "name_el": "Draw or under {total}",
        "name_es": "Draw or under {total}",
        "name_hr": "Neodlučeno ili manje {total}",
        "name_fr": "Nul ou Moins de {total}"
    },
    "id_855^prematch": {
        "name_en": "{$competitor1} or under {total}",
        "name_aa": "{$competitor1} or under {total}",
        "name_de": "{$competitor1} oder unter {total}",
        "name_nl": "{$competitor1} of onder {total}",
        "name_pl": "{$competitor1} or under {total}",
        "name_ro": "{$competitor1} or under {total}",
        "name_ru": "{$competitor1} or under {total}",
        "name_tr": "{$competitor1} ya da alt {total}",
        "name_se": "{$competitor1} or under {total}",
        "name_el": "{$competitor1} or under {total}",
        "name_es": "{$competitor1} or under {total}",
        "name_hr": "{$competitor1} ili manje {total}",
        "name_fr": "{$competitor1} ou Moins de {total}"
    },
    "id_526^prematch": {
        "name_en": "Will there be a 5th set",
        "name_aa": "Will there be a 5th set",
        "name_de": "Wird es einen 5ten Satz geben?",
        "name_nl": "Zal er een vijfde set nodig zijn",
        "name_pl": "Will there be a 5th set",
        "name_ro": "Will there be a 5th set",
        "name_ru": "Will there be a 5th set",
        "name_tr": "5. set oynanıcak mı",
        "name_se": "Will there be a 5th set",
        "name_el": "Will there be a 5th set",
        "name_es": "Will there be a 5th set",
        "name_hr": "Hoće li biti 5. seta",
        "name_fr": "Y aura-t-il un 5ème set ?"
    },
    "id_180^prematch": {
        "name_en": "1st half - {$competitor1} exact corners",
        "name_aa": "1st half - {$competitor1} exact corners",
        "name_de": "1. Halbzeit - {$competitor1} Genaue Anzahl",
        "name_nl": "Eerste helft - Exact aantal hoekschoppen {$competitor1}",
        "name_pl": "1st half - {$competitor1} exact corners",
        "name_ro": "1st half - {$competitor1} exact corners",
        "name_ru": "1st half - {$competitor1} exact corners",
        "name_tr": "İlk yarı - {$competitor1} doğru korner sayısı",
        "name_se": "1st half - {$competitor1} exact corners",
        "name_el": "1st half - {$competitor1} exact corners",
        "name_es": "1st half - {$competitor1} exact corners",
        "name_hr": "1. Poluvrijeme - {$competitor1} točno udaraca iz kuta",
        "name_fr": "1ère mi-temps - {$competitor1} nombre exact de corners"
    },
    "id_277^prematch": {
        "name_en": "Innings 1 to 5 - {$competitor1} total",
        "name_aa": "Innings 1 to 5 - {$competitor1} total",
        "name_de": "Innings 1 bis 5 - {$competitor1} Total",
        "name_nl": "Inning 1 tot 5 - {$competitor1} totaal",
        "name_pl": "Innings 1 to 5 - {$competitor1} total",
        "name_ro": "Innings 1 to 5 - {$competitor1} total",
        "name_ru": "Innings 1 to 5 - {$competitor1} total",
        "name_tr": "Devreler 1 den 5 e - {$competitor1} toplam",
        "name_se": "Innings 1 to 5 - {$competitor1} total",
        "name_el": "Innings 1 to 5 - {$competitor1} total",
        "name_es": "Innings 1 to 5 - {$competitor1} total",
        "name_hr": "Od 1. do 5. inninga - {$competitor1} total",
        "name_fr": "Manches 1 à 5 - {$competitor1} total"
    },
    "id_274^prematch": {
        "name_en": "Innings 1 to 5 - 1x2",
        "name_aa": "Innings 1 to 5 - 1x2",
        "name_de": "Innings 1 bis 5 - 1x2",
        "name_nl": "Inning 1 tot 5 - 1x2",
        "name_pl": "Innings 1 to 5 - 1x2",
        "name_ro": "Innings 1 to 5 - 1x2",
        "name_ru": "Innings 1 to 5 - 1x2",
        "name_tr": "Devreler 1 den 5 e - 1x2",
        "name_se": "Innings 1 to 5 - 1x2",
        "name_el": "Innings 1 to 5 - 1x2",
        "name_es": "Innings 1 to 5 - 1x2",
        "name_hr": "Od 1. do 5. inninga - 1x2",
        "name_fr": "Manches 1 à 5 - 1x2"
    },
    "id_547^prematch": {
        "name_en": "Double chance & total",
        "name_aa": "Double chance & total",
        "name_de": "Doppelte Chance & Total",
        "name_nl": "Dubbele kans & totaal",
        "name_pl": "Double chance & total",
        "name_ro": "Double chance & total",
        "name_ru": "Double chance & total",
        "name_tr": "Çifte şans & toplam gol",
        "name_se": "Double chance & total",
        "name_el": "Double chance & total",
        "name_es": "Double chance & total",
        "name_hr": "Dvostruka prilika i ukupno golova",
        "name_fr": "Double chance & total"
    },
    "id_55^prematch": {
        "name_en": "1st/2nd half both teams to score",
        "name_aa": "1st/2nd half both teams to score",
        "name_de": "1. Halbzeit / 2. Halbzeit Beide Mannschaften treffen",
        "name_nl": "Beide teams scoren in beide helften",
        "name_pl": "1st/2nd half both teams to score",
        "name_ro": "1st/2nd half both teams to score",
        "name_ru": "1st/2nd half both teams to score",
        "name_tr": "Her iki devrede her iki takımda gol bulur",
        "name_se": "1st/2nd half both teams to score",
        "name_el": "1st/2nd half both teams to score",
        "name_es": "1st/2nd half both teams to score",
        "name_hr": "1./2. poluvrijeme oba daju gol",
        "name_fr": "1ere/2ème mi-temps Les deux équipes qui marquent"
    },
    "id_31^prematch": {
        "name_en": "{$competitor1} clean sheet",
        "name_aa": "{$competitor1} clean sheet",
        "name_de": "{$competitor1} ohne Gegentreffer",
        "name_nl": "{$competitor1} houdt doel schoon",
        "name_pl": "{$competitor1} clean sheet",
        "name_ro": "{$competitor1} clean sheet",
        "name_ru": "{$competitor1} clean sheet",
        "name_tr": "{$competitor1} gol yemez",
        "name_se": "{$competitor1} clean sheet",
        "name_el": "{$competitor1} clean sheet",
        "name_es": "{$competitor1} clean sheet",
        "name_hr": "{$competitor1} bez primljenog gola",
        "name_fr": "{$competitor1} cage inviolée"
    },
    "id_241^prematch": {
        "name_en": "Exact games",
        "name_aa": "Exact games",
        "name_de": "Genaue Anzahl Spiele",
        "name_nl": "Exact aantal games",
        "name_pl": "Exact games",
        "name_ro": "Exact games",
        "name_ru": "Exact games",
        "name_tr": "Doğru oyun skoru",
        "name_se": "Exact games",
        "name_el": "Exact games",
        "name_es": "Exact games",
        "name_hr": "Točno gemova",
        "name_fr": "Nombre exact de jeux"
    },
    "id_20910000^prematch": {
        "name_en": "Corner Match Bet",
        "name_aa": "Corner Match Bet",
        "name_de": "Corner Match Bet",
        "name_nl": "Corner Match Bet",
        "name_pl": "Corner Match Bet",
        "name_ro": "Corner Match Bet",
        "name_ru": "Corner Match Bet",
        "name_tr": "Corner Match Bet",
        "name_se": "Corner Match Bet",
        "name_el": "Corner Match Bet",
        "name_es": "Corner Match Bet",
        "name_hr": "Corner Match Bet",
        "name_fr": "Corner Match Bet"
    },
    "id_488^prematch": {
        "name_en": "1st half - {$competitor1} total tries",
        "name_aa": "1st half - {$competitor1} total tries",
        "name_de": "1. Halbzeit - {$competitor1} Total Versuche",
        "name_nl": "Eerste helft - totaal aantal tries {$competitor1}",
        "name_pl": "1st half - {$competitor1} total tries",
        "name_ro": "1st half - {$competitor1} total tries",
        "name_ru": "1st half - {$competitor1} total tries",
        "name_tr": "İlk yarı - {$competitor1} toplam try",
        "name_se": "1st half - {$competitor1} total tries",
        "name_el": "1st half - {$competitor1} total tries",
        "name_es": "1st half - {$competitor1} total tries",
        "name_hr": "1. poluvrijeme - {$competitor1} ukupno polaganja",
        "name_fr": "1ère mi-temps - {$competitor1} total d'essais"
    },
    "id_443^prematch": {
        "name_en": "{!periodnr} period - 1x2",
        "name_aa": "{!periodnr} period - 1x2",
        "name_de": "{!periodnr} Periode - 1x2",
        "name_nl": "{periodnr} periode - 1x2",
        "name_pl": "{!periodnr} period - 1x2",
        "name_ro": "{!periodnr} period - 1x2",
        "name_ru": "{!periodnr} period - 1x2",
        "name_tr": "{!periodnr}. periyot - 1x2",
        "name_se": "{!periodnr} period - 1x2",
        "name_el": "{!periodnr} period - 1x2",
        "name_es": "{!periodnr} period - 1x2",
        "name_hr": "{!periodnr} period - 1x2",
        "name_fr": "{!periodnr} période - 1x2"
    },
    "id_961^prematch": {
        "name_en": "{!freethrownr} free throw scored",
        "name_aa": "{!freethrownr} free throw scored",
        "name_de": "{!freethrownr} Freiwurf erzielt",
        "name_nl": "{freethrownr} vrije worp gescoord",
        "name_pl": "{!freethrownr} free throw scored",
        "name_ro": "{!freethrownr} free throw scored",
        "name_ru": "{!freethrownr} free throw scored",
        "name_tr": "{!freethrownr} free throw scored",
        "name_se": "{!freethrownr} free throw scored",
        "name_el": "{!freethrownr} free throw scored",
        "name_es": "{!freethrownr} free throw scored",
        "name_hr": "{!freethrownr} free throw scored",
        "name_fr": "{!freethrownr} lancer franc réussi"
    },
    "id_5^prematch": {
        "name_en": "Winning method",
        "name_aa": "Winning method",
        "name_de": "Womit wird das Spiel entschieden?",
        "name_nl": "Manier om te winnen",
        "name_pl": "Winning method",
        "name_ro": "Winning method",
        "name_ru": "Winning method",
        "name_tr": "Kazanma Yöntemi",
        "name_se": "Winning method",
        "name_el": "Winning method",
        "name_es": "Winning method",
        "name_hr": "Način pobjede",
        "name_fr": "Méthode de victoire"
    },
    "id_188^prematch": {
        "name_en": "Set handicap",
        "name_aa": "Set handicap",
        "name_de": "Satz Handicap",
        "name_nl": "Set handicap",
        "name_pl": "Set handicap",
        "name_ro": "Set handicap",
        "name_ru": "Set handicap",
        "name_tr": "Handikaplı set",
        "name_se": "Set handicap",
        "name_el": "Set handicap",
        "name_es": "Set handicap",
        "name_hr": "Set hendikep",
        "name_fr": "Set handicap"
    },
    "id_934^prematch": {
        "name_en": "Total sacks (incl. overtime)",
        "name_aa": "Total sacks (incl. overtime)",
        "name_de": "Sacks Gesamt (inkl. Verlängerung)",
        "name_nl": "Totaal aantal sacks (incl. verlenging)",
        "name_pl": "Total sacks (incl. overtime)",
        "name_ro": "Total sacks (incl. overtime)",
        "name_ru": "Total sacks (incl. overtime)",
        "name_tr": "Total sacks (incl. overtime)",
        "name_se": "Total sacks (incl. overtime)",
        "name_el": "Total sacks (incl. overtime)",
        "name_es": "Total sacks (incl. overtime)",
        "name_hr": "Total sacks (incl. overtime)",
        "name_fr": "Nombre de plaquage sur le quaterback (sack)(prolongations incluses)"
    },
    "id_494^prematch": {
        "name_en": "Total frames",
        "name_aa": "Total frames",
        "name_de": "Total Frames",
        "name_nl": "Totaal aantal frames",
        "name_pl": "Total frames",
        "name_ro": "Total frames",
        "name_ru": "Total frames",
        "name_tr": "Toplam frameler",
        "name_se": "Total frames",
        "name_el": "Total frames",
        "name_es": "Total frames",
        "name_hr": "Ukupno frameova",
        "name_fr": "Total manches"
    },
    "id_151^prematch": {
        "name_en": "1st half - total booking points",
        "name_aa": "1st half - total booking points",
        "name_de": "1. Halbzeit - Gesamtanzahl Strafpunkte",
        "name_nl": "Eerste helft - totaal aantal booking points",
        "name_pl": "1st half - total booking points",
        "name_ro": "1st half - total booking points",
        "name_ru": "1st half - total booking points",
        "name_tr": "İlk yarı - toplam kart ceza puanı",
        "name_se": "1st half - total booking points",
        "name_el": "1st half - total booking points",
        "name_es": "1st half - total booking points",
        "name_hr": "1. Poluvrijeme - Ukupno Kartona рoena",
        "name_fr": "1ère mi-temps - Total points cartons"
    },
    "id_20950000^prematch": {
        "name_en": "Race to 3 Corners {sk}",
        "name_aa": "Race to 3 Corners {sk}",
        "name_de": "Race to 3 Corners {sk}",
        "name_nl": "Race to 3 Corners {sk}",
        "name_pl": "Race to 3 Corners {sk}",
        "name_ro": "Race to 3 Corners {sk}",
        "name_ru": "Race to 3 Corners {sk}",
        "name_tr": "Race to 3 Corners {sk}",
        "name_se": "Race to 3 Corners {sk}",
        "name_el": "Race to 3 Corners {sk}",
        "name_es": "Race to 3 Corners {sk}",
        "name_hr": "Race to 3 Corners {sk}",
        "name_fr": "Race to 3 Corners {sk}"
    },
    "id_444^prematch": {
        "name_en": "{!periodnr} period - {!goalnr} goal",
        "name_aa": "{!periodnr} period - {!goalnr} goal",
        "name_de": "{!periodnr} Periode - {!goalnr} Tor",
        "name_nl": "{periodnr} periode - {goalnr} doelpunt",
        "name_pl": "{!periodnr} period - {!goalnr} goal",
        "name_ro": "{!periodnr} period - {!goalnr} goal",
        "name_ru": "{!periodnr} period - {!goalnr} goal",
        "name_tr": "{!periodnr} . periyot - {!goalnr}. gol",
        "name_se": "{!periodnr} period - {!goalnr} goal",
        "name_el": "{!periodnr} period - {!goalnr} goal",
        "name_es": "{!periodnr} period - {!goalnr} goal",
        "name_hr": "{!periodnr} period - {!goalnr} gol",
        "name_fr": "{!periodnr} période - {!goalnr} but"
    },
    "id_225^prematch": {
        "name_en": "Total (incl. overtime)",
        "name_aa": "Total (incl. overtime)",
        "name_de": "Total (inkl. Verlängerung)",
        "name_nl": "Totaal (verlenging inbegrepen)",
        "name_pl": "Total (incl. overtime)",
        "name_ro": "Total (incl. overtime)",
        "name_ru": "Total (incl. overtime)",
        "name_tr": "Toplam (uzatmalar dahil)",
        "name_se": "Total (incl. overtime)",
        "name_el": "Total (incl. overtime)",
        "name_es": "Total (incl. overtime)",
        "name_hr": "Total (uklj. produžetke)",
        "name_fr": "Total (inclus prolongations)"
    },
    "id_12^prematch": {
        "name_en": "{$competitor1} no bet",
        "name_aa": "{$competitor1} no bet",
        "name_de": "{$competitor1} keine Wette",
        "name_nl": "{$competitor1} no bet",
        "name_pl": "{$competitor1} no bet",
        "name_ro": "{$competitor1} no bet",
        "name_ru": "{$competitor1} no bet",
        "name_tr": "{$competitor1} kazanırsa iade",
        "name_se": "{$competitor1} no bet",
        "name_el": "{$competitor1} no bet",
        "name_es": "{$competitor1} no bet",
        "name_hr": "{$competitor1} nema oklade",
        "name_fr": "{$competitor1} remboursé si victoire"
    },
    "id_152^prematch": {
        "name_en": "1st half - total bookings",
        "name_aa": "1st half - total bookings",
        "name_de": "1. Halbzeit - Gesamtanzahl Karten",
        "name_nl": "Eerste helft - totaal aantal kaarten",
        "name_pl": "1st half - total bookings",
        "name_ro": "1st half - total bookings",
        "name_ru": "1st half - total bookings",
        "name_tr": "İlk yarı - toplam kart sayısı",
        "name_se": "1st half - total bookings",
        "name_el": "1st half - total bookings",
        "name_es": "1st half - total bookings",
        "name_hr": "1. Poluvrijeme - Ukupno Kartona",
        "name_fr": "1ère mi-temps - Total cartons"
    },
    "id_94^prematch": {
        "name_en": "2nd half - odd/even",
        "name_aa": "2nd half - odd/even",
        "name_de": "2. Halbzeit - Gerade/ungerade",
        "name_nl": "2e helft - even/oneven",
        "name_pl": "2nd half - odd/even",
        "name_ro": "2nd half - odd/even",
        "name_ru": "2nd half - odd/even",
        "name_tr": "İkinci yarı - tek/çift",
        "name_se": "2nd half - odd/even",
        "name_el": "2nd half - odd/even",
        "name_es": "2nd half - odd/even",
        "name_hr": "2. poluvrijeme - par/nepar",
        "name_fr": "2ème mi-temps - pair/impair"
    },
    "id_1067^prematch": {
        "name_en": "Winner & total (incl. extra innings)",
        "name_aa": "Winner & total (incl. extra innings)",
        "name_de": "Sieger & Total (inkl. Extra Innings)",
        "name_nl": "Winnaar & totaal (incl. extra innings)",
        "name_pl": "Winner & total (incl. extra innings)",
        "name_ro": "Winner & total (incl. extra innings)",
        "name_ru": "Winner & total (incl. extra innings)",
        "name_tr": "Winner & total (incl. extra innings)",
        "name_se": "Winner & total (incl. extra innings)",
        "name_el": "Winner & total (incl. extra innings)",
        "name_es": "Winner & total (incl. extra innings)",
        "name_hr": "Winner & total (incl. extra innings)",
        "name_fr": "Vainqueur et total (prolongations incluses)"
    },
    "id_169^prematch": {
        "name_en": "Corner range",
        "name_aa": "Corner range",
        "name_de": "Eckenanzahlbereich",
        "name_nl": "Hoekschoppen range",
        "name_pl": "Corner range",
        "name_ro": "Corner range",
        "name_ru": "Corner range",
        "name_tr": "Korner aralığı",
        "name_se": "Corner range",
        "name_el": "Corner range",
        "name_es": "Corner range",
        "name_hr": "Udarci iz kuta - opseg",
        "name_fr": "Intervalles nombre de corners"
    },
    "id_74^prematch": {
        "name_en": "1st half - odd/even",
        "name_aa": "1st half - odd/even",
        "name_de": "1. Halbzeit - Gerade/ungerade",
        "name_nl": "1e helft - even/oneven",
        "name_pl": "1st half - odd/even",
        "name_ro": "1st half - odd/even",
        "name_ru": "1st half - odd/even",
        "name_tr": "İlk Yarı - tek/çift",
        "name_se": "1st half - odd/even",
        "name_el": "1st half - odd/even",
        "name_es": "1st half - odd/even",
        "name_hr": "1. Poluvrijeme - par/nepar",
        "name_fr": "1ère mi-temps - pair/impair"
    },
    "id_33^prematch": {
        "name_en": "{$competitor1} win to nil",
        "name_aa": "{$competitor1} win to nil",
        "name_de": "{$competitor1} gewinnt zu null",
        "name_nl": "{$competitor1} wint zonder tegengoal",
        "name_pl": "{$competitor1} win to nil",
        "name_ro": "{$competitor1} win to nil",
        "name_ru": "{$competitor1} win to nil",
        "name_tr": "{$competitor1} gol yemeden kazanır",
        "name_se": "{$competitor1} win to nil",
        "name_el": "{$competitor1} win to nil",
        "name_es": "{$competitor1} win to nil",
        "name_hr": "{$competitor1} pobjeda bez primljenog gola",
        "name_fr": "{$competitor1} gagne sans prendre de buts"
    },
    "id_164^prematch": {
        "name_en": "Last corner",
        "name_aa": "Last corner",
        "name_de": "Letzte Ecke",
        "name_nl": "Laatste hoekschop",
        "name_pl": "Last corner",
        "name_ro": "Last corner",
        "name_ru": "Last corner",
        "name_tr": "Son korner",
        "name_se": "Last corner",
        "name_el": "Last corner",
        "name_es": "Last corner",
        "name_hr": "Posljednji udarac iz kuta",
        "name_fr": "Dernier corner"
    },
    "id_860^prematch": {
        "name_en": "{$competitor1} or both teams to score",
        "name_aa": "{$competitor1} or both teams to score",
        "name_de": "{$competitor1} oder beide Teams werden punkten",
        "name_nl": "{$competitor1} of beide teams scoren",
        "name_pl": "{$competitor1} or both teams to score",
        "name_ro": "{$competitor1} or both teams to score",
        "name_ru": "{$competitor1} or both teams to score",
        "name_tr": "{$competitor1} ya da iki takımda gol atar",
        "name_se": "{$competitor1} or both teams to score",
        "name_el": "{$competitor1} or both teams to score",
        "name_es": "{$competitor1} or both teams to score",
        "name_hr": "{$competitor1} ili oba daju gol",
        "name_fr": "{$competitor1} ou les deux équipes qui marquent"
    },
    "id_301^prematch": {
        "name_en": "{!quarternr} quarter - winning margin",
        "name_aa": "{!quarternr} quarter - winning margin",
        "name_de": "{!quarternr} Viertel - Gewinnspanne",
        "name_nl": "{quarternr} kwart - winstmarge",
        "name_pl": "{!quarternr} quarter - winning margin",
        "name_ro": "{!quarternr} quarter - winning margin",
        "name_ru": "{!quarternr} quarter - winning margin",
        "name_tr": "{!quarternr} çeyrek - Kazanma farkı",
        "name_se": "{!quarternr} quarter - winning margin",
        "name_el": "{!quarternr} quarter - winning margin",
        "name_es": "{!quarternr} quarter - winning margin",
        "name_hr": "{!quarternr} četvrtina - pobjednička margina",
        "name_fr": "{!quarternr} quart-temps - marge de victoire"
    },
    "id_229^prematch": {
        "name_en": "Odd/even (incl. overtime)",
        "name_aa": "Odd/even (incl. overtime)",
        "name_de": "Gerade/ungerade (inkl. Verlängerung)",
        "name_nl": "Even/oneven (verlenging inbegrepen)",
        "name_pl": "Odd/even (incl. overtime)",
        "name_ro": "Odd/even (incl. overtime)",
        "name_ru": "Odd/even (incl. overtime)",
        "name_tr": "tek/çift (uzatmalar dahil)",
        "name_se": "Odd/even (incl. overtime)",
        "name_el": "Odd/even (incl. overtime)",
        "name_es": "Odd/even (incl. overtime)",
        "name_hr": "Par/nepar (uklj. produžetke)",
        "name_fr": "Pair/Impair (inclus prolongations)"
    },
    "id_1^prematch": {
        "name_en": "1x2",
        "name_aa": "1x2",
        "name_de": "1x2",
        "name_nl": "1x2",
        "name_pl": "1x2",
        "name_ro": "1x2",
        "name_ru": "1x2",
        "name_tr": "3 Yollu",
        "name_se": "1x2",
        "name_el": "1x2",
        "name_es": "1x2",
        "name_hr": "1x2",
        "name_fr": "1x2"
    },
    "id_246^prematch": {
        "name_en": "{!gamenr} game - point handicap",
        "name_aa": "{!gamenr} game - point handicap",
        "name_de": "{!gamenr} Spiel - Punkte Handicap",
        "name_nl": "{gamenr} game - punten handicap",
        "name_pl": "{!gamenr} game - point handicap",
        "name_ro": "{!gamenr} game - point handicap",
        "name_ru": "{!gamenr} game - point handicap",
        "name_tr": "{!gamenr}. oyun - handikaplı sayı",
        "name_se": "{!gamenr} game - point handicap",
        "name_el": "{!gamenr} game - point handicap",
        "name_es": "{!gamenr} game - point handicap",
        "name_hr": "{!gamenr} gem - poen hendikep",
        "name_fr": "{!gamenr} jeu - handicap points"
    },
    "id_278^prematch": {
        "name_en": "Innings 1 to 5 - {$competitor2} total",
        "name_aa": "Innings 1 to 5 - {$competitor2} total",
        "name_de": "Innings 1 bis 5 - {$competitor2} Total",
        "name_nl": "Inning 1 tot 5 - {$competitor2} totaal",
        "name_pl": "Innings 1 to 5 - {$competitor2} total",
        "name_ro": "Innings 1 to 5 - {$competitor2} total",
        "name_ru": "Innings 1 to 5 - {$competitor2} total",
        "name_tr": "Devreler 1 den 5 e - {$competitor2} toplam",
        "name_se": "Innings 1 to 5 - {$competitor2} total",
        "name_el": "Innings 1 to 5 - {$competitor2} total",
        "name_es": "Innings 1 to 5 - {$competitor2} total",
        "name_hr": "Od 1. do 5. inninga - {$competitor2} total",
        "name_fr": "Manches 1 à 5 - {$competitor2} total"
    },
    "id_617^prematch": {
        "name_en": "{$competitor2} odd/even (incl. overtime)",
        "name_aa": "{$competitor2} odd/even (incl. overtime)",
        "name_de": "{$competitor2} Gerade/ungerade (inkl. Verlängerung)",
        "name_nl": "{$competitor2} even/oneven (verlenging inbegrepen)",
        "name_pl": "{$competitor2} odd/even (incl. overtime)",
        "name_ro": "{$competitor2} odd/even (incl. overtime)",
        "name_ru": "{$competitor2} odd/even (incl. overtime)",
        "name_tr": "{$competitor2} tek/çift (uzatmalar dahil)",
        "name_se": "{$competitor2} odd/even (incl. overtime)",
        "name_el": "{$competitor2} odd/even (incl. overtime)",
        "name_es": "{$competitor2} odd/even (incl. overtime)",
        "name_hr": "{$competitor2} par/nepar (uklj. produžetke)",
        "name_fr": "{$competitor2} pair/impair (inclus prolongations)"
    },
    "id_850^prematch": {
        "name_en": "{$competitor1} to win exactly 1 set",
        "name_aa": "{$competitor1} to win exactly 1 set",
        "name_de": "{$competitor1} wird genau einen Satz gewinnen",
        "name_nl": "{$competitor1} wint precies 1 set",
        "name_pl": "{$competitor1} to win exactly 1 set",
        "name_ro": "{$competitor1} to win exactly 1 set",
        "name_ru": "{$competitor1} to win exactly 1 set",
        "name_tr": "{$competitor1} sadece 1 set kazanır",
        "name_se": "{$competitor1} to win exactly 1 set",
        "name_el": "{$competitor1} to win exactly 1 set",
        "name_es": "{$competitor1} to win exactly 1 set",
        "name_hr": "{$competitor1} osvaja točno 1 set",
        "name_fr": "{$competitor1} gagnera exactement 1 set"
    },
    "id_851^prematch": {
        "name_en": "{$competitor2} to win exactly 1 set",
        "name_aa": "{$competitor2} to win exactly 1 set",
        "name_de": "{$competitor2} wird genau einen Satz gewinnen",
        "name_nl": "{$competitor2} wint precies 1 set",
        "name_pl": "{$competitor2} to win exactly 1 set",
        "name_ro": "{$competitor2} to win exactly 1 set",
        "name_ru": "{$competitor2} to win exactly 1 set",
        "name_tr": "{$competitor2} sadece 1 set kazanır",
        "name_se": "{$competitor2} to win exactly 1 set",
        "name_el": "{$competitor2} to win exactly 1 set",
        "name_es": "{$competitor2} to win exactly 1 set",
        "name_hr": "{$competitor2} osvaja točno 1 set",
        "name_fr": "{$competitor2} gagnera exactement 1 set"
    },
    "id_15^prematch": {
        "name_en": "Winning margin",
        "name_aa": "Winning margin",
        "name_de": "Gewinnspanne",
        "name_nl": "Winstmarge",
        "name_pl": "Winning margin",
        "name_ro": "Winning margin",
        "name_ru": "Winning margin",
        "name_tr": "Kazanma farkı",
        "name_se": "Winning margin",
        "name_el": "Winning margin",
        "name_es": "Winning margin",
        "name_hr": "Pobjednička margina",
        "name_fr": "Marge de victoire"
    },
    "id_372^prematch": {
        "name_en": "{!setnr} set - total legs",
        "name_aa": "{!setnr} set - total legs",
        "name_de": "{!setnr} Satz - Total Runden",
        "name_nl": "{setnr} set - totaal legs",
        "name_pl": "{!setnr} set - total legs",
        "name_ro": "{!setnr} set - total legs",
        "name_ru": "{!setnr} set - total legs",
        "name_tr": "{!setnr}. set - toplam ayak",
        "name_se": "{!setnr} set - total legs",
        "name_el": "{!setnr} set - total legs",
        "name_es": "{!setnr} set - total legs",
        "name_hr": "{!setnr} set - ukupno legova",
        "name_fr": "{!setnr} set - total manches"
    },
    "id_237^prematch": {
        "name_en": "Point handicap",
        "name_aa": "Point handicap",
        "name_de": "Punkte Handicap",
        "name_nl": "Punten handicap",
        "name_pl": "Point handicap",
        "name_ro": "Point handicap",
        "name_ru": "Point handicap",
        "name_tr": "Sayı handikap",
        "name_se": "Point handicap",
        "name_el": "Point handicap",
        "name_es": "Point handicap",
        "name_hr": "Poen hendikep",
        "name_fr": "Handicap points"
    },
    "id_255^prematch": {
        "name_en": "Winning margin (incl. extra innings)",
        "name_aa": "Winning margin (incl. extra innings)",
        "name_de": "Gewinnspanne (inkl. Extra Innings)",
        "name_nl": "Winstmarge (extra innings inbegrepen)",
        "name_pl": "Winning margin (incl. extra innings)",
        "name_ro": "Winning margin (incl. extra innings)",
        "name_ru": "Winning margin (incl. extra innings)",
        "name_tr": "Kazanma farkı (uzatmalar dahil)",
        "name_se": "Winning margin (incl. extra innings)",
        "name_el": "Winning margin (incl. extra innings)",
        "name_es": "Winning margin (incl. extra innings)",
        "name_hr": "Pobjednička margina (uklj. produžetke)",
        "name_fr": "Marge de victoire (inclus manches supplémentaires)"
    },
    "id_2530000^prematch": {
        "name_en": "Total Corners",
        "name_aa": "Total Corners",
        "name_de": "Total Corners",
        "name_nl": "Total Corners",
        "name_pl": "Total Corners",
        "name_ro": "Total Corners",
        "name_ru": "Total Corners",
        "name_tr": "Total Corners",
        "name_se": "Total Corners",
        "name_el": "Total Corners",
        "name_es": "Total Corners",
        "name_hr": "Total Corners",
        "name_fr": "Total Corners"
    },
    "id_532^prematch": {
        "name_en": "Correct score (in sets)",
        "name_aa": "Correct score (in sets)",
        "name_de": "Genaues Ergebnis (in Sätzen)",
        "name_nl": "Juiste score (in sets)",
        "name_pl": "Correct score (in sets)",
        "name_ro": "Correct score (in sets)",
        "name_ru": "Correct score (in sets)",
        "name_tr": "Doğru skor (setlerde)",
        "name_se": "Correct score (in sets)",
        "name_el": "Correct score (in sets)",
        "name_es": "Correct score (in sets)",
        "name_hr": "Točan rezultat (u setovima)",
        "name_fr": "Score correct (en nombre de sets)"
    },
    "id_856^prematch": {
        "name_en": "Draw or over {total}",
        "name_aa": "Draw or over {total}",
        "name_de": "Gleichstand oder über {total}",
        "name_nl": "Gelijkspel of boven {total}",
        "name_pl": "Draw or over {total}",
        "name_ro": "Draw or over {total}",
        "name_ru": "Draw or over {total}",
        "name_tr": "Berabere ya da üst {total}",
        "name_se": "Draw or over {total}",
        "name_el": "Draw or over {total}",
        "name_es": "Draw or over {total}",
        "name_hr": "Neodlučeno ili over {total}",
        "name_fr": "Nul ou Plus de {total}"
    },
    "id_21^prematch": {
        "name_en": "Exact goals",
        "name_aa": "Exact goals",
        "name_de": "Genaue Anzahl Tore",
        "name_nl": "Exact aantal doelpunten",
        "name_pl": "Exact goals",
        "name_ro": "Exact goals",
        "name_ru": "Exact goals",
        "name_tr": "Kesin hedefler",
        "name_se": "Exact goals",
        "name_el": "Exact goals",
        "name_es": "Exact goals",
        "name_hr": "Točno golova",
        "name_fr": "Nombre de buts exact"
    },
    "id_341^prematch": {
        "name_en": "Will there be a super over",
        "name_aa": "Will there be a super over",
        "name_de": "Wird es ein Super over geben?",
        "name_nl": "Zal er een super over nodig zijn",
        "name_pl": "Will there be a super over",
        "name_ro": "Will there be a super over",
        "name_ru": "Will there be a super over",
        "name_tr": "Super over olur mu",
        "name_se": "Will there be a super over",
        "name_el": "Will there be a super over",
        "name_es": "Will there be a super over",
        "name_hr": "Hoće li biti super overa",
        "name_fr": "Y aura-t-il un Super Over ?"
    },
    "id_440000^prematch": {
        "name_en": "Goal In Time Interval",
        "name_aa": "Goal In Time Interval",
        "name_de": "Goal In Time Interval",
        "name_nl": "Goal In Time Interval",
        "name_pl": "Goal In Time Interval",
        "name_ro": "Goal In Time Interval",
        "name_ru": "Goal In Time Interval",
        "name_tr": "Goal In Time Interval",
        "name_se": "Goal In Time Interval",
        "name_el": "Goal In Time Interval",
        "name_es": "Goal In Time Interval",
        "name_hr": "Goal In Time Interval",
        "name_fr": "Goal In Time Interval"
    },
    "id_1175^prematch": {
        "name_en": "1st quarter 1x2 & 1st quarter total",
        "name_aa": "1st quarter 1x2 & 1st quarter total",
        "name_de": "1. Viertel 1x2 & 1. Viertel Total",
        "name_nl": "1st quarter 1x2 & 1st quarter total",
        "name_pl": "1st quarter 1x2 & 1st quarter total",
        "name_ro": "1st quarter 1x2 & 1st quarter total",
        "name_ru": "1st quarter 1x2 & 1st quarter total",
        "name_tr": "1st quarter 1x2 & 1st quarter total",
        "name_se": "1st quarter 1x2 & 1st quarter total",
        "name_el": "1st quarter 1x2 & 1st quarter total",
        "name_es": "1st quarter 1x2 & 1st quarter total",
        "name_hr": "1st quarter 1x2 & 1st quarter total",
        "name_fr": "1st quarter 1x2 & 1st quarter total"
    },
    "id_484^prematch": {
        "name_en": "1st half - try draw no bet",
        "name_aa": "1st half - try draw no bet",
        "name_de": "1. Halbzeit - Versuch unentschieden, keine Wette",
        "name_nl": "Eerste helft - try draw no bet",
        "name_pl": "1st half - try draw no bet",
        "name_ro": "1st half - try draw no bet",
        "name_ru": "1st half - try draw no bet",
        "name_tr": "İlk yarı - try beraberlikte iade",
        "name_se": "1st half - try draw no bet",
        "name_el": "1st half - try draw no bet",
        "name_es": "1st half - try draw no bet",
        "name_hr": "1. poluvrijeme - polaganje ishod bez neodlučenog",
        "name_fr": "1ère mi-temps - Essais remboursé si match nul"
    },
    "id_470^prematch": {
        "name_en": "1st half - winning margin",
        "name_aa": "1st half - winning margin",
        "name_de": "1. Halbzeit - Gewinnspanne",
        "name_nl": "Eerste helft - winstmarge",
        "name_pl": "1st half - winning margin",
        "name_ro": "1st half - winning margin",
        "name_ru": "1st half - winning margin",
        "name_tr": "İlk yarı - Kazanma farkı",
        "name_se": "1st half - winning margin",
        "name_el": "1st half - winning margin",
        "name_es": "1st half - winning margin",
        "name_hr": "1. poluvrijeme - pobjednička margina",
        "name_fr": "1ère mi-temps - marge de victoire"
    },
    "id_18^prematch": {
        "name_en": "Total",
        "name_aa": "Total",
        "name_de": "Total",
        "name_nl": "Totaal",
        "name_pl": "Total",
        "name_ro": "Total",
        "name_ru": "Total",
        "name_tr": "Toplam gol",
        "name_se": "Total",
        "name_el": "Total",
        "name_es": "Total",
        "name_hr": "Ukupno golova",
        "name_fr": "Total"
    },
    "id_155^prematch": {
        "name_en": "1st half - exact bookings",
        "name_aa": "1st half - exact bookings",
        "name_de": "1. Halbzeit - Genaue Anzahl Karten",
        "name_nl": "Eerste helft -  exact aantal kaarten",
        "name_pl": "1st half - exact bookings",
        "name_ro": "1st half - exact bookings",
        "name_ru": "1st half - exact bookings",
        "name_tr": "İlk yarı - doğru kart sayısı",
        "name_se": "1st half - exact bookings",
        "name_el": "1st half - exact bookings",
        "name_es": "1st half - exact bookings",
        "name_hr": "1. Poluvrijeme - Točno kartona",
        "name_fr": "1ère mi-temps - Nombre exact de cartons"
    },
    "id_354^prematch": {
        "name_en": "{!inningnr} innings overs 0 to {overnr} - {$competitor1} run range",
        "name_aa": "{!inningnr} innings overs 0 to {overnr} - {$competitor1} run range",
        "name_de": "{!inningnr} Inning Overs null zu {overnr} - {$competitor1} Laufbereich",
        "name_nl": "{inningnr} innings overs 0 tot {overnr} - run range {$competitor1}",
        "name_pl": "{!inningnr} innings overs 0 to {overnr} - {$competitor1} run range",
        "name_ro": "{!inningnr} innings overs 0 to {overnr} - {$competitor1} run range",
        "name_ru": "{!inningnr} innings overs 0 to {overnr} - {$competitor1} run range",
        "name_tr": "{!inningnr}. devre 0 a {overnr}. over -{$competitor1} koşu aralığı",
        "name_se": "{!inningnr} innings overs 0 to {overnr} - {$competitor1} run range",
        "name_el": "{!inningnr} innings overs 0 to {overnr} - {$competitor1} run range",
        "name_es": "{!inningnr} innings overs 0 to {overnr} - {$competitor1} run range",
        "name_hr": "{!inningnr} inning - over od 0 do {overnr} - {$competitor1} opseg runova",
        "name_fr": "{!inningnr} manche Séries de lancers 0 à {overnr} - {$competitor1} intervalles nombre de runs"
    },
    "id_345^prematch": {
        "name_en": "{!inningnr} innings - {$competitor1} run range",
        "name_aa": "{!inningnr} innings - {$competitor1} run range",
        "name_de": "{!inningnr} Inning - {$competitor1} Laufbereich",
        "name_nl": "{inningnr} innings - run range {$competitor1}",
        "name_pl": "{!inningnr} innings - {$competitor1} run range",
        "name_ro": "{!inningnr} innings - {$competitor1} run range",
        "name_ru": "{!inningnr} innings - {$competitor1} run range",
        "name_tr": "{!inningnr}. devre - {$competitor1} koşu aralığı",
        "name_se": "{!inningnr} innings - {$competitor1} run range",
        "name_el": "{!inningnr} innings - {$competitor1} run range",
        "name_es": "{!inningnr} innings - {$competitor1} run range",
        "name_hr": "{!inningnr} inning - {$competitor1} opseg runova",
        "name_fr": "{!inningnr} manche - {$competitor1} intervalles nombre de runs"
    },
    "id_20920000^prematch": {
        "name_en": "Half Time/Full Time - Corner",
        "name_aa": "Half Time/Full Time - Corner",
        "name_de": "Half Time/Full Time - Corner",
        "name_nl": "Half Time/Full Time - Corner",
        "name_pl": "Half Time/Full Time - Corner",
        "name_ro": "Half Time/Full Time - Corner",
        "name_ru": "Half Time/Full Time - Corner",
        "name_tr": "Half Time/Full Time - Corner",
        "name_se": "Half Time/Full Time - Corner",
        "name_el": "Half Time/Full Time - Corner",
        "name_es": "Half Time/Full Time - Corner",
        "name_hr": "Half Time/Full Time - Corner",
        "name_fr": "Half Time/Full Time - Corner"
    },
    "id_480^prematch": {
        "name_en": "{$competitor2} total tries",
        "name_aa": "{$competitor2} total tries",
        "name_de": "{$competitor2} Total Versuche",
        "name_nl": "Totaal aantal tries {$competitor2}",
        "name_pl": "{$competitor2} total tries",
        "name_ro": "{$competitor2} total tries",
        "name_ru": "{$competitor2} total tries",
        "name_tr": "{$competitor2} toplam try",
        "name_se": "{$competitor2} total tries",
        "name_el": "{$competitor2} total tries",
        "name_es": "{$competitor2} total tries",
        "name_hr": "{$competitor2} ukupno polaganja",
        "name_fr": "{$competitor2} total d'essais"
    },
    "id_36^prematch": {
        "name_en": "Total & both teams to score",
        "name_aa": "Total & both teams to score",
        "name_de": "Total & Beide Mannschaften treffen",
        "name_nl": "Totaal & beide teams scoren",
        "name_pl": "Total & both teams to score",
        "name_ro": "Total & both teams to score",
        "name_ru": "Total & both teams to score",
        "name_tr": "Toplam gol ve karşılıklı gol",
        "name_se": "Total & both teams to score",
        "name_el": "Total & both teams to score",
        "name_es": "Total & both teams to score",
        "name_hr": "Ukupno golova i oba daju gol",
        "name_fr": "Total & Les deux équipes qui marquent"
    },
    "id_71^prematch": {
        "name_en": "1st half - exact goals",
        "name_aa": "1st half - exact goals",
        "name_de": "1. Halbzeit - Genaue Anzahl Tore",
        "name_nl": "1e helft - exact aantal doelpunten",
        "name_pl": "1st half - exact goals",
        "name_ro": "1st half - exact goals",
        "name_ru": "1st half - exact goals",
        "name_tr": "ilk yarı - doğru skor",
        "name_se": "1st half - exact goals",
        "name_el": "1st half - exact goals",
        "name_es": "1st half - exact goals",
        "name_hr": "1. Poluvrijeme - točno golova",
        "name_fr": "1ère mi-temps - nombre exact de buts"
    },
    "id_616^prematch": {
        "name_en": "{$competitor1} odd/even (incl. overtime)",
        "name_aa": "{$competitor1} odd/even (incl. overtime)",
        "name_de": "{$competitor1} Gerade/ungerade (inkl. Verlängerung)",
        "name_nl": "{$competitor1} even/oneven (verlenging inbegrepen)",
        "name_pl": "{$competitor1} odd/even (incl. overtime)",
        "name_ro": "{$competitor1} odd/even (incl. overtime)",
        "name_ru": "{$competitor1} odd/even (incl. overtime)",
        "name_tr": "{$competitor1} tek/çift (uzatmalar dahil)",
        "name_se": "{$competitor1} odd/even (incl. overtime)",
        "name_el": "{$competitor1} odd/even (incl. overtime)",
        "name_es": "{$competitor1} odd/even (incl. overtime)",
        "name_hr": "{$competitor1} par/nepar (uklj. produžetke)",
        "name_fr": "{$competitor1} pair/impair (inclus prolongations)"
    },
    "id_291^prematch": {
        "name_en": "{!pointnr} point (incl. overtime)",
        "name_aa": "{!pointnr} point (incl. overtime)",
        "name_de": "{!pointnr} Punkt (inkl. Verlängerung)",
        "name_nl": "{pointnr} punt (verlenging inbegrepen)",
        "name_pl": "{!pointnr} point (incl. overtime)",
        "name_ro": "{!pointnr} point (incl. overtime)",
        "name_ru": "{!pointnr} point (incl. overtime)",
        "name_tr": "{!pointnr}. sayı (uzatmalar dahil)",
        "name_se": "{!pointnr} point (incl. overtime)",
        "name_el": "{!pointnr} point (incl. overtime)",
        "name_es": "{!pointnr} point (incl. overtime)",
        "name_hr": "{!pointnr} poen (uklj. produžetke)",
        "name_fr": "{!pointnr} point (inclus prolongations)"
    },
    "id_33040000^prematch": {
        "name_en": "A Penalty in the 2nd Half",
        "name_aa": "A Penalty in the 2nd Half",
        "name_de": "A Penalty in the 2nd Half",
        "name_nl": "A Penalty in the 2nd Half",
        "name_pl": "A Penalty in the 2nd Half",
        "name_ro": "A Penalty in the 2nd Half",
        "name_ru": "A Penalty in the 2nd Half",
        "name_tr": "A Penalty in the 2nd Half",
        "name_se": "A Penalty in the 2nd Half",
        "name_el": "A Penalty in the 2nd Half",
        "name_es": "A Penalty in the 2nd Half",
        "name_hr": "A Penalty in the 2nd Half",
        "name_fr": "A Penalty in the 2nd Half"
    },
    "id_66^prematch": {
        "name_en": "1st half - handicap",
        "name_aa": "1st half - handicap",
        "name_de": "1. Halbzeit - Handicap",
        "name_nl": "1e helft - handicap",
        "name_pl": "1st half - handicap",
        "name_ro": "1st half - handicap",
        "name_ru": "1st half - handicap",
        "name_tr": "İlk Yarı - handikap",
        "name_se": "1st half - handicap",
        "name_el": "1st half - handicap",
        "name_es": "1st half - handicap",
        "name_hr": "1. Poluvrijeme - hendikep",
        "name_fr": "1ère mi-temps - handicap"
    },
    "id_275^prematch": {
        "name_en": "Innings 1 to 5 - handicap",
        "name_aa": "Innings 1 to 5 - handicap",
        "name_de": "Innings 1 bis 5 - Handicap",
        "name_nl": "Inning 1 tot 5 - handicap",
        "name_pl": "Innings 1 to 5 - handicap",
        "name_ro": "Innings 1 to 5 - handicap",
        "name_ru": "Innings 1 to 5 - handicap",
        "name_tr": "Devreler 1 den 5 e - handikap",
        "name_se": "Innings 1 to 5 - handicap",
        "name_el": "Innings 1 to 5 - handicap",
        "name_es": "Innings 1 to 5 - handicap",
        "name_hr": "Od 1. do 5. inninga - hendikep",
        "name_fr": "Manches 1 à 5 - handicap"
    },
    "id_462^prematch": {
        "name_en": "{!periodnr} period - odd/even",
        "name_aa": "{!periodnr} period - odd/even",
        "name_de": "{!periodnr} Periode - Gerade/ungerade",
        "name_nl": "{periodnr} periode - even/oneven",
        "name_pl": "{!periodnr} period - odd/even",
        "name_ro": "{!periodnr} period - odd/even",
        "name_ru": "{!periodnr} period - odd/even",
        "name_tr": "{!periodnr}. periyot - tek/çift",
        "name_se": "{!periodnr} period - odd/even",
        "name_el": "{!periodnr} period - odd/even",
        "name_es": "{!periodnr} period - odd/even",
        "name_hr": "{!periodnr} period - par/nepar",
        "name_fr": "{!periodnr} période - pair/impair"
    },
    "id_27020000^prematch": {
        "name_en": "A Penalty in the Match",
        "name_aa": "A Penalty in the Match",
        "name_de": "A Penalty in the Match",
        "name_nl": "A Penalty in the Match",
        "name_pl": "A Penalty in the Match",
        "name_ro": "A Penalty in the Match",
        "name_ru": "A Penalty in the Match",
        "name_tr": "A Penalty in the Match",
        "name_se": "A Penalty in the Match",
        "name_el": "A Penalty in the Match",
        "name_es": "A Penalty in the Match",
        "name_hr": "A Penalty in the Match",
        "name_fr": "A Penalty in the Match"
    },
    "id_739^prematch": {
        "name_en": "When will the {!runnr} run be scored (incl. extra innings)",
        "name_aa": "When will the {!runnr} run be scored (incl. extra innings)",
        "name_de": "Wann wird der {!runnr} Run erzielt (inkl. Extra-Innings)",
        "name_nl": "Wanneer wordt de {runnr} run gescoord (extra innings inbegrepen)",
        "name_pl": "When will the {!runnr} run be scored (incl. extra innings)",
        "name_ro": "When will the {!runnr} run be scored (incl. extra innings)",
        "name_ru": "When will the {!runnr} run be scored (incl. extra innings)",
        "name_tr": "{!runnr}. koşu ne xaman olur (uzatmalar dahil)",
        "name_se": "When will the {!runnr} run be scored (incl. extra innings)",
        "name_el": "When will the {!runnr} run be scored (incl. extra innings)",
        "name_es": "When will the {!runnr} run be scored (incl. extra innings)",
        "name_hr": "Kada će {!runnr} run biti postignut (uklj. dodatne inninge)",
        "name_fr": "Quand sera marqué le {!runnr} run (inclus manches supplémentaires)"
    },
    "id_618^prematch": {
        "name_en": "1st half - {$competitor1} odd/even",
        "name_aa": "1st half - {$competitor1} odd/even",
        "name_de": "1. Halbzeit - {$competitor1} Gerade/ungerade",
        "name_nl": "Eerste helft - {$competitor1} even/oneven",
        "name_pl": "1st half - {$competitor1} odd/even",
        "name_ro": "1st half - {$competitor1} odd/even",
        "name_ru": "1st half - {$competitor1} odd/even",
        "name_tr": "İlk yarı - {$competitor1} tek/çift",
        "name_se": "1st half - {$competitor1} odd/even",
        "name_el": "1st half - {$competitor1} odd/even",
        "name_es": "1st half - {$competitor1} odd/even",
        "name_hr": "1. poluvrijeme - {$competitor1} par/nepar",
        "name_fr": "1ère mi-temps - {$competitor1} pair/impair"
    },
    "id_192^prematch": {
        "name_en": "{$competitor1} to win a set",
        "name_aa": "{$competitor1} to win a set",
        "name_de": "{$competitor1} wird einen Satz gewinnen",
        "name_nl": "{$competitor1} wint een set",
        "name_pl": "{$competitor1} to win a set",
        "name_ro": "{$competitor1} to win a set",
        "name_ru": "{$competitor1} to win a set",
        "name_tr": "{$competitor1} set kazanır",
        "name_se": "{$competitor1} to win a set",
        "name_el": "{$competitor1} to win a set",
        "name_es": "{$competitor1} to win a set",
        "name_hr": "{$competitor1} osvaja set",
        "name_fr": "{$competitor1} remporte un set"
    },
    "id_101^prematch": {
        "name_en": "When will the {!goalnr} goal be scored (10 min interval)",
        "name_aa": "When will the {!goalnr} goal be scored (10 min interval)",
        "name_de": "Wann wird das {!goalnr}Tor erzielt? (10 Min Intervall )",
        "name_nl": "Wanneer wordt het {goalnr} doelpunt gescoord (10 min interval)",
        "name_pl": "When will the {!goalnr} goal be scored (10 min interval)",
        "name_ro": "When will the {!goalnr} goal be scored (10 min interval)",
        "name_ru": "When will the {!goalnr} goal be scored (10 min interval)",
        "name_tr": "{!goalnr} gol ne zaman atılır (10 dakikalık aralıkla)",
        "name_se": "When will the {!goalnr} goal be scored (10 min interval)",
        "name_el": "When will the {!goalnr} goal be scored (10 min interval)",
        "name_es": "When will the {!goalnr} goal be scored (10 min interval)",
        "name_hr": "Kada će {!goalnr} gol biti dat (10 min interval)",
        "name_fr": "Quand le {!goalnr} but sera-t-il marqué (intervalle 10 minutes)"
    },
    "id_385^prematch": {
        "name_en": "{$competitor1} total 180s",
        "name_aa": "{$competitor1} total 180s",
        "name_de": "{$competitor1} Total 180s",
        "name_nl": "Totaal aantal 180s {$competitor1}",
        "name_pl": "{$competitor1} total 180s",
        "name_ro": "{$competitor1} total 180s",
        "name_ru": "{$competitor1} total 180s",
        "name_tr": "{$competitor1} toplam 180ler",
        "name_se": "{$competitor1} total 180s",
        "name_el": "{$competitor1} total 180s",
        "name_es": "{$competitor1} total 180s",
        "name_hr": "{$competitor1} ukupno 180-ki",
        "name_fr": "{$competitor1} total de 180"
    },
    "id_614^prematch": {
        "name_en": "{!quarternr} quarter - handicap (incl. overtime)",
        "name_aa": "{!quarternr} quarter - handicap (incl. overtime)",
        "name_de": "{!quarternr} Viertel - Handicap (inkl. Verlängerung)",
        "name_nl": "{quarternr} kwart - handicap (verlenging inbegrepen)",
        "name_pl": "{!quarternr} quarter - handicap (incl. overtime)",
        "name_ro": "{!quarternr} quarter - handicap (incl. overtime)",
        "name_ru": "{!quarternr} quarter - handicap (incl. overtime)",
        "name_tr": "{!quarternr}. çeyrek - handikap (uzatmalar dahil)",
        "name_se": "{!quarternr} quarter - handicap (incl. overtime)",
        "name_el": "{!quarternr} quarter - handicap (incl. overtime)",
        "name_es": "{!quarternr} quarter - handicap (incl. overtime)",
        "name_hr": "{!quarternr} četvrtina - hendikep (uklj. produžetke)",
        "name_fr": "{!quarternr} quart-temps - handicap (inclus prolongations)"
    },
    "id_187^prematch": {
        "name_en": "Game handicap",
        "name_aa": "Game handicap",
        "name_de": "Spiel Handicap",
        "name_nl": "Game handicap",
        "name_pl": "Game handicap",
        "name_ro": "Game handicap",
        "name_ru": "Game handicap",
        "name_tr": "Toplam oyun handikapı",
        "name_se": "Game handicap",
        "name_el": "Game handicap",
        "name_es": "Game handicap",
        "name_hr": "Gem hendikep",
        "name_fr": "Jeux handicap"
    },
    "id_310^prematch": {
        "name_en": "{!setnr} set - total points",
        "name_aa": "{!setnr} set - total points",
        "name_de": "{!setnr} Satz - Anzahl Punkte",
        "name_nl": "{setnr} set - totaal aantal punten",
        "name_pl": "{!setnr} set - total points",
        "name_ro": "{!setnr} set - total points",
        "name_ru": "{!setnr} set - total points",
        "name_tr": "{!setnr}. set - toplam sayı",
        "name_se": "{!setnr} set - total points",
        "name_el": "{!setnr} set - total points",
        "name_es": "{!setnr} set - total points",
        "name_hr": "{!setnr} set - ukupno poena",
        "name_fr": "{!setnr} set - total points"
    },
    "id_69^prematch": {
        "name_en": "1st half - {$competitor1} total",
        "name_aa": "1st half - {$competitor1} total",
        "name_de": "1. Halbzeit - {$competitor1} Total",
        "name_nl": "1e helft - {$competitor1} totaal",
        "name_pl": "1st half - {$competitor1} total",
        "name_ro": "1st half - {$competitor1} total",
        "name_ru": "1st half - {$competitor1} total",
        "name_tr": "İlk Yarı - {$competitor1} toplam gol",
        "name_se": "1st half - {$competitor1} total",
        "name_el": "1st half - {$competitor1} total",
        "name_es": "1st half - {$competitor1} total",
        "name_hr": "1. Poluvrijeme - {$competitor1} točno golova",
        "name_fr": "1ère mi-temps - {$competitor1} total"
    },
    "id_471^prematch": {
        "name_en": "1st half - point range",
        "name_aa": "1st half - point range",
        "name_de": "1. Halbzeit - Punktebereich",
        "name_nl": "Eerste helft - punten range",
        "name_pl": "1st half - point range",
        "name_ro": "1st half - point range",
        "name_ru": "1st half - point range",
        "name_tr": "İlk yarı - sayı aralığı",
        "name_se": "1st half - point range",
        "name_el": "1st half - point range",
        "name_es": "1st half - point range",
        "name_hr": "1. poluvrijeme - opseg poena",
        "name_fr": "1ère mi-temps - intervalles nombre de points"
    },
    "id_352^prematch": {
        "name_en": "{!inningnr} innings overs 0 to {overnr} - {$competitor1} total",
        "name_aa": "{!inningnr} innings overs 0 to {overnr} - {$competitor1} total",
        "name_de": "{!inningnr} Inning Overs null zu {overnr} - {$competitor1} Total",
        "name_nl": "{inningnr} innings overs 0 tot {overnr} - totaal {$competitor1}",
        "name_pl": "{!inningnr} innings overs 0 to {overnr} - {$competitor1} total",
        "name_ro": "{!inningnr} innings overs 0 to {overnr} - {$competitor1} total",
        "name_ru": "{!inningnr} innings overs 0 to {overnr} - {$competitor1} total",
        "name_tr": "{!inningnr}. devre 0 a {overnr}. over -{$competitor1} toplam",
        "name_se": "{!inningnr} innings overs 0 to {overnr} - {$competitor1} total",
        "name_el": "{!inningnr} innings overs 0 to {overnr} - {$competitor1} total",
        "name_es": "{!inningnr} innings overs 0 to {overnr} - {$competitor1} total",
        "name_hr": "{!inningnr} inning - over od 0 do {overnr} - {$competitor1} total",
        "name_fr": "{!inningnr} manche Séries de lancers 0 à {overnr} - {$competitor1} total"
    },
    "id_303^prematch": {
        "name_en": "{!quarternr} quarter - handicap",
        "name_aa": "{!quarternr} quarter - handicap",
        "name_de": "{!quarternr} Viertel - Handicap",
        "name_nl": "{quarternr} kwart - handicap",
        "name_pl": "{!quarternr} quarter - handicap",
        "name_ro": "{!quarternr} quarter - handicap",
        "name_ru": "{!quarternr} quarter - handicap",
        "name_tr": "{!quarternr} çeyrek - handikap",
        "name_se": "{!quarternr} quarter - handicap",
        "name_el": "{!quarternr} quarter - handicap",
        "name_es": "{!quarternr} quarter - handicap",
        "name_hr": "{!quarternr} četvrtina - hendikep",
        "name_fr": "{!quarternr} quart-temps - handicap"
    },
    "id_292^prematch": {
        "name_en": "Winner & total (incl. overtime)",
        "name_aa": "Winner & total (incl. overtime)",
        "name_de": "Gewinner & Total (inkl. Verlängerung)",
        "name_nl": "Winnaar & totaal (verlenging inbegrepen)",
        "name_pl": "Winner & total (incl. overtime)",
        "name_ro": "Winner & total (incl. overtime)",
        "name_ru": "Winner & total (incl. overtime)",
        "name_tr": "Kazanan & toplam gol (uzatmalar dahil)",
        "name_se": "Winner & total (incl. overtime)",
        "name_el": "Winner & total (incl. overtime)",
        "name_es": "Winner & total (incl. overtime)",
        "name_hr": "Pobjednik i total (uklj. produžetke)",
        "name_fr": "Vainqueur & total (inclus prolongations)"
    },
    "id_53^prematch": {
        "name_en": "{$competitor1} highest scoring half",
        "name_aa": "{$competitor1} highest scoring half",
        "name_de": "{$competitor1} Halbzeit mit den meisten Toren",
        "name_nl": "{$competitor1} wint helft met de meeste doelpunten",
        "name_pl": "{$competitor1} highest scoring half",
        "name_ro": "{$competitor1} highest scoring half",
        "name_ru": "{$competitor1} highest scoring half",
        "name_tr": "{$competitor1} en gollü devre",
        "name_se": "{$competitor1} highest scoring half",
        "name_el": "{$competitor1} highest scoring half",
        "name_es": "{$competitor1} highest scoring half",
        "name_hr": "{$competitor1} poluvrijeme s najviše golova",
        "name_fr": "{$competitor1} Mi-temps avec le plus de buts marqués"
    },
    "id_191^prematch": {
        "name_en": "{$competitor2} total games",
        "name_aa": "{$competitor2} total games",
        "name_de": "{$competitor2} Gesamtanzahl Spiele",
        "name_nl": "Totaal aantal games {$competitor2}",
        "name_pl": "{$competitor2} total games",
        "name_ro": "{$competitor2} total games",
        "name_ru": "{$competitor2} total games",
        "name_tr": "{$competitor2} toplam oyun",
        "name_se": "{$competitor2} total games",
        "name_el": "{$competitor2} total games",
        "name_es": "{$competitor2} total games",
        "name_hr": "{$competitor2} ukupno gemova",
        "name_fr": "{$competitor2} total jeux"
    },
    "id_858^prematch": {
        "name_en": "{$competitor2} or over {total}",
        "name_aa": "{$competitor2} or over {total}",
        "name_de": "{$competitor2} oder über {total}",
        "name_nl": "{$competitor2} of boven {total}",
        "name_pl": "{$competitor2} or over {total}",
        "name_ro": "{$competitor2} or over {total}",
        "name_ru": "{$competitor2} or over {total}",
        "name_tr": "{$competitor2} ya da üst {total}",
        "name_se": "{$competitor2} or over {total}",
        "name_el": "{$competitor2} or over {total}",
        "name_es": "{$competitor2} or over {total}",
        "name_hr": "{$competitor2} ili over {total}",
        "name_fr": "{$competitor2} ou Plus de {total}"
    },
    "id_1124^prematch": {
        "name_en": "Innings 1 to 5 - Winner",
        "name_aa": "Innings 1 to 5 - Winner",
        "name_de": "Inning 1 bis 5 - Sieger",
        "name_nl": "Innings 1 tot 5 - Winnaar",
        "name_pl": "Innings 1 to 5 - Winner",
        "name_ro": "Innings 1 to 5 - Winner",
        "name_ru": "Innings 1 to 5 - Winner",
        "name_tr": "Innings 1 to 5 - Winner",
        "name_se": "Innings 1 to 5 - Winner",
        "name_el": "Innings 1 to 5 - Winner",
        "name_es": "Innings 1 to 5 - Winner",
        "name_hr": "Innings 1 to 5 - Winner",
        "name_fr": "Manche 1 à 5 - Vainqueur"
    },
    "id_159^prematch": {
        "name_en": "1st half - sending off",
        "name_aa": "1st half - sending off",
        "name_de": "1. Halbzeit - Platzverweise",
        "name_nl": "Eerste helft - uitsluitingen",
        "name_pl": "1st half - sending off",
        "name_ro": "1st half - sending off",
        "name_ru": "1st half - sending off",
        "name_tr": "İlk yarı - oyundan atılma",
        "name_se": "1st half - sending off",
        "name_el": "1st half - sending off",
        "name_es": "1st half - sending off",
        "name_hr": "1. Poluvrijeme - isključenja",
        "name_fr": "1ère mi-temps - Expulsion ?"
    },
    "id_966^prematch": {
        "name_en": "{$competitor2} to lead by {points}",
        "name_aa": "{$competitor2} to lead by {points}",
        "name_de": "{$competitor2} führt mit {points}",
        "name_nl": "{$competitor2} staat {points} punten voor",
        "name_pl": "{$competitor2} to lead by {points}",
        "name_ro": "{$competitor2} to lead by {points}",
        "name_ru": "{$competitor2} to lead by {points}",
        "name_tr": "{$competitor2} to lead by {points}",
        "name_se": "{$competitor2} to lead by {points}",
        "name_el": "{$competitor2} to lead by {points}",
        "name_es": "{$competitor2} to lead by {points}",
        "name_hr": "{$competitor2} to lead by {points}",
        "name_fr": "{$competitor2} de mener de {points}"
    },
    "id_383^prematch": {
        "name_en": "180s handicap",
        "name_aa": "180s handicap",
        "name_de": "180s Handicap",
        "name_nl": "180s handicap",
        "name_pl": "180s handicap",
        "name_ro": "180s handicap",
        "name_ru": "180s handicap",
        "name_tr": "180 handikapı",
        "name_se": "180s handicap",
        "name_el": "180s handicap",
        "name_es": "180s handicap",
        "name_hr": "Hendikep 180-ki",
        "name_fr": "180 handicap"
    },
    "id_863^prematch": {
        "name_en": "{$competitor1} or any clean sheet",
        "name_aa": "{$competitor1} or any clean sheet",
        "name_de": "{$competitor1} or any clean sheet",
        "name_nl": "{$competitor1} of een schoon doel",
        "name_pl": "{$competitor1} or any clean sheet",
        "name_ro": "{$competitor1} or any clean sheet",
        "name_ru": "{$competitor1} or any clean sheet",
        "name_tr": "{$competitor1} ya da gol yok",
        "name_se": "{$competitor1} or any clean sheet",
        "name_el": "{$competitor1} or any clean sheet",
        "name_es": "{$competitor1} or any clean sheet",
        "name_hr": "{$competitor1} ili bez primljenog gola",
        "name_fr": "{$competitor1} ou une des cages reste inviolée"
    },
    "id_183^prematch": {
        "name_en": "1st half - odd/even corners",
        "name_aa": "1st half - odd/even corners",
        "name_de": "1. Halbzeit -  Gerade/ungerade Ecken",
        "name_nl": "Eerste helft - even/oneven aantal hoekschoppen",
        "name_pl": "1st half - odd/even corners",
        "name_ro": "1st half - odd/even corners",
        "name_ru": "1st half - odd/even corners",
        "name_tr": "İlk yarı - tek/çift kornerler",
        "name_se": "1st half - odd/even corners",
        "name_el": "1st half - odd/even corners",
        "name_es": "1st half - odd/even corners",
        "name_hr": "1. Poluvrijeme - par/nepar udarci iz kuta",
        "name_fr": "1ère mi-temps - pair/Impair corners"
    },
    "id_386^prematch": {
        "name_en": "{$competitor2} total 180s",
        "name_aa": "{$competitor2} total 180s",
        "name_de": "{$competitor2} Total 180s",
        "name_nl": "Totaal aantal 180s {$competitor2}",
        "name_pl": "{$competitor2} total 180s",
        "name_ro": "{$competitor2} total 180s",
        "name_ru": "{$competitor2} total 180s",
        "name_tr": "{$competitor2} toplam 180ler",
        "name_se": "{$competitor2} total 180s",
        "name_el": "{$competitor2} total 180s",
        "name_es": "{$competitor2} total 180s",
        "name_hr": "{$competitor2} ukupno 180-ki",
        "name_fr": "{$competitor2} total de 180"
    },
    "id_353^prematch": {
        "name_en": "{!inningnr} innings overs 0 to {overnr} - {$competitor2} total",
        "name_aa": "{!inningnr} innings overs 0 to {overnr} - {$competitor2} total",
        "name_de": "{!inningnr} Inning Overs null zu {overnr} - {$competitor2} Total",
        "name_nl": "{inningnr} innings overs 0 tot {overnr} - totaal {$competitor2}",
        "name_pl": "{!inningnr} innings overs 0 to {overnr} - {$competitor2} total",
        "name_ro": "{!inningnr} innings overs 0 to {overnr} - {$competitor2} total",
        "name_ru": "{!inningnr} innings overs 0 to {overnr} - {$competitor2} total",
        "name_tr": "{!inningnr}. devre 0 a {overnr}. over -{$competitor2} toplam",
        "name_se": "{!inningnr} innings overs 0 to {overnr} - {$competitor2} total",
        "name_el": "{!inningnr} innings overs 0 to {overnr} - {$competitor2} total",
        "name_es": "{!inningnr} innings overs 0 to {overnr} - {$competitor2} total",
        "name_hr": "{!inningnr} inning - over od 0 do {overnr} - {$competitor2} total",
        "name_fr": "{!inningnr} manche Séries de lancers 0 à {overnr} - {$competitor2} total"
    },
    "id_57^prematch": {
        "name_en": "{$competitor2} to score in both halves",
        "name_aa": "{$competitor2} to score in both halves",
        "name_de": "{$competitor2} wird beide Hälften treffen",
        "name_nl": "{$competitor2} scoort in beide helften",
        "name_pl": "{$competitor2} to score in both halves",
        "name_ro": "{$competitor2} to score in both halves",
        "name_ru": "{$competitor2} to score in both halves",
        "name_tr": "{$competitor2} her iki devrede de gol bulur",
        "name_se": "{$competitor2} to score in both halves",
        "name_el": "{$competitor2} to score in both halves",
        "name_es": "{$competitor2} to score in both halves",
        "name_hr": "{$competitor2} daje gol u oba poluvremena",
        "name_fr": "{$competitor2} qui marque dans les deux périodes"
    },
    "id_457^prematch": {
        "name_en": "{!periodnr} period - last team to score",
        "name_aa": "{!periodnr} period - last team to score",
        "name_de": "{!periodnr} Periode - Letzte Mannschaft trifft",
        "name_nl": "{periodnr} periode - laatst scorende team",
        "name_pl": "{!periodnr} period - last team to score",
        "name_ro": "{!periodnr} period - last team to score",
        "name_ru": "{!periodnr} period - last team to score",
        "name_tr": "{!periodnr}. periyot - son golü kim atar",
        "name_se": "{!periodnr} period - last team to score",
        "name_el": "{!periodnr} period - last team to score",
        "name_es": "{!periodnr} period - last team to score",
        "name_hr": "{!periodnr} period - posljednji tim koji daje gol",
        "name_fr": "{!periodnr} période - dernière équipe qui va marquer"
    },
    "id_1174^prematch": {
        "name_en": "Handicap (including OT) & Total (including OT)",
        "name_aa": "Handicap (including OT) & Total (including OT)",
        "name_de": "Handicap (including OT) & Total (including OT)",
        "name_nl": "Handicap (including OT) & Total (including OT)",
        "name_pl": "Handicap (including OT) & Total (including OT)",
        "name_ro": "Handicap (including OT) & Total (including OT)",
        "name_ru": "Handicap (including OT) & Total (including OT)",
        "name_tr": "Handicap (including OT) & Total (including OT)",
        "name_se": "Handicap (including OT) & Total (including OT)",
        "name_el": "Handicap (including OT) & Total (including OT)",
        "name_es": "Handicap (including OT) & Total (including OT)",
        "name_hr": "Handicap (including OT) & Total (including OT)",
        "name_fr": "Handicap (including OT) & Total (including OT)"
    },
    "id_493^prematch": {
        "name_en": "Frame handicap",
        "name_aa": "Frame handicap",
        "name_de": "Frame Handicap",
        "name_nl": "Frame handicap",
        "name_pl": "Frame handicap",
        "name_ro": "Frame handicap",
        "name_ru": "Frame handicap",
        "name_tr": "Frame handikapı",
        "name_se": "Frame handicap",
        "name_el": "Frame handicap",
        "name_es": "Frame handicap",
        "name_hr": "Frame hendikep",
        "name_fr": "Manche handicap"
    },
    "id_161^prematch": {
        "name_en": "1st half - {$competitor2} sending off",
        "name_aa": "1st half - {$competitor2} sending off",
        "name_de": "1. Halbzeit - {$competitor2} Platzverweise",
        "name_nl": "Eerste helft - uitsluitingen {$competitor2}",
        "name_pl": "1st half - {$competitor2} sending off",
        "name_ro": "1st half - {$competitor2} sending off",
        "name_ru": "1st half - {$competitor2} sending off",
        "name_tr": "İlk yarı - {$competitor2} oyundan atılma",
        "name_se": "1st half - {$competitor2} sending off",
        "name_el": "1st half - {$competitor2} sending off",
        "name_es": "1st half - {$competitor2} sending off",
        "name_hr": "1. Poluvrijeme - {$competitor2} isključenja",
        "name_fr": "1ère mi-temps - {$competitor2} expulsion"
    },
    "id_864^prematch": {
        "name_en": "Draw or any clean sheet",
        "name_aa": "Draw or any clean sheet",
        "name_de": "Draw or any clean sheet",
        "name_nl": "Gelijkspel of een schoon doel",
        "name_pl": "Draw or any clean sheet",
        "name_ro": "Draw or any clean sheet",
        "name_ru": "Draw or any clean sheet",
        "name_tr": "Beraber ya da gol yok",
        "name_se": "Draw or any clean sheet",
        "name_el": "Draw or any clean sheet",
        "name_es": "Draw or any clean sheet",
        "name_hr": "Neodlučeno ili bez primljenog gola",
        "name_fr": "Nul ou une des cages reste inviolée"
    },
    "id_30^prematch": {
        "name_en": "Which team to score",
        "name_aa": "Which team to score",
        "name_de": "Welche Mannschaft trifft",
        "name_nl": "Welk team scoort",
        "name_pl": "Which team to score",
        "name_ro": "Which team to score",
        "name_ru": "Which team to score",
        "name_tr": "Golü atacak takım",
        "name_se": "Which team to score",
        "name_el": "Which team to score",
        "name_es": "Which team to score",
        "name_hr": "Koji tim daje gol",
        "name_fr": "Quelle équipe va marquer"
    },
    "id_235^prematch": {
        "name_en": "{!quarternr} quarter - 1x2",
        "name_aa": "{!quarternr} quarter - 1x2",
        "name_de": "{!quarternr} Viertel - 1x2",
        "name_nl": "{quarternr} kwart - 1x2",
        "name_pl": "{!quarternr} quarter - 1x2",
        "name_ro": "{!quarternr} quarter - 1x2",
        "name_ru": "{!quarternr} quarter - 1x2",
        "name_tr": "{!quarternr}. çeyrek - 1x2",
        "name_se": "{!quarternr} quarter - 1x2",
        "name_el": "{!quarternr} quarter - 1x2",
        "name_es": "{!quarternr} quarter - 1x2",
        "name_hr": "{!quarternr} četvrtina - 1x2",
        "name_fr": "{!quarternr} quart-temps - 1x2"
    },
    "id_175^prematch": {
        "name_en": "1st half - last corner",
        "name_aa": "1st half - last corner",
        "name_de": "1. Halbzeit - Letzte Ecke",
        "name_nl": "Eerste helft - laatste hoekschop",
        "name_pl": "1st half - last corner",
        "name_ro": "1st half - last corner",
        "name_ru": "1st half - last corner",
        "name_tr": "İlk yarı - son korner",
        "name_se": "1st half - last corner",
        "name_el": "1st half - last corner",
        "name_es": "1st half - last corner",
        "name_hr": "1. Poluvrijeme - posljednji udarac iz kuta",
        "name_fr": "1ère mi-temps - dernier corner"
    },
    "id_97^prematch": {
        "name_en": "2nd half - {$competitor2} clean sheet",
        "name_aa": "2nd half - {$competitor2} clean sheet",
        "name_de": "2. Halbzeit - {$competitor2} ohne Gegentreffer",
        "name_nl": "2e helft - {$competitor2} houdt doel schoon",
        "name_pl": "2nd half - {$competitor2} clean sheet",
        "name_ro": "2nd half - {$competitor2} clean sheet",
        "name_ru": "2nd half - {$competitor2} clean sheet",
        "name_tr": "İkinci yarı - {$competitor2} gol yemez",
        "name_se": "2nd half - {$competitor2} clean sheet",
        "name_el": "2nd half - {$competitor2} clean sheet",
        "name_es": "2nd half - {$competitor2} clean sheet",
        "name_hr": "2. poluvrijeme - {$competitor2} bez primljenog gola",
        "name_fr": "2ème mi-temps - {$competitor2} cage inviolée"
    },
    "id_559^prematch": {
        "name_en": "Free text market",
        "name_aa": "Free text market",
        "name_de": "Free text market",
        "name_nl": "Free text market",
        "name_pl": "Free text market",
        "name_ro": "Free text market",
        "name_ru": "Free text market",
        "name_tr": "Free text market",
        "name_se": "Free text market",
        "name_el": "Free text market",
        "name_es": "Free text market",
        "name_hr": "Free text market",
        "name_fr": "Free text market"
    },
    "id_1045^prematch": {
        "name_en": "{!inningnr} inning - {$competitor2} total hits",
        "name_aa": "{!inningnr} inning - {$competitor2} total hits",
        "name_de": "{!inningnr} Inning - {$competitor2} Total Hits",
        "name_nl": "{inningnr} inning - {$competitor2} totaal aantal slagen",
        "name_pl": "{!inningnr} inning - {$competitor2} total hits",
        "name_ro": "{!inningnr} inning - {$competitor2} total hits",
        "name_ru": "{!inningnr} inning - {$competitor2} total hits",
        "name_tr": "{!inningnr} inning - {$competitor2} total hits",
        "name_se": "{!inningnr} inning - {$competitor2} total hits",
        "name_el": "{!inningnr} inning - {$competitor2} total hits",
        "name_es": "{!inningnr} inning - {$competitor2} total hits",
        "name_hr": "{!inningnr} inning - {$competitor2} total hits",
        "name_fr": "{!inningnr} manche - {$competitor2} nombre de frappes (hits)"
    },
    "id_87^prematch": {
        "name_en": "2nd half - handicap {hcp}",
        "name_aa": "2nd half - handicap {hcp}",
        "name_de": "2. Halbzeit - Handicap {hcp}",
        "name_nl": "2e helft - handicap {hcp}",
        "name_pl": "2nd half - handicap {hcp}",
        "name_ro": "2nd half - handicap {hcp}",
        "name_ru": "2nd half - handicap {hcp}",
        "name_tr": "İkinci yarı - handikap {hcp}",
        "name_se": "2nd half - handicap {hcp}",
        "name_el": "2nd half - handicap {hcp}",
        "name_es": "2nd half - handicap {hcp}",
        "name_hr": "2. Poluvrijeme - hendikep {hcp}",
        "name_fr": "2ème mi-temps - handicap {hcp}"
    },
    "id_2^prematch": {
        "name_en": "To qualify",
        "name_aa": "To qualify",
        "name_de": "Qualifiziert sich",
        "name_nl": "Te kwalificeren",
        "name_pl": "To qualify",
        "name_ro": "To qualify",
        "name_ru": "To qualify",
        "name_tr": "Finale çıkan",
        "name_se": "To qualify",
        "name_el": "To qualify",
        "name_es": "To qualify",
        "name_hr": "ide dalje",
        "name_fr": "Se qualifie"
    },
    "id_100^prematch": {
        "name_en": "When will the {!goalnr} goal be scored (15 min interval)",
        "name_aa": "When will the {!goalnr} goal be scored (15 min interval)",
        "name_de": "Wann wird das {!goalnr}Tor erzielt? (15 Min Intervall )",
        "name_nl": "Wanneer wordt het {goalnr} doelpunt gescoord (15 min interval)",
        "name_pl": "When will the {!goalnr} goal be scored (15 min interval)",
        "name_ro": "When will the {!goalnr} goal be scored (15 min interval)",
        "name_ru": "When will the {!goalnr} goal be scored (15 min interval)",
        "name_tr": "{!goalnr} gol ne zaman atılır (15 dakikalık aralıkla)",
        "name_se": "When will the {!goalnr} goal be scored (15 min interval)",
        "name_el": "When will the {!goalnr} goal be scored (15 min interval)",
        "name_es": "When will the {!goalnr} goal be scored (15 min interval)",
        "name_hr": "Kada će {!goalnr} gol biti dat (15 min interval)",
        "name_fr": "Quand le {!goalnr} but sera-t-il marqué (intervalle 15 minutes)"
    },
    "id_548^prematch": {
        "name_en": "Multigoals",
        "name_aa": "Multigoals",
        "name_de": "Gesamtzahl der Tore im Spiel",
        "name_nl": "Multigoals",
        "name_pl": "Multigoals",
        "name_ro": "Multigoals",
        "name_ru": "Multigoals",
        "name_tr": "Toplam gol",
        "name_se": "Multigoals",
        "name_el": "Multigoals",
        "name_es": "Multigoals",
        "name_hr": "Multigolovi",
        "name_fr": "Multi buts"
    },
    "id_190^prematch": {
        "name_en": "{$competitor1} total games",
        "name_aa": "{$competitor1} total games",
        "name_de": "{$competitor1} Gesamtanzahl Spiele",
        "name_nl": "Totaal aantal games {$competitor1}",
        "name_pl": "{$competitor1} total games",
        "name_ro": "{$competitor1} total games",
        "name_ru": "{$competitor1} total games",
        "name_tr": "{$competitor1} toplam oyun",
        "name_se": "{$competitor1} total games",
        "name_el": "{$competitor1} total games",
        "name_es": "{$competitor1} total games",
        "name_hr": "{$competitor1} ukupno gemova",
        "name_fr": "{$competitor1} total jeux"
    },
    "id_38^prematch": {
        "name_en": "{!goalnr} goalscorer",
        "name_aa": "{!goalnr} goalscorer",
        "name_de": "{!goalnr} Torschütze",
        "name_nl": "{goalnr} doelpuntenmaker",
        "name_pl": "{!goalnr} goalscorer",
        "name_ro": "{!goalnr} goalscorer",
        "name_ru": "{!goalnr} goalscorer",
        "name_tr": "{!goalnr} gol atan",
        "name_se": "{!goalnr} goalscorer",
        "name_el": "{!goalnr} goalscorer",
        "name_es": "{!goalnr} goalscorer",
        "name_hr": "{!goalnr} strijelac",
        "name_fr": "{!goalnr} buteur"
    },
    "id_9^prematch": {
        "name_en": "Last goal",
        "name_aa": "Last goal",
        "name_de": "letztes Tor",
        "name_nl": "Laatste doelpunt",
        "name_pl": "Last goal",
        "name_ro": "Last goal",
        "name_ru": "Last goal",
        "name_tr": "Son gol",
        "name_se": "Last goal",
        "name_el": "Last goal",
        "name_es": "Last goal",
        "name_hr": "Posljednji gol",
        "name_fr": "Dernier but"
    },
    "id_227^prematch": {
        "name_en": "{$competitor1} total (incl. overtime)",
        "name_aa": "{$competitor1} total (incl. overtime)",
        "name_de": "{$competitor1} Total (inkl. Verlängerung)",
        "name_nl": "{$competitor1} totaal (verlenging inbegrepen)",
        "name_pl": "{$competitor1} total (incl. overtime)",
        "name_ro": "{$competitor1} total (incl. overtime)",
        "name_ru": "{$competitor1} total (incl. overtime)",
        "name_tr": "{$competitor1} toplam gol (uzatmalar dahil)",
        "name_se": "{$competitor1} total (incl. overtime)",
        "name_el": "{$competitor1} total (incl. overtime)",
        "name_es": "{$competitor1} total (incl. overtime)",
        "name_hr": "{$competitor1} total (uklj. produžetke)",
        "name_fr": "{$competitor1} total (inclus prolongations)"
    },
    "id_2720000^prematch": {
        "name_en": "First Half Corners",
        "name_aa": "First Half Corners",
        "name_de": "First Half Corners",
        "name_nl": "First Half Corners",
        "name_pl": "First Half Corners",
        "name_ro": "First Half Corners",
        "name_ru": "First Half Corners",
        "name_tr": "First Half Corners",
        "name_se": "First Half Corners",
        "name_el": "First Half Corners",
        "name_es": "First Half Corners",
        "name_hr": "First Half Corners",
        "name_fr": "First Half Corners"
    },
    "id_34^prematch": {
        "name_en": "{$competitor2} win to nil",
        "name_aa": "{$competitor2} win to nil",
        "name_de": "{$competitor2} gewinnt zu null",
        "name_nl": "{$competitor2} wint zonder tegengoal",
        "name_pl": "{$competitor2} win to nil",
        "name_ro": "{$competitor2} win to nil",
        "name_ru": "{$competitor2} win to nil",
        "name_tr": "{$competitor2} gol yemeden kazanır",
        "name_se": "{$competitor2} win to nil",
        "name_el": "{$competitor2} win to nil",
        "name_es": "{$competitor2} win to nil",
        "name_hr": "{$competitor2} pobjeda bez primljenog gola",
        "name_fr": "{$competitor2} gagne sans prendre de buts"
    },
    "id_932^prematch": {
        "name_en": "Total field goals made (incl. overtime)",
        "name_aa": "Total field goals made (incl. overtime)",
        "name_de": "Field Goals Gesamt (inkl. Verlängerung)",
        "name_nl": "Totaal aantal veldgoals (incl. verlenging)",
        "name_pl": "Total field goals made (incl. overtime)",
        "name_ro": "Total field goals made (incl. overtime)",
        "name_ru": "Total field goals made (incl. overtime)",
        "name_tr": "Total field goals made (incl. overtime)",
        "name_se": "Total field goals made (incl. overtime)",
        "name_el": "Total field goals made (incl. overtime)",
        "name_es": "Total field goals made (incl. overtime)",
        "name_hr": "Total field goals made (incl. overtime)",
        "name_fr": "Nombre de transformations (field goals) réussies (prolongations incluses)"
    },
    "id_258^prematch": {
        "name_en": "Total (incl. extra innings)",
        "name_aa": "Total (incl. extra innings)",
        "name_de": "Total (inkl. Extra Innings)",
        "name_nl": "Totaal (extra innings inbegrepen)",
        "name_pl": "Total (incl. extra innings)",
        "name_ro": "Total (incl. extra innings)",
        "name_ru": "Total (incl. extra innings)",
        "name_tr": "Toplam (uzatmalar dahil)",
        "name_se": "Total (incl. extra innings)",
        "name_el": "Total (incl. extra innings)",
        "name_es": "Total (incl. extra innings)",
        "name_hr": "Total (uklj. produžetke)",
        "name_fr": "Total (inclus manches supplémentaires)"
    },
    "id_965^prematch": {
        "name_en": "{$competitor1} to lead by {points}",
        "name_aa": "{$competitor1} to lead by {points}",
        "name_de": "{$competitor1} führt mit {points}",
        "name_nl": "{$competitor1} staat {points} punten voor",
        "name_pl": "{$competitor1} to lead by {points}",
        "name_ro": "{$competitor1} to lead by {points}",
        "name_ru": "{$competitor1} to lead by {points}",
        "name_tr": "{$competitor1} to lead by {points}",
        "name_se": "{$competitor1} to lead by {points}",
        "name_el": "{$competitor1} to lead by {points}",
        "name_es": "{$competitor1} to lead by {points}",
        "name_hr": "{$competitor1} to lead by {points}",
        "name_fr": "{$competitor1} de mener de {points}"
    },
    "id_865^prematch": {
        "name_en": "{$competitor2} or any clean sheet",
        "name_aa": "{$competitor2} or any clean sheet",
        "name_de": "{$competitor2} or any clean sheet",
        "name_nl": "{$competitor2} of een schoon doel",
        "name_pl": "{$competitor2} or any clean sheet",
        "name_ro": "{$competitor2} or any clean sheet",
        "name_ru": "{$competitor2} or any clean sheet",
        "name_tr": "{$competitor2} ya da gol yok",
        "name_se": "{$competitor2} or any clean sheet",
        "name_el": "{$competitor2} or any clean sheet",
        "name_es": "{$competitor2} or any clean sheet",
        "name_hr": "{$competitor2} ili bez primljenog gola",
        "name_fr": "{$competitor2} ou une des cages reste inviolée"
    },
    "id_527^prematch": {
        "name_en": "{!setnr} set - handicap",
        "name_aa": "{!setnr} set - handicap",
        "name_de": "{!setnr} Satz - Handicap",
        "name_nl": "{setnr} set - handicap",
        "name_pl": "{!setnr} set - handicap",
        "name_ro": "{!setnr} set - handicap",
        "name_ru": "{!setnr} set - handicap",
        "name_tr": "{!setnr}. set - handikap",
        "name_se": "{!setnr} set - handicap",
        "name_el": "{!setnr} set - handicap",
        "name_es": "{!setnr} set - handicap",
        "name_hr": "{!setnr} set - hendikep",
        "name_fr": "{!setnr} set - handicap"
    },
    "id_194^prematch": {
        "name_en": "Any set to nil",
        "name_aa": "Any set to nil",
        "name_de": "Jeder Satz zu null",
        "name_nl": "Een set zonder game tegen",
        "name_pl": "Any set to nil",
        "name_ro": "Any set to nil",
        "name_ru": "Any set to nil",
        "name_tr": "Sıfıra karşı herhangi set",
        "name_se": "Any set to nil",
        "name_el": "Any set to nil",
        "name_es": "Any set to nil",
        "name_hr": "Barem jedan set sa nulom",
        "name_fr": "Un des sets sans concéder de jeu"
    },
    "id_230^prematch": {
        "name_en": "Race to {pointnr} points (incl. overtime)",
        "name_aa": "Race to {pointnr} points (incl. overtime)",
        "name_de": "Wer erreicht zuerst {pointnr} Punkte (inkl. Verlängerung)?",
        "name_nl": "Race naar {pointnr} punten (verlenging inbegrepen)",
        "name_pl": "Race to {pointnr} points (incl. overtime)",
        "name_ro": "Race to {pointnr} points (incl. overtime)",
        "name_ru": "Race to {pointnr} points (incl. overtime)",
        "name_tr": "{pointnr}. sayı çekişmesi (uzatmalar dahil)",
        "name_se": "Race to {pointnr} points (incl. overtime)",
        "name_el": "Race to {pointnr} points (incl. overtime)",
        "name_es": "Race to {pointnr} points (incl. overtime)",
        "name_hr": "Utrka do {pointnr} poena (uklj. produžetke)",
        "name_fr": "Atteindre en premier {pointnr} points (inclus prolongations)"
    },
    "id_95^prematch": {
        "name_en": "2nd half - both teams to score",
        "name_aa": "2nd half - both teams to score",
        "name_de": "2. Halbzeit - Beide Mannschaften treffen",
        "name_nl": "2e helft - beide teams scoren",
        "name_pl": "2nd half - both teams to score",
        "name_ro": "2nd half - both teams to score",
        "name_ru": "2nd half - both teams to score",
        "name_tr": "İkinci yarı - her iki takım da gol atar",
        "name_se": "2nd half - both teams to score",
        "name_el": "2nd half - both teams to score",
        "name_es": "2nd half - both teams to score",
        "name_hr": "2. poluvrijeme - oba daju gol",
        "name_fr": "2ème mi-temps - les deux équipes qui marquent"
    },
    "id_251^prematch": {
        "name_en": "Winner (incl. extra innings)",
        "name_aa": "Winner (incl. extra innings)",
        "name_de": "Gewinner (inkl. Extra Innings)",
        "name_nl": "Winnaar (extra innings inbegrepen)",
        "name_pl": "Winner (incl. extra innings)",
        "name_ro": "Winner (incl. extra innings)",
        "name_ru": "Winner (incl. extra innings)",
        "name_tr": "Kazanan (uzatmalar dahil)",
        "name_se": "Winner (incl. extra innings)",
        "name_el": "Winner (incl. extra innings)",
        "name_es": "Winner (incl. extra innings)",
        "name_hr": "Pobjednik (uklj. produžetke)",
        "name_fr": "Vainqueur (inclus manches supplémentaires)"
    },
    "id_552^prematch": {
        "name_en": "1st half - multigoals",
        "name_aa": "1st half - multigoals",
        "name_de": "1. Halbzeit - Gesamtzahl der Tore im Spiel",
        "name_nl": "Eerste helft - multigoals",
        "name_pl": "1st half - multigoals",
        "name_ro": "1st half - multigoals",
        "name_ru": "1st half - multigoals",
        "name_tr": "İlk yarı - birden fazla gol",
        "name_se": "1st half - multigoals",
        "name_el": "1st half - multigoals",
        "name_es": "1st half - multigoals",
        "name_hr": "1. poluvrijeme - multigolovi",
        "name_fr": "1ère mi-temps - multi buts"
    },
    "id_33000000^prematch": {
        "name_en": "Race to 9 Corners {sk}",
        "name_aa": "Race to 9 Corners {sk}",
        "name_de": "Race to 9 Corners {sk}",
        "name_nl": "Race to 9 Corners {sk}",
        "name_pl": "Race to 9 Corners {sk}",
        "name_ro": "Race to 9 Corners {sk}",
        "name_ru": "Race to 9 Corners {sk}",
        "name_tr": "Race to 9 Corners {sk}",
        "name_se": "Race to 9 Corners {sk}",
        "name_el": "Race to 9 Corners {sk}",
        "name_es": "Race to 9 Corners {sk}",
        "name_hr": "Race to 9 Corners {sk}",
        "name_fr": "Race to 9 Corners {sk}"
    },
    "id_207^prematch": {
        "name_en": "{!setnr} set - correct score",
        "name_aa": "{!setnr} set - correct score",
        "name_de": "{!setnr} Satz - Genaues Ergebnis",
        "name_nl": "{setnr} set - juiste uitslag",
        "name_pl": "{!setnr} set - correct score",
        "name_ro": "{!setnr} set - correct score",
        "name_ru": "{!setnr} set - correct score",
        "name_tr": "{!setnr}. set - doğru skor",
        "name_se": "{!setnr} set - correct score",
        "name_el": "{!setnr} set - correct score",
        "name_es": "{!setnr} set - correct score",
        "name_hr": "{!setnr} set - točan rezultat",
        "name_fr": "{!setnr} set - score correct"
    },
    "id_267^prematch": {
        "name_en": "Race to {runnr} runs (incl. extra innings)",
        "name_aa": "Race to {runnr} runs (incl. extra innings)",
        "name_de": "Wer erreicht zuerst {runnr} Läufe (inkl. Extra Innings)?",
        "name_nl": "Race naar {runnr} runs (extra innings inbegrepen)",
        "name_pl": "Race to {runnr} runs (incl. extra innings)",
        "name_ro": "Race to {runnr} runs (incl. extra innings)",
        "name_ru": "Race to {runnr} runs (incl. extra innings)",
        "name_tr": "{runnr}. koşunun çekişmesi (uzatmalar dahil)",
        "name_se": "Race to {runnr} runs (incl. extra innings)",
        "name_el": "Race to {runnr} runs (incl. extra innings)",
        "name_es": "Race to {runnr} runs (incl. extra innings)",
        "name_hr": "Utrka do {runnr} runova (uklj. produžetke)",
        "name_fr": "Atteindre en premier {runnr} runs (inclus manches supplémentaires)"
    },
    "id_17220000^prematch": {
        "name_en": "Number of Corners in the 1st Half - 2nd Half",
        "name_aa": "Number of Corners in the 1st Half - 2nd Half",
        "name_de": "Number of Corners in the 1st Half - 2nd Half",
        "name_nl": "Number of Corners in the 1st Half - 2nd Half",
        "name_pl": "Number of Corners in the 1st Half - 2nd Half",
        "name_ro": "Number of Corners in the 1st Half - 2nd Half",
        "name_ru": "Number of Corners in the 1st Half - 2nd Half",
        "name_tr": "Number of Corners in the 1st Half - 2nd Half",
        "name_se": "Number of Corners in the 1st Half - 2nd Half",
        "name_el": "Number of Corners in the 1st Half - 2nd Half",
        "name_es": "Number of Corners in the 1st Half - 2nd Half",
        "name_hr": "Number of Corners in the 1st Half - 2nd Half",
        "name_fr": "Number of Corners in the 1st Half - 2nd Half"
    },
    "id_304^prematch": {
        "name_en": "{!quarternr} quarter - odd/even",
        "name_aa": "{!quarternr} quarter - odd/even",
        "name_de": "{!quarternr} Viertel - Gerade/ungerade",
        "name_nl": "{quarternr} kwart - even/oneven",
        "name_pl": "{!quarternr} quarter - odd/even",
        "name_ro": "{!quarternr} quarter - odd/even",
        "name_ru": "{!quarternr} quarter - odd/even",
        "name_tr": "{!quarternr} çeyrek - tek/çift",
        "name_se": "{!quarternr} quarter - odd/even",
        "name_el": "{!quarternr} quarter - odd/even",
        "name_es": "{!quarternr} quarter - odd/even",
        "name_hr": "{!quarternr} četvrtina - par/nepar",
        "name_fr": "{!quarternr} quart-temps - pair/impair"
    },
    "id_1047^prematch": {
        "name_en": "Innings 1 to 5 - {$competitor1} total hits",
        "name_aa": "Innings 1 to 5 - {$competitor1} total hits",
        "name_de": "Inning 1 bis 5 - {$competitor1} Total Hits",
        "name_nl": "Innings 1 tot 5 - {$competitor1} totaal aantal slagen",
        "name_pl": "Innings 1 to 5 - {$competitor1} total hits",
        "name_ro": "Innings 1 to 5 - {$competitor1} total hits",
        "name_ru": "Innings 1 to 5 - {$competitor1} total hits",
        "name_tr": "Innings 1 to 5 - {$competitor1} total hits",
        "name_se": "Innings 1 to 5 - {$competitor1} total hits",
        "name_el": "Innings 1 to 5 - {$competitor1} total hits",
        "name_es": "Innings 1 to 5 - {$competitor1} total hits",
        "name_hr": "Innings 1 to 5 - {$competitor1} total hits",
        "name_fr": "Manches 1 à 5 - {$competitor1} nombre de bonnes balles"
    },
    "id_11^prematch": {
        "name_en": "Draw no bet",
        "name_aa": "Draw no bet",
        "name_de": "Unentschieden, keine Wette",
        "name_nl": "Draw no bet",
        "name_pl": "Draw no bet",
        "name_ro": "Draw no bet",
        "name_ru": "Draw no bet",
        "name_tr": "Beraberlikte iade",
        "name_se": "Draw no bet",
        "name_el": "Draw no bet",
        "name_es": "Draw no bet",
        "name_hr": "Ishod bez neodlučenog",
        "name_fr": "Remboursé si match nul"
    },
    "id_46^prematch": {
        "name_en": "Halftime/fulltime correct score",
        "name_aa": "Halftime/fulltime correct score",
        "name_de": "Halbzeit/reg. Spielzeit - Genaues Ergebnis",
        "name_nl": "Correcte rust/eindstand",
        "name_pl": "Halftime/fulltime correct score",
        "name_ro": "Halftime/fulltime correct score",
        "name_ru": "Halftime/fulltime correct score",
        "name_tr": "ilk yarı/maç sonucu skoru",
        "name_se": "Halftime/fulltime correct score",
        "name_el": "Halftime/fulltime correct score",
        "name_es": "Halftime/fulltime correct score",
        "name_hr": "Poluvrijeme/kraj Točan rezultat",
        "name_fr": "Mi-temps/Fin de match score correct"
    },
    "id_294^prematch": {
        "name_en": "2nd half - draw no bet (incl. overtime)",
        "name_aa": "2nd half - draw no bet (incl. overtime)",
        "name_de": "2. Halbzeit - unentschieden, keine Wette (inkl. Verlängerung)",
        "name_nl": "Tweede helft - draw no bet (verlenging inbegrepen)",
        "name_pl": "2nd half - draw no bet (incl. overtime)",
        "name_ro": "2nd half - draw no bet (incl. overtime)",
        "name_ru": "2nd half - draw no bet (incl. overtime)",
        "name_tr": "İkinci yarı - beraberlikte iade (uzatmalar dahil)",
        "name_se": "2nd half - draw no bet (incl. overtime)",
        "name_el": "2nd half - draw no bet (incl. overtime)",
        "name_es": "2nd half - draw no bet (incl. overtime)",
        "name_hr": "2. poluvrijeme - ishod bez neodlučenog (uklj. produžetke)",
        "name_fr": "2ème mi-temps - remboursé si match nul (inclus prolongations)"
    },
    "id_551^prematch": {
        "name_en": "Multiscores",
        "name_aa": "Multiscores",
        "name_de": "Endergebnis",
        "name_nl": "Multiscores",
        "name_pl": "Multiscores",
        "name_ro": "Multiscores",
        "name_ru": "Multiscores",
        "name_tr": "Toplam skor",
        "name_se": "Multiscores",
        "name_el": "Multiscores",
        "name_es": "Multiscores",
        "name_hr": "Multirezultat",
        "name_fr": "Multi scores"
    },
    "id_459^prematch": {
        "name_en": "{!periodnr} period - draw no bet",
        "name_aa": "{!periodnr} period - draw no bet",
        "name_de": "{!periodnr} Periode - unentschieden, keine Wette",
        "name_nl": "{periodnr} periode - draw no bet",
        "name_pl": "{!periodnr} period - draw no bet",
        "name_ro": "{!periodnr} period - draw no bet",
        "name_ru": "{!periodnr} period - draw no bet",
        "name_tr": "{!periodnr}. periyot - beraberlikte iade",
        "name_se": "{!periodnr} period - draw no bet",
        "name_el": "{!periodnr} period - draw no bet",
        "name_es": "{!periodnr} period - draw no bet",
        "name_hr": "{!periodnr} period - ishod bez neodlučenog",
        "name_fr": "{!periodnr} période - remboursé si match nul"
    },
    "id_47^prematch": {
        "name_en": "Halftime/fulltime",
        "name_aa": "Halftime/fulltime",
        "name_de": "Halbzeit/reg. Spielzeit",
        "name_nl": "Rust/eindstand",
        "name_pl": "Halftime/fulltime",
        "name_ro": "Halftime/fulltime",
        "name_ru": "Halftime/fulltime",
        "name_tr": "İlk yarı/Maç sonucu",
        "name_se": "Halftime/fulltime",
        "name_el": "Halftime/fulltime",
        "name_es": "Halftime/fulltime",
        "name_hr": "Poluvrijeme/kraj",
        "name_fr": "Mi-temps/Fin de match"
    },
    "id_962^prematch": {
        "name_en": "{$competitor1} total maximum consecutive points",
        "name_aa": "{$competitor1} total maximum consecutive points",
        "name_de": "{$competitor1} Maximal aufeinanderfolgende Punkte Gesamt",
        "name_nl": "{$competitor1} totaal aantal maximum opeenvolgende punten",
        "name_pl": "{$competitor1} total maximum consecutive points",
        "name_ro": "{$competitor1} total maximum consecutive points",
        "name_ru": "{$competitor1} total maximum consecutive points",
        "name_tr": "{$competitor1} total maximum consecutive points",
        "name_se": "{$competitor1} total maximum consecutive points",
        "name_el": "{$competitor1} total maximum consecutive points",
        "name_es": "{$competitor1} total maximum consecutive points",
        "name_hr": "{$competitor1} total maximum consecutive points",
        "name_fr": "{$competitor1} nombre maximum de points marqués consécutivement"
    },
    "id_490^prematch": {
        "name_en": "1st half - odd/even tries",
        "name_aa": "1st half - odd/even tries",
        "name_de": "1. Halbzeit - Gerade/ungerade Versuche",
        "name_nl": "Eerste helft - even/oneven aantal tries",
        "name_pl": "1st half - odd/even tries",
        "name_ro": "1st half - odd/even tries",
        "name_ru": "1st half - odd/even tries",
        "name_tr": "İlk yarı - tek/çift try",
        "name_se": "1st half - odd/even tries",
        "name_el": "1st half - odd/even tries",
        "name_es": "1st half - odd/even tries",
        "name_hr": "1. poluvrijeme - par/nepar polaganja",
        "name_fr": "1ère mi-temps - pair/impair Essais"
    },
    "id_483^prematch": {
        "name_en": "1st half - try double chance",
        "name_aa": "1st half - try double chance",
        "name_de": "1. Halbzeit - Versuch Doppelte Chance",
        "name_nl": "Eerste helft - try dubbele kans",
        "name_pl": "1st half - try double chance",
        "name_ro": "1st half - try double chance",
        "name_ru": "1st half - try double chance",
        "name_tr": "İlk yarı - try çifte şans",
        "name_se": "1st half - try double chance",
        "name_el": "1st half - try double chance",
        "name_es": "1st half - try double chance",
        "name_hr": "1. poluvrijeme - polaganje dvostruka prilika",
        "name_fr": "1ère mi-temps - Essais double chance"
    },
    "id_248^prematch": {
        "name_en": "{!gamenr} game - odd/even",
        "name_aa": "{!gamenr} game - odd/even",
        "name_de": "{!gamenr} Spiel - Gerade/ungerade",
        "name_nl": "{gamenr} game - even/oneven",
        "name_pl": "{!gamenr} game - odd/even",
        "name_ro": "{!gamenr} game - odd/even",
        "name_ru": "{!gamenr} game - odd/even",
        "name_tr": "{!gamenr}. oyun - tek/çift",
        "name_se": "{!gamenr} game - odd/even",
        "name_el": "{!gamenr} game - odd/even",
        "name_es": "{!gamenr} game - odd/even",
        "name_hr": "{!gamenr} gem - par/nepar",
        "name_fr": "{!gamenr} jeu - pair/impair"
    },
    "id_58^prematch": {
        "name_en": "Both halves over {total}",
        "name_aa": "Both halves over {total}",
        "name_de": "Beide Hälften über {total}",
        "name_nl": "Beide helften meer dan {total}",
        "name_pl": "Both halves over {total}",
        "name_ro": "Both halves over {total}",
        "name_ru": "Both halves over {total}",
        "name_tr": "Her iki yarı {total} üstü",
        "name_se": "Both halves over {total}",
        "name_el": "Both halves over {total}",
        "name_es": "Both halves over {total}",
        "name_hr": "Oba poluvremena više od {total}",
        "name_fr": "Les deux mi-temps Plus de {total}"
    },
    "id_384^prematch": {
        "name_en": "Total 180s",
        "name_aa": "Total 180s",
        "name_de": "Total 180s",
        "name_nl": "Totaal aantal 180s",
        "name_pl": "Total 180s",
        "name_ro": "Total 180s",
        "name_ru": "Total 180s",
        "name_tr": "Toplam 180ler",
        "name_se": "Total 180s",
        "name_el": "Total 180s",
        "name_es": "Total 180s",
        "name_hr": "Ukupno 180-ki",
        "name_fr": "Total de 180"
    },
    "id_137^prematch": {
        "name_en": "{!bookingnr} booking",
        "name_aa": "{!bookingnr} booking",
        "name_de": "{!bookingnr} Karten",
        "name_nl": "{bookingnr} kaart",
        "name_pl": "{!bookingnr} booking",
        "name_ro": "{!bookingnr} booking",
        "name_ru": "{!bookingnr} booking",
        "name_tr": "{!bookingnr} kart",
        "name_se": "{!bookingnr} booking",
        "name_el": "{!bookingnr} booking",
        "name_es": "{!bookingnr} booking",
        "name_hr": "{!bookingnr} kartoni",
        "name_fr": "{!bookingnr} carton"
    },
    "id_156^prematch": {
        "name_en": "1st half - {$competitor1} exact bookings",
        "name_aa": "1st half - {$competitor1} exact bookings",
        "name_de": "1. Halbzeit -  {$competitor1} Genaue Anzahl Karten",
        "name_nl": "Eerste helft - exact aantal kaarten {$competitor1}",
        "name_pl": "1st half - {$competitor1} exact bookings",
        "name_ro": "1st half - {$competitor1} exact bookings",
        "name_ru": "1st half - {$competitor1} exact bookings",
        "name_tr": "İlk yarı - {$competitor1} doğru kart sayısı",
        "name_se": "1st half - {$competitor1} exact bookings",
        "name_el": "1st half - {$competitor1} exact bookings",
        "name_es": "1st half - {$competitor1} exact bookings",
        "name_hr": "1. Poluvrijeme - {$competitor1} Točno kartona",
        "name_fr": "1ère mi-temps - {$competitor1} nombre exact de cartons"
    },
    "id_33030000^prematch": {
        "name_en": "A Penalty in the 1st Half",
        "name_aa": "A Penalty in the 1st Half",
        "name_de": "A Penalty in the 1st Half",
        "name_nl": "A Penalty in the 1st Half",
        "name_pl": "A Penalty in the 1st Half",
        "name_ro": "A Penalty in the 1st Half",
        "name_ru": "A Penalty in the 1st Half",
        "name_tr": "A Penalty in the 1st Half",
        "name_se": "A Penalty in the 1st Half",
        "name_el": "A Penalty in the 1st Half",
        "name_es": "A Penalty in the 1st Half",
        "name_hr": "A Penalty in the 1st Half",
        "name_fr": "A Penalty in the 1st Half"
    },
    "id_544^prematch": {
        "name_en": "2nd half - 1x2 & total",
        "name_aa": "2nd half - 1x2 & total",
        "name_de": "2. Halbzeit - 1x2 & Total",
        "name_nl": "Tweede helft - 1x2 & totaal",
        "name_pl": "2nd half - 1x2 & total",
        "name_ro": "2nd half - 1x2 & total",
        "name_ru": "2nd half - 1x2 & total",
        "name_tr": "İkinci yarı - 1x2 & toplam gol",
        "name_se": "2nd half - 1x2 & total",
        "name_el": "2nd half - 1x2 & total",
        "name_es": "2nd half - 1x2 & total",
        "name_hr": "2. poluvrijeme - 1x2 i ukupno golova",
        "name_fr": "2ème mi-temps - 1x2 & total"
    },
    "id_477^prematch": {
        "name_en": "Try handicap",
        "name_aa": "Try handicap",
        "name_de": "Versuch Handicap",
        "name_nl": "Try handicap",
        "name_pl": "Try handicap",
        "name_ro": "Try handicap",
        "name_ru": "Try handicap",
        "name_tr": "Handikap dene",
        "name_se": "Try handicap",
        "name_el": "Try handicap",
        "name_es": "Try handicap",
        "name_hr": "Polaganje hendikep",
        "name_fr": "Essais handicap"
    },
    "id_1042^prematch": {
        "name_en": "{$competitor2} total hits (incl. extra innings)",
        "name_aa": "{$competitor2} total hits (incl. extra innings)",
        "name_de": "{$competitor2} Hits Gesamt (inkl. Extra Innings)",
        "name_nl": "{$competitor2} totaal aantal slagen (incl. extra innings)",
        "name_pl": "{$competitor2} total hits (incl. extra innings)",
        "name_ro": "{$competitor2} total hits (incl. extra innings)",
        "name_ru": "{$competitor2} total hits (incl. extra innings)",
        "name_tr": "{$competitor2} total hits (incl. extra innings)",
        "name_se": "{$competitor2} total hits (incl. extra innings)",
        "name_el": "{$competitor2} total hits (incl. extra innings)",
        "name_es": "{$competitor2} total hits (incl. extra innings)",
        "name_hr": "{$competitor2} total hits (incl. extra innings)",
        "name_fr": "{$competitor2} nombre de frappes (hits) (prolongations incluses)"
    },
    "id_29^prematch": {
        "name_en": "Both teams to score",
        "name_aa": "Both teams to score",
        "name_de": "Beide Mannschaften treffen",
        "name_nl": "Beide teams scoren",
        "name_pl": "Both teams to score",
        "name_ro": "Both teams to score",
        "name_ru": "Both teams to score",
        "name_tr": "Karşılıklı gol",
        "name_se": "Both teams to score",
        "name_el": "Both teams to score",
        "name_es": "Both teams to score",
        "name_hr": "Oba daju gol",
        "name_fr": "Les deux équipes qui marquent"
    },
    "id_539^prematch": {
        "name_en": "Head2head",
        "name_aa": "Head2head",
        "name_de": "Head2head",
        "name_nl": "Head2head",
        "name_pl": "Head2head",
        "name_ro": "Head2head",
        "name_ru": "Head2head",
        "name_tr": "Head to head",
        "name_se": "Head2head",
        "name_el": "Head2head",
        "name_es": "Head2head",
        "name_hr": "Međusobni omjer",
        "name_fr": "Face à face"
    },
    "id_607^prematch": {
        "name_en": "{!inningnr} innings - {$competitor2} total",
        "name_aa": "{!inningnr} innings - {$competitor2} total",
        "name_de": "{!inningnr} Inning  {$competitor2} Total",
        "name_nl": "{inningnr} innings - {$competitor2} totaal",
        "name_pl": "{!inningnr} innings - {$competitor2} total",
        "name_ro": "{!inningnr} innings - {$competitor2} total",
        "name_ru": "{!inningnr} innings - {$competitor2} total",
        "name_tr": "{!inningnr}. devre - {$competitor2} toplam",
        "name_se": "{!inningnr} innings - {$competitor2} total",
        "name_el": "{!inningnr} innings - {$competitor2} total",
        "name_es": "{!inningnr} innings - {$competitor2} total",
        "name_hr": "{!inningnr} inning - {$competitor2} total",
        "name_fr": "{!inningnr} manche - {$competitor2} total"
    },
    "id_309^prematch": {
        "name_en": "{!setnr} set - point handicap",
        "name_aa": "{!setnr} set - point handicap",
        "name_de": "{!setnr} Satz - Punkte Handicap",
        "name_nl": "{setnr} set - punten handicap",
        "name_pl": "{!setnr} set - point handicap",
        "name_ro": "{!setnr} set - point handicap",
        "name_ru": "{!setnr} set - point handicap",
        "name_tr": "{!setnr}. set - handikatp sayı",
        "name_se": "{!setnr} set - point handicap",
        "name_el": "{!setnr} set - point handicap",
        "name_es": "{!setnr} set - point handicap",
        "name_hr": "{!setnr} set - poen hendikep",
        "name_fr": "{!setnr} set - point handicap"
    },
    "id_311^prematch": {
        "name_en": "{!setnr} set - odd/even",
        "name_aa": "{!setnr} set - odd/even",
        "name_de": "{!setnr} Satz - Gerade/ungerade",
        "name_nl": "{setnr} set - even/oneven",
        "name_pl": "{!setnr} set - odd/even",
        "name_ro": "{!setnr} set - odd/even",
        "name_ru": "{!setnr} set - odd/even",
        "name_tr": "{!setnr}. set - tek/çift",
        "name_se": "{!setnr} set - odd/even",
        "name_el": "{!setnr} set - odd/even",
        "name_es": "{!setnr} set - odd/even",
        "name_hr": "{!setnr} set - par/nepar",
        "name_fr": "{!setnr} set - pair/impair"
    },
    "id_290^prematch": {
        "name_en": "Winning margin (incl. overtime)",
        "name_aa": "Winning margin (incl. overtime)",
        "name_de": "Gewinnspanne (inkl. Verlängerung)",
        "name_nl": "Winstmarge (verlenging inbegrepen)",
        "name_pl": "Winning margin (incl. overtime)",
        "name_ro": "Winning margin (incl. overtime)",
        "name_ru": "Winning margin (incl. overtime)",
        "name_tr": "Kazanma farkı (uzatmalar dahil)",
        "name_se": "Winning margin (incl. overtime)",
        "name_el": "Winning margin (incl. overtime)",
        "name_es": "Winning margin (incl. overtime)",
        "name_hr": "Pobjednička marгina (uklj. produžetke)",
        "name_fr": "Marge de victoire (inclus prolongations)"
    },
    "id_50^prematch": {
        "name_en": "{$competitor1} to win either half",
        "name_aa": "{$competitor1} to win either half",
        "name_de": "{$competitor1}Sieger in einer der Halbzeiten",
        "name_nl": "{$competitor1} wint minstens een helft",
        "name_pl": "{$competitor1} to win either half",
        "name_ro": "{$competitor1} to win either half",
        "name_ru": "{$competitor1} to win either half",
        "name_tr": "{$competitor1} en az bir yarıyı kazanır",
        "name_se": "{$competitor1} to win either half",
        "name_el": "{$competitor1} to win either half",
        "name_es": "{$competitor1} to win either half",
        "name_hr": "{$competitor1} pobijeđuje bar jedno poluvrijeme",
        "name_fr": "{$competitor1} gagne une des périodes"
    },
    "id_158^prematch": {
        "name_en": "1st half - booking point range",
        "name_aa": "1st half - booking point range",
        "name_de": "1. Halbzeit - Strafpunktebereich",
        "name_nl": "Eerste helft - booking point range",
        "name_pl": "1st half - booking point range",
        "name_ro": "1st half - booking point range",
        "name_ru": "1st half - booking point range",
        "name_tr": "İlk yarı - kart ceza puanı aralığı",
        "name_se": "1st half - booking point range",
        "name_el": "1st half - booking point range",
        "name_es": "1st half - booking point range",
        "name_hr": "1. Poluvrijeme - opseg broja kartona",
        "name_fr": "1ère mi-temps - Intervalles points cartons"
    },
    "id_236^prematch": {
        "name_en": "{!quarternr} quarter - total",
        "name_aa": "{!quarternr} quarter - total",
        "name_de": "{!quarternr} Viertel - Total",
        "name_nl": "{quarternr} kwart - totaal",
        "name_pl": "{!quarternr} quarter - total",
        "name_ro": "{!quarternr} quarter - total",
        "name_ru": "{!quarternr} quarter - total",
        "name_tr": "{!quarternr}. çeyrek - toplam gol",
        "name_se": "{!quarternr} quarter - total",
        "name_el": "{!quarternr} quarter - total",
        "name_es": "{!quarternr} quarter - total",
        "name_hr": "{!quarternr} četvrtina - total",
        "name_fr": "{!quarternr} quart-temps - total"
    },
    "id_171^prematch": {
        "name_en": "{$competitor2} corner range",
        "name_aa": "{$competitor2} corner range",
        "name_de": "{$competitor2} Anzahlbereich Ecken",
        "name_nl": "Hoekschoppen range {$competitor2}",
        "name_pl": "{$competitor2} corner range",
        "name_ro": "{$competitor2} corner range",
        "name_ru": "{$competitor2} corner range",
        "name_tr": "{$competitor2} korner aralığı",
        "name_se": "{$competitor2} corner range",
        "name_el": "{$competitor2} corner range",
        "name_es": "{$competitor2} corner range",
        "name_hr": "{$competitor2} Udarci iz kuta - opseg",
        "name_fr": "{$competitor2} intervalles nombre de corners"
    },
    "id_882^prematch": {
        "name_en": "{%player} to score (incl. overtime)",
        "name_aa": "{%player} to score (incl. overtime)",
        "name_de": "{%player} wird punkten (inkl. Verlängerung)",
        "name_nl": "{%player} scoort (verlenging inbegrepen)",
        "name_pl": "{%player} to score (incl. overtime)",
        "name_ro": "{%player} to score (incl. overtime)",
        "name_ru": "{%player} to score (incl. overtime)",
        "name_tr": "{%player} to score (incl. overtime)",
        "name_se": "{%player} to score (incl. overtime)",
        "name_el": "{%player} to score (incl. overtime)",
        "name_es": "{%player} to score (incl. overtime)",
        "name_hr": "{%player} pogađa (incl. overtime)",
        "name_fr": "{%player} qui marque (prolongations incluses)"
    },
    "id_13^prematch": {
        "name_en": "{$competitor2} no bet",
        "name_aa": "{$competitor2} no bet",
        "name_de": "{$competitor2} keine Wette",
        "name_nl": "{$competitor2} no bet",
        "name_pl": "{$competitor2} no bet",
        "name_ro": "{$competitor2} no bet",
        "name_ru": "{$competitor2} no bet",
        "name_tr": "{$competitor2} kazanırsa iade",
        "name_se": "{$competitor2} no bet",
        "name_el": "{$competitor2} no bet",
        "name_es": "{$competitor2} no bet",
        "name_hr": "{$competitor2} nema oklade",
        "name_fr": "{$competitor2} remboursé si victoire"
    },
    "id_231^prematch": {
        "name_en": "2nd half - handicap (incl. overtime)",
        "name_aa": "2nd half - handicap (incl. overtime)",
        "name_de": "2. Halbzeit - Handicap (inkl. Verlängerung)",
        "name_nl": "Tweede helft - handicap (verlenging inbegrepen)",
        "name_pl": "2nd half - handicap (incl. overtime)",
        "name_ro": "2nd half - handicap (incl. overtime)",
        "name_ru": "2nd half - handicap (incl. overtime)",
        "name_tr": "İkinci yarı - handikap (uzatmalar dahil)",
        "name_se": "2nd half - handicap (incl. overtime)",
        "name_el": "2nd half - handicap (incl. overtime)",
        "name_es": "2nd half - handicap (incl. overtime)",
        "name_hr": "2. poluvrijeme - hendikep (uklj. produžetke)",
        "name_fr": "2ème mi-temps - handicap (inclus prolongations)"
    },
    "id_49^prematch": {
        "name_en": "{$competitor2} to win both halves",
        "name_aa": "{$competitor2} to win both halves",
        "name_de": "{$competitor2} wird beide Hälften gewinnen",
        "name_nl": "{$competitor2} wint beide helften",
        "name_pl": "{$competitor2} to win both halves",
        "name_ro": "{$competitor2} to win both halves",
        "name_ru": "{$competitor2} to win both halves",
        "name_tr": "{$competitor2} her iki devreyi de kazanma",
        "name_se": "{$competitor2} to win both halves",
        "name_el": "{$competitor2} to win both halves",
        "name_es": "{$competitor2} to win both halves",
        "name_hr": "{$competitor2} pobijeđuje u oba poluvremena",
        "name_fr": "{$competitor2} gagne les deux périodes"
    },
    "id_239^prematch": {
        "name_en": "How many games will be decided by extra points",
        "name_aa": "How many games will be decided by extra points",
        "name_de": "Wie viele Spiele werden durch Extrapunkte entschieden",
        "name_nl": "Hoeveel games worden beslist door extra punten",
        "name_pl": "How many games will be decided by extra points",
        "name_ro": "How many games will be decided by extra points",
        "name_ru": "How many games will be decided by extra points",
        "name_tr": "Ekstra sayılarla kaç oyun belirlenecek",
        "name_se": "How many games will be decided by extra points",
        "name_el": "How many games will be decided by extra points",
        "name_es": "How many games will be decided by extra points",
        "name_hr": "Koliko gemova će biti odlučeno ekstra poenima",
        "name_fr": "Combien de jeux vont se décider par des points supplémentaires ?"
    },
    "id_63^prematch": {
        "name_en": "1st half - double chance",
        "name_aa": "1st half - double chance",
        "name_de": "1. Halbzeit - Doppelte Chance",
        "name_nl": "1e helft - dubbele kans",
        "name_pl": "1st half - double chance",
        "name_ro": "1st half - double chance",
        "name_ru": "1st half - double chance",
        "name_tr": "İlk Yarı - çifte şans",
        "name_se": "1st half - double chance",
        "name_el": "1st half - double chance",
        "name_es": "1st half - double chance",
        "name_hr": "1. Poluvrijeme - dvostruka prilika",
        "name_fr": "1ère mi-temps - double chance"
    },
    "id_537^prematch": {
        "name_en": "3-ball",
        "name_aa": "3-ball",
        "name_de": "3-Ball",
        "name_nl": "3-ball",
        "name_pl": "3-ball",
        "name_ro": "3-ball",
        "name_ru": "3-ball",
        "name_tr": "3-top",
        "name_se": "3-ball",
        "name_el": "3-ball",
        "name_es": "3-ball",
        "name_hr": "3-lopte",
        "name_fr": "3-billes"
    },
    "id_223^prematch": {
        "name_en": "Handicap (incl. overtime)",
        "name_aa": "Handicap (incl. overtime)",
        "name_de": "Handicap (inkl. Verlängerung)",
        "name_nl": "Handicap (verlenging inbegrepen)",
        "name_pl": "Handicap (incl. overtime)",
        "name_ro": "Handicap (incl. overtime)",
        "name_ru": "Handicap (incl. overtime)",
        "name_tr": "Handikap (uzatmalar dahil)",
        "name_se": "Handicap (incl. overtime)",
        "name_el": "Handicap (incl. overtime)",
        "name_es": "Handicap (incl. overtime)",
        "name_hr": "Hendikep (uklj. produžetke)",
        "name_fr": "Handicap (inclus prolongations)"
    },
    "id_220^prematch": {
        "name_en": "Will there be overtime",
        "name_aa": "Will there be overtime",
        "name_de": "Wird es eine Verlängerung geben",
        "name_nl": "Zal er verlenging nodig zijn",
        "name_pl": "Will there be overtime",
        "name_ro": "Will there be overtime",
        "name_ru": "Will there be overtime",
        "name_tr": "Uzatma olacak mı",
        "name_se": "Will there be overtime",
        "name_el": "Will there be overtime",
        "name_es": "Will there be overtime",
        "name_hr": "Hoće li biti produžetaka?",
        "name_fr": "Y aura-t-il des prolongations ?"
    },
    "id_150^prematch": {
        "name_en": "1st half - {!bookingnr} booking",
        "name_aa": "1st half - {!bookingnr} booking",
        "name_de": "1. Halbzeit - {!bookingnr} Karte",
        "name_nl": "Eerste helft - {bookingnr} kaart",
        "name_pl": "1st half - {!bookingnr} booking",
        "name_ro": "1st half - {!bookingnr} booking",
        "name_ru": "1st half - {!bookingnr} booking",
        "name_tr": "İlk yarı - {!bookingnr} kart",
        "name_se": "1st half - {!bookingnr} booking",
        "name_el": "1st half - {!bookingnr} booking",
        "name_es": "1st half - {!bookingnr} booking",
        "name_hr": "1. Poluvrijeme -{!bookingnr} kartona",
        "name_fr": "1ère mi-temps - {!bookingnr} carton"
    },
    "id_269^prematch": {
        "name_en": "{$competitor1} to bat in 9th inning",
        "name_aa": "{$competitor1} to bat in 9th inning",
        "name_de": "Wird {$competitor1} im 9. Inning geschlagen?",
        "name_nl": "{$competitor1} komt aan slag in de 9e inning",
        "name_pl": "{$competitor1} to bat in 9th inning",
        "name_ro": "{$competitor1} to bat in 9th inning",
        "name_ru": "{$competitor1} to bat in 9th inning",
        "name_tr": "{$competitor1}'ın 9. devredeki vuruşu",
        "name_se": "{$competitor1} to bat in 9th inning",
        "name_el": "{$competitor1} to bat in 9th inning",
        "name_es": "{$competitor1} to bat in 9th inning",
        "name_hr": "{$competitor1} ima udarac u 9. inningu",
        "name_fr": "{$competitor1} va attaquer dans la 9ème manche"
    },
    "id_174^prematch": {
        "name_en": "1st half - {!cornernr} corner",
        "name_aa": "1st half - {!cornernr} corner",
        "name_de": "1. Halbzeit - {!cornernr} Ecke",
        "name_nl": "Eerste helft - {cornernr} hoekschop",
        "name_pl": "1st half - {!cornernr} corner",
        "name_ro": "1st half - {!cornernr} corner",
        "name_ru": "1st half - {!cornernr} corner",
        "name_tr": "İlk yarı - {!cornernr} korner",
        "name_se": "1st half - {!cornernr} corner",
        "name_el": "1st half - {!cornernr} corner",
        "name_es": "1st half - {!cornernr} corner",
        "name_hr": "1. Poluvrijeme - {!cornernr} Udaraca iz kuta",
        "name_fr": "1ère mi-temps - {!cornernr} corner"
    },
    "id_172^prematch": {
        "name_en": "Odd/even corners",
        "name_aa": "Odd/even corners",
        "name_de": "Gerade/ungerade Ecken",
        "name_nl": "Even/oneven aantal hoekschoppen",
        "name_pl": "Odd/even corners",
        "name_ro": "Odd/even corners",
        "name_ru": "Odd/even corners",
        "name_tr": "tek/çift kornerler",
        "name_se": "Odd/even corners",
        "name_el": "Odd/even corners",
        "name_es": "Odd/even corners",
        "name_hr": "Par/Nepar udarci iz kuta",
        "name_fr": "Pair/Impair corners"
    },
    "id_173^prematch": {
        "name_en": "1st half - corner 1x2",
        "name_aa": "1st half - corner 1x2",
        "name_de": "1. Halbzeit - Ecken 1x2",
        "name_nl": "Eerste helft - hoekschop 1x2",
        "name_pl": "1st half - corner 1x2",
        "name_ro": "1st half - corner 1x2",
        "name_ru": "1st half - corner 1x2",
        "name_tr": "İlk yarı - korner 1x2",
        "name_se": "1st half - corner 1x2",
        "name_el": "1st half - corner 1x2",
        "name_es": "1st half - corner 1x2",
        "name_hr": "1. Poluvrijeme - Udarci iz kuta 1x2",
        "name_fr": "1ère mi-temps - corners 1x2"
    },
    "id_473^prematch": {
        "name_en": "Try 1x2",
        "name_aa": "Try 1x2",
        "name_de": "Versuch 1x2",
        "name_nl": "Try 1x2",
        "name_pl": "Try 1x2",
        "name_ro": "Try 1x2",
        "name_ru": "Try 1x2",
        "name_tr": "Try 1x2",
        "name_se": "Try 1x2",
        "name_el": "Try 1x2",
        "name_es": "Try 1x2",
        "name_hr": "Polaganje 1x2",
        "name_fr": "Essais 1x2"
    },
    "id_862^prematch": {
        "name_en": "{$competitor2} or both teams to score",
        "name_aa": "{$competitor2} or both teams to score",
        "name_de": "{$competitor2} oder beide Teams werden punkten",
        "name_nl": "{$competitor2} of beide teams scoren",
        "name_pl": "{$competitor2} or both teams to score",
        "name_ro": "{$competitor2} or both teams to score",
        "name_ru": "{$competitor2} or both teams to score",
        "name_tr": "{$competitor2} ya da iki takımda gol atar",
        "name_se": "{$competitor2} or both teams to score",
        "name_el": "{$competitor2} or both teams to score",
        "name_es": "{$competitor2} or both teams to score",
        "name_hr": "{$competitor2} ili oba daju gol",
        "name_fr": "{$competitor2} ou les deux équipes qui marquent"
    },
    "id_478^prematch": {
        "name_en": "Total tries",
        "name_aa": "Total tries",
        "name_de": "Total Versuche",
        "name_nl": "Totaal aantal tries",
        "name_pl": "Total tries",
        "name_ro": "Total tries",
        "name_ru": "Total tries",
        "name_tr": "Toplam try",
        "name_se": "Total tries",
        "name_el": "Total tries",
        "name_es": "Total tries",
        "name_hr": "Ukupno polaganja",
        "name_fr": "Total d'essais"
    },
    "id_41^prematch": {
        "name_en": "Correct score [{score}]",
        "name_aa": "Correct score [{score}]",
        "name_de": "Genaues Ergebnis [{score}]",
        "name_nl": "Juiste uitslag [{score}]",
        "name_pl": "Correct score [{score}]",
        "name_ro": "Correct score [{score}]",
        "name_ru": "Correct score [{score}]",
        "name_tr": "Maç sonucu [{score}]",
        "name_se": "Correct score [{score}]",
        "name_el": "Correct score [{score}]",
        "name_es": "Correct score [{score}]",
        "name_hr": "Točan rezultat [{score}]",
        "name_fr": "Score correct [{score}]"
    },
    "id_545^prematch": {
        "name_en": "2nd half - double chance & both teams to score",
        "name_aa": "2nd half - double chance & both teams to score",
        "name_de": "2. Halbzeit - Doppelte Chance & Beide Mannschaften treffen",
        "name_nl": "Tweede helft - dubbele kans & beide teams scoren",
        "name_pl": "2nd half - double chance & both teams to score",
        "name_ro": "2nd half - double chance & both teams to score",
        "name_ru": "2nd half - double chance & both teams to score",
        "name_tr": "İkinci yarı - Çifte şans & her iki takımda gol atar",
        "name_se": "2nd half - double chance & both teams to score",
        "name_el": "2nd half - double chance & both teams to score",
        "name_es": "2nd half - double chance & both teams to score",
        "name_hr": "2. poluvrijeme - dvostruka prilika i oba daju gol",
        "name_fr": "2ème mi-temps - double chance & les deux équipes qui marquent"
    },
    "id_543^prematch": {
        "name_en": "2nd half - 1x2 & both teams to score",
        "name_aa": "2nd half - 1x2 & both teams to score",
        "name_de": "2. Halbzeit - 1x2  & Beide Mannschaften treffen",
        "name_nl": "Tweede helft - 1x2 & beide teams scoren",
        "name_pl": "2nd half - 1x2 & both teams to score",
        "name_ro": "2nd half - 1x2 & both teams to score",
        "name_ru": "2nd half - 1x2 & both teams to score",
        "name_tr": "İkinci yarı - 1x2 & her iki takımda gol atar",
        "name_se": "2nd half - 1x2 & both teams to score",
        "name_el": "2nd half - 1x2 & both teams to score",
        "name_es": "2nd half - 1x2 & both teams to score",
        "name_hr": "2. poluvrijeme - 1x2 i oba daju gol",
        "name_fr": "2ème mi-temps - 1x2 & les deux équipes qui marquent"
    },
    "id_913^prematch": {
        "name_en": "Winner & round range",
        "name_aa": "Winner & round range",
        "name_de": "Sieger & Runden-Bereich",
        "name_nl": "Winnaar & aantal rondes",
        "name_pl": "Winner & round range",
        "name_ro": "Winner & round range",
        "name_ru": "Winner & round range",
        "name_tr": "Winner & round range",
        "name_se": "Winner & round range",
        "name_el": "Winner & round range",
        "name_es": "Winner & round range",
        "name_hr": "Pobjednik i raspon rundi",
        "name_fr": "Vainqueur et Nombre de Manches (fourchette)"
    },
    "id_381^prematch": {
        "name_en": "Most 180s",
        "name_aa": "Most 180s",
        "name_de": "Die meisten 180s",
        "name_nl": "Meeste 180s",
        "name_pl": "Most 180s",
        "name_ro": "Most 180s",
        "name_ru": "Most 180s",
        "name_tr": "En çok 180",
        "name_se": "Most 180s",
        "name_el": "Most 180s",
        "name_es": "Most 180s",
        "name_hr": "Najviše 180-ki",
        "name_fr": "Le plus de 180"
    },
    "id_750^prematch": {
        "name_en": "{!inningnr} inning - {$competitor2} to score",
        "name_aa": "{!inningnr} inning - {$competitor2} to score",
        "name_de": "{!inningnr} Inning - {$competitor2} punktet",
        "name_nl": "{inningnr} inning - {$competitor2} scoort",
        "name_pl": "{!inningnr} inning - {$competitor2} to score",
        "name_ro": "{!inningnr} inning - {$competitor2} to score",
        "name_ru": "{!inningnr} inning - {$competitor2} to score",
        "name_tr": "{!inningnr}. devre - {$competitor2} sayı yapar",
        "name_se": "{!inningnr} inning - {$competitor2} to score",
        "name_el": "{!inningnr} inning - {$competitor2} to score",
        "name_es": "{!inningnr} inning - {$competitor2} to score",
        "name_hr": "{!inningnr} inning - {$competitor2} osvaja poen",
        "name_fr": "{!inningnr} manche - {$competitor2} qui marque"
    },
    "id_820^prematch": {
        "name_en": "Halftime/fulltime & exact goals",
        "name_aa": "Halftime/fulltime & exact goals",
        "name_de": "Halftime/fulltime & exact goals",
        "name_nl": "Ruststand/eindstand & exact aantal doelpunten",
        "name_pl": "Halftime/fulltime & exact goals",
        "name_ro": "Halftime/fulltime & exact goals",
        "name_ru": "Halftime/fulltime & exact goals",
        "name_tr": "İlkyarı/maç sonucu & skor sayısı",
        "name_se": "Halftime/fulltime & exact goals",
        "name_el": "Halftime/fulltime & exact goals",
        "name_es": "Halftime/fulltime & exact goals",
        "name_hr": "Poluvrijeme/kraj i točan broj golova",
        "name_fr": "Mi-temps/fin de match & nombre exaxt de buts"
    },
    "id_234^prematch": {
        "name_en": "Highest scoring quarter",
        "name_aa": "Highest scoring quarter",
        "name_de": "Viertel mit den meisten Punkten",
        "name_nl": "Kwart met de hoogste score",
        "name_pl": "Highest scoring quarter",
        "name_ro": "Highest scoring quarter",
        "name_ru": "Highest scoring quarter",
        "name_tr": "En fazla sayı olan çeyrek",
        "name_se": "Highest scoring quarter",
        "name_el": "Highest scoring quarter",
        "name_es": "Highest scoring quarter",
        "name_hr": "Četvrtina s najviše poena",
        "name_fr": "Quart-temps le plus prolifique au score"
    },
    "id_859^prematch": {
        "name_en": "{$competitor2} or under {total}",
        "name_aa": "{$competitor2} or under {total}",
        "name_de": "{$competitor2} oder unter {total}",
        "name_nl": "{$competitor2} of onder {total}",
        "name_pl": "{$competitor2} or under {total}",
        "name_ro": "{$competitor2} or under {total}",
        "name_ru": "{$competitor2} or under {total}",
        "name_tr": "{$competitor2} ya da alt {total}",
        "name_se": "{$competitor2} or under {total}",
        "name_el": "{$competitor2} or under {total}",
        "name_es": "{$competitor2} or under {total}",
        "name_hr": "{$competitor2} ili manje {total}",
        "name_fr": "{$competitor2} ou Moins de {total}"
    },
    "id_70^prematch": {
        "name_en": "1st half - {$competitor2} total",
        "name_aa": "1st half - {$competitor2} total",
        "name_de": "1. Halbzeit - {$competitor2} Total",
        "name_nl": "1e helft - {$competitor2} totaal",
        "name_pl": "1st half - {$competitor2} total",
        "name_ro": "1st half - {$competitor2} total",
        "name_ru": "1st half - {$competitor2} total",
        "name_tr": "İlk Yarı - {$competitor2} toplam gol",
        "name_se": "1st half - {$competitor2} total",
        "name_el": "1st half - {$competitor2} total",
        "name_es": "1st half - {$competitor2} total",
        "name_hr": "1. Poluvrijeme - {$competitor2} točno golova",
        "name_fr": "1ère mi-temps - {$competitor2} total"
    },
    "id_374^prematch": {
        "name_en": "{!setnr} set - correct score (in legs)",
        "name_aa": "{!setnr} set - correct score (in legs)",
        "name_de": "{!setnr} Satz - Genaues Ergebnis (in Runden)",
        "name_nl": "{setnr} set - juiste score (in legs)",
        "name_pl": "{!setnr} set - correct score (in legs)",
        "name_ro": "{!setnr} set - correct score (in legs)",
        "name_ru": "{!setnr} set - correct score (in legs)",
        "name_tr": "{!setnr}. set - doğru skor (ayaklarda)",
        "name_se": "{!setnr} set - correct score (in legs)",
        "name_el": "{!setnr} set - correct score (in legs)",
        "name_es": "{!setnr} set - correct score (in legs)",
        "name_hr": "{!setnr} set - točan rezultat (u legovima)",
        "name_fr": "{!setnr} set - score correct (en nombre de manches)"
    },
    "id_51^prematch": {
        "name_en": "{$competitor2} to win either half",
        "name_aa": "{$competitor2} to win either half",
        "name_de": "{$competitor2}Sieger in einer der Halbzeiten",
        "name_nl": "{$competitor2} wint minstens een helft",
        "name_pl": "{$competitor2} to win either half",
        "name_ro": "{$competitor2} to win either half",
        "name_ru": "{$competitor2} to win either half",
        "name_tr": "{$competitor2} en az bir yarıyı kazanır",
        "name_se": "{$competitor2} to win either half",
        "name_el": "{$competitor2} to win either half",
        "name_es": "{$competitor2} to win either half",
        "name_hr": "{$competitor2} pobijeđuje bar jedno poluvrijeme",
        "name_fr": "{$competitor2} gagne une des périodes"
    },
    "id_912^prematch": {
        "name_en": "Winner & exact rounds",
        "name_aa": "Winner & exact rounds",
        "name_de": "Sieger & Genaue Runden",
        "name_nl": "Winnaar & exact aantal rondes",
        "name_pl": "Winner & exact rounds",
        "name_ro": "Winner & exact rounds",
        "name_ru": "Winner & exact rounds",
        "name_tr": "Winner & exact rounds",
        "name_se": "Winner & exact rounds",
        "name_el": "Winner & exact rounds",
        "name_es": "Winner & exact rounds",
        "name_hr": "Pobjednik i točan broj rundi",
        "name_fr": "Vainqueur et nombre exact de rounds"
    },
    "id_186^prematch": {
        "name_en": "Winner",
        "name_aa": "Winner",
        "name_de": "Gewinner",
        "name_nl": "Winnaar",
        "name_pl": "Winner",
        "name_ro": "Winner",
        "name_ru": "Winner",
        "name_tr": "Kazanan",
        "name_se": "Winner",
        "name_el": "Winner",
        "name_es": "Winner",
        "name_hr": "Pobjednik",
        "name_fr": "Vainqueur"
    },
    "id_8^prematch": {
        "name_en": "{!goalnr} goal",
        "name_aa": "{!goalnr} goal",
        "name_de": "{!goalnr} Tor",
        "name_nl": "{goalnr} doelpunt",
        "name_pl": "{!goalnr} goal",
        "name_ro": "{!goalnr} goal",
        "name_ru": "{!goalnr} goal",
        "name_tr": "{!goalnr} gol",
        "name_se": "{!goalnr} goal",
        "name_el": "{!goalnr} goal",
        "name_es": "{!goalnr} goal",
        "name_hr": "{!goalnr} gol",
        "name_fr": "{!goalnr} but"
    },
    "id_35^prematch": {
        "name_en": "1x2 & both teams to score",
        "name_aa": "1x2 & both teams to score",
        "name_de": "1x2 & Beide Mannschaften treffen",
        "name_nl": "1x2 & beide teams scoren",
        "name_pl": "1x2 & both teams to score",
        "name_ro": "1x2 & both teams to score",
        "name_ru": "1x2 & both teams to score",
        "name_tr": "1x2 & her iki takım da gol bulur",
        "name_se": "1x2 & both teams to score",
        "name_el": "1x2 & both teams to score",
        "name_es": "1x2 & both teams to score",
        "name_hr": "1x2 i oba daju gol",
        "name_fr": "1x2 & Les deux équipes qui marquent"
    },
    "id_16^prematch": {
        "name_en": "Handicap",
        "name_aa": "Handicap",
        "name_de": "Handicap",
        "name_nl": "Handicap",
        "name_pl": "Handicap",
        "name_ro": "Handicap",
        "name_ru": "Handicap",
        "name_tr": "Handikap",
        "name_se": "Handicap",
        "name_el": "Handicap",
        "name_es": "Handicap",
        "name_hr": "Hendikep",
        "name_fr": "Handicap"
    },
    "id_419^prematch": {
        "name_en": "Odd/even (incl. overtime and penalties)",
        "name_aa": "Odd/even (incl. overtime and penalties)",
        "name_de": "Gerade/ungerade (inkl. Verlängerung und Elfmeterschießen)",
        "name_nl": "Even/oneven (verlenging en strafschoppen inbegrepen)",
        "name_pl": "Odd/even (incl. overtime and penalties)",
        "name_ro": "Odd/even (incl. overtime and penalties)",
        "name_ru": "Odd/even (incl. overtime and penalties)",
        "name_tr": "tek/çift (uzatmalar ve penaltılar dahil)",
        "name_se": "Odd/even (incl. overtime and penalties)",
        "name_el": "Odd/even (incl. overtime and penalties)",
        "name_es": "Odd/even (incl. overtime and penalties)",
        "name_hr": "Par/nepar (uklj. produžetke i penale)",
        "name_fr": "Pair/Impair (inclus prolongations et séance de tirs au but)"
    },
    "id_98^prematch": {
        "name_en": "2nd half - correct score",
        "name_aa": "2nd half - correct score",
        "name_de": "2. Halbzeit - Genaues Ergebnis",
        "name_nl": "2e helft - juiste uitslag",
        "name_pl": "2nd half - correct score",
        "name_ro": "2nd half - correct score",
        "name_ru": "2nd half - correct score",
        "name_tr": "ikinci yarı - skor",
        "name_se": "2nd half - correct score",
        "name_el": "2nd half - correct score",
        "name_es": "2nd half - correct score",
        "name_hr": "2. Poluvrijeme - Točan rezultat",
        "name_fr": "2ème mi-temps - score correct"
    },
    "id_247^prematch": {
        "name_en": "{!gamenr} game - total points",
        "name_aa": "{!gamenr} game - total points",
        "name_de": "{!gamenr} Spiel - Anzahl Punkte",
        "name_nl": "{gamenr} game - totaal aantal punten",
        "name_pl": "{!gamenr} game - total points",
        "name_ro": "{!gamenr} game - total points",
        "name_ru": "{!gamenr} game - total points",
        "name_tr": "{!gamenr}. oyun - toplam sayı",
        "name_se": "{!gamenr} game - total points",
        "name_el": "{!gamenr} game - total points",
        "name_es": "{!gamenr} game - total points",
        "name_hr": "{!gamenr} gem - ukupno poena",
        "name_fr": "{!gamenr} jeu - total points"
    },
    "id_145^prematch": {
        "name_en": "Booking point range",
        "name_aa": "Booking point range",
        "name_de": "Strafpunktebereich",
        "name_nl": "Booking point range",
        "name_pl": "Booking point range",
        "name_ro": "Booking point range",
        "name_ru": "Booking point range",
        "name_tr": "Kart ceza puanı aralığı",
        "name_se": "Booking point range",
        "name_el": "Booking point range",
        "name_es": "Booking point range",
        "name_hr": "Opseg karton poena",
        "name_fr": "Intervalles points cartons"
    },
    "id_201^prematch": {
        "name_en": "Double result (1st set/match)",
        "name_aa": "Double result (1st set/match)",
        "name_de": "Doppeltes Ergebnis (1. Satz / Spiel)",
        "name_nl": "Dubbel resultaat (eerste set/match)",
        "name_pl": "Double result (1st set/match)",
        "name_ro": "Double result (1st set/match)",
        "name_ru": "Double result (1st set/match)",
        "name_tr": "Çifte sonuç bahisi (İlk set/maç)",
        "name_se": "Double result (1st set/match)",
        "name_el": "Double result (1st set/match)",
        "name_es": "Double result (1st set/match)",
        "name_hr": "Dvostruki rezultat (1.set/meč)",
        "name_fr": "Résulat double (1er set/match)"
    },
    "id_62^prematch": {
        "name_en": "1st half - {!goalnr} goal",
        "name_aa": "1st half - {!goalnr} goal",
        "name_de": "1. Halbzeit - {!goalnr} Tor",
        "name_nl": "1e helft - {goalnr} doelpunt",
        "name_pl": "1st half - {!goalnr} goal",
        "name_ro": "1st half - {!goalnr} goal",
        "name_ru": "1st half - {!goalnr} goal",
        "name_tr": "İlk Yarı - {!goalnr} gol",
        "name_se": "1st half - {!goalnr} goal",
        "name_el": "1st half - {!goalnr} goal",
        "name_es": "1st half - {!goalnr} goal",
        "name_hr": "1. Poluvrijeme - {!goalnr} gol",
        "name_fr": "1ère mi-temps - {!goalnr} but"
    },
    "id_382^prematch": {
        "name_en": "{!xth} player to score a 180",
        "name_aa": "{!xth} player to score a 180",
        "name_de": "{!xth} Spieler der ein 180 erzielt",
        "name_nl": "{xth} speler gooit een 180",
        "name_pl": "{!xth} player to score a 180",
        "name_ro": "{!xth} player to score a 180",
        "name_ru": "{!xth} player to score a 180",
        "name_tr": "{!xth}. oyuncu 180 puan yapar",
        "name_se": "{!xth} player to score a 180",
        "name_el": "{!xth} player to score a 180",
        "name_es": "{!xth} player to score a 180",
        "name_hr": "{!xth} igrač osvaja 180 poena",
        "name_fr": "{!xth} joueur qui marque un 180"
    },
    "id_64^prematch": {
        "name_en": "1st half - draw no bet",
        "name_aa": "1st half - draw no bet",
        "name_de": "1. Halbzeit - unentschieden, keine Wette",
        "name_nl": "1e helft - draw no bet",
        "name_pl": "1st half - draw no bet",
        "name_ro": "1st half - draw no bet",
        "name_ru": "1st half - draw no bet",
        "name_tr": "İlk yarı - beraberlikte iade",
        "name_se": "1st half - draw no bet",
        "name_el": "1st half - draw no bet",
        "name_es": "1st half - draw no bet",
        "name_hr": "1. Poluvrijeme - Ishod bez neodlučenog",
        "name_fr": "1ère mi-temps - remboursé si match nul"
    },
    "id_293^prematch": {
        "name_en": "2nd half - 1x2 (incl. overtime)",
        "name_aa": "2nd half - 1x2 (incl. overtime)",
        "name_de": "2. Halbzeit - 1x2 (inkl. Verlängerung)",
        "name_nl": "Tweede helft - 1x2 (verlenging inbegrepen)",
        "name_pl": "2nd half - 1x2 (incl. overtime)",
        "name_ro": "2nd half - 1x2 (incl. overtime)",
        "name_ru": "2nd half - 1x2 (incl. overtime)",
        "name_tr": "İkinci yarı - 1x2 (uzatmalar dahil)",
        "name_se": "2nd half - 1x2 (incl. overtime)",
        "name_el": "2nd half - 1x2 (incl. overtime)",
        "name_es": "2nd half - 1x2 (incl. overtime)",
        "name_hr": "2. poluvrijeme - 1x2 (uklj. produžetke)",
        "name_fr": "2ème mi-temps - 1x2 (inclus prolongations)"
    },
    "id_86^prematch": {
        "name_en": "2nd half - draw no bet",
        "name_aa": "2nd half - draw no bet",
        "name_de": "2. Halbzeit - unentschieden, keine Wette",
        "name_nl": "2e helft - draw no bet",
        "name_pl": "2nd half - draw no bet",
        "name_ro": "2nd half - draw no bet",
        "name_ru": "2nd half - draw no bet",
        "name_tr": "İkinci yarı - beraberlikte iade",
        "name_se": "2nd half - draw no bet",
        "name_el": "2nd half - draw no bet",
        "name_es": "2nd half - draw no bet",
        "name_hr": "2. poluvrijeme - ishod bez neodlučenog",
        "name_fr": "2ème mi-temps - remboursé si match nul"
    },
    "id_467^prematch": {
        "name_en": "{!scorenr} scoring play",
        "name_aa": "{!scorenr} scoring play",
        "name_de": "{!scorenr} Scoring-Spiel",
        "name_nl": "{scorenr} scoring play",
        "name_pl": "{!scorenr} scoring play",
        "name_ro": "{!scorenr} scoring play",
        "name_ru": "{!scorenr} scoring play",
        "name_tr": "{!scorenr}. skor oyun",
        "name_se": "{!scorenr} scoring play",
        "name_el": "{!scorenr} scoring play",
        "name_es": "{!scorenr} scoring play",
        "name_hr": "{!scorenr} poentiranje",
        "name_fr": "{!scorenr} action de jeu qui marque des points"
    },
    "id_1121^prematch": {
        "name_en": "{$competitor1} total home runs (incl. extra innings)",
        "name_aa": "{$competitor1} total home runs (incl. extra innings)",
        "name_de": "{$competitor1} Home Runs Gesamt (inkl. Extra Innings)",
        "name_nl": "Totaal aantal homeruns {$competitor1} (incl. extra innings)",
        "name_pl": "{$competitor1} total home runs (incl. extra innings)",
        "name_ro": "{$competitor1} total home runs (incl. extra innings)",
        "name_ru": "{$competitor1} total home runs (incl. extra innings)",
        "name_tr": "{$competitor1} total home runs (incl. extra innings)",
        "name_se": "{$competitor1} total home runs (incl. extra innings)",
        "name_el": "{$competitor1} total home runs (incl. extra innings)",
        "name_es": "{$competitor1} total home runs (incl. extra innings)",
        "name_hr": "{$competitor1} total home runs (incl. extra innings)",
        "name_fr": "{$competitor1} nombre de coup de circuits (homeruns) (prolongations incluses)"
    },
    "id_500000^prematch": {
        "name_en": "First Match Corner",
        "name_aa": "First Match Corner",
        "name_de": "First Match Corner",
        "name_nl": "First Match Corner",
        "name_pl": "First Match Corner",
        "name_ro": "First Match Corner",
        "name_ru": "First Match Corner",
        "name_tr": "First Match Corner",
        "name_se": "First Match Corner",
        "name_el": "First Match Corner",
        "name_es": "First Match Corner",
        "name_hr": "First Match Corner",
        "name_fr": "First Match Corner"
    },
    "id_351^prematch": {
        "name_en": "{!inningnr} innings overs 0 to {overnr} - 1x2",
        "name_aa": "{!inningnr} innings overs 0 to {overnr} - 1x2",
        "name_de": "{!inningnr} Inning Overs null zu {overnr} - 1x2",
        "name_nl": "{inningnr} innings overs 0 tot {overnr} - 1x2",
        "name_pl": "{!inningnr} innings overs 0 to {overnr} - 1x2",
        "name_ro": "{!inningnr} innings overs 0 to {overnr} - 1x2",
        "name_ru": "{!inningnr} innings overs 0 to {overnr} - 1x2",
        "name_tr": "{!inningnr}. devre 0 a {overnr}. over - 1x2",
        "name_se": "{!inningnr} innings overs 0 to {overnr} - 1x2",
        "name_el": "{!inningnr} innings overs 0 to {overnr} - 1x2",
        "name_es": "{!inningnr} innings overs 0 to {overnr} - 1x2",
        "name_hr": "{!inningnr} inning - over od 0 do {overnr} - 1x2",
        "name_fr": "{!inningnr} manche Séries de lancers 0 à {overnr} - 1x2"
    },
    "id_20960000^prematch": {
        "name_en": "Race to 5 Corners {ck}",
        "name_aa": "Race to 5 Corners {ck}",
        "name_de": "Race to 5 Corners {ck}",
        "name_nl": "Race to 5 Corners {ck}",
        "name_pl": "Race to 5 Corners {ck}",
        "name_ro": "Race to 5 Corners {ck}",
        "name_ru": "Race to 5 Corners {ck}",
        "name_tr": "Race to 5 Corners {ck}",
        "name_se": "Race to 5 Corners {ck}",
        "name_el": "Race to 5 Corners {ck}",
        "name_es": "Race to 5 Corners {ck}",
        "name_hr": "Race to 5 Corners {ck}",
        "name_fr": "Race to 5 Corners {ck}"
    },
    "id_940^prematch": {
        "name_en": "{!fieldgoalnr} field goal made (incl. overtime)",
        "name_aa": "{!fieldgoalnr} field goal made (incl. overtime)",
        "name_de": "{!fieldgoalnr} Field Goal verwandelt (inkl. Verlängerung)",
        "name_nl": "{fieldgoalnr} veldgoal gescoord (incl. verlenging)",
        "name_pl": "{!fieldgoalnr} field goal made (incl. overtime)",
        "name_ro": "{!fieldgoalnr} field goal made (incl. overtime)",
        "name_ru": "{!fieldgoalnr} field goal made (incl. overtime)",
        "name_tr": "{!fieldgoalnr} field goal made (incl. overtime)",
        "name_se": "{!fieldgoalnr} field goal made (incl. overtime)",
        "name_el": "{!fieldgoalnr} field goal made (incl. overtime)",
        "name_es": "{!fieldgoalnr} field goal made (incl. overtime)",
        "name_hr": "{!fieldgoalnr} field goal made (incl. overtime)",
        "name_fr": "{!fieldgoalnr} nombre de transformations (field goal) réussies (prolongations incluses)"
    },
    "id_610^prematch": {
        "name_en": "1x2 (incl. overtime)",
        "name_aa": "1x2 (incl. overtime)",
        "name_de": "1x2 (inkl. Verlängerung)",
        "name_nl": "1x2 (verlenging inbegrepen)",
        "name_pl": "1x2 (incl. overtime)",
        "name_ro": "1x2 (incl. overtime)",
        "name_ru": "1x2 (incl. overtime)",
        "name_tr": "1x2 (uzatmalar dahil)",
        "name_se": "1x2 (incl. overtime)",
        "name_el": "1x2 (incl. overtime)",
        "name_es": "1x2 (incl. overtime)",
        "name_hr": "1x2 (uklj. produžetke)",
        "name_fr": "1x2 (inclus prolongations)"
    },
    "id_375^prematch": {
        "name_en": "{!setnr} set leg {legnr} - winner",
        "name_aa": "{!setnr} set leg {legnr} - winner",
        "name_de": "{!setnr} Satz Runde {legnr} - Gewinner",
        "name_nl": "{setnr} set leg {legnr} - winnaar",
        "name_pl": "{!setnr} set leg {legnr} - winner",
        "name_ro": "{!setnr} set leg {legnr} - winner",
        "name_ru": "{!setnr} set leg {legnr} - winner",
        "name_tr": "{!setnr}. set {legnr}. ayak- kazanan",
        "name_se": "{!setnr} set leg {legnr} - winner",
        "name_el": "{!setnr} set leg {legnr} - winner",
        "name_es": "{!setnr} set leg {legnr} - winner",
        "name_hr": "{!setnr} set leg {legnr} - pobjednik",
        "name_fr": "{!setnr} set manche {legnr} - vainqueur"
    },
    "id_475^prematch": {
        "name_en": "Try draw no bet",
        "name_aa": "Try draw no bet",
        "name_de": "Versuch unentschieden, keine Wette",
        "name_nl": "Try draw no bet",
        "name_pl": "Try draw no bet",
        "name_ro": "Try draw no bet",
        "name_ru": "Try draw no bet",
        "name_tr": "Beraberlikte iade dene",
        "name_se": "Try draw no bet",
        "name_el": "Try draw no bet",
        "name_es": "Try draw no bet",
        "name_hr": "Polaganje ishod bez neodlučenog",
        "name_fr": "Essais remboursé si match nul"
    },
    "id_406^prematch": {
        "name_en": "Winner (incl. overtime and penalties)",
        "name_aa": "Winner (incl. overtime and penalties)",
        "name_de": "Gewinner (inkl. Verlängerung und Elfmeterschießen)",
        "name_nl": "Winnaar (verlenging en strafschoppen inbegrepen)",
        "name_pl": "Winner (incl. overtime and penalties)",
        "name_ro": "Winner (incl. overtime and penalties)",
        "name_ru": "Winner (incl. overtime and penalties)",
        "name_tr": "Kazanan (uzatmalar ve penaltılar dahil)",
        "name_se": "Winner (incl. overtime and penalties)",
        "name_el": "Winner (incl. overtime and penalties)",
        "name_es": "Winner (incl. overtime and penalties)",
        "name_hr": "Pobjednik (uklj. produžetke i penale)",
        "name_fr": "Vainqueur (inclus prolongations et séance de tirs au but)"
    },
    "id_54^prematch": {
        "name_en": "{$competitor2} highest scoring half",
        "name_aa": "{$competitor2} highest scoring half",
        "name_de": "{$competitor2} Halbzeit mit den meisten Toren",
        "name_nl": "{$competitor2} wint helft met de meeste doelpunten",
        "name_pl": "{$competitor2} highest scoring half",
        "name_ro": "{$competitor2} highest scoring half",
        "name_ru": "{$competitor2} highest scoring half",
        "name_tr": "{$competitor2} en gollü devre",
        "name_se": "{$competitor2} highest scoring half",
        "name_el": "{$competitor2} highest scoring half",
        "name_es": "{$competitor2} highest scoring half",
        "name_hr": "{$competitor2} poluvrijeme s najviše golova",
        "name_fr": "{$competitor2} Mi-temps avec le plus de buts marqués"
    },
    "id_489^prematch": {
        "name_en": "1st half - {$competitor2} total tries",
        "name_aa": "1st half - {$competitor2} total tries",
        "name_de": "1. Halbzeit - {$competitor2} Total Versuche",
        "name_nl": "Eerste helft - totaal aantal tries {$competitor2}",
        "name_pl": "1st half - {$competitor2} total tries",
        "name_ro": "1st half - {$competitor2} total tries",
        "name_ru": "1st half - {$competitor2} total tries",
        "name_tr": "İlk yarı - {$competitor2} toplam try",
        "name_se": "1st half - {$competitor2} total tries",
        "name_el": "1st half - {$competitor2} total tries",
        "name_es": "1st half - {$competitor2} total tries",
        "name_hr": "1. poluvrijeme - {$competitor2} ukupno polaganja",
        "name_fr": "1ère mi-temps - {$competitor2} total d'essais"
    },
    "id_39^prematch": {
        "name_en": "Last goalscorer",
        "name_aa": "Last goalscorer",
        "name_de": "Letzter Torschütze",
        "name_nl": "Laatste doelpuntenmaker",
        "name_pl": "Last goalscorer",
        "name_ro": "Last goalscorer",
        "name_ru": "Last goalscorer",
        "name_tr": "Son golcü",
        "name_se": "Last goalscorer",
        "name_el": "Last goalscorer",
        "name_es": "Last goalscorer",
        "name_hr": "Posljednji strijelac",
        "name_fr": "Dernier buteur"
    },
    "id_85^prematch": {
        "name_en": "2nd half - double chance",
        "name_aa": "2nd half - double chance",
        "name_de": "2. Halbzeit - Doppelte Chance",
        "name_nl": "2e helft - dubbele kans",
        "name_pl": "2nd half - double chance",
        "name_ro": "2nd half - double chance",
        "name_ru": "2nd half - double chance",
        "name_tr": "İkinci yarı - çifte şans",
        "name_se": "2nd half - double chance",
        "name_el": "2nd half - double chance",
        "name_es": "2nd half - double chance",
        "name_hr": "2. Poluvrijeme - dvostruka prilika",
        "name_fr": "2ème mi-temps - double chance"
    },
    "id_615^prematch": {
        "name_en": "{!quarternr} quarter - total (incl. overtime)",
        "name_aa": "{!quarternr} quarter - total (incl. overtime)",
        "name_de": "{!quarternr} Viertel - Total (inkl. Verlängerung)",
        "name_nl": "{quarternr} kwart - totaal (verlenging inbegrepen)",
        "name_pl": "{!quarternr} quarter - total (incl. overtime)",
        "name_ro": "{!quarternr} quarter - total (incl. overtime)",
        "name_ru": "{!quarternr} quarter - total (incl. overtime)",
        "name_tr": "{!quarternr}. çeyrek - toplam (uzatmalar dahil)",
        "name_se": "{!quarternr} quarter - total (incl. overtime)",
        "name_el": "{!quarternr} quarter - total (incl. overtime)",
        "name_es": "{!quarternr} quarter - total (incl. overtime)",
        "name_hr": "{!quarternr} četvrtina - total (uklj. produžetke)",
        "name_fr": "{!quarternr} quart-temps - total (inclus prolongations)"
    },
    "id_1044^prematch": {
        "name_en": "{!inningnr} inning - {$competitor1} total hits",
        "name_aa": "{!inningnr} inning - {$competitor1} total hits",
        "name_de": "{!inningnr} Inning - {$competitor1} Total hits",
        "name_nl": "{inningnr} inning - {$competitor1} totaal aantal slagen",
        "name_pl": "{!inningnr} inning - {$competitor1} total hits",
        "name_ro": "{!inningnr} inning - {$competitor1} total hits",
        "name_ru": "{!inningnr} inning - {$competitor1} total hits",
        "name_tr": "{!inningnr} inning - {$competitor1} total hits",
        "name_se": "{!inningnr} inning - {$competitor1} total hits",
        "name_el": "{!inningnr} inning - {$competitor1} total hits",
        "name_es": "{!inningnr} inning - {$competitor1} total hits",
        "name_hr": "{!inningnr} inning - {$competitor1} total hits",
        "name_fr": "{!inningnr} manche - {$competitor1} nombre de frappes (hits)"
    },
    "id_852^prematch": {
        "name_en": "{$competitor1} to win exactly 2 sets",
        "name_aa": "{$competitor1} to win exactly 2 sets",
        "name_de": "{$competitor1} wird genau zwei Sätze gewinnen",
        "name_nl": "{$competitor1} wint precies 2 sets",
        "name_pl": "{$competitor1} to win exactly 2 sets",
        "name_ro": "{$competitor1} to win exactly 2 sets",
        "name_ru": "{$competitor1} to win exactly 2 sets",
        "name_tr": "{$competitor1} sadece 2 set kazanır",
        "name_se": "{$competitor1} to win exactly 2 sets",
        "name_el": "{$competitor1} to win exactly 2 sets",
        "name_es": "{$competitor1} to win exactly 2 sets",
        "name_hr": "{$competitor1} osvaja točno 2 seta",
        "name_fr": "{$competitor1} gagnera exactement 2 sets"
    },
    "id_861^prematch": {
        "name_en": "Draw or both teams to score",
        "name_aa": "Draw or both teams to score",
        "name_de": "Draw or both teams to score",
        "name_nl": "Gelijkspel of beide teams scoren",
        "name_pl": "Draw or both teams to score",
        "name_ro": "Draw or both teams to score",
        "name_ru": "Draw or both teams to score",
        "name_tr": "Berabere ya da her iki takımda gol atar",
        "name_se": "Draw or both teams to score",
        "name_el": "Draw or both teams to score",
        "name_es": "Draw or both teams to score",
        "name_hr": "Neodlučeno ili oba daju gol",
        "name_fr": "Nul ou les deux équipes qui marquent"
    },
    "id_40^prematch": {
        "name_en": "Anytime goalscorer",
        "name_aa": "Anytime goalscorer",
        "name_de": "Torschütze(n)",
        "name_nl": "Doelpuntenmaker",
        "name_pl": "Anytime goalscorer",
        "name_ro": "Anytime goalscorer",
        "name_ru": "Anytime goalscorer",
        "name_tr": "Gol atar",
        "name_se": "Anytime goalscorer",
        "name_el": "Anytime goalscorer",
        "name_es": "Anytime goalscorer",
        "name_hr": "Strijelac u bilo koje vrijeme",
        "name_fr": "Buteur pendant le match"
    },
    "id_525^prematch": {
        "name_en": "Will there be a 4th set",
        "name_aa": "Will there be a 4th set",
        "name_de": "Wird es einen 4ten Satz geben?",
        "name_nl": "Zal er een vierde set nodig zijn",
        "name_pl": "Will there be a 4th set",
        "name_ro": "Will there be a 4th set",
        "name_ru": "Will there be a 4th set",
        "name_tr": "4. set oynanıcak mı",
        "name_se": "Will there be a 4th set",
        "name_el": "Will there be a 4th set",
        "name_es": "Will there be a 4th set",
        "name_hr": "Hoće li biti 4. seta",
        "name_fr": "Y aura-t-il un 4ème set ?"
    },
    "id_880^prematch": {
        "name_en": "{$competitor1} to win",
        "name_aa": "{$competitor1} to win",
        "name_de": "{$competitor1} wird gewinnen",
        "name_nl": "{$competitor1} wint",
        "name_pl": "{$competitor1} to win",
        "name_ro": "{$competitor1} to win",
        "name_ru": "{$competitor1} to win",
        "name_tr": "{$competitor1} kazanan",
        "name_se": "{$competitor1} to win",
        "name_el": "{$competitor1} to win",
        "name_es": "{$competitor1} to win",
        "name_hr": "{$competitor1} pobijeđuje",
        "name_fr": "{$competitor1} gagne"
    },
    "id_485^prematch": {
        "name_en": "1st half - try handicap {hcp}",
        "name_aa": "1st half - try handicap {hcp}",
        "name_de": "1. Halbzeit - Versuch Handicap {hcp}",
        "name_nl": "Eerste helft - try handicap {hcp}",
        "name_pl": "1st half - try handicap {hcp}",
        "name_ro": "1st half - try handicap {hcp}",
        "name_ru": "1st half - try handicap {hcp}",
        "name_tr": "İlk yarı - try handikap{hcp}",
        "name_se": "1st half - try handicap {hcp}",
        "name_el": "1st half - try handicap {hcp}",
        "name_es": "1st half - try handicap {hcp}",
        "name_hr": "1. poluvrijeme - polaganje hendikep {hcp}",
        "name_fr": "1ère mi-temps - Essais handicap {hcp}"
    },
    "id_149^prematch": {
        "name_en": "1st half - booking 1x2",
        "name_aa": "1st half - booking 1x2",
        "name_de": "1. Halbzeit - Karten 1x2",
        "name_nl": "Eerste helft - kaarten 1x2",
        "name_pl": "1st half - booking 1x2",
        "name_ro": "1st half - booking 1x2",
        "name_ru": "1st half - booking 1x2",
        "name_tr": "İlk yarı - kartlar 1x2",
        "name_se": "1st half - booking 1x2",
        "name_el": "1st half - booking 1x2",
        "name_es": "1st half - booking 1x2",
        "name_hr": "1. Poluvrijeme -Kartoni 1x2",
        "name_fr": "1ère mi-temps - Cartons 1x2"
    },
    "id_65^prematch": {
        "name_en": "1st half - handicap {hcp}",
        "name_aa": "1st half - handicap {hcp}",
        "name_de": "1. Halbzeit - Handicap {hcp}",
        "name_nl": "1e helft - handicap {hcp}",
        "name_pl": "1st half - handicap {hcp}",
        "name_ro": "1st half - handicap {hcp}",
        "name_ru": "1st half - handicap {hcp}",
        "name_tr": "İlk Yarı - handikap {hcp}",
        "name_se": "1st half - handicap {hcp}",
        "name_el": "1st half - handicap {hcp}",
        "name_es": "1st half - handicap {hcp}",
        "name_hr": "1. Poluvrijeme - hendikep {hcp}",
        "name_fr": "1ère mi-temps - handicap {hcp}"
    },
    "id_1173^prematch": {
        "name_en": "Winner (including OT) & Total (including OT)",
        "name_aa": "Winner (including OT) & Total (including OT)",
        "name_de": "Sieger (inklusive OT) & Total (inklusive OT)",
        "name_nl": "Winner (including OT) & Total (including OT)",
        "name_pl": "Winner (including OT) & Total (including OT)",
        "name_ro": "Winner (including OT) & Total (including OT)",
        "name_ru": "Winner (including OT) & Total (including OT)",
        "name_tr": "Winner (including OT) & Total (including OT)",
        "name_se": "Winner (including OT) & Total (including OT)",
        "name_el": "Winner (including OT) & Total (including OT)",
        "name_es": "Winner (including OT) & Total (including OT)",
        "name_hr": "Winner (including OT) & Total (including OT)",
        "name_fr": "Winner (including OT) & Total (including OT)"
    },
    "id_76^prematch": {
        "name_en": "1st half - {$competitor1} clean sheet",
        "name_aa": "1st half - {$competitor1} clean sheet",
        "name_de": "1. Halbzeit- {$competitor1} ohne Gegentreffer",
        "name_nl": "1e helft - {$competitor1} houdt doel schoon",
        "name_pl": "1st half - {$competitor1} clean sheet",
        "name_ro": "1st half - {$competitor1} clean sheet",
        "name_ru": "1st half - {$competitor1} clean sheet",
        "name_tr": "İlk Yarı - {$competitor1} gol yemez",
        "name_se": "1st half - {$competitor1} clean sheet",
        "name_el": "1st half - {$competitor1} clean sheet",
        "name_es": "1st half - {$competitor1} clean sheet",
        "name_hr": "1. Poluvrijeme - {$competitor1} bez primljenog gola",
        "name_fr": "1ère mi-temps - {$competitor1} cage inviolée"
    },
    "id_91^prematch": {
        "name_en": "2nd half - {$competitor1} total",
        "name_aa": "2nd half - {$competitor1} total",
        "name_de": "2. Halbzeit - {$competitor1} Total",
        "name_nl": "2e helft - {$competitor1} totaal",
        "name_pl": "2nd half - {$competitor1} total",
        "name_ro": "2nd half - {$competitor1} total",
        "name_ru": "2nd half - {$competitor1} total",
        "name_tr": "İkinci yarı - {$competitor1} toplam gol",
        "name_se": "2nd half - {$competitor1} total",
        "name_el": "2nd half - {$competitor1} total",
        "name_es": "2nd half - {$competitor1} total",
        "name_hr": "2. poluvrijeme - {$competitor1} ukupno golova",
        "name_fr": "2ème mi-temps - {$competitor1} total"
    },
    "id_540^prematch": {
        "name_en": "Double chance (match) & 1st half both teams score",
        "name_aa": "Double chance (match) & 1st half both teams score",
        "name_de": "Doppelte Chance (Spiel) & 1. Halbzeit Beide Mannschaften treffen",
        "name_nl": "Dubbele kans (match) & beide teams scoren in eerste helft",
        "name_pl": "Double chance (match) & 1st half both teams score",
        "name_ro": "Double chance (match) & 1st half both teams score",
        "name_ru": "Double chance (match) & 1st half both teams score",
        "name_tr": "Çifte şans (maç) & ilk yarı her iki takımda gol atar",
        "name_se": "Double chance (match) & 1st half both teams score",
        "name_el": "Double chance (match) & 1st half both teams score",
        "name_es": "Double chance (match) & 1st half both teams score",
        "name_hr": "Dvostruka prilika (meč) i oba daju gol u 1. poluvremenu",
        "name_fr": "Double chance (match) & 1ère mi-temps les deux équipes qui marquent"
    },
    "id_1177^prematch": {
        "name_en": "1st half 1x2 & 1st half total",
        "name_aa": "1st half 1x2 & 1st half total",
        "name_de": "1. Hälfte 1x2 & 1. Hälfte Total",
        "name_nl": "1st half 1x2 & 1st half total",
        "name_pl": "1st half 1x2 & 1st half total",
        "name_ro": "1st half 1x2 & 1st half total",
        "name_ru": "1st half 1x2 & 1st half total",
        "name_tr": "1st half 1x2 & 1st half total",
        "name_se": "1st half 1x2 & 1st half total",
        "name_el": "1st half 1x2 & 1st half total",
        "name_es": "1st half 1x2 & 1st half total",
        "name_hr": "1st half 1x2 & 1st half total",
        "name_fr": "1st half 1x2 & 1st half total"
    },
    "id_202^prematch": {
        "name_en": "{!setnr} set - winner",
        "name_aa": "{!setnr} set - winner",
        "name_de": "{!setnr} Satz - Gewinner",
        "name_nl": "{setnr} set - winnaar",
        "name_pl": "{!setnr} set - winner",
        "name_ro": "{!setnr} set - winner",
        "name_ru": "{!setnr} set - winner",
        "name_tr": "{!setnr}. set - kazananı",
        "name_se": "{!setnr} set - winner",
        "name_el": "{!setnr} set - winner",
        "name_es": "{!setnr} set - winner",
        "name_hr": "{!setnr} set - Pobjednik",
        "name_fr": "{!setnr} set - vainqueur"
    },
    "id_163^prematch": {
        "name_en": "{!cornernr} corner",
        "name_aa": "{!cornernr} corner",
        "name_de": "{!cornernr} Ecke",
        "name_nl": "{cornernr} hoekschop",
        "name_pl": "{!cornernr} corner",
        "name_ro": "{!cornernr} corner",
        "name_ru": "{!cornernr} corner",
        "name_tr": "{!cornernr} korner",
        "name_se": "{!cornernr} corner",
        "name_el": "{!cornernr} corner",
        "name_es": "{!cornernr} corner",
        "name_hr": "{!cornernr} Udarac iz kuta",
        "name_fr": "{!cornernr} corner"
    },
    "id_528^prematch": {
        "name_en": "{!setnr} set - total",
        "name_aa": "{!setnr} set - total",
        "name_de": "{!setnr} Satz - Total",
        "name_nl": "{setnr} set - totaal",
        "name_pl": "{!setnr} set - total",
        "name_ro": "{!setnr} set - total",
        "name_ru": "{!setnr} set - total",
        "name_tr": "{!setnr}. set - toplam",
        "name_se": "{!setnr} set - total",
        "name_el": "{!setnr} set - total",
        "name_es": "{!setnr} set - total",
        "name_hr": "{!setnr} set - total",
        "name_fr": "{!setnr} set - total"
    },
    "id_23^prematch": {
        "name_en": "{$competitor1} exact goals",
        "name_aa": "{$competitor1} exact goals",
        "name_de": "{$competitor1} Genaue Anzahl Tore",
        "name_nl": "{$competitor1} exact aantal doelpunten",
        "name_pl": "{$competitor1} exact goals",
        "name_ro": "{$competitor1} exact goals",
        "name_ru": "{$competitor1} exact goals",
        "name_tr": "{$competitor1} Kesin hedefler",
        "name_se": "{$competitor1} exact goals",
        "name_el": "{$competitor1} exact goals",
        "name_es": "{$competitor1} exact goals",
        "name_hr": "{$competitor1} točno golova",
        "name_fr": "{$competitor1} Nombre de buts exact"
    },
    "id_605^prematch": {
        "name_en": "{!inningnr} innings - total",
        "name_aa": "{!inningnr} innings - total",
        "name_de": "{!inningnr} Inning  Total",
        "name_nl": "{inningnr} innings - totaal",
        "name_pl": "{!inningnr} innings - total",
        "name_ro": "{!inningnr} innings - total",
        "name_ru": "{!inningnr} innings - total",
        "name_tr": "{!inningnr}. devre - toplam",
        "name_se": "{!inningnr} innings - total",
        "name_el": "{!inningnr} innings - total",
        "name_es": "{!inningnr} innings - total",
        "name_hr": "{!inningnr} inning - total",
        "name_fr": "{!inningnr} manche - total"
    },
    "id_318^prematch": {
        "name_en": "{!setnr} set - {$competitor1} total",
        "name_aa": "{!setnr} set - {$competitor1} total",
        "name_de": "{!setnr} Satz - {$competitor1} Total",
        "name_nl": "{setnr} set - {$competitor1} totaal",
        "name_pl": "{!setnr} set - {$competitor1} total",
        "name_ro": "{!setnr} set - {$competitor1} total",
        "name_ru": "{!setnr} set - {$competitor1} total",
        "name_tr": "{!setnr}. set - {$competitor1}  toplam",
        "name_se": "{!setnr} set - {$competitor1} total",
        "name_el": "{!setnr} set - {$competitor1} total",
        "name_es": "{!setnr} set - {$competitor1} total",
        "name_hr": "{!setnr} set - {$competitor1} total",
        "name_fr": "{!setnr} set - {$competitor1} total"
    },
    "id_911^prematch": {
        "name_en": "Will the fight go the distance",
        "name_aa": "Will the fight go the distance",
        "name_de": "Will the fight go the distance",
        "name_nl": "Zal de wedstrijd volledig worden uitgevochten (alle rondes)?",
        "name_pl": "Will the fight go the distance",
        "name_ro": "Will the fight go the distance",
        "name_ru": "Will the fight go the distance",
        "name_tr": "Will the fight go the distance",
        "name_se": "Will the fight go the distance",
        "name_el": "Will the fight go the distance",
        "name_es": "Will the fight go the distance",
        "name_hr": "Hoće li borba trajati do završetka poslednje runde, odnosno neće biti nokauta",
        "name_fr": "Will the fight go the distance"
    },
    "id_52^prematch": {
        "name_en": "Highest scoring half",
        "name_aa": "Highest scoring half",
        "name_de": "Halbzeit mit den meisten Toren",
        "name_nl": "Helft met de meeste doelpunten",
        "name_pl": "Highest scoring half",
        "name_ro": "Highest scoring half",
        "name_ru": "Highest scoring half",
        "name_tr": "En fazla gollü devre",
        "name_se": "Highest scoring half",
        "name_el": "Highest scoring half",
        "name_es": "Highest scoring half",
        "name_hr": "Poluvrijeme s najviše golova",
        "name_fr": "Mi-temps avec le plus de buts marqués"
    },
    "id_1055^prematch": {
        "name_en": "Winner & total",
        "name_aa": "Winner & total",
        "name_de": "Sieger & Total",
        "name_nl": "Winnaar & totaal",
        "name_pl": "Winner & total",
        "name_ro": "Winner & total",
        "name_ru": "Winner & total",
        "name_tr": "Winner & total",
        "name_se": "Winner & total",
        "name_el": "Winner & total",
        "name_es": "Winner & total",
        "name_hr": "Winner & total",
        "name_fr": "Vainqueur  et total"
    },
    "id_139^prematch": {
        "name_en": "Total bookings",
        "name_aa": "Total bookings",
        "name_de": "Gesamtanzahl Karten",
        "name_nl": "Totaal aantal kaarten",
        "name_pl": "Total bookings",
        "name_ro": "Total bookings",
        "name_ru": "Total bookings",
        "name_tr": "Toplam kart sayısı",
        "name_se": "Total bookings",
        "name_el": "Total bookings",
        "name_es": "Total bookings",
        "name_hr": "Ukupno kartona",
        "name_fr": "Total cartons"
    },
    "id_181^prematch": {
        "name_en": "1st half - {$competitor2} exact corners",
        "name_aa": "1st half - {$competitor2} exact corners",
        "name_de": "1. Halbzeit - {$competitor2} Genaue Anzahl",
        "name_nl": "Eerste helft - Exact aantal hoekschoppen {$competitor2}",
        "name_pl": "1st half - {$competitor2} exact corners",
        "name_ro": "1st half - {$competitor2} exact corners",
        "name_ru": "1st half - {$competitor2} exact corners",
        "name_tr": "İlk yarı - {$competitor2} doğru korner sayısı",
        "name_se": "1st half - {$competitor2} exact corners",
        "name_el": "1st half - {$competitor2} exact corners",
        "name_es": "1st half - {$competitor2} exact corners",
        "name_hr": "1. Poluvrijeme - {$competitor2} točno udaraca iz kuta",
        "name_fr": "1ère mi-temps - {$competitor2} nombre exact de corners"
    },
    "id_476^prematch": {
        "name_en": "Try handicap {hcp}",
        "name_aa": "Try handicap {hcp}",
        "name_de": "Versuch Handicap {hcp}",
        "name_nl": "Try handicap {hcp}",
        "name_pl": "Try handicap {hcp}",
        "name_ro": "Try handicap {hcp}",
        "name_ru": "Try handicap {hcp}",
        "name_tr": "{hcp} handikap dene",
        "name_se": "Try handicap {hcp}",
        "name_el": "Try handicap {hcp}",
        "name_es": "Try handicap {hcp}",
        "name_hr": "Polaganje hendikep {hcp}",
        "name_fr": "Essais handicap {hcp}"
    },
    "id_549^prematch": {
        "name_en": "{$competitor1} multigoals",
        "name_aa": "{$competitor1} multigoals",
        "name_de": "{$competitor1} Gesamtzahl der Tore im Spiel",
        "name_nl": "{$competitor1} multigoals",
        "name_pl": "{$competitor1} multigoals",
        "name_ro": "{$competitor1} multigoals",
        "name_ru": "{$competitor1} multigoals",
        "name_tr": "{$competitor1} toplam gol",
        "name_se": "{$competitor1} multigoals",
        "name_el": "{$competitor1} multigoals",
        "name_es": "{$competitor1} multigoals",
        "name_hr": "{$competitor1} multigolovi",
        "name_fr": "{$competitor1} Multi buts"
    },
    "id_529^prematch": {
        "name_en": "{!periodnr} period - double chance",
        "name_aa": "{!periodnr} period - double chance",
        "name_de": "{!periodnr} Periode - Doppelte Chance",
        "name_nl": "{periodnr} periode - dubbele kans",
        "name_pl": "{!periodnr} period - double chance",
        "name_ro": "{!periodnr} period - double chance",
        "name_ru": "{!periodnr} period - double chance",
        "name_tr": "{!periodnr}. periyod - çifte şans",
        "name_se": "{!periodnr} period - double chance",
        "name_el": "{!periodnr} period - double chance",
        "name_es": "{!periodnr} period - double chance",
        "name_hr": "{!periodnr} period - dvostruka prilika",
        "name_fr": "{!periodnr} période - double chance"
    },
    "id_499^prematch": {
        "name_en": "{!framenr} frame - winner",
        "name_aa": "{!framenr} frame - winner",
        "name_de": "{!framenr} Frame - Gewinner",
        "name_nl": "{framenr} frame - winnaar",
        "name_pl": "{!framenr} frame - winner",
        "name_ro": "{!framenr} frame - winner",
        "name_ru": "{!framenr} frame - winner",
        "name_tr": "{!framenr}. frame - kazanan",
        "name_se": "{!framenr} frame - winner",
        "name_el": "{!framenr} frame - winner",
        "name_es": "{!framenr} frame - winner",
        "name_hr": "{!framenr} frame - pobjednik",
        "name_fr": "{!framenr} manche - vainqueur"
    },
    "id_160^prematch": {
        "name_en": "1st half - {$competitor1} sending off",
        "name_aa": "1st half - {$competitor1} sending off",
        "name_de": "1. Halbzeit - {$competitor1} Platzverweise",
        "name_nl": "Eerste helft - uitsluitingen {$competitor1}",
        "name_pl": "1st half - {$competitor1} sending off",
        "name_ro": "1st half - {$competitor1} sending off",
        "name_ru": "1st half - {$competitor1} sending off",
        "name_tr": "İlk yarı - {$competitor1} oyundan atılma",
        "name_se": "1st half - {$competitor1} sending off",
        "name_el": "1st half - {$competitor1} sending off",
        "name_es": "1st half - {$competitor1} sending off",
        "name_hr": "1. Poluvrijeme - {$competitor1} isključenja",
        "name_fr": "1ère mi-temps - {$competitor1} expulsion"
    },
    "id_268^prematch": {
        "name_en": "Will there be an extra inning",
        "name_aa": "Will there be an extra inning",
        "name_de": "Wird es ein Extra-Inning geben?",
        "name_nl": "Zal er een extra inning nodig zijn",
        "name_pl": "Will there be an extra inning",
        "name_ro": "Will there be an extra inning",
        "name_ru": "Will there be an extra inning",
        "name_tr": "Uzatma devresi olucak mı",
        "name_se": "Will there be an extra inning",
        "name_el": "Will there be an extra inning",
        "name_es": "Will there be an extra inning",
        "name_hr": "Hoće li biti produžetaka",
        "name_fr": "Y aura-t-il une manche supplémentaire ?"
    },
    "id_486^prematch": {
        "name_en": "1st half - try handicap",
        "name_aa": "1st half - try handicap",
        "name_de": "1. Halbzeit - Versuch Handicap",
        "name_nl": "Eerste helft - try handicap",
        "name_pl": "1st half - try handicap",
        "name_ro": "1st half - try handicap",
        "name_ru": "1st half - try handicap",
        "name_tr": "İlk yarı - try handikap",
        "name_se": "1st half - try handicap",
        "name_el": "1st half - try handicap",
        "name_es": "1st half - try handicap",
        "name_hr": "1. poluvrijeme - polaganje hendikep",
        "name_fr": "1ère mi-temps - Essais handicap"
    },
    "id_79^prematch": {
        "name_en": "1st half - 1x2 & total",
        "name_aa": "1st half - 1x2 & total",
        "name_de": "1. Halbzeit- 1x2 & Total",
        "name_nl": "1e helft - 1x2 & totaal",
        "name_pl": "1st half - 1x2 & total",
        "name_ro": "1st half - 1x2 & total",
        "name_ru": "1st half - 1x2 & total",
        "name_tr": "ilk yarı - kazanan & gol sayısı",
        "name_se": "1st half - 1x2 & total",
        "name_el": "1st half - 1x2 & total",
        "name_es": "1st half - 1x2 & total",
        "name_hr": "1. Poluvrijeme - 1x2 i ukupno golova",
        "name_fr": "1ère mi-temps - 1x2 & total"
    },
    "id_1041^prematch": {
        "name_en": "{$competitor1} total hits (incl. extra innings)",
        "name_aa": "{$competitor1} total hits (incl. extra innings)",
        "name_de": "{$competitor1} Hits Gesamt (inkl. Extra Innings)",
        "name_nl": "{$competitor1} totaal aantal slagen (incl. extra innings)",
        "name_pl": "{$competitor1} total hits (incl. extra innings)",
        "name_ro": "{$competitor1} total hits (incl. extra innings)",
        "name_ru": "{$competitor1} total hits (incl. extra innings)",
        "name_tr": "{$competitor1} total hits (incl. extra innings)",
        "name_se": "{$competitor1} total hits (incl. extra innings)",
        "name_el": "{$competitor1} total hits (incl. extra innings)",
        "name_es": "{$competitor1} total hits (incl. extra innings)",
        "name_hr": "{$competitor1} total hits (incl. extra innings)",
        "name_fr": "{$competitor1} nombre de frappes (hits) (prolongations incluses)"
    },
    "id_446^prematch": {
        "name_en": "{!periodnr} period - total",
        "name_aa": "{!periodnr} period - total",
        "name_de": "{!periodnr} Periode - Total",
        "name_nl": "{periodnr} periode - totaal",
        "name_pl": "{!periodnr} period - total",
        "name_ro": "{!periodnr} period - total",
        "name_ru": "{!periodnr} period - total",
        "name_tr": "{!periodnr} . periyot - toplam",
        "name_se": "{!periodnr} period - total",
        "name_el": "{!periodnr} period - total",
        "name_es": "{!periodnr} period - total",
        "name_hr": "{!periodnr} period - total",
        "name_fr": "{!periodnr} période - total"
    },
    "id_199^prematch": {
        "name_en": "Correct score",
        "name_aa": "Correct score",
        "name_de": "Genaues Ergebnis",
        "name_nl": "Juiste uitslag",
        "name_pl": "Correct score",
        "name_ro": "Correct score",
        "name_ru": "Correct score",
        "name_tr": "Maç Sonucu",
        "name_se": "Correct score",
        "name_el": "Correct score",
        "name_es": "Correct score",
        "name_hr": "Točan rezultat",
        "name_fr": "Score correct"
    },
    "id_20930000^prematch": {
        "name_en": "1st Half Result - Corner",
        "name_aa": "1st Half Result - Corner",
        "name_de": "1st Half Result - Corner",
        "name_nl": "1st Half Result - Corner",
        "name_pl": "1st Half Result - Corner",
        "name_ro": "1st Half Result - Corner",
        "name_ru": "1st Half Result - Corner",
        "name_tr": "1st Half Result - Corner",
        "name_se": "1st Half Result - Corner",
        "name_el": "1st Half Result - Corner",
        "name_es": "1st Half Result - Corner",
        "name_hr": "1st Half Result - Corner",
        "name_fr": "1st Half Result - Corner"
    },
    "id_83^prematch": {
        "name_en": "2nd half - 1x2",
        "name_aa": "2nd half - 1x2",
        "name_de": "2. Halbzeit- 1x2",
        "name_nl": "2e helft - 1x2",
        "name_pl": "2nd half - 1x2",
        "name_ro": "2nd half - 1x2",
        "name_ru": "2nd half - 1x2",
        "name_tr": "İkinci yarı - 1x2",
        "name_se": "2nd half - 1x2",
        "name_el": "2nd half - 1x2",
        "name_es": "2nd half - 1x2",
        "name_hr": "2. Poluvrijeme - 1x2",
        "name_fr": "2ème mi-temps - 1x2"
    },
    "id_298^prematch": {
        "name_en": "Point range",
        "name_aa": "Point range",
        "name_de": "Punktebereich",
        "name_nl": "Punten range",
        "name_pl": "Point range",
        "name_ro": "Point range",
        "name_ru": "Point range",
        "name_tr": "Toplam sayı aralığı",
        "name_se": "Point range",
        "name_el": "Point range",
        "name_es": "Point range",
        "name_hr": "Opseg poena",
        "name_fr": "Intervalles points"
    },
    "id_818^prematch": {
        "name_en": "Halftime/fulltime & total",
        "name_aa": "Halftime/fulltime & total",
        "name_de": "Halftime/fulltime & total",
        "name_nl": "Ruststand/eindstand & totaal",
        "name_pl": "Halftime/fulltime & total",
        "name_ro": "Halftime/fulltime & total",
        "name_ru": "Halftime/fulltime & total",
        "name_tr": "İlkyarı/maç sonucu & toplam",
        "name_se": "Halftime/fulltime & total",
        "name_el": "Halftime/fulltime & total",
        "name_es": "Halftime/fulltime & total",
        "name_hr": "Poluvrijeme/kraj i total",
        "name_fr": "Mi-temps/fin de match & total"
    },
    "id_606^prematch": {
        "name_en": "{!inningnr} innings - {$competitor1} total",
        "name_aa": "{!inningnr} innings - {$competitor1} total",
        "name_de": "{!inningnr} Inning  {$competitor1} Total",
        "name_nl": "{inningnr} innings - {$competitor1} totaal",
        "name_pl": "{!inningnr} innings - {$competitor1} total",
        "name_ro": "{!inningnr} innings - {$competitor1} total",
        "name_ru": "{!inningnr} innings - {$competitor1} total",
        "name_tr": "{!inningnr}. devre - {$competitor1} toplam",
        "name_se": "{!inningnr} innings - {$competitor1} total",
        "name_el": "{!inningnr} innings - {$competitor1} total",
        "name_es": "{!inningnr} innings - {$competitor1} total",
        "name_hr": "{!inningnr} inning - {$competitor1} total",
        "name_fr": "{!inningnr} manche - {$competitor1} total"
    },
    "id_452^prematch": {
        "name_en": "{!periodnr} period - both teams to score",
        "name_aa": "{!periodnr} period - both teams to score",
        "name_de": "{!periodnr} Periode - Beide Mannschaften treffen",
        "name_nl": "{periodnr} periode - beide teams scoren",
        "name_pl": "{!periodnr} period - both teams to score",
        "name_ro": "{!periodnr} period - both teams to score",
        "name_ru": "{!periodnr} period - both teams to score",
        "name_tr": "{!periodnr}. periyot - her iki takımda gol atar",
        "name_se": "{!periodnr} period - both teams to score",
        "name_el": "{!periodnr} period - both teams to score",
        "name_es": "{!periodnr} period - both teams to score",
        "name_hr": "{!periodnr} period - oba daju gol",
        "name_fr": "{!periodnr} période - les deux équipes qui marquent"
    },
    "id_193^prematch": {
        "name_en": "{$competitor2} to win a set",
        "name_aa": "{$competitor2} to win a set",
        "name_de": "{$competitor2} wird einen Satz gewinnen",
        "name_nl": "{$competitor2} wint een set",
        "name_pl": "{$competitor2} to win a set",
        "name_ro": "{$competitor2} to win a set",
        "name_ru": "{$competitor2} to win a set",
        "name_tr": "{$competitor2} set kazanır",
        "name_se": "{$competitor2} to win a set",
        "name_el": "{$competitor2} to win a set",
        "name_es": "{$competitor2} to win a set",
        "name_hr": "{$competitor2} osvaja set",
        "name_fr": "{$competitor2} remporte un set"
    },
    "id_933^prematch": {
        "name_en": "Total turnovers (incl. overtime)",
        "name_aa": "Total turnovers (incl. overtime)",
        "name_de": "Ballverluste Gesamt (inkl. Verlängerung)",
        "name_nl": "Totaal aantal turnovers (incl. verlenging)",
        "name_pl": "Total turnovers (incl. overtime)",
        "name_ro": "Total turnovers (incl. overtime)",
        "name_ru": "Total turnovers (incl. overtime)",
        "name_tr": "Total turnovers (incl. overtime)",
        "name_se": "Total turnovers (incl. overtime)",
        "name_el": "Total turnovers (incl. overtime)",
        "name_es": "Total turnovers (incl. overtime)",
        "name_hr": "Total turnovers (incl. overtime)",
        "name_fr": "Nombre de renversements (turnovers) (prolongations incluses)"
    },
    "id_536^prematch": {
        "name_en": "Free text multiwinner market",
        "name_aa": "Free text multiwinner market",
        "name_de": "Free text multiwinner market",
        "name_nl": "Free text multiwinner market",
        "name_pl": "Free text multiwinner market",
        "name_ro": "Free text multiwinner market",
        "name_ru": "Free text multiwinner market",
        "name_tr": "Free text multiwinner market",
        "name_se": "Free text multiwinner market",
        "name_el": "Free text multiwinner market",
        "name_es": "Free text multiwinner market",
        "name_hr": "Free text multiwinner market",
        "name_fr": "Free text multiwinner market"
    },
    "id_819^prematch": {
        "name_en": "Halftime/fulltime & 1st half total",
        "name_aa": "Halftime/fulltime & 1st half total",
        "name_de": "Halftime/fulltime & 1st half total",
        "name_nl": "Ruststand/eindstand & eerste helft totaal",
        "name_pl": "Halftime/fulltime & 1st half total",
        "name_ro": "Halftime/fulltime & 1st half total",
        "name_ru": "Halftime/fulltime & 1st half total",
        "name_tr": "İlkyarı/maç sonucu & birinci devre toplam",
        "name_se": "Halftime/fulltime & 1st half total",
        "name_el": "Halftime/fulltime & 1st half total",
        "name_es": "Halftime/fulltime & 1st half total",
        "name_hr": "Poluvrijeme/kraj i total 1. poluvrijeme",
        "name_fr": "Mi-temps/fin de match & total 1ere mi-temps"
    },
    "id_534^prematch": {
        "name_en": "Championship free text market",
        "name_aa": "Championship free text market",
        "name_de": "Championship free text market",
        "name_nl": "Championship free text market",
        "name_pl": "Championship free text market",
        "name_ro": "Championship free text market",
        "name_ru": "Championship free text market",
        "name_tr": "Championship free text market",
        "name_se": "Championship free text market",
        "name_el": "Championship free text market",
        "name_es": "Championship free text market",
        "name_hr": "Championship free text market",
        "name_fr": "Championship free text market"
    },
    "id_611^prematch": {
        "name_en": "{!quarternr} quarter - 1x2 (incl. overtime)",
        "name_aa": "{!quarternr} quarter - 1x2 (incl. overtime)",
        "name_de": "{!quarternr} Viertel - 1x2 (inkl. Verlängerung)",
        "name_nl": "{quarternr} kwart - 1x2 (verlenging inbegrepen)",
        "name_pl": "{!quarternr} quarter - 1x2 (incl. overtime)",
        "name_ro": "{!quarternr} quarter - 1x2 (incl. overtime)",
        "name_ru": "{!quarternr} quarter - 1x2 (incl. overtime)",
        "name_tr": "{!quarternr}. çeyrek - 1x2 (uzatmalar dahil)",
        "name_se": "{!quarternr} quarter - 1x2 (incl. overtime)",
        "name_el": "{!quarternr} quarter - 1x2 (incl. overtime)",
        "name_es": "{!quarternr} quarter - 1x2 (incl. overtime)",
        "name_hr": "{!quarternr} četvrtina - 1x2 (uklj. produžetke)",
        "name_fr": "{!quarternr} quart-temps - 1x2 (inclus prolongations)"
    },
    "id_96^prematch": {
        "name_en": "2nd half - {$competitor1} clean sheet",
        "name_aa": "2nd half - {$competitor1} clean sheet",
        "name_de": "2. Halbzeit - {$competitor1} ohne Gegentreffer",
        "name_nl": "2e helft - {$competitor1} houdt doel schoon",
        "name_pl": "2nd half - {$competitor1} clean sheet",
        "name_ro": "2nd half - {$competitor1} clean sheet",
        "name_ru": "2nd half - {$competitor1} clean sheet",
        "name_tr": "İkinci yarı - {$competitor1} gol yemez",
        "name_se": "2nd half - {$competitor1} clean sheet",
        "name_el": "2nd half - {$competitor1} clean sheet",
        "name_es": "2nd half - {$competitor1} clean sheet",
        "name_hr": "2. poluvrijeme - {$competitor1} bez primljenog gola",
        "name_fr": "2ème mi-temps - {$competitor1} cage inviolée"
    },
    "id_19^prematch": {
        "name_en": "{$competitor1} total",
        "name_aa": "{$competitor1} total",
        "name_de": "{$competitor1} Total",
        "name_nl": "{$competitor1} totaal",
        "name_pl": "{$competitor1} total",
        "name_ro": "{$competitor1} total",
        "name_ru": "{$competitor1} total",
        "name_tr": "{$competitor1} toplam gol",
        "name_se": "{$competitor1} total",
        "name_el": "{$competitor1} total",
        "name_es": "{$competitor1} total",
        "name_hr": "{$competitor1} ukupno golova",
        "name_fr": "{$competitor1} total"
    },
    "id_306^prematch": {
        "name_en": "How many sets will be decided by extra points",
        "name_aa": "How many sets will be decided by extra points",
        "name_de": "Wie viele Sätze werden durch Extrapunkte entschieden",
        "name_nl": "Hoeveel sets worden beslist door extra punten",
        "name_pl": "How many sets will be decided by extra points",
        "name_ro": "How many sets will be decided by extra points",
        "name_ru": "How many sets will be decided by extra points",
        "name_tr": "Ekstra sayılarla kaç sete karar verilecek",
        "name_se": "How many sets will be decided by extra points",
        "name_el": "How many sets will be decided by extra points",
        "name_es": "How many sets will be decided by extra points",
        "name_hr": "Koliko setova će biti odlučeno dodatnim poenima",
        "name_fr": "Combien de sets vont de décider par des points supplémentaires ?"
    },
    "id_88^prematch": {
        "name_en": "2nd half - handicap",
        "name_aa": "2nd half - handicap",
        "name_de": "2. Halbzeit - Handicap",
        "name_nl": "2e helft - handicap",
        "name_pl": "2nd half - handicap",
        "name_ro": "2nd half - handicap",
        "name_ru": "2nd half - handicap",
        "name_tr": "İkinci yarı - handikap",
        "name_se": "2nd half - handicap",
        "name_el": "2nd half - handicap",
        "name_es": "2nd half - handicap",
        "name_hr": "2. Poluvrijeme - hendikep",
        "name_fr": "2ème mi-temps - handicap"
    },
    "id_546^prematch": {
        "name_en": "Double chance & both teams to score",
        "name_aa": "Double chance & both teams to score",
        "name_de": "Doppelte Chance & Beide Mannschaften treffen",
        "name_nl": "Dubbele kans & beide teams scoren",
        "name_pl": "Double chance & both teams to score",
        "name_ro": "Double chance & both teams to score",
        "name_ru": "Double chance & both teams to score",
        "name_tr": "Çifte şans & her iki takım da gol atar",
        "name_se": "Double chance & both teams to score",
        "name_el": "Double chance & both teams to score",
        "name_es": "Double chance & both teams to score",
        "name_hr": "Dvostruka prilika i oba daju gol",
        "name_fr": "Double chance & les deux équipes qui marquent"
    },
    "id_33310000^prematch": {
        "name_en": "Percentage Of Ball Possession",
        "name_aa": "Percentage Of Ball Possession",
        "name_de": "Percentage Of Ball Possession",
        "name_nl": "Percentage Of Ball Possession",
        "name_pl": "Percentage Of Ball Possession",
        "name_ro": "Percentage Of Ball Possession",
        "name_ru": "Percentage Of Ball Possession",
        "name_tr": "Percentage Of Ball Possession",
        "name_se": "Percentage Of Ball Possession",
        "name_el": "Percentage Of Ball Possession",
        "name_es": "Percentage Of Ball Possession",
        "name_hr": "Percentage Of Ball Possession",
        "name_fr": "Percentage Of Ball Possession"
    },
    "id_1043^prematch": {
        "name_en": "{!inningnr} inning - total hits",
        "name_aa": "{!inningnr} inning - total hits",
        "name_de": "{!inningnr} Inning - Total Hits",
        "name_nl": "{inningnr} inning - totaal aantal slagen",
        "name_pl": "{!inningnr} inning - total hits",
        "name_ro": "{!inningnr} inning - total hits",
        "name_ru": "{!inningnr} inning - total hits",
        "name_tr": "{!inningnr} inning - total hits",
        "name_se": "{!inningnr} inning - total hits",
        "name_el": "{!inningnr} inning - total hits",
        "name_es": "{!inningnr} inning - total hits",
        "name_hr": "{!inningnr} inning - total hits",
        "name_fr": "{!inningnr} manche - nombre de frappes (hits)"
    },
    "id_270^prematch": {
        "name_en": "Team to win more innings",
        "name_aa": "Team to win more innings",
        "name_de": "Team mit den meist gewonnen Innings",
        "name_nl": "Team dat meer innings wint",
        "name_pl": "Team to win more innings",
        "name_ro": "Team to win more innings",
        "name_ru": "Team to win more innings",
        "name_tr": "Daha çok devre kazanacak takım",
        "name_se": "Team to win more innings",
        "name_el": "Team to win more innings",
        "name_es": "Team to win more innings",
        "name_hr": "Tim s više dobijenih inninga",
        "name_fr": "Equipe qui gagne le plus de manches"
    },
    "id_262^prematch": {
        "name_en": "Total (over-exact-under) (incl. extra innings)",
        "name_aa": "Total (over-exact-under) (incl. extra innings)",
        "name_de": "Total (darüber-exakt-unter) (inkl. Extra Innings)",
        "name_nl": "Totaal (boven-exact-onder) (extra innings inbegrepen)",
        "name_pl": "Total (over-exact-under) (incl. extra innings)",
        "name_ro": "Total (over-exact-under) (incl. extra innings)",
        "name_ru": "Total (over-exact-under) (incl. extra innings)",
        "name_tr": "Toplam (üst-tam-alt) (uzatmalar dahil)",
        "name_se": "Total (over-exact-under) (incl. extra innings)",
        "name_el": "Total (over-exact-under) (incl. extra innings)",
        "name_es": "Total (over-exact-under) (incl. extra innings)",
        "name_hr": "Total (više-točno-manje) (uklj. produžetke)",
        "name_fr": "Total (plus de-exact-moins de (inclus manches supplémentaires)"
    },
    "id_196^prematch": {
        "name_en": "Exact sets",
        "name_aa": "Exact sets",
        "name_de": "Genaue Anzahl Sätze",
        "name_nl": "Exact aantal sets",
        "name_pl": "Exact sets",
        "name_ro": "Exact sets",
        "name_ru": "Exact sets",
        "name_tr": "Toplam set",
        "name_se": "Exact sets",
        "name_el": "Exact sets",
        "name_es": "Exact sets",
        "name_hr": "Točno setova",
        "name_fr": "Nombre exact de sets"
    },
    "id_32^prematch": {
        "name_en": "{$competitor2} clean sheet",
        "name_aa": "{$competitor2} clean sheet",
        "name_de": "{$competitor2} ohne Gegentreffer",
        "name_nl": "{$competitor2} houdt doel schoon",
        "name_pl": "{$competitor2} clean sheet",
        "name_ro": "{$competitor2} clean sheet",
        "name_ru": "{$competitor2} clean sheet",
        "name_tr": "{$competitor2} gol yemez",
        "name_se": "{$competitor2} clean sheet",
        "name_el": "{$competitor2} clean sheet",
        "name_es": "{$competitor2} clean sheet",
        "name_hr": "{$competitor2} bez primljenog gola",
        "name_fr": "{$competitor2} cage inviolée"
    },
    "id_78^prematch": {
        "name_en": "1st half - 1x2 & both teams to score",
        "name_aa": "1st half - 1x2 & both teams to score",
        "name_de": "1. Halbzeit- 1x2 & Beide Mannschaften treffen",
        "name_nl": "1e helft - 1x2 & beide teams scoren",
        "name_pl": "1st half - 1x2 & both teams to score",
        "name_ro": "1st half - 1x2 & both teams to score",
        "name_ru": "1st half - 1x2 & both teams to score",
        "name_tr": "İlk yarı - 1x2 & her iki takım da gol atar",
        "name_se": "1st half - 1x2 & both teams to score",
        "name_el": "1st half - 1x2 & both teams to score",
        "name_es": "1st half - 1x2 & both teams to score",
        "name_hr": "1. Poluvrijeme - 1x2 i oba daju gol",
        "name_fr": "1ère mi-temps - 1x2 & les deux équipes qui marquent"
    },
    "id_122^prematch": {
        "name_en": "Will there be a penalty shootout",
        "name_aa": "Will there be a penalty shootout",
        "name_de": "Wird es ein Elfmeterschießen geben?",
        "name_nl": "Zullen er strafschoppen nodig zijn",
        "name_pl": "Will there be a penalty shootout",
        "name_ro": "Will there be a penalty shootout",
        "name_ru": "Will there be a penalty shootout",
        "name_tr": "Penaltı atışları olacak mı",
        "name_se": "Will there be a penalty shootout",
        "name_el": "Will there be a penalty shootout",
        "name_es": "Will there be a penalty shootout",
        "name_hr": "Hoće li biti penala",
        "name_fr": "Y aura-t-il une séance de tirs au but ?"
    },
    "id_112^prematch": {
        "name_en": "Overtime & goal",
        "name_aa": "Overtime & goal",
        "name_de": "Verlängerung & Tor",
        "name_nl": "Verlenging & doelpunt",
        "name_pl": "Overtime & goal",
        "name_ro": "Overtime & goal",
        "name_ru": "Overtime & goal",
        "name_tr": "Uzatma & Gol",
        "name_se": "Overtime & goal",
        "name_el": "Overtime & goal",
        "name_es": "Overtime & goal",
        "name_hr": "Produžetak i gol",
        "name_fr": "Prolongations & but"
    },
    "id_182^prematch": {
        "name_en": "1st half - corner range",
        "name_aa": "1st half - corner range",
        "name_de": "1. Halbzeit - Eckenanzahlbereich",
        "name_nl": "Eerste helft - hoekschoppen range",
        "name_pl": "1st half - corner range",
        "name_ro": "1st half - corner range",
        "name_ru": "1st half - corner range",
        "name_tr": "İlk yarı- korner aralığı",
        "name_se": "1st half - corner range",
        "name_el": "1st half - corner range",
        "name_es": "1st half - corner range",
        "name_hr": "1. Poluvrijeme - Udarci iz kuta - opseg",
        "name_fr": "1ère mi-temps - intervalles nombre de corners"
    },
    "id_37^prematch": {
        "name_en": "1x2 & total",
        "name_aa": "1x2 & total",
        "name_de": "1x2 & Total",
        "name_nl": "1x2 & totaal",
        "name_pl": "1x2 & total",
        "name_ro": "1x2 & total",
        "name_ru": "1x2 & total",
        "name_tr": "1x2 & toplam gol",
        "name_se": "1x2 & total",
        "name_el": "1x2 & total",
        "name_es": "1x2 & total",
        "name_hr": "1x2 i ukupno golova",
        "name_fr": "1x2 & total"
    },
    "id_144^prematch": {
        "name_en": "{$competitor2} exact bookings",
        "name_aa": "{$competitor2} exact bookings",
        "name_de": "{$competitor2} Genaue Anzahl Karten",
        "name_nl": "Exact aantal kaarten {$competitor2}",
        "name_pl": "{$competitor2} exact bookings",
        "name_ro": "{$competitor2} exact bookings",
        "name_ru": "{$competitor2} exact bookings",
        "name_tr": "{$competitor2} toplam kart sayısı",
        "name_se": "{$competitor2} exact bookings",
        "name_el": "{$competitor2} exact bookings",
        "name_es": "{$competitor2} exact bookings",
        "name_hr": "{$competitor2} Točno kartona",
        "name_fr": "{$competitor2} nombre exact de cartons"
    },
    "id_77^prematch": {
        "name_en": "1st half - {$competitor2} clean sheet",
        "name_aa": "1st half - {$competitor2} clean sheet",
        "name_de": "1. Halbzeit- {$competitor2} ohne Gegentreffer",
        "name_nl": "1e helft - {$competitor2} houdt doel schoon",
        "name_pl": "1st half - {$competitor2} clean sheet",
        "name_ro": "1st half - {$competitor2} clean sheet",
        "name_ru": "1st half - {$competitor2} clean sheet",
        "name_tr": "İlk Yarı - {$competitor2} gol yemez",
        "name_se": "1st half - {$competitor2} clean sheet",
        "name_el": "1st half - {$competitor2} clean sheet",
        "name_es": "1st half - {$competitor2} clean sheet",
        "name_hr": "1. Poluvrijeme - {$competitor2} bez primljenog gola",
        "name_fr": "1ère mi-temps - {$competitor2} cage inviolée"
    },
    "id_264^prematch": {
        "name_en": "Odd/even (incl. extra innings)",
        "name_aa": "Odd/even (incl. extra innings)",
        "name_de": "Gerade/ungerade (inkl. Extra Innings)",
        "name_nl": "Even/oneven (extra innings inbegrepen)",
        "name_pl": "Odd/even (incl. extra innings)",
        "name_ro": "Odd/even (incl. extra innings)",
        "name_ru": "Odd/even (incl. extra innings)",
        "name_tr": "Tek/çift (uzatmalar dahil)",
        "name_se": "Odd/even (incl. extra innings)",
        "name_el": "Odd/even (incl. extra innings)",
        "name_es": "Odd/even (incl. extra innings)",
        "name_hr": "Par/nepar (uklj. produžetke)",
        "name_fr": "Pair/Impair (inclus manches supplémentaires)"
    },
    "id_68^prematch": {
        "name_en": "1st half - total",
        "name_aa": "1st half - total",
        "name_de": "1. Halbzeit - Total",
        "name_nl": "1e helft - totaal",
        "name_pl": "1st half - total",
        "name_ro": "1st half - total",
        "name_ru": "1st half - total",
        "name_tr": "İlk Yarı - Toplam gol",
        "name_se": "1st half - total",
        "name_el": "1st half - total",
        "name_es": "1st half - total",
        "name_hr": "1. Poluvrijeme - ukupno golova",
        "name_fr": "1ère mi-temps - total"
    },
    "id_84^prematch": {
        "name_en": "2nd half - {!goalnr} goal",
        "name_aa": "2nd half - {!goalnr} goal",
        "name_de": "2. Halbzeit - {!goalnr} Tor",
        "name_nl": "2e helft - {goalnr} doelpunt",
        "name_pl": "2nd half - {!goalnr} goal",
        "name_ro": "2nd half - {!goalnr} goal",
        "name_ru": "2nd half - {!goalnr} goal",
        "name_tr": "ikinci yarı - {!goalnr} gol",
        "name_se": "2nd half - {!goalnr} goal",
        "name_el": "2nd half - {!goalnr} goal",
        "name_es": "2nd half - {!goalnr} goal",
        "name_hr": "2. poluvrijeme - {!goalnr} gol",
        "name_fr": "2ème mi-temps - {!goalnr} but"
    },
    "id_256^prematch": {
        "name_en": "Handicap (incl. extra innings)",
        "name_aa": "Handicap (incl. extra innings)",
        "name_de": "Handicap (inkl. Extra Innings)",
        "name_nl": "Handicap (extra innings inbegrepen)",
        "name_pl": "Handicap (incl. extra innings)",
        "name_ro": "Handicap (incl. extra innings)",
        "name_ru": "Handicap (incl. extra innings)",
        "name_tr": "Handikap (uzatmalar dahil)",
        "name_se": "Handicap (incl. extra innings)",
        "name_el": "Handicap (incl. extra innings)",
        "name_es": "Handicap (incl. extra innings)",
        "name_hr": "Hendikep (uklj. produžetke)",
        "name_fr": "Handicap (inclus manches supplémentaires)"
    },
    "id_184^prematch": {
        "name_en": "{!goalnr} goal & 1x2",
        "name_aa": "{!goalnr} goal & 1x2",
        "name_de": "{!goalnr} Tor & 1x2",
        "name_nl": "{goalnr} doelpuntl & 1x2",
        "name_pl": "{!goalnr} goal & 1x2",
        "name_ro": "{!goalnr} goal & 1x2",
        "name_ru": "{!goalnr} goal & 1x2",
        "name_tr": "{!goalnr} gol & 1x2",
        "name_se": "{!goalnr} goal & 1x2",
        "name_el": "{!goalnr} goal & 1x2",
        "name_es": "{!goalnr} goal & 1x2",
        "name_hr": "{!goalnr} golovi i 1x2",
        "name_fr": "{!goalnr} but & 1x2"
    },
    "id_297^prematch": {
        "name_en": "Total (over-exact-under)",
        "name_aa": "Total (over-exact-under)",
        "name_de": "Total (darüber-exakt-unter)",
        "name_nl": "Totaal (boven-exact-onder)",
        "name_pl": "Total (over-exact-under)",
        "name_ro": "Total (over-exact-under)",
        "name_ru": "Total (over-exact-under)",
        "name_tr": "Toplam gol (üstünde-tam olarak-altında)",
        "name_se": "Total (over-exact-under)",
        "name_el": "Total (over-exact-under)",
        "name_es": "Total (over-exact-under)",
        "name_hr": "Total (više-točno-manje)",
        "name_fr": "Total (plus de -exact- moins de)"
    },
    "id_288^prematch": {
        "name_en": "{!inningnr} inning - total",
        "name_aa": "{!inningnr} inning - total",
        "name_de": "{!inningnr} Inning - Total",
        "name_nl": "{inningnr} inning - totaal",
        "name_pl": "{!inningnr} inning - total",
        "name_ro": "{!inningnr} inning - total",
        "name_ru": "{!inningnr} inning - total",
        "name_tr": "{!inningnr}. devre - toplam",
        "name_se": "{!inningnr} inning - total",
        "name_el": "{!inningnr} inning - total",
        "name_es": "{!inningnr} inning - total",
        "name_hr": "{!inningnr} inning - total",
        "name_fr": "{!inningnr} manche - total"
    },
    "id_1123^prematch": {
        "name_en": "{!inningnr} inning - Winner",
        "name_aa": "{!inningnr} inning - Winner",
        "name_de": "{!inningnr} Inning - Sieger",
        "name_nl": "{!inningnr}e inning - Winnaar",
        "name_pl": "{!inningnr} inning - Winner",
        "name_ro": "{!inningnr} inning - Winner",
        "name_ru": "{!inningnr} inning - Winner",
        "name_tr": "{!inningnr} inning - Winner",
        "name_se": "{!inningnr} inning - Winner",
        "name_el": "{!inningnr} inning - Winner",
        "name_es": "{!inningnr} inning - Winner",
        "name_hr": "{!inningnr} inning - Winner",
        "name_fr": "{!inningnr} manche - Vainqueur"
    },
    "id_165^prematch": {
        "name_en": "Corner handicap",
        "name_aa": "Corner handicap",
        "name_de": "Ecken Handicap",
        "name_nl": "Hoekschop handicap",
        "name_pl": "Corner handicap",
        "name_ro": "Corner handicap",
        "name_ru": "Corner handicap",
        "name_tr": "Korner handikap",
        "name_se": "Corner handicap",
        "name_el": "Corner handicap",
        "name_es": "Corner handicap",
        "name_hr": "Udarci iz kuta Hendikep",
        "name_fr": "Corners handicap"
    },
    "id_749^prematch": {
        "name_en": "{!inningnr} inning - {$competitor1} to score",
        "name_aa": "{!inningnr} inning - {$competitor1} to score",
        "name_de": "{!inningnr} Inning - {$competitor1} punktet",
        "name_nl": "{inningnr} inning - {$competitor1} scoort",
        "name_pl": "{!inningnr} inning - {$competitor1} to score",
        "name_ro": "{!inningnr} inning - {$competitor1} to score",
        "name_ru": "{!inningnr} inning - {$competitor1} to score",
        "name_tr": "{!inningnr}. devre - {$competitor1} sayı yapar",
        "name_se": "{!inningnr} inning - {$competitor1} to score",
        "name_el": "{!inningnr} inning - {$competitor1} to score",
        "name_es": "{!inningnr} inning - {$competitor1} to score",
        "name_hr": "{!inningnr} inning - {$competitor1} osvaja poen",
        "name_fr": "{!inningnr} manche - {$competitor1} qui marque"
    },
    "id_1048^prematch": {
        "name_en": "Innings 1 to 5 - {$competitor2} total hits",
        "name_aa": "Innings 1 to 5 - {$competitor2} total hits",
        "name_de": "Inning 1 bis 5 - {$competitor2} Total Hits",
        "name_nl": "Innings 1 tot 5 - {$competitor2} totaal aantal slagen",
        "name_pl": "Innings 1 to 5 - {$competitor2} total hits",
        "name_ro": "Innings 1 to 5 - {$competitor2} total hits",
        "name_ru": "Innings 1 to 5 - {$competitor2} total hits",
        "name_tr": "Innings 1 to 5 - {$competitor2} total hits",
        "name_se": "Innings 1 to 5 - {$competitor2} total hits",
        "name_el": "Innings 1 to 5 - {$competitor2} total hits",
        "name_es": "Innings 1 to 5 - {$competitor2} total hits",
        "name_hr": "Innings 1 to 5 - {$competitor2} total hits",
        "name_fr": "Manches 1 à 5 - {$competitor2} nombre de bonnes balles"
    },
    "id_75^prematch": {
        "name_en": "1st half - both teams to score",
        "name_aa": "1st half - both teams to score",
        "name_de": "1. Halbzeit - Beide Mannschaften treffen",
        "name_nl": "1e helft - beide teams scoren",
        "name_pl": "1st half - both teams to score",
        "name_ro": "1st half - both teams to score",
        "name_ru": "1st half - both teams to score",
        "name_tr": "İlk yarı - her iki takım da gol atar",
        "name_se": "1st half - both teams to score",
        "name_el": "1st half - both teams to score",
        "name_es": "1st half - both teams to score",
        "name_hr": "1. Poluvrijeme - oba daju gol",
        "name_fr": "1ère mi-temps - les deux équipes qui marquent"
    },
    "id_105^prematch": {
        "name_en": "10 minutes - 1x2 from {from} to {to}",
        "name_aa": "10 minutes - 1x2 from {from} to {to}",
        "name_de": "10 Minuten - 1x2 von{from} bis {to}",
        "name_nl": "10 minuten - 1x2 van {from} tot {to}",
        "name_pl": "10 minutes - 1x2 from {from} to {to}",
        "name_ro": "10 minutes - 1x2 from {from} to {to}",
        "name_ru": "10 minutes - 1x2 from {from} to {to}",
        "name_tr": "10 dakika - 1x2 {from}'dan {to}'a kadar",
        "name_se": "10 minutes - 1x2 from {from} to {to}",
        "name_el": "10 minutes - 1x2 from {from} to {to}",
        "name_es": "10 minutes - 1x2 from {from} to {to}",
        "name_hr": "10 minuta - 1x2 od {from} do {to}",
        "name_fr": "10 minutes - 1x2 de {from} à {to}"
    },
    "id_162^prematch": {
        "name_en": "Corner 1x2",
        "name_aa": "Corner 1x2",
        "name_de": "Ecken 1x2",
        "name_nl": "Hoekschop 1x2",
        "name_pl": "Corner 1x2",
        "name_ro": "Corner 1x2",
        "name_ru": "Corner 1x2",
        "name_tr": "Korner 1x2",
        "name_se": "Corner 1x2",
        "name_el": "Corner 1x2",
        "name_es": "Corner 1x2",
        "name_hr": "Udarac iz kuta 1x2",
        "name_fr": "Corners 1x2"
    },
    "id_166^prematch": {
        "name_en": "Total corners",
        "name_aa": "Total corners",
        "name_de": "Gesamtanzahl Ecken",
        "name_nl": "Totaal aantal hoekschoppen",
        "name_pl": "Total corners",
        "name_ro": "Total corners",
        "name_ru": "Total corners",
        "name_tr": "Toplam korner sayısı",
        "name_se": "Total corners",
        "name_el": "Total corners",
        "name_es": "Total corners",
        "name_hr": "Ukupno udaraca iz kuta",
        "name_fr": "Total corners"
    },
    "id_24^prematch": {
        "name_en": "{$competitor2} exact goals",
        "name_aa": "{$competitor2} exact goals",
        "name_de": "{$competitor2} Genaue Anzahl Tore",
        "name_nl": "{$competitor2} exact aantal doelpunten",
        "name_pl": "{$competitor2} exact goals",
        "name_ro": "{$competitor2} exact goals",
        "name_ru": "{$competitor2} exact goals",
        "name_tr": "{$competitor2} Kesin hedefler",
        "name_se": "{$competitor2} exact goals",
        "name_el": "{$competitor2} exact goals",
        "name_es": "{$competitor2} exact goals",
        "name_hr": "{$competitor2} točno golova",
        "name_fr": "{$competitor2} Nombre de buts exact"
    },
    "id_3^prematch": {
        "name_en": "Which team will win the final",
        "name_aa": "Which team will win the final",
        "name_de": "Welches Team gewinnt das Finale",
        "name_nl": "Welk team wint de finale",
        "name_pl": "Which team will win the final",
        "name_ro": "Which team will win the final",
        "name_ru": "Which team will win the final",
        "name_tr": "Finali kazanacak takım",
        "name_se": "Which team will win the final",
        "name_el": "Which team will win the final",
        "name_es": "Which team will win the final",
        "name_hr": "Koji tim će pobijediti u finalu",
        "name_fr": "Quelle équipe gagnera la finale ?"
    },
    "id_238^prematch": {
        "name_en": "Total points",
        "name_aa": "Total points",
        "name_de": "Anzahl Punkte",
        "name_nl": "Totaal aantal punten",
        "name_pl": "Total points",
        "name_ro": "Total points",
        "name_ru": "Total points",
        "name_tr": "Toplam sayı",
        "name_se": "Total points",
        "name_el": "Total points",
        "name_es": "Total points",
        "name_hr": "Ukupno poena",
        "name_fr": "Total points"
    },
    "id_1176^prematch": {
        "name_en": "1st quarter handicap & 1st quarter total",
        "name_aa": "1st quarter handicap & 1st quarter total",
        "name_de": "1st quarter handicap & 1st quarter total",
        "name_nl": "1st quarter handicap & 1st quarter total",
        "name_pl": "1st quarter handicap & 1st quarter total",
        "name_ro": "1st quarter handicap & 1st quarter total",
        "name_ru": "1st quarter handicap & 1st quarter total",
        "name_tr": "1st quarter handicap & 1st quarter total",
        "name_se": "1st quarter handicap & 1st quarter total",
        "name_el": "1st quarter handicap & 1st quarter total",
        "name_es": "1st quarter handicap & 1st quarter total",
        "name_hr": "1st quarter handicap & 1st quarter total",
        "name_fr": "1st quarter handicap & 1st quarter total"
    },
    "id_964^prematch": {
        "name_en": "Any team total maximum consecutive points",
        "name_aa": "Any team total maximum consecutive points",
        "name_de": "Gesamtpunktzahl jedes Teams in Folge",
        "name_nl": "Willekeurig team totaal aantal maximum opeenvolgende punten",
        "name_pl": "Any team total maximum consecutive points",
        "name_ro": "Any team total maximum consecutive points",
        "name_ru": "Any team total maximum consecutive points",
        "name_tr": "Any team total maximum consecutive points",
        "name_se": "Any team total maximum consecutive points",
        "name_el": "Any team total maximum consecutive points",
        "name_es": "Any team total maximum consecutive points",
        "name_hr": "Any team total maximum consecutive points",
        "name_fr": "Nombre maximum de points marqués consécutivement pour l'une des équipes"
    },
    "id_260^prematch": {
        "name_en": "{$competitor1} total (incl. extra innings)",
        "name_aa": "{$competitor1} total (incl. extra innings)",
        "name_de": "{$competitor1} Total (inkl. Extra Innings)",
        "name_nl": "{$competitor1} totaal (extra innings inbegrepen)",
        "name_pl": "{$competitor1} total (incl. extra innings)",
        "name_ro": "{$competitor1} total (incl. extra innings)",
        "name_ru": "{$competitor1} total (incl. extra innings)",
        "name_tr": "{$competitor1} toplam (uzatmalar dahil)",
        "name_se": "{$competitor1} total (incl. extra innings)",
        "name_el": "{$competitor1} total (incl. extra innings)",
        "name_es": "{$competitor1} total (incl. extra innings)",
        "name_hr": "{$competitor1} total (uklj. produžetke)",
        "name_fr": "{$competitor1} total (inclus manches supplémentaires)"
    },
    "id_1040^prematch": {
        "name_en": "Total hits (incl. extra innings)",
        "name_aa": "Total hits (incl. extra innings)",
        "name_de": "Hits Gesamt (inkl. Extra Innings)",
        "name_nl": "Totaal aantal slagen (incl. extra innings)",
        "name_pl": "Total hits (incl. extra innings)",
        "name_ro": "Total hits (incl. extra innings)",
        "name_ru": "Total hits (incl. extra innings)",
        "name_tr": "Total hits (incl. extra innings)",
        "name_se": "Total hits (incl. extra innings)",
        "name_el": "Total hits (incl. extra innings)",
        "name_es": "Total hits (incl. extra innings)",
        "name_hr": "Total hits (incl. extra innings)",
        "name_fr": "Nombre de frappes (hit) (prolongations incluses)"
    },
    "id_1178^prematch": {
        "name_en": "1st half handicap & 1st half total",
        "name_aa": "1st half handicap & 1st half total",
        "name_de": "1. Hälfte Handicap & 1. Hälfte Total",
        "name_nl": "1st half handicap & 1st half total",
        "name_pl": "1st half handicap & 1st half total",
        "name_ro": "1st half handicap & 1st half total",
        "name_ru": "1st half handicap & 1st half total",
        "name_tr": "1st half handicap & 1st half total",
        "name_se": "1st half handicap & 1st half total",
        "name_el": "1st half handicap & 1st half total",
        "name_es": "1st half handicap & 1st half total",
        "name_hr": "1st half handicap & 1st half total",
        "name_fr": "1st half handicap & 1st half total"
    },
    "id_177^prematch": {
        "name_en": "1st half - total corners",
        "name_aa": "1st half - total corners",
        "name_de": "1. Halbzeit - Gesamtanzahl Ecken",
        "name_nl": "Eerste helft - totaal aantal hoekschoppen",
        "name_pl": "1st half - total corners",
        "name_ro": "1st half - total corners",
        "name_ru": "1st half - total corners",
        "name_tr": "İlk yarı - toplam korner sayısı",
        "name_se": "1st half - total corners",
        "name_el": "1st half - total corners",
        "name_es": "1st half - total corners",
        "name_hr": "1. Poluvrijeme - Ukupno udaraca iz kuta",
        "name_fr": "1ère mi-temps - total corners"
    },
    "id_849^prematch": {
        "name_en": "Any team winning margin (incl. overtime)",
        "name_aa": "Any team winning margin (incl. overtime)",
        "name_de": "Jedes Team Vorsprung (inkl. Verlängerung)",
        "name_nl": "Winstmarge (verlenging inbegrepen)",
        "name_pl": "Any team winning margin (incl. overtime)",
        "name_ro": "Any team winning margin (incl. overtime)",
        "name_ru": "Any team winning margin (incl. overtime)",
        "name_tr": "Herhangi bir takım kazanç marjı (uzatma dahil)",
        "name_se": "Any team winning margin (incl. overtime)",
        "name_el": "Any team winning margin (incl. overtime)",
        "name_es": "Any team winning margin (incl. overtime)",
        "name_hr": "Pobjednička margina bilo koji tim (uklj. produžetak)",
        "name_fr": "Marge de victoire d'une des équipes (inclus prolongations)"
    },
    "id_553^prematch": {
        "name_en": "2nd half - multigoals",
        "name_aa": "2nd half - multigoals",
        "name_de": "2. Halbzeit - Gesamtzahl der Tore im Spiel",
        "name_nl": "Tweede helft - multigoals",
        "name_pl": "2nd half - multigoals",
        "name_ro": "2nd half - multigoals",
        "name_ru": "2nd half - multigoals",
        "name_tr": "İkinci yarı - birden fazla gol",
        "name_se": "2nd half - multigoals",
        "name_el": "2nd half - multigoals",
        "name_es": "2nd half - multigoals",
        "name_hr": "2. poluvrijeme - multigolovi",
        "name_fr": "2ème mi-temps - multi buts"
    },
    "id_136^prematch": {
        "name_en": "Booking 1x2",
        "name_aa": "Booking 1x2",
        "name_de": "Karten 1x2",
        "name_nl": "Kaarten 1x2",
        "name_pl": "Booking 1x2",
        "name_ro": "Booking 1x2",
        "name_ru": "Booking 1x2",
        "name_tr": "Kartlar 1x2",
        "name_se": "Booking 1x2",
        "name_el": "Booking 1x2",
        "name_es": "Booking 1x2",
        "name_hr": "Kartoni 1x2",
        "name_fr": "Cartons 1x2"
    },
    "id_481^prematch": {
        "name_en": "Odd/even tries",
        "name_aa": "Odd/even tries",
        "name_de": "Gerade/ungerade Versuche",
        "name_nl": "Even/oneven aantal tries",
        "name_pl": "Odd/even tries",
        "name_ro": "Odd/even tries",
        "name_ru": "Odd/even tries",
        "name_tr": "tek/çift try",
        "name_se": "Odd/even tries",
        "name_el": "Odd/even tries",
        "name_es": "Odd/even tries",
        "name_hr": "Par/Nepar polaganja",
        "name_fr": "Pair/Impair essais"
    },
    "id_146^prematch": {
        "name_en": "Sending off",
        "name_aa": "Sending off",
        "name_de": "Platzverweise",
        "name_nl": "Uitsluitingen",
        "name_pl": "Sending off",
        "name_ro": "Sending off",
        "name_ru": "Sending off",
        "name_tr": "Oyundan atılma",
        "name_se": "Sending off",
        "name_el": "Sending off",
        "name_es": "Sending off",
        "name_hr": "Isključenje",
        "name_fr": "Expulsion"
    },
    "id_138^prematch": {
        "name_en": "Total booking points",
        "name_aa": "Total booking points",
        "name_de": "Gesamtanzahl Strafpunkte",
        "name_nl": "Totaal aantal booking points",
        "name_pl": "Total booking points",
        "name_ro": "Total booking points",
        "name_ru": "Total booking points",
        "name_tr": "Toplam kart ceza puanı",
        "name_se": "Total booking points",
        "name_el": "Total booking points",
        "name_es": "Total booking points",
        "name_hr": "Ukupno karton poena",
        "name_fr": "Total points cartons"
    }
}