import * as Actions from '../actions/actionTypes';

const initialState = {
    data: null,
    loginError: '',
    signupError: '',
    getOtpError: '',
    getWithOtpError:'',
    changePasswordSuccess: false,
    changePasswordError: '',
    cashbackData: null,
    depositError: null,
    oddType: 'decimal',
    openOtpForm: false,
    openOtpLogin:false,
    openOtpWithDraw:false,
    user_data :null,
    amount :'',
    moncashNumber:'',
    is_sponsorship_withdrawal:false,
    withdrawal_id:null,
    getForgetError:'',
    successDeposit: false ,
    successWithDraw : false,
    isTransactionLoading: true,
    transactionDetail:'',
    setTransactionStatusError:'',
    setUserDetails:null,
    setForgetError:''

};

const userReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.LOGIN_SUCCESS: {
            return {
                ...state,
                loginError: '',
            };
        }

        case Actions.LOGIN_ERROR: {
            return {
                ...state,
                loginError: action.loginError,
            };
        }

        case Actions.SIGNUP_ERROR: {
            return {
                ...state,
                signupError: action.signupError,
            };
        }

        case Actions.CHANGE_PASSWORD_SUCCESS: {
            return {
                ...state,
                changePasswordError: '',
                changePasswordSuccess: action.value,
            };
        }

        case Actions.CHANGE_PASSWORD_ERROR: {
            return {
                ...state,
                changePasswordSuccess: false,
                changePasswordError: action.error,
            };
        }

        case Actions.DEPOSIT_AMOUNT: {
            return {
                ...state,
                depositError: action.error,
            };
        }

        case Actions.RESET_DEPOSIT_AMOUNT: {
            return {
                ...state,
                qr_code: null,
                depositError: null,
            };
        }

        case Actions.SET_USER: {
            return {
                ...state,
                data: action.user,
            };
        }

        case Actions.LOGOUT_USER: {
            return {
                ...state,
                data: initialState.data,
            };
        }

        case Actions.SET_CASHBACK_DATA: {
            return {
                ...state,
                cashbackData: action.data,
            };
        }

        case Actions.SET_CASHBACK_SUCESS: {
            return {
                ...state,
                cashbackData: null,
            };
        }

        case Actions.SET_ODD_TYPE: {
            return {
                ...state,
                oddType: action.oddType,
            };
        }
         
        case Actions.OPEN_OTP_FORM_SIGNUP: {
            return {
                ...state,
                openOtpForm: action.data,
            }
        }
        case Actions.OPEN_OTP_FORM_LOGIN :{
            return {
                ...state,
               openOtpLogin:action.data
            }
        }
        case Actions.OPEN_OTP_FORM_WITHDRAW : {
            return {
                ...state,
               openOtpWithDraw:action.val,
            }
        }

        case Actions.GET_WITHDRAW_OTP_ERROR : {
            return {
                ...state,
                getWithOtpError:action.getWithOtpError,
            }
        }

        case Actions.USER_DATA:{
            return {
                ...state,
                user_data:action.data,
            }
        }
        case Actions.GET_OTP_ERROR:{
            return {
                ...state,
                getOtpError:action.getOtpError
            }
        }
        case Actions.SET_AMOUNT_MONCASHNUMBER:{
            return {
                ...state,
                amount:action.amount,
                moncashNumber: action.moncashNumber,
                is_sponsorship_withdrawal:action.is_sponsorship_withdrawal,
                withdrawal_id:action?.withdrawal_id ? action?.withdrawal_id:null

            }
        }
        case Actions.SUCCESS_WITHDRAW :{
            return {
                ...state,
               successWithDraw : action.val
            }
        }
        case Actions.SUCCESS_DEPOSIT :{
            return {
                ...state,
                successDeposit: action.val
            }
        }
        case Actions.SET_TRANSACTION_LOADING:{
            return {
                ...state,
                isTransactionLoading:action.val
            }
        }
         case Actions.SET_TRANSACTION_DETAIL:{
            return {
                ...state,
                transactionDetail:action.data
            }
        }
        case Actions.SET_TRANSACTIONS_STATUS_ERROR:{
            return {
                ...state,
                setTransactionStatusError:action.val
            }
        }
        case Actions.SET_USER_DETAILS: {
            return{
                ...state,
                setUserDetails:action.details
            }
        }
        case Actions.SET_FORGET_ERROR :{
             return{
                ...state,
                getForgetError:action.getForgetError
            }
        }
        default:
            return state;
    }
};

export default userReducer;
