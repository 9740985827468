import * as Actions from '../actions/actionTypes';
import { differenceInMinutes } from 'date-fns';

let sessionStats = sessionStorage.getItem('stats_data');
if (sessionStats) sessionStats = JSON.parse(sessionStats);

let sessionStatsData = null;
// keep data only if it is fetched 30 min before
if (sessionStats?.date && differenceInMinutes(new Date(), new Date(`${sessionStats.date}`)) < 30) sessionStatsData = sessionStats.data;

const initialState = {
    loading: false,
    language: localStorage.getItem('language') ? localStorage.getItem('language') : 'en',
    country: '',
    translation: null,
    statsData: sessionStatsData,
    isShowCasino: false,
    theme:'',
    sponsorList:[]
};

const generalReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.SET_LOADING: {
            return {
                ...state,
                loading: action.value,
            };
        }

        

        case Actions.SET_LANGUAGE: {
            return {
                ...state,
                language: action.value,
            };
        }

        case Actions.SET_SPONSORSLISTS: {
          return {
              ...state,
              sponsorList: action.data,
          };
      }

        case Actions.SET_COUNTRY_CODE: {
            return {
                ...state,
                country: action.countryCode,
            };
        }

        case Actions.SET_STATS_DATA: {
            return {
                ...state,
                statsData: action.data,
            };
        }
        case Actions.SET_TENET_STATUS: {
            return {
                ...state,
                isShowCasino: action.isShowCasino,
                //  isShowCasino: false,
            };

        }
        case Actions.SET_THEME: {
            return {
                ...state,
                theme: action.theme,
            };

        }

        default:
            return state;
    }
};

export default generalReducer;
