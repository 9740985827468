import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { format, differenceInMinutes } from 'date-fns';
import { apiConfig } from '../config';
import EventEmitter from '../helper/EventEmitter';
import Util from '../helper/Util';

const CancelToken = axios.CancelToken;
let cancel;

class jwtService extends EventEmitter {
    init() {
        this.setInterceptors();
        this.handleAuthentication();
    }

    setInterceptors = () => {
        axios.interceptors.response.use(
            (response) => {
                let { access_token } = this.getAccessToken();
                let { refresh_token } = this.getAccessToken();
                if (access_token) {
                    let last_request_date = sessionStorage.getItem('last_request_date');

                    if (last_request_date) {
                        let now = new Date();
                        let old_date = new Date(last_request_date);
                        // Increase the refresh interval from 5 min to 60 min

                        if (differenceInMinutes(now, old_date) >= 60) {
                            this.refreshToken(refresh_token);
                        }
                        sessionStorage.setItem('last_request_date', now);
                    } else {
                        let now = new Date();
                        sessionStorage.setItem('last_request_date', now);
                    }
                }

                return response;
            },
            (err) => {
                return new Promise((resolve, reject) => {
                    if (err.response && err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
                        // if you ever get an unauthorized response, logout the user
                        this.emit('onAutoLogout', 'Invalid access_token');
                        this.setSession(null);
                        Util.handleRepeatedLogin(err.response);
                    }
                    throw err;
                });
            },
        );
    };

    refreshToken = (refresh_token) => {
        return new Promise((resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + refresh_token;
            axios
                .post(apiConfig.routes.refreshToken)
                .then((response) => {
                    let token = response.data && response.data.access_token;
                    if (token) {
                        if (localStorage.getItem('jwt_access_token')) {
                            localStorage.setItem('jwt_access_token', token);
                        } else {
                            sessionStorage.setItem('jwt_access_token', token);
                        }
                        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
                        resolve();
                    } else {
                        reject(response.data.error);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    handleAuthentication = () => {
        let { access_token } = this.getAccessToken();
        if (!access_token) {
            this.emit('onNoAccessToken');
            return;
        }

        if (this.isAuthTokenValid(access_token)) {
            this.setSession(access_token);
            this.emit('onAutoLogin', true);
        } else {
            this.setSession(null);
            this.emit('onAutoLogout', 'access_token expired');
        }
    };

    signup = (userData, language) => {
        let {
          email,
          firstName,
          lastName, phone_number, password, confirm_password, currency,
            moncash, agent_code, country_code, referral_code
        } = userData;
        return new Promise((resolve, reject) => {
            axios
                .post(apiConfig.routes.signup, {
                    unique_id: process.env.REACT_APP_UNIQUE_ID,
                    email,
                    phone_number,
                    password,
                    confirm_password,
                    currency,
                    moncash,
                    agent_code,
                    country_code,
                    language,
                    referral_code,
                    firstName,
                    lastName
                })
                .then((response) => {
                    let user = response.data;
                    if (user) {
                        resolve(user);
                    } else {
                        reject(response.data.error);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    getOtp = (userData,phone_number, country_code, type, language) => {
        return new Promise((resolve, reject) => {
            axios.post(apiConfig.routes.getotp, {
                unique_id: process.env.REACT_APP_UNIQUE_ID,
                phone_number,
                country_code,
                type,
                language,
                ...userData
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
        })
    }

    getOtpWithDraw = (type, language, phone_number, country_code) => {
        return new Promise((resolve, reject) => {
            axios.post(apiConfig.routes.getotp, {
                unique_id: process.env.REACT_APP_UNIQUE_ID,
                type,
                language,
                phone_number,
                country_code
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
        })
    }

    verifyOtp = (otp, action, user_data, language) => {
        return new Promise((resolve, reject) => {
            axios.post(apiConfig.routes.verifyOtp, {
                unique_id: process.env.REACT_APP_UNIQUE_ID,
                otp,
                action,
                user_data,
                language
            })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }

    signInWithUsernameAndPassword = (username, password, rememberMe, language,isSpecialAgent) => {
        return new Promise((resolve, reject) => {
            axios
                .post(apiConfig.routes.login, {
                    username,
                    password,
                    unique_id: process.env.REACT_APP_UNIQUE_ID,
                    language,
                    is_special_agent:isSpecialAgent
                })
                .then((response) => {
                    let user = response.data;
                    if (user) {
                        this.setSession(user.access_token, user.refresh_token, rememberMe);
                        resolve(user);
                    } else {
                        reject(response.data.error);
                    }
                })
                .catch((error) => {
                    this.logout();
                    reject(error);
                });
        });
    };

    changeUserPassword = (oldPassword, newPassword, language) => {
        return new Promise((resolve, reject) => {
            axios
                .put(apiConfig.routes.changePassword, {
                    old_password: oldPassword,
                    new_password: newPassword,
                    language
                }, { params: { unique_id: process.env.REACT_APP_UNIQUE_ID } })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    deposit = (amount) => {
        return new Promise((resolve, reject) => {
            axios
                .post(`${apiConfig.routes.deposit}`, { amount },
                    { params: { unique_id: process.env.REACT_APP_UNIQUE_ID, } }
                )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    forgetPassword = (phone_number, new_password, language) => {
        return new Promise((resolve, reject) => {
            axios.post(apiConfig.routes.forgetPassword, {
                unique_id: process.env.REACT_APP_UNIQUE_ID,
                phone_number,
                new_password,
                language
            })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }

    withdraw = (params) => {
        return new Promise((resolve, reject) => {
            axios
                .post(`${apiConfig.routes.withdraw}`, { ...params },
                    { params: { unique_id: process.env.REACT_APP_UNIQUE_ID, } }
                )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    getUser = (balance_read, language) => {
        return new Promise((resolve, reject) => {
            axios
                .get(`${apiConfig.routes.user}?${balance_read ? '&balance_read=true' : ''}`, {
                    params: {
                        unique_id: process.env.REACT_APP_UNIQUE_ID,
                        timestamp: new Date().getTime(),
                        language,
                    },
                })
                .then((response) => {
                    let user = response.data;

                    if (user) {
                        resolve(user);
                    } else {
                        reject(response.data.error);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    setSession = (access_token, refresh_token, rememberMe) => {
        if (access_token) {
            if (rememberMe) {
                localStorage.setItem('refresh_token', refresh_token)
                localStorage.setItem('jwt_access_token', access_token);
            } else {
                sessionStorage.setItem('refresh_token', refresh_token)
                sessionStorage.setItem('jwt_access_token', access_token);
            }
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
        } else {
            localStorage.removeItem('jwt_access_token');
            localStorage.removeItem('refresh_token');
            sessionStorage.removeItem('jwt_access_token');
            sessionStorage.removeItem('refresh_token');
            sessionStorage.removeItem('last_request_date');
            delete axios.defaults.headers.common['Authorization'];
        }
    };

    logout = () => {
        this.setSession(null);
    };

    isAuthTokenValid = (access_token) => {
        if (!access_token) {
            return false;
        }
        const decoded = jwtDecode(access_token);
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
            console.warn('access token expired');
            return false;
        } else {
            return true;
        }
    };

    getAccessToken = () => {
        let access_token = window.localStorage.getItem('jwt_access_token')
            ? window.localStorage.getItem('jwt_access_token')
            : window.sessionStorage.getItem('jwt_access_token');

        let refresh_token = window.localStorage.getItem('refresh_token')
            ? window.localStorage.getItem('refresh_token')
            : window.sessionStorage.getItem('refresh_token');

        let rememberMeChecked = window.localStorage.getItem('jwt_access_token') ? true : false;

        return { refresh_token, access_token, rememberMeChecked };
    };
    getPrematches = (sportId) => {
        // const url = `https://fvbg10pe89.execute-api.us-east-1.amazonaws.com/prod/${sportId}`
        const url = `${process.env.REACT_APP_API_URL}/prematch_sport_data?sport_id=${sportId}`
        return new Promise((resolve, reject) => {
            axios
                .get(url, {
                })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    resolve(error);
                });
        });
    }
    createTempBetslip = (data) => {
      return new Promise((resolve, reject) => {
          axios
              .post(apiConfig.routes.createTempBetslip, data, {
                  params: {
                      unique_id: process.env.REACT_APP_UNIQUE_ID,
                  }
              })
              .then((response) => {
                  resolve(response.data);
              })
              .catch((error) => {
                  console.error(error);
                  reject(error);
              });
      });
  };

    getTempBetslip = (coupon_id, language) => {
      cancel && cancel('canceled');
      return new Promise((resolve, reject) => {
          axios
              .get(apiConfig.routes.getTempBetslip, {
                  params: {
                      coupon_id: coupon_id,
                      language: language,
                      unique_id: process.env.REACT_APP_UNIQUE_ID,
                  },
                  cancelToken: new CancelToken(function executor(c) {
                      cancel = c;
                  })
              })
              .then((response) => {
                  resolve(response.data);
              })
              .catch((error) => {
                  reject(error);
              });
      });
  }


    placeBet = (data, language) => {
        return new Promise((resolve, reject) => {
            axios
                .post(apiConfig.routes.placeBet, data, { params: { unique_id: process.env.REACT_APP_UNIQUE_ID, language } })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    placeLiveBet = (data, language) => {
        return new Promise((resolve, reject) => {
            axios
                .put(apiConfig.routes.placeLiveBet, data, { params: { unique_id: process.env.REACT_APP_UNIQUE_ID, language } })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    getBetLimits = (language) => {
        return new Promise((resolve, reject) => {
            axios
                .get(apiConfig.routes.getBetLimits, {
                    params: {
                        unique_id: process.env.REACT_APP_UNIQUE_ID,
                        language
                    },
                })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };


    getSponsorListService = ( params,language) => {
      cancel && cancel('canceled'); // if request is already pending cancel the earlier 
      return new Promise((resolve, reject) => {
          axios
              .get(apiConfig.routes.getSponsorList, {
                  params: {
                      unique_id: process.env.REACT_APP_UNIQUE_ID,
                      ...params,
                      language
                      
                  },
                  cancelToken: new CancelToken(function executor(c) {
                      cancel = c;
                  })
              })
              .then((response) => {
                  resolve(response.data);
              })
              .catch((error) => {
                  reject(error);
              });
      });
  };
    getTotalStackReportService = ( params,language) => {
      cancel && cancel('canceled'); // if request is already pending cancel the earlier 
      return new Promise((resolve, reject) => {
          axios
              .get(apiConfig.routes.getTotalStackReports, {
                  params: {
                      unique_id: process.env.REACT_APP_UNIQUE_ID,
                      ...params,
                      language
                      
                  },
                  cancelToken: new CancelToken(function executor(c) {
                      cancel = c;
                  })
              })
              .then((response) => {
                  resolve(response.data);
              })
              .catch((error) => {
                  reject(error);
              });
      });
  };
    getBetslips = (betslip_status, page, params, language) => {
        cancel && cancel('canceled'); // if request is already pending cancel the earlier 
        return new Promise((resolve, reject) => {
            axios
                .get(apiConfig.routes.getBetslips, {
                    params: {
                        bet_slip_status: betslip_status,
                        page: page,
                        unique_id: process.env.REACT_APP_UNIQUE_ID,
                        params,
                        language
                    },
                    cancelToken: new CancelToken(function executor(c) {
                        cancel = c;
                    })
                })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    getSingleBetslip = (betslip_id, language) => {
        return new Promise((resolve, reject) => {
            axios
                .get(apiConfig.routes.getSingleBetslip, {
                    params: {
                        betslip_id: betslip_id,
                        language: language,
                        unique_id: process.env.REACT_APP_UNIQUE_ID,
                    },
                })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    getLastBetslip = (betslip_id, language) => {
        return new Promise((resolve, reject) => {
            axios
                .get(apiConfig.routes.getLastBetslip, {
                    params: {
                        betslip_id: betslip_id,
                        language: language,
                        unique_id: process.env.REACT_APP_UNIQUE_ID,
                    },
                })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    getTransactions = (tab, params, page, language) => {
        let from_date = params.dateFrom !== null ? format(params.dateFrom, 'yyyy-MM-dd') : '';
        let to_date = params.dateTo !== null ? format(params.dateTo, 'yyyy-MM-dd') : '';
        let base_url = '';
        let timezone_offset;
        if (from_date) {
            timezone_offset = params.dateFrom.getTimezoneOffset() / (-60);
        }
        if (tab === 'sports book') {
            base_url = apiConfig.routes.getTransactions;
        }
        else if (tab === 'casino') {
            base_url = apiConfig.routes.getCasinoTransactions;
        }
        else if (tab === 'live casino') {
            base_url = apiConfig.routes.getLiveCasinoTransactions;
        }
        else if (tab === 'bonus') {
            base_url = apiConfig.routes.bonusTransactions;
        }

        return new Promise((resolve, reject) => {
            axios
                .get(base_url, {
                    params: {
                        from_date: from_date,
                        to_date: to_date,
                        activity_type: params.activityType === 'all' ? '' : params.activityType,
                        page: page,
                        timezone_offset,
                        unique_id: process.env.REACT_APP_UNIQUE_ID,
                        language
                    },
                })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    getCashoutData = (betslip_id, total_odds, stake_price) => {
        return new Promise((resolve, reject) => {
            axios
                .post(apiConfig.routes.getCashoutData, {
                    betslip_id: `${betslip_id}`,
                    stake_price: stake_price,
                }, { params: { unique_id: process.env.REACT_APP_UNIQUE_ID } },)
                .then(response => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    processCashout = (betslip_id, cashout_amount) => {
        return new Promise((resolve, reject) => {
            axios
                .post(apiConfig.routes.processCashout, {
                    betslip_id: betslip_id,
                    cashout_amount: cashout_amount,
                }, {
                    params: {
                        unique_id: process.env.REACT_APP_UNIQUE_ID,
                    }
                },)
                .then(response => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    getBetslipPayout = (betslip_id, language) => {
        return new Promise((resolve, reject) => {
            axios
                .post(apiConfig.routes.getBetslipPayout,
                    {
                        betslip_id: betslip_id,
                        language,
                    },
                    {
                        params: {
                            unique_id: process.env.REACT_APP_UNIQUE_ID,
                        }
                    },
                )
                .then(response => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    getLiveStreamData = () => {
        return new Promise((resolve, reject) => {
            axios.get(apiConfig.routes.getLiveStreamData)
                .then(response => {
                    //console.log(response);
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    getCashbackData = (userId) => {
        return new Promise((resolve, reject) => {
            axios.get(apiConfig.routes.cashback, {
                params: {
                    user_id: userId
                }
            })
                .then(response => {
                    // console.log(response);
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    setCashback = (userId) => {
        return new Promise((resolve, reject) => {
            axios.post(apiConfig.routes.cashback, {
                user_id: userId
            })
                .then(response => {
                    //console.log(response);
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    getTransactionStatus = (transactionId) => {
        return new Promise((resolve, reject) => {
            axios.get(apiConfig.routes.transactionStatus, {
                params: {
                    transactionId: transactionId
                }
            })
                .then(response => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    checkCancellationBetStatus = (coupon_id, language) => {
        return new Promise((resolve, reject) => {
            axios.get(apiConfig.routes.checkCancellationBetStatus, {
                params: {
                    coupon_id: coupon_id,
                    language: language,
                    unique_id: process.env.REACT_APP_UNIQUE_ID,
                }
            })
                .then(response => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    cancelBet = (coupon_id, language) => {
        return new Promise((resolve, reject) => {
            axios.post(apiConfig.routes.cancelBet, {
                coupon_id: coupon_id,
                language: language,
            }, {
                params: {
                    unique_id: process.env.REACT_APP_UNIQUE_ID,
                }
            }).then(response => {
                resolve(response.data);
            }).catch((error) => {
                reject(error);
            });
        });
    }
}


const instance = new jwtService();

export default instance;
