import Util from '../helper/Util';
import { Translate } from '../localization';
import { orderBy, remove } from 'lodash';


export const sportsIds = {
    football: 1,
    basketball: 2,
    baseball: 3,
    iceHockey: 4,
    tennis: 5,
    handball: 6,
    boxing: 10,
    americanFootball: 16,
    tableTennis: 20,
    volleyball: 23
};

export const locationSortingOrder = ['Germany', 'England', 'Spain', 'Italy', 'France', 'Turkey', 'Portugal', 'Netherlands', 'Austria', 'Croatia', 'Poland', 'Greece', 'International'];
// Order of this constant is of Home Page Leagues
export const leagueSortingOrder = [99008110, 7, 679, 35, 8, 52, 17, 37, 23, 132, 207, 209, 34, 182, 224];

export const locationSortingOrderResults = {
    [sportsIds.football]: ['Germany', 'England', 'Spain', 'Italy', 'France', 'Turkey', 'Portugal', 'Netherlands', 'Austria', 'Croatia', 'Poland', 'Greece', 'International'],
    [sportsIds.basketball]: ['United States', 'International', 'Spain', 'Italy', 'Germany', 'Greece', 'Australia',
        'Turkey', 'Russia', 'Austria', 'Israel', 'Poland', 'South Korea', 'Japan', 'Bahrain', 'Qatar'],
    [sportsIds.baseball]: [83, 4, 248, 143, 161, 192, 202, 79, 147, 22, 2, 135, 192, 22, 78, 142, 126],
    [sportsIds.iceHockey]: [83, 4, 248, 143, 161, 192, 202, 79, 147, 22, 2, 135, 192, 22, 78, 142, 126],
    [sportsIds.tennis]: ['England', 'United States', 'Australia', 'Switzerland', 'France', 'Germany', 'Spain', 'New Zealand', 'Argentina'],
    [sportsIds.volleyball]: [52, 4, 2, 6, 5, 13, 16, 17, 21, 41, 42, 43, 44, 62, 63, 70, 72, 73, 74, 75, 76, 90, 113, 164, 175, 202, 203, 204, 205, 206, 286, 299, 337,
        338, 440, 834, 909, 910, 285],
    [sportsIds.boxing]: [1, 52, 2, 7, 29, 88, 662],
};


export const homePageLeagues = [
    // {name_en: 'World Cup', league_id: 16, is_favorite: false, sport_id: 1},
    {
        name_en: 'UEFA Champions League',
        name_tr: 'UEFA Şampiyonlar Ligi',
        name_de: 'UEFA Champions League',
        name_ru: 'Лига Чемпионов УЕФА',
        name_nl: 'UEFA Champions League',
        name_aa: 'دوري الابطال',
        name_el: 'Tσάμπιον Λιγκ',
        name_pl: 'UEFA Liga Mistrzów',
        name_se: 'Champions League',
        name_ro: 'Liga Campionilor UEFA',
        name_es: 'Liga Campeones UEFA',
        name_hr: 'UEFA Liga Prvaka',
        league_id: 7, is_favorite: false, sport_id: 1
    },
    {
        name_en: 'UEFA Europa League',
        name_tr: 'UEFA Avrupa Ligi',
        name_de: 'UEFA Europa League',
        name_ru: 'Лига Европы УЕФА',
        name_nl: 'UEFA Europa League',
        name_aa: 'الدوري الأوروبي الاتحاد الاوروبي',
        name_el: 'ΟΥΕΦΑ Γιουρόπα Λιγκ',
        name_pl: 'UEFA Liga Europejska',
        name_se: 'Europa League',
        name_ro: 'UEFA Europa League',
        name_es: 'Liga Europa de la UEFA',
        name_hr: 'UEFA Europska Liga',
        league_id: 679, is_favorite: false, sport_id: 1
    },
    {
        name_en: 'Bundesliga',
        name_tr: 'Bundesliga',
        name_de: 'Bundesliga',
        name_ru: 'Бундеслига',
        name_nl: 'Bundesliga',
        name_aa: 'الدوري الالماني',
        name_el: 'Μπουντεσλίγκα',
        name_pl: 'Bundesliga',
        name_se: 'Bundesliga',
        name_ro: 'Bundesliga',
        name_es: 'Bundesliga',
        name_hr: 'Bundesliga',
        league_id: 35, is_favorite: false, sport_id: 1
    },
    {
        name_en: 'LaLiga',
        name_tr: 'LaLiga',
        name_de: 'LaLiga',
        name_ru: 'Ла Лига',
        name_nl: 'LaLiga',
        name_aa: 'الدوري الاسباني',
        name_el: '1η Κατηγορία',
        name_pl: 'LaLiga',
        name_se: 'LaLiga',
        name_ro: 'LaLiga',
        name_es: 'LaLiga',
        name_hr: 'LaLiga',
        league_id: 8, is_favorite: false, sport_id: 1
    },
    {
        name_en: 'Super Lig',
        name_tr: 'Süper Lig',
        name_de: 'Süper Lig',
        name_ru: 'Суперлига',
        name_nl: 'Süper Lig',
        name_aa: 'تركسل في الدوري الممتاز',
        name_el: 'Σούπερ Λιγκ',
        name_pl: 'Super Liga',
        name_se: 'Super Lig',
        name_ro: 'Super Liga',
        name_es: 'Superliga',
        name_hr: 'Super Liga',
        league_id: 52, is_favorite: false, sport_id: 1
    },
    {
        name_en: 'Premier League',
        name_tr: 'Premier Lig',
        name_de: 'Premier League',
        name_ru: 'Премьер-лига',
        name_nl: 'Premier League',
        name_aa: 'الدوري الممتاز الإنجليزي',
        name_el: 'Πρέμιερ Λιγκ',
        name_pl: 'Premier League',
        name_se: 'Premier League',
        name_ro: 'Premier League',
        name_es: 'Premier League',
        name_hr: 'Premijer liga',
        league_id: 17, is_favorite: false, sport_id: 1
    },
    {
        name_en: 'Eredivisie',
        name_tr: 'Eredivisie',
        name_de: 'Eredivisie',
        name_ru: 'Эредивизи',
        name_nl: 'Eredivisie',
        name_aa: 'الدوري الهولندي',
        name_el: '1η Κατηγορία',
        name_pl: 'Eredivisie',
        name_se: 'Eredivisie',
        name_ro: 'Eredivisie',
        name_es: 'División de Honor',
        name_hr: 'Eredivisie',
        league_id: 37, is_favorite: false, sport_id: 1
    },
    {
        name_en: 'Serie A',
        name_tr: 'Serie A',
        name_de: 'Serie A',
        name_ru: 'Серия А',
        name_nl: 'Serie A',
        name_aa: 'الدوري الايطالي',
        name_el: '1η Κατηγορία',
        name_pl: 'Serie A',
        name_se: 'Serie A',
        name_ro: 'Seria A',
        name_es: 'Serie A',
        name_hr: 'Serie A',
        league_id: 23, is_favorite: false, sport_id: 1
    },
    {
        name_en: 'NBA',
        name_tr: 'NBA',
        name_de: 'NBA',
        name_ru: 'НБА',
        name_nl: 'NBA',
        name_aa: 'الدوري الاميركي للمحترفين',
        name_el: 'ΝΒΑ',
        name_pl: 'NBA',
        name_se: 'NBA',
        name_ro: 'NBA',
        name_es: 'NBA',
        name_hr: 'NBA',
        league_id: 132, is_favorite: false, sport_id: 2
    },
    { name_en: 'UFC', league_id: 14896, is_favorite: false, sport_id: 315 },// didn't get in Betrader sheet
    {
        name_en: 'League One',
        name_tr: 'Lig 1',
        name_de: 'League One',
        name_ru: 'Первая лига',
        name_nl: 'League One',
        name_aa: 'دوري الدرجة الأولى',
        name_el: '1η Κατηγορία',
        name_pl: '2. Dywizja',
        name_se: 'League One',
        name_ro: 'Liga 1',
        name_es: 'League One',
        name_hr: 'Liga 1',
        league_id: 207, is_favorite: false, sport_id: 1
    },
    {
        name_en: 'League Two',
        name_tr: 'Lig 2',
        name_de: 'League Two',
        name_ru: 'Вторая лига',
        name_nl: 'League Two',
        name_aa: 'دوري الدرجة الثانية',
        name_el: '2η Κατηγορία',
        name_pl: '3. Dywizja',
        name_se: 'League Two',
        name_ro: 'Liga 2',
        name_es: 'League Two',
        name_hr: 'Liga 2',
        league_id: 209, is_favorite: false, sport_id: 1
    },
    {
        name_en: 'Ligue 1',
        name_tr: 'Ligue 1',
        name_de: 'Ligue 1',
        name_ru: 'Лига 1',
        name_nl: 'Ligue 1',
        name_aa: 'الدوري الفرنسي',
        name_el: '1η Κατηγορία',
        name_pl: 'Ligue 1',
        name_se: 'Ligue 1',
        name_ro: 'Liga 1',
        name_es: 'Liga 1',
        name_hr: 'Liga 1',
        league_id: 34, is_favorite: false, sport_id: 1
    },
    {
        name_en: 'Ligue 2',
        name_tr: 'Lig 2',
        name_de: 'Ligue 2',
        name_ru: 'Лига 2',
        name_nl: 'Ligue 2',
        name_aa: 'الدوري2',
        name_el: '2η Κατηγορία',
        name_pl: 'Ligue 2',
        name_se: 'Ligue 2',
        name_ro: 'Liga 2',
        name_es: 'Liga 2',
        name_hr: 'Liga 2',
        league_id: 182, is_favorite: false, sport_id: 1
    },
    { name_en: '2. Liga', league_id: 224, is_favorite: false, sport_id: 1 },
];

export const staticPrematchSports = () => {
    return {
        [sportsIds.football]: { sport_id: sportsIds.football, Id:sportsIds.football, name: Translate.football, icon_name: 'Football', Count: 0, Locations: [], priority: 1, Statistics:true },
        [sportsIds.basketball]: { sport_id: sportsIds.basketball, Id: sportsIds.basketball, name: Translate.basketball, icon_name: 'Basketball', Count: 0, Locations: [], Statistics:true },
        [sportsIds.iceHockey]: { sport_id: sportsIds.iceHockey, Id: sportsIds.iceHockey, name: Translate.iceHockey, icon_name: 'Ice Hockey', Count: 0, Locations: [], Statistics:true },
        [sportsIds.tennis]: { sport_id: sportsIds.tennis, Id: sportsIds.tennis, name: Translate.tennis, icon_name: 'Tennis', Count: 0, Locations: [], Statistics:true },
        [sportsIds.handball]: { sport_id: sportsIds.handball, Id: sportsIds.handball, name: Translate.handball, icon_name: 'Handball', Count: 0, Locations: [], Statistics:false },
        [sportsIds.americanFootball]: { sport_id: sportsIds.americanFootball, Id: sportsIds.americanFootball, name: Translate.americanFootball, icon_name: 'AmericanFootball', Count: 0, Locations: [], Statistics: false },
    };
};

export const liveEventsPeriods = {
    'not_started': { Id: 0, label: 'NSY' },
    '1p': { Id: 6, label: 'P1' },
    '2p': { Id: 7, label: 'P2' },
    '3p': { Id: 8, label: 'P3' },
    '1set': { Id: 8, label: 'SET 1' },
    '2set': { Id: 9, label: 'SET 2' },
    '3set': { Id: 10, label: 'SET 3' },
    '4set': { Id: 11, label: 'SET 4' },
    '5set': { Id: 12, label: 'SET 5' },
    '1q': { Id: 13, label: 'Q1' },
    '2q': { Id: 14, label: 'Q2' },
    '3q': { Id: 15, label: 'Q3' },
    '4q': { Id: 16, label: 'Q4' },
    'paused': { Id: 31, label: 'HT' },
    'awaiting_ot': { Id: 32, label: 'AWT OT' },
    'ot_ht': { Id: 33, label: 'OT HT' },
    'awaiting_pen': { Id: 34, label: 'AWT PEN' },
    'ot': { Id: 40, label: 'OT' },
    '1p_ot': { Id: 41, label: '1P OT' },
    '2p_ot': { Id: 42, label: '2P OT' },
    'pen': { Id: 50, label: 'PEN' },
    'delayed': { Id: 61, label: 'DEL' },
    'interrupted': { Id: 80, label: 'INTR' },
    'abandoned': { Id: 90, label: 'ABD' },
    'ended': { Id: 100, label: 'END' },
    'after_ot ': { Id: 110, label: 'AFT OT' },
    'pause1': { Id: 301, label: 'Brk 1' },
    'pause2': { Id: 302, label: 'Brk 2' },
    'pause3': { Id: 303, label: 'Brk 3' },
    'pause4': { Id: 304, label: 'Brk 4' },
};

export const sortedLocations = (locationArray) => {
    const sortingOrder = locationSortingOrder;
    let preSortedList = [];
    for (let s in sortingOrder) {
        let elem = remove(locationArray, l => {
            if (l) return l.name_en === sortingOrder[s];
        });
        if (elem.length > 0) preSortedList.push(elem[0]);
    }
    locationArray = locationArray.filter(loc => loc.name_en);
    const sortedList = locationArray.sort((a, b) => Util.compareStrings(a.name_en, b.name_en));
    return preSortedList.concat(sortedList);
};

export const sortedLeague = (events) => {
    const sortingOrderLeague = leagueSortingOrder;
    let preSortedListByLeague = [];
    for (let s in sortingOrderLeague) {
        let elem = remove(events, (l) => {
            if (l) return l.league_id === sortingOrderLeague[s];
        });
        if (elem.length > 0) {
            for (let i in elem) preSortedListByLeague.push(elem[i]);
        }
    }

    preSortedListByLeague = preSortedListByLeague.concat(events.sort((a, b) => Util.compareStrings(a.league.name_en, b.league.name_en)));

    return preSortedListByLeague;
};

export const sortedLocationsResults = (locationArray, sportId) => {
    let toCompare = 'name_en';
    const sortingOrder = locationSortingOrderResults;
    let preSortedList = [];
    for (let s in sortingOrder[sportId]) {
        let elem = remove(locationArray, l => {
            if (l) return l[toCompare] == sortingOrder[sportId][s];
        });
        if (elem.length > 0) preSortedList.push(elem[0]);
    }
    return preSortedList.concat(locationArray.sort((a, b) => Util.compareStrings(a.name_en, b.name_en)));
};

// This function sort the event based on following:
// Home page league + Locations filter + rest match

export const sortLiveEvents = (events) => {
    const sortingOrderLeague = leagueSortingOrder;
    const sortingOrderLocation = locationSortingOrder;
    let preSortedListByLeague = [];
    let preSortedListByLocation = [];
    for (let s in sortingOrderLeague) {
        let elem = remove(events, (l) => {
            if (l) return l.league.Id === sortingOrderLeague[s];
        });
        if (elem.length > 0) {
            for (let i in elem) preSortedListByLeague.push(elem[i]);
        }
    }

    for (let s in sortingOrderLocation) {
        let elem = remove(events, (l) => {
            if (l) return l.location.name_en === sortingOrderLocation[s];
        });
        if (elem.length > 0) {
            for (let i in elem) preSortedListByLocation.push(elem[i]);
        }
    }

    return preSortedListByLeague.concat(preSortedListByLocation).concat(events);
};

export const staticDisableSports = () => {
    return {
        231: { Id: 231, sport_id: 231, disable: true, Name: 'Volleyball', name: Translate.volleyball, icon_name: 'Volleyball', Count: 0, Locations: [] },
        232: { Id: 232, sport_id: 232, disable: true, Name: 'Baseball', name: Translate.baseball, icon_name: 'Baseball', Count: 0, Locations: [] },
        233: { Id: 233, sport_id: 233, disable: true, Name: 'Rugby', name: Translate.rugby, icon_name: 'rugby', Count: 0, Locations: [] },
        // 234: { Id: 234, sport_id: 234, disable: true, Name: Translate.football, name: Translate.football, icon_name: 'Volleyball', Count: 0, Locations: [] },
        235: { Id: 235, sport_id: 235, disable: true, Name: 'Boxing', name: Translate.boxing, icon_name: 'Boxing', Count: 0, Locations: [] },
        236: { Id: 236, sport_id: 236, disable: true, Name: 'MMA', name: Translate.MMA, icon_name: 'MMA', Count: 0, Locations: [] },
        237: { Id: 237, sport_id: 237, disable: true, Name: 'Table Tennis', name: Translate.tableTennis, icon_name: 'TableTennis', Count: 0, Locations: [] },
        238: { Id: 238, sport_id: 238, disable: true, Name: 'Darts', name: Translate.darts, icon_name: 'darts', Count: 0, Locations: [] },
        239: { Id: 239, sport_id: 239, disable: true, Name: 'Formula1', name: Translate.formula1, icon_name: 'formula1', Count: 0, Locations: [] },
        240: { Id: 240, sport_id: 240, disable: true, Name: 'FormulaE', name: Translate.formulaE, icon_name: 'formulaE', Count: 0, Locations: [] },
        241: { Id: 241, sport_id: 241, disable: true, Name: 'Special', name: Translate.special, icon_name: 'special', Count: 0, Locations: [] },
    };
};