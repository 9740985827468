export const STATIC_OUTCOME = {
    "203^prematch": {
        "name_en": "1:7",
        "name_aa": "1:7",
        "name_de": "1:7",
        "name_nl": "1:7",
        "name_pl": "1:7",
        "name_ro": "1:7",
        "name_ru": "1:7",
        "name_tr": "1:7",
        "name_se": "1:7",
        "name_el": "1:7",
        "name_es": "1:7",
        "name_hr": "1:7"
    },
    "348^prematch": {
        "name_en": "0:1 0:1",
        "name_aa": "0:1 0:1",
        "name_de": "0:1 0:1",
        "name_nl": "0:1 0:1",
        "name_pl": "0:1 0:1",
        "name_ro": "0:1 0:1",
        "name_ru": "0:1 0:1",
        "name_tr": "0:1 0:1",
        "name_se": "0:1 0:1",
        "name_el": "0:1 0:1",
        "name_es": "0:1 0:1",
        "name_hr": "0:1 0:1"
    },
    "1484^prematch": {
        "name_en": "3:1",
        "name_aa": "3:1",
        "name_de": "3:1",
        "name_nl": "3:1",
        "name_pl": "3:1",
        "name_ro": "3:1",
        "name_ru": "3:1",
        "name_tr": "3:1",
        "name_se": "3:1",
        "name_el": "3:1",
        "name_es": "3:1",
        "name_hr": "3:1"
    },
    "1191^prematch": {
        "name_en": "6:1",
        "name_aa": "6:1",
        "name_de": "6:1",
        "name_nl": "6:1",
        "name_pl": "6:1",
        "name_ro": "6:1",
        "name_ru": "6:1",
        "name_tr": "6:1",
        "name_se": "6:1",
        "name_el": "6:1",
        "name_es": "6:1",
        "name_hr": "6:1"
    },
    "1122^prematch": {
        "name_en": "2-3",
        "name_aa": "2-3",
        "name_de": "2-3",
        "name_nl": "2-3",
        "name_pl": "2-3",
        "name_ro": "2-3",
        "name_ru": "2-3",
        "name_tr": "2-3",
        "name_se": "2-3",
        "name_el": "2-3",
        "name_es": "2-3",
        "name_hr": "2-3"
    },
    "56^prematch": {
        "name_en": "3",
        "name_aa": "3",
        "name_de": "3",
        "name_nl": "3",
        "name_pl": "3",
        "name_ro": "3",
        "name_ru": "3",
        "name_tr": "3",
        "name_se": "3",
        "name_el": "3",
        "name_es": "3",
        "name_hr": "3"
    },
    "70990000^prematch": {
        "name_en": "Neither",
        "name_aa": "Neither",
        "name_de": "Neither",
        "name_nl": "Neither",
        "name_pl": "Neither",
        "name_ro": "Neither",
        "name_ru": "Neither",
        "name_tr": "Neither",
        "name_se": "Neither",
        "name_el": "Neither",
        "name_es": "Neither",
        "name_hr": "Neither"
    },
    "871^prematch": {
        "name_en": "7:6",
        "name_aa": "7:6",
        "name_de": "7:6",
        "name_nl": "7:6",
        "name_pl": "7:6",
        "name_ro": "7:6",
        "name_ru": "7:6",
        "name_tr": "7:6",
        "name_se": "7:6",
        "name_el": "7:6",
        "name_es": "7:6",
        "name_hr": "7:6"
    },
    "1845^prematch": {
        "name_en": "{$competitor1}/{$competitor1}  over {total}",
        "name_aa": "{$competitor1} / {$competitor1} و أكثر من{total}",
        "name_de": "{$competitor1}/{$competitor1}  über {total}",
        "name_nl": "{$competitor1}/{$competitor1}  boven {total}",
        "name_pl": "{$competitor1}/{$competitor1}  ponad {total}",
        "name_ro": "{$competitor1}/{$competitor1}  peste {total}",
        "name_ru": "{$competitor1}/{$competitor1} и больше {total}",
        "name_tr": "{$competitor1}/{$competitor1}  {total} üstü",
        "name_se": "{$competitor1}/{$competitor1}  över {total}",
        "name_el": "{$competitor1}/{$competitor1}  over {total}",
        "name_es": "{$competitor1}/{$competitor1}  más de {total}",
        "name_hr": "{$competitor1}/{$competitor1} i više {total}"
    },
    "56190000^prematch": {
        "name_en": "0-4",
        "name_aa": "0-4",
        "name_de": "0-4",
        "name_nl": "0-4",
        "name_pl": "0-4",
        "name_ro": "0-4",
        "name_ru": "0-4",
        "name_tr": "0-4",
        "name_se": "0-4",
        "name_el": "0-4",
        "name_es": "0-4",
        "name_hr": "0-4"
    },
    "45^prematch": {
        "name_en": "6",
        "name_aa": "6",
        "name_de": "6",
        "name_nl": "6",
        "name_pl": "6",
        "name_ro": "6",
        "name_ru": "6",
        "name_tr": "6",
        "name_se": "6",
        "name_el": "6",
        "name_es": "6",
        "name_hr": "6"
    },
    "134^prematch": {
        "name_en": "{$competitor2} by 1-7",
        "name_aa": "{$competitor2} by 1-7",
        "name_de": "{$competitor2} by 1-7",
        "name_nl": "{$competitor2} by 1-7",
        "name_pl": "{$competitor2} by 1-7",
        "name_ro": "{$competitor2} by 1-7",
        "name_ru": "{$competitor2} by 1-7",
        "name_tr": "{$competitor2} by 1-7",
        "name_se": "{$competitor2} by 1-7",
        "name_el": "{$competitor2} by 1-7",
        "name_es": "{$competitor2} by 1-7",
        "name_hr": "{$competitor2} by 1-7"
    },
    "364^prematch": {
        "name_en": "0:2 0:3",
        "name_aa": "0:2 0:3",
        "name_de": "0:2 0:3",
        "name_nl": "0:2 0:3",
        "name_pl": "0:2 0:3",
        "name_ro": "0:2 0:3",
        "name_ru": "0:2 0:3",
        "name_tr": "0:2 0:3",
        "name_se": "0:2 0:3",
        "name_el": "0:2 0:3",
        "name_es": "0:2 0:3",
        "name_hr": "0:2 0:3"
    },
    "1507^prematch": {
        "name_en": "2:5",
        "name_aa": "2:5",
        "name_de": "2:5",
        "name_nl": "2:5",
        "name_pl": "2:5",
        "name_ro": "2:5",
        "name_ru": "2:5",
        "name_tr": "2:5",
        "name_se": "2:5",
        "name_el": "2:5",
        "name_es": "2:5",
        "name_hr": "2:5"
    },
    "1478^prematch": {
        "name_en": "3:0",
        "name_aa": "3:0",
        "name_de": "3:0",
        "name_nl": "3:0",
        "name_pl": "3:0",
        "name_ro": "3:0",
        "name_ru": "3:0",
        "name_tr": "3:0",
        "name_se": "3:0",
        "name_el": "3:0",
        "name_es": "3:0",
        "name_hr": "3:0"
    },
    "868^prematch": {
        "name_en": "6:3",
        "name_aa": "6:3",
        "name_de": "6:3",
        "name_nl": "6:3",
        "name_pl": "6:3",
        "name_ro": "6:3",
        "name_ru": "6:3",
        "name_tr": "6:3",
        "name_se": "6:3",
        "name_el": "6:3",
        "name_es": "6:3",
        "name_hr": "6:3"
    },
    "350^prematch": {
        "name_en": "0:1 0:2",
        "name_aa": "0:1 0:2",
        "name_de": "0:1 0:2",
        "name_nl": "0:1 0:2",
        "name_pl": "0:1 0:2",
        "name_ro": "0:1 0:2",
        "name_ru": "0:1 0:2",
        "name_tr": "0:1 0:2",
        "name_se": "0:1 0:2",
        "name_el": "0:1 0:2",
        "name_es": "0:1 0:2",
        "name_hr": "0:1 0:2"
    },
    "1506^prematch": {
        "name_en": "1:5",
        "name_aa": "1:5",
        "name_de": "1:5",
        "name_nl": "1:5",
        "name_pl": "1:5",
        "name_ro": "1:5",
        "name_ru": "1:5",
        "name_tr": "1:5",
        "name_se": "1:5",
        "name_el": "1:5",
        "name_es": "1:5",
        "name_hr": "1:5"
    },
    "810^prematch": {
        "name_en": "yes/yes",
        "name_aa": "نعم / نعم",
        "name_de": "ja/ja",
        "name_nl": "ja/ja",
        "name_pl": "tak/tak",
        "name_ro": "da/da",
        "name_ru": "да/да",
        "name_tr": "var/var",
        "name_se": "ja/ja",
        "name_el": "ναι/ναι",
        "name_es": "sí/sí",
        "name_hr": "da/da"
    },
    "1716^prematch": {
        "name_en": "no goal",
        "name_aa": "لا هدف",
        "name_de": "kein Tor",
        "name_nl": "geen doelpunt",
        "name_pl": "bez gola",
        "name_ro": "niciun gol",
        "name_ru": "гола не будет",
        "name_tr": "gol yok",
        "name_se": "inga mål",
        "name_el": "κανένα γκολ",
        "name_es": "sin gol",
        "name_hr": "nema gola"
    },
    "1077^prematch": {
        "name_en": "170-189",
        "name_aa": "170-189",
        "name_de": "170-189",
        "name_nl": "170-189",
        "name_pl": "170-189",
        "name_ro": "170-189",
        "name_ru": "170-189",
        "name_tr": "170-189",
        "name_se": "170-189",
        "name_el": "170-189",
        "name_es": "170-189",
        "name_hr": "170-189"
    },
    "26^prematch": {
        "name_en": "5:4",
        "name_aa": "5:4",
        "name_de": "5:4",
        "name_nl": "5:4",
        "name_pl": "5:4",
        "name_ro": "5:4",
        "name_ru": "5:4",
        "name_tr": "5:4",
        "name_se": "5:4",
        "name_el": "5:4",
        "name_es": "5:4",
        "name_hr": "5:4"
    },
    "1337^prematch": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1"
    },
    "630^prematch": {
        "name_en": "{$competitor1} by 1",
        "name_aa": "{$competitor1} في 1",
        "name_de": "{$competitor1} mit 1",
        "name_nl": "{$competitor1} met 1",
        "name_pl": "{$competitor1} 1-ym",
        "name_ro": "{$competitor1} cu 1",
        "name_ru": "{$competitor1} на 1",
        "name_tr": "{$competitor1} 1 farkla",
        "name_se": "{$competitor1} med 1",
        "name_el": "{$competitor1} με 1",
        "name_es": "{$competitor1} por 1",
        "name_hr": "{$competitor1} za 1"
    },
    "1339^prematch": {
        "name_en": "3",
        "name_aa": "3",
        "name_de": "3",
        "name_nl": "3",
        "name_pl": "3",
        "name_ro": "3",
        "name_ru": "3",
        "name_tr": "3",
        "name_se": "3",
        "name_el": "3",
        "name_es": "3",
        "name_hr": "3"
    },
    "307^prematch": {
        "name_en": "1:11",
        "name_aa": "1:11",
        "name_de": "1:11",
        "name_nl": "1:11",
        "name_pl": "1:11",
        "name_ro": "1:11",
        "name_ru": "1:11",
        "name_tr": "1:11",
        "name_se": "1:11",
        "name_el": "1:11",
        "name_es": "1:11",
        "name_hr": "1:11"
    },
    "412^prematch": {
        "name_en": "3:0 3:0",
        "name_aa": "3:0 3:0",
        "name_de": "3:0 3:0",
        "name_nl": "3:0 3:0",
        "name_pl": "3:0 3:0",
        "name_ro": "3:0 3:0",
        "name_ru": "3:0 3:0",
        "name_tr": "3:0 3:0",
        "name_se": "3:0 3:0",
        "name_el": "3:0 3:0",
        "name_es": "3:0 3:0",
        "name_hr": "3:0 3:0"
    },
    "592^prematch": {
        "name_en": "61-75",
        "name_aa": "61-75",
        "name_de": "61-75",
        "name_nl": "61-75",
        "name_pl": "61-75",
        "name_ro": "61-75",
        "name_ru": "61-75",
        "name_tr": "61-75",
        "name_se": "61-75",
        "name_el": "61-75",
        "name_es": "61-75",
        "name_hr": "61-75"
    },
    "176^prematch": {
        "name_en": "231-240",
        "name_aa": "231-240",
        "name_de": "231-240",
        "name_nl": "231-240",
        "name_pl": "231-240",
        "name_ro": "231-240",
        "name_ru": "231-240",
        "name_tr": "231-240",
        "name_se": "231-240",
        "name_el": "231-240",
        "name_es": "231-240",
        "name_hr": "231-240"
    },
    "1843^prematch": {
        "name_en": "{$competitor2}/draw  under {total}",
        "name_aa": "{$competitor2} / رسم و تحت{total}",
        "name_de": "{$competitor2}/unentschieden  unter {total}",
        "name_nl": "{$competitor2}/gelijkspel  onder {total}",
        "name_pl": "{$competitor2}/remis  poniżej {total}",
        "name_ro": "{$competitor2}/egal  sub {total}",
        "name_ru": "{$competitor2}/ничья и меньше {total}",
        "name_tr": "{$competitor2}/beraberlik  {total} altı",
        "name_se": "{$competitor2}/oavgjort  under {total}",
        "name_el": "{$competitor2}/ισοπαλία  under {total}",
        "name_es": "{$competitor2}/empate  menos de {total}",
        "name_hr": "{$competitor2}/neodlučeno i manje {total}"
    },
    "798^prematch": {
        "name_en": "draw  under {total}",
        "name_aa": "ارسم و تحت {total}",
        "name_de": "unentschieden  unter {total}",
        "name_nl": "gelijkspel  onder {total}",
        "name_pl": "remis  poniżej {total}",
        "name_ro": "egal  sub {total}",
        "name_ru": "ничья  меньше {total}",
        "name_tr": "beraberlik  {total} altı",
        "name_se": "oavgjort  under {total}",
        "name_el": "ισοπαλία και under {total}",
        "name_es": "empate y menos de {total}",
        "name_hr": "neodlučeno i manje od {total}"
    },
    "244^prematch": {
        "name_en": "4:8",
        "name_aa": "4:8",
        "name_de": "4:8",
        "name_nl": "4:8",
        "name_pl": "4:8",
        "name_ro": "4:8",
        "name_ru": "4:8",
        "name_tr": "4:8",
        "name_se": "4:8",
        "name_el": "4:8",
        "name_es": "4:8",
        "name_hr": "4:8"
    },
    "792^prematch": {
        "name_en": "both teams",
        "name_aa": "كلا الفريقين",
        "name_de": "beide Mannschaften",
        "name_nl": "beide teams",
        "name_pl": "oba zespoły",
        "name_ro": "ambele echipe",
        "name_ru": "обе команды",
        "name_tr": "her iki takım da",
        "name_se": "båda lagen",
        "name_el": "και οι 2 ομάδες",
        "name_es": "ambos equipos",
        "name_hr": "oba tima"
    },
    "48^prematch": {
        "name_en": "0",
        "name_aa": "0",
        "name_de": "0",
        "name_nl": "0",
        "name_pl": "0",
        "name_ro": "0",
        "name_ru": "0",
        "name_tr": "0",
        "name_se": "0",
        "name_el": "0",
        "name_es": "0",
        "name_hr": "0"
    },
    "1081^prematch": {
        "name_en": "15-18",
        "name_aa": "15-18",
        "name_de": "15-18",
        "name_nl": "15-18",
        "name_pl": "15-18",
        "name_ro": "15-18",
        "name_ru": "15-18",
        "name_tr": "15-18",
        "name_se": "15-18",
        "name_el": "15-18",
        "name_es": "15-18",
        "name_hr": "15-18"
    },
    "782^prematch": {
        "name_en": "draw",
        "name_aa": "رسم",
        "name_de": "unentschieden",
        "name_nl": "gelijkspel",
        "name_pl": "remis",
        "name_ro": "egal",
        "name_ru": "ничья",
        "name_tr": "beraberlik",
        "name_se": "oavgjort",
        "name_el": "ισοπαλία",
        "name_es": "empate",
        "name_hr": "neodlučeno"
    },
    "1694260000^prematch": {
        "name_en": "Under 4.5",
        "name_aa": "Under 4.5",
        "name_de": "Under 4.5",
        "name_nl": "Under 4.5",
        "name_pl": "Under 4.5",
        "name_ro": "Under 4.5",
        "name_ru": "Under 4.5",
        "name_tr": "Under 4.5",
        "name_se": "Under 4.5",
        "name_el": "Under 4.5",
        "name_es": "Under 4.5",
        "name_hr": "Under 4.5"
    },
    "1004^prematch": {
        "name_en": "other",
        "name_aa": "آخر",
        "name_de": "andere",
        "name_nl": "ander",
        "name_pl": "inny",
        "name_ro": "alta",
        "name_ru": "другой",
        "name_tr": "diğer",
        "name_se": "annan",
        "name_el": "άλλο",
        "name_es": "otro",
        "name_hr": "ostalo"
    },
    "157^prematch": {
        "name_en": "0-100",
        "name_aa": "0-100",
        "name_de": "0-100",
        "name_nl": "0-100",
        "name_pl": "0-100",
        "name_ro": "0-100",
        "name_ru": "0-100",
        "name_tr": "0-100",
        "name_se": "0-100",
        "name_el": "0-100",
        "name_es": "0-100",
        "name_hr": "0-100"
    },
    "316^prematch": {
        "name_en": "1:4",
        "name_aa": "1:4",
        "name_de": "1:4",
        "name_nl": "1:4",
        "name_pl": "1:4",
        "name_ro": "1:4",
        "name_ru": "1:4",
        "name_tr": "1:4",
        "name_se": "1:4",
        "name_el": "1:4",
        "name_es": "1:4",
        "name_hr": "1:4"
    },
    "90^prematch": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2"
    },
    "1162^prematch": {
        "name_en": "0:5",
        "name_aa": "0:5",
        "name_de": "0:5",
        "name_nl": "0:5",
        "name_pl": "0:5",
        "name_ro": "0:5",
        "name_ru": "0:5",
        "name_tr": "0:5",
        "name_se": "0:5",
        "name_el": "0:5",
        "name_es": "0:5",
        "name_hr": "0:5"
    },
    "370^prematch": {
        "name_en": "0:3 0:3",
        "name_aa": "0:3 0:3",
        "name_de": "0:3 0:3",
        "name_nl": "0:3 0:3",
        "name_pl": "0:3 0:3",
        "name_ro": "0:3 0:3",
        "name_ru": "0:3 0:3",
        "name_tr": "0:3 0:3",
        "name_se": "0:3 0:3",
        "name_el": "0:3 0:3",
        "name_es": "0:3 0:3",
        "name_hr": "0:3 0:3"
    },
    "1411^prematch": {
        "name_en": "{$competitor1}  9",
        "name_aa": "{$competitor1}  9",
        "name_de": "{$competitor1}  9",
        "name_nl": "{$competitor1}  9",
        "name_pl": "{$competitor1}  9",
        "name_ro": "{$competitor1}  9",
        "name_ru": "{$competitor1}  9",
        "name_tr": "{$competitor1}  9",
        "name_se": "{$competitor1}  9",
        "name_el": "{$competitor1}  9",
        "name_es": "{$competitor1}  9",
        "name_hr": "{$competitor1}  9"
    },
    "245^prematch": {
        "name_en": "3:8",
        "name_aa": "3:8",
        "name_de": "3:8",
        "name_nl": "3:8",
        "name_pl": "3:8",
        "name_ro": "3:8",
        "name_ru": "3:8",
        "name_tr": "3:8",
        "name_se": "3:8",
        "name_el": "3:8",
        "name_es": "3:8",
        "name_hr": "3:8"
    },
    "1427^prematch": {
        "name_en": "{$competitor2}  11",
        "name_aa": "{$competitor2}  11",
        "name_de": "{$competitor2}  11",
        "name_nl": "{$competitor2}  11",
        "name_pl": "{$competitor2}  11",
        "name_ro": "{$competitor2}  11",
        "name_ru": "{$competitor2}  11",
        "name_tr": "{$competitor2}  11",
        "name_se": "{$competitor2}  11",
        "name_el": "{$competitor2}  11",
        "name_es": "{$competitor2}  11",
        "name_hr": "{$competitor2}  11"
    },
    "276^prematch": {
        "name_en": "10:9",
        "name_aa": "10:9",
        "name_de": "10:9",
        "name_nl": "10:9",
        "name_pl": "10:9",
        "name_ro": "10:9",
        "name_ru": "10:9",
        "name_tr": "10:9",
        "name_se": "10:9",
        "name_el": "10:9",
        "name_es": "10:9",
        "name_hr": "10:9"
    },
    "1500^prematch": {
        "name_en": "1:4",
        "name_aa": "1:4",
        "name_de": "1:4",
        "name_nl": "1:4",
        "name_pl": "1:4",
        "name_ro": "1:4",
        "name_ru": "1:4",
        "name_tr": "1:4",
        "name_se": "1:4",
        "name_el": "1:4",
        "name_es": "1:4",
        "name_hr": "1:4"
    },
    "314^prematch": {
        "name_en": "0:4",
        "name_aa": "0:4",
        "name_de": "0:4",
        "name_nl": "0:4",
        "name_pl": "0:4",
        "name_ro": "0:4",
        "name_ru": "0:4",
        "name_tr": "0:4",
        "name_se": "0:4",
        "name_el": "0:4",
        "name_es": "0:4",
        "name_hr": "0:4"
    },
    "1560000^prematch": {
        "name_en": "Goal before 29:00 min.",
        "name_aa": "Goal before 29:00 min.",
        "name_de": "Goal before 29:00 min.",
        "name_nl": "Goal before 29:00 min.",
        "name_pl": "Goal before 29:00 min.",
        "name_ro": "Goal before 29:00 min.",
        "name_ru": "Goal before 29:00 min.",
        "name_tr": "Goal before 29:00 min.",
        "name_se": "Goal before 29:00 min.",
        "name_el": "Goal before 29:00 min.",
        "name_es": "Goal before 29:00 min.",
        "name_hr": "Goal before 29:00 min."
    },
    "1694050000^prematch": {
        "name_en": "Over 8.5",
        "name_aa": "Over 8.5",
        "name_de": "Over 8.5",
        "name_nl": "Over 8.5",
        "name_pl": "Over 8.5",
        "name_ro": "Over 8.5",
        "name_ru": "Over 8.5",
        "name_tr": "Over 8.5",
        "name_se": "Over 8.5",
        "name_el": "Over 8.5",
        "name_es": "Over 8.5",
        "name_hr": "Over 8.5"
    },
    "123^prematch": {
        "name_en": "{$competitor1} by 11+",
        "name_aa": "{$competitor1} by 11+",
        "name_de": "{$competitor1} by 11+",
        "name_nl": "{$competitor1} by 11+",
        "name_pl": "{$competitor1} by 11+",
        "name_ro": "{$competitor1} by 11+",
        "name_ru": "{$competitor1} by 11+",
        "name_tr": "{$competitor1} by 11+",
        "name_se": "{$competitor1} by 11+",
        "name_el": "{$competitor1} by 11+",
        "name_es": "{$competitor1} by 11+",
        "name_hr": "{$competitor1} by 11+"
    },
    "287^prematch": {
        "name_en": "11:0",
        "name_aa": "11:0",
        "name_de": "11:0",
        "name_nl": "11:0",
        "name_pl": "11:0",
        "name_ro": "11:0",
        "name_ru": "11:0",
        "name_tr": "11:0",
        "name_se": "11:0",
        "name_el": "11:0",
        "name_es": "11:0",
        "name_hr": "11:0"
    },
    "1184^prematch": {
        "name_en": "4:1",
        "name_aa": "4:1",
        "name_de": "4:1",
        "name_nl": "4:1",
        "name_pl": "4:1",
        "name_ro": "4:1",
        "name_ru": "4:1",
        "name_tr": "4:1",
        "name_se": "4:1",
        "name_el": "4:1",
        "name_es": "4:1",
        "name_hr": "4:1"
    },
    "1382^prematch": {
        "name_en": "{$competitor1}  7-9",
        "name_aa": "{$competitor1}  7-9",
        "name_de": "{$competitor1}  7-9",
        "name_nl": "{$competitor1}  7-9",
        "name_pl": "{$competitor1}  7-9",
        "name_ro": "{$competitor1}  7-9",
        "name_ru": "{$competitor1}  7-9",
        "name_tr": "{$competitor1}  7-9",
        "name_se": "{$competitor1}  7-9",
        "name_el": "{$competitor1}  7-9",
        "name_es": "{$competitor1}  7-9",
        "name_hr": "{$competitor1}  7-9"
    },
    "224^prematch": {
        "name_en": "7:5",
        "name_aa": "7:5",
        "name_de": "7:5",
        "name_nl": "7:5",
        "name_pl": "7:5",
        "name_ro": "7:5",
        "name_ru": "7:5",
        "name_tr": "7:5",
        "name_se": "7:5",
        "name_el": "7:5",
        "name_es": "7:5",
        "name_hr": "7:5"
    },
    "1759^prematch": {
        "name_en": "other awaywin",
        "name_aa": "بعيدا اخرى",
        "name_de": "andere Sieg Auswärtsmannschaft",
        "name_nl": "ander uitoverwinning",
        "name_pl": "inne zwycięstwo goście",
        "name_ro": "altul victorie oaspeți",
        "name_ru": "другой, победа гостей",
        "name_tr": "diğer deplasman galibiyeti",
        "name_se": "annan bortaseger",
        "name_el": "νίκη φιλοξενούμενου με άλλο σκορ",
        "name_es": "otra victoriavisitante",
        "name_hr": "ostalo, pobjeda gosta"
    },
    "958^prematch": {
        "name_en": "8th inning",
        "name_aa": "الشوط الثامن",
        "name_de": "8. Inning",
        "name_nl": "8e inning",
        "name_pl": "8. inning",
        "name_ro": "inning 8",
        "name_ru": "8ой иннинг",
        "name_tr": "8. devre",
        "name_se": "8:e inning",
        "name_el": "8ο inning",
        "name_es": "8º inning",
        "name_hr": "8. inning"
    },
    "1071^prematch": {
        "name_en": "100-119",
        "name_aa": "100-119",
        "name_de": "100-119",
        "name_nl": "100-119",
        "name_pl": "100-119",
        "name_ro": "100-119",
        "name_ru": "100-119",
        "name_tr": "100-119",
        "name_se": "100-119",
        "name_el": "100-119",
        "name_es": "100-119",
        "name_hr": "100-119"
    },
    "148^prematch": {
        "name_en": "0:3",
        "name_aa": "0:3",
        "name_de": "0:3",
        "name_nl": "0:3",
        "name_pl": "0:3",
        "name_ro": "0:3",
        "name_ru": "0:3",
        "name_tr": "0:3",
        "name_se": "0:3",
        "name_el": "0:3",
        "name_es": "0:3",
        "name_hr": "0:3"
    },
    "963^prematch": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2"
    },
    "1696950000^prematch": {
        "name_en": "0-6",
        "name_aa": "0-6",
        "name_de": "0-6",
        "name_nl": "0-6",
        "name_pl": "0-6",
        "name_ro": "0-6",
        "name_ru": "0-6",
        "name_tr": "0-6",
        "name_se": "0-6",
        "name_el": "0-6",
        "name_es": "0-6",
        "name_hr": "0-6"
    },
    "1886^prematch": {
        "name_en": "draw/{$competitor2}  5+",
        "name_aa": "رسم / {$competitor2} و 5+",
        "name_de": "unentschieden/{$competitor2}  5+",
        "name_nl": "gelijkspel/{$competitor2}  5+",
        "name_pl": "remis/{$competitor2}  5+",
        "name_ro": "egal/{$competitor2}  5+",
        "name_ru": "ничья/{$competitor2} и 5+",
        "name_tr": "beraberlik/{$competitor2}  5+",
        "name_se": "oavgjort/{$competitor2}  5+",
        "name_el": "ισοπαλία/{$competitor2}  5+",
        "name_es": "empate/{$competitor2}  5+",
        "name_hr": "neodlučeno/{$competitor2} i 5+"
    },
    "560^prematch": {
        "name_en": "2:1",
        "name_aa": "2:1",
        "name_de": "2:1",
        "name_nl": "2:1",
        "name_pl": "2:1",
        "name_ro": "2:1",
        "name_ru": "2:1",
        "name_tr": "2:1",
        "name_se": "2:1",
        "name_el": "2:1",
        "name_es": "2:1",
        "name_hr": "2:1"
    },
    "612^prematch": {
        "name_en": "71-80",
        "name_aa": "71-80",
        "name_de": "71-80",
        "name_nl": "71-80",
        "name_pl": "71-80",
        "name_ro": "71-80",
        "name_ru": "71-80",
        "name_tr": "71-80",
        "name_se": "71-80",
        "name_el": "71-80",
        "name_es": "71-80",
        "name_hr": "71-80"
    },
    "1002^prematch": {
        "name_en": "{$competitor1} by 3+",
        "name_aa": "{$competitor1} في 3+",
        "name_de": "{$competitor1} mit 3+",
        "name_nl": "{$competitor1} met 3+",
        "name_pl": "{$competitor1} 3+",
        "name_ro": "{$competitor1} cu 3+",
        "name_ru": "{$competitor1} на 3+",
        "name_tr": "{$competitor1} 3 ve üstü farkla",
        "name_se": "{$competitor1} med 3+",
        "name_el": "{$competitor1} με 3+",
        "name_es": "{$competitor1} por 3+",
        "name_hr": "{$competitor1} za 3 ili više"
    },
    "1804^prematch": {
        "name_en": "no goal",
        "name_aa": "لا هدف",
        "name_de": "kein Tor",
        "name_nl": "geen doelpunt",
        "name_pl": "bez gola",
        "name_ro": "niciun gol",
        "name_ru": "гола не будет",
        "name_tr": "gol yok",
        "name_se": "inga mål",
        "name_el": "κανένα γκολ",
        "name_es": "sin gol",
        "name_hr": "nema gola"
    },
    "1877^prematch": {
        "name_en": "draw/{$competitor2}  4",
        "name_aa": "رسم / {$competitor2} و 4",
        "name_de": "unentschieden/{$competitor2}  4",
        "name_nl": "gelijkspel/{$competitor2}  4",
        "name_pl": "remis/{$competitor2}  4",
        "name_ro": "egal/{$competitor2}  4",
        "name_ru": "ничья/{$competitor2} и 4",
        "name_tr": "beraberlik/{$competitor2}  4",
        "name_se": "oavgjort/{$competitor2}  4",
        "name_el": "ισοπαλία/{$competitor2}  4",
        "name_es": "empate/{$competitor2}  4",
        "name_hr": "neodlučeno/{$competitor2} i 4"
    },
    "1859^prematch": {
        "name_en": "{$competitor1}/{$competitor1}  2",
        "name_aa": "{$competitor1} / {$competitor1} و 2",
        "name_de": "{$competitor1}/{$competitor1}  2",
        "name_nl": "{$competitor1}/{$competitor1}  2",
        "name_pl": "{$competitor1}/{$competitor1}  2",
        "name_ro": "{$competitor1}/{$competitor1}  2",
        "name_ru": "{$competitor1}/{$competitor1} и 2",
        "name_tr": "{$competitor1}/{$competitor1}  2",
        "name_se": "{$competitor1}/{$competitor1}  2",
        "name_el": "{$competitor1}/{$competitor1}  2",
        "name_es": "{$competitor1}/{$competitor1}  2",
        "name_hr": "{$competitor1}/{$competitor1} i 2"
    },
    "1259^prematch": {
        "name_en": "50-54",
        "name_aa": "50-54",
        "name_de": "50-54",
        "name_nl": "50-54",
        "name_pl": "50-54",
        "name_ro": "50-54",
        "name_ru": "50-54",
        "name_tr": "50-54",
        "name_se": "50-54",
        "name_el": "50-54",
        "name_es": "50-54",
        "name_hr": "50-54"
    },
    "1193^prematch": {
        "name_en": "other",
        "name_aa": "other",
        "name_de": "other",
        "name_nl": "other",
        "name_pl": "other",
        "name_ro": "other",
        "name_ru": "other",
        "name_tr": "other",
        "name_se": "other",
        "name_el": "other",
        "name_es": "other",
        "name_hr": "other"
    },
    "1358^prematch": {
        "name_en": "{$competitor2}  1",
        "name_aa": "{$competitor2}  1",
        "name_de": "{$competitor2}  1",
        "name_nl": "{$competitor2}  1",
        "name_pl": "{$competitor2}  1",
        "name_ro": "{$competitor2}  1",
        "name_ru": "{$competitor2}  1",
        "name_tr": "{$competitor2}  1",
        "name_se": "{$competitor2}  1",
        "name_el": "{$competitor2}  1",
        "name_es": "{$competitor2}  1",
        "name_hr": "{$competitor2}  1"
    },
    "875^prematch": {
        "name_en": "3:6",
        "name_aa": "3:6",
        "name_de": "3:6",
        "name_nl": "3:6",
        "name_pl": "3:6",
        "name_ro": "3:6",
        "name_ru": "3:6",
        "name_tr": "3:6",
        "name_se": "3:6",
        "name_el": "3:6",
        "name_es": "3:6",
        "name_hr": "3:6"
    },
    "70980000^prematch": {
        "name_en": "{$competitor1}",
        "name_aa": "{$competitor1}",
        "name_de": "{$competitor1}",
        "name_nl": "{$competitor1}",
        "name_pl": "{$competitor1}",
        "name_ro": "{$competitor1}",
        "name_ru": "{$competitor1}",
        "name_tr": "{$competitor1}",
        "name_se": "{$competitor1}",
        "name_el": "{$competitor1}",
        "name_es": "{$competitor1}",
        "name_hr": "{$competitor1}"
    },
    "1764^prematch": {
        "name_en": "4",
        "name_aa": "4",
        "name_de": "4",
        "name_nl": "4",
        "name_pl": "4",
        "name_ro": "4",
        "name_ru": "4",
        "name_tr": "4",
        "name_se": "4",
        "name_el": "4",
        "name_es": "4",
        "name_hr": "4"
    },
    "1183^prematch": {
        "name_en": "4:0",
        "name_aa": "4:0",
        "name_de": "4:0",
        "name_nl": "4:0",
        "name_pl": "4:0",
        "name_ro": "4:0",
        "name_ru": "4:0",
        "name_tr": "4:0",
        "name_se": "4:0",
        "name_el": "4:0",
        "name_es": "4:0",
        "name_hr": "4:0"
    },
    "7^prematch": {
        "name_en": "0:2",
        "name_aa": "0:2",
        "name_de": "0:2",
        "name_nl": "0:2",
        "name_pl": "0:2",
        "name_ro": "0:2",
        "name_ru": "0:2",
        "name_tr": "0:2",
        "name_se": "0:2",
        "name_el": "0:2",
        "name_es": "0:2",
        "name_hr": "0:2"
    },
    "1750^prematch": {
        "name_en": "1:0, 2:0 or 3:0",
        "name_aa": "1:0, 2:0 أو 3:0",
        "name_de": "1:0, 2:0 oder 3:0",
        "name_nl": "1:0, 2:0 of 3:0",
        "name_pl": "1:0, 2:0 lub 3:0",
        "name_ro": "1:0, 2:0 sau 3:0",
        "name_ru": "1:0, 2:0 или 3:0",
        "name_tr": "1:0, 2:0 veya 3:0",
        "name_se": "1:0, 2:0 elr 3:0",
        "name_el": "1-0, 2-0 ή 3-0",
        "name_es": "1:0, 2:0 o 3:0",
        "name_hr": "1:0, 2:0 ili 3:0"
    },
    "232^prematch": {
        "name_en": "0:7",
        "name_aa": "0:7",
        "name_de": "0:7",
        "name_nl": "0:7",
        "name_pl": "0:7",
        "name_ro": "0:7",
        "name_ru": "0:7",
        "name_tr": "0:7",
        "name_se": "0:7",
        "name_el": "0:7",
        "name_es": "0:7",
        "name_hr": "0:7"
    },
    "1172^prematch": {
        "name_en": "2:0",
        "name_aa": "2:0",
        "name_de": "2:0",
        "name_nl": "2:0",
        "name_pl": "2:0",
        "name_ro": "2:0",
        "name_ru": "2:0",
        "name_tr": "2:0",
        "name_se": "2:0",
        "name_el": "2:0",
        "name_es": "2:0",
        "name_hr": "2:0"
    },
    "1144^prematch": {
        "name_en": "0-2",
        "name_aa": "0-2",
        "name_de": "0-2",
        "name_nl": "0-2",
        "name_pl": "0-2",
        "name_ro": "0-2",
        "name_ru": "0-2",
        "name_tr": "0-2",
        "name_se": "0-2",
        "name_el": "0-2",
        "name_es": "0-2",
        "name_hr": "0-2"
    },
    "253^prematch": {
        "name_en": "9:4",
        "name_aa": "9:4",
        "name_de": "9:4",
        "name_nl": "9:4",
        "name_pl": "9:4",
        "name_ro": "9:4",
        "name_ru": "9:4",
        "name_tr": "9:4",
        "name_se": "9:4",
        "name_el": "9:4",
        "name_es": "9:4",
        "name_hr": "9:4"
    },
    "1419^prematch": {
        "name_en": "{$competitor2}  3",
        "name_aa": "{$competitor2}  3",
        "name_de": "{$competitor2}  3",
        "name_nl": "{$competitor2}  3",
        "name_pl": "{$competitor2}  3",
        "name_ro": "{$competitor2}  3",
        "name_ru": "{$competitor2}  3",
        "name_tr": "{$competitor2}  3",
        "name_se": "{$competitor2}  3",
        "name_el": "{$competitor2}  3",
        "name_es": "{$competitor2}  3",
        "name_hr": "{$competitor2}  3"
    },
    "959^prematch": {
        "name_en": "9th inning",
        "name_aa": "الشوط التاسع",
        "name_de": "9. Inning",
        "name_nl": "9e inning",
        "name_pl": "9. inning",
        "name_ro": "inning 9",
        "name_ru": "9ый иннинг",
        "name_tr": "9. devre",
        "name_se": "9:e inning",
        "name_el": "9ο inning",
        "name_es": "9º inning",
        "name_hr": "9. inning"
    },
    "1694120000^prematch": {
        "name_en": "Under 11.5",
        "name_aa": "Under 11.5",
        "name_de": "Under 11.5",
        "name_nl": "Under 11.5",
        "name_pl": "Under 11.5",
        "name_ro": "Under 11.5",
        "name_ru": "Under 11.5",
        "name_tr": "Under 11.5",
        "name_se": "Under 11.5",
        "name_el": "Under 11.5",
        "name_es": "Under 11.5",
        "name_hr": "Under 11.5"
    },
    "271^prematch": {
        "name_en": "10:4",
        "name_aa": "10:4",
        "name_de": "10:4",
        "name_nl": "10:4",
        "name_pl": "10:4",
        "name_ro": "10:4",
        "name_ru": "10:4",
        "name_tr": "10:4",
        "name_se": "10:4",
        "name_el": "10:4",
        "name_es": "10:4",
        "name_hr": "10:4"
    },
    "432^prematch": {
        "name_en": "{$competitor2}/draw",
        "name_aa": "{$competitor2} / رسم",
        "name_de": "{$competitor2}/unentschieden",
        "name_nl": "{$competitor2}/gelijkspel",
        "name_pl": "{$competitor2}/remis",
        "name_ro": "{$competitor2}/egal",
        "name_ru": "{$competitor2}/ничья",
        "name_tr": "{$competitor2}/beraberlik",
        "name_se": "{$competitor2}/oavgjort",
        "name_el": "{$competitor2}/ισοπαλία",
        "name_es": "{$competitor2}/empate",
        "name_hr": "{$competitor2}/neodlučeno"
    },
    "1369^prematch": {
        "name_en": "{$competitor2}  1",
        "name_aa": "{$competitor2}  1",
        "name_de": "{$competitor2}  1",
        "name_nl": "{$competitor2}  1",
        "name_pl": "{$competitor2}  1",
        "name_ro": "{$competitor2}  1",
        "name_ru": "{$competitor2}  1",
        "name_tr": "{$competitor2}  1",
        "name_se": "{$competitor2}  1",
        "name_el": "{$competitor2}  1",
        "name_es": "{$competitor2}  1",
        "name_hr": "{$competitor2}  1"
    },
    "1694080000^prematch": {
        "name_en": "Under 9.5",
        "name_aa": "Under 9.5",
        "name_de": "Under 9.5",
        "name_nl": "Under 9.5",
        "name_pl": "Under 9.5",
        "name_ro": "Under 9.5",
        "name_ru": "Under 9.5",
        "name_tr": "Under 9.5",
        "name_se": "Under 9.5",
        "name_el": "Under 9.5",
        "name_es": "Under 9.5",
        "name_hr": "Under 9.5"
    },
    "1741^prematch": {
        "name_en": "3-6",
        "name_aa": "3-6",
        "name_de": "3-6",
        "name_nl": "3-6",
        "name_pl": "3-6",
        "name_ro": "3-6",
        "name_ru": "3-6",
        "name_tr": "3-6",
        "name_se": "3-6",
        "name_el": "3-6",
        "name_es": "3-6",
        "name_hr": "3-6"
    },
    "142^prematch": {
        "name_en": "{$competitor2} by 11+",
        "name_aa": "{$competitor2} by 11+",
        "name_de": "{$competitor2} by 11+",
        "name_nl": "{$competitor2} by 11+",
        "name_pl": "{$competitor2} by 11+",
        "name_ro": "{$competitor2} by 11+",
        "name_ru": "{$competitor2} by 11+",
        "name_tr": "{$competitor2} by 11+",
        "name_se": "{$competitor2} by 11+",
        "name_el": "{$competitor2} by 11+",
        "name_es": "{$competitor2} by 11+",
        "name_hr": "{$competitor2} by 11+"
    },
    "302^prematch": {
        "name_en": "6:11",
        "name_aa": "6:11",
        "name_de": "6:11",
        "name_nl": "6:11",
        "name_pl": "6:11",
        "name_ro": "6:11",
        "name_ru": "6:11",
        "name_tr": "6:11",
        "name_se": "6:11",
        "name_el": "6:11",
        "name_es": "6:11",
        "name_hr": "6:11"
    },
    "1421^prematch": {
        "name_en": "{$competitor2}  5",
        "name_aa": "{$competitor2}  5",
        "name_de": "{$competitor2}  5",
        "name_nl": "{$competitor2}  5",
        "name_pl": "{$competitor2}  5",
        "name_ro": "{$competitor2}  5",
        "name_ru": "{$competitor2}  5",
        "name_tr": "{$competitor2}  5",
        "name_se": "{$competitor2}  5",
        "name_el": "{$competitor2}  5",
        "name_es": "{$competitor2}  5",
        "name_hr": "{$competitor2}  5"
    },
    "1346^prematch": {
        "name_en": "{$competitor1} by ko",
        "name_aa": "{$competitor1} by ko",
        "name_de": "{$competitor1} by ko",
        "name_nl": "{$competitor1} by ko",
        "name_pl": "{$competitor1} by ko",
        "name_ro": "{$competitor1} by ko",
        "name_ru": "{$competitor1} by ko",
        "name_tr": "{$competitor1} by ko",
        "name_se": "{$competitor1} by ko",
        "name_el": "{$competitor1} by ko",
        "name_es": "{$competitor1} by ko",
        "name_hr": "{$competitor1} by ko"
    },
    "826^prematch": {
        "name_en": "no goal",
        "name_aa": "لا هدف",
        "name_de": "kein Tor",
        "name_nl": "geen doelpunt",
        "name_pl": "bez gola",
        "name_ro": "niciun gol",
        "name_ru": "гола не будет",
        "name_tr": "gol yok",
        "name_se": "inga mål",
        "name_el": "κανένα γκολ",
        "name_es": "sin gol",
        "name_hr": "bez gola"
    },
    "410^prematch": {
        "name_en": "2:1 4+",
        "name_aa": "2:1 4+",
        "name_de": "2:1 4+",
        "name_nl": "2:1 4+",
        "name_pl": "2:1 4+",
        "name_ro": "2:1 4+",
        "name_ru": "2:1 4+",
        "name_tr": "2:1 4+",
        "name_se": "2:1 4+",
        "name_el": "2:1 4+",
        "name_es": "2:1 4+",
        "name_hr": "2:1 4+"
    },
    "1861^prematch": {
        "name_en": "draw/{$competitor1}  2",
        "name_aa": "رسم / {$competitor1} و 2",
        "name_de": "unentschieden/{$competitor1}  2",
        "name_nl": "gelijkspel/{$competitor1}  2",
        "name_pl": "remis/{$competitor1}  2",
        "name_ro": "egal/{$competitor1}  2",
        "name_ru": "ничья/{$competitor1} и 2",
        "name_tr": "beraberlik/{$competitor1}  2",
        "name_se": "oavgjort/{$competitor1}  2",
        "name_el": "ισοπαλία/{$competitor1}  2",
        "name_es": "empate/{$competitor1}  2",
        "name_hr": "neodlučeno/{$competitor1} i 2"
    },
    "430^prematch": {
        "name_en": "{$competitor2}/{$competitor1}",
        "name_aa": "{$competitor2} / {$competitor1}",
        "name_de": "{$competitor2}/{$competitor1}",
        "name_nl": "{$competitor2}/{$competitor1}",
        "name_pl": "{$competitor2}/{$competitor1}",
        "name_ro": "{$competitor2}/{$competitor1}",
        "name_ru": "{$competitor2}/{$competitor1}",
        "name_tr": "{$competitor2}/{$competitor1}",
        "name_se": "{$competitor2}/{$competitor1}",
        "name_el": "{$competitor2}/{$competitor1}",
        "name_es": "{$competitor2}/{$competitor1}",
        "name_hr": "{$competitor2}/{$competitor1}"
    },
    "1737^prematch": {
        "name_en": "2-5",
        "name_aa": "2-5",
        "name_de": "2-5",
        "name_nl": "2-5",
        "name_pl": "2-5",
        "name_ro": "2-5",
        "name_ru": "2-5",
        "name_tr": "2-5",
        "name_se": "2-5",
        "name_el": "2-5",
        "name_es": "2-5",
        "name_hr": "2-5"
    },
    "56160000^prematch": {
        "name_en": "5+",
        "name_aa": "5+",
        "name_de": "5+",
        "name_nl": "5+",
        "name_pl": "5+",
        "name_ro": "5+",
        "name_ru": "5+",
        "name_tr": "5+",
        "name_se": "5+",
        "name_el": "5+",
        "name_es": "5+",
        "name_hr": "5+"
    },
    "920^prematch": {
        "name_en": "1st quarter",
        "name_aa": "الربع الاول",
        "name_de": "1. Viertel",
        "name_nl": "Eerste kwart",
        "name_pl": "1. kwarta",
        "name_ro": "Sfertul 1",
        "name_ru": "1ая четверть",
        "name_tr": "İlk çeyrek",
        "name_se": "1:a quarter",
        "name_el": "1η περίοδος",
        "name_es": "1º cuarto",
        "name_hr": "1. četvrtina"
    },
    "1486^prematch": {
        "name_en": "5:1",
        "name_aa": "5:1",
        "name_de": "5:1",
        "name_nl": "5:1",
        "name_pl": "5:1",
        "name_ro": "5:1",
        "name_ru": "5:1",
        "name_tr": "5:1",
        "name_se": "5:1",
        "name_el": "5:1",
        "name_es": "5:1",
        "name_hr": "5:1"
    },
    "1829^prematch": {
        "name_en": "{!(inningnr+2)} inning",
        "name_aa": "{!(inningnr+2)} الشوط",
        "name_de": "{!(inningnr+2)} Inning",
        "name_nl": "{!(inningnr+2)} inning",
        "name_pl": "{!(inningnr+2)} inning",
        "name_ro": "{!(inningnr+2)} inning",
        "name_ru": "{!(inningnr+2)} иннинг",
        "name_tr": "{!(inningnr+2)} devre",
        "name_se": "{!(inningnr+2)} inning",
        "name_el": "{!(inningnr+2)} inning",
        "name_es": "{!(inningnr+2)} inning",
        "name_hr": "{!(inningnr+2)} inning"
    },
    "1342^prematch": {
        "name_en": "0-1",
        "name_aa": "0-1",
        "name_de": "0-1",
        "name_nl": "0-1",
        "name_pl": "0-1",
        "name_ro": "0-1",
        "name_ru": "0-1",
        "name_tr": "0-1",
        "name_se": "0-1",
        "name_el": "0-1",
        "name_es": "0-1",
        "name_hr": "0-1"
    },
    "1343^prematch": {
        "name_en": "2-3",
        "name_aa": "2-3",
        "name_de": "2-3",
        "name_nl": "2-3",
        "name_pl": "2-3",
        "name_ro": "2-3",
        "name_ru": "2-3",
        "name_tr": "2-3",
        "name_se": "2-3",
        "name_el": "2-3",
        "name_es": "2-3",
        "name_hr": "2-3"
    },
    "812^prematch": {
        "name_en": "no/yes",
        "name_aa": "لا / نعم",
        "name_de": "nein/ja",
        "name_nl": "nee/ja",
        "name_pl": "nie/tak",
        "name_ro": "nu/da",
        "name_ru": "нет/да",
        "name_tr": "yok/var",
        "name_se": "nej/ja",
        "name_el": "όχι/ναι",
        "name_es": "no/sí",
        "name_hr": "ne/da"
    },
    "10^prematch": {
        "name_en": "{$competitor1} or {$competitor2}",
        "name_aa": "{$competitor1} أو {$competitor2}",
        "name_de": "{$competitor1} oder {$competitor2}",
        "name_nl": "{$competitor1} of {$competitor2}",
        "name_pl": "{$competitor1} lub {$competitor2}",
        "name_ro": "{$competitor1} sau {$competitor2}",
        "name_ru": "{$competitor1} или {$competitor2}",
        "name_tr": "{$competitor1} veya {$competitor2}",
        "name_se": "{$competitor1} elr {$competitor2}",
        "name_el": "{$competitor1} ή {$competitor2}",
        "name_es": "{$competitor1} o  {$competitor2}",
        "name_hr": "{$competitor1} ili {$competitor2}"
    },
    "780^prematch": {
        "name_en": "{$competitor1}",
        "name_aa": "{$competitor1}",
        "name_de": "{$competitor1}",
        "name_nl": "{$competitor1}",
        "name_pl": "{$competitor1}",
        "name_ro": "{$competitor1}",
        "name_ru": "{$competitor1}",
        "name_tr": "{$competitor1}",
        "name_se": "{$competitor1}",
        "name_el": "{$competitor1}",
        "name_es": "{$competitor1}",
        "name_hr": "{$competitor1}"
    },
    "1163^prematch": {
        "name_en": "0:6",
        "name_aa": "0:6",
        "name_de": "0:6",
        "name_nl": "0:6",
        "name_pl": "0:6",
        "name_ro": "0:6",
        "name_ru": "0:6",
        "name_tr": "0:6",
        "name_se": "0:6",
        "name_el": "0:6",
        "name_es": "0:6",
        "name_hr": "0:6"
    },
    "1385^prematch": {
        "name_en": "draw",
        "name_aa": "draw",
        "name_de": "draw",
        "name_nl": "draw",
        "name_pl": "draw",
        "name_ro": "draw",
        "name_ru": "draw",
        "name_tr": "draw",
        "name_se": "draw",
        "name_el": "draw",
        "name_es": "draw",
        "name_hr": "draw"
    },
    "1362^prematch": {
        "name_en": "{$competitor1}  1",
        "name_aa": "{$competitor1}  1",
        "name_de": "{$competitor1}  1",
        "name_nl": "{$competitor1}  1",
        "name_pl": "{$competitor1}  1",
        "name_ro": "{$competitor1}  1",
        "name_ru": "{$competitor1}  1",
        "name_tr": "{$competitor1}  1",
        "name_se": "{$competitor1}  1",
        "name_el": "{$competitor1}  1",
        "name_es": "{$competitor1}  1",
        "name_hr": "{$competitor1}  1"
    },
    "816^prematch": {
        "name_en": "{$competitor1} goal  draw",
        "name_aa": "{$competitor1} هدف وارسم",
        "name_de": "{$competitor1} Tor  unentschieden",
        "name_nl": "doelpunt {$competitor1}  gelijkspel",
        "name_pl": "{$competitor1} Gol  remis",
        "name_ro": "gol {$competitor1}  egal",
        "name_ru": "гол {$competitor1}  ничья",
        "name_tr": "{$competitor1} golü  beraberlik",
        "name_se": "{$competitor1} mål  oavgjort",
        "name_el": "γκολ {$competitor1} και ισοπαλία",
        "name_es": "{$competitor1} gol y empate",
        "name_hr": "{$competitor1} gol i neodlučeno"
    },
    "870^prematch": {
        "name_en": "7:5",
        "name_aa": "7:5",
        "name_de": "7:5",
        "name_nl": "7:5",
        "name_pl": "7:5",
        "name_ro": "7:5",
        "name_ru": "7:5",
        "name_tr": "7:5",
        "name_se": "7:5",
        "name_el": "7:5",
        "name_es": "7:5",
        "name_hr": "7:5"
    },
    "1336^prematch": {
        "name_en": "0",
        "name_aa": "0",
        "name_de": "0",
        "name_nl": "0",
        "name_pl": "0",
        "name_ro": "0",
        "name_ru": "0",
        "name_tr": "0",
        "name_se": "0",
        "name_el": "0",
        "name_es": "0",
        "name_hr": "0"
    },
    "70870000^prematch": {
        "name_en": "Draw",
        "name_aa": "Draw",
        "name_de": "Draw",
        "name_nl": "Draw",
        "name_pl": "Draw",
        "name_ro": "Draw",
        "name_ru": "Draw",
        "name_tr": "Draw",
        "name_se": "Draw",
        "name_el": "Draw",
        "name_es": "Draw",
        "name_hr": "Draw"
    },
    "1080^prematch": {
        "name_en": "11-14",
        "name_aa": "11-14",
        "name_de": "11-14",
        "name_nl": "11-14",
        "name_pl": "11-14",
        "name_ro": "11-14",
        "name_ru": "11-14",
        "name_tr": "11-14",
        "name_se": "11-14",
        "name_el": "11-14",
        "name_es": "11-14",
        "name_hr": "11-14"
    },
    "1133^prematch": {
        "name_en": "31-45",
        "name_aa": "31-45",
        "name_de": "31-45",
        "name_nl": "31-45",
        "name_pl": "31-45",
        "name_ro": "31-45",
        "name_ru": "31-45",
        "name_tr": "31-45",
        "name_se": "31-45",
        "name_el": "31-45",
        "name_es": "31-45",
        "name_hr": "31-45"
    },
    "1345^prematch": {
        "name_en": "7+",
        "name_aa": "7+",
        "name_de": "7+",
        "name_nl": "7+",
        "name_pl": "7+",
        "name_ro": "7+",
        "name_ru": "7+",
        "name_tr": "7+",
        "name_se": "7+",
        "name_el": "7+",
        "name_es": "7+",
        "name_hr": "7+"
    },
    "346^prematch": {
        "name_en": "0:0 4+",
        "name_aa": "0:0 4+",
        "name_de": "0:0 4+",
        "name_nl": "0:0 4+",
        "name_pl": "0:0 4+",
        "name_ro": "0:0 4+",
        "name_ru": "0:0 4+",
        "name_tr": "0:0 4+",
        "name_se": "0:0 4+",
        "name_el": "0:0 4+",
        "name_es": "0:0 4+",
        "name_hr": "0:0 4+"
    },
    "204^prematch": {
        "name_en": "0:7",
        "name_aa": "0:7",
        "name_de": "0:7",
        "name_nl": "0:7",
        "name_pl": "0:7",
        "name_ro": "0:7",
        "name_ru": "0:7",
        "name_tr": "0:7",
        "name_se": "0:7",
        "name_el": "0:7",
        "name_es": "0:7",
        "name_hr": "0:7"
    },
    "1257^prematch": {
        "name_en": "40-44",
        "name_aa": "40-44",
        "name_de": "40-44",
        "name_nl": "40-44",
        "name_pl": "40-44",
        "name_ro": "40-44",
        "name_ru": "40-44",
        "name_tr": "40-44",
        "name_se": "40-44",
        "name_el": "40-44",
        "name_es": "40-44",
        "name_hr": "40-44"
    },
    "1728^prematch": {
        "name_en": "{$competitor1}/{$competitor2}  over {total}",
        "name_aa": "{$competitor1} / {$competitor2} وأكثر من {total}",
        "name_de": "{$competitor1}/{$competitor2}  über {total}",
        "name_nl": "{$competitor1}/{$competitor2}  boven {total}",
        "name_pl": "{$competitor1}/{$competitor2}  ponad {total}",
        "name_ro": "{$competitor1}/{$competitor2}  peste {total}",
        "name_ru": "{$competitor1}/{$competitor2} и больше {total}",
        "name_tr": "{$competitor1}/{$competitor2}  {total} üstü",
        "name_se": "{$competitor1}/{$competitor2}  över {total}",
        "name_el": "{$competitor1}/{$competitor2} και over {total}",
        "name_es": "{$competitor1}/{$competitor2} y más de {total}",
        "name_hr": "{$competitor1}/{$competitor2} i više {total}"
    },
    "1752^prematch": {
        "name_en": "4:0, 5:0 or 6:0",
        "name_aa": "4:0, 5:0 أو 6:0",
        "name_de": "4:0, 5:0 oder 6:0",
        "name_nl": "4:0, 5:0 of 6:0",
        "name_pl": "4:0, 5:0 lub 6:0",
        "name_ro": "4:0, 5:0 sau 6:0",
        "name_ru": "4:0, 5:0 или 6:0",
        "name_tr": "4:0, 5:0 veya 6:0",
        "name_se": "4:0, 5:0 elr 6:0",
        "name_el": "4-0, 5-0 ή 6-0",
        "name_es": "4:0, 5:0 o 6:0",
        "name_hr": "4:0, 5:0 ili 6:0"
    },
    "1509^prematch": {
        "name_en": "4:5",
        "name_aa": "4:5",
        "name_de": "4:5",
        "name_nl": "4:5",
        "name_pl": "4:5",
        "name_ro": "4:5",
        "name_ru": "4:5",
        "name_tr": "4:5",
        "name_se": "4:5",
        "name_el": "4:5",
        "name_es": "4:5",
        "name_hr": "4:5"
    },
    "1881^prematch": {
        "name_en": "{$competitor1}/{$competitor1}  5+",
        "name_aa": "{$competitor1} / {$competitor1} و 5+",
        "name_de": "{$competitor1}/{$competitor1}  5+",
        "name_nl": "{$competitor1}/{$competitor1}  5+",
        "name_pl": "{$competitor1}/{$competitor1}  5+",
        "name_ro": "{$competitor1}/{$competitor1}  5+",
        "name_ru": "{$competitor1}/{$competitor1} и 5+",
        "name_tr": "{$competitor1}/{$competitor1}  5+",
        "name_se": "{$competitor1}/{$competitor1}  5+",
        "name_el": "{$competitor1}/{$competitor1}  5+",
        "name_es": "{$competitor1}/{$competitor1}  5+",
        "name_hr": "{$competitor1}/{$competitor1} i 5+"
    },
    "1481^prematch": {
        "name_en": "0:1",
        "name_aa": "0:1",
        "name_de": "0:1",
        "name_nl": "0:1",
        "name_pl": "0:1",
        "name_ro": "0:1",
        "name_ru": "0:1",
        "name_tr": "0:1",
        "name_se": "0:1",
        "name_el": "0:1",
        "name_es": "0:1",
        "name_hr": "0:1"
    },
    "27^prematch": {
        "name_en": "4:5",
        "name_aa": "4:5",
        "name_de": "4:5",
        "name_nl": "4:5",
        "name_pl": "4:5",
        "name_ro": "4:5",
        "name_ru": "4:5",
        "name_tr": "4:5",
        "name_se": "4:5",
        "name_el": "4:5",
        "name_es": "4:5",
        "name_hr": "4:5"
    },
    "70930000^prematch": {
        "name_en": "Neither",
        "name_aa": "Neither",
        "name_de": "Neither",
        "name_nl": "Neither",
        "name_pl": "Neither",
        "name_ro": "Neither",
        "name_ru": "Neither",
        "name_tr": "Neither",
        "name_se": "Neither",
        "name_el": "Neither",
        "name_es": "Neither",
        "name_hr": "Neither"
    },
    "474^prematch": {
        "name_en": "0:1",
        "name_aa": "0:1",
        "name_de": "0:1",
        "name_nl": "0:1",
        "name_pl": "0:1",
        "name_ro": "0:1",
        "name_ru": "0:1",
        "name_tr": "0:1",
        "name_se": "0:1",
        "name_el": "0:1",
        "name_es": "0:1",
        "name_hr": "0:1"
    },
    "1188^prematch": {
        "name_en": "5:1",
        "name_aa": "5:1",
        "name_de": "5:1",
        "name_nl": "5:1",
        "name_pl": "5:1",
        "name_ro": "5:1",
        "name_ru": "5:1",
        "name_tr": "5:1",
        "name_se": "5:1",
        "name_el": "5:1",
        "name_es": "5:1",
        "name_hr": "5:1"
    },
    "1496^prematch": {
        "name_en": "3:3",
        "name_aa": "3:3",
        "name_de": "3:3",
        "name_nl": "3:3",
        "name_pl": "3:3",
        "name_ro": "3:3",
        "name_ru": "3:3",
        "name_tr": "3:3",
        "name_se": "3:3",
        "name_el": "3:3",
        "name_es": "3:3",
        "name_hr": "3:3"
    },
    "1084^prematch": {
        "name_en": "27-30",
        "name_aa": "27-30",
        "name_de": "27-30",
        "name_nl": "27-30",
        "name_pl": "27-30",
        "name_ro": "27-30",
        "name_ru": "27-30",
        "name_tr": "27-30",
        "name_se": "27-30",
        "name_el": "27-30",
        "name_es": "27-30",
        "name_hr": "27-30"
    },
    "1410^prematch": {
        "name_en": "{$competitor1}  8",
        "name_aa": "{$competitor1}  8",
        "name_de": "{$competitor1}  8",
        "name_nl": "{$competitor1}  8",
        "name_pl": "{$competitor1}  8",
        "name_ro": "{$competitor1}  8",
        "name_ru": "{$competitor1}  8",
        "name_tr": "{$competitor1}  8",
        "name_se": "{$competitor1}  8",
        "name_el": "{$competitor1}  8",
        "name_es": "{$competitor1}  8",
        "name_hr": "{$competitor1}  8"
    },
    "305^prematch": {
        "name_en": "3:11",
        "name_aa": "3:11",
        "name_de": "3:11",
        "name_nl": "3:11",
        "name_pl": "3:11",
        "name_ro": "3:11",
        "name_ru": "3:11",
        "name_tr": "3:11",
        "name_se": "3:11",
        "name_el": "3:11",
        "name_es": "3:11",
        "name_hr": "3:11"
    },
    "564^prematch": {
        "name_en": "other",
        "name_aa": "آخر",
        "name_de": "andere",
        "name_nl": "ander",
        "name_pl": "inny",
        "name_ro": "altul",
        "name_ru": "другой",
        "name_tr": "diğer",
        "name_se": "annan",
        "name_el": "άλλο",
        "name_es": "otro",
        "name_hr": "ostalo"
    },
    "436^prematch": {
        "name_en": "1st half",
        "name_aa": "النصف الأول",
        "name_de": "1. Halbzeit",
        "name_nl": "eerste helft",
        "name_pl": "1. Połowa",
        "name_ro": "Repriza 1",
        "name_ru": "1ая половина",
        "name_tr": "İlk yarı",
        "name_se": "1:a halvlek",
        "name_el": "1ο ημίχρονο",
        "name_es": "1º mitad",
        "name_hr": "1. poluvrijeme"
    },
    "1642^prematch": {
        "name_en": "{$competitor1} with drop goal",
        "name_aa": "{$competitor1} مع هدف إسقاط",
        "name_de": "{$competitor1} mit Drop goal",
        "name_nl": "{$competitor1} met drop goal",
        "name_pl": "{$competitor1} z dropgolem",
        "name_ro": "{$competitor1} cu drop goal",
        "name_ru": "{$competitor1} с дроп-гола",
        "name_tr": "{$competitor1}şutla",
        "name_se": "{$competitor1} med drop goal",
        "name_el": "{$competitor1} με drop goal",
        "name_es": "{$competitor1} con drop goal",
        "name_hr": "{$competitor1} drop golom"
    },
    "60^prematch": {
        "name_en": "3+",
        "name_aa": "3+",
        "name_de": "3+",
        "name_nl": "3+",
        "name_pl": "3+",
        "name_ro": "3+",
        "name_ru": "3+",
        "name_tr": "3+",
        "name_se": "3+",
        "name_el": "3+",
        "name_es": "3+",
        "name_hr": "3+"
    },
    "14^prematch": {
        "name_en": "4:0",
        "name_aa": "4:0",
        "name_de": "4:0",
        "name_nl": "4:0",
        "name_pl": "4:0",
        "name_ro": "4:0",
        "name_ru": "4:0",
        "name_tr": "4:0",
        "name_se": "4:0",
        "name_el": "4:0",
        "name_es": "4:0",
        "name_hr": "4:0"
    },
    "1092^prematch": {
        "name_en": "46+",
        "name_aa": "46+",
        "name_de": "46+",
        "name_nl": "46+",
        "name_pl": "46+",
        "name_ro": "46+",
        "name_ru": "46+",
        "name_tr": "46+",
        "name_se": "46+",
        "name_el": "46+",
        "name_es": "46+",
        "name_hr": "46+"
    },
    "608^prematch": {
        "name_en": "51-60",
        "name_aa": "51-60",
        "name_de": "51-60",
        "name_nl": "51-60",
        "name_pl": "51-60",
        "name_ro": "51-60",
        "name_ru": "51-60",
        "name_tr": "51-60",
        "name_se": "51-60",
        "name_el": "51-60",
        "name_es": "51-60",
        "name_hr": "51-60"
    },
    "1694160000^prematch": {
        "name_en": "Under 13.5",
        "name_aa": "Under 13.5",
        "name_de": "Under 13.5",
        "name_nl": "Under 13.5",
        "name_pl": "Under 13.5",
        "name_ro": "Under 13.5",
        "name_ru": "Under 13.5",
        "name_tr": "Under 13.5",
        "name_se": "Under 13.5",
        "name_el": "Under 13.5",
        "name_es": "Under 13.5",
        "name_hr": "Under 13.5"
    },
    "1146^prematch": {
        "name_en": "5-6",
        "name_aa": "5-6",
        "name_de": "5-6",
        "name_nl": "5-6",
        "name_pl": "5-6",
        "name_ro": "5-6",
        "name_ru": "5-6",
        "name_tr": "5-6",
        "name_se": "5-6",
        "name_el": "5-6",
        "name_es": "5-6",
        "name_hr": "5-6"
    },
    "1352^prematch": {
        "name_en": "{$competitor2} by decision",
        "name_aa": "{$competitor2} by decision",
        "name_de": "{$competitor2} by decision",
        "name_nl": "{$competitor2} by decision",
        "name_pl": "{$competitor2} by decision",
        "name_ro": "{$competitor2} by decision",
        "name_ru": "{$competitor2} by decision",
        "name_tr": "{$competitor2} by decision",
        "name_se": "{$competitor2} by decision",
        "name_el": "{$competitor2} by decision",
        "name_es": "{$competitor2} by decision",
        "name_hr": "{$competitor2} by decision"
    },
    "1490^prematch": {
        "name_en": "3:2",
        "name_aa": "3:2",
        "name_de": "3:2",
        "name_nl": "3:2",
        "name_pl": "3:2",
        "name_ro": "3:2",
        "name_ru": "3:2",
        "name_tr": "3:2",
        "name_se": "3:2",
        "name_el": "3:2",
        "name_es": "3:2",
        "name_hr": "3:2"
    },
    "261^prematch": {
        "name_en": "5:9",
        "name_aa": "5:9",
        "name_de": "5:9",
        "name_nl": "5:9",
        "name_pl": "5:9",
        "name_ro": "5:9",
        "name_ru": "5:9",
        "name_tr": "5:9",
        "name_se": "5:9",
        "name_el": "5:9",
        "name_es": "5:9",
        "name_hr": "5:9"
    },
    "272^prematch": {
        "name_en": "10:5",
        "name_aa": "10:5",
        "name_de": "10:5",
        "name_nl": "10:5",
        "name_pl": "10:5",
        "name_ro": "10:5",
        "name_ru": "10:5",
        "name_tr": "10:5",
        "name_se": "10:5",
        "name_el": "10:5",
        "name_es": "10:5",
        "name_hr": "10:5"
    },
    "1083^prematch": {
        "name_en": "23-26",
        "name_aa": "23-26",
        "name_de": "23-26",
        "name_nl": "23-26",
        "name_pl": "23-26",
        "name_ro": "23-26",
        "name_ru": "23-26",
        "name_tr": "23-26",
        "name_se": "23-26",
        "name_el": "23-26",
        "name_es": "23-26",
        "name_hr": "23-26"
    },
    "340^prematch": {
        "name_en": "0:0 2:0",
        "name_aa": "0:0 2:0",
        "name_de": "0:0 2:0",
        "name_nl": "0:0 2:0",
        "name_pl": "0:0 2:0",
        "name_ro": "0:0 2:0",
        "name_ru": "0:0 2:0",
        "name_tr": "0:0 2:0",
        "name_se": "0:0 2:0",
        "name_el": "0:0 2:0",
        "name_es": "0:0 2:0",
        "name_hr": "0:0 2:0"
    },
    "952^prematch": {
        "name_en": "2nd inning",
        "name_aa": "الشوط الثاني",
        "name_de": "2. Inning",
        "name_nl": "2e inning",
        "name_pl": "2. inning",
        "name_ro": "inning 2",
        "name_ru": "2ый иннинг",
        "name_tr": "2. devre",
        "name_se": "2:a inning",
        "name_el": "2ο inning",
        "name_es": "2º inning",
        "name_hr": "2. inning"
    },
    "1860^prematch": {
        "name_en": "{$competitor1}/draw  2",
        "name_aa": "{$competitor1} / رسم و 2",
        "name_de": "{$competitor1}/unentschieden  2",
        "name_nl": "{$competitor1}/gelijkspel  2",
        "name_pl": "{$competitor1}/remis  2",
        "name_ro": "{$competitor1}/egal  2",
        "name_ru": "{$competitor1}/ничья и 2",
        "name_tr": "{$competitor1}/beraberlik  2",
        "name_se": "{$competitor1}/oavgjort  2",
        "name_el": "{$competitor1}/ισοπαλία  2",
        "name_es": "{$competitor1}/empate  2",
        "name_hr": "{$competitor1}/neodlučeno i 2"
    },
    "284^prematch": {
        "name_en": "2:10",
        "name_aa": "2:10",
        "name_de": "2:10",
        "name_nl": "2:10",
        "name_pl": "2:10",
        "name_ro": "2:10",
        "name_ru": "2:10",
        "name_tr": "2:10",
        "name_se": "2:10",
        "name_el": "2:10",
        "name_es": "2:10",
        "name_hr": "2:10"
    },
    "1182^prematch": {
        "name_en": "3:4",
        "name_aa": "3:4",
        "name_de": "3:4",
        "name_nl": "3:4",
        "name_pl": "3:4",
        "name_ro": "3:4",
        "name_ru": "3:4",
        "name_tr": "3:4",
        "name_se": "3:4",
        "name_el": "3:4",
        "name_es": "3:4",
        "name_hr": "3:4"
    },
    "1355^prematch": {
        "name_en": "{$competitor1}  3",
        "name_aa": "{$competitor1}  3",
        "name_de": "{$competitor1}  3",
        "name_nl": "{$competitor1}  3",
        "name_pl": "{$competitor1}  3",
        "name_ro": "{$competitor1}  3",
        "name_ru": "{$competitor1}  3",
        "name_tr": "{$competitor1}  3",
        "name_se": "{$competitor1}  3",
        "name_el": "{$competitor1}  3",
        "name_es": "{$competitor1}  3",
        "name_hr": "{$competitor1}  3"
    },
    "1714^prematch": {
        "name_en": "{$competitor1} ({+hcp})",
        "name_aa": "{$competitor1} ({+hcp})",
        "name_de": "{$competitor1} ({+hcp})",
        "name_nl": "{$competitor1} ({hcp})",
        "name_pl": "{$competitor1} ({+hcp})",
        "name_ro": "{$competitor1} ({hcp})",
        "name_ru": "{$competitor1} ({+hcp})",
        "name_tr": "{$competitor1} ({+hcp})",
        "name_se": "{$competitor1} ({+hcp})",
        "name_el": "({+hcp}) {$competitor1}",
        "name_es": "{$competitor1} ({+hcp})",
        "name_hr": "{$competitor1} ({+hcp})"
    },
    "1292^prematch": {
        "name_en": "{$competitor2} by 16-20",
        "name_aa": "{$competitor2} by 16-20",
        "name_de": "{$competitor2} by 16-20",
        "name_nl": "{$competitor2} by 16-20",
        "name_pl": "{$competitor2} by 16-20",
        "name_ro": "{$competitor2} by 16-20",
        "name_ru": "{$competitor2} by 16-20",
        "name_tr": "{$competitor2} by 16-20",
        "name_se": "{$competitor2} by 16-20",
        "name_el": "{$competitor2} by 16-20",
        "name_es": "{$competitor2} by 16-20",
        "name_hr": "{$competitor2} by 16-20"
    },
    "954^prematch": {
        "name_en": "4th inning",
        "name_aa": "الشوط الرابع",
        "name_de": "4. Inning",
        "name_nl": "4e inning",
        "name_pl": "4. inning",
        "name_ro": "inning 4",
        "name_ru": "4ый иннинг",
        "name_tr": "4. devre",
        "name_se": "4:e inning",
        "name_el": "4ο inning",
        "name_es": "4º inning",
        "name_hr": "4. inning"
    },
    "92^prematch": {
        "name_en": "under {total}  yes",
        "name_aa": "تحت {total} و نعم",
        "name_de": "unter {total}  ja",
        "name_nl": "onder {total}  ja",
        "name_pl": "poniżej {total}  tak",
        "name_ro": "sub {total}  da",
        "name_ru": "меньше {total}  да",
        "name_tr": "{total} altı  var",
        "name_se": "under {total}  ja",
        "name_el": "ναι και under {total}",
        "name_es": "menos de {total} y sí",
        "name_hr": "manje od {total} i da"
    },
    "1873^prematch": {
        "name_en": "{$competitor1}/draw  4",
        "name_aa": "{$competitor1} / رسم و 4",
        "name_de": "{$competitor1}/unentschieden  4",
        "name_nl": "{$competitor1}/gelijkspel  4",
        "name_pl": "{$competitor1}/remis  4",
        "name_ro": "{$competitor1}/egal  4",
        "name_ru": "{$competitor1}/ничья и 4",
        "name_tr": "{$competitor1}/beraberlik  4",
        "name_se": "{$competitor1}/oavgjort  4",
        "name_el": "{$competitor1}/draw  4",
        "name_es": "{$competitor1}/empate  4",
        "name_hr": "{$competitor1}/neodlučeno i 4"
    },
    "113^prematch": {
        "name_en": "{$competitor1} by 1",
        "name_aa": "{$competitor1} by 1",
        "name_de": "{$competitor1} by 1",
        "name_nl": "{$competitor1} by 1",
        "name_pl": "{$competitor1} by 1",
        "name_ro": "{$competitor1} by 1",
        "name_ru": "{$competitor1} by 1",
        "name_tr": "{$competitor1} by 1",
        "name_se": "{$competitor1} by 1",
        "name_el": "{$competitor1} by 1",
        "name_es": "{$competitor1} by 1",
        "name_hr": "{$competitor1} by 1"
    },
    "1356^prematch": {
        "name_en": "{$competitor1}  decision",
        "name_aa": "{$competitor1}  decision",
        "name_de": "{$competitor1}  decision",
        "name_nl": "{$competitor1}  decision",
        "name_pl": "{$competitor1}  decision",
        "name_ro": "{$competitor1}  decision",
        "name_ru": "{$competitor1}  decision",
        "name_tr": "{$competitor1}  decision",
        "name_se": "{$competitor1}  decision",
        "name_el": "{$competitor1}  decision",
        "name_es": "{$competitor1}  decision",
        "name_hr": "{$competitor1}  decision"
    },
    "228^prematch": {
        "name_en": "4:7",
        "name_aa": "4:7",
        "name_de": "4:7",
        "name_nl": "4:7",
        "name_pl": "4:7",
        "name_ro": "4:7",
        "name_ru": "4:7",
        "name_tr": "4:7",
        "name_se": "4:7",
        "name_el": "4:7",
        "name_es": "4:7",
        "name_hr": "4:7"
    },
    "1724^prematch": {
        "name_en": "{$competitor1}/draw  under {total}",
        "name_aa": "{$competitor1} / رسم وتحت {total}",
        "name_de": "{$competitor1}/unentschieden  unter {total}",
        "name_nl": "{$competitor1}/gelijkspel  onder {total}",
        "name_pl": "{$competitor1}/remis  poniżej {total}",
        "name_ro": "{$competitor1}/egal  sub {total}",
        "name_ru": "{$competitor1}/ничья и меньше {total}",
        "name_tr": "{$competitor1}/beraberlik  {total} altı",
        "name_se": "{$competitor1}/oavgjort  under {total}",
        "name_el": "{$competitor1}/ ισοπαλία και under {total}",
        "name_es": "{$competitor1}/empate y menos de {total}",
        "name_hr": "{$competitor1}/neodlučeno i manje {total}"
    },
    "975^prematch": {
        "name_en": "{$competitor1}  under {total}",
        "name_aa": "{$competitor1}  under {total}",
        "name_de": "{$competitor1}  under {total}",
        "name_nl": "{$competitor1}  under {total}",
        "name_pl": "{$competitor1}  under {total}",
        "name_ro": "{$competitor1}  under {total}",
        "name_ru": "{$competitor1}  under {total}",
        "name_tr": "{$competitor1}  under {total}",
        "name_se": "{$competitor1}  under {total}",
        "name_el": "{$competitor1}  under {total}",
        "name_es": "{$competitor1}  under {total}",
        "name_hr": "{$competitor1}  under {total}"
    },
    "1694040000^prematch": {
        "name_en": "Under 7.5",
        "name_aa": "Under 7.5",
        "name_de": "Under 7.5",
        "name_nl": "Under 7.5",
        "name_pl": "Under 7.5",
        "name_ro": "Under 7.5",
        "name_ru": "Under 7.5",
        "name_tr": "Under 7.5",
        "name_se": "Under 7.5",
        "name_el": "Under 7.5",
        "name_es": "Under 7.5",
        "name_hr": "Under 7.5"
    },
    "1380^prematch": {
        "name_en": "{$competitor1}  1-3",
        "name_aa": "{$competitor1}  1-3",
        "name_de": "{$competitor1}  1-3",
        "name_nl": "{$competitor1}  1-3",
        "name_pl": "{$competitor1}  1-3",
        "name_ro": "{$competitor1}  1-3",
        "name_ru": "{$competitor1}  1-3",
        "name_tr": "{$competitor1}  1-3",
        "name_se": "{$competitor1}  1-3",
        "name_el": "{$competitor1}  1-3",
        "name_es": "{$competitor1}  1-3",
        "name_hr": "{$competitor1}  1-3"
    },
    "734^prematch": {
        "name_en": "3",
        "name_aa": "3",
        "name_de": "3",
        "name_nl": "3",
        "name_pl": "3",
        "name_ro": "3",
        "name_ru": "3",
        "name_tr": "3",
        "name_se": "3",
        "name_el": "3",
        "name_es": "3",
        "name_hr": "3"
    },
    "170^prematch": {
        "name_en": "171-180",
        "name_aa": "171-180",
        "name_de": "171-180",
        "name_nl": "171-180",
        "name_pl": "171-180",
        "name_ro": "171-180",
        "name_ru": "171-180",
        "name_tr": "171-180",
        "name_se": "171-180",
        "name_el": "171-180",
        "name_es": "171-180",
        "name_hr": "171-180"
    },
    "167^prematch": {
        "name_en": "0-150",
        "name_aa": "0-150",
        "name_de": "0-150",
        "name_nl": "0-150",
        "name_pl": "0-150",
        "name_ro": "0-150",
        "name_ru": "0-150",
        "name_tr": "0-150",
        "name_se": "0-150",
        "name_el": "0-150",
        "name_es": "0-150",
        "name_hr": "0-150"
    },
    "1730^prematch": {
        "name_en": "1-2",
        "name_aa": "1-2",
        "name_de": "1-2",
        "name_nl": "1-2",
        "name_pl": "1-2",
        "name_ro": "1-2",
        "name_ru": "1-2",
        "name_tr": "1-2",
        "name_se": "1-2",
        "name_el": "1-2",
        "name_es": "1-2",
        "name_hr": "1-2"
    },
    "81^prematch": {
        "name_en": "6",
        "name_aa": "6",
        "name_de": "6",
        "name_nl": "6",
        "name_pl": "6",
        "name_ro": "6",
        "name_ru": "6",
        "name_tr": "6",
        "name_se": "6",
        "name_el": "6",
        "name_es": "6",
        "name_hr": "6"
    },
    "70780000^prematch": {
        "name_en": "1-X",
        "name_aa": "1-X",
        "name_de": "1-X",
        "name_nl": "1-X",
        "name_pl": "1-X",
        "name_ro": "1-X",
        "name_ru": "1-X",
        "name_tr": "1-X",
        "name_se": "1-X",
        "name_el": "1-X",
        "name_es": "1-X",
        "name_hr": "1-X"
    },
    "1389^prematch": {
        "name_en": "{$competitor2}  10-12",
        "name_aa": "{$competitor2}  10-12",
        "name_de": "{$competitor2}  10-12",
        "name_nl": "{$competitor2}  10-12",
        "name_pl": "{$competitor2}  10-12",
        "name_ro": "{$competitor2}  10-12",
        "name_ru": "{$competitor2}  10-12",
        "name_tr": "{$competitor2}  10-12",
        "name_se": "{$competitor2}  10-12",
        "name_el": "{$competitor2}  10-12",
        "name_es": "{$competitor2}  10-12",
        "name_hr": "{$competitor2}  10-12"
    },
    "1404^prematch": {
        "name_en": "{$competitor1}  2",
        "name_aa": "{$competitor1}  2",
        "name_de": "{$competitor1}  2",
        "name_nl": "{$competitor1}  2",
        "name_pl": "{$competitor1}  2",
        "name_ro": "{$competitor1}  2",
        "name_ru": "{$competitor1}  2",
        "name_tr": "{$competitor1}  2",
        "name_se": "{$competitor1}  2",
        "name_el": "{$competitor1}  2",
        "name_es": "{$competitor1}  2",
        "name_hr": "{$competitor1}  2"
    },
    "953^prematch": {
        "name_en": "3rd inning",
        "name_aa": "الشوط الثالث",
        "name_de": "3. Inning",
        "name_nl": "3e inning",
        "name_pl": "3. inning",
        "name_ro": "inning 3",
        "name_ru": "3ий иннинг",
        "name_tr": "3. devre",
        "name_se": "3:e inning",
        "name_el": "3ο inning",
        "name_es": "3º inning",
        "name_hr": "3. inning"
    },
    "70770000^prematch": {
        "name_en": "1-1",
        "name_aa": "1-1",
        "name_de": "1-1",
        "name_nl": "1-1",
        "name_pl": "1-1",
        "name_ro": "1-1",
        "name_ru": "1-1",
        "name_tr": "1-1",
        "name_se": "1-1",
        "name_el": "1-1",
        "name_es": "1-1",
        "name_hr": "1-1"
    },
    "1762^prematch": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2"
    },
    "1096^prematch": {
        "name_en": "21-27",
        "name_aa": "21-27",
        "name_de": "21-27",
        "name_nl": "21-27",
        "name_pl": "21-27",
        "name_ro": "21-27",
        "name_ru": "21-27",
        "name_tr": "21-27",
        "name_se": "21-27",
        "name_el": "21-27",
        "name_es": "21-27",
        "name_hr": "21-27"
    },
    "1882^prematch": {
        "name_en": "{$competitor1}/draw  5+",
        "name_aa": "{$competitor1} / رسم و5+",
        "name_de": "{$competitor1}/unentschieden  5+",
        "name_nl": "{$competitor1}/gelijkspel  5+",
        "name_pl": "{$competitor1}/remis  5+",
        "name_ro": "{$competitor1}/egal  5+",
        "name_ru": "{$competitor1}/ничья и 5+",
        "name_tr": "{$competitor1}/beraberlik  5+",
        "name_se": "{$competitor1}/oavgjort 5+",
        "name_el": "{$competitor1}/ισοπαλία  5+",
        "name_es": "{$competitor1}/draw  5+",
        "name_hr": "{$competitor1}/neodlučeno i 5+"
    },
    "1694130000^prematch": {
        "name_en": "Over 12.5",
        "name_aa": "Over 12.5",
        "name_de": "Over 12.5",
        "name_nl": "Over 12.5",
        "name_pl": "Over 12.5",
        "name_ro": "Over 12.5",
        "name_ru": "Over 12.5",
        "name_tr": "Over 12.5",
        "name_se": "Over 12.5",
        "name_el": "Over 12.5",
        "name_es": "Over 12.5",
        "name_hr": "Over 12.5"
    },
    "295^prematch": {
        "name_en": "11:8",
        "name_aa": "11:8",
        "name_de": "11:8",
        "name_nl": "11:8",
        "name_pl": "11:8",
        "name_ro": "11:8",
        "name_ru": "11:8",
        "name_tr": "11:8",
        "name_se": "11:8",
        "name_el": "11:8",
        "name_es": "11:8",
        "name_hr": "11:8"
    },
    "941^prematch": {
        "name_en": "over {total}",
        "name_aa": "أكثر من {total}",
        "name_de": "über {total}",
        "name_nl": "boven {total}",
        "name_pl": "ponad {total}",
        "name_ro": "peste {total}",
        "name_ru": "больше {total}",
        "name_tr": "{total} üstünde",
        "name_se": "över {total}",
        "name_el": "over {total}",
        "name_es": "más de {total}",
        "name_hr": "više od {total}"
    },
    "83560000^prematch": {
        "name_en": "No",
        "name_aa": "No",
        "name_de": "No",
        "name_nl": "No",
        "name_pl": "No",
        "name_ro": "No",
        "name_ru": "No",
        "name_tr": "No",
        "name_se": "No",
        "name_el": "No",
        "name_es": "No",
        "name_hr": "No"
    },
    "189^prematch": {
        "name_en": "2:6",
        "name_aa": "2:6",
        "name_de": "2:6",
        "name_nl": "2:6",
        "name_pl": "2:6",
        "name_ro": "2:6",
        "name_ru": "2:6",
        "name_tr": "2:6",
        "name_se": "2:6",
        "name_el": "2:6",
        "name_es": "2:6",
        "name_hr": "2:6"
    },
    "1867^prematch": {
        "name_en": "{$competitor1}/{$competitor2}  3",
        "name_aa": "{$competitor1} / {$competitor2} و 3",
        "name_de": "{$competitor1}/{$competitor2}  3",
        "name_nl": "{$competitor1}/{$competitor2}  3",
        "name_pl": "{$competitor1}/{$competitor2}  3",
        "name_ro": "{$competitor1}/{$competitor2}  3",
        "name_ru": "{$competitor1}/{$competitor2} и 3",
        "name_tr": "{$competitor1}/{$competitor2}  3",
        "name_se": "{$competitor1}/{$competitor2}  3",
        "name_el": "{$competitor1}/{$competitor2}  3",
        "name_es": "{$competitor1}/{$competitor2}  3",
        "name_hr": "{$competitor1}/{$competitor2} i 3"
    },
    "1694280000^prematch": {
        "name_en": "Under 5.5",
        "name_aa": "Under 5.5",
        "name_de": "Under 5.5",
        "name_nl": "Under 5.5",
        "name_pl": "Under 5.5",
        "name_ro": "Under 5.5",
        "name_ru": "Under 5.5",
        "name_tr": "Under 5.5",
        "name_se": "Under 5.5",
        "name_el": "Under 5.5",
        "name_es": "Under 5.5",
        "name_hr": "Under 5.5"
    },
    "1694680000^prematch": {
        "name_en": "Yes",
        "name_aa": "Yes",
        "name_de": "Yes",
        "name_nl": "Yes",
        "name_pl": "Yes",
        "name_ro": "Yes",
        "name_ru": "Yes",
        "name_tr": "Yes",
        "name_se": "Yes",
        "name_el": "Yes",
        "name_es": "Yes",
        "name_hr": "Yes"
    },
    "1863^prematch": {
        "name_en": "draw/{$competitor2}  2",
        "name_aa": "رسم / {$competitor2} و 2",
        "name_de": "unentschieden/{$competitor2}  2",
        "name_nl": "gelijkspel/{$competitor2}  2",
        "name_pl": "remis/{$competitor2}  2",
        "name_ro": "egal/{$competitor2}  2",
        "name_ru": "ничья/{$competitor2} и 2",
        "name_tr": "beraberlik/{$competitor2}  2",
        "name_se": "oavgjort/{$competitor2}  2",
        "name_el": "ισοπαλία/{$competitor2}  2",
        "name_es": "empate/{$competitor2}  2",
        "name_hr": "neodlučeno/{$competitor2} i 2"
    },
    "336^prematch": {
        "name_en": "0:0 1:1",
        "name_aa": "0:0 1:1",
        "name_de": "0:0 1:1",
        "name_nl": "0:0 1:1",
        "name_pl": "0:0 1:1",
        "name_ro": "0:0 1:1",
        "name_ru": "0:0 1:1",
        "name_tr": "0:0 1:1",
        "name_se": "0:0 1:1",
        "name_el": "0:0 1:1",
        "name_es": "0:0 1:1",
        "name_hr": "0:0 1:1"
    },
    "1851^prematch": {
        "name_en": "{$competitor2}/{$competitor1}  over {total}",
        "name_aa": "{$competitor2} / {$competitor1} وأكثر من {total}",
        "name_de": "{$competitor2}/{$competitor1}  über {total}",
        "name_nl": "{$competitor2}/{$competitor1}  boven {total}",
        "name_pl": "{$competitor2}/{$competitor1}  ponad {total}",
        "name_ro": "{$competitor2}/{$competitor1}  peste {total}",
        "name_ru": "{$competitor2}/{$competitor1} и больше {total}",
        "name_tr": "{$competitor2}/{$competitor1}  {total} üstü",
        "name_se": "{$competitor2}/{$competitor1}  över {total}",
        "name_el": "{$competitor2}/{$competitor1}  over {total}",
        "name_es": "{$competitor2}/{$competitor1}  más de {total}",
        "name_hr": "{$competitor2}/{$competitor1} i više {total}"
    },
    "1430000^prematch": {
        "name_en": "{$competitor2}",
        "name_aa": "{$competitor2}",
        "name_de": "{$competitor2}",
        "name_nl": "{$competitor2}",
        "name_pl": "{$competitor2}",
        "name_ro": "{$competitor2}",
        "name_ru": "{$competitor2}",
        "name_tr": "{$competitor2}",
        "name_se": "{$competitor2}",
        "name_el": "{$competitor2}",
        "name_es": "{$competitor2}",
        "name_hr": "{$competitor2}"
    },
    "922^prematch": {
        "name_en": "3rd quarter",
        "name_aa": "الربع الثالث",
        "name_de": "3. Viertel",
        "name_nl": "Derde kwart",
        "name_pl": "3. kwarta",
        "name_ro": "Sfertul 3",
        "name_ru": "3ая четверть",
        "name_tr": "Üçüncü çeyrek",
        "name_se": "3:e quarter",
        "name_el": "3η περίοδος",
        "name_es": "3º cuarto",
        "name_hr": "3. četvrtina"
    },
    "550^prematch": {
        "name_en": "0:2",
        "name_aa": "0:2",
        "name_de": "0:2",
        "name_nl": "0:2",
        "name_pl": "0:2",
        "name_ro": "0:2",
        "name_ru": "0:2",
        "name_tr": "0:2",
        "name_se": "0:2",
        "name_el": "0:2",
        "name_es": "0:2",
        "name_hr": "0:2"
    },
    "1839^prematch": {
        "name_en": "draw/{$competitor1}  under {total}",
        "name_aa": "رسم / {$competitor1} و تحت{total}",
        "name_de": "unentschieden /{$competitor1}  unter {total}",
        "name_nl": "gelijkspel/{$competitor1}  onder {total}",
        "name_pl": "remis/{$competitor1}  poniżej {total}",
        "name_ro": "egal/{$competitor1}  sub {total}",
        "name_ru": "ничья/{$competitor1} или меньше {total}",
        "name_tr": "beraberlik/{$competitor1}  {total} altı",
        "name_se": "oavgjort/{$competitor1}  under {total}",
        "name_el": "ισοπαλία /{$competitor1}  under {total}",
        "name_es": "empate/{$competitor1}  menos de {total}",
        "name_hr": "neodlučeno/{$competitor1} i manje {total}"
    },
    "869^prematch": {
        "name_en": "6:4",
        "name_aa": "6:4",
        "name_de": "6:4",
        "name_nl": "6:4",
        "name_pl": "6:4",
        "name_ro": "6:4",
        "name_ru": "6:4",
        "name_tr": "6:4",
        "name_se": "6:4",
        "name_el": "6:4",
        "name_es": "6:4",
        "name_hr": "6:4"
    },
    "28^prematch": {
        "name_en": "3:5",
        "name_aa": "3:5",
        "name_de": "3:5",
        "name_nl": "3:5",
        "name_pl": "3:5",
        "name_ro": "3:5",
        "name_ru": "3:5",
        "name_tr": "3:5",
        "name_se": "3:5",
        "name_el": "3:5",
        "name_es": "3:5",
        "name_hr": "3:5"
    },
    "198^prematch": {
        "name_en": "6:6",
        "name_aa": "6:6",
        "name_de": "6:6",
        "name_nl": "6:6",
        "name_pl": "6:6",
        "name_ro": "6:6",
        "name_ru": "6:6",
        "name_tr": "6:6",
        "name_se": "6:6",
        "name_el": "6:6",
        "name_es": "6:6",
        "name_hr": "6:6"
    },
    "1170^prematch": {
        "name_en": "1:5",
        "name_aa": "1:5",
        "name_de": "1:5",
        "name_nl": "1:5",
        "name_pl": "1:5",
        "name_ro": "1:5",
        "name_ru": "1:5",
        "name_tr": "1:5",
        "name_se": "1:5",
        "name_el": "1:5",
        "name_es": "1:5",
        "name_hr": "1:5"
    },
    "289^prematch": {
        "name_en": "11:2",
        "name_aa": "11:2",
        "name_de": "11:2",
        "name_nl": "11:2",
        "name_pl": "11:2",
        "name_ro": "11:2",
        "name_ru": "11:2",
        "name_tr": "11:2",
        "name_se": "11:2",
        "name_el": "11:2",
        "name_es": "11:2",
        "name_hr": "11:2"
    },
    "20^prematch": {
        "name_en": "1:4",
        "name_aa": "1:4",
        "name_de": "1:4",
        "name_nl": "1:4",
        "name_pl": "1:4",
        "name_ro": "1:4",
        "name_ru": "1:4",
        "name_tr": "1:4",
        "name_se": "1:4",
        "name_el": "1:4",
        "name_es": "1:4",
        "name_hr": "1:4"
    },
    "25^prematch": {
        "name_en": "5:3",
        "name_aa": "5:3",
        "name_de": "5:3",
        "name_nl": "5:3",
        "name_pl": "5:3",
        "name_ro": "5:3",
        "name_ru": "5:3",
        "name_tr": "5:3",
        "name_se": "5:3",
        "name_el": "5:3",
        "name_es": "5:3",
        "name_hr": "5:3"
    },
    "22^prematch": {
        "name_en": "5:0",
        "name_aa": "5:0",
        "name_de": "5:0",
        "name_nl": "5:0",
        "name_pl": "5:0",
        "name_ro": "5:0",
        "name_ru": "5:0",
        "name_tr": "5:0",
        "name_se": "5:0",
        "name_el": "5:0",
        "name_es": "5:0",
        "name_hr": "5:0"
    },
    "1350^prematch": {
        "name_en": "{$competitor2} by ko",
        "name_aa": "{$competitor2} by ko",
        "name_de": "{$competitor2} by ko",
        "name_nl": "{$competitor2} by ko",
        "name_pl": "{$competitor2} by ko",
        "name_ro": "{$competitor2} by ko",
        "name_ru": "{$competitor2} by ko",
        "name_tr": "{$competitor2} by ko",
        "name_se": "{$competitor2} by ko",
        "name_el": "{$competitor2} by ko",
        "name_es": "{$competitor2} by ko",
        "name_hr": "{$competitor2} by ko"
    },
    "1386^prematch": {
        "name_en": "{$competitor2}  1-3",
        "name_aa": "{$competitor2}  1-3",
        "name_de": "{$competitor2}  1-3",
        "name_nl": "{$competitor2}  1-3",
        "name_pl": "{$competitor2}  1-3",
        "name_ro": "{$competitor2}  1-3",
        "name_ru": "{$competitor2}  1-3",
        "name_tr": "{$competitor2}  1-3",
        "name_se": "{$competitor2}  1-3",
        "name_el": "{$competitor2}  1-3",
        "name_es": "{$competitor2}  1-3",
        "name_hr": "{$competitor2}  1-3"
    },
    "168^prematch": {
        "name_en": "151-160",
        "name_aa": "151-160",
        "name_de": "151-160",
        "name_nl": "151-160",
        "name_pl": "151-160",
        "name_ro": "151-160",
        "name_ru": "151-160",
        "name_tr": "151-160",
        "name_se": "151-160",
        "name_el": "151-160",
        "name_es": "151-160",
        "name_hr": "151-160"
    },
    "472^prematch": {
        "name_en": "2:1",
        "name_aa": "2:1",
        "name_de": "2:1",
        "name_nl": "2:1",
        "name_pl": "2:1",
        "name_ro": "2:1",
        "name_ru": "2:1",
        "name_tr": "2:1",
        "name_se": "2:1",
        "name_el": "2:1",
        "name_es": "2:1",
        "name_hr": "2:1"
    },
    "1291^prematch": {
        "name_en": "{$competitor2} by 11-15",
        "name_aa": "{$competitor2} by 11-15",
        "name_de": "{$competitor2} by 11-15",
        "name_nl": "{$competitor2} by 11-15",
        "name_pl": "{$competitor2} by 11-15",
        "name_ro": "{$competitor2} by 11-15",
        "name_ru": "{$competitor2} by 11-15",
        "name_tr": "{$competitor2} by 11-15",
        "name_se": "{$competitor2} by 11-15",
        "name_el": "{$competitor2} by 11-15",
        "name_es": "{$competitor2} by 11-15",
        "name_hr": "{$competitor2} by 11-15"
    },
    "1418^prematch": {
        "name_en": "{$competitor2}  2",
        "name_aa": "{$competitor2}  2",
        "name_de": "{$competitor2}  2",
        "name_nl": "{$competitor2}  2",
        "name_pl": "{$competitor2}  2",
        "name_ro": "{$competitor2}  2",
        "name_ru": "{$competitor2}  2",
        "name_tr": "{$competitor2}  2",
        "name_se": "{$competitor2}  2",
        "name_el": "{$competitor2}  2",
        "name_es": "{$competitor2}  2",
        "name_hr": "{$competitor2}  2"
    },
    "1694150000^prematch": {
        "name_en": "Over 13.5",
        "name_aa": "Over 13.5",
        "name_de": "Over 13.5",
        "name_nl": "Over 13.5",
        "name_pl": "Over 13.5",
        "name_ro": "Over 13.5",
        "name_ru": "Over 13.5",
        "name_tr": "Over 13.5",
        "name_se": "Over 13.5",
        "name_el": "Over 13.5",
        "name_es": "Over 13.5",
        "name_hr": "Over 13.5"
    },
    "1384^prematch": {
        "name_en": "{$competitor1}  decision",
        "name_aa": "{$competitor1}  decision",
        "name_de": "{$competitor1}  decision",
        "name_nl": "{$competitor1}  decision",
        "name_pl": "{$competitor1}  decision",
        "name_ro": "{$competitor1}  decision",
        "name_ru": "{$competitor1}  decision",
        "name_tr": "{$competitor1}  decision",
        "name_se": "{$competitor1}  decision",
        "name_el": "{$competitor1}  decision",
        "name_es": "{$competitor1}  decision",
        "name_hr": "{$competitor1}  decision"
    },
    "1347^prematch": {
        "name_en": "{$competitor1} by submission",
        "name_aa": "{$competitor1} by submission",
        "name_de": "{$competitor1} by submission",
        "name_nl": "{$competitor1} by submission",
        "name_pl": "{$competitor1} by submission",
        "name_ro": "{$competitor1} by submission",
        "name_ru": "{$competitor1} by submission",
        "name_tr": "{$competitor1} by submission",
        "name_se": "{$competitor1} by submission",
        "name_el": "{$competitor1} by submission",
        "name_es": "{$competitor1} by submission",
        "name_hr": "{$competitor1} by submission"
    },
    "1755^prematch": {
        "name_en": "1:2, 1:3 or 1:4",
        "name_aa": "1:2, 1:3 أو 1:4",
        "name_de": "1:2, 1:3 oder 1:4",
        "name_nl": "1:2, 1:3 of 1:4",
        "name_pl": "1:2, 1:3 lub 1:4",
        "name_ro": "1:2, 1:3 sau 1:4",
        "name_ru": "1:2, 1:3 или 1:4",
        "name_tr": "1:2, 1:3 veya 1:4",
        "name_se": "1:2, 1:3 elr 1:4",
        "name_el": "1-2, 1-3 ή 1-4",
        "name_es": "1:2, 1:3 o 1:4",
        "name_hr": "1:2, 1:3 ili 1:4"
    },
    "1167^prematch": {
        "name_en": "1:2",
        "name_aa": "1:2",
        "name_de": "1:2",
        "name_nl": "1:2",
        "name_pl": "1:2",
        "name_ro": "1:2",
        "name_ru": "1:2",
        "name_tr": "1:2",
        "name_se": "1:2",
        "name_el": "1:2",
        "name_es": "1:2",
        "name_hr": "1:2"
    },
    "1732^prematch": {
        "name_en": "1-4",
        "name_aa": "1-4",
        "name_de": "1-4",
        "name_nl": "1-4",
        "name_pl": "1-4",
        "name_ro": "1-4",
        "name_ru": "1-4",
        "name_tr": "1-4",
        "name_se": "1-4",
        "name_el": "1-4",
        "name_es": "1-4",
        "name_hr": "1-4"
    },
    "277^prematch": {
        "name_en": "9:10",
        "name_aa": "9:10",
        "name_de": "9:10",
        "name_nl": "9:10",
        "name_pl": "9:10",
        "name_ro": "9:10",
        "name_ru": "9:10",
        "name_tr": "9:10",
        "name_se": "9:10",
        "name_el": "9:10",
        "name_es": "9:10",
        "name_hr": "9:10"
    },
    "308^prematch": {
        "name_en": "0:11",
        "name_aa": "0:11",
        "name_de": "0:11",
        "name_nl": "0:11",
        "name_pl": "0:11",
        "name_ro": "0:11",
        "name_ru": "0:11",
        "name_tr": "0:11",
        "name_se": "0:11",
        "name_el": "0:11",
        "name_es": "0:11",
        "name_hr": "0:11"
    },
    "274^prematch": {
        "name_en": "10:7",
        "name_aa": "10:7",
        "name_de": "10:7",
        "name_nl": "10:7",
        "name_pl": "10:7",
        "name_ro": "10:7",
        "name_ru": "10:7",
        "name_tr": "10:7",
        "name_se": "10:7",
        "name_el": "10:7",
        "name_es": "10:7",
        "name_hr": "10:7"
    },
    "872^prematch": {
        "name_en": "0:6",
        "name_aa": "0:6",
        "name_de": "0:6",
        "name_nl": "0:6",
        "name_pl": "0:6",
        "name_ro": "0:6",
        "name_ru": "0:6",
        "name_tr": "0:6",
        "name_se": "0:6",
        "name_el": "0:6",
        "name_es": "0:6",
        "name_hr": "0:6"
    },
    "584^prematch": {
        "name_en": "1-15",
        "name_aa": "1-15",
        "name_de": "1-15",
        "name_nl": "1-15",
        "name_pl": "1-15",
        "name_ro": "1-15",
        "name_ru": "1-15",
        "name_tr": "1-15",
        "name_se": "1-15",
        "name_el": "1-15",
        "name_es": "1-15",
        "name_hr": "1-15"
    },
    "60020000^prematch": {
        "name_en": "4+/5+",
        "name_aa": "4+/5+",
        "name_de": "4+/5+",
        "name_nl": "4+/5+",
        "name_pl": "4+/5+",
        "name_ro": "4+/5+",
        "name_ru": "4+/5+",
        "name_tr": "4+/5+",
        "name_se": "4+/5+",
        "name_el": "4+/5+",
        "name_es": "4+/5+",
        "name_hr": "4+/5+"
    },
    "115^prematch": {
        "name_en": "{$competitor1} by 3+",
        "name_aa": "{$competitor1} by 3+",
        "name_de": "{$competitor1} by 3+",
        "name_nl": "{$competitor1} by 3+",
        "name_pl": "{$competitor1} by 3+",
        "name_ro": "{$competitor1} by 3+",
        "name_ru": "{$competitor1} by 3+",
        "name_tr": "{$competitor1} by 3+",
        "name_se": "{$competitor1} by 3+",
        "name_el": "{$competitor1} by 3+",
        "name_es": "{$competitor1} by 3+",
        "name_hr": "{$competitor1} by 3+"
    },
    "6^prematch": {
        "name_en": "1:2",
        "name_aa": "1:2",
        "name_de": "1:2",
        "name_nl": "1:2",
        "name_pl": "1:2",
        "name_ro": "1:2",
        "name_ru": "1:2",
        "name_tr": "1:2",
        "name_se": "1:2",
        "name_el": "1:2",
        "name_es": "1:2",
        "name_hr": "1:2"
    },
    "55^prematch": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2"
    },
    "356^prematch": {
        "name_en": "0:1 1:2",
        "name_aa": "0:1 1:2",
        "name_de": "0:1 1:2",
        "name_nl": "0:1 1:2",
        "name_pl": "0:1 1:2",
        "name_ro": "0:1 1:2",
        "name_ru": "0:1 1:2",
        "name_tr": "0:1 1:2",
        "name_se": "0:1 1:2",
        "name_el": "0:1 1:2",
        "name_es": "0:1 1:2",
        "name_hr": "0:1 1:2"
    },
    "736^prematch": {
        "name_en": "4+",
        "name_aa": "4+",
        "name_de": "4+",
        "name_nl": "4+",
        "name_pl": "4+",
        "name_ro": "4+",
        "name_ru": "4+",
        "name_tr": "4+",
        "name_se": "4+",
        "name_el": "4+",
        "name_es": "4+",
        "name_hr": "4+"
    },
    "974^prematch": {
        "name_en": "{$competitor2}  over {total}",
        "name_aa": "{$competitor2}  over {total}",
        "name_de": "{$competitor2}  over {total}",
        "name_nl": "{$competitor2}  over {total}",
        "name_pl": "{$competitor2}  over {total}",
        "name_ro": "{$competitor2}  over {total}",
        "name_ru": "{$competitor2}  over {total}",
        "name_tr": "{$competitor2}  over {total}",
        "name_se": "{$competitor2}  over {total}",
        "name_el": "{$competitor2}  over {total}",
        "name_es": "{$competitor2}  over {total}",
        "name_hr": "{$competitor2}  over {total}"
    },
    "31^prematch": {
        "name_en": "0:5",
        "name_aa": "0:5",
        "name_de": "0:5",
        "name_nl": "0:5",
        "name_pl": "0:5",
        "name_ro": "0:5",
        "name_ru": "0:5",
        "name_tr": "0:5",
        "name_se": "0:5",
        "name_el": "0:5",
        "name_es": "0:5",
        "name_hr": "0:5"
    },
    "1192^prematch": {
        "name_en": "7:0",
        "name_aa": "7:0",
        "name_de": "7:0",
        "name_nl": "7:0",
        "name_pl": "7:0",
        "name_ro": "7:0",
        "name_ru": "7:0",
        "name_tr": "7:0",
        "name_se": "7:0",
        "name_el": "7:0",
        "name_es": "7:0",
        "name_hr": "7:0"
    },
    "70830000^prematch": {
        "name_en": "2-1",
        "name_aa": "2-1",
        "name_de": "2-1",
        "name_nl": "2-1",
        "name_pl": "2-1",
        "name_ro": "2-1",
        "name_ru": "2-1",
        "name_tr": "2-1",
        "name_se": "2-1",
        "name_el": "2-1",
        "name_es": "2-1",
        "name_hr": "2-1"
    },
    "241^prematch": {
        "name_en": "7:8",
        "name_aa": "7:8",
        "name_de": "7:8",
        "name_nl": "7:8",
        "name_pl": "7:8",
        "name_ro": "7:8",
        "name_ru": "7:8",
        "name_tr": "7:8",
        "name_se": "7:8",
        "name_el": "7:8",
        "name_es": "7:8",
        "name_hr": "7:8"
    },
    "127^prematch": {
        "name_en": "{$competitor2} by 1-5",
        "name_aa": "{$competitor2} by 1-5",
        "name_de": "{$competitor2} by 1-5",
        "name_nl": "{$competitor2} by 1-5",
        "name_pl": "{$competitor2} by 1-5",
        "name_ro": "{$competitor2} by 1-5",
        "name_ru": "{$competitor2} by 1-5",
        "name_tr": "{$competitor2} by 1-5",
        "name_se": "{$competitor2} by 1-5",
        "name_el": "{$competitor2} by 1-5",
        "name_es": "{$competitor2} by 1-5",
        "name_hr": "{$competitor2} by 1-5"
    },
    "60090000^prematch": {
        "name_en": "6+/4+",
        "name_aa": "6+/4+",
        "name_de": "6+/4+",
        "name_nl": "6+/4+",
        "name_pl": "6+/4+",
        "name_ro": "6+/4+",
        "name_ru": "6+/4+",
        "name_tr": "6+/4+",
        "name_se": "6+/4+",
        "name_el": "6+/4+",
        "name_es": "6+/4+",
        "name_hr": "6+/4+"
    },
    "1711^prematch": {
        "name_en": "{$competitor1} ({hcp})",
        "name_aa": "{$competitor1} ({hcp})",
        "name_de": "{$competitor1} ({hcp})",
        "name_nl": "{$competitor1} ({hcp})",
        "name_pl": "{$competitor1} ({hcp})",
        "name_ro": "{$competitor1} ({hcp})",
        "name_ru": "{$competitor1} ({hcp})",
        "name_tr": "{$competitor1} ({hcp})",
        "name_se": "{$competitor1} ({hcp})",
        "name_el": "({hcp}) {$competitor1}",
        "name_es": "{$competitor1} ({hcp})",
        "name_hr": "{$competitor1} ({hcp})"
    },
    "1476^prematch": {
        "name_en": "1:0",
        "name_aa": "1:0",
        "name_de": "1:0",
        "name_nl": "1:0",
        "name_pl": "1:0",
        "name_ro": "1:0",
        "name_ru": "1:0",
        "name_tr": "1:0",
        "name_se": "1:0",
        "name_el": "1:0",
        "name_es": "1:0",
        "name_hr": "1:0"
    },
    "221^prematch": {
        "name_en": "7:2",
        "name_aa": "7:2",
        "name_de": "7:2",
        "name_nl": "7:2",
        "name_pl": "7:2",
        "name_ro": "7:2",
        "name_ru": "7:2",
        "name_tr": "7:2",
        "name_se": "7:2",
        "name_el": "7:2",
        "name_es": "7:2",
        "name_hr": "7:2"
    },
    "1089^prematch": {
        "name_en": "31-35",
        "name_aa": "31-35",
        "name_de": "31-35",
        "name_nl": "31-35",
        "name_pl": "31-35",
        "name_ro": "31-35",
        "name_ru": "31-35",
        "name_tr": "31-35",
        "name_se": "31-35",
        "name_el": "31-35",
        "name_es": "31-35",
        "name_hr": "31-35"
    },
    "1751^prematch": {
        "name_en": "0:1, 0:2 or 0:3",
        "name_aa": "0:1, 0:2 أو 0:3",
        "name_de": "0:1, 0:2 oder 0:3",
        "name_nl": "0:1, 0:2 of 0:3",
        "name_pl": "0:1, 0:2 lub 0:3",
        "name_ro": "0:1, 0:2 sau 0:3",
        "name_ru": "0:1, 0:2 или 0:3",
        "name_tr": "0:1, 0:2 veya 0:3",
        "name_se": "0:1, 0:2 elr 0:3",
        "name_el": "0-1, 0-2 ή 0-3",
        "name_es": "0:1, 0:2 o 0:3",
        "name_hr": "0:1, 0:2 ili 0:3"
    },
    "1483^prematch": {
        "name_en": "2:1",
        "name_aa": "2:1",
        "name_de": "2:1",
        "name_nl": "2:1",
        "name_pl": "2:1",
        "name_ro": "2:1",
        "name_ru": "2:1",
        "name_tr": "2:1",
        "name_se": "2:1",
        "name_el": "2:1",
        "name_es": "2:1",
        "name_hr": "2:1"
    },
    "604^prematch": {
        "name_en": "31-40",
        "name_aa": "31-40",
        "name_de": "31-40",
        "name_nl": "31-40",
        "name_pl": "31-40",
        "name_ro": "31-40",
        "name_ru": "31-40",
        "name_tr": "31-40",
        "name_se": "31-40",
        "name_el": "31-40",
        "name_es": "31-40",
        "name_hr": "31-40"
    },
    "961^prematch": {
        "name_en": "0",
        "name_aa": "0",
        "name_de": "0",
        "name_nl": "0",
        "name_pl": "0",
        "name_ro": "0",
        "name_ru": "0",
        "name_tr": "0",
        "name_se": "0",
        "name_el": "0",
        "name_es": "0",
        "name_hr": "0"
    },
    "5^prematch": {
        "name_en": "{$competitor2}",
        "name_aa": "{$competitor2}",
        "name_de": "{$competitor2}",
        "name_nl": "{$competitor2}",
        "name_pl": "{$competitor2}",
        "name_ro": "{$competitor2}",
        "name_ru": "{$competitor2}",
        "name_tr": "{$competitor2}",
        "name_se": "{$competitor2}",
        "name_el": "{$competitor2}",
        "name_es": "{$competitor2}",
        "name_hr": "{$competitor2}"
    },
    "404^prematch": {
        "name_en": "2:0 3:0",
        "name_aa": "2:0 3:0",
        "name_de": "2:0 3:0",
        "name_nl": "2:0 3:0",
        "name_pl": "2:0 3:0",
        "name_ro": "2:0 3:0",
        "name_ru": "2:0 3:0",
        "name_tr": "2:0 3:0",
        "name_se": "2:0 3:0",
        "name_el": "2:0 3:0",
        "name_es": "2:0 3:0",
        "name_hr": "2:0 3:0"
    },
    "1480^prematch": {
        "name_en": "5:0",
        "name_aa": "5:0",
        "name_de": "5:0",
        "name_nl": "5:0",
        "name_pl": "5:0",
        "name_ro": "5:0",
        "name_ru": "5:0",
        "name_tr": "5:0",
        "name_se": "5:0",
        "name_el": "5:0",
        "name_es": "5:0",
        "name_hr": "5:0"
    },
    "60060000^prematch": {
        "name_en": "5+/6+",
        "name_aa": "5+/6+",
        "name_de": "5+/6+",
        "name_nl": "5+/6+",
        "name_pl": "5+/6+",
        "name_ro": "5+/6+",
        "name_ru": "5+/6+",
        "name_tr": "5+/6+",
        "name_se": "5+/6+",
        "name_el": "5+/6+",
        "name_es": "5+/6+",
        "name_hr": "5+/6+"
    },
    "1247^prematch": {
        "name_en": "70-74",
        "name_aa": "70-74",
        "name_de": "70-74",
        "name_nl": "70-74",
        "name_pl": "70-74",
        "name_ro": "70-74",
        "name_ru": "70-74",
        "name_tr": "70-74",
        "name_se": "70-74",
        "name_el": "70-74",
        "name_es": "70-74",
        "name_hr": "70-74"
    },
    "1765^prematch": {
        "name_en": "5",
        "name_aa": "5",
        "name_de": "5",
        "name_nl": "5",
        "name_pl": "5",
        "name_ro": "5",
        "name_ru": "5",
        "name_tr": "5",
        "name_se": "5",
        "name_el": "5",
        "name_es": "5",
        "name_hr": "5"
    },
    "125^prematch": {
        "name_en": "{$competitor1} by 1-5",
        "name_aa": "{$competitor1} by 1-5",
        "name_de": "{$competitor1} by 1-5",
        "name_nl": "{$competitor1} by 1-5",
        "name_pl": "{$competitor1} by 1-5",
        "name_ro": "{$competitor1} by 1-5",
        "name_ru": "{$competitor1} by 1-5",
        "name_tr": "{$competitor1} by 1-5",
        "name_se": "{$competitor1} by 1-5",
        "name_el": "{$competitor1} by 1-5",
        "name_es": "{$competitor1} by 1-5",
        "name_hr": "{$competitor1} by 1-5"
    },
    "153^prematch": {
        "name_en": "42-48",
        "name_aa": "42-48",
        "name_de": "42-48",
        "name_nl": "42-48",
        "name_pl": "42-48",
        "name_ro": "42-48",
        "name_ru": "42-48",
        "name_tr": "42-48",
        "name_se": "42-48",
        "name_el": "42-48",
        "name_es": "42-48",
        "name_hr": "42-48"
    },
    "1070^prematch": {
        "name_en": "0-99",
        "name_aa": "0-99",
        "name_de": "0-99",
        "name_nl": "0-99",
        "name_pl": "0-99",
        "name_ro": "0-99",
        "name_ru": "0-99",
        "name_tr": "0-99",
        "name_se": "0-99",
        "name_el": "0-99",
        "name_es": "0-99",
        "name_hr": "0-99"
    },
    "188^prematch": {
        "name_en": "3:6",
        "name_aa": "3:6",
        "name_de": "3:6",
        "name_nl": "3:6",
        "name_pl": "3:6",
        "name_ro": "3:6",
        "name_ru": "3:6",
        "name_tr": "3:6",
        "name_se": "3:6",
        "name_el": "3:6",
        "name_es": "3:6",
        "name_hr": "3:6"
    },
    "1736^prematch": {
        "name_en": "2-4",
        "name_aa": "2-4",
        "name_de": "2-4",
        "name_nl": "2-4",
        "name_pl": "2-4",
        "name_ro": "2-4",
        "name_ru": "2-4",
        "name_tr": "2-4",
        "name_se": "2-4",
        "name_el": "2-4",
        "name_es": "2-4",
        "name_hr": "2-4"
    },
    "1341^prematch": {
        "name_en": "5+",
        "name_aa": "5+",
        "name_de": "5+",
        "name_nl": "5+",
        "name_pl": "5+",
        "name_ro": "5+",
        "name_ru": "5+",
        "name_tr": "5+",
        "name_se": "5+",
        "name_el": "5+",
        "name_es": "5+",
        "name_hr": "5+"
    },
    "242^prematch": {
        "name_en": "6:8",
        "name_aa": "6:8",
        "name_de": "6:8",
        "name_nl": "6:8",
        "name_pl": "6:8",
        "name_ro": "6:8",
        "name_ru": "6:8",
        "name_tr": "6:8",
        "name_se": "6:8",
        "name_el": "6:8",
        "name_es": "6:8",
        "name_hr": "6:8"
    },
    "1694710000^prematch": {
        "name_en": "No",
        "name_aa": "No",
        "name_de": "No",
        "name_nl": "No",
        "name_pl": "No",
        "name_ro": "No",
        "name_ru": "No",
        "name_tr": "No",
        "name_se": "No",
        "name_el": "No",
        "name_es": "No",
        "name_hr": "No"
    },
    "1078^prematch": {
        "name_en": "190+",
        "name_aa": "190+",
        "name_de": "190+",
        "name_nl": "190+",
        "name_pl": "190+",
        "name_ro": "190+",
        "name_ru": "190+",
        "name_tr": "190+",
        "name_se": "190+",
        "name_el": "190+",
        "name_es": "190+",
        "name_hr": "190+"
    },
    "1406^prematch": {
        "name_en": "{$competitor1}  4",
        "name_aa": "{$competitor1}  4",
        "name_de": "{$competitor1}  4",
        "name_nl": "{$competitor1}  4",
        "name_pl": "{$competitor1}  4",
        "name_ro": "{$competitor1}  4",
        "name_ru": "{$competitor1}  4",
        "name_tr": "{$competitor1}  4",
        "name_se": "{$competitor1}  4",
        "name_el": "{$competitor1}  4",
        "name_es": "{$competitor1}  4",
        "name_hr": "{$competitor1}  4"
    },
    "151^prematch": {
        "name_en": "28-34",
        "name_aa": "28-34",
        "name_de": "28-34",
        "name_nl": "28-34",
        "name_pl": "28-34",
        "name_ro": "28-34",
        "name_ru": "28-34",
        "name_tr": "28-34",
        "name_se": "28-34",
        "name_el": "28-34",
        "name_es": "28-34",
        "name_hr": "28-34"
    },
    "1417^prematch": {
        "name_en": "{$competitor2}  1",
        "name_aa": "{$competitor2}  1",
        "name_de": "{$competitor2}  1",
        "name_nl": "{$competitor2}  1",
        "name_pl": "{$competitor2}  1",
        "name_ro": "{$competitor2}  1",
        "name_ru": "{$competitor2}  1",
        "name_tr": "{$competitor2}  1",
        "name_se": "{$competitor2}  1",
        "name_el": "{$competitor2}  1",
        "name_es": "{$competitor2}  1",
        "name_hr": "{$competitor2}  1"
    },
    "1091^prematch": {
        "name_en": "41-45",
        "name_aa": "41-45",
        "name_de": "41-45",
        "name_nl": "41-45",
        "name_pl": "41-45",
        "name_ro": "41-45",
        "name_ru": "41-45",
        "name_tr": "41-45",
        "name_se": "41-45",
        "name_el": "41-45",
        "name_es": "41-45",
        "name_hr": "41-45"
    },
    "233^prematch": {
        "name_en": "8:0",
        "name_aa": "8:0",
        "name_de": "8:0",
        "name_nl": "8:0",
        "name_pl": "8:0",
        "name_ro": "8:0",
        "name_ru": "8:0",
        "name_tr": "8:0",
        "name_se": "8:0",
        "name_el": "8:0",
        "name_es": "8:0",
        "name_hr": "8:0"
    },
    "225^prematch": {
        "name_en": "7:6",
        "name_aa": "7:6",
        "name_de": "7:6",
        "name_nl": "7:6",
        "name_pl": "7:6",
        "name_ro": "7:6",
        "name_ru": "7:6",
        "name_tr": "7:6",
        "name_se": "7:6",
        "name_el": "7:6",
        "name_es": "7:6",
        "name_hr": "7:6"
    },
    "400^prematch": {
        "name_en": "2:0 2:0",
        "name_aa": "2:0 2:0",
        "name_de": "2:0 2:0",
        "name_nl": "2:0 2:0",
        "name_pl": "2:0 2:0",
        "name_ro": "2:0 2:0",
        "name_ru": "2:0 2:0",
        "name_tr": "2:0 2:0",
        "name_se": "2:0 2:0",
        "name_el": "2:0 2:0",
        "name_es": "2:0 2:0",
        "name_hr": "2:0 2:0"
    },
    "1390^prematch": {
        "name_en": "{$competitor2}  decision",
        "name_aa": "{$competitor2}  decision",
        "name_de": "{$competitor2}  decision",
        "name_nl": "{$competitor2}  decision",
        "name_pl": "{$competitor2}  decision",
        "name_ro": "{$competitor2}  decision",
        "name_ru": "{$competitor2}  decision",
        "name_tr": "{$competitor2}  decision",
        "name_se": "{$competitor2}  decision",
        "name_el": "{$competitor2}  decision",
        "name_es": "{$competitor2}  decision",
        "name_hr": "{$competitor2}  decision"
    },
    "60030000^prematch": {
        "name_en": "4+/6+",
        "name_aa": "4+/6+",
        "name_de": "4+/6+",
        "name_nl": "4+/6+",
        "name_pl": "4+/6+",
        "name_ro": "4+/6+",
        "name_ru": "4+/6+",
        "name_tr": "4+/6+",
        "name_se": "4+/6+",
        "name_el": "4+/6+",
        "name_es": "4+/6+",
        "name_hr": "4+/6+"
    },
    "12^prematch": {
        "name_en": "over {total}",
        "name_aa": "أكثر من {total}",
        "name_de": "über {total}",
        "name_nl": "boven {total}",
        "name_pl": "ponad {total}",
        "name_ro": "peste {total}",
        "name_ru": "больше {total}",
        "name_tr": "{total} üstü",
        "name_se": "över {total}",
        "name_el": "over {total}",
        "name_es": "más de {total}",
        "name_hr": "više od {total}"
    },
    "634^prematch": {
        "name_en": "{$competitor1} by 3+",
        "name_aa": "{$competitor1} في 3+",
        "name_de": "{$competitor1} mit 3+",
        "name_nl": "{$competitor1} met 3+",
        "name_pl": "{$competitor1} 3+",
        "name_ro": "{$competitor1} cu 3+",
        "name_ru": "{$competitor1} на 3+",
        "name_tr": "{$competitor1} 3 ve üstü farkla",
        "name_se": "{$competitor1} med 3+",
        "name_el": "{$competitor1} με 3+",
        "name_es": "{$competitor1} por 3+",
        "name_hr": "{$competitor1} za 3 ili više"
    },
    "1365^prematch": {
        "name_en": "{$competitor1}  4",
        "name_aa": "{$competitor1}  4",
        "name_de": "{$competitor1}  4",
        "name_nl": "{$competitor1}  4",
        "name_pl": "{$competitor1}  4",
        "name_ro": "{$competitor1}  4",
        "name_ru": "{$competitor1}  4",
        "name_tr": "{$competitor1}  4",
        "name_se": "{$competitor1}  4",
        "name_el": "{$competitor1}  4",
        "name_es": "{$competitor1}  4",
        "name_hr": "{$competitor1}  4"
    },
    "152^prematch": {
        "name_en": "35-41",
        "name_aa": "35-41",
        "name_de": "35-41",
        "name_nl": "35-41",
        "name_pl": "35-41",
        "name_ro": "35-41",
        "name_ru": "35-41",
        "name_tr": "35-41",
        "name_se": "35-41",
        "name_el": "35-41",
        "name_es": "35-41",
        "name_hr": "35-41"
    },
    "94^prematch": {
        "name_en": "over {total}  no",
        "name_aa": "أكثر من {total} و لا",
        "name_de": "über {total}  nein",
        "name_nl": "boven {total}  nee",
        "name_pl": "ponad {total}  nie",
        "name_ro": "peste {total}  nu",
        "name_ru": "больше {total}  нет",
        "name_tr": "{total} üstü  yok",
        "name_se": "över {total}  nej",
        "name_el": "όχι και over {total}",
        "name_es": "más de {total} y no",
        "name_hr": "više od {total} i ne"
    },
    "1248^prematch": {
        "name_en": "75-79",
        "name_aa": "75-79",
        "name_de": "75-79",
        "name_nl": "75-79",
        "name_pl": "75-79",
        "name_ro": "75-79",
        "name_ru": "75-79",
        "name_tr": "75-79",
        "name_se": "75-79",
        "name_el": "75-79",
        "name_es": "75-79",
        "name_hr": "75-79"
    },
    "1508^prematch": {
        "name_en": "3:5",
        "name_aa": "3:5",
        "name_de": "3:5",
        "name_nl": "3:5",
        "name_pl": "3:5",
        "name_ro": "3:5",
        "name_ru": "3:5",
        "name_tr": "3:5",
        "name_se": "3:5",
        "name_el": "3:5",
        "name_es": "3:5",
        "name_hr": "3:5"
    },
    "169^prematch": {
        "name_en": "161-170",
        "name_aa": "161-170",
        "name_de": "161-170",
        "name_nl": "161-170",
        "name_pl": "161-170",
        "name_ro": "161-170",
        "name_ru": "161-170",
        "name_tr": "161-170",
        "name_se": "161-170",
        "name_el": "161-170",
        "name_es": "161-170",
        "name_hr": "161-170"
    },
    "1757^prematch": {
        "name_en": "2:3, 2:4, 3:4 or 1:5",
        "name_aa": "2:3, 2:4, 3:4 أو 1:5",
        "name_de": "2:3, 2:4, 3:4 oder 1:5",
        "name_nl": "2:3, 2:4, 3:4 of 1:5",
        "name_pl": "2:3, 2:4, 3:4 lub 1:5",
        "name_ro": "2:3, 2:4, 3:4 sau 1:5",
        "name_ru": "2:3, 2:4, 3:4 или 1:5",
        "name_tr": "2:3, 2:4, 3:4 veya 1:5",
        "name_se": "2:3, 2:4, 3:4 elr 1:5",
        "name_el": "2-3, 2-4, 3-4 ή 1-5",
        "name_es": "2:3, 2:4, 3:4 o 1:5",
        "name_hr": "2:3, 2:4, 3:4 ili 1:5"
    },
    "74^prematch": {
        "name_en": "yes",
        "name_aa": "نعم",
        "name_de": "ja",
        "name_nl": "ja",
        "name_pl": "tak",
        "name_ro": "da",
        "name_ru": "да",
        "name_tr": "var",
        "name_se": "ja",
        "name_el": "ναι",
        "name_es": "sí",
        "name_hr": "da"
    },
    "33^prematch": {
        "name_en": "3",
        "name_aa": "3",
        "name_de": "3",
        "name_nl": "3",
        "name_pl": "3",
        "name_ro": "3",
        "name_ru": "3",
        "name_tr": "3",
        "name_se": "3",
        "name_el": "3",
        "name_es": "3",
        "name_hr": "3"
    },
    "164^prematch": {
        "name_en": "161-170",
        "name_aa": "161-170",
        "name_de": "161-170",
        "name_nl": "161-170",
        "name_pl": "161-170",
        "name_ro": "161-170",
        "name_ru": "161-170",
        "name_tr": "161-170",
        "name_se": "161-170",
        "name_el": "161-170",
        "name_es": "161-170",
        "name_hr": "161-170"
    },
    "70750000^prematch": {
        "name_en": "Draw",
        "name_aa": "Draw",
        "name_de": "Draw",
        "name_nl": "Draw",
        "name_pl": "Draw",
        "name_ro": "Draw",
        "name_ru": "Draw",
        "name_tr": "Draw",
        "name_se": "Draw",
        "name_el": "Draw",
        "name_es": "Draw",
        "name_hr": "Draw"
    },
    "1422^prematch": {
        "name_en": "{$competitor2}  6",
        "name_aa": "{$competitor2}  6",
        "name_de": "{$competitor2}  6",
        "name_nl": "{$competitor2}  6",
        "name_pl": "{$competitor2}  6",
        "name_ro": "{$competitor2}  6",
        "name_ru": "{$competitor2}  6",
        "name_tr": "{$competitor2}  6",
        "name_se": "{$competitor2}  6",
        "name_el": "{$competitor2}  6",
        "name_es": "{$competitor2}  6",
        "name_hr": "{$competitor2}  6"
    },
    "1733^prematch": {
        "name_en": "1-5",
        "name_aa": "1-5",
        "name_de": "1-5",
        "name_nl": "1-5",
        "name_pl": "1-5",
        "name_ro": "1-5",
        "name_ru": "1-5",
        "name_tr": "1-5",
        "name_se": "1-5",
        "name_el": "1-5",
        "name_es": "1-5",
        "name_hr": "1-5"
    },
    "301^prematch": {
        "name_en": "7:11",
        "name_aa": "7:11",
        "name_de": "7:11",
        "name_nl": "7:11",
        "name_pl": "7:11",
        "name_ro": "7:11",
        "name_ru": "7:11",
        "name_tr": "7:11",
        "name_se": "7:11",
        "name_el": "7:11",
        "name_es": "7:11",
        "name_hr": "7:11"
    },
    "1073^prematch": {
        "name_en": "130-139",
        "name_aa": "130-139",
        "name_de": "130-139",
        "name_nl": "130-139",
        "name_pl": "130-139",
        "name_ro": "130-139",
        "name_ru": "130-139",
        "name_tr": "130-139",
        "name_se": "130-139",
        "name_el": "130-139",
        "name_es": "130-139",
        "name_hr": "130-139"
    },
    "1408^prematch": {
        "name_en": "{$competitor1}  6",
        "name_aa": "{$competitor1}  6",
        "name_de": "{$competitor1}  6",
        "name_nl": "{$competitor1}  6",
        "name_pl": "{$competitor1}  6",
        "name_ro": "{$competitor1}  6",
        "name_ru": "{$competitor1}  6",
        "name_tr": "{$competitor1}  6",
        "name_se": "{$competitor1}  6",
        "name_el": "{$competitor1}  6",
        "name_es": "{$competitor1}  6",
        "name_hr": "{$competitor1}  6"
    },
    "229^prematch": {
        "name_en": "3:7",
        "name_aa": "3:7",
        "name_de": "3:7",
        "name_nl": "3:7",
        "name_pl": "3:7",
        "name_ro": "3:7",
        "name_ru": "3:7",
        "name_tr": "3:7",
        "name_se": "3:7",
        "name_el": "3:7",
        "name_es": "3:7",
        "name_hr": "3:7"
    },
    "70760000^prematch": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2"
    },
    "392^prematch": {
        "name_en": "1:1 2:1",
        "name_aa": "1:1 2:1",
        "name_de": "1:1 2:1",
        "name_nl": "1:1 2:1",
        "name_pl": "1:1 2:1",
        "name_ro": "1:1 2:1",
        "name_ru": "1:1 2:1",
        "name_tr": "1:1 2:1",
        "name_se": "1:1 2:1",
        "name_el": "1:1 2:1",
        "name_es": "1:1 2:1",
        "name_hr": "1:1 2:1"
    },
    "1344^prematch": {
        "name_en": "4-6",
        "name_aa": "4-6",
        "name_de": "4-6",
        "name_nl": "4-6",
        "name_pl": "4-6",
        "name_ro": "4-6",
        "name_ru": "4-6",
        "name_tr": "4-6",
        "name_se": "4-6",
        "name_el": "4-6",
        "name_es": "4-6",
        "name_hr": "4-6"
    },
    "1872^prematch": {
        "name_en": "{$competitor1}/{$competitor1}  4",
        "name_aa": "{$competitor1} / {$competitor1} و 4",
        "name_de": "{$competitor1}/{$competitor1}  4",
        "name_nl": "{$competitor1}/{$competitor1}  4",
        "name_pl": "{$competitor1}/{$competitor1}  4",
        "name_ro": "{$competitor1}/{$competitor1}  4",
        "name_ru": "{$competitor1}/{$competitor1} и 4",
        "name_tr": "{$competitor1}/{$competitor1}  4",
        "name_se": "{$competitor1}/{$competitor1}  4",
        "name_el": "{$competitor1}/{$competitor1}  4",
        "name_es": "{$competitor1}/{$competitor1}  4",
        "name_hr": "{$competitor1}/{$competitor1} i 4"
    },
    "1^prematch": {
        "name_en": "{$competitor1}",
        "name_aa": "{$competitor1}",
        "name_de": "{$competitor1}",
        "name_nl": "{$competitor1}",
        "name_pl": "{$competitor1}",
        "name_ro": "{$competitor1}",
        "name_ru": "{$competitor1}",
        "name_tr": "{$competitor1}",
        "name_se": "{$competitor1}",
        "name_el": "{$competitor1}",
        "name_es": "{$competitor1}",
        "name_hr": "{$competitor1}"
    },
    "246^prematch": {
        "name_en": "2:8",
        "name_aa": "2:8",
        "name_de": "2:8",
        "name_nl": "2:8",
        "name_pl": "2:8",
        "name_ro": "2:8",
        "name_ru": "2:8",
        "name_tr": "2:8",
        "name_se": "2:8",
        "name_el": "2:8",
        "name_es": "2:8",
        "name_hr": "2:8"
    },
    "1495^prematch": {
        "name_en": "2:3",
        "name_aa": "2:3",
        "name_de": "2:3",
        "name_nl": "2:3",
        "name_pl": "2:3",
        "name_ro": "2:3",
        "name_ru": "2:3",
        "name_tr": "2:3",
        "name_se": "2:3",
        "name_el": "2:3",
        "name_es": "2:3",
        "name_hr": "2:3"
    },
    "1412^prematch": {
        "name_en": "{$competitor1}  10",
        "name_aa": "{$competitor1}  10",
        "name_de": "{$competitor1}  10",
        "name_nl": "{$competitor1}  10",
        "name_pl": "{$competitor1}  10",
        "name_ro": "{$competitor1}  10",
        "name_ru": "{$competitor1}  10",
        "name_tr": "{$competitor1}  10",
        "name_se": "{$competitor1}  10",
        "name_el": "{$competitor1}  10",
        "name_es": "{$competitor1}  10",
        "name_hr": "{$competitor1}  10"
    },
    "278^prematch": {
        "name_en": "8:10",
        "name_aa": "8:10",
        "name_de": "8:10",
        "name_nl": "8:10",
        "name_pl": "8:10",
        "name_ro": "8:10",
        "name_ru": "8:10",
        "name_tr": "8:10",
        "name_se": "8:10",
        "name_el": "8:10",
        "name_es": "8:10",
        "name_hr": "8:10"
    },
    "15^prematch": {
        "name_en": "4:1",
        "name_aa": "4:1",
        "name_de": "4:1",
        "name_nl": "4:1",
        "name_pl": "4:1",
        "name_ro": "4:1",
        "name_ru": "4:1",
        "name_tr": "4:1",
        "name_se": "4:1",
        "name_el": "4:1",
        "name_es": "4:1",
        "name_hr": "4:1"
    },
    "372^prematch": {
        "name_en": "0:3 4+",
        "name_aa": "0:3 4+",
        "name_de": "0:3 4+",
        "name_nl": "0:3 4+",
        "name_pl": "0:3 4+",
        "name_ro": "0:3 4+",
        "name_ru": "0:3 4+",
        "name_tr": "0:3 4+",
        "name_se": "0:3 4+",
        "name_el": "0:3 4+",
        "name_es": "0:3 4+",
        "name_hr": "0:3 4+"
    },
    "273^prematch": {
        "name_en": "10:6",
        "name_aa": "10:6",
        "name_de": "10:6",
        "name_nl": "10:6",
        "name_pl": "10:6",
        "name_ro": "10:6",
        "name_ru": "10:6",
        "name_tr": "10:6",
        "name_se": "10:6",
        "name_el": "10:6",
        "name_es": "10:6",
        "name_hr": "10:6"
    },
    "873^prematch": {
        "name_en": "1:6",
        "name_aa": "1:6",
        "name_de": "1:6",
        "name_nl": "1:6",
        "name_pl": "1:6",
        "name_ro": "1:6",
        "name_ru": "1:6",
        "name_tr": "1:6",
        "name_se": "1:6",
        "name_el": "1:6",
        "name_es": "1:6",
        "name_hr": "1:6"
    },
    "1694180000^prematch": {
        "name_en": "Under14.5",
        "name_aa": "Under14.5",
        "name_de": "Under14.5",
        "name_nl": "Under14.5",
        "name_pl": "Under14.5",
        "name_ro": "Under14.5",
        "name_ru": "Under14.5",
        "name_tr": "Under14.5",
        "name_se": "Under14.5",
        "name_el": "Under14.5",
        "name_es": "Under14.5",
        "name_hr": "Under14.5"
    },
    "237^prematch": {
        "name_en": "8:4",
        "name_aa": "8:4",
        "name_de": "8:4",
        "name_nl": "8:4",
        "name_pl": "8:4",
        "name_ro": "8:4",
        "name_ru": "8:4",
        "name_tr": "8:4",
        "name_se": "8:4",
        "name_el": "8:4",
        "name_es": "8:4",
        "name_hr": "8:4"
    },
    "1868^prematch": {
        "name_en": "draw/{$competitor1}  3",
        "name_aa": "رسم / {$competitor1} و 3",
        "name_de": "unentschieden/{$competitor1}  3",
        "name_nl": "gelijkspel/{$competitor1}  3",
        "name_pl": "remis/{$competitor1}  3",
        "name_ro": "egal/{$competitor1}  3",
        "name_ru": "ничья/{$competitor1} и 3",
        "name_tr": "beraberlik/{$competitor1}  3",
        "name_se": "oavgjort/{$competitor1}  3",
        "name_el": "ισοπαλία/{$competitor1}  3",
        "name_es": "empate/{$competitor1}  3",
        "name_hr": "neodlučeno/{$competitor1} i 3"
    },
    "255^prematch": {
        "name_en": "9:6",
        "name_aa": "9:6",
        "name_de": "9:6",
        "name_nl": "9:6",
        "name_pl": "9:6",
        "name_ro": "9:6",
        "name_ru": "9:6",
        "name_tr": "9:6",
        "name_se": "9:6",
        "name_el": "9:6",
        "name_es": "9:6",
        "name_hr": "9:6"
    },
    "1641^prematch": {
        "name_en": "{$competitor1} with penalty",
        "name_aa": "{$competitor1} مع ركلة جزاء",
        "name_de": "{$competitor1} mit Strafstoß",
        "name_nl": "{$competitor1} met penalty",
        "name_pl": "{$competitor1} z karnym",
        "name_ro": "{$competitor1} cu penalti",
        "name_ru": "{$competitor1} с пенальти",
        "name_tr": "{$competitor1} penaltı ile",
        "name_se": "{$competitor1} med straff",
        "name_el": "{$competitor1} με πέναλτι",
        "name_es": "{$competitor1} con penalti",
        "name_hr": "{$competitor1} penalom"
    },
    "877^prematch": {
        "name_en": "5:7",
        "name_aa": "5:7",
        "name_de": "5:7",
        "name_nl": "5:7",
        "name_pl": "5:7",
        "name_ro": "5:7",
        "name_ru": "5:7",
        "name_tr": "5:7",
        "name_se": "5:7",
        "name_el": "5:7",
        "name_es": "5:7",
        "name_hr": "5:7"
    },
    "73^prematch": {
        "name_en": "5",
        "name_aa": "5",
        "name_de": "5",
        "name_nl": "5",
        "name_pl": "5",
        "name_ro": "5",
        "name_ru": "5",
        "name_tr": "5",
        "name_se": "5",
        "name_el": "5",
        "name_es": "5",
        "name_hr": "5"
    },
    "598^prematch": {
        "name_en": "1-10",
        "name_aa": "1-10",
        "name_de": "1-10",
        "name_nl": "1-10",
        "name_pl": "1-10",
        "name_ro": "1-10",
        "name_ru": "1-10",
        "name_tr": "1-10",
        "name_se": "1-10",
        "name_el": "1-10",
        "name_es": "1-10",
        "name_hr": "1-10"
    },
    "60100000^prematch": {
        "name_en": "6+/5+",
        "name_aa": "6+/5+",
        "name_de": "6+/5+",
        "name_nl": "6+/5+",
        "name_pl": "6+/5+",
        "name_ro": "6+/5+",
        "name_ru": "6+/5+",
        "name_tr": "6+/5+",
        "name_se": "6+/5+",
        "name_el": "6+/5+",
        "name_es": "6+/5+",
        "name_hr": "6+/5+"
    },
    "1858^prematch": {
        "name_en": "{$competitor2}/{$competitor2}  1",
        "name_aa": "{$competitor2} / {$competitor2} و 1",
        "name_de": "{$competitor2}/{$competitor2}  1",
        "name_nl": "{$competitor2}/{$competitor2}  1",
        "name_pl": "{$competitor2}/{$competitor2}  1",
        "name_ro": "{$competitor2}/{$competitor2}  1",
        "name_ru": "{$competitor2}/{$competitor2} и 1",
        "name_tr": "{$competitor2}/{$competitor2}  1",
        "name_se": "{$competitor2}/{$competitor2}  1",
        "name_el": "{$competitor2}/{$competitor2}  1",
        "name_es": "{$competitor2}/{$competitor2}  1",
        "name_hr": "{$competitor2}/{$competitor2} i 1"
    },
    "1694070000^prematch": {
        "name_en": "Over 9.5",
        "name_aa": "Over 9.5",
        "name_de": "Over 9.5",
        "name_nl": "Over 9.5",
        "name_pl": "Over 9.5",
        "name_ro": "Over 9.5",
        "name_ru": "Over 9.5",
        "name_tr": "Over 9.5",
        "name_se": "Over 9.5",
        "name_el": "Over 9.5",
        "name_es": "Over 9.5",
        "name_hr": "Over 9.5"
    },
    "1644^prematch": {
        "name_en": "{$competitor2} with penalty",
        "name_aa": "{$competitor2} مع ركلة جزاء",
        "name_de": "{$competitor2} mit Strafstoß",
        "name_nl": "{$competitor2} met penalty",
        "name_pl": "{$competitor2} z karnym",
        "name_ro": "{$competitor2} cu penalti",
        "name_ru": "{$competitor2} с пенальти",
        "name_tr": "{$competitor2} penaltı ile",
        "name_se": "{$competitor2} med straff",
        "name_el": "{$competitor2} με πέναλτι",
        "name_es": "{$competitor2} con penalti",
        "name_hr": "{$competitor2} penalom"
    },
    "296^prematch": {
        "name_en": "11:9",
        "name_aa": "11:9",
        "name_de": "11:9",
        "name_nl": "11:9",
        "name_pl": "11:9",
        "name_ro": "11:9",
        "name_ru": "11:9",
        "name_tr": "11:9",
        "name_se": "11:9",
        "name_el": "11:9",
        "name_es": "11:9",
        "name_hr": "11:9"
    },
    "1370^prematch": {
        "name_en": "{$competitor2}  2",
        "name_aa": "{$competitor2}  2",
        "name_de": "{$competitor2}  2",
        "name_nl": "{$competitor2}  2",
        "name_pl": "{$competitor2}  2",
        "name_ro": "{$competitor2}  2",
        "name_ru": "{$competitor2}  2",
        "name_tr": "{$competitor2}  2",
        "name_se": "{$competitor2}  2",
        "name_el": "{$competitor2}  2",
        "name_es": "{$competitor2}  2",
        "name_hr": "{$competitor2}  2"
    },
    "21^prematch": {
        "name_en": "0:4",
        "name_aa": "0:4",
        "name_de": "0:4",
        "name_nl": "0:4",
        "name_pl": "0:4",
        "name_ro": "0:4",
        "name_ru": "0:4",
        "name_tr": "0:4",
        "name_se": "0:4",
        "name_el": "0:4",
        "name_es": "0:4",
        "name_hr": "0:4"
    },
    "1353^prematch": {
        "name_en": "{$competitor1}  1",
        "name_aa": "{$competitor1}  1",
        "name_de": "{$competitor1}  1",
        "name_nl": "{$competitor1}  1",
        "name_pl": "{$competitor1}  1",
        "name_ro": "{$competitor1}  1",
        "name_ru": "{$competitor1}  1",
        "name_tr": "{$competitor1}  1",
        "name_se": "{$competitor1}  1",
        "name_el": "{$competitor1}  1",
        "name_es": "{$competitor1}  1",
        "name_hr": "{$competitor1}  1"
    },
    "1288^prematch": {
        "name_en": "{$competitor1} by 26+",
        "name_aa": "{$competitor1} by 26+",
        "name_de": "{$competitor1} by 26+",
        "name_nl": "{$competitor1} by 26+",
        "name_pl": "{$competitor1} by 26+",
        "name_ro": "{$competitor1} by 26+",
        "name_ru": "{$competitor1} by 26+",
        "name_tr": "{$competitor1} by 26+",
        "name_se": "{$competitor1} by 26+",
        "name_el": "{$competitor1} by 26+",
        "name_es": "{$competitor1} by 26+",
        "name_hr": "{$competitor1} by 26+"
    },
    "1694100000^prematch": {
        "name_en": "Under 10.5",
        "name_aa": "Under 10.5",
        "name_de": "Under 10.5",
        "name_nl": "Under 10.5",
        "name_pl": "Under 10.5",
        "name_ro": "Under 10.5",
        "name_ru": "Under 10.5",
        "name_tr": "Under 10.5",
        "name_se": "Under 10.5",
        "name_el": "Under 10.5",
        "name_es": "Under 10.5",
        "name_hr": "Under 10.5"
    },
    "1196^prematch": {
        "name_en": "0:2",
        "name_aa": "0:2",
        "name_de": "0:2",
        "name_nl": "0:2",
        "name_pl": "0:2",
        "name_ro": "0:2",
        "name_ru": "0:2",
        "name_tr": "0:2",
        "name_se": "0:2",
        "name_el": "0:2",
        "name_es": "0:2",
        "name_hr": "0:2"
    },
    "1499^prematch": {
        "name_en": "0:4",
        "name_aa": "0:4",
        "name_de": "0:4",
        "name_nl": "0:4",
        "name_pl": "0:4",
        "name_ro": "0:4",
        "name_ru": "0:4",
        "name_tr": "0:4",
        "name_se": "0:4",
        "name_el": "0:4",
        "name_es": "0:4",
        "name_hr": "0:4"
    },
    "1175^prematch": {
        "name_en": "2:3",
        "name_aa": "2:3",
        "name_de": "2:3",
        "name_nl": "2:3",
        "name_pl": "2:3",
        "name_ro": "2:3",
        "name_ru": "2:3",
        "name_tr": "2:3",
        "name_se": "2:3",
        "name_el": "2:3",
        "name_es": "2:3",
        "name_hr": "2:3"
    },
    "1359^prematch": {
        "name_en": "{$competitor2}  2",
        "name_aa": "{$competitor2}  2",
        "name_de": "{$competitor2}  2",
        "name_nl": "{$competitor2}  2",
        "name_pl": "{$competitor2}  2",
        "name_ro": "{$competitor2}  2",
        "name_ru": "{$competitor2}  2",
        "name_tr": "{$competitor2}  2",
        "name_se": "{$competitor2}  2",
        "name_el": "{$competitor2}  2",
        "name_es": "{$competitor2}  2",
        "name_hr": "{$competitor2}  2"
    },
    "1475^prematch": {
        "name_en": "0:0",
        "name_aa": "0:0",
        "name_de": "0:0",
        "name_nl": "0:0",
        "name_pl": "0:0",
        "name_ro": "0:0",
        "name_ru": "0:0",
        "name_tr": "0:0",
        "name_se": "0:0",
        "name_el": "0:0",
        "name_es": "0:0",
        "name_hr": "0:0"
    },
    "716^prematch": {
        "name_en": "6",
        "name_aa": "6",
        "name_de": "6",
        "name_nl": "6",
        "name_pl": "6",
        "name_ro": "6",
        "name_ru": "6",
        "name_tr": "6",
        "name_se": "6",
        "name_el": "6",
        "name_es": "6",
        "name_hr": "6"
    },
    "440^prematch": {
        "name_en": "equal",
        "name_aa": "مساو",
        "name_de": "gleich",
        "name_nl": "gelijk",
        "name_pl": "równo",
        "name_ro": "egalitate",
        "name_ru": "равные",
        "name_tr": "eşit",
        "name_se": "lika",
        "name_el": "ίδιος αριθμός",
        "name_es": "igual",
        "name_hr": "jednako"
    },
    "1643^prematch": {
        "name_en": "{$competitor2} with try",
        "name_aa": "{$competitor2} مع المحاولة",
        "name_de": "{$competitor2} mit Versuch",
        "name_nl": "{$competitor2} met try",
        "name_pl": "{$competitor2} z przyłożeniem",
        "name_ro": "{$competitor2} cu încercare",
        "name_ru": "{$competitor2} с попытки",
        "name_tr": "{$competitor2} try ile",
        "name_se": "{$competitor2} med försök",
        "name_el": "{$competitor2} με try",
        "name_es": "{$competitor2} con ensayo",
        "name_hr": "{$competitor2} polaganjem"
    },
    "1754^prematch": {
        "name_en": "2:1, 3:1 or 4:1",
        "name_aa": "2:1, 3:1 أو 4:1",
        "name_de": "2:1, 3:1 oder 4:1",
        "name_nl": "2:1, 3:1 of 4:1",
        "name_pl": "2:1, 3:1 lub 4:1",
        "name_ro": "2:1, 3:1 sau 4:1",
        "name_ru": "2:1, 3:1 или 4:1",
        "name_tr": "2:1, 3:1 veya 4:1",
        "name_se": "2:1, 3:1 elr 4:1",
        "name_el": "2-1, 3-1 ή 4-1",
        "name_es": "2:1, 3:1 o 4:1",
        "name_hr": "2:1, 3:1 ili 4:1"
    },
    "470^prematch": {
        "name_en": "2:0",
        "name_aa": "2:0",
        "name_de": "2:0",
        "name_nl": "2:0",
        "name_pl": "2:0",
        "name_ro": "2:0",
        "name_ru": "2:0",
        "name_tr": "2:0",
        "name_se": "2:0",
        "name_el": "2:0",
        "name_es": "2:0",
        "name_hr": "2:0"
    },
    "286^prematch": {
        "name_en": "0:10",
        "name_aa": "0:10",
        "name_de": "0:10",
        "name_nl": "0:10",
        "name_pl": "0:10",
        "name_ro": "0:10",
        "name_ru": "0:10",
        "name_tr": "0:10",
        "name_se": "0:10",
        "name_el": "0:10",
        "name_es": "0:10",
        "name_hr": "0:10"
    },
    "1171^prematch": {
        "name_en": "1:6",
        "name_aa": "1:6",
        "name_de": "1:6",
        "name_nl": "1:6",
        "name_pl": "1:6",
        "name_ro": "1:6",
        "name_ru": "1:6",
        "name_tr": "1:6",
        "name_se": "1:6",
        "name_el": "1:6",
        "name_es": "1:6",
        "name_hr": "1:6"
    },
    "1756^prematch": {
        "name_en": "3:2, 4:2, 4:3 or 5:1",
        "name_aa": "3:2, 4:2, 4:3 أو 5:1",
        "name_de": "3:2, 4:2, 4:3 oder 5:1",
        "name_nl": "3:2, 4:2, 4:3 of 5:1",
        "name_pl": "3:2, 4:2, 4:3 lub 5:1",
        "name_ro": "3:2, 4:2, 4:3 sau 5:1",
        "name_ru": "3:2, 4:2, 4:3 или 5:1",
        "name_tr": "3:2, 4:2, 4:3 veya 5:1",
        "name_se": "3:2, 4:2, 4:3 elr 5:1",
        "name_el": "3-2, 4-2, 4-3 ή 5-1",
        "name_es": "3:2, 4:2, 4:3 o 5:1",
        "name_hr": "3:2, 4:2, 4:3 ili 5:1"
    },
    "60110000^prematch": {
        "name_en": "6+/6+",
        "name_aa": "6+/6+",
        "name_de": "6+/6+",
        "name_nl": "6+/6+",
        "name_pl": "6+/6+",
        "name_ro": "6+/6+",
        "name_ru": "6+/6+",
        "name_tr": "6+/6+",
        "name_se": "6+/6+",
        "name_el": "6+/6+",
        "name_es": "6+/6+",
        "name_hr": "6+/6+"
    },
    "1186^prematch": {
        "name_en": "4:3",
        "name_aa": "4:3",
        "name_de": "4:3",
        "name_nl": "4:3",
        "name_pl": "4:3",
        "name_ro": "4:3",
        "name_ru": "4:3",
        "name_tr": "4:3",
        "name_se": "4:3",
        "name_el": "4:3",
        "name_es": "4:3",
        "name_hr": "4:3"
    },
    "18^prematch": {
        "name_en": "3:4",
        "name_aa": "3:4",
        "name_de": "3:4",
        "name_nl": "3:4",
        "name_pl": "3:4",
        "name_ro": "3:4",
        "name_ru": "3:4",
        "name_tr": "3:4",
        "name_se": "3:4",
        "name_el": "3:4",
        "name_es": "3:4",
        "name_hr": "3:4"
    },
    "1136^prematch": {
        "name_en": "76+",
        "name_aa": "76+",
        "name_de": "76+",
        "name_nl": "76+",
        "name_pl": "76+",
        "name_ro": "76+",
        "name_ru": "76+",
        "name_tr": "76+",
        "name_se": "76+",
        "name_el": "76+",
        "name_es": "76+",
        "name_hr": "76+"
    },
    "1694420000^prematch": {
        "name_en": "Neither",
        "name_aa": "Neither",
        "name_de": "Neither",
        "name_nl": "Neither",
        "name_pl": "Neither",
        "name_ro": "Neither",
        "name_ru": "Neither",
        "name_tr": "Neither",
        "name_se": "Neither",
        "name_el": "Neither",
        "name_es": "Neither",
        "name_hr": "Neither"
    },
    "155^prematch": {
        "name_en": "56-62",
        "name_aa": "56-62",
        "name_de": "56-62",
        "name_nl": "56-62",
        "name_pl": "56-62",
        "name_ro": "56-62",
        "name_ru": "56-62",
        "name_tr": "56-62",
        "name_se": "56-62",
        "name_el": "56-62",
        "name_es": "56-62",
        "name_hr": "56-62"
    },
    "354^prematch": {
        "name_en": "0:1 1:1",
        "name_aa": "0:1 1:1",
        "name_de": "0:1 1:1",
        "name_nl": "0:1 1:1",
        "name_pl": "0:1 1:1",
        "name_ro": "0:1 1:1",
        "name_ru": "0:1 1:1",
        "name_tr": "0:1 1:1",
        "name_se": "0:1 1:1",
        "name_el": "0:1 1:1",
        "name_es": "0:1 1:1",
        "name_hr": "0:1 1:1"
    },
    "362^prematch": {
        "name_en": "0:2 0:2",
        "name_aa": "0:2 0:2",
        "name_de": "0:2 0:2",
        "name_nl": "0:2 0:2",
        "name_pl": "0:2 0:2",
        "name_ro": "0:2 0:2",
        "name_ru": "0:2 0:2",
        "name_tr": "0:2 0:2",
        "name_se": "0:2 0:2",
        "name_el": "0:2 0:2",
        "name_es": "0:2 0:2",
        "name_hr": "0:2 0:2"
    },
    "1169^prematch": {
        "name_en": "1:4",
        "name_aa": "1:4",
        "name_de": "1:4",
        "name_nl": "1:4",
        "name_pl": "1:4",
        "name_ro": "1:4",
        "name_ru": "1:4",
        "name_tr": "1:4",
        "name_se": "1:4",
        "name_el": "1:4",
        "name_es": "1:4",
        "name_hr": "1:4"
    },
    "1363^prematch": {
        "name_en": "{$competitor1}  2",
        "name_aa": "{$competitor1}  2",
        "name_de": "{$competitor1}  2",
        "name_nl": "{$competitor1}  2",
        "name_pl": "{$competitor1}  2",
        "name_ro": "{$competitor1}  2",
        "name_ru": "{$competitor1}  2",
        "name_tr": "{$competitor1}  2",
        "name_se": "{$competitor1}  2",
        "name_el": "{$competitor1}  2",
        "name_es": "{$competitor1}  2",
        "name_hr": "{$competitor1}  2"
    },
    "480^prematch": {
        "name_en": "other",
        "name_aa": "آخر",
        "name_de": "andere",
        "name_nl": "ander",
        "name_pl": "inny",
        "name_ro": "altul",
        "name_ru": "другой",
        "name_tr": "diğer",
        "name_se": "annan",
        "name_el": "άλλο",
        "name_es": "otro",
        "name_hr": "ostalo"
    },
    "1694090000^prematch": {
        "name_en": "Over 10.5",
        "name_aa": "Over 10.5",
        "name_de": "Over 10.5",
        "name_nl": "Over 10.5",
        "name_pl": "Over 10.5",
        "name_ro": "Over 10.5",
        "name_ru": "Over 10.5",
        "name_tr": "Over 10.5",
        "name_se": "Over 10.5",
        "name_el": "Over 10.5",
        "name_es": "Over 10.5",
        "name_hr": "Over 10.5"
    },
    "414^prematch": {
        "name_en": "3:0 4+",
        "name_aa": "3:0 4+",
        "name_de": "3:0 4+",
        "name_nl": "3:0 4+",
        "name_pl": "3:0 4+",
        "name_ro": "3:0 4+",
        "name_ru": "3:0 4+",
        "name_tr": "3:0 4+",
        "name_se": "3:0 4+",
        "name_el": "3:0 4+",
        "name_es": "3:0 4+",
        "name_hr": "3:0 4+"
    },
    "36^prematch": {
        "name_en": "5",
        "name_aa": "5",
        "name_de": "5",
        "name_nl": "5",
        "name_pl": "5",
        "name_ro": "5",
        "name_ru": "5",
        "name_tr": "5",
        "name_se": "5",
        "name_el": "5",
        "name_es": "5",
        "name_hr": "5"
    },
    "1145^prematch": {
        "name_en": "3-4",
        "name_aa": "3-4",
        "name_de": "3-4",
        "name_nl": "3-4",
        "name_pl": "3-4",
        "name_ro": "3-4",
        "name_ru": "3-4",
        "name_tr": "3-4",
        "name_se": "3-4",
        "name_el": "3-4",
        "name_es": "3-4",
        "name_hr": "3-4"
    },
    "1094^prematch": {
        "name_en": "7-13",
        "name_aa": "7-13",
        "name_de": "7-13",
        "name_nl": "7-13",
        "name_pl": "7-13",
        "name_ro": "7-13",
        "name_ru": "7-13",
        "name_tr": "7-13",
        "name_se": "7-13",
        "name_el": "7-13",
        "name_es": "7-13",
        "name_hr": "7-13"
    },
    "396^prematch": {
        "name_en": "1:2 1:2",
        "name_aa": "1:2 1:2",
        "name_de": "1:2 1:2",
        "name_nl": "1:2 1:2",
        "name_pl": "1:2 1:2",
        "name_ro": "1:2 1:2",
        "name_ru": "1:2 1:2",
        "name_tr": "1:2 1:2",
        "name_se": "1:2 1:2",
        "name_el": "1:2 1:2",
        "name_es": "1:2 1:2",
        "name_hr": "1:2 1:2"
    },
    "110^prematch": {
        "name_en": "0:0",
        "name_aa": "0:0",
        "name_de": "0:0",
        "name_nl": "0:0",
        "name_pl": "0:0",
        "name_ro": "0:0",
        "name_ru": "0:0",
        "name_tr": "0:0",
        "name_se": "0:0",
        "name_el": "0:0",
        "name_es": "0:0",
        "name_hr": "0:0"
    },
    "1715^prematch": {
        "name_en": "{$competitor2} ({-hcp})",
        "name_aa": "{$competitor2} ({-hcp})",
        "name_de": "{$competitor2} ({-hcp})",
        "name_nl": "{$competitor2} ({hcp})",
        "name_pl": "{$competitor2} ({-hcp})",
        "name_ro": "{$competitor2} ({-hcp})",
        "name_ru": "{$competitor2} ({-hcp})",
        "name_tr": "{$competitor2} ({-hcp})",
        "name_se": "{$competitor2} ({-hcp})",
        "name_el": "({-hcp}) {$competitor2}",
        "name_es": "{$competitor2} ({-hcp})",
        "name_hr": "{$competitor2} ({-hcp})"
    },
    "82^prematch": {
        "name_en": "draw  yes",
        "name_aa": "ارسم ونعم",
        "name_de": "unentschieden  ja",
        "name_nl": "gelijkspel  ja",
        "name_pl": "remis  tak",
        "name_ro": "egal  da",
        "name_ru": "ничья  да",
        "name_tr": "beraberlik  var",
        "name_se": "oavgjort  ja",
        "name_el": "ναι και ισοπαλία",
        "name_es": "empate y sí",
        "name_hr": "neodlučeno i da"
    },
    "71^prematch": {
        "name_en": "3",
        "name_aa": "3",
        "name_de": "3",
        "name_nl": "3",
        "name_pl": "3",
        "name_ro": "3",
        "name_ru": "3",
        "name_tr": "3",
        "name_se": "3",
        "name_el": "3",
        "name_es": "3",
        "name_hr": "3"
    },
    "616^prematch": {
        "name_en": "none",
        "name_aa": "لا شيء",
        "name_de": "garnicht",
        "name_nl": "geen",
        "name_pl": "żaden",
        "name_ro": "niciunul",
        "name_ru": "не будет",
        "name_tr": "Gol olmaz",
        "name_se": "ingen",
        "name_el": "κανένα",
        "name_es": "ninguno",
        "name_hr": "nikad"
    },
    "956^prematch": {
        "name_en": "6th inning",
        "name_aa": "الشوط السادس",
        "name_de": "6. Inning",
        "name_nl": "6e inning",
        "name_pl": "6. inning",
        "name_ro": "inning 6",
        "name_ru": "6ой иннинг",
        "name_tr": "6. devre",
        "name_se": "6:e inning",
        "name_el": "6ο inning",
        "name_es": "6º inning",
        "name_hr": "6. inning"
    },
    "402^prematch": {
        "name_en": "2:0 2:1",
        "name_aa": "2:0 2:1",
        "name_de": "2:0 2:1",
        "name_nl": "2:0 2:1",
        "name_pl": "2:0 2:1",
        "name_ro": "2:0 2:1",
        "name_ru": "2:0 2:1",
        "name_tr": "2:0 2:1",
        "name_se": "2:0 2:1",
        "name_el": "2:0 2:1",
        "name_es": "2:0 2:1",
        "name_hr": "2:0 2:1"
    },
    "632^prematch": {
        "name_en": "{$competitor1} by 2",
        "name_aa": "{$competitor1} في 2",
        "name_de": "{$competitor1} mit 2",
        "name_nl": "{$competitor1} met 2",
        "name_pl": "{$competitor1} 2-a",
        "name_ro": "{$competitor1} cu 2",
        "name_ru": "{$competitor1} на 2",
        "name_tr": "{$competitor1} 2 farkla",
        "name_se": "{$competitor1} med 2",
        "name_el": "{$competitor1} με 2",
        "name_es": "{$competitor1} por 2",
        "name_hr": "{$competitor1} za 2"
    },
    "291^prematch": {
        "name_en": "11:4",
        "name_aa": "11:4",
        "name_de": "11:4",
        "name_nl": "11:4",
        "name_pl": "11:4",
        "name_ro": "11:4",
        "name_ru": "11:4",
        "name_tr": "11:4",
        "name_se": "11:4",
        "name_el": "11:4",
        "name_es": "11:4",
        "name_hr": "11:4"
    },
    "1082^prematch": {
        "name_en": "19-22",
        "name_aa": "19-22",
        "name_de": "19-22",
        "name_nl": "19-22",
        "name_pl": "19-22",
        "name_ro": "19-22",
        "name_ru": "19-22",
        "name_tr": "19-22",
        "name_se": "19-22",
        "name_el": "19-22",
        "name_es": "19-22",
        "name_hr": "19-22"
    },
    "133^prematch": {
        "name_en": "draw",
        "name_aa": "draw",
        "name_de": "draw",
        "name_nl": "draw",
        "name_pl": "draw",
        "name_ro": "draw",
        "name_ru": "draw",
        "name_tr": "draw",
        "name_se": "draw",
        "name_el": "draw",
        "name_es": "draw",
        "name_hr": "draw"
    },
    "1137^prematch": {
        "name_en": "0-10",
        "name_aa": "0-10",
        "name_de": "0-10",
        "name_nl": "0-10",
        "name_pl": "0-10",
        "name_ro": "0-10",
        "name_ru": "0-10",
        "name_tr": "0-10",
        "name_se": "0-10",
        "name_el": "0-10",
        "name_es": "0-10",
        "name_hr": "0-10"
    },
    "1429^prematch": {
        "name_en": "{$competitor2}  decision",
        "name_aa": "{$competitor2}  decision",
        "name_de": "{$competitor2}  decision",
        "name_nl": "{$competitor2}  decision",
        "name_pl": "{$competitor2}  decision",
        "name_ro": "{$competitor2}  decision",
        "name_ru": "{$competitor2}  decision",
        "name_tr": "{$competitor2}  decision",
        "name_se": "{$competitor2}  decision",
        "name_el": "{$competitor2}  decision",
        "name_es": "{$competitor2}  decision",
        "name_hr": "{$competitor2}  decision"
    },
    "1505^prematch": {
        "name_en": "0:5",
        "name_aa": "0:5",
        "name_de": "0:5",
        "name_nl": "0:5",
        "name_pl": "0:5",
        "name_ro": "0:5",
        "name_ru": "0:5",
        "name_tr": "0:5",
        "name_se": "0:5",
        "name_el": "0:5",
        "name_es": "0:5",
        "name_hr": "0:5"
    },
    "1076^prematch": {
        "name_en": "160-169",
        "name_aa": "160-169",
        "name_de": "160-169",
        "name_nl": "160-169",
        "name_pl": "160-169",
        "name_ro": "160-169",
        "name_ru": "160-169",
        "name_tr": "160-169",
        "name_se": "160-169",
        "name_el": "160-169",
        "name_es": "160-169",
        "name_hr": "160-169"
    },
    "130^prematch": {
        "name_en": "{$competitor1} by 15+",
        "name_aa": "{$competitor1} by 15+",
        "name_de": "{$competitor1} by 15+",
        "name_nl": "{$competitor1} by 15+",
        "name_pl": "{$competitor1} by 15+",
        "name_ro": "{$competitor1} by 15+",
        "name_ru": "{$competitor1} by 15+",
        "name_tr": "{$competitor1} by 15+",
        "name_se": "{$competitor1} by 15+",
        "name_el": "{$competitor1} by 15+",
        "name_es": "{$competitor1} by 15+",
        "name_hr": "{$competitor1} by 15+"
    },
    "275^prematch": {
        "name_en": "10:8",
        "name_aa": "10:8",
        "name_de": "10:8",
        "name_nl": "10:8",
        "name_pl": "10:8",
        "name_ro": "10:8",
        "name_ru": "10:8",
        "name_tr": "10:8",
        "name_se": "10:8",
        "name_el": "10:8",
        "name_es": "10:8",
        "name_hr": "10:8"
    },
    "462^prematch": {
        "name_en": "0:0",
        "name_aa": "0:0",
        "name_de": "0:0",
        "name_nl": "0:0",
        "name_pl": "0:0",
        "name_ro": "0:0",
        "name_ru": "0:0",
        "name_tr": "0:0",
        "name_se": "0:0",
        "name_el": "0:0",
        "name_es": "0:0",
        "name_hr": "0:0"
    },
    "1132^prematch": {
        "name_en": "0-30",
        "name_aa": "0-30",
        "name_de": "0-30",
        "name_nl": "0-30",
        "name_pl": "0-30",
        "name_ro": "0-30",
        "name_ru": "0-30",
        "name_tr": "0-30",
        "name_se": "0-30",
        "name_el": "0-30",
        "name_es": "0-30",
        "name_hr": "0-30"
    },
    "710^prematch": {
        "name_en": "0-3",
        "name_aa": "0-3",
        "name_de": "0-3",
        "name_nl": "0-3",
        "name_pl": "0-3",
        "name_ro": "0-3",
        "name_ru": "0-3",
        "name_tr": "0-3",
        "name_se": "0-3",
        "name_el": "0-3",
        "name_es": "0-3",
        "name_hr": "0-3"
    },
    "206^prematch": {
        "name_en": "7:0",
        "name_aa": "7:0",
        "name_de": "7:0",
        "name_nl": "7:0",
        "name_pl": "7:0",
        "name_ro": "7:0",
        "name_ru": "7:0",
        "name_tr": "7:0",
        "name_se": "7:0",
        "name_el": "7:0",
        "name_es": "7:0",
        "name_hr": "7:0"
    },
    "390^prematch": {
        "name_en": "1:1 1:2",
        "name_aa": "1:1 1:2",
        "name_de": "1:1 1:2",
        "name_nl": "1:1 1:2",
        "name_pl": "1:1 1:2",
        "name_ro": "1:1 1:2",
        "name_ru": "1:1 1:2",
        "name_tr": "1:1 1:2",
        "name_se": "1:1 1:2",
        "name_el": "1:1 1:2",
        "name_es": "1:1 1:2",
        "name_hr": "1:1 1:2"
    },
    "636^prematch": {
        "name_en": "{$competitor2} by 1",
        "name_aa": "{$competitor2} في 1",
        "name_de": "{$competitor2} mit 1",
        "name_nl": "{$competitor2} met 1",
        "name_pl": "{$competitor2} 1-ym",
        "name_ro": "{$competitor2} cu 1",
        "name_ru": "{$competitor2} на 1",
        "name_tr": "{$competitor2} 1 farkla",
        "name_se": "{$competitor2} med 1",
        "name_el": "{$competitor2} με 1",
        "name_es": "{$competitor2} por 1",
        "name_hr": "{$competitor2} za 1"
    },
    "1742^prematch": {
        "name_en": "4-5",
        "name_aa": "4-5",
        "name_de": "4-5",
        "name_nl": "4-5",
        "name_pl": "4-5",
        "name_ro": "4-5",
        "name_ru": "4-5",
        "name_tr": "4-5",
        "name_se": "4-5",
        "name_el": "4-5",
        "name_es": "4-5",
        "name_hr": "4-5"
    },
    "1368^prematch": {
        "name_en": "draw",
        "name_aa": "draw",
        "name_de": "draw",
        "name_nl": "draw",
        "name_pl": "draw",
        "name_ro": "draw",
        "name_ru": "draw",
        "name_tr": "draw",
        "name_se": "draw",
        "name_el": "draw",
        "name_es": "draw",
        "name_hr": "draw"
    },
    "1189^prematch": {
        "name_en": "5:2",
        "name_aa": "5:2",
        "name_de": "5:2",
        "name_nl": "5:2",
        "name_pl": "5:2",
        "name_ro": "5:2",
        "name_ru": "5:2",
        "name_tr": "5:2",
        "name_se": "5:2",
        "name_el": "5:2",
        "name_es": "5:2",
        "name_hr": "5:2"
    },
    "1694640000^prematch": {
        "name_en": "4+/4+",
        "name_aa": "4+/4+",
        "name_de": "4+/4+",
        "name_nl": "4+/4+",
        "name_pl": "4+/4+",
        "name_ro": "4+/4+",
        "name_ru": "4+/4+",
        "name_tr": "4+/4+",
        "name_se": "4+/4+",
        "name_el": "4+/4+",
        "name_es": "4+/4+",
        "name_hr": "4+/4+"
    },
    "192^prematch": {
        "name_en": "7:0",
        "name_aa": "7:0",
        "name_de": "7:0",
        "name_nl": "7:0",
        "name_pl": "7:0",
        "name_ro": "7:0",
        "name_ru": "7:0",
        "name_tr": "7:0",
        "name_se": "7:0",
        "name_el": "7:0",
        "name_es": "7:0",
        "name_hr": "7:0"
    },
    "1416^prematch": {
        "name_en": "draw",
        "name_aa": "draw",
        "name_de": "draw",
        "name_nl": "draw",
        "name_pl": "draw",
        "name_ro": "draw",
        "name_ru": "draw",
        "name_tr": "draw",
        "name_se": "draw",
        "name_el": "draw",
        "name_es": "draw",
        "name_hr": "draw"
    },
    "1501^prematch": {
        "name_en": "2:4",
        "name_aa": "2:4",
        "name_de": "2:4",
        "name_nl": "2:4",
        "name_pl": "2:4",
        "name_ro": "2:4",
        "name_ru": "2:4",
        "name_tr": "2:4",
        "name_se": "2:4",
        "name_el": "2:4",
        "name_es": "2:4",
        "name_hr": "2:4"
    },
    "83550000^prematch": {
        "name_en": "Yes",
        "name_aa": "Yes",
        "name_de": "Yes",
        "name_nl": "Yes",
        "name_pl": "Yes",
        "name_ro": "Yes",
        "name_ru": "Yes",
        "name_tr": "Yes",
        "name_se": "Yes",
        "name_el": "Yes",
        "name_es": "Yes",
        "name_hr": "Yes"
    },
    "1876^prematch": {
        "name_en": "draw/draw  4",
        "name_aa": "رسم / رسم و 4",
        "name_de": "unentschieden/unentschieden 4",
        "name_nl": "gelijkspel/gelijkspel  4",
        "name_pl": "remis/remis  4",
        "name_ro": "egal/egal  4",
        "name_ru": "ничья/ничья и 4",
        "name_tr": "beraberlik/beraberlik  4",
        "name_se": "oavgjort/oavgjort  4",
        "name_el": "ισοπαλία/ισοπαλία  4",
        "name_es": "empate/empate  4",
        "name_hr": "neodlučeno/neodlučeno i 4"
    },
    "1374^prematch": {
        "name_en": "{$competitor2}  decision",
        "name_aa": "{$competitor2}  decision",
        "name_de": "{$competitor2}  decision",
        "name_nl": "{$competitor2}  decision",
        "name_pl": "{$competitor2}  decision",
        "name_ro": "{$competitor2}  decision",
        "name_ru": "{$competitor2}  decision",
        "name_tr": "{$competitor2}  decision",
        "name_se": "{$competitor2}  decision",
        "name_el": "{$competitor2}  decision",
        "name_es": "{$competitor2}  decision",
        "name_hr": "{$competitor2}  decision"
    },
    "614^prematch": {
        "name_en": "81-90",
        "name_aa": "81-90",
        "name_de": "81-90",
        "name_nl": "81-90",
        "name_pl": "81-90",
        "name_ro": "81-90",
        "name_ru": "81-90",
        "name_tr": "81-90",
        "name_se": "81-90",
        "name_el": "81-90",
        "name_es": "81-90",
        "name_hr": "81-90"
    },
    "187^prematch": {
        "name_en": "4:6",
        "name_aa": "4:6",
        "name_de": "4:6",
        "name_nl": "4:6",
        "name_pl": "4:6",
        "name_ro": "4:6",
        "name_ru": "4:6",
        "name_tr": "4:6",
        "name_se": "4:6",
        "name_el": "4:6",
        "name_es": "4:6",
        "name_hr": "4:6"
    },
    "957^prematch": {
        "name_en": "7th inning",
        "name_aa": "الشوط السابع",
        "name_de": "7. Inning",
        "name_nl": "7e inning",
        "name_pl": "7. inning",
        "name_ro": "inning 7",
        "name_ru": "7ой иннинг",
        "name_tr": "7. devre",
        "name_se": "7:e inning",
        "name_el": "7ο inning",
        "name_es": "7º inning",
        "name_hr": "7. inning"
    },
    "1504^prematch": {
        "name_en": "5:4",
        "name_aa": "5:4",
        "name_de": "5:4",
        "name_nl": "5:4",
        "name_pl": "5:4",
        "name_ro": "5:4",
        "name_ru": "5:4",
        "name_tr": "5:4",
        "name_se": "5:4",
        "name_el": "5:4",
        "name_es": "5:4",
        "name_hr": "5:4"
    },
    "310^prematch": {
        "name_en": "3:3",
        "name_aa": "3:3",
        "name_de": "3:3",
        "name_nl": "3:3",
        "name_pl": "3:3",
        "name_ro": "3:3",
        "name_ru": "3:3",
        "name_tr": "3:3",
        "name_se": "3:3",
        "name_el": "3:3",
        "name_es": "3:3",
        "name_hr": "3:3"
    },
    "69^prematch": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1"
    },
    "1357^prematch": {
        "name_en": "draw",
        "name_aa": "draw",
        "name_de": "draw",
        "name_nl": "draw",
        "name_pl": "draw",
        "name_ro": "draw",
        "name_ru": "draw",
        "name_tr": "draw",
        "name_se": "draw",
        "name_el": "draw",
        "name_es": "draw",
        "name_hr": "draw"
    },
    "222^prematch": {
        "name_en": "7:3",
        "name_aa": "7:3",
        "name_de": "7:3",
        "name_nl": "7:3",
        "name_pl": "7:3",
        "name_ro": "7:3",
        "name_ru": "7:3",
        "name_tr": "7:3",
        "name_se": "7:3",
        "name_el": "7:3",
        "name_es": "7:3",
        "name_hr": "7:3"
    },
    "1717^prematch": {
        "name_en": "draw",
        "name_aa": "رسم",
        "name_de": "unentschieden",
        "name_nl": "gelijkspel",
        "name_pl": "remis",
        "name_ro": "egalitate",
        "name_ru": "ничья",
        "name_tr": "beraberlik",
        "name_se": "oavgjort",
        "name_el": "ισοπαλία",
        "name_es": "empate",
        "name_hr": "neodlučeno"
    },
    "70840000^prematch": {
        "name_en": "2-X",
        "name_aa": "2-X",
        "name_de": "2-X",
        "name_nl": "2-X",
        "name_pl": "2-X",
        "name_ro": "2-X",
        "name_ru": "2-X",
        "name_tr": "2-X",
        "name_se": "2-X",
        "name_el": "2-X",
        "name_es": "2-X",
        "name_hr": "2-X"
    },
    "70850000^prematch": {
        "name_en": "2-2",
        "name_aa": "2-2",
        "name_de": "2-2",
        "name_nl": "2-2",
        "name_pl": "2-2",
        "name_ro": "2-2",
        "name_ru": "2-2",
        "name_tr": "2-2",
        "name_se": "2-2",
        "name_el": "2-2",
        "name_es": "2-2",
        "name_hr": "2-2"
    },
    "352^prematch": {
        "name_en": "0:1 0:3",
        "name_aa": "0:1 0:3",
        "name_de": "0:1 0:3",
        "name_nl": "0:1 0:3",
        "name_pl": "0:1 0:3",
        "name_ro": "0:1 0:3",
        "name_ru": "0:1 0:3",
        "name_tr": "0:1 0:3",
        "name_se": "0:1 0:3",
        "name_el": "0:1 0:3",
        "name_es": "0:1 0:3",
        "name_hr": "0:1 0:3"
    },
    "1088^prematch": {
        "name_en": "26-30",
        "name_aa": "26-30",
        "name_de": "26-30",
        "name_nl": "26-30",
        "name_pl": "26-30",
        "name_ro": "26-30",
        "name_ru": "26-30",
        "name_tr": "26-30",
        "name_se": "26-30",
        "name_el": "26-30",
        "name_es": "26-30",
        "name_hr": "26-30"
    },
    "1349^prematch": {
        "name_en": "draw",
        "name_aa": "draw",
        "name_de": "draw",
        "name_nl": "draw",
        "name_pl": "draw",
        "name_ro": "draw",
        "name_ru": "draw",
        "name_tr": "draw",
        "name_se": "draw",
        "name_el": "draw",
        "name_es": "draw",
        "name_hr": "draw"
    },
    "303^prematch": {
        "name_en": "5:11",
        "name_aa": "5:11",
        "name_de": "5:11",
        "name_nl": "5:11",
        "name_pl": "5:11",
        "name_ro": "5:11",
        "name_ru": "5:11",
        "name_tr": "5:11",
        "name_se": "5:11",
        "name_el": "5:11",
        "name_es": "5:11",
        "name_hr": "5:11"
    },
    "292^prematch": {
        "name_en": "11:5",
        "name_aa": "11:5",
        "name_de": "11:5",
        "name_nl": "11:5",
        "name_pl": "11:5",
        "name_ro": "11:5",
        "name_ru": "11:5",
        "name_tr": "11:5",
        "name_se": "11:5",
        "name_el": "11:5",
        "name_es": "11:5",
        "name_hr": "11:5"
    },
    "1622^prematch": {
        "name_en": "2nd period",
        "name_aa": "الفترة الثانية",
        "name_de": "2. Periode",
        "name_nl": "2e periode",
        "name_pl": "2. Część gry",
        "name_ro": "repriza 2",
        "name_ru": "2ой период",
        "name_tr": "İkinci periyot",
        "name_se": "2:a period",
        "name_el": "2η περίοδος",
        "name_es": "2º periodo",
        "name_hr": "2. period"
    },
    "418^prematch": {
        "name_en": "{$competitor1}/{$competitor1}",
        "name_aa": "{$competitor1} / {$competitor1}",
        "name_de": "{$competitor1}/{$competitor1}",
        "name_nl": "{$competitor1}/{$competitor1}",
        "name_pl": "{$competitor1}/{$competitor1}",
        "name_ro": "{$competitor1}/{$competitor1}",
        "name_ru": "{$competitor1}/{$competitor1}",
        "name_tr": "{$competitor1}/{$competitor1}",
        "name_se": "{$competitor1}/{$competitor1}",
        "name_el": "{$competitor1}/{$competitor1}",
        "name_es": "{$competitor1}/{$competitor1}",
        "name_hr": "{$competitor1}/{$competitor1}"
    },
    "53^prematch": {
        "name_en": "0",
        "name_aa": "0",
        "name_de": "0",
        "name_nl": "0",
        "name_pl": "0",
        "name_ro": "0",
        "name_ru": "0",
        "name_tr": "0",
        "name_se": "0",
        "name_el": "0",
        "name_es": "0",
        "name_hr": "0"
    },
    "338^prematch": {
        "name_en": "0:0 1:2",
        "name_aa": "0:0 1:2",
        "name_de": "0:0 1:2",
        "name_nl": "0:0 1:2",
        "name_pl": "0:0 1:2",
        "name_ro": "0:0 1:2",
        "name_ru": "0:0 1:2",
        "name_tr": "0:0 1:2",
        "name_se": "0:0 1:2",
        "name_el": "0:0 1:2",
        "name_es": "0:0 1:2",
        "name_hr": "0:0 1:2"
    },
    "131^prematch": {
        "name_en": "{$competitor1} by 8-14",
        "name_aa": "{$competitor1} by 8-14",
        "name_de": "{$competitor1} by 8-14",
        "name_nl": "{$competitor1} by 8-14",
        "name_pl": "{$competitor1} by 8-14",
        "name_ro": "{$competitor1} by 8-14",
        "name_ru": "{$competitor1} by 8-14",
        "name_tr": "{$competitor1} by 8-14",
        "name_se": "{$competitor1} by 8-14",
        "name_el": "{$competitor1} by 8-14",
        "name_es": "{$competitor1} by 8-14",
        "name_hr": "{$competitor1} by 8-14"
    },
    "299^prematch": {
        "name_en": "9:11",
        "name_aa": "9:11",
        "name_de": "9:11",
        "name_nl": "9:11",
        "name_pl": "9:11",
        "name_ro": "9:11",
        "name_ru": "9:11",
        "name_tr": "9:11",
        "name_se": "9:11",
        "name_el": "9:11",
        "name_es": "9:11",
        "name_hr": "9:11"
    },
    "1885^prematch": {
        "name_en": "draw/draw  5+",
        "name_aa": "رسم / رسم و5+",
        "name_de": "unentschieden/unentschieden 5+",
        "name_nl": "gelijkspel/gelijkspel  5+",
        "name_pl": "remis/remis  5+",
        "name_ro": "egal/egal  5+",
        "name_ru": "ничья/ничья и 5+",
        "name_tr": "beraberlik/beraberlik  5+",
        "name_se": "oavgjort/oavgjort  5+",
        "name_el": "ισοπαλία/ισοπαλία  5+",
        "name_es": "empate/empate  5+",
        "name_hr": "neodlučeno/neodlučeno i 5+"
    },
    "776^prematch": {
        "name_en": "draw",
        "name_aa": "رسم",
        "name_de": "unentschieden",
        "name_nl": "gelijkspel",
        "name_pl": "remis",
        "name_ro": "egal",
        "name_ru": "ничья",
        "name_tr": "beraberlik",
        "name_se": "oavgjort",
        "name_el": "ισοπαλία",
        "name_es": "empate",
        "name_hr": "neodlučeno"
    },
    "226^prematch": {
        "name_en": "6:7",
        "name_aa": "6:7",
        "name_de": "6:7",
        "name_nl": "6:7",
        "name_pl": "6:7",
        "name_ro": "6:7",
        "name_ru": "6:7",
        "name_tr": "6:7",
        "name_se": "6:7",
        "name_el": "6:7",
        "name_es": "6:7",
        "name_hr": "6:7"
    },
    "191^prematch": {
        "name_en": "0:6",
        "name_aa": "0:6",
        "name_de": "0:6",
        "name_nl": "0:6",
        "name_pl": "0:6",
        "name_ro": "0:6",
        "name_ru": "0:6",
        "name_tr": "0:6",
        "name_se": "0:6",
        "name_el": "0:6",
        "name_es": "0:6",
        "name_hr": "0:6"
    },
    "730^prematch": {
        "name_en": "0-1",
        "name_aa": "0-1",
        "name_de": "0-1",
        "name_nl": "0-1",
        "name_pl": "0-1",
        "name_ro": "0-1",
        "name_ru": "0-1",
        "name_tr": "0-1",
        "name_se": "0-1",
        "name_el": "0-1",
        "name_es": "0-1",
        "name_hr": "0-1"
    },
    "558^prematch": {
        "name_en": "2:0",
        "name_aa": "2:0",
        "name_de": "2:0",
        "name_nl": "2:0",
        "name_pl": "2:0",
        "name_ro": "2:0",
        "name_ru": "2:0",
        "name_tr": "2:0",
        "name_se": "2:0",
        "name_el": "2:0",
        "name_es": "2:0",
        "name_hr": "2:0"
    },
    "1415^prematch": {
        "name_en": "{$competitor1}  decision",
        "name_aa": "{$competitor1}  decision",
        "name_de": "{$competitor1}  decision",
        "name_nl": "{$competitor1}  decision",
        "name_pl": "{$competitor1}  decision",
        "name_ro": "{$competitor1}  decision",
        "name_ru": "{$competitor1}  decision",
        "name_tr": "{$competitor1}  decision",
        "name_se": "{$competitor1}  decision",
        "name_el": "{$competitor1}  decision",
        "name_es": "{$competitor1}  decision",
        "name_hr": "{$competitor1}  decision"
    },
    "802^prematch": {
        "name_en": "{$competitor2}  under {total}",
        "name_aa": "{$competitor2} و تحت {total}",
        "name_de": "{$competitor2}  unter {total}",
        "name_nl": "{$competitor2}  onder {total}",
        "name_pl": "{$competitor2}  poniżej {total}",
        "name_ro": "{$competitor2}  sub {total}",
        "name_ru": "{$competitor2}  меньше {total}",
        "name_tr": "{$competitor2}  {total} altı",
        "name_se": "{$competitor2}  under {total}",
        "name_el": "{$competitor2} και under  {total}",
        "name_es": "{$competitor2}  menos de {total}",
        "name_hr": "{$competitor2} i manje od {total}"
    },
    "70810000^prematch": {
        "name_en": "X-X",
        "name_aa": "X-X",
        "name_de": "X-X",
        "name_nl": "X-X",
        "name_pl": "X-X",
        "name_ro": "X-X",
        "name_ru": "X-X",
        "name_tr": "X-X",
        "name_se": "X-X",
        "name_el": "X-X",
        "name_es": "X-X",
        "name_hr": "X-X"
    },
    "1181^prematch": {
        "name_en": "3:3",
        "name_aa": "3:3",
        "name_de": "3:3",
        "name_nl": "3:3",
        "name_pl": "3:3",
        "name_ro": "3:3",
        "name_ru": "3:3",
        "name_tr": "3:3",
        "name_se": "3:3",
        "name_el": "3:3",
        "name_es": "3:3",
        "name_hr": "3:3"
    },
    "1826^prematch": {
        "name_en": "{!inningnr} inning",
        "name_aa": "{!inningnr} الشوط",
        "name_de": "{!inningnr} Inning",
        "name_nl": "{!inningnr} inning",
        "name_pl": "{!inningnr} inning",
        "name_ro": "{!inningnr} inning",
        "name_ru": "{inningnr}иннинг",
        "name_tr": "{!inningnr} devre",
        "name_se": "{!inningnr} inning",
        "name_el": "{!inningnr} inning",
        "name_es": "{!inningnr} inning",
        "name_hr": "{!inningnr} inning"
    },
    "1124^prematch": {
        "name_en": "6+",
        "name_aa": "6+",
        "name_de": "6+",
        "name_nl": "6+",
        "name_pl": "6+",
        "name_ro": "6+",
        "name_ru": "6+",
        "name_tr": "6+",
        "name_se": "6+",
        "name_el": "6+",
        "name_es": "6+",
        "name_hr": "6+"
    },
    "159^prematch": {
        "name_en": "111-120",
        "name_aa": "111-120",
        "name_de": "111-120",
        "name_nl": "111-120",
        "name_pl": "111-120",
        "name_ro": "111-120",
        "name_ru": "111-120",
        "name_tr": "111-120",
        "name_se": "111-120",
        "name_el": "111-120",
        "name_es": "111-120",
        "name_hr": "111-120"
    },
    "966^prematch": {
        "name_en": "5+",
        "name_aa": "5+",
        "name_de": "5+",
        "name_nl": "5+",
        "name_pl": "5+",
        "name_ro": "5+",
        "name_ru": "5+",
        "name_tr": "5+",
        "name_se": "5+",
        "name_el": "5+",
        "name_es": "5+",
        "name_hr": "5+"
    },
    "320^prematch": {
        "name_en": "3:4",
        "name_aa": "3:4",
        "name_de": "3:4",
        "name_nl": "3:4",
        "name_pl": "3:4",
        "name_ro": "3:4",
        "name_ru": "3:4",
        "name_tr": "3:4",
        "name_se": "3:4",
        "name_el": "3:4",
        "name_es": "3:4",
        "name_hr": "3:4"
    },
    "394^prematch": {
        "name_en": "1:1 4+",
        "name_aa": "1:1 4+",
        "name_de": "1:1 4+",
        "name_nl": "1:1 4+",
        "name_pl": "1:1 4+",
        "name_ro": "1:1 4+",
        "name_ru": "1:1 4+",
        "name_tr": "1:1 4+",
        "name_se": "1:1 4+",
        "name_el": "1:1 4+",
        "name_es": "1:1 4+",
        "name_hr": "1:1 4+"
    },
    "330^prematch": {
        "name_en": "0:0 0:2",
        "name_aa": "0:0 0:2",
        "name_de": "0:0 0:2",
        "name_nl": "0:0 0:2",
        "name_pl": "0:0 0:2",
        "name_ro": "0:0 0:2",
        "name_ru": "0:0 0:2",
        "name_tr": "0:0 0:2",
        "name_se": "0:0 0:2",
        "name_el": "0:0 0:2",
        "name_es": "0:0 0:2",
        "name_hr": "0:0 0:2"
    },
    "332^prematch": {
        "name_en": "0:0 0:3",
        "name_aa": "0:0 0:3",
        "name_de": "0:0 0:3",
        "name_nl": "0:0 0:3",
        "name_pl": "0:0 0:3",
        "name_ro": "0:0 0:3",
        "name_ru": "0:0 0:3",
        "name_tr": "0:0 0:3",
        "name_se": "0:0 0:3",
        "name_el": "0:0 0:3",
        "name_es": "0:0 0:3",
        "name_hr": "0:0 0:3"
    },
    "1387^prematch": {
        "name_en": "{$competitor2}  4-6",
        "name_aa": "{$competitor2}  4-6",
        "name_de": "{$competitor2}  4-6",
        "name_nl": "{$competitor2}  4-6",
        "name_pl": "{$competitor2}  4-6",
        "name_ro": "{$competitor2}  4-6",
        "name_ru": "{$competitor2}  4-6",
        "name_tr": "{$competitor2}  4-6",
        "name_se": "{$competitor2}  4-6",
        "name_el": "{$competitor2}  4-6",
        "name_es": "{$competitor2}  4-6",
        "name_hr": "{$competitor2}  4-6"
    },
    "56170000^prematch": {
        "name_en": "6+",
        "name_aa": "6+",
        "name_de": "6+",
        "name_nl": "6+",
        "name_pl": "6+",
        "name_ro": "6+",
        "name_ru": "6+",
        "name_tr": "6+",
        "name_se": "6+",
        "name_el": "6+",
        "name_es": "6+",
        "name_hr": "6+"
    },
    "119^prematch": {
        "name_en": "draw",
        "name_aa": "draw",
        "name_de": "draw",
        "name_nl": "draw",
        "name_pl": "draw",
        "name_ro": "draw",
        "name_ru": "draw",
        "name_tr": "draw",
        "name_se": "draw",
        "name_el": "draw",
        "name_es": "draw",
        "name_hr": "draw"
    },
    "72^prematch": {
        "name_en": "even",
        "name_aa": "زوجي",
        "name_de": "gerade",
        "name_nl": "even",
        "name_pl": "parzysty",
        "name_ro": "par",
        "name_ru": "четное",
        "name_tr": "çift",
        "name_se": "jämna",
        "name_el": "ζυγά",
        "name_es": "par",
        "name_hr": "nepar"
    },
    "863^prematch": {
        "name_en": "{$competitor1}/{$competitor2}",
        "name_aa": "{$competitor1} / {$competitor2}",
        "name_de": "{$competitor1}/{$competitor2}",
        "name_nl": "{$competitor1}/{$competitor2}",
        "name_pl": "{$competitor1}/{$competitor2}",
        "name_ro": "{$competitor1}/{$competitor2}",
        "name_ru": "{$competitor1}/{$competitor2}",
        "name_tr": "{$competitor1}/{$competitor2}",
        "name_se": "{$competitor1}/{$competitor2}",
        "name_el": "{$competitor1}/{$competitor2}",
        "name_es": "{$competitor1}/{$competitor2}",
        "name_hr": "{$competitor1}/{$competitor2}"
    },
    "56180000^prematch": {
        "name_en": "0-3",
        "name_aa": "0-3",
        "name_de": "0-3",
        "name_nl": "0-3",
        "name_pl": "0-3",
        "name_ro": "0-3",
        "name_ru": "0-3",
        "name_tr": "0-3",
        "name_se": "0-3",
        "name_el": "0-3",
        "name_es": "0-3",
        "name_hr": "0-3"
    },
    "1803^prematch": {
        "name_en": "draw",
        "name_aa": "رسم",
        "name_de": "unentschieden",
        "name_nl": "gelijkspel",
        "name_pl": "remis",
        "name_ro": "egalitate",
        "name_ru": "ничья",
        "name_tr": "beraberlik",
        "name_se": "oavgjort",
        "name_el": "ισοπαλία",
        "name_es": "empate",
        "name_hr": "neodlučeno"
    },
    "183^prematch": {
        "name_en": "6:3",
        "name_aa": "6:3",
        "name_de": "6:3",
        "name_nl": "6:3",
        "name_pl": "6:3",
        "name_ro": "6:3",
        "name_ru": "6:3",
        "name_tr": "6:3",
        "name_se": "6:3",
        "name_el": "6:3",
        "name_es": "6:3",
        "name_hr": "6:3"
    },
    "360^prematch": {
        "name_en": "0:1 4+",
        "name_aa": "0:1 4+",
        "name_de": "0:1 4+",
        "name_nl": "0:1 4+",
        "name_pl": "0:1 4+",
        "name_ro": "0:1 4+",
        "name_ru": "0:1 4+",
        "name_tr": "0:1 4+",
        "name_se": "0:1 4+",
        "name_el": "0:1 4+",
        "name_es": "0:1 4+",
        "name_hr": "0:1 4+"
    },
    "1828^prematch": {
        "name_en": "{!(inningnr+1)} inning",
        "name_aa": "{!(inningnr+1)} الشوط",
        "name_de": "{!(inningnr+1)} Inning",
        "name_nl": "{!(inningnr+1)} inning",
        "name_pl": "{!(inningnr+1)} inning",
        "name_ro": "{!(inningnr+1)} inning",
        "name_ru": "{!(inningnr+1)} иннинг",
        "name_tr": "{!(inningnr+1)} devre",
        "name_se": "{!(inningnr+1)} inning",
        "name_el": "{!(inningnr+1)} inning",
        "name_es": "{!(inningnr+1)} inning",
        "name_hr": "{!(inningnr+1)} inning"
    },
    "554^prematch": {
        "name_en": "1:1",
        "name_aa": "1:1",
        "name_de": "1:1",
        "name_nl": "1:1",
        "name_pl": "1:1",
        "name_ro": "1:1",
        "name_ru": "1:1",
        "name_tr": "1:1",
        "name_se": "1:1",
        "name_el": "1:1",
        "name_es": "1:1",
        "name_hr": "1:1"
    },
    "386^prematch": {
        "name_en": "1:0 4+",
        "name_aa": "1:0 4+",
        "name_de": "1:0 4+",
        "name_nl": "1:0 4+",
        "name_pl": "1:0 4+",
        "name_ro": "1:0 4+",
        "name_ru": "1:0 4+",
        "name_tr": "1:0 4+",
        "name_se": "1:0 4+",
        "name_el": "1:0 4+",
        "name_es": "1:0 4+",
        "name_hr": "1:0 4+"
    },
    "438^prematch": {
        "name_en": "2nd half",
        "name_aa": "النصف الثاني",
        "name_de": "2. Halbzeit",
        "name_nl": "tweede helft",
        "name_pl": "2. Połowa",
        "name_ro": "Repriza 2",
        "name_ru": "2ая половина",
        "name_tr": "İkinci yarı",
        "name_se": "2:a halvlek",
        "name_el": "2ο ημίχρονο",
        "name_es": "2º mitad",
        "name_hr": "2. poluvrijeme"
    },
    "1720^prematch": {
        "name_en": "{$competitor1}/{$competitor2}  yes",
        "name_aa": "{$competitor1} / {$competitor2} ونعم",
        "name_de": "{$competitor1}/{$competitor2}  ja",
        "name_nl": "{$competitor1}/{$competitor2}  ja",
        "name_pl": "{$competitor1}/{$competitor2}  tak",
        "name_ro": "{$competitor1}/{$competitor2}  da",
        "name_ru": "{$competitor1}/{$competitor2} и да",
        "name_tr": "{$competitor1}/{$competitor2}  var",
        "name_se": "{$competitor1}/{$competitor2}  ja",
        "name_el": "{$competitor1}/{$competitor2} και ναι",
        "name_es": "{$competitor1}/{$competitor2} y sí",
        "name_hr": "{$competitor1}/{$competitor2}  i da"
    },
    "179^prematch": {
        "name_en": "6:0",
        "name_aa": "6:0",
        "name_de": "6:0",
        "name_nl": "6:0",
        "name_pl": "6:0",
        "name_ro": "6:0",
        "name_ru": "6:0",
        "name_tr": "6:0",
        "name_se": "6:0",
        "name_el": "6:0",
        "name_es": "6:0",
        "name_hr": "6:0"
    },
    "57^prematch": {
        "name_en": "4",
        "name_aa": "4",
        "name_de": "4",
        "name_nl": "4",
        "name_pl": "4",
        "name_ro": "4",
        "name_ru": "4",
        "name_tr": "4",
        "name_se": "4",
        "name_el": "4",
        "name_es": "4",
        "name_hr": "4"
    },
    "876^prematch": {
        "name_en": "4:6",
        "name_aa": "4:6",
        "name_de": "4:6",
        "name_nl": "4:6",
        "name_pl": "4:6",
        "name_ro": "4:6",
        "name_ru": "4:6",
        "name_tr": "4:6",
        "name_se": "4:6",
        "name_el": "4:6",
        "name_es": "4:6",
        "name_hr": "4:6"
    },
    "1174^prematch": {
        "name_en": "2:2",
        "name_aa": "2:2",
        "name_de": "2:2",
        "name_nl": "2:2",
        "name_pl": "2:2",
        "name_ro": "2:2",
        "name_ru": "2:2",
        "name_tr": "2:2",
        "name_se": "2:2",
        "name_el": "2:2",
        "name_es": "2:2",
        "name_hr": "2:2"
    },
    "1740^prematch": {
        "name_en": "3-5",
        "name_aa": "3-5",
        "name_de": "3-5",
        "name_nl": "3-5",
        "name_pl": "3-5",
        "name_ro": "3-5",
        "name_ru": "3-5",
        "name_tr": "3-5",
        "name_se": "3-5",
        "name_el": "3-5",
        "name_es": "3-5",
        "name_hr": "3-5"
    },
    "1351^prematch": {
        "name_en": "{$competitor2} by submission",
        "name_aa": "{$competitor2} by submission",
        "name_de": "{$competitor2} by submission",
        "name_nl": "{$competitor2} by submission",
        "name_pl": "{$competitor2} by submission",
        "name_ro": "{$competitor2} by submission",
        "name_ru": "{$competitor2} by submission",
        "name_tr": "{$competitor2} by submission",
        "name_se": "{$competitor2} by submission",
        "name_el": "{$competitor2} by submission",
        "name_es": "{$competitor2} by submission",
        "name_hr": "{$competitor2} by submission"
    },
    "161^prematch": {
        "name_en": "131-140",
        "name_aa": "131-140",
        "name_de": "131-140",
        "name_nl": "131-140",
        "name_pl": "131-140",
        "name_ro": "131-140",
        "name_ru": "131-140",
        "name_tr": "131-140",
        "name_se": "131-140",
        "name_el": "131-140",
        "name_es": "131-140",
        "name_hr": "131-140"
    },
    "1862^prematch": {
        "name_en": "draw/draw  2",
        "name_aa": "رسم / رسم و 2",
        "name_de": "unentschieden/unentschieden 2",
        "name_nl": "gelijkspel/gelijkspel  2",
        "name_pl": "remis/remis  2",
        "name_ro": "egal/egal  2",
        "name_ru": "ничья/ничья и 2",
        "name_tr": "beraberlik/beraberlik  2",
        "name_se": "oavgjort/oavgjort  2",
        "name_el": "ισοπαλία/ισοπαλία  2",
        "name_es": "empate/empate  2",
        "name_hr": "neodlučeno/neodlučeno i 2"
    },
    "1491^prematch": {
        "name_en": "4:2",
        "name_aa": "4:2",
        "name_de": "4:2",
        "name_nl": "4:2",
        "name_pl": "4:2",
        "name_ro": "4:2",
        "name_ru": "4:2",
        "name_tr": "4:2",
        "name_se": "4:2",
        "name_el": "4:2",
        "name_es": "4:2",
        "name_hr": "4:2"
    },
    "864^prematch": {
        "name_en": "{$competitor2}/{$competitor2}",
        "name_aa": "{$competitor2} / {$competitor2}",
        "name_de": "{$competitor2}/{$competitor2}",
        "name_nl": "{$competitor2}/{$competitor2}",
        "name_pl": "{$competitor2}/{$competitor2}",
        "name_ro": "{$competitor2}/{$competitor2}",
        "name_ru": "{$competitor2}/{$competitor2}",
        "name_tr": "{$competitor2}/{$competitor2}",
        "name_se": "{$competitor2}/{$competitor2}",
        "name_el": "{$competitor2}/{$competitor2}",
        "name_es": "{$competitor2}/{$competitor2}",
        "name_hr": "{$competitor2}/{$competitor2}"
    },
    "1409^prematch": {
        "name_en": "{$competitor1}  7",
        "name_aa": "{$competitor1}  7",
        "name_de": "{$competitor1}  7",
        "name_nl": "{$competitor1}  7",
        "name_pl": "{$competitor1}  7",
        "name_ro": "{$competitor1}  7",
        "name_ru": "{$competitor1}  7",
        "name_tr": "{$competitor1}  7",
        "name_se": "{$competitor1}  7",
        "name_el": "{$competitor1}  7",
        "name_es": "{$competitor1}  7",
        "name_hr": "{$competitor1}  7"
    },
    "1763^prematch": {
        "name_en": "3",
        "name_aa": "3",
        "name_de": "3",
        "name_nl": "3",
        "name_pl": "3",
        "name_ro": "3",
        "name_ru": "3",
        "name_tr": "3",
        "name_se": "3",
        "name_el": "3",
        "name_es": "3",
        "name_hr": "3"
    },
    "602^prematch": {
        "name_en": "21-30",
        "name_aa": "21-30",
        "name_de": "21-30",
        "name_nl": "21-30",
        "name_pl": "21-30",
        "name_ro": "21-30",
        "name_ru": "21-30",
        "name_tr": "21-30",
        "name_se": "21-30",
        "name_el": "21-30",
        "name_es": "21-30",
        "name_hr": "21-30"
    },
    "1168^prematch": {
        "name_en": "1:3",
        "name_aa": "1:3",
        "name_de": "1:3",
        "name_nl": "1:3",
        "name_pl": "1:3",
        "name_ro": "1:3",
        "name_ru": "1:3",
        "name_tr": "1:3",
        "name_se": "1:3",
        "name_el": "1:3",
        "name_es": "1:3",
        "name_hr": "1:3"
    },
    "1378^prematch": {
        "name_en": "{$competitor2} by ko",
        "name_aa": "{$competitor2} by ko",
        "name_de": "{$competitor2} by ko",
        "name_nl": "{$competitor2} by ko",
        "name_pl": "{$competitor2} by ko",
        "name_ro": "{$competitor2} by ko",
        "name_ru": "{$competitor2} by ko",
        "name_tr": "{$competitor2} by ko",
        "name_se": "{$competitor2} by ko",
        "name_el": "{$competitor2} by ko",
        "name_es": "{$competitor2} by ko",
        "name_hr": "{$competitor2} by ko"
    },
    "1301^prematch": {
        "name_en": "31+",
        "name_aa": "31+",
        "name_de": "31+",
        "name_nl": "31+",
        "name_pl": "31+",
        "name_ro": "31+",
        "name_ru": "31+",
        "name_tr": "31+",
        "name_se": "31+",
        "name_el": "31+",
        "name_es": "31+",
        "name_hr": "31+"
    },
    "30^prematch": {
        "name_en": "1:5",
        "name_aa": "1:5",
        "name_de": "1:5",
        "name_nl": "1:5",
        "name_pl": "1:5",
        "name_ro": "1:5",
        "name_ru": "1:5",
        "name_tr": "1:5",
        "name_se": "1:5",
        "name_el": "1:5",
        "name_es": "1:5",
        "name_hr": "1:5"
    },
    "281^prematch": {
        "name_en": "5:10",
        "name_aa": "5:10",
        "name_de": "5:10",
        "name_nl": "5:10",
        "name_pl": "5:10",
        "name_ro": "5:10",
        "name_ru": "5:10",
        "name_tr": "5:10",
        "name_se": "5:10",
        "name_el": "5:10",
        "name_es": "5:10",
        "name_hr": "5:10"
    },
    "1199^prematch": {
        "name_en": "0.5:1.5",
        "name_aa": "0.5:1.5",
        "name_de": "0.5:1.5",
        "name_nl": "0.5:1.5",
        "name_pl": "0.5:1.5",
        "name_ro": "0.5:1.5",
        "name_ru": "0.5:1.5",
        "name_tr": "0.5:1.5",
        "name_se": "0.5:1.5",
        "name_el": "0.5:1.5",
        "name_es": "0.5:1.5",
        "name_hr": "0.5:1.5"
    },
    "235^prematch": {
        "name_en": "8:2",
        "name_aa": "8:2",
        "name_de": "8:2",
        "name_nl": "8:2",
        "name_pl": "8:2",
        "name_ro": "8:2",
        "name_ru": "8:2",
        "name_tr": "8:2",
        "name_se": "8:2",
        "name_el": "8:2",
        "name_es": "8:2",
        "name_hr": "8:2"
    },
    "175^prematch": {
        "name_en": "221-230",
        "name_aa": "221-230",
        "name_de": "221-230",
        "name_nl": "221-230",
        "name_pl": "221-230",
        "name_ro": "221-230",
        "name_ru": "221-230",
        "name_tr": "221-230",
        "name_se": "221-230",
        "name_el": "221-230",
        "name_es": "221-230",
        "name_hr": "221-230"
    },
    "1694430000^prematch": {
        "name_en": "{$competitor2}",
        "name_aa": "{$competitor2}",
        "name_de": "{$competitor2}",
        "name_nl": "{$competitor2}",
        "name_pl": "{$competitor2}",
        "name_ro": "{$competitor2}",
        "name_ru": "{$competitor2}",
        "name_tr": "{$competitor2}",
        "name_se": "{$competitor2}",
        "name_el": "{$competitor2}",
        "name_es": "{$competitor2}",
        "name_hr": "{$competitor2}"
    },
    "640^prematch": {
        "name_en": "{$competitor2} by 3+",
        "name_aa": "{$competitor2} في 3+",
        "name_de": "{$competitor2} mit 3+",
        "name_nl": "{$competitor2} met 3+",
        "name_pl": "{$competitor2} 3+",
        "name_ro": "{$competitor2} cu 3+",
        "name_ru": "{$competitor2} на 3+",
        "name_tr": "{$competitor2} 3 ve üstü farkla",
        "name_se": "{$competitor2} med 3+",
        "name_el": "{$competitor2} με 3+",
        "name_es": "{$competitor2} por 3+",
        "name_hr": "{$competitor2} zа 3 i više"
    },
    "1477^prematch": {
        "name_en": "2:0",
        "name_aa": "2:0",
        "name_de": "2:0",
        "name_nl": "2:0",
        "name_pl": "2:0",
        "name_ro": "2:0",
        "name_ru": "2:0",
        "name_tr": "2:0",
        "name_se": "2:0",
        "name_el": "2:0",
        "name_es": "2:0",
        "name_hr": "2:0"
    },
    "1252^prematch": {
        "name_en": "0-24",
        "name_aa": "0-24",
        "name_de": "0-24",
        "name_nl": "0-24",
        "name_pl": "0-24",
        "name_ro": "0-24",
        "name_ru": "0-24",
        "name_tr": "0-24",
        "name_se": "0-24",
        "name_el": "0-24",
        "name_es": "0-24",
        "name_hr": "0-24"
    },
    "1147^prematch": {
        "name_en": "7+",
        "name_aa": "7+",
        "name_de": "7+",
        "name_nl": "7+",
        "name_pl": "7+",
        "name_ro": "7+",
        "name_ru": "7+",
        "name_tr": "7+",
        "name_se": "7+",
        "name_el": "7+",
        "name_es": "7+",
        "name_hr": "7+"
    },
    "87^prematch": {
        "name_en": "2+",
        "name_aa": "2+",
        "name_de": "2+",
        "name_nl": "2+",
        "name_pl": "2+",
        "name_ro": "2+",
        "name_ru": "2+",
        "name_tr": "2+",
        "name_se": "2+",
        "name_el": "2+",
        "name_es": "2+",
        "name_hr": "2+"
    },
    "2^prematch": {
        "name_en": "draw",
        "name_aa": "رسم",
        "name_de": "unentschieden",
        "name_nl": "gelijkspel",
        "name_pl": "remis",
        "name_ro": "egal",
        "name_ru": "ничья",
        "name_tr": "beraberlik",
        "name_se": "oavgjort",
        "name_el": "ισοπαλία",
        "name_es": "empate",
        "name_hr": "neodlučeno"
    },
    "960^prematch": {
        "name_en": "equal",
        "name_aa": "مساو",
        "name_de": "gleich",
        "name_nl": "gelijk",
        "name_pl": "równo",
        "name_ro": "egalitate",
        "name_ru": "равные",
        "name_tr": "eşit",
        "name_se": "lika",
        "name_el": "ίδιος αριθμός",
        "name_es": "igual",
        "name_hr": "jednako"
    },
    "70880000^prematch": {
        "name_en": "{$competitor2}",
        "name_aa": "{$competitor2}",
        "name_de": "{$competitor2}",
        "name_nl": "{$competitor2}",
        "name_pl": "{$competitor2}",
        "name_ro": "{$competitor2}",
        "name_ru": "{$competitor2}",
        "name_tr": "{$competitor2}",
        "name_se": "{$competitor2}",
        "name_el": "{$competitor2}",
        "name_es": "{$competitor2}",
        "name_hr": "{$competitor2}"
    },
    "548^prematch": {
        "name_en": "0:1",
        "name_aa": "0:1",
        "name_de": "0:1",
        "name_nl": "0:1",
        "name_pl": "0:1",
        "name_ro": "0:1",
        "name_ru": "0:1",
        "name_tr": "0:1",
        "name_se": "0:1",
        "name_el": "0:1",
        "name_es": "0:1",
        "name_hr": "0:1"
    },
    "1837^prematch": {
        "name_en": "{$competitor1}/draw  under {total}",
        "name_aa": "{$competitor1} / رسم و تحت{total}",
        "name_de": "{$competitor1}/unentschieden  unter {total}",
        "name_nl": "{$competitor1}/gelijkspel  onder {total}",
        "name_pl": "{$competitor1}/remis  poniżej {total}",
        "name_ro": "{$competitor1}/egal  sub {total}",
        "name_ru": "{$competitor1}/ничья или меньше {total}",
        "name_tr": "{$competitor1}/beraberlik  {total} altı",
        "name_se": "{$competitor1}/oavgjort  under {total}",
        "name_el": "{$competitor1}/ισοπαλία  under {total}",
        "name_es": "{$competitor1}/empate menos de {total}",
        "name_hr": "{$competitor1}/neodlučeno i manje {total}"
    },
    "70910000^prematch": {
        "name_en": "{$competitor2}",
        "name_aa": "{$competitor2}",
        "name_de": "{$competitor2}",
        "name_nl": "{$competitor2}",
        "name_pl": "{$competitor2}",
        "name_ro": "{$competitor2}",
        "name_ru": "{$competitor2}",
        "name_tr": "{$competitor2}",
        "name_se": "{$competitor2}",
        "name_el": "{$competitor2}",
        "name_es": "{$competitor2}",
        "name_hr": "{$competitor2}"
    },
    "428^prematch": {
        "name_en": "draw/{$competitor2}",
        "name_aa": "رسم / {$competitor2}",
        "name_de": "unentschieden/{$competitor2}",
        "name_nl": "gelijkspel/{$competitor2}",
        "name_pl": "remis/{$competitor2}",
        "name_ro": "egal/{$competitor2}",
        "name_ru": "ничья/{$competitor2}",
        "name_tr": "beraberlik/{$competitor2}",
        "name_se": "oavgjort/{$competitor2}",
        "name_el": "ισοπαλία/{$competitor2}",
        "name_es": "empate/{$competitor2}",
        "name_hr": "neodlučeno/{$competitor2}"
    },
    "190^prematch": {
        "name_en": "1:6",
        "name_aa": "1:6",
        "name_de": "1:6",
        "name_nl": "1:6",
        "name_pl": "1:6",
        "name_ro": "1:6",
        "name_ru": "1:6",
        "name_tr": "1:6",
        "name_se": "1:6",
        "name_el": "1:6",
        "name_es": "1:6",
        "name_hr": "1:6"
    },
    "1075^prematch": {
        "name_en": "150-159",
        "name_aa": "150-159",
        "name_de": "150-159",
        "name_nl": "150-159",
        "name_pl": "150-159",
        "name_ro": "150-159",
        "name_ru": "150-159",
        "name_tr": "150-159",
        "name_se": "150-159",
        "name_el": "150-159",
        "name_es": "150-159",
        "name_hr": "150-159"
    },
    "38^prematch": {
        "name_en": "3",
        "name_aa": "3",
        "name_de": "3",
        "name_nl": "3",
        "name_pl": "3",
        "name_ro": "3",
        "name_ru": "3",
        "name_tr": "3",
        "name_se": "3",
        "name_el": "3",
        "name_es": "3",
        "name_hr": "3"
    },
    "1164^prematch": {
        "name_en": "0:7",
        "name_aa": "0:7",
        "name_de": "0:7",
        "name_nl": "0:7",
        "name_pl": "0:7",
        "name_ro": "0:7",
        "name_ru": "0:7",
        "name_tr": "0:7",
        "name_se": "0:7",
        "name_el": "0:7",
        "name_es": "0:7",
        "name_hr": "0:7"
    },
    "466^prematch": {
        "name_en": "2:2",
        "name_aa": "2:2",
        "name_de": "2:2",
        "name_nl": "2:2",
        "name_pl": "2:2",
        "name_ro": "2:2",
        "name_ru": "2:2",
        "name_tr": "2:2",
        "name_se": "2:2",
        "name_el": "2:2",
        "name_es": "2:2",
        "name_hr": "2:2"
    },
    "9^prematch": {
        "name_en": "{$competitor1} or draw",
        "name_aa": "{$competitor1} أو ارسم",
        "name_de": "{$competitor1} oder unentschieden",
        "name_nl": "{$competitor1} of gelijkspel",
        "name_pl": "{$competitor1} lub remis",
        "name_ro": "{$competitor1} sau egal",
        "name_ru": "{$competitor1} или ничья",
        "name_tr": "{$competitor1} veya beraberlik",
        "name_se": "{$competitor1} elr oavgjort",
        "name_el": "{$competitor1} ή ισοπαλία",
        "name_es": "{$competitor1} o empate",
        "name_hr": "{$competitor1} ili neodlučeno"
    },
    "227^prematch": {
        "name_en": "5:7",
        "name_aa": "5:7",
        "name_de": "5:7",
        "name_nl": "5:7",
        "name_pl": "5:7",
        "name_ro": "5:7",
        "name_ru": "5:7",
        "name_tr": "5:7",
        "name_se": "5:7",
        "name_el": "5:7",
        "name_es": "5:7",
        "name_hr": "5:7"
    },
    "778^prematch": {
        "name_en": "{$competitor2}",
        "name_aa": "{$competitor2}",
        "name_de": "{$competitor2}",
        "name_nl": "{$competitor2}",
        "name_pl": "{$competitor2}",
        "name_ro": "{$competitor2}",
        "name_ru": "{$competitor2}",
        "name_tr": "{$competitor2}",
        "name_se": "{$competitor2}",
        "name_el": "{$competitor2}",
        "name_es": "{$competitor2}",
        "name_hr": "{$competitor2}"
    },
    "120^prematch": {
        "name_en": "{$competitor1} by 6+",
        "name_aa": "{$competitor1} by 6+",
        "name_de": "{$competitor1} by 6+",
        "name_nl": "{$competitor1} by 6+",
        "name_pl": "{$competitor1} by 6+",
        "name_ro": "{$competitor1} by 6+",
        "name_ru": "{$competitor1} by 6+",
        "name_tr": "{$competitor1} by 6+",
        "name_se": "{$competitor1} by 6+",
        "name_el": "{$competitor1} by 6+",
        "name_es": "{$competitor1} by 6+",
        "name_hr": "{$competitor1} by 6+"
    },
    "1748^prematch": {
        "name_en": "2-3",
        "name_aa": "2-3",
        "name_de": "2-3",
        "name_nl": "2-3",
        "name_pl": "2-3",
        "name_ro": "2-3",
        "name_ru": "2-3",
        "name_tr": "2-3",
        "name_se": "2-3",
        "name_el": "2-3",
        "name_es": "2-3",
        "name_hr": "2-3"
    },
    "60070000^prematch": {
        "name_en": "5+/4+",
        "name_aa": "5+/4+",
        "name_de": "5+/4+",
        "name_nl": "5+/4+",
        "name_pl": "5+/4+",
        "name_ro": "5+/4+",
        "name_ru": "5+/4+",
        "name_tr": "5+/4+",
        "name_se": "5+/4+",
        "name_el": "5+/4+",
        "name_es": "5+/4+",
        "name_hr": "5+/4+"
    },
    "34^prematch": {
        "name_en": "3",
        "name_aa": "3",
        "name_de": "3",
        "name_nl": "3",
        "name_pl": "3",
        "name_ro": "3",
        "name_ru": "3",
        "name_tr": "3",
        "name_se": "3",
        "name_el": "3",
        "name_es": "3",
        "name_hr": "3"
    },
    "258^prematch": {
        "name_en": "8:9",
        "name_aa": "8:9",
        "name_de": "8:9",
        "name_nl": "8:9",
        "name_pl": "8:9",
        "name_ro": "8:9",
        "name_ru": "8:9",
        "name_tr": "8:9",
        "name_se": "8:9",
        "name_el": "8:9",
        "name_es": "8:9",
        "name_hr": "8:9"
    },
    "1497^prematch": {
        "name_en": "4:3",
        "name_aa": "4:3",
        "name_de": "4:3",
        "name_nl": "4:3",
        "name_pl": "4:3",
        "name_ro": "4:3",
        "name_ru": "4:3",
        "name_tr": "4:3",
        "name_se": "4:3",
        "name_el": "4:3",
        "name_es": "4:3",
        "name_hr": "4:3"
    },
    "254^prematch": {
        "name_en": "9:5",
        "name_aa": "9:5",
        "name_de": "9:5",
        "name_nl": "9:5",
        "name_pl": "9:5",
        "name_ro": "9:5",
        "name_ru": "9:5",
        "name_tr": "9:5",
        "name_se": "9:5",
        "name_el": "9:5",
        "name_es": "9:5",
        "name_hr": "9:5"
    },
    "1488^prematch": {
        "name_en": "1:2",
        "name_aa": "1:2",
        "name_de": "1:2",
        "name_nl": "1:2",
        "name_pl": "1:2",
        "name_ro": "1:2",
        "name_ru": "1:2",
        "name_tr": "1:2",
        "name_se": "1:2",
        "name_el": "1:2",
        "name_es": "1:2",
        "name_hr": "1:2"
    },
    "965^prematch": {
        "name_en": "4",
        "name_aa": "4",
        "name_de": "4",
        "name_nl": "4",
        "name_pl": "4",
        "name_ro": "4",
        "name_ru": "4",
        "name_tr": "4",
        "name_se": "4",
        "name_el": "4",
        "name_es": "4",
        "name_hr": "4"
    },
    "865^prematch": {
        "name_en": "6:0",
        "name_aa": "6:0",
        "name_de": "6:0",
        "name_nl": "6:0",
        "name_pl": "6:0",
        "name_ro": "6:0",
        "name_ru": "6:0",
        "name_tr": "6:0",
        "name_se": "6:0",
        "name_el": "6:0",
        "name_es": "6:0",
        "name_hr": "6:0"
    },
    "1250^prematch": {
        "name_en": "85-94",
        "name_aa": "85-94",
        "name_de": "85-94",
        "name_nl": "85-94",
        "name_pl": "85-94",
        "name_ro": "85-94",
        "name_ru": "85-94",
        "name_tr": "85-94",
        "name_se": "85-94",
        "name_el": "85-94",
        "name_es": "85-94",
        "name_hr": "85-94"
    },
    "194^prematch": {
        "name_en": "7:2",
        "name_aa": "7:2",
        "name_de": "7:2",
        "name_nl": "7:2",
        "name_pl": "7:2",
        "name_ro": "7:2",
        "name_ru": "7:2",
        "name_tr": "7:2",
        "name_se": "7:2",
        "name_el": "7:2",
        "name_es": "7:2",
        "name_hr": "7:2"
    },
    "388^prematch": {
        "name_en": "1:1 1:1",
        "name_aa": "1:1 1:1",
        "name_de": "1:1 1:1",
        "name_nl": "1:1 1:1",
        "name_pl": "1:1 1:1",
        "name_ro": "1:1 1:1",
        "name_ru": "1:1 1:1",
        "name_tr": "1:1 1:1",
        "name_se": "1:1 1:1",
        "name_el": "1:1 1:1",
        "name_es": "1:1 1:1",
        "name_hr": "1:1 1:1"
    },
    "230^prematch": {
        "name_en": "2:7",
        "name_aa": "2:7",
        "name_de": "2:7",
        "name_nl": "2:7",
        "name_pl": "2:7",
        "name_ro": "2:7",
        "name_ru": "2:7",
        "name_tr": "2:7",
        "name_se": "2:7",
        "name_el": "2:7",
        "name_es": "2:7",
        "name_hr": "2:7"
    },
    "312^prematch": {
        "name_en": "4:3",
        "name_aa": "4:3",
        "name_de": "4:3",
        "name_nl": "4:3",
        "name_pl": "4:3",
        "name_ro": "4:3",
        "name_ru": "4:3",
        "name_tr": "4:3",
        "name_se": "4:3",
        "name_el": "4:3",
        "name_es": "4:3",
        "name_hr": "4:3"
    },
    "251^prematch": {
        "name_en": "9:2",
        "name_aa": "9:2",
        "name_de": "9:2",
        "name_nl": "9:2",
        "name_pl": "9:2",
        "name_ro": "9:2",
        "name_ru": "9:2",
        "name_tr": "9:2",
        "name_se": "9:2",
        "name_el": "9:2",
        "name_es": "9:2",
        "name_hr": "9:2"
    },
    "263^prematch": {
        "name_en": "3:9",
        "name_aa": "3:9",
        "name_de": "3:9",
        "name_nl": "3:9",
        "name_pl": "3:9",
        "name_ro": "3:9",
        "name_ru": "3:9",
        "name_tr": "3:9",
        "name_se": "3:9",
        "name_el": "3:9",
        "name_es": "3:9",
        "name_hr": "3:9"
    },
    "1258^prematch": {
        "name_en": "45-49",
        "name_aa": "45-49",
        "name_de": "45-49",
        "name_nl": "45-49",
        "name_pl": "45-49",
        "name_ro": "45-49",
        "name_ru": "45-49",
        "name_tr": "45-49",
        "name_se": "45-49",
        "name_el": "45-49",
        "name_es": "45-49",
        "name_hr": "45-49"
    },
    "552^prematch": {
        "name_en": "1:0",
        "name_aa": "1:0",
        "name_de": "1:0",
        "name_nl": "1:0",
        "name_pl": "1:0",
        "name_ro": "1:0",
        "name_ru": "1:0",
        "name_tr": "1:0",
        "name_se": "1:0",
        "name_el": "1:0",
        "name_es": "1:0",
        "name_hr": "1:0"
    },
    "1697240000^prematch": {
        "name_en": "Under",
        "name_aa": "Under",
        "name_de": "Under",
        "name_nl": "Under",
        "name_pl": "Under",
        "name_ro": "Under",
        "name_ru": "Under",
        "name_tr": "Under",
        "name_se": "Under",
        "name_el": "Under",
        "name_es": "Under",
        "name_hr": "Manje"
    },
    "1185^prematch": {
        "name_en": "4:2",
        "name_aa": "4:2",
        "name_de": "4:2",
        "name_nl": "4:2",
        "name_pl": "4:2",
        "name_ro": "4:2",
        "name_ru": "4:2",
        "name_tr": "4:2",
        "name_se": "4:2",
        "name_el": "4:2",
        "name_es": "4:2",
        "name_hr": "4:2"
    },
    "1712^prematch": {
        "name_en": "draw ({hcp})",
        "name_aa": "ارسم ({hcp})",
        "name_de": "unentschieden ({hcp})",
        "name_nl": "gelijkspel ({hcp})",
        "name_pl": "remis ({hcp})",
        "name_ro": "egalitate ({hcp})",
        "name_ru": "ничья ({hcp})",
        "name_tr": "berabere ({hcp})",
        "name_se": "oavgjort ({hcp})",
        "name_el": "({hcp}) ισοπαλία",
        "name_es": "empate ({hcp})",
        "name_hr": "neodlučeno ({hcp})"
    },
    "328^prematch": {
        "name_en": "0:0 0:1",
        "name_aa": "0:0 0:1",
        "name_de": "0:0 0:1",
        "name_nl": "0:0 0:1",
        "name_pl": "0:0 0:1",
        "name_ro": "0:0 0:1",
        "name_ru": "0:0 0:1",
        "name_tr": "0:0 0:1",
        "name_se": "0:0 0:1",
        "name_el": "0:0 0:1",
        "name_es": "0:0 0:1",
        "name_hr": "0:0 0:1"
    },
    "1159^prematch": {
        "name_en": "0:2",
        "name_aa": "0:2",
        "name_de": "0:2",
        "name_nl": "0:2",
        "name_pl": "0:2",
        "name_ro": "0:2",
        "name_ru": "0:2",
        "name_tr": "0:2",
        "name_se": "0:2",
        "name_el": "0:2",
        "name_es": "0:2",
        "name_hr": "0:2"
    },
    "70960000^prematch": {
        "name_en": "Neither",
        "name_aa": "Neither",
        "name_de": "Neither",
        "name_nl": "Neither",
        "name_pl": "Neither",
        "name_ro": "Neither",
        "name_ru": "Neither",
        "name_tr": "Neither",
        "name_se": "Neither",
        "name_el": "Neither",
        "name_es": "Neither",
        "name_hr": "Neither"
    },
    "257^prematch": {
        "name_en": "9:8",
        "name_aa": "9:8",
        "name_de": "9:8",
        "name_nl": "9:8",
        "name_pl": "9:8",
        "name_ro": "9:8",
        "name_ru": "9:8",
        "name_tr": "9:8",
        "name_se": "9:8",
        "name_el": "9:8",
        "name_es": "9:8",
        "name_hr": "9:8"
    },
    "921^prematch": {
        "name_en": "2nd quarter",
        "name_aa": "الربع الثاني",
        "name_de": "2. Viertel",
        "name_nl": "Tweede kwart",
        "name_pl": "2. kwarta",
        "name_ro": "Sfertul 2",
        "name_ru": "2ая четверть",
        "name_tr": "İkinci çeyrek",
        "name_se": "2:a quarter",
        "name_el": "2η περίοδος",
        "name_es": "2º cuarto",
        "name_hr": "2. četvrtina"
    },
    "283^prematch": {
        "name_en": "3:10",
        "name_aa": "3:10",
        "name_de": "3:10",
        "name_nl": "3:10",
        "name_pl": "3:10",
        "name_ro": "3:10",
        "name_ru": "3:10",
        "name_tr": "3:10",
        "name_se": "3:10",
        "name_el": "3:10",
        "name_es": "3:10",
        "name_hr": "3:10"
    },
    "712^prematch": {
        "name_en": "4",
        "name_aa": "4",
        "name_de": "4",
        "name_nl": "4",
        "name_pl": "4",
        "name_ro": "4",
        "name_ru": "4",
        "name_tr": "4",
        "name_se": "4",
        "name_el": "4",
        "name_es": "4",
        "name_hr": "4"
    },
    "950^prematch": {
        "name_en": "{$competitor2}",
        "name_aa": "{$competitor2}",
        "name_de": "{$competitor2}",
        "name_nl": "{$competitor2}",
        "name_pl": "{$competitor2}",
        "name_ro": "{$competitor2}",
        "name_ru": "{$competitor2}",
        "name_tr": "{$competitor2}",
        "name_se": "{$competitor2}",
        "name_el": "{$competitor2}",
        "name_es": "{$competitor2}",
        "name_hr": "{$competitor2}"
    },
    "267^prematch": {
        "name_en": "10:0",
        "name_aa": "10:0",
        "name_de": "10:0",
        "name_nl": "10:0",
        "name_pl": "10:0",
        "name_ro": "10:0",
        "name_ru": "10:0",
        "name_tr": "10:0",
        "name_se": "10:0",
        "name_el": "10:0",
        "name_es": "10:0",
        "name_hr": "10:0"
    },
    "1097^prematch": {
        "name_en": "28-34",
        "name_aa": "28-34",
        "name_de": "28-34",
        "name_nl": "28-34",
        "name_pl": "28-34",
        "name_ro": "28-34",
        "name_ru": "28-34",
        "name_tr": "28-34",
        "name_se": "28-34",
        "name_el": "28-34",
        "name_es": "28-34",
        "name_hr": "28-34"
    },
    "1621^prematch": {
        "name_en": "1st period",
        "name_aa": "الفترة الأولى",
        "name_de": "1. Periode",
        "name_nl": "1e periode",
        "name_pl": "1. Część gry",
        "name_ro": "repriza 1",
        "name_ru": "1ый период",
        "name_tr": "İlk periyot",
        "name_se": "1:a period",
        "name_el": "1η περίοδος",
        "name_es": "1º periodo",
        "name_hr": "1. period"
    },
    "1694030000^prematch": {
        "name_en": "Over 7.5",
        "name_aa": "Over 7.5",
        "name_de": "Over 7.5",
        "name_nl": "Over 7.5",
        "name_pl": "Over 7.5",
        "name_ro": "Over 7.5",
        "name_ru": "Over 7.5",
        "name_tr": "Over 7.5",
        "name_se": "Over 7.5",
        "name_el": "Over 7.5",
        "name_es": "Over 7.5",
        "name_hr": "Over 7.5"
    },
    "304^prematch": {
        "name_en": "4:11",
        "name_aa": "4:11",
        "name_de": "4:11",
        "name_nl": "4:11",
        "name_pl": "4:11",
        "name_ro": "4:11",
        "name_ru": "4:11",
        "name_tr": "4:11",
        "name_se": "4:11",
        "name_el": "4:11",
        "name_es": "4:11",
        "name_hr": "4:11"
    },
    "1694700000^prematch": {
        "name_en": "Yes",
        "name_aa": "Yes",
        "name_de": "Yes",
        "name_nl": "Yes",
        "name_pl": "Yes",
        "name_ro": "Yes",
        "name_ru": "Yes",
        "name_tr": "Yes",
        "name_se": "Yes",
        "name_el": "Yes",
        "name_es": "Yes",
        "name_hr": "Yes"
    },
    "80^prematch": {
        "name_en": "{$competitor1}  no",
        "name_aa": "{$competitor1} ولا",
        "name_de": "{$competitor1}  nein",
        "name_nl": "{$competitor1}  nee",
        "name_pl": "{$competitor1}  nie",
        "name_ro": "{$competitor1}  nu",
        "name_ru": "{$competitor1}  нет",
        "name_tr": "{$competitor1}  yok",
        "name_se": "{$competitor1}  nej",
        "name_el": "όχι και {$competitor1}",
        "name_es": "{$competitor1} y no",
        "name_hr": "{$competitor1} i ne"
    },
    "128^prematch": {
        "name_en": "{$competitor2} by 6-10",
        "name_aa": "{$competitor2} by 6-10",
        "name_de": "{$competitor2} by 6-10",
        "name_nl": "{$competitor2} by 6-10",
        "name_pl": "{$competitor2} by 6-10",
        "name_ro": "{$competitor2} by 6-10",
        "name_ru": "{$competitor2} by 6-10",
        "name_tr": "{$competitor2} by 6-10",
        "name_se": "{$competitor2} by 6-10",
        "name_el": "{$competitor2} by 6-10",
        "name_es": "{$competitor2} by 6-10",
        "name_hr": "{$competitor2} by 6-10"
    },
    "11^prematch": {
        "name_en": "draw or {$competitor2}",
        "name_aa": "ارسم أو {$competitor2}",
        "name_de": "unentschieden oder {$competitor2}",
        "name_nl": "gelijkspel of {$competitor2}",
        "name_pl": "remis lub {$competitor2}",
        "name_ro": "egal sau {$competitor2}",
        "name_ru": "ничья или {$competitor2}",
        "name_tr": "beraberlik veya {$competitor2}",
        "name_se": "oavgjort elr {$competitor2}",
        "name_el": "ισοπαλία ή {$competitor2}",
        "name_es": "empate o {$competitor2}",
        "name_hr": "neodlučeno ili {$competitor2}"
    },
    "1161^prematch": {
        "name_en": "0:4",
        "name_aa": "0:4",
        "name_de": "0:4",
        "name_nl": "0:4",
        "name_pl": "0:4",
        "name_ro": "0:4",
        "name_ru": "0:4",
        "name_tr": "0:4",
        "name_se": "0:4",
        "name_el": "0:4",
        "name_es": "0:4",
        "name_hr": "0:4"
    },
    "1165^prematch": {
        "name_en": "1:0",
        "name_aa": "1:0",
        "name_de": "1:0",
        "name_nl": "1:0",
        "name_pl": "1:0",
        "name_ro": "1:0",
        "name_ru": "1:0",
        "name_tr": "1:0",
        "name_se": "1:0",
        "name_el": "1:0",
        "name_es": "1:0",
        "name_hr": "1:0"
    },
    "588^prematch": {
        "name_en": "31-45",
        "name_aa": "31-45",
        "name_de": "31-45",
        "name_nl": "31-45",
        "name_pl": "31-45",
        "name_ro": "31-45",
        "name_ru": "31-45",
        "name_tr": "31-45",
        "name_se": "31-45",
        "name_el": "31-45",
        "name_es": "31-45",
        "name_hr": "31-45"
    },
    "408^prematch": {
        "name_en": "2:1 2:1",
        "name_aa": "2:1 2:1",
        "name_de": "2:1 2:1",
        "name_nl": "2:1 2:1",
        "name_pl": "2:1 2:1",
        "name_ro": "2:1 2:1",
        "name_ru": "2:1 2:1",
        "name_tr": "2:1 2:1",
        "name_se": "2:1 2:1",
        "name_el": "2:1 2:1",
        "name_es": "2:1 2:1",
        "name_hr": "2:1 2:1"
    },
    "1856^prematch": {
        "name_en": "draw/{$competitor1}  1",
        "name_aa": "رسم / {$competitor1} و 1",
        "name_de": "unentschieden/{$competitor1}  1",
        "name_nl": "gelijkspel/{$competitor1}  1",
        "name_pl": "remis/{$competitor1}  1",
        "name_ro": "egal/{$competitor1}  1",
        "name_ru": "ничья/{$competitor1} и 1",
        "name_tr": "beraberlik/{$competitor1}  1",
        "name_se": "oavgjort/{$competitor1}  1",
        "name_el": "ισοπαλία/{$competitor1}  1",
        "name_es": "empate/{$competitor1}  1",
        "name_hr": "neodlučeno/{$competitor1} i 1"
    },
    "426^prematch": {
        "name_en": "draw/draw",
        "name_aa": "رسم / رسم",
        "name_de": "unentschieden / unentschieden",
        "name_nl": "gelijkspel/gelijkspel",
        "name_pl": "remis/remis",
        "name_ro": "egal/egal",
        "name_ru": "ничья/ничья",
        "name_tr": "beraberlik/beraberlik",
        "name_se": "oavgjort/oavgjort",
        "name_el": "ισοπαλία/ισοπαλία",
        "name_es": "empate/empate",
        "name_hr": "neodlučeno/neodlučeno"
    },
    "1294^prematch": {
        "name_en": "{$competitor2} by 26+",
        "name_aa": "{$competitor2} by 26+",
        "name_de": "{$competitor2} by 26+",
        "name_nl": "{$competitor2} by 26+",
        "name_pl": "{$competitor2} by 26+",
        "name_ro": "{$competitor2} by 26+",
        "name_ru": "{$competitor2} by 26+",
        "name_tr": "{$competitor2} by 26+",
        "name_se": "{$competitor2} by 26+",
        "name_el": "{$competitor2} by 26+",
        "name_es": "{$competitor2} by 26+",
        "name_hr": "{$competitor2} by 26+"
    },
    "420^prematch": {
        "name_en": "{$competitor1}/draw",
        "name_aa": "{$competitor1} / رسم",
        "name_de": "{$competitor1}/unentschieden",
        "name_nl": "{$competitor1}/gelijkspel",
        "name_pl": "{$competitor1}/remis",
        "name_ro": "{$competitor1}/egal",
        "name_ru": "{$competitor1}/ничья",
        "name_tr": "{$competitor1}/beraberlik",
        "name_se": "{$competitor1}/oavgjort",
        "name_el": "{$competitor1}/ισοπαλία",
        "name_es": "{$competitor1}/empate",
        "name_hr": "{$competitor1}/neodlučeno"
    },
    "594^prematch": {
        "name_en": "76-90",
        "name_aa": "76-90",
        "name_de": "76-90",
        "name_nl": "76-90",
        "name_pl": "76-90",
        "name_ro": "76-90",
        "name_ru": "76-90",
        "name_tr": "76-90",
        "name_se": "76-90",
        "name_el": "76-90",
        "name_es": "76-90",
        "name_hr": "76-90"
    },
    "294^prematch": {
        "name_en": "11:7",
        "name_aa": "11:7",
        "name_de": "11:7",
        "name_nl": "11:7",
        "name_pl": "11:7",
        "name_ro": "11:7",
        "name_ru": "11:7",
        "name_tr": "11:7",
        "name_se": "11:7",
        "name_el": "11:7",
        "name_es": "11:7",
        "name_hr": "11:7"
    },
    "1694690000^prematch": {
        "name_en": "No",
        "name_aa": "No",
        "name_de": "No",
        "name_nl": "No",
        "name_pl": "No",
        "name_ro": "No",
        "name_ru": "No",
        "name_tr": "No",
        "name_se": "No",
        "name_el": "No",
        "name_es": "No",
        "name_hr": "No"
    },
    "1498^prematch": {
        "name_en": "5:3",
        "name_aa": "5:3",
        "name_de": "5:3",
        "name_nl": "5:3",
        "name_pl": "5:3",
        "name_ro": "5:3",
        "name_ru": "5:3",
        "name_tr": "5:3",
        "name_se": "5:3",
        "name_el": "5:3",
        "name_es": "5:3",
        "name_hr": "5:3"
    },
    "1141^prematch": {
        "name_en": "0-8",
        "name_aa": "0-8",
        "name_de": "0-8",
        "name_nl": "0-8",
        "name_pl": "0-8",
        "name_ro": "0-8",
        "name_ru": "0-8",
        "name_tr": "0-8",
        "name_se": "0-8",
        "name_el": "0-8",
        "name_es": "0-8",
        "name_hr": "0-8"
    },
    "1670000^prematch": {
        "name_en": "{$competitor2}",
        "name_aa": "{$competitor2}",
        "name_de": "{$competitor2}",
        "name_nl": "{$competitor2}",
        "name_pl": "{$competitor2}",
        "name_ro": "{$competitor2}",
        "name_ru": "{$competitor2}",
        "name_tr": "{$competitor2}",
        "name_se": "{$competitor2}",
        "name_el": "{$competitor2}",
        "name_es": "{$competitor2}",
        "name_hr": "{$competitor2}"
    },
    "939^prematch": {
        "name_en": "under {total}",
        "name_aa": "تحت {total}",
        "name_de": "unter {total}",
        "name_nl": "onder {total}",
        "name_pl": "poniżej {total}",
        "name_ro": "sub {total}",
        "name_ru": "меньше {total}",
        "name_tr": "{total} altında",
        "name_se": "under {total}",
        "name_el": "under {total}",
        "name_es": "menos de {total}",
        "name_hr": "manje od {total}"
    },
    "282^prematch": {
        "name_en": "4:10",
        "name_aa": "4:10",
        "name_de": "4:10",
        "name_nl": "4:10",
        "name_pl": "4:10",
        "name_ro": "4:10",
        "name_ru": "4:10",
        "name_tr": "4:10",
        "name_se": "4:10",
        "name_el": "4:10",
        "name_es": "4:10",
        "name_hr": "4:10"
    },
    "1087^prematch": {
        "name_en": "21-25",
        "name_aa": "21-25",
        "name_de": "21-25",
        "name_nl": "21-25",
        "name_pl": "21-25",
        "name_ro": "21-25",
        "name_ru": "21-25",
        "name_tr": "21-25",
        "name_se": "21-25",
        "name_el": "21-25",
        "name_es": "21-25",
        "name_hr": "21-25"
    },
    "1293^prematch": {
        "name_en": "{$competitor2} by 21-25",
        "name_aa": "{$competitor2} by 21-25",
        "name_de": "{$competitor2} by 21-25",
        "name_nl": "{$competitor2} by 21-25",
        "name_pl": "{$competitor2} by 21-25",
        "name_ro": "{$competitor2} by 21-25",
        "name_ru": "{$competitor2} by 21-25",
        "name_tr": "{$competitor2} by 21-25",
        "name_se": "{$competitor2} by 21-25",
        "name_el": "{$competitor2} by 21-25",
        "name_es": "{$competitor2} by 21-25",
        "name_hr": "{$competitor2} by 21-25"
    },
    "1824^prematch": {
        "name_en": "bottom of 9th inning",
        "name_aa": "الجزء السفلي من الشوط التاسع",
        "name_de": "Ende des 9. Innings",
        "name_nl": "tweede helft 9e inning",
        "name_pl": "bottom of 9th inning",
        "name_ro": "a doua jumătate a inning-ului 9",
        "name_ru": "вторая половина 9го иннинга",
        "name_tr": "9. devrenin başında",
        "name_se": "botten av 9:e inning",
        "name_el": "bottom of 9th inning",
        "name_es": "peor del 9º inning",
        "name_hr": "na početku 9. inninga"
    },
    "1722^prematch": {
        "name_en": "draw/{$competitor2}  yes",
        "name_aa": "ارسم / {$competitor2} ونعم",
        "name_de": "unentschieden/{$competitor2}  ja",
        "name_nl": "gelijkspel/{$competitor2}  ja",
        "name_pl": "remis/{$competitor2}  tak",
        "name_ro": "egal/{$competitor2}  da",
        "name_ru": "ничья/{$competitor2} и да",
        "name_tr": "beraberlik/{$competitor2}  var",
        "name_se": "oavgjort/{$competitor2}  ja",
        "name_el": "ισοπαλία/{$competitor2} και ναι",
        "name_es": "empate/{$competitor2} y sí",
        "name_hr": "neodlučeno/{$competitor2} i da"
    },
    "1143^prematch": {
        "name_en": "12+",
        "name_aa": "12+",
        "name_de": "12+",
        "name_nl": "12+",
        "name_pl": "12+",
        "name_ro": "12+",
        "name_ru": "12+",
        "name_tr": "12+",
        "name_se": "12+",
        "name_el": "12+",
        "name_es": "12+",
        "name_hr": "12+"
    },
    "60120000^prematch": {
        "name_en": "6+/7+",
        "name_aa": "6+/7+",
        "name_de": "6+/7+",
        "name_nl": "6+/7+",
        "name_pl": "6+/7+",
        "name_ro": "6+/7+",
        "name_ru": "6+/7+",
        "name_tr": "6+/7+",
        "name_se": "6+/7+",
        "name_el": "6+/7+",
        "name_es": "6+/7+",
        "name_hr": "6+/7+"
    },
    "1725^prematch": {
        "name_en": "{$competitor1}/{$competitor2}  under {total}",
        "name_aa": "{$competitor1} / {$competitor2} وتحت {total}",
        "name_de": "{$competitor1}/{$competitor2}  unter {total}",
        "name_nl": "{$competitor1}/{$competitor2}  onder {total}",
        "name_pl": "{$competitor1}/{$competitor2}  poniżej {total}",
        "name_ro": "{$competitor1}/{$competitor2}  sub {total}",
        "name_ru": "{$competitor1}/{$competitor2}",
        "name_tr": "{$competitor1}/{$competitor2}  {total} altı",
        "name_se": "{$competitor1}/{$competitor2}  under {total}",
        "name_el": "{$competitor1}/{$competitor2} και under {total}",
        "name_es": "{$competitor1}/{$competitor2} y menos de {total}",
        "name_hr": "{$competitor1}/{$competitor2} i manje {total}"
    },
    "1570000^prematch": {
        "name_en": "No Goal before 29:00 min.",
        "name_aa": "No Goal before 29:00 min.",
        "name_de": "No Goal before 29:00 min.",
        "name_nl": "No Goal before 29:00 min.",
        "name_pl": "No Goal before 29:00 min.",
        "name_ro": "No Goal before 29:00 min.",
        "name_ru": "No Goal before 29:00 min.",
        "name_tr": "No Goal before 29:00 min.",
        "name_se": "No Goal before 29:00 min.",
        "name_el": "No Goal before 29:00 min.",
        "name_es": "No Goal before 29:00 min.",
        "name_hr": "No Goal before 29:00 min."
    },
    "1874^prematch": {
        "name_en": "{$competitor1}/{$competitor2}  4",
        "name_aa": "{$competitor1} / {$competitor2} و 4",
        "name_de": "{$competitor1}/{$competitor2}  4",
        "name_nl": "{$competitor1}/{$competitor2}  4",
        "name_pl": "{$competitor1}/{$competitor2}  4",
        "name_ro": "{$competitor1}/{$competitor2}  4",
        "name_ru": "{$competitor1}/{$competitor2} и 4",
        "name_tr": "{$competitor1}/{$competitor2}  4",
        "name_se": "{$competitor1}/{$competitor2}  4",
        "name_el": "{$competitor1}/{$competitor2}  4",
        "name_es": "{$competitor1}/{$competitor2}  4",
        "name_hr": "{$competitor1}/{$competitor2} i 4"
    },
    "47^prematch": {
        "name_en": "7",
        "name_aa": "7",
        "name_de": "7",
        "name_nl": "7",
        "name_pl": "7",
        "name_ro": "7",
        "name_ru": "7",
        "name_tr": "7",
        "name_se": "7",
        "name_el": "7",
        "name_es": "7",
        "name_hr": "7"
    },
    "924^prematch": {
        "name_en": "equal",
        "name_aa": "مساو",
        "name_de": "beide gleich",
        "name_nl": "gelijk",
        "name_pl": "równo",
        "name_ro": "egalitate",
        "name_ru": "равные",
        "name_tr": "eşit",
        "name_se": "lika",
        "name_el": "ίδιος αριθμός",
        "name_es": "igual",
        "name_hr": "jednako"
    },
    "962^prematch": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1"
    },
    "259^prematch": {
        "name_en": "7:9",
        "name_aa": "7:9",
        "name_de": "7:9",
        "name_nl": "7:9",
        "name_pl": "7:9",
        "name_ro": "7:9",
        "name_ru": "7:9",
        "name_tr": "7:9",
        "name_se": "7:9",
        "name_el": "7:9",
        "name_es": "7:9",
        "name_hr": "7:9"
    },
    "70940000^prematch": {
        "name_en": "{$competitor2}",
        "name_aa": "{$competitor2}",
        "name_de": "{$competitor2}",
        "name_nl": "{$competitor2}",
        "name_pl": "{$competitor2}",
        "name_ro": "{$competitor2}",
        "name_ru": "{$competitor2}",
        "name_tr": "{$competitor2}",
        "name_se": "{$competitor2}",
        "name_el": "{$competitor2}",
        "name_es": "{$competitor2}",
        "name_hr": "{$competitor2}"
    },
    "1348^prematch": {
        "name_en": "{$competitor1} by decision",
        "name_aa": "{$competitor1} by decision",
        "name_de": "{$competitor1} by decision",
        "name_nl": "{$competitor1} by decision",
        "name_pl": "{$competitor1} by decision",
        "name_ro": "{$competitor1} by decision",
        "name_ru": "{$competitor1} by decision",
        "name_tr": "{$competitor1} by decision",
        "name_se": "{$competitor1} by decision",
        "name_el": "{$competitor1} by decision",
        "name_es": "{$competitor1} by decision",
        "name_hr": "{$competitor1} by decision"
    },
    "248^prematch": {
        "name_en": "0:8",
        "name_aa": "0:8",
        "name_de": "0:8",
        "name_nl": "0:8",
        "name_pl": "0:8",
        "name_ro": "0:8",
        "name_ru": "0:8",
        "name_tr": "0:8",
        "name_se": "0:8",
        "name_el": "0:8",
        "name_es": "0:8",
        "name_hr": "0:8"
    },
    "58^prematch": {
        "name_en": "5",
        "name_aa": "5",
        "name_de": "5",
        "name_nl": "5",
        "name_pl": "5",
        "name_ro": "5",
        "name_ru": "5",
        "name_tr": "5",
        "name_se": "5",
        "name_el": "5",
        "name_es": "5",
        "name_hr": "5"
    },
    "384^prematch": {
        "name_en": "1:0 3:0",
        "name_aa": "1:0 3:0",
        "name_de": "1:0 3:0",
        "name_nl": "1:0 3:0",
        "name_pl": "1:0 3:0",
        "name_ro": "1:0 3:0",
        "name_ru": "1:0 3:0",
        "name_tr": "1:0 3:0",
        "name_se": "1:0 3:0",
        "name_el": "1:0 3:0",
        "name_es": "1:0 3:0",
        "name_hr": "1:0 3:0"
    },
    "1880^prematch": {
        "name_en": "{$competitor2}/{$competitor2}  4",
        "name_aa": "{$competitor2} / {$competitor2} و 4",
        "name_de": "{$competitor2}/{$competitor2}  4",
        "name_nl": "{$competitor2}/{$competitor2}  4",
        "name_pl": "{$competitor2}/{$competitor2}  4",
        "name_ro": "{$competitor2}/{$competitor2}  4",
        "name_ru": "{$competitor2}/{$competitor2} и 4",
        "name_tr": "{$competitor2}/{$competitor2}  4",
        "name_se": "{$competitor2}/{$competitor2}  4",
        "name_el": "{$competitor2}/{$competitor2}  4",
        "name_es": "{$competitor2}/{$competitor2}  4",
        "name_hr": "{$competitor2}/{$competitor2} i 4"
    },
    "137^prematch": {
        "name_en": "{$competitor1} by 11+",
        "name_aa": "{$competitor1} by 11+",
        "name_de": "{$competitor1} by 11+",
        "name_nl": "{$competitor1} by 11+",
        "name_pl": "{$competitor1} by 11+",
        "name_ro": "{$competitor1} by 11+",
        "name_ru": "{$competitor1} by 11+",
        "name_tr": "{$competitor1} by 11+",
        "name_se": "{$competitor1} by 11+",
        "name_el": "{$competitor1} by 11+",
        "name_es": "{$competitor1} by 11+",
        "name_hr": "{$competitor1} by 11+"
    },
    "156^prematch": {
        "name_en": "63+",
        "name_aa": "63+",
        "name_de": "63+",
        "name_nl": "63+",
        "name_pl": "63+",
        "name_ro": "63+",
        "name_ru": "63+",
        "name_tr": "63+",
        "name_se": "63+",
        "name_el": "63+",
        "name_es": "63+",
        "name_hr": "63+"
    },
    "1194^prematch": {
        "name_en": "2:0",
        "name_aa": "2:0",
        "name_de": "2:0",
        "name_nl": "2:0",
        "name_pl": "2:0",
        "name_ro": "2:0",
        "name_ru": "2:0",
        "name_tr": "2:0",
        "name_se": "2:0",
        "name_el": "2:0",
        "name_es": "2:0",
        "name_hr": "2:0"
    },
    "1354^prematch": {
        "name_en": "{$competitor1}  2",
        "name_aa": "{$competitor1}  2",
        "name_de": "{$competitor1}  2",
        "name_nl": "{$competitor1}  2",
        "name_pl": "{$competitor1}  2",
        "name_ro": "{$competitor1}  2",
        "name_ru": "{$competitor1}  2",
        "name_tr": "{$competitor1}  2",
        "name_se": "{$competitor1}  2",
        "name_el": "{$competitor1}  2",
        "name_es": "{$competitor1}  2",
        "name_hr": "{$competitor1}  2"
    },
    "1157^prematch": {
        "name_en": "0:0",
        "name_aa": "0:0",
        "name_de": "0:0",
        "name_nl": "0:0",
        "name_pl": "0:0",
        "name_ro": "0:0",
        "name_ru": "0:0",
        "name_tr": "0:0",
        "name_se": "0:0",
        "name_el": "0:0",
        "name_es": "0:0",
        "name_hr": "0:0"
    },
    "822^prematch": {
        "name_en": "{$competitor2} goal  draw",
        "name_aa": "{$competitor2} هدف وارسم",
        "name_de": "{$competitor2} Tor  unentschieden",
        "name_nl": "doelpunt {$competitor2}  gelijkspel",
        "name_pl": "{$competitor2} Gol  remis",
        "name_ro": "gol {$competitor2}  egal",
        "name_ru": "гол {$competitor2}  ничья",
        "name_tr": "{$competitor2} golü  beraberlik",
        "name_se": "{$competitor2} mål  oavgjort",
        "name_el": "γκολ {$competitor2} και ισοπαλία",
        "name_es": "{$competitor2} gol y empate",
        "name_hr": "{$competitor2} gol i neodlučeno"
    },
    "1879^prematch": {
        "name_en": "{$competitor2}/draw  4",
        "name_aa": "{$competitor2} / رسم و 4",
        "name_de": "{$competitor2}/unentschieden  4",
        "name_nl": "{$competitor2}/gelijkspel  4",
        "name_pl": "{$competitor2}/remis  4",
        "name_ro": "{$competitor2}/egal  4",
        "name_ru": "{$competitor2}/ничья и 4",
        "name_tr": "{$competitor2}/beraberlik  4",
        "name_se": "{$competitor2}/oavgjort  4",
        "name_el": "{$competitor2}/ισοπαλία  4",
        "name_es": "{$competitor2}/empate  4",
        "name_hr": "{$competitor2}/neodlučeno i 4"
    },
    "1042^prematch": {
        "name_en": "{$competitor2}",
        "name_aa": "{$competitor2}",
        "name_de": "{$competitor2}",
        "name_nl": "{$competitor2}",
        "name_pl": "{$competitor2}",
        "name_ro": "{$competitor2}",
        "name_ru": "{$competitor2}",
        "name_tr": "{$competitor2}",
        "name_se": "{$competitor2}",
        "name_el": "{$competitor2}",
        "name_es": "{$competitor2}",
        "name_hr": "{$competitor2}"
    },
    "29^prematch": {
        "name_en": "2:5",
        "name_aa": "2:5",
        "name_de": "2:5",
        "name_nl": "2:5",
        "name_pl": "2:5",
        "name_ro": "2:5",
        "name_ru": "2:5",
        "name_tr": "2:5",
        "name_se": "2:5",
        "name_el": "2:5",
        "name_es": "2:5",
        "name_hr": "2:5"
    },
    "1888^prematch": {
        "name_en": "{$competitor2}/draw  5+",
        "name_aa": "{$competitor2} / رسم و5+",
        "name_de": "{$competitor2}/unentschieden  5+",
        "name_nl": "{$competitor2}/gelijkspel  5+",
        "name_pl": "{$competitor2}/remis  5+",
        "name_ro": "{$competitor2}/egal  5+",
        "name_ru": "{$competitor2}/ничья и 5+",
        "name_tr": "{$competitor2}/beraberlik  5+",
        "name_se": "{$competitor2}/oavgjort  5+",
        "name_el": "{$competitor2}/ισοπαλία  5+",
        "name_es": "{$competitor2}/empate  5+",
        "name_hr": "{$competitor2}/neodlučeno i 5+"
    },
    "718^prematch": {
        "name_en": "7",
        "name_aa": "7",
        "name_de": "7",
        "name_nl": "7",
        "name_pl": "7",
        "name_ro": "7",
        "name_ru": "7",
        "name_tr": "7",
        "name_se": "7",
        "name_el": "7",
        "name_es": "7",
        "name_hr": "7"
    },
    "1854^prematch": {
        "name_en": "draw/draw  0",
        "name_aa": "رسم / رسم و0",
        "name_de": "unentschieden/unentschieden  0",
        "name_nl": "gelijkspel/gelijkspel  0",
        "name_pl": "remis/remis  0",
        "name_ro": "egal/egal  0",
        "name_ru": "ничья/ничья и 0",
        "name_tr": "beraberlik/beraberlik  0",
        "name_se": "oavgjort/oavgjort  0",
        "name_el": "ισοπαλία/ισοπαλία  0",
        "name_es": "empate/empate  0",
        "name_hr": "neodlučeno/neodlučeno i 0"
    },
    "154^prematch": {
        "name_en": "49-55",
        "name_aa": "49-55",
        "name_de": "49-55",
        "name_nl": "49-55",
        "name_pl": "49-55",
        "name_ro": "49-55",
        "name_ru": "49-55",
        "name_tr": "49-55",
        "name_se": "49-55",
        "name_el": "49-55",
        "name_es": "49-55",
        "name_hr": "49-55"
    },
    "1244^prematch": {
        "name_en": "50-59",
        "name_aa": "50-59",
        "name_de": "50-59",
        "name_nl": "50-59",
        "name_pl": "50-59",
        "name_ro": "50-59",
        "name_ru": "50-59",
        "name_tr": "50-59",
        "name_se": "50-59",
        "name_el": "50-59",
        "name_es": "50-59",
        "name_hr": "50-59"
    },
    "70970000^prematch": {
        "name_en": "{$competitor2}",
        "name_aa": "{$competitor2}",
        "name_de": "{$competitor2}",
        "name_nl": "{$competitor2}",
        "name_pl": "{$competitor2}",
        "name_ro": "{$competitor2}",
        "name_ru": "{$competitor2}",
        "name_tr": "{$competitor2}",
        "name_se": "{$competitor2}",
        "name_el": "{$competitor2}",
        "name_es": "{$competitor2}",
        "name_hr": "{$competitor2}"
    },
    "1079^prematch": {
        "name_en": "0-10",
        "name_aa": "0-10",
        "name_de": "0-10",
        "name_nl": "0-10",
        "name_pl": "0-10",
        "name_ro": "0-10",
        "name_ru": "0-10",
        "name_tr": "0-10",
        "name_se": "0-10",
        "name_el": "0-10",
        "name_es": "0-10",
        "name_hr": "0-10"
    },
    "1719^prematch": {
        "name_en": "{$competitor1}/draw  no",
        "name_aa": "{$competitor1} / رسم ولا",
        "name_de": "{$competitor1}/unentschieden  nein",
        "name_nl": "{$competitor1}/gelijkspel  nee",
        "name_pl": "{$competitor1}/remis  nie",
        "name_ro": "{$competitor1}/egal  nu",
        "name_ru": "{$competitor1}/ничья и нет",
        "name_tr": "{$competitor1}/beraberlik  yok",
        "name_se": "{$competitor1}/oavgjort  nej",
        "name_el": "{$competitor1}/ ισοπαλία και όχι",
        "name_es": "{$competitor1}/empate y no",
        "name_hr": "{$competitor1}/neodlučeno i ne"
    },
    "1003^prematch": {
        "name_en": "{$competitor2} by 3+",
        "name_aa": "{$competitor2} في 3+",
        "name_de": "{$competitor2} mit 3+",
        "name_nl": "{$competitor2} met 3+",
        "name_pl": "{$competitor2} 3+",
        "name_ro": "{$competitor2} cu 3+",
        "name_ru": "{$competitor2} на 3+",
        "name_tr": "{$competitor2} 3 ve üstü farkla",
        "name_se": "{$competitor2} med 3+",
        "name_el": "{$competitor2} με 3+",
        "name_es": "{$competitor2} por 3+",
        "name_hr": "{$competitor2} za 3 ili više"
    },
    "43^prematch": {
        "name_en": "5",
        "name_aa": "5",
        "name_de": "5",
        "name_nl": "5",
        "name_pl": "5",
        "name_ro": "5",
        "name_ru": "5",
        "name_tr": "5",
        "name_se": "5",
        "name_el": "5",
        "name_es": "5",
        "name_hr": "5"
    },
    "1251^prematch": {
        "name_en": "95+",
        "name_aa": "95+",
        "name_de": "95+",
        "name_nl": "95+",
        "name_pl": "95+",
        "name_ro": "95+",
        "name_ru": "95+",
        "name_tr": "95+",
        "name_se": "95+",
        "name_el": "95+",
        "name_es": "95+",
        "name_hr": "95+"
    },
    "195^prematch": {
        "name_en": "7:3",
        "name_aa": "7:3",
        "name_de": "7:3",
        "name_nl": "7:3",
        "name_pl": "7:3",
        "name_ro": "7:3",
        "name_ru": "7:3",
        "name_tr": "7:3",
        "name_se": "7:3",
        "name_el": "7:3",
        "name_es": "7:3",
        "name_hr": "7:3"
    },
    "1289^prematch": {
        "name_en": "{$competitor2} by 1-5",
        "name_aa": "{$competitor2} by 1-5",
        "name_de": "{$competitor2} by 1-5",
        "name_nl": "{$competitor2} by 1-5",
        "name_pl": "{$competitor2} by 1-5",
        "name_ro": "{$competitor2} by 1-5",
        "name_ru": "{$competitor2} by 1-5",
        "name_tr": "{$competitor2} by 1-5",
        "name_se": "{$competitor2} by 1-5",
        "name_el": "{$competitor2} by 1-5",
        "name_es": "{$competitor2} by 1-5",
        "name_hr": "{$competitor2} by 1-5"
    },
    "1870^prematch": {
        "name_en": "{$competitor2}/{$competitor1}  3",
        "name_aa": "{$competitor2} / {$competitor1} و 3",
        "name_de": "{$competitor2}/{$competitor1}  3",
        "name_nl": "{$competitor2}/{$competitor1}  3",
        "name_pl": "{$competitor2}/{$competitor1}  3",
        "name_ro": "{$competitor2}/{$competitor1}  3",
        "name_ru": "{$competitor2}/{$competitor1} и 3",
        "name_tr": "{$competitor2}/{$competitor1}  3",
        "name_se": "{$competitor2}/{$competitor1}  3",
        "name_el": "{$competitor2}/{$competitor1}  3",
        "name_es": "{$competitor2}/{$competitor1}  3",
        "name_hr": "{$competitor2}/{$competitor1} i 3"
    },
    "1696940000^prematch": {
        "name_en": "7+",
        "name_aa": "7+",
        "name_de": "7+",
        "name_nl": "7+",
        "name_pl": "7+",
        "name_ro": "7+",
        "name_ru": "7+",
        "name_tr": "7+",
        "name_se": "7+",
        "name_el": "7+",
        "name_es": "7+",
        "name_hr": "7+"
    },
    "1285^prematch": {
        "name_en": "{$competitor1} by 11-15",
        "name_aa": "{$competitor1} by 11-15",
        "name_de": "{$competitor1} by 11-15",
        "name_nl": "{$competitor1} by 11-15",
        "name_pl": "{$competitor1} by 11-15",
        "name_ro": "{$competitor1} by 11-15",
        "name_ru": "{$competitor1} by 11-15",
        "name_tr": "{$competitor1} by 11-15",
        "name_se": "{$competitor1} by 11-15",
        "name_el": "{$competitor1} by 11-15",
        "name_es": "{$competitor1} by 11-15",
        "name_hr": "{$competitor1} by 11-15"
    },
    "722^prematch": {
        "name_en": "9",
        "name_aa": "9",
        "name_de": "9",
        "name_nl": "9",
        "name_pl": "9",
        "name_ro": "9",
        "name_ru": "9",
        "name_tr": "9",
        "name_se": "9",
        "name_el": "9",
        "name_es": "9",
        "name_hr": "9"
    },
    "1405^prematch": {
        "name_en": "{$competitor1}  3",
        "name_aa": "{$competitor1}  3",
        "name_de": "{$competitor1}  3",
        "name_nl": "{$competitor1}  3",
        "name_pl": "{$competitor1}  3",
        "name_ro": "{$competitor1}  3",
        "name_ru": "{$competitor1}  3",
        "name_tr": "{$competitor1}  3",
        "name_se": "{$competitor1}  3",
        "name_el": "{$competitor1}  3",
        "name_es": "{$competitor1}  3",
        "name_hr": "{$competitor1}  3"
    },
    "290^prematch": {
        "name_en": "11:3",
        "name_aa": "11:3",
        "name_de": "11:3",
        "name_nl": "11:3",
        "name_pl": "11:3",
        "name_ro": "11:3",
        "name_ru": "11:3",
        "name_tr": "11:3",
        "name_se": "11:3",
        "name_el": "11:3",
        "name_es": "11:3",
        "name_hr": "11:3"
    },
    "1098^prematch": {
        "name_en": "35-41",
        "name_aa": "35-41",
        "name_de": "35-41",
        "name_nl": "35-41",
        "name_pl": "35-41",
        "name_ro": "35-41",
        "name_ru": "35-41",
        "name_tr": "35-41",
        "name_se": "35-41",
        "name_el": "35-41",
        "name_es": "35-41",
        "name_hr": "35-41"
    },
    "1148^prematch": {
        "name_en": "0-4",
        "name_aa": "0-4",
        "name_de": "0-4",
        "name_nl": "0-4",
        "name_pl": "0-4",
        "name_ro": "0-4",
        "name_ru": "0-4",
        "name_tr": "0-4",
        "name_se": "0-4",
        "name_el": "0-4",
        "name_es": "0-4",
        "name_hr": "0-4"
    },
    "720^prematch": {
        "name_en": "8",
        "name_aa": "8",
        "name_de": "8",
        "name_nl": "8",
        "name_pl": "8",
        "name_ro": "8",
        "name_ru": "8",
        "name_tr": "8",
        "name_se": "8",
        "name_el": "8",
        "name_es": "8",
        "name_hr": "8"
    },
    "50^prematch": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1"
    },
    "158^prematch": {
        "name_en": "101-110",
        "name_aa": "101-110",
        "name_de": "101-110",
        "name_nl": "101-110",
        "name_pl": "101-110",
        "name_ro": "101-110",
        "name_ru": "101-110",
        "name_tr": "101-110",
        "name_se": "101-110",
        "name_el": "101-110",
        "name_es": "101-110",
        "name_hr": "101-110"
    },
    "236^prematch": {
        "name_en": "8:3",
        "name_aa": "8:3",
        "name_de": "8:3",
        "name_nl": "8:3",
        "name_pl": "8:3",
        "name_ro": "8:3",
        "name_ru": "8:3",
        "name_tr": "8:3",
        "name_se": "8:3",
        "name_el": "8:3",
        "name_es": "8:3",
        "name_hr": "8:3"
    },
    "1150^prematch": {
        "name_en": "7+",
        "name_aa": "7+",
        "name_de": "7+",
        "name_nl": "7+",
        "name_pl": "7+",
        "name_ro": "7+",
        "name_ru": "7+",
        "name_tr": "7+",
        "name_se": "7+",
        "name_el": "7+",
        "name_es": "7+",
        "name_hr": "7+"
    },
    "1746^prematch": {
        "name_en": "1-2",
        "name_aa": "1-2",
        "name_de": "1-2",
        "name_nl": "1-2",
        "name_pl": "1-2",
        "name_ro": "1-2",
        "name_ru": "1-2",
        "name_tr": "1-2",
        "name_se": "1-2",
        "name_el": "1-2",
        "name_es": "1-2",
        "name_hr": "1-2"
    },
    "249^prematch": {
        "name_en": "9:0",
        "name_aa": "9:0",
        "name_de": "9:0",
        "name_nl": "9:0",
        "name_pl": "9:0",
        "name_ro": "9:0",
        "name_ru": "9:0",
        "name_tr": "9:0",
        "name_se": "9:0",
        "name_el": "9:0",
        "name_es": "9:0",
        "name_hr": "9:0"
    },
    "1743^prematch": {
        "name_en": "4-6",
        "name_aa": "4-6",
        "name_de": "4-6",
        "name_nl": "4-6",
        "name_pl": "4-6",
        "name_ro": "4-6",
        "name_ru": "4-6",
        "name_tr": "4-6",
        "name_se": "4-6",
        "name_el": "4-6",
        "name_es": "4-6",
        "name_hr": "4-6"
    },
    "126^prematch": {
        "name_en": "draw",
        "name_aa": "draw",
        "name_de": "draw",
        "name_nl": "draw",
        "name_pl": "draw",
        "name_ro": "draw",
        "name_ru": "draw",
        "name_tr": "draw",
        "name_se": "draw",
        "name_el": "draw",
        "name_es": "draw",
        "name_hr": "draw"
    },
    "1261^prematch": {
        "name_en": "60+",
        "name_aa": "60+",
        "name_de": "60+",
        "name_nl": "60+",
        "name_pl": "60+",
        "name_ro": "60+",
        "name_ru": "60+",
        "name_tr": "60+",
        "name_se": "60+",
        "name_el": "60+",
        "name_es": "60+",
        "name_hr": "60+"
    },
    "171^prematch": {
        "name_en": "181-190",
        "name_aa": "181-190",
        "name_de": "181-190",
        "name_nl": "181-190",
        "name_pl": "181-190",
        "name_ro": "181-190",
        "name_ru": "181-190",
        "name_tr": "181-190",
        "name_se": "181-190",
        "name_el": "181-190",
        "name_es": "181-190",
        "name_hr": "181-190"
    },
    "1286^prematch": {
        "name_en": "{$competitor1} by 16-20",
        "name_aa": "{$competitor1} by 16-20",
        "name_de": "{$competitor1} by 16-20",
        "name_nl": "{$competitor1} by 16-20",
        "name_pl": "{$competitor1} by 16-20",
        "name_ro": "{$competitor1} by 16-20",
        "name_ru": "{$competitor1} by 16-20",
        "name_tr": "{$competitor1} by 16-20",
        "name_se": "{$competitor1} by 16-20",
        "name_el": "{$competitor1} by 16-20",
        "name_es": "{$competitor1} by 16-20",
        "name_hr": "{$competitor1} by 16-20"
    },
    "1489^prematch": {
        "name_en": "2:2",
        "name_aa": "2:2",
        "name_de": "2:2",
        "name_nl": "2:2",
        "name_pl": "2:2",
        "name_ro": "2:2",
        "name_ru": "2:2",
        "name_tr": "2:2",
        "name_se": "2:2",
        "name_el": "2:2",
        "name_es": "2:2",
        "name_hr": "2:2"
    },
    "1283^prematch": {
        "name_en": "{$competitor1} by 1-5",
        "name_aa": "{$competitor1} by 1-5",
        "name_de": "{$competitor1} by 1-5",
        "name_nl": "{$competitor1} by 1-5",
        "name_pl": "{$competitor1} by 1-5",
        "name_ro": "{$competitor1} by 1-5",
        "name_ru": "{$competitor1} by 1-5",
        "name_tr": "{$competitor1} by 1-5",
        "name_se": "{$competitor1} by 1-5",
        "name_el": "{$competitor1} by 1-5",
        "name_es": "{$competitor1} by 1-5",
        "name_hr": "{$competitor1} by 1-5"
    },
    "1243^prematch": {
        "name_en": "0-49",
        "name_aa": "0-49",
        "name_de": "0-49",
        "name_nl": "0-49",
        "name_pl": "0-49",
        "name_ro": "0-49",
        "name_ru": "0-49",
        "name_tr": "0-49",
        "name_se": "0-49",
        "name_el": "0-49",
        "name_es": "0-49",
        "name_hr": "0-49"
    },
    "285^prematch": {
        "name_en": "1:10",
        "name_aa": "1:10",
        "name_de": "1:10",
        "name_nl": "1:10",
        "name_pl": "1:10",
        "name_ro": "1:10",
        "name_ru": "1:10",
        "name_tr": "1:10",
        "name_se": "1:10",
        "name_el": "1:10",
        "name_es": "1:10",
        "name_hr": "1:10"
    },
    "1694240000^prematch": {
        "name_en": "Under 3.5",
        "name_aa": "Under 3.5",
        "name_de": "Under 3.5",
        "name_nl": "Under 3.5",
        "name_pl": "Under 3.5",
        "name_ro": "Under 3.5",
        "name_ru": "Under 3.5",
        "name_tr": "Under 3.5",
        "name_se": "Under 3.5",
        "name_el": "Under 3.5",
        "name_es": "Under 3.5",
        "name_hr": "Under 3.5"
    },
    "13^prematch": {
        "name_en": "under {total}",
        "name_aa": "تحت {total}",
        "name_de": "unter {total}",
        "name_nl": "onder {total}",
        "name_pl": "poniżej {total}",
        "name_ro": "sub {total}",
        "name_ru": "меньше {total}",
        "name_tr": "{total} altı",
        "name_se": "under {total}",
        "name_el": "under {total}",
        "name_es": "menos de {total}",
        "name_hr": "manje od {total}"
    },
    "231^prematch": {
        "name_en": "1:7",
        "name_aa": "1:7",
        "name_de": "1:7",
        "name_nl": "1:7",
        "name_pl": "1:7",
        "name_ro": "1:7",
        "name_ru": "1:7",
        "name_tr": "1:7",
        "name_se": "1:7",
        "name_el": "1:7",
        "name_es": "1:7",
        "name_hr": "1:7"
    },
    "239^prematch": {
        "name_en": "8:6",
        "name_aa": "8:6",
        "name_de": "8:6",
        "name_nl": "8:6",
        "name_pl": "8:6",
        "name_ro": "8:6",
        "name_ru": "8:6",
        "name_tr": "8:6",
        "name_se": "8:6",
        "name_el": "8:6",
        "name_es": "8:6",
        "name_hr": "8:6"
    },
    "1502^prematch": {
        "name_en": "3:4",
        "name_aa": "3:4",
        "name_de": "3:4",
        "name_nl": "3:4",
        "name_pl": "3:4",
        "name_ro": "3:4",
        "name_ru": "3:4",
        "name_tr": "3:4",
        "name_se": "3:4",
        "name_el": "3:4",
        "name_es": "3:4",
        "name_hr": "3:4"
    },
    "1095^prematch": {
        "name_en": "14-20",
        "name_aa": "14-20",
        "name_de": "14-20",
        "name_nl": "14-20",
        "name_pl": "14-20",
        "name_ro": "14-20",
        "name_ru": "14-20",
        "name_tr": "14-20",
        "name_se": "14-20",
        "name_el": "14-20",
        "name_es": "14-20",
        "name_hr": "14-20"
    },
    "223^prematch": {
        "name_en": "7:4",
        "name_aa": "7:4",
        "name_de": "7:4",
        "name_nl": "7:4",
        "name_pl": "7:4",
        "name_ro": "7:4",
        "name_ru": "7:4",
        "name_tr": "7:4",
        "name_se": "7:4",
        "name_el": "7:4",
        "name_es": "7:4",
        "name_hr": "7:4"
    },
    "118^prematch": {
        "name_en": "{$competitor2} by 3+",
        "name_aa": "{$competitor2} by 3+",
        "name_de": "{$competitor2} by 3+",
        "name_nl": "{$competitor2} by 3+",
        "name_pl": "{$competitor2} by 3+",
        "name_ro": "{$competitor2} by 3+",
        "name_ru": "{$competitor2} by 3+",
        "name_tr": "{$competitor2} by 3+",
        "name_se": "{$competitor2} by 3+",
        "name_el": "{$competitor2} by 3+",
        "name_es": "{$competitor2} by 3+",
        "name_hr": "{$competitor2} by 3+"
    },
    "1383^prematch": {
        "name_en": "{$competitor1}  10-12",
        "name_aa": "{$competitor1}  10-12",
        "name_de": "{$competitor1}  10-12",
        "name_nl": "{$competitor1}  10-12",
        "name_pl": "{$competitor1}  10-12",
        "name_ro": "{$competitor1}  10-12",
        "name_ru": "{$competitor1}  10-12",
        "name_tr": "{$competitor1}  10-12",
        "name_se": "{$competitor1}  10-12",
        "name_el": "{$competitor1}  10-12",
        "name_es": "{$competitor1}  10-12",
        "name_hr": "{$competitor1}  10-12"
    },
    "1485^prematch": {
        "name_en": "4:1",
        "name_aa": "4:1",
        "name_de": "4:1",
        "name_nl": "4:1",
        "name_pl": "4:1",
        "name_ro": "4:1",
        "name_ru": "4:1",
        "name_tr": "4:1",
        "name_se": "4:1",
        "name_el": "4:1",
        "name_es": "4:1",
        "name_hr": "4:1"
    },
    "1492^prematch": {
        "name_en": "5:2",
        "name_aa": "5:2",
        "name_de": "5:2",
        "name_nl": "5:2",
        "name_pl": "5:2",
        "name_ro": "5:2",
        "name_ru": "5:2",
        "name_tr": "5:2",
        "name_se": "5:2",
        "name_el": "5:2",
        "name_es": "5:2",
        "name_hr": "5:2"
    },
    "1135^prematch": {
        "name_en": "61-75",
        "name_aa": "61-75",
        "name_de": "61-75",
        "name_nl": "61-75",
        "name_pl": "61-75",
        "name_ro": "61-75",
        "name_ru": "61-75",
        "name_tr": "61-75",
        "name_se": "61-75",
        "name_el": "61-75",
        "name_es": "61-75",
        "name_hr": "61-75"
    },
    "220^prematch": {
        "name_en": "7:1",
        "name_aa": "7:1",
        "name_de": "7:1",
        "name_nl": "7:1",
        "name_pl": "7:1",
        "name_ro": "7:1",
        "name_ru": "7:1",
        "name_tr": "7:1",
        "name_se": "7:1",
        "name_el": "7:1",
        "name_es": "7:1",
        "name_hr": "7:1"
    },
    "178^prematch": {
        "name_en": "250.5+",
        "name_aa": "250.5+",
        "name_de": "250.5+",
        "name_nl": "250.5+",
        "name_pl": "250.5+",
        "name_ro": "250.5+",
        "name_ru": "250.5+",
        "name_tr": "250.5+",
        "name_se": "250.5+",
        "name_el": "250.5+",
        "name_es": "250.5+",
        "name_hr": "250.5+"
    },
    "1694170000^prematch": {
        "name_en": "Over 14.5",
        "name_aa": "Over 14.5",
        "name_de": "Over 14.5",
        "name_nl": "Over 14.5",
        "name_pl": "Over 14.5",
        "name_ro": "Over 14.5",
        "name_ru": "Over 14.5",
        "name_tr": "Over 14.5",
        "name_se": "Over 14.5",
        "name_el": "Over 14.5",
        "name_es": "Over 14.5",
        "name_hr": "Over 14.5"
    },
    "596^prematch": {
        "name_en": "none",
        "name_aa": "لا شيء",
        "name_de": "garnicht",
        "name_nl": "geen",
        "name_pl": "żaden",
        "name_ro": "niciunul",
        "name_ru": "не будет",
        "name_tr": "Gol olmaz",
        "name_se": "ingen",
        "name_el": "κανένα",
        "name_es": "ninguno",
        "name_hr": "nikad"
    },
    "1388^prematch": {
        "name_en": "{$competitor2}  7-9",
        "name_aa": "{$competitor2}  7-9",
        "name_de": "{$competitor2}  7-9",
        "name_nl": "{$competitor2}  7-9",
        "name_pl": "{$competitor2}  7-9",
        "name_ro": "{$competitor2}  7-9",
        "name_ru": "{$competitor2}  7-9",
        "name_tr": "{$competitor2}  7-9",
        "name_se": "{$competitor2}  7-9",
        "name_el": "{$competitor2}  7-9",
        "name_es": "{$competitor2}  7-9",
        "name_hr": "{$competitor2}  7-9"
    },
    "1850^prematch": {
        "name_en": "draw/{$competitor2}  over {total}",
        "name_aa": "ارسم / {$competitor2} وأكثر من {total}",
        "name_de": "unentschieden/{$competitor2}  über {total}",
        "name_nl": "gelijkspel/{$competitor2}  boven {total}",
        "name_pl": "remis/{$competitor2}  ponad {total}",
        "name_ro": "egal/{$competitor2}  peste {total}",
        "name_ru": "ничья/{$competitor2} и больше {total}",
        "name_tr": "beraberlik/{$competitor2}  {total} üstü",
        "name_se": "oavgjort/{$competitor2}  över {total}",
        "name_el": "ισοπαλία/{$competitor2}  over {total}",
        "name_es": "empate/{$competitor2}  más de {total}",
        "name_hr": "neodlučeno/{$competitor2} i više {total}"
    },
    "70890000^prematch": {
        "name_en": "{$competitor1}",
        "name_aa": "{$competitor1}",
        "name_de": "{$competitor1}",
        "name_nl": "{$competitor1}",
        "name_pl": "{$competitor1}",
        "name_ro": "{$competitor1}",
        "name_ru": "{$competitor1}",
        "name_tr": "{$competitor1}",
        "name_se": "{$competitor1}",
        "name_el": "{$competitor1}",
        "name_es": "{$competitor1}",
        "name_hr": "{$competitor1}"
    },
    "150^prematch": {
        "name_en": "0-27",
        "name_aa": "0-27",
        "name_de": "0-27",
        "name_nl": "0-27",
        "name_pl": "0-27",
        "name_ro": "0-27",
        "name_ru": "0-27",
        "name_tr": "0-27",
        "name_se": "0-27",
        "name_el": "0-27",
        "name_es": "0-27",
        "name_hr": "0-27"
    },
    "1846^prematch": {
        "name_en": "{$competitor1}/draw  over {total}",
        "name_aa": "{$competitor1} / رسم و أكثر من{total}",
        "name_de": "{$competitor1}/unentschieden  über {total}",
        "name_nl": "{$competitor1}/gelijkspel  boven {total}",
        "name_pl": "{$competitor1}/remis  ponad {total}",
        "name_ro": "{$competitor1}/egal  peste {total}",
        "name_ru": "{$competitor1}/ничья и больше {total}",
        "name_tr": "{$competitor1}/beraberlik  {total} üstü",
        "name_se": "{$competitor1}/oavgjort  över {total}",
        "name_el": "{$competitor1}/draw  over {total}",
        "name_es": "{$competitor1}/empate  más de {total}",
        "name_hr": "{$competitor1}/neodlučeno i više {total}"
    },
    "269^prematch": {
        "name_en": "10:2",
        "name_aa": "10:2",
        "name_de": "10:2",
        "name_nl": "10:2",
        "name_pl": "10:2",
        "name_ro": "10:2",
        "name_ru": "10:2",
        "name_tr": "10:2",
        "name_se": "10:2",
        "name_el": "10:2",
        "name_es": "10:2",
        "name_hr": "10:2"
    },
    "174^prematch": {
        "name_en": "211-220",
        "name_aa": "211-220",
        "name_de": "211-220",
        "name_nl": "211-220",
        "name_pl": "211-220",
        "name_ro": "211-220",
        "name_ru": "211-220",
        "name_tr": "211-220",
        "name_se": "211-220",
        "name_el": "211-220",
        "name_es": "211-220",
        "name_hr": "211-220"
    },
    "1373^prematch": {
        "name_en": "{$competitor2}  5",
        "name_aa": "{$competitor2}  5",
        "name_de": "{$competitor2}  5",
        "name_nl": "{$competitor2}  5",
        "name_pl": "{$competitor2}  5",
        "name_ro": "{$competitor2}  5",
        "name_ru": "{$competitor2}  5",
        "name_tr": "{$competitor2}  5",
        "name_se": "{$competitor2}  5",
        "name_el": "{$competitor2}  5",
        "name_es": "{$competitor2}  5",
        "name_hr": "{$competitor2}  5"
    },
    "172^prematch": {
        "name_en": "191-200",
        "name_aa": "191-200",
        "name_de": "191-200",
        "name_nl": "191-200",
        "name_pl": "191-200",
        "name_ro": "191-200",
        "name_ru": "191-200",
        "name_tr": "191-200",
        "name_se": "191-200",
        "name_el": "191-200",
        "name_es": "191-200",
        "name_hr": "191-200"
    },
    "1142^prematch": {
        "name_en": "9-11",
        "name_aa": "9-11",
        "name_de": "9-11",
        "name_nl": "9-11",
        "name_pl": "9-11",
        "name_ro": "9-11",
        "name_ru": "9-11",
        "name_tr": "9-11",
        "name_se": "9-11",
        "name_el": "9-11",
        "name_es": "9-11",
        "name_hr": "9-11"
    },
    "173^prematch": {
        "name_en": "201-210",
        "name_aa": "201-210",
        "name_de": "201-210",
        "name_nl": "201-210",
        "name_pl": "201-210",
        "name_ro": "201-210",
        "name_ru": "201-210",
        "name_tr": "201-210",
        "name_se": "201-210",
        "name_el": "201-210",
        "name_es": "201-210",
        "name_hr": "201-210"
    },
    "1884^prematch": {
        "name_en": "draw/{$competitor1}  5+",
        "name_aa": "رسم / {$competitor1} و 5+",
        "name_de": "unentschieden/{$competitor1}  5+",
        "name_nl": "gelijkspel/{$competitor1}  5+",
        "name_pl": "remis/{$competitor1}  5+",
        "name_ro": "egal/{$competitor1}  5+",
        "name_ru": "ничья/{$competitor1} и 5+",
        "name_tr": "beraberlik/{$competitor1}  5+",
        "name_se": "oavgjort/{$competitor1}  5+",
        "name_el": "ισοπαλία/{$competitor1}  5+",
        "name_es": "empate/{$competitor1}  5+",
        "name_hr": "neodlučeno/{$competitor1} i 5+"
    },
    "862^prematch": {
        "name_en": "{$competitor2}/{$competitor1}",
        "name_aa": "{$competitor2} / {$competitor1}",
        "name_de": "{$competitor2}/{$competitor1}",
        "name_nl": "{$competitor2}/{$competitor1}",
        "name_pl": "{$competitor2}/{$competitor1}",
        "name_ro": "{$competitor2}/{$competitor1}",
        "name_ru": "{$competitor2}/{$competitor1}",
        "name_tr": "{$competitor2}/{$competitor1}",
        "name_se": "{$competitor2}/{$competitor1}",
        "name_el": "{$competitor2}/{$competitor1}",
        "name_es": "{$competitor2}/{$competitor1}",
        "name_hr": "{$competitor2}/{$competitor1}"
    },
    "478^prematch": {
        "name_en": "1:2",
        "name_aa": "1:2",
        "name_de": "1:2",
        "name_nl": "1:2",
        "name_pl": "1:2",
        "name_ro": "1:2",
        "name_ru": "1:2",
        "name_tr": "1:2",
        "name_se": "1:2",
        "name_el": "1:2",
        "name_es": "1:2",
        "name_hr": "1:2"
    },
    "41^prematch": {
        "name_en": "5",
        "name_aa": "5",
        "name_de": "5",
        "name_nl": "5",
        "name_pl": "5",
        "name_ro": "5",
        "name_ru": "5",
        "name_tr": "5",
        "name_se": "5",
        "name_el": "5",
        "name_es": "5",
        "name_hr": "5"
    },
    "1760^prematch": {
        "name_en": "0",
        "name_aa": "0",
        "name_de": "0",
        "name_nl": "0",
        "name_pl": "0",
        "name_ro": "0",
        "name_ru": "0",
        "name_tr": "0",
        "name_se": "0",
        "name_el": "0",
        "name_es": "0",
        "name_hr": "0"
    },
    "1420000^prematch": {
        "name_en": "{$competitor1}",
        "name_aa": "{$competitor1}",
        "name_de": "{$competitor1}",
        "name_nl": "{$competitor1}",
        "name_pl": "{$competitor1}",
        "name_ro": "{$competitor1}",
        "name_ru": "{$competitor1}",
        "name_tr": "{$competitor1}",
        "name_se": "{$competitor1}",
        "name_el": "{$competitor1}",
        "name_es": "{$competitor1}",
        "name_hr": "{$competitor1}"
    },
    "804^prematch": {
        "name_en": "{$competitor2}  over {total}",
        "name_aa": "{$competitor2} وأكثر من {total}",
        "name_de": "{$competitor2}  über {total}",
        "name_nl": "{$competitor2}  boven {total}",
        "name_pl": "{$competitor2}  ponad {total}",
        "name_ro": "{$competitor2}  peste {total}",
        "name_ru": "{$competitor2}  больше {total}",
        "name_tr": "{$competitor2}  {total} üstü",
        "name_se": "{$competitor2}  över {total}",
        "name_el": "{$competitor2} και over  {total}",
        "name_es": "{$competitor2}  más de {total}",
        "name_hr": "{$competitor2} i više od {total}"
    },
    "1493^prematch": {
        "name_en": "0:3",
        "name_aa": "0:3",
        "name_de": "0:3",
        "name_nl": "0:3",
        "name_pl": "0:3",
        "name_ro": "0:3",
        "name_ru": "0:3",
        "name_tr": "0:3",
        "name_se": "0:3",
        "name_el": "0:3",
        "name_es": "0:3",
        "name_hr": "0:3"
    },
    "1300^prematch": {
        "name_en": "26-30",
        "name_aa": "26-30",
        "name_de": "26-30",
        "name_nl": "26-30",
        "name_pl": "26-30",
        "name_ro": "26-30",
        "name_ru": "26-30",
        "name_tr": "26-30",
        "name_se": "26-30",
        "name_el": "26-30",
        "name_es": "26-30",
        "name_hr": "26-30"
    },
    "468^prematch": {
        "name_en": "1:0",
        "name_aa": "1:0",
        "name_de": "1:0",
        "name_nl": "1:0",
        "name_pl": "1:0",
        "name_ro": "1:0",
        "name_ru": "1:0",
        "name_tr": "1:0",
        "name_se": "1:0",
        "name_el": "1:0",
        "name_es": "1:0",
        "name_hr": "1:0"
    },
    "1375^prematch": {
        "name_en": "{$competitor1} by ko",
        "name_aa": "{$competitor1} by ko",
        "name_de": "{$competitor1} by ko",
        "name_nl": "{$competitor1} by ko",
        "name_pl": "{$competitor1} by ko",
        "name_ro": "{$competitor1} by ko",
        "name_ru": "{$competitor1} by ko",
        "name_tr": "{$competitor1} by ko",
        "name_se": "{$competitor1} by ko",
        "name_el": "{$competitor1} by ko",
        "name_es": "{$competitor1} by ko",
        "name_hr": "{$competitor1} by ko"
    },
    "1158^prematch": {
        "name_en": "0:1",
        "name_aa": "0:1",
        "name_de": "0:1",
        "name_nl": "0:1",
        "name_pl": "0:1",
        "name_ro": "0:1",
        "name_ru": "0:1",
        "name_tr": "0:1",
        "name_se": "0:1",
        "name_el": "0:1",
        "name_es": "0:1",
        "name_hr": "0:1"
    },
    "820^prematch": {
        "name_en": "{$competitor2} goal  {$competitor1}",
        "name_aa": "{$competitor2} هدف و{$competitor1}",
        "name_de": "{$competitor2} Tor  {$competitor1}",
        "name_nl": "doelpunt {$competitor2}  {$competitor1}",
        "name_pl": "{$competitor2} Gol  {$competitor1}",
        "name_ro": "gol {$competitor2}  {$competitor1}",
        "name_ru": "гол {$competitor2}  {$competitor1}",
        "name_tr": "{$competitor2} golü  {$competitor1} kazanır",
        "name_se": "{$competitor2} mål  {$competitor1}",
        "name_el": "γκολ {$competitor2} και {$competitor1}",
        "name_es": "{$competitor2} gol y {$competitor1}",
        "name_hr": "{$competitor2} gol i {$competitor1} pobjeda"
    },
    "234^prematch": {
        "name_en": "8:1",
        "name_aa": "8:1",
        "name_de": "8:1",
        "name_nl": "8:1",
        "name_pl": "8:1",
        "name_ro": "8:1",
        "name_ru": "8:1",
        "name_tr": "8:1",
        "name_se": "8:1",
        "name_el": "8:1",
        "name_es": "8:1",
        "name_hr": "8:1"
    },
    "1381^prematch": {
        "name_en": "{$competitor1}  4-6",
        "name_aa": "{$competitor1}  4-6",
        "name_de": "{$competitor1}  4-6",
        "name_nl": "{$competitor1}  4-6",
        "name_pl": "{$competitor1}  4-6",
        "name_ro": "{$competitor1}  4-6",
        "name_ru": "{$competitor1}  4-6",
        "name_tr": "{$competitor1}  4-6",
        "name_se": "{$competitor1}  4-6",
        "name_el": "{$competitor1}  4-6",
        "name_es": "{$competitor1}  4-6",
        "name_hr": "{$competitor1}  4-6"
    },
    "1160^prematch": {
        "name_en": "0:3",
        "name_aa": "0:3",
        "name_de": "0:3",
        "name_nl": "0:3",
        "name_pl": "0:3",
        "name_ro": "0:3",
        "name_ru": "0:3",
        "name_tr": "0:3",
        "name_se": "0:3",
        "name_el": "0:3",
        "name_es": "0:3",
        "name_hr": "0:3"
    },
    "1424^prematch": {
        "name_en": "{$competitor2}  8",
        "name_aa": "{$competitor2}  8",
        "name_de": "{$competitor2}  8",
        "name_nl": "{$competitor2}  8",
        "name_pl": "{$competitor2}  8",
        "name_ro": "{$competitor2}  8",
        "name_ru": "{$competitor2}  8",
        "name_tr": "{$competitor2}  8",
        "name_se": "{$competitor2}  8",
        "name_el": "{$competitor2}  8",
        "name_es": "{$competitor2}  8",
        "name_hr": "{$competitor2}  8"
    },
    "60080000^prematch": {
        "name_en": "5+/7+",
        "name_aa": "5+/7+",
        "name_de": "5+/7+",
        "name_nl": "5+/7+",
        "name_pl": "5+/7+",
        "name_ro": "5+/7+",
        "name_ru": "5+/7+",
        "name_tr": "5+/7+",
        "name_se": "5+/7+",
        "name_el": "5+/7+",
        "name_es": "5+/7+",
        "name_hr": "5+/7+"
    },
    "1510^prematch": {
        "name_en": "other",
        "name_aa": "other",
        "name_de": "other",
        "name_nl": "other",
        "name_pl": "other",
        "name_ro": "other",
        "name_ru": "other",
        "name_tr": "other",
        "name_se": "other",
        "name_el": "other",
        "name_es": "other",
        "name_hr": "other"
    },
    "70^prematch": {
        "name_en": "odd",
        "name_aa": "الفردية",
        "name_de": "ungerade",
        "name_nl": "oneven",
        "name_pl": "nieparzysty",
        "name_ro": "impar",
        "name_ru": "нечетное",
        "name_tr": "tek",
        "name_se": "udda",
        "name_el": "μονά",
        "name_es": "impar",
        "name_hr": "par"
    },
    "374^prematch": {
        "name_en": "1:0 1:0",
        "name_aa": "1:0 1:0",
        "name_de": "1:0 1:0",
        "name_nl": "1:0 1:0",
        "name_pl": "1:0 1:0",
        "name_ro": "1:0 1:0",
        "name_ru": "1:0 1:0",
        "name_tr": "1:0 1:0",
        "name_se": "1:0 1:0",
        "name_el": "1:0 1:0",
        "name_es": "1:0 1:0",
        "name_hr": "1:0 1:0"
    },
    "1849^prematch": {
        "name_en": "draw/draw  over {total}",
        "name_aa": "رسم / رسم و أكثر من{total}",
        "name_de": "unentschieden/unentschieden  über {total}",
        "name_nl": "gelijkspel/gelijkspel  boven {total}",
        "name_pl": "remis/remis  ponad {total}",
        "name_ro": "egal/egal  peste {total}",
        "name_ru": "ничья/ничья и больше {total}",
        "name_tr": "beraberlik/beraberlik  {total} üstü",
        "name_se": "oavgjort/oavgjort  över {total}",
        "name_el": "ισοπαλία/ισοπαλία  over {total}",
        "name_es": "empate/empate  más de {total}",
        "name_hr": "neodlučeno/neodlučeno i više {total}"
    },
    "1844^prematch": {
        "name_en": "{$competitor2}/{$competitor2}  under {total}",
        "name_aa": "{$competitor2} / {$competitor2} و تحت{total}",
        "name_de": "{$competitor2}/{$competitor2}  unter {total}",
        "name_nl": "{$competitor2}/{$competitor2}  onder {total}",
        "name_pl": "{$competitor2}/{$competitor2}  poniżej {total}",
        "name_ro": "{$competitor2}/{$competitor2}  sub {total}",
        "name_ru": "{$competitor2}/{$competitor2} и меньше {total}",
        "name_tr": "{$competitor2}/{$competitor2}  {total} altı",
        "name_se": "{$competitor2}/{$competitor2}  under {total}",
        "name_el": "{$competitor2}/{$competitor2}  under {total}",
        "name_es": "{$competitor2}/{$competitor2}  menos de {total}",
        "name_hr": "{$competitor2}/{$competitor2} i manje {total}"
    },
    "1871^prematch": {
        "name_en": "{$competitor2}/{$competitor2}  3",
        "name_aa": "{$competitor2} / {$competitor2} و 3",
        "name_de": "{$competitor2}/{$competitor2}  3",
        "name_nl": "{$competitor2}/{$competitor2}  3",
        "name_pl": "{$competitor2}/{$competitor2}  3",
        "name_ro": "{$competitor2}/{$competitor2}  3",
        "name_ru": "{$competitor2}/{$competitor2} и 3",
        "name_tr": "{$competitor2}/{$competitor2}  3",
        "name_se": "{$competitor2}/{$competitor2}  3",
        "name_el": "{$competitor2}/{$competitor2}  3",
        "name_es": "{$competitor2}/{$competitor2}  3",
        "name_hr": "{$competitor2}/{$competitor2} i 3"
    },
    "1093^prematch": {
        "name_en": "0-6",
        "name_aa": "0-6",
        "name_de": "0-6",
        "name_nl": "0-6",
        "name_pl": "0-6",
        "name_ro": "0-6",
        "name_ru": "0-6",
        "name_tr": "0-6",
        "name_se": "0-6",
        "name_el": "0-6",
        "name_es": "0-6",
        "name_hr": "0-6"
    },
    "51^prematch": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2"
    },
    "1749^prematch": {
        "name_en": "4+",
        "name_aa": "4+",
        "name_de": "4+",
        "name_nl": "4+",
        "name_pl": "4+",
        "name_ro": "4+",
        "name_ru": "4+",
        "name_tr": "4+",
        "name_se": "4+",
        "name_el": "4+",
        "name_es": "4+",
        "name_hr": "4+"
    },
    "1245^prematch": {
        "name_en": "60-64",
        "name_aa": "60-64",
        "name_de": "60-64",
        "name_nl": "60-64",
        "name_pl": "60-64",
        "name_ro": "60-64",
        "name_ru": "60-64",
        "name_tr": "60-64",
        "name_se": "60-64",
        "name_el": "60-64",
        "name_es": "60-64",
        "name_hr": "60-64"
    },
    "2035^prematch": {
        "name_en": "{$competitor1} ({+hcp})  over {total}",
        "name_aa": "{$competitor1} ({+hcp})  over {total}",
        "name_de": "{$competitor1} ({+hcp})  over {total}",
        "name_nl": "{$competitor1} ({+hcp})  over {total}",
        "name_pl": "{$competitor1} ({+hcp})  over {total}",
        "name_ro": "{$competitor1} ({+hcp})  over {total}",
        "name_ru": "{$competitor1} ({+hcp})  over {total}",
        "name_tr": "{$competitor1} ({+hcp})  over {total}",
        "name_se": "{$competitor1} ({+hcp})  over {total}",
        "name_el": "{$competitor1} ({+hcp})  over {total}",
        "name_es": "{$competitor1} ({+hcp})  over {total}",
        "name_hr": "{$competitor1} ({+hcp})  over {total}"
    },
    "70740000^prematch": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1"
    },
    "186^prematch": {
        "name_en": "5:6",
        "name_aa": "5:6",
        "name_de": "5:6",
        "name_nl": "5:6",
        "name_pl": "5:6",
        "name_ro": "5:6",
        "name_ru": "5:6",
        "name_tr": "5:6",
        "name_se": "5:6",
        "name_el": "5:6",
        "name_es": "5:6",
        "name_hr": "5:6"
    },
    "398^prematch": {
        "name_en": "1:2 4+",
        "name_aa": "1:2 4+",
        "name_de": "1:2 4+",
        "name_nl": "1:2 4+",
        "name_pl": "1:2 4+",
        "name_ro": "1:2 4+",
        "name_ru": "1:2 4+",
        "name_tr": "1:2 4+",
        "name_se": "1:2 4+",
        "name_el": "1:2 4+",
        "name_es": "1:2 4+",
        "name_hr": "1:2 4+"
    },
    "8^prematch": {
        "name_en": "3:0",
        "name_aa": "3:0",
        "name_de": "3:0",
        "name_nl": "3:0",
        "name_pl": "3:0",
        "name_ro": "3:0",
        "name_ru": "3:0",
        "name_tr": "3:0",
        "name_se": "3:0",
        "name_el": "3:0",
        "name_es": "3:0",
        "name_hr": "3:0"
    },
    "416^prematch": {
        "name_en": "4+ 4+",
        "name_aa": "4+ 4+",
        "name_de": "4+ 4+",
        "name_nl": "4+ 4+",
        "name_pl": "4+ 4+",
        "name_ro": "4+ 4+",
        "name_ru": "4+ 4+",
        "name_tr": "4+ 4+",
        "name_se": "4+ 4+",
        "name_el": "4+ 4+",
        "name_es": "4+ 4+",
        "name_hr": "4+ 4+"
    },
    "35^prematch": {
        "name_en": "4",
        "name_aa": "4",
        "name_de": "4",
        "name_nl": "4",
        "name_pl": "4",
        "name_ro": "4",
        "name_ru": "4",
        "name_tr": "4",
        "name_se": "4",
        "name_el": "4",
        "name_es": "4",
        "name_hr": "4"
    },
    "16^prematch": {
        "name_en": "4:2",
        "name_aa": "4:2",
        "name_de": "4:2",
        "name_nl": "4:2",
        "name_pl": "4:2",
        "name_ro": "4:2",
        "name_ru": "4:2",
        "name_tr": "4:2",
        "name_se": "4:2",
        "name_el": "4:2",
        "name_es": "4:2",
        "name_hr": "4:2"
    },
    "326^prematch": {
        "name_en": "0:0 0:0",
        "name_aa": "0:0 0:0",
        "name_de": "0:0 0:0",
        "name_nl": "0:0 0:0",
        "name_pl": "0:0 0:0",
        "name_ro": "0:0 0:0",
        "name_ru": "0:0 0:0",
        "name_tr": "0:0 0:0",
        "name_se": "0:0 0:0",
        "name_el": "0:0 0:0",
        "name_es": "0:0 0:0",
        "name_hr": "0:0 0:0"
    },
    "1694270000^prematch": {
        "name_en": "Over 5.5",
        "name_aa": "Over 5.5",
        "name_de": "Over 5.5",
        "name_nl": "Over 5.5",
        "name_pl": "Over 5.5",
        "name_ro": "Over 5.5",
        "name_ru": "Over 5.5",
        "name_tr": "Over 5.5",
        "name_se": "Over 5.5",
        "name_el": "Over 5.5",
        "name_es": "Over 5.5",
        "name_hr": "Over 5.5"
    },
    "1694060000^prematch": {
        "name_en": "Under 8.5",
        "name_aa": "Under 8.5",
        "name_de": "Under 8.5",
        "name_nl": "Under 8.5",
        "name_pl": "Under 8.5",
        "name_ro": "Under 8.5",
        "name_ru": "Under 8.5",
        "name_tr": "Under 8.5",
        "name_se": "Under 8.5",
        "name_el": "Under 8.5",
        "name_es": "Under 8.5",
        "name_hr": "Under 8.5"
    },
    "70950000^prematch": {
        "name_en": "{$competitor1}",
        "name_aa": "{$competitor1}",
        "name_de": "{$competitor1}",
        "name_nl": "{$competitor1}",
        "name_pl": "{$competitor1}",
        "name_ro": "{$competitor1}",
        "name_ru": "{$competitor1}",
        "name_tr": "{$competitor1}",
        "name_se": "{$competitor1}",
        "name_el": "{$competitor1}",
        "name_es": "{$competitor1}",
        "name_hr": "{$competitor1}"
    },
    "1367^prematch": {
        "name_en": "{$competitor1}  decision",
        "name_aa": "{$competitor1}  decision",
        "name_de": "{$competitor1}  decision",
        "name_nl": "{$competitor1}  decision",
        "name_pl": "{$competitor1}  decision",
        "name_ro": "{$competitor1}  decision",
        "name_ru": "{$competitor1}  decision",
        "name_tr": "{$competitor1}  decision",
        "name_se": "{$competitor1}  decision",
        "name_el": "{$competitor1}  decision",
        "name_es": "{$competitor1}  decision",
        "name_hr": "{$competitor1}  decision"
    },
    "1864^prematch": {
        "name_en": "{$competitor2}/draw  2",
        "name_aa": "{$competitor2} / رسم و 2",
        "name_de": "{$competitor2}/unentschieden  2",
        "name_nl": "{$competitor2}/gelijkspel  2",
        "name_pl": "{$competitor2}/remis  2",
        "name_ro": "{$competitor2}/egal  2",
        "name_ru": "{$competitor2}/ничья и 2",
        "name_tr": "{$competitor2}/beraberlik  2",
        "name_se": "{$competitor2}/oavgjort  2",
        "name_el": "{$competitor2}/ισοπαλία  2",
        "name_es": "{$competitor2}/empate 2",
        "name_hr": "{$competitor2}/neodlučeno i 2"
    },
    "376^prematch": {
        "name_en": "1:0 1:1",
        "name_aa": "1:0 1:1",
        "name_de": "1:0 1:1",
        "name_nl": "1:0 1:1",
        "name_pl": "1:0 1:1",
        "name_ro": "1:0 1:1",
        "name_ru": "1:0 1:1",
        "name_tr": "1:0 1:1",
        "name_se": "1:0 1:1",
        "name_el": "1:0 1:1",
        "name_es": "1:0 1:1",
        "name_hr": "1:0 1:1"
    },
    "247^prematch": {
        "name_en": "1:8",
        "name_aa": "1:8",
        "name_de": "1:8",
        "name_nl": "1:8",
        "name_pl": "1:8",
        "name_ro": "1:8",
        "name_ru": "1:8",
        "name_tr": "1:8",
        "name_se": "1:8",
        "name_el": "1:8",
        "name_es": "1:8",
        "name_hr": "1:8"
    },
    "1290^prematch": {
        "name_en": "{$competitor2} by 6-10",
        "name_aa": "{$competitor2} by 6-10",
        "name_de": "{$competitor2} by 6-10",
        "name_nl": "{$competitor2} by 6-10",
        "name_pl": "{$competitor2} by 6-10",
        "name_ro": "{$competitor2} by 6-10",
        "name_ru": "{$competitor2} by 6-10",
        "name_tr": "{$competitor2} by 6-10",
        "name_se": "{$competitor2} by 6-10",
        "name_el": "{$competitor2} by 6-10",
        "name_es": "{$competitor2} by 6-10",
        "name_hr": "{$competitor2} by 6-10"
    },
    "129^prematch": {
        "name_en": "{$competitor2} by 11+",
        "name_aa": "{$competitor2} by 11+",
        "name_de": "{$competitor2} by 11+",
        "name_nl": "{$competitor2} by 11+",
        "name_pl": "{$competitor2} by 11+",
        "name_ro": "{$competitor2} by 11+",
        "name_ru": "{$competitor2} by 11+",
        "name_tr": "{$competitor2} by 11+",
        "name_se": "{$competitor2} by 11+",
        "name_el": "{$competitor2} by 11+",
        "name_es": "{$competitor2} by 11+",
        "name_hr": "{$competitor2} by 11+"
    },
    "1340^prematch": {
        "name_en": "4",
        "name_aa": "4",
        "name_de": "4",
        "name_nl": "4",
        "name_pl": "4",
        "name_ro": "4",
        "name_ru": "4",
        "name_tr": "4",
        "name_se": "4",
        "name_el": "4",
        "name_es": "4",
        "name_hr": "4"
    },
    "201^prematch": {
        "name_en": "3:7",
        "name_aa": "3:7",
        "name_de": "3:7",
        "name_nl": "3:7",
        "name_pl": "3:7",
        "name_ro": "3:7",
        "name_ru": "3:7",
        "name_tr": "3:7",
        "name_se": "3:7",
        "name_el": "3:7",
        "name_es": "3:7",
        "name_hr": "3:7"
    },
    "1487^prematch": {
        "name_en": "0:2",
        "name_aa": "0:2",
        "name_de": "0:2",
        "name_nl": "0:2",
        "name_pl": "0:2",
        "name_ro": "0:2",
        "name_ru": "0:2",
        "name_tr": "0:2",
        "name_se": "0:2",
        "name_el": "0:2",
        "name_es": "0:2",
        "name_hr": "0:2"
    },
    "1371^prematch": {
        "name_en": "{$competitor2}  3",
        "name_aa": "{$competitor2}  3",
        "name_de": "{$competitor2}  3",
        "name_nl": "{$competitor2}  3",
        "name_pl": "{$competitor2}  3",
        "name_ro": "{$competitor2}  3",
        "name_ru": "{$competitor2}  3",
        "name_tr": "{$competitor2}  3",
        "name_se": "{$competitor2}  3",
        "name_el": "{$competitor2}  3",
        "name_es": "{$competitor2}  3",
        "name_hr": "{$competitor2}  3"
    },
    "1190^prematch": {
        "name_en": "6:0",
        "name_aa": "6:0",
        "name_de": "6:0",
        "name_nl": "6:0",
        "name_pl": "6:0",
        "name_ro": "6:0",
        "name_ru": "6:0",
        "name_tr": "6:0",
        "name_se": "6:0",
        "name_el": "6:0",
        "name_es": "6:0",
        "name_hr": "6:0"
    },
    "1697230000^prematch": {
        "name_en": "Over",
        "name_aa": "Over",
        "name_de": "Over",
        "name_nl": "Over",
        "name_pl": "Over",
        "name_ro": "Over",
        "name_ru": "Over",
        "name_tr": "Over",
        "name_se": "Over",
        "name_el": "Over",
        "name_es": "Over",
        "name_hr": "Over"
    },
    "382^prematch": {
        "name_en": "1:0 2:1",
        "name_aa": "1:0 2:1",
        "name_de": "1:0 2:1",
        "name_nl": "1:0 2:1",
        "name_pl": "1:0 2:1",
        "name_ro": "1:0 2:1",
        "name_ru": "1:0 2:1",
        "name_tr": "1:0 2:1",
        "name_se": "1:0 2:1",
        "name_el": "1:0 2:1",
        "name_es": "1:0 2:1",
        "name_hr": "1:0 2:1"
    },
    "243^prematch": {
        "name_en": "5:8",
        "name_aa": "5:8",
        "name_de": "5:8",
        "name_nl": "5:8",
        "name_pl": "5:8",
        "name_ro": "5:8",
        "name_ru": "5:8",
        "name_tr": "5:8",
        "name_se": "5:8",
        "name_el": "5:8",
        "name_es": "5:8",
        "name_hr": "5:8"
    },
    "714^prematch": {
        "name_en": "5",
        "name_aa": "5",
        "name_de": "5",
        "name_nl": "5",
        "name_pl": "5",
        "name_ro": "5",
        "name_ru": "5",
        "name_tr": "5",
        "name_se": "5",
        "name_el": "5",
        "name_es": "5",
        "name_hr": "5"
    },
    "1420^prematch": {
        "name_en": "{$competitor2}  4",
        "name_aa": "{$competitor2}  4",
        "name_de": "{$competitor2}  4",
        "name_nl": "{$competitor2}  4",
        "name_pl": "{$competitor2}  4",
        "name_ro": "{$competitor2}  4",
        "name_ru": "{$competitor2}  4",
        "name_tr": "{$competitor2}  4",
        "name_se": "{$competitor2}  4",
        "name_el": "{$competitor2}  4",
        "name_es": "{$competitor2}  4",
        "name_hr": "{$competitor2}  4"
    },
    "1739^prematch": {
        "name_en": "3-4",
        "name_aa": "3-4",
        "name_de": "3-4",
        "name_nl": "3-4",
        "name_pl": "3-4",
        "name_ro": "3-4",
        "name_ru": "3-4",
        "name_tr": "3-4",
        "name_se": "3-4",
        "name_el": "3-4",
        "name_es": "3-4",
        "name_hr": "3-4"
    },
    "293^prematch": {
        "name_en": "11:6",
        "name_aa": "11:6",
        "name_de": "11:6",
        "name_nl": "11:6",
        "name_pl": "11:6",
        "name_ro": "11:6",
        "name_ru": "11:6",
        "name_tr": "11:6",
        "name_se": "11:6",
        "name_el": "11:6",
        "name_es": "11:6",
        "name_hr": "11:6"
    },
    "86^prematch": {
        "name_en": "{$competitor2}  yes",
        "name_aa": "{$competitor2} ونعم",
        "name_de": "{$competitor2}  ja",
        "name_nl": "{$competitor2}  ja",
        "name_pl": "{$competitor2}  tak",
        "name_ro": "{$competitor2}  da",
        "name_ru": "{$competitor2}  да",
        "name_tr": "{$competitor2}  var",
        "name_se": "{$competitor2}  ja",
        "name_el": "ναι και {$competitor2}",
        "name_es": "{$competitor2} y sí",
        "name_hr": "{$competitor2} i da"
    },
    "1179^prematch": {
        "name_en": "3:1",
        "name_aa": "3:1",
        "name_de": "3:1",
        "name_nl": "3:1",
        "name_pl": "3:1",
        "name_ro": "3:1",
        "name_ru": "3:1",
        "name_tr": "3:1",
        "name_se": "3:1",
        "name_el": "3:1",
        "name_es": "3:1",
        "name_hr": "3:1"
    },
    "1121^prematch": {
        "name_en": "0-1",
        "name_aa": "0-1",
        "name_de": "0-1",
        "name_nl": "0-1",
        "name_pl": "0-1",
        "name_ro": "0-1",
        "name_ru": "0-1",
        "name_tr": "0-1",
        "name_se": "0-1",
        "name_el": "0-1",
        "name_es": "0-1",
        "name_hr": "0-1"
    },
    "1297^prematch": {
        "name_en": "11-15",
        "name_aa": "11-15",
        "name_de": "11-15",
        "name_nl": "11-15",
        "name_pl": "11-15",
        "name_ro": "11-15",
        "name_ru": "11-15",
        "name_tr": "11-15",
        "name_se": "11-15",
        "name_el": "11-15",
        "name_es": "11-15",
        "name_hr": "11-15"
    },
    "4^prematch": {
        "name_en": "{$competitor1}",
        "name_aa": "{$competitor1}",
        "name_de": "{$competitor1}",
        "name_nl": "{$competitor1}",
        "name_pl": "{$competitor1}",
        "name_ro": "{$competitor1}",
        "name_ru": "{$competitor1}",
        "name_tr": "{$competitor1}",
        "name_se": "{$competitor1}",
        "name_el": "{$competitor1}",
        "name_es": "{$competitor1}",
        "name_hr": "{$competitor1}"
    },
    "422^prematch": {
        "name_en": "{$competitor1}/{$competitor2}",
        "name_aa": "{$competitor1} / {$competitor2}",
        "name_de": "{$competitor1}/{$competitor2}",
        "name_nl": "{$competitor1}/{$competitor2}",
        "name_pl": "{$competitor1}/{$competitor2}",
        "name_ro": "{$competitor1}/{$competitor2}",
        "name_ru": "{$competitor1}/{$competitor2}",
        "name_tr": "{$competitor1}/{$competitor2}",
        "name_se": "{$competitor1}/{$competitor2}",
        "name_el": "{$competitor1}/{$competitor2}",
        "name_es": "{$competitor1}/{$competitor2}",
        "name_hr": "{$competitor1}/{$competitor2}"
    },
    "940^prematch": {
        "name_en": "exact {total}",
        "name_aa": "الدقيق {total}",
        "name_de": "genau {total}",
        "name_nl": "exact {total}",
        "name_pl": "dokładnie {total}",
        "name_ro": "exact {total}",
        "name_ru": "точно {total}",
        "name_tr": "tam {total}",
        "name_se": "exakta {total}",
        "name_el": "ακριβώς {total}",
        "name_es": "exacto {total}",
        "name_hr": "točno {total}"
    },
    "1694250000^prematch": {
        "name_en": "Over 4.5",
        "name_aa": "Over 4.5",
        "name_de": "Over 4.5",
        "name_nl": "Over 4.5",
        "name_pl": "Over 4.5",
        "name_ro": "Over 4.5",
        "name_ru": "Over 4.5",
        "name_tr": "Over 4.5",
        "name_se": "Over 4.5",
        "name_el": "Over 4.5",
        "name_es": "Over 4.5",
        "name_hr": "Over 4.5"
    },
    "610^prematch": {
        "name_en": "61-70",
        "name_aa": "61-70",
        "name_de": "61-70",
        "name_nl": "61-70",
        "name_pl": "61-70",
        "name_ro": "61-70",
        "name_ru": "61-70",
        "name_tr": "61-70",
        "name_se": "61-70",
        "name_el": "61-70",
        "name_es": "61-70",
        "name_hr": "61-70"
    },
    "1198^prematch": {
        "name_en": "1.5:0.5",
        "name_aa": "1.5:0.5",
        "name_de": "1.5:0.5",
        "name_nl": "1.5:0.5",
        "name_pl": "1.5:0.5",
        "name_ro": "1.5:0.5",
        "name_ru": "1.5:0.5",
        "name_tr": "1.5:0.5",
        "name_se": "1.5:0.5",
        "name_el": "1.5:0.5",
        "name_es": "1.5:0.5",
        "name_hr": "1.5:0.5"
    },
    "200^prematch": {
        "name_en": "4:7",
        "name_aa": "4:7",
        "name_de": "4:7",
        "name_nl": "4:7",
        "name_pl": "4:7",
        "name_ro": "4:7",
        "name_ru": "4:7",
        "name_tr": "4:7",
        "name_se": "4:7",
        "name_el": "4:7",
        "name_es": "4:7",
        "name_hr": "4:7"
    },
    "874^prematch": {
        "name_en": "2:6",
        "name_aa": "2:6",
        "name_de": "2:6",
        "name_nl": "2:6",
        "name_pl": "2:6",
        "name_ro": "2:6",
        "name_ru": "2:6",
        "name_tr": "2:6",
        "name_se": "2:6",
        "name_el": "2:6",
        "name_es": "2:6",
        "name_hr": "2:6"
    },
    "1731^prematch": {
        "name_en": "1-3",
        "name_aa": "1-3",
        "name_de": "1-3",
        "name_nl": "1-3",
        "name_pl": "1-3",
        "name_ro": "1-3",
        "name_ru": "1-3",
        "name_tr": "1-3",
        "name_se": "1-3",
        "name_el": "1-3",
        "name_es": "1-3",
        "name_hr": "1-3"
    },
    "1187^prematch": {
        "name_en": "5:0",
        "name_aa": "5:0",
        "name_de": "5:0",
        "name_nl": "5:0",
        "name_pl": "5:0",
        "name_ro": "5:0",
        "name_ru": "5:0",
        "name_tr": "5:0",
        "name_se": "5:0",
        "name_el": "5:0",
        "name_es": "5:0",
        "name_hr": "5:0"
    },
    "141^prematch": {
        "name_en": "{$competitor2} by 6-10",
        "name_aa": "{$competitor2} by 6-10",
        "name_de": "{$competitor2} by 6-10",
        "name_nl": "{$competitor2} by 6-10",
        "name_pl": "{$competitor2} by 6-10",
        "name_ro": "{$competitor2} by 6-10",
        "name_ru": "{$competitor2} by 6-10",
        "name_tr": "{$competitor2} by 6-10",
        "name_se": "{$competitor2} by 6-10",
        "name_el": "{$competitor2} by 6-10",
        "name_es": "{$competitor2} by 6-10",
        "name_hr": "{$competitor2} by 6-10"
    },
    "70860000^prematch": {
        "name_en": "{$competitor1}",
        "name_aa": "{$competitor1}",
        "name_de": "{$competitor1}",
        "name_nl": "{$competitor1}",
        "name_pl": "{$competitor1}",
        "name_ro": "{$competitor1}",
        "name_ru": "{$competitor1}",
        "name_tr": "{$competitor1}",
        "name_se": "{$competitor1}",
        "name_el": "{$competitor1}",
        "name_es": "{$competitor1}",
        "name_hr": "{$competitor1}"
    },
    "406^prematch": {
        "name_en": "2:0 4+",
        "name_aa": "2:0 4+",
        "name_de": "2:0 4+",
        "name_nl": "2:0 4+",
        "name_pl": "2:0 4+",
        "name_ro": "2:0 4+",
        "name_ru": "2:0 4+",
        "name_tr": "2:0 4+",
        "name_se": "2:0 4+",
        "name_el": "2:0 4+",
        "name_es": "2:0 4+",
        "name_hr": "2:0 4+"
    },
    "240^prematch": {
        "name_en": "8:7",
        "name_aa": "8:7",
        "name_de": "8:7",
        "name_nl": "8:7",
        "name_pl": "8:7",
        "name_ro": "8:7",
        "name_ru": "8:7",
        "name_tr": "8:7",
        "name_se": "8:7",
        "name_el": "8:7",
        "name_es": "8:7",
        "name_hr": "8:7"
    },
    "54^prematch": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1"
    },
    "724^prematch": {
        "name_en": "10",
        "name_aa": "10",
        "name_de": "10",
        "name_nl": "10",
        "name_pl": "10",
        "name_ro": "10",
        "name_ru": "10",
        "name_tr": "10",
        "name_se": "10",
        "name_el": "10",
        "name_es": "10",
        "name_hr": "10"
    },
    "814^prematch": {
        "name_en": "{$competitor1} goal  {$competitor1}",
        "name_aa": "{$competitor1} هدف و{$competitor1}",
        "name_de": "{$competitor1} Tor  {$competitor1}",
        "name_nl": "doelpunt {$competitor1}  {$competitor1}",
        "name_pl": "{$competitor1} Gol  {$competitor1}",
        "name_ro": "gol {$competitor1}  {$competitor1}",
        "name_ru": "гол {$competitor1}   {$competitor1}",
        "name_tr": "{$competitor1} golü  {$competitor1} kazanır",
        "name_se": "{$competitor1} mål  {$competitor1}",
        "name_el": "γκολ {$competitor1} και {$competitor1}",
        "name_es": "{$competitor1} gol y {$competitor1}",
        "name_hr": "{$competitor1} gol i {$competitor1} pobjeda"
    },
    "1729^prematch": {
        "name_en": "draw/{$competitor2}  over {total}",
        "name_aa": "ارسم / {$competitor2} وأكثر من {total}",
        "name_de": "unentschieden/{$competitor2}  über {total}",
        "name_nl": "gelijkspel/{$competitor2}  boven {total}",
        "name_pl": "remis/{$competitor2}  ponad {total}",
        "name_ro": "egal/{$competitor2}  peste {total}",
        "name_ru": "ничья/{$competitor2} больше {total}",
        "name_tr": "beraberlik/{$competitor2}  {total} üstü",
        "name_se": "oavgjort/{$competitor2}  över {total}",
        "name_el": "ισοπαλία/{$competitor2} και over {total}",
        "name_es": "empate/{$competitor2} y más de {total}",
        "name_hr": "neodlučeno/{$competitor2} i više {total}"
    },
    "39^prematch": {
        "name_en": "3",
        "name_aa": "3",
        "name_de": "3",
        "name_nl": "3",
        "name_pl": "3",
        "name_ro": "3",
        "name_ru": "3",
        "name_tr": "3",
        "name_se": "3",
        "name_el": "3",
        "name_es": "3",
        "name_hr": "3"
    },
    "85^prematch": {
        "name_en": "0",
        "name_aa": "0",
        "name_de": "0",
        "name_nl": "0",
        "name_pl": "0",
        "name_ro": "0",
        "name_ru": "0",
        "name_tr": "0",
        "name_se": "0",
        "name_el": "0",
        "name_es": "0",
        "name_hr": "0"
    },
    "17^prematch": {
        "name_en": "4:3",
        "name_aa": "4:3",
        "name_de": "4:3",
        "name_nl": "4:3",
        "name_pl": "4:3",
        "name_ro": "4:3",
        "name_ru": "4:3",
        "name_tr": "4:3",
        "name_se": "4:3",
        "name_el": "4:3",
        "name_es": "4:3",
        "name_hr": "4:3"
    },
    "1085^prematch": {
        "name_en": "31+",
        "name_aa": "31+",
        "name_de": "31+",
        "name_nl": "31+",
        "name_pl": "31+",
        "name_ro": "31+",
        "name_ru": "31+",
        "name_tr": "31+",
        "name_se": "31+",
        "name_el": "31+",
        "name_es": "31+",
        "name_hr": "31+"
    },
    "185^prematch": {
        "name_en": "6:5",
        "name_aa": "6:5",
        "name_de": "6:5",
        "name_nl": "6:5",
        "name_pl": "6:5",
        "name_ro": "6:5",
        "name_ru": "6:5",
        "name_tr": "6:5",
        "name_se": "6:5",
        "name_el": "6:5",
        "name_es": "6:5",
        "name_hr": "6:5"
    },
    "1694410000^prematch": {
        "name_en": "{$competitor1}",
        "name_aa": "{$competitor1}",
        "name_de": "{$competitor1}",
        "name_nl": "{$competitor1}",
        "name_pl": "{$competitor1}",
        "name_ro": "{$competitor1}",
        "name_ru": "{$competitor1}",
        "name_tr": "{$competitor1}",
        "name_se": "{$competitor1}",
        "name_el": "{$competitor1}",
        "name_es": "{$competitor1}",
        "name_hr": "{$competitor1}"
    },
    "1139^prematch": {
        "name_en": "26-40",
        "name_aa": "26-40",
        "name_de": "26-40",
        "name_nl": "26-40",
        "name_pl": "26-40",
        "name_ro": "26-40",
        "name_ru": "26-40",
        "name_tr": "26-40",
        "name_se": "26-40",
        "name_el": "26-40",
        "name_es": "26-40",
        "name_hr": "26-40"
    },
    "728^prematch": {
        "name_en": "12+",
        "name_aa": "12+",
        "name_de": "12+",
        "name_nl": "12+",
        "name_pl": "12+",
        "name_ro": "12+",
        "name_ru": "12+",
        "name_tr": "12+",
        "name_se": "12+",
        "name_el": "12+",
        "name_es": "12+",
        "name_hr": "12+"
    },
    "70900000^prematch": {
        "name_en": "Draw",
        "name_aa": "Draw",
        "name_de": "Draw",
        "name_nl": "Draw",
        "name_pl": "Draw",
        "name_ro": "Draw",
        "name_ru": "Draw",
        "name_tr": "Draw",
        "name_se": "Draw",
        "name_el": "Draw",
        "name_es": "Draw",
        "name_hr": "Draw"
    },
    "1869^prematch": {
        "name_en": "draw/{$competitor2}  3",
        "name_aa": "رسم / {$competitor2} و 3",
        "name_de": "unentschieden/{$competitor2}  3",
        "name_nl": "gelijkspel/{$competitor2}  3",
        "name_pl": "remis/{$competitor2}  3",
        "name_ro": "egal/{$competitor2}  3",
        "name_ru": "ничья/{$competitor2} и 3",
        "name_tr": "beraberlik/{$competitor2}  3",
        "name_se": "oavgjort/{$competitor2}  3",
        "name_el": "ισοπαλία{$competitor2}  3",
        "name_es": "empate/{$competitor2}  3",
        "name_hr": "neodlučeno/{$competitor2} i 3"
    },
    "800^prematch": {
        "name_en": "draw  over {total}",
        "name_aa": "السحب والإسقاط {total}",
        "name_de": "unentschieden  über {total}",
        "name_nl": "gelijkspel  boven {total}",
        "name_pl": "remis  ponad {total}",
        "name_ro": "egal  peste {total}",
        "name_ru": "ничья  больше {total}",
        "name_tr": "beraberlik  {total} üstü",
        "name_se": "oavgjort  över {total}",
        "name_el": "ισοπαλία και over {total}",
        "name_es": "empate y más de {total}",
        "name_hr": "neodlučeno i više od {total}"
    },
    "1875^prematch": {
        "name_en": "draw/{$competitor1}  4",
        "name_aa": "رسم / {$competitor1} و 4",
        "name_de": "unentschieden/{$competitor1}  4",
        "name_nl": "gelijkspel/{$competitor1}  4",
        "name_pl": "remis/{$competitor1}  4",
        "name_ro": "egal/{$competitor1}  4",
        "name_ru": "ничья/{$competitor1} и 4",
        "name_tr": "beraberlik/{$competitor1}  4",
        "name_se": "oavgjort/{$competitor1}  4",
        "name_el": "ισοπαλία/{$competitor1}  4",
        "name_es": "empate/{$competitor1}  4",
        "name_hr": "neodlučeno/{$competitor1} i 4"
    },
    "1857^prematch": {
        "name_en": "draw/{$competitor2}  1",
        "name_aa": "رسم / {$competitor2} و 1",
        "name_de": "unentschieden/{$competitor2}  1",
        "name_nl": "gelijkspel/{$competitor2}  1",
        "name_pl": "remis/{$competitor2}  1",
        "name_ro": "egal/{$competitor2}  1",
        "name_ru": "ничья/{$competitor2} и 1",
        "name_tr": "beraberlik/{$competitor2}  1",
        "name_se": "oavgjort/{$competitor2}  1",
        "name_el": "ισοπαλία/{$competitor2}  2",
        "name_es": "empate/{$competitor2}  1",
        "name_hr": "neodlučeno/{$competitor2} i 1"
    },
    "197^prematch": {
        "name_en": "7:5",
        "name_aa": "7:5",
        "name_de": "7:5",
        "name_nl": "7:5",
        "name_pl": "7:5",
        "name_ro": "7:5",
        "name_ru": "7:5",
        "name_tr": "7:5",
        "name_se": "7:5",
        "name_el": "7:5",
        "name_es": "7:5",
        "name_hr": "7:5"
    },
    "1253^prematch": {
        "name_en": "25-29",
        "name_aa": "25-29",
        "name_de": "25-29",
        "name_nl": "25-29",
        "name_pl": "25-29",
        "name_ro": "25-29",
        "name_ru": "25-29",
        "name_tr": "25-29",
        "name_se": "25-29",
        "name_el": "25-29",
        "name_es": "25-29",
        "name_hr": "25-29"
    },
    "70790000^prematch": {
        "name_en": "1-2",
        "name_aa": "1-2",
        "name_de": "1-2",
        "name_nl": "1-2",
        "name_pl": "1-2",
        "name_ro": "1-2",
        "name_ru": "1-2",
        "name_tr": "1-2",
        "name_se": "1-2",
        "name_el": "1-2",
        "name_es": "1-2",
        "name_hr": "1-2"
    },
    "861^prematch": {
        "name_en": "{$competitor1}/{$competitor1}",
        "name_aa": "{$competitor1} / {$competitor1}",
        "name_de": "{$competitor1}/{$competitor1}",
        "name_nl": "{$competitor1}/{$competitor1}",
        "name_pl": "{$competitor1}/{$competitor1}",
        "name_ro": "{$competitor1}/{$competitor1}",
        "name_ru": "{$competitor1}/{$competitor1}",
        "name_tr": "{$competitor1}/{$competitor1}",
        "name_se": "{$competitor1}/{$competitor1}",
        "name_el": "{$competitor1}/{$competitor1}",
        "name_es": "{$competitor1}/{$competitor1}",
        "name_hr": "{$competitor1}/{$competitor1}"
    },
    "40^prematch": {
        "name_en": "4",
        "name_aa": "4",
        "name_de": "4",
        "name_nl": "4",
        "name_pl": "4",
        "name_ro": "4",
        "name_ru": "4",
        "name_tr": "4",
        "name_se": "4",
        "name_el": "4",
        "name_es": "4",
        "name_hr": "4"
    },
    "89^prematch": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1"
    },
    "2038^prematch": {
        "name_en": "{$competitor2} ({-hcp})  under {total}",
        "name_aa": "{$competitor2} ({-hcp})  under {total}",
        "name_de": "{$competitor2} ({-hcp})  under {total}",
        "name_nl": "{$competitor2} ({-hcp})  under {total}",
        "name_pl": "{$competitor2} ({-hcp})  under {total}",
        "name_ro": "{$competitor2} ({-hcp})  under {total}",
        "name_ru": "{$competitor2} ({-hcp})  under {total}",
        "name_tr": "{$competitor2} ({-hcp})  under {total}",
        "name_se": "{$competitor2} ({-hcp})  under {total}",
        "name_el": "{$competitor2} ({-hcp})  under {total}",
        "name_es": "{$competitor2} ({-hcp})  under {total}",
        "name_hr": "{$competitor2} ({-hcp})  under {total}"
    },
    "949^prematch": {
        "name_en": "draw",
        "name_aa": "رسم",
        "name_de": "unentschieden",
        "name_nl": "gelijkspel",
        "name_pl": "remis",
        "name_ro": "egalitate",
        "name_ru": "ничья",
        "name_tr": "beraberlik",
        "name_se": "oavgjort",
        "name_el": "ισοπαλία",
        "name_es": "empate",
        "name_hr": "neodlučeno"
    },
    "1640^prematch": {
        "name_en": "{$competitor1} with try",
        "name_aa": "{$competitor1} مع المحاولة",
        "name_de": "{$competitor1} mit Versuch",
        "name_nl": "{$competitor1} met try",
        "name_pl": "{$competitor1} z przyłożeniem",
        "name_ro": "{$competitor1} cu încercare",
        "name_ru": "{$competitor1} с попытки",
        "name_tr": "{$competitor1} try ile",
        "name_se": "{$competitor1} med försök",
        "name_el": "{$competitor1} με try",
        "name_es": "{$competitor1} con ensayo",
        "name_hr": "{$competitor1} polaganjem"
    },
    "1360^prematch": {
        "name_en": "{$competitor2}  3",
        "name_aa": "{$competitor2}  3",
        "name_de": "{$competitor2}  3",
        "name_nl": "{$competitor2}  3",
        "name_pl": "{$competitor2}  3",
        "name_ro": "{$competitor2}  3",
        "name_ru": "{$competitor2}  3",
        "name_tr": "{$competitor2}  3",
        "name_se": "{$competitor2}  3",
        "name_el": "{$competitor2}  3",
        "name_es": "{$competitor2}  3",
        "name_hr": "{$competitor2}  3"
    },
    "1624^prematch": {
        "name_en": "equal",
        "name_aa": "مساو",
        "name_de": "gleich",
        "name_nl": "gelijk",
        "name_pl": "równo",
        "name_ro": "egalitate",
        "name_ru": "равные",
        "name_tr": "eşit",
        "name_se": "lika",
        "name_el": "ίδιος αριθμός",
        "name_es": "igual",
        "name_hr": "jednako"
    },
    "973^prematch": {
        "name_en": "{$competitor1}  over {total}",
        "name_aa": "{$competitor1}  over {total}",
        "name_de": "{$competitor1}  over {total}",
        "name_nl": "{$competitor1}  over {total}",
        "name_pl": "{$competitor1}  over {total}",
        "name_ro": "{$competitor1}  over {total}",
        "name_ru": "{$competitor1}  over {total}",
        "name_tr": "{$competitor1}  over {total}",
        "name_se": "{$competitor1}  over {total}",
        "name_el": "{$competitor1}  over {total}",
        "name_es": "{$competitor1}  over {total}",
        "name_hr": "{$competitor1}  over {total}"
    },
    "1140^prematch": {
        "name_en": "41+",
        "name_aa": "41+",
        "name_de": "41+",
        "name_nl": "41+",
        "name_pl": "41+",
        "name_ro": "41+",
        "name_ru": "41+",
        "name_tr": "41+",
        "name_se": "41+",
        "name_el": "41+",
        "name_es": "41+",
        "name_hr": "41+"
    },
    "878^prematch": {
        "name_en": "6:7",
        "name_aa": "6:7",
        "name_de": "6:7",
        "name_nl": "6:7",
        "name_pl": "6:7",
        "name_ro": "6:7",
        "name_ru": "6:7",
        "name_tr": "6:7",
        "name_se": "6:7",
        "name_el": "6:7",
        "name_es": "6:7",
        "name_hr": "6:7"
    },
    "732^prematch": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2"
    },
    "1865^prematch": {
        "name_en": "{$competitor2}/{$competitor2}  2",
        "name_aa": "{$competitor2} / {$competitor2} و 2",
        "name_de": "{$competitor2}/{$competitor2}  2",
        "name_nl": "{$competitor2}/{$competitor2}  2",
        "name_pl": "{$competitor2}/{$competitor2}  2",
        "name_ro": "{$competitor2}/{$competitor2}  2",
        "name_ru": "{$competitor2}/{$competitor2} и 2",
        "name_tr": "{$competitor2}/{$competitor2}  2",
        "name_se": "{$competitor2}/{$competitor2}  2",
        "name_el": "{$competitor2}/{$competitor2}  2",
        "name_es": "{$competitor2}/{$competitor2}  2",
        "name_hr": "{$competitor2}/{$competitor2} i 2"
    },
    "1173^prematch": {
        "name_en": "2:1",
        "name_aa": "2:1",
        "name_de": "2:1",
        "name_nl": "2:1",
        "name_pl": "2:1",
        "name_ro": "2:1",
        "name_ru": "2:1",
        "name_tr": "2:1",
        "name_se": "2:1",
        "name_el": "2:1",
        "name_es": "2:1",
        "name_hr": "2:1"
    },
    "76^prematch": {
        "name_en": "no",
        "name_aa": "لا",
        "name_de": "nein",
        "name_nl": "nee",
        "name_pl": "nie",
        "name_ro": "nu",
        "name_ru": "нет",
        "name_tr": "yok",
        "name_se": "nej",
        "name_el": "όχι",
        "name_es": "no",
        "name_hr": "ne"
    },
    "824^prematch": {
        "name_en": "{$competitor2} goal  {$competitor2}",
        "name_aa": "{$competitor2} هدف و{$competitor2}",
        "name_de": "{$competitor2} Tor  {$competitor2}",
        "name_nl": "doelpunt {$competitor2}  {$competitor2}",
        "name_pl": "{$competitor2} Gol  {$competitor2}",
        "name_ro": "gol {$competitor2}  {$competitor2}",
        "name_ru": "гол {$competitor2}   {$competitor2}",
        "name_tr": "{$competitor2} golü  {$competitor2} kazanır",
        "name_se": "{$competitor2} mål  {$competitor2}",
        "name_el": "γκολ {$competitor2} και {$competitor2}",
        "name_es": "{$competitor2} gol y {$competitor2}",
        "name_hr": "{$competitor2} gol i {$competitor2} pobjeda"
    },
    "91^prematch": {
        "name_en": "3+",
        "name_aa": "3+",
        "name_de": "3+",
        "name_nl": "3+",
        "name_pl": "3+",
        "name_ro": "3+",
        "name_ru": "3+",
        "name_tr": "3+",
        "name_se": "3+",
        "name_el": "3+",
        "name_es": "3+",
        "name_hr": "3+"
    },
    "2037^prematch": {
        "name_en": "{$competitor2} ({-hcp})  over {total}",
        "name_aa": "{$competitor2} ({-hcp})  over {total}",
        "name_de": "{$competitor2} ({-hcp})  over {total}",
        "name_nl": "{$competitor2} ({-hcp})  over {total}",
        "name_pl": "{$competitor2} ({-hcp})  over {total}",
        "name_ro": "{$competitor2} ({-hcp})  over {total}",
        "name_ru": "{$competitor2} ({-hcp})  over {total}",
        "name_tr": "{$competitor2} ({-hcp})  over {total}",
        "name_se": "{$competitor2} ({-hcp})  over {total}",
        "name_el": "{$competitor2} ({-hcp})  over {total}",
        "name_es": "{$competitor2} ({-hcp})  over {total}",
        "name_hr": "{$competitor2} ({-hcp})  over {total}"
    },
    "344^prematch": {
        "name_en": "0:0 3:0",
        "name_aa": "0:0 3:0",
        "name_de": "0:0 3:0",
        "name_nl": "0:0 3:0",
        "name_pl": "0:0 3:0",
        "name_ro": "0:0 3:0",
        "name_ru": "0:0 3:0",
        "name_tr": "0:0 3:0",
        "name_se": "0:0 3:0",
        "name_el": "0:0 3:0",
        "name_es": "0:0 3:0",
        "name_hr": "0:0 3:0"
    },
    "1138^prematch": {
        "name_en": "11-25",
        "name_aa": "11-25",
        "name_de": "11-25",
        "name_nl": "11-25",
        "name_pl": "11-25",
        "name_ro": "11-25",
        "name_ru": "11-25",
        "name_tr": "11-25",
        "name_se": "11-25",
        "name_el": "11-25",
        "name_es": "11-25",
        "name_hr": "11-25"
    },
    "1177^prematch": {
        "name_en": "2:5",
        "name_aa": "2:5",
        "name_de": "2:5",
        "name_nl": "2:5",
        "name_pl": "2:5",
        "name_ro": "2:5",
        "name_ru": "2:5",
        "name_tr": "2:5",
        "name_se": "2:5",
        "name_el": "2:5",
        "name_es": "2:5",
        "name_hr": "2:5"
    },
    "562^prematch": {
        "name_en": "2:2",
        "name_aa": "2:2",
        "name_de": "2:2",
        "name_nl": "2:2",
        "name_pl": "2:2",
        "name_ro": "2:2",
        "name_ru": "2:2",
        "name_tr": "2:2",
        "name_se": "2:2",
        "name_el": "2:2",
        "name_es": "2:2",
        "name_hr": "2:2"
    },
    "202^prematch": {
        "name_en": "2:7",
        "name_aa": "2:7",
        "name_de": "2:7",
        "name_nl": "2:7",
        "name_pl": "2:7",
        "name_ro": "2:7",
        "name_ru": "2:7",
        "name_tr": "2:7",
        "name_se": "2:7",
        "name_el": "2:7",
        "name_es": "2:7",
        "name_hr": "2:7"
    },
    "163^prematch": {
        "name_en": "151-160",
        "name_aa": "151-160",
        "name_de": "151-160",
        "name_nl": "151-160",
        "name_pl": "151-160",
        "name_ro": "151-160",
        "name_ru": "151-160",
        "name_tr": "151-160",
        "name_se": "151-160",
        "name_el": "151-160",
        "name_es": "151-160",
        "name_hr": "151-160"
    },
    "23^prematch": {
        "name_en": "5:1",
        "name_aa": "5:1",
        "name_de": "5:1",
        "name_nl": "5:1",
        "name_pl": "5:1",
        "name_ro": "5:1",
        "name_ru": "5:1",
        "name_tr": "5:1",
        "name_se": "5:1",
        "name_el": "5:1",
        "name_es": "5:1",
        "name_hr": "5:1"
    },
    "866^prematch": {
        "name_en": "6:1",
        "name_aa": "6:1",
        "name_de": "6:1",
        "name_nl": "6:1",
        "name_pl": "6:1",
        "name_ro": "6:1",
        "name_ru": "6:1",
        "name_tr": "6:1",
        "name_se": "6:1",
        "name_el": "6:1",
        "name_es": "6:1",
        "name_hr": "6:1"
    },
    "70920000^prematch": {
        "name_en": "{$competitor1}",
        "name_aa": "{$competitor1}",
        "name_de": "{$competitor1}",
        "name_nl": "{$competitor1}",
        "name_pl": "{$competitor1}",
        "name_ro": "{$competitor1}",
        "name_ru": "{$competitor1}",
        "name_tr": "{$competitor1}",
        "name_se": "{$competitor1}",
        "name_el": "{$competitor1}",
        "name_es": "{$competitor1}",
        "name_hr": "{$competitor1}"
    },
    "366^prematch": {
        "name_en": "0:2 1:2",
        "name_aa": "0:2 1:2",
        "name_de": "0:2 1:2",
        "name_nl": "0:2 1:2",
        "name_pl": "0:2 1:2",
        "name_ro": "0:2 1:2",
        "name_ru": "0:2 1:2",
        "name_tr": "0:2 1:2",
        "name_se": "0:2 1:2",
        "name_el": "0:2 1:2",
        "name_es": "0:2 1:2",
        "name_hr": "0:2 1:2"
    },
    "117^prematch": {
        "name_en": "{$competitor2} by 2",
        "name_aa": "{$competitor2} by 2",
        "name_de": "{$competitor2} by 2",
        "name_nl": "{$competitor2} by 2",
        "name_pl": "{$competitor2} by 2",
        "name_ro": "{$competitor2} by 2",
        "name_ru": "{$competitor2} by 2",
        "name_tr": "{$competitor2} by 2",
        "name_se": "{$competitor2} by 2",
        "name_el": "{$competitor2} by 2",
        "name_es": "{$competitor2} by 2",
        "name_hr": "{$competitor2} by 2"
    },
    "318^prematch": {
        "name_en": "2:4",
        "name_aa": "2:4",
        "name_de": "2:4",
        "name_nl": "2:4",
        "name_pl": "2:4",
        "name_ro": "2:4",
        "name_ru": "2:4",
        "name_tr": "2:4",
        "name_se": "2:4",
        "name_el": "2:4",
        "name_es": "2:4",
        "name_hr": "2:4"
    },
    "1414^prematch": {
        "name_en": "{$competitor1}  12",
        "name_aa": "{$competitor1}  12",
        "name_de": "{$competitor1}  12",
        "name_nl": "{$competitor1}  12",
        "name_pl": "{$competitor1}  12",
        "name_ro": "{$competitor1}  12",
        "name_ru": "{$competitor1}  12",
        "name_tr": "{$competitor1}  12",
        "name_se": "{$competitor1}  12",
        "name_el": "{$competitor1}  12",
        "name_es": "{$competitor1}  12",
        "name_hr": "{$competitor1}  12"
    },
    "1197^prematch": {
        "name_en": "1:2",
        "name_aa": "1:2",
        "name_de": "1:2",
        "name_nl": "1:2",
        "name_pl": "1:2",
        "name_ro": "1:2",
        "name_ru": "1:2",
        "name_tr": "1:2",
        "name_se": "1:2",
        "name_el": "1:2",
        "name_es": "1:2",
        "name_hr": "1:2"
    },
    "434^prematch": {
        "name_en": "{$competitor2}/{$competitor2}",
        "name_aa": "{$competitor2} / {$competitor2}",
        "name_de": "{$competitor2}/{$competitor2}",
        "name_nl": "{$competitor2}/{$competitor2}",
        "name_pl": "{$competitor2}/{$competitor2}",
        "name_ro": "{$competitor2}/{$competitor2}",
        "name_ru": "{$competitor2}/{$competitor2}",
        "name_tr": "{$competitor2}/{$competitor2}",
        "name_se": "{$competitor2}/{$competitor2}",
        "name_el": "{$competitor2}/{$competitor2}",
        "name_es": "{$competitor2}/{$competitor2}",
        "name_hr": "{$competitor2}/{$competitor2}"
    },
    "806^prematch": {
        "name_en": "no/no",
        "name_aa": "لا / لا",
        "name_de": "nein/nein",
        "name_nl": "nee/nee",
        "name_pl": "nie/nie",
        "name_ro": "nu/nu",
        "name_ru": "нет/нет",
        "name_tr": "yok/yok",
        "name_se": "nej/nej",
        "name_el": "όχι/όχι",
        "name_es": "no/no",
        "name_hr": "ne/ne"
    },
    "52^prematch": {
        "name_en": "3",
        "name_aa": "3",
        "name_de": "3",
        "name_nl": "3",
        "name_pl": "3",
        "name_ro": "3",
        "name_ru": "3",
        "name_tr": "3",
        "name_se": "3",
        "name_el": "3",
        "name_es": "3",
        "name_hr": "3"
    },
    "794^prematch": {
        "name_en": "{$competitor1}  under {total}",
        "name_aa": "{$competitor1} و تحت {total}",
        "name_de": "{$competitor1}  unter {total}",
        "name_nl": "{$competitor1}  onder {total}",
        "name_pl": "{$competitor1}  poniżej {total}",
        "name_ro": "{$competitor1}  sub {total}",
        "name_ru": "{$competitor1}  меньше {total}",
        "name_tr": "{$competitor1}  {total} altı",
        "name_se": "{$competitor1}  under {total}",
        "name_el": "{$competitor1} και under  {total}",
        "name_es": "{$competitor1} y menos de {total}",
        "name_hr": "{$competitor1} i manje od {total}"
    },
    "1376^prematch": {
        "name_en": "{$competitor1} by decision",
        "name_aa": "{$competitor1} by decision",
        "name_de": "{$competitor1} by decision",
        "name_nl": "{$competitor1} by decision",
        "name_pl": "{$competitor1} by decision",
        "name_ro": "{$competitor1} by decision",
        "name_ru": "{$competitor1} by decision",
        "name_tr": "{$competitor1} by decision",
        "name_se": "{$competitor1} by decision",
        "name_el": "{$competitor1} by decision",
        "name_es": "{$competitor1} by decision",
        "name_hr": "{$competitor1} by decision"
    },
    "139^prematch": {
        "name_en": "{$competitor1} by 1-5",
        "name_aa": "{$competitor1} by 1-5",
        "name_de": "{$competitor1} by 1-5",
        "name_nl": "{$competitor1} by 1-5",
        "name_pl": "{$competitor1} by 1-5",
        "name_ro": "{$competitor1} by 1-5",
        "name_ru": "{$competitor1} by 1-5",
        "name_tr": "{$competitor1} by 1-5",
        "name_se": "{$competitor1} by 1-5",
        "name_el": "{$competitor1} by 1-5",
        "name_es": "{$competitor1} by 1-5",
        "name_hr": "{$competitor1} by 1-5"
    },
    "1296^prematch": {
        "name_en": "6-10",
        "name_aa": "6-10",
        "name_de": "6-10",
        "name_nl": "6-10",
        "name_pl": "6-10",
        "name_ro": "6-10",
        "name_ru": "6-10",
        "name_tr": "6-10",
        "name_se": "6-10",
        "name_el": "6-10",
        "name_es": "6-10",
        "name_hr": "6-10"
    },
    "181^prematch": {
        "name_en": "6:1",
        "name_aa": "6:1",
        "name_de": "6:1",
        "name_nl": "6:1",
        "name_pl": "6:1",
        "name_ro": "6:1",
        "name_ru": "6:1",
        "name_tr": "6:1",
        "name_se": "6:1",
        "name_el": "6:1",
        "name_es": "6:1",
        "name_hr": "6:1"
    },
    "476^prematch": {
        "name_en": "0:2",
        "name_aa": "0:2",
        "name_de": "0:2",
        "name_nl": "0:2",
        "name_pl": "0:2",
        "name_ro": "0:2",
        "name_ru": "0:2",
        "name_tr": "0:2",
        "name_se": "0:2",
        "name_el": "0:2",
        "name_es": "0:2",
        "name_hr": "0:2"
    },
    "1255^prematch": {
        "name_en": "30-34",
        "name_aa": "30-34",
        "name_de": "30-34",
        "name_nl": "30-34",
        "name_pl": "30-34",
        "name_ro": "30-34",
        "name_ru": "30-34",
        "name_tr": "30-34",
        "name_se": "30-34",
        "name_el": "30-34",
        "name_es": "30-34",
        "name_hr": "30-34"
    },
    "70800000^prematch": {
        "name_en": "X-1",
        "name_aa": "X-1",
        "name_de": "X-1",
        "name_nl": "X-1",
        "name_pl": "X-1",
        "name_ro": "X-1",
        "name_ru": "X-1",
        "name_tr": "X-1",
        "name_se": "X-1",
        "name_el": "X-1",
        "name_es": "X-1",
        "name_hr": "X-1"
    },
    "124^prematch": {
        "name_en": "{$competitor1} by 6-10",
        "name_aa": "{$competitor1} by 6-10",
        "name_de": "{$competitor1} by 6-10",
        "name_nl": "{$competitor1} by 6-10",
        "name_pl": "{$competitor1} by 6-10",
        "name_ro": "{$competitor1} by 6-10",
        "name_ru": "{$competitor1} by 6-10",
        "name_tr": "{$competitor1} by 6-10",
        "name_se": "{$competitor1} by 6-10",
        "name_el": "{$competitor1} by 6-10",
        "name_es": "{$competitor1} by 6-10",
        "name_hr": "{$competitor1} by 6-10"
    },
    "1753^prematch": {
        "name_en": "0:4, 0:5 or 0:6",
        "name_aa": "0:4, 0:5 أو 0:6",
        "name_de": "0:4, 0:5 oder 0:6",
        "name_nl": "0:4, 0:5 of 0:6",
        "name_pl": "0:4, 0:5 lub 0:6",
        "name_ro": "0:4, 0:5 sau 0:6",
        "name_ru": "0:4, 0:5 или 0:6",
        "name_tr": "0:4, 0:5 veya 0:6",
        "name_se": "0:4, 0:5 elr 0:6",
        "name_el": "0-4, 0-5 ή 0-6",
        "name_es": "0:4, 0:5 o 0:6",
        "name_hr": "0:4, 0:5 ili 0:6"
    },
    "1364^prematch": {
        "name_en": "{$competitor1}  3",
        "name_aa": "{$competitor1}  3",
        "name_de": "{$competitor1}  3",
        "name_nl": "{$competitor1}  3",
        "name_pl": "{$competitor1}  3",
        "name_ro": "{$competitor1}  3",
        "name_ru": "{$competitor1}  3",
        "name_tr": "{$competitor1}  3",
        "name_se": "{$competitor1}  3",
        "name_el": "{$competitor1}  3",
        "name_es": "{$competitor1}  3",
        "name_hr": "{$competitor1}  3"
    },
    "788^prematch": {
        "name_en": "only {$competitor1}",
        "name_aa": "فقط {$competitor1}",
        "name_de": "nur {$competitor1}",
        "name_nl": "alleen {$competitor1}",
        "name_pl": "tylko {$competitor1}",
        "name_ro": "doar {$competitor1}",
        "name_ru": "только {$competitor1}",
        "name_tr": "sadece {$competitor1}",
        "name_se": "endast {$competitor1}",
        "name_el": "μόνο {$competitor1}",
        "name_es": "solo {$competitor1}",
        "name_hr": "samo {$competitor1}"
    },
    "1645^prematch": {
        "name_en": "{$competitor2} with drop goal",
        "name_aa": "{$competitor2} مع هدف السقوط",
        "name_de": "{$competitor2} mit Drop goal",
        "name_nl": "{$competitor2} met drop goal",
        "name_pl": "{$competitor2} z dropgolem",
        "name_ro": "{$competitor2} cu drop goal",
        "name_ru": "{$competitor2} с дроп-гола",
        "name_tr": "{$competitor2}şutla",
        "name_se": "{$competitor2} med drop goal",
        "name_el": "{$competitor2} με drop goal",
        "name_es": "{$competitor2} con drop goal",
        "name_hr": "{$competitor2} drop golom"
    },
    "1503^prematch": {
        "name_en": "4:4",
        "name_aa": "4:4",
        "name_de": "4:4",
        "name_nl": "4:4",
        "name_pl": "4:4",
        "name_ro": "4:4",
        "name_ru": "4:4",
        "name_tr": "4:4",
        "name_se": "4:4",
        "name_el": "4:4",
        "name_es": "4:4",
        "name_hr": "4:4"
    },
    "1166^prematch": {
        "name_en": "1:1",
        "name_aa": "1:1",
        "name_de": "1:1",
        "name_nl": "1:1",
        "name_pl": "1:1",
        "name_ro": "1:1",
        "name_ru": "1:1",
        "name_tr": "1:1",
        "name_se": "1:1",
        "name_el": "1:1",
        "name_es": "1:1",
        "name_hr": "1:1"
    },
    "1758^prematch": {
        "name_en": "other homewin",
        "name_aa": "منازل أخرى",
        "name_de": "andere Sieg Heimmannschaft",
        "name_nl": "ander thuisoverwinning",
        "name_pl": "inne zwycięstwo gospodarze",
        "name_ro": "altul victorie gazde",
        "name_ru": "другой, победа хозяев",
        "name_tr": "diğer ev sahibi galibiyeti",
        "name_se": "annan hemmaseger",
        "name_el": "νίκη γηπεδούχου με άλλο σκορ",
        "name_es": "otra victorialocal",
        "name_hr": "ostalo, pobjeda domaćina"
    },
    "1284^prematch": {
        "name_en": "{$competitor1} by 6-10",
        "name_aa": "{$competitor1} by 6-10",
        "name_de": "{$competitor1} by 6-10",
        "name_nl": "{$competitor1} by 6-10",
        "name_pl": "{$competitor1} by 6-10",
        "name_ro": "{$competitor1} by 6-10",
        "name_ru": "{$competitor1} by 6-10",
        "name_tr": "{$competitor1} by 6-10",
        "name_se": "{$competitor1} by 6-10",
        "name_el": "{$competitor1} by 6-10",
        "name_es": "{$competitor1} by 6-10",
        "name_hr": "{$competitor1} by 6-10"
    },
    "334^prematch": {
        "name_en": "0:0 1:0",
        "name_aa": "0:0 1:0",
        "name_de": "0:0 1:0",
        "name_nl": "0:0 1:0",
        "name_pl": "0:0 1:0",
        "name_ro": "0:0 1:0",
        "name_ru": "0:0 1:0",
        "name_tr": "0:0 1:0",
        "name_se": "0:0 1:0",
        "name_el": "0:0 1:0",
        "name_es": "0:0 1:0",
        "name_hr": "0:0 1:0"
    },
    "160^prematch": {
        "name_en": "121-130",
        "name_aa": "121-130",
        "name_de": "121-130",
        "name_nl": "121-130",
        "name_pl": "121-130",
        "name_ro": "121-130",
        "name_ru": "121-130",
        "name_tr": "121-130",
        "name_se": "121-130",
        "name_el": "121-130",
        "name_es": "121-130",
        "name_hr": "121-130"
    },
    "268^prematch": {
        "name_en": "10:1",
        "name_aa": "10:1",
        "name_de": "10:1",
        "name_nl": "10:1",
        "name_pl": "10:1",
        "name_ro": "10:1",
        "name_ru": "10:1",
        "name_tr": "10:1",
        "name_se": "10:1",
        "name_el": "10:1",
        "name_es": "10:1",
        "name_hr": "10:1"
    },
    "1853^prematch": {
        "name_en": "{$competitor2}/{$competitor2}  over {total}",
        "name_aa": "{$competitor2} / {$competitor2} وأكثر من {total}",
        "name_de": "{$competitor2}/{$competitor2}  über {total}",
        "name_nl": "{$competitor2}/{$competitor2}  boven {total}",
        "name_pl": "{$competitor2}/{$competitor2}  ponad {total}",
        "name_ro": "{$competitor2}/{$competitor2}  peste {total}",
        "name_ru": "{$competitor2}/{$competitor2} и больше {total}",
        "name_tr": "{$competitor2}/{$competitor2}  {total} üstü",
        "name_se": "{$competitor2}/{$competitor2}  över {total}",
        "name_el": "{$competitor2}/{$competitor2}  over {total}",
        "name_es": "{$competitor2}/{$competitor2}  más de {total}",
        "name_hr": "{$competitor2}/{$competitor2} i više {total}"
    },
    "140^prematch": {
        "name_en": "{$competitor2} by 1-5",
        "name_aa": "{$competitor2} by 1-5",
        "name_de": "{$competitor2} by 1-5",
        "name_nl": "{$competitor2} by 1-5",
        "name_pl": "{$competitor2} by 1-5",
        "name_ro": "{$competitor2} by 1-5",
        "name_ru": "{$competitor2} by 1-5",
        "name_tr": "{$competitor2} by 1-5",
        "name_se": "{$competitor2} by 1-5",
        "name_el": "{$competitor2} by 1-5",
        "name_es": "{$competitor2} by 1-5",
        "name_hr": "{$competitor2} by 1-5"
    },
    "380^prematch": {
        "name_en": "1:0 2:0",
        "name_aa": "1:0 2:0",
        "name_de": "1:0 2:0",
        "name_nl": "1:0 2:0",
        "name_pl": "1:0 2:0",
        "name_ro": "1:0 2:0",
        "name_ru": "1:0 2:0",
        "name_tr": "1:0 2:0",
        "name_se": "1:0 2:0",
        "name_el": "1:0 2:0",
        "name_es": "1:0 2:0",
        "name_hr": "1:0 2:0"
    },
    "79^prematch": {
        "name_en": "4",
        "name_aa": "4",
        "name_de": "4",
        "name_nl": "4",
        "name_pl": "4",
        "name_ro": "4",
        "name_ru": "4",
        "name_tr": "4",
        "name_se": "4",
        "name_el": "4",
        "name_es": "4",
        "name_hr": "4"
    },
    "1494^prematch": {
        "name_en": "1:3",
        "name_aa": "1:3",
        "name_de": "1:3",
        "name_nl": "1:3",
        "name_pl": "1:3",
        "name_ro": "1:3",
        "name_ru": "1:3",
        "name_tr": "1:3",
        "name_se": "1:3",
        "name_el": "1:3",
        "name_es": "1:3",
        "name_hr": "1:3"
    },
    "1041^prematch": {
        "name_en": "{$competitor1}",
        "name_aa": "{$competitor1}",
        "name_de": "{$competitor1}",
        "name_nl": "{$competitor1}",
        "name_pl": "{$competitor1}",
        "name_ro": "{$competitor1}",
        "name_ru": "{$competitor1}",
        "name_tr": "{$competitor1}",
        "name_se": "{$competitor1}",
        "name_el": "{$competitor1}",
        "name_es": "{$competitor1}",
        "name_hr": "{$competitor1}"
    },
    "1260^prematch": {
        "name_en": "55-59",
        "name_aa": "55-59",
        "name_de": "55-59",
        "name_nl": "55-59",
        "name_pl": "55-59",
        "name_ro": "55-59",
        "name_ru": "55-59",
        "name_tr": "55-59",
        "name_se": "55-59",
        "name_el": "55-59",
        "name_es": "55-59",
        "name_hr": "55-59"
    },
    "324^prematch": {
        "name_en": "other",
        "name_aa": "آخر",
        "name_de": "andere",
        "name_nl": "ander",
        "name_pl": "inny",
        "name_ro": "altul",
        "name_ru": "другой",
        "name_tr": "diğer",
        "name_se": "annan",
        "name_el": "άλλο",
        "name_es": "otro",
        "name_hr": "ostalo"
    },
    "1721^prematch": {
        "name_en": "{$competitor1}/{$competitor2}  no",
        "name_aa": "{$competitor1} / {$competitor2} ولا",
        "name_de": "{$competitor1}/{$competitor2}  nein",
        "name_nl": "{$competitor1}/{$competitor2}  nee",
        "name_pl": "{$competitor1}/{$competitor2}  nie",
        "name_ro": "{$competitor1}/{$competitor2}  nu",
        "name_ru": "{$competitor1}/{$competitor2} и нет",
        "name_tr": "{$competitor1}/{$competitor2}  yok",
        "name_se": "{$competitor1}/{$competitor2}  nej",
        "name_el": "{$competitor1}/{$competitor2} και όχι",
        "name_es": "{$competitor1}/{$competitor2} y no",
        "name_hr": "{$competitor1}/{$competitor2}  i ne"
    },
    "1366^prematch": {
        "name_en": "{$competitor1}  5",
        "name_aa": "{$competitor1}  5",
        "name_de": "{$competitor1}  5",
        "name_nl": "{$competitor1}  5",
        "name_pl": "{$competitor1}  5",
        "name_ro": "{$competitor1}  5",
        "name_ru": "{$competitor1}  5",
        "name_tr": "{$competitor1}  5",
        "name_se": "{$competitor1}  5",
        "name_el": "{$competitor1}  5",
        "name_es": "{$competitor1}  5",
        "name_hr": "{$competitor1}  5"
    },
    "199^prematch": {
        "name_en": "5:7",
        "name_aa": "5:7",
        "name_de": "5:7",
        "name_nl": "5:7",
        "name_pl": "5:7",
        "name_ro": "5:7",
        "name_ru": "5:7",
        "name_tr": "5:7",
        "name_se": "5:7",
        "name_el": "5:7",
        "name_es": "5:7",
        "name_hr": "5:7"
    },
    "114^prematch": {
        "name_en": "{$competitor1} by 2",
        "name_aa": "{$competitor1} by 2",
        "name_de": "{$competitor1} by 2",
        "name_nl": "{$competitor1} by 2",
        "name_pl": "{$competitor1} by 2",
        "name_ro": "{$competitor1} by 2",
        "name_ru": "{$competitor1} by 2",
        "name_tr": "{$competitor1} by 2",
        "name_se": "{$competitor1} by 2",
        "name_el": "{$competitor1} by 2",
        "name_es": "{$competitor1} by 2",
        "name_hr": "{$competitor1} by 2"
    },
    "1805^prematch": {
        "name_en": "no goal",
        "name_aa": "لا هدف",
        "name_de": "kein Tor",
        "name_nl": "geen doelpunt",
        "name_pl": "bez gola",
        "name_ro": "niciun gol",
        "name_ru": "гола не будет",
        "name_tr": "gol yok",
        "name_se": "inga mål",
        "name_el": "κανένα γκολ",
        "name_es": "sin gol",
        "name_hr": "nema gola"
    },
    "83^prematch": {
        "name_en": "8",
        "name_aa": "8",
        "name_de": "8",
        "name_nl": "8",
        "name_pl": "8",
        "name_ro": "8",
        "name_ru": "8",
        "name_tr": "8",
        "name_se": "8",
        "name_el": "8",
        "name_es": "8",
        "name_hr": "8"
    },
    "1825^prematch": {
        "name_en": "any extra inning",
        "name_aa": "أي دور إضافي",
        "name_de": "jedes zusätzliches Inning",
        "name_nl": "extra inning(s)",
        "name_pl": "Jakikolwiek extra inning",
        "name_ro": "oricare extra inning",
        "name_ru": "в любой из дополнительных иннингов",
        "name_tr": "uzatma devresinde",
        "name_se": "några extra innings",
        "name_el": "any extra inning",
        "name_es": "cualquier extra inning",
        "name_hr": "bilo koji dodatni inning"
    },
    "298^prematch": {
        "name_en": "10:11",
        "name_aa": "10:11",
        "name_de": "10:11",
        "name_nl": "10:11",
        "name_pl": "10:11",
        "name_ro": "10:11",
        "name_ru": "10:11",
        "name_tr": "10:11",
        "name_se": "10:11",
        "name_el": "10:11",
        "name_es": "10:11",
        "name_hr": "10:11"
    },
    "1623^prematch": {
        "name_en": "3rd period",
        "name_aa": "الفترة الثالثة",
        "name_de": "3. Periode",
        "name_nl": "3e periode",
        "name_pl": "3. Część gry",
        "name_ro": "repriza 3",
        "name_ru": "3ий период",
        "name_tr": "Üçüncü periyot",
        "name_se": "3:e period",
        "name_el": "3η περίοδος",
        "name_es": "3º periodo",
        "name_hr": "3. period"
    },
    "1887^prematch": {
        "name_en": "{$competitor2}/{$competitor1}  5+",
        "name_aa": "{$competitor2} / {$competitor1}و 5+",
        "name_de": "{$competitor2}/{$competitor1}  5+",
        "name_nl": "{$competitor2}/{$competitor1}  5+",
        "name_pl": "{$competitor2}/{$competitor1}  5+",
        "name_ro": "{$competitor2}/{$competitor1}  5+",
        "name_ru": "{$competitor2}/{$competitor1} и 5+",
        "name_tr": "{$competitor2}/{$competitor1}  5+",
        "name_se": "{$competitor2}/{$competitor1}  5+",
        "name_el": "{$competitor2}/{$competitor1}  5+",
        "name_es": "{$competitor2}/{$competitor1}  5+",
        "name_hr": "{$competitor2}/{$competitor1} i 5+"
    },
    "796^prematch": {
        "name_en": "{$competitor1}  over {total}",
        "name_aa": "{$competitor1} وأكثر من {total}",
        "name_de": "{$competitor1}  über {total}",
        "name_nl": "{$competitor1}  boven {total}",
        "name_pl": "{$competitor1}  ponad {total}",
        "name_ro": "{$competitor1}  peste {total}",
        "name_ru": "{$competitor1}  больше {total}",
        "name_tr": "{$competitor1}  {total} üstü",
        "name_se": "{$competitor1}  över {total}",
        "name_el": "{$competitor1} και over  {total}",
        "name_es": "{$competitor1} y más de {total}",
        "name_hr": "{$competitor1} i više od {total}"
    },
    "1086^prematch": {
        "name_en": "0-20",
        "name_aa": "0-20",
        "name_de": "0-20",
        "name_nl": "0-20",
        "name_pl": "0-20",
        "name_ro": "0-20",
        "name_ru": "0-20",
        "name_tr": "0-20",
        "name_se": "0-20",
        "name_el": "0-20",
        "name_es": "0-20",
        "name_hr": "0-20"
    },
    "818^prematch": {
        "name_en": "{$competitor1} goal  {$competitor2}",
        "name_aa": "{$competitor1} هدف و{$competitor2}",
        "name_de": "{$competitor1} Tor  {$competitor2}",
        "name_nl": "doelpunt {$competitor1}  {$competitor2}",
        "name_pl": "{$competitor1} Gol  {$competitor2}",
        "name_ro": "gol {$competitor1}  {$competitor2}",
        "name_ru": "гол {$competitor1}  {$competitor2}",
        "name_tr": "{$competitor1} golü  {$competitor2} kazanır",
        "name_se": "{$competitor1} mål  {$competitor2}",
        "name_el": "γκολ {$competitor1} και {$competitor2}",
        "name_es": "{$competitor1} gol y {$competitor2}",
        "name_hr": "{$competitor1} gol i {$competitor2} pobjeda"
    },
    "606^prematch": {
        "name_en": "41-50",
        "name_aa": "41-50",
        "name_de": "41-50",
        "name_nl": "41-50",
        "name_pl": "41-50",
        "name_ro": "41-50",
        "name_ru": "41-50",
        "name_tr": "41-50",
        "name_se": "41-50",
        "name_el": "41-50",
        "name_es": "41-50",
        "name_hr": "41-50"
    },
    "1840^prematch": {
        "name_en": "draw/draw  under {total}",
        "name_aa": "رسم / رسم و تحت{total}",
        "name_de": "unentschieden/unentschieden  unter {total}",
        "name_nl": "gelijkspel/gelijkspel  onder {total}",
        "name_pl": "remis/remis  poniżej {total}",
        "name_ro": "egal/egal  sub {total}",
        "name_ru": "ничья/ничья или меньше {total}",
        "name_tr": "beraberlik/beraberlik  {total} altı",
        "name_se": "oavgjort/oavgjort  under {total}",
        "name_el": "ισοπαλία/ισοπαλία  under {total}",
        "name_es": "empate/empate  menos de {total}",
        "name_hr": "neodlučeno/neodlučeno i manje {total}"
    },
    "1295^prematch": {
        "name_en": "1-5",
        "name_aa": "1-5",
        "name_de": "1-5",
        "name_nl": "1-5",
        "name_pl": "1-5",
        "name_ro": "1-5",
        "name_ru": "1-5",
        "name_tr": "1-5",
        "name_se": "1-5",
        "name_el": "1-5",
        "name_es": "1-5",
        "name_hr": "1-5"
    },
    "1889^prematch": {
        "name_en": "{$competitor2}/{$competitor2}  5+",
        "name_aa": "{$competitor2} / {$competitor2}و 5+",
        "name_de": "{$competitor2}/{$competitor2}  5+",
        "name_nl": "{$competitor2}/{$competitor2}  5+",
        "name_pl": "{$competitor2}/{$competitor2}  5+",
        "name_ro": "{$competitor2}/{$competitor2}  5+",
        "name_ru": "{$competitor2}/{$competitor2} и 5+",
        "name_tr": "{$competitor2}/{$competitor2}  5+",
        "name_se": "{$competitor2}/{$competitor2}  5+",
        "name_el": "{$competitor2}/{$competitor2}  5+",
        "name_es": "{$competitor2}/{$competitor2}  5+",
        "name_hr": "{$competitor2}/{$competitor2} i 5+"
    },
    "193^prematch": {
        "name_en": "7:1",
        "name_aa": "7:1",
        "name_de": "7:1",
        "name_nl": "7:1",
        "name_pl": "7:1",
        "name_ro": "7:1",
        "name_ru": "7:1",
        "name_tr": "7:1",
        "name_se": "7:1",
        "name_el": "7:1",
        "name_es": "7:1",
        "name_hr": "7:1"
    },
    "1407^prematch": {
        "name_en": "{$competitor1}  5",
        "name_aa": "{$competitor1}  5",
        "name_de": "{$competitor1}  5",
        "name_nl": "{$competitor1}  5",
        "name_pl": "{$competitor1}  5",
        "name_ro": "{$competitor1}  5",
        "name_ru": "{$competitor1}  5",
        "name_tr": "{$competitor1}  5",
        "name_se": "{$competitor1}  5",
        "name_el": "{$competitor1}  5",
        "name_es": "{$competitor1}  5",
        "name_hr": "{$competitor1}  5"
    },
    "96^prematch": {
        "name_en": "under {total}  no",
        "name_aa": "تحت {total} و لا",
        "name_de": "unter {total}  nein",
        "name_nl": "onder {total}  nee",
        "name_pl": "poniżej {total}  nie",
        "name_ro": "sub {total}  nu",
        "name_ru": "меньше {total}  нет",
        "name_tr": "{total} altı  yok",
        "name_se": "under {total}  nej",
        "name_el": "όχι και under {total}",
        "name_es": "menos de {total} y no",
        "name_hr": "manje od {total} i ne"
    },
    "19^prematch": {
        "name_en": "2:4",
        "name_aa": "2:4",
        "name_de": "2:4",
        "name_nl": "2:4",
        "name_pl": "2:4",
        "name_ro": "2:4",
        "name_ru": "2:4",
        "name_tr": "2:4",
        "name_se": "2:4",
        "name_el": "2:4",
        "name_es": "2:4",
        "name_hr": "2:4"
    },
    "60050000^prematch": {
        "name_en": "5+/5+",
        "name_aa": "5+/5+",
        "name_de": "5+/5+",
        "name_nl": "5+/5+",
        "name_pl": "5+/5+",
        "name_ro": "5+/5+",
        "name_ru": "5+/5+",
        "name_tr": "5+/5+",
        "name_se": "5+/5+",
        "name_el": "5+/5+",
        "name_es": "5+/5+",
        "name_hr": "5+/5+"
    },
    "306^prematch": {
        "name_en": "2:11",
        "name_aa": "2:11",
        "name_de": "2:11",
        "name_nl": "2:11",
        "name_pl": "2:11",
        "name_ro": "2:11",
        "name_ru": "2:11",
        "name_tr": "2:11",
        "name_se": "2:11",
        "name_el": "2:11",
        "name_es": "2:11",
        "name_hr": "2:11"
    },
    "867^prematch": {
        "name_en": "6:2",
        "name_aa": "6:2",
        "name_de": "6:2",
        "name_nl": "6:2",
        "name_pl": "6:2",
        "name_ro": "6:2",
        "name_ru": "6:2",
        "name_tr": "6:2",
        "name_se": "6:2",
        "name_el": "6:2",
        "name_es": "6:2",
        "name_hr": "6:2"
    },
    "88^prematch": {
        "name_en": "{$competitor2}  no",
        "name_aa": "{$competitor2} ولا",
        "name_de": "{$competitor2}  nein",
        "name_nl": "{$competitor2}  nee",
        "name_pl": "{$competitor2}  nie",
        "name_ro": "{$competitor2}  nu",
        "name_ru": "{$competitor2}  нет",
        "name_tr": "{$competitor2}  yok",
        "name_se": "{$competitor2}  nej",
        "name_el": "όχι και {$competitor2}",
        "name_es": "{$competitor2} y no",
        "name_hr": "{$competitor2} i ne"
    },
    "546^prematch": {
        "name_en": "0:0",
        "name_aa": "0:0",
        "name_de": "0:0",
        "name_nl": "0:0",
        "name_pl": "0:0",
        "name_ro": "0:0",
        "name_ru": "0:0",
        "name_tr": "0:0",
        "name_se": "0:0",
        "name_el": "0:0",
        "name_es": "0:0",
        "name_hr": "0:0"
    },
    "600^prematch": {
        "name_en": "11-20",
        "name_aa": "11-20",
        "name_de": "11-20",
        "name_nl": "11-20",
        "name_pl": "11-20",
        "name_ro": "11-20",
        "name_ru": "11-20",
        "name_tr": "11-20",
        "name_se": "11-20",
        "name_el": "11-20",
        "name_es": "11-20",
        "name_hr": "11-20"
    },
    "1090^prematch": {
        "name_en": "36-40",
        "name_aa": "36-40",
        "name_de": "36-40",
        "name_nl": "36-40",
        "name_pl": "36-40",
        "name_ro": "36-40",
        "name_ru": "36-40",
        "name_tr": "36-40",
        "name_se": "36-40",
        "name_el": "36-40",
        "name_es": "36-40",
        "name_hr": "36-40"
    },
    "726^prematch": {
        "name_en": "11",
        "name_aa": "11",
        "name_de": "11",
        "name_nl": "11",
        "name_pl": "11",
        "name_ro": "11",
        "name_ru": "11",
        "name_tr": "11",
        "name_se": "11",
        "name_el": "11",
        "name_es": "11",
        "name_hr": "11"
    },
    "1866^prematch": {
        "name_en": "{$competitor1}/{$competitor1}  3",
        "name_aa": "{$competitor1} / {$competitor1}و 3",
        "name_de": "{$competitor1}/{$competitor1}  3",
        "name_nl": "{$competitor1}/{$competitor1}  3",
        "name_pl": "{$competitor1}/{$competitor1}  3",
        "name_ro": "{$competitor1}/{$competitor1}  3",
        "name_ru": "{$competitor1}/{$competitor1} и 3",
        "name_tr": "{$competitor1}/{$competitor1}  3",
        "name_se": "{$competitor1}/{$competitor1}  3",
        "name_el": "{$competitor1}/{$competitor1}  3",
        "name_es": "{$competitor1}/{$competitor1}  3",
        "name_hr": "{$competitor1}/{$competitor1} i 3"
    },
    "1848^prematch": {
        "name_en": "draw/{$competitor1}  over {total}",
        "name_aa": "رسم / {$competitor1} و أكثر من{total}",
        "name_de": "unentschieden/{$competitor1}  über {total}",
        "name_nl": "gelijkspel/{$competitor1}  boven {total}",
        "name_pl": "remis/{$competitor1}  ponad {total}",
        "name_ro": "egal/{$competitor1}  peste {total}",
        "name_ru": "ничья/{$competitor1} и больше {total}",
        "name_tr": "beraberlik/{$competitor1}  {total} üstü",
        "name_se": "oavgjort/{$competitor1}  över {total}",
        "name_el": "ισοπαλία /{$competitor1}  over {total}",
        "name_es": "empate /{$competitor1}  más de {total}",
        "name_hr": "neodlučeno/{$competitor1} i više {total}"
    },
    "1043^prematch": {
        "name_en": "draw",
        "name_aa": "رسم",
        "name_de": "unentschieden",
        "name_nl": "gelijkspel",
        "name_pl": "remis",
        "name_ro": "egalitate",
        "name_ru": "ничья",
        "name_tr": "beraberlik",
        "name_se": "oavgjort",
        "name_el": "ισοπαλία",
        "name_es": "empate",
        "name_hr": "neodlučeno"
    },
    "1745^prematch": {
        "name_en": "7+",
        "name_aa": "7+",
        "name_de": "7+",
        "name_nl": "7+",
        "name_pl": "7+",
        "name_ro": "7+",
        "name_ru": "7+",
        "name_tr": "7+",
        "name_se": "7+",
        "name_el": "7+",
        "name_es": "7+",
        "name_hr": "7+"
    },
    "270^prematch": {
        "name_en": "10:3",
        "name_aa": "10:3",
        "name_de": "10:3",
        "name_nl": "10:3",
        "name_pl": "10:3",
        "name_ro": "10:3",
        "name_ru": "10:3",
        "name_tr": "10:3",
        "name_se": "10:3",
        "name_el": "10:3",
        "name_es": "10:3",
        "name_hr": "10:3"
    },
    "1149^prematch": {
        "name_en": "5-6",
        "name_aa": "5-6",
        "name_de": "5-6",
        "name_nl": "5-6",
        "name_pl": "5-6",
        "name_ro": "5-6",
        "name_ru": "5-6",
        "name_tr": "5-6",
        "name_se": "5-6",
        "name_el": "5-6",
        "name_es": "5-6",
        "name_hr": "5-6"
    },
    "262^prematch": {
        "name_en": "4:9",
        "name_aa": "4:9",
        "name_de": "4:9",
        "name_nl": "4:9",
        "name_pl": "4:9",
        "name_ro": "4:9",
        "name_ru": "4:9",
        "name_tr": "4:9",
        "name_se": "4:9",
        "name_el": "4:9",
        "name_es": "4:9",
        "name_hr": "4:9"
    },
    "1836^prematch": {
        "name_en": "{$competitor1}/{$competitor1}  under {total}",
        "name_aa": "{$competitor1} / {$competitor1} و تحت{total}",
        "name_de": "{$competitor1}/{$competitor1}  unter {total}",
        "name_nl": "{$competitor1}/{$competitor1}  onder {total}",
        "name_pl": "{$competitor1}/{$competitor1}  poniżej {total}",
        "name_ro": "{$competitor1}/{$competitor1}  sub {total}",
        "name_ru": "{$competitor1}/{$competitor1} или меньше {total}",
        "name_tr": "{$competitor1}/{$competitor1}  {total} altı",
        "name_se": "{$competitor1}/{$competitor1}  under {total}",
        "name_el": "{$competitor1}/{$competitor1}  under {total}",
        "name_es": "{$competitor1}/{$competitor1}  menos de {total}",
        "name_hr": "{$competitor1}/{$competitor1} i manje {total}"
    },
    "196^prematch": {
        "name_en": "7:4",
        "name_aa": "7:4",
        "name_de": "7:4",
        "name_nl": "7:4",
        "name_pl": "7:4",
        "name_ro": "7:4",
        "name_ru": "7:4",
        "name_tr": "7:4",
        "name_se": "7:4",
        "name_el": "7:4",
        "name_es": "7:4",
        "name_hr": "7:4"
    },
    "1744^prematch": {
        "name_en": "5-6",
        "name_aa": "5-6",
        "name_de": "5-6",
        "name_nl": "5-6",
        "name_pl": "5-6",
        "name_ro": "5-6",
        "name_ru": "5-6",
        "name_tr": "5-6",
        "name_se": "5-6",
        "name_el": "5-6",
        "name_es": "5-6",
        "name_hr": "5-6"
    },
    "1180^prematch": {
        "name_en": "3:2",
        "name_aa": "3:2",
        "name_de": "3:2",
        "name_nl": "3:2",
        "name_pl": "3:2",
        "name_ro": "3:2",
        "name_ru": "3:2",
        "name_tr": "3:2",
        "name_se": "3:2",
        "name_el": "3:2",
        "name_es": "3:2",
        "name_hr": "3:2"
    },
    "280^prematch": {
        "name_en": "6:10",
        "name_aa": "6:10",
        "name_de": "6:10",
        "name_nl": "6:10",
        "name_pl": "6:10",
        "name_ro": "6:10",
        "name_ru": "6:10",
        "name_tr": "6:10",
        "name_se": "6:10",
        "name_el": "6:10",
        "name_es": "6:10",
        "name_hr": "6:10"
    },
    "1838^prematch": {
        "name_en": "{$competitor1}/{$competitor2}  under {total}",
        "name_aa": "{$competitor1} / {$competitor2} و تحت{total}",
        "name_de": "{$competitor1}/{$competitor2}  unter {total}",
        "name_nl": "{$competitor1}/{$competitor2}  onder {total}",
        "name_pl": "{$competitor1}/{$competitor2}  poniżej {total}",
        "name_ro": "{$competitor1}/{$competitor2}  sub {total}",
        "name_ru": "{$competitor1}/{$competitor2} или меньше {total}",
        "name_tr": "{$competitor1}/{$competitor2}  {total} altı",
        "name_se": "{$competitor1}/{$competitor2}  under {total}",
        "name_el": "{$competitor1}/{$competitor2}  under {total}",
        "name_es": "{$competitor1}/{$competitor2}  menos de {total}",
        "name_hr": "{$competitor1}/{$competitor2} i manje {total}"
    },
    "342^prematch": {
        "name_en": "0:0 2:1",
        "name_aa": "0:0 2:1",
        "name_de": "0:0 2:1",
        "name_nl": "0:0 2:1",
        "name_pl": "0:0 2:1",
        "name_ro": "0:0 2:1",
        "name_ru": "0:0 2:1",
        "name_tr": "0:0 2:1",
        "name_se": "0:0 2:1",
        "name_el": "0:0 2:1",
        "name_es": "0:0 2:1",
        "name_hr": "0:0 2:1"
    },
    "32^prematch": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2"
    },
    "78^prematch": {
        "name_en": "{$competitor1}  yes",
        "name_aa": "{$competitor1} ونعم",
        "name_de": "{$competitor1}  ja",
        "name_nl": "{$competitor1}  ja",
        "name_pl": "{$competitor1}  tak",
        "name_ro": "{$competitor1}  da",
        "name_ru": "{$competitor1}  да",
        "name_tr": "{$competitor1}  var",
        "name_se": "{$competitor1}  ja",
        "name_el": "ναι και {$competitor1}",
        "name_es": "{$competitor1} y sí",
        "name_hr": "{$competitor1} i da"
    },
    "1713^prematch": {
        "name_en": "{$competitor2} ({hcp})",
        "name_aa": "{$competitor2} ({hcp})",
        "name_de": "{$competitor2} ({hcp})",
        "name_nl": "{$competitor2} ({hcp})",
        "name_pl": "{$competitor2} ({hcp})",
        "name_ro": "{$competitor2} ({hcp})",
        "name_ru": "{$competitor2} ({hcp})",
        "name_tr": "{$competitor2} ({hcp})",
        "name_se": "{$competitor2} ({hcp})",
        "name_el": "({hcp}) {$competitor2}",
        "name_es": "{$competitor2} ({hcp})",
        "name_hr": "{$competitor2} ({hcp})"
    },
    "1379^prematch": {
        "name_en": "{$competitor2} by decision",
        "name_aa": "{$competitor2} by decision",
        "name_de": "{$competitor2} by decision",
        "name_nl": "{$competitor2} by decision",
        "name_pl": "{$competitor2} by decision",
        "name_ro": "{$competitor2} by decision",
        "name_ru": "{$competitor2} by decision",
        "name_tr": "{$competitor2} by decision",
        "name_se": "{$competitor2} by decision",
        "name_el": "{$competitor2} by decision",
        "name_es": "{$competitor2} by decision",
        "name_hr": "{$competitor2} by decision"
    },
    "122^prematch": {
        "name_en": "other",
        "name_aa": "other",
        "name_de": "other",
        "name_nl": "other",
        "name_pl": "other",
        "name_ro": "other",
        "name_ru": "other",
        "name_tr": "other",
        "name_se": "other",
        "name_el": "other",
        "name_es": "other",
        "name_hr": "other"
    },
    "1847^prematch": {
        "name_en": "{$competitor1}/{$competitor2}  over {total}",
        "name_aa": "{$competitor1} / {$competitor2} وأكثر من {total}",
        "name_de": "{$competitor1}/{$competitor2}  über {total}",
        "name_nl": "{$competitor1}/{$competitor2}  boven {total}",
        "name_pl": "{$competitor1}/{$competitor2}  ponad {total}",
        "name_ro": "{$competitor1}/{$competitor2}  peste {total}",
        "name_ru": "{$competitor1}/{$competitor2} и больше {total}",
        "name_tr": "{$competitor1}/{$competitor2}  {total} üstü",
        "name_se": "{$competitor1}/{$competitor2}  över {total}",
        "name_el": "{$competitor1}/{$competitor2}  over {total}",
        "name_es": "{$competitor1}/{$competitor2}  más de {total}",
        "name_hr": "{$competitor1}/{$competitor2} i više {total}"
    },
    "1734^prematch": {
        "name_en": "1-6",
        "name_aa": "1-6",
        "name_de": "1-6",
        "name_nl": "1-6",
        "name_pl": "1-6",
        "name_ro": "1-6",
        "name_ru": "1-6",
        "name_tr": "1-6",
        "name_se": "1-6",
        "name_el": "1-6",
        "name_es": "1-6",
        "name_hr": "1-6"
    },
    "948^prematch": {
        "name_en": "{$competitor1}",
        "name_aa": "{$competitor1}",
        "name_de": "{$competitor1}",
        "name_nl": "{$competitor1}",
        "name_pl": "{$competitor1}",
        "name_ro": "{$competitor1}",
        "name_ru": "{$competitor1}",
        "name_tr": "{$competitor1}",
        "name_se": "{$competitor1}",
        "name_el": "{$competitor1}",
        "name_es": "{$competitor1}",
        "name_hr": "{$competitor1}"
    },
    "368^prematch": {
        "name_en": "0:2 4+",
        "name_aa": "0:2 4+",
        "name_de": "0:2 4+",
        "name_nl": "0:2 4+",
        "name_pl": "0:2 4+",
        "name_ro": "0:2 4+",
        "name_ru": "0:2 4+",
        "name_tr": "0:2 4+",
        "name_se": "0:2 4+",
        "name_el": "0:2 4+",
        "name_es": "0:2 4+",
        "name_hr": "0:2 4+"
    },
    "638^prematch": {
        "name_en": "{$competitor2} by 2",
        "name_aa": "{$competitor2} في 2",
        "name_de": "{$competitor2} mit 2",
        "name_nl": "{$competitor2} met 2",
        "name_pl": "{$competitor2} 2-a",
        "name_ro": "{$competitor2} cu 2",
        "name_ru": "{$competitor2} на 2",
        "name_tr": "{$competitor2} 2 farkla",
        "name_se": "{$competitor2} med 2",
        "name_el": "{$competitor2} με 2",
        "name_es": "{$competitor2} por 2",
        "name_hr": "{$competitor2} za 2"
    },
    "1338^prematch": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2"
    },
    "182^prematch": {
        "name_en": "6:2",
        "name_aa": "6:2",
        "name_de": "6:2",
        "name_nl": "6:2",
        "name_pl": "6:2",
        "name_ro": "6:2",
        "name_ru": "6:2",
        "name_tr": "6:2",
        "name_se": "6:2",
        "name_el": "6:2",
        "name_es": "6:2",
        "name_hr": "6:2"
    },
    "1246^prematch": {
        "name_en": "65-69",
        "name_aa": "65-69",
        "name_de": "65-69",
        "name_nl": "65-69",
        "name_pl": "65-69",
        "name_ro": "65-69",
        "name_ru": "65-69",
        "name_tr": "65-69",
        "name_se": "65-69",
        "name_el": "65-69",
        "name_es": "65-69",
        "name_hr": "65-69"
    },
    "37^prematch": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2"
    },
    "71000000^prematch": {
        "name_en": "{$competitor2}",
        "name_aa": "{$competitor2}",
        "name_de": "{$competitor2}",
        "name_nl": "{$competitor2}",
        "name_pl": "{$competitor2}",
        "name_ro": "{$competitor2}",
        "name_ru": "{$competitor2}",
        "name_tr": "{$competitor2}",
        "name_se": "{$competitor2}",
        "name_el": "{$competitor2}",
        "name_es": "{$competitor2}",
        "name_hr": "{$competitor2}"
    },
    "144^prematch": {
        "name_en": "3:2",
        "name_aa": "3:2",
        "name_de": "3:2",
        "name_nl": "3:2",
        "name_pl": "3:2",
        "name_ro": "3:2",
        "name_ru": "3:2",
        "name_tr": "3:2",
        "name_se": "3:2",
        "name_el": "3:2",
        "name_es": "3:2",
        "name_hr": "3:2"
    },
    "77^prematch": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2"
    },
    "1723^prematch": {
        "name_en": "draw/{$competitor2}  no",
        "name_aa": "رسم / {$competitor2} ولا",
        "name_de": "unentschieden/{$competitor2}  nein",
        "name_nl": "gelijkspel/{$competitor2}  nee",
        "name_pl": "remis/{$competitor2}  nie",
        "name_ro": "egal/{$competitor2}  nu",
        "name_ru": "ничья/{$competitor2} и нет",
        "name_tr": "beraberlik/{$competitor2}  yok",
        "name_se": "oavgjort/{$competitor2}  nej",
        "name_el": "ισοπαλία/{$competitor2} και όχι",
        "name_es": "empate/{$competitor2} y no",
        "name_hr": "neodlučeno/{$competitor2} i ne"
    },
    "116^prematch": {
        "name_en": "{$competitor2} by 1",
        "name_aa": "{$competitor2} by 1",
        "name_de": "{$competitor2} by 1",
        "name_nl": "{$competitor2} by 1",
        "name_pl": "{$competitor2} by 1",
        "name_ro": "{$competitor2} by 1",
        "name_ru": "{$competitor2} by 1",
        "name_tr": "{$competitor2} by 1",
        "name_se": "{$competitor2} by 1",
        "name_el": "{$competitor2} by 1",
        "name_es": "{$competitor2} by 1",
        "name_hr": "{$competitor2} by 1"
    },
    "2036^prematch": {
        "name_en": "{$competitor1} ({+hcp})  under {total}",
        "name_aa": "{$competitor1} ({+hcp})  under {total}",
        "name_de": "{$competitor1} ({+hcp})  under {total}",
        "name_nl": "{$competitor1} ({+hcp})  under {total}",
        "name_pl": "{$competitor1} ({+hcp})  under {total}",
        "name_ro": "{$competitor1} ({+hcp})  under {total}",
        "name_ru": "{$competitor1} ({+hcp})  under {total}",
        "name_tr": "{$competitor1} ({+hcp})  under {total}",
        "name_se": "{$competitor1} ({+hcp})  under {total}",
        "name_el": "{$competitor1} ({+hcp})  under {total}",
        "name_es": "{$competitor1} ({+hcp})  under {total}",
        "name_hr": "{$competitor1} ({+hcp})  under {total}"
    },
    "1650000^prematch": {
        "name_en": "{$competitor1}",
        "name_aa": "{$competitor1}",
        "name_de": "{$competitor1}",
        "name_nl": "{$competitor1}",
        "name_pl": "{$competitor1}",
        "name_ro": "{$competitor1}",
        "name_ru": "{$competitor1}",
        "name_tr": "{$competitor1}",
        "name_se": "{$competitor1}",
        "name_el": "{$competitor1}",
        "name_es": "{$competitor1}",
        "name_hr": "{$competitor1}"
    },
    "378^prematch": {
        "name_en": "1:0 1:2",
        "name_aa": "1:0 1:2",
        "name_de": "1:0 1:2",
        "name_nl": "1:0 1:2",
        "name_pl": "1:0 1:2",
        "name_ro": "1:0 1:2",
        "name_ru": "1:0 1:2",
        "name_tr": "1:0 1:2",
        "name_se": "1:0 1:2",
        "name_el": "1:0 1:2",
        "name_es": "1:0 1:2",
        "name_hr": "1:0 1:2"
    },
    "1074^prematch": {
        "name_en": "140-149",
        "name_aa": "140-149",
        "name_de": "140-149",
        "name_nl": "140-149",
        "name_pl": "140-149",
        "name_ro": "140-149",
        "name_ru": "140-149",
        "name_tr": "140-149",
        "name_se": "140-149",
        "name_el": "140-149",
        "name_es": "140-149",
        "name_hr": "140-149"
    },
    "264^prematch": {
        "name_en": "2:9",
        "name_aa": "2:9",
        "name_de": "2:9",
        "name_nl": "2:9",
        "name_pl": "2:9",
        "name_ro": "2:9",
        "name_ru": "2:9",
        "name_tr": "2:9",
        "name_se": "2:9",
        "name_el": "2:9",
        "name_es": "2:9",
        "name_hr": "2:9"
    },
    "556^prematch": {
        "name_en": "1:2",
        "name_aa": "1:2",
        "name_de": "1:2",
        "name_nl": "1:2",
        "name_pl": "1:2",
        "name_ro": "1:2",
        "name_ru": "1:2",
        "name_tr": "1:2",
        "name_se": "1:2",
        "name_el": "1:2",
        "name_es": "1:2",
        "name_hr": "1:2"
    },
    "300^prematch": {
        "name_en": "8:11",
        "name_aa": "8:11",
        "name_de": "8:11",
        "name_nl": "8:11",
        "name_pl": "8:11",
        "name_ro": "8:11",
        "name_ru": "8:11",
        "name_tr": "8:11",
        "name_se": "8:11",
        "name_el": "8:11",
        "name_es": "8:11",
        "name_hr": "8:11"
    },
    "1195^prematch": {
        "name_en": "2:1",
        "name_aa": "2:1",
        "name_de": "2:1",
        "name_nl": "2:1",
        "name_pl": "2:1",
        "name_ro": "2:1",
        "name_ru": "2:1",
        "name_tr": "2:1",
        "name_se": "2:1",
        "name_el": "2:1",
        "name_es": "2:1",
        "name_hr": "2:1"
    },
    "1727^prematch": {
        "name_en": "{$competitor1}/draw  over {total}",
        "name_aa": "{$competitor1} / رسم وأكثر من {total}",
        "name_de": "{$competitor1}/unentschieden  über {total}",
        "name_nl": "{$competitor1}/gelijkspel  boven {total}",
        "name_pl": "{$competitor1}/remis  ponad {total}",
        "name_ro": "{$competitor1}/egal  peste {total}",
        "name_ru": "{$competitor1}/ничья и больше {total}",
        "name_tr": "{$competitor1}/beraberlik  {total} üstü",
        "name_se": "{$competitor1}/oavgjort  över {total}",
        "name_el": "{$competitor1}/ ισοπαλία και over {total}",
        "name_es": "{$competitor1}/empate y más de {total}",
        "name_hr": "{$competitor1}/neodlučeno i manje {total}"
    },
    "60040000^prematch": {
        "name_en": "4+/7+",
        "name_aa": "4+/7+",
        "name_de": "4+/7+",
        "name_nl": "4+/7+",
        "name_pl": "4+/7+",
        "name_ro": "4+/7+",
        "name_ru": "4+/7+",
        "name_tr": "4+/7+",
        "name_se": "4+/7+",
        "name_el": "4+/7+",
        "name_es": "4+/7+",
        "name_hr": "4+/7+"
    },
    "1694110000^prematch": {
        "name_en": "Over 11.5",
        "name_aa": "Over 11.5",
        "name_de": "Over 11.5",
        "name_nl": "Over 11.5",
        "name_pl": "Over 11.5",
        "name_ro": "Over 11.5",
        "name_ru": "Over 11.5",
        "name_tr": "Over 11.5",
        "name_se": "Over 11.5",
        "name_el": "Over 11.5",
        "name_es": "Over 11.5",
        "name_hr": "Over 11.5"
    },
    "1878^prematch": {
        "name_en": "{$competitor2}/{$competitor1}  4",
        "name_aa": "{$competitor2} / {$competitor1} و 4",
        "name_de": "{$competitor2}/{$competitor1}  4",
        "name_nl": "{$competitor2}/{$competitor1}  4",
        "name_pl": "{$competitor2}/{$competitor1}  4",
        "name_ro": "{$competitor2}/{$competitor1}  4",
        "name_ru": "{$competitor2}/{$competitor1} и 4",
        "name_tr": "{$competitor2}/{$competitor1}  4",
        "name_se": "{$competitor2}/{$competitor1}  4",
        "name_el": "{$competitor2}/{$competitor1}  4",
        "name_es": "{$competitor2}/{$competitor1}  4",
        "name_hr": "{$competitor2}/{$competitor1} i 4"
    },
    "1830^prematch": {
        "name_en": "other inning or no run",
        "name_aa": "دور آخر أو لا المدى",
        "name_de": "Anderes Inning oder kein Lauf",
        "name_nl": "andere inning of geen run",
        "name_pl": "inny inning lub bez run",
        "name_ro": "alt inning sau niciun run",
        "name_ru": "другой иннинг или рана не будет",
        "name_tr": "diğer devre veya koşu yok",
        "name_se": "annan inning elr ingen",
        "name_el": "άλλο inning ή κανένα run",
        "name_es": "otro inning o sin carrera",
        "name_hr": "drugačiji inning ili bez run-a"
    },
    "68^prematch": {
        "name_en": "0",
        "name_aa": "0",
        "name_de": "0",
        "name_nl": "0",
        "name_pl": "0",
        "name_ro": "0",
        "name_ru": "0",
        "name_tr": "0",
        "name_se": "0",
        "name_el": "0",
        "name_es": "0",
        "name_hr": "0"
    },
    "1852^prematch": {
        "name_en": "{$competitor2}/draw  over {total}",
        "name_aa": "{$competitor2} / رسم وأكثر من {total}",
        "name_de": "{$competitor2}/unentschieden  über {total}",
        "name_nl": "{$competitor2}/gelijkspel  boven {total}",
        "name_pl": "{$competitor2}/remis  ponad {total}",
        "name_ro": "{$competitor2}/egal  peste {total}",
        "name_ru": "{$competitor2}/ничья и больше {total}",
        "name_tr": "{$competitor2}/beraberlik  {total} üstü",
        "name_se": "{$competitor2}/oavgjort  över {total}",
        "name_el": "{$competitor2}/ισοπαλία  over {total}",
        "name_es": "{$competitor2}/empate  más de {total}",
        "name_hr": "{$competitor2}/neodlučeno i više {total}"
    },
    "1298^prematch": {
        "name_en": "16-20",
        "name_aa": "16-20",
        "name_de": "16-20",
        "name_nl": "16-20",
        "name_pl": "16-20",
        "name_ro": "16-20",
        "name_ru": "16-20",
        "name_tr": "16-20",
        "name_se": "16-20",
        "name_el": "16-20",
        "name_es": "16-20",
        "name_hr": "16-20"
    },
    "84^prematch": {
        "name_en": "draw  no",
        "name_aa": "ارسم و لا",
        "name_de": "unentschieden  nein",
        "name_nl": "gelijkspel  nee",
        "name_pl": "remis  nie",
        "name_ro": "egal  nu",
        "name_ru": "ничья  нет",
        "name_tr": "beraberlik  yok",
        "name_se": "oavgjort  nej",
        "name_el": "όχι και ισοπαλία",
        "name_es": "empate y no",
        "name_hr": "neodlučeno i ne"
    },
    "1361^prematch": {
        "name_en": "{$competitor2}  decision",
        "name_aa": "{$competitor2}  decision",
        "name_de": "{$competitor2}  decision",
        "name_nl": "{$competitor2}  decision",
        "name_pl": "{$competitor2}  decision",
        "name_ro": "{$competitor2}  decision",
        "name_ru": "{$competitor2}  decision",
        "name_tr": "{$competitor2}  decision",
        "name_se": "{$competitor2}  decision",
        "name_el": "{$competitor2}  decision",
        "name_es": "{$competitor2}  decision",
        "name_hr": "{$competitor2}  decision"
    },
    "256^prematch": {
        "name_en": "9:7",
        "name_aa": "9:7",
        "name_de": "9:7",
        "name_nl": "9:7",
        "name_pl": "9:7",
        "name_ro": "9:7",
        "name_ru": "9:7",
        "name_tr": "9:7",
        "name_se": "9:7",
        "name_el": "9:7",
        "name_es": "9:7",
        "name_hr": "9:7"
    },
    "955^prematch": {
        "name_en": "5th inning",
        "name_aa": "الشوط الخامس",
        "name_de": "5. Inning",
        "name_nl": "5e inning",
        "name_pl": "5. inning",
        "name_ro": "inning 5",
        "name_ru": "5ый иннинг",
        "name_tr": "5. devre",
        "name_se": "5:e inning",
        "name_el": "5ο inning",
        "name_es": "5º inning",
        "name_hr": "5. inning"
    },
    "1761^prematch": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1"
    },
    "1072^prematch": {
        "name_en": "120-129",
        "name_aa": "120-129",
        "name_de": "120-129",
        "name_nl": "120-129",
        "name_pl": "120-129",
        "name_ro": "120-129",
        "name_ru": "120-129",
        "name_tr": "120-129",
        "name_se": "120-129",
        "name_el": "120-129",
        "name_es": "120-129",
        "name_hr": "120-129"
    },
    "1134^prematch": {
        "name_en": "46-60",
        "name_aa": "46-60",
        "name_de": "46-60",
        "name_nl": "46-60",
        "name_pl": "46-60",
        "name_ro": "46-60",
        "name_ru": "46-60",
        "name_tr": "46-60",
        "name_se": "46-60",
        "name_el": "46-60",
        "name_es": "46-60",
        "name_hr": "46-60"
    },
    "135^prematch": {
        "name_en": "{$competitor2} by 8-14",
        "name_aa": "{$competitor2} by 8-14",
        "name_de": "{$competitor2} by 8-14",
        "name_nl": "{$competitor2} by 8-14",
        "name_pl": "{$competitor2} by 8-14",
        "name_ro": "{$competitor2} by 8-14",
        "name_ru": "{$competitor2} by 8-14",
        "name_tr": "{$competitor2} by 8-14",
        "name_se": "{$competitor2} by 8-14",
        "name_el": "{$competitor2} by 8-14",
        "name_es": "{$competitor2} by 8-14",
        "name_hr": "{$competitor2} by 8-14"
    },
    "1423^prematch": {
        "name_en": "{$competitor2}  7",
        "name_aa": "{$competitor2}  7",
        "name_de": "{$competitor2}  7",
        "name_nl": "{$competitor2}  7",
        "name_pl": "{$competitor2}  7",
        "name_ro": "{$competitor2}  7",
        "name_ru": "{$competitor2}  7",
        "name_tr": "{$competitor2}  7",
        "name_se": "{$competitor2}  7",
        "name_el": "{$competitor2}  7",
        "name_es": "{$competitor2}  7",
        "name_hr": "{$competitor2}  7"
    },
    "184^prematch": {
        "name_en": "6:4",
        "name_aa": "6:4",
        "name_de": "6:4",
        "name_nl": "6:4",
        "name_pl": "6:4",
        "name_ro": "6:4",
        "name_ru": "6:4",
        "name_tr": "6:4",
        "name_se": "6:4",
        "name_el": "6:4",
        "name_es": "6:4",
        "name_hr": "6:4"
    },
    "297^prematch": {
        "name_en": "11:10",
        "name_aa": "11:10",
        "name_de": "11:10",
        "name_nl": "11:10",
        "name_pl": "11:10",
        "name_ro": "11:10",
        "name_ru": "11:10",
        "name_tr": "11:10",
        "name_se": "11:10",
        "name_el": "11:10",
        "name_es": "11:10",
        "name_hr": "11:10"
    },
    "808^prematch": {
        "name_en": "yes/no",
        "name_aa": "نعم / لا",
        "name_de": "ja/nein",
        "name_nl": "ja/nee",
        "name_pl": "tak/nie",
        "name_ro": "da/nu",
        "name_ru": "да/нет",
        "name_tr": "var/yok",
        "name_se": "ja/nej",
        "name_el": "ναι/όχι",
        "name_es": "yes/no",
        "name_hr": "da/ne"
    },
    "1479^prematch": {
        "name_en": "4:0",
        "name_aa": "4:0",
        "name_de": "4:0",
        "name_nl": "4:0",
        "name_pl": "4:0",
        "name_ro": "4:0",
        "name_ru": "4:0",
        "name_tr": "4:0",
        "name_se": "4:0",
        "name_el": "4:0",
        "name_es": "4:0",
        "name_hr": "4:0"
    },
    "288^prematch": {
        "name_en": "11:1",
        "name_aa": "11:1",
        "name_de": "11:1",
        "name_nl": "11:1",
        "name_pl": "11:1",
        "name_ro": "11:1",
        "name_ru": "11:1",
        "name_tr": "11:1",
        "name_se": "11:1",
        "name_el": "11:1",
        "name_es": "11:1",
        "name_hr": "11:1"
    },
    "923^prematch": {
        "name_en": "4th quarter",
        "name_aa": "الربع الرابع",
        "name_de": "4. Viertel",
        "name_nl": "Vierde kwart",
        "name_pl": "4. kwarta",
        "name_ro": "Sfertul 4",
        "name_ru": "4ая четверть",
        "name_tr": "Dördüncü çeyrek",
        "name_se": "4:e quarter",
        "name_el": "4η περίοδος",
        "name_es": "4º cuarto",
        "name_hr": "4. četvrtina"
    },
    "1123^prematch": {
        "name_en": "4-5",
        "name_aa": "4-5",
        "name_de": "4-5",
        "name_nl": "4-5",
        "name_pl": "4-5",
        "name_ro": "4-5",
        "name_ru": "4-5",
        "name_tr": "4-5",
        "name_se": "4-5",
        "name_el": "4-5",
        "name_es": "4-5",
        "name_hr": "4-5"
    },
    "424^prematch": {
        "name_en": "draw/{$competitor1}",
        "name_aa": "رسم / {$competitor1}",
        "name_de": "unentschieden/{$competitor1}",
        "name_nl": "gelijkspel/{$competitor1}",
        "name_pl": "remis/{$competitor1}",
        "name_ro": "egal/{$competitor1}",
        "name_ru": "ничья/{$competitor1}",
        "name_tr": "beraberlik/{$competitor1}",
        "name_se": "oavgjort/{$competitor1}",
        "name_el": "ισοπαλία/{$competitor1}",
        "name_es": "empate/{$competitor1}",
        "name_hr": "neodlučeno/{$competitor1}"
    },
    "1256^prematch": {
        "name_en": "35-39",
        "name_aa": "35-39",
        "name_de": "35-39",
        "name_nl": "35-39",
        "name_pl": "35-39",
        "name_ro": "35-39",
        "name_ru": "35-39",
        "name_tr": "35-39",
        "name_se": "35-39",
        "name_el": "35-39",
        "name_es": "35-39",
        "name_hr": "35-39"
    },
    "358^prematch": {
        "name_en": "0:1 2:1",
        "name_aa": "0:1 2:1",
        "name_de": "0:1 2:1",
        "name_nl": "0:1 2:1",
        "name_pl": "0:1 2:1",
        "name_ro": "0:1 2:1",
        "name_ru": "0:1 2:1",
        "name_tr": "0:1 2:1",
        "name_se": "0:1 2:1",
        "name_el": "0:1 2:1",
        "name_es": "0:1 2:1",
        "name_hr": "0:1 2:1"
    },
    "1099^prematch": {
        "name_en": "42+",
        "name_aa": "42+",
        "name_de": "42+",
        "name_nl": "42+",
        "name_pl": "42+",
        "name_ro": "42+",
        "name_ru": "42+",
        "name_tr": "42+",
        "name_se": "42+",
        "name_el": "42+",
        "name_es": "42+",
        "name_hr": "42+"
    },
    "1249^prematch": {
        "name_en": "80-84",
        "name_aa": "80-84",
        "name_de": "80-84",
        "name_nl": "80-84",
        "name_pl": "80-84",
        "name_ro": "80-84",
        "name_ru": "80-84",
        "name_tr": "80-84",
        "name_se": "80-84",
        "name_el": "80-84",
        "name_es": "80-84",
        "name_hr": "80-84"
    },
    "279^prematch": {
        "name_en": "7:10",
        "name_aa": "7:10",
        "name_de": "7:10",
        "name_nl": "7:10",
        "name_pl": "7:10",
        "name_ro": "7:10",
        "name_ru": "7:10",
        "name_tr": "7:10",
        "name_se": "7:10",
        "name_el": "7:10",
        "name_es": "7:10",
        "name_hr": "7:10"
    },
    "165^prematch": {
        "name_en": "171-180",
        "name_aa": "171-180",
        "name_de": "171-180",
        "name_nl": "171-180",
        "name_pl": "171-180",
        "name_ro": "171-180",
        "name_ru": "171-180",
        "name_tr": "171-180",
        "name_se": "171-180",
        "name_el": "171-180",
        "name_es": "171-180",
        "name_hr": "171-180"
    },
    "1842^prematch": {
        "name_en": "{$competitor2}/{$competitor1}  under {total}",
        "name_aa": "{$competitor2} / {$competitor1} و تحت{total}",
        "name_de": "{$competitor2}/{$competitor1}  unter {total}",
        "name_nl": "{$competitor2}/{$competitor1}  onder {total}",
        "name_pl": "{$competitor2}/{$competitor1}  poniżej {total}",
        "name_ro": "{$competitor2}/{$competitor1}  sub {total}",
        "name_ru": "{$competitor2}/{$competitor1} и меньше {total}",
        "name_tr": "{$competitor2}/{$competitor1}  {total} altı",
        "name_se": "{$competitor2}/{$competitor1}  under {total}",
        "name_el": "{$competitor2}/{$competitor1}  under {total}",
        "name_es": "{$competitor2}/{$competitor1}  menos de {total}",
        "name_hr": "{$competitor2}/{$competitor1} i manje {total}"
    },
    "56200000^prematch": {
        "name_en": "0-5",
        "name_aa": "0-5",
        "name_de": "0-5",
        "name_nl": "0-5",
        "name_pl": "0-5",
        "name_ro": "0-5",
        "name_ru": "0-5",
        "name_tr": "0-5",
        "name_se": "0-5",
        "name_el": "0-5",
        "name_es": "0-5",
        "name_hr": "0-5"
    },
    "42^prematch": {
        "name_en": "4",
        "name_aa": "4",
        "name_de": "4",
        "name_nl": "4",
        "name_pl": "4",
        "name_ro": "4",
        "name_ru": "4",
        "name_tr": "4",
        "name_se": "4",
        "name_el": "4",
        "name_es": "4",
        "name_hr": "4"
    },
    "1377^prematch": {
        "name_en": "draw",
        "name_aa": "draw",
        "name_de": "draw",
        "name_nl": "draw",
        "name_pl": "draw",
        "name_ro": "draw",
        "name_ru": "draw",
        "name_tr": "draw",
        "name_se": "draw",
        "name_el": "draw",
        "name_es": "draw",
        "name_hr": "draw"
    },
    "464^prematch": {
        "name_en": "1:1",
        "name_aa": "1:1",
        "name_de": "1:1",
        "name_nl": "1:1",
        "name_pl": "1:1",
        "name_ro": "1:1",
        "name_ru": "1:1",
        "name_tr": "1:1",
        "name_se": "1:1",
        "name_el": "1:1",
        "name_es": "1:1",
        "name_hr": "1:1"
    },
    "1738^prematch": {
        "name_en": "2-6",
        "name_aa": "2-6",
        "name_de": "2-6",
        "name_nl": "2-6",
        "name_pl": "2-6",
        "name_ro": "2-6",
        "name_ru": "2-6",
        "name_tr": "2-6",
        "name_se": "2-6",
        "name_el": "2-6",
        "name_es": "2-6",
        "name_hr": "2-6"
    },
    "1299^prematch": {
        "name_en": "21-25",
        "name_aa": "21-25",
        "name_de": "21-25",
        "name_nl": "21-25",
        "name_pl": "21-25",
        "name_ro": "21-25",
        "name_ru": "21-25",
        "name_tr": "21-25",
        "name_se": "21-25",
        "name_el": "21-25",
        "name_es": "21-25",
        "name_hr": "21-25"
    },
    "1883^prematch": {
        "name_en": "{$competitor1}/{$competitor2}  5+",
        "name_aa": "{$competitor1} / {$competitor2} و 5+",
        "name_de": "{$competitor1}/{$competitor2}  5+",
        "name_nl": "{$competitor1}/{$competitor2}  5+",
        "name_pl": "{$competitor1}/{$competitor2}  5+",
        "name_ro": "{$competitor1}/{$competitor2}  5+",
        "name_ru": "{$competitor1}/{$competitor2} и 5+",
        "name_tr": "{$competitor1}/{$competitor2}  5+",
        "name_se": "{$competitor1}/{$competitor2}  5+",
        "name_el": "{$competitor1}/{$competitor2}  5+",
        "name_es": "{$competitor1}/{$competitor2}  5+",
        "name_hr": "{$competitor1}/{$competitor2} i 5+"
    },
    "1823^prematch": {
        "name_en": "top of 9th inning",
        "name_aa": "قمة الشوط التاسع",
        "name_de": "Anfang des 9. Innings",
        "name_nl": "eerste helft 9e inning",
        "name_pl": "top of 9th inning",
        "name_ro": "prima jumătate a inning-ului 9",
        "name_ru": "первая половина 9го иннинга",
        "name_tr": "9. devrenin sonunda",
        "name_se": "toppen av 9:e inning",
        "name_el": "top of 9th inning",
        "name_es": "mejor del 9º inning",
        "name_hr": "na kraju 9. inninga"
    },
    "1747^prematch": {
        "name_en": "1-3",
        "name_aa": "1-3",
        "name_de": "1-3",
        "name_nl": "1-3",
        "name_pl": "1-3",
        "name_ro": "1-3",
        "name_ru": "1-3",
        "name_tr": "1-3",
        "name_se": "1-3",
        "name_el": "1-3",
        "name_es": "1-3",
        "name_hr": "1-3"
    },
    "784^prematch": {
        "name_en": "none",
        "name_aa": "لا شيء",
        "name_de": "keiner",
        "name_nl": "geen",
        "name_pl": "żaden",
        "name_ro": "niciuna",
        "name_ru": "ни одна",
        "name_tr": "Gol olmaz",
        "name_se": "ingen",
        "name_el": "καμία",
        "name_es": "ninguno",
        "name_hr": "nitko"
    },
    "56150000^prematch": {
        "name_en": "4+",
        "name_aa": "4+",
        "name_de": "4+",
        "name_nl": "4+",
        "name_pl": "4+",
        "name_ro": "4+",
        "name_ru": "4+",
        "name_tr": "4+",
        "name_se": "4+",
        "name_el": "4+",
        "name_es": "4+",
        "name_hr": "4+"
    },
    "1841^prematch": {
        "name_en": "draw/{$competitor2}  under {total}",
        "name_aa": "رسم / {$competitor2} و تحت{total}",
        "name_de": "unentschieden/{$competitor2}  unter {total}",
        "name_nl": "gelijkspel/{$competitor2}  onder {total}",
        "name_pl": "remis/{$competitor2}  poniżej {total}",
        "name_ro": "egal/{$competitor2}  sub {total}",
        "name_ru": "ничья/{$competitor2} и меньше {total}",
        "name_tr": "beraberlik/{$competitor2}  {total} altı",
        "name_se": "oavgjort/{$competitor2}  under {total}",
        "name_el": "ισοπαλία /{$competitor2}  under {total}",
        "name_es": "empate/{$competitor2}  menos de {total}",
        "name_hr": "neodlučeno/{$competitor2} i manje {total}"
    },
    "590^prematch": {
        "name_en": "46-60",
        "name_aa": "46-60",
        "name_de": "46-60",
        "name_nl": "46-60",
        "name_pl": "46-60",
        "name_ro": "46-60",
        "name_ru": "46-60",
        "name_tr": "46-60",
        "name_se": "46-60",
        "name_el": "46-60",
        "name_es": "46-60",
        "name_hr": "46-60"
    },
    "1372^prematch": {
        "name_en": "{$competitor2}  4",
        "name_aa": "{$competitor2}  4",
        "name_de": "{$competitor2}  4",
        "name_nl": "{$competitor2}  4",
        "name_pl": "{$competitor2}  4",
        "name_ro": "{$competitor2}  4",
        "name_ru": "{$competitor2}  4",
        "name_tr": "{$competitor2}  4",
        "name_se": "{$competitor2}  4",
        "name_el": "{$competitor2}  4",
        "name_es": "{$competitor2}  4",
        "name_hr": "{$competitor2}  4"
    },
    "250^prematch": {
        "name_en": "9:1",
        "name_aa": "9:1",
        "name_de": "9:1",
        "name_nl": "9:1",
        "name_pl": "9:1",
        "name_ro": "9:1",
        "name_ru": "9:1",
        "name_tr": "9:1",
        "name_se": "9:1",
        "name_el": "9:1",
        "name_es": "9:1",
        "name_hr": "9:1"
    },
    "70820000^prematch": {
        "name_en": "X-2",
        "name_aa": "X-2",
        "name_de": "X-2",
        "name_nl": "X-2",
        "name_pl": "X-2",
        "name_ro": "X-2",
        "name_ru": "X-2",
        "name_tr": "X-2",
        "name_se": "X-2",
        "name_el": "X-2",
        "name_es": "X-2",
        "name_hr": "X-2"
    },
    "252^prematch": {
        "name_en": "9:3",
        "name_aa": "9:3",
        "name_de": "9:3",
        "name_nl": "9:3",
        "name_pl": "9:3",
        "name_ro": "9:3",
        "name_ru": "9:3",
        "name_tr": "9:3",
        "name_se": "9:3",
        "name_el": "9:3",
        "name_es": "9:3",
        "name_hr": "9:3"
    },
    "1694140000^prematch": {
        "name_en": "Under 12.5",
        "name_aa": "Under 12.5",
        "name_de": "Under 12.5",
        "name_nl": "Under 12.5",
        "name_pl": "Under 12.5",
        "name_ro": "Under 12.5",
        "name_ru": "Under 12.5",
        "name_tr": "Under 12.5",
        "name_se": "Under 12.5",
        "name_el": "Under 12.5",
        "name_es": "Under 12.5",
        "name_hr": "Under 12.5"
    },
    "75^prematch": {
        "name_en": "0",
        "name_aa": "0",
        "name_de": "0",
        "name_nl": "0",
        "name_pl": "0",
        "name_ro": "0",
        "name_ru": "0",
        "name_tr": "0",
        "name_se": "0",
        "name_el": "0",
        "name_es": "0",
        "name_hr": "0"
    },
    "1855^prematch": {
        "name_en": "{$competitor1}/{$competitor1}  1",
        "name_aa": "{$competitor1} / {$competitor1} و 1",
        "name_de": "{$competitor1}/{$competitor1}  1",
        "name_nl": "{$competitor1}/{$competitor1}  1",
        "name_pl": "{$competitor1}/{$competitor1}  1",
        "name_ro": "{$competitor1}/{$competitor1}  1",
        "name_ru": "{$competitor1}/{$competitor1} и 1",
        "name_tr": "{$competitor1}/{$competitor1}  1",
        "name_se": "{$competitor1}/{$competitor1}  1",
        "name_el": "{$competitor1}/{$competitor1}  1",
        "name_es": "{$competitor1}/{$competitor1}  1",
        "name_hr": "{$competitor1}/{$competitor1} i 1"
    },
    "1726^prematch": {
        "name_en": "draw/{$competitor2}  under {total}",
        "name_aa": "رسم / {$competitor2} وتحت {total}",
        "name_de": "unentschieden/{$competitor2}  unter {total}",
        "name_nl": "gelijkspel/{$competitor2}  onder {total}",
        "name_pl": "remis/{$competitor2}  poniżej {total}",
        "name_ro": "egal/{$competitor2}  sub {total}",
        "name_ru": "ничья/{$competitor2} и меньше {total}",
        "name_tr": "beraberlik/{$competitor2}  {total} altı",
        "name_se": "oavgjort/{$competitor2}  under {total}",
        "name_el": "ισοπαλία/{$competitor2} και under {total}",
        "name_es": "empate/{$competitor2} y menos de {total}",
        "name_hr": "neodlučeno/{$competitor2} i manje {total}"
    },
    "1735^prematch": {
        "name_en": "2-3",
        "name_aa": "2-3",
        "name_de": "2-3",
        "name_nl": "2-3",
        "name_pl": "2-3",
        "name_ro": "2-3",
        "name_ru": "2-3",
        "name_tr": "2-3",
        "name_se": "2-3",
        "name_el": "2-3",
        "name_es": "2-3",
        "name_hr": "2-3"
    },
    "1413^prematch": {
        "name_en": "{$competitor1}  11",
        "name_aa": "{$competitor1}  11",
        "name_de": "{$competitor1}  11",
        "name_nl": "{$competitor1}  11",
        "name_pl": "{$competitor1}  11",
        "name_ro": "{$competitor1}  11",
        "name_ru": "{$competitor1}  11",
        "name_tr": "{$competitor1}  11",
        "name_se": "{$competitor1}  11",
        "name_el": "{$competitor1}  11",
        "name_es": "{$competitor1}  11",
        "name_hr": "{$competitor1}  11"
    },
    "162^prematch": {
        "name_en": "141-150",
        "name_aa": "141-150",
        "name_de": "141-150",
        "name_nl": "141-150",
        "name_pl": "141-150",
        "name_ro": "141-150",
        "name_ru": "141-150",
        "name_tr": "141-150",
        "name_se": "141-150",
        "name_el": "141-150",
        "name_es": "141-150",
        "name_hr": "141-150"
    },
    "586^prematch": {
        "name_en": "16-30",
        "name_aa": "16-30",
        "name_de": "16-30",
        "name_nl": "16-30",
        "name_pl": "16-30",
        "name_ro": "16-30",
        "name_ru": "16-30",
        "name_tr": "16-30",
        "name_se": "16-30",
        "name_el": "16-30",
        "name_es": "16-30",
        "name_hr": "16-30"
    },
    "166^prematch": {
        "name_en": "180.5+",
        "name_aa": "180.5+",
        "name_de": "180.5+",
        "name_nl": "180.5+",
        "name_pl": "180.5+",
        "name_ro": "180.5+",
        "name_ru": "180.5+",
        "name_tr": "180.5+",
        "name_se": "180.5+",
        "name_el": "180.5+",
        "name_es": "180.5+",
        "name_hr": "180.5+"
    },
    "24^prematch": {
        "name_en": "5:2",
        "name_aa": "5:2",
        "name_de": "5:2",
        "name_nl": "5:2",
        "name_pl": "5:2",
        "name_ro": "5:2",
        "name_ru": "5:2",
        "name_tr": "5:2",
        "name_se": "5:2",
        "name_el": "5:2",
        "name_es": "5:2",
        "name_hr": "5:2"
    },
    "1718^prematch": {
        "name_en": "{$competitor1}/draw  yes",
        "name_aa": "{$competitor1} / رسم ونعم",
        "name_de": "{$competitor1}/unentschieden  ja",
        "name_nl": "{$competitor1}/gelijkspel  ja",
        "name_pl": "{$competitor1}/remis  tak",
        "name_ro": "{$competitor1}/egal  da",
        "name_ru": "{$competitor1}/ничья и да",
        "name_tr": "{$competitor1}/beraberlik  var",
        "name_se": "{$competitor1}/oavgjort  ja",
        "name_el": "{$competitor1}/ ισοπαλία και ναι",
        "name_es": "{$competitor1}/empate y sí",
        "name_hr": "{$competitor1}/neodlučeno i da"
    },
    "1403^prematch": {
        "name_en": "{$competitor1}  1",
        "name_aa": "{$competitor1}  1",
        "name_de": "{$competitor1}  1",
        "name_nl": "{$competitor1}  1",
        "name_pl": "{$competitor1}  1",
        "name_ro": "{$competitor1}  1",
        "name_ru": "{$competitor1}  1",
        "name_tr": "{$competitor1}  1",
        "name_se": "{$competitor1}  1",
        "name_el": "{$competitor1}  1",
        "name_es": "{$competitor1}  1",
        "name_hr": "{$competitor1}  1"
    },
    "322^prematch": {
        "name_en": "4:4",
        "name_aa": "4:4",
        "name_de": "4:4",
        "name_nl": "4:4",
        "name_pl": "4:4",
        "name_ro": "4:4",
        "name_ru": "4:4",
        "name_tr": "4:4",
        "name_se": "4:4",
        "name_el": "4:4",
        "name_es": "4:4",
        "name_hr": "4:4"
    },
    "3^prematch": {
        "name_en": "{$competitor2}",
        "name_aa": "{$competitor2}",
        "name_de": "{$competitor2}",
        "name_nl": "{$competitor2}",
        "name_pl": "{$competitor2}",
        "name_ro": "{$competitor2}",
        "name_ru": "{$competitor2}",
        "name_tr": "{$competitor2}",
        "name_se": "{$competitor2}",
        "name_el": "{$competitor2}",
        "name_es": "{$competitor2}",
        "name_hr": "{$competitor2}"
    },
    "238^prematch": {
        "name_en": "8:5",
        "name_aa": "8:5",
        "name_de": "8:5",
        "name_nl": "8:5",
        "name_pl": "8:5",
        "name_ro": "8:5",
        "name_ru": "8:5",
        "name_tr": "8:5",
        "name_se": "8:5",
        "name_el": "8:5",
        "name_es": "8:5",
        "name_hr": "8:5"
    },
    "1176^prematch": {
        "name_en": "2:4",
        "name_aa": "2:4",
        "name_de": "2:4",
        "name_nl": "2:4",
        "name_pl": "2:4",
        "name_ro": "2:4",
        "name_ru": "2:4",
        "name_tr": "2:4",
        "name_se": "2:4",
        "name_el": "2:4",
        "name_es": "2:4",
        "name_hr": "2:4"
    },
    "121^prematch": {
        "name_en": "{$competitor2} by 6+",
        "name_aa": "{$competitor2} by 6+",
        "name_de": "{$competitor2} by 6+",
        "name_nl": "{$competitor2} by 6+",
        "name_pl": "{$competitor2} by 6+",
        "name_ro": "{$competitor2} by 6+",
        "name_ru": "{$competitor2} by 6+",
        "name_tr": "{$competitor2} by 6+",
        "name_se": "{$competitor2} by 6+",
        "name_el": "{$competitor2} by 6+",
        "name_es": "{$competitor2} by 6+",
        "name_hr": "{$competitor2} by 6+"
    },
    "1694230000^prematch": {
        "name_en": "Over 3.5",
        "name_aa": "Over 3.5",
        "name_de": "Over 3.5",
        "name_nl": "Over 3.5",
        "name_pl": "Over 3.5",
        "name_ro": "Over 3.5",
        "name_ru": "Over 3.5",
        "name_tr": "Over 3.5",
        "name_se": "Over 3.5",
        "name_el": "Over 3.5",
        "name_es": "Over 3.5",
        "name_hr": "Over 3.5"
    },
    "964^prematch": {
        "name_en": "3",
        "name_aa": "3",
        "name_de": "3",
        "name_nl": "3",
        "name_pl": "3",
        "name_ro": "3",
        "name_ru": "3",
        "name_tr": "3",
        "name_se": "3",
        "name_el": "3",
        "name_es": "3",
        "name_hr": "3"
    },
    "1426^prematch": {
        "name_en": "{$competitor2}  10",
        "name_aa": "{$competitor2}  10",
        "name_de": "{$competitor2}  10",
        "name_nl": "{$competitor2}  10",
        "name_pl": "{$competitor2}  10",
        "name_ro": "{$competitor2}  10",
        "name_ru": "{$competitor2}  10",
        "name_tr": "{$competitor2}  10",
        "name_se": "{$competitor2}  10",
        "name_el": "{$competitor2}  10",
        "name_es": "{$competitor2}  10",
        "name_hr": "{$competitor2}  10"
    },
    "260^prematch": {
        "name_en": "6:9",
        "name_aa": "6:9",
        "name_de": "6:9",
        "name_nl": "6:9",
        "name_pl": "6:9",
        "name_ro": "6:9",
        "name_ru": "6:9",
        "name_tr": "6:9",
        "name_se": "6:9",
        "name_el": "6:9",
        "name_es": "6:9",
        "name_hr": "6:9"
    },
    "1178^prematch": {
        "name_en": "3:0",
        "name_aa": "3:0",
        "name_de": "3:0",
        "name_nl": "3:0",
        "name_pl": "3:0",
        "name_ro": "3:0",
        "name_ru": "3:0",
        "name_tr": "3:0",
        "name_se": "3:0",
        "name_el": "3:0",
        "name_es": "3:0",
        "name_hr": "3:0"
    },
    "266^prematch": {
        "name_en": "0:9",
        "name_aa": "0:9",
        "name_de": "0:9",
        "name_nl": "0:9",
        "name_pl": "0:9",
        "name_ro": "0:9",
        "name_ru": "0:9",
        "name_tr": "0:9",
        "name_se": "0:9",
        "name_el": "0:9",
        "name_es": "0:9",
        "name_hr": "0:9"
    },
    "177^prematch": {
        "name_en": "241-250",
        "name_aa": "241-250",
        "name_de": "241-250",
        "name_nl": "241-250",
        "name_pl": "241-250",
        "name_ro": "241-250",
        "name_ru": "241-250",
        "name_tr": "241-250",
        "name_se": "241-250",
        "name_el": "241-250",
        "name_es": "241-250",
        "name_hr": "241-250"
    },
    "1428^prematch": {
        "name_en": "{$competitor2}  12",
        "name_aa": "{$competitor2}  12",
        "name_de": "{$competitor2}  12",
        "name_nl": "{$competitor2}  12",
        "name_pl": "{$competitor2}  12",
        "name_ro": "{$competitor2}  12",
        "name_ru": "{$competitor2}  12",
        "name_tr": "{$competitor2}  12",
        "name_se": "{$competitor2}  12",
        "name_el": "{$competitor2}  12",
        "name_es": "{$competitor2}  12",
        "name_hr": "{$competitor2}  12"
    },
    "1425^prematch": {
        "name_en": "{$competitor2}  9",
        "name_aa": "{$competitor2}  9",
        "name_de": "{$competitor2}  9",
        "name_nl": "{$competitor2}  9",
        "name_pl": "{$competitor2}  9",
        "name_ro": "{$competitor2}  9",
        "name_ru": "{$competitor2}  9",
        "name_tr": "{$competitor2}  9",
        "name_se": "{$competitor2}  9",
        "name_el": "{$competitor2}  9",
        "name_es": "{$competitor2}  9",
        "name_hr": "{$competitor2}  9"
    },
    "1482^prematch": {
        "name_en": "1:1",
        "name_aa": "1:1",
        "name_de": "1:1",
        "name_nl": "1:1",
        "name_pl": "1:1",
        "name_ro": "1:1",
        "name_ru": "1:1",
        "name_tr": "1:1",
        "name_se": "1:1",
        "name_el": "1:1",
        "name_es": "1:1",
        "name_hr": "1:1"
    },
    "951^prematch": {
        "name_en": "1st inning",
        "name_aa": "الشوط الأول",
        "name_de": "1. Inning",
        "name_nl": "1e inning",
        "name_pl": "1. inning",
        "name_ro": "inning 1",
        "name_ru": "1ый иннинг",
        "name_tr": "1. devre",
        "name_se": "1:a inning",
        "name_el": "1ο inning",
        "name_es": "1º inning",
        "name_hr": "1. inning"
    },
    "136^prematch": {
        "name_en": "{$competitor2} by 15+",
        "name_aa": "{$competitor2} by 15+",
        "name_de": "{$competitor2} by 15+",
        "name_nl": "{$competitor2} by 15+",
        "name_pl": "{$competitor2} by 15+",
        "name_ro": "{$competitor2} by 15+",
        "name_ru": "{$competitor2} by 15+",
        "name_tr": "{$competitor2} by 15+",
        "name_se": "{$competitor2} by 15+",
        "name_el": "{$competitor2} by 15+",
        "name_es": "{$competitor2} by 15+",
        "name_hr": "{$competitor2} by 15+"
    },
    "132^prematch": {
        "name_en": "{$competitor1} by 1-7",
        "name_aa": "{$competitor1} by 1-7",
        "name_de": "{$competitor1} by 1-7",
        "name_nl": "{$competitor1} by 1-7",
        "name_pl": "{$competitor1} by 1-7",
        "name_ro": "{$competitor1} by 1-7",
        "name_ru": "{$competitor1} by 1-7",
        "name_tr": "{$competitor1} by 1-7",
        "name_se": "{$competitor1} by 1-7",
        "name_el": "{$competitor1} by 1-7",
        "name_es": "{$competitor1} by 1-7",
        "name_hr": "{$competitor1} by 1-7"
    },
    "146^prematch": {
        "name_en": "4:2",
        "name_aa": "4:2",
        "name_de": "4:2",
        "name_nl": "4:2",
        "name_pl": "4:2",
        "name_ro": "4:2",
        "name_ru": "4:2",
        "name_tr": "4:2",
        "name_se": "4:2",
        "name_el": "4:2",
        "name_es": "4:2",
        "name_hr": "4:2"
    },
    "1766^prematch": {
        "name_en": "6+",
        "name_aa": "6+",
        "name_de": "6+",
        "name_nl": "6+",
        "name_pl": "6+",
        "name_ro": "6+",
        "name_ru": "6+",
        "name_tr": "6+",
        "name_se": "6+",
        "name_el": "6+",
        "name_es": "6+",
        "name_hr": "6+"
    },
    "790^prematch": {
        "name_en": "only {$competitor2}",
        "name_aa": "فقط {$competitor2}",
        "name_de": "nur {$competitor2}",
        "name_nl": "alleen {$competitor2}",
        "name_pl": "tylko {$competitor2}",
        "name_ro": "doar {$competitor2}",
        "name_ru": "только {$competitor2}",
        "name_tr": "sadece {$competitor2}",
        "name_se": "endast {$competitor2}",
        "name_el": "μόνο {$competitor2}",
        "name_es": "solo {$competitor2}",
        "name_hr": "samo {$competitor2}"
    },
    "1287^prematch": {
        "name_en": "{$competitor1} by 21-25",
        "name_aa": "{$competitor1} by 21-25",
        "name_de": "{$competitor1} by 21-25",
        "name_nl": "{$competitor1} by 21-25",
        "name_pl": "{$competitor1} by 21-25",
        "name_ro": "{$competitor1} by 21-25",
        "name_ru": "{$competitor1} by 21-25",
        "name_tr": "{$competitor1} by 21-25",
        "name_se": "{$competitor1} by 21-25",
        "name_el": "{$competitor1} by 21-25",
        "name_es": "{$competitor1} by 21-25",
        "name_hr": "{$competitor1} by 21-25"
    },
    "976^prematch": {
        "name_en": "{$competitor2}  under {total}",
        "name_aa": "{$competitor2}  under {total}",
        "name_de": "{$competitor2}  under {total}",
        "name_nl": "{$competitor2}  under {total}",
        "name_pl": "{$competitor2}  under {total}",
        "name_ro": "{$competitor2}  under {total}",
        "name_ru": "{$competitor2}  under {total}",
        "name_tr": "{$competitor2}  under {total}",
        "name_se": "{$competitor2}  under {total}",
        "name_el": "{$competitor2}  under {total}",
        "name_es": "{$competitor2}  under {total}",
        "name_hr": "{$competitor2}  under {total}"
    },
    "265^prematch": {
        "name_en": "1:9",
        "name_aa": "1:9",
        "name_de": "1:9",
        "name_nl": "1:9",
        "name_pl": "1:9",
        "name_ro": "1:9",
        "name_ru": "1:9",
        "name_tr": "1:9",
        "name_se": "1:9",
        "name_el": "1:9",
        "name_es": "1:9",
        "name_hr": "1:9"
    },
    "138^prematch": {
        "name_en": "{$competitor1} by 6-10",
        "name_aa": "{$competitor1} by 6-10",
        "name_de": "{$competitor1} by 6-10",
        "name_nl": "{$competitor1} by 6-10",
        "name_pl": "{$competitor1} by 6-10",
        "name_ro": "{$competitor1} by 6-10",
        "name_ru": "{$competitor1} by 6-10",
        "name_tr": "{$competitor1} by 6-10",
        "name_se": "{$competitor1} by 6-10",
        "name_el": "{$competitor1} by 6-10",
        "name_es": "{$competitor1} by 6-10",
        "name_hr": "{$competitor1} by 6-10"
    }
}

export const LIVE_OUTCOME = {
    "1208^live_match^8^blue": {
        "name_en": "blue",
        "name_aa": "blue",
        "name_de": "blau",
        "name_nl": "blauw",
        "name_pl": "blue",
        "name_ro": "blue",
        "name_ru": "blue",
        "name_tr": "mavi",
        "name_se": "blue",
        "name_el": "blue",
        "name_es": "blue",
        "name_hr": "plava",
        "name_fr": "bleu"
    },
    "2034^live_match^8^home_and_over": {
        "name_en": "home and over",
        "name_aa": "home and over",
        "name_de": "Heim und Über",
        "name_nl": "Thuis and Meer dan",
        "name_pl": "home and over",
        "name_ro": "home and over",
        "name_ru": "home and over",
        "name_tr": "home and over",
        "name_se": "home and over",
        "name_el": "home and over",
        "name_es": "home and over",
        "name_hr": "Domači i više",
        "name_fr": "home and over"
    },
    "2130^live_match^8^1:2": {
        "name_en": "1:2",
        "name_aa": "1:2",
        "name_de": "1:2",
        "name_nl": "1:2",
        "name_pl": "1:2",
        "name_ro": "1:2",
        "name_ru": "1:2",
        "name_tr": "1:2",
        "name_se": "1:2",
        "name_el": "1:2",
        "name_es": "1:2",
        "name_hr": "1:2",
        "name_fr": "1:2"
    },
    "48^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2333^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "4^live_match^6^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2346^live_match^8^yes": {
        "name_en": "yes",
        "name_aa": "yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "yes",
        "name_ro": "yes",
        "name_ru": "yes",
        "name_tr": "yes",
        "name_se": "yes",
        "name_el": "yes",
        "name_es": "yes",
        "name_hr": "Da",
        "name_fr": "yes"
    },
    "2349^live_match^8^goal": {
        "name_en": "goal",
        "name_aa": "goal",
        "name_de": "Tor",
        "name_nl": "Doelpunt",
        "name_pl": "goal",
        "name_ro": "goal",
        "name_ru": "goal",
        "name_tr": "goal",
        "name_se": "goal",
        "name_el": "goal",
        "name_es": "goal",
        "name_hr": "Golova",
        "name_fr": "goal"
    },
    "1204^live_match^8^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2121^live_match^8^no": {
        "name_en": "no",
        "name_aa": "no",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "no",
        "name_ro": "no",
        "name_ru": "no",
        "name_tr": "no",
        "name_se": "no",
        "name_el": "no",
        "name_es": "no",
        "name_hr": "Ne",
        "name_fr": "no"
    },
    "29^live_match^8^1:2": {
        "name_en": "1:2",
        "name_aa": "1:2",
        "name_de": "1:2",
        "name_nl": "1:2",
        "name_pl": "1:2",
        "name_ro": "1:2",
        "name_ru": "1:2",
        "name_tr": "1:2",
        "name_se": "1:2",
        "name_el": "1:2",
        "name_es": "1:2",
        "name_hr": "1:2",
        "name_fr": "1:2"
    },
    "976^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "1066^live_match^8^0:1": {
        "name_en": "0:1",
        "name_aa": "0:1",
        "name_de": "0:1",
        "name_nl": "0:1",
        "name_pl": "0:1",
        "name_ro": "0:1",
        "name_ru": "0:1",
        "name_tr": "0:1",
        "name_se": "0:1",
        "name_el": "0:1",
        "name_es": "0:1",
        "name_hr": "0:1",
        "name_fr": "0:1"
    },
    "2204^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2166^live_match^8^no": {
        "name_en": "no",
        "name_aa": "no",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "no",
        "name_ro": "no",
        "name_ru": "no",
        "name_tr": "no",
        "name_se": "no",
        "name_el": "no",
        "name_es": "no",
        "name_hr": "Ne",
        "name_fr": "no"
    },
    "2132^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2354^live_match^8^7:7": {
        "name_en": "7:7",
        "name_aa": "7:7",
        "name_de": "7:7",
        "name_nl": "7:7",
        "name_pl": "7:7",
        "name_ro": "7:7",
        "name_ru": "7:7",
        "name_tr": "7:7",
        "name_se": "7:7",
        "name_el": "7:7",
        "name_es": "7:7",
        "name_hr": "7:7",
        "name_fr": "7:7"
    },
    "1108^live_match^8^1:4": {
        "name_en": "1:4",
        "name_aa": "1:4",
        "name_de": "1:4",
        "name_nl": "1:4",
        "name_pl": "1:4",
        "name_ro": "1:4",
        "name_ru": "1:4",
        "name_tr": "1:4",
        "name_se": "1:4",
        "name_el": "1:4",
        "name_es": "1:4",
        "name_hr": "1:4",
        "name_fr": "1:4"
    },
    "2179^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "over",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "Üst",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "2055^live_match^8^12": {
        "name_en": "12",
        "name_aa": "12",
        "name_de": "12",
        "name_nl": "12",
        "name_pl": "12",
        "name_ro": "12",
        "name_ru": "12",
        "name_tr": "12",
        "name_se": "12",
        "name_el": "12",
        "name_es": "12",
        "name_hr": "12",
        "name_fr": "12"
    },
    "32^live_match^8^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2213^live_match^8^home_13-18": {
        "name_en": "home 13-18",
        "name_aa": "home 13-18",
        "name_de": "Heim 13-18",
        "name_nl": "Thuis 13-18",
        "name_pl": "home 13-18",
        "name_ro": "home 13-18",
        "name_ru": "home 13-18",
        "name_tr": "home 13-18",
        "name_se": "home 13-18",
        "name_el": "home 13-18",
        "name_es": "home 13-18",
        "name_hr": "Domači 13-18",
        "name_fr": "home 13-18"
    },
    "2316^live_match^8^6:10": {
        "name_en": "6:10",
        "name_aa": "6:10",
        "name_de": "6:10",
        "name_nl": "6:10",
        "name_pl": "6:10",
        "name_ro": "6:10",
        "name_ru": "6:10",
        "name_tr": "6:10",
        "name_se": "6:10",
        "name_el": "6:10",
        "name_es": "6:10",
        "name_hr": "6:10",
        "name_fr": "6:10"
    },
    "1108^live_match^8^12:8": {
        "name_en": "12:8",
        "name_aa": "12:8",
        "name_de": "12:8",
        "name_nl": "12:8",
        "name_pl": "12:8",
        "name_ro": "12:8",
        "name_ru": "12:8",
        "name_tr": "12:8",
        "name_se": "12:8",
        "name_el": "12:8",
        "name_es": "12:8",
        "name_hr": "12:8",
        "name_fr": "12:8"
    },
    "2187^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "over",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "Üst",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "1108^live_match^8^1:2": {
        "name_en": "1:2",
        "name_aa": "1:2",
        "name_de": "1:2",
        "name_nl": "1:2",
        "name_pl": "1:2",
        "name_ro": "1:2",
        "name_ru": "1:2",
        "name_tr": "1:2",
        "name_se": "1:2",
        "name_el": "1:2",
        "name_es": "1:2",
        "name_hr": "1:2",
        "name_fr": "1:2"
    },
    "2261^live_match^8^2:4": {
        "name_en": "2:4",
        "name_aa": "2:4",
        "name_de": "2:4",
        "name_nl": "2:4",
        "name_pl": "2:4",
        "name_ro": "2:4",
        "name_ru": "2:4",
        "name_tr": "2:4",
        "name_se": "2:4",
        "name_el": "2:4",
        "name_es": "2:4",
        "name_hr": "2:4",
        "name_fr": "2:4"
    },
    "0^live_match^4^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "1368^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "5^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "62^live_match^8^even": {
        "name_en": "even",
        "name_aa": "even",
        "name_de": "Gerade",
        "name_nl": "even",
        "name_pl": "even",
        "name_ro": "even",
        "name_ru": "even",
        "name_tr": "çift",
        "name_se": "even",
        "name_el": "even",
        "name_es": "even",
        "name_hr": "Paran",
        "name_fr": "pair"
    },
    "2117^live_match^8^yes": {
        "name_en": "yes",
        "name_aa": "yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "yes",
        "name_ro": "yes",
        "name_ru": "yes",
        "name_tr": "yes",
        "name_se": "yes",
        "name_el": "yes",
        "name_es": "yes",
        "name_hr": "Da",
        "name_fr": "yes"
    },
    "2072^live_match^8^21:19": {
        "name_en": "21:19",
        "name_aa": "21:19",
        "name_de": "21:19",
        "name_nl": "21:19",
        "name_pl": "21:19",
        "name_ro": "21:19",
        "name_ru": "21:19",
        "name_tr": "21:19",
        "name_se": "21:19",
        "name_el": "21:19",
        "name_es": "21:19",
        "name_hr": "21:19",
        "name_fr": "21:19"
    },
    "2260^live_match^8^away_1": {
        "name_en": "away 1",
        "name_aa": "away 1",
        "name_de": "Auswärt 1",
        "name_nl": "Weg 1",
        "name_pl": "away 1",
        "name_ro": "away 1",
        "name_ru": "away 1",
        "name_tr": "away 1",
        "name_se": "away 1",
        "name_el": "away 1",
        "name_es": "away 1",
        "name_hr": "Gostujući 1",
        "name_fr": "away 1"
    },
    "2073^live_match^8^25:16": {
        "name_en": "25:16",
        "name_aa": "25:16",
        "name_de": "25:16",
        "name_nl": "25:16",
        "name_pl": "25:16",
        "name_ro": "25:16",
        "name_ru": "25:16",
        "name_tr": "25:16",
        "name_se": "25:16",
        "name_el": "25:16",
        "name_es": "25:16",
        "name_hr": "25:16",
        "name_fr": "25:16"
    },
    "101^live_match^6^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "1372^live_match^6^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "17^live_match^6^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2018^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "over",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "Üst",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "1659^live_match^2^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2354^live_match^8^1:12": {
        "name_en": "1:12",
        "name_aa": "1:12",
        "name_de": "1:12",
        "name_nl": "1:12",
        "name_pl": "1:12",
        "name_ro": "1:12",
        "name_ru": "1:12",
        "name_tr": "1:12",
        "name_se": "1:12",
        "name_el": "1:12",
        "name_es": "1:12",
        "name_hr": "1:12",
        "name_fr": "1:12"
    },
    "116^live_match^8^2:3": {
        "name_en": "2:3",
        "name_aa": "2:3",
        "name_de": "2:3",
        "name_nl": "2:3",
        "name_pl": "2:3",
        "name_ro": "2:3",
        "name_ru": "2:3",
        "name_tr": "2:3",
        "name_se": "2:3",
        "name_el": "2:3",
        "name_es": "2:3",
        "name_hr": "2:3",
        "name_fr": "2:3"
    },
    "2105^live_match^8^home": {
        "name_en": "home",
        "name_aa": "home",
        "name_de": "Heim",
        "name_nl": "Thuis",
        "name_pl": "home",
        "name_ro": "home",
        "name_ru": "home",
        "name_tr": "home",
        "name_se": "home",
        "name_el": "home",
        "name_es": "home",
        "name_hr": "Domači",
        "name_fr": "home"
    },
    "2203^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2070^live_match^8^home": {
        "name_en": "home",
        "name_aa": "home",
        "name_de": "Heim",
        "name_nl": "Thuis",
        "name_pl": "home",
        "name_ro": "home",
        "name_ru": "home",
        "name_tr": "home",
        "name_se": "home",
        "name_el": "home",
        "name_es": "home",
        "name_hr": "Domači",
        "name_fr": "home"
    },
    "1108^live_match^8^11:5": {
        "name_en": "11:5",
        "name_aa": "11:5",
        "name_de": "11:5",
        "name_nl": "11:5",
        "name_pl": "11:5",
        "name_ro": "11:5",
        "name_ru": "11:5",
        "name_tr": "11:5",
        "name_se": "11:5",
        "name_el": "11:5",
        "name_es": "11:5",
        "name_hr": "11:5",
        "name_fr": "11:5"
    },
    "2261^live_match^8^3:0": {
        "name_en": "3:0",
        "name_aa": "3:0",
        "name_de": "3:0",
        "name_nl": "3:0",
        "name_pl": "3:0",
        "name_ro": "3:0",
        "name_ru": "3:0",
        "name_tr": "3:0",
        "name_se": "3:0",
        "name_el": "3:0",
        "name_es": "3:0",
        "name_hr": "3:0",
        "name_fr": "3:0"
    },
    "2338^live_match^8^goal_kick": {
        "name_en": "Goal Kick",
        "name_aa": "Goal Kick",
        "name_de": "Goal Kick",
        "name_nl": "Goal Kick",
        "name_pl": "Goal Kick",
        "name_ro": "Goal Kick",
        "name_ru": "Goal Kick",
        "name_tr": "Goal Kick",
        "name_se": "Goal Kick",
        "name_el": "Goal Kick",
        "name_es": "Goal Kick",
        "name_hr": "Goal Kick",
        "name_fr": "Goal Kick"
    },
    "29^live_match^8^0:8": {
        "name_en": "0:8",
        "name_aa": "0:8",
        "name_de": "0:8",
        "name_nl": "0:8",
        "name_pl": "0:8",
        "name_ro": "0:8",
        "name_ru": "0:8",
        "name_tr": "0:8",
        "name_se": "0:8",
        "name_el": "0:8",
        "name_es": "0:8",
        "name_hr": "0:8",
        "name_fr": "0:8"
    },
    "956^live_match^6^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2331^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "1108^live_match^8^5:10": {
        "name_en": "5:10",
        "name_aa": "5:10",
        "name_de": "5:10",
        "name_nl": "5:10",
        "name_pl": "5:10",
        "name_ro": "5:10",
        "name_ru": "5:10",
        "name_tr": "5:10",
        "name_se": "5:10",
        "name_el": "5:10",
        "name_es": "5:10",
        "name_hr": "5:10",
        "name_fr": "5:10"
    },
    "96^live_match^8^player_1_to_0": {
        "name_en": "Player 1 to 0",
        "name_aa": "Player 1 to 0",
        "name_de": "Spieler 1 zu 0",
        "name_nl": "Speler 1 tot 0",
        "name_pl": "Player 1 to 0",
        "name_ro": "Player 1 to 0",
        "name_ru": "Player 1 to 0",
        "name_tr": "1. Oyuncu 0'a karşı",
        "name_se": "Player 1 to 0",
        "name_el": "Player 1 to 0",
        "name_es": "Player 1 to 0",
        "name_hr": "Igrač 1 osvojio gem (protivnik bez osvojenog poena)",
        "name_fr": "Joueur 1 à 0"
    },
    "2128^live_match^8^home_after_extra_points": {
        "name_en": "home after extra points",
        "name_aa": "home after extra points",
        "name_de": "Heimmannschaft Nach Verlängerung",
        "name_nl": "Thuisteam Na extra punten",
        "name_pl": "home after extra points",
        "name_ro": "home after extra points",
        "name_ru": "home after extra points",
        "name_tr": "home after extra points",
        "name_se": "home after extra points",
        "name_el": "home after extra points",
        "name_es": "home after extra points",
        "name_hr": "home after extra points",
        "name_fr": "home after extra points"
    },
    "2157^live_match^8^17:21": {
        "name_en": "17:21",
        "name_aa": "17:21",
        "name_de": "17:21",
        "name_nl": "17:21",
        "name_pl": "17:21",
        "name_ro": "17:21",
        "name_ru": "17:21",
        "name_tr": "17:21",
        "name_se": "17:21",
        "name_el": "17:21",
        "name_es": "17:21",
        "name_hr": "17:21",
        "name_fr": "17:21"
    },
    "2354^live_match^8^10:5": {
        "name_en": "10:5",
        "name_aa": "10:5",
        "name_de": "10:5",
        "name_nl": "10:5",
        "name_pl": "10:5",
        "name_ro": "10:5",
        "name_ru": "10:5",
        "name_tr": "10:5",
        "name_se": "10:5",
        "name_el": "10:5",
        "name_es": "10:5",
        "name_hr": "10:5",
        "name_fr": "10:5"
    },
    "2073^live_match^8^19:25": {
        "name_en": "19:25",
        "name_aa": "19:25",
        "name_de": "19:25",
        "name_nl": "19:25",
        "name_pl": "19:25",
        "name_ro": "19:25",
        "name_ru": "19:25",
        "name_tr": "19:25",
        "name_se": "19:25",
        "name_el": "19:25",
        "name_es": "19:25",
        "name_hr": "19:25",
        "name_fr": "19:25"
    },
    "2242^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "1040^live_match^8^away_and_yes": {
        "name_en": "away and yes",
        "name_aa": "away and yes",
        "name_de": "Auswärtsmannschaft und Ja",
        "name_nl": "uit en ja",
        "name_pl": "away and yes",
        "name_ro": "away and yes",
        "name_ru": "away and yes",
        "name_tr": "deplasman kazanır ve iki takımda gol atar",
        "name_se": "away and yes",
        "name_el": "away and yes",
        "name_es": "away and yes",
        "name_hr": "gosti i da",
        "name_fr": "Extérieur et Oui"
    },
    "2239^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "over",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "Üst",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "2238^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2305^live_match^8^1:7": {
        "name_en": "1:7",
        "name_aa": "1:7",
        "name_de": "1:7",
        "name_nl": "1:7",
        "name_pl": "1:7",
        "name_ro": "1:7",
        "name_ru": "1:7",
        "name_tr": "1:7",
        "name_se": "1:7",
        "name_el": "1:7",
        "name_es": "1:7",
        "name_hr": "1:7",
        "name_fr": "1:7"
    },
    "2158^live_match^8^no": {
        "name_en": "no",
        "name_aa": "no",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "no",
        "name_ro": "no",
        "name_ru": "no",
        "name_tr": "no",
        "name_se": "no",
        "name_el": "no",
        "name_es": "no",
        "name_hr": "Ne",
        "name_fr": "no"
    },
    "2045^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "910^live_match^8^under": {
        "name_en": "Under",
        "name_aa": "u",
        "name_de": "Unter",
        "name_nl": "u",
        "name_pl": "u",
        "name_ro": "u",
        "name_ru": "u",
        "name_tr": "Alt",
        "name_se": "u",
        "name_el": "u",
        "name_es": "u",
        "name_hr": "Manje",
        "name_fr": "m"
    },
    "2176^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "over",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "Üst",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "2354^live_match^8^2:15": {
        "name_en": "2:15",
        "name_aa": "2:15",
        "name_de": "2:15",
        "name_nl": "2:15",
        "name_pl": "2:15",
        "name_ro": "2:15",
        "name_ru": "2:15",
        "name_tr": "2:15",
        "name_se": "2:15",
        "name_el": "2:15",
        "name_es": "2:15",
        "name_hr": "2:15",
        "name_fr": "2:15"
    },
    "26^live_match^8^18:1": {
        "name_en": "18:1",
        "name_aa": "18:1",
        "name_de": "18:1",
        "name_nl": "18:1",
        "name_pl": "18:1",
        "name_ro": "18:1",
        "name_ru": "18:1",
        "name_tr": "18:1",
        "name_se": "18:1",
        "name_el": "18:1",
        "name_es": "18:1",
        "name_hr": "18:1",
        "name_fr": "18:1"
    },
    "2150^live_match^8^away": {
        "name_en": "away",
        "name_aa": "away",
        "name_de": "Auswärts",
        "name_nl": "Uit",
        "name_pl": "away",
        "name_ro": "away",
        "name_ru": "away",
        "name_tr": "away",
        "name_se": "away",
        "name_el": "away",
        "name_es": "away",
        "name_hr": "Gostujući",
        "name_fr": "away"
    },
    "26^live_match^8^3:17": {
        "name_en": "3:17",
        "name_aa": "3:17",
        "name_de": "3:17",
        "name_nl": "3:17",
        "name_pl": "3:17",
        "name_ro": "3:17",
        "name_ru": "3:17",
        "name_tr": "3:17",
        "name_se": "3:17",
        "name_el": "3:17",
        "name_es": "3:17",
        "name_hr": "3:17",
        "name_fr": "3:17"
    },
    "1278^live_match^8^over": {
        "name_en": "Over",
        "name_aa": "Over",
        "name_de": "Über",
        "name_nl": "Over",
        "name_pl": "Over",
        "name_ro": "Over",
        "name_ru": "Over",
        "name_tr": "Üst",
        "name_se": "Over",
        "name_el": "Over",
        "name_es": "Over",
        "name_hr": "Više",
        "name_fr": "p"
    },
    "26^live_match^8^8:2": {
        "name_en": "8:2",
        "name_aa": "8:2",
        "name_de": "8:2",
        "name_nl": "8:2",
        "name_pl": "8:2",
        "name_ro": "8:2",
        "name_ru": "8:2",
        "name_tr": "8:2",
        "name_se": "8:2",
        "name_el": "8:2",
        "name_es": "8:2",
        "name_hr": "8:2",
        "name_fr": "8:2"
    },
    "35^live_match^21^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "13^live_match^6^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2223^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "2052^live_match^6^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2156^live_match^8^away_3-5": {
        "name_en": "away 3-5",
        "name_aa": "away 3-5",
        "name_de": "Auswärt 3-5",
        "name_nl": "Weg 3-5",
        "name_pl": "away 3-5",
        "name_ro": "away 3-5",
        "name_ru": "away 3-5",
        "name_tr": "away 3-5",
        "name_se": "away 3-5",
        "name_el": "away 3-5",
        "name_es": "away 3-5",
        "name_hr": "Gostujiči 3-5",
        "name_fr": "away 3-5"
    },
    "29^live_match^8^9:0": {
        "name_en": "9:0",
        "name_aa": "9:0",
        "name_de": "9:0",
        "name_nl": "9:0",
        "name_pl": "9:0",
        "name_ro": "9:0",
        "name_ru": "9:0",
        "name_tr": "9:0",
        "name_se": "9:0",
        "name_el": "9:0",
        "name_es": "9:0",
        "name_hr": "9:0",
        "name_fr": "9:0"
    },
    "2243^live_match^8^no": {
        "name_en": "no",
        "name_aa": "no",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "no",
        "name_ro": "no",
        "name_ru": "no",
        "name_tr": "no",
        "name_se": "no",
        "name_el": "no",
        "name_es": "no",
        "name_hr": "Ne",
        "name_fr": "no"
    },
    "1326^live_match^8^none": {
        "name_en": "none",
        "name_aa": "none",
        "name_de": "Keiner",
        "name_nl": "Geen",
        "name_pl": "none",
        "name_ro": "none",
        "name_ru": "none",
        "name_tr": "none",
        "name_se": "none",
        "name_el": "none",
        "name_es": "none",
        "name_hr": "Nitko",
        "name_fr": "none"
    },
    "1484^live_match^8^over": {
        "name_en": "Over",
        "name_aa": "Over",
        "name_de": "Über",
        "name_nl": "Over",
        "name_pl": "Over",
        "name_ro": "Over",
        "name_ru": "Over",
        "name_tr": "Üst",
        "name_se": "Over",
        "name_el": "Over",
        "name_es": "Over",
        "name_hr": "Više",
        "name_fr": "p"
    },
    "26^live_match^8^0:8": {
        "name_en": "0:8",
        "name_aa": "0:8",
        "name_de": "0:8",
        "name_nl": "0:8",
        "name_pl": "0:8",
        "name_ro": "0:8",
        "name_ru": "0:8",
        "name_tr": "0:8",
        "name_se": "0:8",
        "name_el": "0:8",
        "name_es": "0:8",
        "name_hr": "0:8",
        "name_fr": "0:8"
    },
    "59^live_match^8^odd": {
        "name_en": "odd",
        "name_aa": "odd",
        "name_de": "Ungerade",
        "name_nl": "oneven",
        "name_pl": "odd",
        "name_ro": "odd",
        "name_ru": "odd",
        "name_tr": "tek",
        "name_se": "odd",
        "name_el": "odd",
        "name_es": "odd",
        "name_hr": "Neparan",
        "name_fr": "impair"
    },
    "2354^live_match^8^11:7": {
        "name_en": "11:7",
        "name_aa": "11:7",
        "name_de": "11:7",
        "name_nl": "11:7",
        "name_pl": "11:7",
        "name_ro": "11:7",
        "name_ru": "11:7",
        "name_tr": "11:7",
        "name_se": "11:7",
        "name_el": "11:7",
        "name_es": "11:7",
        "name_hr": "11:7",
        "name_fr": "11:7"
    },
    "1108^live_match^8^12:9": {
        "name_en": "12:9",
        "name_aa": "12:9",
        "name_de": "12:9",
        "name_nl": "12:9",
        "name_pl": "12:9",
        "name_ro": "12:9",
        "name_ru": "12:9",
        "name_tr": "12:9",
        "name_se": "12:9",
        "name_el": "12:9",
        "name_es": "12:9",
        "name_hr": "12:9",
        "name_fr": "12:9"
    },
    "26^live_match^8^1:18": {
        "name_en": "1:18",
        "name_aa": "1:18",
        "name_de": "1:18",
        "name_nl": "1:18",
        "name_pl": "1:18",
        "name_ro": "1:18",
        "name_ru": "1:18",
        "name_tr": "1:18",
        "name_se": "1:18",
        "name_el": "1:18",
        "name_es": "1:18",
        "name_hr": "1:18",
        "name_fr": "1:18"
    },
    "2350^live_match^8^no_goal": {
        "name_en": "no goal",
        "name_aa": "no goal",
        "name_de": "Kein Tor",
        "name_nl": "Geen doelpunt",
        "name_pl": "no goal",
        "name_ro": "no goal",
        "name_ru": "no goal",
        "name_tr": "no goal",
        "name_se": "no goal",
        "name_el": "no goal",
        "name_es": "no goal",
        "name_hr": "no Golova",
        "name_fr": "no goal"
    },
    "2006^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "1244^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "1108^live_match^8^3:6": {
        "name_en": "3:6",
        "name_aa": "3:6",
        "name_de": "3:6",
        "name_nl": "3:6",
        "name_pl": "3:6",
        "name_ro": "3:6",
        "name_ru": "3:6",
        "name_tr": "3:6",
        "name_se": "3:6",
        "name_el": "3:6",
        "name_es": "3:6",
        "name_hr": "3:6",
        "name_fr": "3:6"
    },
    "29^live_match^8^0:10": {
        "name_en": "0:10",
        "name_aa": "0:10",
        "name_de": "0:10",
        "name_nl": "0:10",
        "name_pl": "0:10",
        "name_ro": "0:10",
        "name_ru": "0:10",
        "name_tr": "0:10",
        "name_se": "0:10",
        "name_el": "0:10",
        "name_es": "0:10",
        "name_hr": "0:10",
        "name_fr": "0:10"
    },
    "26^live_match^8^15:3": {
        "name_en": "15:3",
        "name_aa": "15:3",
        "name_de": "15:3",
        "name_nl": "15:3",
        "name_pl": "15:3",
        "name_ro": "15:3",
        "name_ru": "15:3",
        "name_tr": "15:3",
        "name_se": "15:3",
        "name_el": "15:3",
        "name_es": "15:3",
        "name_hr": "15:3",
        "name_fr": "15:3"
    },
    "29^live_match^8^2:10": {
        "name_en": "2:10",
        "name_aa": "2:10",
        "name_de": "2:10",
        "name_nl": "2:10",
        "name_pl": "2:10",
        "name_ro": "2:10",
        "name_ru": "2:10",
        "name_tr": "2:10",
        "name_se": "2:10",
        "name_el": "2:10",
        "name_es": "2:10",
        "name_hr": "2:10",
        "name_fr": "2:10"
    },
    "2330^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "29^live_match^8^6:2": {
        "name_en": "6:2",
        "name_aa": "6:2",
        "name_de": "6:2",
        "name_nl": "6:2",
        "name_pl": "6:2",
        "name_ro": "6:2",
        "name_ru": "6:2",
        "name_tr": "6:2",
        "name_se": "6:2",
        "name_el": "6:2",
        "name_es": "6:2",
        "name_hr": "6:2",
        "name_fr": "6:2"
    },
    "2073^live_match^8^21:15": {
        "name_en": "21:15",
        "name_aa": "21:15",
        "name_de": "21:15",
        "name_nl": "21:15",
        "name_pl": "21:15",
        "name_ro": "21:15",
        "name_ru": "21:15",
        "name_tr": "21:15",
        "name_se": "21:15",
        "name_el": "21:15",
        "name_es": "21:15",
        "name_hr": "21:15",
        "name_fr": "21:15"
    },
    "26^live_match^8^1:7": {
        "name_en": "1:7",
        "name_aa": "1:7",
        "name_de": "1:7",
        "name_nl": "1:7",
        "name_pl": "1:7",
        "name_ro": "1:7",
        "name_ru": "1:7",
        "name_tr": "1:7",
        "name_se": "1:7",
        "name_el": "1:7",
        "name_es": "1:7",
        "name_hr": "1:7",
        "name_fr": "1:7"
    },
    "2081^live_match^8^6:2": {
        "name_en": "6:2",
        "name_aa": "6:2",
        "name_de": "6:2",
        "name_nl": "6:2",
        "name_pl": "6:2",
        "name_ro": "6:2",
        "name_ru": "6:2",
        "name_tr": "6:2",
        "name_se": "6:2",
        "name_el": "6:2",
        "name_es": "6:2",
        "name_hr": "6:2",
        "name_fr": "6:2"
    },
    "29^live_match^8^10:0": {
        "name_en": "10:0",
        "name_aa": "10:0",
        "name_de": "10:0",
        "name_nl": "10:0",
        "name_pl": "10:0",
        "name_ro": "10:0",
        "name_ru": "10:0",
        "name_tr": "10:0",
        "name_se": "10:0",
        "name_el": "10:0",
        "name_es": "10:0",
        "name_hr": "10:0",
        "name_fr": "10:0"
    },
    "2354^live_match^8^8:3": {
        "name_en": "8:3",
        "name_aa": "8:3",
        "name_de": "8:3",
        "name_nl": "8:3",
        "name_pl": "8:3",
        "name_ro": "8:3",
        "name_ru": "8:3",
        "name_tr": "8:3",
        "name_se": "8:3",
        "name_el": "8:3",
        "name_es": "8:3",
        "name_hr": "8:3",
        "name_fr": "8:3"
    },
    "2039^live_match^8^yes": {
        "name_en": "yes",
        "name_aa": "yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "yes",
        "name_ro": "yes",
        "name_ru": "yes",
        "name_tr": "yes",
        "name_se": "yes",
        "name_el": "yes",
        "name_es": "yes",
        "name_hr": "Da",
        "name_fr": "yes"
    },
    "2213^live_match^8^away_1-6": {
        "name_en": "away 1-6",
        "name_aa": "away 1-6",
        "name_de": "Auswärt 1-6",
        "name_nl": "Weg 1-6",
        "name_pl": "away 1-6",
        "name_ro": "away 1-6",
        "name_ru": "away 1-6",
        "name_tr": "away 1-6",
        "name_se": "away 1-6",
        "name_el": "away 1-6",
        "name_es": "away 1-6",
        "name_hr": "Gostujuči 1-6",
        "name_fr": "away 1-6"
    },
    "19^live_match^8^0:3": {
        "name_en": "0:3",
        "name_aa": "0:3",
        "name_de": "0:3",
        "name_nl": "0:3",
        "name_pl": "0:3",
        "name_ro": "0:3",
        "name_ru": "0:3",
        "name_tr": "0:3",
        "name_se": "0:3",
        "name_el": "0:3",
        "name_es": "0:3",
        "name_hr": "0:3",
        "name_fr": "0:3"
    },
    "986^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "520^live_match^8^4:2": {
        "name_en": "4:2",
        "name_aa": "4:2",
        "name_de": "4:2",
        "name_nl": "4:2",
        "name_pl": "4:2",
        "name_ro": "4:2",
        "name_ru": "4:2",
        "name_tr": "4:2",
        "name_se": "4:2",
        "name_el": "4:2",
        "name_es": "4:2",
        "name_hr": "4:2",
        "name_fr": "4:2"
    },
    "2354^live_match^8^5:3": {
        "name_en": "5:3",
        "name_aa": "5:3",
        "name_de": "5:3",
        "name_nl": "5:3",
        "name_pl": "5:3",
        "name_ro": "5:3",
        "name_ru": "5:3",
        "name_tr": "5:3",
        "name_se": "5:3",
        "name_el": "5:3",
        "name_es": "5:3",
        "name_hr": "5:3",
        "name_fr": "5:3"
    },
    "2011^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "74^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2327^live_match^8^yes": {
        "name_en": "yes",
        "name_aa": "yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "yes",
        "name_ro": "yes",
        "name_ru": "yes",
        "name_tr": "yes",
        "name_se": "yes",
        "name_el": "yes",
        "name_es": "yes",
        "name_hr": "Da",
        "name_fr": "yes"
    },
    "1208^live_match^8^red": {
        "name_en": "red",
        "name_aa": "red",
        "name_de": "rot",
        "name_nl": "rode",
        "name_pl": "red",
        "name_ro": "red",
        "name_ru": "red",
        "name_tr": "kırmızı",
        "name_se": "red",
        "name_el": "red",
        "name_es": "red",
        "name_hr": "crvena",
        "name_fr": "rouge"
    },
    "2074^live_match^8^25:16": {
        "name_en": "25:16",
        "name_aa": "25:16",
        "name_de": "25:16",
        "name_nl": "25:16",
        "name_pl": "25:16",
        "name_ro": "25:16",
        "name_ru": "25:16",
        "name_tr": "25:16",
        "name_se": "25:16",
        "name_el": "25:16",
        "name_es": "25:16",
        "name_hr": "25:16",
        "name_fr": "25:16"
    },
    "2073^live_match^8^16:21": {
        "name_en": "16:21",
        "name_aa": "16:21",
        "name_de": "16:21",
        "name_nl": "16:21",
        "name_pl": "16:21",
        "name_ro": "16:21",
        "name_ru": "16:21",
        "name_tr": "16:21",
        "name_se": "16:21",
        "name_el": "16:21",
        "name_es": "16:21",
        "name_hr": "16:21",
        "name_fr": "16:21"
    },
    "2167^live_match^8^2/1": {
        "name_en": "2/1",
        "name_aa": "2/1",
        "name_de": "2/1",
        "name_nl": "2/1",
        "name_pl": "2/1",
        "name_ro": "2/1",
        "name_ru": "2/1",
        "name_tr": "2/1",
        "name_se": "2/1",
        "name_el": "2/1",
        "name_es": "2/1",
        "name_hr": "2/1",
        "name_fr": "2/1"
    },
    "2156^live_match^8^away_1-2": {
        "name_en": "away 1-2",
        "name_aa": "away 1-2",
        "name_de": "Auswärt 1-2",
        "name_nl": "Weg 1-2",
        "name_pl": "away 1-2",
        "name_ro": "away 1-2",
        "name_ru": "away 1-2",
        "name_tr": "away 1-2",
        "name_se": "away 1-2",
        "name_el": "away 1-2",
        "name_es": "away 1-2",
        "name_hr": "Gostujiči 1-2",
        "name_fr": "away 1-2"
    },
    "2156^live_match^8^home_3-5": {
        "name_en": "home 3-5",
        "name_aa": "home 3-5",
        "name_de": "Heim 3-5",
        "name_nl": "Thuis 3-5",
        "name_pl": "home 3-5",
        "name_ro": "home 3-5",
        "name_ru": "home 3-5",
        "name_tr": "home 3-5",
        "name_se": "home 3-5",
        "name_el": "home 3-5",
        "name_es": "home 3-5",
        "name_hr": "Domači 3-5",
        "name_fr": "home 3-5"
    },
    "116^live_match^8^4:0": {
        "name_en": "4:0",
        "name_aa": "4:0",
        "name_de": "4:0",
        "name_nl": "4:0",
        "name_pl": "4:0",
        "name_ro": "4:0",
        "name_ru": "4:0",
        "name_tr": "4:0",
        "name_se": "4:0",
        "name_el": "4:0",
        "name_es": "4:0",
        "name_hr": "4:0",
        "name_fr": "4:0"
    },
    "26^live_match^8^2:1": {
        "name_en": "2:1",
        "name_aa": "2:1",
        "name_de": "2:1",
        "name_nl": "2:1",
        "name_pl": "2:1",
        "name_ro": "2:1",
        "name_ru": "2:1",
        "name_tr": "2:1",
        "name_se": "2:1",
        "name_el": "2:1",
        "name_es": "2:1",
        "name_hr": "2:1",
        "name_fr": "2:1"
    },
    "29^live_match^8^2:0": {
        "name_en": "2:0",
        "name_aa": "2:0",
        "name_de": "2:0",
        "name_nl": "2:0",
        "name_pl": "2:0",
        "name_ro": "2:0",
        "name_ru": "2:0",
        "name_tr": "2:0",
        "name_se": "2:0",
        "name_el": "2:0",
        "name_es": "2:0",
        "name_hr": "2:0",
        "name_fr": "2:0"
    },
    "2062^live_match^8^home_11+": {
        "name_en": "home 11+",
        "name_aa": "home 11+",
        "name_de": "Heim 11+",
        "name_nl": "thuis 11+",
        "name_pl": "home 11+",
        "name_ro": "home 11+",
        "name_ru": "home 11+",
        "name_tr": "home 11+",
        "name_se": "home 11+",
        "name_el": "home 11+",
        "name_es": "home 11+",
        "name_hr": "Domači 11+",
        "name_fr": "home 11+"
    },
    "2058^live_match^8^dh": {
        "name_en": "DH",
        "name_aa": "DH",
        "name_de": "DH",
        "name_nl": "DH",
        "name_pl": "DH",
        "name_ro": "DH",
        "name_ru": "DH",
        "name_tr": "DH",
        "name_se": "DH",
        "name_el": "DH",
        "name_es": "DH",
        "name_hr": "DH",
        "name_fr": "DH"
    },
    "29^live_match^8^2:4": {
        "name_en": "2:4",
        "name_aa": "2:4",
        "name_de": "2:4",
        "name_nl": "2:4",
        "name_pl": "2:4",
        "name_ro": "2:4",
        "name_ru": "2:4",
        "name_tr": "2:4",
        "name_se": "2:4",
        "name_el": "2:4",
        "name_es": "2:4",
        "name_hr": "2:4",
        "name_fr": "2:4"
    },
    "2213^live_match^8^away_25-30": {
        "name_en": "away 25-30",
        "name_aa": "away 25-30",
        "name_de": "Auswärt 25-30",
        "name_nl": "Weg 25-30",
        "name_pl": "away 25-30",
        "name_ro": "away 25-30",
        "name_ru": "away 25-30",
        "name_tr": "away 25-30",
        "name_se": "away 25-30",
        "name_el": "away 25-30",
        "name_es": "away 25-30",
        "name_hr": "Gostujuči 25-30",
        "name_fr": "away 25-30"
    },
    "2021^live_match^8^neither": {
        "name_en": "neither",
        "name_aa": "neither",
        "name_de": "Keiner",
        "name_nl": "Geen van beide",
        "name_pl": "neither",
        "name_ro": "neither",
        "name_ru": "neither",
        "name_tr": "neither",
        "name_se": "neither",
        "name_el": "neither",
        "name_es": "neither",
        "name_hr": "Ni",
        "name_fr": "neither"
    },
    "2000^live_match^8^yes": {
        "name_en": "yes",
        "name_aa": "yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "yes",
        "name_ro": "yes",
        "name_ru": "yes",
        "name_tr": "yes",
        "name_se": "yes",
        "name_el": "yes",
        "name_es": "yes",
        "name_hr": "Da",
        "name_fr": "yes"
    },
    "37^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2057^live_match^8^12": {
        "name_en": "12",
        "name_aa": "12",
        "name_de": "12",
        "name_nl": "12",
        "name_pl": "12",
        "name_ro": "12",
        "name_ru": "12",
        "name_tr": "12",
        "name_se": "12",
        "name_el": "12",
        "name_es": "12",
        "name_hr": "12",
        "name_fr": "12"
    },
    "1108^live_match^8^5:7": {
        "name_en": "5:7",
        "name_aa": "5:7",
        "name_de": "5:7",
        "name_nl": "5:7",
        "name_pl": "5:7",
        "name_ro": "5:7",
        "name_ru": "5:7",
        "name_tr": "5:7",
        "name_se": "5:7",
        "name_el": "5:7",
        "name_es": "5:7",
        "name_hr": "5:7",
        "name_fr": "5:7"
    },
    "2131^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2075^live_match^8^25:17": {
        "name_en": "25:17",
        "name_aa": "25:17",
        "name_de": "25:17",
        "name_nl": "25:17",
        "name_pl": "25:17",
        "name_ro": "25:17",
        "name_ru": "25:17",
        "name_tr": "25:17",
        "name_se": "25:17",
        "name_el": "25:17",
        "name_es": "25:17",
        "name_hr": "25:17",
        "name_fr": "25:17"
    },
    "96^live_match^8^player_1_to_40": {
        "name_en": "Player 1 to 40",
        "name_aa": "Player 1 to 40",
        "name_de": "Spieler 1 zu 40",
        "name_nl": "Speler 1 tot 40",
        "name_pl": "Player 1 to 40",
        "name_ro": "Player 1 to 40",
        "name_ru": "Player 1 to 40",
        "name_tr": "1. Oyuncu 40'a karşı",
        "name_se": "Player 1 to 40",
        "name_el": "Player 1 to 40",
        "name_es": "Player 1 to 40",
        "name_hr": "Igrač 1 osvojio gem (protivnik osvojio 40 poena)",
        "name_fr": "Joueur 1 à 40"
    },
    "2313^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "1156^live_match^8^2_by_any_other_score": {
        "name_en": "2 by Any Other Score",
        "name_aa": "2 by Any Other Score",
        "name_de": "2 durch jedes andere Ergebnis",
        "name_nl": "2 door een andere score",
        "name_pl": "2 by Any Other Score",
        "name_ro": "2 by Any Other Score",
        "name_ru": "2 by Any Other Score",
        "name_tr": "2 by Any Other Score",
        "name_se": "2 by Any Other Score",
        "name_el": "2 by Any Other Score",
        "name_es": "2 by Any Other Score",
        "name_hr": "2 po bilo ostali rezultat",
        "name_fr": "2 by Any Other Score"
    },
    "2327^live_match^8^no": {
        "name_en": "no",
        "name_aa": "no",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "no",
        "name_ro": "no",
        "name_ru": "no",
        "name_tr": "no",
        "name_se": "no",
        "name_el": "no",
        "name_es": "no",
        "name_hr": "Ne",
        "name_fr": "no"
    },
    "26^live_match^8^2:8": {
        "name_en": "2:8",
        "name_aa": "2:8",
        "name_de": "2:8",
        "name_nl": "2:8",
        "name_pl": "2:8",
        "name_ro": "2:8",
        "name_ru": "2:8",
        "name_tr": "2:8",
        "name_se": "2:8",
        "name_el": "2:8",
        "name_es": "2:8",
        "name_hr": "2:8",
        "name_fr": "2:8"
    },
    "1320^live_match^8^none": {
        "name_en": "none",
        "name_aa": "none",
        "name_de": "Keiner",
        "name_nl": "Geen",
        "name_pl": "none",
        "name_ro": "none",
        "name_ru": "none",
        "name_tr": "none",
        "name_se": "none",
        "name_el": "none",
        "name_es": "none",
        "name_hr": "Nitko",
        "name_fr": "none"
    },
    "2090^live_match^8^player_2_win_to_love": {
        "name_en": "Player 2 Win to love",
        "name_aa": "Player 2 Win to love",
        "name_de": "Player 2 Gewinnt zu null",
        "name_nl": "Player 2 wint op love",
        "name_pl": "Player 2 Win to love",
        "name_ro": "Player 2 Win to love",
        "name_ru": "Player 2 Win to love",
        "name_tr": "Player 2 Win to love",
        "name_se": "Player 2 Win to love",
        "name_el": "Player 2 Win to love",
        "name_es": "Player 2 Win to love",
        "name_hr": "Player 2 Win to love",
        "name_fr": "Player 2 Win to love"
    },
    "2230^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "183^live_match^8^home_and_under": {
        "name_en": "home and under",
        "name_aa": "home and under",
        "name_de": "Heimmannschaft und unter",
        "name_nl": "thuis en onder",
        "name_pl": "home and under",
        "name_ro": "home and under",
        "name_ru": "home and under",
        "name_tr": "ev sahibi ve alt",
        "name_se": "home and under",
        "name_el": "home and under",
        "name_es": "home and under",
        "name_hr": "domaćin i manje",
        "name_fr": "Domicile et Moins de"
    },
    "230^live_match^8^home_ot": {
        "name_en": "Home OT",
        "name_aa": "Home OT",
        "name_de": "Verlängerung - Heimmannschaft",
        "name_nl": "Thuis ET",
        "name_pl": "Home OT",
        "name_ro": "Home OT",
        "name_ru": "Home OT",
        "name_tr": "uzatmalar sonunda ev sahibi",
        "name_se": "Home OT",
        "name_el": "Home OT",
        "name_es": "Home OT",
        "name_hr": "Pobjeda domaćih nakon produžetka",
        "name_fr": "Domicile Prolong."
    },
    "2048^live_match^8^ad": {
        "name_en": "AD",
        "name_aa": "AD",
        "name_de": "AD",
        "name_nl": "AD",
        "name_pl": "AD",
        "name_ro": "AD",
        "name_ru": "AD",
        "name_tr": "AD",
        "name_se": "AD",
        "name_el": "AD",
        "name_es": "AD",
        "name_hr": "AD",
        "name_fr": "AD"
    },
    "2060^live_match^8^home_7-8": {
        "name_en": "home 7-8",
        "name_aa": "home 7-8",
        "name_de": "Heim 7-8",
        "name_nl": "thuis 7-8",
        "name_pl": "home 7-8",
        "name_ro": "home 7-8",
        "name_ru": "home 7-8",
        "name_tr": "home 7-8",
        "name_se": "home 7-8",
        "name_el": "home 7-8",
        "name_es": "home 7-8",
        "name_hr": "Domači 7-8",
        "name_fr": "home 7-8"
    },
    "96^live_match^8^player_1_to_15": {
        "name_en": "Player 1 to 15",
        "name_aa": "Player 1 to 15",
        "name_de": "Spieler 1 zu 15",
        "name_nl": "Speler 1 tot 15",
        "name_pl": "Player 1 to 15",
        "name_ro": "Player 1 to 15",
        "name_ru": "Player 1 to 15",
        "name_tr": "1. Oyuncu 15'e karşı",
        "name_se": "Player 1 to 15",
        "name_el": "Player 1 to 15",
        "name_es": "Player 1 to 15",
        "name_hr": "Igrač 1 osvojio gem (protivnik osvojio 15 poena)",
        "name_fr": "Joueur 1 à 15"
    },
    "1108^live_match^8^2:12": {
        "name_en": "2:12",
        "name_aa": "2:12",
        "name_de": "2:12",
        "name_nl": "2:12",
        "name_pl": "2:12",
        "name_ro": "2:12",
        "name_ru": "2:12",
        "name_tr": "2:12",
        "name_se": "2:12",
        "name_el": "2:12",
        "name_es": "2:12",
        "name_hr": "2:12",
        "name_fr": "2:12"
    },
    "2061^live_match^8^home_1-2": {
        "name_en": "home 1-2",
        "name_aa": "home 1-2",
        "name_de": "Heim 1-2",
        "name_nl": "Thuis 1-2",
        "name_pl": "home 1-2",
        "name_ro": "home 1-2",
        "name_ru": "home 1-2",
        "name_tr": "home 1-2",
        "name_se": "home 1-2",
        "name_el": "home 1-2",
        "name_es": "home 1-2",
        "name_hr": "Domači 1-2",
        "name_fr": "home 1-2"
    },
    "2354^live_match^8^4:4": {
        "name_en": "4:4",
        "name_aa": "4:4",
        "name_de": "4:4",
        "name_nl": "4:4",
        "name_pl": "4:4",
        "name_ro": "4:4",
        "name_ru": "4:4",
        "name_tr": "4:4",
        "name_se": "4:4",
        "name_el": "4:4",
        "name_es": "4:4",
        "name_hr": "4:4",
        "name_fr": "4:4"
    },
    "2256^live_match^8^no": {
        "name_en": "no",
        "name_aa": "no",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "no",
        "name_ro": "no",
        "name_ru": "no",
        "name_tr": "no",
        "name_se": "no",
        "name_el": "no",
        "name_es": "no",
        "name_hr": "Ne",
        "name_fr": "no"
    },
    "2141^live_match^8^away": {
        "name_en": "away",
        "name_aa": "away",
        "name_de": "Auswärts",
        "name_nl": "Uit",
        "name_pl": "away",
        "name_ro": "away",
        "name_ru": "away",
        "name_tr": "away",
        "name_se": "away",
        "name_el": "away",
        "name_es": "away",
        "name_hr": "Gostujući",
        "name_fr": "away"
    },
    "2207^live_match^8^away_13-24": {
        "name_en": "away 13-24",
        "name_aa": "away 13-24",
        "name_de": "Auswärt 13-24",
        "name_nl": "Weg 13-24",
        "name_pl": "away 13-24",
        "name_ro": "away 13-24",
        "name_ru": "away 13-24",
        "name_tr": "away 13-24",
        "name_se": "away 13-24",
        "name_el": "away 13-24",
        "name_es": "away 13-24",
        "name_hr": "Gostujuči 13-24",
        "name_fr": "away 13-24"
    },
    "2073^live_match^8^21:11_or_better": {
        "name_en": "21:11 or better",
        "name_aa": "21:11 or better",
        "name_de": "21:11 oder besser",
        "name_nl": "21:11 of beter",
        "name_pl": "21:11 or better",
        "name_ro": "21:11 or better",
        "name_ru": "21:11 or better",
        "name_tr": "21:11 or better",
        "name_se": "21:11 or better",
        "name_el": "21:11 or better",
        "name_es": "21:11 or better",
        "name_hr": "21:11 or better",
        "name_fr": "21:11 or better"
    },
    "106^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "29^live_match^8^0:3": {
        "name_en": "0:3",
        "name_aa": "0:3",
        "name_de": "0:3",
        "name_nl": "0:3",
        "name_pl": "0:3",
        "name_ro": "0:3",
        "name_ru": "0:3",
        "name_tr": "0:3",
        "name_se": "0:3",
        "name_el": "0:3",
        "name_es": "0:3",
        "name_hr": "0:3",
        "name_fr": "0:3"
    },
    "2072^live_match^8^21:12": {
        "name_en": "21:12",
        "name_aa": "21:12",
        "name_de": "21:12",
        "name_nl": "21:12",
        "name_pl": "21:12",
        "name_ro": "21:12",
        "name_ru": "21:12",
        "name_tr": "21:12",
        "name_se": "21:12",
        "name_el": "21:12",
        "name_es": "21:12",
        "name_hr": "21:12",
        "name_fr": "21:12"
    },
    "2192^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "1672^live_match^8^yes": {
        "name_en": "yes",
        "name_aa": "yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "yes",
        "name_ro": "yes",
        "name_ru": "yes",
        "name_tr": "yes",
        "name_se": "yes",
        "name_el": "yes",
        "name_es": "yes",
        "name_hr": "Da",
        "name_fr": "yes"
    },
    "2210^live_match^8^12-13": {
        "name_en": "home 12-13",
        "name_aa": "home 12-13",
        "name_de": "Heim 12-13",
        "name_nl": "Thuis 12-13",
        "name_pl": "home 12-13",
        "name_ro": "home 12-13",
        "name_ru": "home 12-13",
        "name_tr": "home 12-13",
        "name_se": "home 12-13",
        "name_el": "home 12-13",
        "name_es": "home 12-13",
        "name_hr": "Domači 12-13",
        "name_fr": "home 12-13"
    },
    "26^live_match^8^3:15": {
        "name_en": "3:15",
        "name_aa": "3:15",
        "name_de": "3:15",
        "name_nl": "3:15",
        "name_pl": "3:15",
        "name_ro": "3:15",
        "name_ru": "3:15",
        "name_tr": "3:15",
        "name_se": "3:15",
        "name_el": "3:15",
        "name_es": "3:15",
        "name_hr": "3:15",
        "name_fr": "3:15"
    },
    "26^live_match^8^7:4": {
        "name_en": "7:4",
        "name_aa": "7:4",
        "name_de": "7:4",
        "name_nl": "7:4",
        "name_pl": "7:4",
        "name_ro": "7:4",
        "name_ru": "7:4",
        "name_tr": "7:4",
        "name_se": "7:4",
        "name_el": "7:4",
        "name_es": "7:4",
        "name_hr": "7:4",
        "name_fr": "7:4"
    },
    "2157^live_match^8^14:21": {
        "name_en": "14:21",
        "name_aa": "14:21",
        "name_de": "14:21",
        "name_nl": "14:21",
        "name_pl": "14:21",
        "name_ro": "14:21",
        "name_ru": "14:21",
        "name_tr": "14:21",
        "name_se": "14:21",
        "name_el": "14:21",
        "name_es": "14:21",
        "name_hr": "14:21",
        "name_fr": "14:21"
    },
    "2030^live_match^8^exactly": {
        "name_en": "exactly",
        "name_aa": "exactly",
        "name_de": "Genau",
        "name_nl": "Precies",
        "name_pl": "exactly",
        "name_ro": "exactly",
        "name_ru": "exactly",
        "name_tr": "exactly",
        "name_se": "exactly",
        "name_el": "exactly",
        "name_es": "exactly",
        "name_hr": "Tacno",
        "name_fr": "exactly"
    },
    "2081^live_match^8^4:1": {
        "name_en": "4:1",
        "name_aa": "4:1",
        "name_de": "4:1",
        "name_nl": "4:1",
        "name_pl": "4:1",
        "name_ro": "4:1",
        "name_ru": "4:1",
        "name_tr": "4:1",
        "name_se": "4:1",
        "name_el": "4:1",
        "name_es": "4:1",
        "name_hr": "4:1",
        "name_fr": "4:1"
    },
    "2145^live_match^8^home_13-16": {
        "name_en": "home 13-16",
        "name_aa": "home 13-16",
        "name_de": "Heim 13-16",
        "name_nl": "Thuis 13-16",
        "name_pl": "home 13-16",
        "name_ro": "home 13-16",
        "name_ru": "home 13-16",
        "name_tr": "home 13-16",
        "name_se": "home 13-16",
        "name_el": "home 13-16",
        "name_es": "home 13-16",
        "name_hr": "Domači 13-16",
        "name_fr": "home 13-16"
    },
    "898^live_match^6^x": {
        "name_en": "x",
        "name_aa": "x",
        "name_de": "x",
        "name_nl": "x",
        "name_pl": "x",
        "name_ro": "x",
        "name_ru": "x",
        "name_tr": "x",
        "name_se": "x",
        "name_el": "x",
        "name_es": "x",
        "name_hr": "x",
        "name_fr": "x"
    },
    "1156^live_match^8^3:4": {
        "name_en": "3:4",
        "name_aa": "3:4",
        "name_de": "3:4",
        "name_nl": "3:4",
        "name_pl": "3:4",
        "name_ro": "3:4",
        "name_ru": "3:4",
        "name_tr": "3:4",
        "name_se": "3:4",
        "name_el": "3:4",
        "name_es": "3:4",
        "name_hr": "3:4",
        "name_fr": "3:4"
    },
    "2162^live_match^8^2/2": {
        "name_en": "2/2",
        "name_aa": "2/2",
        "name_de": "2/2",
        "name_nl": "2/2",
        "name_pl": "2/2",
        "name_ro": "2/2",
        "name_ru": "2/2",
        "name_tr": "2/2",
        "name_se": "2/2",
        "name_el": "2/2",
        "name_es": "2/2",
        "name_hr": "2/2",
        "name_fr": "2/2"
    },
    "1661^live_match^2^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "1660^live_match^2^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2327^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "2031^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "341^live_match^8^1:4": {
        "name_en": "1:4",
        "name_aa": "1:4",
        "name_de": "1:4",
        "name_nl": "1:4",
        "name_pl": "1:4",
        "name_ro": "1:4",
        "name_ru": "1:4",
        "name_tr": "1:4",
        "name_se": "1:4",
        "name_el": "1:4",
        "name_es": "1:4",
        "name_hr": "1:4",
        "name_fr": "1:4"
    },
    "2095^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2073^live_match^8^15:21": {
        "name_en": "15:21",
        "name_aa": "15:21",
        "name_de": "15:21",
        "name_nl": "15:21",
        "name_pl": "15:21",
        "name_ro": "15:21",
        "name_ru": "15:21",
        "name_tr": "15:21",
        "name_se": "15:21",
        "name_el": "15:21",
        "name_es": "15:21",
        "name_hr": "15:21",
        "name_fr": "15:21"
    },
    "2157^live_match^8^21:16": {
        "name_en": "21:16",
        "name_aa": "21:16",
        "name_de": "21:16",
        "name_nl": "21:16",
        "name_pl": "21:16",
        "name_ro": "21:16",
        "name_ru": "21:16",
        "name_tr": "21:16",
        "name_se": "21:16",
        "name_el": "21:16",
        "name_es": "21:16",
        "name_hr": "21:16",
        "name_fr": "21:16"
    },
    "26^live_match^8^2:18": {
        "name_en": "2:18",
        "name_aa": "2:18",
        "name_de": "2:18",
        "name_nl": "2:18",
        "name_pl": "2:18",
        "name_ro": "2:18",
        "name_ru": "2:18",
        "name_tr": "2:18",
        "name_se": "2:18",
        "name_el": "2:18",
        "name_es": "2:18",
        "name_hr": "2:18",
        "name_fr": "2:18"
    },
    "2076^live_match^8^13:15": {
        "name_en": "13:15",
        "name_aa": "13:15",
        "name_de": "13:15",
        "name_nl": "13:15",
        "name_pl": "13:15",
        "name_ro": "13:15",
        "name_ru": "13:15",
        "name_tr": "13:15",
        "name_se": "13:15",
        "name_el": "13:15",
        "name_es": "13:15",
        "name_hr": "13:15",
        "name_fr": "13:15"
    },
    "2261^live_match^8^tie": {
        "name_en": "Tie",
        "name_aa": "Tie",
        "name_de": "Unentschieden",
        "name_nl": "Gelijkspel",
        "name_pl": "Tie",
        "name_ro": "Tie",
        "name_ru": "Tie",
        "name_tr": "Tie",
        "name_se": "Tie",
        "name_el": "Tie",
        "name_es": "Tie",
        "name_hr": "Tie",
        "name_fr": "Tie"
    },
    "1108^live_match^8^10:7": {
        "name_en": "10:7",
        "name_aa": "10:7",
        "name_de": "10:7",
        "name_nl": "10:7",
        "name_pl": "10:7",
        "name_ro": "10:7",
        "name_ru": "10:7",
        "name_tr": "10:7",
        "name_se": "10:7",
        "name_el": "10:7",
        "name_es": "10:7",
        "name_hr": "10:7",
        "name_fr": "10:7"
    },
    "2245^live_match^8^neither": {
        "name_en": "neither",
        "name_aa": "neither",
        "name_de": "Keiner",
        "name_nl": "Geen van beide",
        "name_pl": "neither",
        "name_ro": "neither",
        "name_ru": "neither",
        "name_tr": "neither",
        "name_se": "neither",
        "name_el": "neither",
        "name_es": "neither",
        "name_hr": "Ni",
        "name_fr": "neither"
    },
    "2059^live_match^8^home_4-6": {
        "name_en": "home 4-6",
        "name_aa": "home 4-6",
        "name_de": "Heim 4-6",
        "name_nl": "thuis 4-6",
        "name_pl": "home 4-6",
        "name_ro": "home 4-6",
        "name_ru": "home 4-6",
        "name_tr": "home 4-6",
        "name_se": "home 4-6",
        "name_el": "home 4-6",
        "name_es": "home 4-6",
        "name_hr": "Domači 4-6",
        "name_fr": "home 4-6"
    },
    "2328^live_match^8^miss": {
        "name_en": "miss",
        "name_aa": "miss",
        "name_de": "miss",
        "name_nl": "miss",
        "name_pl": "miss",
        "name_ro": "miss",
        "name_ru": "miss",
        "name_tr": "miss",
        "name_se": "miss",
        "name_el": "miss",
        "name_es": "miss",
        "name_hr": "miss",
        "name_fr": "miss"
    },
    "2072^live_match^8^22:25": {
        "name_en": "22:25",
        "name_aa": "22:25",
        "name_de": "22:25",
        "name_nl": "22:25",
        "name_pl": "22:25",
        "name_ro": "22:25",
        "name_ru": "22:25",
        "name_tr": "22:25",
        "name_se": "22:25",
        "name_el": "22:25",
        "name_es": "22:25",
        "name_hr": "22:25",
        "name_fr": "22:25"
    },
    "1108^live_match^8^4:0": {
        "name_en": "4:0",
        "name_aa": "4:0",
        "name_de": "4:0",
        "name_nl": "4:0",
        "name_pl": "4:0",
        "name_ro": "4:0",
        "name_ru": "4:0",
        "name_tr": "4:0",
        "name_se": "4:0",
        "name_el": "4:0",
        "name_es": "4:0",
        "name_hr": "4:0",
        "name_fr": "4:0"
    },
    "1108^live_match^8^2:11": {
        "name_en": "2:11",
        "name_aa": "2:11",
        "name_de": "2:11",
        "name_nl": "2:11",
        "name_pl": "2:11",
        "name_ro": "2:11",
        "name_ru": "2:11",
        "name_tr": "2:11",
        "name_se": "2:11",
        "name_el": "2:11",
        "name_es": "2:11",
        "name_hr": "2:11",
        "name_fr": "2:11"
    },
    "39^live_match^8^over": {
        "name_en": "Over",
        "name_aa": "Over",
        "name_de": "Über",
        "name_nl": "Over",
        "name_pl": "Over",
        "name_ro": "Over",
        "name_ru": "Over",
        "name_tr": "Üst",
        "name_se": "Over",
        "name_el": "Over",
        "name_es": "Over",
        "name_hr": "Više",
        "name_fr": "p"
    },
    "2226^live_match^8^no": {
        "name_en": "no",
        "name_aa": "no",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "no",
        "name_ro": "no",
        "name_ru": "no",
        "name_tr": "no",
        "name_se": "no",
        "name_el": "no",
        "name_es": "no",
        "name_hr": "Ne",
        "name_fr": "no"
    },
    "2305^live_match^8^5:7": {
        "name_en": "5:7",
        "name_aa": "5:7",
        "name_de": "5:7",
        "name_nl": "5:7",
        "name_pl": "5:7",
        "name_ro": "5:7",
        "name_ru": "5:7",
        "name_tr": "5:7",
        "name_se": "5:7",
        "name_el": "5:7",
        "name_es": "5:7",
        "name_hr": "5:7",
        "name_fr": "5:7"
    },
    "2043^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "2326^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "1108^live_match^8^8:0": {
        "name_en": "8:0",
        "name_aa": "8:0",
        "name_de": "8:0",
        "name_nl": "8:0",
        "name_pl": "8:0",
        "name_ro": "8:0",
        "name_ru": "8:0",
        "name_tr": "8:0",
        "name_se": "8:0",
        "name_el": "8:0",
        "name_es": "8:0",
        "name_hr": "8:0",
        "name_fr": "8:0"
    },
    "2145^live_match^8^away_17-20": {
        "name_en": "away 17-20",
        "name_aa": "away 17-20",
        "name_de": "Auswärt 17-20",
        "name_nl": "Weg 17-20",
        "name_pl": "away 17-20",
        "name_ro": "away 17-20",
        "name_ru": "away 17-20",
        "name_tr": "away 17-20",
        "name_se": "away 17-20",
        "name_el": "away 17-20",
        "name_es": "away 17-20",
        "name_hr": "Gostujuči 17-20",
        "name_fr": "away 17-20"
    },
    "2211^live_match^8^home_1-18": {
        "name_en": "home 1-18",
        "name_aa": "home 1-18",
        "name_de": "Heim 1-18",
        "name_nl": "Thuis 1-18",
        "name_pl": "home 1-18",
        "name_ro": "home 1-18",
        "name_ru": "home 1-18",
        "name_tr": "home 1-18",
        "name_se": "home 1-18",
        "name_el": "home 1-18",
        "name_es": "home 1-18",
        "name_hr": "Domači 1-18",
        "name_fr": "home 1-18"
    },
    "2073^live_match^8^21:17": {
        "name_en": "21:17",
        "name_aa": "21:17",
        "name_de": "21:17",
        "name_nl": "21:17",
        "name_pl": "21:17",
        "name_ro": "21:17",
        "name_ru": "21:17",
        "name_tr": "21:17",
        "name_se": "21:17",
        "name_el": "21:17",
        "name_es": "21:17",
        "name_hr": "21:17",
        "name_fr": "21:17"
    },
    "2354^live_match^8^10:2": {
        "name_en": "10:2",
        "name_aa": "10:2",
        "name_de": "10:2",
        "name_nl": "10:2",
        "name_pl": "10:2",
        "name_ro": "10:2",
        "name_ru": "10:2",
        "name_tr": "10:2",
        "name_se": "10:2",
        "name_el": "10:2",
        "name_es": "10:2",
        "name_hr": "10:2",
        "name_fr": "10:2"
    },
    "2214^live_match^8^home_1-20": {
        "name_en": "home 1-20",
        "name_aa": "home 1-20",
        "name_de": "Heim 1-20",
        "name_nl": "Thuis 1-20",
        "name_pl": "home 1-20",
        "name_ro": "home 1-20",
        "name_ru": "home 1-20",
        "name_tr": "home 1-20",
        "name_se": "home 1-20",
        "name_el": "home 1-20",
        "name_es": "home 1-20",
        "name_hr": "Domači 1-20",
        "name_fr": "home 1-20"
    },
    "2088^live_match^8^0:6": {
        "name_en": "0:6",
        "name_aa": "0:6",
        "name_de": "0:6",
        "name_nl": "0:6",
        "name_pl": "0:6",
        "name_ro": "0:6",
        "name_ru": "0:6",
        "name_tr": "0:6",
        "name_se": "0:6",
        "name_el": "0:6",
        "name_es": "0:6",
        "name_hr": "0:6",
        "name_fr": "0:6"
    },
    "29^live_match^8^5:3": {
        "name_en": "5:3",
        "name_aa": "5:3",
        "name_de": "5:3",
        "name_nl": "5:3",
        "name_pl": "5:3",
        "name_ro": "5:3",
        "name_ru": "5:3",
        "name_tr": "5:3",
        "name_se": "5:3",
        "name_el": "5:3",
        "name_es": "5:3",
        "name_hr": "5:3",
        "name_fr": "5:3"
    },
    "2205^live_match^8^goal": {
        "name_en": "goal",
        "name_aa": "goal",
        "name_de": "Tor",
        "name_nl": "Doelpunt",
        "name_pl": "goal",
        "name_ro": "goal",
        "name_ru": "goal",
        "name_tr": "goal",
        "name_se": "goal",
        "name_el": "goal",
        "name_es": "goal",
        "name_hr": "Golova",
        "name_fr": "goal"
    },
    "2124^live_match^8^odd": {
        "name_en": "odd",
        "name_aa": "odd",
        "name_de": "Ungerade",
        "name_nl": "Oneven",
        "name_pl": "odd",
        "name_ro": "odd",
        "name_ru": "odd",
        "name_tr": "odd",
        "name_se": "odd",
        "name_el": "odd",
        "name_es": "odd",
        "name_hr": "Neparan",
        "name_fr": "odd"
    },
    "1108^live_match^8^12:7": {
        "name_en": "12:7",
        "name_aa": "12:7",
        "name_de": "12:7",
        "name_nl": "12:7",
        "name_pl": "12:7",
        "name_ro": "12:7",
        "name_ru": "12:7",
        "name_tr": "12:7",
        "name_se": "12:7",
        "name_el": "12:7",
        "name_es": "12:7",
        "name_hr": "12:7",
        "name_fr": "12:7"
    },
    "2198^live_match^8^away_16+": {
        "name_en": "away 16+",
        "name_aa": "away 16+",
        "name_de": "Auswärt 16+",
        "name_nl": "Weg 16+",
        "name_pl": "away 16+",
        "name_ro": "away 16+",
        "name_ru": "away 16+",
        "name_tr": "away 16+",
        "name_se": "away 16+",
        "name_el": "away 16+",
        "name_es": "away 16+",
        "name_hr": "Gostujuči 16+",
        "name_fr": "away 16+"
    },
    "2074^live_match^8^9:15": {
        "name_en": "9:15",
        "name_aa": "9:15",
        "name_de": "9:15",
        "name_nl": "9:15",
        "name_pl": "9:15",
        "name_ro": "9:15",
        "name_ru": "9:15",
        "name_tr": "9:15",
        "name_se": "9:15",
        "name_el": "9:15",
        "name_es": "9:15",
        "name_hr": "9:15",
        "name_fr": "9:15"
    },
    "2182^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "202^live_match^8^under": {
        "name_en": "Under",
        "name_aa": "u",
        "name_de": "Unter",
        "name_nl": "u",
        "name_pl": "u",
        "name_ro": "u",
        "name_ru": "u",
        "name_tr": "Alt",
        "name_se": "u",
        "name_el": "u",
        "name_es": "u",
        "name_hr": "Manje",
        "name_fr": "m"
    },
    "96^live_match^8^player_2_to_0": {
        "name_en": "Player 2 to 0",
        "name_aa": "Player 2 to 0",
        "name_de": "Spieler 2 zu 0",
        "name_nl": "Speler 2 tot 0",
        "name_pl": "Player 2 to 0",
        "name_ro": "Player 2 to 0",
        "name_ru": "Player 2 to 0",
        "name_tr": "2. Oyuncu 0'a karşı",
        "name_se": "Player 2 to 0",
        "name_el": "Player 2 to 0",
        "name_es": "Player 2 to 0",
        "name_hr": "Igrač 2 osvojio gem (protivnik bez osvojenog poena)",
        "name_fr": "Joueur 2 à 0"
    },
    "1658^live_match^2^x": {
        "name_en": "x",
        "name_aa": "x",
        "name_de": "x",
        "name_nl": "x",
        "name_pl": "x",
        "name_ro": "x",
        "name_ru": "x",
        "name_tr": "x",
        "name_se": "x",
        "name_el": "x",
        "name_es": "x",
        "name_hr": "x",
        "name_fr": "x"
    },
    "1108^live_match^8^4:8": {
        "name_en": "4:8",
        "name_aa": "4:8",
        "name_de": "4:8",
        "name_nl": "4:8",
        "name_pl": "4:8",
        "name_ro": "4:8",
        "name_ru": "4:8",
        "name_tr": "4:8",
        "name_se": "4:8",
        "name_el": "4:8",
        "name_es": "4:8",
        "name_hr": "4:8",
        "name_fr": "4:8"
    },
    "2354^live_match^8^1:9": {
        "name_en": "1:9",
        "name_aa": "1:9",
        "name_de": "1:9",
        "name_nl": "1:9",
        "name_pl": "1:9",
        "name_ro": "1:9",
        "name_ru": "1:9",
        "name_tr": "1:9",
        "name_se": "1:9",
        "name_el": "1:9",
        "name_es": "1:9",
        "name_hr": "1:9",
        "name_fr": "1:9"
    },
    "2330^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2032^live_match^8^away_and_under": {
        "name_en": "away and under",
        "name_aa": "away and under",
        "name_de": "Auswärt und Unter",
        "name_nl": "Uit and Minder dan",
        "name_pl": "away and under",
        "name_ro": "away and under",
        "name_ru": "away and under",
        "name_tr": "away and under",
        "name_se": "away and under",
        "name_el": "away and under",
        "name_es": "away and under",
        "name_hr": "Gostujući i manje",
        "name_fr": "away and under"
    },
    "2050^live_match^6^x": {
        "name_en": "x",
        "name_aa": "x",
        "name_de": "x",
        "name_nl": "x",
        "name_pl": "x",
        "name_ro": "x",
        "name_ru": "x",
        "name_tr": "x",
        "name_se": "x",
        "name_el": "x",
        "name_es": "x",
        "name_hr": "x",
        "name_fr": "x"
    },
    "1206^live_match^8^yellow": {
        "name_en": "yellow",
        "name_aa": "yellow",
        "name_de": "gelb",
        "name_nl": "geel",
        "name_pl": "yellow",
        "name_ro": "yellow",
        "name_ru": "yellow",
        "name_tr": "sarı",
        "name_se": "yellow",
        "name_el": "yellow",
        "name_es": "yellow",
        "name_hr": "Žuta",
        "name_fr": "jaune"
    },
    "2076^live_match^8^15:06": {
        "name_en": "15:06",
        "name_aa": "15:06",
        "name_de": "15:06",
        "name_nl": "15:06",
        "name_pl": "15:06",
        "name_ro": "15:06",
        "name_ru": "15:06",
        "name_tr": "15:06",
        "name_se": "15:06",
        "name_el": "15:06",
        "name_es": "15:06",
        "name_hr": "15:06",
        "name_fr": "15:06"
    },
    "2261^live_match^8^2:7": {
        "name_en": "2:7",
        "name_aa": "2:7",
        "name_de": "2:7",
        "name_nl": "2:7",
        "name_pl": "2:7",
        "name_ro": "2:7",
        "name_ru": "2:7",
        "name_tr": "2:7",
        "name_se": "2:7",
        "name_el": "2:7",
        "name_es": "2:7",
        "name_hr": "2:7",
        "name_fr": "2:7"
    },
    "2005^live_match^8^away_penalties": {
        "name_en": "Away penalties",
        "name_aa": "Away penalties",
        "name_de": "Auswärt elfmeter",
        "name_nl": "Uitstraffen",
        "name_pl": "Away penalties",
        "name_ro": "Away penalties",
        "name_ru": "Away penalties",
        "name_tr": "Away penalties",
        "name_se": "Away penalties",
        "name_el": "Away penalties",
        "name_es": "Away penalties",
        "name_hr": "Away penalties",
        "name_fr": "Away penalties"
    },
    "2213^live_match^8^away_7-12": {
        "name_en": "away 7-12",
        "name_aa": "away 7-12",
        "name_de": "Auswärt 7-12",
        "name_nl": "Weg 7-12",
        "name_pl": "away 7-12",
        "name_ro": "away 7-12",
        "name_ru": "away 7-12",
        "name_tr": "away 7-12",
        "name_se": "away 7-12",
        "name_el": "away 7-12",
        "name_es": "away 7-12",
        "name_hr": "Gostujuči 7-12",
        "name_fr": "away 7-12"
    },
    "2126^live_match^8^home_1-2": {
        "name_en": "home 1-2",
        "name_aa": "home 1-2",
        "name_de": "Heim 1-2",
        "name_nl": "Thuis 1-2",
        "name_pl": "home 1-2",
        "name_ro": "home 1-2",
        "name_ru": "home 1-2",
        "name_tr": "home 1-2",
        "name_se": "home 1-2",
        "name_el": "home 1-2",
        "name_es": "home 1-2",
        "name_hr": "Domači 1-2",
        "name_fr": "home 1-2"
    },
    "2206^live_match^8^other": {
        "name_en": "other",
        "name_aa": "other",
        "name_de": "Sonstig",
        "name_nl": "Andere",
        "name_pl": "other",
        "name_ro": "other",
        "name_ru": "other",
        "name_tr": "other",
        "name_se": "other",
        "name_el": "other",
        "name_es": "other",
        "name_hr": "Ostali",
        "name_fr": "other"
    },
    "143^live_match^8^under": {
        "name_en": "Under",
        "name_aa": "u",
        "name_de": "Unter",
        "name_nl": "u",
        "name_pl": "u",
        "name_ro": "u",
        "name_ru": "u",
        "name_tr": "Alt",
        "name_se": "u",
        "name_el": "u",
        "name_es": "u",
        "name_hr": "Manje",
        "name_fr": "m"
    },
    "26^live_match^8^3:2": {
        "name_en": "3:2",
        "name_aa": "3:2",
        "name_de": "3:2",
        "name_nl": "3:2",
        "name_pl": "3:2",
        "name_ro": "3:2",
        "name_ru": "3:2",
        "name_tr": "3:2",
        "name_se": "3:2",
        "name_el": "3:2",
        "name_es": "3:2",
        "name_hr": "3:2",
        "name_fr": "3:2"
    },
    "26^live_match^8^3:11": {
        "name_en": "3:11",
        "name_aa": "3:11",
        "name_de": "3:11",
        "name_nl": "3:11",
        "name_pl": "3:11",
        "name_ro": "3:11",
        "name_ru": "3:11",
        "name_tr": "3:11",
        "name_se": "3:11",
        "name_el": "3:11",
        "name_es": "3:11",
        "name_hr": "3:11",
        "name_fr": "3:11"
    },
    "1529^live_match^8^home_and_under": {
        "name_en": "home and under",
        "name_aa": "home and under",
        "name_de": "Heim und Unter",
        "name_nl": "thuis en onder",
        "name_pl": "home and under",
        "name_ro": "home and under",
        "name_ru": "home and under",
        "name_tr": "ev sahibi ve alt",
        "name_se": "home and under",
        "name_el": "home and under",
        "name_es": "home and under",
        "name_hr": "1 i Manje",
        "name_fr": "domicile et Moins de"
    },
    "904^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2074^live_match^8^15:07": {
        "name_en": "15:07",
        "name_aa": "15:07",
        "name_de": "15:07",
        "name_nl": "15:07",
        "name_pl": "15:07",
        "name_ro": "15:07",
        "name_ru": "15:07",
        "name_tr": "15:07",
        "name_se": "15:07",
        "name_el": "15:07",
        "name_es": "15:07",
        "name_hr": "15:07",
        "name_fr": "15:07"
    },
    "2062^live_match^8^away_1-2": {
        "name_en": "away 1-2",
        "name_aa": "away 1-2",
        "name_de": "Auswärt 1-2",
        "name_nl": "away 1-2",
        "name_pl": "away 1-2",
        "name_ro": "away 1-2",
        "name_ru": "away 1-2",
        "name_tr": "away 1-2",
        "name_se": "away 1-2",
        "name_el": "away 1-2",
        "name_es": "away 1-2",
        "name_hr": "Gostujuči 1-2",
        "name_fr": "away 1-2"
    },
    "2072^live_match^8^17:21": {
        "name_en": "17:21",
        "name_aa": "17:21",
        "name_de": "17:21",
        "name_nl": "17:21",
        "name_pl": "17:21",
        "name_ro": "17:21",
        "name_ru": "17:21",
        "name_tr": "17:21",
        "name_se": "17:21",
        "name_el": "17:21",
        "name_es": "17:21",
        "name_hr": "17:21",
        "name_fr": "17:21"
    },
    "2173^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "1218^live_match^8^yes": {
        "name_en": "Yes",
        "name_aa": "Yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "Yes",
        "name_ro": "Yes",
        "name_ru": "Yes",
        "name_tr": "evet",
        "name_se": "Yes",
        "name_el": "Yes",
        "name_es": "Yes",
        "name_hr": "Da",
        "name_fr": "oui"
    },
    "26^live_match^8^3:6": {
        "name_en": "3:6",
        "name_aa": "3:6",
        "name_de": "3:6",
        "name_nl": "3:6",
        "name_pl": "3:6",
        "name_ro": "3:6",
        "name_ru": "3:6",
        "name_tr": "3:6",
        "name_se": "3:6",
        "name_el": "3:6",
        "name_es": "3:6",
        "name_hr": "3:6",
        "name_fr": "3:6"
    },
    "1108^live_match^8^5:1": {
        "name_en": "5:1",
        "name_aa": "5:1",
        "name_de": "5:1",
        "name_nl": "5:1",
        "name_pl": "5:1",
        "name_ro": "5:1",
        "name_ru": "5:1",
        "name_tr": "5:1",
        "name_se": "5:1",
        "name_el": "5:1",
        "name_es": "5:1",
        "name_hr": "5:1",
        "name_fr": "5:1"
    },
    "29^live_match^8^1:10": {
        "name_en": "1:10",
        "name_aa": "1:10",
        "name_de": "1:10",
        "name_nl": "1:10",
        "name_pl": "1:10",
        "name_ro": "1:10",
        "name_ru": "1:10",
        "name_tr": "1:10",
        "name_se": "1:10",
        "name_el": "1:10",
        "name_es": "1:10",
        "name_hr": "1:10",
        "name_fr": "1:10"
    },
    "2039^live_match^8^no": {
        "name_en": "no",
        "name_aa": "no",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "no",
        "name_ro": "no",
        "name_ru": "no",
        "name_tr": "no",
        "name_se": "no",
        "name_el": "no",
        "name_es": "no",
        "name_hr": "Ne",
        "name_fr": "no"
    },
    "2021^live_match^8^away": {
        "name_en": "away",
        "name_aa": "away",
        "name_de": "Auswärts",
        "name_nl": "Uit",
        "name_pl": "away",
        "name_ro": "away",
        "name_ru": "away",
        "name_tr": "away",
        "name_se": "away",
        "name_el": "away",
        "name_es": "away",
        "name_hr": "Gostujući",
        "name_fr": "away"
    },
    "2316^live_match^8^10:1": {
        "name_en": "10:1",
        "name_aa": "10:1",
        "name_de": "10:1",
        "name_nl": "10:1",
        "name_pl": "10:1",
        "name_ro": "10:1",
        "name_ru": "10:1",
        "name_tr": "10:1",
        "name_se": "10:1",
        "name_el": "10:1",
        "name_es": "10:1",
        "name_hr": "10:1",
        "name_fr": "10:1"
    },
    "2315^live_match^8^1:5": {
        "name_en": "1:5",
        "name_aa": "1:5",
        "name_de": "1:5",
        "name_nl": "1:5",
        "name_pl": "1:5",
        "name_ro": "1:5",
        "name_ru": "1:5",
        "name_tr": "1:5",
        "name_se": "1:5",
        "name_el": "1:5",
        "name_es": "1:5",
        "name_hr": "1:5",
        "name_fr": "1:5"
    },
    "355^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "341^live_match^8^4:0": {
        "name_en": "4:0",
        "name_aa": "4:0",
        "name_de": "4:0",
        "name_nl": "4:0",
        "name_pl": "4:0",
        "name_ro": "4:0",
        "name_ru": "4:0",
        "name_tr": "4:0",
        "name_se": "4:0",
        "name_el": "4:0",
        "name_es": "4:0",
        "name_hr": "4:0",
        "name_fr": "4:0"
    },
    "26^live_match^8^5:1": {
        "name_en": "5:1",
        "name_aa": "5:1",
        "name_de": "5:1",
        "name_nl": "5:1",
        "name_pl": "5:1",
        "name_ro": "5:1",
        "name_ru": "5:1",
        "name_tr": "5:1",
        "name_se": "5:1",
        "name_el": "5:1",
        "name_es": "5:1",
        "name_hr": "5:1",
        "name_fr": "5:1"
    },
    "2354^live_match^8^7:8": {
        "name_en": "7:8",
        "name_aa": "7:8",
        "name_de": "7:8",
        "name_nl": "7:8",
        "name_pl": "7:8",
        "name_ro": "7:8",
        "name_ru": "7:8",
        "name_tr": "7:8",
        "name_se": "7:8",
        "name_el": "7:8",
        "name_es": "7:8",
        "name_hr": "7:8",
        "name_fr": "7:8"
    },
    "2354^live_match^8^6:4": {
        "name_en": "6:4",
        "name_aa": "6:4",
        "name_de": "6:4",
        "name_nl": "6:4",
        "name_pl": "6:4",
        "name_ro": "6:4",
        "name_ru": "6:4",
        "name_tr": "6:4",
        "name_se": "6:4",
        "name_el": "6:4",
        "name_es": "6:4",
        "name_hr": "6:4",
        "name_fr": "6:4"
    },
    "26^live_match^8^0:6": {
        "name_en": "0:6",
        "name_aa": "0:6",
        "name_de": "0:6",
        "name_nl": "0:6",
        "name_pl": "0:6",
        "name_ro": "0:6",
        "name_ru": "0:6",
        "name_tr": "0:6",
        "name_se": "0:6",
        "name_el": "0:6",
        "name_es": "0:6",
        "name_hr": "0:6",
        "name_fr": "0:6"
    },
    "2156^live_match^8^home_1-2": {
        "name_en": "home 1-2",
        "name_aa": "home 1-2",
        "name_de": "Heim 1-2",
        "name_nl": "Thuis 1-2",
        "name_pl": "home 1-2",
        "name_ro": "home 1-2",
        "name_ru": "home 1-2",
        "name_tr": "home 1-2",
        "name_se": "home 1-2",
        "name_el": "home 1-2",
        "name_es": "home 1-2",
        "name_hr": "Domači 1-2",
        "name_fr": "home 1-2"
    },
    "2074^live_match^8^15:06": {
        "name_en": "15:06",
        "name_aa": "15:06",
        "name_de": "15:06",
        "name_nl": "15:06",
        "name_pl": "15:06",
        "name_ro": "15:06",
        "name_ru": "15:06",
        "name_tr": "15:06",
        "name_se": "15:06",
        "name_el": "15:06",
        "name_es": "15:06",
        "name_hr": "15:06",
        "name_fr": "15:06"
    },
    "2090^live_match^8^40:15": {
        "name_en": "40:15",
        "name_aa": "40:15",
        "name_de": "40:15",
        "name_nl": "40:15",
        "name_pl": "40:15",
        "name_ro": "40:15",
        "name_ru": "40:15",
        "name_tr": "40:15",
        "name_se": "40:15",
        "name_el": "40:15",
        "name_es": "40:15",
        "name_hr": "40:15",
        "name_fr": "40:15"
    },
    "2145^live_match^8^away_13-16": {
        "name_en": "away 13-16",
        "name_aa": "away 13-16",
        "name_de": "Auswärt 13-16",
        "name_nl": "Weg 13-16",
        "name_pl": "away 13-16",
        "name_ro": "away 13-16",
        "name_ru": "away 13-16",
        "name_tr": "away 13-16",
        "name_se": "away 13-16",
        "name_el": "away 13-16",
        "name_es": "away 13-16",
        "name_hr": "Gostujuči 13-16",
        "name_fr": "away 13-16"
    },
    "1108^live_match^8^4:6": {
        "name_en": "4:6",
        "name_aa": "4:6",
        "name_de": "4:6",
        "name_nl": "4:6",
        "name_pl": "4:6",
        "name_ro": "4:6",
        "name_ru": "4:6",
        "name_tr": "4:6",
        "name_se": "4:6",
        "name_el": "4:6",
        "name_es": "4:6",
        "name_hr": "4:6",
        "name_fr": "4:6"
    },
    "2229^live_match^6^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "1108^live_match^8^7:4": {
        "name_en": "7:4",
        "name_aa": "7:4",
        "name_de": "7:4",
        "name_nl": "7:4",
        "name_pl": "7:4",
        "name_ro": "7:4",
        "name_ru": "7:4",
        "name_tr": "7:4",
        "name_se": "7:4",
        "name_el": "7:4",
        "name_es": "7:4",
        "name_hr": "7:4",
        "name_fr": "7:4"
    },
    "2201^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "2118^live_match^6^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2364^live_match^8^draw_and_under_and_ng": {
        "name_en": "draw and under and NG",
        "name_aa": "draw and under and NG",
        "name_de": "Unentschieden und Unter und NG",
        "name_nl": "Gelijkspel en minder dan en NG",
        "name_pl": "draw and under and NG",
        "name_ro": "draw and under and NG",
        "name_ru": "draw and under and NG",
        "name_tr": "draw and under and NG",
        "name_se": "draw and under and NG",
        "name_el": "draw and under and NG",
        "name_es": "draw and under and NG",
        "name_hr": "draw and under and NG",
        "name_fr": "draw and under and NG"
    },
    "2354^live_match^8^5:10": {
        "name_en": "5:10",
        "name_aa": "5:10",
        "name_de": "5:10",
        "name_nl": "5:10",
        "name_pl": "5:10",
        "name_ro": "5:10",
        "name_ru": "5:10",
        "name_tr": "5:10",
        "name_se": "5:10",
        "name_el": "5:10",
        "name_es": "5:10",
        "name_hr": "5:10",
        "name_fr": "5:10"
    },
    "2202^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2156^live_match^8^away_9+": {
        "name_en": "away 9+",
        "name_aa": "away 9+",
        "name_de": "Auswärt 9+",
        "name_nl": "Weg 9+",
        "name_pl": "away 9+",
        "name_ro": "away 9+",
        "name_ru": "away 9+",
        "name_tr": "away 9+",
        "name_se": "away 9+",
        "name_el": "away 9+",
        "name_es": "away 9+",
        "name_hr": "Gostujiči 9+",
        "name_fr": "away 9+"
    },
    "2314^live_match^8^none": {
        "name_en": "none",
        "name_aa": "none",
        "name_de": "Keiner",
        "name_nl": "Geen",
        "name_pl": "none",
        "name_ro": "none",
        "name_ru": "none",
        "name_tr": "none",
        "name_se": "none",
        "name_el": "none",
        "name_es": "none",
        "name_hr": "Nitko",
        "name_fr": "none"
    },
    "2354^live_match^8^9:10": {
        "name_en": "9:10",
        "name_aa": "9:10",
        "name_de": "9:10",
        "name_nl": "9:10",
        "name_pl": "9:10",
        "name_ro": "9:10",
        "name_ru": "9:10",
        "name_tr": "9:10",
        "name_se": "9:10",
        "name_el": "9:10",
        "name_es": "9:10",
        "name_hr": "9:10",
        "name_fr": "9:10"
    },
    "2149^live_match^8^home_6+": {
        "name_en": "home 6+",
        "name_aa": "home 6+",
        "name_de": "Heim 6+",
        "name_nl": "Thuis 6+",
        "name_pl": "home 6+",
        "name_ro": "home 6+",
        "name_ru": "home 6+",
        "name_tr": "home 6+",
        "name_se": "home 6+",
        "name_el": "home 6+",
        "name_es": "home 6+",
        "name_hr": "Domači 6+",
        "name_fr": "home 6+"
    },
    "202^live_match^8^over": {
        "name_en": "Over",
        "name_aa": "Over",
        "name_de": "Über",
        "name_nl": "Over",
        "name_pl": "Over",
        "name_ro": "Over",
        "name_ru": "Over",
        "name_tr": "Üst",
        "name_se": "Over",
        "name_el": "Over",
        "name_es": "Over",
        "name_hr": "Više",
        "name_fr": "p"
    },
    "1206^live_match^8^brown": {
        "name_en": "brown",
        "name_aa": "brown",
        "name_de": "braun",
        "name_nl": "bruin",
        "name_pl": "brown",
        "name_ro": "brown",
        "name_ru": "brown",
        "name_tr": "kahverengi",
        "name_se": "brown",
        "name_el": "brown",
        "name_es": "brown",
        "name_hr": "smeđa",
        "name_fr": "marron"
    },
    "2354^live_match^8^14:0": {
        "name_en": "14:0",
        "name_aa": "14:0",
        "name_de": "14:0",
        "name_nl": "14:0",
        "name_pl": "14:0",
        "name_ro": "14:0",
        "name_ru": "14:0",
        "name_tr": "14:0",
        "name_se": "14:0",
        "name_el": "14:0",
        "name_es": "14:0",
        "name_hr": "14:0",
        "name_fr": "14:0"
    },
    "2312^live_match^8^2_&_yes": {
        "name_en": "2 & Yes",
        "name_aa": "2 & Yes",
        "name_de": "2 & Ja",
        "name_nl": "2 & Ja",
        "name_pl": "2 & Yes",
        "name_ro": "2 & Yes",
        "name_ru": "2 & Yes",
        "name_tr": "2 & Yes",
        "name_se": "2 & Yes",
        "name_el": "2 & Yes",
        "name_es": "2 & Yes",
        "name_hr": "2 & da",
        "name_fr": "2 & Yes"
    },
    "1294^live_match^8^over": {
        "name_en": "Over",
        "name_aa": "Over",
        "name_de": "Über",
        "name_nl": "Over",
        "name_pl": "Over",
        "name_ro": "Over",
        "name_ru": "Over",
        "name_tr": "Üst",
        "name_se": "Over",
        "name_el": "Over",
        "name_es": "Over",
        "name_hr": "Više",
        "name_fr": "p"
    },
    "2355^live_match^8^x": {
        "name_en": "x",
        "name_aa": "x",
        "name_de": "x",
        "name_nl": "x",
        "name_pl": "x",
        "name_ro": "x",
        "name_ru": "x",
        "name_tr": "x",
        "name_se": "x",
        "name_el": "x",
        "name_es": "x",
        "name_hr": "x",
        "name_fr": "x"
    },
    "2029^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "2168^live_match^8^2/1": {
        "name_en": "2/1",
        "name_aa": "2/1",
        "name_de": "2/1",
        "name_nl": "2/1",
        "name_pl": "2/1",
        "name_ro": "2/1",
        "name_ru": "2/1",
        "name_tr": "2/1",
        "name_se": "2/1",
        "name_el": "2/1",
        "name_es": "2/1",
        "name_hr": "2/1",
        "name_fr": "2/1"
    },
    "2126^live_match^8^away_2": {
        "name_en": "away 2",
        "name_aa": "away 2",
        "name_de": "Auswärt 2",
        "name_nl": "Uit 2",
        "name_pl": "away 2",
        "name_ro": "away 2",
        "name_ru": "away 2",
        "name_tr": "away 2",
        "name_se": "away 2",
        "name_el": "away 2",
        "name_es": "away 2",
        "name_hr": "Gostujuči 2",
        "name_fr": "away 2"
    },
    "2126^live_match^8^home_3-4": {
        "name_en": "home 3-4",
        "name_aa": "home 3-4",
        "name_de": "Heim 3-4",
        "name_nl": "thuis 3-4",
        "name_pl": "home 3-4",
        "name_ro": "home 3-4",
        "name_ru": "home 3-4",
        "name_tr": "home 3-4",
        "name_se": "home 3-4",
        "name_el": "home 3-4",
        "name_es": "home 3-4",
        "name_hr": "Domači 3-4",
        "name_fr": "home 3-4"
    },
    "26^live_match^8^2:5": {
        "name_en": "2:5",
        "name_aa": "2:5",
        "name_de": "2:5",
        "name_nl": "2:5",
        "name_pl": "2:5",
        "name_ro": "2:5",
        "name_ru": "2:5",
        "name_tr": "2:5",
        "name_se": "2:5",
        "name_el": "2:5",
        "name_es": "2:5",
        "name_hr": "2:5",
        "name_fr": "2:5"
    },
    "2354^live_match^8^4:8": {
        "name_en": "4:8",
        "name_aa": "4:8",
        "name_de": "4:8",
        "name_nl": "4:8",
        "name_pl": "4:8",
        "name_ro": "4:8",
        "name_ru": "4:8",
        "name_tr": "4:8",
        "name_se": "4:8",
        "name_el": "4:8",
        "name_es": "4:8",
        "name_hr": "4:8",
        "name_fr": "4:8"
    },
    "72^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2052^live_match^6^x": {
        "name_en": "x",
        "name_aa": "x",
        "name_de": "x",
        "name_nl": "x",
        "name_pl": "x",
        "name_ro": "x",
        "name_ru": "x",
        "name_tr": "x",
        "name_se": "x",
        "name_el": "x",
        "name_es": "x",
        "name_hr": "x",
        "name_fr": "x"
    },
    "1108^live_match^8^8:11": {
        "name_en": "8:11",
        "name_aa": "8:11",
        "name_de": "8:11",
        "name_nl": "8:11",
        "name_pl": "8:11",
        "name_ro": "8:11",
        "name_ru": "8:11",
        "name_tr": "8:11",
        "name_se": "8:11",
        "name_el": "8:11",
        "name_es": "8:11",
        "name_hr": "8:11",
        "name_fr": "8:11"
    },
    "1382^live_match^8^over": {
        "name_en": "Over",
        "name_aa": "Over",
        "name_de": "Über",
        "name_nl": "Over",
        "name_pl": "Over",
        "name_ro": "Over",
        "name_ru": "Over",
        "name_tr": "Üst",
        "name_se": "Over",
        "name_el": "Over",
        "name_es": "Over",
        "name_hr": "Više",
        "name_fr": "p"
    },
    "958^live_match^6^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2143^live_match^8^home_3-4": {
        "name_en": "home 3-4",
        "name_aa": "home 3-4",
        "name_de": "Heim 3-4",
        "name_nl": "thuis 3-4",
        "name_pl": "home 3-4",
        "name_ro": "home 3-4",
        "name_ru": "home 3-4",
        "name_tr": "home 3-4",
        "name_se": "home 3-4",
        "name_el": "home 3-4",
        "name_es": "home 3-4",
        "name_hr": "Domači 3-4",
        "name_fr": "home 3-4"
    },
    "2259^live_match^8^away_and_under": {
        "name_en": "away and under",
        "name_aa": "away and under",
        "name_de": "Auswärt und Unter",
        "name_nl": "Uit and Minder dan",
        "name_pl": "away and under",
        "name_ro": "away and under",
        "name_ru": "away and under",
        "name_tr": "away and under",
        "name_se": "away and under",
        "name_el": "away and under",
        "name_es": "away and under",
        "name_hr": "Gostujući i manje",
        "name_fr": "away and under"
    },
    "2075^live_match^8^22:25": {
        "name_en": "22:25",
        "name_aa": "22:25",
        "name_de": "22:25",
        "name_nl": "22:25",
        "name_pl": "22:25",
        "name_ro": "22:25",
        "name_ru": "22:25",
        "name_tr": "22:25",
        "name_se": "22:25",
        "name_el": "22:25",
        "name_es": "22:25",
        "name_hr": "22:25",
        "name_fr": "22:25"
    },
    "2072^live_match^8^23:25": {
        "name_en": "23:25",
        "name_aa": "23:25",
        "name_de": "23:25",
        "name_nl": "23:25",
        "name_pl": "23:25",
        "name_ro": "23:25",
        "name_ru": "23:25",
        "name_tr": "23:25",
        "name_se": "23:25",
        "name_el": "23:25",
        "name_es": "23:25",
        "name_hr": "23:25",
        "name_fr": "23:25"
    },
    "2354^live_match^8^0:6": {
        "name_en": "0:6",
        "name_aa": "0:6",
        "name_de": "0:6",
        "name_nl": "0:6",
        "name_pl": "0:6",
        "name_ro": "0:6",
        "name_ru": "0:6",
        "name_tr": "0:6",
        "name_se": "0:6",
        "name_el": "0:6",
        "name_es": "0:6",
        "name_hr": "0:6",
        "name_fr": "0:6"
    },
    "2073^live_match^8^25:23": {
        "name_en": "25:23",
        "name_aa": "25:23",
        "name_de": "25:23",
        "name_nl": "25:23",
        "name_pl": "25:23",
        "name_ro": "25:23",
        "name_ru": "25:23",
        "name_tr": "25:23",
        "name_se": "25:23",
        "name_el": "25:23",
        "name_es": "25:23",
        "name_hr": "25:23",
        "name_fr": "25:23"
    },
    "2354^live_match^8^1:10": {
        "name_en": "1:10",
        "name_aa": "1:10",
        "name_de": "1:10",
        "name_nl": "1:10",
        "name_pl": "1:10",
        "name_ro": "1:10",
        "name_ru": "1:10",
        "name_tr": "1:10",
        "name_se": "1:10",
        "name_el": "1:10",
        "name_es": "1:10",
        "name_hr": "1:10",
        "name_fr": "1:10"
    },
    "2354^live_match^8^4:7": {
        "name_en": "4:7",
        "name_aa": "4:7",
        "name_de": "4:7",
        "name_nl": "4:7",
        "name_pl": "4:7",
        "name_ro": "4:7",
        "name_ru": "4:7",
        "name_tr": "4:7",
        "name_se": "4:7",
        "name_el": "4:7",
        "name_es": "4:7",
        "name_hr": "4:7",
        "name_fr": "4:7"
    },
    "2330^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "2208^live_match^8^27-28": {
        "name_en": "27-28",
        "name_aa": "27-28",
        "name_de": "27-28",
        "name_nl": "27-28",
        "name_pl": "27-28",
        "name_ro": "27-28",
        "name_ru": "27-28",
        "name_tr": "27-28",
        "name_se": "27-28",
        "name_el": "27-28",
        "name_es": "27-28",
        "name_hr": "27-28",
        "name_fr": "27-28"
    },
    "2261^live_match^8^0:5": {
        "name_en": "0:5",
        "name_aa": "0:5",
        "name_de": "0:5",
        "name_nl": "0:5",
        "name_pl": "0:5",
        "name_ro": "0:5",
        "name_ru": "0:5",
        "name_tr": "0:5",
        "name_se": "0:5",
        "name_el": "0:5",
        "name_es": "0:5",
        "name_hr": "0:5",
        "name_fr": "0:5"
    },
    "2261^live_match^8^5:8": {
        "name_en": "5:8",
        "name_aa": "5:8",
        "name_de": "5:8",
        "name_nl": "5:8",
        "name_pl": "5:8",
        "name_ro": "5:8",
        "name_ru": "5:8",
        "name_tr": "5:8",
        "name_se": "5:8",
        "name_el": "5:8",
        "name_es": "5:8",
        "name_hr": "5:8",
        "name_fr": "5:8"
    },
    "237^live_match^8^da": {
        "name_en": "DA",
        "name_aa": "DA",
        "name_de": "U-A",
        "name_nl": "DA",
        "name_pl": "DA",
        "name_ro": "DA",
        "name_ru": "DA",
        "name_tr": "BD",
        "name_se": "DA",
        "name_el": "DA",
        "name_es": "DA",
        "name_hr": "X-2",
        "name_fr": "NE"
    },
    "50^live_match^8^under": {
        "name_en": "Under",
        "name_aa": "u",
        "name_de": "Unter",
        "name_nl": "u",
        "name_pl": "u",
        "name_ro": "u",
        "name_ru": "u",
        "name_tr": "Alt",
        "name_se": "u",
        "name_el": "u",
        "name_es": "u",
        "name_hr": "Manje",
        "name_fr": "m"
    },
    "2354^live_match^8^10:12": {
        "name_en": "10:12",
        "name_aa": "10:12",
        "name_de": "10:12",
        "name_nl": "10:12",
        "name_pl": "10:12",
        "name_ro": "10:12",
        "name_ru": "10:12",
        "name_tr": "10:12",
        "name_se": "10:12",
        "name_el": "10:12",
        "name_es": "10:12",
        "name_hr": "10:12",
        "name_fr": "10:12"
    },
    "2063^live_match^8^away_14-16": {
        "name_en": "away 14-16",
        "name_aa": "away 14-16",
        "name_de": "Auswärt 14-16",
        "name_nl": "Weg 14-16",
        "name_pl": "away 14-16",
        "name_ro": "away 14-16",
        "name_ru": "away 14-16",
        "name_tr": "away 14-16",
        "name_se": "away 14-16",
        "name_el": "away 14-16",
        "name_es": "away 14-16",
        "name_hr": "Gostujuči 14-16",
        "name_fr": "away 14-16"
    },
    "2354^live_match^8^2:10": {
        "name_en": "2:10",
        "name_aa": "2:10",
        "name_de": "2:10",
        "name_nl": "2:10",
        "name_pl": "2:10",
        "name_ro": "2:10",
        "name_ru": "2:10",
        "name_tr": "2:10",
        "name_se": "2:10",
        "name_el": "2:10",
        "name_es": "2:10",
        "name_hr": "2:10",
        "name_fr": "2:10"
    },
    "1108^live_match^8^4:3": {
        "name_en": "4:3",
        "name_aa": "4:3",
        "name_de": "4:3",
        "name_nl": "4:3",
        "name_pl": "4:3",
        "name_ro": "4:3",
        "name_ru": "4:3",
        "name_tr": "4:3",
        "name_se": "4:3",
        "name_el": "4:3",
        "name_es": "4:3",
        "name_hr": "4:3",
        "name_fr": "4:3"
    },
    "2022^live_match^8^neither": {
        "name_en": "neither",
        "name_aa": "neither",
        "name_de": "Keiner",
        "name_nl": "Geen van beide",
        "name_pl": "neither",
        "name_ro": "neither",
        "name_ru": "neither",
        "name_tr": "neither",
        "name_se": "neither",
        "name_el": "neither",
        "name_es": "neither",
        "name_hr": "Ni",
        "name_fr": "neither"
    },
    "2339^live_match^8^miss": {
        "name_en": "miss",
        "name_aa": "miss",
        "name_de": "miss",
        "name_nl": "miss",
        "name_pl": "miss",
        "name_ro": "miss",
        "name_ru": "miss",
        "name_tr": "miss",
        "name_se": "miss",
        "name_el": "miss",
        "name_es": "miss",
        "name_hr": "miss",
        "name_fr": "miss"
    },
    "1108^live_match^8^8:12": {
        "name_en": "8:12",
        "name_aa": "8:12",
        "name_de": "8:12",
        "name_nl": "8:12",
        "name_pl": "8:12",
        "name_ro": "8:12",
        "name_ru": "8:12",
        "name_tr": "8:12",
        "name_se": "8:12",
        "name_el": "8:12",
        "name_es": "8:12",
        "name_hr": "8:12",
        "name_fr": "8:12"
    },
    "2115^live_match^8^no": {
        "name_en": "no",
        "name_aa": "no",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "no",
        "name_ro": "no",
        "name_ru": "no",
        "name_tr": "no",
        "name_se": "no",
        "name_el": "no",
        "name_es": "no",
        "name_hr": "Ne",
        "name_fr": "no"
    },
    "1108^live_match^8^7:10": {
        "name_en": "7:10",
        "name_aa": "7:10",
        "name_de": "7:10",
        "name_nl": "7:10",
        "name_pl": "7:10",
        "name_ro": "7:10",
        "name_ru": "7:10",
        "name_tr": "7:10",
        "name_se": "7:10",
        "name_el": "7:10",
        "name_es": "7:10",
        "name_hr": "7:10",
        "name_fr": "7:10"
    },
    "52^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "26^live_match^8^11:2": {
        "name_en": "11:2",
        "name_aa": "11:2",
        "name_de": "11:2",
        "name_nl": "11:2",
        "name_pl": "11:2",
        "name_ro": "11:2",
        "name_ru": "11:2",
        "name_tr": "11:2",
        "name_se": "11:2",
        "name_el": "11:2",
        "name_es": "11:2",
        "name_hr": "11:2",
        "name_fr": "11:2"
    },
    "2109^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "351^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2354^live_match^8^8:7": {
        "name_en": "8:7",
        "name_aa": "8:7",
        "name_de": "8:7",
        "name_nl": "8:7",
        "name_pl": "8:7",
        "name_ro": "8:7",
        "name_ru": "8:7",
        "name_tr": "8:7",
        "name_se": "8:7",
        "name_el": "8:7",
        "name_es": "8:7",
        "name_hr": "8:7",
        "name_fr": "8:7"
    },
    "2121^live_match^8^1_yes": {
        "name_en": "1 yes",
        "name_aa": "1 yes",
        "name_de": "1 Ja",
        "name_nl": "1 Ja",
        "name_pl": "1 yes",
        "name_ro": "1 yes",
        "name_ru": "1 yes",
        "name_tr": "1 yes",
        "name_se": "1 yes",
        "name_el": "1 yes",
        "name_es": "1 yes",
        "name_hr": "1 Da",
        "name_fr": "1 yes"
    },
    "73^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2060^live_match^8^home_3-4": {
        "name_en": "home 3-4",
        "name_aa": "home 3-4",
        "name_de": "Heim 3-4",
        "name_nl": "thuis 3-4",
        "name_pl": "home 3-4",
        "name_ro": "home 3-4",
        "name_ru": "home 3-4",
        "name_tr": "home 3-4",
        "name_se": "home 3-4",
        "name_el": "home 3-4",
        "name_es": "home 3-4",
        "name_hr": "Domači 3-4",
        "name_fr": "home 3-4"
    },
    "43^live_match^8^under": {
        "name_en": "Under",
        "name_aa": "u",
        "name_de": "Unter",
        "name_nl": "u",
        "name_pl": "u",
        "name_ro": "u",
        "name_ru": "u",
        "name_tr": "Alt",
        "name_se": "u",
        "name_el": "u",
        "name_es": "u",
        "name_hr": "Manje",
        "name_fr": "m"
    },
    "1108^live_match^8^3:13": {
        "name_en": "3:13",
        "name_aa": "3:13",
        "name_de": "3:13",
        "name_nl": "3:13",
        "name_pl": "3:13",
        "name_ro": "3:13",
        "name_ru": "3:13",
        "name_tr": "3:13",
        "name_se": "3:13",
        "name_el": "3:13",
        "name_es": "3:13",
        "name_hr": "3:13",
        "name_fr": "3:13"
    },
    "2092^live_match^8^yes": {
        "name_en": "yes",
        "name_aa": "yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "yes",
        "name_ro": "yes",
        "name_ru": "yes",
        "name_tr": "yes",
        "name_se": "yes",
        "name_el": "yes",
        "name_es": "yes",
        "name_hr": "Da",
        "name_fr": "yes"
    },
    "2126^live_match^8^away_5+": {
        "name_en": "away 5+",
        "name_aa": "away 5+",
        "name_de": "Auswärt 5+",
        "name_nl": "Weg 5+",
        "name_pl": "away 5+",
        "name_ro": "away 5+",
        "name_ru": "away 5+",
        "name_tr": "away 5+",
        "name_se": "away 5+",
        "name_el": "away 5+",
        "name_es": "away 5+",
        "name_hr": "Gostujuči 5+",
        "name_fr": "away 5+"
    },
    "1296^live_match^8^under": {
        "name_en": "Under",
        "name_aa": "u",
        "name_de": "Unter",
        "name_nl": "u",
        "name_pl": "u",
        "name_ro": "u",
        "name_ru": "u",
        "name_tr": "Alt",
        "name_se": "u",
        "name_el": "u",
        "name_es": "u",
        "name_hr": "Manje",
        "name_fr": "m"
    },
    "2305^live_match^8^away_by_any_other": {
        "name_en": "away by Any Other",
        "name_aa": "away by Any Other",
        "name_de": "away by Any Other",
        "name_nl": "away by Any Other",
        "name_pl": "away by Any Other",
        "name_ro": "away by Any Other",
        "name_ru": "away by Any Other",
        "name_tr": "away by Any Other",
        "name_se": "away by Any Other",
        "name_el": "away by Any Other",
        "name_es": "away by Any Other",
        "name_hr": "Gostujuči po bilo Ostali",
        "name_fr": "away by Any Other"
    },
    "1230^live_match^8^50-99": {
        "name_en": "50-99",
        "name_aa": "50-99",
        "name_de": "50-99",
        "name_nl": "0-49",
        "name_pl": "50-99",
        "name_ro": "50-99",
        "name_ru": "50-99",
        "name_tr": "50-99",
        "name_se": "50-99",
        "name_el": "50-99",
        "name_es": "50-99",
        "name_hr": "50-99",
        "name_fr": "50-99"
    },
    "2338^live_match^8^free_kick": {
        "name_en": "Free Kick",
        "name_aa": "Free Kick",
        "name_de": "Freistoß",
        "name_nl": "Vrije trap",
        "name_pl": "Free Kick",
        "name_ro": "Free Kick",
        "name_ru": "Free Kick",
        "name_tr": "Free Kick",
        "name_se": "Free Kick",
        "name_el": "Free Kick",
        "name_es": "Free Kick",
        "name_hr": "Free Kick",
        "name_fr": "Free Kick"
    },
    "2076^live_match^8^15:5_or_better": {
        "name_en": "15:5 or better",
        "name_aa": "15:5 or better",
        "name_de": "15:5 oder besser",
        "name_nl": "15:5 of beter",
        "name_pl": "15:5 or better",
        "name_ro": "15:5 or better",
        "name_ru": "15:5 or better",
        "name_tr": "15:5 or better",
        "name_se": "15:5 or better",
        "name_el": "15:5 or better",
        "name_es": "15:5 or better",
        "name_hr": "15:5 or better",
        "name_fr": "15:5 or better"
    },
    "26^live_match^8^14:1": {
        "name_en": "14:1",
        "name_aa": "14:1",
        "name_de": "14:1",
        "name_nl": "14:1",
        "name_pl": "14:1",
        "name_ro": "14:1",
        "name_ru": "14:1",
        "name_tr": "14:1",
        "name_se": "14:1",
        "name_el": "14:1",
        "name_es": "14:1",
        "name_hr": "14:1",
        "name_fr": "14:1"
    },
    "2013^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "2044^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "2050^live_match^6^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2123^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "2099^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "2076^live_match^8^6:15": {
        "name_en": "6:15",
        "name_aa": "6:15",
        "name_de": "6:15",
        "name_nl": "6:15",
        "name_pl": "6:15",
        "name_ro": "6:15",
        "name_ru": "6:15",
        "name_tr": "6:15",
        "name_se": "6:15",
        "name_el": "6:15",
        "name_es": "6:15",
        "name_hr": "6:15",
        "name_fr": "6:15"
    },
    "974^live_match^8^odd": {
        "name_en": "odd",
        "name_aa": "odd",
        "name_de": "ungerade",
        "name_nl": "Oneven",
        "name_pl": "odd",
        "name_ro": "odd",
        "name_ru": "odd",
        "name_tr": "tek",
        "name_se": "odd",
        "name_el": "odd",
        "name_es": "odd",
        "name_hr": "Neparan",
        "name_fr": "impair"
    },
    "1108^live_match^8^11:9": {
        "name_en": "11:9",
        "name_aa": "11:9",
        "name_de": "11:9",
        "name_nl": "11:9",
        "name_pl": "11:9",
        "name_ro": "11:9",
        "name_ru": "11:9",
        "name_tr": "11:9",
        "name_se": "11:9",
        "name_el": "11:9",
        "name_es": "11:9",
        "name_hr": "11:9",
        "name_fr": "11:9"
    },
    "2138^live_match^8^even": {
        "name_en": "even",
        "name_aa": "even",
        "name_de": "Gerade",
        "name_nl": "even",
        "name_pl": "even",
        "name_ro": "even",
        "name_ru": "even",
        "name_tr": "even",
        "name_se": "even",
        "name_el": "even",
        "name_es": "even",
        "name_hr": "Paran",
        "name_fr": "even"
    },
    "2310^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2354^live_match^8^13:8": {
        "name_en": "13:8",
        "name_aa": "13:8",
        "name_de": "13:8",
        "name_nl": "13:8",
        "name_pl": "13:8",
        "name_ro": "13:8",
        "name_ru": "13:8",
        "name_tr": "13:8",
        "name_se": "13:8",
        "name_el": "13:8",
        "name_es": "13:8",
        "name_hr": "13:8",
        "name_fr": "13:8"
    },
    "2030^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "2212^live_match^8^away_25-36": {
        "name_en": "away 25-36",
        "name_aa": "away 25-36",
        "name_de": "Auswärt 25-36",
        "name_nl": "Weg 25-36",
        "name_pl": "away 25-36",
        "name_ro": "away 25-36",
        "name_ru": "away 25-36",
        "name_tr": "away 25-36",
        "name_se": "away 25-36",
        "name_el": "away 25-36",
        "name_es": "away 25-36",
        "name_hr": "Gostujuči 25-36",
        "name_fr": "away 25-36"
    },
    "1108^live_match^8^5:8": {
        "name_en": "5:8",
        "name_aa": "5:8",
        "name_de": "5:8",
        "name_nl": "5:8",
        "name_pl": "5:8",
        "name_ro": "5:8",
        "name_ru": "5:8",
        "name_tr": "5:8",
        "name_se": "5:8",
        "name_el": "5:8",
        "name_es": "5:8",
        "name_hr": "5:8",
        "name_fr": "5:8"
    },
    "2074^live_match^8^25:19": {
        "name_en": "25:19",
        "name_aa": "25:19",
        "name_de": "25:19",
        "name_nl": "25:19",
        "name_pl": "25:19",
        "name_ro": "25:19",
        "name_ru": "25:19",
        "name_tr": "25:19",
        "name_se": "25:19",
        "name_el": "25:19",
        "name_es": "25:19",
        "name_hr": "25:19",
        "name_fr": "25:19"
    },
    "2235^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "1533^live_match^8^no": {
        "name_en": "No",
        "name_aa": "No",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "No",
        "name_ro": "No",
        "name_ru": "No",
        "name_tr": "hayır",
        "name_se": "No",
        "name_el": "No",
        "name_es": "No",
        "name_hr": "Ne",
        "name_fr": "non"
    },
    "2048^live_match^8^hd": {
        "name_en": "HD",
        "name_aa": "HD",
        "name_de": "HD",
        "name_nl": "HD",
        "name_pl": "HD",
        "name_ro": "HD",
        "name_ru": "HD",
        "name_tr": "HD",
        "name_se": "HD",
        "name_el": "HD",
        "name_es": "HD",
        "name_hr": "HD",
        "name_fr": "HD"
    },
    "2213^live_match^8^home_1-6": {
        "name_en": "home 1-6",
        "name_aa": "home 1-6",
        "name_de": "Heim 1-6",
        "name_nl": "Thuis 1-6",
        "name_pl": "home 1-6",
        "name_ro": "home 1-6",
        "name_ru": "home 1-6",
        "name_tr": "home 1-6",
        "name_se": "home 1-6",
        "name_el": "home 1-6",
        "name_es": "home 1-6",
        "name_hr": "Domači 1-6",
        "name_fr": "home 1-6"
    },
    "2159^live_match^8^none": {
        "name_en": "none",
        "name_aa": "none",
        "name_de": "Keiner",
        "name_nl": "Geen",
        "name_pl": "none",
        "name_ro": "none",
        "name_ru": "none",
        "name_tr": "none",
        "name_se": "none",
        "name_el": "none",
        "name_es": "none",
        "name_hr": "Nitko",
        "name_fr": "none"
    },
    "1108^live_match^8^7:5": {
        "name_en": "7:5",
        "name_aa": "7:5",
        "name_de": "7:5",
        "name_nl": "7:5",
        "name_pl": "7:5",
        "name_ro": "7:5",
        "name_ru": "7:5",
        "name_tr": "7:5",
        "name_se": "7:5",
        "name_el": "7:5",
        "name_es": "7:5",
        "name_hr": "7:5",
        "name_fr": "7:5"
    },
    "2071^live_match^8^away_12+": {
        "name_en": "away 12+",
        "name_aa": "away 12+",
        "name_de": "away 12+",
        "name_nl": "Weg 12+",
        "name_pl": "away 12+",
        "name_ro": "away 12+",
        "name_ru": "away 12+",
        "name_tr": "away 12+",
        "name_se": "away 12+",
        "name_el": "away 12+",
        "name_es": "away 12+",
        "name_hr": "Gostujuči 12+",
        "name_fr": "away 12+"
    },
    "116^live_match^8^1:2": {
        "name_en": "1:2",
        "name_aa": "1:2",
        "name_de": "1:2",
        "name_nl": "1:2",
        "name_pl": "1:2",
        "name_ro": "1:2",
        "name_ru": "1:2",
        "name_tr": "1:2",
        "name_se": "1:2",
        "name_el": "1:2",
        "name_es": "1:2",
        "name_hr": "1:2",
        "name_fr": "1:2"
    },
    "116^live_match^8^0:3": {
        "name_en": "0:3",
        "name_aa": "0:3",
        "name_de": "0:3",
        "name_nl": "0:3",
        "name_pl": "0:3",
        "name_ro": "0:3",
        "name_ru": "0:3",
        "name_tr": "0:3",
        "name_se": "0:3",
        "name_el": "0:3",
        "name_es": "0:3",
        "name_hr": "0:3",
        "name_fr": "0:3"
    },
    "14^live_match^6^x": {
        "name_en": "x",
        "name_aa": "x",
        "name_de": "x",
        "name_nl": "x",
        "name_pl": "x",
        "name_ro": "x",
        "name_ru": "x",
        "name_tr": "x",
        "name_se": "x",
        "name_el": "x",
        "name_es": "x",
        "name_hr": "x",
        "name_fr": "x"
    },
    "2313^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2107^live_match^8^away": {
        "name_en": "away",
        "name_aa": "away",
        "name_de": "Auswärts",
        "name_nl": "Uit",
        "name_pl": "away",
        "name_ro": "away",
        "name_ru": "away",
        "name_tr": "away",
        "name_se": "away",
        "name_el": "away",
        "name_es": "away",
        "name_hr": "Gostujući",
        "name_fr": "away"
    },
    "116^live_match^8^2:1": {
        "name_en": "2:1",
        "name_aa": "2:1",
        "name_de": "2:1",
        "name_nl": "2:1",
        "name_pl": "2:1",
        "name_ro": "2:1",
        "name_ru": "2:1",
        "name_tr": "2:1",
        "name_se": "2:1",
        "name_el": "2:1",
        "name_es": "2:1",
        "name_hr": "2:1",
        "name_fr": "2:1"
    },
    "1382^live_match^8^under": {
        "name_en": "Under",
        "name_aa": "u",
        "name_de": "Unter",
        "name_nl": "u",
        "name_pl": "u",
        "name_ro": "u",
        "name_ru": "u",
        "name_tr": "Alt",
        "name_se": "u",
        "name_el": "u",
        "name_es": "u",
        "name_hr": "Manje",
        "name_fr": "m"
    },
    "116^live_match^8^3:3": {
        "name_en": "3:3",
        "name_aa": "3:3",
        "name_de": "3:3",
        "name_nl": "3:3",
        "name_pl": "3:3",
        "name_ro": "3:3",
        "name_ru": "3:3",
        "name_tr": "3:3",
        "name_se": "3:3",
        "name_el": "3:3",
        "name_es": "3:3",
        "name_hr": "3:3",
        "name_fr": "3:3"
    },
    "520^live_match^8^0:6": {
        "name_en": "0:6",
        "name_aa": "0:6",
        "name_de": "0:6",
        "name_nl": "0:6",
        "name_pl": "0:6",
        "name_ro": "0:6",
        "name_ru": "0:6",
        "name_tr": "0:6",
        "name_se": "0:6",
        "name_el": "0:6",
        "name_es": "0:6",
        "name_hr": "0:6",
        "name_fr": "0:6"
    },
    "81^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "29^live_match^8^11:1": {
        "name_en": "11:1",
        "name_aa": "11:1",
        "name_de": "11:1",
        "name_nl": "11:1",
        "name_pl": "11:1",
        "name_ro": "11:1",
        "name_ru": "11:1",
        "name_tr": "11:1",
        "name_se": "11:1",
        "name_el": "11:1",
        "name_es": "11:1",
        "name_hr": "11:1",
        "name_fr": "11:1"
    },
    "29^live_match^8^1:11": {
        "name_en": "1:11",
        "name_aa": "1:11",
        "name_de": "1:11",
        "name_nl": "1:11",
        "name_pl": "1:11",
        "name_ro": "1:11",
        "name_ru": "1:11",
        "name_tr": "1:11",
        "name_se": "1:11",
        "name_el": "1:11",
        "name_es": "1:11",
        "name_hr": "1:11",
        "name_fr": "1:11"
    },
    "2074^live_match^8^10:15": {
        "name_en": "10:15",
        "name_aa": "10:15",
        "name_de": "10:15",
        "name_nl": "10:15",
        "name_pl": "10:15",
        "name_ro": "10:15",
        "name_ru": "10:15",
        "name_tr": "10:15",
        "name_se": "10:15",
        "name_el": "10:15",
        "name_es": "10:15",
        "name_hr": "10:15",
        "name_fr": "10:15"
    },
    "914^live_match^8^no": {
        "name_en": "No",
        "name_aa": "No",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "No",
        "name_ro": "No",
        "name_ru": "No",
        "name_tr": "hayır",
        "name_se": "No",
        "name_el": "No",
        "name_es": "No",
        "name_hr": "Ne",
        "name_fr": "non"
    },
    "2075^live_match^8^25:15_or_better": {
        "name_en": "25:15 or better",
        "name_aa": "25:15 or better",
        "name_de": "25:15 oder besser",
        "name_nl": "25:15 of beter",
        "name_pl": "25:15 or better",
        "name_ro": "25:15 or better",
        "name_ru": "25:15 or better",
        "name_tr": "25:15 or better",
        "name_se": "25:15 or better",
        "name_el": "25:15 or better",
        "name_es": "25:15 or better",
        "name_hr": "25:15 or better",
        "name_fr": "25:15 or better"
    },
    "2219^live_match^8^2:0": {
        "name_en": "2:0",
        "name_aa": "2:0",
        "name_de": "2:0",
        "name_nl": "2:0",
        "name_pl": "2:0",
        "name_ro": "2:0",
        "name_ru": "2:0",
        "name_tr": "2:0",
        "name_se": "2:0",
        "name_el": "2:0",
        "name_es": "2:0",
        "name_hr": "2:0",
        "name_fr": "2:0"
    },
    "117^live_match^8^1:0": {
        "name_en": "Overtime first half Correct score flex",
        "name_aa": "Overtime first half Correct score flex",
        "name_de": "Verlängerung erste Halbzeit genaues Ergebnis",
        "name_nl": "Extra tijd eerste helft Correcte score flex",
        "name_pl": "Overtime first half Correct score flex",
        "name_ro": "Overtime first half Correct score flex",
        "name_ru": "Overtime first half Correct score flex",
        "name_tr": "Uzatmalar İlk Devre Doğru Skor Aralığı",
        "name_se": "Overtime first half Correct score flex",
        "name_el": "Overtime first half Correct score flex",
        "name_es": "Overtime first half Correct score flex",
        "name_hr": "Fleksibilan Točan rezultat u prvom produžetku",
        "name_fr": "1ère Mi-temps des prolongations Score correct flexible"
    },
    "183^live_match^8^home_and_over": {
        "name_en": "home and over",
        "name_aa": "home and over",
        "name_de": "Heimmanschaft und mehr",
        "name_nl": "thuis en boven",
        "name_pl": "home and over",
        "name_ro": "home and over",
        "name_ru": "home and over",
        "name_tr": "ev sahibi ve üst",
        "name_se": "home and over",
        "name_el": "home and over",
        "name_es": "home and over",
        "name_hr": "domaćin i više",
        "name_fr": "Domicile et Plus de"
    },
    "2147^live_match^8^away_2+": {
        "name_en": "away 2+",
        "name_aa": "away 2+",
        "name_de": "Auswärt 2+",
        "name_nl": "Weg 2+",
        "name_pl": "away 2+",
        "name_ro": "away 2+",
        "name_ru": "away 2+",
        "name_tr": "away 2+",
        "name_se": "away 2+",
        "name_el": "away 2+",
        "name_es": "away 2+",
        "name_hr": "Gostujuči 2+",
        "name_fr": "away 2+"
    },
    "2354^live_match^8^7:5": {
        "name_en": "7:5",
        "name_aa": "7:5",
        "name_de": "7:5",
        "name_nl": "7:5",
        "name_pl": "7:5",
        "name_ro": "7:5",
        "name_ru": "7:5",
        "name_tr": "7:5",
        "name_se": "7:5",
        "name_el": "7:5",
        "name_es": "7:5",
        "name_hr": "7:5",
        "name_fr": "7:5"
    },
    "1108^live_match^8^5:2": {
        "name_en": "5:2",
        "name_aa": "5:2",
        "name_de": "5:2",
        "name_nl": "5:2",
        "name_pl": "5:2",
        "name_ro": "5:2",
        "name_ru": "5:2",
        "name_tr": "5:2",
        "name_se": "5:2",
        "name_el": "5:2",
        "name_es": "5:2",
        "name_hr": "5:2",
        "name_fr": "5:2"
    },
    "1156^live_match^8^0:2": {
        "name_en": "0:2",
        "name_aa": "0:2",
        "name_de": "0:2",
        "name_nl": "0:2",
        "name_pl": "0:2",
        "name_ro": "0:2",
        "name_ru": "0:2",
        "name_tr": "0:2",
        "name_se": "0:2",
        "name_el": "0:2",
        "name_es": "0:2",
        "name_hr": "0:2",
        "name_fr": "0:2"
    },
    "2354^live_match^8^2:11": {
        "name_en": "2:11",
        "name_aa": "2:11",
        "name_de": "2:11",
        "name_nl": "2:11",
        "name_pl": "2:11",
        "name_ro": "2:11",
        "name_ru": "2:11",
        "name_tr": "2:11",
        "name_se": "2:11",
        "name_el": "2:11",
        "name_es": "2:11",
        "name_hr": "2:11",
        "name_fr": "2:11"
    },
    "78^live_match^8^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2125^live_match^8^none": {
        "name_en": "none",
        "name_aa": "none",
        "name_de": "Keiner",
        "name_nl": "Geen",
        "name_pl": "none",
        "name_ro": "none",
        "name_ru": "none",
        "name_tr": "none",
        "name_se": "none",
        "name_el": "none",
        "name_es": "none",
        "name_hr": "Nitko",
        "name_fr": "none"
    },
    "1108^live_match^8^6:2": {
        "name_en": "6:2",
        "name_aa": "6:2",
        "name_de": "6:2",
        "name_nl": "6:2",
        "name_pl": "6:2",
        "name_ro": "6:2",
        "name_ru": "6:2",
        "name_tr": "6:2",
        "name_se": "6:2",
        "name_el": "6:2",
        "name_es": "6:2",
        "name_hr": "6:2",
        "name_fr": "6:2"
    },
    "117^live_match^8^1:3": {
        "name_en": "1:3",
        "name_aa": "1:3",
        "name_de": "1:3",
        "name_nl": "1:3",
        "name_pl": "1:3",
        "name_ro": "1:3",
        "name_ru": "1:3",
        "name_tr": "1:3",
        "name_se": "1:3",
        "name_el": "1:3",
        "name_es": "1:3",
        "name_hr": "1:3",
        "name_fr": "1:3"
    },
    "2354^live_match^8^8:11": {
        "name_en": "8:11",
        "name_aa": "8:11",
        "name_de": "8:11",
        "name_nl": "8:11",
        "name_pl": "8:11",
        "name_ro": "8:11",
        "name_ru": "8:11",
        "name_tr": "8:11",
        "name_se": "8:11",
        "name_el": "8:11",
        "name_es": "8:11",
        "name_hr": "8:11",
        "name_fr": "8:11"
    },
    "2354^live_match^8^5:2": {
        "name_en": "5:2",
        "name_aa": "5:2",
        "name_de": "5:2",
        "name_nl": "5:2",
        "name_pl": "5:2",
        "name_ro": "5:2",
        "name_ru": "5:2",
        "name_tr": "5:2",
        "name_se": "5:2",
        "name_el": "5:2",
        "name_es": "5:2",
        "name_hr": "5:2",
        "name_fr": "5:2"
    },
    "2081^live_match^8^6:1": {
        "name_en": "6:1",
        "name_aa": "6:1",
        "name_de": "6:1",
        "name_nl": "6:1",
        "name_pl": "6:1",
        "name_ro": "6:1",
        "name_ru": "6:1",
        "name_tr": "6:1",
        "name_se": "6:1",
        "name_el": "6:1",
        "name_es": "6:1",
        "name_hr": "6:1",
        "name_fr": "6:1"
    },
    "2261^live_match^8^1:3": {
        "name_en": "1:3",
        "name_aa": "1:3",
        "name_de": "1:3",
        "name_nl": "1:3",
        "name_pl": "1:3",
        "name_ro": "1:3",
        "name_ru": "1:3",
        "name_tr": "1:3",
        "name_se": "1:3",
        "name_el": "1:3",
        "name_es": "1:3",
        "name_hr": "1:3",
        "name_fr": "1:3"
    },
    "2354^live_match^8^6:0": {
        "name_en": "6:0",
        "name_aa": "6:0",
        "name_de": "6:0",
        "name_nl": "6:0",
        "name_pl": "6:0",
        "name_ro": "6:0",
        "name_ru": "6:0",
        "name_tr": "6:0",
        "name_se": "6:0",
        "name_el": "6:0",
        "name_es": "6:0",
        "name_hr": "6:0",
        "name_fr": "6:0"
    },
    "116^live_match^8^4:3": {
        "name_en": "4:3",
        "name_aa": "4:3",
        "name_de": "4:3",
        "name_nl": "4:3",
        "name_pl": "4:3",
        "name_ro": "4:3",
        "name_ru": "4:3",
        "name_tr": "4:3",
        "name_se": "4:3",
        "name_el": "4:3",
        "name_es": "4:3",
        "name_hr": "4:3",
        "name_fr": "4:3"
    },
    "2040^live_match^8^yes": {
        "name_en": "yes",
        "name_aa": "yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "yes",
        "name_ro": "yes",
        "name_ru": "yes",
        "name_tr": "yes",
        "name_se": "yes",
        "name_el": "yes",
        "name_es": "yes",
        "name_hr": "Da",
        "name_fr": "yes"
    },
    "2128^live_match^8^4:11": {
        "name_en": "4:11",
        "name_aa": "4:11",
        "name_de": "4:11",
        "name_nl": "4:11",
        "name_pl": "4:11",
        "name_ro": "4:11",
        "name_ru": "4:11",
        "name_tr": "4:11",
        "name_se": "4:11",
        "name_el": "4:11",
        "name_es": "4:11",
        "name_hr": "4:11",
        "name_fr": "4:11"
    },
    "2214^live_match^8^away_21+": {
        "name_en": "away 21+",
        "name_aa": "away 21+",
        "name_de": "Auswärt 21+",
        "name_nl": "Weg 21+",
        "name_pl": "away 21+",
        "name_ro": "away 21+",
        "name_ru": "away 21+",
        "name_tr": "away 21+",
        "name_se": "away 21+",
        "name_el": "away 21+",
        "name_es": "away 21+",
        "name_hr": "Gostujuči 21+",
        "name_fr": "away 21+"
    },
    "2321^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "49^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2137^live_match^8^even": {
        "name_en": "even",
        "name_aa": "even",
        "name_de": "Gerade",
        "name_nl": "even",
        "name_pl": "even",
        "name_ro": "even",
        "name_ru": "even",
        "name_tr": "even",
        "name_se": "even",
        "name_el": "even",
        "name_es": "even",
        "name_hr": "Paran",
        "name_fr": "even"
    },
    "2072^live_match^8^21:18": {
        "name_en": "21:18",
        "name_aa": "21:18",
        "name_de": "21:18",
        "name_nl": "21:18",
        "name_pl": "21:18",
        "name_ro": "21:18",
        "name_ru": "21:18",
        "name_tr": "21:18",
        "name_se": "21:18",
        "name_el": "21:18",
        "name_es": "21:18",
        "name_hr": "21:18",
        "name_fr": "21:18"
    },
    "2354^live_match^8^2:3": {
        "name_en": "2:3",
        "name_aa": "2:3",
        "name_de": "2:3",
        "name_nl": "2:3",
        "name_pl": "2:3",
        "name_ro": "2:3",
        "name_ru": "2:3",
        "name_tr": "2:3",
        "name_se": "2:3",
        "name_el": "2:3",
        "name_es": "2:3",
        "name_hr": "2:3",
        "name_fr": "2:3"
    },
    "232^live_match^8^under": {
        "name_en": "Under",
        "name_aa": "u",
        "name_de": "Unter",
        "name_nl": "u",
        "name_pl": "u",
        "name_ro": "u",
        "name_ru": "u",
        "name_tr": "Alt",
        "name_se": "u",
        "name_el": "u",
        "name_es": "u",
        "name_hr": "Manje",
        "name_fr": "m"
    },
    "2250^live_match^8^no": {
        "name_en": "no",
        "name_aa": "no",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "no",
        "name_ro": "no",
        "name_ru": "no",
        "name_tr": "no",
        "name_se": "no",
        "name_el": "no",
        "name_es": "no",
        "name_hr": "Ne",
        "name_fr": "no"
    },
    "1108^live_match^8^1:8": {
        "name_en": "1:8",
        "name_aa": "1:8",
        "name_de": "1:8",
        "name_nl": "1:8",
        "name_pl": "1:8",
        "name_ro": "1:8",
        "name_ru": "1:8",
        "name_tr": "1:8",
        "name_se": "1:8",
        "name_el": "1:8",
        "name_es": "1:8",
        "name_hr": "1:8",
        "name_fr": "1:8"
    },
    "2254^live_match^8^no": {
        "name_en": "no",
        "name_aa": "no",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "no",
        "name_ro": "no",
        "name_ru": "no",
        "name_tr": "no",
        "name_se": "no",
        "name_el": "no",
        "name_es": "no",
        "name_hr": "Ne",
        "name_fr": "no"
    },
    "341^live_match^8^4:1": {
        "name_en": "4:1",
        "name_aa": "4:1",
        "name_de": "4:1",
        "name_nl": "4:1",
        "name_pl": "4:1",
        "name_ro": "4:1",
        "name_ru": "4:1",
        "name_tr": "4:1",
        "name_se": "4:1",
        "name_el": "4:1",
        "name_es": "4:1",
        "name_hr": "4:1",
        "name_fr": "4:1"
    },
    "2261^live_match^8^5:0": {
        "name_en": "5:0",
        "name_aa": "5:0",
        "name_de": "5:0",
        "name_nl": "5:0",
        "name_pl": "5:0",
        "name_ro": "5:0",
        "name_ru": "5:0",
        "name_tr": "5:0",
        "name_se": "5:0",
        "name_el": "5:0",
        "name_es": "5:0",
        "name_hr": "5:0",
        "name_fr": "5:0"
    },
    "1044^live_match^8^12": {
        "name_en": "12",
        "name_aa": "12",
        "name_de": "12",
        "name_nl": "12",
        "name_pl": "12",
        "name_ro": "12",
        "name_ru": "12",
        "name_tr": "12",
        "name_se": "12",
        "name_el": "12",
        "name_es": "12",
        "name_hr": "12",
        "name_fr": "12"
    },
    "2169^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2142^live_match^8^away_7+": {
        "name_en": "away 7+",
        "name_aa": "away 7+",
        "name_de": "Auswärt 7+",
        "name_nl": "Weg 7+",
        "name_pl": "away 7+",
        "name_ro": "away 7+",
        "name_ru": "away 7+",
        "name_tr": "away 7+",
        "name_se": "away 7+",
        "name_el": "away 7+",
        "name_es": "away 7+",
        "name_hr": "Gostujuči 7+",
        "name_fr": "away 7+"
    },
    "2207^live_match^8^home_25-36": {
        "name_en": "home 25-36",
        "name_aa": "home 25-36",
        "name_de": "Heim 25-36",
        "name_nl": "Thuis 25-36",
        "name_pl": "home 25-36",
        "name_ro": "home 25-36",
        "name_ru": "home 25-36",
        "name_tr": "home 25-36",
        "name_se": "home 25-36",
        "name_el": "home 25-36",
        "name_es": "home 25-36",
        "name_hr": "Domači 25-36",
        "name_fr": "home 25-36"
    },
    "1156^live_match^8^3:1": {
        "name_en": "3:1",
        "name_aa": "3:1",
        "name_de": "3:1",
        "name_nl": "3:1",
        "name_pl": "3:1",
        "name_ro": "3:1",
        "name_ru": "3:1",
        "name_tr": "3:1",
        "name_se": "3:1",
        "name_el": "3:1",
        "name_es": "3:1",
        "name_hr": "3:1",
        "name_fr": "3:1"
    },
    "2354^live_match^8^12:0": {
        "name_en": "12:0",
        "name_aa": "12:0",
        "name_de": "12:0",
        "name_nl": "12:0",
        "name_pl": "12:0",
        "name_ro": "12:0",
        "name_ru": "12:0",
        "name_tr": "12:0",
        "name_se": "12:0",
        "name_el": "12:0",
        "name_es": "12:0",
        "name_hr": "12:0",
        "name_fr": "12:0"
    },
    "2076^live_match^8^8:15": {
        "name_en": "8:15",
        "name_aa": "8:15",
        "name_de": "8:15",
        "name_nl": "8:15",
        "name_pl": "8:15",
        "name_ro": "8:15",
        "name_ru": "8:15",
        "name_tr": "8:15",
        "name_se": "8:15",
        "name_el": "8:15",
        "name_es": "8:15",
        "name_hr": "8:15",
        "name_fr": "8:15"
    },
    "84^live_match^8^under": {
        "name_en": "Under",
        "name_aa": "u",
        "name_de": "Unter",
        "name_nl": "u",
        "name_pl": "u",
        "name_ro": "u",
        "name_ru": "u",
        "name_tr": "Alt",
        "name_se": "u",
        "name_el": "u",
        "name_es": "u",
        "name_hr": "Manje",
        "name_fr": "m"
    },
    "1108^live_match^8^1:7": {
        "name_en": "1:7",
        "name_aa": "1:7",
        "name_de": "1:7",
        "name_nl": "1:7",
        "name_pl": "1:7",
        "name_ro": "1:7",
        "name_ru": "1:7",
        "name_tr": "1:7",
        "name_se": "1:7",
        "name_el": "1:7",
        "name_es": "1:7",
        "name_hr": "1:7",
        "name_fr": "1:7"
    },
    "2354^live_match^8^11:1": {
        "name_en": "11:1",
        "name_aa": "11:1",
        "name_de": "11:1",
        "name_nl": "11:1",
        "name_pl": "11:1",
        "name_ro": "11:1",
        "name_ru": "11:1",
        "name_tr": "11:1",
        "name_se": "11:1",
        "name_el": "11:1",
        "name_es": "11:1",
        "name_hr": "11:1",
        "name_fr": "11:1"
    },
    "2354^live_match^8^3:3": {
        "name_en": "3:3",
        "name_aa": "3:3",
        "name_de": "3:3",
        "name_nl": "3:3",
        "name_pl": "3:3",
        "name_ro": "3:3",
        "name_ru": "3:3",
        "name_tr": "3:3",
        "name_se": "3:3",
        "name_el": "3:3",
        "name_es": "3:3",
        "name_hr": "3:3",
        "name_fr": "3:3"
    },
    "30^live_match^8^goal": {
        "name_en": "yes",
        "name_aa": "yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "yes",
        "name_ro": "yes",
        "name_ru": "yes",
        "name_tr": "yes",
        "name_se": "yes",
        "name_el": "yes",
        "name_es": "yes",
        "name_hr": "Da",
        "name_fr": "yes"
    },
    "2169^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "1108^live_match^8^12:3": {
        "name_en": "12:3",
        "name_aa": "12:3",
        "name_de": "12:3",
        "name_nl": "12:3",
        "name_pl": "12:3",
        "name_ro": "12:3",
        "name_ru": "12:3",
        "name_tr": "12:3",
        "name_se": "12:3",
        "name_el": "12:3",
        "name_es": "12:3",
        "name_hr": "12:3",
        "name_fr": "12:3"
    },
    "41^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2354^live_match^8^3:13": {
        "name_en": "3:13",
        "name_aa": "3:13",
        "name_de": "3:13",
        "name_nl": "3:13",
        "name_pl": "3:13",
        "name_ro": "3:13",
        "name_ru": "3:13",
        "name_tr": "3:13",
        "name_se": "3:13",
        "name_el": "3:13",
        "name_es": "3:13",
        "name_hr": "3:13",
        "name_fr": "3:13"
    },
    "2151^live_match^8^none": {
        "name_en": "none",
        "name_aa": "none",
        "name_de": "Keiner",
        "name_nl": "Geen",
        "name_pl": "none",
        "name_ro": "none",
        "name_ru": "none",
        "name_tr": "none",
        "name_se": "none",
        "name_el": "none",
        "name_es": "none",
        "name_hr": "Nitko",
        "name_fr": "none"
    },
    "2015^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "26^live_match^8^3:12": {
        "name_en": "3:12",
        "name_aa": "3:12",
        "name_de": "3:12",
        "name_nl": "3:12",
        "name_pl": "3:12",
        "name_ro": "3:12",
        "name_ru": "3:12",
        "name_tr": "3:12",
        "name_se": "3:12",
        "name_el": "3:12",
        "name_es": "3:12",
        "name_hr": "3:12",
        "name_fr": "3:12"
    },
    "2075^live_match^8^25:22": {
        "name_en": "25:22",
        "name_aa": "25:22",
        "name_de": "25:22",
        "name_nl": "25:22",
        "name_pl": "25:22",
        "name_ro": "25:22",
        "name_ru": "25:22",
        "name_tr": "25:22",
        "name_se": "25:22",
        "name_el": "25:22",
        "name_es": "25:22",
        "name_hr": "25:22",
        "name_fr": "25:22"
    },
    "2156^live_match^8^home_6-8": {
        "name_en": "home 6-8",
        "name_aa": "home 6-8",
        "name_de": "Heim 6-8",
        "name_nl": "Thuis 6-8",
        "name_pl": "home 6-8",
        "name_ro": "home 6-8",
        "name_ru": "home 6-8",
        "name_tr": "home 6-8",
        "name_se": "home 6-8",
        "name_el": "home 6-8",
        "name_es": "home 6-8",
        "name_hr": "Domači 6-8",
        "name_fr": "home 6-8"
    },
    "2033^live_match^8^home_and_over": {
        "name_en": "home and over",
        "name_aa": "home and over",
        "name_de": "Heim und Über",
        "name_nl": "Thuis and Meer dan",
        "name_pl": "home and over",
        "name_ro": "home and over",
        "name_ru": "home and over",
        "name_tr": "home and over",
        "name_se": "home and over",
        "name_el": "home and over",
        "name_es": "home and over",
        "name_hr": "Domači i više",
        "name_fr": "home and over"
    },
    "2354^live_match^8^7:6": {
        "name_en": "7:6",
        "name_aa": "7:6",
        "name_de": "7:6",
        "name_nl": "7:6",
        "name_pl": "7:6",
        "name_ro": "7:6",
        "name_ru": "7:6",
        "name_tr": "7:6",
        "name_se": "7:6",
        "name_el": "7:6",
        "name_es": "7:6",
        "name_hr": "7:6",
        "name_fr": "7:6"
    },
    "2354^live_match^8^8:2": {
        "name_en": "8:2",
        "name_aa": "8:2",
        "name_de": "8:2",
        "name_nl": "8:2",
        "name_pl": "8:2",
        "name_ro": "8:2",
        "name_ru": "8:2",
        "name_tr": "8:2",
        "name_se": "8:2",
        "name_el": "8:2",
        "name_es": "8:2",
        "name_hr": "8:2",
        "name_fr": "8:2"
    },
    "26^live_match^8^15:1": {
        "name_en": "15:1",
        "name_aa": "15:1",
        "name_de": "15:1",
        "name_nl": "15:1",
        "name_pl": "15:1",
        "name_ro": "15:1",
        "name_ru": "15:1",
        "name_tr": "15:1",
        "name_se": "15:1",
        "name_el": "15:1",
        "name_es": "15:1",
        "name_hr": "15:1",
        "name_fr": "15:1"
    },
    "1108^live_match^8^12:2": {
        "name_en": "12:2",
        "name_aa": "12:2",
        "name_de": "12:2",
        "name_nl": "12:2",
        "name_pl": "12:2",
        "name_ro": "12:2",
        "name_ru": "12:2",
        "name_tr": "12:2",
        "name_se": "12:2",
        "name_el": "12:2",
        "name_es": "12:2",
        "name_hr": "12:2",
        "name_fr": "12:2"
    },
    "2157^live_match^8^21:15": {
        "name_en": "21:15",
        "name_aa": "21:15",
        "name_de": "21:15",
        "name_nl": "21:15",
        "name_pl": "21:15",
        "name_ro": "21:15",
        "name_ru": "21:15",
        "name_tr": "21:15",
        "name_se": "21:15",
        "name_el": "21:15",
        "name_es": "21:15",
        "name_hr": "21:15",
        "name_fr": "21:15"
    },
    "2145^live_match^8^home_4-6": {
        "name_en": "home 4-6",
        "name_aa": "home 4-6",
        "name_de": "Heim 4-6",
        "name_nl": "thuis 4-6",
        "name_pl": "home 4-6",
        "name_ro": "home 4-6",
        "name_ru": "home 4-6",
        "name_tr": "home 4-6",
        "name_se": "home 4-6",
        "name_el": "home 4-6",
        "name_es": "home 4-6",
        "name_hr": "Domači 4-6",
        "name_fr": "home 4-6"
    },
    "520^live_match^8^6:2": {
        "name_en": "6:2",
        "name_aa": "6:2",
        "name_de": "6:2",
        "name_nl": "6:2",
        "name_pl": "6:2",
        "name_ro": "6:2",
        "name_ru": "6:2",
        "name_tr": "6:2",
        "name_se": "6:2",
        "name_el": "6:2",
        "name_es": "6:2",
        "name_hr": "6:2",
        "name_fr": "6:2"
    },
    "29^live_match^8^8:2": {
        "name_en": "8:2",
        "name_aa": "8:2",
        "name_de": "8:2",
        "name_nl": "8:2",
        "name_pl": "8:2",
        "name_ro": "8:2",
        "name_ru": "8:2",
        "name_tr": "8:2",
        "name_se": "8:2",
        "name_el": "8:2",
        "name_es": "8:2",
        "name_hr": "8:2",
        "name_fr": "8:2"
    },
    "2353^live_match^8^10-11": {
        "name_en": "away 10-11",
        "name_aa": "away 10-11",
        "name_de": "Auswärt 10-11",
        "name_nl": "Weg 10-11",
        "name_pl": "away 10-11",
        "name_ro": "away 10-11",
        "name_ru": "away 10-11",
        "name_tr": "away 10-11",
        "name_se": "away 10-11",
        "name_el": "away 10-11",
        "name_es": "away 10-11",
        "name_hr": "Gostujuči 10-11",
        "name_fr": "away 10-11"
    },
    "2354^live_match^8^4:14": {
        "name_en": "4:14",
        "name_aa": "4:14",
        "name_de": "4:14",
        "name_nl": "4:14",
        "name_pl": "4:14",
        "name_ro": "4:14",
        "name_ru": "4:14",
        "name_tr": "4:14",
        "name_se": "4:14",
        "name_el": "4:14",
        "name_es": "4:14",
        "name_hr": "4:14",
        "name_fr": "4:14"
    },
    "75^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2261^live_match^8^1:2": {
        "name_en": "1:2",
        "name_aa": "1:2",
        "name_de": "1:2",
        "name_nl": "1:2",
        "name_pl": "1:2",
        "name_ro": "1:2",
        "name_ru": "1:2",
        "name_tr": "1:2",
        "name_se": "1:2",
        "name_el": "1:2",
        "name_es": "1:2",
        "name_hr": "1:2",
        "name_fr": "1:2"
    },
    "1108^live_match^8^8:1": {
        "name_en": "8:1",
        "name_aa": "8:1",
        "name_de": "8:1",
        "name_nl": "8:1",
        "name_pl": "8:1",
        "name_ro": "8:1",
        "name_ru": "8:1",
        "name_tr": "8:1",
        "name_se": "8:1",
        "name_el": "8:1",
        "name_es": "8:1",
        "name_hr": "8:1",
        "name_fr": "8:1"
    },
    "2110^live_match^8^shootout": {
        "name_en": "Shootout",
        "name_aa": "Shootout",
        "name_de": "Shootout",
        "name_nl": "Shootout",
        "name_pl": "Shootout",
        "name_ro": "Shootout",
        "name_ru": "Shootout",
        "name_tr": "Shootout",
        "name_se": "Shootout",
        "name_el": "Shootout",
        "name_es": "Shootout",
        "name_hr": "Shootout",
        "name_fr": "Shootout"
    },
    "1506^live_match^8^none": {
        "name_en": "none",
        "name_aa": "none",
        "name_de": "Keiner",
        "name_nl": "Geen",
        "name_pl": "none",
        "name_ro": "none",
        "name_ru": "none",
        "name_tr": "none",
        "name_se": "none",
        "name_el": "none",
        "name_es": "none",
        "name_hr": "Nitko",
        "name_fr": "none"
    },
    "2145^live_match^8^away_4-6": {
        "name_en": "away 4-6",
        "name_aa": "away 4-6",
        "name_de": "Auswärt 4-6",
        "name_nl": "Weg 4-6",
        "name_pl": "away 4-6",
        "name_ro": "away 4-6",
        "name_ru": "away 4-6",
        "name_tr": "away 4-6",
        "name_se": "away 4-6",
        "name_el": "away 4-6",
        "name_es": "away 4-6",
        "name_hr": "Gostujuči 4-6",
        "name_fr": "away 4-6"
    },
    "1156^live_match^8^1:0": {
        "name_en": "1:0",
        "name_aa": "1:0",
        "name_de": "1:0",
        "name_nl": "1:0",
        "name_pl": "1:0",
        "name_ro": "1:0",
        "name_ru": "1:0",
        "name_tr": "1:0",
        "name_se": "1:0",
        "name_el": "1:0",
        "name_es": "1:0",
        "name_hr": "1:0",
        "name_fr": "1:0"
    },
    "46^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2075^live_match^8^25:18": {
        "name_en": "25:18",
        "name_aa": "25:18",
        "name_de": "25:18",
        "name_nl": "25:18",
        "name_pl": "25:18",
        "name_ro": "25:18",
        "name_ru": "25:18",
        "name_tr": "25:18",
        "name_se": "25:18",
        "name_el": "25:18",
        "name_es": "25:18",
        "name_hr": "25:18",
        "name_fr": "25:18"
    },
    "2071^live_match^8^home_5-7": {
        "name_en": "home 5-7",
        "name_aa": "home 5-7",
        "name_de": "Heim 5-7",
        "name_nl": "thuis 5-7",
        "name_pl": "home 5-7",
        "name_ro": "home 5-7",
        "name_ru": "home 5-7",
        "name_tr": "home 5-7",
        "name_se": "home 5-7",
        "name_el": "home 5-7",
        "name_es": "home 5-7",
        "name_hr": "Domači 5-7",
        "name_fr": "home 5-7"
    },
    "2194^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "2263^live_match^8^yes": {
        "name_en": "yes",
        "name_aa": "yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "yes",
        "name_ro": "yes",
        "name_ru": "yes",
        "name_tr": "yes",
        "name_se": "yes",
        "name_el": "yes",
        "name_es": "yes",
        "name_hr": "Da",
        "name_fr": "yes"
    },
    "26^live_match^8^9:0": {
        "name_en": "9:0",
        "name_aa": "9:0",
        "name_de": "9:0",
        "name_nl": "9:0",
        "name_pl": "9:0",
        "name_ro": "9:0",
        "name_ru": "9:0",
        "name_tr": "9:0",
        "name_se": "9:0",
        "name_el": "9:0",
        "name_es": "9:0",
        "name_hr": "9:0",
        "name_fr": "9:0"
    },
    "1108^live_match^8^6:10": {
        "name_en": "6:10",
        "name_aa": "6:10",
        "name_de": "6:10",
        "name_nl": "6:10",
        "name_pl": "6:10",
        "name_ro": "6:10",
        "name_ru": "6:10",
        "name_tr": "6:10",
        "name_se": "6:10",
        "name_el": "6:10",
        "name_es": "6:10",
        "name_hr": "6:10",
        "name_fr": "6:10"
    },
    "341^live_match^8^4:3": {
        "name_en": "4:3",
        "name_aa": "4:3",
        "name_de": "4:3",
        "name_nl": "4:3",
        "name_pl": "4:3",
        "name_ro": "4:3",
        "name_ru": "4:3",
        "name_tr": "4:3",
        "name_se": "4:3",
        "name_el": "4:3",
        "name_es": "4:3",
        "name_hr": "4:3",
        "name_fr": "4:3"
    },
    "2048^live_match^8^ha": {
        "name_en": "HA",
        "name_aa": "HA",
        "name_de": "HA",
        "name_nl": "HA",
        "name_pl": "HA",
        "name_ro": "HA",
        "name_ru": "HA",
        "name_tr": "HA",
        "name_se": "HA",
        "name_el": "HA",
        "name_es": "HA",
        "name_hr": "HA",
        "name_fr": "HA"
    },
    "2261^live_match^8^7:4": {
        "name_en": "7:4",
        "name_aa": "7:4",
        "name_de": "7:4",
        "name_nl": "7:4",
        "name_pl": "7:4",
        "name_ro": "7:4",
        "name_ru": "7:4",
        "name_tr": "7:4",
        "name_se": "7:4",
        "name_el": "7:4",
        "name_es": "7:4",
        "name_hr": "7:4",
        "name_fr": "7:4"
    },
    "230^live_match^8^away_ot": {
        "name_en": "Away OT",
        "name_aa": "Away OT",
        "name_de": "Verlängerung - Auswärtsmannschaft",
        "name_nl": "Uit ET",
        "name_pl": "Away OT",
        "name_ro": "Away OT",
        "name_ru": "Away OT",
        "name_tr": "uzatmalar sonunda deplasman",
        "name_se": "Away OT",
        "name_el": "Away OT",
        "name_es": "Away OT",
        "name_hr": "Pobjeda gostiju nakon produžetka",
        "name_fr": "Extérieur Prolong."
    },
    "29^live_match^8^3:3": {
        "name_en": "3:3",
        "name_aa": "3:3",
        "name_de": "3:3",
        "name_nl": "3:3",
        "name_pl": "3:3",
        "name_ro": "3:3",
        "name_ru": "3:3",
        "name_tr": "3:3",
        "name_se": "3:3",
        "name_el": "3:3",
        "name_es": "3:3",
        "name_hr": "3:3",
        "name_fr": "3:3"
    },
    "2072^live_match^8^21:13": {
        "name_en": "21:13",
        "name_aa": "21:13",
        "name_de": "21:13",
        "name_nl": "21:13",
        "name_pl": "21:13",
        "name_ro": "21:13",
        "name_ru": "21:13",
        "name_tr": "21:13",
        "name_se": "21:13",
        "name_el": "21:13",
        "name_es": "21:13",
        "name_hr": "21:13",
        "name_fr": "21:13"
    },
    "2212^live_match^8^home_13-24": {
        "name_en": "home 13-24",
        "name_aa": "home 13-24",
        "name_de": "Heim 13-24",
        "name_nl": "Thuis 13-24",
        "name_pl": "home 13-24",
        "name_ro": "home 13-24",
        "name_ru": "home 13-24",
        "name_tr": "home 13-24",
        "name_se": "home 13-24",
        "name_el": "home 13-24",
        "name_es": "home 13-24",
        "name_hr": "Domači 13-24",
        "name_fr": "home 13-24"
    },
    "2001^live_match^8^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2239^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "29^live_match^8^3:6": {
        "name_en": "3:6",
        "name_aa": "3:6",
        "name_de": "3:6",
        "name_nl": "3:6",
        "name_pl": "3:6",
        "name_ro": "3:6",
        "name_ru": "3:6",
        "name_tr": "3:6",
        "name_se": "3:6",
        "name_el": "3:6",
        "name_es": "3:6",
        "name_hr": "3:6",
        "name_fr": "3:6"
    },
    "2235^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "1108^live_match^8^9:1": {
        "name_en": "9:1",
        "name_aa": "9:1",
        "name_de": "9:1",
        "name_nl": "9:1",
        "name_pl": "9:1",
        "name_ro": "9:1",
        "name_ru": "9:1",
        "name_tr": "9:1",
        "name_se": "9:1",
        "name_el": "9:1",
        "name_es": "9:1",
        "name_hr": "9:1",
        "name_fr": "9:1"
    },
    "26^live_match^8^6:5": {
        "name_en": "6:5",
        "name_aa": "6:5",
        "name_de": "6:5",
        "name_nl": "6:5",
        "name_pl": "6:5",
        "name_ro": "6:5",
        "name_ru": "6:5",
        "name_tr": "6:5",
        "name_se": "6:5",
        "name_el": "6:5",
        "name_es": "6:5",
        "name_hr": "6:5",
        "name_fr": "6:5"
    },
    "1108^live_match^8^2:0": {
        "name_en": "2:0",
        "name_aa": "2:0",
        "name_de": "2:0",
        "name_nl": "2:0",
        "name_pl": "2:0",
        "name_ro": "2:0",
        "name_ru": "2:0",
        "name_tr": "2:0",
        "name_se": "2:0",
        "name_el": "2:0",
        "name_es": "2:0",
        "name_hr": "2:0",
        "name_fr": "2:0"
    },
    "14^live_match^6^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2324^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "2354^live_match^8^3:1": {
        "name_en": "3:1",
        "name_aa": "3:1",
        "name_de": "3:1",
        "name_nl": "3:1",
        "name_pl": "3:1",
        "name_ro": "3:1",
        "name_ru": "3:1",
        "name_tr": "3:1",
        "name_se": "3:1",
        "name_el": "3:1",
        "name_es": "3:1",
        "name_hr": "3:1",
        "name_fr": "3:1"
    },
    "2072^live_match^8^18:21": {
        "name_en": "18:21",
        "name_aa": "18:21",
        "name_de": "18:21",
        "name_nl": "18:21",
        "name_pl": "18:21",
        "name_ro": "18:21",
        "name_ru": "18:21",
        "name_tr": "18:21",
        "name_se": "18:21",
        "name_el": "18:21",
        "name_es": "18:21",
        "name_hr": "18:21",
        "name_fr": "18:21"
    },
    "2261^live_match^8^4:6": {
        "name_en": "4:6",
        "name_aa": "4:6",
        "name_de": "4:6",
        "name_nl": "4:6",
        "name_pl": "4:6",
        "name_ro": "4:6",
        "name_ru": "4:6",
        "name_tr": "4:6",
        "name_se": "4:6",
        "name_el": "4:6",
        "name_es": "4:6",
        "name_hr": "4:6",
        "name_fr": "4:6"
    },
    "2172^live_match^8^hh": {
        "name_en": "HH",
        "name_aa": "HH",
        "name_de": "HH",
        "name_nl": "HH",
        "name_pl": "HH",
        "name_ro": "HH",
        "name_ru": "HH",
        "name_tr": "HH",
        "name_se": "HH",
        "name_el": "HH",
        "name_es": "HH",
        "name_hr": "HH",
        "name_fr": "HH"
    },
    "2316^live_match^8^10:0": {
        "name_en": "10:0",
        "name_aa": "10:0",
        "name_de": "10:0",
        "name_nl": "10:0",
        "name_pl": "10:0",
        "name_ro": "10:0",
        "name_ru": "10:0",
        "name_tr": "10:0",
        "name_se": "10:0",
        "name_el": "10:0",
        "name_es": "10:0",
        "name_hr": "10:0",
        "name_fr": "10:0"
    },
    "2181^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "2197^live_match^8^yes": {
        "name_en": "yes",
        "name_aa": "yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "yes",
        "name_ro": "yes",
        "name_ru": "yes",
        "name_tr": "yes",
        "name_se": "yes",
        "name_el": "yes",
        "name_es": "yes",
        "name_hr": "Da",
        "name_fr": "yes"
    },
    "295^live_match^8^over": {
        "name_en": "Over",
        "name_aa": "Over",
        "name_de": "Über",
        "name_nl": "Over",
        "name_pl": "Over",
        "name_ro": "Over",
        "name_ru": "Over",
        "name_tr": "Üst",
        "name_se": "Over",
        "name_el": "Over",
        "name_es": "Over",
        "name_hr": "Više",
        "name_fr": "p"
    },
    "116^live_match^8^3:2": {
        "name_en": "3:2",
        "name_aa": "3:2",
        "name_de": "3:2",
        "name_nl": "3:2",
        "name_pl": "3:2",
        "name_ro": "3:2",
        "name_ru": "3:2",
        "name_tr": "3:2",
        "name_se": "3:2",
        "name_el": "3:2",
        "name_es": "3:2",
        "name_hr": "3:2",
        "name_fr": "3:2"
    },
    "2354^live_match^8^12:2": {
        "name_en": "12:2",
        "name_aa": "12:2",
        "name_de": "12:2",
        "name_nl": "12:2",
        "name_pl": "12:2",
        "name_ro": "12:2",
        "name_ru": "12:2",
        "name_tr": "12:2",
        "name_se": "12:2",
        "name_el": "12:2",
        "name_es": "12:2",
        "name_hr": "12:2",
        "name_fr": "12:2"
    },
    "2172^live_match^8^other": {
        "name_en": "other",
        "name_aa": "other",
        "name_de": "Sonstig",
        "name_nl": "Andere",
        "name_pl": "other",
        "name_ro": "other",
        "name_ru": "other",
        "name_tr": "other",
        "name_se": "other",
        "name_el": "other",
        "name_es": "other",
        "name_hr": "Ostali",
        "name_fr": "other"
    },
    "2071^live_match^8^home_8-11": {
        "name_en": "home 8-11",
        "name_aa": "home 8-11",
        "name_de": "Heim 8-11",
        "name_nl": "thuis 8-11",
        "name_pl": "home 8-11",
        "name_ro": "home 8-11",
        "name_ru": "home 8-11",
        "name_tr": "home 8-11",
        "name_se": "home 8-11",
        "name_el": "home 8-11",
        "name_es": "home 8-11",
        "name_hr": "Domači 8-11",
        "name_fr": "home 8-11"
    },
    "922^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2316^live_match^8^4:10": {
        "name_en": "4:10",
        "name_aa": "4:10",
        "name_de": "4:10",
        "name_nl": "4:10",
        "name_pl": "4:10",
        "name_ro": "4:10",
        "name_ru": "4:10",
        "name_tr": "4:10",
        "name_se": "4:10",
        "name_el": "4:10",
        "name_es": "4:10",
        "name_hr": "4:10",
        "name_fr": "4:10"
    },
    "2073^live_match^8^21:19": {
        "name_en": "21:19",
        "name_aa": "21:19",
        "name_de": "21:19",
        "name_nl": "21:19",
        "name_pl": "21:19",
        "name_ro": "21:19",
        "name_ru": "21:19",
        "name_tr": "21:19",
        "name_se": "21:19",
        "name_el": "21:19",
        "name_es": "21:19",
        "name_hr": "21:19",
        "name_fr": "21:19"
    },
    "2089^live_match^8^15:15": {
        "name_en": "15:15",
        "name_aa": "15:15",
        "name_de": "15:15",
        "name_nl": "15:15",
        "name_pl": "15:15",
        "name_ro": "15:15",
        "name_ru": "15:15",
        "name_tr": "15:15",
        "name_se": "15:15",
        "name_el": "15:15",
        "name_es": "15:15",
        "name_hr": "15:15",
        "name_fr": "15:15"
    },
    "26^live_match^8^1:19": {
        "name_en": "1:19",
        "name_aa": "1:19",
        "name_de": "1:19",
        "name_nl": "1:19",
        "name_pl": "1:19",
        "name_ro": "1:19",
        "name_ru": "1:19",
        "name_tr": "1:19",
        "name_se": "1:19",
        "name_el": "1:19",
        "name_es": "1:19",
        "name_hr": "1:19",
        "name_fr": "1:19"
    },
    "26^live_match^8^1:15": {
        "name_en": "1:15",
        "name_aa": "1:15",
        "name_de": "1:15",
        "name_nl": "1:15",
        "name_pl": "1:15",
        "name_ro": "1:15",
        "name_ru": "1:15",
        "name_tr": "1:15",
        "name_se": "1:15",
        "name_el": "1:15",
        "name_es": "1:15",
        "name_hr": "1:15",
        "name_fr": "1:15"
    },
    "44^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2212^live_match^8^home_1-12": {
        "name_en": "home 1-12",
        "name_aa": "home 1-12",
        "name_de": "Heim 1-12",
        "name_nl": "Thuis 1-12",
        "name_pl": "home 1-12",
        "name_ro": "home 1-12",
        "name_ru": "home 1-12",
        "name_tr": "home 1-12",
        "name_se": "home 1-12",
        "name_el": "home 1-12",
        "name_es": "home 1-12",
        "name_hr": "Domači 1-12",
        "name_fr": "home 1-12"
    },
    "2096^live_match^8^home_no": {
        "name_en": "home no",
        "name_aa": "home no",
        "name_de": "Heim Nein",
        "name_nl": "Thuis Nee",
        "name_pl": "home no",
        "name_ro": "home no",
        "name_ru": "home no",
        "name_tr": "home no",
        "name_se": "home no",
        "name_el": "home no",
        "name_es": "home no",
        "name_hr": "Domači ne",
        "name_fr": "home no"
    },
    "2354^live_match^8^10:9": {
        "name_en": "10:9",
        "name_aa": "10:9",
        "name_de": "10:9",
        "name_nl": "10:9",
        "name_pl": "10:9",
        "name_ro": "10:9",
        "name_ru": "10:9",
        "name_tr": "10:9",
        "name_se": "10:9",
        "name_el": "10:9",
        "name_es": "10:9",
        "name_hr": "10:9",
        "name_fr": "10:9"
    },
    "26^live_match^8^0:13": {
        "name_en": "0:13",
        "name_aa": "0:13",
        "name_de": "0:13",
        "name_nl": "0:13",
        "name_pl": "0:13",
        "name_ro": "0:13",
        "name_ru": "0:13",
        "name_tr": "0:13",
        "name_se": "0:13",
        "name_el": "0:13",
        "name_es": "0:13",
        "name_hr": "0:13",
        "name_fr": "0:13"
    },
    "2006^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "1086^live_match^8^1st_period": {
        "name_en": "1st period",
        "name_aa": "1st period",
        "name_de": "1. Periode",
        "name_nl": "1ste periode",
        "name_pl": "1st period",
        "name_ro": "1st period",
        "name_ru": "1st period",
        "name_tr": "1. periyot",
        "name_se": "1st period",
        "name_el": "1st period",
        "name_es": "1st period",
        "name_hr": "1. period",
        "name_fr": "1ère période"
    },
    "2074^live_match^8^15:08": {
        "name_en": "15:08",
        "name_aa": "15:08",
        "name_de": "15:08",
        "name_nl": "15:08",
        "name_pl": "15:08",
        "name_ro": "15:08",
        "name_ru": "15:08",
        "name_tr": "15:08",
        "name_se": "15:08",
        "name_el": "15:08",
        "name_es": "15:08",
        "name_hr": "15:08",
        "name_fr": "15:08"
    },
    "2170^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2307^live_match^8^0:6": {
        "name_en": "0:6",
        "name_aa": "0:6",
        "name_de": "0:6",
        "name_nl": "0:6",
        "name_pl": "0:6",
        "name_ro": "0:6",
        "name_ru": "0:6",
        "name_tr": "0:6",
        "name_se": "0:6",
        "name_el": "0:6",
        "name_es": "0:6",
        "name_hr": "0:6",
        "name_fr": "0:6"
    },
    "2128^live_match^8^9:11": {
        "name_en": "9:11",
        "name_aa": "9:11",
        "name_de": "9:11",
        "name_nl": "9:11",
        "name_pl": "9:11",
        "name_ro": "9:11",
        "name_ru": "9:11",
        "name_tr": "9:11",
        "name_se": "9:11",
        "name_el": "9:11",
        "name_es": "9:11",
        "name_hr": "9:11",
        "name_fr": "9:11"
    },
    "2037^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "2251^live_match^8^no": {
        "name_en": "no",
        "name_aa": "no",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "no",
        "name_ro": "no",
        "name_ru": "no",
        "name_tr": "no",
        "name_se": "no",
        "name_el": "no",
        "name_es": "no",
        "name_hr": "Ne",
        "name_fr": "no"
    },
    "26^live_match^8^1:13": {
        "name_en": "1:13",
        "name_aa": "1:13",
        "name_de": "1:13",
        "name_nl": "1:13",
        "name_pl": "1:13",
        "name_ro": "1:13",
        "name_ru": "1:13",
        "name_tr": "1:13",
        "name_se": "1:13",
        "name_el": "1:13",
        "name_es": "1:13",
        "name_hr": "1:13",
        "name_fr": "1:13"
    },
    "116^live_match^8^4:1": {
        "name_en": "4:1",
        "name_aa": "4:1",
        "name_de": "4:1",
        "name_nl": "4:1",
        "name_pl": "4:1",
        "name_ro": "4:1",
        "name_ru": "4:1",
        "name_tr": "4:1",
        "name_se": "4:1",
        "name_el": "4:1",
        "name_es": "4:1",
        "name_hr": "4:1",
        "name_fr": "4:1"
    },
    "1156^live_match^8^0:3": {
        "name_en": "0:3",
        "name_aa": "0:3",
        "name_de": "0:3",
        "name_nl": "0:3",
        "name_pl": "0:3",
        "name_ro": "0:3",
        "name_ru": "0:3",
        "name_tr": "0:3",
        "name_se": "0:3",
        "name_el": "0:3",
        "name_es": "0:3",
        "name_hr": "0:3",
        "name_fr": "0:3"
    },
    "2076^live_match^8^15:12": {
        "name_en": "15:12",
        "name_aa": "15:12",
        "name_de": "15:12",
        "name_nl": "15:12",
        "name_pl": "15:12",
        "name_ro": "15:12",
        "name_ru": "15:12",
        "name_tr": "15:12",
        "name_se": "15:12",
        "name_el": "15:12",
        "name_es": "15:12",
        "name_hr": "15:12",
        "name_fr": "15:12"
    },
    "1156^live_match^8^4:2": {
        "name_en": "4:2",
        "name_aa": "4:2",
        "name_de": "4:2",
        "name_nl": "4:2",
        "name_pl": "4:2",
        "name_ro": "4:2",
        "name_ru": "4:2",
        "name_tr": "4:2",
        "name_se": "4:2",
        "name_el": "4:2",
        "name_es": "4:2",
        "name_hr": "4:2",
        "name_fr": "4:2"
    },
    "26^live_match^8^0:24": {
        "name_en": "0:24",
        "name_aa": "0:24",
        "name_de": "0:24",
        "name_nl": "0:24",
        "name_pl": "0:24",
        "name_ro": "0:24",
        "name_ru": "0:24",
        "name_tr": "0:24",
        "name_se": "0:24",
        "name_el": "0:24",
        "name_es": "0:24",
        "name_hr": "0:24",
        "name_fr": "0:24"
    },
    "2348^live_match^8^no_goal": {
        "name_en": "no goal",
        "name_aa": "no goal",
        "name_de": "Kein Tor",
        "name_nl": "Geen doelpunt",
        "name_pl": "no goal",
        "name_ro": "no goal",
        "name_ru": "no goal",
        "name_tr": "no goal",
        "name_se": "no goal",
        "name_el": "no goal",
        "name_es": "no goal",
        "name_hr": "no Golova",
        "name_fr": "no goal"
    },
    "2256^live_match^8^yes": {
        "name_en": "yes",
        "name_aa": "yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "yes",
        "name_ro": "yes",
        "name_ru": "yes",
        "name_tr": "yes",
        "name_se": "yes",
        "name_el": "yes",
        "name_es": "yes",
        "name_hr": "Da",
        "name_fr": "yes"
    },
    "2130^live_match^8^2:1": {
        "name_en": "2:1",
        "name_aa": "2:1",
        "name_de": "2:1",
        "name_nl": "2:1",
        "name_pl": "2:1",
        "name_ro": "2:1",
        "name_ru": "2:1",
        "name_tr": "2:1",
        "name_se": "2:1",
        "name_el": "2:1",
        "name_es": "2:1",
        "name_hr": "2:1",
        "name_fr": "2:1"
    },
    "2341^live_match^8^no": {
        "name_en": "no",
        "name_aa": "no",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "no",
        "name_ro": "no",
        "name_ru": "no",
        "name_tr": "no",
        "name_se": "no",
        "name_el": "no",
        "name_es": "no",
        "name_hr": "Ne",
        "name_fr": "no"
    },
    "2072^live_match^8^25:23": {
        "name_en": "25:23",
        "name_aa": "25:23",
        "name_de": "25:23",
        "name_nl": "25:23",
        "name_pl": "25:23",
        "name_ro": "25:23",
        "name_ru": "25:23",
        "name_tr": "25:23",
        "name_se": "25:23",
        "name_el": "25:23",
        "name_es": "25:23",
        "name_hr": "25:23",
        "name_fr": "25:23"
    },
    "2354^live_match^8^3:11": {
        "name_en": "3:11",
        "name_aa": "3:11",
        "name_de": "3:11",
        "name_nl": "3:11",
        "name_pl": "3:11",
        "name_ro": "3:11",
        "name_ru": "3:11",
        "name_tr": "3:11",
        "name_se": "3:11",
        "name_el": "3:11",
        "name_es": "3:11",
        "name_hr": "3:11",
        "name_fr": "3:11"
    },
    "2047^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "2222^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "77^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "287^live_match^8^1x": {
        "name_en": "1X",
        "name_aa": "1X",
        "name_de": "1X",
        "name_nl": "1X",
        "name_pl": "1X",
        "name_ro": "1X",
        "name_ru": "1X",
        "name_tr": "1X",
        "name_se": "1X",
        "name_el": "1X",
        "name_es": "1X",
        "name_hr": "1X",
        "name_fr": "1X"
    },
    "876^live_match^8^over": {
        "name_en": "Over",
        "name_aa": "Over",
        "name_de": "Über",
        "name_nl": "Over",
        "name_pl": "Over",
        "name_ro": "Over",
        "name_ru": "Over",
        "name_tr": "Üst",
        "name_se": "Over",
        "name_el": "Over",
        "name_es": "Over",
        "name_hr": "Više",
        "name_fr": "p"
    },
    "2142^live_match^8^tie": {
        "name_en": "Tie",
        "name_aa": "Tie",
        "name_de": "Unentschieden",
        "name_nl": "Gelijkspel",
        "name_pl": "Tie",
        "name_ro": "Tie",
        "name_ru": "Tie",
        "name_tr": "Tie",
        "name_se": "Tie",
        "name_el": "Tie",
        "name_es": "Tie",
        "name_hr": "Tie",
        "name_fr": "Tie"
    },
    "76^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "1668^live_match^8^no": {
        "name_en": "no",
        "name_aa": "no",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "no",
        "name_ro": "no",
        "name_ru": "no",
        "name_tr": "no",
        "name_se": "no",
        "name_el": "no",
        "name_es": "no",
        "name_hr": "Ne",
        "name_fr": "no"
    },
    "1108^live_match^8^11:4": {
        "name_en": "11:4",
        "name_aa": "11:4",
        "name_de": "11:4",
        "name_nl": "11:4",
        "name_pl": "11:4",
        "name_ro": "11:4",
        "name_ru": "11:4",
        "name_tr": "11:4",
        "name_se": "11:4",
        "name_el": "11:4",
        "name_es": "11:4",
        "name_hr": "11:4",
        "name_fr": "11:4"
    },
    "26^live_match^8^8:6": {
        "name_en": "8:6",
        "name_aa": "8:6",
        "name_de": "8:6",
        "name_nl": "8:6",
        "name_pl": "8:6",
        "name_ro": "8:6",
        "name_ru": "8:6",
        "name_tr": "8:6",
        "name_se": "8:6",
        "name_el": "8:6",
        "name_es": "8:6",
        "name_hr": "8:6",
        "name_fr": "8:6"
    },
    "0^live_match^4^x": {
        "name_en": "x",
        "name_aa": "x",
        "name_de": "x",
        "name_nl": "x",
        "name_pl": "x",
        "name_ro": "x",
        "name_ru": "x",
        "name_tr": "x",
        "name_se": "x",
        "name_el": "x",
        "name_es": "x",
        "name_hr": "x",
        "name_fr": "x"
    },
    "916^live_match^8^no": {
        "name_en": "No",
        "name_aa": "No",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "No",
        "name_ro": "No",
        "name_ru": "No",
        "name_tr": "hayır",
        "name_se": "No",
        "name_el": "No",
        "name_es": "No",
        "name_hr": "Ne",
        "name_fr": "non"
    },
    "2354^live_match^8^9:7": {
        "name_en": "9:7",
        "name_aa": "9:7",
        "name_de": "9:7",
        "name_nl": "9:7",
        "name_pl": "9:7",
        "name_ro": "9:7",
        "name_ru": "9:7",
        "name_tr": "9:7",
        "name_se": "9:7",
        "name_el": "9:7",
        "name_es": "9:7",
        "name_hr": "9:7",
        "name_fr": "9:7"
    },
    "2252^live_match^8^no": {
        "name_en": "no",
        "name_aa": "no",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "no",
        "name_ro": "no",
        "name_ru": "no",
        "name_tr": "no",
        "name_se": "no",
        "name_el": "no",
        "name_es": "no",
        "name_hr": "Ne",
        "name_fr": "no"
    },
    "2043^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "2236^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "2213^live_match^8^away_19-24": {
        "name_en": "away 19-24",
        "name_aa": "away 19-24",
        "name_de": "Auswärt 19-24",
        "name_nl": "Weg 19-24",
        "name_pl": "away 19-24",
        "name_ro": "away 19-24",
        "name_ru": "away 19-24",
        "name_tr": "away 19-24",
        "name_se": "away 19-24",
        "name_el": "away 19-24",
        "name_es": "away 19-24",
        "name_hr": "Gostujuči 19-24",
        "name_fr": "away 19-24"
    },
    "2354^live_match^8^12:10": {
        "name_en": "12:10",
        "name_aa": "12:10",
        "name_de": "12:10",
        "name_nl": "12:10",
        "name_pl": "12:10",
        "name_ro": "12:10",
        "name_ru": "12:10",
        "name_tr": "12:10",
        "name_se": "12:10",
        "name_el": "12:10",
        "name_es": "12:10",
        "name_hr": "12:10",
        "name_fr": "12:10"
    },
    "2253^live_match^8^no": {
        "name_en": "no",
        "name_aa": "no",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "no",
        "name_ro": "no",
        "name_ru": "no",
        "name_tr": "no",
        "name_se": "no",
        "name_el": "no",
        "name_es": "no",
        "name_hr": "Ne",
        "name_fr": "no"
    },
    "2354^live_match^8^11:8": {
        "name_en": "11:8",
        "name_aa": "11:8",
        "name_de": "11:8",
        "name_nl": "11:8",
        "name_pl": "11:8",
        "name_ro": "11:8",
        "name_ru": "11:8",
        "name_tr": "11:8",
        "name_se": "11:8",
        "name_el": "11:8",
        "name_es": "11:8",
        "name_hr": "11:8",
        "name_fr": "11:8"
    },
    "2343^live_match^8^no": {
        "name_en": "no",
        "name_aa": "no",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "no",
        "name_ro": "no",
        "name_ru": "no",
        "name_tr": "no",
        "name_se": "no",
        "name_el": "no",
        "name_es": "no",
        "name_hr": "Ne",
        "name_fr": "no"
    },
    "2130^live_match^8^3:0": {
        "name_en": "3:0",
        "name_aa": "3:0",
        "name_de": "3:0",
        "name_nl": "3:0",
        "name_pl": "3:0",
        "name_ro": "3:0",
        "name_ru": "3:0",
        "name_tr": "3:0",
        "name_se": "3:0",
        "name_el": "3:0",
        "name_es": "3:0",
        "name_hr": "3:0",
        "name_fr": "3:0"
    },
    "18^live_match^8^2:1": {
        "name_en": "2:1",
        "name_aa": "2:1",
        "name_de": "2:1",
        "name_nl": "2:1",
        "name_pl": "2:1",
        "name_ro": "2:1",
        "name_ru": "2:1",
        "name_tr": "2:1",
        "name_se": "2:1",
        "name_el": "2:1",
        "name_es": "2:1",
        "name_hr": "2:1",
        "name_fr": "2:1"
    },
    "26^live_match^8^17:0": {
        "name_en": "17:0",
        "name_aa": "17:0",
        "name_de": "17:0",
        "name_nl": "17:0",
        "name_pl": "17:0",
        "name_ro": "17:0",
        "name_ru": "17:0",
        "name_tr": "17:0",
        "name_se": "17:0",
        "name_el": "17:0",
        "name_es": "17:0",
        "name_hr": "17:0",
        "name_fr": "17:0"
    },
    "116^live_match^8^0:0": {
        "name_en": "0:0",
        "name_aa": "0:0",
        "name_de": "0:0",
        "name_nl": "0:0",
        "name_pl": "0:0",
        "name_ro": "0:0",
        "name_ru": "0:0",
        "name_tr": "0:0",
        "name_se": "0:0",
        "name_el": "0:0",
        "name_es": "0:0",
        "name_hr": "0:0",
        "name_fr": "0:0"
    },
    "2190^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "2354^live_match^8^3:14": {
        "name_en": "3:14",
        "name_aa": "3:14",
        "name_de": "3:14",
        "name_nl": "3:14",
        "name_pl": "3:14",
        "name_ro": "3:14",
        "name_ru": "3:14",
        "name_tr": "3:14",
        "name_se": "3:14",
        "name_el": "3:14",
        "name_es": "3:14",
        "name_hr": "3:14",
        "name_fr": "3:14"
    },
    "53^live_match^8^under": {
        "name_en": "Under",
        "name_aa": "u",
        "name_de": "Unter",
        "name_nl": "u",
        "name_pl": "u",
        "name_ro": "u",
        "name_ru": "u",
        "name_tr": "Alt",
        "name_se": "u",
        "name_el": "u",
        "name_es": "u",
        "name_hr": "Manje",
        "name_fr": "m"
    },
    "1670^live_match^8^no": {
        "name_en": "no",
        "name_aa": "no",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "no",
        "name_ro": "no",
        "name_ru": "no",
        "name_tr": "no",
        "name_se": "no",
        "name_el": "no",
        "name_es": "no",
        "name_hr": "Ne",
        "name_fr": "no"
    },
    "2069^live_match^8^yes": {
        "name_en": "yes",
        "name_aa": "yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "yes",
        "name_ro": "yes",
        "name_ru": "yes",
        "name_tr": "yes",
        "name_se": "yes",
        "name_el": "yes",
        "name_es": "yes",
        "name_hr": "Da",
        "name_fr": "yes"
    },
    "2354^live_match^8^7:0": {
        "name_en": "7:0",
        "name_aa": "7:0",
        "name_de": "7:0",
        "name_nl": "7:0",
        "name_pl": "7:0",
        "name_ro": "7:0",
        "name_ru": "7:0",
        "name_tr": "7:0",
        "name_se": "7:0",
        "name_el": "7:0",
        "name_es": "7:0",
        "name_hr": "7:0",
        "name_fr": "7:0"
    },
    "1108^live_match^8^4:12": {
        "name_en": "4:12",
        "name_aa": "4:12",
        "name_de": "4:12",
        "name_nl": "4:12",
        "name_pl": "4:12",
        "name_ro": "4:12",
        "name_ru": "4:12",
        "name_tr": "4:12",
        "name_se": "4:12",
        "name_el": "4:12",
        "name_es": "4:12",
        "name_hr": "4:12",
        "name_fr": "4:12"
    },
    "928^live_match^8^under": {
        "name_en": "Under",
        "name_aa": "u",
        "name_de": "Unter",
        "name_nl": "u",
        "name_pl": "u",
        "name_ro": "u",
        "name_ru": "u",
        "name_tr": "Alt",
        "name_se": "u",
        "name_el": "u",
        "name_es": "u",
        "name_hr": "Manje",
        "name_fr": "m"
    },
    "26^live_match^8^9:5": {
        "name_en": "9:5",
        "name_aa": "9:5",
        "name_de": "9:5",
        "name_nl": "9:5",
        "name_pl": "9:5",
        "name_ro": "9:5",
        "name_ru": "9:5",
        "name_tr": "9:5",
        "name_se": "9:5",
        "name_el": "9:5",
        "name_es": "9:5",
        "name_hr": "9:5",
        "name_fr": "9:5"
    },
    "26^live_match^8^0:22": {
        "name_en": "0:22",
        "name_aa": "0:22",
        "name_de": "0:22",
        "name_nl": "0:22",
        "name_pl": "0:22",
        "name_ro": "0:22",
        "name_ru": "0:22",
        "name_tr": "0:22",
        "name_se": "0:22",
        "name_el": "0:22",
        "name_es": "0:22",
        "name_hr": "0:22",
        "name_fr": "0:22"
    },
    "26^live_match^8^0:1": {
        "name_en": "0:1",
        "name_aa": "0:1",
        "name_de": "0:1",
        "name_nl": "0:1",
        "name_pl": "0:1",
        "name_ro": "0:1",
        "name_ru": "0:1",
        "name_tr": "0:1",
        "name_se": "0:1",
        "name_el": "0:1",
        "name_es": "0:1",
        "name_hr": "0:1",
        "name_fr": "0:1"
    },
    "26^live_match^8^2:20": {
        "name_en": "2:20",
        "name_aa": "2:20",
        "name_de": "2:20",
        "name_nl": "2:20",
        "name_pl": "2:20",
        "name_ro": "2:20",
        "name_ru": "2:20",
        "name_tr": "2:20",
        "name_se": "2:20",
        "name_el": "2:20",
        "name_es": "2:20",
        "name_hr": "2:20",
        "name_fr": "2:20"
    },
    "120^live_match^6^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2209^live_match^8^201-225": {
        "name_en": "201-225",
        "name_aa": "201-225",
        "name_de": "201-225",
        "name_nl": "201-225",
        "name_pl": "201-225",
        "name_ro": "201-225",
        "name_ru": "201-225",
        "name_tr": "201-225",
        "name_se": "201-225",
        "name_el": "201-225",
        "name_es": "201-225",
        "name_hr": "201-225",
        "name_fr": "201-225"
    },
    "956^live_match^6^x": {
        "name_en": "x",
        "name_aa": "x",
        "name_de": "x",
        "name_nl": "x",
        "name_pl": "x",
        "name_ro": "x",
        "name_ru": "x",
        "name_tr": "x",
        "name_se": "x",
        "name_el": "x",
        "name_es": "x",
        "name_hr": "x",
        "name_fr": "x"
    },
    "2063^live_match^8^tie": {
        "name_en": "tie",
        "name_aa": "tie",
        "name_de": "Unentschieden",
        "name_nl": "Gelijkspel",
        "name_pl": "tie",
        "name_ro": "tie",
        "name_ru": "tie",
        "name_tr": "tie",
        "name_se": "tie",
        "name_el": "tie",
        "name_es": "tie",
        "name_hr": "tie",
        "name_fr": "tie"
    },
    "2364^live_match^8^home_and_over_and_gg": {
        "name_en": "home and over and GG",
        "name_aa": "home and over and GG",
        "name_de": "Heim und Über und GG",
        "name_nl": "Thuis en meer dan en GG",
        "name_pl": "home and over and GG",
        "name_ro": "home and over and GG",
        "name_ru": "home and over and GG",
        "name_tr": "home and over and GG",
        "name_se": "home and over and GG",
        "name_el": "home and over and GG",
        "name_es": "home and over and GG",
        "name_hr": "home and over and GG",
        "name_fr": "home and over and GG"
    },
    "1664^live_match^2^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "26^live_match^8^9:2": {
        "name_en": "9:2",
        "name_aa": "9:2",
        "name_de": "9:2",
        "name_nl": "9:2",
        "name_pl": "9:2",
        "name_ro": "9:2",
        "name_ru": "9:2",
        "name_tr": "9:2",
        "name_se": "9:2",
        "name_el": "9:2",
        "name_es": "9:2",
        "name_hr": "9:2",
        "name_fr": "9:2"
    },
    "29^live_match^8^1:6": {
        "name_en": "1:6",
        "name_aa": "1:6",
        "name_de": "1:6",
        "name_nl": "1:6",
        "name_pl": "1:6",
        "name_ro": "1:6",
        "name_ru": "1:6",
        "name_tr": "1:6",
        "name_se": "1:6",
        "name_el": "1:6",
        "name_es": "1:6",
        "name_hr": "1:6",
        "name_fr": "1:6"
    },
    "26^live_match^8^13:0": {
        "name_en": "13:0",
        "name_aa": "13:0",
        "name_de": "13:0",
        "name_nl": "13:0",
        "name_pl": "13:0",
        "name_ro": "13:0",
        "name_ru": "13:0",
        "name_tr": "13:0",
        "name_se": "13:0",
        "name_el": "13:0",
        "name_es": "13:0",
        "name_hr": "13:0",
        "name_fr": "13:0"
    },
    "2341^live_match^8^yes": {
        "name_en": "yes",
        "name_aa": "yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "yes",
        "name_ro": "yes",
        "name_ru": "yes",
        "name_tr": "yes",
        "name_se": "yes",
        "name_el": "yes",
        "name_es": "yes",
        "name_hr": "Da",
        "name_fr": "yes"
    },
    "29^live_match^8^3:7": {
        "name_en": "3:7",
        "name_aa": "3:7",
        "name_de": "3:7",
        "name_nl": "3:7",
        "name_pl": "3:7",
        "name_ro": "3:7",
        "name_ru": "3:7",
        "name_tr": "3:7",
        "name_se": "3:7",
        "name_el": "3:7",
        "name_es": "3:7",
        "name_hr": "3:7",
        "name_fr": "3:7"
    },
    "26^live_match^8^10:4": {
        "name_en": "10:4",
        "name_aa": "10:4",
        "name_de": "10:4",
        "name_nl": "10:4",
        "name_pl": "10:4",
        "name_ro": "10:4",
        "name_ru": "10:4",
        "name_tr": "10:4",
        "name_se": "10:4",
        "name_el": "10:4",
        "name_es": "10:4",
        "name_hr": "10:4",
        "name_fr": "10:4"
    },
    "918^live_match^8^1/1": {
        "name_en": "1/1",
        "name_aa": "1/1",
        "name_de": "1/1",
        "name_nl": "1/1",
        "name_pl": "1/1",
        "name_ro": "1/1",
        "name_ru": "1/1",
        "name_tr": "1/1",
        "name_se": "1/1",
        "name_el": "1/1",
        "name_es": "1/1",
        "name_hr": "1/1",
        "name_fr": "1/1"
    },
    "2076^live_match^8^15:08": {
        "name_en": "15:08",
        "name_aa": "15:08",
        "name_de": "15:08",
        "name_nl": "15:08",
        "name_pl": "15:08",
        "name_ro": "15:08",
        "name_ru": "15:08",
        "name_tr": "15:08",
        "name_se": "15:08",
        "name_el": "15:08",
        "name_es": "15:08",
        "name_hr": "15:08",
        "name_fr": "15:08"
    },
    "2347^live_match^8^no": {
        "name_en": "no",
        "name_aa": "no",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "no",
        "name_ro": "no",
        "name_ru": "no",
        "name_tr": "no",
        "name_se": "no",
        "name_el": "no",
        "name_es": "no",
        "name_hr": "Ne",
        "name_fr": "no"
    },
    "2132^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "26^live_match^8^0:15": {
        "name_en": "0:15",
        "name_aa": "0:15",
        "name_de": "0:15",
        "name_nl": "0:15",
        "name_pl": "0:15",
        "name_ro": "0:15",
        "name_ru": "0:15",
        "name_tr": "0:15",
        "name_se": "0:15",
        "name_el": "0:15",
        "name_es": "0:15",
        "name_hr": "0:15",
        "name_fr": "0:15"
    },
    "26^live_match^8^8:5": {
        "name_en": "8:5",
        "name_aa": "8:5",
        "name_de": "8:5",
        "name_nl": "8:5",
        "name_pl": "8:5",
        "name_ro": "8:5",
        "name_ru": "8:5",
        "name_tr": "8:5",
        "name_se": "8:5",
        "name_el": "8:5",
        "name_es": "8:5",
        "name_hr": "8:5",
        "name_fr": "8:5"
    },
    "2349^live_match^8^no_goal": {
        "name_en": "no goal",
        "name_aa": "no goal",
        "name_de": "Kein Tor",
        "name_nl": "Geen doelpunt",
        "name_pl": "no goal",
        "name_ro": "no goal",
        "name_ru": "no goal",
        "name_tr": "no goal",
        "name_se": "no goal",
        "name_el": "no goal",
        "name_es": "no goal",
        "name_hr": "no Golova",
        "name_fr": "no goal"
    },
    "2142^live_match^8^home_3-4": {
        "name_en": "home 3-4",
        "name_aa": "home 3-4",
        "name_de": "Heim 3-4",
        "name_nl": "thuis 3-4",
        "name_pl": "home 3-4",
        "name_ro": "home 3-4",
        "name_ru": "home 3-4",
        "name_tr": "home 3-4",
        "name_se": "home 3-4",
        "name_el": "home 3-4",
        "name_es": "home 3-4",
        "name_hr": "Domači 3-4",
        "name_fr": "home 3-4"
    },
    "2074^live_match^8^15:10": {
        "name_en": "15:10",
        "name_aa": "15:10",
        "name_de": "15:10",
        "name_nl": "15:10",
        "name_pl": "15:10",
        "name_ro": "15:10",
        "name_ru": "15:10",
        "name_tr": "15:10",
        "name_se": "15:10",
        "name_el": "15:10",
        "name_es": "15:10",
        "name_hr": "15:10",
        "name_fr": "15:10"
    },
    "26^live_match^8^12:3": {
        "name_en": "12:3",
        "name_aa": "12:3",
        "name_de": "12:3",
        "name_nl": "12:3",
        "name_pl": "12:3",
        "name_ro": "12:3",
        "name_ru": "12:3",
        "name_tr": "12:3",
        "name_se": "12:3",
        "name_el": "12:3",
        "name_es": "12:3",
        "name_hr": "12:3",
        "name_fr": "12:3"
    },
    "2326^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2141^live_match^8^none": {
        "name_en": "none",
        "name_aa": "none",
        "name_de": "Keiner",
        "name_nl": "Geen",
        "name_pl": "none",
        "name_ro": "none",
        "name_ru": "none",
        "name_tr": "none",
        "name_se": "none",
        "name_el": "none",
        "name_es": "none",
        "name_hr": "Nitko",
        "name_fr": "none"
    },
    "2145^live_match^8^home_7-9": {
        "name_en": "home 7-9",
        "name_aa": "home 7-9",
        "name_de": "Heim 7-9",
        "name_nl": "thuis 7-9",
        "name_pl": "home 7-9",
        "name_ro": "home 7-9",
        "name_ru": "home 7-9",
        "name_tr": "home 7-9",
        "name_se": "home 7-9",
        "name_el": "home 7-9",
        "name_es": "home 7-9",
        "name_hr": "Domači 7-9",
        "name_fr": "home 7-9"
    },
    "2354^live_match^8^5:0": {
        "name_en": "5:0",
        "name_aa": "5:0",
        "name_de": "5:0",
        "name_nl": "5:0",
        "name_pl": "5:0",
        "name_ro": "5:0",
        "name_ru": "5:0",
        "name_tr": "5:0",
        "name_se": "5:0",
        "name_el": "5:0",
        "name_es": "5:0",
        "name_hr": "5:0",
        "name_fr": "5:0"
    },
    "2128^live_match^8^11:8": {
        "name_en": "11:8",
        "name_aa": "11:8",
        "name_de": "11:8",
        "name_nl": "11:8",
        "name_pl": "11:8",
        "name_ro": "11:8",
        "name_ru": "11:8",
        "name_tr": "11:8",
        "name_se": "11:8",
        "name_el": "11:8",
        "name_es": "11:8",
        "name_hr": "11:8",
        "name_fr": "11:8"
    },
    "1320^live_match^8^away": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2224^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "80^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "958^live_match^6^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2073^live_match^8^25:21": {
        "name_en": "25:21",
        "name_aa": "25:21",
        "name_de": "25:21",
        "name_nl": "25:21",
        "name_pl": "25:21",
        "name_ro": "25:21",
        "name_ru": "25:21",
        "name_tr": "25:21",
        "name_se": "25:21",
        "name_el": "25:21",
        "name_es": "25:21",
        "name_hr": "25:21",
        "name_fr": "25:21"
    },
    "2008^live_match^6^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "2354^live_match^8^0:2": {
        "name_en": "0:2",
        "name_aa": "0:2",
        "name_de": "0:2",
        "name_nl": "0:2",
        "name_pl": "0:2",
        "name_ro": "0:2",
        "name_ru": "0:2",
        "name_tr": "0:2",
        "name_se": "0:2",
        "name_el": "0:2",
        "name_es": "0:2",
        "name_hr": "0:2",
        "name_fr": "0:2"
    },
    "116^live_match^8^1:4": {
        "name_en": "1:4",
        "name_aa": "1:4",
        "name_de": "1:4",
        "name_nl": "1:4",
        "name_pl": "1:4",
        "name_ro": "1:4",
        "name_ru": "1:4",
        "name_tr": "1:4",
        "name_se": "1:4",
        "name_el": "1:4",
        "name_es": "1:4",
        "name_hr": "1:4",
        "name_fr": "1:4"
    },
    "2181^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "1108^live_match^8^15:0": {
        "name_en": "15:0",
        "name_aa": "15:0",
        "name_de": "15:0",
        "name_nl": "15:0",
        "name_pl": "15:0",
        "name_ro": "15:0",
        "name_ru": "15:0",
        "name_tr": "15:0",
        "name_se": "15:0",
        "name_el": "15:0",
        "name_es": "15:0",
        "name_hr": "15:0",
        "name_fr": "15:0"
    },
    "35^live_match^8^over": {
        "name_en": "Over",
        "name_aa": "Over",
        "name_de": "Über",
        "name_nl": "Over",
        "name_pl": "Over",
        "name_ro": "Over",
        "name_ru": "Over",
        "name_tr": "Üst",
        "name_se": "Over",
        "name_el": "Over",
        "name_es": "Over",
        "name_hr": "Više",
        "name_fr": "p"
    },
    "2318^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "117^live_match^8^2:0": {
        "name_en": "Overtime first half Correct score flex",
        "name_aa": "Overtime first half Correct score flex",
        "name_de": "Verlängerung erste Halbzeit genaues Ergebnis",
        "name_nl": "Extra tijd eerste helft Correcte score flex",
        "name_pl": "Overtime first half Correct score flex",
        "name_ro": "Overtime first half Correct score flex",
        "name_ru": "Overtime first half Correct score flex",
        "name_tr": "Uzatmalar İlk Devre Doğru Skor Aralığı",
        "name_se": "Overtime first half Correct score flex",
        "name_el": "Overtime first half Correct score flex",
        "name_es": "Overtime first half Correct score flex",
        "name_hr": "Fleksibilan Točan rezultat u prvom produžetku",
        "name_fr": "1ère Mi-temps des prolongations Score correct flexible"
    },
    "2096^live_match^8^home_yes": {
        "name_en": "home yes",
        "name_aa": "home yes",
        "name_de": "Heim Ja",
        "name_nl": "Thuis Ja",
        "name_pl": "home yes",
        "name_ro": "home yes",
        "name_ru": "home yes",
        "name_tr": "home yes",
        "name_se": "home yes",
        "name_el": "home yes",
        "name_es": "home yes",
        "name_hr": "Domači da",
        "name_fr": "home yes"
    },
    "29^live_match^8^1:3": {
        "name_en": "1:3",
        "name_aa": "1:3",
        "name_de": "1:3",
        "name_nl": "1:3",
        "name_pl": "1:3",
        "name_ro": "1:3",
        "name_ru": "1:3",
        "name_tr": "1:3",
        "name_se": "1:3",
        "name_el": "1:3",
        "name_es": "1:3",
        "name_hr": "1:3",
        "name_fr": "1:3"
    },
    "1108^live_match^8^5:9": {
        "name_en": "5:9",
        "name_aa": "5:9",
        "name_de": "5:9",
        "name_nl": "5:9",
        "name_pl": "5:9",
        "name_ro": "5:9",
        "name_ru": "5:9",
        "name_tr": "5:9",
        "name_se": "5:9",
        "name_el": "5:9",
        "name_es": "5:9",
        "name_hr": "5:9",
        "name_fr": "5:9"
    },
    "2354^live_match^8^1:8": {
        "name_en": "1:8",
        "name_aa": "1:8",
        "name_de": "1:8",
        "name_nl": "1:8",
        "name_pl": "1:8",
        "name_ro": "1:8",
        "name_ru": "1:8",
        "name_tr": "1:8",
        "name_se": "1:8",
        "name_el": "1:8",
        "name_es": "1:8",
        "name_hr": "1:8",
        "name_fr": "1:8"
    },
    "2262^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "341^live_match^8^0:4": {
        "name_en": "0:4",
        "name_aa": "0:4",
        "name_de": "0:4",
        "name_nl": "0:4",
        "name_pl": "0:4",
        "name_ro": "0:4",
        "name_ru": "0:4",
        "name_tr": "0:4",
        "name_se": "0:4",
        "name_el": "0:4",
        "name_es": "0:4",
        "name_hr": "0:4",
        "name_fr": "0:4"
    },
    "2005^live_match^8^away_ot": {
        "name_en": "Away OT",
        "name_aa": "Away OT",
        "name_de": "Auswart OT",
        "name_nl": "Weg OT",
        "name_pl": "Away OT",
        "name_ro": "Away OT",
        "name_ru": "Away OT",
        "name_tr": "Away OT",
        "name_se": "Away OT",
        "name_el": "Away OT",
        "name_es": "Away OT",
        "name_hr": "Away OT",
        "name_fr": "Away OT"
    },
    "0^live_match^4^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2333^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "2102^live_match^8^no": {
        "name_en": "no",
        "name_aa": "no",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "no",
        "name_ro": "no",
        "name_ru": "no",
        "name_tr": "no",
        "name_se": "no",
        "name_el": "no",
        "name_es": "no",
        "name_hr": "Ne",
        "name_fr": "no"
    },
    "62^live_match^8^odd": {
        "name_en": "odd",
        "name_aa": "odd",
        "name_de": "Ungerade",
        "name_nl": "oneven",
        "name_pl": "odd",
        "name_ro": "odd",
        "name_ru": "odd",
        "name_tr": "tek",
        "name_se": "odd",
        "name_el": "odd",
        "name_es": "odd",
        "name_hr": "Neparan",
        "name_fr": "impair"
    },
    "1108^live_match^8^3:0": {
        "name_en": "3:0",
        "name_aa": "3:0",
        "name_de": "3:0",
        "name_nl": "3:0",
        "name_pl": "3:0",
        "name_ro": "3:0",
        "name_ru": "3:0",
        "name_tr": "3:0",
        "name_se": "3:0",
        "name_el": "3:0",
        "name_es": "3:0",
        "name_hr": "3:0",
        "name_fr": "3:0"
    },
    "2354^live_match^8^9:9": {
        "name_en": "9:9",
        "name_aa": "9:9",
        "name_de": "9:9",
        "name_nl": "9:9",
        "name_pl": "9:9",
        "name_ro": "9:9",
        "name_ru": "9:9",
        "name_tr": "9:9",
        "name_se": "9:9",
        "name_el": "9:9",
        "name_es": "9:9",
        "name_hr": "9:9",
        "name_fr": "9:9"
    },
    "1108^live_match^8^7:12": {
        "name_en": "7:12",
        "name_aa": "7:12",
        "name_de": "7:12",
        "name_nl": "7:12",
        "name_pl": "7:12",
        "name_ro": "7:12",
        "name_ru": "7:12",
        "name_tr": "7:12",
        "name_se": "7:12",
        "name_el": "7:12",
        "name_es": "7:12",
        "name_hr": "7:12",
        "name_fr": "7:12"
    },
    "2191^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "117^live_match^8^2:2": {
        "name_en": "2:2",
        "name_aa": "2:2",
        "name_de": "2:2",
        "name_nl": "2:2",
        "name_pl": "2:2",
        "name_ro": "2:2",
        "name_ru": "2:2",
        "name_tr": "2:2",
        "name_se": "2:2",
        "name_el": "2:2",
        "name_es": "2:2",
        "name_hr": "2:2",
        "name_fr": "2:2"
    },
    "870^live_match^6^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2143^live_match^8^home_1-2": {
        "name_en": "home 1-2",
        "name_aa": "home 1-2",
        "name_de": "Heim 1-2",
        "name_nl": "Thuis 1-2",
        "name_pl": "home 1-2",
        "name_ro": "home 1-2",
        "name_ru": "home 1-2",
        "name_tr": "home 1-2",
        "name_se": "home 1-2",
        "name_el": "home 1-2",
        "name_es": "home 1-2",
        "name_hr": "Domači 1-2",
        "name_fr": "home 1-2"
    },
    "1580^live_match^6^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "1156^live_match^8^3:2": {
        "name_en": "3:2",
        "name_aa": "3:2",
        "name_de": "3:2",
        "name_nl": "3:2",
        "name_pl": "3:2",
        "name_ro": "3:2",
        "name_ru": "3:2",
        "name_tr": "3:2",
        "name_se": "3:2",
        "name_el": "3:2",
        "name_es": "3:2",
        "name_hr": "3:2",
        "name_fr": "3:2"
    },
    "2072^live_match^8^14:21": {
        "name_en": "14:21",
        "name_aa": "14:21",
        "name_de": "14:21",
        "name_nl": "14:21",
        "name_pl": "14:21",
        "name_ro": "14:21",
        "name_ru": "14:21",
        "name_tr": "14:21",
        "name_se": "14:21",
        "name_el": "14:21",
        "name_es": "14:21",
        "name_hr": "14:21",
        "name_fr": "14:21"
    },
    "2218^live_match^8^2:0": {
        "name_en": "2:0",
        "name_aa": "2:0",
        "name_de": "2:0",
        "name_nl": "2:0",
        "name_pl": "2:0",
        "name_ro": "2:0",
        "name_ru": "2:0",
        "name_tr": "2:0",
        "name_se": "2:0",
        "name_el": "2:0",
        "name_es": "2:0",
        "name_hr": "2:0",
        "name_fr": "2:0"
    },
    "2032^live_match^8^home_and_over": {
        "name_en": "home and over",
        "name_aa": "home and over",
        "name_de": "Heim und Über",
        "name_nl": "Thuis and Meer dan",
        "name_pl": "home and over",
        "name_ro": "home and over",
        "name_ru": "home and over",
        "name_tr": "home and over",
        "name_se": "home and over",
        "name_el": "home and over",
        "name_es": "home and over",
        "name_hr": "Domači i više",
        "name_fr": "home and over"
    },
    "116^live_match^8^0:5": {
        "name_en": "0:5",
        "name_aa": "0:5",
        "name_de": "0:5",
        "name_nl": "0:5",
        "name_pl": "0:5",
        "name_ro": "0:5",
        "name_ru": "0:5",
        "name_tr": "0:5",
        "name_se": "0:5",
        "name_el": "0:5",
        "name_es": "0:5",
        "name_hr": "0:5",
        "name_fr": "0:5"
    },
    "2352^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "11^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2315^live_match^8^3:3": {
        "name_en": "3:3",
        "name_aa": "3:3",
        "name_de": "3:3",
        "name_nl": "3:3",
        "name_pl": "3:3",
        "name_ro": "3:3",
        "name_ru": "3:3",
        "name_tr": "3:3",
        "name_se": "3:3",
        "name_el": "3:3",
        "name_es": "3:3",
        "name_hr": "3:3",
        "name_fr": "3:3"
    },
    "2354^live_match^8^0:12": {
        "name_en": "0:12",
        "name_aa": "0:12",
        "name_de": "0:12",
        "name_nl": "0:12",
        "name_pl": "0:12",
        "name_ro": "0:12",
        "name_ru": "0:12",
        "name_tr": "0:12",
        "name_se": "0:12",
        "name_el": "0:12",
        "name_es": "0:12",
        "name_hr": "0:12",
        "name_fr": "0:12"
    },
    "2042^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "2071^live_match^8^away_8-11": {
        "name_en": "away 8-11",
        "name_aa": "away 8-11",
        "name_de": "Auswärt 8-11",
        "name_nl": "Weg 8-11",
        "name_pl": "away 8-11",
        "name_ro": "away 8-11",
        "name_ru": "away 8-11",
        "name_tr": "away 8-11",
        "name_se": "away 8-11",
        "name_el": "away 8-11",
        "name_es": "away 8-11",
        "name_hr": "Gostujuči8-11",
        "name_fr": "away 8-11"
    },
    "1108^live_match^8^9:12": {
        "name_en": "9:12",
        "name_aa": "9:12",
        "name_de": "9:12",
        "name_nl": "9:12",
        "name_pl": "9:12",
        "name_ro": "9:12",
        "name_ru": "9:12",
        "name_tr": "9:12",
        "name_se": "9:12",
        "name_el": "9:12",
        "name_es": "9:12",
        "name_hr": "9:12",
        "name_fr": "9:12"
    },
    "2113^live_match^8^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2062^live_match^8^away_11+": {
        "name_en": "away 11+",
        "name_aa": "away 11+",
        "name_de": "Auswärt 11+",
        "name_nl": "Weg 11+",
        "name_pl": "away 11+",
        "name_ro": "away 11+",
        "name_ru": "away 11+",
        "name_tr": "away 11+",
        "name_se": "away 11+",
        "name_el": "away 11+",
        "name_es": "away 11+",
        "name_hr": "Gostujuči 11+",
        "name_fr": "away 11+"
    },
    "2354^live_match^8^8:6": {
        "name_en": "8:6",
        "name_aa": "8:6",
        "name_de": "8:6",
        "name_nl": "8:6",
        "name_pl": "8:6",
        "name_ro": "8:6",
        "name_ru": "8:6",
        "name_tr": "8:6",
        "name_se": "8:6",
        "name_el": "8:6",
        "name_es": "8:6",
        "name_hr": "8:6",
        "name_fr": "8:6"
    },
    "2061^live_match^8^away_1-2": {
        "name_en": "away 1-2",
        "name_aa": "away 1-2",
        "name_de": "Auswärt 1-2",
        "name_nl": "Weg 1-2",
        "name_pl": "away 1-2",
        "name_ro": "away 1-2",
        "name_ru": "away 1-2",
        "name_tr": "away 1-2",
        "name_se": "away 1-2",
        "name_el": "away 1-2",
        "name_es": "away 1-2",
        "name_hr": "Gostujiči 1-2",
        "name_fr": "away 1-2"
    },
    "2088^live_match^8^4:2": {
        "name_en": "4:2",
        "name_aa": "4:2",
        "name_de": "4:2",
        "name_nl": "4:2",
        "name_pl": "4:2",
        "name_ro": "4:2",
        "name_ru": "4:2",
        "name_tr": "4:2",
        "name_se": "4:2",
        "name_el": "4:2",
        "name_es": "4:2",
        "name_hr": "4:2",
        "name_fr": "4:2"
    },
    "26^live_match^8^3:8": {
        "name_en": "3:8",
        "name_aa": "3:8",
        "name_de": "3:8",
        "name_nl": "3:8",
        "name_pl": "3:8",
        "name_ro": "3:8",
        "name_ru": "3:8",
        "name_tr": "3:8",
        "name_se": "3:8",
        "name_el": "3:8",
        "name_es": "3:8",
        "name_hr": "3:8",
        "name_fr": "3:8"
    },
    "2215^live_match^8^home_1-24": {
        "name_en": "home 1-24",
        "name_aa": "home 1-24",
        "name_de": "Heim 1-24",
        "name_nl": "Thuis 1-24",
        "name_pl": "home 1-24",
        "name_ro": "home 1-24",
        "name_ru": "home 1-24",
        "name_tr": "home 1-24",
        "name_se": "home 1-24",
        "name_el": "home 1-24",
        "name_es": "home 1-24",
        "name_hr": "Domači 1-24",
        "name_fr": "home 1-24"
    },
    "26^live_match^8^4:8": {
        "name_en": "4:8",
        "name_aa": "4:8",
        "name_de": "4:8",
        "name_nl": "4:8",
        "name_pl": "4:8",
        "name_ro": "4:8",
        "name_ru": "4:8",
        "name_tr": "4:8",
        "name_se": "4:8",
        "name_el": "4:8",
        "name_es": "4:8",
        "name_hr": "4:8",
        "name_fr": "4:8"
    },
    "117^live_match^8^0:1": {
        "name_en": "0:1",
        "name_aa": "0:1",
        "name_de": "0:1",
        "name_nl": "0:1",
        "name_pl": "0:1",
        "name_ro": "0:1",
        "name_ru": "0:1",
        "name_tr": "0:1",
        "name_se": "0:1",
        "name_el": "0:1",
        "name_es": "0:1",
        "name_hr": "0:1",
        "name_fr": "0:1"
    },
    "1108^live_match^8^0:13": {
        "name_en": "0:13",
        "name_aa": "0:13",
        "name_de": "0:13",
        "name_nl": "0:13",
        "name_pl": "0:13",
        "name_ro": "0:13",
        "name_ru": "0:13",
        "name_tr": "0:13",
        "name_se": "0:13",
        "name_el": "0:13",
        "name_es": "0:13",
        "name_hr": "0:13",
        "name_fr": "0:13"
    },
    "2354^live_match^8^4:10": {
        "name_en": "4:10",
        "name_aa": "4:10",
        "name_de": "4:10",
        "name_nl": "4:10",
        "name_pl": "4:10",
        "name_ro": "4:10",
        "name_ru": "4:10",
        "name_tr": "4:10",
        "name_se": "4:10",
        "name_el": "4:10",
        "name_es": "4:10",
        "name_hr": "4:10",
        "name_fr": "4:10"
    },
    "26^live_match^8^2:9": {
        "name_en": "2:9",
        "name_aa": "2:9",
        "name_de": "2:9",
        "name_nl": "2:9",
        "name_pl": "2:9",
        "name_ro": "2:9",
        "name_ru": "2:9",
        "name_tr": "2:9",
        "name_se": "2:9",
        "name_el": "2:9",
        "name_es": "2:9",
        "name_hr": "2:9",
        "name_fr": "2:9"
    },
    "2076^live_match^8^15:6": {
        "name_en": "15:6",
        "name_aa": "15:6",
        "name_de": "15:6",
        "name_nl": "15:6",
        "name_pl": "15:6",
        "name_ro": "15:6",
        "name_ru": "15:6",
        "name_tr": "15:6",
        "name_se": "15:6",
        "name_el": "15:6",
        "name_es": "15:6",
        "name_hr": "15:6",
        "name_fr": "15:6"
    },
    "2128^live_match^8^7:11": {
        "name_en": "7:11",
        "name_aa": "7:11",
        "name_de": "7:11",
        "name_nl": "7:11",
        "name_pl": "7:11",
        "name_ro": "7:11",
        "name_ru": "7:11",
        "name_tr": "7:11",
        "name_se": "7:11",
        "name_el": "7:11",
        "name_es": "7:11",
        "name_hr": "7:11",
        "name_fr": "7:11"
    },
    "2248^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "2058^live_match^8^hh": {
        "name_en": "HH",
        "name_aa": "HH",
        "name_de": "HH",
        "name_nl": "HH",
        "name_pl": "HH",
        "name_ro": "HH",
        "name_ru": "HH",
        "name_tr": "HH",
        "name_se": "HH",
        "name_el": "HH",
        "name_es": "HH",
        "name_hr": "HH",
        "name_fr": "HH"
    },
    "2302^live_match^8^none": {
        "name_en": "none",
        "name_aa": "none",
        "name_de": "Keiner",
        "name_nl": "Geen",
        "name_pl": "none",
        "name_ro": "none",
        "name_ru": "none",
        "name_tr": "none",
        "name_se": "none",
        "name_el": "none",
        "name_es": "none",
        "name_hr": "Nitko",
        "name_fr": "none"
    },
    "26^live_match^8^13:2": {
        "name_en": "13:2",
        "name_aa": "13:2",
        "name_de": "13:2",
        "name_nl": "13:2",
        "name_pl": "13:2",
        "name_ro": "13:2",
        "name_ru": "13:2",
        "name_tr": "13:2",
        "name_se": "13:2",
        "name_el": "13:2",
        "name_es": "13:2",
        "name_hr": "13:2",
        "name_fr": "13:2"
    },
    "29^live_match^8^3:2": {
        "name_en": "3:2",
        "name_aa": "3:2",
        "name_de": "3:2",
        "name_nl": "3:2",
        "name_pl": "3:2",
        "name_ro": "3:2",
        "name_ru": "3:2",
        "name_tr": "3:2",
        "name_se": "3:2",
        "name_el": "3:2",
        "name_es": "3:2",
        "name_hr": "3:2",
        "name_fr": "3:2"
    },
    "2155^live_match^8^home_and_over": {
        "name_en": "home and over",
        "name_aa": "home and over",
        "name_de": "Heim und Über",
        "name_nl": "Thuis and Meer dan",
        "name_pl": "home and over",
        "name_ro": "home and over",
        "name_ru": "home and over",
        "name_tr": "home and over",
        "name_se": "home and over",
        "name_el": "home and over",
        "name_es": "home and over",
        "name_hr": "Domači i više",
        "name_fr": "home and over"
    },
    "2306^live_match^8^none": {
        "name_en": "none",
        "name_aa": "none",
        "name_de": "Keiner",
        "name_nl": "Geen",
        "name_pl": "none",
        "name_ro": "none",
        "name_ru": "none",
        "name_tr": "none",
        "name_se": "none",
        "name_el": "none",
        "name_es": "none",
        "name_hr": "Nitko",
        "name_fr": "none"
    },
    "2354^live_match^8^0:7": {
        "name_en": "0:7",
        "name_aa": "0:7",
        "name_de": "0:7",
        "name_nl": "0:7",
        "name_pl": "0:7",
        "name_ro": "0:7",
        "name_ru": "0:7",
        "name_tr": "0:7",
        "name_se": "0:7",
        "name_el": "0:7",
        "name_es": "0:7",
        "name_hr": "0:7",
        "name_fr": "0:7"
    },
    "2208^live_match^8^0-20": {
        "name_en": "0-20",
        "name_aa": "0-20",
        "name_de": "0-20",
        "name_nl": "0-20",
        "name_pl": "0-20",
        "name_ro": "0-20",
        "name_ru": "0-20",
        "name_tr": "0-20",
        "name_se": "0-20",
        "name_el": "0-20",
        "name_es": "0-20",
        "name_hr": "0-20",
        "name_fr": "0-20"
    },
    "61^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2261^live_match^8^0:8": {
        "name_en": "0:8",
        "name_aa": "0:8",
        "name_de": "0:8",
        "name_nl": "0:8",
        "name_pl": "0:8",
        "name_ro": "0:8",
        "name_ru": "0:8",
        "name_tr": "0:8",
        "name_se": "0:8",
        "name_el": "0:8",
        "name_es": "0:8",
        "name_hr": "0:8",
        "name_fr": "0:8"
    },
    "26^live_match^8^0:17": {
        "name_en": "0:17",
        "name_aa": "0:17",
        "name_de": "0:17",
        "name_nl": "0:17",
        "name_pl": "0:17",
        "name_ro": "0:17",
        "name_ru": "0:17",
        "name_tr": "0:17",
        "name_se": "0:17",
        "name_el": "0:17",
        "name_es": "0:17",
        "name_hr": "0:17",
        "name_fr": "0:17"
    },
    "26^live_match^8^4:7": {
        "name_en": "4:7",
        "name_aa": "4:7",
        "name_de": "4:7",
        "name_nl": "4:7",
        "name_pl": "4:7",
        "name_ro": "4:7",
        "name_ru": "4:7",
        "name_tr": "4:7",
        "name_se": "4:7",
        "name_el": "4:7",
        "name_es": "4:7",
        "name_hr": "4:7",
        "name_fr": "4:7"
    },
    "1040^live_match^8^away_and_no": {
        "name_en": "away and no",
        "name_aa": "away and no",
        "name_de": "Auswärtsmannschaft und Nein",
        "name_nl": "uit en nee",
        "name_pl": "away and no",
        "name_ro": "away and no",
        "name_ru": "away and no",
        "name_tr": "deplasman kazanır ve iki takımda gol atamaz",
        "name_se": "away and no",
        "name_el": "away and no",
        "name_es": "away and no",
        "name_hr": "gosti i ne",
        "name_fr": "Extérieur et Non"
    },
    "2260^live_match^8^home_2": {
        "name_en": "home 2",
        "name_aa": "home 2",
        "name_de": "Heim 2",
        "name_nl": "Thuis 2",
        "name_pl": "home 2",
        "name_ro": "home 2",
        "name_ru": "home 2",
        "name_tr": "home 2",
        "name_se": "home 2",
        "name_el": "home 2",
        "name_es": "home 2",
        "name_hr": "Domači 2",
        "name_fr": "home 2"
    },
    "26^live_match^8^2:19": {
        "name_en": "2:19",
        "name_aa": "2:19",
        "name_de": "2:19",
        "name_nl": "2:19",
        "name_pl": "2:19",
        "name_ro": "2:19",
        "name_ru": "2:19",
        "name_tr": "2:19",
        "name_se": "2:19",
        "name_el": "2:19",
        "name_es": "2:19",
        "name_hr": "2:19",
        "name_fr": "2:19"
    },
    "2354^live_match^8^9:8": {
        "name_en": "9:8",
        "name_aa": "9:8",
        "name_de": "9:8",
        "name_nl": "9:8",
        "name_pl": "9:8",
        "name_ro": "9:8",
        "name_ru": "9:8",
        "name_tr": "9:8",
        "name_se": "9:8",
        "name_el": "9:8",
        "name_es": "9:8",
        "name_hr": "9:8",
        "name_fr": "9:8"
    },
    "26^live_match^8^1:16": {
        "name_en": "1:16",
        "name_aa": "1:16",
        "name_de": "1:16",
        "name_nl": "1:16",
        "name_pl": "1:16",
        "name_ro": "1:16",
        "name_ru": "1:16",
        "name_tr": "1:16",
        "name_se": "1:16",
        "name_el": "1:16",
        "name_es": "1:16",
        "name_hr": "1:16",
        "name_fr": "1:16"
    },
    "2072^live_match^8^18:25": {
        "name_en": "18:25",
        "name_aa": "18:25",
        "name_de": "18:25",
        "name_nl": "18:25",
        "name_pl": "18:25",
        "name_ro": "18:25",
        "name_ru": "18:25",
        "name_tr": "18:25",
        "name_se": "18:25",
        "name_el": "18:25",
        "name_es": "18:25",
        "name_hr": "18:25",
        "name_fr": "18:25"
    },
    "2311^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2261^live_match^8^2:6": {
        "name_en": "2:6",
        "name_aa": "2:6",
        "name_de": "2:6",
        "name_nl": "2:6",
        "name_pl": "2:6",
        "name_ro": "2:6",
        "name_ru": "2:6",
        "name_tr": "2:6",
        "name_se": "2:6",
        "name_el": "2:6",
        "name_es": "2:6",
        "name_hr": "2:6",
        "name_fr": "2:6"
    },
    "2237^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "1506^live_match^8^home": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "29^live_match^8^7:3": {
        "name_en": "7:3",
        "name_aa": "7:3",
        "name_de": "7:3",
        "name_nl": "7:3",
        "name_pl": "7:3",
        "name_ro": "7:3",
        "name_ru": "7:3",
        "name_tr": "7:3",
        "name_se": "7:3",
        "name_el": "7:3",
        "name_es": "7:3",
        "name_hr": "7:3",
        "name_fr": "7:3"
    },
    "2007^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "29^live_match^8^2:5": {
        "name_en": "2:5",
        "name_aa": "2:5",
        "name_de": "2:5",
        "name_nl": "2:5",
        "name_pl": "2:5",
        "name_ro": "2:5",
        "name_ru": "2:5",
        "name_tr": "2:5",
        "name_se": "2:5",
        "name_el": "2:5",
        "name_es": "2:5",
        "name_hr": "2:5",
        "name_fr": "2:5"
    },
    "2072^live_match^8^21:25": {
        "name_en": "21:25",
        "name_aa": "21:25",
        "name_de": "21:25",
        "name_nl": "21:25",
        "name_pl": "21:25",
        "name_ro": "21:25",
        "name_ru": "21:25",
        "name_tr": "21:25",
        "name_se": "21:25",
        "name_el": "21:25",
        "name_es": "21:25",
        "name_hr": "21:25",
        "name_fr": "21:25"
    },
    "2227^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "2091^live_match^8^no": {
        "name_en": "no",
        "name_aa": "no",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "no",
        "name_ro": "no",
        "name_ru": "no",
        "name_tr": "no",
        "name_se": "no",
        "name_el": "no",
        "name_es": "no",
        "name_hr": "Ne",
        "name_fr": "no"
    },
    "2023^live_match^8^away": {
        "name_en": "away",
        "name_aa": "away",
        "name_de": "Auswärts",
        "name_nl": "Uit",
        "name_pl": "away",
        "name_ro": "away",
        "name_ru": "away",
        "name_tr": "away",
        "name_se": "away",
        "name_el": "away",
        "name_es": "away",
        "name_hr": "Gostujući",
        "name_fr": "away"
    },
    "1529^live_match^8^away_and_over": {
        "name_en": "away and over",
        "name_aa": "away and over",
        "name_de": "Auswärt und Über",
        "name_nl": "uit en boven",
        "name_pl": "away and over",
        "name_ro": "away and over",
        "name_ru": "away and over",
        "name_tr": "deplasman ve üst",
        "name_se": "away and over",
        "name_el": "away and over",
        "name_es": "away and over",
        "name_hr": "2 i Više",
        "name_fr": "extérieur et Plus de"
    },
    "2354^live_match^8^9:1": {
        "name_en": "9:1",
        "name_aa": "9:1",
        "name_de": "9:1",
        "name_nl": "9:1",
        "name_pl": "9:1",
        "name_ro": "9:1",
        "name_ru": "9:1",
        "name_tr": "9:1",
        "name_se": "9:1",
        "name_el": "9:1",
        "name_es": "9:1",
        "name_hr": "9:1",
        "name_fr": "9:1"
    },
    "2354^live_match^8^7:3": {
        "name_en": "7:3",
        "name_aa": "7:3",
        "name_de": "7:3",
        "name_nl": "7:3",
        "name_pl": "7:3",
        "name_ro": "7:3",
        "name_ru": "7:3",
        "name_tr": "7:3",
        "name_se": "7:3",
        "name_el": "7:3",
        "name_es": "7:3",
        "name_hr": "7:3",
        "name_fr": "7:3"
    },
    "2329^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "2217^live_match^8^1:3": {
        "name_en": "1:3",
        "name_aa": "1:3",
        "name_de": "1:3",
        "name_nl": "1:3",
        "name_pl": "1:3",
        "name_ro": "1:3",
        "name_ru": "1:3",
        "name_tr": "1:3",
        "name_se": "1:3",
        "name_el": "1:3",
        "name_es": "1:3",
        "name_hr": "1:3",
        "name_fr": "1:3"
    },
    "2109^live_match^8^exactly": {
        "name_en": "exactly",
        "name_aa": "exactly",
        "name_de": "Genau",
        "name_nl": "Precies",
        "name_pl": "exactly",
        "name_ro": "exactly",
        "name_ru": "exactly",
        "name_tr": "exactly",
        "name_se": "exactly",
        "name_el": "exactly",
        "name_es": "exactly",
        "name_hr": "Tacno",
        "name_fr": "exactly"
    },
    "2215^live_match^8^away_25+": {
        "name_en": "away 25+",
        "name_aa": "away 25+",
        "name_de": "Auswärt 25+",
        "name_nl": "Weg 25+",
        "name_pl": "away 25+",
        "name_ro": "away 25+",
        "name_ru": "away 25+",
        "name_tr": "away 25+",
        "name_se": "away 25+",
        "name_el": "away 25+",
        "name_es": "away 25+",
        "name_hr": "Gostujuči 25+",
        "name_fr": "away 25+"
    },
    "2145^live_match^8^home_21+": {
        "name_en": "home 21+",
        "name_aa": "home 21+",
        "name_de": "Heim 21+",
        "name_nl": "thuis 21+",
        "name_pl": "home 21+",
        "name_ro": "home 21+",
        "name_ru": "home 21+",
        "name_tr": "home 21+",
        "name_se": "home 21+",
        "name_el": "home 21+",
        "name_es": "home 21+",
        "name_hr": "Domači 21+",
        "name_fr": "home 21+"
    },
    "2114^live_match^8^15:30": {
        "name_en": "15:30",
        "name_aa": "15:30",
        "name_de": "15:30",
        "name_nl": "15:30",
        "name_pl": "15:30",
        "name_ro": "15:30",
        "name_ru": "15:30",
        "name_tr": "15:30",
        "name_se": "15:30",
        "name_el": "15:30",
        "name_es": "15:30",
        "name_hr": "15:30",
        "name_fr": "15:30"
    },
    "2143^live_match^8^away_3-4": {
        "name_en": "away 3-4",
        "name_aa": "away 3-4",
        "name_de": "Auswärt 3-4",
        "name_nl": "Weg 3-4",
        "name_pl": "away 3-4",
        "name_ro": "away 3-4",
        "name_ru": "away 3-4",
        "name_tr": "away 3-4",
        "name_se": "away 3-4",
        "name_el": "away 3-4",
        "name_es": "away 3-4",
        "name_hr": "Gostujuči 3-4",
        "name_fr": "away 3-4"
    },
    "70^live_match^8^under": {
        "name_en": "Under",
        "name_aa": "u",
        "name_de": "Unter",
        "name_nl": "u",
        "name_pl": "u",
        "name_ro": "u",
        "name_ru": "u",
        "name_tr": "Alt",
        "name_se": "u",
        "name_el": "u",
        "name_es": "u",
        "name_hr": "Manje",
        "name_fr": "m"
    },
    "230^live_match^8^home_penalties": {
        "name_en": "Home penalties",
        "name_aa": "Home penalties",
        "name_de": "Elfmeterschießen - Heimmannschaft",
        "name_nl": "Thuis Penalties",
        "name_pl": "Home penalties",
        "name_ro": "Home penalties",
        "name_ru": "Home penalties",
        "name_tr": "penaltılar sonunda ev sahibi",
        "name_se": "Home penalties",
        "name_el": "Home penalties",
        "name_es": "Home penalties",
        "name_hr": "Pobjeda domaćih nakon izvođenja penala",
        "name_fr": "Domicile Tirs au but"
    },
    "1669^live_match^8^no": {
        "name_en": "no",
        "name_aa": "no",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "no",
        "name_ro": "no",
        "name_ru": "no",
        "name_tr": "no",
        "name_se": "no",
        "name_el": "no",
        "name_es": "no",
        "name_hr": "Ne",
        "name_fr": "no"
    },
    "2097^live_match^6^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2254^live_match^8^yes": {
        "name_en": "yes",
        "name_aa": "yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "yes",
        "name_ro": "yes",
        "name_ru": "yes",
        "name_tr": "yes",
        "name_se": "yes",
        "name_el": "yes",
        "name_es": "yes",
        "name_hr": "Da",
        "name_fr": "yes"
    },
    "2316^live_match^8^10:6": {
        "name_en": "10:6",
        "name_aa": "10:6",
        "name_de": "10:6",
        "name_nl": "10:6",
        "name_pl": "10:6",
        "name_ro": "10:6",
        "name_ru": "10:6",
        "name_tr": "10:6",
        "name_se": "10:6",
        "name_el": "10:6",
        "name_es": "10:6",
        "name_hr": "10:6",
        "name_fr": "10:6"
    },
    "1510^live_match^8^away": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "116^live_match^8^1:3": {
        "name_en": "1:3",
        "name_aa": "1:3",
        "name_de": "1:3",
        "name_nl": "1:3",
        "name_pl": "1:3",
        "name_ro": "1:3",
        "name_ru": "1:3",
        "name_tr": "1:3",
        "name_se": "1:3",
        "name_el": "1:3",
        "name_es": "1:3",
        "name_hr": "1:3",
        "name_fr": "1:3"
    },
    "26^live_match^8^12:4": {
        "name_en": "12:4",
        "name_aa": "12:4",
        "name_de": "12:4",
        "name_nl": "12:4",
        "name_pl": "12:4",
        "name_ro": "12:4",
        "name_ru": "12:4",
        "name_tr": "12:4",
        "name_se": "12:4",
        "name_el": "12:4",
        "name_es": "12:4",
        "name_hr": "12:4",
        "name_fr": "12:4"
    },
    "1108^live_match^8^3:12": {
        "name_en": "3:12",
        "name_aa": "3:12",
        "name_de": "3:12",
        "name_nl": "3:12",
        "name_pl": "3:12",
        "name_ro": "3:12",
        "name_ru": "3:12",
        "name_tr": "3:12",
        "name_se": "3:12",
        "name_el": "3:12",
        "name_es": "3:12",
        "name_hr": "3:12",
        "name_fr": "3:12"
    },
    "2206^live_match^8^ha": {
        "name_en": "HA",
        "name_aa": "HA",
        "name_de": "HA",
        "name_nl": "HA",
        "name_pl": "HA",
        "name_ro": "HA",
        "name_ru": "HA",
        "name_tr": "HA",
        "name_se": "HA",
        "name_el": "HA",
        "name_es": "HA",
        "name_hr": "HA",
        "name_fr": "HA"
    },
    "1108^live_match^8^6:3": {
        "name_en": "6:3",
        "name_aa": "6:3",
        "name_de": "6:3",
        "name_nl": "6:3",
        "name_pl": "6:3",
        "name_ro": "6:3",
        "name_ru": "6:3",
        "name_tr": "6:3",
        "name_se": "6:3",
        "name_el": "6:3",
        "name_es": "6:3",
        "name_hr": "6:3",
        "name_fr": "6:3"
    },
    "2061^live_match^8^home_5-6": {
        "name_en": "home 5-6",
        "name_aa": "home 5-6",
        "name_de": "Heim 5-6",
        "name_nl": "thuis 5-6",
        "name_pl": "home 5-6",
        "name_ro": "home 5-6",
        "name_ru": "home 5-6",
        "name_tr": "home 5-6",
        "name_se": "home 5-6",
        "name_el": "home 5-6",
        "name_es": "home 5-6",
        "name_hr": "Domači 5-6",
        "name_fr": "home 5-6"
    },
    "1156^live_match^8^3:0": {
        "name_en": "3:0",
        "name_aa": "3:0",
        "name_de": "3:0",
        "name_nl": "3:0",
        "name_pl": "3:0",
        "name_ro": "3:0",
        "name_ru": "3:0",
        "name_tr": "3:0",
        "name_se": "3:0",
        "name_el": "3:0",
        "name_es": "3:0",
        "name_hr": "3:0",
        "name_fr": "3:0"
    },
    "2063^live_match^8^away_3-6": {
        "name_en": "away 3-6",
        "name_aa": "away 3-6",
        "name_de": "Auswärt 3-6",
        "name_nl": "Weg 3-6",
        "name_pl": "away 3-6",
        "name_ro": "away 3-6",
        "name_ru": "away 3-6",
        "name_tr": "away 3-6",
        "name_se": "away 3-6",
        "name_el": "away 3-6",
        "name_es": "away 3-6",
        "name_hr": "Gostujuči 3-6",
        "name_fr": "away 3-6"
    },
    "2063^live_match^8^home_10-13": {
        "name_en": "home 10-13",
        "name_aa": "home 10-13",
        "name_de": "Heim 10-13",
        "name_nl": "thuis 10-13",
        "name_pl": "home 10-13",
        "name_ro": "home 10-13",
        "name_ru": "home 10-13",
        "name_tr": "home 10-13",
        "name_se": "home 10-13",
        "name_el": "home 10-13",
        "name_es": "home 10-13",
        "name_hr": "Domači 10-13",
        "name_fr": "home 10-13"
    },
    "2211^live_match^8^home_19+": {
        "name_en": "home 19+",
        "name_aa": "home 19+",
        "name_de": "Heim 19+",
        "name_nl": "Thuis 19+",
        "name_pl": "home 19+",
        "name_ro": "home 19+",
        "name_ru": "home 19+",
        "name_tr": "home 19+",
        "name_se": "home 19+",
        "name_el": "home 19+",
        "name_es": "home 19+",
        "name_hr": "Domači 19+",
        "name_fr": "home 19+"
    },
    "2009^live_match^6^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "2161^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "2354^live_match^8^4:2": {
        "name_en": "4:2",
        "name_aa": "4:2",
        "name_de": "4:2",
        "name_nl": "4:2",
        "name_pl": "4:2",
        "name_ro": "4:2",
        "name_ru": "4:2",
        "name_tr": "4:2",
        "name_se": "4:2",
        "name_el": "4:2",
        "name_es": "4:2",
        "name_hr": "4:2",
        "name_fr": "4:2"
    },
    "26^live_match^8^12:1": {
        "name_en": "12:1",
        "name_aa": "12:1",
        "name_de": "12:1",
        "name_nl": "12:1",
        "name_pl": "12:1",
        "name_ro": "12:1",
        "name_ru": "12:1",
        "name_tr": "12:1",
        "name_se": "12:1",
        "name_el": "12:1",
        "name_es": "12:1",
        "name_hr": "12:1",
        "name_fr": "12:1"
    },
    "2260^live_match^8^home_1": {
        "name_en": "home 1",
        "name_aa": "home 1",
        "name_de": "Heim 1",
        "name_nl": "Thuis 1",
        "name_pl": "home 1",
        "name_ro": "home 1",
        "name_ru": "home 1",
        "name_tr": "home 1",
        "name_se": "home 1",
        "name_el": "home 1",
        "name_es": "home 1",
        "name_hr": "Domači 1",
        "name_fr": "home 1"
    },
    "2354^live_match^8^10:3": {
        "name_en": "10:3",
        "name_aa": "10:3",
        "name_de": "10:3",
        "name_nl": "10:3",
        "name_pl": "10:3",
        "name_ro": "10:3",
        "name_ru": "10:3",
        "name_tr": "10:3",
        "name_se": "10:3",
        "name_el": "10:3",
        "name_es": "10:3",
        "name_hr": "10:3",
        "name_fr": "10:3"
    },
    "26^live_match^8^10:0": {
        "name_en": "10:0",
        "name_aa": "10:0",
        "name_de": "10:0",
        "name_nl": "10:0",
        "name_pl": "10:0",
        "name_ro": "10:0",
        "name_ru": "10:0",
        "name_tr": "10:0",
        "name_se": "10:0",
        "name_el": "10:0",
        "name_es": "10:0",
        "name_hr": "10:0",
        "name_fr": "10:0"
    },
    "1673^live_match^8^yes": {
        "name_en": "yes",
        "name_aa": "yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "yes",
        "name_ro": "yes",
        "name_ru": "yes",
        "name_tr": "yes",
        "name_se": "yes",
        "name_el": "yes",
        "name_es": "yes",
        "name_hr": "Da",
        "name_fr": "yes"
    },
    "353^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "34^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "26^live_match^8^19:2": {
        "name_en": "19:2",
        "name_aa": "19:2",
        "name_de": "19:2",
        "name_nl": "19:2",
        "name_pl": "19:2",
        "name_ro": "19:2",
        "name_ru": "19:2",
        "name_tr": "19:2",
        "name_se": "19:2",
        "name_el": "19:2",
        "name_es": "19:2",
        "name_hr": "19:2",
        "name_fr": "19:2"
    },
    "2316^live_match^8^10:5": {
        "name_en": "10:5",
        "name_aa": "10:5",
        "name_de": "10:5",
        "name_nl": "10:5",
        "name_pl": "10:5",
        "name_ro": "10:5",
        "name_ru": "10:5",
        "name_tr": "10:5",
        "name_se": "10:5",
        "name_el": "10:5",
        "name_es": "10:5",
        "name_hr": "10:5",
        "name_fr": "10:5"
    },
    "2019^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "2072^live_match^8^19:25": {
        "name_en": "19:25",
        "name_aa": "19:25",
        "name_de": "19:25",
        "name_nl": "19:25",
        "name_pl": "19:25",
        "name_ro": "19:25",
        "name_ru": "19:25",
        "name_tr": "19:25",
        "name_se": "19:25",
        "name_el": "19:25",
        "name_es": "19:25",
        "name_hr": "19:25",
        "name_fr": "19:25"
    },
    "0^live_match^2^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2063^live_match^8^away_21+": {
        "name_en": "away 21+",
        "name_aa": "away 21+",
        "name_de": "Auswärt 21+",
        "name_nl": "Weg 21+",
        "name_pl": "away 21+",
        "name_ro": "away 21+",
        "name_ru": "away 21+",
        "name_tr": "away 21+",
        "name_se": "away 21+",
        "name_el": "away 21+",
        "name_es": "away 21+",
        "name_hr": "Gostujuči 21+",
        "name_fr": "away 21+"
    },
    "2354^live_match^8^10:0": {
        "name_en": "10:0",
        "name_aa": "10:0",
        "name_de": "10:0",
        "name_nl": "10:0",
        "name_pl": "10:0",
        "name_ro": "10:0",
        "name_ru": "10:0",
        "name_tr": "10:0",
        "name_se": "10:0",
        "name_el": "10:0",
        "name_es": "10:0",
        "name_hr": "10:0",
        "name_fr": "10:0"
    },
    "21^live_match^7^2": {
        "name_en": "Over",
        "name_aa": "Over",
        "name_de": "Über",
        "name_nl": "Over",
        "name_pl": "Over",
        "name_ro": "Over",
        "name_ru": "Over",
        "name_tr": "Üst",
        "name_se": "Over",
        "name_el": "Over",
        "name_es": "Over",
        "name_hr": "Više",
        "name_fr": "p"
    },
    "230^live_match^8^away_regular_time": {
        "name_en": "Away regular time",
        "name_aa": "Away regular time",
        "name_de": "reguläre Spielzeit - Auswärtsmannschaft",
        "name_nl": "Uit reguliere speeltijd",
        "name_pl": "Away regular time",
        "name_ro": "Away regular time",
        "name_ru": "Away regular time",
        "name_tr": "normal süre sonunda deplasman",
        "name_se": "Away regular time",
        "name_el": "Away regular time",
        "name_es": "Away regular time",
        "name_hr": "Pobjeda gostiju nakon regularnog vremena",
        "name_fr": "Extérieur temps réglementaire"
    },
    "72^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "1156^live_match^8^1_by_any_other_score": {
        "name_en": "1 by Any Other Score",
        "name_aa": "1 by Any Other Score",
        "name_de": "1 durch jedes andere Ergebnis",
        "name_nl": "1 door een andere score",
        "name_pl": "1 by Any Other Score",
        "name_ro": "1 by Any Other Score",
        "name_ru": "1 by Any Other Score",
        "name_tr": "1 by Any Other Score",
        "name_se": "1 by Any Other Score",
        "name_el": "1 by Any Other Score",
        "name_es": "1 by Any Other Score",
        "name_hr": "1 po bilo ostali rezultat",
        "name_fr": "1 by Any Other Score"
    },
    "237^live_match^8^ha": {
        "name_en": "HA",
        "name_aa": "HA",
        "name_de": "H-A",
        "name_nl": "HA",
        "name_pl": "HA",
        "name_ro": "HA",
        "name_ru": "HA",
        "name_tr": "ED",
        "name_se": "HA",
        "name_el": "HA",
        "name_es": "HA",
        "name_hr": "1-2",
        "name_fr": "DE"
    },
    "2354^live_match^8^4:13": {
        "name_en": "4:13",
        "name_aa": "4:13",
        "name_de": "4:13",
        "name_nl": "4:13",
        "name_pl": "4:13",
        "name_ro": "4:13",
        "name_ru": "4:13",
        "name_tr": "4:13",
        "name_se": "4:13",
        "name_el": "4:13",
        "name_es": "4:13",
        "name_hr": "4:13",
        "name_fr": "4:13"
    },
    "79^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2059^live_match^8^home_10-12": {
        "name_en": "home 10-12",
        "name_aa": "home 10-12",
        "name_de": "Heim 10-12",
        "name_nl": "thuis 10-12",
        "name_pl": "home 10-12",
        "name_ro": "home 10-12",
        "name_ru": "home 10-12",
        "name_tr": "home 10-12",
        "name_se": "home 10-12",
        "name_el": "home 10-12",
        "name_es": "home 10-12",
        "name_hr": "Domači 10-12",
        "name_fr": "home 10-12"
    },
    "2094^live_match^8^yes": {
        "name_en": "yes",
        "name_aa": "yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "yes",
        "name_ro": "yes",
        "name_ru": "yes",
        "name_tr": "yes",
        "name_se": "yes",
        "name_el": "yes",
        "name_es": "yes",
        "name_hr": "Da",
        "name_fr": "yes"
    },
    "32^live_match^8^0": {
        "name_en": "0",
        "name_aa": "0",
        "name_de": "0",
        "name_nl": "0",
        "name_pl": "0",
        "name_ro": "0",
        "name_ru": "0",
        "name_tr": "0",
        "name_se": "0",
        "name_el": "0",
        "name_es": "0",
        "name_hr": "0",
        "name_fr": "0"
    },
    "1108^live_match^8^0:2": {
        "name_en": "0:2",
        "name_aa": "0:2",
        "name_de": "0:2",
        "name_nl": "0:2",
        "name_pl": "0:2",
        "name_ro": "0:2",
        "name_ru": "0:2",
        "name_tr": "0:2",
        "name_se": "0:2",
        "name_el": "0:2",
        "name_es": "0:2",
        "name_hr": "0:2",
        "name_fr": "0:2"
    },
    "29^live_match^8^0:15": {
        "name_en": "0:15",
        "name_aa": "0:15",
        "name_de": "0:15",
        "name_nl": "0:15",
        "name_pl": "0:15",
        "name_ro": "0:15",
        "name_ru": "0:15",
        "name_tr": "0:15",
        "name_se": "0:15",
        "name_el": "0:15",
        "name_es": "0:15",
        "name_hr": "0:15",
        "name_fr": "0:15"
    },
    "2072^live_match^8^15:25_or_better": {
        "name_en": "15:25 or better",
        "name_aa": "15:25 or better",
        "name_de": "15:25 oder besser",
        "name_nl": "15:25 of beter",
        "name_pl": "15:25 or better",
        "name_ro": "15:25 or better",
        "name_ru": "15:25 or better",
        "name_tr": "15:25 or better",
        "name_se": "15:25 or better",
        "name_el": "15:25 or better",
        "name_es": "15:25 or better",
        "name_hr": "15:25 or better",
        "name_fr": "15:25 or better"
    },
    "2076^live_match^8^10:15": {
        "name_en": "10:15",
        "name_aa": "10:15",
        "name_de": "10:15",
        "name_nl": "10:15",
        "name_pl": "10:15",
        "name_ro": "10:15",
        "name_ru": "10:15",
        "name_tr": "10:15",
        "name_se": "10:15",
        "name_el": "10:15",
        "name_es": "10:15",
        "name_hr": "10:15",
        "name_fr": "10:15"
    },
    "2354^live_match^8^7:4": {
        "name_en": "7:4",
        "name_aa": "7:4",
        "name_de": "7:4",
        "name_nl": "7:4",
        "name_pl": "7:4",
        "name_ro": "7:4",
        "name_ru": "7:4",
        "name_tr": "7:4",
        "name_se": "7:4",
        "name_el": "7:4",
        "name_es": "7:4",
        "name_hr": "7:4",
        "name_fr": "7:4"
    },
    "2099^live_match^8^exactly": {
        "name_en": "exactly",
        "name_aa": "exactly",
        "name_de": "Genau",
        "name_nl": "Precies",
        "name_pl": "exactly",
        "name_ro": "exactly",
        "name_ru": "exactly",
        "name_tr": "exactly",
        "name_se": "exactly",
        "name_el": "exactly",
        "name_es": "exactly",
        "name_hr": "Tacno",
        "name_fr": "exactly"
    },
    "2072^live_match^8^25:20": {
        "name_en": "25:20",
        "name_aa": "25:20",
        "name_de": "25:20",
        "name_nl": "25:20",
        "name_pl": "25:20",
        "name_ro": "25:20",
        "name_ru": "25:20",
        "name_tr": "25:20",
        "name_se": "25:20",
        "name_el": "25:20",
        "name_es": "25:20",
        "name_hr": "25:20",
        "name_fr": "25:20"
    },
    "2354^live_match^8^12:5": {
        "name_en": "12:5",
        "name_aa": "12:5",
        "name_de": "12:5",
        "name_nl": "12:5",
        "name_pl": "12:5",
        "name_ro": "12:5",
        "name_ru": "12:5",
        "name_tr": "12:5",
        "name_se": "12:5",
        "name_el": "12:5",
        "name_es": "12:5",
        "name_hr": "12:5",
        "name_fr": "12:5"
    },
    "2153^live_match^8^home_3": {
        "name_en": "home 3",
        "name_aa": "home 3",
        "name_de": "Heim 3",
        "name_nl": "Thuis 3",
        "name_pl": "home 3",
        "name_ro": "home 3",
        "name_ru": "home 3",
        "name_tr": "home 3",
        "name_se": "home 3",
        "name_el": "home 3",
        "name_es": "home 3",
        "name_hr": "Domači 3",
        "name_fr": "home 3"
    },
    "233^live_match^8^over": {
        "name_en": "Over",
        "name_aa": "Over",
        "name_de": "Über",
        "name_nl": "Over",
        "name_pl": "Over",
        "name_ro": "Over",
        "name_ru": "Over",
        "name_tr": "Üst",
        "name_se": "Over",
        "name_el": "Over",
        "name_es": "Over",
        "name_hr": "Više",
        "name_fr": "p"
    },
    "1044^live_match^8^1x": {
        "name_en": "1X",
        "name_aa": "1X",
        "name_de": "1X",
        "name_nl": "1X",
        "name_pl": "1X",
        "name_ro": "1X",
        "name_ru": "1X",
        "name_tr": "1X",
        "name_se": "1X",
        "name_el": "1X",
        "name_es": "1X",
        "name_hr": "1X",
        "name_fr": "1X"
    },
    "2074^live_match^8^7:15": {
        "name_en": "7:15",
        "name_aa": "7:15",
        "name_de": "7:15",
        "name_nl": "7:15",
        "name_pl": "7:15",
        "name_ro": "7:15",
        "name_ru": "7:15",
        "name_tr": "7:15",
        "name_se": "7:15",
        "name_el": "7:15",
        "name_es": "7:15",
        "name_hr": "7:15",
        "name_fr": "7:15"
    },
    "230^live_match^8^away_penalties": {
        "name_en": "Away penalties",
        "name_aa": "Away penalties",
        "name_de": "Elfmeterschießen - Auswärtsmannschaft",
        "name_nl": "Uit Penalties",
        "name_pl": "Away penalties",
        "name_ro": "Away penalties",
        "name_ru": "Away penalties",
        "name_tr": "penaltılar sonunda deplasman",
        "name_se": "Away penalties",
        "name_el": "Away penalties",
        "name_es": "Away penalties",
        "name_hr": "Pobjeda gostiju nakon izvođenja penala",
        "name_fr": "Extérieur Tirs au but"
    },
    "2048^live_match^8^ah": {
        "name_en": "AH",
        "name_aa": "AH",
        "name_de": "AH",
        "name_nl": "AH",
        "name_pl": "AH",
        "name_ro": "AH",
        "name_ru": "AH",
        "name_tr": "AH",
        "name_se": "AH",
        "name_el": "AH",
        "name_es": "AH",
        "name_hr": "AH",
        "name_fr": "AH"
    },
    "26^live_match^8^21:0": {
        "name_en": "21:0",
        "name_aa": "21:0",
        "name_de": "21:0",
        "name_nl": "21:0",
        "name_pl": "21:0",
        "name_ro": "21:0",
        "name_ru": "21:0",
        "name_tr": "21:0",
        "name_se": "21:0",
        "name_el": "21:0",
        "name_es": "21:0",
        "name_hr": "21:0",
        "name_fr": "21:0"
    },
    "2259^live_match^8^home_and_over": {
        "name_en": "home and over",
        "name_aa": "home and over",
        "name_de": "Heim und Über",
        "name_nl": "Thuis and Meer dan",
        "name_pl": "home and over",
        "name_ro": "home and over",
        "name_ru": "home and over",
        "name_tr": "home and over",
        "name_se": "home and over",
        "name_el": "home and over",
        "name_es": "home and over",
        "name_hr": "Domači i više",
        "name_fr": "home and over"
    },
    "2208^live_match^8^21-22": {
        "name_en": "21-22",
        "name_aa": "21-22",
        "name_de": "21-22",
        "name_nl": "21-22",
        "name_pl": "21-22",
        "name_ro": "21-22",
        "name_ru": "21-22",
        "name_tr": "21-22",
        "name_se": "21-22",
        "name_el": "21-22",
        "name_es": "21-22",
        "name_hr": "21-22",
        "name_fr": "21-22"
    },
    "2354^live_match^8^1:11": {
        "name_en": "1:11",
        "name_aa": "1:11",
        "name_de": "1:11",
        "name_nl": "1:11",
        "name_pl": "1:11",
        "name_ro": "1:11",
        "name_ru": "1:11",
        "name_tr": "1:11",
        "name_se": "1:11",
        "name_el": "1:11",
        "name_es": "1:11",
        "name_hr": "1:11",
        "name_fr": "1:11"
    },
    "2028^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "2354^live_match^8^6:2": {
        "name_en": "6:2",
        "name_aa": "6:2",
        "name_de": "6:2",
        "name_nl": "6:2",
        "name_pl": "6:2",
        "name_ro": "6:2",
        "name_ru": "6:2",
        "name_tr": "6:2",
        "name_se": "6:2",
        "name_el": "6:2",
        "name_es": "6:2",
        "name_hr": "6:2",
        "name_fr": "6:2"
    },
    "117^live_match^8^0:2": {
        "name_en": "0:2",
        "name_aa": "0:2",
        "name_de": "0:2",
        "name_nl": "0:2",
        "name_pl": "0:2",
        "name_ro": "0:2",
        "name_ru": "0:2",
        "name_tr": "0:2",
        "name_se": "0:2",
        "name_el": "0:2",
        "name_es": "0:2",
        "name_hr": "0:2",
        "name_fr": "0:2"
    },
    "2261^live_match^8^5:3": {
        "name_en": "5:3",
        "name_aa": "5:3",
        "name_de": "5:3",
        "name_nl": "5:3",
        "name_pl": "5:3",
        "name_ro": "5:3",
        "name_ru": "5:3",
        "name_tr": "5:3",
        "name_se": "5:3",
        "name_el": "5:3",
        "name_es": "5:3",
        "name_hr": "5:3",
        "name_fr": "5:3"
    },
    "26^live_match^8^16:2": {
        "name_en": "16:2",
        "name_aa": "16:2",
        "name_de": "16:2",
        "name_nl": "16:2",
        "name_pl": "16:2",
        "name_ro": "16:2",
        "name_ru": "16:2",
        "name_tr": "16:2",
        "name_se": "16:2",
        "name_el": "16:2",
        "name_es": "16:2",
        "name_hr": "16:2",
        "name_fr": "16:2"
    },
    "2051^live_match^6^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2261^live_match^8^1:4": {
        "name_en": "1:4",
        "name_aa": "1:4",
        "name_de": "1:4",
        "name_nl": "1:4",
        "name_pl": "1:4",
        "name_ro": "1:4",
        "name_ru": "1:4",
        "name_tr": "1:4",
        "name_se": "1:4",
        "name_el": "1:4",
        "name_es": "1:4",
        "name_hr": "1:4",
        "name_fr": "1:4"
    },
    "1108^live_match^8^8:2": {
        "name_en": "8:2",
        "name_aa": "8:2",
        "name_de": "8:2",
        "name_nl": "8:2",
        "name_pl": "8:2",
        "name_ro": "8:2",
        "name_ru": "8:2",
        "name_tr": "8:2",
        "name_se": "8:2",
        "name_el": "8:2",
        "name_es": "8:2",
        "name_hr": "8:2",
        "name_fr": "8:2"
    },
    "2219^live_match^8^tie": {
        "name_en": "Tie",
        "name_aa": "Tie",
        "name_de": "Unentschieden",
        "name_nl": "Gelijkspel",
        "name_pl": "Tie",
        "name_ro": "Tie",
        "name_ru": "Tie",
        "name_tr": "Tie",
        "name_se": "Tie",
        "name_el": "Tie",
        "name_es": "Tie",
        "name_hr": "Tie",
        "name_fr": "Tie"
    },
    "2232^live_match^6^x": {
        "name_en": "x",
        "name_aa": "x",
        "name_de": "x",
        "name_nl": "x",
        "name_pl": "x",
        "name_ro": "x",
        "name_ru": "x",
        "name_tr": "x",
        "name_se": "x",
        "name_el": "x",
        "name_es": "x",
        "name_hr": "x",
        "name_fr": "x"
    },
    "237^live_match^8^ah": {
        "name_en": "AH",
        "name_aa": "AH",
        "name_de": "A-H",
        "name_nl": "AH",
        "name_pl": "AH",
        "name_ro": "AH",
        "name_ru": "AH",
        "name_tr": "DE",
        "name_se": "AH",
        "name_el": "AH",
        "name_es": "AH",
        "name_hr": "2-1",
        "name_fr": "ED"
    },
    "2261^live_match^8^8:6": {
        "name_en": "8:6",
        "name_aa": "8:6",
        "name_de": "8:6",
        "name_nl": "8:6",
        "name_pl": "8:6",
        "name_ro": "8:6",
        "name_ru": "8:6",
        "name_tr": "8:6",
        "name_se": "8:6",
        "name_el": "8:6",
        "name_es": "8:6",
        "name_hr": "8:6",
        "name_fr": "8:6"
    },
    "107^live_match^6^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2340^live_match^8^miss": {
        "name_en": "miss",
        "name_aa": "miss",
        "name_de": "miss",
        "name_nl": "miss",
        "name_pl": "miss",
        "name_ro": "miss",
        "name_ru": "miss",
        "name_tr": "miss",
        "name_se": "miss",
        "name_el": "miss",
        "name_es": "miss",
        "name_hr": "miss",
        "name_fr": "miss"
    },
    "2059^live_match^8^away_7-9": {
        "name_en": "away 7-9",
        "name_aa": "away 7-9",
        "name_de": "Auswärt 7-9",
        "name_nl": "Weg 7-9",
        "name_pl": "away 7-9",
        "name_ro": "away 7-9",
        "name_ru": "away 7-9",
        "name_tr": "away 7-9",
        "name_se": "away 7-9",
        "name_el": "away 7-9",
        "name_es": "away 7-9",
        "name_hr": "Gostujuči  7-9",
        "name_fr": "away 7-9"
    },
    "2062^live_match^8^home_5-6": {
        "name_en": "home 5-6",
        "name_aa": "home 5-6",
        "name_de": "Heim 5-6",
        "name_nl": "thuis 5-6",
        "name_pl": "home 5-6",
        "name_ro": "home 5-6",
        "name_ru": "home 5-6",
        "name_tr": "home 5-6",
        "name_se": "home 5-6",
        "name_el": "home 5-6",
        "name_es": "home 5-6",
        "name_hr": "Domači 5-6",
        "name_fr": "home 5-6"
    },
    "2354^live_match^8^13:0": {
        "name_en": "13:0",
        "name_aa": "13:0",
        "name_de": "13:0",
        "name_nl": "13:0",
        "name_pl": "13:0",
        "name_ro": "13:0",
        "name_ru": "13:0",
        "name_tr": "13:0",
        "name_se": "13:0",
        "name_el": "13:0",
        "name_es": "13:0",
        "name_hr": "13:0",
        "name_fr": "13:0"
    },
    "2351^live_match^8^no": {
        "name_en": "no",
        "name_aa": "no",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "no",
        "name_ro": "no",
        "name_ru": "no",
        "name_tr": "no",
        "name_se": "no",
        "name_el": "no",
        "name_es": "no",
        "name_hr": "Ne",
        "name_fr": "no"
    },
    "2074^live_match^8^15:6": {
        "name_en": "15:6",
        "name_aa": "15:6",
        "name_de": "15:6",
        "name_nl": "15:6",
        "name_pl": "15:6",
        "name_ro": "15:6",
        "name_ru": "15:6",
        "name_tr": "15:6",
        "name_se": "15:6",
        "name_el": "15:6",
        "name_es": "15:6",
        "name_hr": "15:6",
        "name_fr": "15:6"
    },
    "2261^live_match^8^5:6": {
        "name_en": "5:6",
        "name_aa": "5:6",
        "name_de": "5:6",
        "name_nl": "5:6",
        "name_pl": "5:6",
        "name_ro": "5:6",
        "name_ru": "5:6",
        "name_tr": "5:6",
        "name_se": "5:6",
        "name_el": "5:6",
        "name_es": "5:6",
        "name_hr": "5:6",
        "name_fr": "5:6"
    },
    "2098^live_match^8^exactly": {
        "name_en": "exactly",
        "name_aa": "exactly",
        "name_de": "Genau",
        "name_nl": "Precies",
        "name_pl": "exactly",
        "name_ro": "exactly",
        "name_ru": "exactly",
        "name_tr": "exactly",
        "name_se": "exactly",
        "name_el": "exactly",
        "name_es": "exactly",
        "name_hr": "Tacno",
        "name_fr": "exactly"
    },
    "2074^live_match^8^15:12": {
        "name_en": "15:12",
        "name_aa": "15:12",
        "name_de": "15:12",
        "name_nl": "15:12",
        "name_pl": "15:12",
        "name_ro": "15:12",
        "name_ru": "15:12",
        "name_tr": "15:12",
        "name_se": "15:12",
        "name_el": "15:12",
        "name_es": "15:12",
        "name_hr": "15:12",
        "name_fr": "15:12"
    },
    "1580^live_match^6^x": {
        "name_en": "x",
        "name_aa": "x",
        "name_de": "x",
        "name_nl": "x",
        "name_pl": "x",
        "name_ro": "x",
        "name_ru": "x",
        "name_tr": "x",
        "name_se": "x",
        "name_el": "x",
        "name_es": "x",
        "name_hr": "x",
        "name_fr": "x"
    },
    "26^live_match^8^4:9": {
        "name_en": "4:9",
        "name_aa": "4:9",
        "name_de": "4:9",
        "name_nl": "4:9",
        "name_pl": "4:9",
        "name_ro": "4:9",
        "name_ru": "4:9",
        "name_tr": "4:9",
        "name_se": "4:9",
        "name_el": "4:9",
        "name_es": "4:9",
        "name_hr": "4:9",
        "name_fr": "4:9"
    },
    "2354^live_match^8^3:8": {
        "name_en": "3:8",
        "name_aa": "3:8",
        "name_de": "3:8",
        "name_nl": "3:8",
        "name_pl": "3:8",
        "name_ro": "3:8",
        "name_ru": "3:8",
        "name_tr": "3:8",
        "name_se": "3:8",
        "name_el": "3:8",
        "name_es": "3:8",
        "name_hr": "3:8",
        "name_fr": "3:8"
    },
    "2207^live_match^8^draw": {
        "name_en": "draw",
        "name_aa": "draw",
        "name_de": "Unentschieden",
        "name_nl": "Gelijkspel",
        "name_pl": "draw",
        "name_ro": "draw",
        "name_ru": "draw",
        "name_tr": "draw",
        "name_se": "draw",
        "name_el": "draw",
        "name_es": "draw",
        "name_hr": "Neriješeno",
        "name_fr": "draw"
    },
    "2129^live_match^8^1:1": {
        "name_en": "1:1",
        "name_aa": "1:1",
        "name_de": "1:1",
        "name_nl": "1:1",
        "name_pl": "1:1",
        "name_ro": "1:1",
        "name_ru": "1:1",
        "name_tr": "1:1",
        "name_se": "1:1",
        "name_el": "1:1",
        "name_es": "1:1",
        "name_hr": "1:1",
        "name_fr": "1:1"
    },
    "1671^live_match^8^yes": {
        "name_en": "yes",
        "name_aa": "yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "yes",
        "name_ro": "yes",
        "name_ru": "yes",
        "name_tr": "yes",
        "name_se": "yes",
        "name_el": "yes",
        "name_es": "yes",
        "name_hr": "Da",
        "name_fr": "yes"
    },
    "520^live_match^8^3:6": {
        "name_en": "3:6",
        "name_aa": "3:6",
        "name_de": "3:6",
        "name_nl": "3:6",
        "name_pl": "3:6",
        "name_ro": "3:6",
        "name_ru": "3:6",
        "name_tr": "3:6",
        "name_se": "3:6",
        "name_el": "3:6",
        "name_es": "3:6",
        "name_hr": "3:6",
        "name_fr": "3:6"
    },
    "29^live_match^8^9:2": {
        "name_en": "9:2",
        "name_aa": "9:2",
        "name_de": "9:2",
        "name_nl": "9:2",
        "name_pl": "9:2",
        "name_ro": "9:2",
        "name_ru": "9:2",
        "name_tr": "9:2",
        "name_se": "9:2",
        "name_el": "9:2",
        "name_es": "9:2",
        "name_hr": "9:2",
        "name_fr": "9:2"
    },
    "16^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "26^live_match^8^0:18": {
        "name_en": "0:18",
        "name_aa": "0:18",
        "name_de": "0:18",
        "name_nl": "0:18",
        "name_pl": "0:18",
        "name_ro": "0:18",
        "name_ru": "0:18",
        "name_tr": "0:18",
        "name_se": "0:18",
        "name_el": "0:18",
        "name_es": "0:18",
        "name_hr": "0:18",
        "name_fr": "0:18"
    },
    "2260^live_match^8^away_4+": {
        "name_en": "away 4+",
        "name_aa": "away 4+",
        "name_de": "Auswärt 4+",
        "name_nl": "Weg 4+",
        "name_pl": "away 4+",
        "name_ro": "away 4+",
        "name_ru": "away 4+",
        "name_tr": "away 4+",
        "name_se": "away 4+",
        "name_el": "away 4+",
        "name_es": "away 4+",
        "name_hr": "Gostujuči  4+",
        "name_fr": "away 4+"
    },
    "2058^live_match^8^ha": {
        "name_en": "HA",
        "name_aa": "HA",
        "name_de": "HA",
        "name_nl": "HA",
        "name_pl": "HA",
        "name_ro": "HA",
        "name_ru": "HA",
        "name_tr": "HA",
        "name_se": "HA",
        "name_el": "HA",
        "name_es": "HA",
        "name_hr": "HA",
        "name_fr": "HA"
    },
    "2125^live_match^8^away": {
        "name_en": "away",
        "name_aa": "away",
        "name_de": "Auswärts",
        "name_nl": "Uit",
        "name_pl": "away",
        "name_ro": "away",
        "name_ru": "away",
        "name_tr": "away",
        "name_se": "away",
        "name_el": "away",
        "name_es": "away",
        "name_hr": "Gostujući",
        "name_fr": "away"
    },
    "2062^live_match^8^away_3-4": {
        "name_en": "away 3-4",
        "name_aa": "away 3-4",
        "name_de": "Auswärt 3-4",
        "name_nl": "Weg 3-4",
        "name_pl": "away 3-4",
        "name_ro": "away 3-4",
        "name_ru": "away 3-4",
        "name_tr": "away 3-4",
        "name_se": "away 3-4",
        "name_el": "away 3-4",
        "name_es": "away 3-4",
        "name_hr": "Gostujuči 3-4",
        "name_fr": "away 3-4"
    },
    "2038^live_match^4^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2337^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "2336^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "1108^live_match^8^5:11": {
        "name_en": "5:11",
        "name_aa": "5:11",
        "name_de": "5:11",
        "name_nl": "5:11",
        "name_pl": "5:11",
        "name_ro": "5:11",
        "name_ru": "5:11",
        "name_tr": "5:11",
        "name_se": "5:11",
        "name_el": "5:11",
        "name_es": "5:11",
        "name_hr": "5:11",
        "name_fr": "5:11"
    },
    "1108^live_match^8^4:2": {
        "name_en": "4:2",
        "name_aa": "4:2",
        "name_de": "4:2",
        "name_nl": "4:2",
        "name_pl": "4:2",
        "name_ro": "4:2",
        "name_ru": "4:2",
        "name_tr": "4:2",
        "name_se": "4:2",
        "name_el": "4:2",
        "name_es": "4:2",
        "name_hr": "4:2",
        "name_fr": "4:2"
    },
    "18^live_match^8^1:2": {
        "name_en": "1:2",
        "name_aa": "1:2",
        "name_de": "1:2",
        "name_nl": "1:2",
        "name_pl": "1:2",
        "name_ro": "1:2",
        "name_ru": "1:2",
        "name_tr": "1:2",
        "name_se": "1:2",
        "name_el": "1:2",
        "name_es": "1:2",
        "name_hr": "1:2",
        "name_fr": "1:2"
    },
    "880^live_match^8^over": {
        "name_en": "Over",
        "name_aa": "Over",
        "name_de": "Über",
        "name_nl": "Over",
        "name_pl": "Over",
        "name_ro": "Over",
        "name_ru": "Over",
        "name_tr": "Üst",
        "name_se": "Over",
        "name_el": "Over",
        "name_es": "Over",
        "name_hr": "Više",
        "name_fr": "p"
    },
    "26^live_match^8^5:5": {
        "name_en": "5:5",
        "name_aa": "5:5",
        "name_de": "5:5",
        "name_nl": "5:5",
        "name_pl": "5:5",
        "name_ro": "5:5",
        "name_ru": "5:5",
        "name_tr": "5:5",
        "name_se": "5:5",
        "name_el": "5:5",
        "name_es": "5:5",
        "name_hr": "5:5",
        "name_fr": "5:5"
    },
    "1156^live_match^8^4:5": {
        "name_en": "4:5",
        "name_aa": "4:5",
        "name_de": "4:5",
        "name_nl": "4:5",
        "name_pl": "4:5",
        "name_ro": "4:5",
        "name_ru": "4:5",
        "name_tr": "4:5",
        "name_se": "4:5",
        "name_el": "4:5",
        "name_es": "4:5",
        "name_hr": "4:5",
        "name_fr": "4:5"
    },
    "126^live_match^8^under": {
        "name_en": "Under",
        "name_aa": "u",
        "name_de": "Unter",
        "name_nl": "u",
        "name_pl": "u",
        "name_ro": "u",
        "name_ru": "u",
        "name_tr": "Alt",
        "name_se": "u",
        "name_el": "u",
        "name_es": "u",
        "name_hr": "Manje",
        "name_fr": "m"
    },
    "1108^live_match^8^7:8": {
        "name_en": "7:8",
        "name_aa": "7:8",
        "name_de": "7:8",
        "name_nl": "7:8",
        "name_pl": "7:8",
        "name_ro": "7:8",
        "name_ru": "7:8",
        "name_tr": "7:8",
        "name_se": "7:8",
        "name_el": "7:8",
        "name_es": "7:8",
        "name_hr": "7:8",
        "name_fr": "7:8"
    },
    "29^live_match^8^4:4": {
        "name_en": "4:4",
        "name_aa": "4:4",
        "name_de": "4:4",
        "name_nl": "4:4",
        "name_pl": "4:4",
        "name_ro": "4:4",
        "name_ru": "4:4",
        "name_tr": "4:4",
        "name_se": "4:4",
        "name_el": "4:4",
        "name_es": "4:4",
        "name_hr": "4:4",
        "name_fr": "4:4"
    },
    "2022^live_match^8^away": {
        "name_en": "away",
        "name_aa": "away",
        "name_de": "Auswärts",
        "name_nl": "Uit",
        "name_pl": "away",
        "name_ro": "away",
        "name_ru": "away",
        "name_tr": "away",
        "name_se": "away",
        "name_el": "away",
        "name_es": "away",
        "name_hr": "Gostujući",
        "name_fr": "away"
    },
    "2185^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "18^live_match^8^0:2": {
        "name_en": "0:2",
        "name_aa": "0:2",
        "name_de": "0:2",
        "name_nl": "0:2",
        "name_pl": "0:2",
        "name_ro": "0:2",
        "name_ru": "0:2",
        "name_tr": "0:2",
        "name_se": "0:2",
        "name_el": "0:2",
        "name_es": "0:2",
        "name_hr": "0:2",
        "name_fr": "0:2"
    },
    "2261^live_match^8^6:4": {
        "name_en": "6:4",
        "name_aa": "6:4",
        "name_de": "6:4",
        "name_nl": "6:4",
        "name_pl": "6:4",
        "name_ro": "6:4",
        "name_ru": "6:4",
        "name_tr": "6:4",
        "name_se": "6:4",
        "name_el": "6:4",
        "name_es": "6:4",
        "name_hr": "6:4",
        "name_fr": "6:4"
    },
    "78^live_match^8^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "1661^live_match^2^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2218^live_match^8^2:1": {
        "name_en": "2:1",
        "name_aa": "2:1",
        "name_de": "2:1",
        "name_nl": "2:1",
        "name_pl": "2:1",
        "name_ro": "2:1",
        "name_ru": "2:1",
        "name_tr": "2:1",
        "name_se": "2:1",
        "name_el": "2:1",
        "name_es": "2:1",
        "name_hr": "2:1",
        "name_fr": "2:1"
    },
    "29^live_match^8^5:1": {
        "name_en": "5:1",
        "name_aa": "5:1",
        "name_de": "5:1",
        "name_nl": "5:1",
        "name_pl": "5:1",
        "name_ro": "5:1",
        "name_ru": "5:1",
        "name_tr": "5:1",
        "name_se": "5:1",
        "name_el": "5:1",
        "name_es": "5:1",
        "name_hr": "5:1",
        "name_fr": "5:1"
    },
    "0^live_match^5^u": {
        "name_en": "Under",
        "name_aa": "u",
        "name_de": "Unter",
        "name_nl": "u",
        "name_pl": "u",
        "name_ro": "u",
        "name_ru": "u",
        "name_tr": "Alt",
        "name_se": "u",
        "name_el": "u",
        "name_es": "u",
        "name_hr": "Manje",
        "name_fr": "m"
    },
    "2321^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "2133^live_match^8^yes": {
        "name_en": "yes",
        "name_aa": "yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "yes",
        "name_ro": "yes",
        "name_ru": "yes",
        "name_tr": "yes",
        "name_se": "yes",
        "name_el": "yes",
        "name_es": "yes",
        "name_hr": "Da",
        "name_fr": "yes"
    },
    "2261^live_match^8^4:7": {
        "name_en": "4:7",
        "name_aa": "4:7",
        "name_de": "4:7",
        "name_nl": "4:7",
        "name_pl": "4:7",
        "name_ro": "4:7",
        "name_ru": "4:7",
        "name_tr": "4:7",
        "name_se": "4:7",
        "name_el": "4:7",
        "name_es": "4:7",
        "name_hr": "4:7",
        "name_fr": "4:7"
    },
    "2152^live_match^8^away": {
        "name_en": "away",
        "name_aa": "away",
        "name_de": "Auswärts",
        "name_nl": "Uit",
        "name_pl": "away",
        "name_ro": "away",
        "name_ru": "away",
        "name_tr": "away",
        "name_se": "away",
        "name_el": "away",
        "name_es": "away",
        "name_hr": "Gostujući",
        "name_fr": "away"
    },
    "2028^live_match^8^exactly": {
        "name_en": "exactly",
        "name_aa": "exactly",
        "name_de": "Genau",
        "name_nl": "Precies",
        "name_pl": "exactly",
        "name_ro": "exactly",
        "name_ru": "exactly",
        "name_tr": "exactly",
        "name_se": "exactly",
        "name_el": "exactly",
        "name_es": "exactly",
        "name_hr": "Tacno",
        "name_fr": "exactly"
    },
    "2354^live_match^8^8:10": {
        "name_en": "8:10",
        "name_aa": "8:10",
        "name_de": "8:10",
        "name_nl": "8:10",
        "name_pl": "8:10",
        "name_ro": "8:10",
        "name_ru": "8:10",
        "name_tr": "8:10",
        "name_se": "8:10",
        "name_el": "8:10",
        "name_es": "8:10",
        "name_hr": "8:10",
        "name_fr": "8:10"
    },
    "2073^live_match^8^22:25": {
        "name_en": "22:25",
        "name_aa": "22:25",
        "name_de": "22:25",
        "name_nl": "22:25",
        "name_pl": "22:25",
        "name_ro": "22:25",
        "name_ru": "22:25",
        "name_tr": "22:25",
        "name_se": "22:25",
        "name_el": "22:25",
        "name_es": "22:25",
        "name_hr": "22:25",
        "name_fr": "22:25"
    },
    "19^live_match^8^1:3": {
        "name_en": "1:3",
        "name_aa": "1:3",
        "name_de": "1:3",
        "name_nl": "1:3",
        "name_pl": "1:3",
        "name_ro": "1:3",
        "name_ru": "1:3",
        "name_tr": "1:3",
        "name_se": "1:3",
        "name_el": "1:3",
        "name_es": "1:3",
        "name_hr": "1:3",
        "name_fr": "1:3"
    },
    "2262^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "75^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2234^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "2081^live_match^8^4:2": {
        "name_en": "4:2",
        "name_aa": "4:2",
        "name_de": "4:2",
        "name_nl": "4:2",
        "name_pl": "4:2",
        "name_ro": "4:2",
        "name_ru": "4:2",
        "name_tr": "4:2",
        "name_se": "4:2",
        "name_el": "4:2",
        "name_es": "4:2",
        "name_hr": "4:2",
        "name_fr": "4:2"
    },
    "2059^live_match^8^away_16+": {
        "name_en": "away 16+",
        "name_aa": "away 16+",
        "name_de": "Auswärt 16+",
        "name_nl": "Weg 16+",
        "name_pl": "away 16+",
        "name_ro": "away 16+",
        "name_ru": "away 16+",
        "name_tr": "away 16+",
        "name_se": "away 16+",
        "name_el": "away 16+",
        "name_es": "away 16+",
        "name_hr": "Gostujuči 16+",
        "name_fr": "away 16+"
    },
    "2354^live_match^8^3:0": {
        "name_en": "3:0",
        "name_aa": "3:0",
        "name_de": "3:0",
        "name_nl": "3:0",
        "name_pl": "3:0",
        "name_ro": "3:0",
        "name_ru": "3:0",
        "name_tr": "3:0",
        "name_se": "3:0",
        "name_el": "3:0",
        "name_es": "3:0",
        "name_hr": "3:0",
        "name_fr": "3:0"
    },
    "33^live_match^8^over": {
        "name_en": "Over",
        "name_aa": "Over",
        "name_de": "Über",
        "name_nl": "Over",
        "name_pl": "Over",
        "name_ro": "Over",
        "name_ru": "Over",
        "name_tr": "Üst",
        "name_se": "Over",
        "name_el": "Over",
        "name_es": "Over",
        "name_hr": "Više",
        "name_fr": "p"
    },
    "291^live_match^6^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2136^live_match^8^yes": {
        "name_en": "yes",
        "name_aa": "yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "yes",
        "name_ro": "yes",
        "name_ru": "yes",
        "name_tr": "yes",
        "name_se": "yes",
        "name_el": "yes",
        "name_es": "yes",
        "name_hr": "Da",
        "name_fr": "yes"
    },
    "237^live_match^8^hh": {
        "name_en": "HH",
        "name_aa": "HH",
        "name_de": "H-H",
        "name_nl": "HH",
        "name_pl": "HH",
        "name_ro": "HH",
        "name_ru": "HH",
        "name_tr": "EE",
        "name_se": "HH",
        "name_el": "HH",
        "name_es": "HH",
        "name_hr": "1-1",
        "name_fr": "DD"
    },
    "2339^live_match^8^score": {
        "name_en": "score",
        "name_aa": "score",
        "name_de": "Erzielt",
        "name_nl": "score",
        "name_pl": "score",
        "name_ro": "score",
        "name_ru": "score",
        "name_tr": "score",
        "name_se": "score",
        "name_el": "score",
        "name_es": "score",
        "name_hr": "score",
        "name_fr": "score"
    },
    "29^live_match^8^5:0": {
        "name_en": "5:0",
        "name_aa": "5:0",
        "name_de": "5:0",
        "name_nl": "5:0",
        "name_pl": "5:0",
        "name_ro": "5:0",
        "name_ru": "5:0",
        "name_tr": "5:0",
        "name_se": "5:0",
        "name_el": "5:0",
        "name_es": "5:0",
        "name_hr": "5:0",
        "name_fr": "5:0"
    },
    "29^live_match^8^1:14": {
        "name_en": "1:14",
        "name_aa": "1:14",
        "name_de": "1:14",
        "name_nl": "1:14",
        "name_pl": "1:14",
        "name_ro": "1:14",
        "name_ru": "1:14",
        "name_tr": "1:14",
        "name_se": "1:14",
        "name_el": "1:14",
        "name_es": "1:14",
        "name_hr": "1:14",
        "name_fr": "1:14"
    },
    "1276^live_match^8^over": {
        "name_en": "Over",
        "name_aa": "Over",
        "name_de": "Über",
        "name_nl": "Over",
        "name_pl": "Over",
        "name_ro": "Over",
        "name_ru": "Over",
        "name_tr": "Üst",
        "name_se": "Over",
        "name_el": "Over",
        "name_es": "Over",
        "name_hr": "Više",
        "name_fr": "p"
    },
    "26^live_match^8^2:13": {
        "name_en": "2:13",
        "name_aa": "2:13",
        "name_de": "2:13",
        "name_nl": "2:13",
        "name_pl": "2:13",
        "name_ro": "2:13",
        "name_ru": "2:13",
        "name_tr": "2:13",
        "name_se": "2:13",
        "name_el": "2:13",
        "name_es": "2:13",
        "name_hr": "2:13",
        "name_fr": "2:13"
    },
    "520^live_match^8^1:4": {
        "name_en": "1:4",
        "name_aa": "1:4",
        "name_de": "1:4",
        "name_nl": "1:4",
        "name_pl": "1:4",
        "name_ro": "1:4",
        "name_ru": "1:4",
        "name_tr": "1:4",
        "name_se": "1:4",
        "name_el": "1:4",
        "name_es": "1:4",
        "name_hr": "1:4",
        "name_fr": "1:4"
    },
    "2111^live_match^8^away_4": {
        "name_en": "away 4",
        "name_aa": "away 4",
        "name_de": "Auswärt 4",
        "name_nl": "Weg 4",
        "name_pl": "away 4",
        "name_ro": "away 4",
        "name_ru": "away 4",
        "name_tr": "away 4",
        "name_se": "away 4",
        "name_el": "away 4",
        "name_es": "away 4",
        "name_hr": "Gostujuči 4",
        "name_fr": "away 4"
    },
    "88^live_match^8^over": {
        "name_en": "Over",
        "name_aa": "Over",
        "name_de": "Über",
        "name_nl": "Over",
        "name_pl": "Over",
        "name_ro": "Over",
        "name_ru": "Over",
        "name_tr": "Üst",
        "name_se": "Over",
        "name_el": "Over",
        "name_es": "Over",
        "name_hr": "Više",
        "name_fr": "p"
    },
    "2073^live_match^8^15:25_or_better": {
        "name_en": "15:25 or better",
        "name_aa": "15:25 or better",
        "name_de": "15:25 oder besser",
        "name_nl": "15:25 of beter",
        "name_pl": "15:25 or better",
        "name_ro": "15:25 or better",
        "name_ru": "15:25 or better",
        "name_tr": "15:25 or better",
        "name_se": "15:25 or better",
        "name_el": "15:25 or better",
        "name_es": "15:25 or better",
        "name_hr": "15:25 or better",
        "name_fr": "15:25 or better"
    },
    "930^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2211^live_match^8^away_19+": {
        "name_en": "away 19+",
        "name_aa": "away 19+",
        "name_de": "Auswärt 19+",
        "name_nl": "Weg 19+",
        "name_pl": "away 19+",
        "name_ro": "away 19+",
        "name_ru": "away 19+",
        "name_tr": "away 19+",
        "name_se": "away 19+",
        "name_el": "away 19+",
        "name_es": "away 19+",
        "name_hr": "Gostujuči 19+",
        "name_fr": "away 19+"
    },
    "2157^live_match^8^21:14": {
        "name_en": "21:14",
        "name_aa": "21:14",
        "name_de": "21:14",
        "name_nl": "21:14",
        "name_pl": "21:14",
        "name_ro": "21:14",
        "name_ru": "21:14",
        "name_tr": "21:14",
        "name_se": "21:14",
        "name_el": "21:14",
        "name_es": "21:14",
        "name_hr": "21:14",
        "name_fr": "21:14"
    },
    "2332^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "2010^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "2032^live_match^8^draw_and_under": {
        "name_en": "draw and under",
        "name_aa": "draw and under",
        "name_de": "Unentschieden und Unter",
        "name_nl": "Gelijkspel and Minder dan",
        "name_pl": "draw and under",
        "name_ro": "draw and under",
        "name_ru": "draw and under",
        "name_tr": "draw and under",
        "name_se": "draw and under",
        "name_el": "draw and under",
        "name_es": "draw and under",
        "name_hr": "Neriješeno i manje",
        "name_fr": "draw and under"
    },
    "2261^live_match^8^0:1": {
        "name_en": "0:1",
        "name_aa": "0:1",
        "name_de": "0:1",
        "name_nl": "0:1",
        "name_pl": "0:1",
        "name_ro": "0:1",
        "name_ru": "0:1",
        "name_tr": "0:1",
        "name_se": "0:1",
        "name_el": "0:1",
        "name_es": "0:1",
        "name_hr": "0:1",
        "name_fr": "0:1"
    },
    "2160^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "2215^live_match^8^draw": {
        "name_en": "draw",
        "name_aa": "draw",
        "name_de": "Unentschieden",
        "name_nl": "Gelijkspel",
        "name_pl": "draw",
        "name_ro": "draw",
        "name_ru": "draw",
        "name_tr": "draw",
        "name_se": "draw",
        "name_el": "draw",
        "name_es": "draw",
        "name_hr": "Neriješeno",
        "name_fr": "draw"
    },
    "2128^live_match^8^5:11": {
        "name_en": "5:11",
        "name_aa": "5:11",
        "name_de": "5:11",
        "name_nl": "5:11",
        "name_pl": "5:11",
        "name_ro": "5:11",
        "name_ru": "5:11",
        "name_tr": "5:11",
        "name_se": "5:11",
        "name_el": "5:11",
        "name_es": "5:11",
        "name_hr": "5:11",
        "name_fr": "5:11"
    },
    "1108^live_match^8^10:5": {
        "name_en": "10:5",
        "name_aa": "10:5",
        "name_de": "10:5",
        "name_nl": "10:5",
        "name_pl": "10:5",
        "name_ro": "10:5",
        "name_ru": "10:5",
        "name_tr": "10:5",
        "name_se": "10:5",
        "name_el": "10:5",
        "name_es": "10:5",
        "name_hr": "10:5",
        "name_fr": "10:5"
    },
    "2080^live_match^8^home": {
        "name_en": "home",
        "name_aa": "home",
        "name_de": "Heim",
        "name_nl": "Thuis",
        "name_pl": "home",
        "name_ro": "home",
        "name_ru": "home",
        "name_tr": "home",
        "name_se": "home",
        "name_el": "home",
        "name_es": "home",
        "name_hr": "Domači",
        "name_fr": "home"
    },
    "2260^live_match^8^home_4+": {
        "name_en": "home 4+",
        "name_aa": "home 4+",
        "name_de": "Heim 4+",
        "name_nl": "Thuis 4+",
        "name_pl": "home 4+",
        "name_ro": "home 4+",
        "name_ru": "home 4+",
        "name_tr": "home 4+",
        "name_se": "home 4+",
        "name_el": "home 4+",
        "name_es": "home 4+",
        "name_hr": "Domači 4+",
        "name_fr": "home 4+"
    },
    "2076^live_match^8^15:07": {
        "name_en": "15:07",
        "name_aa": "15:07",
        "name_de": "15:07",
        "name_nl": "15:07",
        "name_pl": "15:07",
        "name_ro": "15:07",
        "name_ru": "15:07",
        "name_tr": "15:07",
        "name_se": "15:07",
        "name_el": "15:07",
        "name_es": "15:07",
        "name_hr": "15:07",
        "name_fr": "15:07"
    },
    "1230^live_match^8^120-146": {
        "name_en": "120-146",
        "name_aa": "120-146",
        "name_de": "120-146",
        "name_nl": "0-49",
        "name_pl": "120-146",
        "name_ro": "120-146",
        "name_ru": "120-146",
        "name_tr": "120-146",
        "name_se": "120-146",
        "name_el": "120-146",
        "name_es": "120-146",
        "name_hr": "120-146",
        "name_fr": "120-146"
    },
    "107^live_match^6^x": {
        "name_en": "x",
        "name_aa": "x",
        "name_de": "x",
        "name_nl": "x",
        "name_pl": "x",
        "name_ro": "x",
        "name_ru": "x",
        "name_tr": "x",
        "name_se": "x",
        "name_el": "x",
        "name_es": "x",
        "name_hr": "x",
        "name_fr": "x"
    },
    "2225^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "2034^live_match^8^home_and_under": {
        "name_en": "home and under",
        "name_aa": "home and under",
        "name_de": "Heim und Unter",
        "name_nl": "Thuis and Minder dan",
        "name_pl": "home and under",
        "name_ro": "home and under",
        "name_ru": "home and under",
        "name_tr": "home and under",
        "name_se": "home and under",
        "name_el": "home and under",
        "name_es": "home and under",
        "name_hr": "Domači i manje",
        "name_fr": "home and under"
    },
    "2261^live_match^8^8:0": {
        "name_en": "8:0",
        "name_aa": "8:0",
        "name_de": "8:0",
        "name_nl": "8:0",
        "name_pl": "8:0",
        "name_ro": "8:0",
        "name_ru": "8:0",
        "name_tr": "8:0",
        "name_se": "8:0",
        "name_el": "8:0",
        "name_es": "8:0",
        "name_hr": "8:0",
        "name_fr": "8:0"
    },
    "26^live_match^8^5:3": {
        "name_en": "5:3",
        "name_aa": "5:3",
        "name_de": "5:3",
        "name_nl": "5:3",
        "name_pl": "5:3",
        "name_ro": "5:3",
        "name_ru": "5:3",
        "name_tr": "5:3",
        "name_se": "5:3",
        "name_el": "5:3",
        "name_es": "5:3",
        "name_hr": "5:3",
        "name_fr": "5:3"
    },
    "26^live_match^8^9:7": {
        "name_en": "9:7",
        "name_aa": "9:7",
        "name_de": "9:7",
        "name_nl": "9:7",
        "name_pl": "9:7",
        "name_ro": "9:7",
        "name_ru": "9:7",
        "name_tr": "9:7",
        "name_se": "9:7",
        "name_el": "9:7",
        "name_es": "9:7",
        "name_hr": "9:7",
        "name_fr": "9:7"
    },
    "2188^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "2344^live_match^8^yes": {
        "name_en": "yes",
        "name_aa": "yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "yes",
        "name_ro": "yes",
        "name_ru": "yes",
        "name_tr": "yes",
        "name_se": "yes",
        "name_el": "yes",
        "name_es": "yes",
        "name_hr": "Da",
        "name_fr": "yes"
    },
    "2250^live_match^8^yes": {
        "name_en": "yes",
        "name_aa": "yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "yes",
        "name_ro": "yes",
        "name_ru": "yes",
        "name_tr": "yes",
        "name_se": "yes",
        "name_el": "yes",
        "name_es": "yes",
        "name_hr": "Da",
        "name_fr": "yes"
    },
    "2146^live_match^8^away_2+": {
        "name_en": "away 2+",
        "name_aa": "away 2+",
        "name_de": "Auswärt 2+",
        "name_nl": "Weg 2+",
        "name_pl": "away 2+",
        "name_ro": "away 2+",
        "name_ru": "away 2+",
        "name_tr": "away 2+",
        "name_se": "away 2+",
        "name_el": "away 2+",
        "name_es": "away 2+",
        "name_hr": "Gostujuči 2+",
        "name_fr": "away 2+"
    },
    "2354^live_match^8^4:0": {
        "name_en": "4:0",
        "name_aa": "4:0",
        "name_de": "4:0",
        "name_nl": "4:0",
        "name_pl": "4:0",
        "name_ro": "4:0",
        "name_ru": "4:0",
        "name_tr": "4:0",
        "name_se": "4:0",
        "name_el": "4:0",
        "name_es": "4:0",
        "name_hr": "4:0",
        "name_fr": "4:0"
    },
    "26^live_match^8^3:10": {
        "name_en": "3:10",
        "name_aa": "3:10",
        "name_de": "3:10",
        "name_nl": "3:10",
        "name_pl": "3:10",
        "name_ro": "3:10",
        "name_ru": "3:10",
        "name_tr": "3:10",
        "name_se": "3:10",
        "name_el": "3:10",
        "name_es": "3:10",
        "name_hr": "3:10",
        "name_fr": "3:10"
    },
    "2354^live_match^8^14:1": {
        "name_en": "14:1",
        "name_aa": "14:1",
        "name_de": "14:1",
        "name_nl": "14:1",
        "name_pl": "14:1",
        "name_ro": "14:1",
        "name_ru": "14:1",
        "name_tr": "14:1",
        "name_se": "14:1",
        "name_el": "14:1",
        "name_es": "14:1",
        "name_hr": "14:1",
        "name_fr": "14:1"
    },
    "2157^live_match^8^19:21": {
        "name_en": "19:21",
        "name_aa": "19:21",
        "name_de": "19:21",
        "name_nl": "19:21",
        "name_pl": "19:21",
        "name_ro": "19:21",
        "name_ru": "19:21",
        "name_tr": "19:21",
        "name_se": "19:21",
        "name_el": "19:21",
        "name_es": "19:21",
        "name_hr": "19:21",
        "name_fr": "19:21"
    },
    "2088^live_match^8^6:0": {
        "name_en": "6:0",
        "name_aa": "6:0",
        "name_de": "6:0",
        "name_nl": "6:0",
        "name_pl": "6:0",
        "name_ro": "6:0",
        "name_ru": "6:0",
        "name_tr": "6:0",
        "name_se": "6:0",
        "name_el": "6:0",
        "name_es": "6:0",
        "name_hr": "6:0",
        "name_fr": "6:0"
    },
    "2173^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "2111^live_match^8^away_3": {
        "name_en": "away 3",
        "name_aa": "away 3",
        "name_de": "Auswärt 3",
        "name_nl": "Weg 3",
        "name_pl": "away 3",
        "name_ro": "away 3",
        "name_ru": "away 3",
        "name_tr": "away 3",
        "name_se": "away 3",
        "name_el": "away 3",
        "name_es": "away 3",
        "name_hr": "Gostujuči 3",
        "name_fr": "away 3"
    },
    "1669^live_match^8^yes": {
        "name_en": "yes",
        "name_aa": "yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "yes",
        "name_ro": "yes",
        "name_ru": "yes",
        "name_tr": "yes",
        "name_se": "yes",
        "name_el": "yes",
        "name_es": "yes",
        "name_hr": "Da",
        "name_fr": "yes"
    },
    "2073^live_match^8^17:21": {
        "name_en": "17:21",
        "name_aa": "17:21",
        "name_de": "17:21",
        "name_nl": "17:21",
        "name_pl": "17:21",
        "name_ro": "17:21",
        "name_ru": "17:21",
        "name_tr": "17:21",
        "name_se": "17:21",
        "name_el": "17:21",
        "name_es": "17:21",
        "name_hr": "17:21",
        "name_fr": "17:21"
    },
    "26^live_match^8^10:7": {
        "name_en": "10:7",
        "name_aa": "10:7",
        "name_de": "10:7",
        "name_nl": "10:7",
        "name_pl": "10:7",
        "name_ro": "10:7",
        "name_ru": "10:7",
        "name_tr": "10:7",
        "name_se": "10:7",
        "name_el": "10:7",
        "name_es": "10:7",
        "name_hr": "10:7",
        "name_fr": "10:7"
    },
    "2183^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "87^live_match^8^under": {
        "name_en": "Under",
        "name_aa": "u",
        "name_de": "Unter",
        "name_nl": "u",
        "name_pl": "u",
        "name_ro": "u",
        "name_ru": "u",
        "name_tr": "Alt",
        "name_se": "u",
        "name_el": "u",
        "name_es": "u",
        "name_hr": "Manje",
        "name_fr": "m"
    },
    "2354^live_match^8^5:13": {
        "name_en": "5:13",
        "name_aa": "5:13",
        "name_de": "5:13",
        "name_nl": "5:13",
        "name_pl": "5:13",
        "name_ro": "5:13",
        "name_ru": "5:13",
        "name_tr": "5:13",
        "name_se": "5:13",
        "name_el": "5:13",
        "name_es": "5:13",
        "name_hr": "5:13",
        "name_fr": "5:13"
    },
    "1108^live_match^8^6:7": {
        "name_en": "6:7",
        "name_aa": "6:7",
        "name_de": "6:7",
        "name_nl": "6:7",
        "name_pl": "6:7",
        "name_ro": "6:7",
        "name_ru": "6:7",
        "name_tr": "6:7",
        "name_se": "6:7",
        "name_el": "6:7",
        "name_es": "6:7",
        "name_hr": "6:7",
        "name_fr": "6:7"
    },
    "2072^live_match^8^17:25": {
        "name_en": "17:25",
        "name_aa": "17:25",
        "name_de": "17:25",
        "name_nl": "17:25",
        "name_pl": "17:25",
        "name_ro": "17:25",
        "name_ru": "17:25",
        "name_tr": "17:25",
        "name_se": "17:25",
        "name_el": "17:25",
        "name_es": "17:25",
        "name_hr": "17:25",
        "name_fr": "17:25"
    },
    "26^live_match^8^10:5": {
        "name_en": "10:5",
        "name_aa": "10:5",
        "name_de": "10:5",
        "name_nl": "10:5",
        "name_pl": "10:5",
        "name_ro": "10:5",
        "name_ru": "10:5",
        "name_tr": "10:5",
        "name_se": "10:5",
        "name_el": "10:5",
        "name_es": "10:5",
        "name_hr": "10:5",
        "name_fr": "10:5"
    },
    "486^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "26^live_match^8^4:6": {
        "name_en": "4:6",
        "name_aa": "4:6",
        "name_de": "4:6",
        "name_nl": "4:6",
        "name_pl": "4:6",
        "name_ro": "4:6",
        "name_ru": "4:6",
        "name_tr": "4:6",
        "name_se": "4:6",
        "name_el": "4:6",
        "name_es": "4:6",
        "name_hr": "4:6",
        "name_fr": "4:6"
    },
    "29^live_match^8^0:0": {
        "name_en": "0:0",
        "name_aa": "0:0",
        "name_de": "0:0",
        "name_nl": "0:0",
        "name_pl": "0:0",
        "name_ro": "0:0",
        "name_ru": "0:0",
        "name_tr": "0:0",
        "name_se": "0:0",
        "name_el": "0:0",
        "name_es": "0:0",
        "name_hr": "0:0",
        "name_fr": "0:0"
    },
    "117^live_match^8^1:2": {
        "name_en": "1:2",
        "name_aa": "1:2",
        "name_de": "1:2",
        "name_nl": "1:2",
        "name_pl": "1:2",
        "name_ro": "1:2",
        "name_ru": "1:2",
        "name_tr": "1:2",
        "name_se": "1:2",
        "name_el": "1:2",
        "name_es": "1:2",
        "name_hr": "1:2",
        "name_fr": "1:2"
    },
    "26^live_match^8^2:10": {
        "name_en": "2:10",
        "name_aa": "2:10",
        "name_de": "2:10",
        "name_nl": "2:10",
        "name_pl": "2:10",
        "name_ro": "2:10",
        "name_ru": "2:10",
        "name_tr": "2:10",
        "name_se": "2:10",
        "name_el": "2:10",
        "name_es": "2:10",
        "name_hr": "2:10",
        "name_fr": "2:10"
    },
    "2160^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "2354^live_match^8^2:5": {
        "name_en": "2:5",
        "name_aa": "2:5",
        "name_de": "2:5",
        "name_nl": "2:5",
        "name_pl": "2:5",
        "name_ro": "2:5",
        "name_ru": "2:5",
        "name_tr": "2:5",
        "name_se": "2:5",
        "name_el": "2:5",
        "name_es": "2:5",
        "name_hr": "2:5",
        "name_fr": "2:5"
    },
    "520^live_match^8^0:4": {
        "name_en": "0:4",
        "name_aa": "0:4",
        "name_de": "0:4",
        "name_nl": "0:4",
        "name_pl": "0:4",
        "name_ro": "0:4",
        "name_ru": "0:4",
        "name_tr": "0:4",
        "name_se": "0:4",
        "name_el": "0:4",
        "name_es": "0:4",
        "name_hr": "0:4",
        "name_fr": "0:4"
    },
    "2305^live_match^8^4:7": {
        "name_en": "4:7",
        "name_aa": "4:7",
        "name_de": "4:7",
        "name_nl": "4:7",
        "name_pl": "4:7",
        "name_ro": "4:7",
        "name_ru": "4:7",
        "name_tr": "4:7",
        "name_se": "4:7",
        "name_el": "4:7",
        "name_es": "4:7",
        "name_hr": "4:7",
        "name_fr": "4:7"
    },
    "2073^live_match^8^16:25": {
        "name_en": "16:25",
        "name_aa": "16:25",
        "name_de": "16:25",
        "name_nl": "16:25",
        "name_pl": "16:25",
        "name_ro": "16:25",
        "name_ru": "16:25",
        "name_tr": "16:25",
        "name_se": "16:25",
        "name_el": "16:25",
        "name_es": "16:25",
        "name_hr": "16:25",
        "name_fr": "16:25"
    },
    "2026^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "14^live_match^6^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2196^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "1108^live_match^8^8:5": {
        "name_en": "8:5",
        "name_aa": "8:5",
        "name_de": "8:5",
        "name_nl": "8:5",
        "name_pl": "8:5",
        "name_ro": "8:5",
        "name_ru": "8:5",
        "name_tr": "8:5",
        "name_se": "8:5",
        "name_el": "8:5",
        "name_es": "8:5",
        "name_hr": "8:5",
        "name_fr": "8:5"
    },
    "2071^live_match^8^home_3-4": {
        "name_en": "home 3-4",
        "name_aa": "home 3-4",
        "name_de": "Heim 3-4",
        "name_nl": "thuis 3-4",
        "name_pl": "home 3-4",
        "name_ro": "home 3-4",
        "name_ru": "home 3-4",
        "name_tr": "home 3-4",
        "name_se": "home 3-4",
        "name_el": "home 3-4",
        "name_es": "home 3-4",
        "name_hr": "Domači 3-4",
        "name_fr": "home 3-4"
    },
    "2225^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "2261^live_match^8^2_9+": {
        "name_en": "2 9+",
        "name_aa": "2 9+",
        "name_de": "2 9+",
        "name_nl": "2 9+",
        "name_pl": "2 9+",
        "name_ro": "2 9+",
        "name_ru": "2 9+",
        "name_tr": "2 9+",
        "name_se": "2 9+",
        "name_el": "2 9+",
        "name_es": "2 9+",
        "name_hr": "2 9+",
        "name_fr": "2 9+"
    },
    "2258^live_match^8^yes": {
        "name_en": "yes",
        "name_aa": "yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "yes",
        "name_ro": "yes",
        "name_ru": "yes",
        "name_tr": "yes",
        "name_se": "yes",
        "name_el": "yes",
        "name_es": "yes",
        "name_hr": "Da",
        "name_fr": "yes"
    },
    "2315^live_match^8^4:2": {
        "name_en": "4:2",
        "name_aa": "4:2",
        "name_de": "4:2",
        "name_nl": "4:2",
        "name_pl": "4:2",
        "name_ro": "4:2",
        "name_ru": "4:2",
        "name_tr": "4:2",
        "name_se": "4:2",
        "name_el": "4:2",
        "name_es": "4:2",
        "name_hr": "4:2",
        "name_fr": "4:2"
    },
    "2081^live_match^8^6:3": {
        "name_en": "6:3",
        "name_aa": "6:3",
        "name_de": "6:3",
        "name_nl": "6:3",
        "name_pl": "6:3",
        "name_ro": "6:3",
        "name_ru": "6:3",
        "name_tr": "6:3",
        "name_se": "6:3",
        "name_el": "6:3",
        "name_es": "6:3",
        "name_hr": "6:3",
        "name_fr": "6:3"
    },
    "2126^live_match^8^away_3-4": {
        "name_en": "away 3-4",
        "name_aa": "away 3-4",
        "name_de": "Auswärt 3-4",
        "name_nl": "Weg 3-4",
        "name_pl": "away 3-4",
        "name_ro": "away 3-4",
        "name_ru": "away 3-4",
        "name_tr": "away 3-4",
        "name_se": "away 3-4",
        "name_el": "away 3-4",
        "name_es": "away 3-4",
        "name_hr": "Gostujuči 3-4",
        "name_fr": "away 3-4"
    },
    "2247^live_match^6^x": {
        "name_en": "x",
        "name_aa": "x",
        "name_de": "x",
        "name_nl": "x",
        "name_pl": "x",
        "name_ro": "x",
        "name_ru": "x",
        "name_tr": "x",
        "name_se": "x",
        "name_el": "x",
        "name_es": "x",
        "name_hr": "x",
        "name_fr": "x"
    },
    "2221^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2060^live_match^8^home_1-2": {
        "name_en": "home 1-2",
        "name_aa": "home 1-2",
        "name_de": "Heim 1-2",
        "name_nl": "thuis 1-2",
        "name_pl": "home 1-2",
        "name_ro": "home 1-2",
        "name_ru": "home 1-2",
        "name_tr": "home 1-2",
        "name_se": "home 1-2",
        "name_el": "home 1-2",
        "name_es": "home 1-2",
        "name_hr": "Domači 1-2",
        "name_fr": "home 1-2"
    },
    "29^live_match^8^3:1": {
        "name_en": "3:1",
        "name_aa": "3:1",
        "name_de": "3:1",
        "name_nl": "3:1",
        "name_pl": "3:1",
        "name_ro": "3:1",
        "name_ru": "3:1",
        "name_tr": "3:1",
        "name_se": "3:1",
        "name_el": "3:1",
        "name_es": "3:1",
        "name_hr": "3:1",
        "name_fr": "3:1"
    },
    "2354^live_match^8^3:7": {
        "name_en": "3:7",
        "name_aa": "3:7",
        "name_de": "3:7",
        "name_nl": "3:7",
        "name_pl": "3:7",
        "name_ro": "3:7",
        "name_ru": "3:7",
        "name_tr": "3:7",
        "name_se": "3:7",
        "name_el": "3:7",
        "name_es": "3:7",
        "name_hr": "3:7",
        "name_fr": "3:7"
    },
    "29^live_match^8^2:9": {
        "name_en": "2:9",
        "name_aa": "2:9",
        "name_de": "2:9",
        "name_nl": "2:9",
        "name_pl": "2:9",
        "name_ro": "2:9",
        "name_ru": "2:9",
        "name_tr": "2:9",
        "name_se": "2:9",
        "name_el": "2:9",
        "name_es": "2:9",
        "name_hr": "2:9",
        "name_fr": "2:9"
    },
    "2207^live_match^8^home_61-72": {
        "name_en": "home 61-72",
        "name_aa": "home 61-72",
        "name_de": "Heim 61-72",
        "name_nl": "Thuis 61-72",
        "name_pl": "home 61-72",
        "name_ro": "home 61-72",
        "name_ru": "home 61-72",
        "name_tr": "home 61-72",
        "name_se": "home 61-72",
        "name_el": "home 61-72",
        "name_es": "home 61-72",
        "name_hr": "Domači 61-72",
        "name_fr": "home 61-72"
    },
    "2354^live_match^8^1:3": {
        "name_en": "1:3",
        "name_aa": "1:3",
        "name_de": "1:3",
        "name_nl": "1:3",
        "name_pl": "1:3",
        "name_ro": "1:3",
        "name_ru": "1:3",
        "name_tr": "1:3",
        "name_se": "1:3",
        "name_el": "1:3",
        "name_es": "1:3",
        "name_hr": "1:3",
        "name_fr": "1:3"
    },
    "357^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "142^live_match^8^over": {
        "name_en": "Over",
        "name_aa": "Over",
        "name_de": "Über",
        "name_nl": "Over",
        "name_pl": "Over",
        "name_ro": "Over",
        "name_ru": "Over",
        "name_tr": "Üst",
        "name_se": "Over",
        "name_el": "Over",
        "name_es": "Over",
        "name_hr": "Više",
        "name_fr": "p"
    },
    "2174^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "102^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "1108^live_match^8^2:15": {
        "name_en": "2:15",
        "name_aa": "2:15",
        "name_de": "2:15",
        "name_nl": "2:15",
        "name_pl": "2:15",
        "name_ro": "2:15",
        "name_ru": "2:15",
        "name_tr": "2:15",
        "name_se": "2:15",
        "name_el": "2:15",
        "name_es": "2:15",
        "name_hr": "2:15",
        "name_fr": "2:15"
    },
    "2152^live_match^8^home": {
        "name_en": "home",
        "name_aa": "home",
        "name_de": "Heim",
        "name_nl": "Thuis",
        "name_pl": "home",
        "name_ro": "home",
        "name_ru": "home",
        "name_tr": "home",
        "name_se": "home",
        "name_el": "home",
        "name_es": "home",
        "name_hr": "Domači",
        "name_fr": "home"
    },
    "26^live_match^8^1:10": {
        "name_en": "1:10",
        "name_aa": "1:10",
        "name_de": "1:10",
        "name_nl": "1:10",
        "name_pl": "1:10",
        "name_ro": "1:10",
        "name_ru": "1:10",
        "name_tr": "1:10",
        "name_se": "1:10",
        "name_el": "1:10",
        "name_es": "1:10",
        "name_hr": "1:10",
        "name_fr": "1:10"
    },
    "2354^live_match^8^7:2": {
        "name_en": "7:2",
        "name_aa": "7:2",
        "name_de": "7:2",
        "name_nl": "7:2",
        "name_pl": "7:2",
        "name_ro": "7:2",
        "name_ru": "7:2",
        "name_tr": "7:2",
        "name_se": "7:2",
        "name_el": "7:2",
        "name_es": "7:2",
        "name_hr": "7:2",
        "name_fr": "7:2"
    },
    "2075^live_match^8^17:25": {
        "name_en": "17:25",
        "name_aa": "17:25",
        "name_de": "17:25",
        "name_nl": "17:25",
        "name_pl": "17:25",
        "name_ro": "17:25",
        "name_ru": "17:25",
        "name_tr": "17:25",
        "name_se": "17:25",
        "name_el": "17:25",
        "name_es": "17:25",
        "name_hr": "17:25",
        "name_fr": "17:25"
    },
    "2075^live_match^8^15:25_or_better": {
        "name_en": "15:25 or better",
        "name_aa": "15:25 or better",
        "name_de": "15:25 oder besser",
        "name_nl": "15:25 of beter",
        "name_pl": "15:25 or better",
        "name_ro": "15:25 or better",
        "name_ru": "15:25 or better",
        "name_tr": "15:25 or better",
        "name_se": "15:25 or better",
        "name_el": "15:25 or better",
        "name_es": "15:25 or better",
        "name_hr": "15:25 or better",
        "name_fr": "15:25 or better"
    },
    "2195^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "29^live_match^8^8:1": {
        "name_en": "8:1",
        "name_aa": "8:1",
        "name_de": "8:1",
        "name_nl": "8:1",
        "name_pl": "8:1",
        "name_ro": "8:1",
        "name_ru": "8:1",
        "name_tr": "8:1",
        "name_se": "8:1",
        "name_el": "8:1",
        "name_es": "8:1",
        "name_hr": "8:1",
        "name_fr": "8:1"
    },
    "2073^live_match^8^21:18": {
        "name_en": "21:18",
        "name_aa": "21:18",
        "name_de": "21:18",
        "name_nl": "21:18",
        "name_pl": "21:18",
        "name_ro": "21:18",
        "name_ru": "21:18",
        "name_tr": "21:18",
        "name_se": "21:18",
        "name_el": "21:18",
        "name_es": "21:18",
        "name_hr": "21:18",
        "name_fr": "21:18"
    },
    "35^live_match^8^under": {
        "name_en": "Under",
        "name_aa": "u",
        "name_de": "Unter",
        "name_nl": "u",
        "name_pl": "u",
        "name_ro": "u",
        "name_ru": "u",
        "name_tr": "Alt",
        "name_se": "u",
        "name_el": "u",
        "name_es": "u",
        "name_hr": "Manje",
        "name_fr": "m"
    },
    "1108^live_match^8^10:12": {
        "name_en": "10:12",
        "name_aa": "10:12",
        "name_de": "10:12",
        "name_nl": "10:12",
        "name_pl": "10:12",
        "name_ro": "10:12",
        "name_ru": "10:12",
        "name_tr": "10:12",
        "name_se": "10:12",
        "name_el": "10:12",
        "name_es": "10:12",
        "name_hr": "10:12",
        "name_fr": "10:12"
    },
    "2059^live_match^8^home_16+": {
        "name_en": "home 16+",
        "name_aa": "home 16+",
        "name_de": "Heim 16+",
        "name_nl": "thuis 16+",
        "name_pl": "home 16+",
        "name_ro": "home 16+",
        "name_ru": "home 16+",
        "name_tr": "home 16+",
        "name_se": "home 16+",
        "name_el": "home 16+",
        "name_es": "home 16+",
        "name_hr": "Domači 16+",
        "name_fr": "home 16+"
    },
    "29^live_match^8^1:5": {
        "name_en": "1:5",
        "name_aa": "1:5",
        "name_de": "1:5",
        "name_nl": "1:5",
        "name_pl": "1:5",
        "name_ro": "1:5",
        "name_ru": "1:5",
        "name_tr": "1:5",
        "name_se": "1:5",
        "name_el": "1:5",
        "name_es": "1:5",
        "name_hr": "1:5",
        "name_fr": "1:5"
    },
    "26^live_match^8^4:3": {
        "name_en": "4:3",
        "name_aa": "4:3",
        "name_de": "4:3",
        "name_nl": "4:3",
        "name_pl": "4:3",
        "name_ro": "4:3",
        "name_ru": "4:3",
        "name_tr": "4:3",
        "name_se": "4:3",
        "name_el": "4:3",
        "name_es": "4:3",
        "name_hr": "4:3",
        "name_fr": "4:3"
    },
    "295^live_match^8^under": {
        "name_en": "Under",
        "name_aa": "u",
        "name_de": "Unter",
        "name_nl": "u",
        "name_pl": "u",
        "name_ro": "u",
        "name_ru": "u",
        "name_tr": "Alt",
        "name_se": "u",
        "name_el": "u",
        "name_es": "u",
        "name_hr": "Manje",
        "name_fr": "m"
    },
    "73^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2146^live_match^8^home_2+": {
        "name_en": "home 2+",
        "name_aa": "home 2+",
        "name_de": "Heim 2+",
        "name_nl": "Thuis 2+",
        "name_pl": "home 2+",
        "name_ro": "home 2+",
        "name_ru": "home 2+",
        "name_tr": "home 2+",
        "name_se": "home 2+",
        "name_el": "home 2+",
        "name_es": "home 2+",
        "name_hr": "Domači 2+",
        "name_fr": "home 2+"
    },
    "2002^live_match^6^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2209^live_match^8^176-200": {
        "name_en": "176-200",
        "name_aa": "176-200",
        "name_de": "176-200",
        "name_nl": "176-200",
        "name_pl": "176-200",
        "name_ro": "176-200",
        "name_ru": "176-200",
        "name_tr": "176-200",
        "name_se": "176-200",
        "name_el": "176-200",
        "name_es": "176-200",
        "name_hr": "176-200",
        "name_fr": "176-200"
    },
    "2261^live_match^8^1_9+": {
        "name_en": "1 9+",
        "name_aa": "1 9+",
        "name_de": "1 9+",
        "name_nl": "1 9+",
        "name_pl": "1 9+",
        "name_ro": "1 9+",
        "name_ru": "1 9+",
        "name_tr": "1 9+",
        "name_se": "1 9+",
        "name_el": "1 9+",
        "name_es": "1 9+",
        "name_hr": "1 9+",
        "name_fr": "1 9+"
    },
    "1665^live_match^2^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2201^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "2073^live_match^8^18:25": {
        "name_en": "18:25",
        "name_aa": "18:25",
        "name_de": "18:25",
        "name_nl": "18:25",
        "name_pl": "18:25",
        "name_ro": "18:25",
        "name_ru": "18:25",
        "name_tr": "18:25",
        "name_se": "18:25",
        "name_el": "18:25",
        "name_es": "18:25",
        "name_hr": "18:25",
        "name_fr": "18:25"
    },
    "2074^live_match^8^15:8": {
        "name_en": "15:8",
        "name_aa": "15:8",
        "name_de": "15:8",
        "name_nl": "15:8",
        "name_pl": "15:8",
        "name_ro": "15:8",
        "name_ru": "15:8",
        "name_tr": "15:8",
        "name_se": "15:8",
        "name_el": "15:8",
        "name_es": "15:8",
        "name_hr": "15:8",
        "name_fr": "15:8"
    },
    "1108^live_match^8^1:10": {
        "name_en": "1:10",
        "name_aa": "1:10",
        "name_de": "1:10",
        "name_nl": "1:10",
        "name_pl": "1:10",
        "name_ro": "1:10",
        "name_ru": "1:10",
        "name_tr": "1:10",
        "name_se": "1:10",
        "name_el": "1:10",
        "name_es": "1:10",
        "name_hr": "1:10",
        "name_fr": "1:10"
    },
    "2118^live_match^6^x": {
        "name_en": "x",
        "name_aa": "x",
        "name_de": "x",
        "name_nl": "x",
        "name_pl": "x",
        "name_ro": "x",
        "name_ru": "x",
        "name_tr": "x",
        "name_se": "x",
        "name_el": "x",
        "name_es": "x",
        "name_hr": "x",
        "name_fr": "x"
    },
    "20^live_match^6^x": {
        "name_en": "x",
        "name_aa": "x",
        "name_de": "x",
        "name_nl": "x",
        "name_pl": "x",
        "name_ro": "x",
        "name_ru": "x",
        "name_tr": "x",
        "name_se": "x",
        "name_el": "x",
        "name_es": "x",
        "name_hr": "x",
        "name_fr": "x"
    },
    "2145^live_match^8^home_1-3": {
        "name_en": "home 1-3",
        "name_aa": "home 1-3",
        "name_de": "Heim 1-3",
        "name_nl": "thuis 1-3",
        "name_pl": "home 1-3",
        "name_ro": "home 1-3",
        "name_ru": "home 1-3",
        "name_tr": "home 1-3",
        "name_se": "home 1-3",
        "name_el": "home 1-3",
        "name_es": "home 1-3",
        "name_hr": "Domači 1-3",
        "name_fr": "home 1-3"
    },
    "26^live_match^8^0:21": {
        "name_en": "0:21",
        "name_aa": "0:21",
        "name_de": "0:21",
        "name_nl": "0:21",
        "name_pl": "0:21",
        "name_ro": "0:21",
        "name_ru": "0:21",
        "name_tr": "0:21",
        "name_se": "0:21",
        "name_el": "0:21",
        "name_es": "0:21",
        "name_hr": "0:21",
        "name_fr": "0:21"
    },
    "520^live_match^8^4:1": {
        "name_en": "4:1",
        "name_aa": "4:1",
        "name_de": "4:1",
        "name_nl": "4:1",
        "name_pl": "4:1",
        "name_ro": "4:1",
        "name_ru": "4:1",
        "name_tr": "4:1",
        "name_se": "4:1",
        "name_el": "4:1",
        "name_es": "4:1",
        "name_hr": "4:1",
        "name_fr": "4:1"
    },
    "2354^live_match^8^12:9": {
        "name_en": "12:9",
        "name_aa": "12:9",
        "name_de": "12:9",
        "name_nl": "12:9",
        "name_pl": "12:9",
        "name_ro": "12:9",
        "name_ru": "12:9",
        "name_tr": "12:9",
        "name_se": "12:9",
        "name_el": "12:9",
        "name_es": "12:9",
        "name_hr": "12:9",
        "name_fr": "12:9"
    },
    "2316^live_match^8^home_by_any_other": {
        "name_en": "home by Any Other",
        "name_aa": "home by Any Other",
        "name_de": "home by Any Other",
        "name_nl": "home by Any Other",
        "name_pl": "home by Any Other",
        "name_ro": "home by Any Other",
        "name_ru": "home by Any Other",
        "name_tr": "home by Any Other",
        "name_se": "home by Any Other",
        "name_el": "home by Any Other",
        "name_es": "home by Any Other",
        "name_hr": "Domači po bilo Ostali",
        "name_fr": "home by Any Other"
    },
    "2354^live_match^8^2:9": {
        "name_en": "2:9",
        "name_aa": "2:9",
        "name_de": "2:9",
        "name_nl": "2:9",
        "name_pl": "2:9",
        "name_ro": "2:9",
        "name_ru": "2:9",
        "name_tr": "2:9",
        "name_se": "2:9",
        "name_el": "2:9",
        "name_es": "2:9",
        "name_hr": "2:9",
        "name_fr": "2:9"
    },
    "2354^live_match^8^0:0": {
        "name_en": "0:0",
        "name_aa": "0:0",
        "name_de": "0:0",
        "name_nl": "0:0",
        "name_pl": "0:0",
        "name_ro": "0:0",
        "name_ru": "0:0",
        "name_tr": "0:0",
        "name_se": "0:0",
        "name_el": "0:0",
        "name_es": "0:0",
        "name_hr": "0:0",
        "name_fr": "0:0"
    },
    "6^live_match^6^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2153^live_match^8^away_2": {
        "name_en": "away 2",
        "name_aa": "away 2",
        "name_de": "Auswärt 2",
        "name_nl": "Weg 2",
        "name_pl": "away 2",
        "name_ro": "away 2",
        "name_ru": "away 2",
        "name_tr": "away 2",
        "name_se": "away 2",
        "name_el": "away 2",
        "name_es": "away 2",
        "name_hr": "Gostujući 2",
        "name_fr": "away 2"
    },
    "2354^live_match^8^11:11": {
        "name_en": "11:11",
        "name_aa": "11:11",
        "name_de": "11:11",
        "name_nl": "11:11",
        "name_pl": "11:11",
        "name_ro": "11:11",
        "name_ru": "11:11",
        "name_tr": "11:11",
        "name_se": "11:11",
        "name_el": "11:11",
        "name_es": "11:11",
        "name_hr": "11:11",
        "name_fr": "11:11"
    },
    "2154^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2261^live_match^8^8:2": {
        "name_en": "8:2",
        "name_aa": "8:2",
        "name_de": "8:2",
        "name_nl": "8:2",
        "name_pl": "8:2",
        "name_ro": "8:2",
        "name_ru": "8:2",
        "name_tr": "8:2",
        "name_se": "8:2",
        "name_el": "8:2",
        "name_es": "8:2",
        "name_hr": "8:2",
        "name_fr": "8:2"
    },
    "2172^live_match^8^ha": {
        "name_en": "HA",
        "name_aa": "HA",
        "name_de": "HA",
        "name_nl": "HA",
        "name_pl": "HA",
        "name_ro": "HA",
        "name_ru": "HA",
        "name_tr": "HA",
        "name_se": "HA",
        "name_el": "HA",
        "name_es": "HA",
        "name_hr": "HA",
        "name_fr": "HA"
    },
    "1108^live_match^8^2:1": {
        "name_en": "2:1",
        "name_aa": "2:1",
        "name_de": "2:1",
        "name_nl": "2:1",
        "name_pl": "2:1",
        "name_ro": "2:1",
        "name_ru": "2:1",
        "name_tr": "2:1",
        "name_se": "2:1",
        "name_el": "2:1",
        "name_es": "2:1",
        "name_hr": "2:1",
        "name_fr": "2:1"
    },
    "2354^live_match^8^10:6": {
        "name_en": "10:6",
        "name_aa": "10:6",
        "name_de": "10:6",
        "name_nl": "10:6",
        "name_pl": "10:6",
        "name_ro": "10:6",
        "name_ru": "10:6",
        "name_tr": "10:6",
        "name_se": "10:6",
        "name_el": "10:6",
        "name_es": "10:6",
        "name_hr": "10:6",
        "name_fr": "10:6"
    },
    "2354^live_match^8^2:8": {
        "name_en": "2:8",
        "name_aa": "2:8",
        "name_de": "2:8",
        "name_nl": "2:8",
        "name_pl": "2:8",
        "name_ro": "2:8",
        "name_ru": "2:8",
        "name_tr": "2:8",
        "name_se": "2:8",
        "name_el": "2:8",
        "name_es": "2:8",
        "name_hr": "2:8",
        "name_fr": "2:8"
    },
    "2315^live_match^8^5:1": {
        "name_en": "5:1",
        "name_aa": "5:1",
        "name_de": "5:1",
        "name_nl": "5:1",
        "name_pl": "5:1",
        "name_ro": "5:1",
        "name_ru": "5:1",
        "name_tr": "5:1",
        "name_se": "5:1",
        "name_el": "5:1",
        "name_es": "5:1",
        "name_hr": "5:1",
        "name_fr": "5:1"
    },
    "29^live_match^8^7:4": {
        "name_en": "7:4",
        "name_aa": "7:4",
        "name_de": "7:4",
        "name_nl": "7:4",
        "name_pl": "7:4",
        "name_ro": "7:4",
        "name_ru": "7:4",
        "name_tr": "7:4",
        "name_se": "7:4",
        "name_el": "7:4",
        "name_es": "7:4",
        "name_hr": "7:4",
        "name_fr": "7:4"
    },
    "26^live_match^8^2:4": {
        "name_en": "2:4",
        "name_aa": "2:4",
        "name_de": "2:4",
        "name_nl": "2:4",
        "name_pl": "2:4",
        "name_ro": "2:4",
        "name_ru": "2:4",
        "name_tr": "2:4",
        "name_se": "2:4",
        "name_el": "2:4",
        "name_es": "2:4",
        "name_hr": "2:4",
        "name_fr": "2:4"
    },
    "2063^live_match^8^home_21+": {
        "name_en": "home 21+",
        "name_aa": "home 21+",
        "name_de": "Heim 21+",
        "name_nl": "thuis 21+",
        "name_pl": "home 21+",
        "name_ro": "home 21+",
        "name_ru": "home 21+",
        "name_tr": "home 21+",
        "name_se": "home 21+",
        "name_el": "home 21+",
        "name_es": "home 21+",
        "name_hr": "Domači 21+",
        "name_fr": "home 21+"
    },
    "1108^live_match^8^9:4": {
        "name_en": "9:4",
        "name_aa": "9:4",
        "name_de": "9:4",
        "name_nl": "9:4",
        "name_pl": "9:4",
        "name_ro": "9:4",
        "name_ru": "9:4",
        "name_tr": "9:4",
        "name_se": "9:4",
        "name_el": "9:4",
        "name_es": "9:4",
        "name_hr": "9:4",
        "name_fr": "9:4"
    },
    "1156^live_match^8^3:5": {
        "name_en": "3:5",
        "name_aa": "3:5",
        "name_de": "3:5",
        "name_nl": "3:5",
        "name_pl": "3:5",
        "name_ro": "3:5",
        "name_ru": "3:5",
        "name_tr": "3:5",
        "name_se": "3:5",
        "name_el": "3:5",
        "name_es": "3:5",
        "name_hr": "3:5",
        "name_fr": "3:5"
    },
    "26^live_match^8^1:22": {
        "name_en": "1:22",
        "name_aa": "1:22",
        "name_de": "1:22",
        "name_nl": "1:22",
        "name_pl": "1:22",
        "name_ro": "1:22",
        "name_ru": "1:22",
        "name_tr": "1:22",
        "name_se": "1:22",
        "name_el": "1:22",
        "name_es": "1:22",
        "name_hr": "1:22",
        "name_fr": "1:22"
    },
    "1108^live_match^8^0:6": {
        "name_en": "0:6",
        "name_aa": "0:6",
        "name_de": "0:6",
        "name_nl": "0:6",
        "name_pl": "0:6",
        "name_ro": "0:6",
        "name_ru": "0:6",
        "name_tr": "0:6",
        "name_se": "0:6",
        "name_el": "0:6",
        "name_es": "0:6",
        "name_hr": "0:6",
        "name_fr": "0:6"
    },
    "2308^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "1673^live_match^8^no": {
        "name_en": "no",
        "name_aa": "no",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "no",
        "name_ro": "no",
        "name_ru": "no",
        "name_tr": "no",
        "name_se": "no",
        "name_el": "no",
        "name_es": "no",
        "name_hr": "Ne",
        "name_fr": "no"
    },
    "2353^live_match^8^18+": {
        "name_en": "away 18+",
        "name_aa": "away 18+",
        "name_de": "Auswärt 18+",
        "name_nl": "Weg 18+",
        "name_pl": "away 18+",
        "name_ro": "away 18+",
        "name_ru": "away 18+",
        "name_tr": "away 18+",
        "name_se": "away 18+",
        "name_el": "away 18+",
        "name_es": "away 18+",
        "name_hr": "Gostujuči 18+",
        "name_fr": "away 18+"
    },
    "237^live_match^8^ad": {
        "name_en": "AD",
        "name_aa": "AD",
        "name_de": "A-U",
        "name_nl": "AD",
        "name_pl": "AD",
        "name_ro": "AD",
        "name_ru": "AD",
        "name_tr": "DB",
        "name_se": "AD",
        "name_el": "AD",
        "name_es": "AD",
        "name_hr": "2-X",
        "name_fr": "EN"
    },
    "1108^live_match^8^3:7": {
        "name_en": "3:7",
        "name_aa": "3:7",
        "name_de": "3:7",
        "name_nl": "3:7",
        "name_pl": "3:7",
        "name_ro": "3:7",
        "name_ru": "3:7",
        "name_tr": "3:7",
        "name_se": "3:7",
        "name_el": "3:7",
        "name_es": "3:7",
        "name_hr": "3:7",
        "name_fr": "3:7"
    },
    "26^live_match^8^1:11": {
        "name_en": "1:11",
        "name_aa": "1:11",
        "name_de": "1:11",
        "name_nl": "1:11",
        "name_pl": "1:11",
        "name_ro": "1:11",
        "name_ru": "1:11",
        "name_tr": "1:11",
        "name_se": "1:11",
        "name_el": "1:11",
        "name_es": "1:11",
        "name_hr": "1:11",
        "name_fr": "1:11"
    },
    "345^live_match^8^even": {
        "name_en": "even",
        "name_aa": "even",
        "name_de": "Gerade",
        "name_nl": "even",
        "name_pl": "even",
        "name_ro": "even",
        "name_ru": "even",
        "name_tr": "çift",
        "name_se": "even",
        "name_el": "even",
        "name_es": "even",
        "name_hr": "Paran",
        "name_fr": "pair"
    },
    "2137^live_match^8^odd": {
        "name_en": "odd",
        "name_aa": "odd",
        "name_de": "Ungerade",
        "name_nl": "Oneven",
        "name_pl": "odd",
        "name_ro": "odd",
        "name_ru": "odd",
        "name_tr": "odd",
        "name_se": "odd",
        "name_el": "odd",
        "name_es": "odd",
        "name_hr": "Neparan",
        "name_fr": "odd"
    },
    "2129^live_match^8^2:0": {
        "name_en": "2:0",
        "name_aa": "2:0",
        "name_de": "2:0",
        "name_nl": "2:0",
        "name_pl": "2:0",
        "name_ro": "2:0",
        "name_ru": "2:0",
        "name_tr": "2:0",
        "name_se": "2:0",
        "name_el": "2:0",
        "name_es": "2:0",
        "name_hr": "2:0",
        "name_fr": "2:0"
    },
    "2145^live_match^8^away_10-12": {
        "name_en": "away 10-12",
        "name_aa": "away 10-12",
        "name_de": "Auswärt 10-12",
        "name_nl": "Weg 10-12",
        "name_pl": "away 10-12",
        "name_ro": "away 10-12",
        "name_ru": "away 10-12",
        "name_tr": "away 10-12",
        "name_se": "away 10-12",
        "name_el": "away 10-12",
        "name_es": "away 10-12",
        "name_hr": "Gostujuči 10-12",
        "name_fr": "away 10-12"
    },
    "26^live_match^8^5:10": {
        "name_en": "5:10",
        "name_aa": "5:10",
        "name_de": "5:10",
        "name_nl": "5:10",
        "name_pl": "5:10",
        "name_ro": "5:10",
        "name_ru": "5:10",
        "name_tr": "5:10",
        "name_se": "5:10",
        "name_el": "5:10",
        "name_es": "5:10",
        "name_hr": "5:10",
        "name_fr": "5:10"
    },
    "2134^live_match^8^yes": {
        "name_en": "yes",
        "name_aa": "yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "yes",
        "name_ro": "yes",
        "name_ru": "yes",
        "name_tr": "yes",
        "name_se": "yes",
        "name_el": "yes",
        "name_es": "yes",
        "name_hr": "Da",
        "name_fr": "yes"
    },
    "2168^live_match^8^1/1": {
        "name_en": "1/1",
        "name_aa": "1/1",
        "name_de": "1/1",
        "name_nl": "1/1",
        "name_pl": "1/1",
        "name_ro": "1/1",
        "name_ru": "1/1",
        "name_tr": "1/1",
        "name_se": "1/1",
        "name_el": "1/1",
        "name_es": "1/1",
        "name_hr": "1/1",
        "name_fr": "1/1"
    },
    "2354^live_match^8^3:2": {
        "name_en": "3:2",
        "name_aa": "3:2",
        "name_de": "3:2",
        "name_nl": "3:2",
        "name_pl": "3:2",
        "name_ro": "3:2",
        "name_ru": "3:2",
        "name_tr": "3:2",
        "name_se": "3:2",
        "name_el": "3:2",
        "name_es": "3:2",
        "name_hr": "3:2",
        "name_fr": "3:2"
    },
    "22^live_match^6^x": {
        "name_en": "x",
        "name_aa": "x",
        "name_de": "x",
        "name_nl": "x",
        "name_pl": "x",
        "name_ro": "x",
        "name_ru": "x",
        "name_tr": "x",
        "name_se": "x",
        "name_el": "x",
        "name_es": "x",
        "name_hr": "x",
        "name_fr": "x"
    },
    "2081^live_match^8^7:6": {
        "name_en": "7:6",
        "name_aa": "7:6",
        "name_de": "7:6",
        "name_nl": "7:6",
        "name_pl": "7:6",
        "name_ro": "7:6",
        "name_ru": "7:6",
        "name_tr": "7:6",
        "name_se": "7:6",
        "name_el": "7:6",
        "name_es": "7:6",
        "name_hr": "7:6",
        "name_fr": "7:6"
    },
    "2130^live_match^8^0:3": {
        "name_en": "0:3",
        "name_aa": "0:3",
        "name_de": "0:3",
        "name_nl": "0:3",
        "name_pl": "0:3",
        "name_ro": "0:3",
        "name_ru": "0:3",
        "name_tr": "0:3",
        "name_se": "0:3",
        "name_el": "0:3",
        "name_es": "0:3",
        "name_hr": "0:3",
        "name_fr": "0:3"
    },
    "29^live_match^8^8:3": {
        "name_en": "8:3",
        "name_aa": "8:3",
        "name_de": "8:3",
        "name_nl": "8:3",
        "name_pl": "8:3",
        "name_ro": "8:3",
        "name_ru": "8:3",
        "name_tr": "8:3",
        "name_se": "8:3",
        "name_el": "8:3",
        "name_es": "8:3",
        "name_hr": "8:3",
        "name_fr": "8:3"
    },
    "26^live_match^8^1:4": {
        "name_en": "1:4",
        "name_aa": "1:4",
        "name_de": "1:4",
        "name_nl": "1:4",
        "name_pl": "1:4",
        "name_ro": "1:4",
        "name_ru": "1:4",
        "name_tr": "1:4",
        "name_se": "1:4",
        "name_el": "1:4",
        "name_es": "1:4",
        "name_hr": "1:4",
        "name_fr": "1:4"
    },
    "29^live_match^8^3:5": {
        "name_en": "3:5",
        "name_aa": "3:5",
        "name_de": "3:5",
        "name_nl": "3:5",
        "name_pl": "3:5",
        "name_ro": "3:5",
        "name_ru": "3:5",
        "name_tr": "3:5",
        "name_se": "3:5",
        "name_el": "3:5",
        "name_es": "3:5",
        "name_hr": "3:5",
        "name_fr": "3:5"
    },
    "26^live_match^8^1:14": {
        "name_en": "1:14",
        "name_aa": "1:14",
        "name_de": "1:14",
        "name_nl": "1:14",
        "name_pl": "1:14",
        "name_ro": "1:14",
        "name_ru": "1:14",
        "name_tr": "1:14",
        "name_se": "1:14",
        "name_el": "1:14",
        "name_es": "1:14",
        "name_hr": "1:14",
        "name_fr": "1:14"
    },
    "2056^live_match^8^12": {
        "name_en": "12",
        "name_aa": "12",
        "name_de": "12",
        "name_nl": "12",
        "name_pl": "12",
        "name_ro": "12",
        "name_ru": "12",
        "name_tr": "12",
        "name_se": "12",
        "name_el": "12",
        "name_es": "12",
        "name_hr": "12",
        "name_fr": "12"
    },
    "2354^live_match^8^0:5": {
        "name_en": "0:5",
        "name_aa": "0:5",
        "name_de": "0:5",
        "name_nl": "0:5",
        "name_pl": "0:5",
        "name_ro": "0:5",
        "name_ru": "0:5",
        "name_tr": "0:5",
        "name_se": "0:5",
        "name_el": "0:5",
        "name_es": "0:5",
        "name_hr": "0:5",
        "name_fr": "0:5"
    },
    "1108^live_match^8^3:5": {
        "name_en": "3:5",
        "name_aa": "3:5",
        "name_de": "3:5",
        "name_nl": "3:5",
        "name_pl": "3:5",
        "name_ro": "3:5",
        "name_ru": "3:5",
        "name_tr": "3:5",
        "name_se": "3:5",
        "name_el": "3:5",
        "name_es": "3:5",
        "name_hr": "3:5",
        "name_fr": "3:5"
    },
    "2304^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2328^live_match^8^to_win_to_0,_15_or_30": {
        "name_en": "To Win to 0, 15 or 30",
        "name_aa": "To Win to 0, 15 or 30",
        "name_de": "To Win to 0, 15 or 30",
        "name_nl": "To Win to 0, 15 or 30",
        "name_pl": "To Win to 0, 15 or 30",
        "name_ro": "To Win to 0, 15 or 30",
        "name_ru": "To Win to 0, 15 or 30",
        "name_tr": "To Win to 0, 15 or 30",
        "name_se": "To Win to 0, 15 or 30",
        "name_el": "To Win to 0, 15 or 30",
        "name_es": "To Win to 0, 15 or 30",
        "name_hr": "To Win to 0, 15 or 30",
        "name_fr": "To Win to 0, 15 or 30"
    },
    "26^live_match^8^10:1": {
        "name_en": "10:1",
        "name_aa": "10:1",
        "name_de": "10:1",
        "name_nl": "10:1",
        "name_pl": "10:1",
        "name_ro": "10:1",
        "name_ru": "10:1",
        "name_tr": "10:1",
        "name_se": "10:1",
        "name_el": "10:1",
        "name_es": "10:1",
        "name_hr": "10:1",
        "name_fr": "10:1"
    },
    "2004^live_match^8^no_goal": {
        "name_en": "No Goal",
        "name_aa": "No Goal",
        "name_de": "Kein Tor",
        "name_nl": "Geen doelpunt",
        "name_pl": "No Goal",
        "name_ro": "No Goal",
        "name_ru": "No Goal",
        "name_tr": "No Goal",
        "name_se": "No Goal",
        "name_el": "No Goal",
        "name_es": "No Goal",
        "name_hr": "Ne Gola",
        "name_fr": "No Goal"
    },
    "2074^live_match^8^6:15": {
        "name_en": "6:15",
        "name_aa": "6:15",
        "name_de": "6:15",
        "name_nl": "6:15",
        "name_pl": "6:15",
        "name_ro": "6:15",
        "name_ru": "6:15",
        "name_tr": "6:15",
        "name_se": "6:15",
        "name_el": "6:15",
        "name_es": "6:15",
        "name_hr": "6:15",
        "name_fr": "6:15"
    },
    "26^live_match^8^20:2": {
        "name_en": "20:2",
        "name_aa": "20:2",
        "name_de": "20:2",
        "name_nl": "20:2",
        "name_pl": "20:2",
        "name_ro": "20:2",
        "name_ru": "20:2",
        "name_tr": "20:2",
        "name_se": "20:2",
        "name_el": "20:2",
        "name_es": "20:2",
        "name_hr": "20:2",
        "name_fr": "20:2"
    },
    "2354^live_match^8^5:5": {
        "name_en": "5:5",
        "name_aa": "5:5",
        "name_de": "5:5",
        "name_nl": "5:5",
        "name_pl": "5:5",
        "name_ro": "5:5",
        "name_ru": "5:5",
        "name_tr": "5:5",
        "name_se": "5:5",
        "name_el": "5:5",
        "name_es": "5:5",
        "name_hr": "5:5",
        "name_fr": "5:5"
    },
    "116^live_match^8^0:6": {
        "name_en": "0:6",
        "name_aa": "0:6",
        "name_de": "0:6",
        "name_nl": "0:6",
        "name_pl": "0:6",
        "name_ro": "0:6",
        "name_ru": "0:6",
        "name_tr": "0:6",
        "name_se": "0:6",
        "name_el": "0:6",
        "name_es": "0:6",
        "name_hr": "0:6",
        "name_fr": "0:6"
    },
    "26^live_match^8^8:1": {
        "name_en": "8:1",
        "name_aa": "8:1",
        "name_de": "8:1",
        "name_nl": "8:1",
        "name_pl": "8:1",
        "name_ro": "8:1",
        "name_ru": "8:1",
        "name_tr": "8:1",
        "name_se": "8:1",
        "name_el": "8:1",
        "name_es": "8:1",
        "name_hr": "8:1",
        "name_fr": "8:1"
    },
    "49^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2114^live_match^8^0:40": {
        "name_en": "0:40",
        "name_aa": "0:40",
        "name_de": "0:40",
        "name_nl": "0:40",
        "name_pl": "0:40",
        "name_ro": "0:40",
        "name_ru": "0:40",
        "name_tr": "0:40",
        "name_se": "0:40",
        "name_el": "0:40",
        "name_es": "0:40",
        "name_hr": "0:40",
        "name_fr": "0:40"
    },
    "2073^live_match^8^21:14": {
        "name_en": "21:14",
        "name_aa": "21:14",
        "name_de": "21:14",
        "name_nl": "21:14",
        "name_pl": "21:14",
        "name_ro": "21:14",
        "name_ru": "21:14",
        "name_tr": "21:14",
        "name_se": "21:14",
        "name_el": "21:14",
        "name_es": "21:14",
        "name_hr": "21:14",
        "name_fr": "21:14"
    },
    "64^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2229^live_match^6^tie": {
        "name_en": "Tie",
        "name_aa": "Tie",
        "name_de": "Unentschieden",
        "name_nl": "Gelijkspel",
        "name_pl": "Tie",
        "name_ro": "Tie",
        "name_ru": "Tie",
        "name_tr": "Tie",
        "name_se": "Tie",
        "name_el": "Tie",
        "name_es": "Tie",
        "name_hr": "Tie",
        "name_fr": "Tie"
    },
    "2055^live_match^8^x2": {
        "name_en": "X2",
        "name_aa": "X2",
        "name_de": "X2",
        "name_nl": "X2",
        "name_pl": "X2",
        "name_ro": "X2",
        "name_ru": "X2",
        "name_tr": "X2",
        "name_se": "X2",
        "name_el": "X2",
        "name_es": "X2",
        "name_hr": "X2",
        "name_fr": "X2"
    },
    "26^live_match^8^2:6": {
        "name_en": "2:6",
        "name_aa": "2:6",
        "name_de": "2:6",
        "name_nl": "2:6",
        "name_pl": "2:6",
        "name_ro": "2:6",
        "name_ru": "2:6",
        "name_tr": "2:6",
        "name_se": "2:6",
        "name_el": "2:6",
        "name_es": "2:6",
        "name_hr": "2:6",
        "name_fr": "2:6"
    },
    "54^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2032^live_match^8^draw_and_over": {
        "name_en": "draw and over",
        "name_aa": "draw and over",
        "name_de": "Unentschieden und Über",
        "name_nl": "Gelijkspel and Meer dan",
        "name_pl": "draw and over",
        "name_ro": "draw and over",
        "name_ru": "draw and over",
        "name_tr": "draw and over",
        "name_se": "draw and over",
        "name_el": "draw and over",
        "name_es": "draw and over",
        "name_hr": "Neriješeno i više",
        "name_fr": "draw and over"
    },
    "26^live_match^8^0:3": {
        "name_en": "0:3",
        "name_aa": "0:3",
        "name_de": "0:3",
        "name_nl": "0:3",
        "name_pl": "0:3",
        "name_ro": "0:3",
        "name_ru": "0:3",
        "name_tr": "0:3",
        "name_se": "0:3",
        "name_el": "0:3",
        "name_es": "0:3",
        "name_hr": "0:3",
        "name_fr": "0:3"
    },
    "2174^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "26^live_match^8^9:1": {
        "name_en": "9:1",
        "name_aa": "9:1",
        "name_de": "9:1",
        "name_nl": "9:1",
        "name_pl": "9:1",
        "name_ro": "9:1",
        "name_ru": "9:1",
        "name_tr": "9:1",
        "name_se": "9:1",
        "name_el": "9:1",
        "name_es": "9:1",
        "name_hr": "9:1",
        "name_fr": "9:1"
    },
    "1664^live_match^2^x": {
        "name_en": "x",
        "name_aa": "x",
        "name_de": "x",
        "name_nl": "x",
        "name_pl": "x",
        "name_ro": "x",
        "name_ru": "x",
        "name_tr": "x",
        "name_se": "x",
        "name_el": "x",
        "name_es": "x",
        "name_hr": "x",
        "name_fr": "x"
    },
    "2095^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2259^live_match^8^home_and_under": {
        "name_en": "home and under",
        "name_aa": "home and under",
        "name_de": "Heim und Unter",
        "name_nl": "Thuis and Minder dan",
        "name_pl": "home and under",
        "name_ro": "home and under",
        "name_ru": "home and under",
        "name_tr": "home and under",
        "name_se": "home and under",
        "name_el": "home and under",
        "name_es": "home and under",
        "name_hr": "Domači i manje",
        "name_fr": "home and under"
    },
    "28^live_match^8^3+": {
        "name_en": "3+",
        "name_aa": "3+",
        "name_de": "3+",
        "name_nl": "3+",
        "name_pl": "3+",
        "name_ro": "3+",
        "name_ru": "3+",
        "name_tr": "3+",
        "name_se": "3+",
        "name_el": "3+",
        "name_es": "3+",
        "name_hr": "3+",
        "name_fr": "3+"
    },
    "287^live_match^8^12": {
        "name_en": "12",
        "name_aa": "12",
        "name_de": "12",
        "name_nl": "12",
        "name_pl": "12",
        "name_ro": "12",
        "name_ru": "12",
        "name_tr": "12",
        "name_se": "12",
        "name_el": "12",
        "name_es": "12",
        "name_hr": "12",
        "name_fr": "12"
    },
    "1661^live_match^2^x": {
        "name_en": "x",
        "name_aa": "x",
        "name_de": "x",
        "name_nl": "x",
        "name_pl": "x",
        "name_ro": "x",
        "name_ru": "x",
        "name_tr": "x",
        "name_se": "x",
        "name_el": "x",
        "name_es": "x",
        "name_hr": "x",
        "name_fr": "x"
    },
    "2125^live_match^8^home": {
        "name_en": "home",
        "name_aa": "home",
        "name_de": "Heim",
        "name_nl": "Thuis",
        "name_pl": "home",
        "name_ro": "home",
        "name_ru": "home",
        "name_tr": "home",
        "name_se": "home",
        "name_el": "home",
        "name_es": "home",
        "name_hr": "Domači",
        "name_fr": "home"
    },
    "1108^live_match^8^11:2": {
        "name_en": "11:2",
        "name_aa": "11:2",
        "name_de": "11:2",
        "name_nl": "11:2",
        "name_pl": "11:2",
        "name_ro": "11:2",
        "name_ru": "11:2",
        "name_tr": "11:2",
        "name_se": "11:2",
        "name_el": "11:2",
        "name_es": "11:2",
        "name_hr": "11:2",
        "name_fr": "11:2"
    },
    "10^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2354^live_match^8^7:9": {
        "name_en": "7:9",
        "name_aa": "7:9",
        "name_de": "7:9",
        "name_nl": "7:9",
        "name_pl": "7:9",
        "name_ro": "7:9",
        "name_ru": "7:9",
        "name_tr": "7:9",
        "name_se": "7:9",
        "name_el": "7:9",
        "name_es": "7:9",
        "name_hr": "7:9",
        "name_fr": "7:9"
    },
    "2030^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "36^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2074^live_match^8^15:11": {
        "name_en": "15:11",
        "name_aa": "15:11",
        "name_de": "15:11",
        "name_nl": "15:11",
        "name_pl": "15:11",
        "name_ro": "15:11",
        "name_ru": "15:11",
        "name_tr": "15:11",
        "name_se": "15:11",
        "name_el": "15:11",
        "name_es": "15:11",
        "name_hr": "15:11",
        "name_fr": "15:11"
    },
    "2088^live_match^8^5:1": {
        "name_en": "5:1",
        "name_aa": "5:1",
        "name_de": "5:1",
        "name_nl": "5:1",
        "name_pl": "5:1",
        "name_ro": "5:1",
        "name_ru": "5:1",
        "name_tr": "5:1",
        "name_se": "5:1",
        "name_el": "5:1",
        "name_es": "5:1",
        "name_hr": "5:1",
        "name_fr": "5:1"
    },
    "2212^live_match^8^away_37+": {
        "name_en": "away 37+",
        "name_aa": "away 37+",
        "name_de": "Auswärt 37+",
        "name_nl": "Weg 37+",
        "name_pl": "away 37+",
        "name_ro": "away 37+",
        "name_ru": "away 37+",
        "name_tr": "away 37+",
        "name_se": "away 37+",
        "name_el": "away 37+",
        "name_es": "away 37+",
        "name_hr": "Gostujuči 37+",
        "name_fr": "away 37+"
    },
    "2061^live_match^8^away_7-8": {
        "name_en": "away 7-8",
        "name_aa": "away 7-8",
        "name_de": "Auswärt 7-8",
        "name_nl": "Weg 7-8",
        "name_pl": "away 7-8",
        "name_ro": "away 7-8",
        "name_ru": "away 7-8",
        "name_tr": "away 7-8",
        "name_se": "away 7-8",
        "name_el": "away 7-8",
        "name_es": "away 7-8",
        "name_hr": "Gostujuči 7-8",
        "name_fr": "away 7-8"
    },
    "2084^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2038^live_match^4^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "26^live_match^8^3:1": {
        "name_en": "3:1",
        "name_aa": "3:1",
        "name_de": "3:1",
        "name_nl": "3:1",
        "name_pl": "3:1",
        "name_ro": "3:1",
        "name_ru": "3:1",
        "name_tr": "3:1",
        "name_se": "3:1",
        "name_el": "3:1",
        "name_es": "3:1",
        "name_hr": "3:1",
        "name_fr": "3:1"
    },
    "26^live_match^8^8:7": {
        "name_en": "8:7",
        "name_aa": "8:7",
        "name_de": "8:7",
        "name_nl": "8:7",
        "name_pl": "8:7",
        "name_ro": "8:7",
        "name_ru": "8:7",
        "name_tr": "8:7",
        "name_se": "8:7",
        "name_el": "8:7",
        "name_es": "8:7",
        "name_hr": "8:7",
        "name_fr": "8:7"
    },
    "2074^live_match^8^15:09": {
        "name_en": "15:09",
        "name_aa": "15:09",
        "name_de": "15:09",
        "name_nl": "15:09",
        "name_pl": "15:09",
        "name_ro": "15:09",
        "name_ru": "15:09",
        "name_tr": "15:09",
        "name_se": "15:09",
        "name_el": "15:09",
        "name_es": "15:09",
        "name_hr": "15:09",
        "name_fr": "15:09"
    },
    "2344^live_match^8^no": {
        "name_en": "no",
        "name_aa": "no",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "no",
        "name_ro": "no",
        "name_ru": "no",
        "name_tr": "no",
        "name_se": "no",
        "name_el": "no",
        "name_es": "no",
        "name_hr": "Ne",
        "name_fr": "no"
    },
    "2210^live_match^8^10-11": {
        "name_en": "home 10-11",
        "name_aa": "home 10-11",
        "name_de": "Heim 10-11",
        "name_nl": "Thuis 10-11",
        "name_pl": "home 10-11",
        "name_ro": "home 10-11",
        "name_ru": "home 10-11",
        "name_tr": "home 10-11",
        "name_se": "home 10-11",
        "name_el": "home 10-11",
        "name_es": "home 10-11",
        "name_hr": "Domači 10-11",
        "name_fr": "home 10-11"
    },
    "2062^live_match^8^home_7-8": {
        "name_en": "home 7-8",
        "name_aa": "home 7-8",
        "name_de": "Heim 7-8",
        "name_nl": "thuis 7-8",
        "name_pl": "home 7-8",
        "name_ro": "home 7-8",
        "name_ru": "home 7-8",
        "name_tr": "home 7-8",
        "name_se": "home 7-8",
        "name_el": "home 7-8",
        "name_es": "home 7-8",
        "name_hr": "Domači 7-8",
        "name_fr": "home 7-8"
    },
    "2354^live_match^8^0:9": {
        "name_en": "0:9",
        "name_aa": "0:9",
        "name_de": "0:9",
        "name_nl": "0:9",
        "name_pl": "0:9",
        "name_ro": "0:9",
        "name_ru": "0:9",
        "name_tr": "0:9",
        "name_se": "0:9",
        "name_el": "0:9",
        "name_es": "0:9",
        "name_hr": "0:9",
        "name_fr": "0:9"
    },
    "2354^live_match^8^3:4": {
        "name_en": "3:4",
        "name_aa": "3:4",
        "name_de": "3:4",
        "name_nl": "3:4",
        "name_pl": "3:4",
        "name_ro": "3:4",
        "name_ru": "3:4",
        "name_tr": "3:4",
        "name_se": "3:4",
        "name_el": "3:4",
        "name_es": "3:4",
        "name_hr": "3:4",
        "name_fr": "3:4"
    },
    "2190^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "2135^live_match^8^no": {
        "name_en": "no",
        "name_aa": "no",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "no",
        "name_ro": "no",
        "name_ru": "no",
        "name_tr": "no",
        "name_se": "no",
        "name_el": "no",
        "name_es": "no",
        "name_hr": "Ne",
        "name_fr": "no"
    },
    "26^live_match^8^1:20": {
        "name_en": "1:20",
        "name_aa": "1:20",
        "name_de": "1:20",
        "name_nl": "1:20",
        "name_pl": "1:20",
        "name_ro": "1:20",
        "name_ru": "1:20",
        "name_tr": "1:20",
        "name_se": "1:20",
        "name_el": "1:20",
        "name_es": "1:20",
        "name_hr": "1:20",
        "name_fr": "1:20"
    },
    "2246^live_match^8^home": {
        "name_en": "home",
        "name_aa": "home",
        "name_de": "Heim",
        "name_nl": "Thuis",
        "name_pl": "home",
        "name_ro": "home",
        "name_ru": "home",
        "name_tr": "home",
        "name_se": "home",
        "name_el": "home",
        "name_es": "home",
        "name_hr": "Domači",
        "name_fr": "home"
    },
    "2354^live_match^8^10:10": {
        "name_en": "10:10",
        "name_aa": "10:10",
        "name_de": "10:10",
        "name_nl": "10:10",
        "name_pl": "10:10",
        "name_ro": "10:10",
        "name_ru": "10:10",
        "name_tr": "10:10",
        "name_se": "10:10",
        "name_el": "10:10",
        "name_es": "10:10",
        "name_hr": "10:10",
        "name_fr": "10:10"
    },
    "2261^live_match^8^7:6": {
        "name_en": "7:6",
        "name_aa": "7:6",
        "name_de": "7:6",
        "name_nl": "7:6",
        "name_pl": "7:6",
        "name_ro": "7:6",
        "name_ru": "7:6",
        "name_tr": "7:6",
        "name_se": "7:6",
        "name_el": "7:6",
        "name_es": "7:6",
        "name_hr": "7:6",
        "name_fr": "7:6"
    },
    "2074^live_match^8^16:25": {
        "name_en": "16:25",
        "name_aa": "16:25",
        "name_de": "16:25",
        "name_nl": "16:25",
        "name_pl": "16:25",
        "name_ro": "16:25",
        "name_ru": "16:25",
        "name_tr": "16:25",
        "name_se": "16:25",
        "name_el": "16:25",
        "name_es": "16:25",
        "name_hr": "16:25",
        "name_fr": "16:25"
    },
    "2207^live_match^8^home_1-12": {
        "name_en": "home 1-12",
        "name_aa": "home 1-12",
        "name_de": "Heim 1-12",
        "name_nl": "Thuis 1-12",
        "name_pl": "home 1-12",
        "name_ro": "home 1-12",
        "name_ru": "home 1-12",
        "name_tr": "home 1-12",
        "name_se": "home 1-12",
        "name_el": "home 1-12",
        "name_es": "home 1-12",
        "name_hr": "Domači 1-12",
        "name_fr": "home 1-12"
    },
    "232^live_match^8^over": {
        "name_en": "Over",
        "name_aa": "Over",
        "name_de": "Über",
        "name_nl": "Over",
        "name_pl": "Over",
        "name_ro": "Over",
        "name_ru": "Over",
        "name_tr": "Üst",
        "name_se": "Over",
        "name_el": "Over",
        "name_es": "Over",
        "name_hr": "Više",
        "name_fr": "p"
    },
    "116^live_match^8^1:0": {
        "name_en": "1:0",
        "name_aa": "1:0",
        "name_de": "1:0",
        "name_nl": "1:0",
        "name_pl": "1:0",
        "name_ro": "1:0",
        "name_ru": "1:0",
        "name_tr": "1:0",
        "name_se": "1:0",
        "name_el": "1:0",
        "name_es": "1:0",
        "name_hr": "1:0",
        "name_fr": "1:0"
    },
    "2354^live_match^8^6:10": {
        "name_en": "6:10",
        "name_aa": "6:10",
        "name_de": "6:10",
        "name_nl": "6:10",
        "name_pl": "6:10",
        "name_ro": "6:10",
        "name_ru": "6:10",
        "name_tr": "6:10",
        "name_se": "6:10",
        "name_el": "6:10",
        "name_es": "6:10",
        "name_hr": "6:10",
        "name_fr": "6:10"
    },
    "2004^live_match^8^header": {
        "name_en": "Header",
        "name_aa": "Header",
        "name_de": "Kopfball",
        "name_nl": "Kopbal",
        "name_pl": "Header",
        "name_ro": "Header",
        "name_ru": "Header",
        "name_tr": "Header",
        "name_se": "Header",
        "name_el": "Header",
        "name_es": "Header",
        "name_hr": "Header",
        "name_fr": "Header"
    },
    "2026^live_match^8^exactly": {
        "name_en": "exactly",
        "name_aa": "exactly",
        "name_de": "Genau",
        "name_nl": "Precies",
        "name_pl": "exactly",
        "name_ro": "exactly",
        "name_ru": "exactly",
        "name_tr": "exactly",
        "name_se": "exactly",
        "name_el": "exactly",
        "name_es": "exactly",
        "name_hr": "Tacno",
        "name_fr": "exactly"
    },
    "2157^live_match^8^21:17": {
        "name_en": "21:17",
        "name_aa": "21:17",
        "name_de": "21:17",
        "name_nl": "21:17",
        "name_pl": "21:17",
        "name_ro": "21:17",
        "name_ru": "21:17",
        "name_tr": "21:17",
        "name_se": "21:17",
        "name_el": "21:17",
        "name_es": "21:17",
        "name_hr": "21:17",
        "name_fr": "21:17"
    },
    "58^live_match^8^odd": {
        "name_en": "odd",
        "name_aa": "odd",
        "name_de": "Ungerade",
        "name_nl": "oneven",
        "name_pl": "odd",
        "name_ro": "odd",
        "name_ru": "odd",
        "name_tr": "tek",
        "name_se": "odd",
        "name_el": "odd",
        "name_es": "odd",
        "name_hr": "Neparan",
        "name_fr": "impair"
    },
    "116^live_match^8^5:0": {
        "name_en": "5:0",
        "name_aa": "5:0",
        "name_de": "5:0",
        "name_nl": "5:0",
        "name_pl": "5:0",
        "name_ro": "5:0",
        "name_ru": "5:0",
        "name_tr": "5:0",
        "name_se": "5:0",
        "name_el": "5:0",
        "name_es": "5:0",
        "name_hr": "5:0",
        "name_fr": "5:0"
    },
    "2056^live_match^8^x2": {
        "name_en": "X2",
        "name_aa": "X2",
        "name_de": "X2",
        "name_nl": "X2",
        "name_pl": "X2",
        "name_ro": "X2",
        "name_ru": "X2",
        "name_tr": "X2",
        "name_se": "X2",
        "name_el": "X2",
        "name_es": "X2",
        "name_hr": "X2",
        "name_fr": "X2"
    },
    "26^live_match^8^1:21": {
        "name_en": "1:21",
        "name_aa": "1:21",
        "name_de": "1:21",
        "name_nl": "1:21",
        "name_pl": "1:21",
        "name_ro": "1:21",
        "name_ru": "1:21",
        "name_tr": "1:21",
        "name_se": "1:21",
        "name_el": "1:21",
        "name_es": "1:21",
        "name_hr": "1:21",
        "name_fr": "1:21"
    },
    "1032^live_match^8^5": {
        "name_en": "5",
        "name_aa": "5",
        "name_de": "5",
        "name_nl": "5",
        "name_pl": "5",
        "name_ro": "5",
        "name_ru": "5",
        "name_tr": "5",
        "name_se": "5",
        "name_el": "5",
        "name_es": "5",
        "name_hr": "5",
        "name_fr": "5"
    },
    "2354^live_match^8^3:9": {
        "name_en": "3:9",
        "name_aa": "3:9",
        "name_de": "3:9",
        "name_nl": "3:9",
        "name_pl": "3:9",
        "name_ro": "3:9",
        "name_ru": "3:9",
        "name_tr": "3:9",
        "name_se": "3:9",
        "name_el": "3:9",
        "name_es": "3:9",
        "name_hr": "3:9",
        "name_fr": "3:9"
    },
    "303^live_match^8^no": {
        "name_en": "No",
        "name_aa": "No",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "No",
        "name_ro": "No",
        "name_ru": "No",
        "name_tr": "hayır",
        "name_se": "No",
        "name_el": "No",
        "name_es": "No",
        "name_hr": "Ne",
        "name_fr": "non"
    },
    "2140^live_match^8^none": {
        "name_en": "none",
        "name_aa": "none",
        "name_de": "Keiner",
        "name_nl": "Geen",
        "name_pl": "none",
        "name_ro": "none",
        "name_ru": "none",
        "name_tr": "none",
        "name_se": "none",
        "name_el": "none",
        "name_es": "none",
        "name_hr": "Nitko",
        "name_fr": "none"
    },
    "2261^live_match^8^3:7": {
        "name_en": "3:7",
        "name_aa": "3:7",
        "name_de": "3:7",
        "name_nl": "3:7",
        "name_pl": "3:7",
        "name_ro": "3:7",
        "name_ru": "3:7",
        "name_tr": "3:7",
        "name_se": "3:7",
        "name_el": "3:7",
        "name_es": "3:7",
        "name_hr": "3:7",
        "name_fr": "3:7"
    },
    "183^live_match^8^draw_and_over": {
        "name_en": "draw and over",
        "name_aa": "draw and over",
        "name_de": "Unentschieden und mehr",
        "name_nl": "gelijk en boven",
        "name_pl": "draw and over",
        "name_ro": "draw and over",
        "name_ru": "draw and over",
        "name_tr": "berabere ve üst",
        "name_se": "draw and over",
        "name_el": "draw and over",
        "name_es": "draw and over",
        "name_hr": "neriješeno i više",
        "name_fr": "Match nul et Plus de"
    },
    "1108^live_match^8^12:4": {
        "name_en": "12:4",
        "name_aa": "12:4",
        "name_de": "12:4",
        "name_nl": "12:4",
        "name_pl": "12:4",
        "name_ro": "12:4",
        "name_ru": "12:4",
        "name_tr": "12:4",
        "name_se": "12:4",
        "name_el": "12:4",
        "name_es": "12:4",
        "name_hr": "12:4",
        "name_fr": "12:4"
    },
    "120^live_match^6^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "1032^live_match^8^3": {
        "name_en": "3",
        "name_aa": "3",
        "name_de": "3",
        "name_nl": "3",
        "name_pl": "3",
        "name_ro": "3",
        "name_ru": "3",
        "name_tr": "3",
        "name_se": "3",
        "name_el": "3",
        "name_es": "3",
        "name_hr": "3",
        "name_fr": "3"
    },
    "1208^live_match^8^green": {
        "name_en": "green",
        "name_aa": "green",
        "name_de": "grün",
        "name_nl": "groen",
        "name_pl": "green",
        "name_ro": "green",
        "name_ru": "green",
        "name_tr": "yeşil",
        "name_se": "green",
        "name_el": "green",
        "name_es": "green",
        "name_hr": "zelena",
        "name_fr": "vert"
    },
    "1533^live_match^8^yes": {
        "name_en": "Yes",
        "name_aa": "Yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "Yes",
        "name_ro": "Yes",
        "name_ru": "Yes",
        "name_tr": "evet",
        "name_se": "Yes",
        "name_el": "Yes",
        "name_es": "Yes",
        "name_hr": "Da",
        "name_fr": "oui"
    },
    "2090^live_match^8^15:40": {
        "name_en": "15:40",
        "name_aa": "15:40",
        "name_de": "15:40",
        "name_nl": "15:40",
        "name_pl": "15:40",
        "name_ro": "15:40",
        "name_ru": "15:40",
        "name_tr": "15:40",
        "name_se": "15:40",
        "name_el": "15:40",
        "name_es": "15:40",
        "name_hr": "15:40",
        "name_fr": "15:40"
    },
    "2153^live_match^8^away_5+": {
        "name_en": "away 5+",
        "name_aa": "away 5+",
        "name_de": "Auswärt 5+",
        "name_nl": "Weg 5+",
        "name_pl": "away 5+",
        "name_ro": "away 5+",
        "name_ru": "away 5+",
        "name_tr": "away 5+",
        "name_se": "away 5+",
        "name_el": "away 5+",
        "name_es": "away 5+",
        "name_hr": "Gostujuči 5+",
        "name_fr": "away 5+"
    },
    "2004^live_match^8^free_kick": {
        "name_en": "Free Kick",
        "name_aa": "Free Kick",
        "name_de": "Freistoß",
        "name_nl": "Vrije trap",
        "name_pl": "Free Kick",
        "name_ro": "Free Kick",
        "name_ru": "Free Kick",
        "name_tr": "Free Kick",
        "name_se": "Free Kick",
        "name_el": "Free Kick",
        "name_es": "Free Kick",
        "name_hr": "Free Kick",
        "name_fr": "Free Kick"
    },
    "2208^live_match^8^33-34": {
        "name_en": "33-34",
        "name_aa": "33-34",
        "name_de": "33-34",
        "name_nl": "33-34",
        "name_pl": "33-34",
        "name_ro": "33-34",
        "name_ru": "33-34",
        "name_tr": "33-34",
        "name_se": "33-34",
        "name_el": "33-34",
        "name_es": "33-34",
        "name_hr": "33-34",
        "name_fr": "33-34"
    },
    "183^live_match^8^away_and_under": {
        "name_en": "away and under",
        "name_aa": "away and under",
        "name_de": "Auswärtssieg und unter",
        "name_nl": "uit en onder",
        "name_pl": "away and under",
        "name_ro": "away and under",
        "name_ru": "away and under",
        "name_tr": "deplasman ve alt",
        "name_se": "away and under",
        "name_el": "away and under",
        "name_es": "away and under",
        "name_hr": "gost i manje",
        "name_fr": "Extérieur et Moins de"
    },
    "26^live_match^8^11:1": {
        "name_en": "11:1",
        "name_aa": "11:1",
        "name_de": "11:1",
        "name_nl": "11:1",
        "name_pl": "11:1",
        "name_ro": "11:1",
        "name_ru": "11:1",
        "name_tr": "11:1",
        "name_se": "11:1",
        "name_el": "11:1",
        "name_es": "11:1",
        "name_hr": "11:1",
        "name_fr": "11:1"
    },
    "2166^live_match^8^yes": {
        "name_en": "yes",
        "name_aa": "yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "yes",
        "name_ro": "yes",
        "name_ru": "yes",
        "name_tr": "yes",
        "name_se": "yes",
        "name_el": "yes",
        "name_es": "yes",
        "name_hr": "Da",
        "name_fr": "yes"
    },
    "2076^live_match^8^5:15_or_better": {
        "name_en": "5:15 or better",
        "name_aa": "5:15 or better",
        "name_de": "5:15 oder besser",
        "name_nl": "5:15 of beter",
        "name_pl": "5:15 or better",
        "name_ro": "5:15 or better",
        "name_ru": "5:15 or better",
        "name_tr": "5:15 or better",
        "name_se": "5:15 or better",
        "name_el": "5:15 or better",
        "name_es": "5:15 or better",
        "name_hr": "5:15 or better",
        "name_fr": "5:15 or better"
    },
    "2158^live_match^8^yes": {
        "name_en": "yes",
        "name_aa": "yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "yes",
        "name_ro": "yes",
        "name_ru": "yes",
        "name_tr": "yes",
        "name_se": "yes",
        "name_el": "yes",
        "name_es": "yes",
        "name_hr": "Da",
        "name_fr": "yes"
    },
    "2121^live_match^8^1_no": {
        "name_en": "1 no",
        "name_aa": "1 no",
        "name_de": "1 Nein",
        "name_nl": "1 Nee",
        "name_pl": "1 no",
        "name_ro": "1 no",
        "name_ru": "1 no",
        "name_tr": "1 no",
        "name_se": "1 no",
        "name_el": "1 no",
        "name_es": "1 no",
        "name_hr": "1 Ne",
        "name_fr": "1 no"
    },
    "2354^live_match^8^11:4": {
        "name_en": "11:4",
        "name_aa": "11:4",
        "name_de": "11:4",
        "name_nl": "11:4",
        "name_pl": "11:4",
        "name_ro": "11:4",
        "name_ru": "11:4",
        "name_tr": "11:4",
        "name_se": "11:4",
        "name_el": "11:4",
        "name_es": "11:4",
        "name_hr": "11:4",
        "name_fr": "11:4"
    },
    "2031^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "2316^live_match^8^0:10": {
        "name_en": "0:10",
        "name_aa": "0:10",
        "name_de": "0:10",
        "name_nl": "0:10",
        "name_pl": "0:10",
        "name_ro": "0:10",
        "name_ru": "0:10",
        "name_tr": "0:10",
        "name_se": "0:10",
        "name_el": "0:10",
        "name_es": "0:10",
        "name_hr": "0:10",
        "name_fr": "0:10"
    },
    "2005^live_match^8^home_ot": {
        "name_en": "Home OT",
        "name_aa": "Home OT",
        "name_de": "Heim OT",
        "name_nl": "Home OT",
        "name_pl": "Home OT",
        "name_ro": "Home OT",
        "name_ru": "Home OT",
        "name_tr": "Home OT",
        "name_se": "Home OT",
        "name_el": "Home OT",
        "name_es": "Home OT",
        "name_hr": "Home OT",
        "name_fr": "Home OT"
    },
    "2257^live_match^8^yes": {
        "name_en": "yes",
        "name_aa": "yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "yes",
        "name_ro": "yes",
        "name_ru": "yes",
        "name_tr": "yes",
        "name_se": "yes",
        "name_el": "yes",
        "name_es": "yes",
        "name_hr": "Da",
        "name_fr": "yes"
    },
    "2093^live_match^8^yes": {
        "name_en": "yes",
        "name_aa": "yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "yes",
        "name_ro": "yes",
        "name_ru": "yes",
        "name_tr": "yes",
        "name_se": "yes",
        "name_el": "yes",
        "name_es": "yes",
        "name_hr": "Da",
        "name_fr": "yes"
    },
    "2135^live_match^8^yes": {
        "name_en": "yes",
        "name_aa": "yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "yes",
        "name_ro": "yes",
        "name_ru": "yes",
        "name_tr": "yes",
        "name_se": "yes",
        "name_el": "yes",
        "name_es": "yes",
        "name_hr": "Da",
        "name_fr": "yes"
    },
    "2354^live_match^8^2:4": {
        "name_en": "2:4",
        "name_aa": "2:4",
        "name_de": "2:4",
        "name_nl": "2:4",
        "name_pl": "2:4",
        "name_ro": "2:4",
        "name_ru": "2:4",
        "name_tr": "2:4",
        "name_se": "2:4",
        "name_el": "2:4",
        "name_es": "2:4",
        "name_hr": "2:4",
        "name_fr": "2:4"
    },
    "2072^live_match^8^16:21": {
        "name_en": "16:21",
        "name_aa": "16:21",
        "name_de": "16:21",
        "name_nl": "16:21",
        "name_pl": "16:21",
        "name_ro": "16:21",
        "name_ru": "16:21",
        "name_tr": "16:21",
        "name_se": "16:21",
        "name_el": "16:21",
        "name_es": "16:21",
        "name_hr": "16:21",
        "name_fr": "16:21"
    },
    "2354^live_match^8^8:4": {
        "name_en": "8:4",
        "name_aa": "8:4",
        "name_de": "8:4",
        "name_nl": "8:4",
        "name_pl": "8:4",
        "name_ro": "8:4",
        "name_ru": "8:4",
        "name_tr": "8:4",
        "name_se": "8:4",
        "name_el": "8:4",
        "name_es": "8:4",
        "name_hr": "8:4",
        "name_fr": "8:4"
    },
    "2354^live_match^8^11:2": {
        "name_en": "11:2",
        "name_aa": "11:2",
        "name_de": "11:2",
        "name_nl": "11:2",
        "name_pl": "11:2",
        "name_ro": "11:2",
        "name_ru": "11:2",
        "name_tr": "11:2",
        "name_se": "11:2",
        "name_el": "11:2",
        "name_es": "11:2",
        "name_hr": "11:2",
        "name_fr": "11:2"
    },
    "2062^live_match^8^away_5-6": {
        "name_en": "away 5-6",
        "name_aa": "away 5-6",
        "name_de": "Auswärt 5-6",
        "name_nl": "Weg 5-6",
        "name_pl": "away 5-6",
        "name_ro": "away 5-6",
        "name_ru": "away 5-6",
        "name_tr": "away 5-6",
        "name_se": "away 5-6",
        "name_el": "away 5-6",
        "name_es": "away 5-6",
        "name_hr": "Gostujuči 5-6",
        "name_fr": "away 5-6"
    },
    "2142^live_match^8^away_5-6": {
        "name_en": "away 5-6",
        "name_aa": "away 5-6",
        "name_de": "Auswärt 5-6",
        "name_nl": "Weg 5-6",
        "name_pl": "away 5-6",
        "name_ro": "away 5-6",
        "name_ru": "away 5-6",
        "name_tr": "away 5-6",
        "name_se": "away 5-6",
        "name_el": "away 5-6",
        "name_es": "away 5-6",
        "name_hr": "Gostujuči 5-6",
        "name_fr": "away 5-6"
    },
    "117^live_match^8^0:4": {
        "name_en": "0:4",
        "name_aa": "0:4",
        "name_de": "0:4",
        "name_nl": "0:4",
        "name_pl": "0:4",
        "name_ro": "0:4",
        "name_ru": "0:4",
        "name_tr": "0:4",
        "name_se": "0:4",
        "name_el": "0:4",
        "name_es": "0:4",
        "name_hr": "0:4",
        "name_fr": "0:4"
    },
    "26^live_match^8^6:3": {
        "name_en": "6:3",
        "name_aa": "6:3",
        "name_de": "6:3",
        "name_nl": "6:3",
        "name_pl": "6:3",
        "name_ro": "6:3",
        "name_ru": "6:3",
        "name_tr": "6:3",
        "name_se": "6:3",
        "name_el": "6:3",
        "name_es": "6:3",
        "name_hr": "6:3",
        "name_fr": "6:3"
    },
    "2261^live_match^8^2:8": {
        "name_en": "2:8",
        "name_aa": "2:8",
        "name_de": "2:8",
        "name_nl": "2:8",
        "name_pl": "2:8",
        "name_ro": "2:8",
        "name_ru": "2:8",
        "name_tr": "2:8",
        "name_se": "2:8",
        "name_el": "2:8",
        "name_es": "2:8",
        "name_hr": "2:8",
        "name_fr": "2:8"
    },
    "1108^live_match^8^9:11": {
        "name_en": "9:11",
        "name_aa": "9:11",
        "name_de": "9:11",
        "name_nl": "9:11",
        "name_pl": "9:11",
        "name_ro": "9:11",
        "name_ru": "9:11",
        "name_tr": "9:11",
        "name_se": "9:11",
        "name_el": "9:11",
        "name_es": "9:11",
        "name_hr": "9:11",
        "name_fr": "9:11"
    },
    "1484^live_match^8^under": {
        "name_en": "Under",
        "name_aa": "u",
        "name_de": "Unter",
        "name_nl": "u",
        "name_pl": "u",
        "name_ro": "u",
        "name_ru": "u",
        "name_tr": "Alt",
        "name_se": "u",
        "name_el": "u",
        "name_es": "u",
        "name_hr": "Manje",
        "name_fr": "m"
    },
    "1671^live_match^8^no": {
        "name_en": "no",
        "name_aa": "no",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "no",
        "name_ro": "no",
        "name_ru": "no",
        "name_tr": "no",
        "name_se": "no",
        "name_el": "no",
        "name_es": "no",
        "name_hr": "Ne",
        "name_fr": "no"
    },
    "2060^live_match^8^home_5-6": {
        "name_en": "home 5-6",
        "name_aa": "home 5-6",
        "name_de": "Heim 5-6",
        "name_nl": "thuis 5-6",
        "name_pl": "home 5-6",
        "name_ro": "home 5-6",
        "name_ru": "home 5-6",
        "name_tr": "home 5-6",
        "name_se": "home 5-6",
        "name_el": "home 5-6",
        "name_es": "home 5-6",
        "name_hr": "Domači 5-6",
        "name_fr": "home 5-6"
    },
    "2024^live_match^8^away": {
        "name_en": "away",
        "name_aa": "away",
        "name_de": "Auswärts",
        "name_nl": "Uit",
        "name_pl": "away",
        "name_ro": "away",
        "name_ru": "away",
        "name_tr": "away",
        "name_se": "away",
        "name_el": "away",
        "name_es": "away",
        "name_hr": "Gostujući",
        "name_fr": "away"
    },
    "21^live_match^7^1": {
        "name_en": "Under",
        "name_aa": "u",
        "name_de": "Unter",
        "name_nl": "u",
        "name_pl": "u",
        "name_ro": "u",
        "name_ru": "u",
        "name_tr": "Alt",
        "name_se": "u",
        "name_el": "u",
        "name_es": "u",
        "name_hr": "Manje",
        "name_fr": "m"
    },
    "2245^live_match^8^home": {
        "name_en": "home",
        "name_aa": "home",
        "name_de": "Heim",
        "name_nl": "Thuis",
        "name_pl": "home",
        "name_ro": "home",
        "name_ru": "home",
        "name_tr": "home",
        "name_se": "home",
        "name_el": "home",
        "name_es": "home",
        "name_hr": "Domači",
        "name_fr": "home"
    },
    "2033^live_match^8^away_and_under": {
        "name_en": "away and under",
        "name_aa": "away and under",
        "name_de": "Auswärt und Unter",
        "name_nl": "Uit and Minder dan",
        "name_pl": "away and under",
        "name_ro": "away and under",
        "name_ru": "away and under",
        "name_tr": "away and under",
        "name_se": "away and under",
        "name_el": "away and under",
        "name_es": "away and under",
        "name_hr": "Gostujući i manje",
        "name_fr": "away and under"
    },
    "2044^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "88^live_match^8^under": {
        "name_en": "Under",
        "name_aa": "u",
        "name_de": "Unter",
        "name_nl": "u",
        "name_pl": "u",
        "name_ro": "u",
        "name_ru": "u",
        "name_tr": "Alt",
        "name_se": "u",
        "name_el": "u",
        "name_es": "u",
        "name_hr": "Manje",
        "name_fr": "m"
    },
    "45^live_match^8^even": {
        "name_en": "even",
        "name_aa": "even",
        "name_de": "Gerade",
        "name_nl": "even",
        "name_pl": "even",
        "name_ro": "even",
        "name_ru": "even",
        "name_tr": "çift",
        "name_se": "even",
        "name_el": "even",
        "name_es": "even",
        "name_hr": "Paran",
        "name_fr": "pair"
    },
    "2004^live_match^8^penalty": {
        "name_en": "Penalty",
        "name_aa": "Penalty",
        "name_de": "Elfmeter",
        "name_nl": "Strafschop",
        "name_pl": "Penalty",
        "name_ro": "Penalty",
        "name_ru": "Penalty",
        "name_tr": "Penalty",
        "name_se": "Penalty",
        "name_el": "Penalty",
        "name_es": "Penalty",
        "name_hr": "Penalty",
        "name_fr": "Penalty"
    },
    "2072^live_match^8^16:25": {
        "name_en": "16:25",
        "name_aa": "16:25",
        "name_de": "16:25",
        "name_nl": "16:25",
        "name_pl": "16:25",
        "name_ro": "16:25",
        "name_ru": "16:25",
        "name_tr": "16:25",
        "name_se": "16:25",
        "name_el": "16:25",
        "name_es": "16:25",
        "name_hr": "16:25",
        "name_fr": "16:25"
    },
    "2261^live_match^8^1:7": {
        "name_en": "1:7",
        "name_aa": "1:7",
        "name_de": "1:7",
        "name_nl": "1:7",
        "name_pl": "1:7",
        "name_ro": "1:7",
        "name_ru": "1:7",
        "name_tr": "1:7",
        "name_se": "1:7",
        "name_el": "1:7",
        "name_es": "1:7",
        "name_hr": "1:7",
        "name_fr": "1:7"
    },
    "2217^live_match^8^tie": {
        "name_en": "Tie",
        "name_aa": "Tie",
        "name_de": "Unentschieden",
        "name_nl": "Gelijkspel",
        "name_pl": "Tie",
        "name_ro": "Tie",
        "name_ru": "Tie",
        "name_tr": "Tie",
        "name_se": "Tie",
        "name_el": "Tie",
        "name_es": "Tie",
        "name_hr": "Tie",
        "name_fr": "Tie"
    },
    "2176^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "2087^live_match^8^3:1": {
        "name_en": "3:1",
        "name_aa": "3:1",
        "name_de": "3:1",
        "name_nl": "3:1",
        "name_pl": "3:1",
        "name_ro": "3:1",
        "name_ru": "3:1",
        "name_tr": "3:1",
        "name_se": "3:1",
        "name_el": "3:1",
        "name_es": "3:1",
        "name_hr": "3:1",
        "name_fr": "3:1"
    },
    "303^live_match^8^yes": {
        "name_en": "Yes",
        "name_aa": "Yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "Yes",
        "name_ro": "Yes",
        "name_ru": "Yes",
        "name_tr": "evet",
        "name_se": "Yes",
        "name_el": "Yes",
        "name_es": "Yes",
        "name_hr": "Da",
        "name_fr": "oui"
    },
    "2354^live_match^8^3:10": {
        "name_en": "3:10",
        "name_aa": "3:10",
        "name_de": "3:10",
        "name_nl": "3:10",
        "name_pl": "3:10",
        "name_ro": "3:10",
        "name_ru": "3:10",
        "name_tr": "3:10",
        "name_se": "3:10",
        "name_el": "3:10",
        "name_es": "3:10",
        "name_hr": "3:10",
        "name_fr": "3:10"
    },
    "26^live_match^8^4:2": {
        "name_en": "4:2",
        "name_aa": "4:2",
        "name_de": "4:2",
        "name_nl": "4:2",
        "name_pl": "4:2",
        "name_ro": "4:2",
        "name_ru": "4:2",
        "name_tr": "4:2",
        "name_se": "4:2",
        "name_el": "4:2",
        "name_es": "4:2",
        "name_hr": "4:2",
        "name_fr": "4:2"
    },
    "2238^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "59^live_match^8^even": {
        "name_en": "even",
        "name_aa": "even",
        "name_de": "Gerade",
        "name_nl": "even",
        "name_pl": "even",
        "name_ro": "even",
        "name_ru": "even",
        "name_tr": "çift",
        "name_se": "even",
        "name_el": "even",
        "name_es": "even",
        "name_hr": "Paran",
        "name_fr": "pair"
    },
    "2354^live_match^8^8:1": {
        "name_en": "8:1",
        "name_aa": "8:1",
        "name_de": "8:1",
        "name_nl": "8:1",
        "name_pl": "8:1",
        "name_ro": "8:1",
        "name_ru": "8:1",
        "name_tr": "8:1",
        "name_se": "8:1",
        "name_el": "8:1",
        "name_es": "8:1",
        "name_hr": "8:1",
        "name_fr": "8:1"
    },
    "26^live_match^8^0:11": {
        "name_en": "0:11",
        "name_aa": "0:11",
        "name_de": "0:11",
        "name_nl": "0:11",
        "name_pl": "0:11",
        "name_ro": "0:11",
        "name_ru": "0:11",
        "name_tr": "0:11",
        "name_se": "0:11",
        "name_el": "0:11",
        "name_es": "0:11",
        "name_hr": "0:11",
        "name_fr": "0:11"
    },
    "1108^live_match^8^10:11": {
        "name_en": "10:11",
        "name_aa": "10:11",
        "name_de": "10:11",
        "name_nl": "10:11",
        "name_pl": "10:11",
        "name_ro": "10:11",
        "name_ru": "10:11",
        "name_tr": "10:11",
        "name_se": "10:11",
        "name_el": "10:11",
        "name_es": "10:11",
        "name_hr": "10:11",
        "name_fr": "10:11"
    },
    "2354^live_match^8^11:3": {
        "name_en": "11:3",
        "name_aa": "11:3",
        "name_de": "11:3",
        "name_nl": "11:3",
        "name_pl": "11:3",
        "name_ro": "11:3",
        "name_ru": "11:3",
        "name_tr": "11:3",
        "name_se": "11:3",
        "name_el": "11:3",
        "name_es": "11:3",
        "name_hr": "11:3",
        "name_fr": "11:3"
    },
    "2232^live_match^6^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "64^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2072^live_match^8^21:15": {
        "name_en": "21:15",
        "name_aa": "21:15",
        "name_de": "21:15",
        "name_nl": "21:15",
        "name_pl": "21:15",
        "name_ro": "21:15",
        "name_ru": "21:15",
        "name_tr": "21:15",
        "name_se": "21:15",
        "name_el": "21:15",
        "name_es": "21:15",
        "name_hr": "21:15",
        "name_fr": "21:15"
    },
    "2303^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2261^live_match^8^0:2": {
        "name_en": "0:2",
        "name_aa": "0:2",
        "name_de": "0:2",
        "name_nl": "0:2",
        "name_pl": "0:2",
        "name_ro": "0:2",
        "name_ru": "0:2",
        "name_tr": "0:2",
        "name_se": "0:2",
        "name_el": "0:2",
        "name_es": "0:2",
        "name_hr": "0:2",
        "name_fr": "0:2"
    },
    "2305^live_match^8^7:3": {
        "name_en": "7:3",
        "name_aa": "7:3",
        "name_de": "7:3",
        "name_nl": "7:3",
        "name_pl": "7:3",
        "name_ro": "7:3",
        "name_ru": "7:3",
        "name_tr": "7:3",
        "name_se": "7:3",
        "name_el": "7:3",
        "name_es": "7:3",
        "name_hr": "7:3",
        "name_fr": "7:3"
    },
    "117^live_match^8^3:1": {
        "name_en": "3:1",
        "name_aa": "3:1",
        "name_de": "3:1",
        "name_nl": "3:1",
        "name_pl": "3:1",
        "name_ro": "3:1",
        "name_ru": "3:1",
        "name_tr": "3:1",
        "name_se": "3:1",
        "name_el": "3:1",
        "name_es": "3:1",
        "name_hr": "3:1",
        "name_fr": "3:1"
    },
    "26^live_match^8^20:0": {
        "name_en": "20:0",
        "name_aa": "20:0",
        "name_de": "20:0",
        "name_nl": "20:0",
        "name_pl": "20:0",
        "name_ro": "20:0",
        "name_ru": "20:0",
        "name_tr": "20:0",
        "name_se": "20:0",
        "name_el": "20:0",
        "name_es": "20:0",
        "name_hr": "20:0",
        "name_fr": "20:0"
    },
    "2307^live_match^8^6:0": {
        "name_en": "6:0",
        "name_aa": "6:0",
        "name_de": "6:0",
        "name_nl": "6:0",
        "name_pl": "6:0",
        "name_ro": "6:0",
        "name_ru": "6:0",
        "name_tr": "6:0",
        "name_se": "6:0",
        "name_el": "6:0",
        "name_es": "6:0",
        "name_hr": "6:0",
        "name_fr": "6:0"
    },
    "2337^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "2120^live_match^8^no": {
        "name_en": "no",
        "name_aa": "no",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "no",
        "name_ro": "no",
        "name_ru": "no",
        "name_tr": "no",
        "name_se": "no",
        "name_el": "no",
        "name_es": "no",
        "name_hr": "Ne",
        "name_fr": "no"
    },
    "2153^live_match^8^home_4": {
        "name_en": "home 4",
        "name_aa": "home 4",
        "name_de": "Heim 4",
        "name_nl": "Thuis 4",
        "name_pl": "home 4",
        "name_ro": "home 4",
        "name_ru": "home 4",
        "name_tr": "home 4",
        "name_se": "home 4",
        "name_el": "home 4",
        "name_es": "home 4",
        "name_hr": "Domači 4",
        "name_fr": "home 4"
    },
    "2059^live_match^8^away_13-15": {
        "name_en": "away 13-15",
        "name_aa": "away 13-15",
        "name_de": "Auswärt 13-15",
        "name_nl": "Weg 13-15",
        "name_pl": "away 13-15",
        "name_ro": "away 13-15",
        "name_ru": "away 13-15",
        "name_tr": "away 13-15",
        "name_se": "away 13-15",
        "name_el": "away 13-15",
        "name_es": "away 13-15",
        "name_hr": "Gostujuči 13-15",
        "name_fr": "away 13-15"
    },
    "2319^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "70^live_match^8^over": {
        "name_en": "Over",
        "name_aa": "Over",
        "name_de": "Über",
        "name_nl": "Over",
        "name_pl": "Over",
        "name_ro": "Over",
        "name_ru": "Over",
        "name_tr": "Üst",
        "name_se": "Over",
        "name_el": "Over",
        "name_es": "Over",
        "name_hr": "Više",
        "name_fr": "p"
    },
    "2354^live_match^8^10:7": {
        "name_en": "10:7",
        "name_aa": "10:7",
        "name_de": "10:7",
        "name_nl": "10:7",
        "name_pl": "10:7",
        "name_ro": "10:7",
        "name_ru": "10:7",
        "name_tr": "10:7",
        "name_se": "10:7",
        "name_el": "10:7",
        "name_es": "10:7",
        "name_hr": "10:7",
        "name_fr": "10:7"
    },
    "349^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2147^live_match^8^other": {
        "name_en": "other",
        "name_aa": "other",
        "name_de": "Sonstig",
        "name_nl": "Andere",
        "name_pl": "other",
        "name_ro": "other",
        "name_ru": "other",
        "name_tr": "other",
        "name_se": "other",
        "name_el": "other",
        "name_es": "other",
        "name_hr": "Ostali",
        "name_fr": "other"
    },
    "2345^live_match^8^no": {
        "name_en": "no",
        "name_aa": "no",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "no",
        "name_ro": "no",
        "name_ru": "no",
        "name_tr": "no",
        "name_se": "no",
        "name_el": "no",
        "name_es": "no",
        "name_hr": "Ne",
        "name_fr": "no"
    },
    "10^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2075^live_match^8^21:25": {
        "name_en": "21:25",
        "name_aa": "21:25",
        "name_de": "21:25",
        "name_nl": "21:25",
        "name_pl": "21:25",
        "name_ro": "21:25",
        "name_ru": "21:25",
        "name_tr": "21:25",
        "name_se": "21:25",
        "name_el": "21:25",
        "name_es": "21:25",
        "name_hr": "21:25",
        "name_fr": "21:25"
    },
    "29^live_match^8^0:16": {
        "name_en": "0:16",
        "name_aa": "0:16",
        "name_de": "0:16",
        "name_nl": "0:16",
        "name_pl": "0:16",
        "name_ro": "0:16",
        "name_ru": "0:16",
        "name_tr": "0:16",
        "name_se": "0:16",
        "name_el": "0:16",
        "name_es": "0:16",
        "name_hr": "0:16",
        "name_fr": "0:16"
    },
    "119^live_match^8^equals": {
        "name_en": "equals",
        "name_aa": "equals",
        "name_de": "Gleiche Anzahl",
        "name_nl": "gelijken",
        "name_pl": "equals",
        "name_ro": "equals",
        "name_ru": "equals",
        "name_tr": "eşit",
        "name_se": "equals",
        "name_el": "equals",
        "name_es": "equals",
        "name_hr": "jednako",
        "name_fr": "identiques"
    },
    "1108^live_match^8^2:6": {
        "name_en": "2:6",
        "name_aa": "2:6",
        "name_de": "2:6",
        "name_nl": "2:6",
        "name_pl": "2:6",
        "name_ro": "2:6",
        "name_ru": "2:6",
        "name_tr": "2:6",
        "name_se": "2:6",
        "name_el": "2:6",
        "name_es": "2:6",
        "name_hr": "2:6",
        "name_fr": "2:6"
    },
    "1216^live_match^8^yes": {
        "name_en": "Yes",
        "name_aa": "Yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "Yes",
        "name_ro": "Yes",
        "name_ru": "Yes",
        "name_tr": "evet",
        "name_se": "Yes",
        "name_el": "Yes",
        "name_es": "Yes",
        "name_hr": "Da",
        "name_fr": "oui"
    },
    "2075^live_match^8^25:20": {
        "name_en": "25:20",
        "name_aa": "25:20",
        "name_de": "25:20",
        "name_nl": "25:20",
        "name_pl": "25:20",
        "name_ro": "25:20",
        "name_ru": "25:20",
        "name_tr": "25:20",
        "name_se": "25:20",
        "name_el": "25:20",
        "name_es": "25:20",
        "name_hr": "25:20",
        "name_fr": "25:20"
    },
    "228^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2073^live_match^8^21:12": {
        "name_en": "21:12",
        "name_aa": "21:12",
        "name_de": "21:12",
        "name_nl": "21:12",
        "name_pl": "21:12",
        "name_ro": "21:12",
        "name_ru": "21:12",
        "name_tr": "21:12",
        "name_se": "21:12",
        "name_el": "21:12",
        "name_es": "21:12",
        "name_hr": "21:12",
        "name_fr": "21:12"
    },
    "26^live_match^8^12:5": {
        "name_en": "12:5",
        "name_aa": "12:5",
        "name_de": "12:5",
        "name_nl": "12:5",
        "name_pl": "12:5",
        "name_ro": "12:5",
        "name_ru": "12:5",
        "name_tr": "12:5",
        "name_se": "12:5",
        "name_el": "12:5",
        "name_es": "12:5",
        "name_hr": "12:5",
        "name_fr": "12:5"
    },
    "2139^live_match^8^odd": {
        "name_en": "odd",
        "name_aa": "odd",
        "name_de": "Ungerade",
        "name_nl": "Oneven",
        "name_pl": "odd",
        "name_ro": "odd",
        "name_ru": "odd",
        "name_tr": "odd",
        "name_se": "odd",
        "name_el": "odd",
        "name_es": "odd",
        "name_hr": "Neparan",
        "name_fr": "odd"
    },
    "2128^live_match^8^11:3_or_better": {
        "name_en": "11:3 or better",
        "name_aa": "11:3 or better",
        "name_de": "11-3 oder besser",
        "name_nl": "11:3 of beter",
        "name_pl": "11:3 or better",
        "name_ro": "11:3 or better",
        "name_ru": "11:3 or better",
        "name_tr": "11:3 or better",
        "name_se": "11:3 or better",
        "name_el": "11:3 or better",
        "name_es": "11:3 or better",
        "name_hr": "11:3 or better",
        "name_fr": "11:3 or better"
    },
    "2073^live_match^8^20:25": {
        "name_en": "20:25",
        "name_aa": "20:25",
        "name_de": "20:25",
        "name_nl": "20:25",
        "name_pl": "20:25",
        "name_ro": "20:25",
        "name_ru": "20:25",
        "name_tr": "20:25",
        "name_se": "20:25",
        "name_el": "20:25",
        "name_es": "20:25",
        "name_hr": "20:25",
        "name_fr": "20:25"
    },
    "2020^live_match^8^away": {
        "name_en": "away",
        "name_aa": "away",
        "name_de": "Auswärts",
        "name_nl": "Uit",
        "name_pl": "away",
        "name_ro": "away",
        "name_ru": "away",
        "name_tr": "away",
        "name_se": "away",
        "name_el": "away",
        "name_es": "away",
        "name_hr": "Gostujući",
        "name_fr": "away"
    },
    "2354^live_match^8^9:6": {
        "name_en": "9:6",
        "name_aa": "9:6",
        "name_de": "9:6",
        "name_nl": "9:6",
        "name_pl": "9:6",
        "name_ro": "9:6",
        "name_ru": "9:6",
        "name_tr": "9:6",
        "name_se": "9:6",
        "name_el": "9:6",
        "name_es": "9:6",
        "name_hr": "9:6",
        "name_fr": "9:6"
    },
    "115^live_match^6^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "1108^live_match^8^3:10": {
        "name_en": "3:10",
        "name_aa": "3:10",
        "name_de": "3:10",
        "name_nl": "3:10",
        "name_pl": "3:10",
        "name_ro": "3:10",
        "name_ru": "3:10",
        "name_tr": "3:10",
        "name_se": "3:10",
        "name_el": "3:10",
        "name_es": "3:10",
        "name_hr": "3:10",
        "name_fr": "3:10"
    },
    "2302^live_match^8^away": {
        "name_en": "away",
        "name_aa": "away",
        "name_de": "Auswärts",
        "name_nl": "Uit",
        "name_pl": "away",
        "name_ro": "away",
        "name_ru": "away",
        "name_tr": "away",
        "name_se": "away",
        "name_el": "away",
        "name_es": "away",
        "name_hr": "Gostujući",
        "name_fr": "away"
    },
    "2305^live_match^8^home_by_any_other": {
        "name_en": "home by Any Other",
        "name_aa": "home by Any Other",
        "name_de": "home by Any Other",
        "name_nl": "home by Any Other",
        "name_pl": "home by Any Other",
        "name_ro": "home by Any Other",
        "name_ru": "home by Any Other",
        "name_tr": "home by Any Other",
        "name_se": "home by Any Other",
        "name_el": "home by Any Other",
        "name_es": "home by Any Other",
        "name_hr": "Domači po bilo Ostali",
        "name_fr": "home by Any Other"
    },
    "2033^live_match^8^draw_and_under": {
        "name_en": "draw and under",
        "name_aa": "draw and under",
        "name_de": "Unentschieden und Unter",
        "name_nl": "Gelijkspel and Minder dan",
        "name_pl": "draw and under",
        "name_ro": "draw and under",
        "name_ru": "draw and under",
        "name_tr": "draw and under",
        "name_se": "draw and under",
        "name_el": "draw and under",
        "name_es": "draw and under",
        "name_hr": "Neriješeno i manje",
        "name_fr": "draw and under"
    },
    "2354^live_match^8^9:12": {
        "name_en": "9:12",
        "name_aa": "9:12",
        "name_de": "9:12",
        "name_nl": "9:12",
        "name_pl": "9:12",
        "name_ro": "9:12",
        "name_ru": "9:12",
        "name_tr": "9:12",
        "name_se": "9:12",
        "name_el": "9:12",
        "name_es": "9:12",
        "name_hr": "9:12",
        "name_fr": "9:12"
    },
    "2261^live_match^8^6:2": {
        "name_en": "6:2",
        "name_aa": "6:2",
        "name_de": "6:2",
        "name_nl": "6:2",
        "name_pl": "6:2",
        "name_ro": "6:2",
        "name_ru": "6:2",
        "name_tr": "6:2",
        "name_se": "6:2",
        "name_el": "6:2",
        "name_es": "6:2",
        "name_hr": "6:2",
        "name_fr": "6:2"
    },
    "26^live_match^8^7:2": {
        "name_en": "7:2",
        "name_aa": "7:2",
        "name_de": "7:2",
        "name_nl": "7:2",
        "name_pl": "7:2",
        "name_ro": "7:2",
        "name_ru": "7:2",
        "name_tr": "7:2",
        "name_se": "7:2",
        "name_el": "7:2",
        "name_es": "7:2",
        "name_hr": "7:2",
        "name_fr": "7:2"
    },
    "2072^live_match^8^15:21": {
        "name_en": "15:21",
        "name_aa": "15:21",
        "name_de": "15:21",
        "name_nl": "15:21",
        "name_pl": "15:21",
        "name_ro": "15:21",
        "name_ru": "15:21",
        "name_tr": "15:21",
        "name_se": "15:21",
        "name_el": "15:21",
        "name_es": "15:21",
        "name_hr": "15:21",
        "name_fr": "15:21"
    },
    "2353^live_match^8^16-17": {
        "name_en": "away 16-17",
        "name_aa": "away 16-17",
        "name_de": "Auswärt 16-17",
        "name_nl": "Weg 16-17",
        "name_pl": "away 16-17",
        "name_ro": "away 16-17",
        "name_ru": "away 16-17",
        "name_tr": "away 16-17",
        "name_se": "away 16-17",
        "name_el": "away 16-17",
        "name_es": "away 16-17",
        "name_hr": "Gostujuči 16-17",
        "name_fr": "away 16-17"
    },
    "2340^live_match^8^score": {
        "name_en": "score",
        "name_aa": "score",
        "name_de": "Erzielt",
        "name_nl": "score",
        "name_pl": "score",
        "name_ro": "score",
        "name_ru": "score",
        "name_tr": "score",
        "name_se": "score",
        "name_el": "score",
        "name_es": "score",
        "name_hr": "score",
        "name_fr": "score"
    },
    "2008^live_match^6^exactly": {
        "name_en": "exactly",
        "name_aa": "exactly",
        "name_de": "Genau",
        "name_nl": "Precies",
        "name_pl": "exactly",
        "name_ro": "exactly",
        "name_ru": "exactly",
        "name_tr": "exactly",
        "name_se": "exactly",
        "name_el": "exactly",
        "name_es": "exactly",
        "name_hr": "Tacno",
        "name_fr": "exactly"
    },
    "2061^live_match^8^tie": {
        "name_en": "tie",
        "name_aa": "tie",
        "name_de": "Unentschieden",
        "name_nl": "Gelijkspel",
        "name_pl": "tie",
        "name_ro": "tie",
        "name_ru": "tie",
        "name_tr": "tie",
        "name_se": "tie",
        "name_el": "tie",
        "name_es": "tie",
        "name_hr": "tie",
        "name_fr": "tie"
    },
    "2332^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "2316^live_match^8^8:10": {
        "name_en": "8:10",
        "name_aa": "8:10",
        "name_de": "8:10",
        "name_nl": "8:10",
        "name_pl": "8:10",
        "name_ro": "8:10",
        "name_ru": "8:10",
        "name_tr": "8:10",
        "name_se": "8:10",
        "name_el": "8:10",
        "name_es": "8:10",
        "name_hr": "8:10",
        "name_fr": "8:10"
    },
    "1064^live_match^8^under": {
        "name_en": "Under",
        "name_aa": "u",
        "name_de": "Unter",
        "name_nl": "u",
        "name_pl": "u",
        "name_ro": "u",
        "name_ru": "u",
        "name_tr": "Alt",
        "name_se": "u",
        "name_el": "u",
        "name_es": "u",
        "name_hr": "Manje",
        "name_fr": "m"
    },
    "2159^live_match^8^away": {
        "name_en": "away",
        "name_aa": "away",
        "name_de": "Auswärts",
        "name_nl": "Uit",
        "name_pl": "away",
        "name_ro": "away",
        "name_ru": "away",
        "name_tr": "away",
        "name_se": "away",
        "name_el": "away",
        "name_es": "away",
        "name_hr": "Gostujući",
        "name_fr": "away"
    },
    "2188^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "285^live_match^6^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "1208^live_match^8^pink": {
        "name_en": "pink",
        "name_aa": "pink",
        "name_de": "pink",
        "name_nl": "roze",
        "name_pl": "pink",
        "name_ro": "pink",
        "name_ru": "pink",
        "name_tr": "pembe",
        "name_se": "pink",
        "name_el": "pink",
        "name_es": "pink",
        "name_hr": "Roze",
        "name_fr": "rose"
    },
    "26^live_match^8^3:7": {
        "name_en": "3:7",
        "name_aa": "3:7",
        "name_de": "3:7",
        "name_nl": "3:7",
        "name_pl": "3:7",
        "name_ro": "3:7",
        "name_ru": "3:7",
        "name_tr": "3:7",
        "name_se": "3:7",
        "name_el": "3:7",
        "name_es": "3:7",
        "name_hr": "3:7",
        "name_fr": "3:7"
    },
    "2083^live_match^8^home_and_over": {
        "name_en": "home and over",
        "name_aa": "home and over",
        "name_de": "Heim und Über",
        "name_nl": "Thuis and Meer dan",
        "name_pl": "home and over",
        "name_ro": "home and over",
        "name_ru": "home and over",
        "name_tr": "home and over",
        "name_se": "home and over",
        "name_el": "home and over",
        "name_es": "home and over",
        "name_hr": "Domači i više",
        "name_fr": "home and over"
    },
    "1663^live_match^2^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2244^live_match^8^neither": {
        "name_en": "neither",
        "name_aa": "neither",
        "name_de": "Keiner",
        "name_nl": "Geen van beide",
        "name_pl": "neither",
        "name_ro": "neither",
        "name_ru": "neither",
        "name_tr": "neither",
        "name_se": "neither",
        "name_el": "neither",
        "name_es": "neither",
        "name_hr": "Ni",
        "name_fr": "neither"
    },
    "2260^live_match^8^away_2": {
        "name_en": "away 2",
        "name_aa": "away 2",
        "name_de": "Auswärt 2",
        "name_nl": "Weg 2",
        "name_pl": "away 2",
        "name_ro": "away 2",
        "name_ru": "away 2",
        "name_tr": "away 2",
        "name_se": "away 2",
        "name_el": "away 2",
        "name_es": "away 2",
        "name_hr": "Gostujući 2",
        "name_fr": "away 2"
    },
    "33^live_match^8^under": {
        "name_en": "Under",
        "name_aa": "u",
        "name_de": "Unter",
        "name_nl": "u",
        "name_pl": "u",
        "name_ro": "u",
        "name_ru": "u",
        "name_tr": "Alt",
        "name_se": "u",
        "name_el": "u",
        "name_es": "u",
        "name_hr": "Manje",
        "name_fr": "m"
    },
    "45^live_match^8^odd": {
        "name_en": "odd",
        "name_aa": "odd",
        "name_de": "Ungerade",
        "name_nl": "oneven",
        "name_pl": "odd",
        "name_ro": "odd",
        "name_ru": "odd",
        "name_tr": "tek",
        "name_se": "odd",
        "name_el": "odd",
        "name_es": "odd",
        "name_hr": "Neparan",
        "name_fr": "impair"
    },
    "2047^live_match^8^exactly": {
        "name_en": "exactly",
        "name_aa": "exactly",
        "name_de": "Genau",
        "name_nl": "Precies",
        "name_pl": "exactly",
        "name_ro": "exactly",
        "name_ru": "exactly",
        "name_tr": "exactly",
        "name_se": "exactly",
        "name_el": "exactly",
        "name_es": "exactly",
        "name_hr": "Tacno",
        "name_fr": "exactly"
    },
    "1032^live_match^8^9+": {
        "name_en": "9+",
        "name_aa": "9+",
        "name_de": "9+",
        "name_nl": "9+",
        "name_pl": "9+",
        "name_ro": "9+",
        "name_ru": "9+",
        "name_tr": "9+",
        "name_se": "9+",
        "name_el": "9+",
        "name_es": "9+",
        "name_hr": "9+",
        "name_fr": "9+"
    },
    "2111^live_match^8^home_3": {
        "name_en": "home 3",
        "name_aa": "home 3",
        "name_de": "Heim 3",
        "name_nl": "Thuis 3",
        "name_pl": "home 3",
        "name_ro": "home 3",
        "name_ru": "home 3",
        "name_tr": "home 3",
        "name_se": "home 3",
        "name_el": "home 3",
        "name_es": "home 3",
        "name_hr": "Domači 3",
        "name_fr": "home 3"
    },
    "1326^live_match^8^home": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "1662^live_match^2^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2020^live_match^8^neither": {
        "name_en": "neither",
        "name_aa": "neither",
        "name_de": "Keiner",
        "name_nl": "Geen van beide",
        "name_pl": "neither",
        "name_ro": "neither",
        "name_ru": "neither",
        "name_tr": "neither",
        "name_se": "neither",
        "name_el": "neither",
        "name_es": "neither",
        "name_hr": "Ni",
        "name_fr": "neither"
    },
    "2073^live_match^8^21:13": {
        "name_en": "21:13",
        "name_aa": "21:13",
        "name_de": "21:13",
        "name_nl": "21:13",
        "name_pl": "21:13",
        "name_ro": "21:13",
        "name_ru": "21:13",
        "name_tr": "21:13",
        "name_se": "21:13",
        "name_el": "21:13",
        "name_es": "21:13",
        "name_hr": "21:13",
        "name_fr": "21:13"
    },
    "2324^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "2136^live_match^8^no": {
        "name_en": "no",
        "name_aa": "no",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "no",
        "name_ro": "no",
        "name_ru": "no",
        "name_tr": "no",
        "name_se": "no",
        "name_el": "no",
        "name_es": "no",
        "name_hr": "Ne",
        "name_fr": "no"
    },
    "2141^live_match^8^home": {
        "name_en": "home",
        "name_aa": "home",
        "name_de": "Heim",
        "name_nl": "Thuis",
        "name_pl": "home",
        "name_ro": "home",
        "name_ru": "home",
        "name_tr": "home",
        "name_se": "home",
        "name_el": "home",
        "name_es": "home",
        "name_hr": "Domači",
        "name_fr": "home"
    },
    "1660^live_match^2^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2228^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "29^live_match^8^0:12": {
        "name_en": "0:12",
        "name_aa": "0:12",
        "name_de": "0:12",
        "name_nl": "0:12",
        "name_pl": "0:12",
        "name_ro": "0:12",
        "name_ru": "0:12",
        "name_tr": "0:12",
        "name_se": "0:12",
        "name_el": "0:12",
        "name_es": "0:12",
        "name_hr": "0:12",
        "name_fr": "0:12"
    },
    "2164^live_match^8^no": {
        "name_en": "no",
        "name_aa": "no",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "no",
        "name_ro": "no",
        "name_ru": "no",
        "name_tr": "no",
        "name_se": "no",
        "name_el": "no",
        "name_es": "no",
        "name_hr": "Ne",
        "name_fr": "no"
    },
    "2338^live_match^8^penalty_awarded": {
        "name_en": "Penalty Awarded",
        "name_aa": "Penalty Awarded",
        "name_de": "Penalty Awarded",
        "name_nl": "Penalty Awarded",
        "name_pl": "Penalty Awarded",
        "name_ro": "Penalty Awarded",
        "name_ru": "Penalty Awarded",
        "name_tr": "Penalty Awarded",
        "name_se": "Penalty Awarded",
        "name_el": "Penalty Awarded",
        "name_es": "Penalty Awarded",
        "name_hr": "Penalty Awarded",
        "name_fr": "Penalty Awarded"
    },
    "2156^live_match^8^home_9+": {
        "name_en": "home 9+",
        "name_aa": "home 9+",
        "name_de": "Heim 9+",
        "name_nl": "Thuis 9+",
        "name_pl": "home 9+",
        "name_ro": "home 9+",
        "name_ru": "home 9+",
        "name_tr": "home 9+",
        "name_se": "home 9+",
        "name_el": "home 9+",
        "name_es": "home 9+",
        "name_hr": "Domači 9+",
        "name_fr": "home 9+"
    },
    "1108^live_match^8^10:1": {
        "name_en": "10:1",
        "name_aa": "10:1",
        "name_de": "10:1",
        "name_nl": "10:1",
        "name_pl": "10:1",
        "name_ro": "10:1",
        "name_ru": "10:1",
        "name_tr": "10:1",
        "name_se": "10:1",
        "name_el": "10:1",
        "name_es": "10:1",
        "name_hr": "10:1",
        "name_fr": "10:1"
    },
    "2178^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "2076^live_match^8^15:09": {
        "name_en": "15:09",
        "name_aa": "15:09",
        "name_de": "15:09",
        "name_nl": "15:09",
        "name_pl": "15:09",
        "name_ro": "15:09",
        "name_ru": "15:09",
        "name_tr": "15:09",
        "name_se": "15:09",
        "name_el": "15:09",
        "name_es": "15:09",
        "name_hr": "15:09",
        "name_fr": "15:09"
    },
    "26^live_match^8^0:10": {
        "name_en": "0:10",
        "name_aa": "0:10",
        "name_de": "0:10",
        "name_nl": "0:10",
        "name_pl": "0:10",
        "name_ro": "0:10",
        "name_ru": "0:10",
        "name_tr": "0:10",
        "name_se": "0:10",
        "name_el": "0:10",
        "name_es": "0:10",
        "name_hr": "0:10",
        "name_fr": "0:10"
    },
    "2354^live_match^8^7:10": {
        "name_en": "7:10",
        "name_aa": "7:10",
        "name_de": "7:10",
        "name_nl": "7:10",
        "name_pl": "7:10",
        "name_ro": "7:10",
        "name_ru": "7:10",
        "name_tr": "7:10",
        "name_se": "7:10",
        "name_el": "7:10",
        "name_es": "7:10",
        "name_hr": "7:10",
        "name_fr": "7:10"
    },
    "29^live_match^8^4:2": {
        "name_en": "4:2",
        "name_aa": "4:2",
        "name_de": "4:2",
        "name_nl": "4:2",
        "name_pl": "4:2",
        "name_ro": "4:2",
        "name_ru": "4:2",
        "name_tr": "4:2",
        "name_se": "4:2",
        "name_el": "4:2",
        "name_es": "4:2",
        "name_hr": "4:2",
        "name_fr": "4:2"
    },
    "2177^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "29^live_match^8^2:8": {
        "name_en": "2:8",
        "name_aa": "2:8",
        "name_de": "2:8",
        "name_nl": "2:8",
        "name_pl": "2:8",
        "name_ro": "2:8",
        "name_ru": "2:8",
        "name_tr": "2:8",
        "name_se": "2:8",
        "name_el": "2:8",
        "name_es": "2:8",
        "name_hr": "2:8",
        "name_fr": "2:8"
    },
    "2251^live_match^8^yes": {
        "name_en": "yes",
        "name_aa": "yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "yes",
        "name_ro": "yes",
        "name_ru": "yes",
        "name_tr": "yes",
        "name_se": "yes",
        "name_el": "yes",
        "name_es": "yes",
        "name_hr": "Da",
        "name_fr": "yes"
    },
    "2180^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "285^live_match^6^x": {
        "name_en": "x",
        "name_aa": "x",
        "name_de": "x",
        "name_nl": "x",
        "name_pl": "x",
        "name_ro": "x",
        "name_ru": "x",
        "name_tr": "x",
        "name_se": "x",
        "name_el": "x",
        "name_es": "x",
        "name_hr": "x",
        "name_fr": "x"
    },
    "2167^live_match^8^2/2": {
        "name_en": "2/2",
        "name_aa": "2/2",
        "name_de": "2/2",
        "name_nl": "2/2",
        "name_pl": "2/2",
        "name_ro": "2/2",
        "name_ru": "2/2",
        "name_tr": "2/2",
        "name_se": "2/2",
        "name_el": "2/2",
        "name_es": "2/2",
        "name_hr": "2/2",
        "name_fr": "2/2"
    },
    "26^live_match^8^3:3": {
        "name_en": "3:3",
        "name_aa": "3:3",
        "name_de": "3:3",
        "name_nl": "3:3",
        "name_pl": "3:3",
        "name_ro": "3:3",
        "name_ru": "3:3",
        "name_tr": "3:3",
        "name_se": "3:3",
        "name_el": "3:3",
        "name_es": "3:3",
        "name_hr": "3:3",
        "name_fr": "3:3"
    },
    "1108^live_match^8^10:8": {
        "name_en": "10:8",
        "name_aa": "10:8",
        "name_de": "10:8",
        "name_nl": "10:8",
        "name_pl": "10:8",
        "name_ro": "10:8",
        "name_ru": "10:8",
        "name_tr": "10:8",
        "name_se": "10:8",
        "name_el": "10:8",
        "name_es": "10:8",
        "name_hr": "10:8",
        "name_fr": "10:8"
    },
    "2075^live_match^8^25:16": {
        "name_en": "25:16",
        "name_aa": "25:16",
        "name_de": "25:16",
        "name_nl": "25:16",
        "name_pl": "25:16",
        "name_ro": "25:16",
        "name_ru": "25:16",
        "name_tr": "25:16",
        "name_se": "25:16",
        "name_el": "25:16",
        "name_es": "25:16",
        "name_hr": "25:16",
        "name_fr": "25:16"
    },
    "2034^live_match^8^away_and_over": {
        "name_en": "away and over",
        "name_aa": "away and over",
        "name_de": "Auswärt und Über",
        "name_nl": "Uit and Meer dan",
        "name_pl": "away and over",
        "name_ro": "away and over",
        "name_ru": "away and over",
        "name_tr": "away and over",
        "name_se": "away and over",
        "name_el": "away and over",
        "name_es": "away and over",
        "name_hr": "Gostujući i više",
        "name_fr": "away and over"
    },
    "26^live_match^8^22:0": {
        "name_en": "22:0",
        "name_aa": "22:0",
        "name_de": "22:0",
        "name_nl": "22:0",
        "name_pl": "22:0",
        "name_ro": "22:0",
        "name_ru": "22:0",
        "name_tr": "22:0",
        "name_se": "22:0",
        "name_el": "22:0",
        "name_es": "22:0",
        "name_hr": "22:0",
        "name_fr": "22:0"
    },
    "29^live_match^8^4:1": {
        "name_en": "4:1",
        "name_aa": "4:1",
        "name_de": "4:1",
        "name_nl": "4:1",
        "name_pl": "4:1",
        "name_ro": "4:1",
        "name_ru": "4:1",
        "name_tr": "4:1",
        "name_se": "4:1",
        "name_el": "4:1",
        "name_es": "4:1",
        "name_hr": "4:1",
        "name_fr": "4:1"
    },
    "2261^live_match^8^4:5": {
        "name_en": "4:5",
        "name_aa": "4:5",
        "name_de": "4:5",
        "name_nl": "4:5",
        "name_pl": "4:5",
        "name_ro": "4:5",
        "name_ru": "4:5",
        "name_tr": "4:5",
        "name_se": "4:5",
        "name_el": "4:5",
        "name_es": "4:5",
        "name_hr": "4:5",
        "name_fr": "4:5"
    },
    "26^live_match^8^2:7": {
        "name_en": "2:7",
        "name_aa": "2:7",
        "name_de": "2:7",
        "name_nl": "2:7",
        "name_pl": "2:7",
        "name_ro": "2:7",
        "name_ru": "2:7",
        "name_tr": "2:7",
        "name_se": "2:7",
        "name_el": "2:7",
        "name_es": "2:7",
        "name_hr": "2:7",
        "name_fr": "2:7"
    },
    "26^live_match^8^1:2": {
        "name_en": "1:2",
        "name_aa": "1:2",
        "name_de": "1:2",
        "name_nl": "1:2",
        "name_pl": "1:2",
        "name_ro": "1:2",
        "name_ru": "1:2",
        "name_tr": "1:2",
        "name_se": "1:2",
        "name_el": "1:2",
        "name_es": "1:2",
        "name_hr": "1:2",
        "name_fr": "1:2"
    },
    "2017^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "2071^live_match^8^away_2": {
        "name_en": "away 2",
        "name_aa": "away 2",
        "name_de": "Auswärt 2",
        "name_nl": "Weg 2",
        "name_pl": "away 2",
        "name_ro": "away 2",
        "name_ru": "away 2",
        "name_tr": "away 2",
        "name_se": "away 2",
        "name_el": "away 2",
        "name_es": "away 2",
        "name_hr": "Gostujuči 2",
        "name_fr": "away 2"
    },
    "2153^live_match^8^home_1": {
        "name_en": "home 1",
        "name_aa": "home 1",
        "name_de": "Heim 1",
        "name_nl": "Thuis 1",
        "name_pl": "home 1",
        "name_ro": "home 1",
        "name_ru": "home 1",
        "name_tr": "home 1",
        "name_se": "home 1",
        "name_el": "home 1",
        "name_es": "home 1",
        "name_hr": "Domači 1",
        "name_fr": "home 1"
    },
    "2085^live_match^8^4_points": {
        "name_en": "4 Points",
        "name_aa": "4 Points",
        "name_de": "4 Punkte",
        "name_nl": "4 Punten",
        "name_pl": "4 Points",
        "name_ro": "4 Points",
        "name_ru": "4 Points",
        "name_tr": "4 Points",
        "name_se": "4 Points",
        "name_el": "4 Points",
        "name_es": "4 Points",
        "name_hr": "4 Points",
        "name_fr": "4 Points"
    },
    "2010^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "2261^live_match^8^4:2": {
        "name_en": "4:2",
        "name_aa": "4:2",
        "name_de": "4:2",
        "name_nl": "4:2",
        "name_pl": "4:2",
        "name_ro": "4:2",
        "name_ru": "4:2",
        "name_tr": "4:2",
        "name_se": "4:2",
        "name_el": "4:2",
        "name_es": "4:2",
        "name_hr": "4:2",
        "name_fr": "4:2"
    },
    "520^live_match^8^3:4": {
        "name_en": "3:4",
        "name_aa": "3:4",
        "name_de": "3:4",
        "name_nl": "3:4",
        "name_pl": "3:4",
        "name_ro": "3:4",
        "name_ru": "3:4",
        "name_tr": "3:4",
        "name_se": "3:4",
        "name_el": "3:4",
        "name_es": "3:4",
        "name_hr": "3:4",
        "name_fr": "3:4"
    },
    "1108^live_match^8^4:13": {
        "name_en": "4:13",
        "name_aa": "4:13",
        "name_de": "4:13",
        "name_nl": "4:13",
        "name_pl": "4:13",
        "name_ro": "4:13",
        "name_ru": "4:13",
        "name_tr": "4:13",
        "name_se": "4:13",
        "name_el": "4:13",
        "name_es": "4:13",
        "name_hr": "4:13",
        "name_fr": "4:13"
    },
    "520^live_match^8^6:1": {
        "name_en": "6:1",
        "name_aa": "6:1",
        "name_de": "6:1",
        "name_nl": "6:1",
        "name_pl": "6:1",
        "name_ro": "6:1",
        "name_ru": "6:1",
        "name_tr": "6:1",
        "name_se": "6:1",
        "name_el": "6:1",
        "name_es": "6:1",
        "name_hr": "6:1",
        "name_fr": "6:1"
    },
    "2073^live_match^8^25:18": {
        "name_en": "25:18",
        "name_aa": "25:18",
        "name_de": "25:18",
        "name_nl": "25:18",
        "name_pl": "25:18",
        "name_ro": "25:18",
        "name_ru": "25:18",
        "name_tr": "25:18",
        "name_se": "25:18",
        "name_el": "25:18",
        "name_es": "25:18",
        "name_hr": "25:18",
        "name_fr": "25:18"
    },
    "19^live_match^8^3:0": {
        "name_en": "3:0",
        "name_aa": "3:0",
        "name_de": "3:0",
        "name_nl": "3:0",
        "name_pl": "3:0",
        "name_ro": "3:0",
        "name_ru": "3:0",
        "name_tr": "3:0",
        "name_se": "3:0",
        "name_el": "3:0",
        "name_es": "3:0",
        "name_hr": "3:0",
        "name_fr": "3:0"
    },
    "2354^live_match^8^3:5": {
        "name_en": "3:5",
        "name_aa": "3:5",
        "name_de": "3:5",
        "name_nl": "3:5",
        "name_pl": "3:5",
        "name_ro": "3:5",
        "name_ru": "3:5",
        "name_tr": "3:5",
        "name_se": "3:5",
        "name_el": "3:5",
        "name_es": "3:5",
        "name_hr": "3:5",
        "name_fr": "3:5"
    },
    "1208^live_match^8^brown": {
        "name_en": "brown",
        "name_aa": "brown",
        "name_de": "braun",
        "name_nl": "bruin",
        "name_pl": "brown",
        "name_ro": "brown",
        "name_ru": "brown",
        "name_tr": "kahverengi",
        "name_se": "brown",
        "name_el": "brown",
        "name_es": "brown",
        "name_hr": "smeđa",
        "name_fr": "marron"
    },
    "2074^live_match^8^25:15_or_better": {
        "name_en": "25:15 or better",
        "name_aa": "25:15 or better",
        "name_de": "25:15 oder besser",
        "name_nl": "25:15 of beter",
        "name_pl": "25:15 or better",
        "name_ro": "25:15 or better",
        "name_ru": "25:15 or better",
        "name_tr": "25:15 or better",
        "name_se": "25:15 or better",
        "name_el": "25:15 or better",
        "name_es": "25:15 or better",
        "name_hr": "25:15 or better",
        "name_fr": "25:15 or better"
    },
    "2261^live_match^8^2:5": {
        "name_en": "2:5",
        "name_aa": "2:5",
        "name_de": "2:5",
        "name_nl": "2:5",
        "name_pl": "2:5",
        "name_ro": "2:5",
        "name_ru": "2:5",
        "name_tr": "2:5",
        "name_se": "2:5",
        "name_el": "2:5",
        "name_es": "2:5",
        "name_hr": "2:5",
        "name_fr": "2:5"
    },
    "26^live_match^8^7:8": {
        "name_en": "7:8",
        "name_aa": "7:8",
        "name_de": "7:8",
        "name_nl": "7:8",
        "name_pl": "7:8",
        "name_ro": "7:8",
        "name_ru": "7:8",
        "name_tr": "7:8",
        "name_se": "7:8",
        "name_el": "7:8",
        "name_es": "7:8",
        "name_hr": "7:8",
        "name_fr": "7:8"
    },
    "116^live_match^8^4:2": {
        "name_en": "4:2",
        "name_aa": "4:2",
        "name_de": "4:2",
        "name_nl": "4:2",
        "name_pl": "4:2",
        "name_ro": "4:2",
        "name_ru": "4:2",
        "name_tr": "4:2",
        "name_se": "4:2",
        "name_el": "4:2",
        "name_es": "4:2",
        "name_hr": "4:2",
        "name_fr": "4:2"
    },
    "17^live_match^6^x": {
        "name_en": "x",
        "name_aa": "x",
        "name_de": "x",
        "name_nl": "x",
        "name_pl": "x",
        "name_ro": "x",
        "name_ru": "x",
        "name_tr": "x",
        "name_se": "x",
        "name_el": "x",
        "name_es": "x",
        "name_hr": "x",
        "name_fr": "x"
    },
    "1108^live_match^8^14:2": {
        "name_en": "14:2",
        "name_aa": "14:2",
        "name_de": "14:2",
        "name_nl": "14:2",
        "name_pl": "14:2",
        "name_ro": "14:2",
        "name_ru": "14:2",
        "name_tr": "14:2",
        "name_se": "14:2",
        "name_el": "14:2",
        "name_es": "14:2",
        "name_hr": "14:2",
        "name_fr": "14:2"
    },
    "2145^live_match^8^away_21+": {
        "name_en": "away 21+",
        "name_aa": "away 21+",
        "name_de": "Auswärt 21+",
        "name_nl": "Weg 21+",
        "name_pl": "away 21+",
        "name_ro": "away 21+",
        "name_ru": "away 21+",
        "name_tr": "away 21+",
        "name_se": "away 21+",
        "name_el": "away 21+",
        "name_es": "away 21+",
        "name_hr": "Gostujuči 21+",
        "name_fr": "away 21+"
    },
    "2354^live_match^8^11:9": {
        "name_en": "11:9",
        "name_aa": "11:9",
        "name_de": "11:9",
        "name_nl": "11:9",
        "name_pl": "11:9",
        "name_ro": "11:9",
        "name_ru": "11:9",
        "name_tr": "11:9",
        "name_se": "11:9",
        "name_el": "11:9",
        "name_es": "11:9",
        "name_hr": "11:9",
        "name_fr": "11:9"
    },
    "2207^live_match^8^away_61-72": {
        "name_en": "away 61-72",
        "name_aa": "away 61-72",
        "name_de": "Auswärt 61-72",
        "name_nl": "Weg 61-72",
        "name_pl": "away 61-72",
        "name_ro": "away 61-72",
        "name_ru": "away 61-72",
        "name_tr": "away 61-72",
        "name_se": "away 61-72",
        "name_el": "away 61-72",
        "name_es": "away 61-72",
        "name_hr": "Gostujuči 61-72",
        "name_fr": "away 61-72"
    },
    "0^live_match^2^x": {
        "name_en": "x",
        "name_aa": "x",
        "name_de": "x",
        "name_nl": "x",
        "name_pl": "x",
        "name_ro": "x",
        "name_ru": "x",
        "name_tr": "x",
        "name_se": "x",
        "name_el": "x",
        "name_es": "x",
        "name_hr": "x",
        "name_fr": "x"
    },
    "2072^live_match^8^25:21": {
        "name_en": "25:21",
        "name_aa": "25:21",
        "name_de": "25:21",
        "name_nl": "25:21",
        "name_pl": "25:21",
        "name_ro": "25:21",
        "name_ru": "25:21",
        "name_tr": "25:21",
        "name_se": "25:21",
        "name_el": "25:21",
        "name_es": "25:21",
        "name_hr": "25:21",
        "name_fr": "25:21"
    },
    "78^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2074^live_match^8^home_after_extra_points": {
        "name_en": "home after extra points",
        "name_aa": "home after extra points",
        "name_de": "Heimmannschaft Nach Verlängerung",
        "name_nl": "Thuisteam Na extra punten",
        "name_pl": "home after extra points",
        "name_ro": "home after extra points",
        "name_ru": "home after extra points",
        "name_tr": "home after extra points",
        "name_se": "home after extra points",
        "name_el": "home after extra points",
        "name_es": "home after extra points",
        "name_hr": "home after extra points",
        "name_fr": "home after extra points"
    },
    "880^live_match^8^under": {
        "name_en": "Under",
        "name_aa": "u",
        "name_de": "Unter",
        "name_nl": "u",
        "name_pl": "u",
        "name_ro": "u",
        "name_ru": "u",
        "name_tr": "Alt",
        "name_se": "u",
        "name_el": "u",
        "name_es": "u",
        "name_hr": "Manje",
        "name_fr": "m"
    },
    "1108^live_match^8^0:7": {
        "name_en": "0:7",
        "name_aa": "0:7",
        "name_de": "0:7",
        "name_nl": "0:7",
        "name_pl": "0:7",
        "name_ro": "0:7",
        "name_ru": "0:7",
        "name_tr": "0:7",
        "name_se": "0:7",
        "name_el": "0:7",
        "name_es": "0:7",
        "name_hr": "0:7",
        "name_fr": "0:7"
    },
    "2207^live_match^8^home_49-60": {
        "name_en": "home 49-60",
        "name_aa": "home 49-60",
        "name_de": "Heim 49-60",
        "name_nl": "Thuis 49-60",
        "name_pl": "home 49-60",
        "name_ro": "home 49-60",
        "name_ru": "home 49-60",
        "name_tr": "home 49-60",
        "name_se": "home 49-60",
        "name_el": "home 49-60",
        "name_es": "home 49-60",
        "name_hr": "Domači 49-60",
        "name_fr": "home 49-60"
    },
    "2064^live_match^6^home_win_by_6+": {
        "name_en": "home win by 6+",
        "name_aa": "home win by 6+",
        "name_de": "Heim gewinnt durch 6+",
        "name_nl": "thuiszege met 6+",
        "name_pl": "home win by 6+",
        "name_ro": "home win by 6+",
        "name_ru": "home win by 6+",
        "name_tr": "home win by 6+",
        "name_se": "home win by 6+",
        "name_el": "home win by 6+",
        "name_es": "home win by 6+",
        "name_hr": "Domači pobijediti po 6+",
        "name_fr": "home win by 6+"
    },
    "2223^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "29^live_match^8^5:4": {
        "name_en": "5:4",
        "name_aa": "5:4",
        "name_de": "5:4",
        "name_nl": "5:4",
        "name_pl": "5:4",
        "name_ro": "5:4",
        "name_ru": "5:4",
        "name_tr": "5:4",
        "name_se": "5:4",
        "name_el": "5:4",
        "name_es": "5:4",
        "name_hr": "5:4",
        "name_fr": "5:4"
    },
    "2315^live_match^8^6:0": {
        "name_en": "6:0",
        "name_aa": "6:0",
        "name_de": "6:0",
        "name_nl": "6:0",
        "name_pl": "6:0",
        "name_ro": "6:0",
        "name_ru": "6:0",
        "name_tr": "6:0",
        "name_se": "6:0",
        "name_el": "6:0",
        "name_es": "6:0",
        "name_hr": "6:0",
        "name_fr": "6:0"
    },
    "2107^live_match^8^neither": {
        "name_en": "neither",
        "name_aa": "neither",
        "name_de": "Keiner",
        "name_nl": "Geen van beide",
        "name_pl": "neither",
        "name_ro": "neither",
        "name_ru": "neither",
        "name_tr": "neither",
        "name_se": "neither",
        "name_el": "neither",
        "name_es": "neither",
        "name_hr": "Ni",
        "name_fr": "neither"
    },
    "28^live_match^8^0": {
        "name_en": "0",
        "name_aa": "0",
        "name_de": "0",
        "name_nl": "0",
        "name_pl": "0",
        "name_ro": "0",
        "name_ru": "0",
        "name_tr": "0",
        "name_se": "0",
        "name_el": "0",
        "name_es": "0",
        "name_hr": "0",
        "name_fr": "0"
    },
    "29^live_match^8^3:0": {
        "name_en": "3:0",
        "name_aa": "3:0",
        "name_de": "3:0",
        "name_nl": "3:0",
        "name_pl": "3:0",
        "name_ro": "3:0",
        "name_ru": "3:0",
        "name_tr": "3:0",
        "name_se": "3:0",
        "name_el": "3:0",
        "name_es": "3:0",
        "name_hr": "3:0",
        "name_fr": "3:0"
    },
    "2317^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "2059^live_match^8^tie": {
        "name_en": "tie",
        "name_aa": "tie",
        "name_de": "Unentschieden",
        "name_nl": "Gelijkspel",
        "name_pl": "tie",
        "name_ro": "tie",
        "name_ru": "tie",
        "name_tr": "tie",
        "name_se": "tie",
        "name_el": "tie",
        "name_es": "tie",
        "name_hr": "tie",
        "name_fr": "tie"
    },
    "183^live_match^8^draw_and_under": {
        "name_en": "draw and under",
        "name_aa": "draw and under",
        "name_de": "Unentschieden und unter",
        "name_nl": "gelijk en onder",
        "name_pl": "draw and under",
        "name_ro": "draw and under",
        "name_ru": "draw and under",
        "name_tr": "berabere ve alt",
        "name_se": "draw and under",
        "name_el": "draw and under",
        "name_es": "draw and under",
        "name_hr": "neriješeno i manje",
        "name_fr": "Match nul et Moins de"
    },
    "26^live_match^8^1:8": {
        "name_en": "1:8",
        "name_aa": "1:8",
        "name_de": "1:8",
        "name_nl": "1:8",
        "name_pl": "1:8",
        "name_ro": "1:8",
        "name_ru": "1:8",
        "name_tr": "1:8",
        "name_se": "1:8",
        "name_el": "1:8",
        "name_es": "1:8",
        "name_hr": "1:8",
        "name_fr": "1:8"
    },
    "2063^live_match^8^home_7-9": {
        "name_en": "home 7-9",
        "name_aa": "home 7-9",
        "name_de": "Heim 7-9",
        "name_nl": "thuis 7-9",
        "name_pl": "home 7-9",
        "name_ro": "home 7-9",
        "name_ru": "home 7-9",
        "name_tr": "home 7-9",
        "name_se": "home 7-9",
        "name_el": "home 7-9",
        "name_es": "home 7-9",
        "name_hr": "Domači 7-9",
        "name_fr": "home 7-9"
    },
    "1040^live_match^8^home_and_yes": {
        "name_en": "home and yes",
        "name_aa": "home and yes",
        "name_de": "Heimmannschaft und Ja",
        "name_nl": "thuis en ja",
        "name_pl": "home and yes",
        "name_ro": "home and yes",
        "name_ru": "home and yes",
        "name_tr": "ev sahibi kazanır ve iki takımda gol atar",
        "name_se": "home and yes",
        "name_el": "home and yes",
        "name_es": "home and yes",
        "name_hr": "domaći i da",
        "name_fr": "Domicile et Oui"
    },
    "1370^live_match^8^under": {
        "name_en": "Under",
        "name_aa": "u",
        "name_de": "Unter",
        "name_nl": "u",
        "name_pl": "u",
        "name_ro": "u",
        "name_ru": "u",
        "name_tr": "Alt",
        "name_se": "u",
        "name_el": "u",
        "name_es": "u",
        "name_hr": "Manje",
        "name_fr": "m"
    },
    "2246^live_match^8^away": {
        "name_en": "away",
        "name_aa": "away",
        "name_de": "Auswärts",
        "name_nl": "Uit",
        "name_pl": "away",
        "name_ro": "away",
        "name_ru": "away",
        "name_tr": "away",
        "name_se": "away",
        "name_el": "away",
        "name_es": "away",
        "name_hr": "Gostujući",
        "name_fr": "away"
    },
    "2214^live_match^8^away_1-20": {
        "name_en": "away 1-20",
        "name_aa": "away 1-20",
        "name_de": "Auswärt 1-20",
        "name_nl": "Weg 1-20",
        "name_pl": "away 1-20",
        "name_ro": "away 1-20",
        "name_ru": "away 1-20",
        "name_tr": "away 1-20",
        "name_se": "away 1-20",
        "name_el": "away 1-20",
        "name_es": "away 1-20",
        "name_hr": "Gostujuči 1-20",
        "name_fr": "away 1-20"
    },
    "2170^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2354^live_match^8^10:1": {
        "name_en": "10:1",
        "name_aa": "10:1",
        "name_de": "10:1",
        "name_nl": "10:1",
        "name_pl": "10:1",
        "name_ro": "10:1",
        "name_ru": "10:1",
        "name_tr": "10:1",
        "name_se": "10:1",
        "name_el": "10:1",
        "name_es": "10:1",
        "name_hr": "10:1",
        "name_fr": "10:1"
    },
    "2142^live_match^8^home_1-2": {
        "name_en": "home 1-2",
        "name_aa": "home 1-2",
        "name_de": "Heim 1-2",
        "name_nl": "thuis 1-2",
        "name_pl": "home 1-2",
        "name_ro": "home 1-2",
        "name_ru": "home 1-2",
        "name_tr": "home 1-2",
        "name_se": "home 1-2",
        "name_el": "home 1-2",
        "name_es": "home 1-2",
        "name_hr": "Domači 1-2",
        "name_fr": "home 1-2"
    },
    "2354^live_match^8^1:5": {
        "name_en": "1:5",
        "name_aa": "1:5",
        "name_de": "1:5",
        "name_nl": "1:5",
        "name_pl": "1:5",
        "name_ro": "1:5",
        "name_ru": "1:5",
        "name_tr": "1:5",
        "name_se": "1:5",
        "name_el": "1:5",
        "name_es": "1:5",
        "name_hr": "1:5",
        "name_fr": "1:5"
    },
    "29^live_match^8^4:0": {
        "name_en": "4:0",
        "name_aa": "4:0",
        "name_de": "4:0",
        "name_nl": "4:0",
        "name_pl": "4:0",
        "name_ro": "4:0",
        "name_ru": "4:0",
        "name_tr": "4:0",
        "name_se": "4:0",
        "name_el": "4:0",
        "name_es": "4:0",
        "name_hr": "4:0",
        "name_fr": "4:0"
    },
    "2304^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "1108^live_match^8^7:11": {
        "name_en": "7:11",
        "name_aa": "7:11",
        "name_de": "7:11",
        "name_nl": "7:11",
        "name_pl": "7:11",
        "name_ro": "7:11",
        "name_ru": "7:11",
        "name_tr": "7:11",
        "name_se": "7:11",
        "name_el": "7:11",
        "name_es": "7:11",
        "name_hr": "7:11",
        "name_fr": "7:11"
    },
    "1446^live_match^8^under": {
        "name_en": "Under",
        "name_aa": "u",
        "name_de": "Unter",
        "name_nl": "u",
        "name_pl": "u",
        "name_ro": "u",
        "name_ru": "u",
        "name_tr": "Alt",
        "name_se": "u",
        "name_el": "u",
        "name_es": "u",
        "name_hr": "Manje",
        "name_fr": "m"
    },
    "2165^live_match^8^no": {
        "name_en": "no",
        "name_aa": "no",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "no",
        "name_ro": "no",
        "name_ru": "no",
        "name_tr": "no",
        "name_se": "no",
        "name_el": "no",
        "name_es": "no",
        "name_hr": "Ne",
        "name_fr": "no"
    },
    "106^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "107^live_match^6^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "1108^live_match^8^3:14": {
        "name_en": "3:14",
        "name_aa": "3:14",
        "name_de": "3:14",
        "name_nl": "3:14",
        "name_pl": "3:14",
        "name_ro": "3:14",
        "name_ru": "3:14",
        "name_tr": "3:14",
        "name_se": "3:14",
        "name_el": "3:14",
        "name_es": "3:14",
        "name_hr": "3:14",
        "name_fr": "3:14"
    },
    "2212^live_match^8^home_25-36": {
        "name_en": "home 25-36",
        "name_aa": "home 25-36",
        "name_de": "Heim 25-36",
        "name_nl": "Thuis 25-36",
        "name_pl": "home 25-36",
        "name_ro": "home 25-36",
        "name_ru": "home 25-36",
        "name_tr": "home 25-36",
        "name_se": "home 25-36",
        "name_el": "home 25-36",
        "name_es": "home 25-36",
        "name_hr": "Domači 25-36",
        "name_fr": "home 25-36"
    },
    "2024^live_match^8^home": {
        "name_en": "home",
        "name_aa": "home",
        "name_de": "Heim",
        "name_nl": "Thuis",
        "name_pl": "home",
        "name_ro": "home",
        "name_ru": "home",
        "name_tr": "home",
        "name_se": "home",
        "name_el": "home",
        "name_es": "home",
        "name_hr": "Domači",
        "name_fr": "home"
    },
    "2042^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "2193^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "1108^live_match^8^11:0": {
        "name_en": "11:0",
        "name_aa": "11:0",
        "name_de": "11:0",
        "name_nl": "11:0",
        "name_pl": "11:0",
        "name_ro": "11:0",
        "name_ru": "11:0",
        "name_tr": "11:0",
        "name_se": "11:0",
        "name_el": "11:0",
        "name_es": "11:0",
        "name_hr": "11:0",
        "name_fr": "11:0"
    },
    "1108^live_match^8^11:1": {
        "name_en": "11:1",
        "name_aa": "11:1",
        "name_de": "11:1",
        "name_nl": "11:1",
        "name_pl": "11:1",
        "name_ro": "11:1",
        "name_ru": "11:1",
        "name_tr": "11:1",
        "name_se": "11:1",
        "name_el": "11:1",
        "name_es": "11:1",
        "name_hr": "11:1",
        "name_fr": "11:1"
    },
    "2315^live_match^8^0:6": {
        "name_en": "0:6",
        "name_aa": "0:6",
        "name_de": "0:6",
        "name_nl": "0:6",
        "name_pl": "0:6",
        "name_ro": "0:6",
        "name_ru": "0:6",
        "name_tr": "0:6",
        "name_se": "0:6",
        "name_el": "0:6",
        "name_es": "0:6",
        "name_hr": "0:6",
        "name_fr": "0:6"
    },
    "2214^live_match^8^home_21+": {
        "name_en": "home 21+",
        "name_aa": "home 21+",
        "name_de": "Heim 21+",
        "name_nl": "thuis 21+",
        "name_pl": "home 21+",
        "name_ro": "home 21+",
        "name_ru": "home 21+",
        "name_tr": "home 21+",
        "name_se": "home 21+",
        "name_el": "home 21+",
        "name_es": "home 21+",
        "name_hr": "Domači 21+",
        "name_fr": "home 21+"
    },
    "29^live_match^8^10:2": {
        "name_en": "10:2",
        "name_aa": "10:2",
        "name_de": "10:2",
        "name_nl": "10:2",
        "name_pl": "10:2",
        "name_ro": "10:2",
        "name_ru": "10:2",
        "name_tr": "10:2",
        "name_se": "10:2",
        "name_el": "10:2",
        "name_es": "10:2",
        "name_hr": "10:2",
        "name_fr": "10:2"
    },
    "2308^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "1108^live_match^8^13:8": {
        "name_en": "13:8",
        "name_aa": "13:8",
        "name_de": "13:8",
        "name_nl": "13:8",
        "name_pl": "13:8",
        "name_ro": "13:8",
        "name_ru": "13:8",
        "name_tr": "13:8",
        "name_se": "13:8",
        "name_el": "13:8",
        "name_es": "13:8",
        "name_hr": "13:8",
        "name_fr": "13:8"
    },
    "2098^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "2047^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "2329^live_match^8^any_other_outcome": {
        "name_en": "Any other Outcome",
        "name_aa": "Any other Outcome",
        "name_de": "Any other Outcome",
        "name_nl": "Any other Outcome",
        "name_pl": "Any other Outcome",
        "name_ro": "Any other Outcome",
        "name_ru": "Any other Outcome",
        "name_tr": "Any other Outcome",
        "name_se": "Any other Outcome",
        "name_el": "Any other Outcome",
        "name_es": "Any other Outcome",
        "name_hr": "Any other Outcome",
        "name_fr": "Any other Outcome"
    },
    "1108^live_match^8^2:3": {
        "name_en": "2:3",
        "name_aa": "2:3",
        "name_de": "2:3",
        "name_nl": "2:3",
        "name_pl": "2:3",
        "name_ro": "2:3",
        "name_ru": "2:3",
        "name_tr": "2:3",
        "name_se": "2:3",
        "name_el": "2:3",
        "name_es": "2:3",
        "name_hr": "2:3",
        "name_fr": "2:3"
    },
    "2072^live_match^8^21:17": {
        "name_en": "21:17",
        "name_aa": "21:17",
        "name_de": "21:17",
        "name_nl": "21:17",
        "name_pl": "21:17",
        "name_ro": "21:17",
        "name_ru": "21:17",
        "name_tr": "21:17",
        "name_se": "21:17",
        "name_el": "21:17",
        "name_es": "21:17",
        "name_hr": "21:17",
        "name_fr": "21:17"
    },
    "2059^live_match^8^home_13-15": {
        "name_en": "home 13-15",
        "name_aa": "home 13-15",
        "name_de": "home 13-15",
        "name_nl": "thuis 13-15",
        "name_pl": "home 13-15",
        "name_ro": "home 13-15",
        "name_ru": "home 13-15",
        "name_tr": "home 13-15",
        "name_se": "home 13-15",
        "name_el": "home 13-15",
        "name_es": "home 13-15",
        "name_hr": "Domači 13-15",
        "name_fr": "home 13-15"
    },
    "1156^live_match^8^2:4": {
        "name_en": "2:4",
        "name_aa": "2:4",
        "name_de": "2:4",
        "name_nl": "2:4",
        "name_pl": "2:4",
        "name_ro": "2:4",
        "name_ru": "2:4",
        "name_tr": "2:4",
        "name_se": "2:4",
        "name_el": "2:4",
        "name_es": "2:4",
        "name_hr": "2:4",
        "name_fr": "2:4"
    },
    "2059^live_match^8^home_1-3": {
        "name_en": "home 1-3",
        "name_aa": "home 1-3",
        "name_de": "Heim 1-3",
        "name_nl": "thuis 1-3",
        "name_pl": "home 1-3",
        "name_ro": "home 1-3",
        "name_ru": "home 1-3",
        "name_tr": "home 1-3",
        "name_se": "home 1-3",
        "name_el": "home 1-3",
        "name_es": "home 1-3",
        "name_hr": "Domači 1-3",
        "name_fr": "home 1-3"
    },
    "2074^live_match^8^11:15": {
        "name_en": "11:15",
        "name_aa": "11:15",
        "name_de": "11:15",
        "name_nl": "11:15",
        "name_pl": "11:15",
        "name_ro": "11:15",
        "name_ru": "11:15",
        "name_tr": "11:15",
        "name_se": "11:15",
        "name_el": "11:15",
        "name_es": "11:15",
        "name_hr": "11:15",
        "name_fr": "11:15"
    },
    "1108^live_match^8^11:7": {
        "name_en": "11:7",
        "name_aa": "11:7",
        "name_de": "11:7",
        "name_nl": "11:7",
        "name_pl": "11:7",
        "name_ro": "11:7",
        "name_ru": "11:7",
        "name_tr": "11:7",
        "name_se": "11:7",
        "name_el": "11:7",
        "name_es": "11:7",
        "name_hr": "11:7",
        "name_fr": "11:7"
    },
    "2075^live_match^8^16:25": {
        "name_en": "16:25",
        "name_aa": "16:25",
        "name_de": "16:25",
        "name_nl": "16:25",
        "name_pl": "16:25",
        "name_ro": "16:25",
        "name_ru": "16:25",
        "name_tr": "16:25",
        "name_se": "16:25",
        "name_el": "16:25",
        "name_es": "16:25",
        "name_hr": "16:25",
        "name_fr": "16:25"
    },
    "870^live_match^6^x": {
        "name_en": "x",
        "name_aa": "x",
        "name_de": "x",
        "name_nl": "x",
        "name_pl": "x",
        "name_ro": "x",
        "name_ru": "x",
        "name_tr": "x",
        "name_se": "x",
        "name_el": "x",
        "name_es": "x",
        "name_hr": "x",
        "name_fr": "x"
    },
    "1230^live_match^8^0-49": {
        "name_en": "0-49",
        "name_aa": "0-49",
        "name_de": "0-49",
        "name_nl": "0-49",
        "name_pl": "0-49",
        "name_ro": "0-49",
        "name_ru": "0-49",
        "name_tr": "0-49",
        "name_se": "0-49",
        "name_el": "0-49",
        "name_es": "0-49",
        "name_hr": "0-49",
        "name_fr": "0-49"
    },
    "2354^live_match^8^4:11": {
        "name_en": "4:11",
        "name_aa": "4:11",
        "name_de": "4:11",
        "name_nl": "4:11",
        "name_pl": "4:11",
        "name_ro": "4:11",
        "name_ru": "4:11",
        "name_tr": "4:11",
        "name_se": "4:11",
        "name_el": "4:11",
        "name_es": "4:11",
        "name_hr": "4:11",
        "name_fr": "4:11"
    },
    "2230^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "2320^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "26^live_match^8^6:4": {
        "name_en": "6:4",
        "name_aa": "6:4",
        "name_de": "6:4",
        "name_nl": "6:4",
        "name_pl": "6:4",
        "name_ro": "6:4",
        "name_ru": "6:4",
        "name_tr": "6:4",
        "name_se": "6:4",
        "name_el": "6:4",
        "name_es": "6:4",
        "name_hr": "6:4",
        "name_fr": "6:4"
    },
    "116^live_match^8^2:4": {
        "name_en": "2:4",
        "name_aa": "2:4",
        "name_de": "2:4",
        "name_nl": "2:4",
        "name_pl": "2:4",
        "name_ro": "2:4",
        "name_ru": "2:4",
        "name_tr": "2:4",
        "name_se": "2:4",
        "name_el": "2:4",
        "name_es": "2:4",
        "name_hr": "2:4",
        "name_fr": "2:4"
    },
    "2114^live_match^8^30:15": {
        "name_en": "30:15",
        "name_aa": "30:15",
        "name_de": "30:15",
        "name_nl": "30:15",
        "name_pl": "30:15",
        "name_ro": "30:15",
        "name_ru": "30:15",
        "name_tr": "30:15",
        "name_se": "30:15",
        "name_el": "30:15",
        "name_es": "30:15",
        "name_hr": "30:15",
        "name_fr": "30:15"
    },
    "2029^live_match^8^exactly": {
        "name_en": "exactly",
        "name_aa": "exactly",
        "name_de": "Genau",
        "name_nl": "Precies",
        "name_pl": "exactly",
        "name_ro": "exactly",
        "name_ru": "exactly",
        "name_tr": "exactly",
        "name_se": "exactly",
        "name_el": "exactly",
        "name_es": "exactly",
        "name_hr": "Tacno",
        "name_fr": "exactly"
    },
    "910^live_match^8^over": {
        "name_en": "Over",
        "name_aa": "Over",
        "name_de": "Über",
        "name_nl": "Over",
        "name_pl": "Over",
        "name_ro": "Over",
        "name_ru": "Over",
        "name_tr": "Üst",
        "name_se": "Over",
        "name_el": "Over",
        "name_es": "Over",
        "name_hr": "Više",
        "name_fr": "p"
    },
    "2143^live_match^8^home_7+": {
        "name_en": "home 7+",
        "name_aa": "home 7+",
        "name_de": "Heim 7+",
        "name_nl": "Thuis 7+",
        "name_pl": "home 7+",
        "name_ro": "home 7+",
        "name_ru": "home 7+",
        "name_tr": "home 7+",
        "name_se": "home 7+",
        "name_el": "home 7+",
        "name_es": "home 7+",
        "name_hr": "Domači 7+",
        "name_fr": "home 7+"
    },
    "26^live_match^8^6:8": {
        "name_en": "6:8",
        "name_aa": "6:8",
        "name_de": "6:8",
        "name_nl": "6:8",
        "name_pl": "6:8",
        "name_ro": "6:8",
        "name_ru": "6:8",
        "name_tr": "6:8",
        "name_se": "6:8",
        "name_el": "6:8",
        "name_es": "6:8",
        "name_hr": "6:8",
        "name_fr": "6:8"
    },
    "2175^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "2220^live_match^8^0:3": {
        "name_en": "0:3",
        "name_aa": "0:3",
        "name_de": "0:3",
        "name_nl": "0:3",
        "name_pl": "0:3",
        "name_ro": "0:3",
        "name_ru": "0:3",
        "name_tr": "0:3",
        "name_se": "0:3",
        "name_el": "0:3",
        "name_es": "0:3",
        "name_hr": "0:3",
        "name_fr": "0:3"
    },
    "2312^live_match^8^2_&_no": {
        "name_en": "2 & No",
        "name_aa": "2 & No",
        "name_de": "2 & Nein",
        "name_nl": "2 & Nee",
        "name_pl": "2 & No",
        "name_ro": "2 & No",
        "name_ru": "2 & No",
        "name_tr": "2 & No",
        "name_se": "2 & No",
        "name_el": "2 & No",
        "name_es": "2 & No",
        "name_hr": "2 & ne",
        "name_fr": "2 & No"
    },
    "1108^live_match^8^0:5": {
        "name_en": "0:5",
        "name_aa": "0:5",
        "name_de": "0:5",
        "name_nl": "0:5",
        "name_pl": "0:5",
        "name_ro": "0:5",
        "name_ru": "0:5",
        "name_tr": "0:5",
        "name_se": "0:5",
        "name_el": "0:5",
        "name_es": "0:5",
        "name_hr": "0:5",
        "name_fr": "0:5"
    },
    "2073^live_match^8^12:21": {
        "name_en": "12:21",
        "name_aa": "12:21",
        "name_de": "12:21",
        "name_nl": "12:21",
        "name_pl": "12:21",
        "name_ro": "12:21",
        "name_ru": "12:21",
        "name_tr": "12:21",
        "name_se": "12:21",
        "name_el": "12:21",
        "name_es": "12:21",
        "name_hr": "12:21",
        "name_fr": "12:21"
    },
    "2354^live_match^8^2:2": {
        "name_en": "2:2",
        "name_aa": "2:2",
        "name_de": "2:2",
        "name_nl": "2:2",
        "name_pl": "2:2",
        "name_ro": "2:2",
        "name_ru": "2:2",
        "name_tr": "2:2",
        "name_se": "2:2",
        "name_el": "2:2",
        "name_es": "2:2",
        "name_hr": "2:2",
        "name_fr": "2:2"
    },
    "29^live_match^8^0:6": {
        "name_en": "0:6",
        "name_aa": "0:6",
        "name_de": "0:6",
        "name_nl": "0:6",
        "name_pl": "0:6",
        "name_ro": "0:6",
        "name_ru": "0:6",
        "name_tr": "0:6",
        "name_se": "0:6",
        "name_el": "0:6",
        "name_es": "0:6",
        "name_hr": "0:6",
        "name_fr": "0:6"
    },
    "2213^live_match^8^away_37+": {
        "name_en": "away 37+",
        "name_aa": "away 37+",
        "name_de": "Auswärt 37+",
        "name_nl": "Weg 37+",
        "name_pl": "away 37+",
        "name_ro": "away 37+",
        "name_ru": "away 37+",
        "name_tr": "away 37+",
        "name_se": "away 37+",
        "name_el": "away 37+",
        "name_es": "away 37+",
        "name_hr": "Gostujuči 37+",
        "name_fr": "away 37+"
    },
    "1108^live_match^8^10:3": {
        "name_en": "10:3",
        "name_aa": "10:3",
        "name_de": "10:3",
        "name_nl": "10:3",
        "name_pl": "10:3",
        "name_ro": "10:3",
        "name_ru": "10:3",
        "name_tr": "10:3",
        "name_se": "10:3",
        "name_el": "10:3",
        "name_es": "10:3",
        "name_hr": "10:3",
        "name_fr": "10:3"
    },
    "2063^live_match^8^home_14-16": {
        "name_en": "home 14-16",
        "name_aa": "home 14-16",
        "name_de": "Heim 14-16",
        "name_nl": "thuis 14-16",
        "name_pl": "home 14-16",
        "name_ro": "home 14-16",
        "name_ru": "home 14-16",
        "name_tr": "home 14-16",
        "name_se": "home 14-16",
        "name_el": "home 14-16",
        "name_es": "home 14-16",
        "name_hr": "Domači 14-16",
        "name_fr": "home 14-16"
    },
    "2257^live_match^8^no": {
        "name_en": "no",
        "name_aa": "no",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "no",
        "name_ro": "no",
        "name_ru": "no",
        "name_tr": "no",
        "name_se": "no",
        "name_el": "no",
        "name_es": "no",
        "name_hr": "Ne",
        "name_fr": "no"
    },
    "28^live_match^8^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "520^live_match^8^5:7": {
        "name_en": "5:7",
        "name_aa": "5:7",
        "name_de": "5:7",
        "name_nl": "5:7",
        "name_pl": "5:7",
        "name_ro": "5:7",
        "name_ru": "5:7",
        "name_tr": "5:7",
        "name_se": "5:7",
        "name_el": "5:7",
        "name_es": "5:7",
        "name_hr": "5:7",
        "name_fr": "5:7"
    },
    "2086^live_match^8^0:2": {
        "name_en": "0:2",
        "name_aa": "0:2",
        "name_de": "0:2",
        "name_nl": "0:2",
        "name_pl": "0:2",
        "name_ro": "0:2",
        "name_ru": "0:2",
        "name_tr": "0:2",
        "name_se": "0:2",
        "name_el": "0:2",
        "name_es": "0:2",
        "name_hr": "0:2",
        "name_fr": "0:2"
    },
    "2081^live_match^8^6:4": {
        "name_en": "6:4",
        "name_aa": "6:4",
        "name_de": "6:4",
        "name_nl": "6:4",
        "name_pl": "6:4",
        "name_ro": "6:4",
        "name_ru": "6:4",
        "name_tr": "6:4",
        "name_se": "6:4",
        "name_el": "6:4",
        "name_es": "6:4",
        "name_hr": "6:4",
        "name_fr": "6:4"
    },
    "2338^live_match^8^corner": {
        "name_en": "Corner",
        "name_aa": "Corner",
        "name_de": "Corner",
        "name_nl": "Corner",
        "name_pl": "Corner",
        "name_ro": "Corner",
        "name_ru": "Corner",
        "name_tr": "Corner",
        "name_se": "Corner",
        "name_el": "Corner",
        "name_es": "Corner",
        "name_hr": "Corner",
        "name_fr": "Corner"
    },
    "2076^live_match^8^11:15": {
        "name_en": "11:15",
        "name_aa": "11:15",
        "name_de": "11:15",
        "name_nl": "11:15",
        "name_pl": "11:15",
        "name_ro": "11:15",
        "name_ru": "11:15",
        "name_tr": "11:15",
        "name_se": "11:15",
        "name_el": "11:15",
        "name_es": "11:15",
        "name_hr": "11:15",
        "name_fr": "11:15"
    },
    "2354^live_match^8^4:3": {
        "name_en": "4:3",
        "name_aa": "4:3",
        "name_de": "4:3",
        "name_nl": "4:3",
        "name_pl": "4:3",
        "name_ro": "4:3",
        "name_ru": "4:3",
        "name_tr": "4:3",
        "name_se": "4:3",
        "name_el": "4:3",
        "name_es": "4:3",
        "name_hr": "4:3",
        "name_fr": "4:3"
    },
    "1108^live_match^8^0:8": {
        "name_en": "0:8",
        "name_aa": "0:8",
        "name_de": "0:8",
        "name_nl": "0:8",
        "name_pl": "0:8",
        "name_ro": "0:8",
        "name_ru": "0:8",
        "name_tr": "0:8",
        "name_se": "0:8",
        "name_el": "0:8",
        "name_es": "0:8",
        "name_hr": "0:8",
        "name_fr": "0:8"
    },
    "2261^live_match^8^4:1": {
        "name_en": "4:1",
        "name_aa": "4:1",
        "name_de": "4:1",
        "name_nl": "4:1",
        "name_pl": "4:1",
        "name_ro": "4:1",
        "name_ru": "4:1",
        "name_tr": "4:1",
        "name_se": "4:1",
        "name_el": "4:1",
        "name_es": "4:1",
        "name_hr": "4:1",
        "name_fr": "4:1"
    },
    "29^live_match^8^4:3": {
        "name_en": "4:3",
        "name_aa": "4:3",
        "name_de": "4:3",
        "name_nl": "4:3",
        "name_pl": "4:3",
        "name_ro": "4:3",
        "name_ru": "4:3",
        "name_tr": "4:3",
        "name_se": "4:3",
        "name_el": "4:3",
        "name_es": "4:3",
        "name_hr": "4:3",
        "name_fr": "4:3"
    },
    "2128^live_match^8^11:7": {
        "name_en": "11:7",
        "name_aa": "11:7",
        "name_de": "11:7",
        "name_nl": "11:7",
        "name_pl": "11:7",
        "name_ro": "11:7",
        "name_ru": "11:7",
        "name_tr": "11:7",
        "name_se": "11:7",
        "name_el": "11:7",
        "name_es": "11:7",
        "name_hr": "11:7",
        "name_fr": "11:7"
    },
    "1523^live_match^8^x2_and_over": {
        "name_en": "X2 and over",
        "name_aa": "X2 and over",
        "name_de": "X2 und Über",
        "name_nl": "X2 and Meer dan",
        "name_pl": "X2 and over",
        "name_ro": "X2 and over",
        "name_ru": "X2 and over",
        "name_tr": "X2 and over",
        "name_se": "X2 and over",
        "name_el": "X2 and over",
        "name_es": "X2 and over",
        "name_hr": "X2 i više",
        "name_fr": "X2 and over"
    },
    "2087^live_match^8^2:2": {
        "name_en": "2:2",
        "name_aa": "2:2",
        "name_de": "2:2",
        "name_nl": "2:2",
        "name_pl": "2:2",
        "name_ro": "2:2",
        "name_ru": "2:2",
        "name_tr": "2:2",
        "name_se": "2:2",
        "name_el": "2:2",
        "name_es": "2:2",
        "name_hr": "2:2",
        "name_fr": "2:2"
    },
    "1108^live_match^8^7:3": {
        "name_en": "7:3",
        "name_aa": "7:3",
        "name_de": "7:3",
        "name_nl": "7:3",
        "name_pl": "7:3",
        "name_ro": "7:3",
        "name_ru": "7:3",
        "name_tr": "7:3",
        "name_se": "7:3",
        "name_el": "7:3",
        "name_es": "7:3",
        "name_hr": "7:3",
        "name_fr": "7:3"
    },
    "2048^live_match^8^hh": {
        "name_en": "HH",
        "name_aa": "HH",
        "name_de": "HH",
        "name_nl": "HH",
        "name_pl": "HH",
        "name_ro": "HH",
        "name_ru": "HH",
        "name_tr": "HH",
        "name_se": "HH",
        "name_el": "HH",
        "name_es": "HH",
        "name_hr": "HH",
        "name_fr": "HH"
    },
    "2346^live_match^8^no": {
        "name_en": "no",
        "name_aa": "no",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "no",
        "name_ro": "no",
        "name_ru": "no",
        "name_tr": "no",
        "name_se": "no",
        "name_el": "no",
        "name_es": "no",
        "name_hr": "Ne",
        "name_fr": "no"
    },
    "116^live_match^8^3:1": {
        "name_en": "3:1",
        "name_aa": "3:1",
        "name_de": "3:1",
        "name_nl": "3:1",
        "name_pl": "3:1",
        "name_ro": "3:1",
        "name_ru": "3:1",
        "name_tr": "3:1",
        "name_se": "3:1",
        "name_el": "3:1",
        "name_es": "3:1",
        "name_hr": "3:1",
        "name_fr": "3:1"
    },
    "81^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2009^live_match^6^exactly": {
        "name_en": "exactly",
        "name_aa": "exactly",
        "name_de": "Genau",
        "name_nl": "Precies",
        "name_pl": "exactly",
        "name_ro": "exactly",
        "name_ru": "exactly",
        "name_tr": "exactly",
        "name_se": "exactly",
        "name_el": "exactly",
        "name_es": "exactly",
        "name_hr": "Tacno",
        "name_fr": "exactly"
    },
    "28^live_match^8^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "26^live_match^8^5:4": {
        "name_en": "5:4",
        "name_aa": "5:4",
        "name_de": "5:4",
        "name_nl": "5:4",
        "name_pl": "5:4",
        "name_ro": "5:4",
        "name_ru": "5:4",
        "name_tr": "5:4",
        "name_se": "5:4",
        "name_el": "5:4",
        "name_es": "5:4",
        "name_hr": "5:4",
        "name_fr": "5:4"
    },
    "26^live_match^8^7:1": {
        "name_en": "7:1",
        "name_aa": "7:1",
        "name_de": "7:1",
        "name_nl": "7:1",
        "name_pl": "7:1",
        "name_ro": "7:1",
        "name_ru": "7:1",
        "name_tr": "7:1",
        "name_se": "7:1",
        "name_el": "7:1",
        "name_es": "7:1",
        "name_hr": "7:1",
        "name_fr": "7:1"
    },
    "347^live_match^8^odd": {
        "name_en": "odd",
        "name_aa": "odd",
        "name_de": "Ungerade",
        "name_nl": "oneven",
        "name_pl": "odd",
        "name_ro": "odd",
        "name_ru": "odd",
        "name_tr": "tek",
        "name_se": "odd",
        "name_el": "odd",
        "name_es": "odd",
        "name_hr": "Neparan",
        "name_fr": "impair"
    },
    "2128^live_match^8^11:4": {
        "name_en": "11:4",
        "name_aa": "11:4",
        "name_de": "11:4",
        "name_nl": "11:4",
        "name_pl": "11:4",
        "name_ro": "11:4",
        "name_ru": "11:4",
        "name_tr": "11:4",
        "name_se": "11:4",
        "name_el": "11:4",
        "name_es": "11:4",
        "name_hr": "11:4",
        "name_fr": "11:4"
    },
    "116^live_match^8^1:5": {
        "name_en": "1:5",
        "name_aa": "1:5",
        "name_de": "1:5",
        "name_nl": "1:5",
        "name_pl": "1:5",
        "name_ro": "1:5",
        "name_ru": "1:5",
        "name_tr": "1:5",
        "name_se": "1:5",
        "name_el": "1:5",
        "name_es": "1:5",
        "name_hr": "1:5",
        "name_fr": "1:5"
    },
    "2027^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "2210^live_match^8^18+": {
        "name_en": "home 18+",
        "name_aa": "home 18+",
        "name_de": "Heim 18+",
        "name_nl": "Thuis 18+",
        "name_pl": "home 18+",
        "name_ro": "home 18+",
        "name_ru": "home 18+",
        "name_tr": "home 18+",
        "name_se": "home 18+",
        "name_el": "home 18+",
        "name_es": "home 18+",
        "name_hr": "Domači 18+",
        "name_fr": "home 18+"
    },
    "2314^live_match^8^home": {
        "name_en": "home",
        "name_aa": "home",
        "name_de": "Heim",
        "name_nl": "Thuis",
        "name_pl": "home",
        "name_ro": "home",
        "name_ru": "home",
        "name_tr": "home",
        "name_se": "home",
        "name_el": "home",
        "name_es": "home",
        "name_hr": "Domači",
        "name_fr": "home"
    },
    "974^live_match^8^even": {
        "name_en": "even",
        "name_aa": "even",
        "name_de": "gerade",
        "name_nl": "Even",
        "name_pl": "even",
        "name_ro": "even",
        "name_ru": "even",
        "name_tr": "çift",
        "name_se": "even",
        "name_el": "even",
        "name_es": "even",
        "name_hr": "Paran",
        "name_fr": "pair"
    },
    "204^live_match^8^over": {
        "name_en": "Over",
        "name_aa": "Over",
        "name_de": "Über",
        "name_nl": "Over",
        "name_pl": "Over",
        "name_ro": "Over",
        "name_ru": "Over",
        "name_tr": "Üst",
        "name_se": "Over",
        "name_el": "Over",
        "name_es": "Over",
        "name_hr": "Više",
        "name_fr": "p"
    },
    "26^live_match^8^1:17": {
        "name_en": "1:17",
        "name_aa": "1:17",
        "name_de": "1:17",
        "name_nl": "1:17",
        "name_pl": "1:17",
        "name_ro": "1:17",
        "name_ru": "1:17",
        "name_tr": "1:17",
        "name_se": "1:17",
        "name_el": "1:17",
        "name_es": "1:17",
        "name_hr": "1:17",
        "name_fr": "1:17"
    },
    "1108^live_match^8^1:5": {
        "name_en": "1:5",
        "name_aa": "1:5",
        "name_de": "1:5",
        "name_nl": "1:5",
        "name_pl": "1:5",
        "name_ro": "1:5",
        "name_ru": "1:5",
        "name_tr": "1:5",
        "name_se": "1:5",
        "name_el": "1:5",
        "name_es": "1:5",
        "name_hr": "1:5",
        "name_fr": "1:5"
    },
    "0^live_match^2^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2072^live_match^8^away_after_extra_points": {
        "name_en": "away after extra points",
        "name_aa": "away after extra points",
        "name_de": "Auswart nach extra Punkte",
        "name_nl": "Uitteam Na extra punten",
        "name_pl": "away after extra points",
        "name_ro": "away after extra points",
        "name_ru": "away after extra points",
        "name_tr": "away after extra points",
        "name_se": "away after extra points",
        "name_el": "away after extra points",
        "name_es": "away after extra points",
        "name_hr": "away after extra points",
        "name_fr": "away after extra points"
    },
    "2041^live_match^8^no": {
        "name_en": "no",
        "name_aa": "no",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "no",
        "name_ro": "no",
        "name_ru": "no",
        "name_tr": "no",
        "name_se": "no",
        "name_el": "no",
        "name_es": "no",
        "name_hr": "Ne",
        "name_fr": "no"
    },
    "2072^live_match^8^12:21": {
        "name_en": "12:21",
        "name_aa": "12:21",
        "name_de": "12:21",
        "name_nl": "12:21",
        "name_pl": "12:21",
        "name_ro": "12:21",
        "name_ru": "12:21",
        "name_tr": "12:21",
        "name_se": "12:21",
        "name_el": "12:21",
        "name_es": "12:21",
        "name_hr": "12:21",
        "name_fr": "12:21"
    },
    "2072^live_match^8^19:21": {
        "name_en": "19:21",
        "name_aa": "19:21",
        "name_de": "19:21",
        "name_nl": "19:21",
        "name_pl": "19:21",
        "name_ro": "19:21",
        "name_ru": "19:21",
        "name_tr": "19:21",
        "name_se": "19:21",
        "name_el": "19:21",
        "name_es": "19:21",
        "name_hr": "19:21",
        "name_fr": "19:21"
    },
    "2066^live_match^6^other": {
        "name_en": "other",
        "name_aa": "other",
        "name_de": "Sonstig",
        "name_nl": "Andere",
        "name_pl": "other",
        "name_ro": "other",
        "name_ru": "other",
        "name_tr": "other",
        "name_se": "other",
        "name_el": "other",
        "name_es": "other",
        "name_hr": "Ostali",
        "name_fr": "other"
    },
    "143^live_match^8^over": {
        "name_en": "Over",
        "name_aa": "Over",
        "name_de": "Über",
        "name_nl": "Over",
        "name_pl": "Over",
        "name_ro": "Over",
        "name_ru": "Over",
        "name_tr": "Üst",
        "name_se": "Over",
        "name_el": "Over",
        "name_es": "Over",
        "name_hr": "Više",
        "name_fr": "p"
    },
    "2326^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "1529^live_match^8^draw_and_over": {
        "name_en": "draw and over",
        "name_aa": "draw and over",
        "name_de": "Unentschieden und Über",
        "name_nl": "gelijk en boven",
        "name_pl": "draw and over",
        "name_ro": "draw and over",
        "name_ru": "draw and over",
        "name_tr": "beraberlik ve üst",
        "name_se": "draw and over",
        "name_el": "draw and over",
        "name_es": "draw and over",
        "name_hr": "neriješeno i Više",
        "name_fr": "match nul et Plus de"
    },
    "878^live_match^8^under": {
        "name_en": "Under",
        "name_aa": "u",
        "name_de": "Unter",
        "name_nl": "u",
        "name_pl": "u",
        "name_ro": "u",
        "name_ru": "u",
        "name_tr": "Alt",
        "name_se": "u",
        "name_el": "u",
        "name_es": "u",
        "name_hr": "Manje",
        "name_fr": "m"
    },
    "2354^live_match^8^0:3": {
        "name_en": "0:3",
        "name_aa": "0:3",
        "name_de": "0:3",
        "name_nl": "0:3",
        "name_pl": "0:3",
        "name_ro": "0:3",
        "name_ru": "0:3",
        "name_tr": "0:3",
        "name_se": "0:3",
        "name_el": "0:3",
        "name_es": "0:3",
        "name_hr": "0:3",
        "name_fr": "0:3"
    },
    "518^live_match^8^yes": {
        "name_en": "Yes",
        "name_aa": "Yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "Yes",
        "name_ro": "Yes",
        "name_ru": "Yes",
        "name_tr": "evet",
        "name_se": "Yes",
        "name_el": "Yes",
        "name_es": "Yes",
        "name_hr": "Da",
        "name_fr": "oui"
    },
    "26^live_match^8^19:0": {
        "name_en": "19:0",
        "name_aa": "19:0",
        "name_de": "19:0",
        "name_nl": "19:0",
        "name_pl": "19:0",
        "name_ro": "19:0",
        "name_ru": "19:0",
        "name_tr": "19:0",
        "name_se": "19:0",
        "name_el": "19:0",
        "name_es": "19:0",
        "name_hr": "19:0",
        "name_fr": "19:0"
    },
    "2063^live_match^8^home_17-20": {
        "name_en": "home 17-20",
        "name_aa": "home 17-20",
        "name_de": "Heim 17-20",
        "name_nl": "thuis 17-20",
        "name_pl": "home 17-20",
        "name_ro": "home 17-20",
        "name_ru": "home 17-20",
        "name_tr": "home 17-20",
        "name_se": "home 17-20",
        "name_el": "home 17-20",
        "name_es": "home 17-20",
        "name_hr": "Domači 17-20",
        "name_fr": "home 17-20"
    },
    "1510^live_match^8^home": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "0^live_match^5^o": {
        "name_en": "Over",
        "name_aa": "Over",
        "name_de": "Über",
        "name_nl": "Over",
        "name_pl": "Over",
        "name_ro": "Over",
        "name_ru": "Over",
        "name_tr": "Üst",
        "name_se": "Over",
        "name_el": "Over",
        "name_es": "Over",
        "name_hr": "Više",
        "name_fr": "p"
    },
    "359^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2332^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2301^live_match^8^none": {
        "name_en": "none",
        "name_aa": "none",
        "name_de": "Keiner",
        "name_nl": "Geen",
        "name_pl": "none",
        "name_ro": "none",
        "name_ru": "none",
        "name_tr": "none",
        "name_se": "none",
        "name_el": "none",
        "name_es": "none",
        "name_hr": "Nitko",
        "name_fr": "none"
    },
    "2207^live_match^8^home_13-24": {
        "name_en": "home 13-24",
        "name_aa": "home 13-24",
        "name_de": "Heim 13-24",
        "name_nl": "Thuis 13-24",
        "name_pl": "home 13-24",
        "name_ro": "home 13-24",
        "name_ru": "home 13-24",
        "name_tr": "home 13-24",
        "name_se": "home 13-24",
        "name_el": "home 13-24",
        "name_es": "home 13-24",
        "name_hr": "Domači 13-24",
        "name_fr": "home 13-24"
    },
    "2305^live_match^8^3:7": {
        "name_en": "3:7",
        "name_aa": "3:7",
        "name_de": "3:7",
        "name_nl": "3:7",
        "name_pl": "3:7",
        "name_ro": "3:7",
        "name_ru": "3:7",
        "name_tr": "3:7",
        "name_se": "3:7",
        "name_el": "3:7",
        "name_es": "3:7",
        "name_hr": "3:7",
        "name_fr": "3:7"
    },
    "2075^live_match^8^home_after_extra_points": {
        "name_en": "home after extra points",
        "name_aa": "home after extra points",
        "name_de": "Heimmannschaft Nach Verlängerung",
        "name_nl": "Thuisteam Na extra punten",
        "name_pl": "home after extra points",
        "name_ro": "home after extra points",
        "name_ru": "home after extra points",
        "name_tr": "home after extra points",
        "name_se": "home after extra points",
        "name_el": "home after extra points",
        "name_es": "home after extra points",
        "name_hr": "home after extra points",
        "name_fr": "home after extra points"
    },
    "2101^live_match^8^yes": {
        "name_en": "yes",
        "name_aa": "yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "yes",
        "name_ro": "yes",
        "name_ru": "yes",
        "name_tr": "yes",
        "name_se": "yes",
        "name_el": "yes",
        "name_es": "yes",
        "name_hr": "Da",
        "name_fr": "yes"
    },
    "2364^live_match^8^away_and_over_and_gg": {
        "name_en": "away and over and GG",
        "name_aa": "away and over and GG",
        "name_de": "Auswart und Über und GG",
        "name_nl": "Weg en meer dan en GG",
        "name_pl": "away and over and GG",
        "name_ro": "away and over and GG",
        "name_ru": "away and over and GG",
        "name_tr": "away and over and GG",
        "name_se": "away and over and GG",
        "name_el": "away and over and GG",
        "name_es": "away and over and GG",
        "name_hr": "away and over and GG",
        "name_fr": "away and over and GG"
    },
    "2305^live_match^8^7:4": {
        "name_en": "7:4",
        "name_aa": "7:4",
        "name_de": "7:4",
        "name_nl": "7:4",
        "name_pl": "7:4",
        "name_ro": "7:4",
        "name_ru": "7:4",
        "name_tr": "7:4",
        "name_se": "7:4",
        "name_el": "7:4",
        "name_es": "7:4",
        "name_hr": "7:4",
        "name_fr": "7:4"
    },
    "2089^live_match^8^30:0": {
        "name_en": "30:0",
        "name_aa": "30:0",
        "name_de": "30:0",
        "name_nl": "30:0",
        "name_pl": "30:0",
        "name_ro": "30:0",
        "name_ru": "30:0",
        "name_tr": "30:0",
        "name_se": "30:0",
        "name_el": "30:0",
        "name_es": "30:0",
        "name_hr": "30:0",
        "name_fr": "30:0"
    },
    "26^live_match^8^11:6": {
        "name_en": "11:6",
        "name_aa": "11:6",
        "name_de": "11:6",
        "name_nl": "11:6",
        "name_pl": "11:6",
        "name_ro": "11:6",
        "name_ru": "11:6",
        "name_tr": "11:6",
        "name_se": "11:6",
        "name_el": "11:6",
        "name_es": "11:6",
        "name_hr": "11:6",
        "name_fr": "11:6"
    },
    "2082^live_match^8^3:6_or_4:6": {
        "name_en": "3:6 or 4:6",
        "name_aa": "3:6 or 4:6",
        "name_de": "3:6 oder 4:6",
        "name_nl": "3:6 of 4:6",
        "name_pl": "3:6 or 4:6",
        "name_ro": "3:6 or 4:6",
        "name_ru": "3:6 or 4:6",
        "name_tr": "3:6 or 4:6",
        "name_se": "3:6 or 4:6",
        "name_el": "3:6 or 4:6",
        "name_es": "3:6 or 4:6",
        "name_hr": "3:6 or 4:6",
        "name_fr": "3:6 or 4:6"
    },
    "1324^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2073^live_match^8^23:25": {
        "name_en": "23:25",
        "name_aa": "23:25",
        "name_de": "23:25",
        "name_nl": "23:25",
        "name_pl": "23:25",
        "name_ro": "23:25",
        "name_ru": "23:25",
        "name_tr": "23:25",
        "name_se": "23:25",
        "name_el": "23:25",
        "name_es": "23:25",
        "name_hr": "23:25",
        "name_fr": "23:25"
    },
    "1108^live_match^8^5:3": {
        "name_en": "5:3",
        "name_aa": "5:3",
        "name_de": "5:3",
        "name_nl": "5:3",
        "name_pl": "5:3",
        "name_ro": "5:3",
        "name_ru": "5:3",
        "name_tr": "5:3",
        "name_se": "5:3",
        "name_el": "5:3",
        "name_es": "5:3",
        "name_hr": "5:3",
        "name_fr": "5:3"
    },
    "2364^live_match^8^draw_and_over_and_gg": {
        "name_en": "draw and over and GG",
        "name_aa": "draw and over and GG",
        "name_de": "Unentschieden und Über GG",
        "name_nl": "Gelijkspel en meer dan en GG",
        "name_pl": "draw and over and GG",
        "name_ro": "draw and over and GG",
        "name_ru": "draw and over and GG",
        "name_tr": "draw and over and GG",
        "name_se": "draw and over and GG",
        "name_el": "draw and over and GG",
        "name_es": "draw and over and GG",
        "name_hr": "draw and over and GG",
        "name_fr": "draw and over and GG"
    },
    "2073^live_match^8^25:17": {
        "name_en": "25:17",
        "name_aa": "25:17",
        "name_de": "25:17",
        "name_nl": "25:17",
        "name_pl": "25:17",
        "name_ro": "25:17",
        "name_ru": "25:17",
        "name_tr": "25:17",
        "name_se": "25:17",
        "name_el": "25:17",
        "name_es": "25:17",
        "name_hr": "25:17",
        "name_fr": "25:17"
    },
    "2111^live_match^8^home_5+": {
        "name_en": "home 5+",
        "name_aa": "home 5+",
        "name_de": "Heim 5+",
        "name_nl": "Thuis 5+",
        "name_pl": "home 5+",
        "name_ro": "home 5+",
        "name_ru": "home 5+",
        "name_tr": "home 5+",
        "name_se": "home 5+",
        "name_el": "home 5+",
        "name_es": "home 5+",
        "name_hr": "Domači 5+",
        "name_fr": "home 5+"
    },
    "2113^live_match^8^3": {
        "name_en": "3",
        "name_aa": "3",
        "name_de": "3",
        "name_nl": "3",
        "name_pl": "3",
        "name_ro": "3",
        "name_ru": "3",
        "name_tr": "3",
        "name_se": "3",
        "name_el": "3",
        "name_es": "3",
        "name_hr": "3",
        "name_fr": "3"
    },
    "2074^live_match^8^25:20": {
        "name_en": "25:20",
        "name_aa": "25:20",
        "name_de": "25:20",
        "name_nl": "25:20",
        "name_pl": "25:20",
        "name_ro": "25:20",
        "name_ru": "25:20",
        "name_tr": "25:20",
        "name_se": "25:20",
        "name_el": "25:20",
        "name_es": "25:20",
        "name_hr": "25:20",
        "name_fr": "25:20"
    },
    "2354^live_match^8^2:14": {
        "name_en": "2:14",
        "name_aa": "2:14",
        "name_de": "2:14",
        "name_nl": "2:14",
        "name_pl": "2:14",
        "name_ro": "2:14",
        "name_ru": "2:14",
        "name_tr": "2:14",
        "name_se": "2:14",
        "name_el": "2:14",
        "name_es": "2:14",
        "name_hr": "2:14",
        "name_fr": "2:14"
    },
    "2074^live_match^8^15:25_or_better": {
        "name_en": "15:25 or better",
        "name_aa": "15:25 or better",
        "name_de": "15:25 oder besser",
        "name_nl": "15:25 of beter",
        "name_pl": "15:25 or better",
        "name_ro": "15:25 or better",
        "name_ru": "15:25 or better",
        "name_tr": "15:25 or better",
        "name_se": "15:25 or better",
        "name_el": "15:25 or better",
        "name_es": "15:25 or better",
        "name_hr": "15:25 or better",
        "name_fr": "15:25 or better"
    },
    "26^live_match^8^3:14": {
        "name_en": "3:14",
        "name_aa": "3:14",
        "name_de": "3:14",
        "name_nl": "3:14",
        "name_pl": "3:14",
        "name_ro": "3:14",
        "name_ru": "3:14",
        "name_tr": "3:14",
        "name_se": "3:14",
        "name_el": "3:14",
        "name_es": "3:14",
        "name_hr": "3:14",
        "name_fr": "3:14"
    },
    "1156^live_match^8^1:2": {
        "name_en": "1:2",
        "name_aa": "1:2",
        "name_de": "1:2",
        "name_nl": "1:2",
        "name_pl": "1:2",
        "name_ro": "1:2",
        "name_ru": "1:2",
        "name_tr": "1:2",
        "name_se": "1:2",
        "name_el": "1:2",
        "name_es": "1:2",
        "name_hr": "1:2",
        "name_fr": "1:2"
    },
    "2183^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "898^live_match^6^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "1108^live_match^8^10:0": {
        "name_en": "10:0",
        "name_aa": "10:0",
        "name_de": "10:0",
        "name_nl": "10:0",
        "name_pl": "10:0",
        "name_ro": "10:0",
        "name_ru": "10:0",
        "name_tr": "10:0",
        "name_se": "10:0",
        "name_el": "10:0",
        "name_es": "10:0",
        "name_hr": "10:0",
        "name_fr": "10:0"
    },
    "2354^live_match^8^10:8": {
        "name_en": "10:8",
        "name_aa": "10:8",
        "name_de": "10:8",
        "name_nl": "10:8",
        "name_pl": "10:8",
        "name_ro": "10:8",
        "name_ru": "10:8",
        "name_tr": "10:8",
        "name_se": "10:8",
        "name_el": "10:8",
        "name_es": "10:8",
        "name_hr": "10:8",
        "name_fr": "10:8"
    },
    "1108^live_match^8^0:11": {
        "name_en": "0:11",
        "name_aa": "0:11",
        "name_de": "0:11",
        "name_nl": "0:11",
        "name_pl": "0:11",
        "name_ro": "0:11",
        "name_ru": "0:11",
        "name_tr": "0:11",
        "name_se": "0:11",
        "name_el": "0:11",
        "name_es": "0:11",
        "name_hr": "0:11",
        "name_fr": "0:11"
    },
    "2261^live_match^8^1:5": {
        "name_en": "1:5",
        "name_aa": "1:5",
        "name_de": "1:5",
        "name_nl": "1:5",
        "name_pl": "1:5",
        "name_ro": "1:5",
        "name_ru": "1:5",
        "name_tr": "1:5",
        "name_se": "1:5",
        "name_el": "1:5",
        "name_es": "1:5",
        "name_hr": "1:5",
        "name_fr": "1:5"
    },
    "2249^live_match^8^no": {
        "name_en": "no",
        "name_aa": "no",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "no",
        "name_ro": "no",
        "name_ru": "no",
        "name_tr": "no",
        "name_se": "no",
        "name_el": "no",
        "name_es": "no",
        "name_hr": "Ne",
        "name_fr": "no"
    },
    "1108^live_match^8^4:10": {
        "name_en": "4:10",
        "name_aa": "4:10",
        "name_de": "4:10",
        "name_nl": "4:10",
        "name_pl": "4:10",
        "name_ro": "4:10",
        "name_ru": "4:10",
        "name_tr": "4:10",
        "name_se": "4:10",
        "name_el": "4:10",
        "name_es": "4:10",
        "name_hr": "4:10",
        "name_fr": "4:10"
    },
    "2118^live_match^6^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2111^live_match^8^away_1": {
        "name_en": "away 1",
        "name_aa": "away 1",
        "name_de": "Auswärt 1",
        "name_nl": "Weg 1",
        "name_pl": "away 1",
        "name_ro": "away 1",
        "name_ru": "away 1",
        "name_tr": "away 1",
        "name_se": "away 1",
        "name_el": "away 1",
        "name_es": "away 1",
        "name_hr": "Gostujuči 1",
        "name_fr": "away 1"
    },
    "2053^live_match^6^x": {
        "name_en": "x",
        "name_aa": "x",
        "name_de": "x",
        "name_nl": "x",
        "name_pl": "x",
        "name_ro": "x",
        "name_ru": "x",
        "name_tr": "x",
        "name_se": "x",
        "name_el": "x",
        "name_es": "x",
        "name_hr": "x",
        "name_fr": "x"
    },
    "1108^live_match^8^14:0": {
        "name_en": "14:0",
        "name_aa": "14:0",
        "name_de": "14:0",
        "name_nl": "14:0",
        "name_pl": "14:0",
        "name_ro": "14:0",
        "name_ru": "14:0",
        "name_tr": "14:0",
        "name_se": "14:0",
        "name_el": "14:0",
        "name_es": "14:0",
        "name_hr": "14:0",
        "name_fr": "14:0"
    },
    "2154^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2213^live_match^8^away_13-18": {
        "name_en": "away 13-18",
        "name_aa": "away 13-18",
        "name_de": "Auswärt 13-18",
        "name_nl": "Weg 13-18",
        "name_pl": "away 13-18",
        "name_ro": "away 13-18",
        "name_ru": "away 13-18",
        "name_tr": "away 13-18",
        "name_se": "away 13-18",
        "name_el": "away 13-18",
        "name_es": "away 13-18",
        "name_hr": "Gostujuči 13-18",
        "name_fr": "away 13-18"
    },
    "1156^live_match^8^4:3": {
        "name_en": "4:3",
        "name_aa": "4:3",
        "name_de": "4:3",
        "name_nl": "4:3",
        "name_pl": "4:3",
        "name_ro": "4:3",
        "name_ru": "4:3",
        "name_tr": "4:3",
        "name_se": "4:3",
        "name_el": "4:3",
        "name_es": "4:3",
        "name_hr": "4:3",
        "name_fr": "4:3"
    },
    "1040^live_match^8^draw_and_no": {
        "name_en": "draw and no",
        "name_aa": "draw and no",
        "name_de": "Unentschieden und Nein",
        "name_nl": "gelijkspel en nee",
        "name_pl": "draw and no",
        "name_ro": "draw and no",
        "name_ru": "draw and no",
        "name_tr": "berabere biter ve iki takımda gol atamaz",
        "name_se": "draw and no",
        "name_el": "draw and no",
        "name_es": "draw and no",
        "name_hr": "neriješeno i ne",
        "name_fr": "Match nul et Non"
    },
    "117^live_match^8^4:0": {
        "name_en": "4:0",
        "name_aa": "4:0",
        "name_de": "4:0",
        "name_nl": "4:0",
        "name_pl": "4:0",
        "name_ro": "4:0",
        "name_ru": "4:0",
        "name_tr": "4:0",
        "name_se": "4:0",
        "name_el": "4:0",
        "name_es": "4:0",
        "name_hr": "4:0",
        "name_fr": "4:0"
    },
    "26^live_match^8^17:3": {
        "name_en": "17:3",
        "name_aa": "17:3",
        "name_de": "17:3",
        "name_nl": "17:3",
        "name_pl": "17:3",
        "name_ro": "17:3",
        "name_ru": "17:3",
        "name_tr": "17:3",
        "name_se": "17:3",
        "name_el": "17:3",
        "name_es": "17:3",
        "name_hr": "17:3",
        "name_fr": "17:3"
    },
    "2354^live_match^8^5:9": {
        "name_en": "5:9",
        "name_aa": "5:9",
        "name_de": "5:9",
        "name_nl": "5:9",
        "name_pl": "5:9",
        "name_ro": "5:9",
        "name_ru": "5:9",
        "name_tr": "5:9",
        "name_se": "5:9",
        "name_el": "5:9",
        "name_es": "5:9",
        "name_hr": "5:9",
        "name_fr": "5:9"
    },
    "1206^live_match^8^green": {
        "name_en": "green",
        "name_aa": "green",
        "name_de": "grün",
        "name_nl": "groen",
        "name_pl": "green",
        "name_ro": "green",
        "name_ru": "green",
        "name_tr": "yeşil",
        "name_se": "green",
        "name_el": "green",
        "name_es": "green",
        "name_hr": "zelena",
        "name_fr": "vert"
    },
    "2107^live_match^8^home": {
        "name_en": "home",
        "name_aa": "home",
        "name_de": "Heim",
        "name_nl": "Thuis",
        "name_pl": "home",
        "name_ro": "home",
        "name_ru": "home",
        "name_tr": "home",
        "name_se": "home",
        "name_el": "home",
        "name_es": "home",
        "name_hr": "Domači",
        "name_fr": "home"
    },
    "26^live_match^8^4:10": {
        "name_en": "4:10",
        "name_aa": "4:10",
        "name_de": "4:10",
        "name_nl": "4:10",
        "name_pl": "4:10",
        "name_ro": "4:10",
        "name_ru": "4:10",
        "name_tr": "4:10",
        "name_se": "4:10",
        "name_el": "4:10",
        "name_es": "4:10",
        "name_hr": "4:10",
        "name_fr": "4:10"
    },
    "2210^live_match^8^14-15": {
        "name_en": "home 14-15",
        "name_aa": "home 14-15",
        "name_de": "Heim 14-15",
        "name_nl": "Thuis 14-15",
        "name_pl": "home 14-15",
        "name_ro": "home 14-15",
        "name_ru": "home 14-15",
        "name_tr": "home 14-15",
        "name_se": "home 14-15",
        "name_el": "home 14-15",
        "name_es": "home 14-15",
        "name_hr": "Domači 14-15",
        "name_fr": "home 14-15"
    },
    "2328^live_match^8^any_other_outcome": {
        "name_en": "Any other Outcome",
        "name_aa": "Any other Outcome",
        "name_de": "Any other Outcome",
        "name_nl": "Any other Outcome",
        "name_pl": "Any other Outcome",
        "name_ro": "Any other Outcome",
        "name_ru": "Any other Outcome",
        "name_tr": "Any other Outcome",
        "name_se": "Any other Outcome",
        "name_el": "Any other Outcome",
        "name_es": "Any other Outcome",
        "name_hr": "Any other Outcome",
        "name_fr": "Any other Outcome"
    },
    "2306^live_match^8^home": {
        "name_en": "home",
        "name_aa": "home",
        "name_de": "Heim",
        "name_nl": "Thuis",
        "name_pl": "home",
        "name_ro": "home",
        "name_ru": "home",
        "name_tr": "home",
        "name_se": "home",
        "name_el": "home",
        "name_es": "home",
        "name_hr": "Domači",
        "name_fr": "home"
    },
    "2143^live_match^8^tie": {
        "name_en": "Tie",
        "name_aa": "Tie",
        "name_de": "Unentschieden",
        "name_nl": "Gelijkspel",
        "name_pl": "Tie",
        "name_ro": "Tie",
        "name_ru": "Tie",
        "name_tr": "Tie",
        "name_se": "Tie",
        "name_el": "Tie",
        "name_es": "Tie",
        "name_hr": "Tie",
        "name_fr": "Tie"
    },
    "2261^live_match^8^2:1": {
        "name_en": "2:1",
        "name_aa": "2:1",
        "name_de": "2:1",
        "name_nl": "2:1",
        "name_pl": "2:1",
        "name_ro": "2:1",
        "name_ru": "2:1",
        "name_tr": "2:1",
        "name_se": "2:1",
        "name_el": "2:1",
        "name_es": "2:1",
        "name_hr": "2:1",
        "name_fr": "2:1"
    },
    "2073^live_match^8^21:25": {
        "name_en": "21:25",
        "name_aa": "21:25",
        "name_de": "21:25",
        "name_nl": "21:25",
        "name_pl": "21:25",
        "name_ro": "21:25",
        "name_ru": "21:25",
        "name_tr": "21:25",
        "name_se": "21:25",
        "name_el": "21:25",
        "name_es": "21:25",
        "name_hr": "21:25",
        "name_fr": "21:25"
    },
    "2058^live_match^8^da": {
        "name_en": "DA",
        "name_aa": "DA",
        "name_de": "DA",
        "name_nl": "DA",
        "name_pl": "DA",
        "name_ro": "DA",
        "name_ru": "DA",
        "name_tr": "DA",
        "name_se": "DA",
        "name_el": "DA",
        "name_es": "DA",
        "name_hr": "Da",
        "name_fr": "DA"
    },
    "2354^live_match^8^8:12": {
        "name_en": "8:12",
        "name_aa": "8:12",
        "name_de": "8:12",
        "name_nl": "8:12",
        "name_pl": "8:12",
        "name_ro": "8:12",
        "name_ru": "8:12",
        "name_tr": "8:12",
        "name_se": "8:12",
        "name_el": "8:12",
        "name_es": "8:12",
        "name_hr": "8:12",
        "name_fr": "8:12"
    },
    "2072^live_match^8^21:16": {
        "name_en": "21:16",
        "name_aa": "21:16",
        "name_de": "21:16",
        "name_nl": "21:16",
        "name_pl": "21:16",
        "name_ro": "21:16",
        "name_ru": "21:16",
        "name_tr": "21:16",
        "name_se": "21:16",
        "name_el": "21:16",
        "name_es": "21:16",
        "name_hr": "21:16",
        "name_fr": "21:16"
    },
    "53^live_match^8^over": {
        "name_en": "Over",
        "name_aa": "Over",
        "name_de": "Über",
        "name_nl": "Over",
        "name_pl": "Over",
        "name_ro": "Over",
        "name_ru": "Over",
        "name_tr": "Üst",
        "name_se": "Over",
        "name_el": "Over",
        "name_es": "Over",
        "name_hr": "Više",
        "name_fr": "p"
    },
    "6^live_match^6^x": {
        "name_en": "x",
        "name_aa": "x",
        "name_de": "x",
        "name_nl": "x",
        "name_pl": "x",
        "name_ro": "x",
        "name_ru": "x",
        "name_tr": "x",
        "name_se": "x",
        "name_el": "x",
        "name_es": "x",
        "name_hr": "x",
        "name_fr": "x"
    },
    "1108^live_match^8^13:4": {
        "name_en": "13:4",
        "name_aa": "13:4",
        "name_de": "13:4",
        "name_nl": "13:4",
        "name_pl": "13:4",
        "name_ro": "13:4",
        "name_ru": "13:4",
        "name_tr": "13:4",
        "name_se": "13:4",
        "name_el": "13:4",
        "name_es": "13:4",
        "name_hr": "13:4",
        "name_fr": "13:4"
    },
    "1156^live_match^8^1:4": {
        "name_en": "1:4",
        "name_aa": "1:4",
        "name_de": "1:4",
        "name_nl": "1:4",
        "name_pl": "1:4",
        "name_ro": "1:4",
        "name_ru": "1:4",
        "name_tr": "1:4",
        "name_se": "1:4",
        "name_el": "1:4",
        "name_es": "1:4",
        "name_hr": "1:4",
        "name_fr": "1:4"
    },
    "2073^live_match^8^21:16": {
        "name_en": "21:16",
        "name_aa": "21:16",
        "name_de": "21:16",
        "name_nl": "21:16",
        "name_pl": "21:16",
        "name_ro": "21:16",
        "name_ru": "21:16",
        "name_tr": "21:16",
        "name_se": "21:16",
        "name_el": "21:16",
        "name_es": "21:16",
        "name_hr": "21:16",
        "name_fr": "21:16"
    },
    "26^live_match^8^8:8": {
        "name_en": "8:8",
        "name_aa": "8:8",
        "name_de": "8:8",
        "name_nl": "8:8",
        "name_pl": "8:8",
        "name_ro": "8:8",
        "name_ru": "8:8",
        "name_tr": "8:8",
        "name_se": "8:8",
        "name_el": "8:8",
        "name_es": "8:8",
        "name_hr": "8:8",
        "name_fr": "8:8"
    },
    "2040^live_match^8^no": {
        "name_en": "no",
        "name_aa": "no",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "no",
        "name_ro": "no",
        "name_ru": "no",
        "name_tr": "no",
        "name_se": "no",
        "name_el": "no",
        "name_es": "no",
        "name_hr": "Ne",
        "name_fr": "no"
    },
    "2007^live_match^8^exactly": {
        "name_en": "exactly",
        "name_aa": "exactly",
        "name_de": "Genau",
        "name_nl": "Precies",
        "name_pl": "exactly",
        "name_ro": "exactly",
        "name_ru": "exactly",
        "name_tr": "exactly",
        "name_se": "exactly",
        "name_el": "exactly",
        "name_es": "exactly",
        "name_hr": "Tacno",
        "name_fr": "exactly"
    },
    "29^live_match^8^0:9": {
        "name_en": "0:9",
        "name_aa": "0:9",
        "name_de": "0:9",
        "name_nl": "0:9",
        "name_pl": "0:9",
        "name_ro": "0:9",
        "name_ru": "0:9",
        "name_tr": "0:9",
        "name_se": "0:9",
        "name_el": "0:9",
        "name_es": "0:9",
        "name_hr": "0:9",
        "name_fr": "0:9"
    },
    "2022^live_match^8^home": {
        "name_en": "home",
        "name_aa": "home",
        "name_de": "Heim",
        "name_nl": "Thuis",
        "name_pl": "home",
        "name_ro": "home",
        "name_ru": "home",
        "name_tr": "home",
        "name_se": "home",
        "name_el": "home",
        "name_es": "home",
        "name_hr": "Domači",
        "name_fr": "home"
    },
    "116^live_match^8^2:0": {
        "name_en": "2:0",
        "name_aa": "2:0",
        "name_de": "2:0",
        "name_nl": "2:0",
        "name_pl": "2:0",
        "name_ro": "2:0",
        "name_ru": "2:0",
        "name_tr": "2:0",
        "name_se": "2:0",
        "name_el": "2:0",
        "name_es": "2:0",
        "name_hr": "2:0",
        "name_fr": "2:0"
    },
    "1156^live_match^8^2:1": {
        "name_en": "2:1",
        "name_aa": "2:1",
        "name_de": "2:1",
        "name_nl": "2:1",
        "name_pl": "2:1",
        "name_ro": "2:1",
        "name_ru": "2:1",
        "name_tr": "2:1",
        "name_se": "2:1",
        "name_el": "2:1",
        "name_es": "2:1",
        "name_hr": "2:1",
        "name_fr": "2:1"
    },
    "2027^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "1108^live_match^8^3:4": {
        "name_en": "3:4",
        "name_aa": "3:4",
        "name_de": "3:4",
        "name_nl": "3:4",
        "name_pl": "3:4",
        "name_ro": "3:4",
        "name_ru": "3:4",
        "name_tr": "3:4",
        "name_se": "3:4",
        "name_el": "3:4",
        "name_es": "3:4",
        "name_hr": "3:4",
        "name_fr": "3:4"
    },
    "2212^live_match^8^draw": {
        "name_en": "draw",
        "name_aa": "draw",
        "name_de": "Unentschieden",
        "name_nl": "Gelijkspel",
        "name_pl": "draw",
        "name_ro": "draw",
        "name_ru": "draw",
        "name_tr": "draw",
        "name_se": "draw",
        "name_el": "draw",
        "name_es": "draw",
        "name_hr": "Neriješeno",
        "name_fr": "draw"
    },
    "976^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "26^live_match^8^2:12": {
        "name_en": "2:12",
        "name_aa": "2:12",
        "name_de": "2:12",
        "name_nl": "2:12",
        "name_pl": "2:12",
        "name_ro": "2:12",
        "name_ru": "2:12",
        "name_tr": "2:12",
        "name_se": "2:12",
        "name_el": "2:12",
        "name_es": "2:12",
        "name_hr": "2:12",
        "name_fr": "2:12"
    },
    "520^live_match^8^7:6": {
        "name_en": "7:6",
        "name_aa": "7:6",
        "name_de": "7:6",
        "name_nl": "7:6",
        "name_pl": "7:6",
        "name_ro": "7:6",
        "name_ru": "7:6",
        "name_tr": "7:6",
        "name_se": "7:6",
        "name_el": "7:6",
        "name_es": "7:6",
        "name_hr": "7:6",
        "name_fr": "7:6"
    },
    "2065^live_match^6^away_win_by_3+": {
        "name_en": "away win by 3+",
        "name_aa": "away win by 3+",
        "name_de": "Auswart gewinnt durch 3+",
        "name_nl": "uitoverwinning met 3+",
        "name_pl": "away win by 3+",
        "name_ro": "away win by 3+",
        "name_ru": "away win by 3+",
        "name_tr": "away win by 3+",
        "name_se": "away win by 3+",
        "name_el": "away win by 3+",
        "name_es": "away win by 3+",
        "name_hr": "Gostujuči pobijediti po 3+",
        "name_fr": "away win by 3+"
    },
    "2248^live_match^8^exactly": {
        "name_en": "exactly",
        "name_aa": "exactly",
        "name_de": "Genau",
        "name_nl": "Precies",
        "name_pl": "exactly",
        "name_ro": "exactly",
        "name_ru": "exactly",
        "name_tr": "exactly",
        "name_se": "exactly",
        "name_el": "exactly",
        "name_es": "exactly",
        "name_hr": "Tacno",
        "name_fr": "exactly"
    },
    "1062^live_match^8^under": {
        "name_en": "Under",
        "name_aa": "u",
        "name_de": "Unter",
        "name_nl": "u",
        "name_pl": "u",
        "name_ro": "u",
        "name_ru": "u",
        "name_tr": "Alt",
        "name_se": "u",
        "name_el": "u",
        "name_es": "u",
        "name_hr": "Manje",
        "name_fr": "m"
    },
    "26^live_match^8^18:2": {
        "name_en": "18:2",
        "name_aa": "18:2",
        "name_de": "18:2",
        "name_nl": "18:2",
        "name_pl": "18:2",
        "name_ro": "18:2",
        "name_ru": "18:2",
        "name_tr": "18:2",
        "name_se": "18:2",
        "name_el": "18:2",
        "name_es": "18:2",
        "name_hr": "18:2",
        "name_fr": "18:2"
    },
    "2354^live_match^8^9:11": {
        "name_en": "9:11",
        "name_aa": "9:11",
        "name_de": "9:11",
        "name_nl": "9:11",
        "name_pl": "9:11",
        "name_ro": "9:11",
        "name_ru": "9:11",
        "name_tr": "9:11",
        "name_se": "9:11",
        "name_el": "9:11",
        "name_es": "9:11",
        "name_hr": "9:11",
        "name_fr": "9:11"
    },
    "2062^live_match^8^home_9-10": {
        "name_en": "home 9-10",
        "name_aa": "home 9-10",
        "name_de": "Heim 9-10",
        "name_nl": "thuis 9-10",
        "name_pl": "home 9-10",
        "name_ro": "home 9-10",
        "name_ru": "home 9-10",
        "name_tr": "home 9-10",
        "name_se": "home 9-10",
        "name_el": "home 9-10",
        "name_es": "home 9-10",
        "name_hr": "Domači 9-10",
        "name_fr": "home 9-10"
    },
    "26^live_match^8^4:1": {
        "name_en": "4:1",
        "name_aa": "4:1",
        "name_de": "4:1",
        "name_nl": "4:1",
        "name_pl": "4:1",
        "name_ro": "4:1",
        "name_ru": "4:1",
        "name_tr": "4:1",
        "name_se": "4:1",
        "name_el": "4:1",
        "name_es": "4:1",
        "name_hr": "4:1",
        "name_fr": "4:1"
    },
    "1108^live_match^8^10:2": {
        "name_en": "10:2",
        "name_aa": "10:2",
        "name_de": "10:2",
        "name_nl": "10:2",
        "name_pl": "10:2",
        "name_ro": "10:2",
        "name_ru": "10:2",
        "name_tr": "10:2",
        "name_se": "10:2",
        "name_el": "10:2",
        "name_es": "10:2",
        "name_hr": "10:2",
        "name_fr": "10:2"
    },
    "2145^live_match^8^tie": {
        "name_en": "Tie",
        "name_aa": "Tie",
        "name_de": "Unentschieden",
        "name_nl": "Gelijkspel",
        "name_pl": "Tie",
        "name_ro": "Tie",
        "name_ru": "Tie",
        "name_tr": "Tie",
        "name_se": "Tie",
        "name_el": "Tie",
        "name_es": "Tie",
        "name_hr": "Tie",
        "name_fr": "Tie"
    },
    "2036^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "2315^live_match^8^2:4": {
        "name_en": "2:4",
        "name_aa": "2:4",
        "name_de": "2:4",
        "name_nl": "2:4",
        "name_pl": "2:4",
        "name_ro": "2:4",
        "name_ru": "2:4",
        "name_tr": "2:4",
        "name_se": "2:4",
        "name_el": "2:4",
        "name_es": "2:4",
        "name_hr": "2:4",
        "name_fr": "2:4"
    },
    "26^live_match^8^16:0": {
        "name_en": "16:0",
        "name_aa": "16:0",
        "name_de": "16:0",
        "name_nl": "16:0",
        "name_pl": "16:0",
        "name_ro": "16:0",
        "name_ru": "16:0",
        "name_tr": "16:0",
        "name_se": "16:0",
        "name_el": "16:0",
        "name_es": "16:0",
        "name_hr": "16:0",
        "name_fr": "16:0"
    },
    "29^live_match^8^5:2": {
        "name_en": "5:2",
        "name_aa": "5:2",
        "name_de": "5:2",
        "name_nl": "5:2",
        "name_pl": "5:2",
        "name_ro": "5:2",
        "name_ru": "5:2",
        "name_tr": "5:2",
        "name_se": "5:2",
        "name_el": "5:2",
        "name_es": "5:2",
        "name_hr": "5:2",
        "name_fr": "5:2"
    },
    "1224^live_match^8^no": {
        "name_en": "No",
        "name_aa": "No",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "No",
        "name_ro": "No",
        "name_ru": "No",
        "name_tr": "hayır",
        "name_se": "No",
        "name_el": "No",
        "name_es": "No",
        "name_hr": "Ne",
        "name_fr": "non"
    },
    "230^live_match^8^home_regular_time": {
        "name_en": "Home regular time",
        "name_aa": "Home regular time",
        "name_de": "reguläre Spielzeit - Heimmannschaft",
        "name_nl": "THuis reguliere speeltijd",
        "name_pl": "Home regular time",
        "name_ro": "Home regular time",
        "name_ru": "Home regular time",
        "name_tr": "normal süre sonunda ev sahibi",
        "name_se": "Home regular time",
        "name_el": "Home regular time",
        "name_es": "Home regular time",
        "name_hr": "Pobjeda domaćih nakon regularnog vremena",
        "name_fr": "Domicile temps réglementaire"
    },
    "1664^live_match^2^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "1108^live_match^8^3:11": {
        "name_en": "3:11",
        "name_aa": "3:11",
        "name_de": "3:11",
        "name_nl": "3:11",
        "name_pl": "3:11",
        "name_ro": "3:11",
        "name_ru": "3:11",
        "name_tr": "3:11",
        "name_se": "3:11",
        "name_el": "3:11",
        "name_es": "3:11",
        "name_hr": "3:11",
        "name_fr": "3:11"
    },
    "2236^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "2305^live_match^8^7:2": {
        "name_en": "7:2",
        "name_aa": "7:2",
        "name_de": "7:2",
        "name_nl": "7:2",
        "name_pl": "7:2",
        "name_ro": "7:2",
        "name_ru": "7:2",
        "name_tr": "7:2",
        "name_se": "7:2",
        "name_el": "7:2",
        "name_es": "7:2",
        "name_hr": "7:2",
        "name_fr": "7:2"
    },
    "2128^live_match^8^11:6": {
        "name_en": "11:6",
        "name_aa": "11:6",
        "name_de": "11:6",
        "name_nl": "11:6",
        "name_pl": "11:6",
        "name_ro": "11:6",
        "name_ru": "11:6",
        "name_tr": "11:6",
        "name_se": "11:6",
        "name_el": "11:6",
        "name_es": "11:6",
        "name_hr": "11:6",
        "name_fr": "11:6"
    },
    "1523^live_match^8^1x_and_under": {
        "name_en": "1X and under",
        "name_aa": "1X and under",
        "name_de": "1X und Unter",
        "name_nl": "1X and Minder dan",
        "name_pl": "1X and under",
        "name_ro": "1X and under",
        "name_ru": "1X and under",
        "name_tr": "1X and under",
        "name_se": "1X and under",
        "name_el": "1X and under",
        "name_es": "1X and under",
        "name_hr": "1X i manje",
        "name_fr": "1X and under"
    },
    "26^live_match^8^3:16": {
        "name_en": "3:16",
        "name_aa": "3:16",
        "name_de": "3:16",
        "name_nl": "3:16",
        "name_pl": "3:16",
        "name_ro": "3:16",
        "name_ru": "3:16",
        "name_tr": "3:16",
        "name_se": "3:16",
        "name_el": "3:16",
        "name_es": "3:16",
        "name_hr": "3:16",
        "name_fr": "3:16"
    },
    "2119^live_match^8^yes": {
        "name_en": "yes",
        "name_aa": "yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "yes",
        "name_ro": "yes",
        "name_ru": "yes",
        "name_tr": "yes",
        "name_se": "yes",
        "name_el": "yes",
        "name_es": "yes",
        "name_hr": "Da",
        "name_fr": "yes"
    },
    "2202^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "1108^live_match^8^7:9": {
        "name_en": "7:9",
        "name_aa": "7:9",
        "name_de": "7:9",
        "name_nl": "7:9",
        "name_pl": "7:9",
        "name_ro": "7:9",
        "name_ru": "7:9",
        "name_tr": "7:9",
        "name_se": "7:9",
        "name_el": "7:9",
        "name_es": "7:9",
        "name_hr": "7:9",
        "name_fr": "7:9"
    },
    "2198^live_match^8^home_16+": {
        "name_en": "home 16+",
        "name_aa": "home 16+",
        "name_de": "Heim 16+",
        "name_nl": "thuis 16+",
        "name_pl": "home 16+",
        "name_ro": "home 16+",
        "name_ru": "home 16+",
        "name_tr": "home 16+",
        "name_se": "home 16+",
        "name_el": "home 16+",
        "name_es": "home 16+",
        "name_hr": "Domači 16+",
        "name_fr": "home 16+"
    },
    "2140^live_match^8^away": {
        "name_en": "away",
        "name_aa": "away",
        "name_de": "Auswärts",
        "name_nl": "Uit",
        "name_pl": "away",
        "name_ro": "away",
        "name_ru": "away",
        "name_tr": "away",
        "name_se": "away",
        "name_el": "away",
        "name_es": "away",
        "name_hr": "Gostujući",
        "name_fr": "away"
    },
    "2334^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "2105^live_match^8^away": {
        "name_en": "away",
        "name_aa": "away",
        "name_de": "Auswärts",
        "name_nl": "Uit",
        "name_pl": "away",
        "name_ro": "away",
        "name_ru": "away",
        "name_tr": "away",
        "name_se": "away",
        "name_el": "away",
        "name_es": "away",
        "name_hr": "Gostujući",
        "name_fr": "away"
    },
    "2126^live_match^8^home_5+": {
        "name_en": "home 5+",
        "name_aa": "home 5+",
        "name_de": "Heim 5+",
        "name_nl": "Thuis 5+",
        "name_pl": "home 5+",
        "name_ro": "home 5+",
        "name_ru": "home 5+",
        "name_tr": "home 5+",
        "name_se": "home 5+",
        "name_el": "home 5+",
        "name_es": "home 5+",
        "name_hr": "Domači 5+",
        "name_fr": "home 5+"
    },
    "26^live_match^8^13:3": {
        "name_en": "13:3",
        "name_aa": "13:3",
        "name_de": "13:3",
        "name_nl": "13:3",
        "name_pl": "13:3",
        "name_ro": "13:3",
        "name_ru": "13:3",
        "name_tr": "13:3",
        "name_se": "13:3",
        "name_el": "13:3",
        "name_es": "13:3",
        "name_hr": "13:3",
        "name_fr": "13:3"
    },
    "986^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2074^live_match^8^15:5_or_better": {
        "name_en": "15:5 or better",
        "name_aa": "15:5 or better",
        "name_de": "15:5 oder besser",
        "name_nl": "15:5 of beter",
        "name_pl": "15:5 or better",
        "name_ro": "15:5 or better",
        "name_ru": "15:5 or better",
        "name_tr": "15:5 or better",
        "name_se": "15:5 or better",
        "name_el": "15:5 or better",
        "name_es": "15:5 or better",
        "name_hr": "15:5 or better",
        "name_fr": "15:5 or better"
    },
    "2145^live_match^8^home_10-12": {
        "name_en": "home 10-12",
        "name_aa": "home 10-12",
        "name_de": "Heim 10-12",
        "name_nl": "Thuis 10-12",
        "name_pl": "home 10-12",
        "name_ro": "home 10-12",
        "name_ru": "home 10-12",
        "name_tr": "home 10-12",
        "name_se": "home 10-12",
        "name_el": "home 10-12",
        "name_es": "home 10-12",
        "name_hr": "Domači 10-12",
        "name_fr": "home 10-12"
    },
    "1108^live_match^8^9:6": {
        "name_en": "9:6",
        "name_aa": "9:6",
        "name_de": "9:6",
        "name_nl": "9:6",
        "name_pl": "9:6",
        "name_ro": "9:6",
        "name_ru": "9:6",
        "name_tr": "9:6",
        "name_se": "9:6",
        "name_el": "9:6",
        "name_es": "9:6",
        "name_hr": "9:6",
        "name_fr": "9:6"
    },
    "2312^live_match^8^1_&_no": {
        "name_en": "1 & No",
        "name_aa": "1 & No",
        "name_de": "1 & Nein",
        "name_nl": "1 & Nee",
        "name_pl": "1 & No",
        "name_ro": "1 & No",
        "name_ru": "1 & No",
        "name_tr": "1 & No",
        "name_se": "1 & No",
        "name_el": "1 & No",
        "name_es": "1 & No",
        "name_hr": "1 & ne",
        "name_fr": "1 & No"
    },
    "2048^live_match^8^dd": {
        "name_en": "DD",
        "name_aa": "DD",
        "name_de": "DD",
        "name_nl": "DD",
        "name_pl": "DD",
        "name_ro": "DD",
        "name_ru": "DD",
        "name_tr": "DD",
        "name_se": "DD",
        "name_el": "DD",
        "name_es": "DD",
        "name_hr": "DD",
        "name_fr": "DD"
    },
    "2171^live_match^8^yes": {
        "name_en": "yes",
        "name_aa": "yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "yes",
        "name_ro": "yes",
        "name_ru": "yes",
        "name_tr": "yes",
        "name_se": "yes",
        "name_el": "yes",
        "name_es": "yes",
        "name_hr": "Da",
        "name_fr": "yes"
    },
    "1108^live_match^8^3:1": {
        "name_en": "3:1",
        "name_aa": "3:1",
        "name_de": "3:1",
        "name_nl": "3:1",
        "name_pl": "3:1",
        "name_ro": "3:1",
        "name_ru": "3:1",
        "name_tr": "3:1",
        "name_se": "3:1",
        "name_el": "3:1",
        "name_es": "3:1",
        "name_hr": "3:1",
        "name_fr": "3:1"
    },
    "2025^live_match^8^home": {
        "name_en": "home",
        "name_aa": "home",
        "name_de": "Heim",
        "name_nl": "Thuis",
        "name_pl": "home",
        "name_ro": "home",
        "name_ru": "home",
        "name_tr": "home",
        "name_se": "home",
        "name_el": "home",
        "name_es": "home",
        "name_hr": "Domači",
        "name_fr": "home"
    },
    "2309^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "2101^live_match^8^no": {
        "name_en": "no",
        "name_aa": "no",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "no",
        "name_ro": "no",
        "name_ru": "no",
        "name_tr": "no",
        "name_se": "no",
        "name_el": "no",
        "name_es": "no",
        "name_hr": "Ne",
        "name_fr": "no"
    },
    "26^live_match^8^0:16": {
        "name_en": "0:16",
        "name_aa": "0:16",
        "name_de": "0:16",
        "name_nl": "0:16",
        "name_pl": "0:16",
        "name_ro": "0:16",
        "name_ru": "0:16",
        "name_tr": "0:16",
        "name_se": "0:16",
        "name_el": "0:16",
        "name_es": "0:16",
        "name_hr": "0:16",
        "name_fr": "0:16"
    },
    "26^live_match^8^9:3": {
        "name_en": "9:3",
        "name_aa": "9:3",
        "name_de": "9:3",
        "name_nl": "9:3",
        "name_pl": "9:3",
        "name_ro": "9:3",
        "name_ru": "9:3",
        "name_tr": "9:3",
        "name_se": "9:3",
        "name_el": "9:3",
        "name_es": "9:3",
        "name_hr": "9:3",
        "name_fr": "9:3"
    },
    "2071^live_match^8^home_2": {
        "name_en": "home 2",
        "name_aa": "home 2",
        "name_de": "Heim 2",
        "name_nl": "thuis 2",
        "name_pl": "home 2",
        "name_ro": "home 2",
        "name_ru": "home 2",
        "name_tr": "home 2",
        "name_se": "home 2",
        "name_el": "home 2",
        "name_es": "home 2",
        "name_hr": "Domači  2",
        "name_fr": "home 2"
    },
    "2354^live_match^8^6:8": {
        "name_en": "6:8",
        "name_aa": "6:8",
        "name_de": "6:8",
        "name_nl": "6:8",
        "name_pl": "6:8",
        "name_ro": "6:8",
        "name_ru": "6:8",
        "name_tr": "6:8",
        "name_se": "6:8",
        "name_el": "6:8",
        "name_es": "6:8",
        "name_hr": "6:8",
        "name_fr": "6:8"
    },
    "520^live_match^8^4:3": {
        "name_en": "4:3",
        "name_aa": "4:3",
        "name_de": "4:3",
        "name_nl": "4:3",
        "name_pl": "4:3",
        "name_ro": "4:3",
        "name_ru": "4:3",
        "name_tr": "4:3",
        "name_se": "4:3",
        "name_el": "4:3",
        "name_es": "4:3",
        "name_hr": "4:3",
        "name_fr": "4:3"
    },
    "2354^live_match^8^13:1": {
        "name_en": "13:1",
        "name_aa": "13:1",
        "name_de": "13:1",
        "name_nl": "13:1",
        "name_pl": "13:1",
        "name_ro": "13:1",
        "name_ru": "13:1",
        "name_tr": "13:1",
        "name_se": "13:1",
        "name_el": "13:1",
        "name_es": "13:1",
        "name_hr": "13:1",
        "name_fr": "13:1"
    },
    "325^live_match^8^12": {
        "name_en": "12",
        "name_aa": "12",
        "name_de": "12",
        "name_nl": "12",
        "name_pl": "12",
        "name_ro": "12",
        "name_ru": "12",
        "name_tr": "12",
        "name_se": "12",
        "name_el": "12",
        "name_es": "12",
        "name_hr": "12",
        "name_fr": "12"
    },
    "1668^live_match^8^yes": {
        "name_en": "yes",
        "name_aa": "yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "yes",
        "name_ro": "yes",
        "name_ru": "yes",
        "name_tr": "yes",
        "name_se": "yes",
        "name_el": "yes",
        "name_es": "yes",
        "name_hr": "Da",
        "name_fr": "yes"
    },
    "2073^live_match^8^25:22": {
        "name_en": "25:22",
        "name_aa": "25:22",
        "name_de": "25:22",
        "name_nl": "25:22",
        "name_pl": "25:22",
        "name_ro": "25:22",
        "name_ru": "25:22",
        "name_tr": "25:22",
        "name_se": "25:22",
        "name_el": "25:22",
        "name_es": "25:22",
        "name_hr": "25:22",
        "name_fr": "25:22"
    },
    "2048^live_match^8^aa": {
        "name_en": "AA",
        "name_aa": "AA",
        "name_de": "AA",
        "name_nl": "AA",
        "name_pl": "AA",
        "name_ro": "AA",
        "name_ru": "AA",
        "name_tr": "AA",
        "name_se": "AA",
        "name_el": "AA",
        "name_es": "AA",
        "name_hr": "AA",
        "name_fr": "AA"
    },
    "26^live_match^8^2:17": {
        "name_en": "2:17",
        "name_aa": "2:17",
        "name_de": "2:17",
        "name_nl": "2:17",
        "name_pl": "2:17",
        "name_ro": "2:17",
        "name_ru": "2:17",
        "name_tr": "2:17",
        "name_se": "2:17",
        "name_el": "2:17",
        "name_es": "2:17",
        "name_hr": "2:17",
        "name_fr": "2:17"
    },
    "2060^live_match^8^away_1-2": {
        "name_en": "away 1-2",
        "name_aa": "away 1-2",
        "name_de": "Auswärt 1-2",
        "name_nl": "Weg 1-2",
        "name_pl": "away 1-2",
        "name_ro": "away 1-2",
        "name_ru": "away 1-2",
        "name_tr": "away 1-2",
        "name_se": "away 1-2",
        "name_el": "away 1-2",
        "name_es": "away 1-2",
        "name_hr": "Gostujuči 1-2",
        "name_fr": "away 1-2"
    },
    "2241^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "96^live_match^8^player_2_to_30": {
        "name_en": "Player 2 to 30",
        "name_aa": "Player 2 to 30",
        "name_de": "Spieler 2 zu 30",
        "name_nl": "Speler 2 tot 30",
        "name_pl": "Player 2 to 30",
        "name_ro": "Player 2 to 30",
        "name_ru": "Player 2 to 30",
        "name_tr": "2. Oyuncu 30'a karşı",
        "name_se": "Player 2 to 30",
        "name_el": "Player 2 to 30",
        "name_es": "Player 2 to 30",
        "name_hr": "Igrač 2 osvojio gem (protivnik osvojio 30 poena)",
        "name_fr": "Joueur 2 à 30"
    },
    "2261^live_match^8^1:0": {
        "name_en": "1:0",
        "name_aa": "1:0",
        "name_de": "1:0",
        "name_nl": "1:0",
        "name_pl": "1:0",
        "name_ro": "1:0",
        "name_ru": "1:0",
        "name_tr": "1:0",
        "name_se": "1:0",
        "name_el": "1:0",
        "name_es": "1:0",
        "name_hr": "1:0",
        "name_fr": "1:0"
    },
    "2194^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "2354^live_match^8^15:0": {
        "name_en": "15:0",
        "name_aa": "15:0",
        "name_de": "15:0",
        "name_nl": "15:0",
        "name_pl": "15:0",
        "name_ro": "15:0",
        "name_ru": "15:0",
        "name_tr": "15:0",
        "name_se": "15:0",
        "name_el": "15:0",
        "name_es": "15:0",
        "name_hr": "15:0",
        "name_fr": "15:0"
    },
    "2354^live_match^8^2:6": {
        "name_en": "2:6",
        "name_aa": "2:6",
        "name_de": "2:6",
        "name_nl": "2:6",
        "name_pl": "2:6",
        "name_ro": "2:6",
        "name_ru": "2:6",
        "name_tr": "2:6",
        "name_se": "2:6",
        "name_el": "2:6",
        "name_es": "2:6",
        "name_hr": "2:6",
        "name_fr": "2:6"
    },
    "2153^live_match^8^away_3": {
        "name_en": "away 3",
        "name_aa": "away 3",
        "name_de": "Auswärt 3",
        "name_nl": "Weg 3",
        "name_pl": "away 3",
        "name_ro": "away 3",
        "name_ru": "away 3",
        "name_tr": "away 3",
        "name_se": "away 3",
        "name_el": "away 3",
        "name_es": "away 3",
        "name_hr": "Gostujuči 3",
        "name_fr": "away 3"
    },
    "26^live_match^8^0:0": {
        "name_en": "0:0",
        "name_aa": "0:0",
        "name_de": "0:0",
        "name_nl": "0:0",
        "name_pl": "0:0",
        "name_ro": "0:0",
        "name_ru": "0:0",
        "name_tr": "0:0",
        "name_se": "0:0",
        "name_el": "0:0",
        "name_es": "0:0",
        "name_hr": "0:0",
        "name_fr": "0:0"
    },
    "2247^live_match^6^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "83^live_match^8^under": {
        "name_en": "Under",
        "name_aa": "u",
        "name_de": "Unter",
        "name_nl": "u",
        "name_pl": "u",
        "name_ro": "u",
        "name_ru": "u",
        "name_tr": "Alt",
        "name_se": "u",
        "name_el": "u",
        "name_es": "u",
        "name_hr": "Manje",
        "name_fr": "m"
    },
    "29^live_match^8^2:3": {
        "name_en": "2:3",
        "name_aa": "2:3",
        "name_de": "2:3",
        "name_nl": "2:3",
        "name_pl": "2:3",
        "name_ro": "2:3",
        "name_ru": "2:3",
        "name_tr": "2:3",
        "name_se": "2:3",
        "name_el": "2:3",
        "name_es": "2:3",
        "name_hr": "2:3",
        "name_fr": "2:3"
    },
    "29^live_match^8^7:1": {
        "name_en": "7:1",
        "name_aa": "7:1",
        "name_de": "7:1",
        "name_nl": "7:1",
        "name_pl": "7:1",
        "name_ro": "7:1",
        "name_ru": "7:1",
        "name_tr": "7:1",
        "name_se": "7:1",
        "name_el": "7:1",
        "name_es": "7:1",
        "name_hr": "7:1",
        "name_fr": "7:1"
    },
    "2364^live_match^8^home_and_over_and_ng": {
        "name_en": "home and over and NG",
        "name_aa": "home and over and NG",
        "name_de": "Heim und Über und NG",
        "name_nl": "Thuis en meer dan en NG",
        "name_pl": "home and over and NG",
        "name_ro": "home and over and NG",
        "name_ru": "home and over and NG",
        "name_tr": "home and over and NG",
        "name_se": "home and over and NG",
        "name_el": "home and over and NG",
        "name_es": "home and over and NG",
        "name_hr": "home and over and NG",
        "name_fr": "home and over and NG"
    },
    "237^live_match^8^dd": {
        "name_en": "DD",
        "name_aa": "DD",
        "name_de": "U-U",
        "name_nl": "DD",
        "name_pl": "DD",
        "name_ro": "DD",
        "name_ru": "DD",
        "name_tr": "BB",
        "name_se": "DD",
        "name_el": "DD",
        "name_es": "DD",
        "name_hr": "X-X",
        "name_fr": "NN"
    },
    "29^live_match^8^2:6": {
        "name_en": "2:6",
        "name_aa": "2:6",
        "name_de": "2:6",
        "name_nl": "2:6",
        "name_pl": "2:6",
        "name_ro": "2:6",
        "name_ru": "2:6",
        "name_tr": "2:6",
        "name_se": "2:6",
        "name_el": "2:6",
        "name_es": "2:6",
        "name_hr": "2:6",
        "name_fr": "2:6"
    },
    "1672^live_match^8^no": {
        "name_en": "no",
        "name_aa": "no",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "no",
        "name_ro": "no",
        "name_ru": "no",
        "name_tr": "no",
        "name_se": "no",
        "name_el": "no",
        "name_es": "no",
        "name_hr": "Ne",
        "name_fr": "no"
    },
    "1108^live_match^8^1:6": {
        "name_en": "1:6",
        "name_aa": "1:6",
        "name_de": "1:6",
        "name_nl": "1:6",
        "name_pl": "1:6",
        "name_ro": "1:6",
        "name_ru": "1:6",
        "name_tr": "1:6",
        "name_se": "1:6",
        "name_el": "1:6",
        "name_es": "1:6",
        "name_hr": "1:6",
        "name_fr": "1:6"
    },
    "2021^live_match^8^home": {
        "name_en": "home",
        "name_aa": "home",
        "name_de": "Heim",
        "name_nl": "Thuis",
        "name_pl": "home",
        "name_ro": "home",
        "name_ru": "home",
        "name_tr": "home",
        "name_se": "home",
        "name_el": "home",
        "name_es": "home",
        "name_hr": "Domači",
        "name_fr": "home"
    },
    "2329^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "2316^live_match^8^10:3": {
        "name_en": "10:3",
        "name_aa": "10:3",
        "name_de": "10:3",
        "name_nl": "10:3",
        "name_pl": "10:3",
        "name_ro": "10:3",
        "name_ru": "10:3",
        "name_tr": "10:3",
        "name_se": "10:3",
        "name_el": "10:3",
        "name_es": "10:3",
        "name_hr": "10:3",
        "name_fr": "10:3"
    },
    "117^live_match^8^1:1": {
        "name_en": "1:1",
        "name_aa": "1:1",
        "name_de": "1:1",
        "name_nl": "1:1",
        "name_pl": "1:1",
        "name_ro": "1:1",
        "name_ru": "1:1",
        "name_tr": "1:1",
        "name_se": "1:1",
        "name_el": "1:1",
        "name_es": "1:1",
        "name_hr": "1:1",
        "name_fr": "1:1"
    },
    "2216^live_match^8^home_1-39": {
        "name_en": "home 1-39",
        "name_aa": "home 1-39",
        "name_de": "Heim 1-39",
        "name_nl": "Thuis 1-39",
        "name_pl": "home 1-39",
        "name_ro": "home 1-39",
        "name_ru": "home 1-39",
        "name_tr": "home 1-39",
        "name_se": "home 1-39",
        "name_el": "home 1-39",
        "name_es": "home 1-39",
        "name_hr": "Domači 1-39",
        "name_fr": "home 1-39"
    },
    "2048^live_match^8^dh": {
        "name_en": "DH",
        "name_aa": "DH",
        "name_de": "DH",
        "name_nl": "DH",
        "name_pl": "DH",
        "name_ro": "DH",
        "name_ru": "DH",
        "name_tr": "DH",
        "name_se": "DH",
        "name_el": "DH",
        "name_es": "DH",
        "name_hr": "DH",
        "name_fr": "DH"
    },
    "2354^live_match^8^12:6": {
        "name_en": "12:6",
        "name_aa": "12:6",
        "name_de": "12:6",
        "name_nl": "12:6",
        "name_pl": "12:6",
        "name_ro": "12:6",
        "name_ru": "12:6",
        "name_tr": "12:6",
        "name_se": "12:6",
        "name_el": "12:6",
        "name_es": "12:6",
        "name_hr": "12:6",
        "name_fr": "12:6"
    },
    "520^live_match^8^7:5": {
        "name_en": "7:5",
        "name_aa": "7:5",
        "name_de": "7:5",
        "name_nl": "7:5",
        "name_pl": "7:5",
        "name_ro": "7:5",
        "name_ru": "7:5",
        "name_tr": "7:5",
        "name_se": "7:5",
        "name_el": "7:5",
        "name_es": "7:5",
        "name_hr": "7:5",
        "name_fr": "7:5"
    },
    "2191^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "872^live_match^8^under": {
        "name_en": "Under",
        "name_aa": "u",
        "name_de": "Unter",
        "name_nl": "u",
        "name_pl": "u",
        "name_ro": "u",
        "name_ru": "u",
        "name_tr": "Alt",
        "name_se": "u",
        "name_el": "u",
        "name_es": "u",
        "name_hr": "Manje",
        "name_fr": "m"
    },
    "36^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2150^live_match^8^none": {
        "name_en": "none",
        "name_aa": "none",
        "name_de": "Keiner",
        "name_nl": "Geen",
        "name_pl": "none",
        "name_ro": "none",
        "name_ru": "none",
        "name_tr": "none",
        "name_se": "none",
        "name_el": "none",
        "name_es": "none",
        "name_hr": "Nitko",
        "name_fr": "none"
    },
    "2097^live_match^6^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "26^live_match^8^15:0": {
        "name_en": "15:0",
        "name_aa": "15:0",
        "name_de": "15:0",
        "name_nl": "15:0",
        "name_pl": "15:0",
        "name_ro": "15:0",
        "name_ru": "15:0",
        "name_tr": "15:0",
        "name_se": "15:0",
        "name_el": "15:0",
        "name_es": "15:0",
        "name_hr": "15:0",
        "name_fr": "15:0"
    },
    "2068^live_match^6^away_win_by_3+": {
        "name_en": "away win by 3+",
        "name_aa": "away win by 3+",
        "name_de": "Auswart gewinnt durch 3+",
        "name_nl": "uitoverwinning met 3+",
        "name_pl": "away win by 3+",
        "name_ro": "away win by 3+",
        "name_ru": "away win by 3+",
        "name_tr": "away win by 3+",
        "name_se": "away win by 3+",
        "name_el": "away win by 3+",
        "name_es": "away win by 3+",
        "name_hr": "Gostujuči pobijediti po 3+",
        "name_fr": "away win by 3+"
    },
    "2074^live_match^8^21:25": {
        "name_en": "21:25",
        "name_aa": "21:25",
        "name_de": "21:25",
        "name_nl": "21:25",
        "name_pl": "21:25",
        "name_ro": "21:25",
        "name_ru": "21:25",
        "name_tr": "21:25",
        "name_se": "21:25",
        "name_el": "21:25",
        "name_es": "21:25",
        "name_hr": "21:25",
        "name_fr": "21:25"
    },
    "69^live_match^8^odd": {
        "name_en": "odd",
        "name_aa": "odd",
        "name_de": "Ungerade",
        "name_nl": "oneven",
        "name_pl": "odd",
        "name_ro": "odd",
        "name_ru": "odd",
        "name_tr": "tek",
        "name_se": "odd",
        "name_el": "odd",
        "name_es": "odd",
        "name_hr": "Neparan",
        "name_fr": "impair"
    },
    "2316^live_match^8^away_by_any_other": {
        "name_en": "away by Any Other",
        "name_aa": "away by Any Other",
        "name_de": "away by Any Other",
        "name_nl": "away by Any Other",
        "name_pl": "away by Any Other",
        "name_ro": "away by Any Other",
        "name_ru": "away by Any Other",
        "name_tr": "away by Any Other",
        "name_se": "away by Any Other",
        "name_el": "away by Any Other",
        "name_es": "away by Any Other",
        "name_hr": "Gostujuči po bilo Ostali",
        "name_fr": "away by Any Other"
    },
    "2156^live_match^8^away_6-8": {
        "name_en": "away 6-8",
        "name_aa": "away 6-8",
        "name_de": "Auswärt 6-8",
        "name_nl": "Weg 6-8",
        "name_pl": "away 6-8",
        "name_ro": "away 6-8",
        "name_ru": "away 6-8",
        "name_tr": "away 6-8",
        "name_se": "away 6-8",
        "name_el": "away 6-8",
        "name_es": "away 6-8",
        "name_hr": "Gostujiči 6-8",
        "name_fr": "away 6-8"
    },
    "2163^live_match^8^yes": {
        "name_en": "yes",
        "name_aa": "yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "yes",
        "name_ro": "yes",
        "name_ru": "yes",
        "name_tr": "yes",
        "name_se": "yes",
        "name_el": "yes",
        "name_es": "yes",
        "name_hr": "Da",
        "name_fr": "yes"
    },
    "2162^live_match^8^2/1": {
        "name_en": "2/1",
        "name_aa": "2/1",
        "name_de": "2/1",
        "name_nl": "2/1",
        "name_pl": "2/1",
        "name_ro": "2/1",
        "name_ru": "2/1",
        "name_tr": "2/1",
        "name_se": "2/1",
        "name_el": "2/1",
        "name_es": "2/1",
        "name_hr": "2/1",
        "name_fr": "2/1"
    },
    "2220^live_match^8^2:1": {
        "name_en": "2:1",
        "name_aa": "2:1",
        "name_de": "2:1",
        "name_nl": "2:1",
        "name_pl": "2:1",
        "name_ro": "2:1",
        "name_ru": "2:1",
        "name_tr": "2:1",
        "name_se": "2:1",
        "name_el": "2:1",
        "name_es": "2:1",
        "name_hr": "2:1",
        "name_fr": "2:1"
    },
    "2157^live_match^8^21:18": {
        "name_en": "21:18",
        "name_aa": "21:18",
        "name_de": "21:18",
        "name_nl": "21:18",
        "name_pl": "21:18",
        "name_ro": "21:18",
        "name_ru": "21:18",
        "name_tr": "21:18",
        "name_se": "21:18",
        "name_el": "21:18",
        "name_es": "21:18",
        "name_hr": "21:18",
        "name_fr": "21:18"
    },
    "2115^live_match^8^yes": {
        "name_en": "yes",
        "name_aa": "yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "yes",
        "name_ro": "yes",
        "name_ru": "yes",
        "name_tr": "yes",
        "name_se": "yes",
        "name_el": "yes",
        "name_es": "yes",
        "name_hr": "Da",
        "name_fr": "yes"
    },
    "29^live_match^8^0:5": {
        "name_en": "0:5",
        "name_aa": "0:5",
        "name_de": "0:5",
        "name_nl": "0:5",
        "name_pl": "0:5",
        "name_ro": "0:5",
        "name_ru": "0:5",
        "name_tr": "0:5",
        "name_se": "0:5",
        "name_el": "0:5",
        "name_es": "0:5",
        "name_hr": "0:5",
        "name_fr": "0:5"
    },
    "2017^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "2138^live_match^8^odd": {
        "name_en": "odd",
        "name_aa": "odd",
        "name_de": "Ungerade",
        "name_nl": "Oneven",
        "name_pl": "odd",
        "name_ro": "odd",
        "name_ru": "odd",
        "name_tr": "odd",
        "name_se": "odd",
        "name_el": "odd",
        "name_es": "odd",
        "name_hr": "Neparan",
        "name_fr": "odd"
    },
    "2012^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "2119^live_match^8^no": {
        "name_en": "no",
        "name_aa": "no",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "no",
        "name_ro": "no",
        "name_ru": "no",
        "name_tr": "no",
        "name_se": "no",
        "name_el": "no",
        "name_es": "no",
        "name_hr": "Ne",
        "name_fr": "no"
    },
    "2151^live_match^8^home": {
        "name_en": "home",
        "name_aa": "home",
        "name_de": "Heim",
        "name_nl": "Thuis",
        "name_pl": "home",
        "name_ro": "home",
        "name_ru": "home",
        "name_tr": "home",
        "name_se": "home",
        "name_el": "home",
        "name_es": "home",
        "name_hr": "Domači",
        "name_fr": "home"
    },
    "2164^live_match^8^yes": {
        "name_en": "yes",
        "name_aa": "yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "yes",
        "name_ro": "yes",
        "name_ru": "yes",
        "name_tr": "yes",
        "name_se": "yes",
        "name_el": "yes",
        "name_es": "yes",
        "name_hr": "Da",
        "name_fr": "yes"
    },
    "2155^live_match^8^home_and_under": {
        "name_en": "home and under",
        "name_aa": "home and under",
        "name_de": "Heim und Unter",
        "name_nl": "Thuis and Minder dan",
        "name_pl": "home and under",
        "name_ro": "home and under",
        "name_ru": "home and under",
        "name_tr": "home and under",
        "name_se": "home and under",
        "name_el": "home and under",
        "name_es": "home and under",
        "name_hr": "Domači i manje",
        "name_fr": "home and under"
    },
    "1108^live_match^8^4:1": {
        "name_en": "4:1",
        "name_aa": "4:1",
        "name_de": "4:1",
        "name_nl": "4:1",
        "name_pl": "4:1",
        "name_ro": "4:1",
        "name_ru": "4:1",
        "name_tr": "4:1",
        "name_se": "4:1",
        "name_el": "4:1",
        "name_es": "4:1",
        "name_hr": "4:1",
        "name_fr": "4:1"
    },
    "2148^live_match^8^home_3+": {
        "name_en": "home 3+",
        "name_aa": "home 3+",
        "name_de": "Heim 3+",
        "name_nl": "Thuis 3+",
        "name_pl": "home 3+",
        "name_ro": "home 3+",
        "name_ru": "home 3+",
        "name_tr": "home 3+",
        "name_se": "home 3+",
        "name_el": "home 3+",
        "name_es": "home 3+",
        "name_hr": "Domači 3+",
        "name_fr": "home 3+"
    },
    "1108^live_match^8^2:4": {
        "name_en": "2:4",
        "name_aa": "2:4",
        "name_de": "2:4",
        "name_nl": "2:4",
        "name_pl": "2:4",
        "name_ro": "2:4",
        "name_ru": "2:4",
        "name_tr": "2:4",
        "name_se": "2:4",
        "name_el": "2:4",
        "name_es": "2:4",
        "name_hr": "2:4",
        "name_fr": "2:4"
    },
    "83^live_match^8^over": {
        "name_en": "Over",
        "name_aa": "Over",
        "name_de": "Über",
        "name_nl": "Over",
        "name_pl": "Over",
        "name_ro": "Over",
        "name_ru": "Over",
        "name_tr": "Üst",
        "name_se": "Over",
        "name_el": "Over",
        "name_es": "Over",
        "name_hr": "Više",
        "name_fr": "p"
    },
    "2073^live_match^8^18:21": {
        "name_en": "18:21",
        "name_aa": "18:21",
        "name_de": "18:21",
        "name_nl": "18:21",
        "name_pl": "18:21",
        "name_ro": "18:21",
        "name_ru": "18:21",
        "name_tr": "18:21",
        "name_se": "18:21",
        "name_el": "18:21",
        "name_es": "18:21",
        "name_hr": "18:21",
        "name_fr": "18:21"
    },
    "80^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2353^live_match^8^14-15": {
        "name_en": "away 14-15",
        "name_aa": "away 14-15",
        "name_de": "Auswärt 14-15",
        "name_nl": "Weg 14-15",
        "name_pl": "away 14-15",
        "name_ro": "away 14-15",
        "name_ru": "away 14-15",
        "name_tr": "away 14-15",
        "name_se": "away 14-15",
        "name_el": "away 14-15",
        "name_es": "away 14-15",
        "name_hr": "Gostujuči 14-15",
        "name_fr": "away 14-15"
    },
    "1108^live_match^8^6:4": {
        "name_en": "6:4",
        "name_aa": "6:4",
        "name_de": "6:4",
        "name_nl": "6:4",
        "name_pl": "6:4",
        "name_ro": "6:4",
        "name_ru": "6:4",
        "name_tr": "6:4",
        "name_se": "6:4",
        "name_el": "6:4",
        "name_es": "6:4",
        "name_hr": "6:4",
        "name_fr": "6:4"
    },
    "2104^live_match^8^yes": {
        "name_en": "yes",
        "name_aa": "yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "yes",
        "name_ro": "yes",
        "name_ru": "yes",
        "name_tr": "yes",
        "name_se": "yes",
        "name_el": "yes",
        "name_es": "yes",
        "name_hr": "Da",
        "name_fr": "yes"
    },
    "2013^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "1320^live_match^8^home": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "1108^live_match^8^6:0": {
        "name_en": "6:0",
        "name_aa": "6:0",
        "name_de": "6:0",
        "name_nl": "6:0",
        "name_pl": "6:0",
        "name_ro": "6:0",
        "name_ru": "6:0",
        "name_tr": "6:0",
        "name_se": "6:0",
        "name_el": "6:0",
        "name_es": "6:0",
        "name_hr": "6:0",
        "name_fr": "6:0"
    },
    "1108^live_match^8^2:8": {
        "name_en": "2:8",
        "name_aa": "2:8",
        "name_de": "2:8",
        "name_nl": "2:8",
        "name_pl": "2:8",
        "name_ro": "2:8",
        "name_ru": "2:8",
        "name_tr": "2:8",
        "name_se": "2:8",
        "name_el": "2:8",
        "name_es": "2:8",
        "name_hr": "2:8",
        "name_fr": "2:8"
    },
    "2067^live_match^6^other": {
        "name_en": "other",
        "name_aa": "other",
        "name_de": "Sonstig",
        "name_nl": "Andere",
        "name_pl": "other",
        "name_ro": "other",
        "name_ru": "other",
        "name_tr": "other",
        "name_se": "other",
        "name_el": "other",
        "name_es": "other",
        "name_hr": "Ostali",
        "name_fr": "other"
    },
    "2128^live_match^8^11:5": {
        "name_en": "11:5",
        "name_aa": "11:5",
        "name_de": "11:5",
        "name_nl": "11:5",
        "name_pl": "11:5",
        "name_ro": "11:5",
        "name_ru": "11:5",
        "name_tr": "11:5",
        "name_se": "11:5",
        "name_el": "11:5",
        "name_es": "11:5",
        "name_hr": "11:5",
        "name_fr": "11:5"
    },
    "26^live_match^8^11:4": {
        "name_en": "11:4",
        "name_aa": "11:4",
        "name_de": "11:4",
        "name_nl": "11:4",
        "name_pl": "11:4",
        "name_ro": "11:4",
        "name_ru": "11:4",
        "name_tr": "11:4",
        "name_se": "11:4",
        "name_el": "11:4",
        "name_es": "11:4",
        "name_hr": "11:4",
        "name_fr": "11:4"
    },
    "2004^live_match^8^shot": {
        "name_en": "Shot",
        "name_aa": "Shot",
        "name_de": "Schuss",
        "name_nl": "Schot",
        "name_pl": "Shot",
        "name_ro": "Shot",
        "name_ru": "Shot",
        "name_tr": "Shot",
        "name_se": "Shot",
        "name_el": "Shot",
        "name_es": "Shot",
        "name_hr": "Shot",
        "name_fr": "Shot"
    },
    "2233^live_match^6^x": {
        "name_en": "x",
        "name_aa": "x",
        "name_de": "x",
        "name_nl": "x",
        "name_pl": "x",
        "name_ro": "x",
        "name_ru": "x",
        "name_tr": "x",
        "name_se": "x",
        "name_el": "x",
        "name_es": "x",
        "name_hr": "x",
        "name_fr": "x"
    },
    "1108^live_match^8^9:5": {
        "name_en": "9:5",
        "name_aa": "9:5",
        "name_de": "9:5",
        "name_nl": "9:5",
        "name_pl": "9:5",
        "name_ro": "9:5",
        "name_ru": "9:5",
        "name_tr": "9:5",
        "name_se": "9:5",
        "name_el": "9:5",
        "name_es": "9:5",
        "name_hr": "9:5",
        "name_fr": "9:5"
    },
    "2309^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "1665^live_match^2^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2305^live_match^8^7:1": {
        "name_en": "7:1",
        "name_aa": "7:1",
        "name_de": "7:1",
        "name_nl": "7:1",
        "name_pl": "7:1",
        "name_ro": "7:1",
        "name_ru": "7:1",
        "name_tr": "7:1",
        "name_se": "7:1",
        "name_el": "7:1",
        "name_es": "7:1",
        "name_hr": "7:1",
        "name_fr": "7:1"
    },
    "2261^live_match^8^7:8": {
        "name_en": "7:8",
        "name_aa": "7:8",
        "name_de": "7:8",
        "name_nl": "7:8",
        "name_pl": "7:8",
        "name_ro": "7:8",
        "name_ru": "7:8",
        "name_tr": "7:8",
        "name_se": "7:8",
        "name_el": "7:8",
        "name_es": "7:8",
        "name_hr": "7:8",
        "name_fr": "7:8"
    },
    "11^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "115^live_match^6^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2240^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2081^live_match^8^7:5": {
        "name_en": "7:5",
        "name_aa": "7:5",
        "name_de": "7:5",
        "name_nl": "7:5",
        "name_pl": "7:5",
        "name_ro": "7:5",
        "name_ru": "7:5",
        "name_tr": "7:5",
        "name_se": "7:5",
        "name_el": "7:5",
        "name_es": "7:5",
        "name_hr": "7:5",
        "name_fr": "7:5"
    },
    "2075^live_match^8^20:25": {
        "name_en": "20:25",
        "name_aa": "20:25",
        "name_de": "20:25",
        "name_nl": "20:25",
        "name_pl": "20:25",
        "name_ro": "20:25",
        "name_ru": "20:25",
        "name_tr": "20:25",
        "name_se": "20:25",
        "name_el": "20:25",
        "name_es": "20:25",
        "name_hr": "20:25",
        "name_fr": "20:25"
    },
    "2072^live_match^8^25:17": {
        "name_en": "25:17",
        "name_aa": "25:17",
        "name_de": "25:17",
        "name_nl": "25:17",
        "name_pl": "25:17",
        "name_ro": "25:17",
        "name_ru": "25:17",
        "name_tr": "25:17",
        "name_se": "25:17",
        "name_el": "25:17",
        "name_es": "25:17",
        "name_hr": "25:17",
        "name_fr": "25:17"
    },
    "26^live_match^8^2:16": {
        "name_en": "2:16",
        "name_aa": "2:16",
        "name_de": "2:16",
        "name_nl": "2:16",
        "name_pl": "2:16",
        "name_ro": "2:16",
        "name_ru": "2:16",
        "name_tr": "2:16",
        "name_se": "2:16",
        "name_el": "2:16",
        "name_es": "2:16",
        "name_hr": "2:16",
        "name_fr": "2:16"
    },
    "1032^live_match^8^7": {
        "name_en": "7",
        "name_aa": "7",
        "name_de": "7",
        "name_nl": "7",
        "name_pl": "7",
        "name_ro": "7",
        "name_ru": "7",
        "name_tr": "7",
        "name_se": "7",
        "name_el": "7",
        "name_es": "7",
        "name_hr": "7",
        "name_fr": "7"
    },
    "2338^live_match^8^throw_in": {
        "name_en": "Throw In",
        "name_aa": "Throw In",
        "name_de": "Einwurfe",
        "name_nl": "Throw In",
        "name_pl": "Throw In",
        "name_ro": "Throw In",
        "name_ru": "Throw In",
        "name_tr": "Throw In",
        "name_se": "Throw In",
        "name_el": "Throw In",
        "name_es": "Throw In",
        "name_hr": "Throw In",
        "name_fr": "Throw In"
    },
    "27^live_match^8^1x": {
        "name_en": "1X",
        "name_aa": "1X",
        "name_de": "1X",
        "name_nl": "1X",
        "name_pl": "1X",
        "name_ro": "1X",
        "name_ru": "1X",
        "name_tr": "1X",
        "name_se": "1X",
        "name_el": "1X",
        "name_es": "1X",
        "name_hr": "1X",
        "name_fr": "1X"
    },
    "2068^live_match^6^home_win_by_3+": {
        "name_en": "home win by 3+",
        "name_aa": "home win by 3+",
        "name_de": "Heim gewinnt durch 3+",
        "name_nl": "thuiszege met 3+",
        "name_pl": "home win by 3+",
        "name_ro": "home win by 3+",
        "name_ru": "home win by 3+",
        "name_tr": "home win by 3+",
        "name_se": "home win by 3+",
        "name_el": "home win by 3+",
        "name_es": "home win by 3+",
        "name_hr": "Domači pobijediti po 3+",
        "name_fr": "home win by 3+"
    },
    "26^live_match^8^5:0": {
        "name_en": "5:0",
        "name_aa": "5:0",
        "name_de": "5:0",
        "name_nl": "5:0",
        "name_pl": "5:0",
        "name_ro": "5:0",
        "name_ru": "5:0",
        "name_tr": "5:0",
        "name_se": "5:0",
        "name_el": "5:0",
        "name_es": "5:0",
        "name_hr": "5:0",
        "name_fr": "5:0"
    },
    "2325^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "520^live_match^8^6:7": {
        "name_en": "6:7",
        "name_aa": "6:7",
        "name_de": "6:7",
        "name_nl": "6:7",
        "name_pl": "6:7",
        "name_ro": "6:7",
        "name_ru": "6:7",
        "name_tr": "6:7",
        "name_se": "6:7",
        "name_el": "6:7",
        "name_es": "6:7",
        "name_hr": "6:7",
        "name_fr": "6:7"
    },
    "2261^live_match^8^4:0": {
        "name_en": "4:0",
        "name_aa": "4:0",
        "name_de": "4:0",
        "name_nl": "4:0",
        "name_pl": "4:0",
        "name_ro": "4:0",
        "name_ru": "4:0",
        "name_tr": "4:0",
        "name_se": "4:0",
        "name_el": "4:0",
        "name_es": "4:0",
        "name_hr": "4:0",
        "name_fr": "4:0"
    },
    "2306^live_match^8^away": {
        "name_en": "away",
        "name_aa": "away",
        "name_de": "Auswärts",
        "name_nl": "Uit",
        "name_pl": "away",
        "name_ro": "away",
        "name_ru": "away",
        "name_tr": "away",
        "name_se": "away",
        "name_el": "away",
        "name_es": "away",
        "name_hr": "Gostujući",
        "name_fr": "away"
    },
    "2210^live_match^8^16-17": {
        "name_en": "home 16-17",
        "name_aa": "home 16-17",
        "name_de": "Heim 16-17",
        "name_nl": "Thuis 16-17",
        "name_pl": "home 16-17",
        "name_ro": "home 16-17",
        "name_ru": "home 16-17",
        "name_tr": "home 16-17",
        "name_se": "home 16-17",
        "name_el": "home 16-17",
        "name_es": "home 16-17",
        "name_hr": "Domači 16-17",
        "name_fr": "home 16-17"
    },
    "13^live_match^6^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "928^live_match^8^over": {
        "name_en": "Over",
        "name_aa": "Over",
        "name_de": "Über",
        "name_nl": "Over",
        "name_pl": "Over",
        "name_ro": "Over",
        "name_ru": "Over",
        "name_tr": "Üst",
        "name_se": "Over",
        "name_el": "Over",
        "name_es": "Over",
        "name_hr": "Više",
        "name_fr": "p"
    },
    "1108^live_match^8^0:12": {
        "name_en": "0:12",
        "name_aa": "0:12",
        "name_de": "0:12",
        "name_nl": "0:12",
        "name_pl": "0:12",
        "name_ro": "0:12",
        "name_ru": "0:12",
        "name_tr": "0:12",
        "name_se": "0:12",
        "name_el": "0:12",
        "name_es": "0:12",
        "name_hr": "0:12",
        "name_fr": "0:12"
    },
    "2217^live_match^8^3:1": {
        "name_en": "3:1",
        "name_aa": "3:1",
        "name_de": "3:1",
        "name_nl": "3:1",
        "name_pl": "3:1",
        "name_ro": "3:1",
        "name_ru": "3:1",
        "name_tr": "3:1",
        "name_se": "3:1",
        "name_el": "3:1",
        "name_es": "3:1",
        "name_hr": "3:1",
        "name_fr": "3:1"
    },
    "29^live_match^8^2:7": {
        "name_en": "2:7",
        "name_aa": "2:7",
        "name_de": "2:7",
        "name_nl": "2:7",
        "name_pl": "2:7",
        "name_ro": "2:7",
        "name_ru": "2:7",
        "name_tr": "2:7",
        "name_se": "2:7",
        "name_el": "2:7",
        "name_es": "2:7",
        "name_hr": "2:7",
        "name_fr": "2:7"
    },
    "2027^live_match^8^exactly": {
        "name_en": "exactly",
        "name_aa": "exactly",
        "name_de": "Genau",
        "name_nl": "Precies",
        "name_pl": "exactly",
        "name_ro": "exactly",
        "name_ru": "exactly",
        "name_tr": "exactly",
        "name_se": "exactly",
        "name_el": "exactly",
        "name_es": "exactly",
        "name_hr": "Tacno",
        "name_fr": "exactly"
    },
    "2093^live_match^8^no": {
        "name_en": "no",
        "name_aa": "no",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "no",
        "name_ro": "no",
        "name_ru": "no",
        "name_tr": "no",
        "name_se": "no",
        "name_el": "no",
        "name_es": "no",
        "name_hr": "Ne",
        "name_fr": "no"
    },
    "2261^live_match^8^0:6": {
        "name_en": "0:6",
        "name_aa": "0:6",
        "name_de": "0:6",
        "name_nl": "0:6",
        "name_pl": "0:6",
        "name_ro": "0:6",
        "name_ru": "0:6",
        "name_tr": "0:6",
        "name_se": "0:6",
        "name_el": "0:6",
        "name_es": "0:6",
        "name_hr": "0:6",
        "name_fr": "0:6"
    },
    "2354^live_match^8^5:12": {
        "name_en": "5:12",
        "name_aa": "5:12",
        "name_de": "5:12",
        "name_nl": "5:12",
        "name_pl": "5:12",
        "name_ro": "5:12",
        "name_ru": "5:12",
        "name_tr": "5:12",
        "name_se": "5:12",
        "name_el": "5:12",
        "name_es": "5:12",
        "name_hr": "5:12",
        "name_fr": "5:12"
    },
    "2142^live_match^8^away_1-2": {
        "name_en": "away 1-2",
        "name_aa": "away 1-2",
        "name_de": "Auswärt 1-2",
        "name_nl": "Weg 1-2",
        "name_pl": "away 1-2",
        "name_ro": "away 1-2",
        "name_ru": "away 1-2",
        "name_tr": "away 1-2",
        "name_se": "away 1-2",
        "name_el": "away 1-2",
        "name_es": "away 1-2",
        "name_hr": "Gostujuči 1-2",
        "name_fr": "away 1-2"
    },
    "2073^live_match^8^13:21": {
        "name_en": "13:21",
        "name_aa": "13:21",
        "name_de": "13:21",
        "name_nl": "13:21",
        "name_pl": "13:21",
        "name_ro": "13:21",
        "name_ru": "13:21",
        "name_tr": "13:21",
        "name_se": "13:21",
        "name_el": "13:21",
        "name_es": "13:21",
        "name_hr": "13:21",
        "name_fr": "13:21"
    },
    "1108^live_match^8^9:8": {
        "name_en": "9:8",
        "name_aa": "9:8",
        "name_de": "9:8",
        "name_nl": "9:8",
        "name_pl": "9:8",
        "name_ro": "9:8",
        "name_ru": "9:8",
        "name_tr": "9:8",
        "name_se": "9:8",
        "name_el": "9:8",
        "name_es": "9:8",
        "name_hr": "9:8",
        "name_fr": "9:8"
    },
    "26^live_match^8^16:1": {
        "name_en": "16:1",
        "name_aa": "16:1",
        "name_de": "16:1",
        "name_nl": "16:1",
        "name_pl": "16:1",
        "name_ro": "16:1",
        "name_ru": "16:1",
        "name_tr": "16:1",
        "name_se": "16:1",
        "name_el": "16:1",
        "name_es": "16:1",
        "name_hr": "16:1",
        "name_fr": "16:1"
    },
    "2083^live_match^8^home_and_under": {
        "name_en": "home and under",
        "name_aa": "home and under",
        "name_de": "Heim und Unter",
        "name_nl": "Thuis and Minder dan",
        "name_pl": "home and under",
        "name_ro": "home and under",
        "name_ru": "home and under",
        "name_tr": "home and under",
        "name_se": "home and under",
        "name_el": "home and under",
        "name_es": "home and under",
        "name_hr": "Domači i manje",
        "name_fr": "home and under"
    },
    "26^live_match^8^2:11": {
        "name_en": "2:11",
        "name_aa": "2:11",
        "name_de": "2:11",
        "name_nl": "2:11",
        "name_pl": "2:11",
        "name_ro": "2:11",
        "name_ru": "2:11",
        "name_tr": "2:11",
        "name_se": "2:11",
        "name_el": "2:11",
        "name_es": "2:11",
        "name_hr": "2:11",
        "name_fr": "2:11"
    },
    "2178^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "520^live_match^8^2:4": {
        "name_en": "2:4",
        "name_aa": "2:4",
        "name_de": "2:4",
        "name_nl": "2:4",
        "name_pl": "2:4",
        "name_ro": "2:4",
        "name_ru": "2:4",
        "name_tr": "2:4",
        "name_se": "2:4",
        "name_el": "2:4",
        "name_es": "2:4",
        "name_hr": "2:4",
        "name_fr": "2:4"
    },
    "2087^live_match^8^4:0": {
        "name_en": "4:0",
        "name_aa": "4:0",
        "name_de": "4:0",
        "name_nl": "4:0",
        "name_pl": "4:0",
        "name_ro": "4:0",
        "name_ru": "4:0",
        "name_tr": "4:0",
        "name_se": "4:0",
        "name_el": "4:0",
        "name_es": "4:0",
        "name_hr": "4:0",
        "name_fr": "4:0"
    },
    "2072^live_match^8^20:25": {
        "name_en": "20:25",
        "name_aa": "20:25",
        "name_de": "20:25",
        "name_nl": "20:25",
        "name_pl": "20:25",
        "name_ro": "20:25",
        "name_ru": "20:25",
        "name_tr": "20:25",
        "name_se": "20:25",
        "name_el": "20:25",
        "name_es": "20:25",
        "name_hr": "20:25",
        "name_fr": "20:25"
    },
    "2104^live_match^8^no": {
        "name_en": "no",
        "name_aa": "no",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "no",
        "name_ro": "no",
        "name_ru": "no",
        "name_tr": "no",
        "name_se": "no",
        "name_el": "no",
        "name_es": "no",
        "name_hr": "Ne",
        "name_fr": "no"
    },
    "2208^live_match^8^29-30": {
        "name_en": "29-30",
        "name_aa": "29-30",
        "name_de": "29-30",
        "name_nl": "29-30",
        "name_pl": "29-30",
        "name_ro": "29-30",
        "name_ru": "29-30",
        "name_tr": "29-30",
        "name_se": "29-30",
        "name_el": "29-30",
        "name_es": "29-30",
        "name_hr": "29-30",
        "name_fr": "29-30"
    },
    "2168^live_match^8^1/2": {
        "name_en": "1/2",
        "name_aa": "1/2",
        "name_de": "1/2",
        "name_nl": "1/2",
        "name_pl": "1/2",
        "name_ro": "1/2",
        "name_ru": "1/2",
        "name_tr": "1/2",
        "name_se": "1/2",
        "name_el": "1/2",
        "name_es": "1/2",
        "name_hr": "1/2",
        "name_fr": "1/2"
    },
    "2189^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "2354^live_match^8^10:4": {
        "name_en": "10:4",
        "name_aa": "10:4",
        "name_de": "10:4",
        "name_nl": "10:4",
        "name_pl": "10:4",
        "name_ro": "10:4",
        "name_ru": "10:4",
        "name_tr": "10:4",
        "name_se": "10:4",
        "name_el": "10:4",
        "name_es": "10:4",
        "name_hr": "10:4",
        "name_fr": "10:4"
    },
    "2354^live_match^8^0:8": {
        "name_en": "0:8",
        "name_aa": "0:8",
        "name_de": "0:8",
        "name_nl": "0:8",
        "name_pl": "0:8",
        "name_ro": "0:8",
        "name_ru": "0:8",
        "name_tr": "0:8",
        "name_se": "0:8",
        "name_el": "0:8",
        "name_es": "0:8",
        "name_hr": "0:8",
        "name_fr": "0:8"
    },
    "2316^live_match^8^10:8": {
        "name_en": "10:8",
        "name_aa": "10:8",
        "name_de": "10:8",
        "name_nl": "10:8",
        "name_pl": "10:8",
        "name_ro": "10:8",
        "name_ru": "10:8",
        "name_tr": "10:8",
        "name_se": "10:8",
        "name_el": "10:8",
        "name_es": "10:8",
        "name_hr": "10:8",
        "name_fr": "10:8"
    },
    "2215^live_match^8^home_25+": {
        "name_en": "home 25+",
        "name_aa": "home 25+",
        "name_de": "Heim 25+",
        "name_nl": "Thuis 25+",
        "name_pl": "home 25+",
        "name_ro": "home 25+",
        "name_ru": "home 25+",
        "name_tr": "home 25+",
        "name_se": "home 25+",
        "name_el": "home 25+",
        "name_es": "home 25+",
        "name_hr": "Domači 25+",
        "name_fr": "home 25+"
    },
    "2261^live_match^8^2:0": {
        "name_en": "2:0",
        "name_aa": "2:0",
        "name_de": "2:0",
        "name_nl": "2:0",
        "name_pl": "2:0",
        "name_ro": "2:0",
        "name_ru": "2:0",
        "name_tr": "2:0",
        "name_se": "2:0",
        "name_el": "2:0",
        "name_es": "2:0",
        "name_hr": "2:0",
        "name_fr": "2:0"
    },
    "2307^live_match^8^3:3": {
        "name_en": "3:3",
        "name_aa": "3:3",
        "name_de": "3:3",
        "name_nl": "3:3",
        "name_pl": "3:3",
        "name_ro": "3:3",
        "name_ru": "3:3",
        "name_tr": "3:3",
        "name_se": "3:3",
        "name_el": "3:3",
        "name_es": "3:3",
        "name_hr": "3:3",
        "name_fr": "3:3"
    },
    "2062^live_match^8^home_1-2": {
        "name_en": "home 1-2",
        "name_aa": "home 1-2",
        "name_de": "Heim 1-2",
        "name_nl": "thuis 1-2",
        "name_pl": "home 1-2",
        "name_ro": "home 1-2",
        "name_ru": "home 1-2",
        "name_tr": "home 1-2",
        "name_se": "home 1-2",
        "name_el": "home 1-2",
        "name_es": "home 1-2",
        "name_hr": "Domači 1-2",
        "name_fr": "home 1-2"
    },
    "1032^live_match^8^8": {
        "name_en": "8",
        "name_aa": "8",
        "name_de": "8",
        "name_nl": "8",
        "name_pl": "8",
        "name_ro": "8",
        "name_ru": "8",
        "name_tr": "8",
        "name_se": "8",
        "name_el": "8",
        "name_es": "8",
        "name_hr": "8",
        "name_fr": "8"
    },
    "1210^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2133^live_match^8^no": {
        "name_en": "no",
        "name_aa": "no",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "no",
        "name_ro": "no",
        "name_ru": "no",
        "name_tr": "no",
        "name_se": "no",
        "name_el": "no",
        "name_es": "no",
        "name_hr": "Ne",
        "name_fr": "no"
    },
    "954^live_match^6^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "19^live_match^8^3:2": {
        "name_en": "3:2",
        "name_aa": "3:2",
        "name_de": "3:2",
        "name_nl": "3:2",
        "name_pl": "3:2",
        "name_ro": "3:2",
        "name_ru": "3:2",
        "name_tr": "3:2",
        "name_se": "3:2",
        "name_el": "3:2",
        "name_es": "3:2",
        "name_hr": "3:2",
        "name_fr": "3:2"
    },
    "1108^live_match^8^6:11": {
        "name_en": "6:11",
        "name_aa": "6:11",
        "name_de": "6:11",
        "name_nl": "6:11",
        "name_pl": "6:11",
        "name_ro": "6:11",
        "name_ru": "6:11",
        "name_tr": "6:11",
        "name_se": "6:11",
        "name_el": "6:11",
        "name_es": "6:11",
        "name_hr": "6:11",
        "name_fr": "6:11"
    },
    "2074^live_match^8^25:22": {
        "name_en": "25:22",
        "name_aa": "25:22",
        "name_de": "25:22",
        "name_nl": "25:22",
        "name_pl": "25:22",
        "name_ro": "25:22",
        "name_ru": "25:22",
        "name_tr": "25:22",
        "name_se": "25:22",
        "name_el": "25:22",
        "name_es": "25:22",
        "name_hr": "25:22",
        "name_fr": "25:22"
    },
    "16^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2200^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "35^live_match^21^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "1044^live_match^8^x2": {
        "name_en": "X2",
        "name_aa": "X2",
        "name_de": "X2",
        "name_nl": "1X",
        "name_pl": "X2",
        "name_ro": "X2",
        "name_ru": "X2",
        "name_tr": "X2",
        "name_se": "X2",
        "name_el": "X2",
        "name_es": "X2",
        "name_hr": "X2",
        "name_fr": "X2"
    },
    "2354^live_match^8^4:9": {
        "name_en": "4:9",
        "name_aa": "4:9",
        "name_de": "4:9",
        "name_nl": "4:9",
        "name_pl": "4:9",
        "name_ro": "4:9",
        "name_ru": "4:9",
        "name_tr": "4:9",
        "name_se": "4:9",
        "name_el": "4:9",
        "name_es": "4:9",
        "name_hr": "4:9",
        "name_fr": "4:9"
    },
    "116^live_match^8^5:1": {
        "name_en": "5:1",
        "name_aa": "5:1",
        "name_de": "5:1",
        "name_nl": "5:1",
        "name_pl": "5:1",
        "name_ro": "5:1",
        "name_ru": "5:1",
        "name_tr": "5:1",
        "name_se": "5:1",
        "name_el": "5:1",
        "name_es": "5:1",
        "name_hr": "5:1",
        "name_fr": "5:1"
    },
    "2322^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "2310^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "1663^live_match^2^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "1108^live_match^8^5:12": {
        "name_en": "5:12",
        "name_aa": "5:12",
        "name_de": "5:12",
        "name_nl": "5:12",
        "name_pl": "5:12",
        "name_ro": "5:12",
        "name_ru": "5:12",
        "name_tr": "5:12",
        "name_se": "5:12",
        "name_el": "5:12",
        "name_es": "5:12",
        "name_hr": "5:12",
        "name_fr": "5:12"
    },
    "26^live_match^8^4:5": {
        "name_en": "4:5",
        "name_aa": "4:5",
        "name_de": "4:5",
        "name_nl": "4:5",
        "name_pl": "4:5",
        "name_ro": "4:5",
        "name_ru": "4:5",
        "name_tr": "4:5",
        "name_se": "4:5",
        "name_el": "4:5",
        "name_es": "4:5",
        "name_hr": "4:5",
        "name_fr": "4:5"
    },
    "117^live_match^8^2:1": {
        "name_en": "2:1",
        "name_aa": "2:1",
        "name_de": "2:1",
        "name_nl": "2:1",
        "name_pl": "2:1",
        "name_ro": "2:1",
        "name_ru": "2:1",
        "name_tr": "2:1",
        "name_se": "2:1",
        "name_el": "2:1",
        "name_es": "2:1",
        "name_hr": "2:1",
        "name_fr": "2:1"
    },
    "26^live_match^8^16:3": {
        "name_en": "16:3",
        "name_aa": "16:3",
        "name_de": "16:3",
        "name_nl": "16:3",
        "name_pl": "16:3",
        "name_ro": "16:3",
        "name_ru": "16:3",
        "name_tr": "16:3",
        "name_se": "16:3",
        "name_el": "16:3",
        "name_es": "16:3",
        "name_hr": "16:3",
        "name_fr": "16:3"
    },
    "1108^live_match^8^6:5": {
        "name_en": "6:5",
        "name_aa": "6:5",
        "name_de": "6:5",
        "name_nl": "6:5",
        "name_pl": "6:5",
        "name_ro": "6:5",
        "name_ru": "6:5",
        "name_tr": "6:5",
        "name_se": "6:5",
        "name_el": "6:5",
        "name_es": "6:5",
        "name_hr": "6:5",
        "name_fr": "6:5"
    },
    "2102^live_match^8^yes": {
        "name_en": "yes",
        "name_aa": "yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "yes",
        "name_ro": "yes",
        "name_ru": "yes",
        "name_tr": "yes",
        "name_se": "yes",
        "name_el": "yes",
        "name_es": "yes",
        "name_hr": "Da",
        "name_fr": "yes"
    },
    "2073^live_match^8^25:19": {
        "name_en": "25:19",
        "name_aa": "25:19",
        "name_de": "25:19",
        "name_nl": "25:19",
        "name_pl": "25:19",
        "name_ro": "25:19",
        "name_ru": "25:19",
        "name_tr": "25:19",
        "name_se": "25:19",
        "name_el": "25:19",
        "name_es": "25:19",
        "name_hr": "25:19",
        "name_fr": "25:19"
    },
    "2329^live_match^8^to_win_to_0_or_15": {
        "name_en": "To Win to 0 or 15",
        "name_aa": "To Win to 0 or 15",
        "name_de": "To Win to 0 or 15",
        "name_nl": "To Win to 0 or 15",
        "name_pl": "To Win to 0 or 15",
        "name_ro": "To Win to 0 or 15",
        "name_ru": "To Win to 0 or 15",
        "name_tr": "To Win to 0 or 15",
        "name_se": "To Win to 0 or 15",
        "name_el": "To Win to 0 or 15",
        "name_es": "To Win to 0 or 15",
        "name_hr": "To Win to 0 or 15",
        "name_fr": "To Win to 0 or 15"
    },
    "2005^live_match^8^home_regular_time": {
        "name_en": "Home regular time",
        "name_aa": "Home regular time",
        "name_de": "Home regular time",
        "name_nl": "Thuis reguliere tijd",
        "name_pl": "Home regular time",
        "name_ro": "Home regular time",
        "name_ru": "Home regular time",
        "name_tr": "Home regular time",
        "name_se": "Home regular time",
        "name_el": "Home regular time",
        "name_es": "Home regular time",
        "name_hr": "Home regular time",
        "name_fr": "Home regular time"
    },
    "2146^live_match^8^other": {
        "name_en": "other",
        "name_aa": "other",
        "name_de": "Sonstig",
        "name_nl": "Andere",
        "name_pl": "other",
        "name_ro": "other",
        "name_ru": "other",
        "name_tr": "other",
        "name_se": "other",
        "name_el": "other",
        "name_es": "other",
        "name_hr": "Ostali",
        "name_fr": "other"
    },
    "1446^live_match^8^over": {
        "name_en": "Over",
        "name_aa": "Over",
        "name_de": "Über",
        "name_nl": "Over",
        "name_pl": "Over",
        "name_ro": "Over",
        "name_ru": "Over",
        "name_tr": "Üst",
        "name_se": "Over",
        "name_el": "Over",
        "name_es": "Over",
        "name_hr": "Više",
        "name_fr": "p"
    },
    "2096^live_match^8^away_no": {
        "name_en": "away no",
        "name_aa": "away no",
        "name_de": "Auswart Nein",
        "name_nl": "Weg Nee",
        "name_pl": "away no",
        "name_ro": "away no",
        "name_ru": "away no",
        "name_tr": "away no",
        "name_se": "away no",
        "name_el": "away no",
        "name_es": "away no",
        "name_hr": "Gostujuči ne",
        "name_fr": "away no"
    },
    "29^live_match^8^7:0": {
        "name_en": "7:0",
        "name_aa": "7:0",
        "name_de": "7:0",
        "name_nl": "7:0",
        "name_pl": "7:0",
        "name_ro": "7:0",
        "name_ru": "7:0",
        "name_tr": "7:0",
        "name_se": "7:0",
        "name_el": "7:0",
        "name_es": "7:0",
        "name_hr": "7:0",
        "name_fr": "7:0"
    },
    "960^live_match^6^x": {
        "name_en": "x",
        "name_aa": "x",
        "name_de": "x",
        "name_nl": "x",
        "name_pl": "x",
        "name_ro": "x",
        "name_ru": "x",
        "name_tr": "x",
        "name_se": "x",
        "name_el": "x",
        "name_es": "x",
        "name_hr": "x",
        "name_fr": "x"
    },
    "2162^live_match^8^1/1": {
        "name_en": "1/1",
        "name_aa": "1/1",
        "name_de": "1/1",
        "name_nl": "1/1",
        "name_pl": "1/1",
        "name_ro": "1/1",
        "name_ru": "1/1",
        "name_tr": "1/1",
        "name_se": "1/1",
        "name_el": "1/1",
        "name_es": "1/1",
        "name_hr": "1/1",
        "name_fr": "1/1"
    },
    "2128^live_match^8^8:11": {
        "name_en": "8:11",
        "name_aa": "8:11",
        "name_de": "8:11",
        "name_nl": "8:11",
        "name_pl": "8:11",
        "name_ro": "8:11",
        "name_ru": "8:11",
        "name_tr": "8:11",
        "name_se": "8:11",
        "name_el": "8:11",
        "name_es": "8:11",
        "name_hr": "8:11",
        "name_fr": "8:11"
    },
    "26^live_match^8^0:19": {
        "name_en": "0:19",
        "name_aa": "0:19",
        "name_de": "0:19",
        "name_nl": "0:19",
        "name_pl": "0:19",
        "name_ro": "0:19",
        "name_ru": "0:19",
        "name_tr": "0:19",
        "name_se": "0:19",
        "name_el": "0:19",
        "name_es": "0:19",
        "name_hr": "0:19",
        "name_fr": "0:19"
    },
    "2354^live_match^8^13:2": {
        "name_en": "13:2",
        "name_aa": "13:2",
        "name_de": "13:2",
        "name_nl": "13:2",
        "name_pl": "13:2",
        "name_ro": "13:2",
        "name_ru": "13:2",
        "name_tr": "13:2",
        "name_se": "13:2",
        "name_el": "13:2",
        "name_es": "13:2",
        "name_hr": "13:2",
        "name_fr": "13:2"
    },
    "85^live_match^8^under": {
        "name_en": "Under",
        "name_aa": "u",
        "name_de": "Unter",
        "name_nl": "u",
        "name_pl": "u",
        "name_ro": "u",
        "name_ru": "u",
        "name_tr": "Alt",
        "name_se": "u",
        "name_el": "u",
        "name_es": "u",
        "name_hr": "Manje",
        "name_fr": "m"
    },
    "2246^live_match^8^neither": {
        "name_en": "neither",
        "name_aa": "neither",
        "name_de": "Keiner",
        "name_nl": "Geen van beide",
        "name_pl": "neither",
        "name_ro": "neither",
        "name_ru": "neither",
        "name_tr": "neither",
        "name_se": "neither",
        "name_el": "neither",
        "name_es": "neither",
        "name_hr": "Ni",
        "name_fr": "neither"
    },
    "26^live_match^8^9:6": {
        "name_en": "9:6",
        "name_aa": "9:6",
        "name_de": "9:6",
        "name_nl": "9:6",
        "name_pl": "9:6",
        "name_ro": "9:6",
        "name_ru": "9:6",
        "name_tr": "9:6",
        "name_se": "9:6",
        "name_el": "9:6",
        "name_es": "9:6",
        "name_hr": "9:6",
        "name_fr": "9:6"
    },
    "1294^live_match^8^under": {
        "name_en": "Under",
        "name_aa": "u",
        "name_de": "Unter",
        "name_nl": "u",
        "name_pl": "u",
        "name_ro": "u",
        "name_ru": "u",
        "name_tr": "Alt",
        "name_se": "u",
        "name_el": "u",
        "name_es": "u",
        "name_hr": "Manje",
        "name_fr": "m"
    },
    "2354^live_match^8^15:1": {
        "name_en": "15:1",
        "name_aa": "15:1",
        "name_de": "15:1",
        "name_nl": "15:1",
        "name_pl": "15:1",
        "name_ro": "15:1",
        "name_ru": "15:1",
        "name_tr": "15:1",
        "name_se": "15:1",
        "name_el": "15:1",
        "name_es": "15:1",
        "name_hr": "15:1",
        "name_fr": "15:1"
    },
    "2260^live_match^8^home_3": {
        "name_en": "home 3",
        "name_aa": "home 3",
        "name_de": "Heim 3",
        "name_nl": "Thuis 3",
        "name_pl": "home 3",
        "name_ro": "home 3",
        "name_ru": "home 3",
        "name_tr": "home 3",
        "name_se": "home 3",
        "name_el": "home 3",
        "name_es": "home 3",
        "name_hr": "Domači 3",
        "name_fr": "home 3"
    },
    "26^live_match^8^3:9": {
        "name_en": "3:9",
        "name_aa": "3:9",
        "name_de": "3:9",
        "name_nl": "3:9",
        "name_pl": "3:9",
        "name_ro": "3:9",
        "name_ru": "3:9",
        "name_tr": "3:9",
        "name_se": "3:9",
        "name_el": "3:9",
        "name_es": "3:9",
        "name_hr": "3:9",
        "name_fr": "3:9"
    },
    "142^live_match^8^under": {
        "name_en": "Under",
        "name_aa": "u",
        "name_de": "Unter",
        "name_nl": "u",
        "name_pl": "u",
        "name_ro": "u",
        "name_ru": "u",
        "name_tr": "Alt",
        "name_se": "u",
        "name_el": "u",
        "name_es": "u",
        "name_hr": "Manje",
        "name_fr": "m"
    },
    "2075^live_match^8^25:21": {
        "name_en": "25:21",
        "name_aa": "25:21",
        "name_de": "25:21",
        "name_nl": "25:21",
        "name_pl": "25:21",
        "name_ro": "25:21",
        "name_ru": "25:21",
        "name_tr": "25:21",
        "name_se": "25:21",
        "name_el": "25:21",
        "name_es": "25:21",
        "name_hr": "25:21",
        "name_fr": "25:21"
    },
    "1662^live_match^2^x": {
        "name_en": "x",
        "name_aa": "x",
        "name_de": "x",
        "name_nl": "x",
        "name_pl": "x",
        "name_ro": "x",
        "name_ru": "x",
        "name_tr": "x",
        "name_se": "x",
        "name_el": "x",
        "name_es": "x",
        "name_hr": "x",
        "name_fr": "x"
    },
    "2354^live_match^8^8:8": {
        "name_en": "8:8",
        "name_aa": "8:8",
        "name_de": "8:8",
        "name_nl": "8:8",
        "name_pl": "8:8",
        "name_ro": "8:8",
        "name_ru": "8:8",
        "name_tr": "8:8",
        "name_se": "8:8",
        "name_el": "8:8",
        "name_es": "8:8",
        "name_hr": "8:8",
        "name_fr": "8:8"
    },
    "2335^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "904^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2127^live_match^8^no": {
        "name_en": "no",
        "name_aa": "no",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "no",
        "name_ro": "no",
        "name_ru": "no",
        "name_tr": "no",
        "name_se": "no",
        "name_el": "no",
        "name_es": "no",
        "name_hr": "Ne",
        "name_fr": "no"
    },
    "2175^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "32^live_match^8^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2064^live_match^6^away_win_by_6+": {
        "name_en": "away win by 6+",
        "name_aa": "away win by 6+",
        "name_de": "Auswart gewinnt durch 6+",
        "name_nl": "uitoverwinning met 6+",
        "name_pl": "away win by 6+",
        "name_ro": "away win by 6+",
        "name_ru": "away win by 6+",
        "name_tr": "away win by 6+",
        "name_se": "away win by 6+",
        "name_el": "away win by 6+",
        "name_es": "away win by 6+",
        "name_hr": "Gostujuči pobijediti po 6+",
        "name_fr": "away win by 6+"
    },
    "26^live_match^8^11:0": {
        "name_en": "11:0",
        "name_aa": "11:0",
        "name_de": "11:0",
        "name_nl": "11:0",
        "name_pl": "11:0",
        "name_ro": "11:0",
        "name_ru": "11:0",
        "name_tr": "11:0",
        "name_se": "11:0",
        "name_el": "11:0",
        "name_es": "11:0",
        "name_hr": "11:0",
        "name_fr": "11:0"
    },
    "2075^live_match^8^away_after_extra_points": {
        "name_en": "away after extra points",
        "name_aa": "away after extra points",
        "name_de": "Auswärtsmannschaft Nach Verlängerung",
        "name_nl": "Uitteam Na extra punten",
        "name_pl": "away after extra points",
        "name_ro": "away after extra points",
        "name_ru": "away after extra points",
        "name_tr": "away after extra points",
        "name_se": "away after extra points",
        "name_el": "away after extra points",
        "name_es": "away after extra points",
        "name_hr": "away after extra points",
        "name_fr": "away after extra points"
    },
    "2354^live_match^8^6:5": {
        "name_en": "6:5",
        "name_aa": "6:5",
        "name_de": "6:5",
        "name_nl": "6:5",
        "name_pl": "6:5",
        "name_ro": "6:5",
        "name_ru": "6:5",
        "name_tr": "6:5",
        "name_se": "6:5",
        "name_el": "6:5",
        "name_es": "6:5",
        "name_hr": "6:5",
        "name_fr": "6:5"
    },
    "1296^live_match^8^over": {
        "name_en": "Over",
        "name_aa": "Over",
        "name_de": "Über",
        "name_nl": "Over",
        "name_pl": "Over",
        "name_ro": "Over",
        "name_ru": "Over",
        "name_tr": "Üst",
        "name_se": "Over",
        "name_el": "Over",
        "name_es": "Over",
        "name_hr": "Više",
        "name_fr": "p"
    },
    "39^live_match^8^under": {
        "name_en": "Under",
        "name_aa": "u",
        "name_de": "Unter",
        "name_nl": "u",
        "name_pl": "u",
        "name_ro": "u",
        "name_ru": "u",
        "name_tr": "Alt",
        "name_se": "u",
        "name_el": "u",
        "name_es": "u",
        "name_hr": "Manje",
        "name_fr": "m"
    },
    "2075^live_match^8^25:23": {
        "name_en": "25:23",
        "name_aa": "25:23",
        "name_de": "25:23",
        "name_nl": "25:23",
        "name_pl": "25:23",
        "name_ro": "25:23",
        "name_ru": "25:23",
        "name_tr": "25:23",
        "name_se": "25:23",
        "name_el": "25:23",
        "name_es": "25:23",
        "name_hr": "25:23",
        "name_fr": "25:23"
    },
    "2258^live_match^8^no": {
        "name_en": "no",
        "name_aa": "no",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "no",
        "name_ro": "no",
        "name_ru": "no",
        "name_tr": "no",
        "name_se": "no",
        "name_el": "no",
        "name_es": "no",
        "name_hr": "Ne",
        "name_fr": "no"
    },
    "26^live_match^8^2:14": {
        "name_en": "2:14",
        "name_aa": "2:14",
        "name_de": "2:14",
        "name_nl": "2:14",
        "name_pl": "2:14",
        "name_ro": "2:14",
        "name_ru": "2:14",
        "name_tr": "2:14",
        "name_se": "2:14",
        "name_el": "2:14",
        "name_es": "2:14",
        "name_hr": "2:14",
        "name_fr": "2:14"
    },
    "4^live_match^6^x": {
        "name_en": "x",
        "name_aa": "x",
        "name_de": "x",
        "name_nl": "x",
        "name_pl": "x",
        "name_ro": "x",
        "name_ru": "x",
        "name_tr": "x",
        "name_se": "x",
        "name_el": "x",
        "name_es": "x",
        "name_hr": "x",
        "name_fr": "x"
    },
    "2354^live_match^8^7:12": {
        "name_en": "7:12",
        "name_aa": "7:12",
        "name_de": "7:12",
        "name_nl": "7:12",
        "name_pl": "7:12",
        "name_ro": "7:12",
        "name_ru": "7:12",
        "name_tr": "7:12",
        "name_se": "7:12",
        "name_el": "7:12",
        "name_es": "7:12",
        "name_hr": "7:12",
        "name_fr": "7:12"
    },
    "2330^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2331^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "2354^live_match^8^6:9": {
        "name_en": "6:9",
        "name_aa": "6:9",
        "name_de": "6:9",
        "name_nl": "6:9",
        "name_pl": "6:9",
        "name_ro": "6:9",
        "name_ru": "6:9",
        "name_tr": "6:9",
        "name_se": "6:9",
        "name_el": "6:9",
        "name_es": "6:9",
        "name_hr": "6:9",
        "name_fr": "6:9"
    },
    "922^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2354^live_match^8^12:1": {
        "name_en": "12:1",
        "name_aa": "12:1",
        "name_de": "12:1",
        "name_nl": "12:1",
        "name_pl": "12:1",
        "name_ro": "12:1",
        "name_ru": "12:1",
        "name_tr": "12:1",
        "name_se": "12:1",
        "name_el": "12:1",
        "name_es": "12:1",
        "name_hr": "12:1",
        "name_fr": "12:1"
    },
    "2023^live_match^8^home": {
        "name_en": "home",
        "name_aa": "home",
        "name_de": "Heim",
        "name_nl": "Thuis",
        "name_pl": "home",
        "name_ro": "home",
        "name_ru": "home",
        "name_tr": "home",
        "name_se": "home",
        "name_el": "home",
        "name_es": "home",
        "name_hr": "Domači",
        "name_fr": "home"
    },
    "2261^live_match^8^6:7": {
        "name_en": "6:7",
        "name_aa": "6:7",
        "name_de": "6:7",
        "name_nl": "6:7",
        "name_pl": "6:7",
        "name_ro": "6:7",
        "name_ru": "6:7",
        "name_tr": "6:7",
        "name_se": "6:7",
        "name_el": "6:7",
        "name_es": "6:7",
        "name_hr": "6:7",
        "name_fr": "6:7"
    },
    "30^live_match^8^nogoal": {
        "name_en": "No",
        "name_aa": "No",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "No",
        "name_ro": "No",
        "name_ru": "No",
        "name_tr": "hayır",
        "name_se": "No",
        "name_el": "No",
        "name_es": "No",
        "name_hr": "Ne",
        "name_fr": "non"
    },
    "2345^live_match^8^yes": {
        "name_en": "yes",
        "name_aa": "yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "yes",
        "name_ro": "yes",
        "name_ru": "yes",
        "name_tr": "yes",
        "name_se": "yes",
        "name_el": "yes",
        "name_es": "yes",
        "name_hr": "Da",
        "name_fr": "yes"
    },
    "2153^live_match^8^away_1": {
        "name_en": "away 1",
        "name_aa": "away 1",
        "name_de": "Auswärt 1",
        "name_nl": "Weg 1",
        "name_pl": "away 1",
        "name_ro": "away 1",
        "name_ru": "away 1",
        "name_tr": "away 1",
        "name_se": "away 1",
        "name_el": "away 1",
        "name_es": "away 1",
        "name_hr": "Gostujući 1",
        "name_fr": "away 1"
    },
    "26^live_match^8^5:6": {
        "name_en": "5:6",
        "name_aa": "5:6",
        "name_de": "5:6",
        "name_nl": "5:6",
        "name_pl": "5:6",
        "name_ro": "5:6",
        "name_ru": "5:6",
        "name_tr": "5:6",
        "name_se": "5:6",
        "name_el": "5:6",
        "name_es": "5:6",
        "name_hr": "5:6",
        "name_fr": "5:6"
    },
    "341^live_match^8^2:4": {
        "name_en": "2:4",
        "name_aa": "2:4",
        "name_de": "2:4",
        "name_nl": "2:4",
        "name_pl": "2:4",
        "name_ro": "2:4",
        "name_ru": "2:4",
        "name_tr": "2:4",
        "name_se": "2:4",
        "name_el": "2:4",
        "name_es": "2:4",
        "name_hr": "2:4",
        "name_fr": "2:4"
    },
    "876^live_match^8^under": {
        "name_en": "Under",
        "name_aa": "u",
        "name_de": "Unter",
        "name_nl": "u",
        "name_pl": "u",
        "name_ro": "u",
        "name_ru": "u",
        "name_tr": "Alt",
        "name_se": "u",
        "name_el": "u",
        "name_es": "u",
        "name_hr": "Manje",
        "name_fr": "m"
    },
    "2219^live_match^8^0:2": {
        "name_en": "0:2",
        "name_aa": "0:2",
        "name_de": "0:2",
        "name_nl": "0:2",
        "name_pl": "0:2",
        "name_ro": "0:2",
        "name_ru": "0:2",
        "name_tr": "0:2",
        "name_se": "0:2",
        "name_el": "0:2",
        "name_es": "0:2",
        "name_hr": "0:2",
        "name_fr": "0:2"
    },
    "116^live_match^8^0:2": {
        "name_en": "0:2",
        "name_aa": "0:2",
        "name_de": "0:2",
        "name_nl": "0:2",
        "name_pl": "0:2",
        "name_ro": "0:2",
        "name_ru": "0:2",
        "name_tr": "0:2",
        "name_se": "0:2",
        "name_el": "0:2",
        "name_es": "0:2",
        "name_hr": "0:2",
        "name_fr": "0:2"
    },
    "1108^live_match^8^9:2": {
        "name_en": "9:2",
        "name_aa": "9:2",
        "name_de": "9:2",
        "name_nl": "9:2",
        "name_pl": "9:2",
        "name_ro": "9:2",
        "name_ru": "9:2",
        "name_tr": "9:2",
        "name_se": "9:2",
        "name_el": "9:2",
        "name_es": "9:2",
        "name_hr": "9:2",
        "name_fr": "9:2"
    },
    "2319^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "2182^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "1062^live_match^8^over": {
        "name_en": "Over",
        "name_aa": "Over",
        "name_de": "Über",
        "name_nl": "Over",
        "name_pl": "Over",
        "name_ro": "Over",
        "name_ru": "Over",
        "name_tr": "Üst",
        "name_se": "Over",
        "name_el": "Over",
        "name_es": "Over",
        "name_hr": "Više",
        "name_fr": "p"
    },
    "29^live_match^8^2:1": {
        "name_en": "2:1",
        "name_aa": "2:1",
        "name_de": "2:1",
        "name_nl": "2:1",
        "name_pl": "2:1",
        "name_ro": "2:1",
        "name_ru": "2:1",
        "name_tr": "2:1",
        "name_se": "2:1",
        "name_el": "2:1",
        "name_es": "2:1",
        "name_hr": "2:1",
        "name_fr": "2:1"
    },
    "119^live_match^8^second_half": {
        "name_en": "second half",
        "name_aa": "second half",
        "name_de": "Zweite Halbzeit",
        "name_nl": "tweede helft",
        "name_pl": "second half",
        "name_ro": "second half",
        "name_ru": "second half",
        "name_tr": "ikinci yarı",
        "name_se": "second half",
        "name_el": "second half",
        "name_es": "second half",
        "name_hr": "drugo poluvrijeme",
        "name_fr": "deuxième mi-temps"
    },
    "2033^live_match^8^home_and_under": {
        "name_en": "home and under",
        "name_aa": "home and under",
        "name_de": "Heim und Unter",
        "name_nl": "Thuis and Minder dan",
        "name_pl": "home and under",
        "name_ro": "home and under",
        "name_ru": "home and under",
        "name_tr": "home and under",
        "name_se": "home and under",
        "name_el": "home and under",
        "name_es": "home and under",
        "name_hr": "Domači i manje",
        "name_fr": "home and under"
    },
    "2001^live_match^8^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2218^live_match^8^0:2": {
        "name_en": "0:2",
        "name_aa": "0:2",
        "name_de": "0:2",
        "name_nl": "0:2",
        "name_pl": "0:2",
        "name_ro": "0:2",
        "name_ru": "0:2",
        "name_tr": "0:2",
        "name_se": "0:2",
        "name_el": "0:2",
        "name_es": "0:2",
        "name_hr": "0:2",
        "name_fr": "0:2"
    },
    "2056^live_match^8^1x": {
        "name_en": "1X",
        "name_aa": "1X",
        "name_de": "1X",
        "name_nl": "1X",
        "name_pl": "1X",
        "name_ro": "1X",
        "name_ru": "1X",
        "name_tr": "1X",
        "name_se": "1X",
        "name_el": "1X",
        "name_es": "1X",
        "name_hr": "1X",
        "name_fr": "1X"
    },
    "2213^live_match^8^home_19-24": {
        "name_en": "home 19-24",
        "name_aa": "home 19-24",
        "name_de": "Heim 19-24",
        "name_nl": "Thuis 19-24",
        "name_pl": "home 19-24",
        "name_ro": "home 19-24",
        "name_ru": "home 19-24",
        "name_tr": "home 19-24",
        "name_se": "home 19-24",
        "name_el": "home 19-24",
        "name_es": "home 19-24",
        "name_hr": "Domači 19-24",
        "name_fr": "home 19-24"
    },
    "2074^live_match^8^20:25": {
        "name_en": "20:25",
        "name_aa": "20:25",
        "name_de": "20:25",
        "name_nl": "20:25",
        "name_pl": "20:25",
        "name_ro": "20:25",
        "name_ru": "20:25",
        "name_tr": "20:25",
        "name_se": "20:25",
        "name_el": "20:25",
        "name_es": "20:25",
        "name_hr": "20:25",
        "name_fr": "20:25"
    },
    "237^live_match^8^hd": {
        "name_en": "HD",
        "name_aa": "HD",
        "name_de": "H-D",
        "name_nl": "HD",
        "name_pl": "HD",
        "name_ro": "HD",
        "name_ru": "HD",
        "name_tr": "EB",
        "name_se": "HD",
        "name_el": "HD",
        "name_es": "HD",
        "name_hr": "1-X",
        "name_fr": "DN"
    },
    "2122^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "2089^live_match^8^0:30": {
        "name_en": "0:30",
        "name_aa": "0:30",
        "name_de": "0:30",
        "name_nl": "0:30",
        "name_pl": "0:30",
        "name_ro": "0:30",
        "name_ru": "0:30",
        "name_tr": "0:30",
        "name_se": "0:30",
        "name_el": "0:30",
        "name_es": "0:30",
        "name_hr": "0:30",
        "name_fr": "0:30"
    },
    "2354^live_match^8^9:0": {
        "name_en": "9:0",
        "name_aa": "9:0",
        "name_de": "9:0",
        "name_nl": "9:0",
        "name_pl": "9:0",
        "name_ro": "9:0",
        "name_ru": "9:0",
        "name_tr": "9:0",
        "name_se": "9:0",
        "name_el": "9:0",
        "name_es": "9:0",
        "name_hr": "9:0",
        "name_fr": "9:0"
    },
    "357^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2037^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "325^live_match^8^1x": {
        "name_en": "1X",
        "name_aa": "1X",
        "name_de": "1X",
        "name_nl": "1X",
        "name_pl": "1X",
        "name_ro": "1X",
        "name_ru": "1X",
        "name_tr": "1X",
        "name_se": "1X",
        "name_el": "1X",
        "name_es": "1X",
        "name_hr": "1X",
        "name_fr": "1X"
    },
    "2213^live_match^8^away_31-36": {
        "name_en": "away 31-36",
        "name_aa": "away 31-36",
        "name_de": "Auswärt 31-36",
        "name_nl": "Weg 31-36",
        "name_pl": "away 31-36",
        "name_ro": "away 31-36",
        "name_ru": "away 31-36",
        "name_tr": "away 31-36",
        "name_se": "away 31-36",
        "name_el": "away 31-36",
        "name_es": "away 31-36",
        "name_hr": "Gostujuči 31-36",
        "name_fr": "away 31-36"
    },
    "2143^live_match^8^home_5-6": {
        "name_en": "home 5-6",
        "name_aa": "home 5-6",
        "name_de": "Heim 5-6",
        "name_nl": "thuis 5-6",
        "name_pl": "home 5-6",
        "name_ro": "home 5-6",
        "name_ru": "home 5-6",
        "name_tr": "home 5-6",
        "name_se": "home 5-6",
        "name_el": "home 5-6",
        "name_es": "home 5-6",
        "name_hr": "Domači 5-6",
        "name_fr": "home 5-6"
    },
    "2354^live_match^8^2:1": {
        "name_en": "2:1",
        "name_aa": "2:1",
        "name_de": "2:1",
        "name_nl": "2:1",
        "name_pl": "2:1",
        "name_ro": "2:1",
        "name_ru": "2:1",
        "name_tr": "2:1",
        "name_se": "2:1",
        "name_el": "2:1",
        "name_es": "2:1",
        "name_hr": "2:1",
        "name_fr": "2:1"
    },
    "2026^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "2260^live_match^8^away_3": {
        "name_en": "away 3",
        "name_aa": "away 3",
        "name_de": "Auswärt 3",
        "name_nl": "Weg 3",
        "name_pl": "away 3",
        "name_ro": "away 3",
        "name_ru": "away 3",
        "name_tr": "away 3",
        "name_se": "away 3",
        "name_el": "away 3",
        "name_es": "away 3",
        "name_hr": "Gostujuči 3",
        "name_fr": "away 3"
    },
    "1218^live_match^8^no": {
        "name_en": "No",
        "name_aa": "No",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "No",
        "name_ro": "No",
        "name_ru": "No",
        "name_tr": "hayır",
        "name_se": "No",
        "name_el": "No",
        "name_es": "No",
        "name_hr": "Ne",
        "name_fr": "non"
    },
    "115^live_match^6^x": {
        "name_en": "x",
        "name_aa": "x",
        "name_de": "x",
        "name_nl": "x",
        "name_pl": "x",
        "name_ro": "x",
        "name_ru": "x",
        "name_tr": "x",
        "name_se": "x",
        "name_el": "x",
        "name_es": "x",
        "name_hr": "x",
        "name_fr": "x"
    },
    "1156^live_match^8^5:4": {
        "name_en": "5:4",
        "name_aa": "5:4",
        "name_de": "5:4",
        "name_nl": "5:4",
        "name_pl": "5:4",
        "name_ro": "5:4",
        "name_ru": "5:4",
        "name_tr": "5:4",
        "name_se": "5:4",
        "name_el": "5:4",
        "name_es": "5:4",
        "name_hr": "5:4",
        "name_fr": "5:4"
    },
    "1298^live_match^8^under": {
        "name_en": "Under",
        "name_aa": "u",
        "name_de": "Unter",
        "name_nl": "u",
        "name_pl": "u",
        "name_ro": "u",
        "name_ru": "u",
        "name_tr": "Alt",
        "name_se": "u",
        "name_el": "u",
        "name_es": "u",
        "name_hr": "Manje",
        "name_fr": "m"
    },
    "2163^live_match^8^no": {
        "name_en": "no",
        "name_aa": "no",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "no",
        "name_ro": "no",
        "name_ru": "no",
        "name_tr": "no",
        "name_se": "no",
        "name_el": "no",
        "name_es": "no",
        "name_hr": "Ne",
        "name_fr": "no"
    },
    "2008^live_match^6^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "20^live_match^6^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "26^live_match^8^8:4": {
        "name_en": "8:4",
        "name_aa": "8:4",
        "name_de": "8:4",
        "name_nl": "8:4",
        "name_pl": "8:4",
        "name_ro": "8:4",
        "name_ru": "8:4",
        "name_tr": "8:4",
        "name_se": "8:4",
        "name_el": "8:4",
        "name_es": "8:4",
        "name_hr": "8:4",
        "name_fr": "8:4"
    },
    "2149^live_match^8^tie": {
        "name_en": "Tie",
        "name_aa": "Tie",
        "name_de": "Unentschieden",
        "name_nl": "Gelijkspel",
        "name_pl": "Tie",
        "name_ro": "Tie",
        "name_ru": "Tie",
        "name_tr": "Tie",
        "name_se": "Tie",
        "name_el": "Tie",
        "name_es": "Tie",
        "name_hr": "Tie",
        "name_fr": "Tie"
    },
    "2073^live_match^8^away_after_extra_points": {
        "name_en": "away after extra points",
        "name_aa": "away after extra points",
        "name_de": "Auswärtsmannschaft Nach Verlängerung",
        "name_nl": "Uitteam Na extra punten",
        "name_pl": "away after extra points",
        "name_ro": "away after extra points",
        "name_ru": "away after extra points",
        "name_tr": "away after extra points",
        "name_se": "away after extra points",
        "name_el": "away after extra points",
        "name_es": "away after extra points",
        "name_hr": "away after extra points",
        "name_fr": "away after extra points"
    },
    "2121^live_match^8^2_yes": {
        "name_en": "2 yes",
        "name_aa": "2 yes",
        "name_de": "2 Ja",
        "name_nl": "2 Ja",
        "name_pl": "2 yes",
        "name_ro": "2 yes",
        "name_ru": "2 yes",
        "name_tr": "2 yes",
        "name_se": "2 yes",
        "name_el": "2 yes",
        "name_es": "2 yes",
        "name_hr": "2 Da",
        "name_fr": "2 yes"
    },
    "26^live_match^8^10:6": {
        "name_en": "10:6",
        "name_aa": "10:6",
        "name_de": "10:6",
        "name_nl": "10:6",
        "name_pl": "10:6",
        "name_ro": "10:6",
        "name_ru": "10:6",
        "name_tr": "10:6",
        "name_se": "10:6",
        "name_el": "10:6",
        "name_es": "10:6",
        "name_hr": "10:6",
        "name_fr": "10:6"
    },
    "355^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2061^live_match^8^away_5-6": {
        "name_en": "away 5-6",
        "name_aa": "away 5-6",
        "name_de": "Auswärt 5-6",
        "name_nl": "Weg 5-6",
        "name_pl": "away 5-6",
        "name_ro": "away 5-6",
        "name_ru": "away 5-6",
        "name_tr": "away 5-6",
        "name_se": "away 5-6",
        "name_el": "away 5-6",
        "name_es": "away 5-6",
        "name_hr": "Gostujuči 5-6",
        "name_fr": "away 5-6"
    },
    "2323^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "1108^live_match^8^12:0": {
        "name_en": "12:0",
        "name_aa": "12:0",
        "name_de": "12:0",
        "name_nl": "12:0",
        "name_pl": "12:0",
        "name_ro": "12:0",
        "name_ru": "12:0",
        "name_tr": "12:0",
        "name_se": "12:0",
        "name_el": "12:0",
        "name_es": "12:0",
        "name_hr": "12:0",
        "name_fr": "12:0"
    },
    "2071^live_match^8^away_3-4": {
        "name_en": "away 3-4",
        "name_aa": "away 3-4",
        "name_de": "Auswärt 3-4",
        "name_nl": "Weg 3-4",
        "name_pl": "away 3-4",
        "name_ro": "away 3-4",
        "name_ru": "away 3-4",
        "name_tr": "away 3-4",
        "name_se": "away 3-4",
        "name_el": "away 3-4",
        "name_es": "away 3-4",
        "name_hr": "Gostujuči 3-4",
        "name_fr": "away 3-4"
    },
    "34^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2354^live_match^8^8:0": {
        "name_en": "8:0",
        "name_aa": "8:0",
        "name_de": "8:0",
        "name_nl": "8:0",
        "name_pl": "8:0",
        "name_ro": "8:0",
        "name_ru": "8:0",
        "name_tr": "8:0",
        "name_se": "8:0",
        "name_el": "8:0",
        "name_es": "8:0",
        "name_hr": "8:0",
        "name_fr": "8:0"
    },
    "1108^live_match^8^6:8": {
        "name_en": "6:8",
        "name_aa": "6:8",
        "name_de": "6:8",
        "name_nl": "6:8",
        "name_pl": "6:8",
        "name_ro": "6:8",
        "name_ru": "6:8",
        "name_tr": "6:8",
        "name_se": "6:8",
        "name_el": "6:8",
        "name_es": "6:8",
        "name_hr": "6:8",
        "name_fr": "6:8"
    },
    "2036^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "2007^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "2354^live_match^8^5:11": {
        "name_en": "5:11",
        "name_aa": "5:11",
        "name_de": "5:11",
        "name_nl": "5:11",
        "name_pl": "5:11",
        "name_ro": "5:11",
        "name_ru": "5:11",
        "name_tr": "5:11",
        "name_se": "5:11",
        "name_el": "5:11",
        "name_es": "5:11",
        "name_hr": "5:11",
        "name_fr": "5:11"
    },
    "2014^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "2076^live_match^8^15:13": {
        "name_en": "15:13",
        "name_aa": "15:13",
        "name_de": "15:13",
        "name_nl": "15:13",
        "name_pl": "15:13",
        "name_ro": "15:13",
        "name_ru": "15:13",
        "name_tr": "15:13",
        "name_se": "15:13",
        "name_el": "15:13",
        "name_es": "15:13",
        "name_hr": "15:13",
        "name_fr": "15:13"
    },
    "2320^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "1108^live_match^8^12:5": {
        "name_en": "12:5",
        "name_aa": "12:5",
        "name_de": "12:5",
        "name_nl": "12:5",
        "name_pl": "12:5",
        "name_ro": "12:5",
        "name_ru": "12:5",
        "name_tr": "12:5",
        "name_se": "12:5",
        "name_el": "12:5",
        "name_es": "12:5",
        "name_hr": "12:5",
        "name_fr": "12:5"
    },
    "20^live_match^6^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "1086^live_match^8^2nd_period": {
        "name_en": "2nd period",
        "name_aa": "2nd period",
        "name_de": "2. Periode",
        "name_nl": "2de periode",
        "name_pl": "2nd period",
        "name_ro": "2nd period",
        "name_ru": "2nd period",
        "name_tr": "2. periyot",
        "name_se": "2nd period",
        "name_el": "2nd period",
        "name_es": "2nd period",
        "name_hr": "2. period",
        "name_fr": "2ème période"
    },
    "26^live_match^8^6:1": {
        "name_en": "6:1",
        "name_aa": "6:1",
        "name_de": "6:1",
        "name_nl": "6:1",
        "name_pl": "6:1",
        "name_ro": "6:1",
        "name_ru": "6:1",
        "name_tr": "6:1",
        "name_se": "6:1",
        "name_el": "6:1",
        "name_es": "6:1",
        "name_hr": "6:1",
        "name_fr": "6:1"
    },
    "2059^live_match^8^away_4-6": {
        "name_en": "away 4-6",
        "name_aa": "away 4-6",
        "name_de": "Auswärt 4-6",
        "name_nl": "Weg 4-6",
        "name_pl": "away 4-6",
        "name_ro": "away 4-6",
        "name_ru": "away 4-6",
        "name_tr": "away 4-6",
        "name_se": "away 4-6",
        "name_el": "away 4-6",
        "name_es": "away 4-6",
        "name_hr": "Gostujuči 4-6",
        "name_fr": "away 4-6"
    },
    "2247^live_match^6^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "960^live_match^6^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2157^live_match^8^21:19": {
        "name_en": "21:19",
        "name_aa": "21:19",
        "name_de": "21:19",
        "name_nl": "21:19",
        "name_pl": "21:19",
        "name_ro": "21:19",
        "name_ru": "21:19",
        "name_tr": "21:19",
        "name_se": "21:19",
        "name_el": "21:19",
        "name_es": "21:19",
        "name_hr": "21:19",
        "name_fr": "21:19"
    },
    "2103^live_match^8^yes": {
        "name_en": "yes",
        "name_aa": "yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "yes",
        "name_ro": "yes",
        "name_ru": "yes",
        "name_tr": "yes",
        "name_se": "yes",
        "name_el": "yes",
        "name_es": "yes",
        "name_hr": "Da",
        "name_fr": "yes"
    },
    "26^live_match^8^9:4": {
        "name_en": "9:4",
        "name_aa": "9:4",
        "name_de": "9:4",
        "name_nl": "9:4",
        "name_pl": "9:4",
        "name_ro": "9:4",
        "name_ru": "9:4",
        "name_tr": "9:4",
        "name_se": "9:4",
        "name_el": "9:4",
        "name_es": "9:4",
        "name_hr": "9:4",
        "name_fr": "9:4"
    },
    "956^live_match^6^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "116^live_match^8^0:4": {
        "name_en": "0:4",
        "name_aa": "0:4",
        "name_de": "0:4",
        "name_nl": "0:4",
        "name_pl": "0:4",
        "name_ro": "0:4",
        "name_ru": "0:4",
        "name_tr": "0:4",
        "name_se": "0:4",
        "name_el": "0:4",
        "name_es": "0:4",
        "name_hr": "0:4",
        "name_fr": "0:4"
    },
    "2066^live_match^6^home_win_by_3+": {
        "name_en": "home win by 3+",
        "name_aa": "home win by 3+",
        "name_de": "Heim gewinnt durch 3+",
        "name_nl": "thuiszege met 3+",
        "name_pl": "home win by 3+",
        "name_ro": "home win by 3+",
        "name_ru": "home win by 3+",
        "name_tr": "home win by 3+",
        "name_se": "home win by 3+",
        "name_el": "home win by 3+",
        "name_es": "home win by 3+",
        "name_hr": "Domači pobijediti po 3+",
        "name_fr": "home win by 3+"
    },
    "2116^live_match^8^no": {
        "name_en": "no",
        "name_aa": "no",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "no",
        "name_ro": "no",
        "name_ru": "no",
        "name_tr": "no",
        "name_se": "no",
        "name_el": "no",
        "name_es": "no",
        "name_hr": "Ne",
        "name_fr": "no"
    },
    "117^live_match^8^0:5": {
        "name_en": "0:5",
        "name_aa": "0:5",
        "name_de": "0:5",
        "name_nl": "0:5",
        "name_pl": "0:5",
        "name_ro": "0:5",
        "name_ru": "0:5",
        "name_tr": "0:5",
        "name_se": "0:5",
        "name_el": "0:5",
        "name_es": "0:5",
        "name_hr": "0:5",
        "name_fr": "0:5"
    },
    "1066^live_match^8^1:2": {
        "name_en": "1:2",
        "name_aa": "1:2",
        "name_de": "1:2",
        "name_nl": "1:2",
        "name_pl": "1:2",
        "name_ro": "1:2",
        "name_ru": "1:2",
        "name_tr": "1:2",
        "name_se": "1:2",
        "name_el": "1:2",
        "name_es": "1:2",
        "name_hr": "1:2",
        "name_fr": "1:2"
    },
    "2327^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "31^live_match^8^even": {
        "name_en": "even",
        "name_aa": "even",
        "name_de": "Gerade",
        "name_nl": "even",
        "name_pl": "even",
        "name_ro": "even",
        "name_ru": "even",
        "name_tr": "çift",
        "name_se": "even",
        "name_el": "even",
        "name_es": "even",
        "name_hr": "Paran",
        "name_fr": "pair"
    },
    "26^live_match^8^6:2": {
        "name_en": "6:2",
        "name_aa": "6:2",
        "name_de": "6:2",
        "name_nl": "6:2",
        "name_pl": "6:2",
        "name_ro": "6:2",
        "name_ru": "6:2",
        "name_tr": "6:2",
        "name_se": "6:2",
        "name_el": "6:2",
        "name_es": "6:2",
        "name_hr": "6:2",
        "name_fr": "6:2"
    },
    "2063^live_match^8^away_10-13": {
        "name_en": "away 10-13",
        "name_aa": "away 10-13",
        "name_de": "Auswärt 10-13",
        "name_nl": "Weg 10-13",
        "name_pl": "away 10-13",
        "name_ro": "away 10-13",
        "name_ru": "away 10-13",
        "name_tr": "away 10-13",
        "name_se": "away 10-13",
        "name_el": "away 10-13",
        "name_es": "away 10-13",
        "name_hr": "Gostujuči 10-13",
        "name_fr": "away 10-13"
    },
    "2261^live_match^8^0:7": {
        "name_en": "0:7",
        "name_aa": "0:7",
        "name_de": "0:7",
        "name_nl": "0:7",
        "name_pl": "0:7",
        "name_ro": "0:7",
        "name_ru": "0:7",
        "name_tr": "0:7",
        "name_se": "0:7",
        "name_el": "0:7",
        "name_es": "0:7",
        "name_hr": "0:7",
        "name_fr": "0:7"
    },
    "26^live_match^8^1:3": {
        "name_en": "1:3",
        "name_aa": "1:3",
        "name_de": "1:3",
        "name_nl": "1:3",
        "name_pl": "1:3",
        "name_ro": "1:3",
        "name_ru": "1:3",
        "name_tr": "1:3",
        "name_se": "1:3",
        "name_el": "1:3",
        "name_es": "1:3",
        "name_hr": "1:3",
        "name_fr": "1:3"
    },
    "291^live_match^6^x": {
        "name_en": "x",
        "name_aa": "x",
        "name_de": "x",
        "name_nl": "x",
        "name_pl": "x",
        "name_ro": "x",
        "name_ru": "x",
        "name_tr": "x",
        "name_se": "x",
        "name_el": "x",
        "name_es": "x",
        "name_hr": "x",
        "name_fr": "x"
    },
    "1108^live_match^8^10:6": {
        "name_en": "10:6",
        "name_aa": "10:6",
        "name_de": "10:6",
        "name_nl": "10:6",
        "name_pl": "10:6",
        "name_ro": "10:6",
        "name_ru": "10:6",
        "name_tr": "10:6",
        "name_se": "10:6",
        "name_el": "10:6",
        "name_es": "10:6",
        "name_hr": "10:6",
        "name_fr": "10:6"
    },
    "2261^live_match^8^5:7": {
        "name_en": "5:7",
        "name_aa": "5:7",
        "name_de": "5:7",
        "name_nl": "5:7",
        "name_pl": "5:7",
        "name_ro": "5:7",
        "name_ru": "5:7",
        "name_tr": "5:7",
        "name_se": "5:7",
        "name_el": "5:7",
        "name_es": "5:7",
        "name_hr": "5:7",
        "name_fr": "5:7"
    },
    "1529^live_match^8^draw_and_under": {
        "name_en": "draw and under",
        "name_aa": "draw and under",
        "name_de": "Unentschieden und Unter",
        "name_nl": "gelijk en onder",
        "name_pl": "draw and under",
        "name_ro": "draw and under",
        "name_ru": "draw and under",
        "name_tr": "beraberlik ve alt",
        "name_se": "draw and under",
        "name_el": "draw and under",
        "name_es": "draw and under",
        "name_hr": "neriješeno i Manje",
        "name_fr": "match nul et Moins de"
    },
    "2220^live_match^8^1:2": {
        "name_en": "1:2",
        "name_aa": "1:2",
        "name_de": "1:2",
        "name_nl": "1:2",
        "name_pl": "1:2",
        "name_ro": "1:2",
        "name_ru": "1:2",
        "name_tr": "1:2",
        "name_se": "1:2",
        "name_el": "1:2",
        "name_es": "1:2",
        "name_hr": "1:2",
        "name_fr": "1:2"
    },
    "2261^live_match^8^8:1": {
        "name_en": "8:1",
        "name_aa": "8:1",
        "name_de": "8:1",
        "name_nl": "8:1",
        "name_pl": "8:1",
        "name_ro": "8:1",
        "name_ru": "8:1",
        "name_tr": "8:1",
        "name_se": "8:1",
        "name_el": "8:1",
        "name_es": "8:1",
        "name_hr": "8:1",
        "name_fr": "8:1"
    },
    "29^live_match^8^1:12": {
        "name_en": "1:12",
        "name_aa": "1:12",
        "name_de": "1:12",
        "name_nl": "1:12",
        "name_pl": "1:12",
        "name_ro": "1:12",
        "name_ru": "1:12",
        "name_tr": "1:12",
        "name_se": "1:12",
        "name_el": "1:12",
        "name_es": "1:12",
        "name_hr": "1:12",
        "name_fr": "1:12"
    },
    "2261^live_match^8^5:1": {
        "name_en": "5:1",
        "name_aa": "5:1",
        "name_de": "5:1",
        "name_nl": "5:1",
        "name_pl": "5:1",
        "name_ro": "5:1",
        "name_ru": "5:1",
        "name_tr": "5:1",
        "name_se": "5:1",
        "name_el": "5:1",
        "name_es": "5:1",
        "name_hr": "5:1",
        "name_fr": "5:1"
    },
    "2068^live_match^6^other": {
        "name_en": "other",
        "name_aa": "other",
        "name_de": "Sonstig",
        "name_nl": "Andere",
        "name_pl": "other",
        "name_ro": "other",
        "name_ru": "other",
        "name_tr": "other",
        "name_se": "other",
        "name_el": "other",
        "name_es": "other",
        "name_hr": "Ostali",
        "name_fr": "other"
    },
    "1108^live_match^8^11:3": {
        "name_en": "11:3",
        "name_aa": "11:3",
        "name_de": "11:3",
        "name_nl": "11:3",
        "name_pl": "11:3",
        "name_ro": "11:3",
        "name_ru": "11:3",
        "name_tr": "11:3",
        "name_se": "11:3",
        "name_el": "11:3",
        "name_es": "11:3",
        "name_hr": "11:3",
        "name_fr": "11:3"
    },
    "1066^live_match^8^2:1": {
        "name_en": "2:1",
        "name_aa": "2:1",
        "name_de": "2:1",
        "name_nl": "2:1",
        "name_pl": "2:1",
        "name_ro": "2:1",
        "name_ru": "2:1",
        "name_tr": "2:1",
        "name_se": "2:1",
        "name_el": "2:1",
        "name_es": "2:1",
        "name_hr": "2:1",
        "name_fr": "2:1"
    },
    "2005^live_match^8^away_regular_time": {
        "name_en": "Away regular time",
        "name_aa": "Away regular time",
        "name_de": "Away regular time",
        "name_nl": "Uit reguliere speeltijd",
        "name_pl": "Away regular time",
        "name_ro": "Away regular time",
        "name_ru": "Away regular time",
        "name_tr": "Away regular time",
        "name_se": "Away regular time",
        "name_el": "Away regular time",
        "name_es": "Away regular time",
        "name_hr": "Away regular time",
        "name_fr": "Away regular time"
    },
    "1108^live_match^8^1:0": {
        "name_en": "1:0",
        "name_aa": "1:0",
        "name_de": "1:0",
        "name_nl": "1:0",
        "name_pl": "1:0",
        "name_ro": "1:0",
        "name_ru": "1:0",
        "name_tr": "1:0",
        "name_se": "1:0",
        "name_el": "1:0",
        "name_es": "1:0",
        "name_hr": "1:0",
        "name_fr": "1:0"
    },
    "2354^live_match^8^5:7": {
        "name_en": "5:7",
        "name_aa": "5:7",
        "name_de": "5:7",
        "name_nl": "5:7",
        "name_pl": "5:7",
        "name_ro": "5:7",
        "name_ru": "5:7",
        "name_tr": "5:7",
        "name_se": "5:7",
        "name_el": "5:7",
        "name_es": "5:7",
        "name_hr": "5:7",
        "name_fr": "5:7"
    },
    "2207^live_match^8^away_25-36": {
        "name_en": "away 25-36",
        "name_aa": "away 25-36",
        "name_de": "Auswärt 25-36",
        "name_nl": "Weg 25-36",
        "name_pl": "away 25-36",
        "name_ro": "away 25-36",
        "name_ru": "away 25-36",
        "name_tr": "away 25-36",
        "name_se": "away 25-36",
        "name_el": "away 25-36",
        "name_es": "away 25-36",
        "name_hr": "Gostujuči 25-36",
        "name_fr": "away 25-36"
    },
    "2073^live_match^8^14:21": {
        "name_en": "14:21",
        "name_aa": "14:21",
        "name_de": "14:21",
        "name_nl": "14:21",
        "name_pl": "14:21",
        "name_ro": "14:21",
        "name_ru": "14:21",
        "name_tr": "14:21",
        "name_se": "14:21",
        "name_el": "14:21",
        "name_es": "14:21",
        "name_hr": "14:21",
        "name_fr": "14:21"
    },
    "19^live_match^8^2:3": {
        "name_en": "2:3",
        "name_aa": "2:3",
        "name_de": "2:3",
        "name_nl": "2:3",
        "name_pl": "2:3",
        "name_ro": "2:3",
        "name_ru": "2:3",
        "name_tr": "2:3",
        "name_se": "2:3",
        "name_el": "2:3",
        "name_es": "2:3",
        "name_hr": "2:3",
        "name_fr": "2:3"
    },
    "1344^live_match^8^even": {
        "name_en": "even",
        "name_aa": "even",
        "name_de": "Gerade",
        "name_nl": "even",
        "name_pl": "even",
        "name_ro": "even",
        "name_ru": "even",
        "name_tr": "çift",
        "name_se": "even",
        "name_el": "even",
        "name_es": "even",
        "name_hr": "Paran",
        "name_fr": "pair"
    },
    "2184^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "2208^live_match^8^23-24": {
        "name_en": "23-24",
        "name_aa": "23-24",
        "name_de": "23-24",
        "name_nl": "23-24",
        "name_pl": "23-24",
        "name_ro": "23-24",
        "name_ru": "23-24",
        "name_tr": "23-24",
        "name_se": "23-24",
        "name_el": "23-24",
        "name_es": "23-24",
        "name_hr": "23-24",
        "name_fr": "23-24"
    },
    "2014^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "2314^live_match^8^away": {
        "name_en": "away",
        "name_aa": "away",
        "name_de": "Auswärts",
        "name_nl": "Uit",
        "name_pl": "away",
        "name_ro": "away",
        "name_ru": "away",
        "name_tr": "away",
        "name_se": "away",
        "name_el": "away",
        "name_es": "away",
        "name_hr": "Gostujući",
        "name_fr": "away"
    },
    "29^live_match^8^0:13": {
        "name_en": "0:13",
        "name_aa": "0:13",
        "name_de": "0:13",
        "name_nl": "0:13",
        "name_pl": "0:13",
        "name_ro": "0:13",
        "name_ru": "0:13",
        "name_tr": "0:13",
        "name_se": "0:13",
        "name_el": "0:13",
        "name_es": "0:13",
        "name_hr": "0:13",
        "name_fr": "0:13"
    },
    "2354^live_match^8^0:11": {
        "name_en": "0:11",
        "name_aa": "0:11",
        "name_de": "0:11",
        "name_nl": "0:11",
        "name_pl": "0:11",
        "name_ro": "0:11",
        "name_ru": "0:11",
        "name_tr": "0:11",
        "name_se": "0:11",
        "name_el": "0:11",
        "name_es": "0:11",
        "name_hr": "0:11",
        "name_fr": "0:11"
    },
    "1108^live_match^8^9:10": {
        "name_en": "9:10",
        "name_aa": "9:10",
        "name_de": "9:10",
        "name_nl": "9:10",
        "name_pl": "9:10",
        "name_ro": "9:10",
        "name_ru": "9:10",
        "name_tr": "9:10",
        "name_se": "9:10",
        "name_el": "9:10",
        "name_es": "9:10",
        "name_hr": "9:10",
        "name_fr": "9:10"
    },
    "2149^live_match^8^away_6+": {
        "name_en": "away 6+",
        "name_aa": "away 6+",
        "name_de": "Auswärt 6+",
        "name_nl": "Weg 6+",
        "name_pl": "away 6+",
        "name_ro": "away 6+",
        "name_ru": "away 6+",
        "name_tr": "away 6+",
        "name_se": "away 6+",
        "name_el": "away 6+",
        "name_es": "away 6+",
        "name_hr": "Gostujuči 6+",
        "name_fr": "away 6+"
    },
    "2301^live_match^8^away": {
        "name_en": "away",
        "name_aa": "away",
        "name_de": "Auswärts",
        "name_nl": "Uit",
        "name_pl": "away",
        "name_ro": "away",
        "name_ru": "away",
        "name_tr": "away",
        "name_se": "away",
        "name_el": "away",
        "name_es": "away",
        "name_hr": "Gostujući",
        "name_fr": "away"
    },
    "1108^live_match^8^10:9": {
        "name_en": "10:9",
        "name_aa": "10:9",
        "name_de": "10:9",
        "name_nl": "10:9",
        "name_pl": "10:9",
        "name_ro": "10:9",
        "name_ru": "10:9",
        "name_tr": "10:9",
        "name_se": "10:9",
        "name_el": "10:9",
        "name_es": "10:9",
        "name_hr": "10:9",
        "name_fr": "10:9"
    },
    "2244^live_match^8^away": {
        "name_en": "away",
        "name_aa": "away",
        "name_de": "Auswärts",
        "name_nl": "Uit",
        "name_pl": "away",
        "name_ro": "away",
        "name_ru": "away",
        "name_tr": "away",
        "name_se": "away",
        "name_el": "away",
        "name_es": "away",
        "name_hr": "Gostujući",
        "name_fr": "away"
    },
    "1523^live_match^8^1x_and_over": {
        "name_en": "1X and over",
        "name_aa": "1X and over",
        "name_de": "1X und Über",
        "name_nl": "1X and Meer dan",
        "name_pl": "1X and over",
        "name_ro": "1X and over",
        "name_ru": "1X and over",
        "name_tr": "1X and over",
        "name_se": "1X and over",
        "name_el": "1X and over",
        "name_es": "1X and over",
        "name_hr": "1X i više",
        "name_fr": "1X and over"
    },
    "2157^live_match^8^16:21": {
        "name_en": "16:21",
        "name_aa": "16:21",
        "name_de": "16:21",
        "name_nl": "16:21",
        "name_pl": "16:21",
        "name_ro": "16:21",
        "name_ru": "16:21",
        "name_tr": "16:21",
        "name_se": "16:21",
        "name_el": "16:21",
        "name_es": "16:21",
        "name_hr": "16:21",
        "name_fr": "16:21"
    },
    "2046^live_match^8^exactly": {
        "name_en": "exactly",
        "name_aa": "exactly",
        "name_de": "Genau",
        "name_nl": "Precies",
        "name_pl": "exactly",
        "name_ro": "exactly",
        "name_ru": "exactly",
        "name_tr": "exactly",
        "name_se": "exactly",
        "name_el": "exactly",
        "name_es": "exactly",
        "name_hr": "Tacno",
        "name_fr": "exactly"
    },
    "1108^live_match^8^0:3": {
        "name_en": "0:3",
        "name_aa": "0:3",
        "name_de": "0:3",
        "name_nl": "0:3",
        "name_pl": "0:3",
        "name_ro": "0:3",
        "name_ru": "0:3",
        "name_tr": "0:3",
        "name_se": "0:3",
        "name_el": "0:3",
        "name_es": "0:3",
        "name_hr": "0:3",
        "name_fr": "0:3"
    },
    "2126^live_match^8^away_1-2": {
        "name_en": "away 1-2",
        "name_aa": "away 1-2",
        "name_de": "Auswärt 1-2",
        "name_nl": "Uit 1-2",
        "name_pl": "away 1-2",
        "name_ro": "away 1-2",
        "name_ru": "away 1-2",
        "name_tr": "away 1-2",
        "name_se": "away 1-2",
        "name_el": "away 1-2",
        "name_es": "away 1-2",
        "name_hr": "Gostujuči 1-2",
        "name_fr": "away 1-2"
    },
    "1066^live_match^8^1:1": {
        "name_en": "1:1",
        "name_aa": "1:1",
        "name_de": "1:1",
        "name_nl": "1:1",
        "name_pl": "1:1",
        "name_ro": "1:1",
        "name_ru": "1:1",
        "name_tr": "1:1",
        "name_se": "1:1",
        "name_el": "1:1",
        "name_es": "1:1",
        "name_hr": "1:1",
        "name_fr": "1:1"
    },
    "2216^live_match^8^draw": {
        "name_en": "draw",
        "name_aa": "draw",
        "name_de": "Unentschieden",
        "name_nl": "Gelijkspel",
        "name_pl": "draw",
        "name_ro": "draw",
        "name_ru": "draw",
        "name_tr": "draw",
        "name_se": "draw",
        "name_el": "draw",
        "name_es": "draw",
        "name_hr": "Neriješeno",
        "name_fr": "draw"
    },
    "2172^live_match^8^aa": {
        "name_en": "AA",
        "name_aa": "AA",
        "name_de": "AA",
        "name_nl": "AA",
        "name_pl": "AA",
        "name_ro": "AA",
        "name_ru": "AA",
        "name_tr": "AA",
        "name_se": "AA",
        "name_el": "AA",
        "name_es": "AA",
        "name_hr": "AA",
        "name_fr": "AA"
    },
    "1108^live_match^8^2:7": {
        "name_en": "2:7",
        "name_aa": "2:7",
        "name_de": "2:7",
        "name_nl": "2:7",
        "name_pl": "2:7",
        "name_ro": "2:7",
        "name_ru": "2:7",
        "name_tr": "2:7",
        "name_se": "2:7",
        "name_el": "2:7",
        "name_es": "2:7",
        "name_hr": "2:7",
        "name_fr": "2:7"
    },
    "2354^live_match^8^11:10": {
        "name_en": "11:10",
        "name_aa": "11:10",
        "name_de": "11:10",
        "name_nl": "11:10",
        "name_pl": "11:10",
        "name_ro": "11:10",
        "name_ru": "11:10",
        "name_tr": "11:10",
        "name_se": "11:10",
        "name_el": "11:10",
        "name_es": "11:10",
        "name_hr": "11:10",
        "name_fr": "11:10"
    },
    "874^live_match^8^under": {
        "name_en": "Under",
        "name_aa": "u",
        "name_de": "Unter",
        "name_nl": "u",
        "name_pl": "u",
        "name_ro": "u",
        "name_ru": "u",
        "name_tr": "Alt",
        "name_se": "u",
        "name_el": "u",
        "name_es": "u",
        "name_hr": "Manje",
        "name_fr": "m"
    },
    "26^live_match^8^0:12": {
        "name_en": "0:12",
        "name_aa": "0:12",
        "name_de": "0:12",
        "name_nl": "0:12",
        "name_pl": "0:12",
        "name_ro": "0:12",
        "name_ru": "0:12",
        "name_tr": "0:12",
        "name_se": "0:12",
        "name_el": "0:12",
        "name_es": "0:12",
        "name_hr": "0:12",
        "name_fr": "0:12"
    },
    "2131^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "29^live_match^8^4:6": {
        "name_en": "4:6",
        "name_aa": "4:6",
        "name_de": "4:6",
        "name_nl": "4:6",
        "name_pl": "4:6",
        "name_ro": "4:6",
        "name_ru": "4:6",
        "name_tr": "4:6",
        "name_se": "4:6",
        "name_el": "4:6",
        "name_es": "4:6",
        "name_hr": "4:6",
        "name_fr": "4:6"
    },
    "325^live_match^8^x2": {
        "name_en": "X2",
        "name_aa": "X2",
        "name_de": "X2",
        "name_nl": "1X",
        "name_pl": "X2",
        "name_ro": "X2",
        "name_ru": "X2",
        "name_tr": "X2",
        "name_se": "X2",
        "name_el": "X2",
        "name_es": "X2",
        "name_hr": "X2",
        "name_fr": "X2"
    },
    "2086^live_match^8^1:1": {
        "name_en": "1:1",
        "name_aa": "1:1",
        "name_de": "1:1",
        "name_nl": "1:1",
        "name_pl": "1:1",
        "name_ro": "1:1",
        "name_ru": "1:1",
        "name_tr": "1:1",
        "name_se": "1:1",
        "name_el": "1:1",
        "name_es": "1:1",
        "name_hr": "1:1",
        "name_fr": "1:1"
    },
    "26^live_match^8^3:13": {
        "name_en": "3:13",
        "name_aa": "3:13",
        "name_de": "3:13",
        "name_nl": "3:13",
        "name_pl": "3:13",
        "name_ro": "3:13",
        "name_ru": "3:13",
        "name_tr": "3:13",
        "name_se": "3:13",
        "name_el": "3:13",
        "name_es": "3:13",
        "name_hr": "3:13",
        "name_fr": "3:13"
    },
    "2063^live_match^8^away_17-20": {
        "name_en": "away 17-20",
        "name_aa": "away 17-20",
        "name_de": "Auswärt 17-20",
        "name_nl": "Weg 17-20",
        "name_pl": "away 17-20",
        "name_ro": "away 17-20",
        "name_ru": "away 17-20",
        "name_tr": "away 17-20",
        "name_se": "away 17-20",
        "name_el": "away 17-20",
        "name_es": "away 17-20",
        "name_hr": "Gostujuči 17-20",
        "name_fr": "away 17-20"
    },
    "2172^live_match^8^ah": {
        "name_en": "AH",
        "name_aa": "AH",
        "name_de": "AH",
        "name_nl": "AH",
        "name_pl": "AH",
        "name_ro": "AH",
        "name_ru": "AH",
        "name_tr": "AH",
        "name_se": "AH",
        "name_el": "AH",
        "name_es": "AH",
        "name_hr": "AH",
        "name_fr": "AH"
    },
    "2091^live_match^8^yes": {
        "name_en": "yes",
        "name_aa": "yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "yes",
        "name_ro": "yes",
        "name_ru": "yes",
        "name_tr": "yes",
        "name_se": "yes",
        "name_el": "yes",
        "name_es": "yes",
        "name_hr": "Da",
        "name_fr": "yes"
    },
    "1086^live_match^8^equal": {
        "name_en": "equal",
        "name_aa": "equal",
        "name_de": "gleich",
        "name_nl": "gelijk",
        "name_pl": "equal",
        "name_ro": "equal",
        "name_ru": "equal",
        "name_tr": "eşit",
        "name_se": "equal",
        "name_el": "equal",
        "name_es": "equal",
        "name_hr": "jednako",
        "name_fr": "identiques"
    },
    "1216^live_match^8^no": {
        "name_en": "No",
        "name_aa": "No",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "No",
        "name_ro": "No",
        "name_ru": "No",
        "name_tr": "hayır",
        "name_se": "No",
        "name_el": "No",
        "name_es": "No",
        "name_hr": "Ne",
        "name_fr": "non"
    },
    "116^live_match^8^2:2": {
        "name_en": "2:2",
        "name_aa": "2:2",
        "name_de": "2:2",
        "name_nl": "2:2",
        "name_pl": "2:2",
        "name_ro": "2:2",
        "name_ru": "2:2",
        "name_tr": "2:2",
        "name_se": "2:2",
        "name_el": "2:2",
        "name_es": "2:2",
        "name_hr": "2:2",
        "name_fr": "2:2"
    },
    "341^live_match^8^3:4": {
        "name_en": "3:4",
        "name_aa": "3:4",
        "name_de": "3:4",
        "name_nl": "3:4",
        "name_pl": "3:4",
        "name_ro": "3:4",
        "name_ru": "3:4",
        "name_tr": "3:4",
        "name_se": "3:4",
        "name_el": "3:4",
        "name_es": "3:4",
        "name_hr": "3:4",
        "name_fr": "3:4"
    },
    "918^live_match^8^1/2": {
        "name_en": "1/2",
        "name_aa": "1/2",
        "name_de": "1/2",
        "name_nl": "1/2",
        "name_pl": "1/2",
        "name_ro": "1/2",
        "name_ru": "1/2",
        "name_tr": "1/2",
        "name_se": "1/2",
        "name_el": "1/2",
        "name_es": "1/2",
        "name_hr": "1/2",
        "name_fr": "1/2"
    },
    "26^live_match^8^11:3": {
        "name_en": "11:3",
        "name_aa": "11:3",
        "name_de": "11:3",
        "name_nl": "11:3",
        "name_pl": "11:3",
        "name_ro": "11:3",
        "name_ru": "11:3",
        "name_tr": "11:3",
        "name_se": "11:3",
        "name_el": "11:3",
        "name_es": "11:3",
        "name_hr": "11:3",
        "name_fr": "11:3"
    },
    "1298^live_match^8^over": {
        "name_en": "Over",
        "name_aa": "Over",
        "name_de": "Über",
        "name_nl": "Over",
        "name_pl": "Over",
        "name_ro": "Over",
        "name_ru": "Over",
        "name_tr": "Üst",
        "name_se": "Over",
        "name_el": "Over",
        "name_es": "Over",
        "name_hr": "Više",
        "name_fr": "p"
    },
    "2061^live_match^8^away_9-10": {
        "name_en": "away 9-10",
        "name_aa": "away 9-10",
        "name_de": "Auswärt 9-10",
        "name_nl": "Weg 9-10",
        "name_pl": "away 9-10",
        "name_ro": "away 9-10",
        "name_ru": "away 9-10",
        "name_tr": "away 9-10",
        "name_se": "away 9-10",
        "name_el": "away 9-10",
        "name_es": "away 9-10",
        "name_hr": "Gostujuči 9-10",
        "name_fr": "away 9-10"
    },
    "2153^live_match^8^home_2": {
        "name_en": "home 2",
        "name_aa": "home 2",
        "name_de": "Heim 2",
        "name_nl": "Thuis 2",
        "name_pl": "home 2",
        "name_ro": "home 2",
        "name_ru": "home 2",
        "name_tr": "home 2",
        "name_se": "home 2",
        "name_el": "home 2",
        "name_es": "home 2",
        "name_hr": "Domači 2",
        "name_fr": "home 2"
    },
    "2143^live_match^8^away_5-6": {
        "name_en": "away 5-6",
        "name_aa": "away 5-6",
        "name_de": "Auswärt 5-6",
        "name_nl": "Weg 5-6",
        "name_pl": "away 5-6",
        "name_ro": "away 5-6",
        "name_ru": "away 5-6",
        "name_tr": "away 5-6",
        "name_se": "away 5-6",
        "name_el": "away 5-6",
        "name_es": "away 5-6",
        "name_hr": "Gostujuči 5-6",
        "name_fr": "away 5-6"
    },
    "2051^live_match^6^x": {
        "name_en": "x",
        "name_aa": "x",
        "name_de": "x",
        "name_nl": "x",
        "name_pl": "x",
        "name_ro": "x",
        "name_ru": "x",
        "name_tr": "x",
        "name_se": "x",
        "name_el": "x",
        "name_es": "x",
        "name_hr": "x",
        "name_fr": "x"
    },
    "1278^live_match^8^under": {
        "name_en": "Under",
        "name_aa": "u",
        "name_de": "Unter",
        "name_nl": "u",
        "name_pl": "u",
        "name_ro": "u",
        "name_ru": "u",
        "name_tr": "Alt",
        "name_se": "u",
        "name_el": "u",
        "name_es": "u",
        "name_hr": "Manje",
        "name_fr": "m"
    },
    "2116^live_match^8^yes": {
        "name_en": "yes",
        "name_aa": "yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "yes",
        "name_ro": "yes",
        "name_ru": "yes",
        "name_tr": "yes",
        "name_se": "yes",
        "name_el": "yes",
        "name_es": "yes",
        "name_hr": "Da",
        "name_fr": "yes"
    },
    "1108^live_match^8^12:6": {
        "name_en": "12:6",
        "name_aa": "12:6",
        "name_de": "12:6",
        "name_nl": "12:6",
        "name_pl": "12:6",
        "name_ro": "12:6",
        "name_ru": "12:6",
        "name_tr": "12:6",
        "name_se": "12:6",
        "name_el": "12:6",
        "name_es": "12:6",
        "name_hr": "12:6",
        "name_fr": "12:6"
    },
    "2067^live_match^6^home_win_by_3+": {
        "name_en": "home win by 3+",
        "name_aa": "home win by 3+",
        "name_de": "Heim gewinnt durch 3+",
        "name_nl": "thuiszege met 3+",
        "name_pl": "home win by 3+",
        "name_ro": "home win by 3+",
        "name_ru": "home win by 3+",
        "name_tr": "home win by 3+",
        "name_se": "home win by 3+",
        "name_el": "home win by 3+",
        "name_es": "home win by 3+",
        "name_hr": "Domači pobijediti po 3+",
        "name_fr": "home win by 3+"
    },
    "2261^live_match^8^0:4": {
        "name_en": "0:4",
        "name_aa": "0:4",
        "name_de": "0:4",
        "name_nl": "0:4",
        "name_pl": "0:4",
        "name_ro": "0:4",
        "name_ru": "0:4",
        "name_tr": "0:4",
        "name_se": "0:4",
        "name_el": "0:4",
        "name_es": "0:4",
        "name_hr": "0:4",
        "name_fr": "0:4"
    },
    "1202^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2215^live_match^8^away_1-24": {
        "name_en": "away 1-24",
        "name_aa": "away 1-24",
        "name_de": "Auswärt 1-24",
        "name_nl": "Weg 1-24",
        "name_pl": "away 1-24",
        "name_ro": "away 1-24",
        "name_ru": "away 1-24",
        "name_tr": "away 1-24",
        "name_se": "away 1-24",
        "name_el": "away 1-24",
        "name_es": "away 1-24",
        "name_hr": "Gostujuči 1-24",
        "name_fr": "away 1-24"
    },
    "26^live_match^8^0:9": {
        "name_en": "0:9",
        "name_aa": "0:9",
        "name_de": "0:9",
        "name_nl": "0:9",
        "name_pl": "0:9",
        "name_ro": "0:9",
        "name_ru": "0:9",
        "name_tr": "0:9",
        "name_se": "0:9",
        "name_el": "0:9",
        "name_es": "0:9",
        "name_hr": "0:9",
        "name_fr": "0:9"
    },
    "2261^live_match^8^6:3": {
        "name_en": "6:3",
        "name_aa": "6:3",
        "name_de": "6:3",
        "name_nl": "6:3",
        "name_pl": "6:3",
        "name_ro": "6:3",
        "name_ru": "6:3",
        "name_tr": "6:3",
        "name_se": "6:3",
        "name_el": "6:3",
        "name_es": "6:3",
        "name_hr": "6:3",
        "name_fr": "6:3"
    },
    "1108^live_match^8^13:0": {
        "name_en": "13:0",
        "name_aa": "13:0",
        "name_de": "13:0",
        "name_nl": "13:0",
        "name_pl": "13:0",
        "name_ro": "13:0",
        "name_ru": "13:0",
        "name_tr": "13:0",
        "name_se": "13:0",
        "name_el": "13:0",
        "name_es": "13:0",
        "name_hr": "13:0",
        "name_fr": "13:0"
    },
    "1230^live_match^8^147+": {
        "name_en": "147+",
        "name_aa": "147+",
        "name_de": "147+",
        "name_nl": "147+",
        "name_pl": "147+",
        "name_ro": "147+",
        "name_ru": "147+",
        "name_tr": "147+",
        "name_se": "147+",
        "name_el": "147+",
        "name_es": "147+",
        "name_hr": "147+",
        "name_fr": "147+"
    },
    "2343^live_match^8^yes": {
        "name_en": "yes",
        "name_aa": "yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "yes",
        "name_ro": "yes",
        "name_ru": "yes",
        "name_tr": "yes",
        "name_se": "yes",
        "name_el": "yes",
        "name_es": "yes",
        "name_hr": "Da",
        "name_fr": "yes"
    },
    "2328^live_match^8^score": {
        "name_en": "score",
        "name_aa": "score",
        "name_de": "Erzielt",
        "name_nl": "score",
        "name_pl": "score",
        "name_ro": "score",
        "name_ru": "score",
        "name_tr": "score",
        "name_se": "score",
        "name_el": "score",
        "name_es": "score",
        "name_hr": "score",
        "name_fr": "score"
    },
    "2354^live_match^8^2:0": {
        "name_en": "2:0",
        "name_aa": "2:0",
        "name_de": "2:0",
        "name_nl": "2:0",
        "name_pl": "2:0",
        "name_ro": "2:0",
        "name_ru": "2:0",
        "name_tr": "2:0",
        "name_se": "2:0",
        "name_el": "2:0",
        "name_es": "2:0",
        "name_hr": "2:0",
        "name_fr": "2:0"
    },
    "51^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2316^live_match^8^10:4": {
        "name_en": "10:4",
        "name_aa": "10:4",
        "name_de": "10:4",
        "name_nl": "10:4",
        "name_pl": "10:4",
        "name_ro": "10:4",
        "name_ru": "10:4",
        "name_tr": "10:4",
        "name_se": "10:4",
        "name_el": "10:4",
        "name_es": "10:4",
        "name_hr": "10:4",
        "name_fr": "10:4"
    },
    "1659^live_match^2^x": {
        "name_en": "x",
        "name_aa": "x",
        "name_de": "x",
        "name_nl": "x",
        "name_pl": "x",
        "name_ro": "x",
        "name_ru": "x",
        "name_tr": "x",
        "name_se": "x",
        "name_el": "x",
        "name_es": "x",
        "name_hr": "x",
        "name_fr": "x"
    },
    "1108^live_match^8^2:5": {
        "name_en": "2:5",
        "name_aa": "2:5",
        "name_de": "2:5",
        "name_nl": "2:5",
        "name_pl": "2:5",
        "name_ro": "2:5",
        "name_ru": "2:5",
        "name_tr": "2:5",
        "name_se": "2:5",
        "name_el": "2:5",
        "name_es": "2:5",
        "name_hr": "2:5",
        "name_fr": "2:5"
    },
    "26^live_match^8^0:14": {
        "name_en": "0:14",
        "name_aa": "0:14",
        "name_de": "0:14",
        "name_nl": "0:14",
        "name_pl": "0:14",
        "name_ro": "0:14",
        "name_ru": "0:14",
        "name_tr": "0:14",
        "name_se": "0:14",
        "name_el": "0:14",
        "name_es": "0:14",
        "name_hr": "0:14",
        "name_fr": "0:14"
    },
    "2226^live_match^8^yes": {
        "name_en": "yes",
        "name_aa": "yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "yes",
        "name_ro": "yes",
        "name_ru": "yes",
        "name_tr": "yes",
        "name_se": "yes",
        "name_el": "yes",
        "name_es": "yes",
        "name_hr": "Da",
        "name_fr": "yes"
    },
    "1108^live_match^8^7:0": {
        "name_en": "7:0",
        "name_aa": "7:0",
        "name_de": "7:0",
        "name_nl": "7:0",
        "name_pl": "7:0",
        "name_ro": "7:0",
        "name_ru": "7:0",
        "name_tr": "7:0",
        "name_se": "7:0",
        "name_el": "7:0",
        "name_es": "7:0",
        "name_hr": "7:0",
        "name_fr": "7:0"
    },
    "2074^live_match^8^away_after_extra_points": {
        "name_en": "away after extra points",
        "name_aa": "away after extra points",
        "name_de": "Auswärtsmannschaft Nach Verlängerung",
        "name_nl": "Uitteam Na extra punten",
        "name_pl": "away after extra points",
        "name_ro": "away after extra points",
        "name_ru": "away after extra points",
        "name_tr": "away after extra points",
        "name_se": "away after extra points",
        "name_el": "away after extra points",
        "name_es": "away after extra points",
        "name_hr": "away after extra points",
        "name_fr": "away after extra points"
    },
    "2354^live_match^8^9:5": {
        "name_en": "9:5",
        "name_aa": "9:5",
        "name_de": "9:5",
        "name_nl": "9:5",
        "name_pl": "9:5",
        "name_ro": "9:5",
        "name_ru": "9:5",
        "name_tr": "9:5",
        "name_se": "9:5",
        "name_el": "9:5",
        "name_es": "9:5",
        "name_hr": "9:5",
        "name_fr": "9:5"
    },
    "228^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2338^live_match^8^no_set_piece": {
        "name_en": "No Set Piece",
        "name_aa": "No Set Piece",
        "name_de": "No Set Piece",
        "name_nl": "No Set Piece",
        "name_pl": "No Set Piece",
        "name_ro": "No Set Piece",
        "name_ru": "No Set Piece",
        "name_tr": "No Set Piece",
        "name_se": "No Set Piece",
        "name_el": "No Set Piece",
        "name_es": "No Set Piece",
        "name_hr": "No Set Piece",
        "name_fr": "No Set Piece"
    },
    "74^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2354^live_match^8^12:7": {
        "name_en": "12:7",
        "name_aa": "12:7",
        "name_de": "12:7",
        "name_nl": "12:7",
        "name_pl": "12:7",
        "name_ro": "12:7",
        "name_ru": "12:7",
        "name_tr": "12:7",
        "name_se": "12:7",
        "name_el": "12:7",
        "name_es": "12:7",
        "name_hr": "12:7",
        "name_fr": "12:7"
    },
    "2216^live_match^8^away_40+": {
        "name_en": "away 40+",
        "name_aa": "away 40+",
        "name_de": "Auswärt 40+",
        "name_nl": "Weg 40+",
        "name_pl": "away 40+",
        "name_ro": "away 40+",
        "name_ru": "away 40+",
        "name_tr": "away 40+",
        "name_se": "away 40+",
        "name_el": "away 40+",
        "name_es": "away 40+",
        "name_hr": "Gostujuči 40+",
        "name_fr": "away 40+"
    },
    "204^live_match^8^under": {
        "name_en": "Under",
        "name_aa": "u",
        "name_de": "Unter",
        "name_nl": "u",
        "name_pl": "u",
        "name_ro": "u",
        "name_ru": "u",
        "name_tr": "Alt",
        "name_se": "u",
        "name_el": "u",
        "name_es": "u",
        "name_hr": "Manje",
        "name_fr": "m"
    },
    "512^live_match^8^no": {
        "name_en": "No",
        "name_aa": "No",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "No",
        "name_ro": "No",
        "name_ru": "No",
        "name_tr": "hayır",
        "name_se": "No",
        "name_el": "No",
        "name_es": "No",
        "name_hr": "Ne",
        "name_fr": "non"
    },
    "2059^live_match^8^away_1-3": {
        "name_en": "away 1-3",
        "name_aa": "away 1-3",
        "name_de": "Auswärt 1-3",
        "name_nl": "Weg 1-3",
        "name_pl": "away 1-3",
        "name_ro": "away 1-3",
        "name_ru": "away 1-3",
        "name_tr": "away 1-3",
        "name_se": "away 1-3",
        "name_el": "away 1-3",
        "name_es": "away 1-3",
        "name_hr": "Gostujuči 1-3",
        "name_fr": "away 1-3"
    },
    "2354^live_match^8^1:1": {
        "name_en": "1:1",
        "name_aa": "1:1",
        "name_de": "1:1",
        "name_nl": "1:1",
        "name_pl": "1:1",
        "name_ro": "1:1",
        "name_ru": "1:1",
        "name_tr": "1:1",
        "name_se": "1:1",
        "name_el": "1:1",
        "name_es": "1:1",
        "name_hr": "1:1",
        "name_fr": "1:1"
    },
    "2301^live_match^8^home": {
        "name_en": "home",
        "name_aa": "home",
        "name_de": "Heim",
        "name_nl": "Thuis",
        "name_pl": "home",
        "name_ro": "home",
        "name_ru": "home",
        "name_tr": "home",
        "name_se": "home",
        "name_el": "home",
        "name_es": "home",
        "name_hr": "Domači",
        "name_fr": "home"
    },
    "2085^live_match^8^over_6_points": {
        "name_en": "Over 6 Points",
        "name_aa": "Over 6 Points",
        "name_de": "Über 6 punkte",
        "name_nl": "Meer dan 6 Punten",
        "name_pl": "Over 6 Points",
        "name_ro": "Over 6 Points",
        "name_ru": "Over 6 Points",
        "name_tr": "Over 6 Points",
        "name_se": "Over 6 Points",
        "name_el": "Over 6 Points",
        "name_es": "Over 6 Points",
        "name_hr": "Over 6 Points",
        "name_fr": "Over 6 Points"
    },
    "89^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2011^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "116^live_match^8^3:0": {
        "name_en": "3:0",
        "name_aa": "3:0",
        "name_de": "3:0",
        "name_nl": "3:0",
        "name_pl": "3:0",
        "name_ro": "3:0",
        "name_ru": "3:0",
        "name_tr": "3:0",
        "name_se": "3:0",
        "name_el": "3:0",
        "name_es": "3:0",
        "name_hr": "3:0",
        "name_fr": "3:0"
    },
    "2218^live_match^8^1:2": {
        "name_en": "1:2",
        "name_aa": "1:2",
        "name_de": "1:2",
        "name_nl": "1:2",
        "name_pl": "1:2",
        "name_ro": "1:2",
        "name_ru": "1:2",
        "name_tr": "1:2",
        "name_se": "1:2",
        "name_el": "1:2",
        "name_es": "1:2",
        "name_hr": "1:2",
        "name_fr": "1:2"
    },
    "102^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "924^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "1670^live_match^8^yes": {
        "name_en": "yes",
        "name_aa": "yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "yes",
        "name_ro": "yes",
        "name_ru": "yes",
        "name_tr": "yes",
        "name_se": "yes",
        "name_el": "yes",
        "name_es": "yes",
        "name_hr": "Da",
        "name_fr": "yes"
    },
    "52^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "26^live_match^8^10:3": {
        "name_en": "10:3",
        "name_aa": "10:3",
        "name_de": "10:3",
        "name_nl": "10:3",
        "name_pl": "10:3",
        "name_ro": "10:3",
        "name_ru": "10:3",
        "name_tr": "10:3",
        "name_se": "10:3",
        "name_el": "10:3",
        "name_es": "10:3",
        "name_hr": "10:3",
        "name_fr": "10:3"
    },
    "2143^live_match^8^away_7+": {
        "name_en": "away 7+",
        "name_aa": "away 7+",
        "name_de": "Auswärt 7+",
        "name_nl": "Weg 7+",
        "name_pl": "away 7+",
        "name_ro": "away 7+",
        "name_ru": "away 7+",
        "name_tr": "away 7+",
        "name_se": "away 7+",
        "name_el": "away 7+",
        "name_es": "away 7+",
        "name_hr": "Gostujuči 7+",
        "name_fr": "away 7+"
    },
    "2059^live_match^8^away_10-12": {
        "name_en": "away 10-12",
        "name_aa": "away 10-12",
        "name_de": "Auswärt 10-12",
        "name_nl": "Weg 10-12",
        "name_pl": "away 10-12",
        "name_ro": "away 10-12",
        "name_ru": "away 10-12",
        "name_tr": "away 10-12",
        "name_se": "away 10-12",
        "name_el": "away 10-12",
        "name_es": "away 10-12",
        "name_hr": "Gostujuči 10-12",
        "name_fr": "away 10-12"
    },
    "79^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2264^live_match^8^exactly": {
        "name_en": "exactly",
        "name_aa": "exactly",
        "name_de": "Genau",
        "name_nl": "Precies",
        "name_pl": "exactly",
        "name_ro": "exactly",
        "name_ru": "exactly",
        "name_tr": "exactly",
        "name_se": "exactly",
        "name_el": "exactly",
        "name_es": "exactly",
        "name_hr": "Tacno",
        "name_fr": "exactly"
    },
    "2261^live_match^8^3:4": {
        "name_en": "3:4",
        "name_aa": "3:4",
        "name_de": "3:4",
        "name_nl": "3:4",
        "name_pl": "3:4",
        "name_ro": "3:4",
        "name_ru": "3:4",
        "name_tr": "3:4",
        "name_se": "3:4",
        "name_el": "3:4",
        "name_es": "3:4",
        "name_hr": "3:4",
        "name_fr": "3:4"
    },
    "291^live_match^6^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2153^live_match^8^away_4": {
        "name_en": "away 4",
        "name_aa": "away 4",
        "name_de": "Auswärt 4",
        "name_nl": "Weg 4",
        "name_pl": "away 4",
        "name_ro": "away 4",
        "name_ru": "away 4",
        "name_tr": "away 4",
        "name_se": "away 4",
        "name_el": "away 4",
        "name_es": "away 4",
        "name_hr": "Gostujuči 4",
        "name_fr": "away 4"
    },
    "2342^live_match^8^yes": {
        "name_en": "yes",
        "name_aa": "yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "yes",
        "name_ro": "yes",
        "name_ru": "yes",
        "name_tr": "yes",
        "name_se": "yes",
        "name_el": "yes",
        "name_es": "yes",
        "name_hr": "Da",
        "name_fr": "yes"
    },
    "116^live_match^8^0:1": {
        "name_en": "0:1",
        "name_aa": "0:1",
        "name_de": "0:1",
        "name_nl": "0:1",
        "name_pl": "0:1",
        "name_ro": "0:1",
        "name_ru": "0:1",
        "name_tr": "0:1",
        "name_se": "0:1",
        "name_el": "0:1",
        "name_es": "0:1",
        "name_hr": "0:1",
        "name_fr": "0:1"
    },
    "2253^live_match^8^yes": {
        "name_en": "yes",
        "name_aa": "yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "yes",
        "name_ro": "yes",
        "name_ru": "yes",
        "name_tr": "yes",
        "name_se": "yes",
        "name_el": "yes",
        "name_es": "yes",
        "name_hr": "Da",
        "name_fr": "yes"
    },
    "1032^live_match^8^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "17^live_match^6^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2317^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "77^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "1066^live_match^8^0:2": {
        "name_en": "0:2",
        "name_aa": "0:2",
        "name_de": "0:2",
        "name_nl": "0:2",
        "name_pl": "0:2",
        "name_ro": "0:2",
        "name_ru": "0:2",
        "name_tr": "0:2",
        "name_se": "0:2",
        "name_el": "0:2",
        "name_es": "0:2",
        "name_hr": "0:2",
        "name_fr": "0:2"
    },
    "2354^live_match^8^6:1": {
        "name_en": "6:1",
        "name_aa": "6:1",
        "name_de": "6:1",
        "name_nl": "6:1",
        "name_pl": "6:1",
        "name_ro": "6:1",
        "name_ru": "6:1",
        "name_tr": "6:1",
        "name_se": "6:1",
        "name_el": "6:1",
        "name_es": "6:1",
        "name_hr": "6:1",
        "name_fr": "6:1"
    },
    "2300^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "71^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "29^live_match^8^8:0": {
        "name_en": "8:0",
        "name_aa": "8:0",
        "name_de": "8:0",
        "name_nl": "8:0",
        "name_pl": "8:0",
        "name_ro": "8:0",
        "name_ru": "8:0",
        "name_tr": "8:0",
        "name_se": "8:0",
        "name_el": "8:0",
        "name_es": "8:0",
        "name_hr": "8:0",
        "name_fr": "8:0"
    },
    "2354^live_match^8^0:4": {
        "name_en": "0:4",
        "name_aa": "0:4",
        "name_de": "0:4",
        "name_nl": "0:4",
        "name_pl": "0:4",
        "name_ro": "0:4",
        "name_ru": "0:4",
        "name_tr": "0:4",
        "name_se": "0:4",
        "name_el": "0:4",
        "name_es": "0:4",
        "name_hr": "0:4",
        "name_fr": "0:4"
    },
    "2354^live_match^8^3:12": {
        "name_en": "3:12",
        "name_aa": "3:12",
        "name_de": "3:12",
        "name_nl": "3:12",
        "name_pl": "3:12",
        "name_ro": "3:12",
        "name_ru": "3:12",
        "name_tr": "3:12",
        "name_se": "3:12",
        "name_el": "3:12",
        "name_es": "3:12",
        "name_hr": "3:12",
        "name_fr": "3:12"
    },
    "2046^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "1214^live_match^8^odd": {
        "name_en": "odd",
        "name_aa": "odd",
        "name_de": "Ungerade",
        "name_nl": "oneven",
        "name_pl": "odd",
        "name_ro": "odd",
        "name_ru": "odd",
        "name_tr": "tek",
        "name_se": "odd",
        "name_el": "odd",
        "name_es": "odd",
        "name_hr": "Neparan",
        "name_fr": "impair"
    },
    "2162^live_match^8^1/2": {
        "name_en": "1/2",
        "name_aa": "1/2",
        "name_de": "1/2",
        "name_nl": "1/2",
        "name_pl": "1/2",
        "name_ro": "1/2",
        "name_ru": "1/2",
        "name_tr": "1/2",
        "name_se": "1/2",
        "name_el": "1/2",
        "name_es": "1/2",
        "name_hr": "1/2",
        "name_fr": "1/2"
    },
    "2228^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "2060^live_match^8^tie": {
        "name_en": "tie",
        "name_aa": "tie",
        "name_de": "Unentschieden",
        "name_nl": "Gelijkspel",
        "name_pl": "tie",
        "name_ro": "tie",
        "name_ru": "tie",
        "name_tr": "tie",
        "name_se": "tie",
        "name_el": "tie",
        "name_es": "tie",
        "name_hr": "tie",
        "name_fr": "tie"
    },
    "2331^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2212^live_match^8^home_37+": {
        "name_en": "home 37+",
        "name_aa": "home 37+",
        "name_de": "Heim 37+",
        "name_nl": "Thuis 37+",
        "name_pl": "home 37+",
        "name_ro": "home 37+",
        "name_ru": "home 37+",
        "name_tr": "home 37+",
        "name_se": "home 37+",
        "name_el": "home 37+",
        "name_es": "home 37+",
        "name_hr": "Domači 37+",
        "name_fr": "home 37+"
    },
    "2063^live_match^8^home_1-2": {
        "name_en": "home 1-2",
        "name_aa": "home 1-2",
        "name_de": "Heim 1-2",
        "name_nl": "thuis 1-2",
        "name_pl": "home 1-2",
        "name_ro": "home 1-2",
        "name_ru": "home 1-2",
        "name_tr": "home 1-2",
        "name_se": "home 1-2",
        "name_el": "home 1-2",
        "name_es": "home 1-2",
        "name_hr": "Domači 1-2",
        "name_fr": "home 1-2"
    },
    "2111^live_match^8^home_2": {
        "name_en": "home 2",
        "name_aa": "home 2",
        "name_de": "Heim 2",
        "name_nl": "Thuis 2",
        "name_pl": "home 2",
        "name_ro": "home 2",
        "name_ru": "home 2",
        "name_tr": "home 2",
        "name_se": "home 2",
        "name_el": "home 2",
        "name_es": "home 2",
        "name_hr": "Domači 2",
        "name_fr": "home 2"
    },
    "2076^live_match^8^15:9": {
        "name_en": "15:9",
        "name_aa": "15:9",
        "name_de": "15:9",
        "name_nl": "15:9",
        "name_pl": "15:9",
        "name_ro": "15:9",
        "name_ru": "15:9",
        "name_tr": "15:9",
        "name_se": "15:9",
        "name_el": "15:9",
        "name_es": "15:9",
        "name_hr": "15:9",
        "name_fr": "15:9"
    },
    "1108^live_match^8^4:5": {
        "name_en": "4:5",
        "name_aa": "4:5",
        "name_de": "4:5",
        "name_nl": "4:5",
        "name_pl": "4:5",
        "name_ro": "4:5",
        "name_ru": "4:5",
        "name_tr": "4:5",
        "name_se": "4:5",
        "name_el": "4:5",
        "name_es": "4:5",
        "name_hr": "4:5",
        "name_fr": "4:5"
    },
    "486^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2167^live_match^8^1/1": {
        "name_en": "1/1",
        "name_aa": "1/1",
        "name_de": "1/1",
        "name_nl": "1/1",
        "name_pl": "1/1",
        "name_ro": "1/1",
        "name_ru": "1/1",
        "name_tr": "1/1",
        "name_se": "1/1",
        "name_el": "1/1",
        "name_es": "1/1",
        "name_hr": "1/1",
        "name_fr": "1/1"
    },
    "2186^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "2061^live_match^8^home_3-4": {
        "name_en": "home 3-4",
        "name_aa": "home 3-4",
        "name_de": "Heim 3-4",
        "name_nl": "thuis 3-4",
        "name_pl": "home 3-4",
        "name_ro": "home 3-4",
        "name_ru": "home 3-4",
        "name_tr": "home 3-4",
        "name_se": "home 3-4",
        "name_el": "home 3-4",
        "name_es": "home 3-4",
        "name_hr": "Domači 3-4",
        "name_fr": "home 3-4"
    },
    "1529^live_match^8^away_and_under": {
        "name_en": "away and under",
        "name_aa": "away and under",
        "name_de": "Auswärt und Unter",
        "name_nl": "uit en onder",
        "name_pl": "away and under",
        "name_ro": "away and under",
        "name_ru": "away and under",
        "name_tr": "deplasman ve alt",
        "name_se": "away and under",
        "name_el": "away and under",
        "name_es": "away and under",
        "name_hr": "2 i Manje",
        "name_fr": "extérieur et Moins de"
    },
    "2354^live_match^8^9:3": {
        "name_en": "9:3",
        "name_aa": "9:3",
        "name_de": "9:3",
        "name_nl": "9:3",
        "name_pl": "9:3",
        "name_ro": "9:3",
        "name_ru": "9:3",
        "name_tr": "9:3",
        "name_se": "9:3",
        "name_el": "9:3",
        "name_es": "9:3",
        "name_hr": "9:3",
        "name_fr": "9:3"
    },
    "2207^live_match^8^away_49-60": {
        "name_en": "away 49-60",
        "name_aa": "away 49-60",
        "name_de": "Auswärt 49-60",
        "name_nl": "Weg 49-60",
        "name_pl": "away 49-60",
        "name_ro": "away 49-60",
        "name_ru": "away 49-60",
        "name_tr": "away 49-60",
        "name_se": "away 49-60",
        "name_el": "away 49-60",
        "name_es": "away 49-60",
        "name_hr": "Gostujuči 49-60",
        "name_fr": "away 49-60"
    },
    "2354^live_match^8^11:5": {
        "name_en": "11:5",
        "name_aa": "11:5",
        "name_de": "11:5",
        "name_nl": "11:5",
        "name_pl": "11:5",
        "name_ro": "11:5",
        "name_ru": "11:5",
        "name_tr": "11:5",
        "name_se": "11:5",
        "name_el": "11:5",
        "name_es": "11:5",
        "name_hr": "11:5",
        "name_fr": "11:5"
    },
    "2111^live_match^8^home_4": {
        "name_en": "home 4",
        "name_aa": "home 4",
        "name_de": "Heim 4",
        "name_nl": "Thuis 4",
        "name_pl": "home 4",
        "name_ro": "home 4",
        "name_ru": "home 4",
        "name_tr": "home 4",
        "name_se": "home 4",
        "name_el": "home 4",
        "name_es": "home 4",
        "name_hr": "Domači 4",
        "name_fr": "home 4"
    },
    "1108^live_match^8^4:9": {
        "name_en": "4:9",
        "name_aa": "4:9",
        "name_de": "4:9",
        "name_nl": "4:9",
        "name_pl": "4:9",
        "name_ro": "4:9",
        "name_ru": "4:9",
        "name_tr": "4:9",
        "name_se": "4:9",
        "name_el": "4:9",
        "name_es": "4:9",
        "name_hr": "4:9",
        "name_fr": "4:9"
    },
    "2038^live_match^4^x": {
        "name_en": "x",
        "name_aa": "x",
        "name_de": "x",
        "name_nl": "x",
        "name_pl": "x",
        "name_ro": "x",
        "name_ru": "x",
        "name_tr": "x",
        "name_se": "x",
        "name_el": "x",
        "name_es": "x",
        "name_hr": "x",
        "name_fr": "x"
    },
    "289^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2195^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "2261^live_match^8^8:5": {
        "name_en": "8:5",
        "name_aa": "8:5",
        "name_de": "8:5",
        "name_nl": "8:5",
        "name_pl": "8:5",
        "name_ro": "8:5",
        "name_ru": "8:5",
        "name_tr": "8:5",
        "name_se": "8:5",
        "name_el": "8:5",
        "name_es": "8:5",
        "name_hr": "8:5",
        "name_fr": "8:5"
    },
    "2220^live_match^8^3:0": {
        "name_en": "3:0",
        "name_aa": "3:0",
        "name_de": "3:0",
        "name_nl": "3:0",
        "name_pl": "3:0",
        "name_ro": "3:0",
        "name_ru": "3:0",
        "name_tr": "3:0",
        "name_se": "3:0",
        "name_el": "3:0",
        "name_es": "3:0",
        "name_hr": "3:0",
        "name_fr": "3:0"
    },
    "96^live_match^8^player_2_to_15": {
        "name_en": "Player 2 to 15",
        "name_aa": "Player 2 to 15",
        "name_de": "Spieler 2 zu 15",
        "name_nl": "Speler 2 tot 15",
        "name_pl": "Player 2 to 15",
        "name_ro": "Player 2 to 15",
        "name_ru": "Player 2 to 15",
        "name_tr": "2. Oyuncu 15'e karşı",
        "name_se": "Player 2 to 15",
        "name_el": "Player 2 to 15",
        "name_es": "Player 2 to 15",
        "name_hr": "Igrač 2 osvojio gem (protivnik osvojio 15 poena)",
        "name_fr": "Joueur 2 à 15"
    },
    "26^live_match^8^1:9": {
        "name_en": "1:9",
        "name_aa": "1:9",
        "name_de": "1:9",
        "name_nl": "1:9",
        "name_pl": "1:9",
        "name_ro": "1:9",
        "name_ru": "1:9",
        "name_tr": "1:9",
        "name_se": "1:9",
        "name_el": "1:9",
        "name_es": "1:9",
        "name_hr": "1:9",
        "name_fr": "1:9"
    },
    "96^live_match^8^player_2_to_40": {
        "name_en": "Player 2 to 40",
        "name_aa": "Player 2 to 40",
        "name_de": "Spieler 2 zu 40",
        "name_nl": "Speler 2 tot 40",
        "name_pl": "Player 2 to 40",
        "name_ro": "Player 2 to 40",
        "name_ru": "Player 2 to 40",
        "name_tr": "2. Oyuncu 40'a karşı",
        "name_se": "Player 2 to 40",
        "name_el": "Player 2 to 40",
        "name_es": "Player 2 to 40",
        "name_hr": "Igrač 2 osvojio gem (protivnik osvojio 40 poena)",
        "name_fr": "Joueur 2 à 40"
    },
    "2316^live_match^8^3:10": {
        "name_en": "3:10",
        "name_aa": "3:10",
        "name_de": "3:10",
        "name_nl": "3:10",
        "name_pl": "3:10",
        "name_ro": "3:10",
        "name_ru": "3:10",
        "name_tr": "3:10",
        "name_se": "3:10",
        "name_el": "3:10",
        "name_es": "3:10",
        "name_hr": "3:10",
        "name_fr": "3:10"
    },
    "2073^live_match^8^25:15_or_better": {
        "name_en": "25:15 or better",
        "name_aa": "25:15 or better",
        "name_de": "25:15 oder besser",
        "name_nl": "25:15 of beter",
        "name_pl": "25:15 or better",
        "name_ro": "25:15 or better",
        "name_ru": "25:15 or better",
        "name_tr": "25:15 or better",
        "name_se": "25:15 or better",
        "name_el": "25:15 or better",
        "name_es": "25:15 or better",
        "name_hr": "25:15 or better",
        "name_fr": "25:15 or better"
    },
    "1312^live_match^8^under": {
        "name_en": "Under",
        "name_aa": "u",
        "name_de": "Unter",
        "name_nl": "u",
        "name_pl": "u",
        "name_ro": "u",
        "name_ru": "u",
        "name_tr": "Alt",
        "name_se": "u",
        "name_el": "u",
        "name_es": "u",
        "name_hr": "Manje",
        "name_fr": "m"
    },
    "1667^live_match^8^yes": {
        "name_en": "yes",
        "name_aa": "yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "yes",
        "name_ro": "yes",
        "name_ru": "yes",
        "name_tr": "yes",
        "name_se": "yes",
        "name_el": "yes",
        "name_es": "yes",
        "name_hr": "Da",
        "name_fr": "yes"
    },
    "2354^live_match^8^12:3": {
        "name_en": "12:3",
        "name_aa": "12:3",
        "name_de": "12:3",
        "name_nl": "12:3",
        "name_pl": "12:3",
        "name_ro": "12:3",
        "name_ru": "12:3",
        "name_tr": "12:3",
        "name_se": "12:3",
        "name_el": "12:3",
        "name_es": "12:3",
        "name_hr": "12:3",
        "name_fr": "12:3"
    },
    "2354^live_match^8^10:11": {
        "name_en": "10:11",
        "name_aa": "10:11",
        "name_de": "10:11",
        "name_nl": "10:11",
        "name_pl": "10:11",
        "name_ro": "10:11",
        "name_ru": "10:11",
        "name_tr": "10:11",
        "name_se": "10:11",
        "name_el": "10:11",
        "name_es": "10:11",
        "name_hr": "10:11",
        "name_fr": "10:11"
    },
    "2255^live_match^8^yes": {
        "name_en": "yes",
        "name_aa": "yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "yes",
        "name_ro": "yes",
        "name_ru": "yes",
        "name_tr": "yes",
        "name_se": "yes",
        "name_el": "yes",
        "name_es": "yes",
        "name_hr": "Da",
        "name_fr": "yes"
    },
    "1108^live_match^8^15:1": {
        "name_en": "15:1",
        "name_aa": "15:1",
        "name_de": "15:1",
        "name_nl": "15:1",
        "name_pl": "15:1",
        "name_ro": "15:1",
        "name_ru": "15:1",
        "name_tr": "15:1",
        "name_se": "15:1",
        "name_el": "15:1",
        "name_es": "15:1",
        "name_hr": "15:1",
        "name_fr": "15:1"
    },
    "2083^live_match^8^away_and_over": {
        "name_en": "away and over",
        "name_aa": "away and over",
        "name_de": "Auswärt und Über",
        "name_nl": "Uit and Meer dan",
        "name_pl": "away and over",
        "name_ro": "away and over",
        "name_ru": "away and over",
        "name_tr": "away and over",
        "name_se": "away and over",
        "name_el": "away and over",
        "name_es": "away and over",
        "name_hr": "Gostujući i više",
        "name_fr": "away and over"
    },
    "29^live_match^8^0:1": {
        "name_en": "0:1",
        "name_aa": "0:1",
        "name_de": "0:1",
        "name_nl": "0:1",
        "name_pl": "0:1",
        "name_ro": "0:1",
        "name_ru": "0:1",
        "name_tr": "0:1",
        "name_se": "0:1",
        "name_el": "0:1",
        "name_es": "0:1",
        "name_hr": "0:1",
        "name_fr": "0:1"
    },
    "349^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2354^live_match^8^11:0": {
        "name_en": "11:0",
        "name_aa": "11:0",
        "name_de": "11:0",
        "name_nl": "11:0",
        "name_pl": "11:0",
        "name_ro": "11:0",
        "name_ru": "11:0",
        "name_tr": "11:0",
        "name_se": "11:0",
        "name_el": "11:0",
        "name_es": "11:0",
        "name_hr": "11:0",
        "name_fr": "11:0"
    },
    "7^live_match^7^1": {
        "name_en": "Under",
        "name_aa": "u",
        "name_de": "Unter",
        "name_nl": "u",
        "name_pl": "u",
        "name_ro": "u",
        "name_ru": "u",
        "name_tr": "Alt",
        "name_se": "u",
        "name_el": "u",
        "name_es": "u",
        "name_hr": "Manje",
        "name_fr": "m"
    },
    "26^live_match^8^18:0": {
        "name_en": "18:0",
        "name_aa": "18:0",
        "name_de": "18:0",
        "name_nl": "18:0",
        "name_pl": "18:0",
        "name_ro": "18:0",
        "name_ru": "18:0",
        "name_tr": "18:0",
        "name_se": "18:0",
        "name_el": "18:0",
        "name_es": "18:0",
        "name_hr": "18:0",
        "name_fr": "18:0"
    },
    "85^live_match^8^over": {
        "name_en": "Over",
        "name_aa": "Over",
        "name_de": "Über",
        "name_nl": "Over",
        "name_pl": "Over",
        "name_ro": "Over",
        "name_ru": "Over",
        "name_tr": "Üst",
        "name_se": "Over",
        "name_el": "Over",
        "name_es": "Over",
        "name_hr": "Više",
        "name_fr": "p"
    },
    "26^live_match^8^14:2": {
        "name_en": "14:2",
        "name_aa": "14:2",
        "name_de": "14:2",
        "name_nl": "14:2",
        "name_pl": "14:2",
        "name_ro": "14:2",
        "name_ru": "14:2",
        "name_tr": "14:2",
        "name_se": "14:2",
        "name_el": "14:2",
        "name_es": "14:2",
        "name_hr": "14:2",
        "name_fr": "14:2"
    },
    "29^live_match^8^9:1": {
        "name_en": "9:1",
        "name_aa": "9:1",
        "name_de": "9:1",
        "name_nl": "9:1",
        "name_pl": "9:1",
        "name_ro": "9:1",
        "name_ru": "9:1",
        "name_tr": "9:1",
        "name_se": "9:1",
        "name_el": "9:1",
        "name_es": "9:1",
        "name_hr": "9:1",
        "name_fr": "9:1"
    },
    "2305^live_match^8^7:0": {
        "name_en": "7:0",
        "name_aa": "7:0",
        "name_de": "7:0",
        "name_nl": "7:0",
        "name_pl": "7:0",
        "name_ro": "7:0",
        "name_ru": "7:0",
        "name_tr": "7:0",
        "name_se": "7:0",
        "name_el": "7:0",
        "name_es": "7:0",
        "name_hr": "7:0",
        "name_fr": "7:0"
    },
    "2354^live_match^8^12:8": {
        "name_en": "12:8",
        "name_aa": "12:8",
        "name_de": "12:8",
        "name_nl": "12:8",
        "name_pl": "12:8",
        "name_ro": "12:8",
        "name_ru": "12:8",
        "name_tr": "12:8",
        "name_se": "12:8",
        "name_el": "12:8",
        "name_es": "12:8",
        "name_hr": "12:8",
        "name_fr": "12:8"
    },
    "2074^live_match^8^17:25": {
        "name_en": "17:25",
        "name_aa": "17:25",
        "name_de": "17:25",
        "name_nl": "17:25",
        "name_pl": "17:25",
        "name_ro": "17:25",
        "name_ru": "17:25",
        "name_tr": "17:25",
        "name_se": "17:25",
        "name_el": "17:25",
        "name_es": "17:25",
        "name_hr": "17:25",
        "name_fr": "17:25"
    },
    "2085^live_match^8^6_points": {
        "name_en": "6 Points",
        "name_aa": "6 Points",
        "name_de": "6 Punkte",
        "name_nl": "6 Punten",
        "name_pl": "6 Points",
        "name_ro": "6 Points",
        "name_ru": "6 Points",
        "name_tr": "6 Points",
        "name_se": "6 Points",
        "name_el": "6 Points",
        "name_es": "6 Points",
        "name_hr": "6 Points",
        "name_fr": "6 Points"
    },
    "2121^live_match^8^2_no": {
        "name_en": "2 no",
        "name_aa": "2 no",
        "name_de": "2 Nein",
        "name_nl": "2 Nee",
        "name_pl": "2 no",
        "name_ro": "2 no",
        "name_ru": "2 no",
        "name_tr": "2 no",
        "name_se": "2 no",
        "name_el": "2 no",
        "name_es": "2 no",
        "name_hr": "2 Ne",
        "name_fr": "2 no"
    },
    "289^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2199^live_match^8^goal": {
        "name_en": "goal",
        "name_aa": "goal",
        "name_de": "Tor",
        "name_nl": "Doelpunt",
        "name_pl": "goal",
        "name_ro": "goal",
        "name_ru": "goal",
        "name_tr": "goal",
        "name_se": "goal",
        "name_el": "goal",
        "name_es": "goal",
        "name_hr": "Golova",
        "name_fr": "goal"
    },
    "2264^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "2354^live_match^8^5:4": {
        "name_en": "5:4",
        "name_aa": "5:4",
        "name_de": "5:4",
        "name_nl": "5:4",
        "name_pl": "5:4",
        "name_ro": "5:4",
        "name_ru": "5:4",
        "name_tr": "5:4",
        "name_se": "5:4",
        "name_el": "5:4",
        "name_es": "5:4",
        "name_hr": "5:4",
        "name_fr": "5:4"
    },
    "1156^live_match^8^4:1": {
        "name_en": "4:1",
        "name_aa": "4:1",
        "name_de": "4:1",
        "name_nl": "4:1",
        "name_pl": "4:1",
        "name_ro": "4:1",
        "name_ru": "4:1",
        "name_tr": "4:1",
        "name_se": "4:1",
        "name_el": "4:1",
        "name_es": "4:1",
        "name_hr": "4:1",
        "name_fr": "4:1"
    },
    "2062^live_match^8^home_3-4": {
        "name_en": "home 3-4",
        "name_aa": "home 3-4",
        "name_de": "Heim 3-4",
        "name_nl": "thuis 3-4",
        "name_pl": "home 3-4",
        "name_ro": "home 3-4",
        "name_ru": "home 3-4",
        "name_tr": "home 3-4",
        "name_se": "home 3-4",
        "name_el": "home 3-4",
        "name_es": "home 3-4",
        "name_hr": "Domači 3-4",
        "name_fr": "home 3-4"
    },
    "2354^live_match^8^1:7": {
        "name_en": "1:7",
        "name_aa": "1:7",
        "name_de": "1:7",
        "name_nl": "1:7",
        "name_pl": "1:7",
        "name_ro": "1:7",
        "name_ru": "1:7",
        "name_tr": "1:7",
        "name_se": "1:7",
        "name_el": "1:7",
        "name_es": "1:7",
        "name_hr": "1:7",
        "name_fr": "1:7"
    },
    "38^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2085^live_match^8^5_points": {
        "name_en": "5 Points",
        "name_aa": "5 Points",
        "name_de": "5 Punkte",
        "name_nl": "5 Punten",
        "name_pl": "5 Points",
        "name_ro": "5 Points",
        "name_ru": "5 Points",
        "name_tr": "5 Points",
        "name_se": "5 Points",
        "name_el": "5 Points",
        "name_es": "5 Points",
        "name_hr": "5 Points",
        "name_fr": "5 Points"
    },
    "26^live_match^8^4:0": {
        "name_en": "4:0",
        "name_aa": "4:0",
        "name_de": "4:0",
        "name_nl": "4:0",
        "name_pl": "4:0",
        "name_ro": "4:0",
        "name_ru": "4:0",
        "name_tr": "4:0",
        "name_se": "4:0",
        "name_el": "4:0",
        "name_es": "4:0",
        "name_hr": "4:0",
        "name_fr": "4:0"
    },
    "2148^live_match^8^other": {
        "name_en": "other",
        "name_aa": "other",
        "name_de": "Sonstig",
        "name_nl": "Andere",
        "name_pl": "other",
        "name_ro": "other",
        "name_ru": "other",
        "name_tr": "other",
        "name_se": "other",
        "name_el": "other",
        "name_es": "other",
        "name_hr": "Ostali",
        "name_fr": "other"
    },
    "1108^live_match^8^5:4": {
        "name_en": "5:4",
        "name_aa": "5:4",
        "name_de": "5:4",
        "name_nl": "5:4",
        "name_pl": "5:4",
        "name_ro": "5:4",
        "name_ru": "5:4",
        "name_tr": "5:4",
        "name_se": "5:4",
        "name_el": "5:4",
        "name_es": "5:4",
        "name_hr": "5:4",
        "name_fr": "5:4"
    },
    "41^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "1108^live_match^8^4:7": {
        "name_en": "4:7",
        "name_aa": "4:7",
        "name_de": "4:7",
        "name_nl": "4:7",
        "name_pl": "4:7",
        "name_ro": "4:7",
        "name_ru": "4:7",
        "name_tr": "4:7",
        "name_se": "4:7",
        "name_el": "4:7",
        "name_es": "4:7",
        "name_hr": "4:7",
        "name_fr": "4:7"
    },
    "1108^live_match^8^11:10": {
        "name_en": "11:10",
        "name_aa": "11:10",
        "name_de": "11:10",
        "name_nl": "11:10",
        "name_pl": "11:10",
        "name_ro": "11:10",
        "name_ru": "11:10",
        "name_tr": "11:10",
        "name_se": "11:10",
        "name_el": "11:10",
        "name_es": "11:10",
        "name_hr": "11:10",
        "name_fr": "11:10"
    },
    "1108^live_match^8^4:11": {
        "name_en": "4:11",
        "name_aa": "4:11",
        "name_de": "4:11",
        "name_nl": "4:11",
        "name_pl": "4:11",
        "name_ro": "4:11",
        "name_ru": "4:11",
        "name_tr": "4:11",
        "name_se": "4:11",
        "name_el": "4:11",
        "name_es": "4:11",
        "name_hr": "4:11",
        "name_fr": "4:11"
    },
    "2364^live_match^8^away_and_under_and_gg": {
        "name_en": "away and under and GG",
        "name_aa": "away and under and GG",
        "name_de": "Auswart und Unter und GG",
        "name_nl": "Weg en minder dan en GG",
        "name_pl": "away and under and GG",
        "name_ro": "away and under and GG",
        "name_ru": "away and under and GG",
        "name_tr": "away and under and GG",
        "name_se": "away and under and GG",
        "name_el": "away and under and GG",
        "name_es": "away and under and GG",
        "name_hr": "away and under and GG",
        "name_fr": "away and under and GG"
    },
    "2076^live_match^8^12:15": {
        "name_en": "12:15",
        "name_aa": "12:15",
        "name_de": "12:15",
        "name_nl": "12:15",
        "name_pl": "12:15",
        "name_ro": "12:15",
        "name_ru": "12:15",
        "name_tr": "12:15",
        "name_se": "12:15",
        "name_el": "12:15",
        "name_es": "12:15",
        "name_hr": "12:15",
        "name_fr": "12:15"
    },
    "26^live_match^8^7:9": {
        "name_en": "7:9",
        "name_aa": "7:9",
        "name_de": "7:9",
        "name_nl": "7:9",
        "name_pl": "7:9",
        "name_ro": "7:9",
        "name_ru": "7:9",
        "name_tr": "7:9",
        "name_se": "7:9",
        "name_el": "7:9",
        "name_es": "7:9",
        "name_hr": "7:9",
        "name_fr": "7:9"
    },
    "2149^live_match^8^away_1-5": {
        "name_en": "away 1-5",
        "name_aa": "away 1-5",
        "name_de": "Auswärt 1-5",
        "name_nl": "Weg 1-5",
        "name_pl": "away 1-5",
        "name_ro": "away 1-5",
        "name_ru": "away 1-5",
        "name_tr": "away 1-5",
        "name_se": "away 1-5",
        "name_el": "away 1-5",
        "name_es": "away 1-5",
        "name_hr": "Gostujuči 1-5",
        "name_fr": "away 1-5"
    },
    "2045^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "2203^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "153^live_match^8^over": {
        "name_en": "Over",
        "name_aa": "Over",
        "name_de": "Über",
        "name_nl": "Over",
        "name_pl": "Over",
        "name_ro": "Over",
        "name_ru": "Over",
        "name_tr": "Üst",
        "name_se": "Over",
        "name_el": "Over",
        "name_es": "Over",
        "name_hr": "Više",
        "name_fr": "p"
    },
    "1214^live_match^8^even": {
        "name_en": "even",
        "name_aa": "even",
        "name_de": "Gerade",
        "name_nl": "even",
        "name_pl": "even",
        "name_ro": "even",
        "name_ru": "even",
        "name_tr": "çift",
        "name_se": "even",
        "name_el": "even",
        "name_es": "even",
        "name_hr": "Paran",
        "name_fr": "pair"
    },
    "2352^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "1108^live_match^8^8:7": {
        "name_en": "8:7",
        "name_aa": "8:7",
        "name_de": "8:7",
        "name_nl": "8:7",
        "name_pl": "8:7",
        "name_ro": "8:7",
        "name_ru": "8:7",
        "name_tr": "8:7",
        "name_se": "8:7",
        "name_el": "8:7",
        "name_es": "8:7",
        "name_hr": "8:7",
        "name_fr": "8:7"
    },
    "2053^live_match^6^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "60^live_match^8^odd": {
        "name_en": "odd",
        "name_aa": "odd",
        "name_de": "Ungerade",
        "name_nl": "oneven",
        "name_pl": "odd",
        "name_ro": "odd",
        "name_ru": "odd",
        "name_tr": "tek",
        "name_se": "odd",
        "name_el": "odd",
        "name_es": "odd",
        "name_hr": "Neparan",
        "name_fr": "impair"
    },
    "32^live_match^8^3+": {
        "name_en": "3+",
        "name_aa": "3+",
        "name_de": "3+",
        "name_nl": "3+",
        "name_pl": "3+",
        "name_ro": "3+",
        "name_ru": "3+",
        "name_tr": "3+",
        "name_se": "3+",
        "name_el": "3+",
        "name_es": "3+",
        "name_hr": "3+",
        "name_fr": "3+"
    },
    "1366^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "1344^live_match^8^odd": {
        "name_en": "odd",
        "name_aa": "odd",
        "name_de": "Ungerade",
        "name_nl": "oneven",
        "name_pl": "odd",
        "name_ro": "odd",
        "name_ru": "odd",
        "name_tr": "tek",
        "name_se": "odd",
        "name_el": "odd",
        "name_es": "odd",
        "name_hr": "Neparan",
        "name_fr": "impair"
    },
    "1208^live_match^8^yellow": {
        "name_en": "yellow",
        "name_aa": "yellow",
        "name_de": "gelb",
        "name_nl": "geel",
        "name_pl": "yellow",
        "name_ro": "yellow",
        "name_ru": "yellow",
        "name_tr": "sarı",
        "name_se": "yellow",
        "name_el": "yellow",
        "name_es": "yellow",
        "name_hr": "žuta",
        "name_fr": "jaune"
    },
    "2081^live_match^8^6:0": {
        "name_en": "6:0",
        "name_aa": "6:0",
        "name_de": "6:0",
        "name_nl": "6:0",
        "name_pl": "6:0",
        "name_ro": "6:0",
        "name_ru": "6:0",
        "name_tr": "6:0",
        "name_se": "6:0",
        "name_el": "6:0",
        "name_es": "6:0",
        "name_hr": "6:0",
        "name_fr": "6:0"
    },
    "89^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2090^live_match^8^30:30": {
        "name_en": "30:30",
        "name_aa": "30:30",
        "name_de": "30:30",
        "name_nl": "30:30",
        "name_pl": "30:30",
        "name_ro": "30:30",
        "name_ru": "30:30",
        "name_tr": "30:30",
        "name_se": "30:30",
        "name_el": "30:30",
        "name_es": "30:30",
        "name_hr": "30:30",
        "name_fr": "30:30"
    },
    "1108^live_match^8^0:1": {
        "name_en": "0:1",
        "name_aa": "0:1",
        "name_de": "0:1",
        "name_nl": "0:1",
        "name_pl": "0:1",
        "name_ro": "0:1",
        "name_ru": "0:1",
        "name_tr": "0:1",
        "name_se": "0:1",
        "name_el": "0:1",
        "name_es": "0:1",
        "name_hr": "0:1",
        "name_fr": "0:1"
    },
    "1224^live_match^8^yes": {
        "name_en": "Yes",
        "name_aa": "Yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "Yes",
        "name_ro": "Yes",
        "name_ru": "Yes",
        "name_tr": "evet",
        "name_se": "Yes",
        "name_el": "Yes",
        "name_es": "Yes",
        "name_hr": "Da",
        "name_fr": "oui"
    },
    "2082^live_match^8^6:3_or_6:4": {
        "name_en": "6:3 or 6:4",
        "name_aa": "6:3 or 6:4",
        "name_de": "6:3 oder 6:4",
        "name_nl": "6:3 of 6:4",
        "name_pl": "6:3 or 6:4",
        "name_ro": "6:3 or 6:4",
        "name_ru": "6:3 or 6:4",
        "name_tr": "6:3 or 6:4",
        "name_se": "6:3 or 6:4",
        "name_el": "6:3 or 6:4",
        "name_es": "6:3 or 6:4",
        "name_hr": "6:3 or 6:4",
        "name_fr": "6:3 or 6:4"
    },
    "2312^live_match^8^1_&_yes": {
        "name_en": "1 & Yes",
        "name_aa": "1 & Yes",
        "name_de": "1 & Ja",
        "name_nl": "1 & Ja",
        "name_pl": "1 & Yes",
        "name_ro": "1 & Yes",
        "name_ru": "1 & Yes",
        "name_tr": "1 & Yes",
        "name_se": "1 & Yes",
        "name_el": "1 & Yes",
        "name_es": "1 & Yes",
        "name_hr": "1 & da",
        "name_fr": "1 & Yes"
    },
    "2123^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "520^live_match^8^6:0": {
        "name_en": "6:0",
        "name_aa": "6:0",
        "name_de": "6:0",
        "name_nl": "6:0",
        "name_pl": "6:0",
        "name_ro": "6:0",
        "name_ru": "6:0",
        "name_tr": "6:0",
        "name_se": "6:0",
        "name_el": "6:0",
        "name_es": "6:0",
        "name_hr": "6:0",
        "name_fr": "6:0"
    },
    "2074^live_match^8^5:15_or_better": {
        "name_en": "5:15 or better",
        "name_aa": "5:15 or better",
        "name_de": "5:15 oder besser",
        "name_nl": "5:15 of beter",
        "name_pl": "5:15 or better",
        "name_ro": "5:15 or better",
        "name_ru": "5:15 or better",
        "name_tr": "5:15 or better",
        "name_se": "5:15 or better",
        "name_el": "5:15 or better",
        "name_es": "5:15 or better",
        "name_hr": "5:15 or better",
        "name_fr": "5:15 or better"
    },
    "26^live_match^8^17:2": {
        "name_en": "17:2",
        "name_aa": "17:2",
        "name_de": "17:2",
        "name_nl": "17:2",
        "name_pl": "17:2",
        "name_ro": "17:2",
        "name_ru": "17:2",
        "name_tr": "17:2",
        "name_se": "17:2",
        "name_el": "17:2",
        "name_es": "17:2",
        "name_hr": "17:2",
        "name_fr": "17:2"
    },
    "1086^live_match^8^3rd_period": {
        "name_en": "3rd period",
        "name_aa": "3rd period",
        "name_de": "3. Periode",
        "name_nl": "3de periode",
        "name_pl": "3rd period",
        "name_ro": "3rd period",
        "name_ru": "3rd period",
        "name_tr": "3. periyot",
        "name_se": "3rd period",
        "name_el": "3rd period",
        "name_es": "3rd period",
        "name_hr": "3. period",
        "name_fr": "3ème période"
    },
    "341^live_match^8^4:2": {
        "name_en": "4:2",
        "name_aa": "4:2",
        "name_de": "4:2",
        "name_nl": "4:2",
        "name_pl": "4:2",
        "name_ro": "4:2",
        "name_ru": "4:2",
        "name_tr": "4:2",
        "name_se": "4:2",
        "name_el": "4:2",
        "name_es": "4:2",
        "name_hr": "4:2",
        "name_fr": "4:2"
    },
    "26^live_match^8^1:6": {
        "name_en": "1:6",
        "name_aa": "1:6",
        "name_de": "1:6",
        "name_nl": "1:6",
        "name_pl": "1:6",
        "name_ro": "1:6",
        "name_ru": "1:6",
        "name_tr": "1:6",
        "name_se": "1:6",
        "name_el": "1:6",
        "name_es": "1:6",
        "name_hr": "1:6",
        "name_fr": "1:6"
    },
    "2072^live_match^8^25:16": {
        "name_en": "25:16",
        "name_aa": "25:16",
        "name_de": "25:16",
        "name_nl": "25:16",
        "name_pl": "25:16",
        "name_ro": "25:16",
        "name_ru": "25:16",
        "name_tr": "25:16",
        "name_se": "25:16",
        "name_el": "25:16",
        "name_es": "25:16",
        "name_hr": "25:16",
        "name_fr": "25:16"
    },
    "2214^live_match^8^draw": {
        "name_en": "draw",
        "name_aa": "draw",
        "name_de": "Unentschieden",
        "name_nl": "Gelijkspel",
        "name_pl": "draw",
        "name_ro": "draw",
        "name_ru": "draw",
        "name_tr": "draw",
        "name_se": "draw",
        "name_el": "draw",
        "name_es": "draw",
        "name_hr": "Neriješeno",
        "name_fr": "draw"
    },
    "1326^live_match^8^away": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2067^live_match^6^away_win_by_3+": {
        "name_en": "away win by 3+",
        "name_aa": "away win by 3+",
        "name_de": "Auswart gewinnt durch 3+",
        "name_nl": "uitoverwinning met 3+",
        "name_pl": "away win by 3+",
        "name_ro": "away win by 3+",
        "name_ru": "away win by 3+",
        "name_tr": "away win by 3+",
        "name_se": "away win by 3+",
        "name_el": "away win by 3+",
        "name_es": "away win by 3+",
        "name_hr": "Gostujuči pobijediti po 3+",
        "name_fr": "away win by 3+"
    },
    "2088^live_match^8^1:5": {
        "name_en": "1:5",
        "name_aa": "1:5",
        "name_de": "1:5",
        "name_nl": "1:5",
        "name_pl": "1:5",
        "name_ro": "1:5",
        "name_ru": "1:5",
        "name_tr": "1:5",
        "name_se": "1:5",
        "name_el": "1:5",
        "name_es": "1:5",
        "name_hr": "1:5",
        "name_fr": "1:5"
    },
    "2035^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "2032^live_match^8^away_and_over": {
        "name_en": "away and over",
        "name_aa": "away and over",
        "name_de": "Auswärt und Über",
        "name_nl": "Uit and Meer dan",
        "name_pl": "away and over",
        "name_ro": "away and over",
        "name_ru": "away and over",
        "name_tr": "away and over",
        "name_se": "away and over",
        "name_el": "away and over",
        "name_es": "away and over",
        "name_hr": "Gostujući i više",
        "name_fr": "away and over"
    },
    "29^live_match^8^12:1": {
        "name_en": "12:1",
        "name_aa": "12:1",
        "name_de": "12:1",
        "name_nl": "12:1",
        "name_pl": "12:1",
        "name_ro": "12:1",
        "name_ru": "12:1",
        "name_tr": "12:1",
        "name_se": "12:1",
        "name_el": "12:1",
        "name_es": "12:1",
        "name_hr": "12:1",
        "name_fr": "12:1"
    },
    "2354^live_match^8^14:2": {
        "name_en": "14:2",
        "name_aa": "14:2",
        "name_de": "14:2",
        "name_nl": "14:2",
        "name_pl": "14:2",
        "name_ro": "14:2",
        "name_ru": "14:2",
        "name_tr": "14:2",
        "name_se": "14:2",
        "name_el": "14:2",
        "name_es": "14:2",
        "name_hr": "14:2",
        "name_fr": "14:2"
    },
    "2060^live_match^8^away_3-4": {
        "name_en": "away 3-4",
        "name_aa": "away 3-4",
        "name_de": "Auswärt 3-4",
        "name_nl": "Weg 3-4",
        "name_pl": "away 3-4",
        "name_ro": "away 3-4",
        "name_ru": "away 3-4",
        "name_tr": "away 3-4",
        "name_se": "away 3-4",
        "name_el": "away 3-4",
        "name_es": "away 3-4",
        "name_hr": "Gostujuči 3-4",
        "name_fr": "away 3-4"
    },
    "2165^live_match^8^yes": {
        "name_en": "yes",
        "name_aa": "yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "yes",
        "name_ro": "yes",
        "name_ru": "yes",
        "name_tr": "yes",
        "name_se": "yes",
        "name_el": "yes",
        "name_es": "yes",
        "name_hr": "Da",
        "name_fr": "yes"
    },
    "29^live_match^8^0:14": {
        "name_en": "0:14",
        "name_aa": "0:14",
        "name_de": "0:14",
        "name_nl": "0:14",
        "name_pl": "0:14",
        "name_ro": "0:14",
        "name_ru": "0:14",
        "name_tr": "0:14",
        "name_se": "0:14",
        "name_el": "0:14",
        "name_es": "0:14",
        "name_hr": "0:14",
        "name_fr": "0:14"
    },
    "26^live_match^8^7:3": {
        "name_en": "7:3",
        "name_aa": "7:3",
        "name_de": "7:3",
        "name_nl": "7:3",
        "name_pl": "7:3",
        "name_ro": "7:3",
        "name_ru": "7:3",
        "name_tr": "7:3",
        "name_se": "7:3",
        "name_el": "7:3",
        "name_es": "7:3",
        "name_hr": "7:3",
        "name_fr": "7:3"
    },
    "2316^live_match^8^5:10": {
        "name_en": "5:10",
        "name_aa": "5:10",
        "name_de": "5:10",
        "name_nl": "5:10",
        "name_pl": "5:10",
        "name_ro": "5:10",
        "name_ru": "5:10",
        "name_tr": "5:10",
        "name_se": "5:10",
        "name_el": "5:10",
        "name_es": "5:10",
        "name_hr": "5:10",
        "name_fr": "5:10"
    },
    "2110^live_match^8^overtime": {
        "name_en": "Overtime",
        "name_aa": "Overtime",
        "name_de": "Verlängerung",
        "name_nl": "Overtime",
        "name_pl": "Overtime",
        "name_ro": "Overtime",
        "name_ru": "Overtime",
        "name_tr": "Overtime",
        "name_se": "Overtime",
        "name_el": "Overtime",
        "name_es": "Overtime",
        "name_hr": "Overtime",
        "name_fr": "Overtime"
    },
    "2354^live_match^8^2:12": {
        "name_en": "2:12",
        "name_aa": "2:12",
        "name_de": "2:12",
        "name_nl": "2:12",
        "name_pl": "2:12",
        "name_ro": "2:12",
        "name_ru": "2:12",
        "name_tr": "2:12",
        "name_se": "2:12",
        "name_el": "2:12",
        "name_es": "2:12",
        "name_hr": "2:12",
        "name_fr": "2:12"
    },
    "2208^live_match^8^25-26": {
        "name_en": "25-26",
        "name_aa": "25-26",
        "name_de": "25-26",
        "name_nl": "25-26",
        "name_pl": "25-26",
        "name_ro": "25-26",
        "name_ru": "25-26",
        "name_tr": "25-26",
        "name_se": "25-26",
        "name_el": "25-26",
        "name_es": "25-26",
        "name_hr": "25-26",
        "name_fr": "25-26"
    },
    "2325^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "2354^live_match^8^4:6": {
        "name_en": "4:6",
        "name_aa": "4:6",
        "name_de": "4:6",
        "name_nl": "4:6",
        "name_pl": "4:6",
        "name_ro": "4:6",
        "name_ru": "4:6",
        "name_tr": "4:6",
        "name_se": "4:6",
        "name_el": "4:6",
        "name_es": "4:6",
        "name_hr": "4:6",
        "name_fr": "4:6"
    },
    "87^live_match^8^over": {
        "name_en": "Over",
        "name_aa": "Over",
        "name_de": "Über",
        "name_nl": "Over",
        "name_pl": "Over",
        "name_ro": "Over",
        "name_ru": "Over",
        "name_tr": "Üst",
        "name_se": "Over",
        "name_el": "Over",
        "name_es": "Over",
        "name_hr": "Više",
        "name_fr": "p"
    },
    "2206^live_match^8^ah": {
        "name_en": "AH",
        "name_aa": "AH",
        "name_de": "AH",
        "name_nl": "AH",
        "name_pl": "AH",
        "name_ro": "AH",
        "name_ru": "AH",
        "name_tr": "AH",
        "name_se": "AH",
        "name_el": "AH",
        "name_es": "AH",
        "name_hr": "AH",
        "name_fr": "AH"
    },
    "2264^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "520^live_match^8^4:6": {
        "name_en": "4:6",
        "name_aa": "4:6",
        "name_de": "4:6",
        "name_nl": "4:6",
        "name_pl": "4:6",
        "name_ro": "4:6",
        "name_ru": "4:6",
        "name_tr": "4:6",
        "name_se": "4:6",
        "name_el": "4:6",
        "name_es": "4:6",
        "name_hr": "4:6",
        "name_fr": "4:6"
    },
    "520^live_match^8^6:3": {
        "name_en": "6:3",
        "name_aa": "6:3",
        "name_de": "6:3",
        "name_nl": "6:3",
        "name_pl": "6:3",
        "name_ro": "6:3",
        "name_ru": "6:3",
        "name_tr": "6:3",
        "name_se": "6:3",
        "name_el": "6:3",
        "name_es": "6:3",
        "name_hr": "6:3",
        "name_fr": "6:3"
    },
    "2244^live_match^8^home": {
        "name_en": "home",
        "name_aa": "home",
        "name_de": "Heim",
        "name_nl": "Thuis",
        "name_pl": "home",
        "name_ro": "home",
        "name_ru": "home",
        "name_tr": "home",
        "name_se": "home",
        "name_el": "home",
        "name_es": "home",
        "name_hr": "Domači",
        "name_fr": "home"
    },
    "2072^live_match^8^25:18": {
        "name_en": "25:18",
        "name_aa": "25:18",
        "name_de": "25:18",
        "name_nl": "25:18",
        "name_pl": "25:18",
        "name_ro": "25:18",
        "name_ru": "25:18",
        "name_tr": "25:18",
        "name_se": "25:18",
        "name_el": "25:18",
        "name_es": "25:18",
        "name_hr": "25:18",
        "name_fr": "25:18"
    },
    "29^live_match^8^5:6": {
        "name_en": "5:6",
        "name_aa": "5:6",
        "name_de": "5:6",
        "name_nl": "5:6",
        "name_pl": "5:6",
        "name_ro": "5:6",
        "name_ru": "5:6",
        "name_tr": "5:6",
        "name_se": "5:6",
        "name_el": "5:6",
        "name_es": "5:6",
        "name_hr": "5:6",
        "name_fr": "5:6"
    },
    "1527^live_match^8^no": {
        "name_en": "No",
        "name_aa": "No",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "No",
        "name_ro": "No",
        "name_ru": "No",
        "name_tr": "hayır",
        "name_se": "No",
        "name_el": "No",
        "name_es": "No",
        "name_hr": "Ne",
        "name_fr": "non"
    },
    "2111^live_match^8^draw": {
        "name_en": "draw",
        "name_aa": "draw",
        "name_de": "Unentschieden",
        "name_nl": "Gelijkspel",
        "name_pl": "draw",
        "name_ro": "draw",
        "name_ru": "draw",
        "name_tr": "draw",
        "name_se": "draw",
        "name_el": "draw",
        "name_es": "draw",
        "name_hr": "Neriješeno",
        "name_fr": "draw"
    },
    "78^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "29^live_match^8^1:0": {
        "name_en": "1:0",
        "name_aa": "1:0",
        "name_de": "1:0",
        "name_nl": "1:0",
        "name_pl": "1:0",
        "name_ro": "1:0",
        "name_ru": "1:0",
        "name_tr": "1:0",
        "name_se": "1:0",
        "name_el": "1:0",
        "name_es": "1:0",
        "name_hr": "1:0",
        "name_fr": "1:0"
    },
    "2311^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2208^live_match^8^35+": {
        "name_en": "35+",
        "name_aa": "35+",
        "name_de": "35+",
        "name_nl": "35+",
        "name_pl": "35+",
        "name_ro": "35+",
        "name_ru": "35+",
        "name_tr": "35+",
        "name_se": "35+",
        "name_el": "35+",
        "name_es": "35+",
        "name_hr": "35+",
        "name_fr": "35+"
    },
    "2187^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "26^live_match^8^7:7": {
        "name_en": "7:7",
        "name_aa": "7:7",
        "name_de": "7:7",
        "name_nl": "7:7",
        "name_pl": "7:7",
        "name_ro": "7:7",
        "name_ru": "7:7",
        "name_tr": "7:7",
        "name_se": "7:7",
        "name_el": "7:7",
        "name_es": "7:7",
        "name_hr": "7:7",
        "name_fr": "7:7"
    },
    "2354^live_match^8^0:13": {
        "name_en": "0:13",
        "name_aa": "0:13",
        "name_de": "0:13",
        "name_nl": "0:13",
        "name_pl": "0:13",
        "name_ro": "0:13",
        "name_ru": "0:13",
        "name_tr": "0:13",
        "name_se": "0:13",
        "name_el": "0:13",
        "name_es": "0:13",
        "name_hr": "0:13",
        "name_fr": "0:13"
    },
    "2323^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "2216^live_match^8^away_1-39": {
        "name_en": "away 1-39",
        "name_aa": "away 1-39",
        "name_de": "Auswärt 1-39",
        "name_nl": "Weg 1-39",
        "name_pl": "away 1-39",
        "name_ro": "away 1-39",
        "name_ru": "away 1-39",
        "name_tr": "away 1-39",
        "name_se": "away 1-39",
        "name_el": "away 1-39",
        "name_es": "away 1-39",
        "name_hr": "Gostujuči 1-39",
        "name_fr": "away 1-39"
    },
    "2354^live_match^8^3:15": {
        "name_en": "3:15",
        "name_aa": "3:15",
        "name_de": "3:15",
        "name_nl": "3:15",
        "name_pl": "3:15",
        "name_ro": "3:15",
        "name_ru": "3:15",
        "name_tr": "3:15",
        "name_se": "3:15",
        "name_el": "3:15",
        "name_es": "3:15",
        "name_hr": "3:15",
        "name_fr": "3:15"
    },
    "2303^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2143^live_match^8^away_1-2": {
        "name_en": "away 1-2",
        "name_aa": "away 1-2",
        "name_de": "Auswärt 1-2",
        "name_nl": "Weg 1-2",
        "name_pl": "away 1-2",
        "name_ro": "away 1-2",
        "name_ru": "away 1-2",
        "name_tr": "away 1-2",
        "name_se": "away 1-2",
        "name_el": "away 1-2",
        "name_es": "away 1-2",
        "name_hr": "Gostujuči 1-2",
        "name_fr": "away 1-2"
    },
    "2168^live_match^8^2/2": {
        "name_en": "2/2",
        "name_aa": "2/2",
        "name_de": "2/2",
        "name_nl": "2/2",
        "name_pl": "2/2",
        "name_ro": "2/2",
        "name_ru": "2/2",
        "name_tr": "2/2",
        "name_se": "2/2",
        "name_el": "2/2",
        "name_es": "2/2",
        "name_hr": "2/2",
        "name_fr": "2/2"
    },
    "1108^live_match^8^0:9": {
        "name_en": "0:9",
        "name_aa": "0:9",
        "name_de": "0:9",
        "name_nl": "0:9",
        "name_pl": "0:9",
        "name_ro": "0:9",
        "name_ru": "0:9",
        "name_tr": "0:9",
        "name_se": "0:9",
        "name_el": "0:9",
        "name_es": "0:9",
        "name_hr": "0:9",
        "name_fr": "0:9"
    },
    "1156^live_match^8^2:0": {
        "name_en": "2:0",
        "name_aa": "2:0",
        "name_de": "2:0",
        "name_nl": "2:0",
        "name_pl": "2:0",
        "name_ro": "2:0",
        "name_ru": "2:0",
        "name_tr": "2:0",
        "name_se": "2:0",
        "name_el": "2:0",
        "name_es": "2:0",
        "name_hr": "2:0",
        "name_fr": "2:0"
    },
    "2157^live_match^8^home_after_extra_points": {
        "name_en": "home after extra points",
        "name_aa": "home after extra points",
        "name_de": "Heimmannschaft Nach Verlängerung",
        "name_nl": "Thuisteam Na extra punten",
        "name_pl": "home after extra points",
        "name_ro": "home after extra points",
        "name_ru": "home after extra points",
        "name_tr": "home after extra points",
        "name_se": "home after extra points",
        "name_el": "home after extra points",
        "name_es": "home after extra points",
        "name_hr": "home after extra points",
        "name_fr": "home after extra points"
    },
    "2213^live_match^8^home_37+": {
        "name_en": "home 37+",
        "name_aa": "home 37+",
        "name_de": "Heim 37+",
        "name_nl": "Thuis 37+",
        "name_pl": "home 37+",
        "name_ro": "home 37+",
        "name_ru": "home 37+",
        "name_tr": "home 37+",
        "name_se": "home 37+",
        "name_el": "home 37+",
        "name_es": "home 37+",
        "name_hr": "Domači 37+",
        "name_fr": "home 37+"
    },
    "2083^live_match^8^away_and_under": {
        "name_en": "away and under",
        "name_aa": "away and under",
        "name_de": "Auswärt und Unter",
        "name_nl": "Uit and Minder dan",
        "name_pl": "away and under",
        "name_ro": "away and under",
        "name_ru": "away and under",
        "name_tr": "away and under",
        "name_se": "away and under",
        "name_el": "away and under",
        "name_es": "away and under",
        "name_hr": "Gostujući i manje",
        "name_fr": "away and under"
    },
    "26^live_match^8^8:0": {
        "name_en": "8:0",
        "name_aa": "8:0",
        "name_de": "8:0",
        "name_nl": "8:0",
        "name_pl": "8:0",
        "name_ro": "8:0",
        "name_ru": "8:0",
        "name_tr": "8:0",
        "name_se": "8:0",
        "name_el": "8:0",
        "name_es": "8:0",
        "name_hr": "8:0",
        "name_fr": "8:0"
    },
    "2364^live_match^8^draw_and_over_and_ng": {
        "name_en": "draw and over and NG",
        "name_aa": "draw and over and NG",
        "name_de": "Unentschieden und Über und NG",
        "name_nl": "Gelijkspel en meer dan en NG",
        "name_pl": "draw and over and NG",
        "name_ro": "draw and over and NG",
        "name_ru": "draw and over and NG",
        "name_tr": "draw and over and NG",
        "name_se": "draw and over and NG",
        "name_el": "draw and over and NG",
        "name_es": "draw and over and NG",
        "name_hr": "draw and over and NG",
        "name_fr": "draw and over and NG"
    },
    "2261^live_match^8^8:3": {
        "name_en": "8:3",
        "name_aa": "8:3",
        "name_de": "8:3",
        "name_nl": "8:3",
        "name_pl": "8:3",
        "name_ro": "8:3",
        "name_ru": "8:3",
        "name_tr": "8:3",
        "name_se": "8:3",
        "name_el": "8:3",
        "name_es": "8:3",
        "name_hr": "8:3",
        "name_fr": "8:3"
    },
    "1529^live_match^8^home_and_over": {
        "name_en": "home and over",
        "name_aa": "home and over",
        "name_de": "Heim und Über",
        "name_nl": "thuis en boven",
        "name_pl": "home and over",
        "name_ro": "home and over",
        "name_ru": "home and over",
        "name_tr": "ev sahibi ve üst",
        "name_se": "home and over",
        "name_el": "home and over",
        "name_es": "home and over",
        "name_hr": "1 i Više",
        "name_fr": "domicile et Plus de"
    },
    "2076^live_match^8^15:7": {
        "name_en": "15:7",
        "name_aa": "15:7",
        "name_de": "15:7",
        "name_nl": "15:7",
        "name_pl": "15:7",
        "name_ro": "15:7",
        "name_ru": "15:7",
        "name_tr": "15:7",
        "name_se": "15:7",
        "name_el": "15:7",
        "name_es": "15:7",
        "name_hr": "15:7",
        "name_fr": "15:7"
    },
    "2000^live_match^8^no": {
        "name_en": "no",
        "name_aa": "no",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "no",
        "name_ro": "no",
        "name_ru": "no",
        "name_tr": "no",
        "name_se": "no",
        "name_el": "no",
        "name_es": "no",
        "name_hr": "Ne",
        "name_fr": "no"
    },
    "2088^live_match^8^2:4": {
        "name_en": "2:4",
        "name_aa": "2:4",
        "name_de": "2:4",
        "name_nl": "2:4",
        "name_pl": "2:4",
        "name_ro": "2:4",
        "name_ru": "2:4",
        "name_tr": "2:4",
        "name_se": "2:4",
        "name_el": "2:4",
        "name_es": "2:4",
        "name_hr": "2:4",
        "name_fr": "2:4"
    },
    "2099^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "2150^live_match^8^home": {
        "name_en": "home",
        "name_aa": "home",
        "name_de": "Heim",
        "name_nl": "Thuis",
        "name_pl": "home",
        "name_ro": "home",
        "name_ru": "home",
        "name_tr": "home",
        "name_se": "home",
        "name_el": "home",
        "name_es": "home",
        "name_hr": "Domači",
        "name_fr": "home"
    },
    "872^live_match^8^over": {
        "name_en": "Over",
        "name_aa": "Over",
        "name_de": "Über",
        "name_nl": "Over",
        "name_pl": "Over",
        "name_ro": "Over",
        "name_ru": "Over",
        "name_tr": "Üst",
        "name_se": "Over",
        "name_el": "Over",
        "name_es": "Over",
        "name_hr": "Više",
        "name_fr": "p"
    },
    "1040^live_match^8^home_and_no": {
        "name_en": "home and no",
        "name_aa": "home and no",
        "name_de": "Heimmannschaft und Nein",
        "name_nl": "thuis en nee",
        "name_pl": "home and no",
        "name_ro": "home and no",
        "name_ru": "home and no",
        "name_tr": "ev sahibi kazanır ve iki takımda gol atamaz",
        "name_se": "home and no",
        "name_el": "home and no",
        "name_es": "home and no",
        "name_hr": "domaći i ne",
        "name_fr": "Domicile et Non"
    },
    "2076^live_match^8^15:10": {
        "name_en": "15:10",
        "name_aa": "15:10",
        "name_de": "15:10",
        "name_nl": "15:10",
        "name_pl": "15:10",
        "name_ro": "15:10",
        "name_ru": "15:10",
        "name_tr": "15:10",
        "name_se": "15:10",
        "name_el": "15:10",
        "name_es": "15:10",
        "name_hr": "15:10",
        "name_fr": "15:10"
    },
    "1108^live_match^8^6:1": {
        "name_en": "6:1",
        "name_aa": "6:1",
        "name_de": "6:1",
        "name_nl": "6:1",
        "name_pl": "6:1",
        "name_ro": "6:1",
        "name_ru": "6:1",
        "name_tr": "6:1",
        "name_se": "6:1",
        "name_el": "6:1",
        "name_es": "6:1",
        "name_hr": "6:1",
        "name_fr": "6:1"
    },
    "2142^live_match^8^home_7+": {
        "name_en": "home 7+",
        "name_aa": "home 7+",
        "name_de": "Heim 7+",
        "name_nl": "Thuis 7+",
        "name_pl": "home 7+",
        "name_ro": "home 7+",
        "name_ru": "home 7+",
        "name_tr": "home 7+",
        "name_se": "home 7+",
        "name_el": "home 7+",
        "name_es": "home 7+",
        "name_hr": "Domači 7+",
        "name_fr": "home 7+"
    },
    "2233^live_match^6^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "918^live_match^8^2/2": {
        "name_en": "2/2",
        "name_aa": "2/2",
        "name_de": "2/2",
        "name_nl": "2/2",
        "name_pl": "2/2",
        "name_ro": "2/2",
        "name_ru": "2/2",
        "name_tr": "2/2",
        "name_se": "2/2",
        "name_el": "2/2",
        "name_es": "2/2",
        "name_hr": "2/2",
        "name_fr": "2/2"
    },
    "2088^live_match^8^3:3": {
        "name_en": "3:3",
        "name_aa": "3:3",
        "name_de": "3:3",
        "name_nl": "3:3",
        "name_pl": "3:3",
        "name_ro": "3:3",
        "name_ru": "3:3",
        "name_tr": "3:3",
        "name_se": "3:3",
        "name_el": "3:3",
        "name_es": "3:3",
        "name_hr": "3:3",
        "name_fr": "3:3"
    },
    "1370^live_match^8^over": {
        "name_en": "Over",
        "name_aa": "Over",
        "name_de": "Über",
        "name_nl": "Over",
        "name_pl": "Over",
        "name_ro": "Over",
        "name_ru": "Over",
        "name_tr": "Üst",
        "name_se": "Over",
        "name_el": "Over",
        "name_es": "Over",
        "name_hr": "Više",
        "name_fr": "p"
    },
    "2075^live_match^8^25:19": {
        "name_en": "25:19",
        "name_aa": "25:19",
        "name_de": "25:19",
        "name_nl": "25:19",
        "name_pl": "25:19",
        "name_ro": "25:19",
        "name_ru": "25:19",
        "name_tr": "25:19",
        "name_se": "25:19",
        "name_el": "25:19",
        "name_es": "25:19",
        "name_hr": "25:19",
        "name_fr": "25:19"
    },
    "870^live_match^6^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2263^live_match^8^no": {
        "name_en": "no",
        "name_aa": "no",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "no",
        "name_ro": "no",
        "name_ru": "no",
        "name_tr": "no",
        "name_se": "no",
        "name_el": "no",
        "name_es": "no",
        "name_hr": "Ne",
        "name_fr": "no"
    },
    "1658^live_match^2^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2157^live_match^8^away_after_extra_points": {
        "name_en": "away after extra points",
        "name_aa": "away after extra points",
        "name_de": "Auswärtsmannschaft Nach Verlängerung",
        "name_nl": "Uitteam Na extra punten",
        "name_pl": "away after extra points",
        "name_ro": "away after extra points",
        "name_ru": "away after extra points",
        "name_tr": "away after extra points",
        "name_se": "away after extra points",
        "name_el": "away after extra points",
        "name_es": "away after extra points",
        "name_hr": "away after extra points",
        "name_fr": "away after extra points"
    },
    "54^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "26^live_match^8^14:3": {
        "name_en": "14:3",
        "name_aa": "14:3",
        "name_de": "14:3",
        "name_nl": "14:3",
        "name_pl": "14:3",
        "name_ro": "14:3",
        "name_ru": "14:3",
        "name_tr": "14:3",
        "name_se": "14:3",
        "name_el": "14:3",
        "name_es": "14:3",
        "name_hr": "14:3",
        "name_fr": "14:3"
    },
    "26^live_match^8^2:3": {
        "name_en": "2:3",
        "name_aa": "2:3",
        "name_de": "2:3",
        "name_nl": "2:3",
        "name_pl": "2:3",
        "name_ro": "2:3",
        "name_ru": "2:3",
        "name_tr": "2:3",
        "name_se": "2:3",
        "name_el": "2:3",
        "name_es": "2:3",
        "name_hr": "2:3",
        "name_fr": "2:3"
    },
    "2316^live_match^8^2:10": {
        "name_en": "2:10",
        "name_aa": "2:10",
        "name_de": "2:10",
        "name_nl": "2:10",
        "name_pl": "2:10",
        "name_ro": "2:10",
        "name_ru": "2:10",
        "name_tr": "2:10",
        "name_se": "2:10",
        "name_el": "2:10",
        "name_es": "2:10",
        "name_hr": "2:10",
        "name_fr": "2:10"
    },
    "2145^live_match^8^away_7-9": {
        "name_en": "away 7-9",
        "name_aa": "away 7-9",
        "name_de": "Auswärt 7-9",
        "name_nl": "Weg 7-9",
        "name_pl": "away 7-9",
        "name_ro": "away 7-9",
        "name_ru": "away 7-9",
        "name_tr": "away 7-9",
        "name_se": "away 7-9",
        "name_el": "away 7-9",
        "name_es": "away 7-9",
        "name_hr": "Gostujuči 7-9",
        "name_fr": "away 7-9"
    },
    "29^live_match^8^4:7": {
        "name_en": "4:7",
        "name_aa": "4:7",
        "name_de": "4:7",
        "name_nl": "4:7",
        "name_pl": "4:7",
        "name_ro": "4:7",
        "name_ru": "4:7",
        "name_tr": "4:7",
        "name_se": "4:7",
        "name_el": "4:7",
        "name_es": "4:7",
        "name_hr": "4:7",
        "name_fr": "4:7"
    },
    "2157^live_match^8^13:21_or_better": {
        "name_en": "13:21 or better",
        "name_aa": "13:21 or better",
        "name_de": "13:21 oder besser",
        "name_nl": "13:21 of beter",
        "name_pl": "13:21 or better",
        "name_ro": "13:21 or better",
        "name_ru": "13:21 or better",
        "name_tr": "13:21 or better",
        "name_se": "13:21 or better",
        "name_el": "13:21 or better",
        "name_es": "13:21 or better",
        "name_hr": "13:21 or better",
        "name_fr": "13:21 or better"
    },
    "2041^live_match^8^yes": {
        "name_en": "yes",
        "name_aa": "yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "yes",
        "name_ro": "yes",
        "name_ru": "yes",
        "name_tr": "yes",
        "name_se": "yes",
        "name_el": "yes",
        "name_es": "yes",
        "name_hr": "Da",
        "name_fr": "yes"
    },
    "918^live_match^8^2/1": {
        "name_en": "2/1",
        "name_aa": "2/1",
        "name_de": "2/1",
        "name_nl": "2/1",
        "name_pl": "2/1",
        "name_ro": "2/1",
        "name_ru": "2/1",
        "name_tr": "2/1",
        "name_se": "2/1",
        "name_el": "2/1",
        "name_es": "2/1",
        "name_hr": "2/1",
        "name_fr": "2/1"
    },
    "6^live_match^6^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "29^live_match^8^7:2": {
        "name_en": "7:2",
        "name_aa": "7:2",
        "name_de": "7:2",
        "name_nl": "7:2",
        "name_pl": "7:2",
        "name_ro": "7:2",
        "name_ru": "7:2",
        "name_tr": "7:2",
        "name_se": "7:2",
        "name_el": "7:2",
        "name_es": "7:2",
        "name_hr": "7:2",
        "name_fr": "7:2"
    },
    "2070^live_match^8^none": {
        "name_en": "none",
        "name_aa": "none",
        "name_de": "Keiner",
        "name_nl": "Geen",
        "name_pl": "none",
        "name_ro": "none",
        "name_ru": "none",
        "name_tr": "none",
        "name_se": "none",
        "name_el": "none",
        "name_es": "none",
        "name_hr": "Nitko",
        "name_fr": "none"
    },
    "2161^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "2148^live_match^8^away_3+": {
        "name_en": "away 3+",
        "name_aa": "away 3+",
        "name_de": "Auswärt 3+",
        "name_nl": "Weg 3+",
        "name_pl": "away 3+",
        "name_ro": "away 3+",
        "name_ru": "away 3+",
        "name_tr": "away 3+",
        "name_se": "away 3+",
        "name_el": "away 3+",
        "name_es": "away 3+",
        "name_hr": "Gostujuči 3+",
        "name_fr": "away 3+"
    },
    "2354^live_match^8^6:6": {
        "name_en": "6:6",
        "name_aa": "6:6",
        "name_de": "6:6",
        "name_nl": "6:6",
        "name_pl": "6:6",
        "name_ro": "6:6",
        "name_ru": "6:6",
        "name_tr": "6:6",
        "name_se": "6:6",
        "name_el": "6:6",
        "name_es": "6:6",
        "name_hr": "6:6",
        "name_fr": "6:6"
    },
    "26^live_match^8^2:0": {
        "name_en": "2:0",
        "name_aa": "2:0",
        "name_de": "2:0",
        "name_nl": "2:0",
        "name_pl": "2:0",
        "name_ro": "2:0",
        "name_ru": "2:0",
        "name_tr": "2:0",
        "name_se": "2:0",
        "name_el": "2:0",
        "name_es": "2:0",
        "name_hr": "2:0",
        "name_fr": "2:0"
    },
    "2260^live_match^8^tie": {
        "name_en": "Tie",
        "name_aa": "Tie",
        "name_de": "Unentschieden",
        "name_nl": "Gelijkspel",
        "name_pl": "Tie",
        "name_ro": "Tie",
        "name_ru": "Tie",
        "name_tr": "Tie",
        "name_se": "Tie",
        "name_el": "Tie",
        "name_es": "Tie",
        "name_hr": "Tie",
        "name_fr": "Tie"
    },
    "2364^live_match^8^home_and_under_and_gg": {
        "name_en": "home and under and GG",
        "name_aa": "home and under and GG",
        "name_de": "Heim und Unter und GG",
        "name_nl": "Thuis en minder dan en GG",
        "name_pl": "home and under and GG",
        "name_ro": "home and under and GG",
        "name_ru": "home and under and GG",
        "name_tr": "home and under and GG",
        "name_se": "home and under and GG",
        "name_el": "home and under and GG",
        "name_es": "home and under and GG",
        "name_hr": "home and under and GG",
        "name_fr": "home and under and GG"
    },
    "2207^live_match^8^away_73+": {
        "name_en": "away 73+",
        "name_aa": "away 73+",
        "name_de": "Auswärt 73+",
        "name_nl": "Weg 73+",
        "name_pl": "away 73+",
        "name_ro": "away 73+",
        "name_ru": "away 73+",
        "name_tr": "away 73+",
        "name_se": "away 73+",
        "name_el": "away 73+",
        "name_es": "away 73+",
        "name_hr": "Gostujuči 73+",
        "name_fr": "away 73+"
    },
    "1108^live_match^8^9:0": {
        "name_en": "9:0",
        "name_aa": "9:0",
        "name_de": "9:0",
        "name_nl": "9:0",
        "name_pl": "9:0",
        "name_ro": "9:0",
        "name_ru": "9:0",
        "name_tr": "9:0",
        "name_se": "9:0",
        "name_el": "9:0",
        "name_es": "9:0",
        "name_hr": "9:0",
        "name_fr": "9:0"
    },
    "2113^live_match^8^4": {
        "name_en": "4",
        "name_aa": "4",
        "name_de": "4",
        "name_nl": "4",
        "name_pl": "4",
        "name_ro": "4",
        "name_ru": "4",
        "name_tr": "4",
        "name_se": "4",
        "name_el": "4",
        "name_es": "4",
        "name_hr": "4",
        "name_fr": "4"
    },
    "2261^live_match^8^5:4": {
        "name_en": "5:4",
        "name_aa": "5:4",
        "name_de": "5:4",
        "name_nl": "5:4",
        "name_pl": "5:4",
        "name_ro": "5:4",
        "name_ru": "5:4",
        "name_tr": "5:4",
        "name_se": "5:4",
        "name_el": "5:4",
        "name_es": "5:4",
        "name_hr": "5:4",
        "name_fr": "5:4"
    },
    "1660^live_match^2^x": {
        "name_en": "x",
        "name_aa": "x",
        "name_de": "x",
        "name_nl": "x",
        "name_pl": "x",
        "name_ro": "x",
        "name_ru": "x",
        "name_tr": "x",
        "name_se": "x",
        "name_el": "x",
        "name_es": "x",
        "name_hr": "x",
        "name_fr": "x"
    },
    "2072^live_match^8^21:14": {
        "name_en": "21:14",
        "name_aa": "21:14",
        "name_de": "21:14",
        "name_nl": "21:14",
        "name_pl": "21:14",
        "name_ro": "21:14",
        "name_ru": "21:14",
        "name_tr": "21:14",
        "name_se": "21:14",
        "name_el": "21:14",
        "name_es": "21:14",
        "name_hr": "21:14",
        "name_fr": "21:14"
    },
    "1204^live_match^8^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "26^live_match^8^0:2": {
        "name_en": "0:2",
        "name_aa": "0:2",
        "name_de": "0:2",
        "name_nl": "0:2",
        "name_pl": "0:2",
        "name_ro": "0:2",
        "name_ru": "0:2",
        "name_tr": "0:2",
        "name_se": "0:2",
        "name_el": "0:2",
        "name_es": "0:2",
        "name_hr": "0:2",
        "name_fr": "0:2"
    },
    "2063^live_match^8^away_7-9": {
        "name_en": "away 7-9",
        "name_aa": "away 7-9",
        "name_de": "Auswärt 7-9",
        "name_nl": "Weg 7-9",
        "name_pl": "away 7-9",
        "name_ro": "away 7-9",
        "name_ru": "away 7-9",
        "name_tr": "away 7-9",
        "name_se": "away 7-9",
        "name_el": "away 7-9",
        "name_es": "away 7-9",
        "name_hr": "Gostujuči 7-9",
        "name_fr": "away 7-9"
    },
    "2075^live_match^8^18:25": {
        "name_en": "18:25",
        "name_aa": "18:25",
        "name_de": "18:25",
        "name_nl": "18:25",
        "name_pl": "18:25",
        "name_ro": "18:25",
        "name_ru": "18:25",
        "name_tr": "18:25",
        "name_se": "18:25",
        "name_el": "18:25",
        "name_es": "18:25",
        "name_hr": "18:25",
        "name_fr": "18:25"
    },
    "2082^live_match^8^0:6_or_1:6_or_2:6": {
        "name_en": "0:6 or 1:6 or 2:6",
        "name_aa": "0:6 or 1:6 or 2:6",
        "name_de": "0:6 oder 1:6 oder 2:6",
        "name_nl": "0:6 of 1:6 of 2:6",
        "name_pl": "0:6 or 1:6 or 2:6",
        "name_ro": "0:6 or 1:6 or 2:6",
        "name_ru": "0:6 or 1:6 or 2:6",
        "name_tr": "0:6 or 1:6 or 2:6",
        "name_se": "0:6 or 1:6 or 2:6",
        "name_el": "0:6 or 1:6 or 2:6",
        "name_es": "0:6 or 1:6 or 2:6",
        "name_hr": "0:6 or 1:6 or 2:6",
        "name_fr": "0:6 or 1:6 or 2:6"
    },
    "2354^live_match^8^11:6": {
        "name_en": "11:6",
        "name_aa": "11:6",
        "name_de": "11:6",
        "name_nl": "11:6",
        "name_pl": "11:6",
        "name_ro": "11:6",
        "name_ru": "11:6",
        "name_tr": "11:6",
        "name_se": "11:6",
        "name_el": "11:6",
        "name_es": "11:6",
        "name_hr": "11:6",
        "name_fr": "11:6"
    },
    "2048^live_match^8^da": {
        "name_en": "DA",
        "name_aa": "DA",
        "name_de": "DA",
        "name_nl": "DA",
        "name_pl": "DA",
        "name_ro": "DA",
        "name_ru": "DA",
        "name_tr": "DA",
        "name_se": "DA",
        "name_el": "DA",
        "name_es": "DA",
        "name_hr": "Da",
        "name_fr": "DA"
    },
    "29^live_match^8^6:0": {
        "name_en": "6:0",
        "name_aa": "6:0",
        "name_de": "6:0",
        "name_nl": "6:0",
        "name_pl": "6:0",
        "name_ro": "6:0",
        "name_ru": "6:0",
        "name_tr": "6:0",
        "name_se": "6:0",
        "name_el": "6:0",
        "name_es": "6:0",
        "name_hr": "6:0",
        "name_fr": "6:0"
    },
    "26^live_match^8^4:4": {
        "name_en": "4:4",
        "name_aa": "4:4",
        "name_de": "4:4",
        "name_nl": "4:4",
        "name_pl": "4:4",
        "name_ro": "4:4",
        "name_ru": "4:4",
        "name_tr": "4:4",
        "name_se": "4:4",
        "name_el": "4:4",
        "name_es": "4:4",
        "name_hr": "4:4",
        "name_fr": "4:4"
    },
    "898^live_match^6^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2261^live_match^8^1:6": {
        "name_en": "1:6",
        "name_aa": "1:6",
        "name_de": "1:6",
        "name_nl": "1:6",
        "name_pl": "1:6",
        "name_ro": "1:6",
        "name_ru": "1:6",
        "name_tr": "1:6",
        "name_se": "1:6",
        "name_el": "1:6",
        "name_es": "1:6",
        "name_hr": "1:6",
        "name_fr": "1:6"
    },
    "1108^live_match^8^13:1": {
        "name_en": "13:1",
        "name_aa": "13:1",
        "name_de": "13:1",
        "name_nl": "13:1",
        "name_pl": "13:1",
        "name_ro": "13:1",
        "name_ru": "13:1",
        "name_tr": "13:1",
        "name_se": "13:1",
        "name_el": "13:1",
        "name_es": "13:1",
        "name_hr": "13:1",
        "name_fr": "13:1"
    },
    "2129^live_match^8^tie": {
        "name_en": "Tie",
        "name_aa": "Tie",
        "name_de": "Unentschieden",
        "name_nl": "Gelijkspel",
        "name_pl": "Tie",
        "name_ro": "Tie",
        "name_ru": "Tie",
        "name_tr": "Tie",
        "name_se": "Tie",
        "name_el": "Tie",
        "name_es": "Tie",
        "name_hr": "Tie",
        "name_fr": "Tie"
    },
    "2252^live_match^8^yes": {
        "name_en": "yes",
        "name_aa": "yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "yes",
        "name_ro": "yes",
        "name_ru": "yes",
        "name_tr": "yes",
        "name_se": "yes",
        "name_el": "yes",
        "name_es": "yes",
        "name_hr": "Da",
        "name_fr": "yes"
    },
    "874^live_match^8^over": {
        "name_en": "Over",
        "name_aa": "Over",
        "name_de": "Über",
        "name_nl": "Over",
        "name_pl": "Over",
        "name_ro": "Over",
        "name_ru": "Over",
        "name_tr": "Üst",
        "name_se": "Over",
        "name_el": "Over",
        "name_es": "Over",
        "name_hr": "Više",
        "name_fr": "p"
    },
    "2354^live_match^8^7:11": {
        "name_en": "7:11",
        "name_aa": "7:11",
        "name_de": "7:11",
        "name_nl": "7:11",
        "name_pl": "7:11",
        "name_ro": "7:11",
        "name_ru": "7:11",
        "name_tr": "7:11",
        "name_se": "7:11",
        "name_el": "7:11",
        "name_es": "7:11",
        "name_hr": "7:11",
        "name_fr": "7:11"
    },
    "2051^live_match^6^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2353^live_match^8^12-13": {
        "name_en": "away 12-13",
        "name_aa": "away 12-13",
        "name_de": "Auswärt 12-13",
        "name_nl": "Weg 12-13",
        "name_pl": "away 12-13",
        "name_ro": "away 12-13",
        "name_ru": "away 12-13",
        "name_tr": "away 12-13",
        "name_se": "away 12-13",
        "name_el": "away 12-13",
        "name_es": "away 12-13",
        "name_hr": "Gostujuči 12-13",
        "name_fr": "away 12-13"
    },
    "2249^live_match^8^yes": {
        "name_en": "yes",
        "name_aa": "yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "yes",
        "name_ro": "yes",
        "name_ru": "yes",
        "name_tr": "yes",
        "name_se": "yes",
        "name_el": "yes",
        "name_es": "yes",
        "name_hr": "Da",
        "name_fr": "yes"
    },
    "2240^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2074^live_match^8^19:25": {
        "name_en": "19:25",
        "name_aa": "19:25",
        "name_de": "19:25",
        "name_nl": "19:25",
        "name_pl": "19:25",
        "name_ro": "19:25",
        "name_ru": "19:25",
        "name_tr": "19:25",
        "name_se": "19:25",
        "name_el": "19:25",
        "name_es": "19:25",
        "name_hr": "19:25",
        "name_fr": "19:25"
    },
    "2080^live_match^8^away": {
        "name_en": "away",
        "name_aa": "away",
        "name_de": "Auswärts",
        "name_nl": "Uit",
        "name_pl": "away",
        "name_ro": "away",
        "name_ru": "away",
        "name_tr": "away",
        "name_se": "away",
        "name_el": "away",
        "name_es": "away",
        "name_hr": "Gostujući",
        "name_fr": "away"
    },
    "2074^live_match^8^15:7": {
        "name_en": "15:7",
        "name_aa": "15:7",
        "name_de": "15:7",
        "name_nl": "15:7",
        "name_pl": "15:7",
        "name_ro": "15:7",
        "name_ru": "15:7",
        "name_tr": "15:7",
        "name_se": "15:7",
        "name_el": "15:7",
        "name_es": "15:7",
        "name_hr": "15:7",
        "name_fr": "15:7"
    },
    "2334^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "2326^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "2076^live_match^8^15:8": {
        "name_en": "15:8",
        "name_aa": "15:8",
        "name_de": "15:8",
        "name_nl": "15:8",
        "name_pl": "15:8",
        "name_ro": "15:8",
        "name_ru": "15:8",
        "name_tr": "15:8",
        "name_se": "15:8",
        "name_el": "15:8",
        "name_es": "15:8",
        "name_hr": "15:8",
        "name_fr": "15:8"
    },
    "2364^live_match^8^away_and_under_and_ng": {
        "name_en": "away and under and NG",
        "name_aa": "away and under and NG",
        "name_de": "Auswart und Unter und NG",
        "name_nl": "Weg en minder dan en NG",
        "name_pl": "away and under and NG",
        "name_ro": "away and under and NG",
        "name_ru": "away and under and NG",
        "name_tr": "away and under and NG",
        "name_se": "away and under and NG",
        "name_el": "away and under and NG",
        "name_es": "away and under and NG",
        "name_hr": "away and under and NG",
        "name_fr": "away and under and NG"
    },
    "2261^live_match^8^6:5": {
        "name_en": "6:5",
        "name_aa": "6:5",
        "name_de": "6:5",
        "name_nl": "6:5",
        "name_pl": "6:5",
        "name_ro": "6:5",
        "name_ru": "6:5",
        "name_tr": "6:5",
        "name_se": "6:5",
        "name_el": "6:5",
        "name_es": "6:5",
        "name_hr": "6:5",
        "name_fr": "6:5"
    },
    "2074^live_match^8^13:15": {
        "name_en": "13:15",
        "name_aa": "13:15",
        "name_de": "13:15",
        "name_nl": "13:15",
        "name_pl": "13:15",
        "name_ro": "13:15",
        "name_ru": "13:15",
        "name_tr": "13:15",
        "name_se": "13:15",
        "name_el": "13:15",
        "name_es": "13:15",
        "name_hr": "13:15",
        "name_fr": "13:15"
    },
    "22^live_match^6^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "116^live_match^8^6:0": {
        "name_en": "6:0",
        "name_aa": "6:0",
        "name_de": "6:0",
        "name_nl": "6:0",
        "name_pl": "6:0",
        "name_ro": "6:0",
        "name_ru": "6:0",
        "name_tr": "6:0",
        "name_se": "6:0",
        "name_el": "6:0",
        "name_es": "6:0",
        "name_hr": "6:0",
        "name_fr": "6:0"
    },
    "26^live_match^8^0:23": {
        "name_en": "0:23",
        "name_aa": "0:23",
        "name_de": "0:23",
        "name_nl": "0:23",
        "name_pl": "0:23",
        "name_ro": "0:23",
        "name_ru": "0:23",
        "name_tr": "0:23",
        "name_se": "0:23",
        "name_el": "0:23",
        "name_es": "0:23",
        "name_hr": "0:23",
        "name_fr": "0:23"
    },
    "26^live_match^8^15:2": {
        "name_en": "15:2",
        "name_aa": "15:2",
        "name_de": "15:2",
        "name_nl": "15:2",
        "name_pl": "15:2",
        "name_ro": "15:2",
        "name_ru": "15:2",
        "name_tr": "15:2",
        "name_se": "15:2",
        "name_el": "15:2",
        "name_es": "15:2",
        "name_hr": "15:2",
        "name_fr": "15:2"
    },
    "2261^live_match^8^8:4": {
        "name_en": "8:4",
        "name_aa": "8:4",
        "name_de": "8:4",
        "name_nl": "8:4",
        "name_pl": "8:4",
        "name_ro": "8:4",
        "name_ru": "8:4",
        "name_tr": "8:4",
        "name_se": "8:4",
        "name_el": "8:4",
        "name_es": "8:4",
        "name_hr": "8:4",
        "name_fr": "8:4"
    },
    "2073^live_match^8^19:21": {
        "name_en": "19:21",
        "name_aa": "19:21",
        "name_de": "19:21",
        "name_nl": "19:21",
        "name_pl": "19:21",
        "name_ro": "19:21",
        "name_ru": "19:21",
        "name_tr": "19:21",
        "name_se": "19:21",
        "name_el": "19:21",
        "name_es": "19:21",
        "name_hr": "19:21",
        "name_fr": "19:21"
    },
    "1108^live_match^8^7:2": {
        "name_en": "7:2",
        "name_aa": "7:2",
        "name_de": "7:2",
        "name_nl": "7:2",
        "name_pl": "7:2",
        "name_ro": "7:2",
        "name_ru": "7:2",
        "name_tr": "7:2",
        "name_se": "7:2",
        "name_el": "7:2",
        "name_es": "7:2",
        "name_hr": "7:2",
        "name_fr": "7:2"
    },
    "2054^live_match^8^12": {
        "name_en": "12",
        "name_aa": "12",
        "name_de": "12",
        "name_nl": "12",
        "name_pl": "12",
        "name_ro": "12",
        "name_ru": "12",
        "name_tr": "12",
        "name_se": "12",
        "name_el": "12",
        "name_es": "12",
        "name_hr": "12",
        "name_fr": "12"
    },
    "2012^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "2261^live_match^8^3:1": {
        "name_en": "3:1",
        "name_aa": "3:1",
        "name_de": "3:1",
        "name_nl": "3:1",
        "name_pl": "3:1",
        "name_ro": "3:1",
        "name_ru": "3:1",
        "name_tr": "3:1",
        "name_se": "3:1",
        "name_el": "3:1",
        "name_es": "3:1",
        "name_hr": "3:1",
        "name_fr": "3:1"
    },
    "2074^live_match^8^25:23": {
        "name_en": "25:23",
        "name_aa": "25:23",
        "name_de": "25:23",
        "name_nl": "25:23",
        "name_pl": "25:23",
        "name_ro": "25:23",
        "name_ru": "25:23",
        "name_tr": "25:23",
        "name_se": "25:23",
        "name_el": "25:23",
        "name_es": "25:23",
        "name_hr": "25:23",
        "name_fr": "25:23"
    },
    "2152^live_match^8^none": {
        "name_en": "none",
        "name_aa": "none",
        "name_de": "Keiner",
        "name_nl": "Geen",
        "name_pl": "none",
        "name_ro": "none",
        "name_ru": "none",
        "name_tr": "none",
        "name_se": "none",
        "name_el": "none",
        "name_es": "none",
        "name_hr": "Nitko",
        "name_fr": "none"
    },
    "126^live_match^8^over": {
        "name_en": "Over",
        "name_aa": "Over",
        "name_de": "Über",
        "name_nl": "Over",
        "name_pl": "Over",
        "name_ro": "Over",
        "name_ru": "Over",
        "name_tr": "Üst",
        "name_se": "Over",
        "name_el": "Over",
        "name_es": "Over",
        "name_hr": "Više",
        "name_fr": "p"
    },
    "2076^live_match^8^away_after_extra_points": {
        "name_en": "away after extra points",
        "name_aa": "away after extra points",
        "name_de": "Auswärtsmannschaft Nach Verlängerung",
        "name_nl": "Uitteam Na extra punten",
        "name_pl": "away after extra points",
        "name_ro": "away after extra points",
        "name_ru": "away after extra points",
        "name_tr": "away after extra points",
        "name_se": "away after extra points",
        "name_el": "away after extra points",
        "name_es": "away after extra points",
        "name_hr": "away after extra points",
        "name_fr": "away after extra points"
    },
    "2207^live_match^8^away_37-48": {
        "name_en": "away 37-48",
        "name_aa": "away 37-48",
        "name_de": "Auswärt 37-48",
        "name_nl": "Weg 37-48",
        "name_pl": "away 37-48",
        "name_ro": "away 37-48",
        "name_ru": "away 37-48",
        "name_tr": "away 37-48",
        "name_se": "away 37-48",
        "name_el": "away 37-48",
        "name_es": "away 37-48",
        "name_hr": "Gostujuči 37-48",
        "name_fr": "away 37-48"
    },
    "26^live_match^8^7:0": {
        "name_en": "7:0",
        "name_aa": "7:0",
        "name_de": "7:0",
        "name_nl": "7:0",
        "name_pl": "7:0",
        "name_ro": "7:0",
        "name_ru": "7:0",
        "name_tr": "7:0",
        "name_se": "7:0",
        "name_el": "7:0",
        "name_es": "7:0",
        "name_hr": "7:0",
        "name_fr": "7:0"
    },
    "48^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "1108^live_match^8^3:2": {
        "name_en": "3:2",
        "name_aa": "3:2",
        "name_de": "3:2",
        "name_nl": "3:2",
        "name_pl": "3:2",
        "name_ro": "3:2",
        "name_ru": "3:2",
        "name_tr": "3:2",
        "name_se": "3:2",
        "name_el": "3:2",
        "name_es": "3:2",
        "name_hr": "3:2",
        "name_fr": "3:2"
    },
    "2255^live_match^8^no": {
        "name_en": "no",
        "name_aa": "no",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "no",
        "name_ro": "no",
        "name_ru": "no",
        "name_tr": "no",
        "name_se": "no",
        "name_el": "no",
        "name_es": "no",
        "name_hr": "Ne",
        "name_fr": "no"
    },
    "86^live_match^8^under": {
        "name_en": "Under",
        "name_aa": "u",
        "name_de": "Unter",
        "name_nl": "u",
        "name_pl": "u",
        "name_ro": "u",
        "name_ru": "u",
        "name_tr": "Alt",
        "name_se": "u",
        "name_el": "u",
        "name_es": "u",
        "name_hr": "Manje",
        "name_fr": "m"
    },
    "2185^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "1108^live_match^8^5:0": {
        "name_en": "5:0",
        "name_aa": "5:0",
        "name_de": "5:0",
        "name_nl": "5:0",
        "name_pl": "5:0",
        "name_ro": "5:0",
        "name_ru": "5:0",
        "name_tr": "5:0",
        "name_se": "5:0",
        "name_el": "5:0",
        "name_es": "5:0",
        "name_hr": "5:0",
        "name_fr": "5:0"
    },
    "2113^live_match^8^5": {
        "name_en": "5",
        "name_aa": "5",
        "name_de": "5",
        "name_nl": "5",
        "name_pl": "5",
        "name_ro": "5",
        "name_ru": "5",
        "name_tr": "5",
        "name_se": "5",
        "name_el": "5",
        "name_es": "5",
        "name_hr": "5",
        "name_fr": "5"
    },
    "26^live_match^8^12:2": {
        "name_en": "12:2",
        "name_aa": "12:2",
        "name_de": "12:2",
        "name_nl": "12:2",
        "name_pl": "12:2",
        "name_ro": "12:2",
        "name_ru": "12:2",
        "name_tr": "12:2",
        "name_se": "12:2",
        "name_el": "12:2",
        "name_es": "12:2",
        "name_hr": "12:2",
        "name_fr": "12:2"
    },
    "2069^live_match^8^no": {
        "name_en": "no",
        "name_aa": "no",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "no",
        "name_ro": "no",
        "name_ru": "no",
        "name_tr": "no",
        "name_se": "no",
        "name_el": "no",
        "name_es": "no",
        "name_hr": "Ne",
        "name_fr": "no"
    },
    "1206^live_match^8^pink": {
        "name_en": "pink",
        "name_aa": "pink",
        "name_de": "pink",
        "name_nl": "roze",
        "name_pl": "pink",
        "name_ro": "pink",
        "name_ru": "pink",
        "name_tr": "pembe",
        "name_se": "pink",
        "name_el": "pink",
        "name_es": "pink",
        "name_hr": "Roze",
        "name_fr": "rose"
    },
    "2243^live_match^8^yes": {
        "name_en": "yes",
        "name_aa": "yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "yes",
        "name_ro": "yes",
        "name_ru": "yes",
        "name_tr": "yes",
        "name_se": "yes",
        "name_el": "yes",
        "name_es": "yes",
        "name_hr": "Da",
        "name_fr": "yes"
    },
    "2015^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "2142^live_match^8^home_5-6": {
        "name_en": "home 5-6",
        "name_aa": "home 5-6",
        "name_de": "Heim 5-6",
        "name_nl": "thuis 5-6",
        "name_pl": "home 5-6",
        "name_ro": "home 5-6",
        "name_ru": "home 5-6",
        "name_tr": "home 5-6",
        "name_se": "home 5-6",
        "name_el": "home 5-6",
        "name_es": "home 5-6",
        "name_hr": "Domači 5-6",
        "name_fr": "home 5-6"
    },
    "1324^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2305^live_match^8^2:7": {
        "name_en": "2:7",
        "name_aa": "2:7",
        "name_de": "2:7",
        "name_nl": "2:7",
        "name_pl": "2:7",
        "name_ro": "2:7",
        "name_ru": "2:7",
        "name_tr": "2:7",
        "name_se": "2:7",
        "name_el": "2:7",
        "name_es": "2:7",
        "name_hr": "2:7",
        "name_fr": "2:7"
    },
    "2096^live_match^8^away_yes": {
        "name_en": "away yes",
        "name_aa": "away yes",
        "name_de": "Auswart Ja",
        "name_nl": "Weg  Ja",
        "name_pl": "away yes",
        "name_ro": "away yes",
        "name_ru": "away yes",
        "name_tr": "away yes",
        "name_se": "away yes",
        "name_el": "away yes",
        "name_es": "away yes",
        "name_hr": "Gostujuči da",
        "name_fr": "away yes"
    },
    "2318^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "1108^live_match^8^2:10": {
        "name_en": "2:10",
        "name_aa": "2:10",
        "name_de": "2:10",
        "name_nl": "2:10",
        "name_pl": "2:10",
        "name_ro": "2:10",
        "name_ru": "2:10",
        "name_tr": "2:10",
        "name_se": "2:10",
        "name_el": "2:10",
        "name_es": "2:10",
        "name_hr": "2:10",
        "name_fr": "2:10"
    },
    "2074^live_match^8^18:25": {
        "name_en": "18:25",
        "name_aa": "18:25",
        "name_de": "18:25",
        "name_nl": "18:25",
        "name_pl": "18:25",
        "name_ro": "18:25",
        "name_ru": "18:25",
        "name_tr": "18:25",
        "name_se": "18:25",
        "name_el": "18:25",
        "name_es": "18:25",
        "name_hr": "18:25",
        "name_fr": "18:25"
    },
    "29^live_match^8^4:5": {
        "name_en": "4:5",
        "name_aa": "4:5",
        "name_de": "4:5",
        "name_nl": "4:5",
        "name_pl": "4:5",
        "name_ro": "4:5",
        "name_ru": "4:5",
        "name_tr": "4:5",
        "name_se": "4:5",
        "name_el": "4:5",
        "name_es": "4:5",
        "name_hr": "4:5",
        "name_fr": "4:5"
    },
    "2234^live_match^8^exactly": {
        "name_en": "exactly",
        "name_aa": "exactly",
        "name_de": "Genau",
        "name_nl": "Precies",
        "name_pl": "exactly",
        "name_ro": "exactly",
        "name_ru": "exactly",
        "name_tr": "exactly",
        "name_se": "exactly",
        "name_el": "exactly",
        "name_es": "exactly",
        "name_hr": "Tacno",
        "name_fr": "exactly"
    },
    "26^live_match^8^2:15": {
        "name_en": "2:15",
        "name_aa": "2:15",
        "name_de": "2:15",
        "name_nl": "2:15",
        "name_pl": "2:15",
        "name_ro": "2:15",
        "name_ru": "2:15",
        "name_tr": "2:15",
        "name_se": "2:15",
        "name_el": "2:15",
        "name_es": "2:15",
        "name_hr": "2:15",
        "name_fr": "2:15"
    },
    "2153^live_match^8^tie": {
        "name_en": "Tie",
        "name_aa": "Tie",
        "name_de": "Unentschieden",
        "name_nl": "Gelijkspel",
        "name_pl": "Tie",
        "name_ro": "Tie",
        "name_ru": "Tie",
        "name_tr": "Tie",
        "name_se": "Tie",
        "name_el": "Tie",
        "name_es": "Tie",
        "name_hr": "Tie",
        "name_fr": "Tie"
    },
    "2364^live_match^8^away_and_over_and_ng": {
        "name_en": "away and over and NG",
        "name_aa": "away and over and NG",
        "name_de": "Auswart und Über und NG",
        "name_nl": "Weg en meer dan en NG",
        "name_pl": "away and over and NG",
        "name_ro": "away and over and NG",
        "name_ru": "away and over and NG",
        "name_tr": "away and over and NG",
        "name_se": "away and over and NG",
        "name_el": "away and over and NG",
        "name_es": "away and over and NG",
        "name_hr": "away and over and NG",
        "name_fr": "away and over and NG"
    },
    "2354^live_match^8^6:11": {
        "name_en": "6:11",
        "name_aa": "6:11",
        "name_de": "6:11",
        "name_nl": "6:11",
        "name_pl": "6:11",
        "name_ro": "6:11",
        "name_ru": "6:11",
        "name_tr": "6:11",
        "name_se": "6:11",
        "name_el": "6:11",
        "name_es": "6:11",
        "name_hr": "6:11",
        "name_fr": "6:11"
    },
    "29^live_match^8^1:1": {
        "name_en": "1:1",
        "name_aa": "1:1",
        "name_de": "1:1",
        "name_nl": "1:1",
        "name_pl": "1:1",
        "name_ro": "1:1",
        "name_ru": "1:1",
        "name_tr": "1:1",
        "name_se": "1:1",
        "name_el": "1:1",
        "name_es": "1:1",
        "name_hr": "1:1",
        "name_fr": "1:1"
    },
    "2205^live_match^8^behind": {
        "name_en": "behind",
        "name_aa": "behind",
        "name_de": "behind",
        "name_nl": "behind",
        "name_pl": "behind",
        "name_ro": "behind",
        "name_ru": "behind",
        "name_tr": "behind",
        "name_se": "behind",
        "name_el": "behind",
        "name_es": "behind",
        "name_hr": "behind",
        "name_fr": "behind"
    },
    "2082^live_match^8^5:7_or_6:7": {
        "name_en": "5:7 or 6:7",
        "name_aa": "5:7 or 6:7",
        "name_de": "5:7 oder 6:7",
        "name_nl": "5:7 of 6:7",
        "name_pl": "5:7 or 6:7",
        "name_ro": "5:7 or 6:7",
        "name_ru": "5:7 or 6:7",
        "name_tr": "5:7 or 6:7",
        "name_se": "5:7 or 6:7",
        "name_el": "5:7 or 6:7",
        "name_es": "5:7 or 6:7",
        "name_hr": "5:7 or 6:7",
        "name_fr": "5:7 or 6:7"
    },
    "2354^live_match^8^1:0": {
        "name_en": "1:0",
        "name_aa": "1:0",
        "name_de": "1:0",
        "name_nl": "1:0",
        "name_pl": "1:0",
        "name_ro": "1:0",
        "name_ru": "1:0",
        "name_tr": "1:0",
        "name_se": "1:0",
        "name_el": "1:0",
        "name_es": "1:0",
        "name_hr": "1:0",
        "name_fr": "1:0"
    },
    "2157^live_match^8^18:21": {
        "name_en": "18:21",
        "name_aa": "18:21",
        "name_de": "18:21",
        "name_nl": "18:21",
        "name_pl": "18:21",
        "name_ro": "18:21",
        "name_ru": "18:21",
        "name_tr": "18:21",
        "name_se": "18:21",
        "name_el": "18:21",
        "name_es": "18:21",
        "name_hr": "18:21",
        "name_fr": "18:21"
    },
    "2206^live_match^8^hh": {
        "name_en": "HH",
        "name_aa": "HH",
        "name_de": "HH",
        "name_nl": "HH",
        "name_pl": "HH",
        "name_ro": "HH",
        "name_ru": "HH",
        "name_tr": "HH",
        "name_se": "HH",
        "name_el": "HH",
        "name_es": "HH",
        "name_hr": "HH",
        "name_fr": "HH"
    },
    "2024^live_match^8^neither": {
        "name_en": "neither",
        "name_aa": "neither",
        "name_de": "Keiner",
        "name_nl": "Geen van beide",
        "name_pl": "neither",
        "name_ro": "neither",
        "name_ru": "neither",
        "name_tr": "neither",
        "name_se": "neither",
        "name_el": "neither",
        "name_es": "neither",
        "name_hr": "Ni",
        "name_fr": "neither"
    },
    "2126^live_match^8^home_2": {
        "name_en": "home 2",
        "name_aa": "home 2",
        "name_de": "Heim 2",
        "name_nl": "Thuis 2",
        "name_pl": "home 2",
        "name_ro": "home 2",
        "name_ru": "home 2",
        "name_tr": "home 2",
        "name_se": "home 2",
        "name_el": "home 2",
        "name_es": "home 2",
        "name_hr": "Domači 2",
        "name_fr": "home 2"
    },
    "1108^live_match^8^3:8": {
        "name_en": "3:8",
        "name_aa": "3:8",
        "name_de": "3:8",
        "name_nl": "3:8",
        "name_pl": "3:8",
        "name_ro": "3:8",
        "name_ru": "3:8",
        "name_tr": "3:8",
        "name_se": "3:8",
        "name_el": "3:8",
        "name_es": "3:8",
        "name_hr": "3:8",
        "name_fr": "3:8"
    },
    "2355^live_match^8^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "29^live_match^8^13:0": {
        "name_en": "13:0",
        "name_aa": "13:0",
        "name_de": "13:0",
        "name_nl": "13:0",
        "name_pl": "13:0",
        "name_ro": "13:0",
        "name_ru": "13:0",
        "name_tr": "13:0",
        "name_se": "13:0",
        "name_el": "13:0",
        "name_es": "13:0",
        "name_hr": "13:0",
        "name_fr": "13:0"
    },
    "2216^live_match^8^home_40+": {
        "name_en": "home 40+",
        "name_aa": "home 40+",
        "name_de": "Heim 40+",
        "name_nl": "Thuis 40+",
        "name_pl": "home 40+",
        "name_ro": "home 40+",
        "name_ru": "home 40+",
        "name_tr": "home 40+",
        "name_se": "home 40+",
        "name_el": "home 40+",
        "name_es": "home 40+",
        "name_hr": "Domači  40+",
        "name_fr": "home 40+"
    },
    "2002^live_match^6^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "26^live_match^8^6:0": {
        "name_en": "6:0",
        "name_aa": "6:0",
        "name_de": "6:0",
        "name_nl": "6:0",
        "name_pl": "6:0",
        "name_ro": "6:0",
        "name_ru": "6:0",
        "name_tr": "6:0",
        "name_se": "6:0",
        "name_el": "6:0",
        "name_es": "6:0",
        "name_hr": "6:0",
        "name_fr": "6:0"
    },
    "2103^live_match^8^no": {
        "name_en": "no",
        "name_aa": "no",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "no",
        "name_ro": "no",
        "name_ru": "no",
        "name_tr": "no",
        "name_se": "no",
        "name_el": "no",
        "name_es": "no",
        "name_hr": "Ne",
        "name_fr": "no"
    },
    "26^live_match^8^5:9": {
        "name_en": "5:9",
        "name_aa": "5:9",
        "name_de": "5:9",
        "name_nl": "5:9",
        "name_pl": "5:9",
        "name_ro": "5:9",
        "name_ru": "5:9",
        "name_tr": "5:9",
        "name_se": "5:9",
        "name_el": "5:9",
        "name_es": "5:9",
        "name_hr": "5:9",
        "name_fr": "5:9"
    },
    "2307^live_match^8^5:1": {
        "name_en": "5:1",
        "name_aa": "5:1",
        "name_de": "5:1",
        "name_nl": "5:1",
        "name_pl": "5:1",
        "name_ro": "5:1",
        "name_ru": "5:1",
        "name_tr": "5:1",
        "name_se": "5:1",
        "name_el": "5:1",
        "name_es": "5:1",
        "name_hr": "5:1",
        "name_fr": "5:1"
    },
    "1108^live_match^8^3:9": {
        "name_en": "3:9",
        "name_aa": "3:9",
        "name_de": "3:9",
        "name_nl": "3:9",
        "name_pl": "3:9",
        "name_ro": "3:9",
        "name_ru": "3:9",
        "name_tr": "3:9",
        "name_se": "3:9",
        "name_el": "3:9",
        "name_es": "3:9",
        "name_hr": "3:9",
        "name_fr": "3:9"
    },
    "2031^live_match^8^exactly": {
        "name_en": "exactly",
        "name_aa": "exactly",
        "name_de": "Genau",
        "name_nl": "Precies",
        "name_pl": "exactly",
        "name_ro": "exactly",
        "name_ru": "exactly",
        "name_tr": "exactly",
        "name_se": "exactly",
        "name_el": "exactly",
        "name_es": "exactly",
        "name_hr": "Tacno",
        "name_fr": "exactly"
    },
    "2354^live_match^8^8:9": {
        "name_en": "8:9",
        "name_aa": "8:9",
        "name_de": "8:9",
        "name_nl": "8:9",
        "name_pl": "8:9",
        "name_ro": "8:9",
        "name_ru": "8:9",
        "name_tr": "8:9",
        "name_se": "8:9",
        "name_el": "8:9",
        "name_es": "8:9",
        "name_hr": "8:9",
        "name_fr": "8:9"
    },
    "2094^live_match^8^no": {
        "name_en": "no",
        "name_aa": "no",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "no",
        "name_ro": "no",
        "name_ru": "no",
        "name_tr": "no",
        "name_se": "no",
        "name_el": "no",
        "name_es": "no",
        "name_hr": "Ne",
        "name_fr": "no"
    },
    "1108^live_match^8^1:11": {
        "name_en": "1:11",
        "name_aa": "1:11",
        "name_de": "1:11",
        "name_nl": "1:11",
        "name_pl": "1:11",
        "name_ro": "1:11",
        "name_ru": "1:11",
        "name_tr": "1:11",
        "name_se": "1:11",
        "name_el": "1:11",
        "name_es": "1:11",
        "name_hr": "1:11",
        "name_fr": "1:11"
    },
    "1108^live_match^8^7:6": {
        "name_en": "7:6",
        "name_aa": "7:6",
        "name_de": "7:6",
        "name_nl": "7:6",
        "name_pl": "7:6",
        "name_ro": "7:6",
        "name_ru": "7:6",
        "name_tr": "7:6",
        "name_se": "7:6",
        "name_el": "7:6",
        "name_es": "7:6",
        "name_hr": "7:6",
        "name_fr": "7:6"
    },
    "2075^live_match^8^16:256": {
        "name_en": "16:25",
        "name_aa": "16:25",
        "name_de": "16:25",
        "name_nl": "16:25",
        "name_pl": "16:25",
        "name_ro": "16:25",
        "name_ru": "16:25",
        "name_tr": "16:25",
        "name_se": "16:25",
        "name_el": "16:25",
        "name_es": "16:25",
        "name_hr": "16:25",
        "name_fr": "16:25"
    },
    "2354^live_match^8^6:7": {
        "name_en": "6:7",
        "name_aa": "6:7",
        "name_de": "6:7",
        "name_nl": "6:7",
        "name_pl": "6:7",
        "name_ro": "6:7",
        "name_ru": "6:7",
        "name_tr": "6:7",
        "name_se": "6:7",
        "name_el": "6:7",
        "name_es": "6:7",
        "name_hr": "6:7",
        "name_fr": "6:7"
    },
    "2060^live_match^8^away_11+": {
        "name_en": "away 11+",
        "name_aa": "away 11+",
        "name_de": "Auswärt 11+",
        "name_nl": "Weg 11+",
        "name_pl": "away 11+",
        "name_ro": "away 11+",
        "name_ru": "away 11+",
        "name_tr": "away 11+",
        "name_se": "away 11+",
        "name_el": "away 11+",
        "name_es": "away 11+",
        "name_hr": "Gostujuči 11+",
        "name_fr": "away 11+"
    },
    "2300^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "2033^live_match^8^draw_and_over": {
        "name_en": "draw and over",
        "name_aa": "draw and over",
        "name_de": "Unentschieden und Über",
        "name_nl": "Gelijkspel and Meer dan",
        "name_pl": "draw and over",
        "name_ro": "draw and over",
        "name_ru": "draw and over",
        "name_tr": "draw and over",
        "name_se": "draw and over",
        "name_el": "draw and over",
        "name_es": "draw and over",
        "name_hr": "Neriješeno i više",
        "name_fr": "draw and over"
    },
    "120^live_match^6^x": {
        "name_en": "x",
        "name_aa": "x",
        "name_de": "x",
        "name_nl": "x",
        "name_pl": "x",
        "name_ro": "x",
        "name_ru": "x",
        "name_tr": "x",
        "name_se": "x",
        "name_el": "x",
        "name_es": "x",
        "name_hr": "x",
        "name_fr": "x"
    },
    "2224^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "2198^live_match^8^other": {
        "name_en": "other",
        "name_aa": "other",
        "name_de": "Sonstig",
        "name_nl": "Andere",
        "name_pl": "other",
        "name_ro": "other",
        "name_ru": "other",
        "name_tr": "other",
        "name_se": "other",
        "name_el": "other",
        "name_es": "other",
        "name_hr": "Ostali",
        "name_fr": "other"
    },
    "2076^live_match^8^9:15": {
        "name_en": "9:15",
        "name_aa": "9:15",
        "name_de": "9:15",
        "name_nl": "9:15",
        "name_pl": "9:15",
        "name_ro": "9:15",
        "name_ru": "9:15",
        "name_tr": "9:15",
        "name_se": "9:15",
        "name_el": "9:15",
        "name_es": "9:15",
        "name_hr": "9:15",
        "name_fr": "9:15"
    },
    "2128^live_match^8^11:9": {
        "name_en": "11:9",
        "name_aa": "11:9",
        "name_de": "11:9",
        "name_nl": "11:9",
        "name_pl": "11:9",
        "name_ro": "11:9",
        "name_ru": "11:9",
        "name_tr": "11:9",
        "name_se": "11:9",
        "name_el": "11:9",
        "name_es": "11:9",
        "name_hr": "11:9",
        "name_fr": "11:9"
    },
    "2261^live_match^8^3:8": {
        "name_en": "3:8",
        "name_aa": "3:8",
        "name_de": "3:8",
        "name_nl": "3:8",
        "name_pl": "3:8",
        "name_ro": "3:8",
        "name_ru": "3:8",
        "name_tr": "3:8",
        "name_se": "3:8",
        "name_el": "3:8",
        "name_es": "3:8",
        "name_hr": "3:8",
        "name_fr": "3:8"
    },
    "2354^live_match^8^2:7": {
        "name_en": "2:7",
        "name_aa": "2:7",
        "name_de": "2:7",
        "name_nl": "2:7",
        "name_pl": "2:7",
        "name_ro": "2:7",
        "name_ru": "2:7",
        "name_tr": "2:7",
        "name_se": "2:7",
        "name_el": "2:7",
        "name_es": "2:7",
        "name_hr": "2:7",
        "name_fr": "2:7"
    },
    "2046^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "2065^live_match^6^home_win_by_3+": {
        "name_en": "home win by 3+",
        "name_aa": "home win by 3+",
        "name_de": "Heim gewinnt durch 3+",
        "name_nl": "thuiszege met 3+",
        "name_pl": "home win by 3+",
        "name_ro": "home win by 3+",
        "name_ru": "home win by 3+",
        "name_tr": "home win by 3+",
        "name_se": "home win by 3+",
        "name_el": "home win by 3+",
        "name_es": "home win by 3+",
        "name_hr": "Domači pobijediti po 3+",
        "name_fr": "home win by 3+"
    },
    "2057^live_match^8^x2": {
        "name_en": "X2",
        "name_aa": "X2",
        "name_de": "X2",
        "name_nl": "X2",
        "name_pl": "X2",
        "name_ro": "X2",
        "name_ru": "X2",
        "name_tr": "X2",
        "name_se": "X2",
        "name_el": "X2",
        "name_es": "X2",
        "name_hr": "X2",
        "name_fr": "X2"
    },
    "2120^live_match^8^yes": {
        "name_en": "yes",
        "name_aa": "yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "yes",
        "name_ro": "yes",
        "name_ru": "yes",
        "name_tr": "yes",
        "name_se": "yes",
        "name_el": "yes",
        "name_es": "yes",
        "name_hr": "Da",
        "name_fr": "yes"
    },
    "2121^live_match^8^yes": {
        "name_en": "yes",
        "name_aa": "yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "yes",
        "name_ro": "yes",
        "name_ru": "yes",
        "name_tr": "yes",
        "name_se": "yes",
        "name_el": "yes",
        "name_es": "yes",
        "name_hr": "Da",
        "name_fr": "yes"
    },
    "119^live_match^8^first_half": {
        "name_en": "first half",
        "name_aa": "first half",
        "name_de": "Erste Halbzeit",
        "name_nl": "eerste helft",
        "name_pl": "first half",
        "name_ro": "first half",
        "name_ru": "first half",
        "name_tr": "ilk yarı",
        "name_se": "first half",
        "name_el": "first half",
        "name_es": "first half",
        "name_hr": "prvo poluvrijeme",
        "name_fr": "première mi-temps"
    },
    "2072^live_match^8^25:19": {
        "name_en": "25:19",
        "name_aa": "25:19",
        "name_de": "25:19",
        "name_nl": "25:19",
        "name_pl": "25:19",
        "name_ro": "25:19",
        "name_ru": "25:19",
        "name_tr": "25:19",
        "name_se": "25:19",
        "name_el": "25:19",
        "name_es": "25:19",
        "name_hr": "25:19",
        "name_fr": "25:19"
    },
    "101^live_match^6^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2060^live_match^8^home_9-10": {
        "name_en": "home 9-10",
        "name_aa": "home 9-10",
        "name_de": "Heim 9-10",
        "name_nl": "thuis 9-10",
        "name_pl": "home 9-10",
        "name_ro": "home 9-10",
        "name_ru": "home 9-10",
        "name_tr": "home 9-10",
        "name_se": "home 9-10",
        "name_el": "home 9-10",
        "name_es": "home 9-10",
        "name_hr": "Domači 9-10",
        "name_fr": "home 9-10"
    },
    "1663^live_match^2^x": {
        "name_en": "x",
        "name_aa": "x",
        "name_de": "x",
        "name_nl": "x",
        "name_pl": "x",
        "name_ro": "x",
        "name_ru": "x",
        "name_tr": "x",
        "name_se": "x",
        "name_el": "x",
        "name_es": "x",
        "name_hr": "x",
        "name_fr": "x"
    },
    "26^live_match^8^3:0": {
        "name_en": "3:0",
        "name_aa": "3:0",
        "name_de": "3:0",
        "name_nl": "3:0",
        "name_pl": "3:0",
        "name_ro": "3:0",
        "name_ru": "3:0",
        "name_tr": "3:0",
        "name_se": "3:0",
        "name_el": "3:0",
        "name_es": "3:0",
        "name_hr": "3:0",
        "name_fr": "3:0"
    },
    "1312^live_match^8^over": {
        "name_en": "Over",
        "name_aa": "Over",
        "name_de": "Über",
        "name_nl": "Over",
        "name_pl": "Over",
        "name_ro": "Over",
        "name_ru": "Over",
        "name_tr": "Üst",
        "name_se": "Over",
        "name_el": "Over",
        "name_es": "Over",
        "name_hr": "Više",
        "name_fr": "p"
    },
    "2305^live_match^8^0:7": {
        "name_en": "0:7",
        "name_aa": "0:7",
        "name_de": "0:7",
        "name_nl": "0:7",
        "name_pl": "0:7",
        "name_ro": "0:7",
        "name_ru": "0:7",
        "name_tr": "0:7",
        "name_se": "0:7",
        "name_el": "0:7",
        "name_es": "0:7",
        "name_hr": "0:7",
        "name_fr": "0:7"
    },
    "2059^live_match^8^home_7-9": {
        "name_en": "home 7-9",
        "name_aa": "home 7-9",
        "name_de": "Heim 7-9",
        "name_nl": "thuis 7-9",
        "name_pl": "home 7-9",
        "name_ro": "home 7-9",
        "name_ru": "home 7-9",
        "name_tr": "home 7-9",
        "name_se": "home 7-9",
        "name_el": "home 7-9",
        "name_es": "home 7-9",
        "name_hr": "Domači 7-9",
        "name_fr": "home 7-9"
    },
    "2087^live_match^8^0:4": {
        "name_en": "0:4",
        "name_aa": "0:4",
        "name_de": "0:4",
        "name_nl": "0:4",
        "name_pl": "0:4",
        "name_ro": "0:4",
        "name_ru": "0:4",
        "name_tr": "0:4",
        "name_se": "0:4",
        "name_el": "0:4",
        "name_es": "0:4",
        "name_hr": "0:4",
        "name_fr": "0:4"
    },
    "2199^live_match^8^behind": {
        "name_en": "behind",
        "name_aa": "behind",
        "name_de": "behind",
        "name_nl": "behind",
        "name_pl": "behind",
        "name_ro": "behind",
        "name_ru": "behind",
        "name_tr": "behind",
        "name_se": "behind",
        "name_el": "behind",
        "name_es": "behind",
        "name_hr": "behind",
        "name_fr": "behind"
    },
    "2032^live_match^8^home_and_under": {
        "name_en": "home and under",
        "name_aa": "home and under",
        "name_de": "Heim und Unter",
        "name_nl": "Thuis and Minder dan",
        "name_pl": "home and under",
        "name_ro": "home and under",
        "name_ru": "home and under",
        "name_tr": "home and under",
        "name_se": "home and under",
        "name_el": "home and under",
        "name_es": "home and under",
        "name_hr": "Domači i manje",
        "name_fr": "home and under"
    },
    "2196^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "1156^live_match^8^2:3": {
        "name_en": "2:3",
        "name_aa": "2:3",
        "name_de": "2:3",
        "name_nl": "2:3",
        "name_pl": "2:3",
        "name_ro": "2:3",
        "name_ru": "2:3",
        "name_tr": "2:3",
        "name_se": "2:3",
        "name_el": "2:3",
        "name_es": "2:3",
        "name_hr": "2:3",
        "name_fr": "2:3"
    },
    "2354^live_match^8^8:5": {
        "name_en": "8:5",
        "name_aa": "8:5",
        "name_de": "8:5",
        "name_nl": "8:5",
        "name_pl": "8:5",
        "name_ro": "8:5",
        "name_ru": "8:5",
        "name_tr": "8:5",
        "name_se": "8:5",
        "name_el": "8:5",
        "name_es": "8:5",
        "name_hr": "8:5",
        "name_fr": "8:5"
    },
    "2261^live_match^8^7:2": {
        "name_en": "7:2",
        "name_aa": "7:2",
        "name_de": "7:2",
        "name_nl": "7:2",
        "name_pl": "7:2",
        "name_ro": "7:2",
        "name_ru": "7:2",
        "name_tr": "7:2",
        "name_se": "7:2",
        "name_el": "7:2",
        "name_es": "7:2",
        "name_hr": "7:2",
        "name_fr": "7:2"
    },
    "29^live_match^8^1:4": {
        "name_en": "1:4",
        "name_aa": "1:4",
        "name_de": "1:4",
        "name_nl": "1:4",
        "name_pl": "1:4",
        "name_ro": "1:4",
        "name_ru": "1:4",
        "name_tr": "1:4",
        "name_se": "1:4",
        "name_el": "1:4",
        "name_es": "1:4",
        "name_hr": "1:4",
        "name_fr": "1:4"
    },
    "2354^live_match^8^5:1": {
        "name_en": "5:1",
        "name_aa": "5:1",
        "name_de": "5:1",
        "name_nl": "5:1",
        "name_pl": "5:1",
        "name_ro": "5:1",
        "name_ru": "5:1",
        "name_tr": "5:1",
        "name_se": "5:1",
        "name_el": "5:1",
        "name_es": "5:1",
        "name_hr": "5:1",
        "name_fr": "5:1"
    },
    "2217^live_match^8^0:4": {
        "name_en": "0:4",
        "name_aa": "0:4",
        "name_de": "0:4",
        "name_nl": "0:4",
        "name_pl": "0:4",
        "name_ro": "0:4",
        "name_ru": "0:4",
        "name_tr": "0:4",
        "name_se": "0:4",
        "name_el": "0:4",
        "name_es": "0:4",
        "name_hr": "0:4",
        "name_fr": "0:4"
    },
    "2227^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "2197^live_match^8^no": {
        "name_en": "no",
        "name_aa": "no",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "no",
        "name_ro": "no",
        "name_ru": "no",
        "name_tr": "no",
        "name_se": "no",
        "name_el": "no",
        "name_es": "no",
        "name_hr": "Ne",
        "name_fr": "no"
    },
    "2316^live_match^8^7:10": {
        "name_en": "7:10",
        "name_aa": "7:10",
        "name_de": "7:10",
        "name_nl": "7:10",
        "name_pl": "7:10",
        "name_ro": "7:10",
        "name_ru": "7:10",
        "name_tr": "7:10",
        "name_se": "7:10",
        "name_el": "7:10",
        "name_es": "7:10",
        "name_hr": "7:10",
        "name_fr": "7:10"
    },
    "2005^live_match^8^home_penalties": {
        "name_en": "Home penalties",
        "name_aa": "Home penalties",
        "name_de": "Heim elfmeter",
        "name_nl": "Thuisstraffen",
        "name_pl": "Home penalties",
        "name_ro": "Home penalties",
        "name_ru": "Home penalties",
        "name_tr": "Home penalties",
        "name_se": "Home penalties",
        "name_el": "Home penalties",
        "name_es": "Home penalties",
        "name_hr": "Home penalties",
        "name_fr": "Home penalties"
    },
    "2261^live_match^8^2:3": {
        "name_en": "2:3",
        "name_aa": "2:3",
        "name_de": "2:3",
        "name_nl": "2:3",
        "name_pl": "2:3",
        "name_ro": "2:3",
        "name_ru": "2:3",
        "name_tr": "2:3",
        "name_se": "2:3",
        "name_el": "2:3",
        "name_es": "2:3",
        "name_hr": "2:3",
        "name_fr": "2:3"
    },
    "76^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2100^live_match^8^no": {
        "name_en": "no",
        "name_aa": "no",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "no",
        "name_ro": "no",
        "name_ru": "no",
        "name_tr": "no",
        "name_se": "no",
        "name_el": "no",
        "name_es": "no",
        "name_hr": "Ne",
        "name_fr": "no"
    },
    "2209^live_match^8^151-175": {
        "name_en": "151-175",
        "name_aa": "151-175",
        "name_de": "151-175",
        "name_nl": "151-175",
        "name_pl": "151-175",
        "name_ro": "151-175",
        "name_ru": "151-175",
        "name_tr": "151-175",
        "name_se": "151-175",
        "name_el": "151-175",
        "name_es": "151-175",
        "name_hr": "151-175",
        "name_fr": "151-175"
    },
    "2080^live_match^8^none": {
        "name_en": "none",
        "name_aa": "none",
        "name_de": "Keiner",
        "name_nl": "Geen",
        "name_pl": "none",
        "name_ro": "none",
        "name_ru": "none",
        "name_tr": "none",
        "name_se": "none",
        "name_el": "none",
        "name_es": "none",
        "name_hr": "Nitko",
        "name_fr": "none"
    },
    "2245^live_match^8^away": {
        "name_en": "away",
        "name_aa": "away",
        "name_de": "Auswärts",
        "name_nl": "Uit",
        "name_pl": "away",
        "name_ro": "away",
        "name_ru": "away",
        "name_tr": "away",
        "name_se": "away",
        "name_el": "away",
        "name_es": "away",
        "name_hr": "Gostujući",
        "name_fr": "away"
    },
    "1108^live_match^8^8:10": {
        "name_en": "8:10",
        "name_aa": "8:10",
        "name_de": "8:10",
        "name_nl": "8:10",
        "name_pl": "8:10",
        "name_ro": "8:10",
        "name_ru": "8:10",
        "name_tr": "8:10",
        "name_se": "8:10",
        "name_el": "8:10",
        "name_es": "8:10",
        "name_hr": "8:10",
        "name_fr": "8:10"
    },
    "43^live_match^8^over": {
        "name_en": "Over",
        "name_aa": "Over",
        "name_de": "Über",
        "name_nl": "Over",
        "name_pl": "Over",
        "name_ro": "Over",
        "name_ru": "Over",
        "name_tr": "Üst",
        "name_se": "Over",
        "name_el": "Over",
        "name_es": "Over",
        "name_hr": "Više",
        "name_fr": "p"
    },
    "1108^live_match^8^13:2": {
        "name_en": "13:2",
        "name_aa": "13:2",
        "name_de": "13:2",
        "name_nl": "13:2",
        "name_pl": "13:2",
        "name_ro": "13:2",
        "name_ru": "13:2",
        "name_tr": "13:2",
        "name_se": "13:2",
        "name_el": "13:2",
        "name_es": "13:2",
        "name_hr": "13:2",
        "name_fr": "13:2"
    },
    "1202^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2307^live_match^8^4:2": {
        "name_en": "4:2",
        "name_aa": "4:2",
        "name_de": "4:2",
        "name_nl": "4:2",
        "name_pl": "4:2",
        "name_ro": "4:2",
        "name_ru": "4:2",
        "name_tr": "4:2",
        "name_se": "4:2",
        "name_el": "4:2",
        "name_es": "4:2",
        "name_hr": "4:2",
        "name_fr": "4:2"
    },
    "1066^live_match^8^2:0": {
        "name_en": "2:0",
        "name_aa": "2:0",
        "name_de": "2:0",
        "name_nl": "2:0",
        "name_pl": "2:0",
        "name_ro": "2:0",
        "name_ru": "2:0",
        "name_tr": "2:0",
        "name_se": "2:0",
        "name_el": "2:0",
        "name_es": "2:0",
        "name_hr": "2:0",
        "name_fr": "2:0"
    },
    "2155^live_match^8^away_and_under": {
        "name_en": "away and under",
        "name_aa": "away and under",
        "name_de": "Auswärt und Unter",
        "name_nl": "Uit and Minder dan",
        "name_pl": "away and under",
        "name_ro": "away and under",
        "name_ru": "away and under",
        "name_tr": "away and under",
        "name_se": "away and under",
        "name_el": "away and under",
        "name_es": "away and under",
        "name_hr": "Gostujući i manje",
        "name_fr": "away and under"
    },
    "116^live_match^8^1:1": {
        "name_en": "1:1",
        "name_aa": "1:1",
        "name_de": "1:1",
        "name_nl": "1:1",
        "name_pl": "1:1",
        "name_ro": "1:1",
        "name_ru": "1:1",
        "name_tr": "1:1",
        "name_se": "1:1",
        "name_el": "1:1",
        "name_es": "1:1",
        "name_hr": "1:1",
        "name_fr": "1:1"
    },
    "2071^live_match^8^away_5-7": {
        "name_en": "away 5-7",
        "name_aa": "away 5-7",
        "name_de": "Auswärt 5-7",
        "name_nl": "Weg 5-7",
        "name_pl": "away 5-7",
        "name_ro": "away 5-7",
        "name_ru": "away 5-7",
        "name_tr": "away 5-7",
        "name_se": "away 5-7",
        "name_el": "away 5-7",
        "name_es": "away 5-7",
        "name_hr": "Gostujuči 5-7",
        "name_fr": "away 5-7"
    },
    "29^live_match^8^14:0": {
        "name_en": "14:0",
        "name_aa": "14:0",
        "name_de": "14:0",
        "name_nl": "14:0",
        "name_pl": "14:0",
        "name_ro": "14:0",
        "name_ru": "14:0",
        "name_tr": "14:0",
        "name_se": "14:0",
        "name_el": "14:0",
        "name_es": "14:0",
        "name_hr": "14:0",
        "name_fr": "14:0"
    },
    "2332^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2208^live_match^8^31-32": {
        "name_en": "31-32",
        "name_aa": "31-32",
        "name_de": "31-32",
        "name_nl": "31-32",
        "name_pl": "31-32",
        "name_ro": "31-32",
        "name_ru": "31-32",
        "name_tr": "31-32",
        "name_se": "31-32",
        "name_el": "31-32",
        "name_es": "31-32",
        "name_hr": "31-32",
        "name_fr": "31-32"
    },
    "359^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "1066^live_match^8^other": {
        "name_en": "other",
        "name_aa": "other",
        "name_de": "andere",
        "name_nl": "andere",
        "name_pl": "other",
        "name_ro": "other",
        "name_ru": "other",
        "name_tr": "diğerleri",
        "name_se": "other",
        "name_el": "other",
        "name_es": "other",
        "name_hr": "Ostali",
        "name_fr": "autre"
    },
    "2092^live_match^8^no": {
        "name_en": "no",
        "name_aa": "no",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "no",
        "name_ro": "no",
        "name_ru": "no",
        "name_tr": "no",
        "name_se": "no",
        "name_el": "no",
        "name_es": "no",
        "name_hr": "Ne",
        "name_fr": "no"
    },
    "2050^live_match^6^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "1368^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2061^live_match^8^away_3-4": {
        "name_en": "away 3-4",
        "name_aa": "away 3-4",
        "name_de": "Auswärt 3-4",
        "name_nl": "Weg 3-4",
        "name_pl": "away 3-4",
        "name_ro": "away 3-4",
        "name_ru": "away 3-4",
        "name_tr": "away 3-4",
        "name_se": "away 3-4",
        "name_el": "away 3-4",
        "name_es": "away 3-4",
        "name_hr": "Gostujuči 3-4",
        "name_fr": "away 3-4"
    },
    "2074^live_match^8^15:9": {
        "name_en": "15:9",
        "name_aa": "15:9",
        "name_de": "15:9",
        "name_nl": "15:9",
        "name_pl": "15:9",
        "name_ro": "15:9",
        "name_ru": "15:9",
        "name_tr": "15:9",
        "name_se": "15:9",
        "name_el": "15:9",
        "name_es": "15:9",
        "name_hr": "15:9",
        "name_fr": "15:9"
    },
    "2111^live_match^8^away_5+": {
        "name_en": "away 5+",
        "name_aa": "away 5+",
        "name_de": "Auswärt 5+",
        "name_nl": "Weg 5+",
        "name_pl": "away 5+",
        "name_ro": "away 5+",
        "name_ru": "away 5+",
        "name_tr": "away 5+",
        "name_se": "away 5+",
        "name_el": "away 5+",
        "name_es": "away 5+",
        "name_hr": "Gostujuči 5+",
        "name_fr": "away 5+"
    },
    "2034^live_match^8^away_and_under": {
        "name_en": "away and under",
        "name_aa": "away and under",
        "name_de": "Auswärt und Unter",
        "name_nl": "Uit and Minder dan",
        "name_pl": "away and under",
        "name_ro": "away and under",
        "name_ru": "away and under",
        "name_tr": "away and under",
        "name_se": "away and under",
        "name_el": "away and under",
        "name_es": "away and under",
        "name_hr": "Gostujući i manje",
        "name_fr": "away and under"
    },
    "1276^live_match^8^under": {
        "name_en": "Under",
        "name_aa": "u",
        "name_de": "Unter",
        "name_nl": "u",
        "name_pl": "u",
        "name_ro": "u",
        "name_ru": "u",
        "name_tr": "Alt",
        "name_se": "u",
        "name_el": "u",
        "name_es": "u",
        "name_hr": "Manje",
        "name_fr": "m"
    },
    "2207^live_match^8^away_1-12": {
        "name_en": "away 1-12",
        "name_aa": "away 1-12",
        "name_de": "Auswärt 1-12",
        "name_nl": "Weg 1-12",
        "name_pl": "away 1-12",
        "name_ro": "away 1-12",
        "name_ru": "away 1-12",
        "name_tr": "away 1-12",
        "name_se": "away 1-12",
        "name_el": "away 1-12",
        "name_es": "away 1-12",
        "name_hr": "Gostujuči 1-12",
        "name_fr": "away 1-12"
    },
    "2261^live_match^8^6:0": {
        "name_en": "6:0",
        "name_aa": "6:0",
        "name_de": "6:0",
        "name_nl": "6:0",
        "name_pl": "6:0",
        "name_ro": "6:0",
        "name_ru": "6:0",
        "name_tr": "6:0",
        "name_se": "6:0",
        "name_el": "6:0",
        "name_es": "6:0",
        "name_hr": "6:0",
        "name_fr": "6:0"
    },
    "26^live_match^8^0:7": {
        "name_en": "0:7",
        "name_aa": "0:7",
        "name_de": "0:7",
        "name_nl": "0:7",
        "name_pl": "0:7",
        "name_ro": "0:7",
        "name_ru": "0:7",
        "name_tr": "0:7",
        "name_se": "0:7",
        "name_el": "0:7",
        "name_es": "0:7",
        "name_hr": "0:7",
        "name_fr": "0:7"
    },
    "2354^live_match^8^1:2": {
        "name_en": "1:2",
        "name_aa": "1:2",
        "name_de": "1:2",
        "name_nl": "1:2",
        "name_pl": "1:2",
        "name_ro": "1:2",
        "name_ru": "1:2",
        "name_tr": "1:2",
        "name_se": "1:2",
        "name_el": "1:2",
        "name_es": "1:2",
        "name_hr": "1:2",
        "name_fr": "1:2"
    },
    "2072^live_match^8^13:21": {
        "name_en": "13:21",
        "name_aa": "13:21",
        "name_de": "13:21",
        "name_nl": "13:21",
        "name_pl": "13:21",
        "name_ro": "13:21",
        "name_ru": "13:21",
        "name_tr": "13:21",
        "name_se": "13:21",
        "name_el": "13:21",
        "name_es": "13:21",
        "name_hr": "13:21",
        "name_fr": "13:21"
    },
    "2354^live_match^8^9:4": {
        "name_en": "9:4",
        "name_aa": "9:4",
        "name_de": "9:4",
        "name_nl": "9:4",
        "name_pl": "9:4",
        "name_ro": "9:4",
        "name_ru": "9:4",
        "name_tr": "9:4",
        "name_se": "9:4",
        "name_el": "9:4",
        "name_es": "9:4",
        "name_hr": "9:4",
        "name_fr": "9:4"
    },
    "2261^live_match^8^4:8": {
        "name_en": "4:8",
        "name_aa": "4:8",
        "name_de": "4:8",
        "name_nl": "4:8",
        "name_pl": "4:8",
        "name_ro": "4:8",
        "name_ru": "4:8",
        "name_tr": "4:8",
        "name_se": "4:8",
        "name_el": "4:8",
        "name_es": "4:8",
        "name_hr": "4:8",
        "name_fr": "4:8"
    },
    "2004^live_match^8^own_goal": {
        "name_en": "Own Goal",
        "name_aa": "Own Goal",
        "name_de": "Eigentor",
        "name_nl": "Eigen doelpunt",
        "name_pl": "Own Goal",
        "name_ro": "Own Goal",
        "name_ru": "Own Goal",
        "name_tr": "Own Goal",
        "name_se": "Own Goal",
        "name_el": "Own Goal",
        "name_es": "Own Goal",
        "name_hr": "Vlastiti gola",
        "name_fr": "Own Goal"
    },
    "2002^live_match^6^x": {
        "name_en": "x",
        "name_aa": "x",
        "name_de": "x",
        "name_nl": "x",
        "name_pl": "x",
        "name_ro": "x",
        "name_ru": "x",
        "name_tr": "x",
        "name_se": "x",
        "name_el": "x",
        "name_es": "x",
        "name_hr": "x",
        "name_fr": "x"
    },
    "518^live_match^8^no": {
        "name_en": "No",
        "name_aa": "No",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "No",
        "name_ro": "No",
        "name_ru": "No",
        "name_tr": "hayır",
        "name_se": "No",
        "name_el": "No",
        "name_es": "No",
        "name_hr": "Ne",
        "name_fr": "non"
    },
    "2261^live_match^8^7:1": {
        "name_en": "7:1",
        "name_aa": "7:1",
        "name_de": "7:1",
        "name_nl": "7:1",
        "name_pl": "7:1",
        "name_ro": "7:1",
        "name_ru": "7:1",
        "name_tr": "7:1",
        "name_se": "7:1",
        "name_el": "7:1",
        "name_es": "7:1",
        "name_hr": "7:1",
        "name_fr": "7:1"
    },
    "29^live_match^8^6:5": {
        "name_en": "6:5",
        "name_aa": "6:5",
        "name_de": "6:5",
        "name_nl": "6:5",
        "name_pl": "6:5",
        "name_ro": "6:5",
        "name_ru": "6:5",
        "name_tr": "6:5",
        "name_se": "6:5",
        "name_el": "6:5",
        "name_es": "6:5",
        "name_hr": "6:5",
        "name_fr": "6:5"
    },
    "2242^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2117^live_match^8^no": {
        "name_en": "no",
        "name_aa": "no",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "no",
        "name_ro": "no",
        "name_ru": "no",
        "name_tr": "no",
        "name_se": "no",
        "name_el": "no",
        "name_es": "no",
        "name_hr": "Ne",
        "name_fr": "no"
    },
    "2206^live_match^8^aa": {
        "name_en": "AA",
        "name_aa": "AA",
        "name_de": "AA",
        "name_nl": "AA",
        "name_pl": "AA",
        "name_ro": "AA",
        "name_ru": "AA",
        "name_tr": "AA",
        "name_se": "AA",
        "name_el": "AA",
        "name_es": "AA",
        "name_hr": "AA",
        "name_fr": "AA"
    },
    "117^live_match^8^3:0": {
        "name_en": "3:0",
        "name_aa": "3:0",
        "name_de": "3:0",
        "name_nl": "3:0",
        "name_pl": "3:0",
        "name_ro": "3:0",
        "name_ru": "3:0",
        "name_tr": "3:0",
        "name_se": "3:0",
        "name_el": "3:0",
        "name_es": "3:0",
        "name_hr": "3:0",
        "name_fr": "3:0"
    },
    "2082^live_match^8^7:5_or_7:6": {
        "name_en": "7:5 or 7:6",
        "name_aa": "7:5 or 7:6",
        "name_de": "7:5 oder 7:6",
        "name_nl": "7:5 of 7:6",
        "name_pl": "7:5 or 7:6",
        "name_ro": "7:5 or 7:6",
        "name_ru": "7:5 or 7:6",
        "name_tr": "7:5 or 7:6",
        "name_se": "7:5 or 7:6",
        "name_el": "7:5 or 7:6",
        "name_es": "7:5 or 7:6",
        "name_hr": "7:5 or 7:6",
        "name_fr": "7:5 or 7:6"
    },
    "2071^live_match^8^home_12+": {
        "name_en": "home 12+",
        "name_aa": "home 12+",
        "name_de": "Heim 12+",
        "name_nl": "thuis 12+",
        "name_pl": "home 12+",
        "name_ro": "home 12+",
        "name_ru": "home 12+",
        "name_tr": "home 12+",
        "name_se": "home 12+",
        "name_el": "home 12+",
        "name_es": "home 12+",
        "name_hr": "Domači 12+",
        "name_fr": "home 12+"
    },
    "2316^live_match^8^10:7": {
        "name_en": "10:7",
        "name_aa": "10:7",
        "name_de": "10:7",
        "name_nl": "10:7",
        "name_pl": "10:7",
        "name_ro": "10:7",
        "name_ru": "10:7",
        "name_tr": "10:7",
        "name_se": "10:7",
        "name_el": "10:7",
        "name_es": "10:7",
        "name_hr": "10:7",
        "name_fr": "10:7"
    },
    "29^live_match^8^0:2": {
        "name_en": "0:2",
        "name_aa": "0:2",
        "name_de": "0:2",
        "name_nl": "0:2",
        "name_pl": "0:2",
        "name_ro": "0:2",
        "name_ru": "0:2",
        "name_tr": "0:2",
        "name_se": "0:2",
        "name_el": "0:2",
        "name_es": "0:2",
        "name_hr": "0:2",
        "name_fr": "0:2"
    },
    "1108^live_match^8^1:3": {
        "name_en": "1:3",
        "name_aa": "1:3",
        "name_de": "1:3",
        "name_nl": "1:3",
        "name_pl": "1:3",
        "name_ro": "1:3",
        "name_ru": "1:3",
        "name_tr": "1:3",
        "name_se": "1:3",
        "name_el": "1:3",
        "name_es": "1:3",
        "name_hr": "1:3",
        "name_fr": "1:3"
    },
    "2347^live_match^8^yes": {
        "name_en": "yes",
        "name_aa": "yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "yes",
        "name_ro": "yes",
        "name_ru": "yes",
        "name_tr": "yes",
        "name_se": "yes",
        "name_el": "yes",
        "name_es": "yes",
        "name_hr": "Da",
        "name_fr": "yes"
    },
    "2354^live_match^8^4:12": {
        "name_en": "4:12",
        "name_aa": "4:12",
        "name_de": "4:12",
        "name_nl": "4:12",
        "name_pl": "4:12",
        "name_ro": "4:12",
        "name_ru": "4:12",
        "name_tr": "4:12",
        "name_se": "4:12",
        "name_el": "4:12",
        "name_es": "4:12",
        "name_hr": "4:12",
        "name_fr": "4:12"
    },
    "2184^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "2207^live_match^8^home_37-48": {
        "name_en": "home 37-48",
        "name_aa": "home 37-48",
        "name_de": "Heim 37-48",
        "name_nl": "Thuis 37-48",
        "name_pl": "home 37-48",
        "name_ro": "home 37-48",
        "name_ru": "home 37-48",
        "name_tr": "home 37-48",
        "name_se": "home 37-48",
        "name_el": "home 37-48",
        "name_es": "home 37-48",
        "name_hr": "Domači 37-48",
        "name_fr": "home 37-48"
    },
    "26^live_match^8^13:1": {
        "name_en": "13:1",
        "name_aa": "13:1",
        "name_de": "13:1",
        "name_nl": "13:1",
        "name_pl": "13:1",
        "name_ro": "13:1",
        "name_ru": "13:1",
        "name_tr": "13:1",
        "name_se": "13:1",
        "name_el": "13:1",
        "name_es": "13:1",
        "name_hr": "13:1",
        "name_fr": "13:1"
    },
    "1108^live_match^8^2:13": {
        "name_en": "2:13",
        "name_aa": "2:13",
        "name_de": "2:13",
        "name_nl": "2:13",
        "name_pl": "2:13",
        "name_ro": "2:13",
        "name_ru": "2:13",
        "name_tr": "2:13",
        "name_se": "2:13",
        "name_el": "2:13",
        "name_es": "2:13",
        "name_hr": "2:13",
        "name_fr": "2:13"
    },
    "954^live_match^6^x": {
        "name_en": "x",
        "name_aa": "x",
        "name_de": "x",
        "name_nl": "x",
        "name_pl": "x",
        "name_ro": "x",
        "name_ru": "x",
        "name_tr": "x",
        "name_se": "x",
        "name_el": "x",
        "name_es": "x",
        "name_hr": "x",
        "name_fr": "x"
    },
    "27^live_match^8^x2": {
        "name_en": "X2",
        "name_aa": "X2",
        "name_de": "X2",
        "name_nl": "1X",
        "name_pl": "X2",
        "name_ro": "X2",
        "name_ru": "X2",
        "name_tr": "X2",
        "name_se": "X2",
        "name_el": "X2",
        "name_es": "X2",
        "name_hr": "X2",
        "name_fr": "X2"
    },
    "2061^live_match^8^away_11+": {
        "name_en": "away 11+",
        "name_aa": "away 11+",
        "name_de": "Auswärt 11+",
        "name_nl": "Weg 11+",
        "name_pl": "away 11+",
        "name_ro": "away 11+",
        "name_ru": "away 11+",
        "name_tr": "away 11+",
        "name_se": "away 11+",
        "name_el": "away 11+",
        "name_es": "away 11+",
        "name_hr": "Gostujuči 11+",
        "name_fr": "away 11+"
    },
    "1108^live_match^8^0:10": {
        "name_en": "0:10",
        "name_aa": "0:10",
        "name_de": "0:10",
        "name_nl": "0:10",
        "name_pl": "0:10",
        "name_ro": "0:10",
        "name_ru": "0:10",
        "name_tr": "0:10",
        "name_se": "0:10",
        "name_el": "0:10",
        "name_es": "0:10",
        "name_hr": "0:10",
        "name_fr": "0:10"
    },
    "2212^live_match^8^away_13-24": {
        "name_en": "away 13-24",
        "name_aa": "away 13-24",
        "name_de": "Auswärt 13-24",
        "name_nl": "Weg 13-24",
        "name_pl": "away 13-24",
        "name_ro": "away 13-24",
        "name_ru": "away 13-24",
        "name_tr": "away 13-24",
        "name_se": "away 13-24",
        "name_el": "away 13-24",
        "name_es": "away 13-24",
        "name_hr": "Gostujuči 13-24",
        "name_fr": "away 13-24"
    },
    "1108^live_match^8^0:4": {
        "name_en": "0:4",
        "name_aa": "0:4",
        "name_de": "0:4",
        "name_nl": "0:4",
        "name_pl": "0:4",
        "name_ro": "0:4",
        "name_ru": "0:4",
        "name_tr": "0:4",
        "name_se": "0:4",
        "name_el": "0:4",
        "name_es": "0:4",
        "name_hr": "0:4",
        "name_fr": "0:4"
    },
    "1156^live_match^8^1:3": {
        "name_en": "1:3",
        "name_aa": "1:3",
        "name_de": "1:3",
        "name_nl": "1:3",
        "name_pl": "1:3",
        "name_ro": "1:3",
        "name_ru": "1:3",
        "name_tr": "1:3",
        "name_se": "1:3",
        "name_el": "1:3",
        "name_es": "1:3",
        "name_hr": "1:3",
        "name_fr": "1:3"
    },
    "26^live_match^8^8:3": {
        "name_en": "8:3",
        "name_aa": "8:3",
        "name_de": "8:3",
        "name_nl": "8:3",
        "name_pl": "8:3",
        "name_ro": "8:3",
        "name_ru": "8:3",
        "name_tr": "8:3",
        "name_se": "8:3",
        "name_el": "8:3",
        "name_es": "8:3",
        "name_hr": "8:3",
        "name_fr": "8:3"
    },
    "2153^live_match^8^home_5+": {
        "name_en": "home 5+",
        "name_aa": "home 5+",
        "name_de": "Heim 5+",
        "name_nl": "Thuis 5+",
        "name_pl": "home 5+",
        "name_ro": "home 5+",
        "name_ru": "home 5+",
        "name_tr": "home 5+",
        "name_se": "home 5+",
        "name_el": "home 5+",
        "name_es": "home 5+",
        "name_hr": "Domači 5+",
        "name_fr": "home 5+"
    },
    "2241^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "2072^live_match^8^21:11_or_better": {
        "name_en": "21:11 or better",
        "name_aa": "21:11 or better",
        "name_de": "21:11 oder besser",
        "name_nl": "21:11 of beter",
        "name_pl": "21:11 or better",
        "name_ro": "21:11 or better",
        "name_ru": "21:11 or better",
        "name_tr": "21:11 or better",
        "name_se": "21:11 or better",
        "name_el": "21:11 or better",
        "name_es": "21:11 or better",
        "name_hr": "21:11 or better",
        "name_fr": "21:11 or better"
    },
    "2076^live_match^8^15:11": {
        "name_en": "15:11",
        "name_aa": "15:11",
        "name_de": "15:11",
        "name_nl": "15:11",
        "name_pl": "15:11",
        "name_ro": "15:11",
        "name_ru": "15:11",
        "name_tr": "15:11",
        "name_se": "15:11",
        "name_el": "15:11",
        "name_es": "15:11",
        "name_hr": "15:11",
        "name_fr": "15:11"
    },
    "2105^live_match^8^neither": {
        "name_en": "neither",
        "name_aa": "neither",
        "name_de": "Keiner",
        "name_nl": "Geen van beide",
        "name_pl": "neither",
        "name_ro": "neither",
        "name_ru": "neither",
        "name_tr": "neither",
        "name_se": "neither",
        "name_el": "neither",
        "name_es": "neither",
        "name_hr": "Ni",
        "name_fr": "neither"
    },
    "2111^live_match^8^away_2": {
        "name_en": "away 2",
        "name_aa": "away 2",
        "name_de": "Auswärt 2",
        "name_nl": "Weg 2",
        "name_pl": "away 2",
        "name_ro": "away 2",
        "name_ru": "away 2",
        "name_tr": "away 2",
        "name_se": "away 2",
        "name_el": "away 2",
        "name_es": "away 2",
        "name_hr": "Gostujuči 2",
        "name_fr": "away 2"
    },
    "2053^live_match^6^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2180^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "2213^live_match^8^home_31-36": {
        "name_en": "home 31-36",
        "name_aa": "home 31-36",
        "name_de": "Heim 31-36",
        "name_nl": "Thuis 31-36",
        "name_pl": "home 31-36",
        "name_ro": "home 31-36",
        "name_ru": "home 31-36",
        "name_tr": "home 31-36",
        "name_se": "home 31-36",
        "name_el": "home 31-36",
        "name_es": "home 31-36",
        "name_hr": "Domači 31-36",
        "name_fr": "home 31-36"
    },
    "1206^live_match^8^black": {
        "name_en": "black",
        "name_aa": "black",
        "name_de": "schwarz",
        "name_nl": "zwart",
        "name_pl": "black",
        "name_ro": "black",
        "name_ru": "black",
        "name_tr": "siyah",
        "name_se": "black",
        "name_el": "black",
        "name_es": "black",
        "name_hr": "crna",
        "name_fr": "noir"
    },
    "2074^live_match^8^12:15": {
        "name_en": "12:15",
        "name_aa": "12:15",
        "name_de": "12:15",
        "name_nl": "12:15",
        "name_pl": "12:15",
        "name_ro": "12:15",
        "name_ru": "12:15",
        "name_tr": "12:15",
        "name_se": "12:15",
        "name_el": "12:15",
        "name_es": "12:15",
        "name_hr": "12:15",
        "name_fr": "12:15"
    },
    "47^live_match^8^over": {
        "name_en": "Over",
        "name_aa": "Over",
        "name_de": "Über",
        "name_nl": "Over",
        "name_pl": "Over",
        "name_ro": "Over",
        "name_ru": "Over",
        "name_tr": "Üst",
        "name_se": "Over",
        "name_el": "Over",
        "name_es": "Over",
        "name_hr": "Više",
        "name_fr": "p"
    },
    "26^live_match^8^12:0": {
        "name_en": "12:0",
        "name_aa": "12:0",
        "name_de": "12:0",
        "name_nl": "12:0",
        "name_pl": "12:0",
        "name_ro": "12:0",
        "name_ru": "12:0",
        "name_tr": "12:0",
        "name_se": "12:0",
        "name_el": "12:0",
        "name_es": "12:0",
        "name_hr": "12:0",
        "name_fr": "12:0"
    },
    "2070^live_match^8^away": {
        "name_en": "away",
        "name_aa": "away",
        "name_de": "Auswärts",
        "name_nl": "Uit",
        "name_pl": "away",
        "name_ro": "away",
        "name_ru": "away",
        "name_tr": "away",
        "name_se": "away",
        "name_el": "away",
        "name_es": "away",
        "name_hr": "Gostujući",
        "name_fr": "away"
    },
    "1108^live_match^8^13:3": {
        "name_en": "13:3",
        "name_aa": "13:3",
        "name_de": "13:3",
        "name_nl": "13:3",
        "name_pl": "13:3",
        "name_ro": "13:3",
        "name_ru": "13:3",
        "name_tr": "13:3",
        "name_se": "13:3",
        "name_el": "13:3",
        "name_es": "13:3",
        "name_hr": "13:3",
        "name_fr": "13:3"
    },
    "2177^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "2142^live_match^8^away_3-4": {
        "name_en": "away 3-4",
        "name_aa": "away 3-4",
        "name_de": "Auswärt 3-4",
        "name_nl": "Weg 3-4",
        "name_pl": "away 3-4",
        "name_ro": "away 3-4",
        "name_ru": "away 3-4",
        "name_tr": "away 3-4",
        "name_se": "away 3-4",
        "name_el": "away 3-4",
        "name_es": "away 3-4",
        "name_hr": "Gostujuči 3-4",
        "name_fr": "away 3-4"
    },
    "1108^live_match^8^14:1": {
        "name_en": "14:1",
        "name_aa": "14:1",
        "name_de": "14:1",
        "name_nl": "14:1",
        "name_pl": "14:1",
        "name_ro": "14:1",
        "name_ru": "14:1",
        "name_tr": "14:1",
        "name_se": "14:1",
        "name_el": "14:1",
        "name_es": "14:1",
        "name_hr": "14:1",
        "name_fr": "14:1"
    },
    "520^live_match^8^6:4": {
        "name_en": "6:4",
        "name_aa": "6:4",
        "name_de": "6:4",
        "name_nl": "6:4",
        "name_pl": "6:4",
        "name_ro": "6:4",
        "name_ru": "6:4",
        "name_tr": "6:4",
        "name_se": "6:4",
        "name_el": "6:4",
        "name_es": "6:4",
        "name_hr": "6:4",
        "name_fr": "6:4"
    },
    "2114^live_match^8^40:0": {
        "name_en": "40:0",
        "name_aa": "40:0",
        "name_de": "40:0",
        "name_nl": "40:0",
        "name_pl": "40:0",
        "name_ro": "40:0",
        "name_ru": "40:0",
        "name_tr": "40:0",
        "name_se": "40:0",
        "name_el": "40:0",
        "name_es": "40:0",
        "name_hr": "40:0",
        "name_fr": "40:0"
    },
    "84^live_match^8^over": {
        "name_en": "Over",
        "name_aa": "Over",
        "name_de": "Über",
        "name_nl": "Over",
        "name_pl": "Over",
        "name_ro": "Over",
        "name_ru": "Over",
        "name_tr": "Üst",
        "name_se": "Over",
        "name_el": "Over",
        "name_es": "Over",
        "name_hr": "Više",
        "name_fr": "p"
    },
    "29^live_match^8^11:0": {
        "name_en": "11:0",
        "name_aa": "11:0",
        "name_de": "11:0",
        "name_nl": "11:0",
        "name_pl": "11:0",
        "name_ro": "11:0",
        "name_ru": "11:0",
        "name_tr": "11:0",
        "name_se": "11:0",
        "name_el": "11:0",
        "name_es": "11:0",
        "name_hr": "11:0",
        "name_fr": "11:0"
    },
    "2261^live_match^8^3:2": {
        "name_en": "3:2",
        "name_aa": "3:2",
        "name_de": "3:2",
        "name_nl": "3:2",
        "name_pl": "3:2",
        "name_ro": "3:2",
        "name_ru": "3:2",
        "name_tr": "3:2",
        "name_se": "3:2",
        "name_el": "3:2",
        "name_es": "3:2",
        "name_hr": "3:2",
        "name_fr": "3:2"
    },
    "1108^live_match^8^8:3": {
        "name_en": "8:3",
        "name_aa": "8:3",
        "name_de": "8:3",
        "name_nl": "8:3",
        "name_pl": "8:3",
        "name_ro": "8:3",
        "name_ru": "8:3",
        "name_tr": "8:3",
        "name_se": "8:3",
        "name_el": "8:3",
        "name_es": "8:3",
        "name_hr": "8:3",
        "name_fr": "8:3"
    },
    "26^live_match^8^6:9": {
        "name_en": "6:9",
        "name_aa": "6:9",
        "name_de": "6:9",
        "name_nl": "6:9",
        "name_pl": "6:9",
        "name_ro": "6:9",
        "name_ru": "6:9",
        "name_tr": "6:9",
        "name_se": "6:9",
        "name_el": "6:9",
        "name_es": "6:9",
        "name_hr": "6:9",
        "name_fr": "6:9"
    },
    "1659^live_match^2^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2213^live_match^8^home_25-30": {
        "name_en": "home 25-30",
        "name_aa": "home 25-30",
        "name_de": "Heim 25-30",
        "name_nl": "Thuis 25-30",
        "name_pl": "home 25-30",
        "name_ro": "home 25-30",
        "name_ru": "home 25-30",
        "name_tr": "home 25-30",
        "name_se": "home 25-30",
        "name_el": "home 25-30",
        "name_es": "home 25-30",
        "name_hr": "Domači 25-30",
        "name_fr": "home 25-30"
    },
    "1527^live_match^8^yes": {
        "name_en": "Yes",
        "name_aa": "Yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "Yes",
        "name_ro": "Yes",
        "name_ru": "Yes",
        "name_tr": "evet",
        "name_se": "Yes",
        "name_el": "Yes",
        "name_es": "Yes",
        "name_hr": "Da",
        "name_fr": "oui"
    },
    "2124^live_match^8^even": {
        "name_en": "even",
        "name_aa": "even",
        "name_de": "Gerade",
        "name_nl": "Even",
        "name_pl": "even",
        "name_ro": "even",
        "name_ru": "even",
        "name_tr": "even",
        "name_se": "even",
        "name_el": "even",
        "name_es": "even",
        "name_hr": "Paran",
        "name_fr": "even"
    },
    "26^live_match^8^21:1": {
        "name_en": "21:1",
        "name_aa": "21:1",
        "name_de": "21:1",
        "name_nl": "21:1",
        "name_pl": "21:1",
        "name_ro": "21:1",
        "name_ru": "21:1",
        "name_tr": "21:1",
        "name_se": "21:1",
        "name_el": "21:1",
        "name_es": "21:1",
        "name_hr": "21:1",
        "name_fr": "21:1"
    },
    "2074^live_match^8^25:21": {
        "name_en": "25:21",
        "name_aa": "25:21",
        "name_de": "25:21",
        "name_nl": "25:21",
        "name_pl": "25:21",
        "name_ro": "25:21",
        "name_ru": "25:21",
        "name_tr": "25:21",
        "name_se": "25:21",
        "name_el": "25:21",
        "name_es": "25:21",
        "name_hr": "25:21",
        "name_fr": "25:21"
    },
    "2060^live_match^8^away_5-6": {
        "name_en": "away 5-6",
        "name_aa": "away 5-6",
        "name_de": "Auswärt 5-6",
        "name_nl": "Weg 5-6",
        "name_pl": "away 5-6",
        "name_ro": "away 5-6",
        "name_ru": "away 5-6",
        "name_tr": "away 5-6",
        "name_se": "away 5-6",
        "name_el": "away 5-6",
        "name_es": "away 5-6",
        "name_hr": "Gostujuči 5-6",
        "name_fr": "away 5-6"
    },
    "26^live_match^8^1:5": {
        "name_en": "1:5",
        "name_aa": "1:5",
        "name_de": "1:5",
        "name_nl": "1:5",
        "name_pl": "1:5",
        "name_ro": "1:5",
        "name_ru": "1:5",
        "name_tr": "1:5",
        "name_se": "1:5",
        "name_el": "1:5",
        "name_es": "1:5",
        "name_hr": "1:5",
        "name_fr": "1:5"
    },
    "2058^live_match^8^aa": {
        "name_en": "AA",
        "name_aa": "AA",
        "name_de": "AA",
        "name_nl": "AA",
        "name_pl": "AA",
        "name_ro": "AA",
        "name_ru": "AA",
        "name_tr": "AA",
        "name_se": "AA",
        "name_el": "AA",
        "name_es": "AA",
        "name_hr": "AA",
        "name_fr": "AA"
    },
    "2261^live_match^8^6:8": {
        "name_en": "6:8",
        "name_aa": "6:8",
        "name_de": "6:8",
        "name_nl": "6:8",
        "name_pl": "6:8",
        "name_ro": "6:8",
        "name_ru": "6:8",
        "name_tr": "6:8",
        "name_se": "6:8",
        "name_el": "6:8",
        "name_es": "6:8",
        "name_hr": "6:8",
        "name_fr": "6:8"
    },
    "2353^live_match^8^0-9": {
        "name_en": "away 0-9",
        "name_aa": "away 0-9",
        "name_de": "Auswärt 0-9",
        "name_nl": "Weg 0-9",
        "name_pl": "away 0-9",
        "name_ro": "away 0-9",
        "name_ru": "away 0-9",
        "name_tr": "away 0-9",
        "name_se": "away 0-9",
        "name_el": "away 0-9",
        "name_es": "away 0-9",
        "name_hr": "Gostujuči 0-9",
        "name_fr": "away 0-9"
    },
    "2355^live_match^8^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2316^live_match^8^10:2": {
        "name_en": "10:2",
        "name_aa": "10:2",
        "name_de": "10:2",
        "name_nl": "10:2",
        "name_pl": "10:2",
        "name_ro": "10:2",
        "name_ru": "10:2",
        "name_tr": "10:2",
        "name_se": "10:2",
        "name_el": "10:2",
        "name_es": "10:2",
        "name_hr": "10:2",
        "name_fr": "10:2"
    },
    "2354^live_match^8^13:4": {
        "name_en": "13:4",
        "name_aa": "13:4",
        "name_de": "13:4",
        "name_nl": "13:4",
        "name_pl": "13:4",
        "name_ro": "13:4",
        "name_ru": "13:4",
        "name_tr": "13:4",
        "name_se": "13:4",
        "name_el": "13:4",
        "name_es": "13:4",
        "name_hr": "13:4",
        "name_fr": "13:4"
    },
    "44^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "61^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "101^live_match^6^x": {
        "name_en": "x",
        "name_aa": "x",
        "name_de": "x",
        "name_nl": "x",
        "name_pl": "x",
        "name_ro": "x",
        "name_ru": "x",
        "name_tr": "x",
        "name_se": "x",
        "name_el": "x",
        "name_es": "x",
        "name_hr": "x",
        "name_fr": "x"
    },
    "2062^live_match^8^tie": {
        "name_en": "tie",
        "name_aa": "tie",
        "name_de": "Unentschieden",
        "name_nl": "Gelijkspel",
        "name_pl": "tie",
        "name_ro": "tie",
        "name_ru": "tie",
        "name_tr": "tie",
        "name_se": "tie",
        "name_el": "tie",
        "name_es": "tie",
        "name_hr": "tie",
        "name_fr": "tie"
    },
    "1032^live_match^8^4": {
        "name_en": "4",
        "name_aa": "4",
        "name_de": "4",
        "name_nl": "4",
        "name_pl": "4",
        "name_ro": "4",
        "name_ru": "4",
        "name_tr": "4",
        "name_se": "4",
        "name_el": "4",
        "name_es": "4",
        "name_hr": "4",
        "name_fr": "4"
    },
    "2261^live_match^8^3:5": {
        "name_en": "3:5",
        "name_aa": "3:5",
        "name_de": "3:5",
        "name_nl": "3:5",
        "name_pl": "3:5",
        "name_ro": "3:5",
        "name_ru": "3:5",
        "name_tr": "3:5",
        "name_se": "3:5",
        "name_el": "3:5",
        "name_es": "3:5",
        "name_hr": "3:5",
        "name_fr": "3:5"
    },
    "38^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2074^live_match^8^25:18": {
        "name_en": "25:18",
        "name_aa": "25:18",
        "name_de": "25:18",
        "name_nl": "25:18",
        "name_pl": "25:18",
        "name_ro": "25:18",
        "name_ru": "25:18",
        "name_tr": "25:18",
        "name_se": "25:18",
        "name_el": "25:18",
        "name_es": "25:18",
        "name_hr": "25:18",
        "name_fr": "25:18"
    },
    "2073^live_match^8^25:20": {
        "name_en": "25:20",
        "name_aa": "25:20",
        "name_de": "25:20",
        "name_nl": "25:20",
        "name_pl": "25:20",
        "name_ro": "25:20",
        "name_ru": "25:20",
        "name_tr": "25:20",
        "name_se": "25:20",
        "name_el": "25:20",
        "name_es": "25:20",
        "name_hr": "25:20",
        "name_fr": "25:20"
    },
    "2354^live_match^8^12:4": {
        "name_en": "12:4",
        "name_aa": "12:4",
        "name_de": "12:4",
        "name_nl": "12:4",
        "name_pl": "12:4",
        "name_ro": "12:4",
        "name_ru": "12:4",
        "name_tr": "12:4",
        "name_se": "12:4",
        "name_el": "12:4",
        "name_es": "12:4",
        "name_hr": "12:4",
        "name_fr": "12:4"
    },
    "2072^live_match^8^11:21_or_better": {
        "name_en": "11:21 or better",
        "name_aa": "11:21 or better",
        "name_de": "11:21 oder besser",
        "name_nl": "11:21 of beter",
        "name_pl": "11:21 or better",
        "name_ro": "11:21 or better",
        "name_ru": "11:21 or better",
        "name_tr": "11:21 or better",
        "name_se": "11:21 or better",
        "name_el": "11:21 or better",
        "name_es": "11:21 or better",
        "name_hr": "11:21 or better",
        "name_fr": "11:21 or better"
    },
    "2074^live_match^8^23:25": {
        "name_en": "23:25",
        "name_aa": "23:25",
        "name_de": "23:25",
        "name_nl": "23:25",
        "name_pl": "23:25",
        "name_ro": "23:25",
        "name_ru": "23:25",
        "name_tr": "23:25",
        "name_se": "23:25",
        "name_el": "23:25",
        "name_es": "23:25",
        "name_hr": "23:25",
        "name_fr": "23:25"
    },
    "2111^live_match^8^home_1": {
        "name_en": "home 1",
        "name_aa": "home 1",
        "name_de": "Heim 1",
        "name_nl": "Thuis 1",
        "name_pl": "home 1",
        "name_ro": "home 1",
        "name_ru": "home 1",
        "name_tr": "home 1",
        "name_se": "home 1",
        "name_el": "home 1",
        "name_es": "home 1",
        "name_hr": "Domači 1",
        "name_fr": "home 1"
    },
    "2221^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "5^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2305^live_match^8^7:5": {
        "name_en": "7:5",
        "name_aa": "7:5",
        "name_de": "7:5",
        "name_nl": "7:5",
        "name_pl": "7:5",
        "name_ro": "7:5",
        "name_ru": "7:5",
        "name_tr": "7:5",
        "name_se": "7:5",
        "name_el": "7:5",
        "name_es": "7:5",
        "name_hr": "7:5",
        "name_fr": "7:5"
    },
    "94^live_match^8^odd": {
        "name_en": "odd",
        "name_aa": "odd",
        "name_de": "Ungerade",
        "name_nl": "oneven",
        "name_pl": "odd",
        "name_ro": "odd",
        "name_ru": "odd",
        "name_tr": "tek",
        "name_se": "odd",
        "name_el": "odd",
        "name_es": "odd",
        "name_hr": "Neparan",
        "name_fr": "impair"
    },
    "2063^live_match^8^away_1-2": {
        "name_en": "away 1-2",
        "name_aa": "away 1-2",
        "name_de": "Auswärt 1-2",
        "name_nl": "Weg 1-2",
        "name_pl": "away 1-2",
        "name_ro": "away 1-2",
        "name_ru": "away 1-2",
        "name_tr": "away 1-2",
        "name_se": "away 1-2",
        "name_el": "away 1-2",
        "name_es": "away 1-2",
        "name_hr": "Gostujujuči 1-2",
        "name_fr": "away 1-2"
    },
    "2354^live_match^8^13:3": {
        "name_en": "13:3",
        "name_aa": "13:3",
        "name_de": "13:3",
        "name_nl": "13:3",
        "name_pl": "13:3",
        "name_ro": "13:3",
        "name_ru": "13:3",
        "name_tr": "13:3",
        "name_se": "13:3",
        "name_el": "13:3",
        "name_es": "13:3",
        "name_hr": "13:3",
        "name_fr": "13:3"
    },
    "1372^live_match^6^x": {
        "name_en": "x",
        "name_aa": "x",
        "name_de": "x",
        "name_nl": "x",
        "name_pl": "x",
        "name_ro": "x",
        "name_ru": "x",
        "name_tr": "x",
        "name_se": "x",
        "name_el": "x",
        "name_es": "x",
        "name_hr": "x",
        "name_fr": "x"
    },
    "1108^live_match^8^2:9": {
        "name_en": "2:9",
        "name_aa": "2:9",
        "name_de": "2:9",
        "name_nl": "2:9",
        "name_pl": "2:9",
        "name_ro": "2:9",
        "name_ru": "2:9",
        "name_tr": "2:9",
        "name_se": "2:9",
        "name_el": "2:9",
        "name_es": "2:9",
        "name_hr": "2:9",
        "name_fr": "2:9"
    },
    "2074^live_match^8^22:25": {
        "name_en": "22:25",
        "name_aa": "22:25",
        "name_de": "22:25",
        "name_nl": "22:25",
        "name_pl": "22:25",
        "name_ro": "22:25",
        "name_ru": "22:25",
        "name_tr": "22:25",
        "name_se": "22:25",
        "name_el": "22:25",
        "name_es": "22:25",
        "name_hr": "22:25",
        "name_fr": "22:25"
    },
    "1244^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2234^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "2060^live_match^8^away_9-10": {
        "name_en": "away 9-10",
        "name_aa": "away 9-10",
        "name_de": "Auswärt 9-10",
        "name_nl": "Weg 9-10",
        "name_pl": "away 9-10",
        "name_ro": "away 9-10",
        "name_ru": "away 9-10",
        "name_tr": "away 9-10",
        "name_se": "away 9-10",
        "name_el": "away 9-10",
        "name_es": "away 9-10",
        "name_hr": "Gostujuči 9-10",
        "name_fr": "away 9-10"
    },
    "512^live_match^8^yes": {
        "name_en": "Yes",
        "name_aa": "Yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "Yes",
        "name_ro": "Yes",
        "name_ru": "Yes",
        "name_tr": "evet",
        "name_se": "Yes",
        "name_el": "Yes",
        "name_es": "Yes",
        "name_hr": "Da",
        "name_fr": "oui"
    },
    "2331^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "26^live_match^8^3:4": {
        "name_en": "3:4",
        "name_aa": "3:4",
        "name_de": "3:4",
        "name_nl": "3:4",
        "name_pl": "3:4",
        "name_ro": "3:4",
        "name_ru": "3:4",
        "name_tr": "3:4",
        "name_se": "3:4",
        "name_el": "3:4",
        "name_es": "3:4",
        "name_hr": "3:4",
        "name_fr": "3:4"
    },
    "1064^live_match^8^over": {
        "name_en": "Over",
        "name_aa": "Over",
        "name_de": "Über",
        "name_nl": "Over",
        "name_pl": "Over",
        "name_ro": "Over",
        "name_ru": "Over",
        "name_tr": "Üst",
        "name_se": "Over",
        "name_el": "Over",
        "name_es": "Over",
        "name_hr": "Više",
        "name_fr": "p"
    },
    "26^live_match^8^5:2": {
        "name_en": "5:2",
        "name_aa": "5:2",
        "name_de": "5:2",
        "name_nl": "5:2",
        "name_pl": "5:2",
        "name_ro": "5:2",
        "name_ru": "5:2",
        "name_tr": "5:2",
        "name_se": "5:2",
        "name_el": "5:2",
        "name_es": "5:2",
        "name_hr": "5:2",
        "name_fr": "5:2"
    },
    "351^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2322^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "2261^live_match^8^4:3": {
        "name_en": "4:3",
        "name_aa": "4:3",
        "name_de": "4:3",
        "name_nl": "4:3",
        "name_pl": "4:3",
        "name_ro": "4:3",
        "name_ru": "4:3",
        "name_tr": "4:3",
        "name_se": "4:3",
        "name_el": "4:3",
        "name_es": "4:3",
        "name_hr": "4:3",
        "name_fr": "4:3"
    },
    "1108^live_match^8^1:9": {
        "name_en": "1:9",
        "name_aa": "1:9",
        "name_de": "1:9",
        "name_nl": "1:9",
        "name_pl": "1:9",
        "name_ro": "1:9",
        "name_ru": "1:9",
        "name_tr": "1:9",
        "name_se": "1:9",
        "name_el": "1:9",
        "name_es": "1:9",
        "name_hr": "1:9",
        "name_fr": "1:9"
    },
    "2065^live_match^6^other": {
        "name_en": "other",
        "name_aa": "other",
        "name_de": "Sonstig",
        "name_nl": "Andere",
        "name_pl": "other",
        "name_ro": "other",
        "name_ru": "other",
        "name_tr": "other",
        "name_se": "other",
        "name_el": "other",
        "name_es": "other",
        "name_hr": "Ostali",
        "name_fr": "other"
    },
    "2232^live_match^6^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "1366^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2207^live_match^8^home_73+": {
        "name_en": "home 73+",
        "name_aa": "home 73+",
        "name_de": "Heim 73+",
        "name_nl": "Thuis 73+",
        "name_pl": "home 73+",
        "name_ro": "home 73+",
        "name_ru": "home 73+",
        "name_tr": "home 73+",
        "name_se": "home 73+",
        "name_el": "home 73+",
        "name_es": "home 73+",
        "name_hr": "Domači 73+",
        "name_fr": "home 73+"
    },
    "1108^live_match^8^5:6": {
        "name_en": "5:6",
        "name_aa": "5:6",
        "name_de": "5:6",
        "name_nl": "5:6",
        "name_pl": "5:6",
        "name_ro": "5:6",
        "name_ru": "5:6",
        "name_tr": "5:6",
        "name_se": "5:6",
        "name_el": "5:6",
        "name_es": "5:6",
        "name_hr": "5:6",
        "name_fr": "5:6"
    },
    "2139^live_match^8^even": {
        "name_en": "even",
        "name_aa": "even",
        "name_de": "Gerade",
        "name_nl": "even",
        "name_pl": "even",
        "name_ro": "even",
        "name_ru": "even",
        "name_tr": "even",
        "name_se": "even",
        "name_el": "even",
        "name_es": "even",
        "name_hr": "Paran",
        "name_fr": "even"
    },
    "2209^live_match^8^0-150": {
        "name_en": "0-150",
        "name_aa": "0-150",
        "name_de": "0-150",
        "name_nl": "0-150",
        "name_pl": "0-150",
        "name_ro": "0-150",
        "name_ru": "0-150",
        "name_tr": "0-150",
        "name_se": "0-150",
        "name_el": "0-150",
        "name_es": "0-150",
        "name_hr": "0-150",
        "name_fr": "0-150"
    },
    "60^live_match^8^even": {
        "name_en": "even",
        "name_aa": "even",
        "name_de": "Gerade",
        "name_nl": "even",
        "name_pl": "even",
        "name_ro": "even",
        "name_ru": "even",
        "name_tr": "çift",
        "name_se": "even",
        "name_el": "even",
        "name_es": "even",
        "name_hr": "Paran",
        "name_fr": "pair"
    },
    "1662^live_match^2^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2075^live_match^8^23:25": {
        "name_en": "23:25",
        "name_aa": "23:25",
        "name_de": "23:25",
        "name_nl": "23:25",
        "name_pl": "23:25",
        "name_ro": "23:25",
        "name_ru": "23:25",
        "name_tr": "23:25",
        "name_se": "23:25",
        "name_el": "23:25",
        "name_es": "23:25",
        "name_hr": "23:25",
        "name_fr": "23:25"
    },
    "1108^live_match^8^10:4": {
        "name_en": "10:4",
        "name_aa": "10:4",
        "name_de": "10:4",
        "name_nl": "10:4",
        "name_pl": "10:4",
        "name_ro": "10:4",
        "name_ru": "10:4",
        "name_tr": "10:4",
        "name_se": "10:4",
        "name_el": "10:4",
        "name_es": "10:4",
        "name_hr": "10:4",
        "name_fr": "10:4"
    },
    "2074^live_match^8^15:13": {
        "name_en": "15:13",
        "name_aa": "15:13",
        "name_de": "15:13",
        "name_nl": "15:13",
        "name_pl": "15:13",
        "name_ro": "15:13",
        "name_ru": "15:13",
        "name_tr": "15:13",
        "name_se": "15:13",
        "name_el": "15:13",
        "name_es": "15:13",
        "name_hr": "15:13",
        "name_fr": "15:13"
    },
    "29^live_match^8^2:11": {
        "name_en": "2:11",
        "name_aa": "2:11",
        "name_de": "2:11",
        "name_nl": "2:11",
        "name_pl": "2:11",
        "name_ro": "2:11",
        "name_ru": "2:11",
        "name_tr": "2:11",
        "name_se": "2:11",
        "name_el": "2:11",
        "name_es": "2:11",
        "name_hr": "2:11",
        "name_fr": "2:11"
    },
    "26^live_match^8^0:4": {
        "name_en": "0:4",
        "name_aa": "0:4",
        "name_de": "0:4",
        "name_nl": "0:4",
        "name_pl": "0:4",
        "name_ro": "0:4",
        "name_ru": "0:4",
        "name_tr": "0:4",
        "name_se": "0:4",
        "name_el": "0:4",
        "name_es": "0:4",
        "name_hr": "0:4",
        "name_fr": "0:4"
    },
    "2222^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "1108^live_match^8^11:8": {
        "name_en": "11:8",
        "name_aa": "11:8",
        "name_de": "11:8",
        "name_nl": "11:8",
        "name_pl": "11:8",
        "name_ro": "11:8",
        "name_ru": "11:8",
        "name_tr": "11:8",
        "name_se": "11:8",
        "name_el": "11:8",
        "name_es": "11:8",
        "name_hr": "11:8",
        "name_fr": "11:8"
    },
    "2145^live_match^8^away_1-3": {
        "name_en": "away 1-3",
        "name_aa": "away 1-3",
        "name_de": "Auswärt 1-3",
        "name_nl": "Weg 1-3",
        "name_pl": "away 1-3",
        "name_ro": "away 1-3",
        "name_ru": "away 1-3",
        "name_tr": "away 1-3",
        "name_se": "away 1-3",
        "name_el": "away 1-3",
        "name_es": "away 1-3",
        "name_hr": "Gostujuči 1-3",
        "name_fr": "away 1-3"
    },
    "2261^live_match^8^1:8": {
        "name_en": "1:8",
        "name_aa": "1:8",
        "name_de": "1:8",
        "name_nl": "1:8",
        "name_pl": "1:8",
        "name_ro": "1:8",
        "name_ru": "1:8",
        "name_tr": "1:8",
        "name_se": "1:8",
        "name_el": "1:8",
        "name_es": "1:8",
        "name_hr": "1:8",
        "name_fr": "1:8"
    },
    "2063^live_match^8^home_3-6": {
        "name_en": "home 3-6",
        "name_aa": "home 3-6",
        "name_de": "Heim 3-6",
        "name_nl": "thuis 3-6",
        "name_pl": "home 3-6",
        "name_ro": "home 3-6",
        "name_ru": "home 3-6",
        "name_tr": "home 3-6",
        "name_se": "home 3-6",
        "name_el": "home 3-6",
        "name_es": "home 3-6",
        "name_hr": "Domači 3-6",
        "name_fr": "home 3-6"
    },
    "2073^live_match^8^11:21_or_better": {
        "name_en": "11:21 or better",
        "name_aa": "11:21 or better",
        "name_de": "11:21 oder besser",
        "name_nl": "11:21 of beter",
        "name_pl": "11:21 or better",
        "name_ro": "11:21 or better",
        "name_ru": "11:21 or better",
        "name_tr": "11:21 or better",
        "name_se": "11:21 or better",
        "name_el": "11:21 or better",
        "name_es": "11:21 or better",
        "name_hr": "11:21 or better",
        "name_fr": "11:21 or better"
    },
    "2351^live_match^8^yes": {
        "name_en": "yes",
        "name_aa": "yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "yes",
        "name_ro": "yes",
        "name_ru": "yes",
        "name_tr": "yes",
        "name_se": "yes",
        "name_el": "yes",
        "name_es": "yes",
        "name_hr": "Da",
        "name_fr": "yes"
    },
    "2098^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "47^live_match^8^under": {
        "name_en": "Under",
        "name_aa": "u",
        "name_de": "Unter",
        "name_nl": "u",
        "name_pl": "u",
        "name_ro": "u",
        "name_ru": "u",
        "name_tr": "Alt",
        "name_se": "u",
        "name_el": "u",
        "name_es": "u",
        "name_hr": "Manje",
        "name_fr": "m"
    },
    "2229^live_match^6^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2074^live_match^8^8:15": {
        "name_en": "8:15",
        "name_aa": "8:15",
        "name_de": "8:15",
        "name_nl": "8:15",
        "name_pl": "8:15",
        "name_ro": "8:15",
        "name_ru": "8:15",
        "name_tr": "8:15",
        "name_se": "8:15",
        "name_el": "8:15",
        "name_es": "8:15",
        "name_hr": "8:15",
        "name_fr": "8:15"
    },
    "2060^live_match^8^home_11+": {
        "name_en": "home 11+",
        "name_aa": "home 11+",
        "name_de": "Heim 11+",
        "name_nl": "thuis 11+",
        "name_pl": "home 11+",
        "name_ro": "home 11+",
        "name_ru": "home 11+",
        "name_tr": "home 11+",
        "name_se": "home 11+",
        "name_el": "home 11+",
        "name_es": "home 11+",
        "name_hr": "Domači 11+",
        "name_fr": "home 11+"
    },
    "26^live_match^8^6:7": {
        "name_en": "6:7",
        "name_aa": "6:7",
        "name_de": "6:7",
        "name_nl": "6:7",
        "name_pl": "6:7",
        "name_ro": "6:7",
        "name_ru": "6:7",
        "name_tr": "6:7",
        "name_se": "6:7",
        "name_el": "6:7",
        "name_es": "6:7",
        "name_hr": "6:7",
        "name_fr": "6:7"
    },
    "1208^live_match^8^black": {
        "name_en": "black",
        "name_aa": "black",
        "name_de": "schwarz",
        "name_nl": "zwart",
        "name_pl": "black",
        "name_ro": "black",
        "name_ru": "black",
        "name_tr": "siyah",
        "name_se": "black",
        "name_el": "black",
        "name_es": "black",
        "name_hr": "crna",
        "name_fr": "noir"
    },
    "2128^live_match^8^3:11_or_better": {
        "name_en": "3:11 or better",
        "name_aa": "3:11 or better",
        "name_de": "3:11 oder besser",
        "name_nl": "3:11 of beter",
        "name_pl": "3:11 or better",
        "name_ro": "3:11 or better",
        "name_ru": "3:11 or better",
        "name_tr": "3:11 or better",
        "name_se": "3:11 or better",
        "name_el": "3:11 or better",
        "name_es": "3:11 or better",
        "name_hr": "3:11 or better",
        "name_fr": "3:11 or better"
    },
    "2233^live_match^6^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2335^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "29^live_match^8^0:4": {
        "name_en": "0:4",
        "name_aa": "0:4",
        "name_de": "0:4",
        "name_nl": "0:4",
        "name_pl": "0:4",
        "name_ro": "0:4",
        "name_ru": "0:4",
        "name_tr": "0:4",
        "name_se": "0:4",
        "name_el": "0:4",
        "name_es": "0:4",
        "name_hr": "0:4",
        "name_fr": "0:4"
    },
    "2149^live_match^8^home_1-5": {
        "name_en": "home 1-5",
        "name_aa": "home 1-5",
        "name_de": "Heim 1-5",
        "name_nl": "Thuis 1-5",
        "name_pl": "home 1-5",
        "name_ro": "home 1-5",
        "name_ru": "home 1-5",
        "name_tr": "home 1-5",
        "name_se": "home 1-5",
        "name_el": "home 1-5",
        "name_es": "home 1-5",
        "name_hr": "Domači 1-5",
        "name_fr": "home 1-5"
    },
    "1108^live_match^8^6:9": {
        "name_en": "6:9",
        "name_aa": "6:9",
        "name_de": "6:9",
        "name_nl": "6:9",
        "name_pl": "6:9",
        "name_ro": "6:9",
        "name_ru": "6:9",
        "name_tr": "6:9",
        "name_se": "6:9",
        "name_el": "6:9",
        "name_es": "6:9",
        "name_hr": "6:9",
        "name_fr": "6:9"
    },
    "2155^live_match^8^away_and_over": {
        "name_en": "away and over",
        "name_aa": "away and over",
        "name_de": "Auswärt und Über",
        "name_nl": "Uit and Meer dan",
        "name_pl": "away and over",
        "name_ro": "away and over",
        "name_ru": "away and over",
        "name_tr": "away and over",
        "name_se": "away and over",
        "name_el": "away and over",
        "name_es": "away and over",
        "name_hr": "Gostujući i više",
        "name_fr": "away and over"
    },
    "2354^live_match^8^7:1": {
        "name_en": "7:1",
        "name_aa": "7:1",
        "name_de": "7:1",
        "name_nl": "7:1",
        "name_pl": "7:1",
        "name_ro": "7:1",
        "name_ru": "7:1",
        "name_tr": "7:1",
        "name_se": "7:1",
        "name_el": "7:1",
        "name_es": "7:1",
        "name_hr": "7:1",
        "name_fr": "7:1"
    },
    "1108^live_match^8^11:6": {
        "name_en": "11:6",
        "name_aa": "11:6",
        "name_de": "11:6",
        "name_nl": "11:6",
        "name_pl": "11:6",
        "name_ro": "11:6",
        "name_ru": "11:6",
        "name_tr": "11:6",
        "name_se": "11:6",
        "name_el": "11:6",
        "name_es": "11:6",
        "name_hr": "11:6",
        "name_fr": "11:6"
    },
    "2354^live_match^8^3:6": {
        "name_en": "3:6",
        "name_aa": "3:6",
        "name_de": "3:6",
        "name_nl": "3:6",
        "name_pl": "3:6",
        "name_ro": "3:6",
        "name_ru": "3:6",
        "name_tr": "3:6",
        "name_se": "3:6",
        "name_el": "3:6",
        "name_es": "3:6",
        "name_hr": "3:6",
        "name_fr": "3:6"
    },
    "26^live_match^8^1:1": {
        "name_en": "1:1",
        "name_aa": "1:1",
        "name_de": "1:1",
        "name_nl": "1:1",
        "name_pl": "1:1",
        "name_ro": "1:1",
        "name_ru": "1:1",
        "name_tr": "1:1",
        "name_se": "1:1",
        "name_el": "1:1",
        "name_es": "1:1",
        "name_hr": "1:1",
        "name_fr": "1:1"
    },
    "1667^live_match^8^no": {
        "name_en": "no",
        "name_aa": "no",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "no",
        "name_ro": "no",
        "name_ru": "no",
        "name_tr": "no",
        "name_se": "no",
        "name_el": "no",
        "name_es": "no",
        "name_hr": "Ne",
        "name_fr": "no"
    },
    "237^live_match^8^aa": {
        "name_en": "AA",
        "name_aa": "AA",
        "name_de": "A-A",
        "name_nl": "AA",
        "name_pl": "AA",
        "name_ro": "AA",
        "name_ru": "AA",
        "name_tr": "DD",
        "name_se": "AA",
        "name_el": "AA",
        "name_es": "AA",
        "name_hr": "2-2",
        "name_fr": "EE"
    },
    "2023^live_match^8^neither": {
        "name_en": "neither",
        "name_aa": "neither",
        "name_de": "Keiner",
        "name_nl": "Geen van beide",
        "name_pl": "neither",
        "name_ro": "neither",
        "name_ru": "neither",
        "name_tr": "neither",
        "name_se": "neither",
        "name_el": "neither",
        "name_es": "neither",
        "name_hr": "Ni",
        "name_fr": "neither"
    },
    "153^live_match^8^under": {
        "name_en": "Under",
        "name_aa": "u",
        "name_de": "Unter",
        "name_nl": "u",
        "name_pl": "u",
        "name_ro": "u",
        "name_ru": "u",
        "name_tr": "Alt",
        "name_se": "u",
        "name_el": "u",
        "name_es": "u",
        "name_hr": "Manje",
        "name_fr": "m"
    },
    "2354^live_match^8^6:3": {
        "name_en": "6:3",
        "name_aa": "6:3",
        "name_de": "6:3",
        "name_nl": "6:3",
        "name_pl": "6:3",
        "name_ro": "6:3",
        "name_ru": "6:3",
        "name_tr": "6:3",
        "name_se": "6:3",
        "name_el": "6:3",
        "name_es": "6:3",
        "name_hr": "6:3",
        "name_fr": "6:3"
    },
    "2020^live_match^8^home": {
        "name_en": "home",
        "name_aa": "home",
        "name_de": "Heim",
        "name_nl": "Thuis",
        "name_pl": "home",
        "name_ro": "home",
        "name_ru": "home",
        "name_tr": "home",
        "name_se": "home",
        "name_el": "home",
        "name_es": "home",
        "name_hr": "Domači",
        "name_fr": "home"
    },
    "2072^live_match^8^25:22": {
        "name_en": "25:22",
        "name_aa": "25:22",
        "name_de": "25:22",
        "name_nl": "25:22",
        "name_pl": "25:22",
        "name_ro": "25:22",
        "name_ru": "25:22",
        "name_tr": "25:22",
        "name_se": "25:22",
        "name_el": "25:22",
        "name_es": "25:22",
        "name_hr": "25:22",
        "name_fr": "25:22"
    },
    "2090^live_match^8^player_1_win_to_love": {
        "name_en": "Player 1 Win to love",
        "name_aa": "Player 1 Win to love",
        "name_de": "Player 1 Gewinnt zu null",
        "name_nl": "Player 1 wint op love",
        "name_pl": "Player 1 Win to love",
        "name_ro": "Player 1 Win to love",
        "name_ru": "Player 1 Win to love",
        "name_tr": "Player 1 Win to love",
        "name_se": "Player 1 Win to love",
        "name_el": "Player 1 Win to love",
        "name_es": "Player 1 Win to love",
        "name_hr": "Player 1 Win to love",
        "name_fr": "Player 1 Win to love"
    },
    "26^live_match^8^2:2": {
        "name_en": "2:2",
        "name_aa": "2:2",
        "name_de": "2:2",
        "name_nl": "1:2",
        "name_pl": "2:2",
        "name_ro": "2:2",
        "name_ru": "2:2",
        "name_tr": "2:2",
        "name_se": "2:2",
        "name_el": "2:2",
        "name_es": "2:2",
        "name_hr": "2:2",
        "name_fr": "2:2"
    },
    "1108^live_match^8^2:14": {
        "name_en": "2:14",
        "name_aa": "2:14",
        "name_de": "2:14",
        "name_nl": "2:14",
        "name_pl": "2:14",
        "name_ro": "2:14",
        "name_ru": "2:14",
        "name_tr": "2:14",
        "name_se": "2:14",
        "name_el": "2:14",
        "name_es": "2:14",
        "name_hr": "2:14",
        "name_fr": "2:14"
    },
    "29^live_match^8^3:4": {
        "name_en": "3:4",
        "name_aa": "3:4",
        "name_de": "3:4",
        "name_nl": "3:4",
        "name_pl": "3:4",
        "name_ro": "3:4",
        "name_ru": "3:4",
        "name_tr": "3:4",
        "name_se": "3:4",
        "name_el": "3:4",
        "name_es": "3:4",
        "name_hr": "3:4",
        "name_fr": "3:4"
    },
    "520^live_match^8^4:0": {
        "name_en": "4:0",
        "name_aa": "4:0",
        "name_de": "4:0",
        "name_nl": "4:0",
        "name_pl": "4:0",
        "name_ro": "4:0",
        "name_ru": "4:0",
        "name_tr": "4:0",
        "name_se": "4:0",
        "name_el": "4:0",
        "name_es": "4:0",
        "name_hr": "4:0",
        "name_fr": "4:0"
    },
    "1108^live_match^8^1:12": {
        "name_en": "1:12",
        "name_aa": "1:12",
        "name_de": "1:12",
        "name_nl": "1:12",
        "name_pl": "1:12",
        "name_ro": "1:12",
        "name_ru": "1:12",
        "name_tr": "1:12",
        "name_se": "1:12",
        "name_el": "1:12",
        "name_es": "1:12",
        "name_hr": "1:12",
        "name_fr": "1:12"
    },
    "2062^live_match^8^away_7-8": {
        "name_en": "away 7-8",
        "name_aa": "away 7-8",
        "name_de": "Auswärt 7-8",
        "name_nl": "Weg 7-8",
        "name_pl": "away 7-8",
        "name_ro": "away 7-8",
        "name_ru": "away 7-8",
        "name_tr": "away 7-8",
        "name_se": "away 7-8",
        "name_el": "away 7-8",
        "name_es": "away 7-8",
        "name_hr": "Gostujuči 7-8",
        "name_fr": "away 7-8"
    },
    "2025^live_match^8^neither": {
        "name_en": "neither",
        "name_aa": "neither",
        "name_de": "Keiner",
        "name_nl": "Geen van beide",
        "name_pl": "neither",
        "name_ro": "neither",
        "name_ru": "neither",
        "name_tr": "neither",
        "name_se": "neither",
        "name_el": "neither",
        "name_es": "neither",
        "name_hr": "Ni",
        "name_fr": "neither"
    },
    "2302^live_match^8^home": {
        "name_en": "home",
        "name_aa": "home",
        "name_de": "Heim",
        "name_nl": "Thuis",
        "name_pl": "home",
        "name_ro": "home",
        "name_ru": "home",
        "name_tr": "home",
        "name_se": "home",
        "name_el": "home",
        "name_es": "home",
        "name_hr": "Domači",
        "name_fr": "home"
    },
    "1108^live_match^8^9:3": {
        "name_en": "9:3",
        "name_aa": "9:3",
        "name_de": "9:3",
        "name_nl": "9:3",
        "name_pl": "9:3",
        "name_ro": "9:3",
        "name_ru": "9:3",
        "name_tr": "9:3",
        "name_se": "9:3",
        "name_el": "9:3",
        "name_es": "9:3",
        "name_hr": "9:3",
        "name_fr": "9:3"
    },
    "287^live_match^8^x2": {
        "name_en": "X2",
        "name_aa": "X2",
        "name_de": "X2",
        "name_nl": "1X",
        "name_pl": "X2",
        "name_ro": "X2",
        "name_ru": "X2",
        "name_tr": "X2",
        "name_se": "X2",
        "name_el": "X2",
        "name_es": "X2",
        "name_hr": "X2",
        "name_fr": "X2"
    },
    "2084^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "26^live_match^8^19:1": {
        "name_en": "19:1",
        "name_aa": "19:1",
        "name_de": "19:1",
        "name_nl": "19:1",
        "name_pl": "19:1",
        "name_ro": "19:1",
        "name_ru": "19:1",
        "name_tr": "19:1",
        "name_se": "19:1",
        "name_el": "19:1",
        "name_es": "19:1",
        "name_hr": "19:1",
        "name_fr": "19:1"
    },
    "1040^live_match^8^draw_and_yes": {
        "name_en": "draw and yes",
        "name_aa": "draw and yes",
        "name_de": "Unentschieden und Ja",
        "name_nl": "gelijkspel en ja",
        "name_pl": "draw and yes",
        "name_ro": "draw and yes",
        "name_ru": "draw and yes",
        "name_tr": "berabere biter ve iki takımda gol atar",
        "name_se": "draw and yes",
        "name_el": "draw and yes",
        "name_es": "draw and yes",
        "name_hr": "neriješeno i da",
        "name_fr": "Match nul et Oui"
    },
    "1108^live_match^8^8:4": {
        "name_en": "8:4",
        "name_aa": "8:4",
        "name_de": "8:4",
        "name_nl": "8:4",
        "name_pl": "8:4",
        "name_ro": "8:4",
        "name_ru": "8:4",
        "name_tr": "8:4",
        "name_se": "8:4",
        "name_el": "8:4",
        "name_es": "8:4",
        "name_hr": "8:4",
        "name_fr": "8:4"
    },
    "2217^live_match^8^2:2": {
        "name_en": "2:2",
        "name_aa": "2:2",
        "name_de": "2:2",
        "name_nl": "2:2",
        "name_pl": "2:2",
        "name_ro": "2:2",
        "name_ru": "2:2",
        "name_tr": "2:2",
        "name_se": "2:2",
        "name_el": "2:2",
        "name_es": "2:2",
        "name_hr": "2:2",
        "name_fr": "2:2"
    },
    "2210^live_match^8^0-9": {
        "name_en": "home 0-9",
        "name_aa": "home 0-9",
        "name_de": "Heim 0-9",
        "name_nl": "Thuis 0-9",
        "name_pl": "home 0-9",
        "name_ro": "home 0-9",
        "name_ru": "home 0-9",
        "name_tr": "home 0-9",
        "name_se": "home 0-9",
        "name_el": "home 0-9",
        "name_es": "home 0-9",
        "name_hr": "Domači 0-9",
        "name_fr": "home 0-9"
    },
    "86^live_match^8^over": {
        "name_en": "Over",
        "name_aa": "Over",
        "name_de": "Über",
        "name_nl": "Over",
        "name_pl": "Over",
        "name_ro": "Over",
        "name_ru": "Over",
        "name_tr": "Üst",
        "name_se": "Over",
        "name_el": "Over",
        "name_es": "Over",
        "name_hr": "Više",
        "name_fr": "p"
    },
    "1108^live_match^8^12:1": {
        "name_en": "12:1",
        "name_aa": "12:1",
        "name_de": "12:1",
        "name_nl": "12:1",
        "name_pl": "12:1",
        "name_ro": "12:1",
        "name_ru": "12:1",
        "name_tr": "12:1",
        "name_se": "12:1",
        "name_el": "12:1",
        "name_es": "12:1",
        "name_hr": "12:1",
        "name_fr": "12:1"
    },
    "26^live_match^8^14:0": {
        "name_en": "14:0",
        "name_aa": "14:0",
        "name_de": "14:0",
        "name_nl": "14:0",
        "name_pl": "14:0",
        "name_ro": "14:0",
        "name_ru": "14:0",
        "name_tr": "14:0",
        "name_se": "14:0",
        "name_el": "14:0",
        "name_es": "14:0",
        "name_hr": "14:0",
        "name_fr": "14:0"
    },
    "2127^live_match^8^yes": {
        "name_en": "yes",
        "name_aa": "yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "yes",
        "name_ro": "yes",
        "name_ru": "yes",
        "name_tr": "yes",
        "name_se": "yes",
        "name_el": "yes",
        "name_es": "yes",
        "name_hr": "Da",
        "name_fr": "yes"
    },
    "2159^live_match^8^home": {
        "name_en": "home",
        "name_aa": "home",
        "name_de": "Heim",
        "name_nl": "Thuis",
        "name_pl": "home",
        "name_ro": "home",
        "name_ru": "home",
        "name_tr": "home",
        "name_se": "home",
        "name_el": "home",
        "name_es": "home",
        "name_hr": "Domači",
        "name_fr": "home"
    },
    "347^live_match^8^even": {
        "name_en": "even",
        "name_aa": "even",
        "name_de": "Gerade",
        "name_nl": "even",
        "name_pl": "even",
        "name_ro": "even",
        "name_ru": "even",
        "name_tr": "çift",
        "name_se": "even",
        "name_el": "even",
        "name_es": "even",
        "name_hr": "Paran",
        "name_fr": "pair"
    },
    "19^live_match^8^3:1": {
        "name_en": "3:1",
        "name_aa": "3:1",
        "name_de": "3:1",
        "name_nl": "3:1",
        "name_pl": "3:1",
        "name_ro": "3:1",
        "name_ru": "3:1",
        "name_tr": "3:1",
        "name_se": "3:1",
        "name_el": "3:1",
        "name_es": "3:1",
        "name_hr": "3:1",
        "name_fr": "3:1"
    },
    "26^live_match^8^20:1": {
        "name_en": "20:1",
        "name_aa": "20:1",
        "name_de": "20:1",
        "name_nl": "20:1",
        "name_pl": "20:1",
        "name_ro": "20:1",
        "name_ru": "20:1",
        "name_tr": "20:1",
        "name_se": "20:1",
        "name_el": "20:1",
        "name_es": "20:1",
        "name_hr": "20:1",
        "name_fr": "20:1"
    },
    "29^live_match^8^0:11": {
        "name_en": "0:11",
        "name_aa": "0:11",
        "name_de": "0:11",
        "name_nl": "0:11",
        "name_pl": "0:11",
        "name_ro": "0:11",
        "name_ru": "0:11",
        "name_tr": "0:11",
        "name_se": "0:11",
        "name_el": "0:11",
        "name_es": "0:11",
        "name_hr": "0:11",
        "name_fr": "0:11"
    },
    "26^live_match^8^1:0": {
        "name_en": "1:0",
        "name_aa": "1:0",
        "name_de": "1:0",
        "name_nl": "1:0",
        "name_pl": "1:0",
        "name_ro": "1:0",
        "name_ru": "1:0",
        "name_tr": "1:0",
        "name_se": "1:0",
        "name_el": "1:0",
        "name_es": "1:0",
        "name_hr": "1:0",
        "name_fr": "1:0"
    },
    "2354^live_match^8^1:4": {
        "name_en": "1:4",
        "name_aa": "1:4",
        "name_de": "1:4",
        "name_nl": "1:4",
        "name_pl": "1:4",
        "name_ro": "1:4",
        "name_ru": "1:4",
        "name_tr": "1:4",
        "name_se": "1:4",
        "name_el": "1:4",
        "name_es": "1:4",
        "name_hr": "1:4",
        "name_fr": "1:4"
    },
    "183^live_match^8^away_and_over": {
        "name_en": "away and over",
        "name_aa": "away and over",
        "name_de": "Auswärtsteam und mehr",
        "name_nl": "uit en boven",
        "name_pl": "away and over",
        "name_ro": "away and over",
        "name_ru": "away and over",
        "name_tr": "deplasman ve üst",
        "name_se": "away and over",
        "name_el": "away and over",
        "name_es": "away and over",
        "name_hr": "gost i više",
        "name_fr": "Extérieur et Plus de"
    },
    "2179^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "13^live_match^6^x": {
        "name_en": "x",
        "name_aa": "x",
        "name_de": "x",
        "name_nl": "x",
        "name_pl": "x",
        "name_ro": "x",
        "name_ru": "x",
        "name_tr": "x",
        "name_se": "x",
        "name_el": "x",
        "name_es": "x",
        "name_hr": "x",
        "name_fr": "x"
    },
    "2086^live_match^8^2:0": {
        "name_en": "2:0",
        "name_aa": "2:0",
        "name_de": "2:0",
        "name_nl": "2:0",
        "name_pl": "2:0",
        "name_ro": "2:0",
        "name_ru": "2:0",
        "name_tr": "2:0",
        "name_se": "2:0",
        "name_el": "2:0",
        "name_es": "2:0",
        "name_hr": "2:0",
        "name_fr": "2:0"
    },
    "26^live_match^8^9:8": {
        "name_en": "9:8",
        "name_aa": "9:8",
        "name_de": "9:8",
        "name_nl": "9:8",
        "name_pl": "9:8",
        "name_ro": "9:8",
        "name_ru": "9:8",
        "name_tr": "9:8",
        "name_se": "9:8",
        "name_el": "9:8",
        "name_es": "9:8",
        "name_hr": "9:8",
        "name_fr": "9:8"
    },
    "26^live_match^8^7:6": {
        "name_en": "7:6",
        "name_aa": "7:6",
        "name_de": "7:6",
        "name_nl": "7:6",
        "name_pl": "7:6",
        "name_ro": "7:6",
        "name_ru": "7:6",
        "name_tr": "7:6",
        "name_se": "7:6",
        "name_el": "7:6",
        "name_es": "7:6",
        "name_hr": "7:6",
        "name_fr": "7:6"
    },
    "2364^live_match^8^draw_and_under_and_gg": {
        "name_en": "draw and under and GG",
        "name_aa": "draw and under and GG",
        "name_de": "Unentschieden und Unter und GG",
        "name_nl": "Gelijkspel en minder dan en GG",
        "name_pl": "draw and under and GG",
        "name_ro": "draw and under and GG",
        "name_ru": "draw and under and GG",
        "name_tr": "draw and under and GG",
        "name_se": "draw and under and GG",
        "name_el": "draw and under and GG",
        "name_es": "draw and under and GG",
        "name_hr": "draw and under and GG",
        "name_fr": "draw and under and GG"
    },
    "2109^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "26^live_match^8^17:1": {
        "name_en": "17:1",
        "name_aa": "17:1",
        "name_de": "17:1",
        "name_nl": "17:1",
        "name_pl": "17:1",
        "name_ro": "17:1",
        "name_ru": "17:1",
        "name_tr": "17:1",
        "name_se": "17:1",
        "name_el": "17:1",
        "name_es": "17:1",
        "name_hr": "17:1",
        "name_fr": "17:1"
    },
    "46^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "26^live_match^8^10:2": {
        "name_en": "10:2",
        "name_aa": "10:2",
        "name_de": "10:2",
        "name_nl": "10:2",
        "name_pl": "10:2",
        "name_ro": "10:2",
        "name_ru": "10:2",
        "name_tr": "10:2",
        "name_se": "10:2",
        "name_el": "10:2",
        "name_es": "10:2",
        "name_hr": "10:2",
        "name_fr": "10:2"
    },
    "2081^live_match^8^4:3": {
        "name_en": "4:3",
        "name_aa": "4:3",
        "name_de": "4:3",
        "name_nl": "4:3",
        "name_pl": "4:3",
        "name_ro": "4:3",
        "name_ru": "4:3",
        "name_tr": "4:3",
        "name_se": "4:3",
        "name_el": "4:3",
        "name_es": "4:3",
        "name_hr": "4:3",
        "name_fr": "4:3"
    },
    "2145^live_match^8^home_17-20": {
        "name_en": "home 17-20",
        "name_aa": "home 17-20",
        "name_de": "Heim 17-20",
        "name_nl": "thuis 17-20",
        "name_pl": "home 17-20",
        "name_ro": "home 17-20",
        "name_ru": "home 17-20",
        "name_tr": "home 17-20",
        "name_se": "home 17-20",
        "name_el": "home 17-20",
        "name_es": "home 17-20",
        "name_hr": "Domači 17-20",
        "name_fr": "home 17-20"
    },
    "1665^live_match^2^x": {
        "name_en": "x",
        "name_aa": "x",
        "name_de": "x",
        "name_nl": "x",
        "name_pl": "x",
        "name_ro": "x",
        "name_ru": "x",
        "name_tr": "x",
        "name_se": "x",
        "name_el": "x",
        "name_es": "x",
        "name_hr": "x",
        "name_fr": "x"
    },
    "2076^live_match^8^home_after_extra_points": {
        "name_en": "home after extra points",
        "name_aa": "home after extra points",
        "name_de": "Heimmannschaft Nach Verlängerung",
        "name_nl": "Thuisteam Na extra punten",
        "name_pl": "home after extra points",
        "name_ro": "home after extra points",
        "name_ru": "home after extra points",
        "name_tr": "home after extra points",
        "name_se": "home after extra points",
        "name_el": "home after extra points",
        "name_es": "home after extra points",
        "name_hr": "home after extra points",
        "name_fr": "home after extra points"
    },
    "2248^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "51^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "1206^live_match^8^blue": {
        "name_en": "blue",
        "name_aa": "blue",
        "name_de": "blau",
        "name_nl": "blauw",
        "name_pl": "blue",
        "name_ro": "blue",
        "name_ru": "blue",
        "name_tr": "mavi",
        "name_se": "blue",
        "name_el": "blue",
        "name_es": "blue",
        "name_hr": "plava",
        "name_fr": "bleu"
    },
    "37^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2074^live_match^8^25:17": {
        "name_en": "25:17",
        "name_aa": "25:17",
        "name_de": "25:17",
        "name_nl": "25:17",
        "name_pl": "25:17",
        "name_ro": "25:17",
        "name_ru": "25:17",
        "name_tr": "25:17",
        "name_se": "25:17",
        "name_el": "25:17",
        "name_es": "25:17",
        "name_hr": "25:17",
        "name_fr": "25:17"
    },
    "117^live_match^8^0:0": {
        "name_en": "0:0",
        "name_aa": "0:0",
        "name_de": "0:0",
        "name_nl": "0:0",
        "name_pl": "0:0",
        "name_ro": "0:0",
        "name_ru": "0:0",
        "name_tr": "0:0",
        "name_se": "0:0",
        "name_el": "0:0",
        "name_es": "0:0",
        "name_hr": "0:0",
        "name_fr": "0:0"
    },
    "2061^live_match^8^home_11+": {
        "name_en": "home 11+",
        "name_aa": "home 11+",
        "name_de": "Heim 11+",
        "name_nl": "thuis 11+",
        "name_pl": "home 11+",
        "name_ro": "home 11+",
        "name_ru": "home 11+",
        "name_tr": "home 11+",
        "name_se": "home 11+",
        "name_el": "home 11+",
        "name_es": "home 11+",
        "name_hr": "Domači 11+",
        "name_fr": "home 11+"
    },
    "2364^live_match^8^home_and_under_and_ng": {
        "name_en": "home and under and NG",
        "name_aa": "home and under and NG",
        "name_de": "Heim und Unter und NG",
        "name_nl": "Thuis en minder dan en NG",
        "name_pl": "home and under and NG",
        "name_ro": "home and under and NG",
        "name_ru": "home and under and NG",
        "name_tr": "home and under and NG",
        "name_se": "home and under and NG",
        "name_el": "home and under and NG",
        "name_es": "home and under and NG",
        "name_hr": "home and under and NG",
        "name_fr": "home and under and NG"
    },
    "2261^live_match^8^0:3": {
        "name_en": "0:3",
        "name_aa": "0:3",
        "name_de": "0:3",
        "name_nl": "0:3",
        "name_pl": "0:3",
        "name_ro": "0:3",
        "name_ru": "0:3",
        "name_tr": "0:3",
        "name_se": "0:3",
        "name_el": "0:3",
        "name_es": "0:3",
        "name_hr": "0:3",
        "name_fr": "0:3"
    },
    "1156^live_match^8^5:3": {
        "name_en": "5:3",
        "name_aa": "5:3",
        "name_de": "5:3",
        "name_nl": "53",
        "name_pl": "5:3",
        "name_ro": "5:3",
        "name_ru": "5:3",
        "name_tr": "5:3",
        "name_se": "5:3",
        "name_el": "5:3",
        "name_es": "5:3",
        "name_hr": "5:3",
        "name_fr": "5:3"
    },
    "916^live_match^8^yes": {
        "name_en": "Yes",
        "name_aa": "Yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "Yes",
        "name_ro": "Yes",
        "name_ru": "Yes",
        "name_tr": "evet",
        "name_se": "Yes",
        "name_el": "Yes",
        "name_es": "Yes",
        "name_hr": "Da",
        "name_fr": "oui"
    },
    "1208^live_match^8^foul": {
        "name_en": "foul",
        "name_aa": "foul",
        "name_de": "foul",
        "name_nl": "fout",
        "name_pl": "foul",
        "name_ro": "foul",
        "name_ru": "foul",
        "name_tr": "faul",
        "name_se": "foul",
        "name_el": "foul",
        "name_es": "foul",
        "name_hr": "Faul",
        "name_fr": "faute"
    },
    "2075^live_match^8^19:25": {
        "name_en": "19:25",
        "name_aa": "19:25",
        "name_de": "19:25",
        "name_nl": "19:25",
        "name_pl": "19:25",
        "name_ro": "19:25",
        "name_ru": "19:25",
        "name_tr": "19:25",
        "name_se": "19:25",
        "name_el": "19:25",
        "name_es": "19:25",
        "name_hr": "19:25",
        "name_fr": "19:25"
    },
    "2066^live_match^6^away_win_by_3+": {
        "name_en": "away win by 3+",
        "name_aa": "away win by 3+",
        "name_de": "Auswart gewinnt durch 3+",
        "name_nl": "uitoverwinning met 3+",
        "name_pl": "away win by 3+",
        "name_ro": "away win by 3+",
        "name_ru": "away win by 3+",
        "name_tr": "away win by 3+",
        "name_se": "away win by 3+",
        "name_el": "away win by 3+",
        "name_es": "away win by 3+",
        "name_hr": "Gostujuči pobijediti po 3+",
        "name_fr": "away win by 3+"
    },
    "2062^live_match^8^away_9-10": {
        "name_en": "away 9-10",
        "name_aa": "away 9-10",
        "name_de": "Auswärt 9-10",
        "name_nl": "Weg 9-10",
        "name_pl": "away 9-10",
        "name_ro": "away 9-10",
        "name_ru": "away 9-10",
        "name_tr": "away 9-10",
        "name_se": "away 9-10",
        "name_el": "away 9-10",
        "name_es": "away 9-10",
        "name_hr": "Gostujuči 9-10",
        "name_fr": "away 9-10"
    },
    "2350^live_match^8^goal": {
        "name_en": "goal",
        "name_aa": "goal",
        "name_de": "Tor",
        "name_nl": "Doelpunt",
        "name_pl": "goal",
        "name_ro": "goal",
        "name_ru": "goal",
        "name_tr": "goal",
        "name_se": "goal",
        "name_el": "goal",
        "name_es": "goal",
        "name_hr": "Golova",
        "name_fr": "goal"
    },
    "1108^live_match^8^8:6": {
        "name_en": "8:6",
        "name_aa": "8:6",
        "name_de": "8:6",
        "name_nl": "8:6",
        "name_pl": "8:6",
        "name_ro": "8:6",
        "name_ru": "8:6",
        "name_tr": "8:6",
        "name_se": "8:6",
        "name_el": "8:6",
        "name_es": "8:6",
        "name_hr": "8:6",
        "name_fr": "8:6"
    },
    "2122^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "1523^live_match^8^12_and_under": {
        "name_en": "12 and under",
        "name_aa": "12 and under",
        "name_de": "12 und Unter",
        "name_nl": "12 and Minder dan",
        "name_pl": "12 and under",
        "name_ro": "12 and under",
        "name_ru": "12 and under",
        "name_tr": "12 and under",
        "name_se": "12 and under",
        "name_el": "12 and under",
        "name_es": "12 and under",
        "name_hr": "12 i manje",
        "name_fr": "12 and under"
    },
    "2261^live_match^8^5:2": {
        "name_en": "5:2",
        "name_aa": "5:2",
        "name_de": "5:2",
        "name_nl": "5:2",
        "name_pl": "5:2",
        "name_ro": "5:2",
        "name_ru": "5:2",
        "name_tr": "5:2",
        "name_se": "5:2",
        "name_el": "5:2",
        "name_es": "5:2",
        "name_hr": "5:2",
        "name_fr": "5:2"
    },
    "26^live_match^8^0:20": {
        "name_en": "0:20",
        "name_aa": "0:20",
        "name_de": "0:20",
        "name_nl": "0:20",
        "name_pl": "0:20",
        "name_ro": "0:20",
        "name_ru": "0:20",
        "name_tr": "0:20",
        "name_se": "0:20",
        "name_el": "0:20",
        "name_es": "0:20",
        "name_hr": "0:20",
        "name_fr": "0:20"
    },
    "2035^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "1108^live_match^8^9:7": {
        "name_en": "9:7",
        "name_aa": "9:7",
        "name_de": "9:7",
        "name_nl": "9:7",
        "name_pl": "9:7",
        "name_ro": "9:7",
        "name_ru": "9:7",
        "name_tr": "9:7",
        "name_se": "9:7",
        "name_el": "9:7",
        "name_es": "9:7",
        "name_hr": "9:7",
        "name_fr": "9:7"
    },
    "2193^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "2354^live_match^8^4:5": {
        "name_en": "4:5",
        "name_aa": "4:5",
        "name_de": "4:5",
        "name_nl": "4:5",
        "name_pl": "4:5",
        "name_ro": "4:5",
        "name_ru": "4:5",
        "name_tr": "4:5",
        "name_se": "4:5",
        "name_el": "4:5",
        "name_es": "4:5",
        "name_hr": "4:5",
        "name_fr": "4:5"
    },
    "29^live_match^8^1:13": {
        "name_en": "1:13",
        "name_aa": "1:13",
        "name_de": "1:13",
        "name_nl": "1:13",
        "name_pl": "1:13",
        "name_ro": "1:13",
        "name_ru": "1:13",
        "name_tr": "1:13",
        "name_se": "1:13",
        "name_el": "1:13",
        "name_es": "1:13",
        "name_hr": "1:13",
        "name_fr": "1:13"
    },
    "26^live_match^8^0:5": {
        "name_en": "0:5",
        "name_aa": "0:5",
        "name_de": "0:5",
        "name_nl": "0:5",
        "name_pl": "0:5",
        "name_ro": "0:5",
        "name_ru": "0:5",
        "name_tr": "0:5",
        "name_se": "0:5",
        "name_el": "0:5",
        "name_es": "0:5",
        "name_hr": "0:5",
        "name_fr": "0:5"
    },
    "2167^live_match^8^1/2": {
        "name_en": "1/2",
        "name_aa": "1/2",
        "name_de": "1/2",
        "name_nl": "1/2",
        "name_pl": "1/2",
        "name_ro": "1/2",
        "name_ru": "1/2",
        "name_tr": "1/2",
        "name_se": "1/2",
        "name_el": "1/2",
        "name_es": "1/2",
        "name_hr": "1/2",
        "name_fr": "1/2"
    },
    "353^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "27^live_match^8^12": {
        "name_en": "12",
        "name_aa": "12",
        "name_de": "12",
        "name_nl": "12",
        "name_pl": "12",
        "name_ro": "12",
        "name_ru": "12",
        "name_tr": "12",
        "name_se": "12",
        "name_el": "12",
        "name_es": "12",
        "name_hr": "12",
        "name_fr": "12"
    },
    "2261^live_match^8^7:5": {
        "name_en": "7:5",
        "name_aa": "7:5",
        "name_de": "7:5",
        "name_nl": "7:5",
        "name_pl": "7:5",
        "name_ro": "7:5",
        "name_ru": "7:5",
        "name_tr": "7:5",
        "name_se": "7:5",
        "name_el": "7:5",
        "name_es": "7:5",
        "name_hr": "7:5",
        "name_fr": "7:5"
    },
    "914^live_match^8^yes": {
        "name_en": "Yes",
        "name_aa": "Yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "Yes",
        "name_ro": "Yes",
        "name_ru": "Yes",
        "name_tr": "evet",
        "name_se": "Yes",
        "name_el": "Yes",
        "name_es": "Yes",
        "name_hr": "Da",
        "name_fr": "oui"
    },
    "2186^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "2307^live_match^8^2:4": {
        "name_en": "2:4",
        "name_aa": "2:4",
        "name_de": "2:4",
        "name_nl": "2:4",
        "name_pl": "2:4",
        "name_ro": "2:4",
        "name_ru": "2:4",
        "name_tr": "2:4",
        "name_se": "2:4",
        "name_el": "2:4",
        "name_es": "2:4",
        "name_hr": "2:4",
        "name_fr": "2:4"
    },
    "71^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "1210^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2316^live_match^8^1:10": {
        "name_en": "1:10",
        "name_aa": "1:10",
        "name_de": "1:10",
        "name_nl": "1:10",
        "name_pl": "1:10",
        "name_ro": "1:10",
        "name_ru": "1:10",
        "name_tr": "1:10",
        "name_se": "1:10",
        "name_el": "1:10",
        "name_es": "1:10",
        "name_hr": "1:10",
        "name_fr": "1:10"
    },
    "18^live_match^8^2:0": {
        "name_en": "2:0",
        "name_aa": "2:0",
        "name_de": "2:0",
        "name_nl": "2:0",
        "name_pl": "2:0",
        "name_ro": "2:0",
        "name_ru": "2:0",
        "name_tr": "2:0",
        "name_se": "2:0",
        "name_el": "2:0",
        "name_es": "2:0",
        "name_hr": "2:0",
        "name_fr": "2:0"
    },
    "2055^live_match^8^1x": {
        "name_en": "1X",
        "name_aa": "1X",
        "name_de": "1X",
        "name_nl": "1X",
        "name_pl": "1X",
        "name_ro": "1X",
        "name_ru": "1X",
        "name_tr": "1X",
        "name_se": "1X",
        "name_el": "1X",
        "name_es": "1X",
        "name_hr": "1X",
        "name_fr": "1X"
    },
    "2087^live_match^8^1:3": {
        "name_en": "1:3",
        "name_aa": "1:3",
        "name_de": "1:3",
        "name_nl": "1:3",
        "name_pl": "1:3",
        "name_ro": "1:3",
        "name_ru": "1:3",
        "name_tr": "1:3",
        "name_se": "1:3",
        "name_el": "1:3",
        "name_es": "1:3",
        "name_hr": "1:3",
        "name_fr": "1:3"
    },
    "58^live_match^8^even": {
        "name_en": "even",
        "name_aa": "even",
        "name_de": "Gerade",
        "name_nl": "even",
        "name_pl": "even",
        "name_ro": "even",
        "name_ru": "even",
        "name_tr": "çift",
        "name_se": "even",
        "name_el": "even",
        "name_es": "even",
        "name_hr": "Paran",
        "name_fr": "pair"
    },
    "960^live_match^6^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "29^live_match^8^12:0": {
        "name_en": "12:0",
        "name_aa": "12:0",
        "name_de": "12:0",
        "name_nl": "12:0",
        "name_pl": "12:0",
        "name_ro": "12:0",
        "name_ru": "12:0",
        "name_tr": "12:0",
        "name_se": "12:0",
        "name_el": "12:0",
        "name_es": "12:0",
        "name_hr": "12:0",
        "name_fr": "12:0"
    },
    "1506^live_match^8^away": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2054^live_match^8^x2": {
        "name_en": "X2",
        "name_aa": "X2",
        "name_de": "X2",
        "name_nl": "X2",
        "name_pl": "X2",
        "name_ro": "X2",
        "name_ru": "X2",
        "name_tr": "X2",
        "name_se": "X2",
        "name_el": "X2",
        "name_es": "X2",
        "name_hr": "X2",
        "name_fr": "X2"
    },
    "2217^live_match^8^4:0": {
        "name_en": "4:0",
        "name_aa": "4:0",
        "name_de": "4:0",
        "name_nl": "4:0",
        "name_pl": "4:0",
        "name_ro": "4:0",
        "name_ru": "4:0",
        "name_tr": "4:0",
        "name_se": "4:0",
        "name_el": "4:0",
        "name_es": "4:0",
        "name_hr": "4:0",
        "name_fr": "4:0"
    },
    "954^live_match^6^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2028^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "2157^live_match^8^21:13_or_better": {
        "name_en": "21:13 or better",
        "name_aa": "21:13 or better",
        "name_de": "21:13 oder besser",
        "name_nl": "21:13 of beter",
        "name_pl": "21:13 or better",
        "name_ro": "21:13 or better",
        "name_ru": "21:13 or better",
        "name_tr": "21:13 or better",
        "name_se": "21:13 or better",
        "name_el": "21:13 or better",
        "name_es": "21:13 or better",
        "name_hr": "21:13 or better",
        "name_fr": "21:13 or better"
    },
    "1523^live_match^8^x2_and_under": {
        "name_en": "X2 and under",
        "name_aa": "X2 and under",
        "name_de": "X2 und Unter",
        "name_nl": "X2 en Minder dan",
        "name_pl": "X2 and under",
        "name_ro": "X2 and under",
        "name_ru": "X2 and under",
        "name_tr": "X2 and under",
        "name_se": "X2 and under",
        "name_el": "X2 and under",
        "name_es": "X2 and under",
        "name_hr": "X2 i manje",
        "name_fr": "X2 and under"
    },
    "1658^live_match^2^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "520^live_match^8^2:6": {
        "name_en": "2:6",
        "name_aa": "2:6",
        "name_de": "2:6",
        "name_nl": "2:6",
        "name_pl": "2:6",
        "name_ro": "2:6",
        "name_ru": "2:6",
        "name_tr": "2:6",
        "name_se": "2:6",
        "name_el": "2:6",
        "name_es": "2:6",
        "name_hr": "2:6",
        "name_fr": "2:6"
    },
    "2261^live_match^8^8:7": {
        "name_en": "8:7",
        "name_aa": "8:7",
        "name_de": "8:7",
        "name_nl": "8:7",
        "name_pl": "8:7",
        "name_ro": "8:7",
        "name_ru": "8:7",
        "name_tr": "8:7",
        "name_se": "8:7",
        "name_el": "8:7",
        "name_es": "8:7",
        "name_hr": "8:7",
        "name_fr": "8:7"
    },
    "2016^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "29^live_match^8^2:2": {
        "name_en": "2:2",
        "name_aa": "2:2",
        "name_de": "2:2",
        "name_nl": "2:2",
        "name_pl": "2:2",
        "name_ro": "2:2",
        "name_ru": "2:2",
        "name_tr": "2:2",
        "name_se": "2:2",
        "name_el": "2:2",
        "name_es": "2:2",
        "name_hr": "2:2",
        "name_fr": "2:2"
    },
    "2029^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "2019^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "2192^live_match^8^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "69^live_match^8^even": {
        "name_en": "even",
        "name_aa": "even",
        "name_de": "Gerade",
        "name_nl": "even",
        "name_pl": "even",
        "name_ro": "even",
        "name_ru": "even",
        "name_tr": "çift",
        "name_se": "even",
        "name_el": "even",
        "name_es": "even",
        "name_hr": "Paran",
        "name_fr": "pair"
    },
    "2354^live_match^8^0:1": {
        "name_en": "0:1",
        "name_aa": "0:1",
        "name_de": "0:1",
        "name_nl": "0:1",
        "name_pl": "0:1",
        "name_ro": "0:1",
        "name_ru": "0:1",
        "name_tr": "0:1",
        "name_se": "0:1",
        "name_el": "0:1",
        "name_es": "0:1",
        "name_hr": "0:1",
        "name_fr": "0:1"
    },
    "2064^live_match^6^other": {
        "name_en": "other",
        "name_aa": "other",
        "name_de": "Sonstig",
        "name_nl": "Andere",
        "name_pl": "other",
        "name_ro": "other",
        "name_ru": "other",
        "name_tr": "other",
        "name_se": "other",
        "name_el": "other",
        "name_es": "other",
        "name_hr": "Ostali",
        "name_fr": "other"
    },
    "2209^live_match^8^226+": {
        "name_en": "226+",
        "name_aa": "226+",
        "name_de": "226+",
        "name_nl": "226+",
        "name_pl": "226+",
        "name_ro": "226+",
        "name_ru": "226+",
        "name_tr": "226+",
        "name_se": "226+",
        "name_el": "226+",
        "name_es": "226+",
        "name_hr": "226+",
        "name_fr": "226+"
    },
    "878^live_match^8^over": {
        "name_en": "Over",
        "name_aa": "Over",
        "name_de": "Über",
        "name_nl": "Over",
        "name_pl": "Over",
        "name_ro": "Over",
        "name_ru": "Over",
        "name_tr": "Üst",
        "name_se": "Over",
        "name_el": "Over",
        "name_es": "Over",
        "name_hr": "Više",
        "name_fr": "p"
    },
    "2128^live_match^8^6:11": {
        "name_en": "6:11",
        "name_aa": "6:11",
        "name_de": "6:11",
        "name_nl": "6:11",
        "name_pl": "6:11",
        "name_ro": "6:11",
        "name_ru": "6:11",
        "name_tr": "6:11",
        "name_se": "6:11",
        "name_el": "6:11",
        "name_es": "6:11",
        "name_hr": "6:11",
        "name_fr": "6:11"
    },
    "2072^live_match^8^home_after_extra_points": {
        "name_en": "home after extra points",
        "name_aa": "home after extra points",
        "name_de": "Heimmannschaft Nach Verlängerung",
        "name_nl": "Thuisteam Na extra punten",
        "name_pl": "home after extra points",
        "name_ro": "home after extra points",
        "name_ru": "home after extra points",
        "name_tr": "home after extra points",
        "name_se": "home after extra points",
        "name_el": "home after extra points",
        "name_es": "home after extra points",
        "name_hr": "home after extra points",
        "name_fr": "home after extra points"
    },
    "2213^live_match^8^home_7-12": {
        "name_en": "home 7-12",
        "name_aa": "home 7-12",
        "name_de": "Heim 7-12",
        "name_nl": "Thuis 7-12",
        "name_pl": "home 7-12",
        "name_ro": "home 7-12",
        "name_ru": "home 7-12",
        "name_tr": "home 7-12",
        "name_se": "home 7-12",
        "name_el": "home 7-12",
        "name_es": "home 7-12",
        "name_hr": "Domači  7-12",
        "name_fr": "home 7-12"
    },
    "930^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "26^live_match^8^11:5": {
        "name_en": "11:5",
        "name_aa": "11:5",
        "name_de": "11:5",
        "name_nl": "11:5",
        "name_pl": "11:5",
        "name_ro": "11:5",
        "name_ru": "11:5",
        "name_tr": "11:5",
        "name_se": "11:5",
        "name_el": "11:5",
        "name_es": "11:5",
        "name_hr": "11:5",
        "name_fr": "11:5"
    },
    "2171^live_match^8^no": {
        "name_en": "no",
        "name_aa": "no",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "no",
        "name_ro": "no",
        "name_ru": "no",
        "name_tr": "no",
        "name_se": "no",
        "name_el": "no",
        "name_es": "no",
        "name_hr": "Ne",
        "name_fr": "no"
    },
    "2054^live_match^8^1x": {
        "name_en": "1X",
        "name_aa": "1X",
        "name_de": "1X",
        "name_nl": "1X",
        "name_pl": "1X",
        "name_ro": "1X",
        "name_ru": "1X",
        "name_tr": "1X",
        "name_se": "1X",
        "name_el": "1X",
        "name_es": "1X",
        "name_hr": "1X",
        "name_fr": "1X"
    },
    "2211^live_match^8^draw": {
        "name_en": "draw",
        "name_aa": "draw",
        "name_de": "Unentschieden",
        "name_nl": "Gelijkspel",
        "name_pl": "draw",
        "name_ro": "draw",
        "name_ru": "draw",
        "name_tr": "draw",
        "name_se": "draw",
        "name_el": "draw",
        "name_es": "draw",
        "name_hr": "Neriješeno",
        "name_fr": "draw"
    },
    "2307^live_match^8^1:5": {
        "name_en": "1:5",
        "name_aa": "1:5",
        "name_de": "1:5",
        "name_nl": "1:5",
        "name_pl": "1:5",
        "name_ro": "1:5",
        "name_ru": "1:5",
        "name_tr": "1:5",
        "name_se": "1:5",
        "name_el": "1:5",
        "name_es": "1:5",
        "name_hr": "1:5",
        "name_fr": "1:5"
    },
    "1156^live_match^8^0:1": {
        "name_en": "0:1",
        "name_aa": "0:1",
        "name_de": "0:1",
        "name_nl": "0:1",
        "name_pl": "0:1",
        "name_ro": "0:1",
        "name_ru": "0:1",
        "name_tr": "0:1",
        "name_se": "0:1",
        "name_el": "0:1",
        "name_es": "0:1",
        "name_hr": "0:1",
        "name_fr": "0:1"
    },
    "29^live_match^8^10:1": {
        "name_en": "10:1",
        "name_aa": "10:1",
        "name_de": "10:1",
        "name_nl": "10:1",
        "name_pl": "10:1",
        "name_ro": "10:1",
        "name_ru": "10:1",
        "name_tr": "10:1",
        "name_se": "10:1",
        "name_el": "10:1",
        "name_es": "10:1",
        "name_hr": "10:1",
        "name_fr": "10:1"
    },
    "96^live_match^8^player_1_to_30": {
        "name_en": "Player 1 to 30",
        "name_aa": "Player 1 to 30",
        "name_de": "Spieler 1 zu 30",
        "name_nl": "Speler 1 tot 30",
        "name_pl": "Player 1 to 30",
        "name_ro": "Player 1 to 30",
        "name_ru": "Player 1 to 30",
        "name_tr": "1. Oyuncu 30'a karşı",
        "name_se": "Player 1 to 30",
        "name_el": "Player 1 to 30",
        "name_es": "Player 1 to 30",
        "name_hr": "Igrač 1 osvojio gem (protivnik osvojio 30 poena)",
        "name_fr": "Joueur 1 à 30"
    },
    "29^live_match^8^1:8": {
        "name_en": "1:8",
        "name_aa": "1:8",
        "name_de": "1:8",
        "name_nl": "1:8",
        "name_pl": "1:8",
        "name_ro": "1:8",
        "name_ru": "1:8",
        "name_tr": "1:8",
        "name_se": "1:8",
        "name_el": "1:8",
        "name_es": "1:8",
        "name_hr": "1:8",
        "name_fr": "1:8"
    },
    "2354^live_match^8^4:1": {
        "name_en": "4:1",
        "name_aa": "4:1",
        "name_de": "4:1",
        "name_nl": "4:1",
        "name_pl": "4:1",
        "name_ro": "4:1",
        "name_ru": "4:1",
        "name_tr": "4:1",
        "name_se": "4:1",
        "name_el": "4:1",
        "name_es": "4:1",
        "name_hr": "4:1",
        "name_fr": "4:1"
    },
    "2213^live_match^8^draw": {
        "name_en": "draw",
        "name_aa": "draw",
        "name_de": "Unentschieden",
        "name_nl": "Gelijkspel",
        "name_pl": "draw",
        "name_ro": "draw",
        "name_ru": "draw",
        "name_tr": "draw",
        "name_se": "draw",
        "name_el": "draw",
        "name_es": "draw",
        "name_hr": "Neriješeno",
        "name_fr": "draw"
    },
    "2057^live_match^8^1x": {
        "name_en": "1X",
        "name_aa": "1X",
        "name_de": "1X",
        "name_nl": "1X",
        "name_pl": "1X",
        "name_ro": "1X",
        "name_ru": "1X",
        "name_tr": "1X",
        "name_se": "1X",
        "name_el": "1X",
        "name_es": "1X",
        "name_hr": "1X",
        "name_fr": "1X"
    },
    "345^live_match^8^odd": {
        "name_en": "odd",
        "name_aa": "odd",
        "name_de": "Ungerade",
        "name_nl": "oneven",
        "name_pl": "odd",
        "name_ro": "odd",
        "name_ru": "odd",
        "name_tr": "tek",
        "name_se": "odd",
        "name_el": "odd",
        "name_es": "odd",
        "name_hr": "Neparan",
        "name_fr": "impair"
    },
    "2261^live_match^8^7:0": {
        "name_en": "7:0",
        "name_aa": "7:0",
        "name_de": "7:0",
        "name_nl": "7:0",
        "name_pl": "7:0",
        "name_ro": "7:0",
        "name_ru": "7:0",
        "name_tr": "7:0",
        "name_se": "7:0",
        "name_el": "7:0",
        "name_es": "7:0",
        "name_hr": "7:0",
        "name_fr": "7:0"
    },
    "958^live_match^6^x": {
        "name_en": "x",
        "name_aa": "x",
        "name_de": "x",
        "name_nl": "x",
        "name_pl": "x",
        "name_ro": "x",
        "name_ru": "x",
        "name_tr": "x",
        "name_se": "x",
        "name_el": "x",
        "name_es": "x",
        "name_hr": "x",
        "name_fr": "x"
    },
    "2354^live_match^8^5:6": {
        "name_en": "5:6",
        "name_aa": "5:6",
        "name_de": "5:6",
        "name_nl": "5:6",
        "name_pl": "5:6",
        "name_ro": "5:6",
        "name_ru": "5:6",
        "name_tr": "5:6",
        "name_se": "5:6",
        "name_el": "5:6",
        "name_es": "5:6",
        "name_hr": "5:6",
        "name_fr": "5:6"
    },
    "2110^live_match^8^regulation": {
        "name_en": "Regulation",
        "name_aa": "Regulation",
        "name_de": "Regyläre spielzeit",
        "name_nl": "Reguliere speeltijd",
        "name_pl": "Regulation",
        "name_ro": "Regulation",
        "name_ru": "Regulation",
        "name_tr": "Regulation",
        "name_se": "Regulation",
        "name_el": "Regulation",
        "name_es": "Regulation",
        "name_hr": "Regulation",
        "name_fr": "Regulation"
    },
    "2204^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2261^live_match^8^3:6": {
        "name_en": "3:6",
        "name_aa": "3:6",
        "name_de": "3:6",
        "name_nl": "3:6",
        "name_pl": "3:6",
        "name_ro": "3:6",
        "name_ru": "3:6",
        "name_tr": "3:6",
        "name_se": "3:6",
        "name_el": "3:6",
        "name_es": "3:6",
        "name_hr": "3:6",
        "name_fr": "3:6"
    },
    "2354^live_match^8^5:8": {
        "name_en": "5:8",
        "name_aa": "5:8",
        "name_de": "5:8",
        "name_nl": "5:8",
        "name_pl": "5:8",
        "name_ro": "5:8",
        "name_ru": "5:8",
        "name_tr": "5:8",
        "name_se": "5:8",
        "name_el": "5:8",
        "name_es": "5:8",
        "name_hr": "5:8",
        "name_fr": "5:8"
    },
    "63^live_match^8^yes": {
        "name_en": "Yes",
        "name_aa": "Yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "Yes",
        "name_ro": "Yes",
        "name_ru": "Yes",
        "name_tr": "evet",
        "name_se": "Yes",
        "name_el": "Yes",
        "name_es": "Yes",
        "name_hr": "Da",
        "name_fr": "oui"
    },
    "4^live_match^6^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "26^live_match^8^1:12": {
        "name_en": "1:12",
        "name_aa": "1:12",
        "name_de": "1:12",
        "name_nl": "1:12",
        "name_pl": "1:12",
        "name_ro": "1:12",
        "name_ru": "1:12",
        "name_tr": "1:12",
        "name_se": "1:12",
        "name_el": "1:12",
        "name_es": "1:12",
        "name_hr": "1:12",
        "name_fr": "1:12"
    },
    "2033^live_match^8^away_and_over": {
        "name_en": "away and over",
        "name_aa": "away and over",
        "name_de": "Auswärt und Über",
        "name_nl": "Uit and Meer dan",
        "name_pl": "away and over",
        "name_ro": "away and over",
        "name_ru": "away and over",
        "name_tr": "away and over",
        "name_se": "away and over",
        "name_el": "away and over",
        "name_es": "away and over",
        "name_hr": "Gostujući i više",
        "name_fr": "away and over"
    },
    "2058^live_match^8^ah": {
        "name_en": "AH",
        "name_aa": "AH",
        "name_de": "AH",
        "name_nl": "AH",
        "name_pl": "AH",
        "name_ro": "AH",
        "name_ru": "AH",
        "name_tr": "AH",
        "name_se": "AH",
        "name_el": "AH",
        "name_es": "AH",
        "name_hr": "AH",
        "name_fr": "AH"
    },
    "103^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2212^live_match^8^away_1-12": {
        "name_en": "away 1-12",
        "name_aa": "away 1-12",
        "name_de": "Auswärt 1-12",
        "name_nl": "Weg 1-12",
        "name_pl": "away 1-12",
        "name_ro": "away 1-12",
        "name_ru": "away 1-12",
        "name_tr": "away 1-12",
        "name_se": "away 1-12",
        "name_el": "away 1-12",
        "name_es": "away 1-12",
        "name_hr": "Gostujuči 1-12",
        "name_fr": "away 1-12"
    },
    "2157^live_match^8^15:21": {
        "name_en": "15:21",
        "name_aa": "15:21",
        "name_de": "15:21",
        "name_nl": "15:21",
        "name_pl": "15:21",
        "name_ro": "15:21",
        "name_ru": "15:21",
        "name_tr": "15:21",
        "name_se": "15:21",
        "name_el": "15:21",
        "name_es": "15:21",
        "name_hr": "15:21",
        "name_fr": "15:21"
    },
    "2097^live_match^6^x": {
        "name_en": "x",
        "name_aa": "x",
        "name_de": "x",
        "name_nl": "x",
        "name_pl": "x",
        "name_ro": "x",
        "name_ru": "x",
        "name_tr": "x",
        "name_se": "x",
        "name_el": "x",
        "name_es": "x",
        "name_hr": "x",
        "name_fr": "x"
    },
    "2052^live_match^6^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2211^live_match^8^away_1-18": {
        "name_en": "away 1-18",
        "name_aa": "away 1-18",
        "name_de": "Auswärt 1-18",
        "name_nl": "Weg 1-18",
        "name_pl": "away 1-18",
        "name_ro": "away 1-18",
        "name_ru": "away 1-18",
        "name_tr": "away 1-18",
        "name_se": "away 1-18",
        "name_el": "away 1-18",
        "name_es": "away 1-18",
        "name_hr": "Gostujuči 1-18",
        "name_fr": "away 1-18"
    },
    "2128^live_match^8^away_after_extra_points": {
        "name_en": "away after extra points",
        "name_aa": "away after extra points",
        "name_de": "Auswärtsmannschaft Nach Verlängerung",
        "name_nl": "Uitteam Na extra punten",
        "name_pl": "away after extra points",
        "name_ro": "away after extra points",
        "name_ru": "away after extra points",
        "name_tr": "away after extra points",
        "name_se": "away after extra points",
        "name_el": "away after extra points",
        "name_es": "away after extra points",
        "name_hr": "away after extra points",
        "name_fr": "away after extra points"
    },
    "29^live_match^8^1:9": {
        "name_en": "1:9",
        "name_aa": "1:9",
        "name_de": "1:9",
        "name_nl": "1:9",
        "name_pl": "1:9",
        "name_ro": "1:9",
        "name_ru": "1:9",
        "name_tr": "1:9",
        "name_se": "1:9",
        "name_el": "1:9",
        "name_es": "1:9",
        "name_hr": "1:9",
        "name_fr": "1:9"
    },
    "29^live_match^8^1:7": {
        "name_en": "1:7",
        "name_aa": "1:7",
        "name_de": "1:7",
        "name_nl": "1:7",
        "name_pl": "1:7",
        "name_ro": "1:7",
        "name_ru": "1:7",
        "name_tr": "1:7",
        "name_se": "1:7",
        "name_el": "1:7",
        "name_es": "1:7",
        "name_hr": "1:7",
        "name_fr": "1:7"
    },
    "1372^live_match^6^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "26^live_match^8^7:5": {
        "name_en": "7:5",
        "name_aa": "7:5",
        "name_de": "7:5",
        "name_nl": "7:5",
        "name_pl": "7:5",
        "name_ro": "7:5",
        "name_ru": "7:5",
        "name_tr": "7:5",
        "name_se": "7:5",
        "name_el": "7:5",
        "name_es": "7:5",
        "name_hr": "7:5",
        "name_fr": "7:5"
    },
    "2336^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "2025^live_match^8^away": {
        "name_en": "away",
        "name_aa": "away",
        "name_de": "Auswärts",
        "name_nl": "Uit",
        "name_pl": "away",
        "name_ro": "away",
        "name_ru": "away",
        "name_tr": "away",
        "name_se": "away",
        "name_el": "away",
        "name_es": "away",
        "name_hr": "Gostujući",
        "name_fr": "away"
    },
    "1032^live_match^8^6": {
        "name_en": "6",
        "name_aa": "6",
        "name_de": "6",
        "name_nl": "6",
        "name_pl": "6",
        "name_ro": "6",
        "name_ru": "6",
        "name_tr": "6",
        "name_se": "6",
        "name_el": "6",
        "name_es": "6",
        "name_hr": "6",
        "name_fr": "6"
    },
    "2140^live_match^8^home": {
        "name_en": "home",
        "name_aa": "home",
        "name_de": "Heim",
        "name_nl": "Thuis",
        "name_pl": "home",
        "name_ro": "home",
        "name_ru": "home",
        "name_tr": "home",
        "name_se": "home",
        "name_el": "home",
        "name_es": "home",
        "name_hr": "Domači",
        "name_fr": "home"
    },
    "924^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "117^live_match^8^0:3": {
        "name_en": "0:3",
        "name_aa": "0:3",
        "name_de": "0:3",
        "name_nl": "0:3",
        "name_pl": "0:3",
        "name_ro": "0:3",
        "name_ru": "0:3",
        "name_tr": "0:3",
        "name_se": "0:3",
        "name_el": "0:3",
        "name_es": "0:3",
        "name_hr": "0:3",
        "name_fr": "0:3"
    },
    "63^live_match^8^no": {
        "name_en": "No",
        "name_aa": "No",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "No",
        "name_ro": "No",
        "name_ru": "No",
        "name_tr": "hayır",
        "name_se": "No",
        "name_el": "No",
        "name_es": "No",
        "name_hr": "Ne",
        "name_fr": "non"
    },
    "29^live_match^8^6:3": {
        "name_en": "6:3",
        "name_aa": "6:3",
        "name_de": "6:3",
        "name_nl": "6:3",
        "name_pl": "6:3",
        "name_ro": "6:3",
        "name_ru": "6:3",
        "name_tr": "6:3",
        "name_se": "6:3",
        "name_el": "6:3",
        "name_es": "6:3",
        "name_hr": "6:3",
        "name_fr": "6:3"
    },
    "2200^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "29^live_match^8^6:4": {
        "name_en": "6:4",
        "name_aa": "6:4",
        "name_de": "6:4",
        "name_nl": "6:4",
        "name_pl": "6:4",
        "name_ro": "6:4",
        "name_ru": "6:4",
        "name_tr": "6:4",
        "name_se": "6:4",
        "name_el": "6:4",
        "name_es": "6:4",
        "name_hr": "6:4",
        "name_fr": "6:4"
    },
    "1108^live_match^8^8:9": {
        "name_en": "8:9",
        "name_aa": "8:9",
        "name_de": "8:9",
        "name_nl": "8:9",
        "name_pl": "8:9",
        "name_ro": "8:9",
        "name_ru": "8:9",
        "name_tr": "8:9",
        "name_se": "8:9",
        "name_el": "8:9",
        "name_es": "8:9",
        "name_hr": "8:9",
        "name_fr": "8:9"
    },
    "237^live_match^8^dh": {
        "name_en": "DH",
        "name_aa": "DH",
        "name_de": "U-H",
        "name_nl": "DH",
        "name_pl": "DH",
        "name_ro": "DH",
        "name_ru": "DH",
        "name_tr": "BE",
        "name_se": "DH",
        "name_el": "DH",
        "name_es": "DH",
        "name_hr": "X-1",
        "name_fr": "ND"
    },
    "2261^live_match^8^6:1": {
        "name_en": "6:1",
        "name_aa": "6:1",
        "name_de": "6:1",
        "name_nl": "6:1",
        "name_pl": "6:1",
        "name_ro": "6:1",
        "name_ru": "6:1",
        "name_tr": "6:1",
        "name_se": "6:1",
        "name_el": "6:1",
        "name_es": "6:1",
        "name_hr": "6:1",
        "name_fr": "6:1"
    },
    "2354^live_match^8^0:10": {
        "name_en": "0:10",
        "name_aa": "0:10",
        "name_de": "0:10",
        "name_nl": "0:10",
        "name_pl": "0:10",
        "name_ro": "0:10",
        "name_ru": "0:10",
        "name_tr": "0:10",
        "name_se": "0:10",
        "name_el": "0:10",
        "name_es": "0:10",
        "name_hr": "0:10",
        "name_fr": "0:10"
    },
    "29^live_match^8^0:7": {
        "name_en": "0:7",
        "name_aa": "0:7",
        "name_de": "0:7",
        "name_nl": "0:7",
        "name_pl": "0:7",
        "name_ro": "0:7",
        "name_ru": "0:7",
        "name_tr": "0:7",
        "name_se": "0:7",
        "name_el": "0:7",
        "name_es": "0:7",
        "name_hr": "0:7",
        "name_fr": "0:7"
    },
    "1108^live_match^8^7:1": {
        "name_en": "7:1",
        "name_aa": "7:1",
        "name_de": "7:1",
        "name_nl": "7:1",
        "name_pl": "7:1",
        "name_ro": "7:1",
        "name_ru": "7:1",
        "name_tr": "7:1",
        "name_se": "7:1",
        "name_el": "7:1",
        "name_es": "7:1",
        "name_hr": "7:1",
        "name_fr": "7:1"
    },
    "2072^live_match^8^25:15_or_better": {
        "name_en": "25:15 or better",
        "name_aa": "25:15 or better",
        "name_de": "25:15 oder besser",
        "name_nl": "25:15 of beter",
        "name_pl": "25:15 or better",
        "name_ro": "25:15 or better",
        "name_ru": "25:15 or better",
        "name_tr": "25:15 or better",
        "name_se": "25:15 or better",
        "name_el": "25:15 or better",
        "name_es": "25:15 or better",
        "name_hr": "25:15 or better",
        "name_fr": "25:15 or better"
    },
    "2261^live_match^8^7:3": {
        "name_en": "7:3",
        "name_aa": "7:3",
        "name_de": "7:3",
        "name_nl": "7:3",
        "name_pl": "7:3",
        "name_ro": "7:3",
        "name_ru": "7:3",
        "name_tr": "7:3",
        "name_se": "7:3",
        "name_el": "7:3",
        "name_es": "7:3",
        "name_hr": "7:3",
        "name_fr": "7:3"
    },
    "2009^live_match^6^over": {
        "name_en": "over",
        "name_aa": "over",
        "name_de": "Über",
        "name_nl": "Meer dan",
        "name_pl": "over",
        "name_ro": "over",
        "name_ru": "over",
        "name_tr": "over",
        "name_se": "over",
        "name_el": "over",
        "name_es": "over",
        "name_hr": "Više",
        "name_fr": "over"
    },
    "2073^live_match^8^home_after_extra_points": {
        "name_en": "home after extra points",
        "name_aa": "home after extra points",
        "name_de": "Heimmannschaft Nach Verlängerung",
        "name_nl": "Thuisteam Na extra punten",
        "name_pl": "home after extra points",
        "name_ro": "home after extra points",
        "name_ru": "home after extra points",
        "name_tr": "home after extra points",
        "name_se": "home after extra points",
        "name_el": "home after extra points",
        "name_es": "home after extra points",
        "name_hr": "home after extra points",
        "name_fr": "home after extra points"
    },
    "2018^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "2061^live_match^8^home_9-10": {
        "name_en": "home 9-10",
        "name_aa": "home 9-10",
        "name_de": "Heim 9-10",
        "name_nl": "thuis 9-10",
        "name_pl": "home 9-10",
        "name_ro": "home 9-10",
        "name_ru": "home 9-10",
        "name_tr": "home 9-10",
        "name_se": "home 9-10",
        "name_el": "home 9-10",
        "name_es": "home 9-10",
        "name_hr": "Domači 9-10",
        "name_fr": "home 9-10"
    },
    "2076^live_match^8^7:15": {
        "name_en": "7:15",
        "name_aa": "7:15",
        "name_de": "7:15",
        "name_nl": "7:15",
        "name_pl": "7:15",
        "name_ro": "7:15",
        "name_ru": "7:15",
        "name_tr": "7:15",
        "name_se": "7:15",
        "name_el": "7:15",
        "name_es": "7:15",
        "name_hr": "7:15",
        "name_fr": "7:15"
    },
    "2189^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "2016^live_match^8^under": {
        "name_en": "under",
        "name_aa": "under",
        "name_de": "Unter",
        "name_nl": "Minder dan",
        "name_pl": "under",
        "name_ro": "under",
        "name_ru": "under",
        "name_tr": "under",
        "name_se": "under",
        "name_el": "under",
        "name_es": "under",
        "name_hr": "Manje",
        "name_fr": "under"
    },
    "29^live_match^8^6:1": {
        "name_en": "6:1",
        "name_aa": "6:1",
        "name_de": "6:1",
        "name_nl": "6:1",
        "name_pl": "6:1",
        "name_ro": "6:1",
        "name_ru": "6:1",
        "name_tr": "6:1",
        "name_se": "6:1",
        "name_el": "6:1",
        "name_es": "6:1",
        "name_hr": "6:1",
        "name_fr": "6:1"
    },
    "50^live_match^8^over": {
        "name_en": "Over",
        "name_aa": "Over",
        "name_de": "Über",
        "name_nl": "Over",
        "name_pl": "Over",
        "name_ro": "Over",
        "name_ru": "Over",
        "name_tr": "Üst",
        "name_se": "Over",
        "name_el": "Over",
        "name_es": "Over",
        "name_hr": "Više",
        "name_fr": "p"
    },
    "22^live_match^6^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "26^live_match^8^5:7": {
        "name_en": "5:7",
        "name_aa": "5:7",
        "name_de": "5:7",
        "name_nl": "5:7",
        "name_pl": "5:7",
        "name_ro": "5:7",
        "name_ru": "5:7",
        "name_tr": "5:7",
        "name_se": "5:7",
        "name_el": "5:7",
        "name_es": "5:7",
        "name_hr": "5:7",
        "name_fr": "5:7"
    },
    "2259^live_match^8^away_and_over": {
        "name_en": "away and over",
        "name_aa": "away and over",
        "name_de": "Auswärt und Über",
        "name_nl": "Uit and Meer dan",
        "name_pl": "away and over",
        "name_ro": "away and over",
        "name_ru": "away and over",
        "name_tr": "away and over",
        "name_se": "away and over",
        "name_el": "away and over",
        "name_es": "away and over",
        "name_hr": "Gostujući i više",
        "name_fr": "away and over"
    },
    "2354^live_match^8^2:13": {
        "name_en": "2:13",
        "name_aa": "2:13",
        "name_de": "2:13",
        "name_nl": "2:13",
        "name_pl": "2:13",
        "name_ro": "2:13",
        "name_ru": "2:13",
        "name_tr": "2:13",
        "name_se": "2:13",
        "name_el": "2:13",
        "name_es": "2:13",
        "name_hr": "2:13",
        "name_fr": "2:13"
    },
    "2147^live_match^8^home_2+": {
        "name_en": "home 2+",
        "name_aa": "home 2+",
        "name_de": "Heim 2+",
        "name_nl": "Thuis 2+",
        "name_pl": "home 2+",
        "name_ro": "home 2+",
        "name_ru": "home 2+",
        "name_tr": "home 2+",
        "name_se": "home 2+",
        "name_el": "home 2+",
        "name_es": "home 2+",
        "name_hr": "Domači 2+",
        "name_fr": "home 2+"
    },
    "1230^live_match^8^100-119": {
        "name_en": "100-119",
        "name_aa": "100-119",
        "name_de": "100-119",
        "name_nl": "0-49",
        "name_pl": "100-119",
        "name_ro": "100-119",
        "name_ru": "100-119",
        "name_tr": "100-119",
        "name_se": "100-119",
        "name_el": "100-119",
        "name_es": "100-119",
        "name_hr": "100-119",
        "name_fr": "100-119"
    },
    "1580^live_match^6^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "2129^live_match^8^0:2": {
        "name_en": "0:2",
        "name_aa": "0:2",
        "name_de": "0:2",
        "name_nl": "0:2",
        "name_pl": "0:2",
        "name_ro": "0:2",
        "name_ru": "0:2",
        "name_tr": "0:2",
        "name_se": "0:2",
        "name_el": "0:2",
        "name_es": "0:2",
        "name_hr": "0:2",
        "name_fr": "0:2"
    },
    "2348^live_match^8^goal": {
        "name_en": "goal",
        "name_aa": "goal",
        "name_de": "Tor",
        "name_nl": "Doelpunt",
        "name_pl": "goal",
        "name_ro": "goal",
        "name_ru": "goal",
        "name_tr": "goal",
        "name_se": "goal",
        "name_el": "goal",
        "name_es": "goal",
        "name_hr": "Golova",
        "name_fr": "goal"
    },
    "1066^live_match^8^1:0": {
        "name_en": "1:0",
        "name_aa": "1:0",
        "name_de": "1:0",
        "name_nl": "1:0",
        "name_pl": "1:0",
        "name_ro": "1:0",
        "name_ru": "1:0",
        "name_tr": "1:0",
        "name_se": "1:0",
        "name_el": "1:0",
        "name_es": "1:0",
        "name_hr": "1:0",
        "name_fr": "1:0"
    },
    "2134^live_match^8^no": {
        "name_en": "no",
        "name_aa": "no",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "no",
        "name_ro": "no",
        "name_ru": "no",
        "name_tr": "no",
        "name_se": "no",
        "name_el": "no",
        "name_es": "no",
        "name_hr": "Ne",
        "name_fr": "no"
    },
    "2081^live_match^8^4:0": {
        "name_en": "4:0",
        "name_aa": "4:0",
        "name_de": "4:0",
        "name_nl": "4:0",
        "name_pl": "4:0",
        "name_ro": "4:0",
        "name_ru": "4:0",
        "name_tr": "4:0",
        "name_se": "4:0",
        "name_el": "4:0",
        "name_es": "4:0",
        "name_hr": "4:0",
        "name_fr": "4:0"
    },
    "26^live_match^8^6:6": {
        "name_en": "6:6",
        "name_aa": "6:6",
        "name_de": "6:6",
        "name_nl": "6:6",
        "name_pl": "6:6",
        "name_ro": "6:6",
        "name_ru": "6:6",
        "name_tr": "6:6",
        "name_se": "6:6",
        "name_el": "6:6",
        "name_es": "6:6",
        "name_hr": "6:6",
        "name_fr": "6:6"
    },
    "2100^live_match^8^yes": {
        "name_en": "yes",
        "name_aa": "yes",
        "name_de": "Ja",
        "name_nl": "Ja",
        "name_pl": "yes",
        "name_ro": "yes",
        "name_ru": "yes",
        "name_tr": "yes",
        "name_se": "yes",
        "name_el": "yes",
        "name_es": "yes",
        "name_hr": "Da",
        "name_fr": "yes"
    },
    "2354^live_match^8^1:6": {
        "name_en": "1:6",
        "name_aa": "1:6",
        "name_de": "1:6",
        "name_nl": "1:6",
        "name_pl": "1:6",
        "name_ro": "1:6",
        "name_ru": "1:6",
        "name_tr": "1:6",
        "name_se": "1:6",
        "name_el": "1:6",
        "name_es": "1:6",
        "name_hr": "1:6",
        "name_fr": "1:6"
    },
    "103^live_match^7^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2060^live_match^8^away_7-8": {
        "name_en": "away 7-8",
        "name_aa": "away 7-8",
        "name_de": "Auswärt 7-8",
        "name_nl": "Weg 7-8",
        "name_pl": "away 7-8",
        "name_ro": "away 7-8",
        "name_ru": "away 7-8",
        "name_tr": "away 7-8",
        "name_se": "away 7-8",
        "name_el": "away 7-8",
        "name_es": "away 7-8",
        "name_hr": "Gostujuči 7-8",
        "name_fr": "away 7-8"
    },
    "7^live_match^7^2": {
        "name_en": "Over",
        "name_aa": "Over",
        "name_de": "Über",
        "name_nl": "Over",
        "name_pl": "Over",
        "name_ro": "Over",
        "name_ru": "Over",
        "name_tr": "Üst",
        "name_se": "Over",
        "name_el": "Over",
        "name_es": "Over",
        "name_hr": "Više",
        "name_fr": "p"
    },
    "2354^live_match^8^9:2": {
        "name_en": "9:2",
        "name_aa": "9:2",
        "name_de": "9:2",
        "name_nl": "9:2",
        "name_pl": "9:2",
        "name_ro": "9:2",
        "name_ru": "9:2",
        "name_tr": "9:2",
        "name_se": "9:2",
        "name_el": "9:2",
        "name_es": "9:2",
        "name_hr": "9:2",
        "name_fr": "9:2"
    },
    "94^live_match^8^even": {
        "name_en": "even",
        "name_aa": "even",
        "name_de": "Gerade",
        "name_nl": "even",
        "name_pl": "even",
        "name_ro": "even",
        "name_ru": "even",
        "name_tr": "çift",
        "name_se": "even",
        "name_el": "even",
        "name_es": "even",
        "name_hr": "Paran",
        "name_fr": "pair"
    },
    "2061^live_match^8^home_7-8": {
        "name_en": "home 7-8",
        "name_aa": "home 7-8",
        "name_de": "Heim 7-8",
        "name_nl": "thuis 7-8",
        "name_pl": "home 7-8",
        "name_ro": "home 7-8",
        "name_ru": "home 7-8",
        "name_tr": "home 7-8",
        "name_se": "home 7-8",
        "name_el": "home 7-8",
        "name_es": "home 7-8",
        "name_hr": "Domači 7-8",
        "name_fr": "home 7-8"
    },
    "26^live_match^8^4:11": {
        "name_en": "4:11",
        "name_aa": "4:11",
        "name_de": "4:11",
        "name_nl": "4:11",
        "name_pl": "4:11",
        "name_ro": "4:11",
        "name_ru": "4:11",
        "name_tr": "4:11",
        "name_se": "4:11",
        "name_el": "4:11",
        "name_es": "4:11",
        "name_hr": "4:11",
        "name_fr": "4:11"
    },
    "233^live_match^8^under": {
        "name_en": "Under",
        "name_aa": "u",
        "name_de": "Unter",
        "name_nl": "u",
        "name_pl": "u",
        "name_ro": "u",
        "name_ru": "u",
        "name_tr": "Alt",
        "name_se": "u",
        "name_el": "u",
        "name_es": "u",
        "name_hr": "Manje",
        "name_fr": "m"
    },
    "2073^live_match^8^17:25": {
        "name_en": "17:25",
        "name_aa": "17:25",
        "name_de": "17:25",
        "name_nl": "17:25",
        "name_pl": "17:25",
        "name_ro": "17:25",
        "name_ru": "17:25",
        "name_tr": "17:25",
        "name_se": "17:25",
        "name_el": "17:25",
        "name_es": "17:25",
        "name_hr": "17:25",
        "name_fr": "17:25"
    },
    "26^live_match^8^5:8": {
        "name_en": "5:8",
        "name_aa": "5:8",
        "name_de": "5:8",
        "name_nl": "5:8",
        "name_pl": "5:8",
        "name_ro": "5:8",
        "name_ru": "5:8",
        "name_tr": "5:8",
        "name_se": "5:8",
        "name_el": "5:8",
        "name_es": "5:8",
        "name_hr": "5:8",
        "name_fr": "5:8"
    },
    "2151^live_match^8^away": {
        "name_en": "away",
        "name_aa": "away",
        "name_de": "Auswärts",
        "name_nl": "Uit",
        "name_pl": "away",
        "name_ro": "away",
        "name_ru": "away",
        "name_tr": "away",
        "name_se": "away",
        "name_el": "away",
        "name_es": "away",
        "name_hr": "Gostujući",
        "name_fr": "away"
    },
    "285^live_match^6^1": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "31^live_match^8^odd": {
        "name_en": "odd",
        "name_aa": "odd",
        "name_de": "Ungerade",
        "name_nl": "oneven",
        "name_pl": "odd",
        "name_ro": "odd",
        "name_ru": "odd",
        "name_tr": "tek",
        "name_se": "odd",
        "name_el": "odd",
        "name_es": "odd",
        "name_hr": "Neparan",
        "name_fr": "impair"
    },
    "2237^live_match^7^2": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "1523^live_match^8^12_and_over": {
        "name_en": "12 and over",
        "name_aa": "12 and over",
        "name_de": "12 und Über",
        "name_nl": "12 and Meer dan",
        "name_pl": "12 and over",
        "name_ro": "12 and over",
        "name_ru": "12 and over",
        "name_tr": "12 and over",
        "name_se": "12 and over",
        "name_el": "12 and over",
        "name_es": "12 and over",
        "name_hr": "12 i više",
        "name_fr": "12 and over"
    },
    "520^live_match^8^1:6": {
        "name_en": "1:6",
        "name_aa": "1:6",
        "name_de": "1:6",
        "name_nl": "1:6",
        "name_pl": "1:6",
        "name_ro": "1:6",
        "name_ru": "1:6",
        "name_tr": "1:6",
        "name_se": "1:6",
        "name_el": "1:6",
        "name_es": "1:6",
        "name_hr": "1:6",
        "name_fr": "1:6"
    },
    "29^live_match^8^5:5": {
        "name_en": "5:5",
        "name_aa": "5:5",
        "name_de": "5:5",
        "name_nl": "5:5",
        "name_pl": "5:5",
        "name_ro": "5:5",
        "name_ru": "5:5",
        "name_tr": "5:5",
        "name_se": "5:5",
        "name_el": "5:5",
        "name_es": "5:5",
        "name_hr": "5:5",
        "name_fr": "5:5"
    },
    "26^live_match^8^3:5": {
        "name_en": "3:5",
        "name_aa": "3:5",
        "name_de": "3:5",
        "name_nl": "3:5",
        "name_pl": "3:5",
        "name_ro": "3:5",
        "name_ru": "3:5",
        "name_tr": "3:5",
        "name_se": "3:5",
        "name_el": "3:5",
        "name_es": "3:5",
        "name_hr": "3:5",
        "name_fr": "3:5"
    },
    "2082^live_match^8^6:0_or_6:1_or_6:2": {
        "name_en": "6:0 or 6:1 or 6:2",
        "name_aa": "6:0 or 6:1 or 6:2",
        "name_de": "6:0 oder 6:1 oder 6:2",
        "name_nl": "6:0 of 6:1 of 6:2",
        "name_pl": "6:0 or 6:1 or 6:2",
        "name_ro": "6:0 or 6:1 or 6:2",
        "name_ru": "6:0 or 6:1 or 6:2",
        "name_tr": "6:0 or 6:1 or 6:2",
        "name_se": "6:0 or 6:1 or 6:2",
        "name_el": "6:0 or 6:1 or 6:2",
        "name_es": "6:0 or 6:1 or 6:2",
        "name_hr": "6:0 or 6:1 or 6:2",
        "name_fr": "6:0 or 6:1 or 6:2"
    },
    "2342^live_match^8^no": {
        "name_en": "no",
        "name_aa": "no",
        "name_de": "Nein",
        "name_nl": "Nee",
        "name_pl": "no",
        "name_ro": "no",
        "name_ru": "no",
        "name_tr": "no",
        "name_se": "no",
        "name_el": "no",
        "name_es": "no",
        "name_hr": "Ne",
        "name_fr": "no"
    }
}

export const PREMATCH_OUTCOME = {
    "203^prematch": {
        "name_en": "1:7",
        "name_aa": "1:7",
        "name_de": "1:7",
        "name_nl": "1:7",
        "name_pl": "1:7",
        "name_ro": "1:7",
        "name_ru": "1:7",
        "name_tr": "1:7",
        "name_se": "1:7",
        "name_el": "1:7",
        "name_es": "1:7",
        "name_hr": "1:7",
        "name_fr": "1:7"
    },
    "348^prematch": {
        "name_en": "0:1 0:1",
        "name_aa": "0:1 0:1",
        "name_de": "0:1 0:1",
        "name_nl": "0:1 0:1",
        "name_pl": "0:1 0:1",
        "name_ro": "0:1 0:1",
        "name_ru": "0:1 0:1",
        "name_tr": "0:1 0:1",
        "name_se": "0:1 0:1",
        "name_el": "0:1 0:1",
        "name_es": "0:1 0:1",
        "name_hr": "0:1 0:1",
        "name_fr": "0:1 0:1"
    },
    "1484^prematch": {
        "name_en": "3:1",
        "name_aa": "3:1",
        "name_de": "3:1",
        "name_nl": "3:1",
        "name_pl": "3:1",
        "name_ro": "3:1",
        "name_ru": "3:1",
        "name_tr": "3:1",
        "name_se": "3:1",
        "name_el": "3:1",
        "name_es": "3:1",
        "name_hr": "3:1",
        "name_fr": "3:1"
    },
    "1191^prematch": {
        "name_en": "6:1",
        "name_aa": "6:1",
        "name_de": "6:1",
        "name_nl": "6:1",
        "name_pl": "6:1",
        "name_ro": "6:1",
        "name_ru": "6:1",
        "name_tr": "6:1",
        "name_se": "6:1",
        "name_el": "6:1",
        "name_es": "6:1",
        "name_hr": "6:1",
        "name_fr": "6:1"
    },
    "1122^prematch": {
        "name_en": "2-3",
        "name_aa": "2-3",
        "name_de": "2-3",
        "name_nl": "2-3",
        "name_pl": "2-3",
        "name_ro": "2-3",
        "name_ru": "2-3",
        "name_tr": "2-3",
        "name_se": "2-3",
        "name_el": "2-3",
        "name_es": "2-3",
        "name_hr": "2-3",
        "name_fr": "2-3"
    },
    "56^prematch": {
        "name_en": "3",
        "name_aa": "3",
        "name_de": "3",
        "name_nl": "3",
        "name_pl": "3",
        "name_ro": "3",
        "name_ru": "3",
        "name_tr": "3",
        "name_se": "3",
        "name_el": "3",
        "name_es": "3",
        "name_hr": "3",
        "name_fr": "3"
    },
    "70990000^prematch": {
        "name_en": "Neither",
        "name_aa": "Neither",
        "name_de": "Neither",
        "name_nl": "Neither",
        "name_pl": "Neither",
        "name_ro": "Neither",
        "name_ru": "Neither",
        "name_tr": "Neither",
        "name_se": "Neither",
        "name_el": "Neither",
        "name_es": "Neither",
        "name_hr": "Neither",
        "name_fr": "Neither"
    },
    "871^prematch": {
        "name_en": "7:6",
        "name_aa": "7:6",
        "name_de": "7:6",
        "name_nl": "7:6",
        "name_pl": "7:6",
        "name_ro": "7:6",
        "name_ru": "7:6",
        "name_tr": "7:6",
        "name_se": "7:6",
        "name_el": "7:6",
        "name_es": "7:6",
        "name_hr": "7:6",
        "name_fr": "7:6"
    },
    "1845^prematch": {
        "name_en": "{$competitor1}/{$competitor1} & over {total}",
        "name_aa": "{$competitor1}/{$competitor1} & over {total}",
        "name_de": "{$competitor1}/{$competitor1} & über {total}",
        "name_nl": "{$competitor1}/{$competitor1} & boven {total}",
        "name_pl": "{$competitor1}/{$competitor1} & over {total}",
        "name_ro": "{$competitor1}/{$competitor1} & over {total}",
        "name_ru": "{$competitor1}/{$competitor1} & over {total}",
        "name_tr": "{$competitor1}/{$competitor1} & {total} üstü",
        "name_se": "{$competitor1}/{$competitor1} & over {total}",
        "name_el": "{$competitor1}/{$competitor1} & over {total}",
        "name_es": "{$competitor1}/{$competitor1} & over {total}",
        "name_hr": "{$competitor1}/{$competitor1} i više {total}",
        "name_fr": "{$competitor1}/{$competitor1} & Plus de {total}"
    },
    "56190000^prematch": {
        "name_en": "0-4",
        "name_aa": "0-4",
        "name_de": "0-4",
        "name_nl": "0-4",
        "name_pl": "0-4",
        "name_ro": "0-4",
        "name_ru": "0-4",
        "name_tr": "0-4",
        "name_se": "0-4",
        "name_el": "0-4",
        "name_es": "0-4",
        "name_hr": "0-4",
        "name_fr": "0-4"
    },
    "45^prematch": {
        "name_en": "6",
        "name_aa": "6",
        "name_de": "6",
        "name_nl": "6",
        "name_pl": "6",
        "name_ro": "6",
        "name_ru": "6",
        "name_tr": "6",
        "name_se": "6",
        "name_el": "6",
        "name_es": "6",
        "name_hr": "6",
        "name_fr": "6"
    },
    "134^prematch": {
        "name_en": "{$competitor2} by 1-7",
        "name_aa": "{$competitor2} by 1-7",
        "name_de": "{$competitor2} by 1-7",
        "name_nl": "{$competitor2} by 1-7",
        "name_pl": "{$competitor2} by 1-7",
        "name_ro": "{$competitor2} by 1-7",
        "name_ru": "{$competitor2} by 1-7",
        "name_tr": "{$competitor2} by 1-7",
        "name_se": "{$competitor2} by 1-7",
        "name_el": "{$competitor2} by 1-7",
        "name_es": "{$competitor2} by 1-7",
        "name_hr": "{$competitor2} by 1-7",
        "name_fr": "{$competitor2} by 1-7"
    },
    "364^prematch": {
        "name_en": "0:2 0:3",
        "name_aa": "0:2 0:3",
        "name_de": "0:2 0:3",
        "name_nl": "0:2 0:3",
        "name_pl": "0:2 0:3",
        "name_ro": "0:2 0:3",
        "name_ru": "0:2 0:3",
        "name_tr": "0:2 0:3",
        "name_se": "0:2 0:3",
        "name_el": "0:2 0:3",
        "name_es": "0:2 0:3",
        "name_hr": "0:2 0:3",
        "name_fr": "0:2 0:3"
    },
    "1507^prematch": {
        "name_en": "2:5",
        "name_aa": "2:5",
        "name_de": "2:5",
        "name_nl": "2:5",
        "name_pl": "2:5",
        "name_ro": "2:5",
        "name_ru": "2:5",
        "name_tr": "2:5",
        "name_se": "2:5",
        "name_el": "2:5",
        "name_es": "2:5",
        "name_hr": "2:5",
        "name_fr": "2:5"
    },
    "1478^prematch": {
        "name_en": "3:0",
        "name_aa": "3:0",
        "name_de": "3:0",
        "name_nl": "3:0",
        "name_pl": "3:0",
        "name_ro": "3:0",
        "name_ru": "3:0",
        "name_tr": "3:0",
        "name_se": "3:0",
        "name_el": "3:0",
        "name_es": "3:0",
        "name_hr": "3:0",
        "name_fr": "3:0"
    },
    "868^prematch": {
        "name_en": "6:3",
        "name_aa": "6:3",
        "name_de": "6:3",
        "name_nl": "6:3",
        "name_pl": "6:3",
        "name_ro": "6:3",
        "name_ru": "6:3",
        "name_tr": "6:3",
        "name_se": "6:3",
        "name_el": "6:3",
        "name_es": "6:3",
        "name_hr": "6:3",
        "name_fr": "6:3"
    },
    "350^prematch": {
        "name_en": "0:1 0:2",
        "name_aa": "0:1 0:2",
        "name_de": "0:1 0:2",
        "name_nl": "0:1 0:2",
        "name_pl": "0:1 0:2",
        "name_ro": "0:1 0:2",
        "name_ru": "0:1 0:2",
        "name_tr": "0:1 0:2",
        "name_se": "0:1 0:2",
        "name_el": "0:1 0:2",
        "name_es": "0:1 0:2",
        "name_hr": "0:1 0:2",
        "name_fr": "0:1 0:2"
    },
    "1506^prematch": {
        "name_en": "1:5",
        "name_aa": "1:5",
        "name_de": "1:5",
        "name_nl": "1:5",
        "name_pl": "1:5",
        "name_ro": "1:5",
        "name_ru": "1:5",
        "name_tr": "1:5",
        "name_se": "1:5",
        "name_el": "1:5",
        "name_es": "1:5",
        "name_hr": "1:5",
        "name_fr": "1:5"
    },
    "810^prematch": {
        "name_en": "yes/yes",
        "name_aa": "yes/yes",
        "name_de": "ja/ja",
        "name_nl": "ja/ja",
        "name_pl": "yes/yes",
        "name_ro": "yes/yes",
        "name_ru": "yes/yes",
        "name_tr": "var/var",
        "name_se": "yes/yes",
        "name_el": "yes/yes",
        "name_es": "yes/yes",
        "name_hr": "da/da",
        "name_fr": "oui/oui"
    },
    "1716^prematch": {
        "name_en": "no goal",
        "name_aa": "no goal",
        "name_de": "kein Tor",
        "name_nl": "geen doelpunt",
        "name_pl": "no goal",
        "name_ro": "no goal",
        "name_ru": "no goal",
        "name_tr": "gol yok",
        "name_se": "no goal",
        "name_el": "no goal",
        "name_es": "no goal",
        "name_hr": "nema gola",
        "name_fr": "aucun but"
    },
    "1077^prematch": {
        "name_en": "170-189",
        "name_aa": "170-189",
        "name_de": "170-189",
        "name_nl": "170-189",
        "name_pl": "170-189",
        "name_ro": "170-189",
        "name_ru": "170-189",
        "name_tr": "170-189",
        "name_se": "170-189",
        "name_el": "170-189",
        "name_es": "170-189",
        "name_hr": "170-189",
        "name_fr": "170-189"
    },
    "26^prematch": {
        "name_en": "5:4",
        "name_aa": "5:4",
        "name_de": "5:4",
        "name_nl": "5:4",
        "name_pl": "5:4",
        "name_ro": "5:4",
        "name_ru": "5:4",
        "name_tr": "5:4",
        "name_se": "5:4",
        "name_el": "5:4",
        "name_es": "5:4",
        "name_hr": "5:4",
        "name_fr": "5:4"
    },
    "1337^prematch": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "630^prematch": {
        "name_en": "{$competitor1} by 1",
        "name_aa": "{$competitor1} by 1",
        "name_de": "{$competitor1} mit 1",
        "name_nl": "{$competitor1} met 1",
        "name_pl": "{$competitor1} by 1",
        "name_ro": "{$competitor1} by 1",
        "name_ru": "{$competitor1} by 1",
        "name_tr": "{$competitor1} 1 farkla",
        "name_se": "{$competitor1} by 1",
        "name_el": "{$competitor1} by 1",
        "name_es": "{$competitor1} by 1",
        "name_hr": "{$competitor1} za 1",
        "name_fr": "{$competitor1} par 1"
    },
    "1339^prematch": {
        "name_en": "3",
        "name_aa": "3",
        "name_de": "3",
        "name_nl": "3",
        "name_pl": "3",
        "name_ro": "3",
        "name_ru": "3",
        "name_tr": "3",
        "name_se": "3",
        "name_el": "3",
        "name_es": "3",
        "name_hr": "3",
        "name_fr": "3"
    },
    "307^prematch": {
        "name_en": "1:11",
        "name_aa": "1:11",
        "name_de": "1:11",
        "name_nl": "1:11",
        "name_pl": "1:11",
        "name_ro": "1:11",
        "name_ru": "1:11",
        "name_tr": "1:11",
        "name_se": "1:11",
        "name_el": "1:11",
        "name_es": "1:11",
        "name_hr": "1:11",
        "name_fr": "1:11"
    },
    "412^prematch": {
        "name_en": "3:0 3:0",
        "name_aa": "3:0 3:0",
        "name_de": "3:0 3:0",
        "name_nl": "3:0 3:0",
        "name_pl": "3:0 3:0",
        "name_ro": "3:0 3:0",
        "name_ru": "3:0 3:0",
        "name_tr": "3:0 3:0",
        "name_se": "3:0 3:0",
        "name_el": "3:0 3:0",
        "name_es": "3:0 3:0",
        "name_hr": "3:0 3:0",
        "name_fr": "3:0 3:0"
    },
    "592^prematch": {
        "name_en": "61-75",
        "name_aa": "61-75",
        "name_de": "61-75",
        "name_nl": "61-75",
        "name_pl": "61-75",
        "name_ro": "61-75",
        "name_ru": "61-75",
        "name_tr": "61-75",
        "name_se": "61-75",
        "name_el": "61-75",
        "name_es": "61-75",
        "name_hr": "61-75",
        "name_fr": "61-75"
    },
    "176^prematch": {
        "name_en": "231-240",
        "name_aa": "231-240",
        "name_de": "231-240",
        "name_nl": "231-240",
        "name_pl": "231-240",
        "name_ro": "231-240",
        "name_ru": "231-240",
        "name_tr": "231-240",
        "name_se": "231-240",
        "name_el": "231-240",
        "name_es": "231-240",
        "name_hr": "231-240",
        "name_fr": "231-240"
    },
    "1843^prematch": {
        "name_en": "{$competitor2}/draw & under {total}",
        "name_aa": "{$competitor2}/draw & under {total}",
        "name_de": "{$competitor2}/unentschieden & unter {total}",
        "name_nl": "{$competitor2}/gelijkspel & onder {total}",
        "name_pl": "{$competitor2}/draw & under {total}",
        "name_ro": "{$competitor2}/draw & under {total}",
        "name_ru": "{$competitor2}/draw & under {total}",
        "name_tr": "{$competitor2}/beraberlik & {total} altı",
        "name_se": "{$competitor2}/draw & under {total}",
        "name_el": "{$competitor2}/draw & under {total}",
        "name_es": "{$competitor2}/draw & under {total}",
        "name_hr": "{$competitor2}/neodlučeno i manje {total}",
        "name_fr": "{$competitor2}/Match nul & Moins de {total}"
    },
    "798^prematch": {
        "name_en": "draw & under {total}",
        "name_aa": "draw & under {total}",
        "name_de": "unentschieden & unter {total}",
        "name_nl": "gelijkspel & onder {total}",
        "name_pl": "draw & under {total}",
        "name_ro": "draw & under {total}",
        "name_ru": "draw & under {total}",
        "name_tr": "beraberlik & {total} altı",
        "name_se": "draw & under {total}",
        "name_el": "draw & under {total}",
        "name_es": "draw & under {total}",
        "name_hr": "neodlučeno i manje od {total}",
        "name_fr": "Match nul & moins de {total}"
    },
    "244^prematch": {
        "name_en": "4:8",
        "name_aa": "4:8",
        "name_de": "4:8",
        "name_nl": "4:8",
        "name_pl": "4:8",
        "name_ro": "4:8",
        "name_ru": "4:8",
        "name_tr": "4:8",
        "name_se": "4:8",
        "name_el": "4:8",
        "name_es": "4:8",
        "name_hr": "4:8",
        "name_fr": "4:8"
    },
    "792^prematch": {
        "name_en": "both teams",
        "name_aa": "both teams",
        "name_de": "beide Mannschaften",
        "name_nl": "beide teams",
        "name_pl": "both teams",
        "name_ro": "both teams",
        "name_ru": "both teams",
        "name_tr": "her iki takım da",
        "name_se": "both teams",
        "name_el": "both teams",
        "name_es": "both teams",
        "name_hr": "oba tima",
        "name_fr": "les deux équipes"
    },
    "48^prematch": {
        "name_en": "0",
        "name_aa": "0",
        "name_de": "0",
        "name_nl": "0",
        "name_pl": "0",
        "name_ro": "0",
        "name_ru": "0",
        "name_tr": "0",
        "name_se": "0",
        "name_el": "0",
        "name_es": "0",
        "name_hr": "0",
        "name_fr": "0"
    },
    "1081^prematch": {
        "name_en": "15-18",
        "name_aa": "15-18",
        "name_de": "15-18",
        "name_nl": "15-18",
        "name_pl": "15-18",
        "name_ro": "15-18",
        "name_ru": "15-18",
        "name_tr": "15-18",
        "name_se": "15-18",
        "name_el": "15-18",
        "name_es": "15-18",
        "name_hr": "15-18",
        "name_fr": "15-18"
    },
    "782^prematch": {
        "name_en": "draw",
        "name_aa": "draw",
        "name_de": "unentschieden",
        "name_nl": "gelijkspel",
        "name_pl": "draw",
        "name_ro": "draw",
        "name_ru": "draw",
        "name_tr": "beraberlik",
        "name_se": "draw",
        "name_el": "draw",
        "name_es": "draw",
        "name_hr": "neodlučeno",
        "name_fr": "Match nul"
    },
    "1694260000^prematch": {
        "name_en": "Under 4.5",
        "name_aa": "Under 4.5",
        "name_de": "Under 4.5",
        "name_nl": "Under 4.5",
        "name_pl": "Under 4.5",
        "name_ro": "Under 4.5",
        "name_ru": "Under 4.5",
        "name_tr": "Under 4.5",
        "name_se": "Under 4.5",
        "name_el": "Under 4.5",
        "name_es": "Under 4.5",
        "name_hr": "Under 4.5",
        "name_fr": "Under 4.5"
    },
    "1004^prematch": {
        "name_en": "other",
        "name_aa": "other",
        "name_de": "andere",
        "name_nl": "ander",
        "name_pl": "other",
        "name_ro": "other",
        "name_ru": "other",
        "name_tr": "diğer",
        "name_se": "other",
        "name_el": "other",
        "name_es": "other",
        "name_hr": "ostalo",
        "name_fr": "autre"
    },
    "157^prematch": {
        "name_en": "0-100",
        "name_aa": "0-100",
        "name_de": "0-100",
        "name_nl": "0-100",
        "name_pl": "0-100",
        "name_ro": "0-100",
        "name_ru": "0-100",
        "name_tr": "0-100",
        "name_se": "0-100",
        "name_el": "0-100",
        "name_es": "0-100",
        "name_hr": "0-100",
        "name_fr": "0-100"
    },
    "316^prematch": {
        "name_en": "1:4",
        "name_aa": "1:4",
        "name_de": "1:4",
        "name_nl": "1:4",
        "name_pl": "1:4",
        "name_ro": "1:4",
        "name_ru": "1:4",
        "name_tr": "1:4",
        "name_se": "1:4",
        "name_el": "1:4",
        "name_es": "1:4",
        "name_hr": "1:4",
        "name_fr": "1:4"
    },
    "90^prematch": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "1162^prematch": {
        "name_en": "0:5",
        "name_aa": "0:5",
        "name_de": "0:5",
        "name_nl": "0:5",
        "name_pl": "0:5",
        "name_ro": "0:5",
        "name_ru": "0:5",
        "name_tr": "0:5",
        "name_se": "0:5",
        "name_el": "0:5",
        "name_es": "0:5",
        "name_hr": "0:5",
        "name_fr": "0:5"
    },
    "370^prematch": {
        "name_en": "0:3 0:3",
        "name_aa": "0:3 0:3",
        "name_de": "0:3 0:3",
        "name_nl": "0:3 0:3",
        "name_pl": "0:3 0:3",
        "name_ro": "0:3 0:3",
        "name_ru": "0:3 0:3",
        "name_tr": "0:3 0:3",
        "name_se": "0:3 0:3",
        "name_el": "0:3 0:3",
        "name_es": "0:3 0:3",
        "name_hr": "0:3 0:3",
        "name_fr": "0:3 0:3"
    },
    "1411^prematch": {
        "name_en": "{$competitor1} & 9",
        "name_aa": "{$competitor1} & 9",
        "name_de": "{$competitor1} & 9",
        "name_nl": "{$competitor1} & 9",
        "name_pl": "{$competitor1} & 9",
        "name_ro": "{$competitor1} & 9",
        "name_ru": "{$competitor1} & 9",
        "name_tr": "{$competitor1} & 9",
        "name_se": "{$competitor1} & 9",
        "name_el": "{$competitor1} & 9",
        "name_es": "{$competitor1} & 9",
        "name_hr": "{$competitor1} & 9",
        "name_fr": "{$competitor1} & 9"
    },
    "245^prematch": {
        "name_en": "3:8",
        "name_aa": "3:8",
        "name_de": "3:8",
        "name_nl": "3:8",
        "name_pl": "3:8",
        "name_ro": "3:8",
        "name_ru": "3:8",
        "name_tr": "3:8",
        "name_se": "3:8",
        "name_el": "3:8",
        "name_es": "3:8",
        "name_hr": "3:8",
        "name_fr": "3:8"
    },
    "1427^prematch": {
        "name_en": "{$competitor2} & 11",
        "name_aa": "{$competitor2} & 11",
        "name_de": "{$competitor2} & 11",
        "name_nl": "{$competitor2} & 11",
        "name_pl": "{$competitor2} & 11",
        "name_ro": "{$competitor2} & 11",
        "name_ru": "{$competitor2} & 11",
        "name_tr": "{$competitor2} & 11",
        "name_se": "{$competitor2} & 11",
        "name_el": "{$competitor2} & 11",
        "name_es": "{$competitor2} & 11",
        "name_hr": "{$competitor2} & 11",
        "name_fr": "{$competitor2} & 11"
    },
    "276^prematch": {
        "name_en": "10:9",
        "name_aa": "10:9",
        "name_de": "10:9",
        "name_nl": "10:9",
        "name_pl": "10:9",
        "name_ro": "10:9",
        "name_ru": "10:9",
        "name_tr": "10:9",
        "name_se": "10:9",
        "name_el": "10:9",
        "name_es": "10:9",
        "name_hr": "10:9",
        "name_fr": "10:9"
    },
    "1500^prematch": {
        "name_en": "1:4",
        "name_aa": "1:4",
        "name_de": "1:4",
        "name_nl": "1:4",
        "name_pl": "1:4",
        "name_ro": "1:4",
        "name_ru": "1:4",
        "name_tr": "1:4",
        "name_se": "1:4",
        "name_el": "1:4",
        "name_es": "1:4",
        "name_hr": "1:4",
        "name_fr": "1:4"
    },
    "314^prematch": {
        "name_en": "0:4",
        "name_aa": "0:4",
        "name_de": "0:4",
        "name_nl": "0:4",
        "name_pl": "0:4",
        "name_ro": "0:4",
        "name_ru": "0:4",
        "name_tr": "0:4",
        "name_se": "0:4",
        "name_el": "0:4",
        "name_es": "0:4",
        "name_hr": "0:4",
        "name_fr": "0:4"
    },
    "1560000^prematch": {
        "name_en": "Goal before 29:00 min.",
        "name_aa": "Goal before 29:00 min.",
        "name_de": "Goal before 29:00 min.",
        "name_nl": "Goal before 29:00 min.",
        "name_pl": "Goal before 29:00 min.",
        "name_ro": "Goal before 29:00 min.",
        "name_ru": "Goal before 29:00 min.",
        "name_tr": "Goal before 29:00 min.",
        "name_se": "Goal before 29:00 min.",
        "name_el": "Goal before 29:00 min.",
        "name_es": "Goal before 29:00 min.",
        "name_hr": "Goal before 29:00 min.",
        "name_fr": "Goal before 29:00 min."
    },
    "1694050000^prematch": {
        "name_en": "Over 8.5",
        "name_aa": "Over 8.5",
        "name_de": "Over 8.5",
        "name_nl": "Over 8.5",
        "name_pl": "Over 8.5",
        "name_ro": "Over 8.5",
        "name_ru": "Over 8.5",
        "name_tr": "Over 8.5",
        "name_se": "Over 8.5",
        "name_el": "Over 8.5",
        "name_es": "Over 8.5",
        "name_hr": "Over 8.5",
        "name_fr": "Over 8.5"
    },
    "123^prematch": {
        "name_en": "{$competitor1} by 11+",
        "name_aa": "{$competitor1} by 11+",
        "name_de": "{$competitor1} by 11+",
        "name_nl": "{$competitor1} by 11+",
        "name_pl": "{$competitor1} by 11+",
        "name_ro": "{$competitor1} by 11+",
        "name_ru": "{$competitor1} by 11+",
        "name_tr": "{$competitor1} by 11+",
        "name_se": "{$competitor1} by 11+",
        "name_el": "{$competitor1} by 11+",
        "name_es": "{$competitor1} by 11+",
        "name_hr": "{$competitor1} by 11+",
        "name_fr": "{$competitor1} by 11+"
    },
    "287^prematch": {
        "name_en": "11:0",
        "name_aa": "11:0",
        "name_de": "11:0",
        "name_nl": "11:0",
        "name_pl": "11:0",
        "name_ro": "11:0",
        "name_ru": "11:0",
        "name_tr": "11:0",
        "name_se": "11:0",
        "name_el": "11:0",
        "name_es": "11:0",
        "name_hr": "11:0",
        "name_fr": "11:0"
    },
    "1184^prematch": {
        "name_en": "4:1",
        "name_aa": "4:1",
        "name_de": "4:1",
        "name_nl": "4:1",
        "name_pl": "4:1",
        "name_ro": "4:1",
        "name_ru": "4:1",
        "name_tr": "4:1",
        "name_se": "4:1",
        "name_el": "4:1",
        "name_es": "4:1",
        "name_hr": "4:1",
        "name_fr": "4:1"
    },
    "1382^prematch": {
        "name_en": "{$competitor1} & 7-9",
        "name_aa": "{$competitor1} & 7-9",
        "name_de": "{$competitor1} & 7-9",
        "name_nl": "{$competitor1} & 7-9",
        "name_pl": "{$competitor1} & 7-9",
        "name_ro": "{$competitor1} & 7-9",
        "name_ru": "{$competitor1} & 7-9",
        "name_tr": "{$competitor1} & 7-9",
        "name_se": "{$competitor1} & 7-9",
        "name_el": "{$competitor1} & 7-9",
        "name_es": "{$competitor1} & 7-9",
        "name_hr": "{$competitor1} & 7-9",
        "name_fr": "{$competitor1} & 7-9"
    },
    "224^prematch": {
        "name_en": "7:5",
        "name_aa": "7:5",
        "name_de": "7:5",
        "name_nl": "7:5",
        "name_pl": "7:5",
        "name_ro": "7:5",
        "name_ru": "7:5",
        "name_tr": "7:5",
        "name_se": "7:5",
        "name_el": "7:5",
        "name_es": "7:5",
        "name_hr": "7:5",
        "name_fr": "7:5"
    },
    "1759^prematch": {
        "name_en": "other awaywin",
        "name_aa": "other awaywin",
        "name_de": "andere Sieg Auswärtsmannschaft",
        "name_nl": "ander uitoverwinning",
        "name_pl": "other awaywin",
        "name_ro": "other awaywin",
        "name_ru": "other awaywin",
        "name_tr": "diğer deplasman galibiyeti",
        "name_se": "other awaywin",
        "name_el": "other awaywin",
        "name_es": "other awaywin",
        "name_hr": "ostalo, pobjeda gosta",
        "name_fr": "autre victoire extérieur"
    },
    "958^prematch": {
        "name_en": "8th inning",
        "name_aa": "8th inning",
        "name_de": "8. Inning",
        "name_nl": "8e inning",
        "name_pl": "8th inning",
        "name_ro": "8th inning",
        "name_ru": "8th inning",
        "name_tr": "8. devre",
        "name_se": "8th inning",
        "name_el": "8th inning",
        "name_es": "8th inning",
        "name_hr": "8. inning",
        "name_fr": "8ème manche"
    },
    "1071^prematch": {
        "name_en": "100-119",
        "name_aa": "100-119",
        "name_de": "100-119",
        "name_nl": "100-119",
        "name_pl": "100-119",
        "name_ro": "100-119",
        "name_ru": "100-119",
        "name_tr": "100-119",
        "name_se": "100-119",
        "name_el": "100-119",
        "name_es": "100-119",
        "name_hr": "100-119",
        "name_fr": "100-119"
    },
    "148^prematch": {
        "name_en": "0:3",
        "name_aa": "0:3",
        "name_de": "0:3",
        "name_nl": "0:3",
        "name_pl": "0:3",
        "name_ro": "0:3",
        "name_ru": "0:3",
        "name_tr": "0:3",
        "name_se": "0:3",
        "name_el": "0:3",
        "name_es": "0:3",
        "name_hr": "0:3",
        "name_fr": "0:3"
    },
    "963^prematch": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "1696950000^prematch": {
        "name_en": "0-6",
        "name_aa": "0-6",
        "name_de": "0-6",
        "name_nl": "0-6",
        "name_pl": "0-6",
        "name_ro": "0-6",
        "name_ru": "0-6",
        "name_tr": "0-6",
        "name_se": "0-6",
        "name_el": "0-6",
        "name_es": "0-6",
        "name_hr": "0-6",
        "name_fr": "0-6"
    },
    "1886^prematch": {
        "name_en": "draw/{$competitor2} & 5+",
        "name_aa": "draw/{$competitor2} & 5+",
        "name_de": "unentschieden/{$competitor2} & 5+",
        "name_nl": "gelijkspel/{$competitor2} & 5+",
        "name_pl": "draw/{$competitor2} & 5+",
        "name_ro": "draw/{$competitor2} & 5+",
        "name_ru": "draw/{$competitor2} & 5+",
        "name_tr": "beraberlik/{$competitor2} & 5+",
        "name_se": "draw/{$competitor2} & 5+",
        "name_el": "draw/{$competitor2} & 5+",
        "name_es": "draw/{$competitor2} & 5+",
        "name_hr": "neodlučeno/{$competitor2} i 5+",
        "name_fr": "Match nul/{$competitor2} & 5 ou +"
    },
    "560^prematch": {
        "name_en": "2:1",
        "name_aa": "2:1",
        "name_de": "2:1",
        "name_nl": "2:1",
        "name_pl": "2:1",
        "name_ro": "2:1",
        "name_ru": "2:1",
        "name_tr": "2:1",
        "name_se": "2:1",
        "name_el": "2:1",
        "name_es": "2:1",
        "name_hr": "2:1",
        "name_fr": "2:1"
    },
    "612^prematch": {
        "name_en": "71-80",
        "name_aa": "71-80",
        "name_de": "71-80",
        "name_nl": "71-80",
        "name_pl": "71-80",
        "name_ro": "71-80",
        "name_ru": "71-80",
        "name_tr": "71-80",
        "name_se": "71-80",
        "name_el": "71-80",
        "name_es": "71-80",
        "name_hr": "71-80",
        "name_fr": "71-80"
    },
    "1002^prematch": {
        "name_en": "{$competitor1} by 3+",
        "name_aa": "{$competitor1} by 3+",
        "name_de": "{$competitor1} mit 3+",
        "name_nl": "{$competitor1} met 3+",
        "name_pl": "{$competitor1} by 3+",
        "name_ro": "{$competitor1} by 3+",
        "name_ru": "{$competitor1} by 3+",
        "name_tr": "{$competitor1} 3 ve üstü farkla",
        "name_se": "{$competitor1} by 3+",
        "name_el": "{$competitor1} by 3+",
        "name_es": "{$competitor1} by 3+",
        "name_hr": "{$competitor1} za 3 ili više",
        "name_fr": "{$competitor1} par 3 ou +"
    },
    "1804^prematch": {
        "name_en": "no goal",
        "name_aa": "no goal",
        "name_de": "kein Tor",
        "name_nl": "geen doelpunt",
        "name_pl": "no goal",
        "name_ro": "no goal",
        "name_ru": "no goal",
        "name_tr": "gol yok",
        "name_se": "no goal",
        "name_el": "no goal",
        "name_es": "no goal",
        "name_hr": "nema gola",
        "name_fr": "aucun but"
    },
    "1877^prematch": {
        "name_en": "draw/{$competitor2} & 4",
        "name_aa": "draw/{$competitor2} & 4",
        "name_de": "unentschieden/{$competitor2} & 4",
        "name_nl": "gelijkspel/{$competitor2} & 4",
        "name_pl": "draw/{$competitor2} & 4",
        "name_ro": "draw/{$competitor2} & 4",
        "name_ru": "draw/{$competitor2} & 4",
        "name_tr": "beraberlik/{$competitor2} & 4",
        "name_se": "draw/{$competitor2} & 4",
        "name_el": "draw/{$competitor2} & 4",
        "name_es": "draw/{$competitor2} & 4",
        "name_hr": "neodlučeno/{$competitor2} i 4",
        "name_fr": "Match nul/{$competitor2} & 4"
    },
    "1859^prematch": {
        "name_en": "{$competitor1}/{$competitor1} & 2",
        "name_aa": "{$competitor1}/{$competitor1} & 2",
        "name_de": "{$competitor1}/{$competitor1} & 2",
        "name_nl": "{$competitor1}/{$competitor1} & 2",
        "name_pl": "{$competitor1}/{$competitor1} & 2",
        "name_ro": "{$competitor1}/{$competitor1} & 2",
        "name_ru": "{$competitor1}/{$competitor1} & 2",
        "name_tr": "{$competitor1}/{$competitor1} & 2",
        "name_se": "{$competitor1}/{$competitor1} & 2",
        "name_el": "{$competitor1}/{$competitor1} & 2",
        "name_es": "{$competitor1}/{$competitor1} & 2",
        "name_hr": "{$competitor1}/{$competitor1} i 2",
        "name_fr": "{$competitor1}/{$competitor1} & 2"
    },
    "1259^prematch": {
        "name_en": "50-54",
        "name_aa": "50-54",
        "name_de": "50-54",
        "name_nl": "50-54",
        "name_pl": "50-54",
        "name_ro": "50-54",
        "name_ru": "50-54",
        "name_tr": "50-54",
        "name_se": "50-54",
        "name_el": "50-54",
        "name_es": "50-54",
        "name_hr": "50-54",
        "name_fr": "50-54"
    },
    "1193^prematch": {
        "name_en": "other",
        "name_aa": "other",
        "name_de": "other",
        "name_nl": "other",
        "name_pl": "other",
        "name_ro": "other",
        "name_ru": "other",
        "name_tr": "other",
        "name_se": "other",
        "name_el": "other",
        "name_es": "other",
        "name_hr": "other",
        "name_fr": "other"
    },
    "1358^prematch": {
        "name_en": "{$competitor2} & 1",
        "name_aa": "{$competitor2} & 1",
        "name_de": "{$competitor2} & 1",
        "name_nl": "{$competitor2} & 1",
        "name_pl": "{$competitor2} & 1",
        "name_ro": "{$competitor2} & 1",
        "name_ru": "{$competitor2} & 1",
        "name_tr": "{$competitor2} & 1",
        "name_se": "{$competitor2} & 1",
        "name_el": "{$competitor2} & 1",
        "name_es": "{$competitor2} & 1",
        "name_hr": "{$competitor2} & 1",
        "name_fr": "{$competitor2} & 1"
    },
    "875^prematch": {
        "name_en": "3:6",
        "name_aa": "3:6",
        "name_de": "3:6",
        "name_nl": "3:6",
        "name_pl": "3:6",
        "name_ro": "3:6",
        "name_ru": "3:6",
        "name_tr": "3:6",
        "name_se": "3:6",
        "name_el": "3:6",
        "name_es": "3:6",
        "name_hr": "3:6",
        "name_fr": "3:6"
    },
    "70980000^prematch": {
        "name_en": "{$competitor1}",
        "name_aa": "{$competitor1}",
        "name_de": "{$competitor1}",
        "name_nl": "{$competitor1}",
        "name_pl": "{$competitor1}",
        "name_ro": "{$competitor1}",
        "name_ru": "{$competitor1}",
        "name_tr": "{$competitor1}",
        "name_se": "{$competitor1}",
        "name_el": "{$competitor1}",
        "name_es": "{$competitor1}",
        "name_hr": "{$competitor1}",
        "name_fr": "{$competitor1}"
    },
    "1764^prematch": {
        "name_en": "4",
        "name_aa": "4",
        "name_de": "4",
        "name_nl": "4",
        "name_pl": "4",
        "name_ro": "4",
        "name_ru": "4",
        "name_tr": "4",
        "name_se": "4",
        "name_el": "4",
        "name_es": "4",
        "name_hr": "4",
        "name_fr": "4"
    },
    "1183^prematch": {
        "name_en": "4:0",
        "name_aa": "4:0",
        "name_de": "4:0",
        "name_nl": "4:0",
        "name_pl": "4:0",
        "name_ro": "4:0",
        "name_ru": "4:0",
        "name_tr": "4:0",
        "name_se": "4:0",
        "name_el": "4:0",
        "name_es": "4:0",
        "name_hr": "4:0",
        "name_fr": "4:0"
    },
    "7^prematch": {
        "name_en": "0:2",
        "name_aa": "0:2",
        "name_de": "0:2",
        "name_nl": "0:2",
        "name_pl": "0:2",
        "name_ro": "0:2",
        "name_ru": "0:2",
        "name_tr": "0:2",
        "name_se": "0:2",
        "name_el": "0:2",
        "name_es": "0:2",
        "name_hr": "0:2",
        "name_fr": "0:2"
    },
    "1750^prematch": {
        "name_en": "1:0, 2:0 or 3:0",
        "name_aa": "1:0, 2:0 or 3:0",
        "name_de": "1:0, 2:0 oder 3:0",
        "name_nl": "1:0, 2:0 of 3:0",
        "name_pl": "1:0, 2:0 or 3:0",
        "name_ro": "1:0, 2:0 or 3:0",
        "name_ru": "1:0, 2:0 or 3:0",
        "name_tr": "1:0, 2:0 veya 3:0",
        "name_se": "1:0, 2:0 or 3:0",
        "name_el": "1:0, 2:0 or 3:0",
        "name_es": "1:0, 2:0 or 3:0",
        "name_hr": "1:0, 2:0 ili 3:0",
        "name_fr": "1:0, 2:0 ou 3:0"
    },
    "232^prematch": {
        "name_en": "0:7",
        "name_aa": "0:7",
        "name_de": "0:7",
        "name_nl": "0:7",
        "name_pl": "0:7",
        "name_ro": "0:7",
        "name_ru": "0:7",
        "name_tr": "0:7",
        "name_se": "0:7",
        "name_el": "0:7",
        "name_es": "0:7",
        "name_hr": "0:7",
        "name_fr": "0:7"
    },
    "1172^prematch": {
        "name_en": "2:0",
        "name_aa": "2:0",
        "name_de": "2:0",
        "name_nl": "2:0",
        "name_pl": "2:0",
        "name_ro": "2:0",
        "name_ru": "2:0",
        "name_tr": "2:0",
        "name_se": "2:0",
        "name_el": "2:0",
        "name_es": "2:0",
        "name_hr": "2:0",
        "name_fr": "2:0"
    },
    "1144^prematch": {
        "name_en": "0-2",
        "name_aa": "0-2",
        "name_de": "0-2",
        "name_nl": "0-2",
        "name_pl": "0-2",
        "name_ro": "0-2",
        "name_ru": "0-2",
        "name_tr": "0-2",
        "name_se": "0-2",
        "name_el": "0-2",
        "name_es": "0-2",
        "name_hr": "0-2",
        "name_fr": "0-2"
    },
    "253^prematch": {
        "name_en": "9:4",
        "name_aa": "9:4",
        "name_de": "9:4",
        "name_nl": "9:4",
        "name_pl": "9:4",
        "name_ro": "9:4",
        "name_ru": "9:4",
        "name_tr": "9:4",
        "name_se": "9:4",
        "name_el": "9:4",
        "name_es": "9:4",
        "name_hr": "9:4",
        "name_fr": "9:4"
    },
    "1419^prematch": {
        "name_en": "{$competitor2} & 3",
        "name_aa": "{$competitor2} & 3",
        "name_de": "{$competitor2} & 3",
        "name_nl": "{$competitor2} & 3",
        "name_pl": "{$competitor2} & 3",
        "name_ro": "{$competitor2} & 3",
        "name_ru": "{$competitor2} & 3",
        "name_tr": "{$competitor2} & 3",
        "name_se": "{$competitor2} & 3",
        "name_el": "{$competitor2} & 3",
        "name_es": "{$competitor2} & 3",
        "name_hr": "{$competitor2} & 3",
        "name_fr": "{$competitor2} & 3"
    },
    "959^prematch": {
        "name_en": "9th inning",
        "name_aa": "9th inning",
        "name_de": "9. Inning",
        "name_nl": "9e inning",
        "name_pl": "9th inning",
        "name_ro": "9th inning",
        "name_ru": "9th inning",
        "name_tr": "9. devre",
        "name_se": "9th inning",
        "name_el": "9th inning",
        "name_es": "9th inning",
        "name_hr": "9. inning",
        "name_fr": "9ème manche"
    },
    "1694120000^prematch": {
        "name_en": "Under 11.5",
        "name_aa": "Under 11.5",
        "name_de": "Under 11.5",
        "name_nl": "Under 11.5",
        "name_pl": "Under 11.5",
        "name_ro": "Under 11.5",
        "name_ru": "Under 11.5",
        "name_tr": "Under 11.5",
        "name_se": "Under 11.5",
        "name_el": "Under 11.5",
        "name_es": "Under 11.5",
        "name_hr": "Under 11.5",
        "name_fr": "Under 11.5"
    },
    "271^prematch": {
        "name_en": "10:4",
        "name_aa": "10:4",
        "name_de": "10:4",
        "name_nl": "10:4",
        "name_pl": "10:4",
        "name_ro": "10:4",
        "name_ru": "10:4",
        "name_tr": "10:4",
        "name_se": "10:4",
        "name_el": "10:4",
        "name_es": "10:4",
        "name_hr": "10:4",
        "name_fr": "10:4"
    },
    "432^prematch": {
        "name_en": "{$competitor2}/draw",
        "name_aa": "{$competitor2}/draw",
        "name_de": "{$competitor2}/unentschieden",
        "name_nl": "{$competitor2}/gelijkspel",
        "name_pl": "{$competitor2}/draw",
        "name_ro": "{$competitor2}/draw",
        "name_ru": "{$competitor2}/draw",
        "name_tr": "{$competitor2}/beraberlik",
        "name_se": "{$competitor2}/draw",
        "name_el": "{$competitor2}/draw",
        "name_es": "{$competitor2}/draw",
        "name_hr": "{$competitor2}/neodlučeno",
        "name_fr": "{$competitor2}/Match nul"
    },
    "1369^prematch": {
        "name_en": "{$competitor2} & 1",
        "name_aa": "{$competitor2} & 1",
        "name_de": "{$competitor2} & 1",
        "name_nl": "{$competitor2} & 1",
        "name_pl": "{$competitor2} & 1",
        "name_ro": "{$competitor2} & 1",
        "name_ru": "{$competitor2} & 1",
        "name_tr": "{$competitor2} & 1",
        "name_se": "{$competitor2} & 1",
        "name_el": "{$competitor2} & 1",
        "name_es": "{$competitor2} & 1",
        "name_hr": "{$competitor2} & 1",
        "name_fr": "{$competitor2} & 1"
    },
    "1694080000^prematch": {
        "name_en": "Under 9.5",
        "name_aa": "Under 9.5",
        "name_de": "Under 9.5",
        "name_nl": "Under 9.5",
        "name_pl": "Under 9.5",
        "name_ro": "Under 9.5",
        "name_ru": "Under 9.5",
        "name_tr": "Under 9.5",
        "name_se": "Under 9.5",
        "name_el": "Under 9.5",
        "name_es": "Under 9.5",
        "name_hr": "Under 9.5",
        "name_fr": "Under 9.5"
    },
    "1741^prematch": {
        "name_en": "3-6",
        "name_aa": "3-6",
        "name_de": "3-6",
        "name_nl": "3-6",
        "name_pl": "3-6",
        "name_ro": "3-6",
        "name_ru": "3-6",
        "name_tr": "3-6",
        "name_se": "3-6",
        "name_el": "3-6",
        "name_es": "3-6",
        "name_hr": "3-6",
        "name_fr": "3-6"
    },
    "142^prematch": {
        "name_en": "{$competitor2} by 11+",
        "name_aa": "{$competitor2} by 11+",
        "name_de": "{$competitor2} by 11+",
        "name_nl": "{$competitor2} by 11+",
        "name_pl": "{$competitor2} by 11+",
        "name_ro": "{$competitor2} by 11+",
        "name_ru": "{$competitor2} by 11+",
        "name_tr": "{$competitor2} by 11+",
        "name_se": "{$competitor2} by 11+",
        "name_el": "{$competitor2} by 11+",
        "name_es": "{$competitor2} by 11+",
        "name_hr": "{$competitor2} by 11+",
        "name_fr": "{$competitor2} by 11+"
    },
    "302^prematch": {
        "name_en": "6:11",
        "name_aa": "6:11",
        "name_de": "6:11",
        "name_nl": "6:11",
        "name_pl": "6:11",
        "name_ro": "6:11",
        "name_ru": "6:11",
        "name_tr": "6:11",
        "name_se": "6:11",
        "name_el": "6:11",
        "name_es": "6:11",
        "name_hr": "6:11",
        "name_fr": "6:11"
    },
    "1421^prematch": {
        "name_en": "{$competitor2} & 5",
        "name_aa": "{$competitor2} & 5",
        "name_de": "{$competitor2} & 5",
        "name_nl": "{$competitor2} & 5",
        "name_pl": "{$competitor2} & 5",
        "name_ro": "{$competitor2} & 5",
        "name_ru": "{$competitor2} & 5",
        "name_tr": "{$competitor2} & 5",
        "name_se": "{$competitor2} & 5",
        "name_el": "{$competitor2} & 5",
        "name_es": "{$competitor2} & 5",
        "name_hr": "{$competitor2} & 5",
        "name_fr": "{$competitor2} & 5"
    },
    "1346^prematch": {
        "name_en": "{$competitor1} by ko",
        "name_aa": "{$competitor1} by ko",
        "name_de": "{$competitor1} by ko",
        "name_nl": "{$competitor1} by ko",
        "name_pl": "{$competitor1} by ko",
        "name_ro": "{$competitor1} by ko",
        "name_ru": "{$competitor1} by ko",
        "name_tr": "{$competitor1} by ko",
        "name_se": "{$competitor1} by ko",
        "name_el": "{$competitor1} by ko",
        "name_es": "{$competitor1} by ko",
        "name_hr": "{$competitor1} by ko",
        "name_fr": "{$competitor1} by ko"
    },
    "826^prematch": {
        "name_en": "no goal",
        "name_aa": "no goal",
        "name_de": "kein Tor",
        "name_nl": "geen doelpunt",
        "name_pl": "no goal",
        "name_ro": "no goal",
        "name_ru": "no goal",
        "name_tr": "gol yok",
        "name_se": "no goal",
        "name_el": "no goal",
        "name_es": "no goal",
        "name_hr": "bez gola",
        "name_fr": "aucun but"
    },
    "410^prematch": {
        "name_en": "2:1 4+",
        "name_aa": "2:1 4+",
        "name_de": "2:1 4+",
        "name_nl": "2:1 4+",
        "name_pl": "2:1 4+",
        "name_ro": "2:1 4+",
        "name_ru": "2:1 4+",
        "name_tr": "2:1 4+",
        "name_se": "2:1 4+",
        "name_el": "2:1 4+",
        "name_es": "2:1 4+",
        "name_hr": "2:1 4+",
        "name_fr": "2:1 4+"
    },
    "1861^prematch": {
        "name_en": "draw/{$competitor1} & 2",
        "name_aa": "draw/{$competitor1} & 2",
        "name_de": "unentschieden/{$competitor1} & 2",
        "name_nl": "gelijkspel/{$competitor1} & 2",
        "name_pl": "draw/{$competitor1} & 2",
        "name_ro": "draw/{$competitor1} & 2",
        "name_ru": "draw/{$competitor1} & 2",
        "name_tr": "beraberlik/{$competitor1} & 2",
        "name_se": "draw/{$competitor1} & 2",
        "name_el": "draw/{$competitor1} & 2",
        "name_es": "draw/{$competitor1} & 2",
        "name_hr": "neodlučeno/{$competitor1} i 2",
        "name_fr": "Match nul/{$competitor1} & 2"
    },
    "430^prematch": {
        "name_en": "{$competitor2}/{$competitor1}",
        "name_aa": "{$competitor2}/{$competitor1}",
        "name_de": "{$competitor2}/{$competitor1}",
        "name_nl": "{$competitor2}/{$competitor1}",
        "name_pl": "{$competitor2}/{$competitor1}",
        "name_ro": "{$competitor2}/{$competitor1}",
        "name_ru": "{$competitor2}/{$competitor1}",
        "name_tr": "{$competitor2}/{$competitor1}",
        "name_se": "{$competitor2}/{$competitor1}",
        "name_el": "{$competitor2}/{$competitor1}",
        "name_es": "{$competitor2}/{$competitor1}",
        "name_hr": "{$competitor2}/{$competitor1}",
        "name_fr": "{$competitor2}/{$competitor1}"
    },
    "1737^prematch": {
        "name_en": "2-5",
        "name_aa": "2-5",
        "name_de": "2-5",
        "name_nl": "2-5",
        "name_pl": "2-5",
        "name_ro": "2-5",
        "name_ru": "2-5",
        "name_tr": "2-5",
        "name_se": "2-5",
        "name_el": "2-5",
        "name_es": "2-5",
        "name_hr": "2-5",
        "name_fr": "2-5"
    },
    "56160000^prematch": {
        "name_en": "5+",
        "name_aa": "5+",
        "name_de": "5+",
        "name_nl": "5+",
        "name_pl": "5+",
        "name_ro": "5+",
        "name_ru": "5+",
        "name_tr": "5+",
        "name_se": "5+",
        "name_el": "5+",
        "name_es": "5+",
        "name_hr": "5+",
        "name_fr": "5+"
    },
    "920^prematch": {
        "name_en": "1st quarter",
        "name_aa": "1st quarter",
        "name_de": "1. Viertel",
        "name_nl": "Eerste kwart",
        "name_pl": "1st quarter",
        "name_ro": "1st quarter",
        "name_ru": "1st quarter",
        "name_tr": "İlk çeyrek",
        "name_se": "1st quarter",
        "name_el": "1st quarter",
        "name_es": "1st quarter",
        "name_hr": "1. četvrtina",
        "name_fr": "1er quart-temps"
    },
    "1486^prematch": {
        "name_en": "5:1",
        "name_aa": "5:1",
        "name_de": "5:1",
        "name_nl": "5:1",
        "name_pl": "5:1",
        "name_ro": "5:1",
        "name_ru": "5:1",
        "name_tr": "5:1",
        "name_se": "5:1",
        "name_el": "5:1",
        "name_es": "5:1",
        "name_hr": "5:1",
        "name_fr": "5:1"
    },
    "1829^prematch": {
        "name_en": "{!(inningnr+2)} inning",
        "name_aa": "{!(inningnr+2)} inning",
        "name_de": "{!(inningnr+2)} Inning",
        "name_nl": "{!(inningnr+2)} inning",
        "name_pl": "{!(inningnr+2)} inning",
        "name_ro": "{!(inningnr+2)} inning",
        "name_ru": "{!(inningnr+2)} inning",
        "name_tr": "{!(inningnr+2)} devre",
        "name_se": "{!(inningnr+2)} inning",
        "name_el": "{!(inningnr+2)} inning",
        "name_es": "{!(inningnr+2)} inning",
        "name_hr": "{!(inningnr+2)} inning",
        "name_fr": "{!(inningnr+2)} manche"
    },
    "1342^prematch": {
        "name_en": "0-1",
        "name_aa": "0-1",
        "name_de": "0-1",
        "name_nl": "0-1",
        "name_pl": "0-1",
        "name_ro": "0-1",
        "name_ru": "0-1",
        "name_tr": "0-1",
        "name_se": "0-1",
        "name_el": "0-1",
        "name_es": "0-1",
        "name_hr": "0-1",
        "name_fr": "0-1"
    },
    "1343^prematch": {
        "name_en": "2-3",
        "name_aa": "2-3",
        "name_de": "2-3",
        "name_nl": "2-3",
        "name_pl": "2-3",
        "name_ro": "2-3",
        "name_ru": "2-3",
        "name_tr": "2-3",
        "name_se": "2-3",
        "name_el": "2-3",
        "name_es": "2-3",
        "name_hr": "2-3",
        "name_fr": "2-3"
    },
    "812^prematch": {
        "name_en": "no/yes",
        "name_aa": "no/yes",
        "name_de": "nein/ja",
        "name_nl": "nee/ja",
        "name_pl": "no/yes",
        "name_ro": "no/yes",
        "name_ru": "no/yes",
        "name_tr": "yok/var",
        "name_se": "no/yes",
        "name_el": "no/yes",
        "name_es": "no/yes",
        "name_hr": "ne/da",
        "name_fr": "non/oui"
    },
    "10^prematch": {
        "name_en": "{$competitor1} or {$competitor2}",
        "name_aa": "{$competitor1} or {$competitor2}",
        "name_de": "{$competitor1} oder {$competitor2}",
        "name_nl": "{$competitor1} of {$competitor2}",
        "name_pl": "{$competitor1} or {$competitor2}",
        "name_ro": "{$competitor1} or {$competitor2}",
        "name_ru": "{$competitor1} or {$competitor2}",
        "name_tr": "{$competitor1} veya {$competitor2}",
        "name_se": "{$competitor1} or {$competitor2}",
        "name_el": "{$competitor1} or {$competitor2}",
        "name_es": "{$competitor1} or {$competitor2}",
        "name_hr": "{$competitor1} ili {$competitor2}",
        "name_fr": "{$competitor1} ou {$competitor2}"
    },
    "780^prematch": {
        "name_en": "{$competitor1}",
        "name_aa": "{$competitor1}",
        "name_de": "{$competitor1}",
        "name_nl": "{$competitor1}",
        "name_pl": "{$competitor1}",
        "name_ro": "{$competitor1}",
        "name_ru": "{$competitor1}",
        "name_tr": "{$competitor1}",
        "name_se": "{$competitor1}",
        "name_el": "{$competitor1}",
        "name_es": "{$competitor1}",
        "name_hr": "{$competitor1}",
        "name_fr": "{$competitor1}"
    },
    "1163^prematch": {
        "name_en": "0:6",
        "name_aa": "0:6",
        "name_de": "0:6",
        "name_nl": "0:6",
        "name_pl": "0:6",
        "name_ro": "0:6",
        "name_ru": "0:6",
        "name_tr": "0:6",
        "name_se": "0:6",
        "name_el": "0:6",
        "name_es": "0:6",
        "name_hr": "0:6",
        "name_fr": "0:6"
    },
    "1385^prematch": {
        "name_en": "draw",
        "name_aa": "draw",
        "name_de": "draw",
        "name_nl": "draw",
        "name_pl": "draw",
        "name_ro": "draw",
        "name_ru": "draw",
        "name_tr": "draw",
        "name_se": "draw",
        "name_el": "draw",
        "name_es": "draw",
        "name_hr": "draw",
        "name_fr": "draw"
    },
    "1362^prematch": {
        "name_en": "{$competitor1} & 1",
        "name_aa": "{$competitor1} & 1",
        "name_de": "{$competitor1} & 1",
        "name_nl": "{$competitor1} & 1",
        "name_pl": "{$competitor1} & 1",
        "name_ro": "{$competitor1} & 1",
        "name_ru": "{$competitor1} & 1",
        "name_tr": "{$competitor1} & 1",
        "name_se": "{$competitor1} & 1",
        "name_el": "{$competitor1} & 1",
        "name_es": "{$competitor1} & 1",
        "name_hr": "{$competitor1} & 1",
        "name_fr": "{$competitor1} & 1"
    },
    "816^prematch": {
        "name_en": "{$competitor1} goal & draw",
        "name_aa": "{$competitor1} goal & draw",
        "name_de": "{$competitor1} Tor & unentschieden",
        "name_nl": "doelpunt {$competitor1} & gelijkspel",
        "name_pl": "{$competitor1} goal & draw",
        "name_ro": "{$competitor1} goal & draw",
        "name_ru": "{$competitor1} goal & draw",
        "name_tr": "{$competitor1} golü & beraberlik",
        "name_se": "{$competitor1} goal & draw",
        "name_el": "{$competitor1} goal & draw",
        "name_es": "{$competitor1} goal & draw",
        "name_hr": "{$competitor1} gol i neodlučeno",
        "name_fr": "but {$competitor1} & Match nul"
    },
    "870^prematch": {
        "name_en": "7:5",
        "name_aa": "7:5",
        "name_de": "7:5",
        "name_nl": "7:5",
        "name_pl": "7:5",
        "name_ro": "7:5",
        "name_ru": "7:5",
        "name_tr": "7:5",
        "name_se": "7:5",
        "name_el": "7:5",
        "name_es": "7:5",
        "name_hr": "7:5",
        "name_fr": "7:5"
    },
    "1336^prematch": {
        "name_en": "0",
        "name_aa": "0",
        "name_de": "0",
        "name_nl": "0",
        "name_pl": "0",
        "name_ro": "0",
        "name_ru": "0",
        "name_tr": "0",
        "name_se": "0",
        "name_el": "0",
        "name_es": "0",
        "name_hr": "0",
        "name_fr": "0"
    },
    "70870000^prematch": {
        "name_en": "Draw",
        "name_aa": "Draw",
        "name_de": "Draw",
        "name_nl": "Draw",
        "name_pl": "Draw",
        "name_ro": "Draw",
        "name_ru": "Draw",
        "name_tr": "Draw",
        "name_se": "Draw",
        "name_el": "Draw",
        "name_es": "Draw",
        "name_hr": "Draw",
        "name_fr": "Draw"
    },
    "1080^prematch": {
        "name_en": "11-14",
        "name_aa": "11-14",
        "name_de": "11-14",
        "name_nl": "11-14",
        "name_pl": "11-14",
        "name_ro": "11-14",
        "name_ru": "11-14",
        "name_tr": "11-14",
        "name_se": "11-14",
        "name_el": "11-14",
        "name_es": "11-14",
        "name_hr": "11-14",
        "name_fr": "11-14"
    },
    "1133^prematch": {
        "name_en": "31-45",
        "name_aa": "31-45",
        "name_de": "31-45",
        "name_nl": "31-45",
        "name_pl": "31-45",
        "name_ro": "31-45",
        "name_ru": "31-45",
        "name_tr": "31-45",
        "name_se": "31-45",
        "name_el": "31-45",
        "name_es": "31-45",
        "name_hr": "31-45",
        "name_fr": "31-45"
    },
    "1345^prematch": {
        "name_en": "7+",
        "name_aa": "7+",
        "name_de": "7+",
        "name_nl": "7+",
        "name_pl": "7+",
        "name_ro": "7+",
        "name_ru": "7+",
        "name_tr": "7+",
        "name_se": "7+",
        "name_el": "7+",
        "name_es": "7+",
        "name_hr": "7+",
        "name_fr": "7+"
    },
    "346^prematch": {
        "name_en": "0:0 4+",
        "name_aa": "0:0 4+",
        "name_de": "0:0 4+",
        "name_nl": "0:0 4+",
        "name_pl": "0:0 4+",
        "name_ro": "0:0 4+",
        "name_ru": "0:0 4+",
        "name_tr": "0:0 4+",
        "name_se": "0:0 4+",
        "name_el": "0:0 4+",
        "name_es": "0:0 4+",
        "name_hr": "0:0 4+",
        "name_fr": "0:0 4+"
    },
    "204^prematch": {
        "name_en": "0:7",
        "name_aa": "0:7",
        "name_de": "0:7",
        "name_nl": "0:7",
        "name_pl": "0:7",
        "name_ro": "0:7",
        "name_ru": "0:7",
        "name_tr": "0:7",
        "name_se": "0:7",
        "name_el": "0:7",
        "name_es": "0:7",
        "name_hr": "0:7",
        "name_fr": "0:7"
    },
    "1257^prematch": {
        "name_en": "40-44",
        "name_aa": "40-44",
        "name_de": "40-44",
        "name_nl": "40-44",
        "name_pl": "40-44",
        "name_ro": "40-44",
        "name_ru": "40-44",
        "name_tr": "40-44",
        "name_se": "40-44",
        "name_el": "40-44",
        "name_es": "40-44",
        "name_hr": "40-44",
        "name_fr": "40-44"
    },
    "1728^prematch": {
        "name_en": "{$competitor1}/{$competitor2} & over {total}",
        "name_aa": "{$competitor1}/{$competitor2} & over {total}",
        "name_de": "{$competitor1}/{$competitor2} & über {total}",
        "name_nl": "{$competitor1}/{$competitor2} & boven {total}",
        "name_pl": "{$competitor1}/{$competitor2} & over {total}",
        "name_ro": "{$competitor1}/{$competitor2} & over {total}",
        "name_ru": "{$competitor1}/{$competitor2} & over {total}",
        "name_tr": "{$competitor1}/{$competitor2} & {total} üstü",
        "name_se": "{$competitor1}/{$competitor2} & over {total}",
        "name_el": "{$competitor1}/{$competitor2} & over {total}",
        "name_es": "{$competitor1}/{$competitor2} & over {total}",
        "name_hr": "{$competitor1}/{$competitor2} i više {total}",
        "name_fr": "{$competitor1}/{$competitor2} & plus de {total}"
    },
    "1752^prematch": {
        "name_en": "4:0, 5:0 or 6:0",
        "name_aa": "4:0, 5:0 or 6:0",
        "name_de": "4:0, 5:0 oder 6:0",
        "name_nl": "4:0, 5:0 of 6:0",
        "name_pl": "4:0, 5:0 or 6:0",
        "name_ro": "4:0, 5:0 or 6:0",
        "name_ru": "4:0, 5:0 or 6:0",
        "name_tr": "4:0, 5:0 veya 6:0",
        "name_se": "4:0, 5:0 or 6:0",
        "name_el": "4:0, 5:0 or 6:0",
        "name_es": "4:0, 5:0 or 6:0",
        "name_hr": "4:0, 5:0 ili 6:0",
        "name_fr": "4:0, 5:0 ou 6:0"
    },
    "1509^prematch": {
        "name_en": "4:5",
        "name_aa": "4:5",
        "name_de": "4:5",
        "name_nl": "4:5",
        "name_pl": "4:5",
        "name_ro": "4:5",
        "name_ru": "4:5",
        "name_tr": "4:5",
        "name_se": "4:5",
        "name_el": "4:5",
        "name_es": "4:5",
        "name_hr": "4:5",
        "name_fr": "4:5"
    },
    "1881^prematch": {
        "name_en": "{$competitor1}/{$competitor1} & 5+",
        "name_aa": "{$competitor1}/{$competitor1} & 5+",
        "name_de": "{$competitor1}/{$competitor1} & 5+",
        "name_nl": "{$competitor1}/{$competitor1} & 5+",
        "name_pl": "{$competitor1}/{$competitor1} & 5+",
        "name_ro": "{$competitor1}/{$competitor1} & 5+",
        "name_ru": "{$competitor1}/{$competitor1} & 5+",
        "name_tr": "{$competitor1}/{$competitor1} & 5+",
        "name_se": "{$competitor1}/{$competitor1} & 5+",
        "name_el": "{$competitor1}/{$competitor1} & 5+",
        "name_es": "{$competitor1}/{$competitor1} & 5+",
        "name_hr": "{$competitor1}/{$competitor1} i 5+",
        "name_fr": "{$competitor1}/{$competitor1} & 5 ou +"
    },
    "1481^prematch": {
        "name_en": "0:1",
        "name_aa": "0:1",
        "name_de": "0:1",
        "name_nl": "0:1",
        "name_pl": "0:1",
        "name_ro": "0:1",
        "name_ru": "0:1",
        "name_tr": "0:1",
        "name_se": "0:1",
        "name_el": "0:1",
        "name_es": "0:1",
        "name_hr": "0:1",
        "name_fr": "0:1"
    },
    "27^prematch": {
        "name_en": "4:5",
        "name_aa": "4:5",
        "name_de": "4:5",
        "name_nl": "4:5",
        "name_pl": "4:5",
        "name_ro": "4:5",
        "name_ru": "4:5",
        "name_tr": "4:5",
        "name_se": "4:5",
        "name_el": "4:5",
        "name_es": "4:5",
        "name_hr": "4:5",
        "name_fr": "4:5"
    },
    "70930000^prematch": {
        "name_en": "Neither",
        "name_aa": "Neither",
        "name_de": "Neither",
        "name_nl": "Neither",
        "name_pl": "Neither",
        "name_ro": "Neither",
        "name_ru": "Neither",
        "name_tr": "Neither",
        "name_se": "Neither",
        "name_el": "Neither",
        "name_es": "Neither",
        "name_hr": "Neither",
        "name_fr": "Neither"
    },
    "474^prematch": {
        "name_en": "0:1",
        "name_aa": "0:1",
        "name_de": "0:1",
        "name_nl": "0:1",
        "name_pl": "0:1",
        "name_ro": "0:1",
        "name_ru": "0:1",
        "name_tr": "0:1",
        "name_se": "0:1",
        "name_el": "0:1",
        "name_es": "0:1",
        "name_hr": "0:1",
        "name_fr": "0:1"
    },
    "1188^prematch": {
        "name_en": "5:1",
        "name_aa": "5:1",
        "name_de": "5:1",
        "name_nl": "5:1",
        "name_pl": "5:1",
        "name_ro": "5:1",
        "name_ru": "5:1",
        "name_tr": "5:1",
        "name_se": "5:1",
        "name_el": "5:1",
        "name_es": "5:1",
        "name_hr": "5:1",
        "name_fr": "5:1"
    },
    "1496^prematch": {
        "name_en": "3:3",
        "name_aa": "3:3",
        "name_de": "3:3",
        "name_nl": "3:3",
        "name_pl": "3:3",
        "name_ro": "3:3",
        "name_ru": "3:3",
        "name_tr": "3:3",
        "name_se": "3:3",
        "name_el": "3:3",
        "name_es": "3:3",
        "name_hr": "3:3",
        "name_fr": "3:3"
    },
    "1084^prematch": {
        "name_en": "27-30",
        "name_aa": "27-30",
        "name_de": "27-30",
        "name_nl": "27-30",
        "name_pl": "27-30",
        "name_ro": "27-30",
        "name_ru": "27-30",
        "name_tr": "27-30",
        "name_se": "27-30",
        "name_el": "27-30",
        "name_es": "27-30",
        "name_hr": "27-30",
        "name_fr": "27-30"
    },
    "1410^prematch": {
        "name_en": "{$competitor1} & 8",
        "name_aa": "{$competitor1} & 8",
        "name_de": "{$competitor1} & 8",
        "name_nl": "{$competitor1} & 8",
        "name_pl": "{$competitor1} & 8",
        "name_ro": "{$competitor1} & 8",
        "name_ru": "{$competitor1} & 8",
        "name_tr": "{$competitor1} & 8",
        "name_se": "{$competitor1} & 8",
        "name_el": "{$competitor1} & 8",
        "name_es": "{$competitor1} & 8",
        "name_hr": "{$competitor1} & 8",
        "name_fr": "{$competitor1} & 8"
    },
    "305^prematch": {
        "name_en": "3:11",
        "name_aa": "3:11",
        "name_de": "3:11",
        "name_nl": "3:11",
        "name_pl": "3:11",
        "name_ro": "3:11",
        "name_ru": "3:11",
        "name_tr": "3:11",
        "name_se": "3:11",
        "name_el": "3:11",
        "name_es": "3:11",
        "name_hr": "3:11",
        "name_fr": "3:11"
    },
    "564^prematch": {
        "name_en": "other",
        "name_aa": "other",
        "name_de": "andere",
        "name_nl": "ander",
        "name_pl": "other",
        "name_ro": "other",
        "name_ru": "other",
        "name_tr": "diğer",
        "name_se": "other",
        "name_el": "other",
        "name_es": "other",
        "name_hr": "ostalo",
        "name_fr": "autre"
    },
    "436^prematch": {
        "name_en": "1st half",
        "name_aa": "1st half",
        "name_de": "1. Halbzeit",
        "name_nl": "eerste helft",
        "name_pl": "1st half",
        "name_ro": "1st half",
        "name_ru": "1st half",
        "name_tr": "İlk yarı",
        "name_se": "1st half",
        "name_el": "1st half",
        "name_es": "1st half",
        "name_hr": "1. poluvrijeme",
        "name_fr": "1ère mi-temps"
    },
    "1642^prematch": {
        "name_en": "{$competitor1} with drop goal",
        "name_aa": "{$competitor1} with drop goal",
        "name_de": "{$competitor1} mit Drop goal",
        "name_nl": "{$competitor1} met drop goal",
        "name_pl": "{$competitor1} with drop goal",
        "name_ro": "{$competitor1} with drop goal",
        "name_ru": "{$competitor1} with drop goal",
        "name_tr": "{$competitor1}şutla",
        "name_se": "{$competitor1} with drop goal",
        "name_el": "{$competitor1} with drop goal",
        "name_es": "{$competitor1} with drop goal",
        "name_hr": "{$competitor1} drop golom",
        "name_fr": "{$competitor1} avec un drop"
    },
    "60^prematch": {
        "name_en": "3+",
        "name_aa": "3+",
        "name_de": "3+",
        "name_nl": "3+",
        "name_pl": "3+",
        "name_ro": "3+",
        "name_ru": "3+",
        "name_tr": "3+",
        "name_se": "3+",
        "name_el": "3+",
        "name_es": "3+",
        "name_hr": "3+",
        "name_fr": "3+"
    },
    "14^prematch": {
        "name_en": "4:0",
        "name_aa": "4:0",
        "name_de": "4:0",
        "name_nl": "4:0",
        "name_pl": "4:0",
        "name_ro": "4:0",
        "name_ru": "4:0",
        "name_tr": "4:0",
        "name_se": "4:0",
        "name_el": "4:0",
        "name_es": "4:0",
        "name_hr": "4:0",
        "name_fr": "4:0"
    },
    "1092^prematch": {
        "name_en": "46+",
        "name_aa": "46+",
        "name_de": "46+",
        "name_nl": "46+",
        "name_pl": "46+",
        "name_ro": "46+",
        "name_ru": "46+",
        "name_tr": "46+",
        "name_se": "46+",
        "name_el": "46+",
        "name_es": "46+",
        "name_hr": "46+",
        "name_fr": "46+"
    },
    "608^prematch": {
        "name_en": "51-60",
        "name_aa": "51-60",
        "name_de": "51-60",
        "name_nl": "51-60",
        "name_pl": "51-60",
        "name_ro": "51-60",
        "name_ru": "51-60",
        "name_tr": "51-60",
        "name_se": "51-60",
        "name_el": "51-60",
        "name_es": "51-60",
        "name_hr": "51-60",
        "name_fr": "51-60"
    },
    "1694160000^prematch": {
        "name_en": "Under 13.5",
        "name_aa": "Under 13.5",
        "name_de": "Under 13.5",
        "name_nl": "Under 13.5",
        "name_pl": "Under 13.5",
        "name_ro": "Under 13.5",
        "name_ru": "Under 13.5",
        "name_tr": "Under 13.5",
        "name_se": "Under 13.5",
        "name_el": "Under 13.5",
        "name_es": "Under 13.5",
        "name_hr": "Under 13.5",
        "name_fr": "Under 13.5"
    },
    "1146^prematch": {
        "name_en": "5-6",
        "name_aa": "5-6",
        "name_de": "5-6",
        "name_nl": "5-6",
        "name_pl": "5-6",
        "name_ro": "5-6",
        "name_ru": "5-6",
        "name_tr": "5-6",
        "name_se": "5-6",
        "name_el": "5-6",
        "name_es": "5-6",
        "name_hr": "5-6",
        "name_fr": "5-6"
    },
    "1352^prematch": {
        "name_en": "{$competitor2} by decision",
        "name_aa": "{$competitor2} by decision",
        "name_de": "{$competitor2} by decision",
        "name_nl": "{$competitor2} by decision",
        "name_pl": "{$competitor2} by decision",
        "name_ro": "{$competitor2} by decision",
        "name_ru": "{$competitor2} by decision",
        "name_tr": "{$competitor2} by decision",
        "name_se": "{$competitor2} by decision",
        "name_el": "{$competitor2} by decision",
        "name_es": "{$competitor2} by decision",
        "name_hr": "{$competitor2} by decision",
        "name_fr": "{$competitor2} by decision"
    },
    "1490^prematch": {
        "name_en": "3:2",
        "name_aa": "3:2",
        "name_de": "3:2",
        "name_nl": "3:2",
        "name_pl": "3:2",
        "name_ro": "3:2",
        "name_ru": "3:2",
        "name_tr": "3:2",
        "name_se": "3:2",
        "name_el": "3:2",
        "name_es": "3:2",
        "name_hr": "3:2",
        "name_fr": "3:2"
    },
    "261^prematch": {
        "name_en": "5:9",
        "name_aa": "5:9",
        "name_de": "5:9",
        "name_nl": "5:9",
        "name_pl": "5:9",
        "name_ro": "5:9",
        "name_ru": "5:9",
        "name_tr": "5:9",
        "name_se": "5:9",
        "name_el": "5:9",
        "name_es": "5:9",
        "name_hr": "5:9",
        "name_fr": "5:9"
    },
    "272^prematch": {
        "name_en": "10:5",
        "name_aa": "10:5",
        "name_de": "10:5",
        "name_nl": "10:5",
        "name_pl": "10:5",
        "name_ro": "10:5",
        "name_ru": "10:5",
        "name_tr": "10:5",
        "name_se": "10:5",
        "name_el": "10:5",
        "name_es": "10:5",
        "name_hr": "10:5",
        "name_fr": "10:5"
    },
    "1083^prematch": {
        "name_en": "23-26",
        "name_aa": "23-26",
        "name_de": "23-26",
        "name_nl": "23-26",
        "name_pl": "23-26",
        "name_ro": "23-26",
        "name_ru": "23-26",
        "name_tr": "23-26",
        "name_se": "23-26",
        "name_el": "23-26",
        "name_es": "23-26",
        "name_hr": "23-26",
        "name_fr": "23-26"
    },
    "340^prematch": {
        "name_en": "0:0 2:0",
        "name_aa": "0:0 2:0",
        "name_de": "0:0 2:0",
        "name_nl": "0:0 2:0",
        "name_pl": "0:0 2:0",
        "name_ro": "0:0 2:0",
        "name_ru": "0:0 2:0",
        "name_tr": "0:0 2:0",
        "name_se": "0:0 2:0",
        "name_el": "0:0 2:0",
        "name_es": "0:0 2:0",
        "name_hr": "0:0 2:0",
        "name_fr": "0:0 2:0"
    },
    "952^prematch": {
        "name_en": "2nd inning",
        "name_aa": "2nd inning",
        "name_de": "2. Inning",
        "name_nl": "2e inning",
        "name_pl": "2nd inning",
        "name_ro": "2nd inning",
        "name_ru": "2nd inning",
        "name_tr": "2. devre",
        "name_se": "2nd inning",
        "name_el": "2nd inning",
        "name_es": "2nd inning",
        "name_hr": "2. inning",
        "name_fr": "2ème manche"
    },
    "1860^prematch": {
        "name_en": "{$competitor1}/draw & 2",
        "name_aa": "{$competitor1}/draw & 2",
        "name_de": "{$competitor1}/unentschieden & 2",
        "name_nl": "{$competitor1}/gelijkspel & 2",
        "name_pl": "{$competitor1}/draw & 2",
        "name_ro": "{$competitor1}/draw & 2",
        "name_ru": "{$competitor1}/draw & 2",
        "name_tr": "{$competitor1}/beraberlik & 2",
        "name_se": "{$competitor1}/draw & 2",
        "name_el": "{$competitor1}/draw & 2",
        "name_es": "{$competitor1}/draw & 2",
        "name_hr": "{$competitor1}/neodlučeno i 2",
        "name_fr": "{$competitor1}/Match nul & 2"
    },
    "284^prematch": {
        "name_en": "2:10",
        "name_aa": "2:10",
        "name_de": "2:10",
        "name_nl": "2:10",
        "name_pl": "2:10",
        "name_ro": "2:10",
        "name_ru": "2:10",
        "name_tr": "2:10",
        "name_se": "2:10",
        "name_el": "2:10",
        "name_es": "2:10",
        "name_hr": "2:10",
        "name_fr": "2:10"
    },
    "1182^prematch": {
        "name_en": "3:4",
        "name_aa": "3:4",
        "name_de": "3:4",
        "name_nl": "3:4",
        "name_pl": "3:4",
        "name_ro": "3:4",
        "name_ru": "3:4",
        "name_tr": "3:4",
        "name_se": "3:4",
        "name_el": "3:4",
        "name_es": "3:4",
        "name_hr": "3:4",
        "name_fr": "3:4"
    },
    "1355^prematch": {
        "name_en": "{$competitor1} & 3",
        "name_aa": "{$competitor1} & 3",
        "name_de": "{$competitor1} & 3",
        "name_nl": "{$competitor1} & 3",
        "name_pl": "{$competitor1} & 3",
        "name_ro": "{$competitor1} & 3",
        "name_ru": "{$competitor1} & 3",
        "name_tr": "{$competitor1} & 3",
        "name_se": "{$competitor1} & 3",
        "name_el": "{$competitor1} & 3",
        "name_es": "{$competitor1} & 3",
        "name_hr": "{$competitor1} & 3",
        "name_fr": "{$competitor1} & 3"
    },
    "1714^prematch": {
        "name_en": "{$competitor1} ({+hcp})",
        "name_aa": "{$competitor1} ({+hcp})",
        "name_de": "{$competitor1} ({+hcp})",
        "name_nl": "{$competitor1} ({hcp})",
        "name_pl": "{$competitor1} ({+hcp})",
        "name_ro": "{$competitor1} ({+hcp})",
        "name_ru": "{$competitor1} ({+hcp})",
        "name_tr": "{$competitor1} ({+hcp})",
        "name_se": "{$competitor1} ({+hcp})",
        "name_el": "{$competitor1} ({+hcp})",
        "name_es": "{$competitor1} ({+hcp})",
        "name_hr": "{$competitor1} ({+hcp})",
        "name_fr": "{$competitor1} ({+hcp})"
    },
    "1292^prematch": {
        "name_en": "{$competitor2} by 16-20",
        "name_aa": "{$competitor2} by 16-20",
        "name_de": "{$competitor2} by 16-20",
        "name_nl": "{$competitor2} by 16-20",
        "name_pl": "{$competitor2} by 16-20",
        "name_ro": "{$competitor2} by 16-20",
        "name_ru": "{$competitor2} by 16-20",
        "name_tr": "{$competitor2} by 16-20",
        "name_se": "{$competitor2} by 16-20",
        "name_el": "{$competitor2} by 16-20",
        "name_es": "{$competitor2} by 16-20",
        "name_hr": "{$competitor2} by 16-20",
        "name_fr": "{$competitor2} by 16-20"
    },
    "954^prematch": {
        "name_en": "4th inning",
        "name_aa": "4th inning",
        "name_de": "4. Inning",
        "name_nl": "4e inning",
        "name_pl": "4th inning",
        "name_ro": "4th inning",
        "name_ru": "4th inning",
        "name_tr": "4. devre",
        "name_se": "4th inning",
        "name_el": "4th inning",
        "name_es": "4th inning",
        "name_hr": "4. inning",
        "name_fr": "4ème manche"
    },
    "92^prematch": {
        "name_en": "under {total} & yes",
        "name_aa": "under {total} & yes",
        "name_de": "unter {total} & ja",
        "name_nl": "onder {total} & ja",
        "name_pl": "under {total} & yes",
        "name_ro": "under {total} & yes",
        "name_ru": "under {total} & yes",
        "name_tr": "{total} altı & var",
        "name_se": "under {total} & yes",
        "name_el": "under {total} & yes",
        "name_es": "under {total} & yes",
        "name_hr": "manje od {total} i da",
        "name_fr": "moins de {total} & oui"
    },
    "1873^prematch": {
        "name_en": "{$competitor1}/draw & 4",
        "name_aa": "{$competitor1}/draw & 4",
        "name_de": "{$competitor1}/unentschieden & 4",
        "name_nl": "{$competitor1}/gelijkspel & 4",
        "name_pl": "{$competitor1}/draw & 4",
        "name_ro": "{$competitor1}/draw & 4",
        "name_ru": "{$competitor1}/draw & 4",
        "name_tr": "{$competitor1}/beraberlik & 4",
        "name_se": "{$competitor1}/draw & 4",
        "name_el": "{$competitor1}/draw & 4",
        "name_es": "{$competitor1}/draw & 4",
        "name_hr": "{$competitor1}/neodlučeno i 4",
        "name_fr": "{$competitor1}/Match nul & 4"
    },
    "113^prematch": {
        "name_en": "{$competitor1} by 1",
        "name_aa": "{$competitor1} by 1",
        "name_de": "{$competitor1} by 1",
        "name_nl": "{$competitor1} by 1",
        "name_pl": "{$competitor1} by 1",
        "name_ro": "{$competitor1} by 1",
        "name_ru": "{$competitor1} by 1",
        "name_tr": "{$competitor1} by 1",
        "name_se": "{$competitor1} by 1",
        "name_el": "{$competitor1} by 1",
        "name_es": "{$competitor1} by 1",
        "name_hr": "{$competitor1} by 1",
        "name_fr": "{$competitor1} by 1"
    },
    "1356^prematch": {
        "name_en": "{$competitor1} & decision",
        "name_aa": "{$competitor1} & decision",
        "name_de": "{$competitor1} & decision",
        "name_nl": "{$competitor1} & decision",
        "name_pl": "{$competitor1} & decision",
        "name_ro": "{$competitor1} & decision",
        "name_ru": "{$competitor1} & decision",
        "name_tr": "{$competitor1} & decision",
        "name_se": "{$competitor1} & decision",
        "name_el": "{$competitor1} & decision",
        "name_es": "{$competitor1} & decision",
        "name_hr": "{$competitor1} & decision",
        "name_fr": "{$competitor1} & decision"
    },
    "228^prematch": {
        "name_en": "4:7",
        "name_aa": "4:7",
        "name_de": "4:7",
        "name_nl": "4:7",
        "name_pl": "4:7",
        "name_ro": "4:7",
        "name_ru": "4:7",
        "name_tr": "4:7",
        "name_se": "4:7",
        "name_el": "4:7",
        "name_es": "4:7",
        "name_hr": "4:7",
        "name_fr": "4:7"
    },
    "1724^prematch": {
        "name_en": "{$competitor1}/draw & under {total}",
        "name_aa": "{$competitor1}/draw & under {total}",
        "name_de": "{$competitor1}/unentschieden & unter {total}",
        "name_nl": "{$competitor1}/gelijkspel & onder {total}",
        "name_pl": "{$competitor1}/draw & under {total}",
        "name_ro": "{$competitor1}/draw & under {total}",
        "name_ru": "{$competitor1}/draw & under {total}",
        "name_tr": "{$competitor1}/beraberlik & {total} altı",
        "name_se": "{$competitor1}/draw & under {total}",
        "name_el": "{$competitor1}/draw & under {total}",
        "name_es": "{$competitor1}/draw & under {total}",
        "name_hr": "{$competitor1}/neodlučeno i manje {total}",
        "name_fr": "{$competitor1}/Match nul & moins de {total}"
    },
    "975^prematch": {
        "name_en": "{$competitor1} & under {total}",
        "name_aa": "{$competitor1} & under {total}",
        "name_de": "{$competitor1} & under {total}",
        "name_nl": "{$competitor1} & under {total}",
        "name_pl": "{$competitor1} & under {total}",
        "name_ro": "{$competitor1} & under {total}",
        "name_ru": "{$competitor1} & under {total}",
        "name_tr": "{$competitor1} & under {total}",
        "name_se": "{$competitor1} & under {total}",
        "name_el": "{$competitor1} & under {total}",
        "name_es": "{$competitor1} & under {total}",
        "name_hr": "{$competitor1} & under {total}",
        "name_fr": "{$competitor1} & under {total}"
    },
    "1694040000^prematch": {
        "name_en": "Under 7.5",
        "name_aa": "Under 7.5",
        "name_de": "Under 7.5",
        "name_nl": "Under 7.5",
        "name_pl": "Under 7.5",
        "name_ro": "Under 7.5",
        "name_ru": "Under 7.5",
        "name_tr": "Under 7.5",
        "name_se": "Under 7.5",
        "name_el": "Under 7.5",
        "name_es": "Under 7.5",
        "name_hr": "Under 7.5",
        "name_fr": "Under 7.5"
    },
    "1380^prematch": {
        "name_en": "{$competitor1} & 1-3",
        "name_aa": "{$competitor1} & 1-3",
        "name_de": "{$competitor1} & 1-3",
        "name_nl": "{$competitor1} & 1-3",
        "name_pl": "{$competitor1} & 1-3",
        "name_ro": "{$competitor1} & 1-3",
        "name_ru": "{$competitor1} & 1-3",
        "name_tr": "{$competitor1} & 1-3",
        "name_se": "{$competitor1} & 1-3",
        "name_el": "{$competitor1} & 1-3",
        "name_es": "{$competitor1} & 1-3",
        "name_hr": "{$competitor1} & 1-3",
        "name_fr": "{$competitor1} & 1-3"
    },
    "734^prematch": {
        "name_en": "3",
        "name_aa": "3",
        "name_de": "3",
        "name_nl": "3",
        "name_pl": "3",
        "name_ro": "3",
        "name_ru": "3",
        "name_tr": "3",
        "name_se": "3",
        "name_el": "3",
        "name_es": "3",
        "name_hr": "3",
        "name_fr": "3"
    },
    "170^prematch": {
        "name_en": "171-180",
        "name_aa": "171-180",
        "name_de": "171-180",
        "name_nl": "171-180",
        "name_pl": "171-180",
        "name_ro": "171-180",
        "name_ru": "171-180",
        "name_tr": "171-180",
        "name_se": "171-180",
        "name_el": "171-180",
        "name_es": "171-180",
        "name_hr": "171-180",
        "name_fr": "171-180"
    },
    "167^prematch": {
        "name_en": "0-150",
        "name_aa": "0-150",
        "name_de": "0-150",
        "name_nl": "0-150",
        "name_pl": "0-150",
        "name_ro": "0-150",
        "name_ru": "0-150",
        "name_tr": "0-150",
        "name_se": "0-150",
        "name_el": "0-150",
        "name_es": "0-150",
        "name_hr": "0-150",
        "name_fr": "0-150"
    },
    "1730^prematch": {
        "name_en": "1-2",
        "name_aa": "1-2",
        "name_de": "1-2",
        "name_nl": "1-2",
        "name_pl": "1-2",
        "name_ro": "1-2",
        "name_ru": "1-2",
        "name_tr": "1-2",
        "name_se": "1-2",
        "name_el": "1-2",
        "name_es": "1-2",
        "name_hr": "1-2",
        "name_fr": "1-2"
    },
    "81^prematch": {
        "name_en": "6",
        "name_aa": "6",
        "name_de": "6",
        "name_nl": "6",
        "name_pl": "6",
        "name_ro": "6",
        "name_ru": "6",
        "name_tr": "6",
        "name_se": "6",
        "name_el": "6",
        "name_es": "6",
        "name_hr": "6",
        "name_fr": "6"
    },
    "70780000^prematch": {
        "name_en": "1-X",
        "name_aa": "1-X",
        "name_de": "1-X",
        "name_nl": "1-X",
        "name_pl": "1-X",
        "name_ro": "1-X",
        "name_ru": "1-X",
        "name_tr": "1-X",
        "name_se": "1-X",
        "name_el": "1-X",
        "name_es": "1-X",
        "name_hr": "1-X",
        "name_fr": "1-X"
    },
    "1389^prematch": {
        "name_en": "{$competitor2} & 10-12",
        "name_aa": "{$competitor2} & 10-12",
        "name_de": "{$competitor2} & 10-12",
        "name_nl": "{$competitor2} & 10-12",
        "name_pl": "{$competitor2} & 10-12",
        "name_ro": "{$competitor2} & 10-12",
        "name_ru": "{$competitor2} & 10-12",
        "name_tr": "{$competitor2} & 10-12",
        "name_se": "{$competitor2} & 10-12",
        "name_el": "{$competitor2} & 10-12",
        "name_es": "{$competitor2} & 10-12",
        "name_hr": "{$competitor2} & 10-12",
        "name_fr": "{$competitor2} & 10-12"
    },
    "1404^prematch": {
        "name_en": "{$competitor1} & 2",
        "name_aa": "{$competitor1} & 2",
        "name_de": "{$competitor1} & 2",
        "name_nl": "{$competitor1} & 2",
        "name_pl": "{$competitor1} & 2",
        "name_ro": "{$competitor1} & 2",
        "name_ru": "{$competitor1} & 2",
        "name_tr": "{$competitor1} & 2",
        "name_se": "{$competitor1} & 2",
        "name_el": "{$competitor1} & 2",
        "name_es": "{$competitor1} & 2",
        "name_hr": "{$competitor1} & 2",
        "name_fr": "{$competitor1} & 2"
    },
    "953^prematch": {
        "name_en": "3rd inning",
        "name_aa": "3rd inning",
        "name_de": "3. Inning",
        "name_nl": "3e inning",
        "name_pl": "3rd inning",
        "name_ro": "3rd inning",
        "name_ru": "3rd inning",
        "name_tr": "3. devre",
        "name_se": "3rd inning",
        "name_el": "3rd inning",
        "name_es": "3rd inning",
        "name_hr": "3. inning",
        "name_fr": "3ème manche"
    },
    "70770000^prematch": {
        "name_en": "1-1",
        "name_aa": "1-1",
        "name_de": "1-1",
        "name_nl": "1-1",
        "name_pl": "1-1",
        "name_ro": "1-1",
        "name_ru": "1-1",
        "name_tr": "1-1",
        "name_se": "1-1",
        "name_el": "1-1",
        "name_es": "1-1",
        "name_hr": "1-1",
        "name_fr": "1-1"
    },
    "1762^prematch": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "1096^prematch": {
        "name_en": "21-27",
        "name_aa": "21-27",
        "name_de": "21-27",
        "name_nl": "21-27",
        "name_pl": "21-27",
        "name_ro": "21-27",
        "name_ru": "21-27",
        "name_tr": "21-27",
        "name_se": "21-27",
        "name_el": "21-27",
        "name_es": "21-27",
        "name_hr": "21-27",
        "name_fr": "21-27"
    },
    "1882^prematch": {
        "name_en": "{$competitor1}/draw & 5+",
        "name_aa": "{$competitor1}/draw & 5+",
        "name_de": "{$competitor1}/unentschieden & 5+",
        "name_nl": "{$competitor1}/gelijkspel & 5+",
        "name_pl": "{$competitor1}/draw & 5+",
        "name_ro": "{$competitor1}/draw & 5+",
        "name_ru": "{$competitor1}/draw & 5+",
        "name_tr": "{$competitor1}/beraberlik & 5+",
        "name_se": "{$competitor1}/draw & 5+",
        "name_el": "{$competitor1}/draw & 5+",
        "name_es": "{$competitor1}/draw & 5+",
        "name_hr": "{$competitor1}/neodlučeno i 5+",
        "name_fr": "{$competitor1}/Match nul & 5 ou +"
    },
    "1694130000^prematch": {
        "name_en": "Over 12.5",
        "name_aa": "Over 12.5",
        "name_de": "Over 12.5",
        "name_nl": "Over 12.5",
        "name_pl": "Over 12.5",
        "name_ro": "Over 12.5",
        "name_ru": "Over 12.5",
        "name_tr": "Over 12.5",
        "name_se": "Over 12.5",
        "name_el": "Over 12.5",
        "name_es": "Over 12.5",
        "name_hr": "Over 12.5",
        "name_fr": "Over 12.5"
    },
    "295^prematch": {
        "name_en": "11:8",
        "name_aa": "11:8",
        "name_de": "11:8",
        "name_nl": "11:8",
        "name_pl": "11:8",
        "name_ro": "11:8",
        "name_ru": "11:8",
        "name_tr": "11:8",
        "name_se": "11:8",
        "name_el": "11:8",
        "name_es": "11:8",
        "name_hr": "11:8",
        "name_fr": "11:8"
    },
    "941^prematch": {
        "name_en": "over {total}",
        "name_aa": "over {total}",
        "name_de": "über {total}",
        "name_nl": "boven {total}",
        "name_pl": "over {total}",
        "name_ro": "over {total}",
        "name_ru": "over {total}",
        "name_tr": "{total} üstünde",
        "name_se": "over {total}",
        "name_el": "over {total}",
        "name_es": "over {total}",
        "name_hr": "više od {total}",
        "name_fr": "plus de {total}"
    },
    "83560000^prematch": {
        "name_en": "No",
        "name_aa": "No",
        "name_de": "No",
        "name_nl": "No",
        "name_pl": "No",
        "name_ro": "No",
        "name_ru": "No",
        "name_tr": "No",
        "name_se": "No",
        "name_el": "No",
        "name_es": "No",
        "name_hr": "No",
        "name_fr": "No"
    },
    "189^prematch": {
        "name_en": "2:6",
        "name_aa": "2:6",
        "name_de": "2:6",
        "name_nl": "2:6",
        "name_pl": "2:6",
        "name_ro": "2:6",
        "name_ru": "2:6",
        "name_tr": "2:6",
        "name_se": "2:6",
        "name_el": "2:6",
        "name_es": "2:6",
        "name_hr": "2:6",
        "name_fr": "2:6"
    },
    "1867^prematch": {
        "name_en": "{$competitor1}/{$competitor2} & 3",
        "name_aa": "{$competitor1}/{$competitor2} & 3",
        "name_de": "{$competitor1}/{$competitor2} & 3",
        "name_nl": "{$competitor1}/{$competitor2} & 3",
        "name_pl": "{$competitor1}/{$competitor2} & 3",
        "name_ro": "{$competitor1}/{$competitor2} & 3",
        "name_ru": "{$competitor1}/{$competitor2} & 3",
        "name_tr": "{$competitor1}/{$competitor2} & 3",
        "name_se": "{$competitor1}/{$competitor2} & 3",
        "name_el": "{$competitor1}/{$competitor2} & 3",
        "name_es": "{$competitor1}/{$competitor2} & 3",
        "name_hr": "{$competitor1}/{$competitor2} i 3",
        "name_fr": "{$competitor1}/{$competitor2} & 3"
    },
    "1694280000^prematch": {
        "name_en": "Under 5.5",
        "name_aa": "Under 5.5",
        "name_de": "Under 5.5",
        "name_nl": "Under 5.5",
        "name_pl": "Under 5.5",
        "name_ro": "Under 5.5",
        "name_ru": "Under 5.5",
        "name_tr": "Under 5.5",
        "name_se": "Under 5.5",
        "name_el": "Under 5.5",
        "name_es": "Under 5.5",
        "name_hr": "Under 5.5",
        "name_fr": "Under 5.5"
    },
    "1694680000^prematch": {
        "name_en": "Yes",
        "name_aa": "Yes",
        "name_de": "Yes",
        "name_nl": "Yes",
        "name_pl": "Yes",
        "name_ro": "Yes",
        "name_ru": "Yes",
        "name_tr": "Yes",
        "name_se": "Yes",
        "name_el": "Yes",
        "name_es": "Yes",
        "name_hr": "Yes",
        "name_fr": "Yes"
    },
    "1863^prematch": {
        "name_en": "draw/{$competitor2} & 2",
        "name_aa": "draw/{$competitor2} & 2",
        "name_de": "unentschieden/{$competitor2} & 2",
        "name_nl": "gelijkspel/{$competitor2} & 2",
        "name_pl": "draw/{$competitor2} & 2",
        "name_ro": "draw/{$competitor2} & 2",
        "name_ru": "draw/{$competitor2} & 2",
        "name_tr": "beraberlik/{$competitor2} & 2",
        "name_se": "draw/{$competitor2} & 2",
        "name_el": "draw/{$competitor2} & 2",
        "name_es": "draw/{$competitor2} & 2",
        "name_hr": "neodlučeno/{$competitor2} i 2",
        "name_fr": "Match nul/{$competitor2} & 2"
    },
    "336^prematch": {
        "name_en": "0:0 1:1",
        "name_aa": "0:0 1:1",
        "name_de": "0:0 1:1",
        "name_nl": "0:0 1:1",
        "name_pl": "0:0 1:1",
        "name_ro": "0:0 1:1",
        "name_ru": "0:0 1:1",
        "name_tr": "0:0 1:1",
        "name_se": "0:0 1:1",
        "name_el": "0:0 1:1",
        "name_es": "0:0 1:1",
        "name_hr": "0:0 1:1",
        "name_fr": "0:0 1:1"
    },
    "1851^prematch": {
        "name_en": "{$competitor2}/{$competitor1} & over {total}",
        "name_aa": "{$competitor2}/{$competitor1} & over {total}",
        "name_de": "{$competitor2}/{$competitor1} & über {total}",
        "name_nl": "{$competitor2}/{$competitor1} & boven {total}",
        "name_pl": "{$competitor2}/{$competitor1} & over {total}",
        "name_ro": "{$competitor2}/{$competitor1} & over {total}",
        "name_ru": "{$competitor2}/{$competitor1} & over {total}",
        "name_tr": "{$competitor2}/{$competitor1} & {total} üstü",
        "name_se": "{$competitor2}/{$competitor1} & over {total}",
        "name_el": "{$competitor2}/{$competitor1} & over {total}",
        "name_es": "{$competitor2}/{$competitor1} & over {total}",
        "name_hr": "{$competitor2}/{$competitor1} i više {total}",
        "name_fr": "{$competitor2}/{$competitor1} & Plus de {total}"
    },
    "1430000^prematch": {
        "name_en": "{$competitor2}",
        "name_aa": "{$competitor2}",
        "name_de": "{$competitor2}",
        "name_nl": "{$competitor2}",
        "name_pl": "{$competitor2}",
        "name_ro": "{$competitor2}",
        "name_ru": "{$competitor2}",
        "name_tr": "{$competitor2}",
        "name_se": "{$competitor2}",
        "name_el": "{$competitor2}",
        "name_es": "{$competitor2}",
        "name_hr": "{$competitor2}",
        "name_fr": "{$competitor2}"
    },
    "922^prematch": {
        "name_en": "3rd quarter",
        "name_aa": "3rd quarter",
        "name_de": "3. Viertel",
        "name_nl": "Derde kwart",
        "name_pl": "3rd quarter",
        "name_ro": "3rd quarter",
        "name_ru": "3rd quarter",
        "name_tr": "Üçüncü çeyrek",
        "name_se": "3rd quarter",
        "name_el": "3rd quarter",
        "name_es": "3rd quarter",
        "name_hr": "3. četvrtina",
        "name_fr": "3ème quart-temps"
    },
    "550^prematch": {
        "name_en": "0:2",
        "name_aa": "0:2",
        "name_de": "0:2",
        "name_nl": "0:2",
        "name_pl": "0:2",
        "name_ro": "0:2",
        "name_ru": "0:2",
        "name_tr": "0:2",
        "name_se": "0:2",
        "name_el": "0:2",
        "name_es": "0:2",
        "name_hr": "0:2",
        "name_fr": "0:2"
    },
    "1839^prematch": {
        "name_en": "draw/{$competitor1} & under {total}",
        "name_aa": "draw/{$competitor1} & under {total}",
        "name_de": "unentschieden /{$competitor1} & unter {total}",
        "name_nl": "gelijkspel/{$competitor1} & onder {total}",
        "name_pl": "draw/{$competitor1} & under {total}",
        "name_ro": "draw/{$competitor1} & under {total}",
        "name_ru": "draw/{$competitor1} & under {total}",
        "name_tr": "beraberlik/{$competitor1} & {total} altı",
        "name_se": "draw/{$competitor1} & under {total}",
        "name_el": "draw/{$competitor1} & under {total}",
        "name_es": "draw/{$competitor1} & under {total}",
        "name_hr": "neodlučeno/{$competitor1} i manje {total}",
        "name_fr": "Match nul/{$competitor1} & Moins de {total}"
    },
    "869^prematch": {
        "name_en": "6:4",
        "name_aa": "6:4",
        "name_de": "6:4",
        "name_nl": "6:4",
        "name_pl": "6:4",
        "name_ro": "6:4",
        "name_ru": "6:4",
        "name_tr": "6:4",
        "name_se": "6:4",
        "name_el": "6:4",
        "name_es": "6:4",
        "name_hr": "6:4",
        "name_fr": "6:4"
    },
    "28^prematch": {
        "name_en": "3:5",
        "name_aa": "3:5",
        "name_de": "3:5",
        "name_nl": "3:5",
        "name_pl": "3:5",
        "name_ro": "3:5",
        "name_ru": "3:5",
        "name_tr": "3:5",
        "name_se": "3:5",
        "name_el": "3:5",
        "name_es": "3:5",
        "name_hr": "3:5",
        "name_fr": "3:5"
    },
    "198^prematch": {
        "name_en": "6:6",
        "name_aa": "6:6",
        "name_de": "6:6",
        "name_nl": "6:6",
        "name_pl": "6:6",
        "name_ro": "6:6",
        "name_ru": "6:6",
        "name_tr": "6:6",
        "name_se": "6:6",
        "name_el": "6:6",
        "name_es": "6:6",
        "name_hr": "6:6",
        "name_fr": "6:6"
    },
    "1170^prematch": {
        "name_en": "1:5",
        "name_aa": "1:5",
        "name_de": "1:5",
        "name_nl": "1:5",
        "name_pl": "1:5",
        "name_ro": "1:5",
        "name_ru": "1:5",
        "name_tr": "1:5",
        "name_se": "1:5",
        "name_el": "1:5",
        "name_es": "1:5",
        "name_hr": "1:5",
        "name_fr": "1:5"
    },
    "289^prematch": {
        "name_en": "11:2",
        "name_aa": "11:2",
        "name_de": "11:2",
        "name_nl": "11:2",
        "name_pl": "11:2",
        "name_ro": "11:2",
        "name_ru": "11:2",
        "name_tr": "11:2",
        "name_se": "11:2",
        "name_el": "11:2",
        "name_es": "11:2",
        "name_hr": "11:2",
        "name_fr": "11:2"
    },
    "20^prematch": {
        "name_en": "1:4",
        "name_aa": "1:4",
        "name_de": "1:4",
        "name_nl": "1:4",
        "name_pl": "1:4",
        "name_ro": "1:4",
        "name_ru": "1:4",
        "name_tr": "1:4",
        "name_se": "1:4",
        "name_el": "1:4",
        "name_es": "1:4",
        "name_hr": "1:4",
        "name_fr": "1:4"
    },
    "25^prematch": {
        "name_en": "5:3",
        "name_aa": "5:3",
        "name_de": "5:3",
        "name_nl": "5:3",
        "name_pl": "5:3",
        "name_ro": "5:3",
        "name_ru": "5:3",
        "name_tr": "5:3",
        "name_se": "5:3",
        "name_el": "5:3",
        "name_es": "5:3",
        "name_hr": "5:3",
        "name_fr": "5:3"
    },
    "22^prematch": {
        "name_en": "5:0",
        "name_aa": "5:0",
        "name_de": "5:0",
        "name_nl": "5:0",
        "name_pl": "5:0",
        "name_ro": "5:0",
        "name_ru": "5:0",
        "name_tr": "5:0",
        "name_se": "5:0",
        "name_el": "5:0",
        "name_es": "5:0",
        "name_hr": "5:0",
        "name_fr": "5:0"
    },
    "1350^prematch": {
        "name_en": "{$competitor2} by ko",
        "name_aa": "{$competitor2} by ko",
        "name_de": "{$competitor2} by ko",
        "name_nl": "{$competitor2} by ko",
        "name_pl": "{$competitor2} by ko",
        "name_ro": "{$competitor2} by ko",
        "name_ru": "{$competitor2} by ko",
        "name_tr": "{$competitor2} by ko",
        "name_se": "{$competitor2} by ko",
        "name_el": "{$competitor2} by ko",
        "name_es": "{$competitor2} by ko",
        "name_hr": "{$competitor2} by ko",
        "name_fr": "{$competitor2} by ko"
    },
    "1386^prematch": {
        "name_en": "{$competitor2} & 1-3",
        "name_aa": "{$competitor2} & 1-3",
        "name_de": "{$competitor2} & 1-3",
        "name_nl": "{$competitor2} & 1-3",
        "name_pl": "{$competitor2} & 1-3",
        "name_ro": "{$competitor2} & 1-3",
        "name_ru": "{$competitor2} & 1-3",
        "name_tr": "{$competitor2} & 1-3",
        "name_se": "{$competitor2} & 1-3",
        "name_el": "{$competitor2} & 1-3",
        "name_es": "{$competitor2} & 1-3",
        "name_hr": "{$competitor2} & 1-3",
        "name_fr": "{$competitor2} & 1-3"
    },
    "168^prematch": {
        "name_en": "151-160",
        "name_aa": "151-160",
        "name_de": "151-160",
        "name_nl": "151-160",
        "name_pl": "151-160",
        "name_ro": "151-160",
        "name_ru": "151-160",
        "name_tr": "151-160",
        "name_se": "151-160",
        "name_el": "151-160",
        "name_es": "151-160",
        "name_hr": "151-160",
        "name_fr": "151-160"
    },
    "472^prematch": {
        "name_en": "2:1",
        "name_aa": "2:1",
        "name_de": "2:1",
        "name_nl": "2:1",
        "name_pl": "2:1",
        "name_ro": "2:1",
        "name_ru": "2:1",
        "name_tr": "2:1",
        "name_se": "2:1",
        "name_el": "2:1",
        "name_es": "2:1",
        "name_hr": "2:1",
        "name_fr": "2:1"
    },
    "1291^prematch": {
        "name_en": "{$competitor2} by 11-15",
        "name_aa": "{$competitor2} by 11-15",
        "name_de": "{$competitor2} by 11-15",
        "name_nl": "{$competitor2} by 11-15",
        "name_pl": "{$competitor2} by 11-15",
        "name_ro": "{$competitor2} by 11-15",
        "name_ru": "{$competitor2} by 11-15",
        "name_tr": "{$competitor2} by 11-15",
        "name_se": "{$competitor2} by 11-15",
        "name_el": "{$competitor2} by 11-15",
        "name_es": "{$competitor2} by 11-15",
        "name_hr": "{$competitor2} by 11-15",
        "name_fr": "{$competitor2} by 11-15"
    },
    "1418^prematch": {
        "name_en": "{$competitor2} & 2",
        "name_aa": "{$competitor2} & 2",
        "name_de": "{$competitor2} & 2",
        "name_nl": "{$competitor2} & 2",
        "name_pl": "{$competitor2} & 2",
        "name_ro": "{$competitor2} & 2",
        "name_ru": "{$competitor2} & 2",
        "name_tr": "{$competitor2} & 2",
        "name_se": "{$competitor2} & 2",
        "name_el": "{$competitor2} & 2",
        "name_es": "{$competitor2} & 2",
        "name_hr": "{$competitor2} & 2",
        "name_fr": "{$competitor2} & 2"
    },
    "1694150000^prematch": {
        "name_en": "Over 13.5",
        "name_aa": "Over 13.5",
        "name_de": "Over 13.5",
        "name_nl": "Over 13.5",
        "name_pl": "Over 13.5",
        "name_ro": "Over 13.5",
        "name_ru": "Over 13.5",
        "name_tr": "Over 13.5",
        "name_se": "Over 13.5",
        "name_el": "Over 13.5",
        "name_es": "Over 13.5",
        "name_hr": "Over 13.5",
        "name_fr": "Over 13.5"
    },
    "1384^prematch": {
        "name_en": "{$competitor1} & decision",
        "name_aa": "{$competitor1} & decision",
        "name_de": "{$competitor1} & decision",
        "name_nl": "{$competitor1} & decision",
        "name_pl": "{$competitor1} & decision",
        "name_ro": "{$competitor1} & decision",
        "name_ru": "{$competitor1} & decision",
        "name_tr": "{$competitor1} & decision",
        "name_se": "{$competitor1} & decision",
        "name_el": "{$competitor1} & decision",
        "name_es": "{$competitor1} & decision",
        "name_hr": "{$competitor1} & decision",
        "name_fr": "{$competitor1} & decision"
    },
    "1347^prematch": {
        "name_en": "{$competitor1} by submission",
        "name_aa": "{$competitor1} by submission",
        "name_de": "{$competitor1} by submission",
        "name_nl": "{$competitor1} by submission",
        "name_pl": "{$competitor1} by submission",
        "name_ro": "{$competitor1} by submission",
        "name_ru": "{$competitor1} by submission",
        "name_tr": "{$competitor1} by submission",
        "name_se": "{$competitor1} by submission",
        "name_el": "{$competitor1} by submission",
        "name_es": "{$competitor1} by submission",
        "name_hr": "{$competitor1} by submission",
        "name_fr": "{$competitor1} by submission"
    },
    "1755^prematch": {
        "name_en": "1:2, 1:3 or 1:4",
        "name_aa": "1:2, 1:3 or 1:4",
        "name_de": "1:2, 1:3 oder 1:4",
        "name_nl": "1:2, 1:3 of 1:4",
        "name_pl": "1:2, 1:3 or 1:4",
        "name_ro": "1:2, 1:3 or 1:4",
        "name_ru": "1:2, 1:3 or 1:4",
        "name_tr": "1:2, 1:3 veya 1:4",
        "name_se": "1:2, 1:3 or 1:4",
        "name_el": "1:2, 1:3 or 1:4",
        "name_es": "1:2, 1:3 or 1:4",
        "name_hr": "1:2, 1:3 ili 1:4",
        "name_fr": "1:2, 1:3 ou 1:4"
    },
    "1167^prematch": {
        "name_en": "1:2",
        "name_aa": "1:2",
        "name_de": "1:2",
        "name_nl": "1:2",
        "name_pl": "1:2",
        "name_ro": "1:2",
        "name_ru": "1:2",
        "name_tr": "1:2",
        "name_se": "1:2",
        "name_el": "1:2",
        "name_es": "1:2",
        "name_hr": "1:2",
        "name_fr": "1:2"
    },
    "1732^prematch": {
        "name_en": "1-4",
        "name_aa": "1-4",
        "name_de": "1-4",
        "name_nl": "1-4",
        "name_pl": "1-4",
        "name_ro": "1-4",
        "name_ru": "1-4",
        "name_tr": "1-4",
        "name_se": "1-4",
        "name_el": "1-4",
        "name_es": "1-4",
        "name_hr": "1-4",
        "name_fr": "1-4"
    },
    "277^prematch": {
        "name_en": "9:10",
        "name_aa": "9:10",
        "name_de": "9:10",
        "name_nl": "9:10",
        "name_pl": "9:10",
        "name_ro": "9:10",
        "name_ru": "9:10",
        "name_tr": "9:10",
        "name_se": "9:10",
        "name_el": "9:10",
        "name_es": "9:10",
        "name_hr": "9:10",
        "name_fr": "9:10"
    },
    "308^prematch": {
        "name_en": "0:11",
        "name_aa": "0:11",
        "name_de": "0:11",
        "name_nl": "0:11",
        "name_pl": "0:11",
        "name_ro": "0:11",
        "name_ru": "0:11",
        "name_tr": "0:11",
        "name_se": "0:11",
        "name_el": "0:11",
        "name_es": "0:11",
        "name_hr": "0:11",
        "name_fr": "0:11"
    },
    "274^prematch": {
        "name_en": "10:7",
        "name_aa": "10:7",
        "name_de": "10:7",
        "name_nl": "10:7",
        "name_pl": "10:7",
        "name_ro": "10:7",
        "name_ru": "10:7",
        "name_tr": "10:7",
        "name_se": "10:7",
        "name_el": "10:7",
        "name_es": "10:7",
        "name_hr": "10:7",
        "name_fr": "10:7"
    },
    "872^prematch": {
        "name_en": "0:6",
        "name_aa": "0:6",
        "name_de": "0:6",
        "name_nl": "0:6",
        "name_pl": "0:6",
        "name_ro": "0:6",
        "name_ru": "0:6",
        "name_tr": "0:6",
        "name_se": "0:6",
        "name_el": "0:6",
        "name_es": "0:6",
        "name_hr": "0:6",
        "name_fr": "0:6"
    },
    "584^prematch": {
        "name_en": "1-15",
        "name_aa": "1-15",
        "name_de": "1-15",
        "name_nl": "1-15",
        "name_pl": "1-15",
        "name_ro": "1-15",
        "name_ru": "1-15",
        "name_tr": "1-15",
        "name_se": "1-15",
        "name_el": "1-15",
        "name_es": "1-15",
        "name_hr": "1-15",
        "name_fr": "1-15"
    },
    "60020000^prematch": {
        "name_en": "4+/5+",
        "name_aa": "4+/5+",
        "name_de": "4+/5+",
        "name_nl": "4+/5+",
        "name_pl": "4+/5+",
        "name_ro": "4+/5+",
        "name_ru": "4+/5+",
        "name_tr": "4+/5+",
        "name_se": "4+/5+",
        "name_el": "4+/5+",
        "name_es": "4+/5+",
        "name_hr": "4+/5+",
        "name_fr": "4+/5+"
    },
    "115^prematch": {
        "name_en": "{$competitor1} by 3+",
        "name_aa": "{$competitor1} by 3+",
        "name_de": "{$competitor1} by 3+",
        "name_nl": "{$competitor1} by 3+",
        "name_pl": "{$competitor1} by 3+",
        "name_ro": "{$competitor1} by 3+",
        "name_ru": "{$competitor1} by 3+",
        "name_tr": "{$competitor1} by 3+",
        "name_se": "{$competitor1} by 3+",
        "name_el": "{$competitor1} by 3+",
        "name_es": "{$competitor1} by 3+",
        "name_hr": "{$competitor1} by 3+",
        "name_fr": "{$competitor1} by 3+"
    },
    "6^prematch": {
        "name_en": "1:2",
        "name_aa": "1:2",
        "name_de": "1:2",
        "name_nl": "1:2",
        "name_pl": "1:2",
        "name_ro": "1:2",
        "name_ru": "1:2",
        "name_tr": "1:2",
        "name_se": "1:2",
        "name_el": "1:2",
        "name_es": "1:2",
        "name_hr": "1:2",
        "name_fr": "1:2"
    },
    "55^prematch": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "356^prematch": {
        "name_en": "0:1 1:2",
        "name_aa": "0:1 1:2",
        "name_de": "0:1 1:2",
        "name_nl": "0:1 1:2",
        "name_pl": "0:1 1:2",
        "name_ro": "0:1 1:2",
        "name_ru": "0:1 1:2",
        "name_tr": "0:1 1:2",
        "name_se": "0:1 1:2",
        "name_el": "0:1 1:2",
        "name_es": "0:1 1:2",
        "name_hr": "0:1 1:2",
        "name_fr": "0:1 1:2"
    },
    "736^prematch": {
        "name_en": "4+",
        "name_aa": "4+",
        "name_de": "4+",
        "name_nl": "4+",
        "name_pl": "4+",
        "name_ro": "4+",
        "name_ru": "4+",
        "name_tr": "4+",
        "name_se": "4+",
        "name_el": "4+",
        "name_es": "4+",
        "name_hr": "4+",
        "name_fr": "4+"
    },
    "974^prematch": {
        "name_en": "{$competitor2} & over {total}",
        "name_aa": "{$competitor2} & over {total}",
        "name_de": "{$competitor2} & over {total}",
        "name_nl": "{$competitor2} & over {total}",
        "name_pl": "{$competitor2} & over {total}",
        "name_ro": "{$competitor2} & over {total}",
        "name_ru": "{$competitor2} & over {total}",
        "name_tr": "{$competitor2} & over {total}",
        "name_se": "{$competitor2} & over {total}",
        "name_el": "{$competitor2} & over {total}",
        "name_es": "{$competitor2} & over {total}",
        "name_hr": "{$competitor2} & over {total}",
        "name_fr": "{$competitor2} & over {total}"
    },
    "31^prematch": {
        "name_en": "0:5",
        "name_aa": "0:5",
        "name_de": "0:5",
        "name_nl": "0:5",
        "name_pl": "0:5",
        "name_ro": "0:5",
        "name_ru": "0:5",
        "name_tr": "0:5",
        "name_se": "0:5",
        "name_el": "0:5",
        "name_es": "0:5",
        "name_hr": "0:5",
        "name_fr": "0:5"
    },
    "1192^prematch": {
        "name_en": "7:0",
        "name_aa": "7:0",
        "name_de": "7:0",
        "name_nl": "7:0",
        "name_pl": "7:0",
        "name_ro": "7:0",
        "name_ru": "7:0",
        "name_tr": "7:0",
        "name_se": "7:0",
        "name_el": "7:0",
        "name_es": "7:0",
        "name_hr": "7:0",
        "name_fr": "7:0"
    },
    "70830000^prematch": {
        "name_en": "2-1",
        "name_aa": "2-1",
        "name_de": "2-1",
        "name_nl": "2-1",
        "name_pl": "2-1",
        "name_ro": "2-1",
        "name_ru": "2-1",
        "name_tr": "2-1",
        "name_se": "2-1",
        "name_el": "2-1",
        "name_es": "2-1",
        "name_hr": "2-1",
        "name_fr": "2-1"
    },
    "241^prematch": {
        "name_en": "7:8",
        "name_aa": "7:8",
        "name_de": "7:8",
        "name_nl": "7:8",
        "name_pl": "7:8",
        "name_ro": "7:8",
        "name_ru": "7:8",
        "name_tr": "7:8",
        "name_se": "7:8",
        "name_el": "7:8",
        "name_es": "7:8",
        "name_hr": "7:8",
        "name_fr": "7:8"
    },
    "127^prematch": {
        "name_en": "{$competitor2} by 1-5",
        "name_aa": "{$competitor2} by 1-5",
        "name_de": "{$competitor2} by 1-5",
        "name_nl": "{$competitor2} by 1-5",
        "name_pl": "{$competitor2} by 1-5",
        "name_ro": "{$competitor2} by 1-5",
        "name_ru": "{$competitor2} by 1-5",
        "name_tr": "{$competitor2} by 1-5",
        "name_se": "{$competitor2} by 1-5",
        "name_el": "{$competitor2} by 1-5",
        "name_es": "{$competitor2} by 1-5",
        "name_hr": "{$competitor2} by 1-5",
        "name_fr": "{$competitor2} by 1-5"
    },
    "60090000^prematch": {
        "name_en": "6+/4+",
        "name_aa": "6+/4+",
        "name_de": "6+/4+",
        "name_nl": "6+/4+",
        "name_pl": "6+/4+",
        "name_ro": "6+/4+",
        "name_ru": "6+/4+",
        "name_tr": "6+/4+",
        "name_se": "6+/4+",
        "name_el": "6+/4+",
        "name_es": "6+/4+",
        "name_hr": "6+/4+",
        "name_fr": "6+/4+"
    },
    "1711^prematch": {
        "name_en": "{$competitor1} ({hcp})",
        "name_aa": "{$competitor1} ({hcp})",
        "name_de": "{$competitor1} ({hcp})",
        "name_nl": "{$competitor1} ({hcp})",
        "name_pl": "{$competitor1} ({hcp})",
        "name_ro": "{$competitor1} ({hcp})",
        "name_ru": "{$competitor1} ({hcp})",
        "name_tr": "{$competitor1} ({hcp})",
        "name_se": "{$competitor1} ({hcp})",
        "name_el": "{$competitor1} ({hcp})",
        "name_es": "{$competitor1} ({hcp})",
        "name_hr": "{$competitor1} ({hcp})",
        "name_fr": "{$competitor1} ({hcp})"
    },
    "1476^prematch": {
        "name_en": "1:0",
        "name_aa": "1:0",
        "name_de": "1:0",
        "name_nl": "1:0",
        "name_pl": "1:0",
        "name_ro": "1:0",
        "name_ru": "1:0",
        "name_tr": "1:0",
        "name_se": "1:0",
        "name_el": "1:0",
        "name_es": "1:0",
        "name_hr": "1:0",
        "name_fr": "1:0"
    },
    "221^prematch": {
        "name_en": "7:2",
        "name_aa": "7:2",
        "name_de": "7:2",
        "name_nl": "7:2",
        "name_pl": "7:2",
        "name_ro": "7:2",
        "name_ru": "7:2",
        "name_tr": "7:2",
        "name_se": "7:2",
        "name_el": "7:2",
        "name_es": "7:2",
        "name_hr": "7:2",
        "name_fr": "7:2"
    },
    "1089^prematch": {
        "name_en": "31-35",
        "name_aa": "31-35",
        "name_de": "31-35",
        "name_nl": "31-35",
        "name_pl": "31-35",
        "name_ro": "31-35",
        "name_ru": "31-35",
        "name_tr": "31-35",
        "name_se": "31-35",
        "name_el": "31-35",
        "name_es": "31-35",
        "name_hr": "31-35",
        "name_fr": "31-35"
    },
    "1751^prematch": {
        "name_en": "0:1, 0:2 or 0:3",
        "name_aa": "0:1, 0:2 or 0:3",
        "name_de": "0:1, 0:2 oder 0:3",
        "name_nl": "0:1, 0:2 of 0:3",
        "name_pl": "0:1, 0:2 or 0:3",
        "name_ro": "0:1, 0:2 or 0:3",
        "name_ru": "0:1, 0:2 or 0:3",
        "name_tr": "0:1, 0:2 veya 0:3",
        "name_se": "0:1, 0:2 or 0:3",
        "name_el": "0:1, 0:2 or 0:3",
        "name_es": "0:1, 0:2 or 0:3",
        "name_hr": "0:1, 0:2 ili 0:3",
        "name_fr": "0:1, 0:2 ou 0:3"
    },
    "1483^prematch": {
        "name_en": "2:1",
        "name_aa": "2:1",
        "name_de": "2:1",
        "name_nl": "2:1",
        "name_pl": "2:1",
        "name_ro": "2:1",
        "name_ru": "2:1",
        "name_tr": "2:1",
        "name_se": "2:1",
        "name_el": "2:1",
        "name_es": "2:1",
        "name_hr": "2:1",
        "name_fr": "2:1"
    },
    "604^prematch": {
        "name_en": "31-40",
        "name_aa": "31-40",
        "name_de": "31-40",
        "name_nl": "31-40",
        "name_pl": "31-40",
        "name_ro": "31-40",
        "name_ru": "31-40",
        "name_tr": "31-40",
        "name_se": "31-40",
        "name_el": "31-40",
        "name_es": "31-40",
        "name_hr": "31-40",
        "name_fr": "31-40"
    },
    "961^prematch": {
        "name_en": "0",
        "name_aa": "0",
        "name_de": "0",
        "name_nl": "0",
        "name_pl": "0",
        "name_ro": "0",
        "name_ru": "0",
        "name_tr": "0",
        "name_se": "0",
        "name_el": "0",
        "name_es": "0",
        "name_hr": "0",
        "name_fr": "0"
    },
    "5^prematch": {
        "name_en": "{$competitor2}",
        "name_aa": "{$competitor2}",
        "name_de": "{$competitor2}",
        "name_nl": "{$competitor2}",
        "name_pl": "{$competitor2}",
        "name_ro": "{$competitor2}",
        "name_ru": "{$competitor2}",
        "name_tr": "{$competitor2}",
        "name_se": "{$competitor2}",
        "name_el": "{$competitor2}",
        "name_es": "{$competitor2}",
        "name_hr": "{$competitor2}",
        "name_fr": "{$competitor2}"
    },
    "404^prematch": {
        "name_en": "2:0 3:0",
        "name_aa": "2:0 3:0",
        "name_de": "2:0 3:0",
        "name_nl": "2:0 3:0",
        "name_pl": "2:0 3:0",
        "name_ro": "2:0 3:0",
        "name_ru": "2:0 3:0",
        "name_tr": "2:0 3:0",
        "name_se": "2:0 3:0",
        "name_el": "2:0 3:0",
        "name_es": "2:0 3:0",
        "name_hr": "2:0 3:0",
        "name_fr": "2:0 3:0"
    },
    "1480^prematch": {
        "name_en": "5:0",
        "name_aa": "5:0",
        "name_de": "5:0",
        "name_nl": "5:0",
        "name_pl": "5:0",
        "name_ro": "5:0",
        "name_ru": "5:0",
        "name_tr": "5:0",
        "name_se": "5:0",
        "name_el": "5:0",
        "name_es": "5:0",
        "name_hr": "5:0",
        "name_fr": "5:0"
    },
    "60060000^prematch": {
        "name_en": "5+/6+",
        "name_aa": "5+/6+",
        "name_de": "5+/6+",
        "name_nl": "5+/6+",
        "name_pl": "5+/6+",
        "name_ro": "5+/6+",
        "name_ru": "5+/6+",
        "name_tr": "5+/6+",
        "name_se": "5+/6+",
        "name_el": "5+/6+",
        "name_es": "5+/6+",
        "name_hr": "5+/6+",
        "name_fr": "5+/6+"
    },
    "1247^prematch": {
        "name_en": "70-74",
        "name_aa": "70-74",
        "name_de": "70-74",
        "name_nl": "70-74",
        "name_pl": "70-74",
        "name_ro": "70-74",
        "name_ru": "70-74",
        "name_tr": "70-74",
        "name_se": "70-74",
        "name_el": "70-74",
        "name_es": "70-74",
        "name_hr": "70-74",
        "name_fr": "70-74"
    },
    "1765^prematch": {
        "name_en": "5",
        "name_aa": "5",
        "name_de": "5",
        "name_nl": "5",
        "name_pl": "5",
        "name_ro": "5",
        "name_ru": "5",
        "name_tr": "5",
        "name_se": "5",
        "name_el": "5",
        "name_es": "5",
        "name_hr": "5",
        "name_fr": "5"
    },
    "125^prematch": {
        "name_en": "{$competitor1} by 1-5",
        "name_aa": "{$competitor1} by 1-5",
        "name_de": "{$competitor1} by 1-5",
        "name_nl": "{$competitor1} by 1-5",
        "name_pl": "{$competitor1} by 1-5",
        "name_ro": "{$competitor1} by 1-5",
        "name_ru": "{$competitor1} by 1-5",
        "name_tr": "{$competitor1} by 1-5",
        "name_se": "{$competitor1} by 1-5",
        "name_el": "{$competitor1} by 1-5",
        "name_es": "{$competitor1} by 1-5",
        "name_hr": "{$competitor1} by 1-5",
        "name_fr": "{$competitor1} by 1-5"
    },
    "153^prematch": {
        "name_en": "42-48",
        "name_aa": "42-48",
        "name_de": "42-48",
        "name_nl": "42-48",
        "name_pl": "42-48",
        "name_ro": "42-48",
        "name_ru": "42-48",
        "name_tr": "42-48",
        "name_se": "42-48",
        "name_el": "42-48",
        "name_es": "42-48",
        "name_hr": "42-48",
        "name_fr": "42-48"
    },
    "1070^prematch": {
        "name_en": "0-99",
        "name_aa": "0-99",
        "name_de": "0-99",
        "name_nl": "0-99",
        "name_pl": "0-99",
        "name_ro": "0-99",
        "name_ru": "0-99",
        "name_tr": "0-99",
        "name_se": "0-99",
        "name_el": "0-99",
        "name_es": "0-99",
        "name_hr": "0-99",
        "name_fr": "0-99"
    },
    "188^prematch": {
        "name_en": "3:6",
        "name_aa": "3:6",
        "name_de": "3:6",
        "name_nl": "3:6",
        "name_pl": "3:6",
        "name_ro": "3:6",
        "name_ru": "3:6",
        "name_tr": "3:6",
        "name_se": "3:6",
        "name_el": "3:6",
        "name_es": "3:6",
        "name_hr": "3:6",
        "name_fr": "3:6"
    },
    "1736^prematch": {
        "name_en": "2-4",
        "name_aa": "2-4",
        "name_de": "2-4",
        "name_nl": "2-4",
        "name_pl": "2-4",
        "name_ro": "2-4",
        "name_ru": "2-4",
        "name_tr": "2-4",
        "name_se": "2-4",
        "name_el": "2-4",
        "name_es": "2-4",
        "name_hr": "2-4",
        "name_fr": "2-4"
    },
    "1341^prematch": {
        "name_en": "5+",
        "name_aa": "5+",
        "name_de": "5+",
        "name_nl": "5+",
        "name_pl": "5+",
        "name_ro": "5+",
        "name_ru": "5+",
        "name_tr": "5+",
        "name_se": "5+",
        "name_el": "5+",
        "name_es": "5+",
        "name_hr": "5+",
        "name_fr": "5+"
    },
    "242^prematch": {
        "name_en": "6:8",
        "name_aa": "6:8",
        "name_de": "6:8",
        "name_nl": "6:8",
        "name_pl": "6:8",
        "name_ro": "6:8",
        "name_ru": "6:8",
        "name_tr": "6:8",
        "name_se": "6:8",
        "name_el": "6:8",
        "name_es": "6:8",
        "name_hr": "6:8",
        "name_fr": "6:8"
    },
    "1694710000^prematch": {
        "name_en": "No",
        "name_aa": "No",
        "name_de": "No",
        "name_nl": "No",
        "name_pl": "No",
        "name_ro": "No",
        "name_ru": "No",
        "name_tr": "No",
        "name_se": "No",
        "name_el": "No",
        "name_es": "No",
        "name_hr": "No",
        "name_fr": "No"
    },
    "1078^prematch": {
        "name_en": "190+",
        "name_aa": "190+",
        "name_de": "190+",
        "name_nl": "190+",
        "name_pl": "190+",
        "name_ro": "190+",
        "name_ru": "190+",
        "name_tr": "190+",
        "name_se": "190+",
        "name_el": "190+",
        "name_es": "190+",
        "name_hr": "190+",
        "name_fr": "190+"
    },
    "1406^prematch": {
        "name_en": "{$competitor1} & 4",
        "name_aa": "{$competitor1} & 4",
        "name_de": "{$competitor1} & 4",
        "name_nl": "{$competitor1} & 4",
        "name_pl": "{$competitor1} & 4",
        "name_ro": "{$competitor1} & 4",
        "name_ru": "{$competitor1} & 4",
        "name_tr": "{$competitor1} & 4",
        "name_se": "{$competitor1} & 4",
        "name_el": "{$competitor1} & 4",
        "name_es": "{$competitor1} & 4",
        "name_hr": "{$competitor1} & 4",
        "name_fr": "{$competitor1} & 4"
    },
    "151^prematch": {
        "name_en": "28-34",
        "name_aa": "28-34",
        "name_de": "28-34",
        "name_nl": "28-34",
        "name_pl": "28-34",
        "name_ro": "28-34",
        "name_ru": "28-34",
        "name_tr": "28-34",
        "name_se": "28-34",
        "name_el": "28-34",
        "name_es": "28-34",
        "name_hr": "28-34",
        "name_fr": "28-34"
    },
    "1417^prematch": {
        "name_en": "{$competitor2} & 1",
        "name_aa": "{$competitor2} & 1",
        "name_de": "{$competitor2} & 1",
        "name_nl": "{$competitor2} & 1",
        "name_pl": "{$competitor2} & 1",
        "name_ro": "{$competitor2} & 1",
        "name_ru": "{$competitor2} & 1",
        "name_tr": "{$competitor2} & 1",
        "name_se": "{$competitor2} & 1",
        "name_el": "{$competitor2} & 1",
        "name_es": "{$competitor2} & 1",
        "name_hr": "{$competitor2} & 1",
        "name_fr": "{$competitor2} & 1"
    },
    "1091^prematch": {
        "name_en": "41-45",
        "name_aa": "41-45",
        "name_de": "41-45",
        "name_nl": "41-45",
        "name_pl": "41-45",
        "name_ro": "41-45",
        "name_ru": "41-45",
        "name_tr": "41-45",
        "name_se": "41-45",
        "name_el": "41-45",
        "name_es": "41-45",
        "name_hr": "41-45",
        "name_fr": "41-45"
    },
    "233^prematch": {
        "name_en": "8:0",
        "name_aa": "8:0",
        "name_de": "8:0",
        "name_nl": "8:0",
        "name_pl": "8:0",
        "name_ro": "8:0",
        "name_ru": "8:0",
        "name_tr": "8:0",
        "name_se": "8:0",
        "name_el": "8:0",
        "name_es": "8:0",
        "name_hr": "8:0",
        "name_fr": "8:0"
    },
    "225^prematch": {
        "name_en": "7:6",
        "name_aa": "7:6",
        "name_de": "7:6",
        "name_nl": "7:6",
        "name_pl": "7:6",
        "name_ro": "7:6",
        "name_ru": "7:6",
        "name_tr": "7:6",
        "name_se": "7:6",
        "name_el": "7:6",
        "name_es": "7:6",
        "name_hr": "7:6",
        "name_fr": "7:6"
    },
    "400^prematch": {
        "name_en": "2:0 2:0",
        "name_aa": "2:0 2:0",
        "name_de": "2:0 2:0",
        "name_nl": "2:0 2:0",
        "name_pl": "2:0 2:0",
        "name_ro": "2:0 2:0",
        "name_ru": "2:0 2:0",
        "name_tr": "2:0 2:0",
        "name_se": "2:0 2:0",
        "name_el": "2:0 2:0",
        "name_es": "2:0 2:0",
        "name_hr": "2:0 2:0",
        "name_fr": "2:0 2:0"
    },
    "1390^prematch": {
        "name_en": "{$competitor2} & decision",
        "name_aa": "{$competitor2} & decision",
        "name_de": "{$competitor2} & decision",
        "name_nl": "{$competitor2} & decision",
        "name_pl": "{$competitor2} & decision",
        "name_ro": "{$competitor2} & decision",
        "name_ru": "{$competitor2} & decision",
        "name_tr": "{$competitor2} & decision",
        "name_se": "{$competitor2} & decision",
        "name_el": "{$competitor2} & decision",
        "name_es": "{$competitor2} & decision",
        "name_hr": "{$competitor2} & decision",
        "name_fr": "{$competitor2} & decision"
    },
    "60030000^prematch": {
        "name_en": "4+/6+",
        "name_aa": "4+/6+",
        "name_de": "4+/6+",
        "name_nl": "4+/6+",
        "name_pl": "4+/6+",
        "name_ro": "4+/6+",
        "name_ru": "4+/6+",
        "name_tr": "4+/6+",
        "name_se": "4+/6+",
        "name_el": "4+/6+",
        "name_es": "4+/6+",
        "name_hr": "4+/6+",
        "name_fr": "4+/6+"
    },
    "12^prematch": {
        "name_en": "over {total}",
        "name_aa": "over {total}",
        "name_de": "über {total}",
        "name_nl": "boven {total}",
        "name_pl": "over {total}",
        "name_ro": "over {total}",
        "name_ru": "over {total}",
        "name_tr": "{total} üstü",
        "name_se": "over {total}",
        "name_el": "over {total}",
        "name_es": "over {total}",
        "name_hr": "više od {total}",
        "name_fr": "Plus de {total}"
    },
    "634^prematch": {
        "name_en": "{$competitor1} by 3+",
        "name_aa": "{$competitor1} by 3+",
        "name_de": "{$competitor1} mit 3+",
        "name_nl": "{$competitor1} met 3+",
        "name_pl": "{$competitor1} by 3+",
        "name_ro": "{$competitor1} by 3+",
        "name_ru": "{$competitor1} by 3+",
        "name_tr": "{$competitor1} 3 ve üstü farkla",
        "name_se": "{$competitor1} by 3+",
        "name_el": "{$competitor1} by 3+",
        "name_es": "{$competitor1} by 3+",
        "name_hr": "{$competitor1} za 3 ili više",
        "name_fr": "{$competitor1} par 3 ou +"
    },
    "1365^prematch": {
        "name_en": "{$competitor1} & 4",
        "name_aa": "{$competitor1} & 4",
        "name_de": "{$competitor1} & 4",
        "name_nl": "{$competitor1} & 4",
        "name_pl": "{$competitor1} & 4",
        "name_ro": "{$competitor1} & 4",
        "name_ru": "{$competitor1} & 4",
        "name_tr": "{$competitor1} & 4",
        "name_se": "{$competitor1} & 4",
        "name_el": "{$competitor1} & 4",
        "name_es": "{$competitor1} & 4",
        "name_hr": "{$competitor1} & 4",
        "name_fr": "{$competitor1} & 4"
    },
    "152^prematch": {
        "name_en": "35-41",
        "name_aa": "35-41",
        "name_de": "35-41",
        "name_nl": "35-41",
        "name_pl": "35-41",
        "name_ro": "35-41",
        "name_ru": "35-41",
        "name_tr": "35-41",
        "name_se": "35-41",
        "name_el": "35-41",
        "name_es": "35-41",
        "name_hr": "35-41",
        "name_fr": "35-41"
    },
    "94^prematch": {
        "name_en": "over {total} & no",
        "name_aa": "over {total} & no",
        "name_de": "über {total} & nein",
        "name_nl": "boven {total} & nee",
        "name_pl": "over {total} & no",
        "name_ro": "over {total} & no",
        "name_ru": "over {total} & no",
        "name_tr": "{total} üstü & yok",
        "name_se": "over {total} & no",
        "name_el": "over {total} & no",
        "name_es": "over {total} & no",
        "name_hr": "više od {total} i ne",
        "name_fr": "plus de {total} & non"
    },
    "1248^prematch": {
        "name_en": "75-79",
        "name_aa": "75-79",
        "name_de": "75-79",
        "name_nl": "75-79",
        "name_pl": "75-79",
        "name_ro": "75-79",
        "name_ru": "75-79",
        "name_tr": "75-79",
        "name_se": "75-79",
        "name_el": "75-79",
        "name_es": "75-79",
        "name_hr": "75-79",
        "name_fr": "75-79"
    },
    "1508^prematch": {
        "name_en": "3:5",
        "name_aa": "3:5",
        "name_de": "3:5",
        "name_nl": "3:5",
        "name_pl": "3:5",
        "name_ro": "3:5",
        "name_ru": "3:5",
        "name_tr": "3:5",
        "name_se": "3:5",
        "name_el": "3:5",
        "name_es": "3:5",
        "name_hr": "3:5",
        "name_fr": "3:5"
    },
    "169^prematch": {
        "name_en": "161-170",
        "name_aa": "161-170",
        "name_de": "161-170",
        "name_nl": "161-170",
        "name_pl": "161-170",
        "name_ro": "161-170",
        "name_ru": "161-170",
        "name_tr": "161-170",
        "name_se": "161-170",
        "name_el": "161-170",
        "name_es": "161-170",
        "name_hr": "161-170",
        "name_fr": "161-170"
    },
    "1757^prematch": {
        "name_en": "2:3, 2:4, 3:4 or 1:5",
        "name_aa": "2:3, 2:4, 3:4 or 1:5",
        "name_de": "2:3, 2:4, 3:4 oder 1:5",
        "name_nl": "2:3, 2:4, 3:4 of 1:5",
        "name_pl": "2:3, 2:4, 3:4 or 1:5",
        "name_ro": "2:3, 2:4, 3:4 or 1:5",
        "name_ru": "2:3, 2:4, 3:4 or 1:5",
        "name_tr": "2:3, 2:4, 3:4 veya 1:5",
        "name_se": "2:3, 2:4, 3:4 or 1:5",
        "name_el": "2:3, 2:4, 3:4 or 1:5",
        "name_es": "2:3, 2:4, 3:4 or 1:5",
        "name_hr": "2:3, 2:4, 3:4 ili 1:5",
        "name_fr": "2:3, 2:4, 3:4 ou 1:5"
    },
    "74^prematch": {
        "name_en": "yes",
        "name_aa": "yes",
        "name_de": "ja",
        "name_nl": "ja",
        "name_pl": "yes",
        "name_ro": "yes",
        "name_ru": "yes",
        "name_tr": "var",
        "name_se": "yes",
        "name_el": "yes",
        "name_es": "yes",
        "name_hr": "da",
        "name_fr": "oui"
    },
    "33^prematch": {
        "name_en": "3",
        "name_aa": "3",
        "name_de": "3",
        "name_nl": "3",
        "name_pl": "3",
        "name_ro": "3",
        "name_ru": "3",
        "name_tr": "3",
        "name_se": "3",
        "name_el": "3",
        "name_es": "3",
        "name_hr": "3",
        "name_fr": "3"
    },
    "164^prematch": {
        "name_en": "161-170",
        "name_aa": "161-170",
        "name_de": "161-170",
        "name_nl": "161-170",
        "name_pl": "161-170",
        "name_ro": "161-170",
        "name_ru": "161-170",
        "name_tr": "161-170",
        "name_se": "161-170",
        "name_el": "161-170",
        "name_es": "161-170",
        "name_hr": "161-170",
        "name_fr": "161-170"
    },
    "70750000^prematch": {
        "name_en": "Draw",
        "name_aa": "Draw",
        "name_de": "Draw",
        "name_nl": "Draw",
        "name_pl": "Draw",
        "name_ro": "Draw",
        "name_ru": "Draw",
        "name_tr": "Draw",
        "name_se": "Draw",
        "name_el": "Draw",
        "name_es": "Draw",
        "name_hr": "Draw",
        "name_fr": "Draw"
    },
    "1422^prematch": {
        "name_en": "{$competitor2} & 6",
        "name_aa": "{$competitor2} & 6",
        "name_de": "{$competitor2} & 6",
        "name_nl": "{$competitor2} & 6",
        "name_pl": "{$competitor2} & 6",
        "name_ro": "{$competitor2} & 6",
        "name_ru": "{$competitor2} & 6",
        "name_tr": "{$competitor2} & 6",
        "name_se": "{$competitor2} & 6",
        "name_el": "{$competitor2} & 6",
        "name_es": "{$competitor2} & 6",
        "name_hr": "{$competitor2} & 6",
        "name_fr": "{$competitor2} & 6"
    },
    "1733^prematch": {
        "name_en": "1-5",
        "name_aa": "1-5",
        "name_de": "1-5",
        "name_nl": "1-5",
        "name_pl": "1-5",
        "name_ro": "1-5",
        "name_ru": "1-5",
        "name_tr": "1-5",
        "name_se": "1-5",
        "name_el": "1-5",
        "name_es": "1-5",
        "name_hr": "1-5",
        "name_fr": "1-5"
    },
    "301^prematch": {
        "name_en": "7:11",
        "name_aa": "7:11",
        "name_de": "7:11",
        "name_nl": "7:11",
        "name_pl": "7:11",
        "name_ro": "7:11",
        "name_ru": "7:11",
        "name_tr": "7:11",
        "name_se": "7:11",
        "name_el": "7:11",
        "name_es": "7:11",
        "name_hr": "7:11",
        "name_fr": "7:11"
    },
    "1073^prematch": {
        "name_en": "130-139",
        "name_aa": "130-139",
        "name_de": "130-139",
        "name_nl": "130-139",
        "name_pl": "130-139",
        "name_ro": "130-139",
        "name_ru": "130-139",
        "name_tr": "130-139",
        "name_se": "130-139",
        "name_el": "130-139",
        "name_es": "130-139",
        "name_hr": "130-139",
        "name_fr": "130-139"
    },
    "1408^prematch": {
        "name_en": "{$competitor1} & 6",
        "name_aa": "{$competitor1} & 6",
        "name_de": "{$competitor1} & 6",
        "name_nl": "{$competitor1} & 6",
        "name_pl": "{$competitor1} & 6",
        "name_ro": "{$competitor1} & 6",
        "name_ru": "{$competitor1} & 6",
        "name_tr": "{$competitor1} & 6",
        "name_se": "{$competitor1} & 6",
        "name_el": "{$competitor1} & 6",
        "name_es": "{$competitor1} & 6",
        "name_hr": "{$competitor1} & 6",
        "name_fr": "{$competitor1} & 6"
    },
    "229^prematch": {
        "name_en": "3:7",
        "name_aa": "3:7",
        "name_de": "3:7",
        "name_nl": "3:7",
        "name_pl": "3:7",
        "name_ro": "3:7",
        "name_ru": "3:7",
        "name_tr": "3:7",
        "name_se": "3:7",
        "name_el": "3:7",
        "name_es": "3:7",
        "name_hr": "3:7",
        "name_fr": "3:7"
    },
    "70760000^prematch": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "392^prematch": {
        "name_en": "1:1 2:1",
        "name_aa": "1:1 2:1",
        "name_de": "1:1 2:1",
        "name_nl": "1:1 2:1",
        "name_pl": "1:1 2:1",
        "name_ro": "1:1 2:1",
        "name_ru": "1:1 2:1",
        "name_tr": "1:1 2:1",
        "name_se": "1:1 2:1",
        "name_el": "1:1 2:1",
        "name_es": "1:1 2:1",
        "name_hr": "1:1 2:1",
        "name_fr": "1:1 2:1"
    },
    "1344^prematch": {
        "name_en": "4-6",
        "name_aa": "4-6",
        "name_de": "4-6",
        "name_nl": "4-6",
        "name_pl": "4-6",
        "name_ro": "4-6",
        "name_ru": "4-6",
        "name_tr": "4-6",
        "name_se": "4-6",
        "name_el": "4-6",
        "name_es": "4-6",
        "name_hr": "4-6",
        "name_fr": "4-6"
    },
    "1872^prematch": {
        "name_en": "{$competitor1}/{$competitor1} & 4",
        "name_aa": "{$competitor1}/{$competitor1} & 4",
        "name_de": "{$competitor1}/{$competitor1} & 4",
        "name_nl": "{$competitor1}/{$competitor1} & 4",
        "name_pl": "{$competitor1}/{$competitor1} & 4",
        "name_ro": "{$competitor1}/{$competitor1} & 4",
        "name_ru": "{$competitor1}/{$competitor1} & 4",
        "name_tr": "{$competitor1}/{$competitor1} & 4",
        "name_se": "{$competitor1}/{$competitor1} & 4",
        "name_el": "{$competitor1}/{$competitor1} & 4",
        "name_es": "{$competitor1}/{$competitor1} & 4",
        "name_hr": "{$competitor1}/{$competitor1} i 4",
        "name_fr": "{$competitor1}/{$competitor1} & 4"
    },
    "1^prematch": {
        "name_en": "{$competitor1}",
        "name_aa": "{$competitor1}",
        "name_de": "{$competitor1}",
        "name_nl": "{$competitor1}",
        "name_pl": "{$competitor1}",
        "name_ro": "{$competitor1}",
        "name_ru": "{$competitor1}",
        "name_tr": "{$competitor1}",
        "name_se": "{$competitor1}",
        "name_el": "{$competitor1}",
        "name_es": "{$competitor1}",
        "name_hr": "{$competitor1}",
        "name_fr": "{$competitor1}"
    },
    "246^prematch": {
        "name_en": "2:8",
        "name_aa": "2:8",
        "name_de": "2:8",
        "name_nl": "2:8",
        "name_pl": "2:8",
        "name_ro": "2:8",
        "name_ru": "2:8",
        "name_tr": "2:8",
        "name_se": "2:8",
        "name_el": "2:8",
        "name_es": "2:8",
        "name_hr": "2:8",
        "name_fr": "2:8"
    },
    "1495^prematch": {
        "name_en": "2:3",
        "name_aa": "2:3",
        "name_de": "2:3",
        "name_nl": "2:3",
        "name_pl": "2:3",
        "name_ro": "2:3",
        "name_ru": "2:3",
        "name_tr": "2:3",
        "name_se": "2:3",
        "name_el": "2:3",
        "name_es": "2:3",
        "name_hr": "2:3",
        "name_fr": "2:3"
    },
    "1412^prematch": {
        "name_en": "{$competitor1} & 10",
        "name_aa": "{$competitor1} & 10",
        "name_de": "{$competitor1} & 10",
        "name_nl": "{$competitor1} & 10",
        "name_pl": "{$competitor1} & 10",
        "name_ro": "{$competitor1} & 10",
        "name_ru": "{$competitor1} & 10",
        "name_tr": "{$competitor1} & 10",
        "name_se": "{$competitor1} & 10",
        "name_el": "{$competitor1} & 10",
        "name_es": "{$competitor1} & 10",
        "name_hr": "{$competitor1} & 10",
        "name_fr": "{$competitor1} & 10"
    },
    "278^prematch": {
        "name_en": "8:10",
        "name_aa": "8:10",
        "name_de": "8:10",
        "name_nl": "8:10",
        "name_pl": "8:10",
        "name_ro": "8:10",
        "name_ru": "8:10",
        "name_tr": "8:10",
        "name_se": "8:10",
        "name_el": "8:10",
        "name_es": "8:10",
        "name_hr": "8:10",
        "name_fr": "8:10"
    },
    "15^prematch": {
        "name_en": "4:1",
        "name_aa": "4:1",
        "name_de": "4:1",
        "name_nl": "4:1",
        "name_pl": "4:1",
        "name_ro": "4:1",
        "name_ru": "4:1",
        "name_tr": "4:1",
        "name_se": "4:1",
        "name_el": "4:1",
        "name_es": "4:1",
        "name_hr": "4:1",
        "name_fr": "4:1"
    },
    "372^prematch": {
        "name_en": "0:3 4+",
        "name_aa": "0:3 4+",
        "name_de": "0:3 4+",
        "name_nl": "0:3 4+",
        "name_pl": "0:3 4+",
        "name_ro": "0:3 4+",
        "name_ru": "0:3 4+",
        "name_tr": "0:3 4+",
        "name_se": "0:3 4+",
        "name_el": "0:3 4+",
        "name_es": "0:3 4+",
        "name_hr": "0:3 4+",
        "name_fr": "0:3 4+"
    },
    "273^prematch": {
        "name_en": "10:6",
        "name_aa": "10:6",
        "name_de": "10:6",
        "name_nl": "10:6",
        "name_pl": "10:6",
        "name_ro": "10:6",
        "name_ru": "10:6",
        "name_tr": "10:6",
        "name_se": "10:6",
        "name_el": "10:6",
        "name_es": "10:6",
        "name_hr": "10:6",
        "name_fr": "10:6"
    },
    "873^prematch": {
        "name_en": "1:6",
        "name_aa": "1:6",
        "name_de": "1:6",
        "name_nl": "1:6",
        "name_pl": "1:6",
        "name_ro": "1:6",
        "name_ru": "1:6",
        "name_tr": "1:6",
        "name_se": "1:6",
        "name_el": "1:6",
        "name_es": "1:6",
        "name_hr": "1:6",
        "name_fr": "1:6"
    },
    "1694180000^prematch": {
        "name_en": "Under14.5",
        "name_aa": "Under14.5",
        "name_de": "Under14.5",
        "name_nl": "Under14.5",
        "name_pl": "Under14.5",
        "name_ro": "Under14.5",
        "name_ru": "Under14.5",
        "name_tr": "Under14.5",
        "name_se": "Under14.5",
        "name_el": "Under14.5",
        "name_es": "Under14.5",
        "name_hr": "Under14.5",
        "name_fr": "Under14.5"
    },
    "237^prematch": {
        "name_en": "8:4",
        "name_aa": "8:4",
        "name_de": "8:4",
        "name_nl": "8:4",
        "name_pl": "8:4",
        "name_ro": "8:4",
        "name_ru": "8:4",
        "name_tr": "8:4",
        "name_se": "8:4",
        "name_el": "8:4",
        "name_es": "8:4",
        "name_hr": "8:4",
        "name_fr": "8:4"
    },
    "1868^prematch": {
        "name_en": "draw/{$competitor1} & 3",
        "name_aa": "draw/{$competitor1} & 3",
        "name_de": "unentschieden/{$competitor1} & 3",
        "name_nl": "gelijkspel/{$competitor1} & 3",
        "name_pl": "draw/{$competitor1} & 3",
        "name_ro": "draw/{$competitor1} & 3",
        "name_ru": "draw/{$competitor1} & 3",
        "name_tr": "beraberlik/{$competitor1} & 3",
        "name_se": "draw/{$competitor1} & 3",
        "name_el": "draw/{$competitor1} & 3",
        "name_es": "draw/{$competitor1} & 3",
        "name_hr": "neodlučeno/{$competitor1} i 3",
        "name_fr": "Match nul/{$competitor1} & 3"
    },
    "255^prematch": {
        "name_en": "9:6",
        "name_aa": "9:6",
        "name_de": "9:6",
        "name_nl": "9:6",
        "name_pl": "9:6",
        "name_ro": "9:6",
        "name_ru": "9:6",
        "name_tr": "9:6",
        "name_se": "9:6",
        "name_el": "9:6",
        "name_es": "9:6",
        "name_hr": "9:6",
        "name_fr": "9:6"
    },
    "1641^prematch": {
        "name_en": "{$competitor1} with penalty",
        "name_aa": "{$competitor1} with penalty",
        "name_de": "{$competitor1} mit Strafstoß",
        "name_nl": "{$competitor1} met penalty",
        "name_pl": "{$competitor1} with penalty",
        "name_ro": "{$competitor1} with penalty",
        "name_ru": "{$competitor1} with penalty",
        "name_tr": "{$competitor1} penaltı ile",
        "name_se": "{$competitor1} with penalty",
        "name_el": "{$competitor1} with penalty",
        "name_es": "{$competitor1} with penalty",
        "name_hr": "{$competitor1} penalom",
        "name_fr": "{$competitor1} avec une pénalité"
    },
    "877^prematch": {
        "name_en": "5:7",
        "name_aa": "5:7",
        "name_de": "5:7",
        "name_nl": "5:7",
        "name_pl": "5:7",
        "name_ro": "5:7",
        "name_ru": "5:7",
        "name_tr": "5:7",
        "name_se": "5:7",
        "name_el": "5:7",
        "name_es": "5:7",
        "name_hr": "5:7",
        "name_fr": "5:7"
    },
    "73^prematch": {
        "name_en": "5",
        "name_aa": "5",
        "name_de": "5",
        "name_nl": "5",
        "name_pl": "5",
        "name_ro": "5",
        "name_ru": "5",
        "name_tr": "5",
        "name_se": "5",
        "name_el": "5",
        "name_es": "5",
        "name_hr": "5",
        "name_fr": "5"
    },
    "598^prematch": {
        "name_en": "1-10",
        "name_aa": "1-10",
        "name_de": "1-10",
        "name_nl": "1-10",
        "name_pl": "1-10",
        "name_ro": "1-10",
        "name_ru": "1-10",
        "name_tr": "1-10",
        "name_se": "1-10",
        "name_el": "1-10",
        "name_es": "1-10",
        "name_hr": "1-10",
        "name_fr": "1-10"
    },
    "60100000^prematch": {
        "name_en": "6+/5+",
        "name_aa": "6+/5+",
        "name_de": "6+/5+",
        "name_nl": "6+/5+",
        "name_pl": "6+/5+",
        "name_ro": "6+/5+",
        "name_ru": "6+/5+",
        "name_tr": "6+/5+",
        "name_se": "6+/5+",
        "name_el": "6+/5+",
        "name_es": "6+/5+",
        "name_hr": "6+/5+",
        "name_fr": "6+/5+"
    },
    "1858^prematch": {
        "name_en": "{$competitor2}/{$competitor2} & 1",
        "name_aa": "{$competitor2}/{$competitor2} & 1",
        "name_de": "{$competitor2}/{$competitor2} & 1",
        "name_nl": "{$competitor2}/{$competitor2} & 1",
        "name_pl": "{$competitor2}/{$competitor2} & 1",
        "name_ro": "{$competitor2}/{$competitor2} & 1",
        "name_ru": "{$competitor2}/{$competitor2} & 1",
        "name_tr": "{$competitor2}/{$competitor2} & 1",
        "name_se": "{$competitor2}/{$competitor2} & 1",
        "name_el": "{$competitor2}/{$competitor2} & 1",
        "name_es": "{$competitor2}/{$competitor2} & 1",
        "name_hr": "{$competitor2}/{$competitor2} i 1",
        "name_fr": "{$competitor2}/{$competitor2} & 1"
    },
    "1694070000^prematch": {
        "name_en": "Over 9.5",
        "name_aa": "Over 9.5",
        "name_de": "Over 9.5",
        "name_nl": "Over 9.5",
        "name_pl": "Over 9.5",
        "name_ro": "Over 9.5",
        "name_ru": "Over 9.5",
        "name_tr": "Over 9.5",
        "name_se": "Over 9.5",
        "name_el": "Over 9.5",
        "name_es": "Over 9.5",
        "name_hr": "Over 9.5",
        "name_fr": "Over 9.5"
    },
    "1644^prematch": {
        "name_en": "{$competitor2} with penalty",
        "name_aa": "{$competitor2} with penalty",
        "name_de": "{$competitor2} mit Strafstoß",
        "name_nl": "{$competitor2} met penalty",
        "name_pl": "{$competitor2} with penalty",
        "name_ro": "{$competitor2} with penalty",
        "name_ru": "{$competitor2} with penalty",
        "name_tr": "{$competitor2} penaltı ile",
        "name_se": "{$competitor2} with penalty",
        "name_el": "{$competitor2} with penalty",
        "name_es": "{$competitor2} with penalty",
        "name_hr": "{$competitor2} penalom",
        "name_fr": "{$competitor2} avec une pénalité"
    },
    "296^prematch": {
        "name_en": "11:9",
        "name_aa": "11:9",
        "name_de": "11:9",
        "name_nl": "11:9",
        "name_pl": "11:9",
        "name_ro": "11:9",
        "name_ru": "11:9",
        "name_tr": "11:9",
        "name_se": "11:9",
        "name_el": "11:9",
        "name_es": "11:9",
        "name_hr": "11:9",
        "name_fr": "11:9"
    },
    "1370^prematch": {
        "name_en": "{$competitor2} & 2",
        "name_aa": "{$competitor2} & 2",
        "name_de": "{$competitor2} & 2",
        "name_nl": "{$competitor2} & 2",
        "name_pl": "{$competitor2} & 2",
        "name_ro": "{$competitor2} & 2",
        "name_ru": "{$competitor2} & 2",
        "name_tr": "{$competitor2} & 2",
        "name_se": "{$competitor2} & 2",
        "name_el": "{$competitor2} & 2",
        "name_es": "{$competitor2} & 2",
        "name_hr": "{$competitor2} & 2",
        "name_fr": "{$competitor2} & 2"
    },
    "21^prematch": {
        "name_en": "0:4",
        "name_aa": "0:4",
        "name_de": "0:4",
        "name_nl": "0:4",
        "name_pl": "0:4",
        "name_ro": "0:4",
        "name_ru": "0:4",
        "name_tr": "0:4",
        "name_se": "0:4",
        "name_el": "0:4",
        "name_es": "0:4",
        "name_hr": "0:4",
        "name_fr": "0:4"
    },
    "1353^prematch": {
        "name_en": "{$competitor1} & 1",
        "name_aa": "{$competitor1} & 1",
        "name_de": "{$competitor1} & 1",
        "name_nl": "{$competitor1} & 1",
        "name_pl": "{$competitor1} & 1",
        "name_ro": "{$competitor1} & 1",
        "name_ru": "{$competitor1} & 1",
        "name_tr": "{$competitor1} & 1",
        "name_se": "{$competitor1} & 1",
        "name_el": "{$competitor1} & 1",
        "name_es": "{$competitor1} & 1",
        "name_hr": "{$competitor1} & 1",
        "name_fr": "{$competitor1} & 1"
    },
    "1288^prematch": {
        "name_en": "{$competitor1} by 26+",
        "name_aa": "{$competitor1} by 26+",
        "name_de": "{$competitor1} by 26+",
        "name_nl": "{$competitor1} by 26+",
        "name_pl": "{$competitor1} by 26+",
        "name_ro": "{$competitor1} by 26+",
        "name_ru": "{$competitor1} by 26+",
        "name_tr": "{$competitor1} by 26+",
        "name_se": "{$competitor1} by 26+",
        "name_el": "{$competitor1} by 26+",
        "name_es": "{$competitor1} by 26+",
        "name_hr": "{$competitor1} by 26+",
        "name_fr": "{$competitor1} by 26+"
    },
    "1694100000^prematch": {
        "name_en": "Under 10.5",
        "name_aa": "Under 10.5",
        "name_de": "Under 10.5",
        "name_nl": "Under 10.5",
        "name_pl": "Under 10.5",
        "name_ro": "Under 10.5",
        "name_ru": "Under 10.5",
        "name_tr": "Under 10.5",
        "name_se": "Under 10.5",
        "name_el": "Under 10.5",
        "name_es": "Under 10.5",
        "name_hr": "Under 10.5",
        "name_fr": "Under 10.5"
    },
    "1196^prematch": {
        "name_en": "0:2",
        "name_aa": "0:2",
        "name_de": "0:2",
        "name_nl": "0:2",
        "name_pl": "0:2",
        "name_ro": "0:2",
        "name_ru": "0:2",
        "name_tr": "0:2",
        "name_se": "0:2",
        "name_el": "0:2",
        "name_es": "0:2",
        "name_hr": "0:2",
        "name_fr": "0:2"
    },
    "1499^prematch": {
        "name_en": "0:4",
        "name_aa": "0:4",
        "name_de": "0:4",
        "name_nl": "0:4",
        "name_pl": "0:4",
        "name_ro": "0:4",
        "name_ru": "0:4",
        "name_tr": "0:4",
        "name_se": "0:4",
        "name_el": "0:4",
        "name_es": "0:4",
        "name_hr": "0:4",
        "name_fr": "0:4"
    },
    "1175^prematch": {
        "name_en": "2:3",
        "name_aa": "2:3",
        "name_de": "2:3",
        "name_nl": "2:3",
        "name_pl": "2:3",
        "name_ro": "2:3",
        "name_ru": "2:3",
        "name_tr": "2:3",
        "name_se": "2:3",
        "name_el": "2:3",
        "name_es": "2:3",
        "name_hr": "2:3",
        "name_fr": "2:3"
    },
    "1359^prematch": {
        "name_en": "{$competitor2} & 2",
        "name_aa": "{$competitor2} & 2",
        "name_de": "{$competitor2} & 2",
        "name_nl": "{$competitor2} & 2",
        "name_pl": "{$competitor2} & 2",
        "name_ro": "{$competitor2} & 2",
        "name_ru": "{$competitor2} & 2",
        "name_tr": "{$competitor2} & 2",
        "name_se": "{$competitor2} & 2",
        "name_el": "{$competitor2} & 2",
        "name_es": "{$competitor2} & 2",
        "name_hr": "{$competitor2} & 2",
        "name_fr": "{$competitor2} & 2"
    },
    "1475^prematch": {
        "name_en": "0:0",
        "name_aa": "0:0",
        "name_de": "0:0",
        "name_nl": "0:0",
        "name_pl": "0:0",
        "name_ro": "0:0",
        "name_ru": "0:0",
        "name_tr": "0:0",
        "name_se": "0:0",
        "name_el": "0:0",
        "name_es": "0:0",
        "name_hr": "0:0",
        "name_fr": "0:0"
    },
    "716^prematch": {
        "name_en": "6",
        "name_aa": "6",
        "name_de": "6",
        "name_nl": "6",
        "name_pl": "6",
        "name_ro": "6",
        "name_ru": "6",
        "name_tr": "6",
        "name_se": "6",
        "name_el": "6",
        "name_es": "6",
        "name_hr": "6",
        "name_fr": "6"
    },
    "440^prematch": {
        "name_en": "equal",
        "name_aa": "equal",
        "name_de": "gleich",
        "name_nl": "gelijk",
        "name_pl": "equal",
        "name_ro": "equal",
        "name_ru": "equal",
        "name_tr": "eşit",
        "name_se": "equal",
        "name_el": "equal",
        "name_es": "equal",
        "name_hr": "jednako",
        "name_fr": "égal"
    },
    "1643^prematch": {
        "name_en": "{$competitor2} with try",
        "name_aa": "{$competitor2} with try",
        "name_de": "{$competitor2} mit Versuch",
        "name_nl": "{$competitor2} met try",
        "name_pl": "{$competitor2} with try",
        "name_ro": "{$competitor2} with try",
        "name_ru": "{$competitor2} with try",
        "name_tr": "{$competitor2} try ile",
        "name_se": "{$competitor2} with try",
        "name_el": "{$competitor2} with try",
        "name_es": "{$competitor2} with try",
        "name_hr": "{$competitor2} polaganjem",
        "name_fr": "{$competitor2} avec un essai"
    },
    "1754^prematch": {
        "name_en": "2:1, 3:1 or 4:1",
        "name_aa": "2:1, 3:1 or 4:1",
        "name_de": "2:1, 3:1 oder 4:1",
        "name_nl": "2:1, 3:1 of 4:1",
        "name_pl": "2:1, 3:1 or 4:1",
        "name_ro": "2:1, 3:1 or 4:1",
        "name_ru": "2:1, 3:1 or 4:1",
        "name_tr": "2:1, 3:1 veya 4:1",
        "name_se": "2:1, 3:1 or 4:1",
        "name_el": "2:1, 3:1 or 4:1",
        "name_es": "2:1, 3:1 or 4:1",
        "name_hr": "2:1, 3:1 ili 4:1",
        "name_fr": "2:1, 3:1 ou 4:1"
    },
    "470^prematch": {
        "name_en": "2:0",
        "name_aa": "2:0",
        "name_de": "2:0",
        "name_nl": "2:0",
        "name_pl": "2:0",
        "name_ro": "2:0",
        "name_ru": "2:0",
        "name_tr": "2:0",
        "name_se": "2:0",
        "name_el": "2:0",
        "name_es": "2:0",
        "name_hr": "2:0",
        "name_fr": "2:0"
    },
    "286^prematch": {
        "name_en": "0:10",
        "name_aa": "0:10",
        "name_de": "0:10",
        "name_nl": "0:10",
        "name_pl": "0:10",
        "name_ro": "0:10",
        "name_ru": "0:10",
        "name_tr": "0:10",
        "name_se": "0:10",
        "name_el": "0:10",
        "name_es": "0:10",
        "name_hr": "0:10",
        "name_fr": "0:10"
    },
    "1171^prematch": {
        "name_en": "1:6",
        "name_aa": "1:6",
        "name_de": "1:6",
        "name_nl": "1:6",
        "name_pl": "1:6",
        "name_ro": "1:6",
        "name_ru": "1:6",
        "name_tr": "1:6",
        "name_se": "1:6",
        "name_el": "1:6",
        "name_es": "1:6",
        "name_hr": "1:6",
        "name_fr": "1:6"
    },
    "1756^prematch": {
        "name_en": "3:2, 4:2, 4:3 or 5:1",
        "name_aa": "3:2, 4:2, 4:3 or 5:1",
        "name_de": "3:2, 4:2, 4:3 oder 5:1",
        "name_nl": "3:2, 4:2, 4:3 of 5:1",
        "name_pl": "3:2, 4:2, 4:3 or 5:1",
        "name_ro": "3:2, 4:2, 4:3 or 5:1",
        "name_ru": "3:2, 4:2, 4:3 or 5:1",
        "name_tr": "3:2, 4:2, 4:3 veya 5:1",
        "name_se": "3:2, 4:2, 4:3 or 5:1",
        "name_el": "3:2, 4:2, 4:3 or 5:1",
        "name_es": "3:2, 4:2, 4:3 or 5:1",
        "name_hr": "3:2, 4:2, 4:3 ili 5:1",
        "name_fr": "3:2, 4:2, 4:3 ou 5:1"
    },
    "60110000^prematch": {
        "name_en": "6+/6+",
        "name_aa": "6+/6+",
        "name_de": "6+/6+",
        "name_nl": "6+/6+",
        "name_pl": "6+/6+",
        "name_ro": "6+/6+",
        "name_ru": "6+/6+",
        "name_tr": "6+/6+",
        "name_se": "6+/6+",
        "name_el": "6+/6+",
        "name_es": "6+/6+",
        "name_hr": "6+/6+",
        "name_fr": "6+/6+"
    },
    "1186^prematch": {
        "name_en": "4:3",
        "name_aa": "4:3",
        "name_de": "4:3",
        "name_nl": "4:3",
        "name_pl": "4:3",
        "name_ro": "4:3",
        "name_ru": "4:3",
        "name_tr": "4:3",
        "name_se": "4:3",
        "name_el": "4:3",
        "name_es": "4:3",
        "name_hr": "4:3",
        "name_fr": "4:3"
    },
    "18^prematch": {
        "name_en": "3:4",
        "name_aa": "3:4",
        "name_de": "3:4",
        "name_nl": "3:4",
        "name_pl": "3:4",
        "name_ro": "3:4",
        "name_ru": "3:4",
        "name_tr": "3:4",
        "name_se": "3:4",
        "name_el": "3:4",
        "name_es": "3:4",
        "name_hr": "3:4",
        "name_fr": "3:4"
    },
    "1136^prematch": {
        "name_en": "76+",
        "name_aa": "76+",
        "name_de": "76+",
        "name_nl": "76+",
        "name_pl": "76+",
        "name_ro": "76+",
        "name_ru": "76+",
        "name_tr": "76+",
        "name_se": "76+",
        "name_el": "76+",
        "name_es": "76+",
        "name_hr": "76+",
        "name_fr": "76+"
    },
    "1694420000^prematch": {
        "name_en": "Neither",
        "name_aa": "Neither",
        "name_de": "Neither",
        "name_nl": "Neither",
        "name_pl": "Neither",
        "name_ro": "Neither",
        "name_ru": "Neither",
        "name_tr": "Neither",
        "name_se": "Neither",
        "name_el": "Neither",
        "name_es": "Neither",
        "name_hr": "Neither",
        "name_fr": "Neither"
    },
    "155^prematch": {
        "name_en": "56-62",
        "name_aa": "56-62",
        "name_de": "56-62",
        "name_nl": "56-62",
        "name_pl": "56-62",
        "name_ro": "56-62",
        "name_ru": "56-62",
        "name_tr": "56-62",
        "name_se": "56-62",
        "name_el": "56-62",
        "name_es": "56-62",
        "name_hr": "56-62",
        "name_fr": "56-62"
    },
    "354^prematch": {
        "name_en": "0:1 1:1",
        "name_aa": "0:1 1:1",
        "name_de": "0:1 1:1",
        "name_nl": "0:1 1:1",
        "name_pl": "0:1 1:1",
        "name_ro": "0:1 1:1",
        "name_ru": "0:1 1:1",
        "name_tr": "0:1 1:1",
        "name_se": "0:1 1:1",
        "name_el": "0:1 1:1",
        "name_es": "0:1 1:1",
        "name_hr": "0:1 1:1",
        "name_fr": "0:1 1:1"
    },
    "362^prematch": {
        "name_en": "0:2 0:2",
        "name_aa": "0:2 0:2",
        "name_de": "0:2 0:2",
        "name_nl": "0:2 0:2",
        "name_pl": "0:2 0:2",
        "name_ro": "0:2 0:2",
        "name_ru": "0:2 0:2",
        "name_tr": "0:2 0:2",
        "name_se": "0:2 0:2",
        "name_el": "0:2 0:2",
        "name_es": "0:2 0:2",
        "name_hr": "0:2 0:2",
        "name_fr": "0:2 0:2"
    },
    "1169^prematch": {
        "name_en": "1:4",
        "name_aa": "1:4",
        "name_de": "1:4",
        "name_nl": "1:4",
        "name_pl": "1:4",
        "name_ro": "1:4",
        "name_ru": "1:4",
        "name_tr": "1:4",
        "name_se": "1:4",
        "name_el": "1:4",
        "name_es": "1:4",
        "name_hr": "1:4",
        "name_fr": "1:4"
    },
    "1363^prematch": {
        "name_en": "{$competitor1} & 2",
        "name_aa": "{$competitor1} & 2",
        "name_de": "{$competitor1} & 2",
        "name_nl": "{$competitor1} & 2",
        "name_pl": "{$competitor1} & 2",
        "name_ro": "{$competitor1} & 2",
        "name_ru": "{$competitor1} & 2",
        "name_tr": "{$competitor1} & 2",
        "name_se": "{$competitor1} & 2",
        "name_el": "{$competitor1} & 2",
        "name_es": "{$competitor1} & 2",
        "name_hr": "{$competitor1} & 2",
        "name_fr": "{$competitor1} & 2"
    },
    "480^prematch": {
        "name_en": "other",
        "name_aa": "other",
        "name_de": "andere",
        "name_nl": "ander",
        "name_pl": "other",
        "name_ro": "other",
        "name_ru": "other",
        "name_tr": "diğer",
        "name_se": "other",
        "name_el": "other",
        "name_es": "other",
        "name_hr": "ostalo",
        "name_fr": "autre"
    },
    "1694090000^prematch": {
        "name_en": "Over 10.5",
        "name_aa": "Over 10.5",
        "name_de": "Over 10.5",
        "name_nl": "Over 10.5",
        "name_pl": "Over 10.5",
        "name_ro": "Over 10.5",
        "name_ru": "Over 10.5",
        "name_tr": "Over 10.5",
        "name_se": "Over 10.5",
        "name_el": "Over 10.5",
        "name_es": "Over 10.5",
        "name_hr": "Over 10.5",
        "name_fr": "Over 10.5"
    },
    "414^prematch": {
        "name_en": "3:0 4+",
        "name_aa": "3:0 4+",
        "name_de": "3:0 4+",
        "name_nl": "3:0 4+",
        "name_pl": "3:0 4+",
        "name_ro": "3:0 4+",
        "name_ru": "3:0 4+",
        "name_tr": "3:0 4+",
        "name_se": "3:0 4+",
        "name_el": "3:0 4+",
        "name_es": "3:0 4+",
        "name_hr": "3:0 4+",
        "name_fr": "3:0 4+"
    },
    "36^prematch": {
        "name_en": "5",
        "name_aa": "5",
        "name_de": "5",
        "name_nl": "5",
        "name_pl": "5",
        "name_ro": "5",
        "name_ru": "5",
        "name_tr": "5",
        "name_se": "5",
        "name_el": "5",
        "name_es": "5",
        "name_hr": "5",
        "name_fr": "5"
    },
    "1145^prematch": {
        "name_en": "3-4",
        "name_aa": "3-4",
        "name_de": "3-4",
        "name_nl": "3-4",
        "name_pl": "3-4",
        "name_ro": "3-4",
        "name_ru": "3-4",
        "name_tr": "3-4",
        "name_se": "3-4",
        "name_el": "3-4",
        "name_es": "3-4",
        "name_hr": "3-4",
        "name_fr": "3-4"
    },
    "1094^prematch": {
        "name_en": "7-13",
        "name_aa": "7-13",
        "name_de": "7-13",
        "name_nl": "7-13",
        "name_pl": "7-13",
        "name_ro": "7-13",
        "name_ru": "7-13",
        "name_tr": "7-13",
        "name_se": "7-13",
        "name_el": "7-13",
        "name_es": "7-13",
        "name_hr": "7-13",
        "name_fr": "7-13"
    },
    "396^prematch": {
        "name_en": "1:2 1:2",
        "name_aa": "1:2 1:2",
        "name_de": "1:2 1:2",
        "name_nl": "1:2 1:2",
        "name_pl": "1:2 1:2",
        "name_ro": "1:2 1:2",
        "name_ru": "1:2 1:2",
        "name_tr": "1:2 1:2",
        "name_se": "1:2 1:2",
        "name_el": "1:2 1:2",
        "name_es": "1:2 1:2",
        "name_hr": "1:2 1:2",
        "name_fr": "1:2 1:2"
    },
    "110^prematch": {
        "name_en": "0:0",
        "name_aa": "0:0",
        "name_de": "0:0",
        "name_nl": "0:0",
        "name_pl": "0:0",
        "name_ro": "0:0",
        "name_ru": "0:0",
        "name_tr": "0:0",
        "name_se": "0:0",
        "name_el": "0:0",
        "name_es": "0:0",
        "name_hr": "0:0",
        "name_fr": "0:0"
    },
    "1715^prematch": {
        "name_en": "{$competitor2} ({-hcp})",
        "name_aa": "{$competitor2} ({-hcp})",
        "name_de": "{$competitor2} ({-hcp})",
        "name_nl": "{$competitor2} ({hcp})",
        "name_pl": "{$competitor2} ({-hcp})",
        "name_ro": "{$competitor2} ({-hcp})",
        "name_ru": "{$competitor2} ({-hcp})",
        "name_tr": "{$competitor2} ({-hcp})",
        "name_se": "{$competitor2} ({-hcp})",
        "name_el": "{$competitor2} ({-hcp})",
        "name_es": "{$competitor2} ({-hcp})",
        "name_hr": "{$competitor2} ({-hcp})",
        "name_fr": "{$competitor2} ({-hcp})"
    },
    "82^prematch": {
        "name_en": "draw & yes",
        "name_aa": "draw & yes",
        "name_de": "unentschieden & ja",
        "name_nl": "gelijkspel & ja",
        "name_pl": "draw & yes",
        "name_ro": "draw & yes",
        "name_ru": "draw & yes",
        "name_tr": "beraberlik & var",
        "name_se": "draw & yes",
        "name_el": "draw & yes",
        "name_es": "draw & yes",
        "name_hr": "neodlučeno i da",
        "name_fr": "Match nul & oui"
    },
    "71^prematch": {
        "name_en": "3",
        "name_aa": "3",
        "name_de": "3",
        "name_nl": "3",
        "name_pl": "3",
        "name_ro": "3",
        "name_ru": "3",
        "name_tr": "3",
        "name_se": "3",
        "name_el": "3",
        "name_es": "3",
        "name_hr": "3",
        "name_fr": "3"
    },
    "616^prematch": {
        "name_en": "none",
        "name_aa": "none",
        "name_de": "garnicht",
        "name_nl": "geen",
        "name_pl": "none",
        "name_ro": "none",
        "name_ru": "none",
        "name_tr": "Gol olmaz",
        "name_se": "none",
        "name_el": "none",
        "name_es": "none",
        "name_hr": "nikad",
        "name_fr": "aucun"
    },
    "956^prematch": {
        "name_en": "6th inning",
        "name_aa": "6th inning",
        "name_de": "6. Inning",
        "name_nl": "6e inning",
        "name_pl": "6th inning",
        "name_ro": "6th inning",
        "name_ru": "6th inning",
        "name_tr": "6. devre",
        "name_se": "6th inning",
        "name_el": "6th inning",
        "name_es": "6th inning",
        "name_hr": "6. inning",
        "name_fr": "6ème manche"
    },
    "402^prematch": {
        "name_en": "2:0 2:1",
        "name_aa": "2:0 2:1",
        "name_de": "2:0 2:1",
        "name_nl": "2:0 2:1",
        "name_pl": "2:0 2:1",
        "name_ro": "2:0 2:1",
        "name_ru": "2:0 2:1",
        "name_tr": "2:0 2:1",
        "name_se": "2:0 2:1",
        "name_el": "2:0 2:1",
        "name_es": "2:0 2:1",
        "name_hr": "2:0 2:1",
        "name_fr": "2:0 2:1"
    },
    "632^prematch": {
        "name_en": "{$competitor1} by 2",
        "name_aa": "{$competitor1} by 2",
        "name_de": "{$competitor1} mit 2",
        "name_nl": "{$competitor1} met 2",
        "name_pl": "{$competitor1} by 2",
        "name_ro": "{$competitor1} by 2",
        "name_ru": "{$competitor1} by 2",
        "name_tr": "{$competitor1} 2 farkla",
        "name_se": "{$competitor1} by 2",
        "name_el": "{$competitor1} by 2",
        "name_es": "{$competitor1} by 2",
        "name_hr": "{$competitor1} za 2",
        "name_fr": "{$competitor1} par 2"
    },
    "291^prematch": {
        "name_en": "11:4",
        "name_aa": "11:4",
        "name_de": "11:4",
        "name_nl": "11:4",
        "name_pl": "11:4",
        "name_ro": "11:4",
        "name_ru": "11:4",
        "name_tr": "11:4",
        "name_se": "11:4",
        "name_el": "11:4",
        "name_es": "11:4",
        "name_hr": "11:4",
        "name_fr": "11:4"
    },
    "1082^prematch": {
        "name_en": "19-22",
        "name_aa": "19-22",
        "name_de": "19-22",
        "name_nl": "19-22",
        "name_pl": "19-22",
        "name_ro": "19-22",
        "name_ru": "19-22",
        "name_tr": "19-22",
        "name_se": "19-22",
        "name_el": "19-22",
        "name_es": "19-22",
        "name_hr": "19-22",
        "name_fr": "19-22"
    },
    "133^prematch": {
        "name_en": "draw",
        "name_aa": "draw",
        "name_de": "draw",
        "name_nl": "draw",
        "name_pl": "draw",
        "name_ro": "draw",
        "name_ru": "draw",
        "name_tr": "draw",
        "name_se": "draw",
        "name_el": "draw",
        "name_es": "draw",
        "name_hr": "draw",
        "name_fr": "draw"
    },
    "1137^prematch": {
        "name_en": "0-10",
        "name_aa": "0-10",
        "name_de": "0-10",
        "name_nl": "0-10",
        "name_pl": "0-10",
        "name_ro": "0-10",
        "name_ru": "0-10",
        "name_tr": "0-10",
        "name_se": "0-10",
        "name_el": "0-10",
        "name_es": "0-10",
        "name_hr": "0-10",
        "name_fr": "0-10"
    },
    "1429^prematch": {
        "name_en": "{$competitor2} & decision",
        "name_aa": "{$competitor2} & decision",
        "name_de": "{$competitor2} & decision",
        "name_nl": "{$competitor2} & decision",
        "name_pl": "{$competitor2} & decision",
        "name_ro": "{$competitor2} & decision",
        "name_ru": "{$competitor2} & decision",
        "name_tr": "{$competitor2} & decision",
        "name_se": "{$competitor2} & decision",
        "name_el": "{$competitor2} & decision",
        "name_es": "{$competitor2} & decision",
        "name_hr": "{$competitor2} & decision",
        "name_fr": "{$competitor2} & decision"
    },
    "1505^prematch": {
        "name_en": "0:5",
        "name_aa": "0:5",
        "name_de": "0:5",
        "name_nl": "0:5",
        "name_pl": "0:5",
        "name_ro": "0:5",
        "name_ru": "0:5",
        "name_tr": "0:5",
        "name_se": "0:5",
        "name_el": "0:5",
        "name_es": "0:5",
        "name_hr": "0:5",
        "name_fr": "0:5"
    },
    "1076^prematch": {
        "name_en": "160-169",
        "name_aa": "160-169",
        "name_de": "160-169",
        "name_nl": "160-169",
        "name_pl": "160-169",
        "name_ro": "160-169",
        "name_ru": "160-169",
        "name_tr": "160-169",
        "name_se": "160-169",
        "name_el": "160-169",
        "name_es": "160-169",
        "name_hr": "160-169",
        "name_fr": "160-169"
    },
    "130^prematch": {
        "name_en": "{$competitor1} by 15+",
        "name_aa": "{$competitor1} by 15+",
        "name_de": "{$competitor1} by 15+",
        "name_nl": "{$competitor1} by 15+",
        "name_pl": "{$competitor1} by 15+",
        "name_ro": "{$competitor1} by 15+",
        "name_ru": "{$competitor1} by 15+",
        "name_tr": "{$competitor1} by 15+",
        "name_se": "{$competitor1} by 15+",
        "name_el": "{$competitor1} by 15+",
        "name_es": "{$competitor1} by 15+",
        "name_hr": "{$competitor1} by 15+",
        "name_fr": "{$competitor1} by 15+"
    },
    "275^prematch": {
        "name_en": "10:8",
        "name_aa": "10:8",
        "name_de": "10:8",
        "name_nl": "10:8",
        "name_pl": "10:8",
        "name_ro": "10:8",
        "name_ru": "10:8",
        "name_tr": "10:8",
        "name_se": "10:8",
        "name_el": "10:8",
        "name_es": "10:8",
        "name_hr": "10:8",
        "name_fr": "10:8"
    },
    "462^prematch": {
        "name_en": "0:0",
        "name_aa": "0:0",
        "name_de": "0:0",
        "name_nl": "0:0",
        "name_pl": "0:0",
        "name_ro": "0:0",
        "name_ru": "0:0",
        "name_tr": "0:0",
        "name_se": "0:0",
        "name_el": "0:0",
        "name_es": "0:0",
        "name_hr": "0:0",
        "name_fr": "0:0"
    },
    "1132^prematch": {
        "name_en": "0-30",
        "name_aa": "0-30",
        "name_de": "0-30",
        "name_nl": "0-30",
        "name_pl": "0-30",
        "name_ro": "0-30",
        "name_ru": "0-30",
        "name_tr": "0-30",
        "name_se": "0-30",
        "name_el": "0-30",
        "name_es": "0-30",
        "name_hr": "0-30",
        "name_fr": "0-30"
    },
    "710^prematch": {
        "name_en": "0-3",
        "name_aa": "0-3",
        "name_de": "0-3",
        "name_nl": "0-3",
        "name_pl": "0-3",
        "name_ro": "0-3",
        "name_ru": "0-3",
        "name_tr": "0-3",
        "name_se": "0-3",
        "name_el": "0-3",
        "name_es": "0-3",
        "name_hr": "0-3",
        "name_fr": "0-3"
    },
    "206^prematch": {
        "name_en": "7:0",
        "name_aa": "7:0",
        "name_de": "7:0",
        "name_nl": "7:0",
        "name_pl": "7:0",
        "name_ro": "7:0",
        "name_ru": "7:0",
        "name_tr": "7:0",
        "name_se": "7:0",
        "name_el": "7:0",
        "name_es": "7:0",
        "name_hr": "7:0",
        "name_fr": "7:0"
    },
    "390^prematch": {
        "name_en": "1:1 1:2",
        "name_aa": "1:1 1:2",
        "name_de": "1:1 1:2",
        "name_nl": "1:1 1:2",
        "name_pl": "1:1 1:2",
        "name_ro": "1:1 1:2",
        "name_ru": "1:1 1:2",
        "name_tr": "1:1 1:2",
        "name_se": "1:1 1:2",
        "name_el": "1:1 1:2",
        "name_es": "1:1 1:2",
        "name_hr": "1:1 1:2",
        "name_fr": "1:1 1:2"
    },
    "636^prematch": {
        "name_en": "{$competitor2} by 1",
        "name_aa": "{$competitor2} by 1",
        "name_de": "{$competitor2} mit 1",
        "name_nl": "{$competitor2} met 1",
        "name_pl": "{$competitor2} by 1",
        "name_ro": "{$competitor2} by 1",
        "name_ru": "{$competitor2} by 1",
        "name_tr": "{$competitor2} 1 farkla",
        "name_se": "{$competitor2} by 1",
        "name_el": "{$competitor2} by 1",
        "name_es": "{$competitor2} by 1",
        "name_hr": "{$competitor2} za 1",
        "name_fr": "{$competitor2} par 1"
    },
    "1742^prematch": {
        "name_en": "4-5",
        "name_aa": "4-5",
        "name_de": "4-5",
        "name_nl": "4-5",
        "name_pl": "4-5",
        "name_ro": "4-5",
        "name_ru": "4-5",
        "name_tr": "4-5",
        "name_se": "4-5",
        "name_el": "4-5",
        "name_es": "4-5",
        "name_hr": "4-5",
        "name_fr": "4-5"
    },
    "1368^prematch": {
        "name_en": "draw",
        "name_aa": "draw",
        "name_de": "draw",
        "name_nl": "draw",
        "name_pl": "draw",
        "name_ro": "draw",
        "name_ru": "draw",
        "name_tr": "draw",
        "name_se": "draw",
        "name_el": "draw",
        "name_es": "draw",
        "name_hr": "draw",
        "name_fr": "draw"
    },
    "1189^prematch": {
        "name_en": "5:2",
        "name_aa": "5:2",
        "name_de": "5:2",
        "name_nl": "5:2",
        "name_pl": "5:2",
        "name_ro": "5:2",
        "name_ru": "5:2",
        "name_tr": "5:2",
        "name_se": "5:2",
        "name_el": "5:2",
        "name_es": "5:2",
        "name_hr": "5:2",
        "name_fr": "5:2"
    },
    "1694640000^prematch": {
        "name_en": "4+/4+",
        "name_aa": "4+/4+",
        "name_de": "4+/4+",
        "name_nl": "4+/4+",
        "name_pl": "4+/4+",
        "name_ro": "4+/4+",
        "name_ru": "4+/4+",
        "name_tr": "4+/4+",
        "name_se": "4+/4+",
        "name_el": "4+/4+",
        "name_es": "4+/4+",
        "name_hr": "4+/4+",
        "name_fr": "4+/4+"
    },
    "192^prematch": {
        "name_en": "7:0",
        "name_aa": "7:0",
        "name_de": "7:0",
        "name_nl": "7:0",
        "name_pl": "7:0",
        "name_ro": "7:0",
        "name_ru": "7:0",
        "name_tr": "7:0",
        "name_se": "7:0",
        "name_el": "7:0",
        "name_es": "7:0",
        "name_hr": "7:0",
        "name_fr": "7:0"
    },
    "1416^prematch": {
        "name_en": "draw",
        "name_aa": "draw",
        "name_de": "draw",
        "name_nl": "draw",
        "name_pl": "draw",
        "name_ro": "draw",
        "name_ru": "draw",
        "name_tr": "draw",
        "name_se": "draw",
        "name_el": "draw",
        "name_es": "draw",
        "name_hr": "draw",
        "name_fr": "draw"
    },
    "1501^prematch": {
        "name_en": "2:4",
        "name_aa": "2:4",
        "name_de": "2:4",
        "name_nl": "2:4",
        "name_pl": "2:4",
        "name_ro": "2:4",
        "name_ru": "2:4",
        "name_tr": "2:4",
        "name_se": "2:4",
        "name_el": "2:4",
        "name_es": "2:4",
        "name_hr": "2:4",
        "name_fr": "2:4"
    },
    "83550000^prematch": {
        "name_en": "Yes",
        "name_aa": "Yes",
        "name_de": "Yes",
        "name_nl": "Yes",
        "name_pl": "Yes",
        "name_ro": "Yes",
        "name_ru": "Yes",
        "name_tr": "Yes",
        "name_se": "Yes",
        "name_el": "Yes",
        "name_es": "Yes",
        "name_hr": "Yes",
        "name_fr": "Yes"
    },
    "1876^prematch": {
        "name_en": "draw/draw & 4",
        "name_aa": "draw/draw & 4",
        "name_de": "unentschieden/unentschieden& 4",
        "name_nl": "gelijkspel/gelijkspel & 4",
        "name_pl": "draw/draw & 4",
        "name_ro": "draw/draw & 4",
        "name_ru": "draw/draw & 4",
        "name_tr": "beraberlik/beraberlik & 4",
        "name_se": "draw/draw & 4",
        "name_el": "draw/draw & 4",
        "name_es": "draw/draw & 4",
        "name_hr": "neodlučeno/neodlučeno i 4",
        "name_fr": "Match nul/Match nul & 4"
    },
    "1374^prematch": {
        "name_en": "{$competitor2} & decision",
        "name_aa": "{$competitor2} & decision",
        "name_de": "{$competitor2} & decision",
        "name_nl": "{$competitor2} & decision",
        "name_pl": "{$competitor2} & decision",
        "name_ro": "{$competitor2} & decision",
        "name_ru": "{$competitor2} & decision",
        "name_tr": "{$competitor2} & decision",
        "name_se": "{$competitor2} & decision",
        "name_el": "{$competitor2} & decision",
        "name_es": "{$competitor2} & decision",
        "name_hr": "{$competitor2} & decision",
        "name_fr": "{$competitor2} & decision"
    },
    "614^prematch": {
        "name_en": "81-90",
        "name_aa": "81-90",
        "name_de": "81-90",
        "name_nl": "81-90",
        "name_pl": "81-90",
        "name_ro": "81-90",
        "name_ru": "81-90",
        "name_tr": "81-90",
        "name_se": "81-90",
        "name_el": "81-90",
        "name_es": "81-90",
        "name_hr": "81-90",
        "name_fr": "81-90"
    },
    "187^prematch": {
        "name_en": "4:6",
        "name_aa": "4:6",
        "name_de": "4:6",
        "name_nl": "4:6",
        "name_pl": "4:6",
        "name_ro": "4:6",
        "name_ru": "4:6",
        "name_tr": "4:6",
        "name_se": "4:6",
        "name_el": "4:6",
        "name_es": "4:6",
        "name_hr": "4:6",
        "name_fr": "4:6"
    },
    "957^prematch": {
        "name_en": "7th inning",
        "name_aa": "7th inning",
        "name_de": "7. Inning",
        "name_nl": "7e inning",
        "name_pl": "7th inning",
        "name_ro": "7th inning",
        "name_ru": "7th inning",
        "name_tr": "7. devre",
        "name_se": "7th inning",
        "name_el": "7th inning",
        "name_es": "7th inning",
        "name_hr": "7. inning",
        "name_fr": "7ème manche"
    },
    "1504^prematch": {
        "name_en": "5:4",
        "name_aa": "5:4",
        "name_de": "5:4",
        "name_nl": "5:4",
        "name_pl": "5:4",
        "name_ro": "5:4",
        "name_ru": "5:4",
        "name_tr": "5:4",
        "name_se": "5:4",
        "name_el": "5:4",
        "name_es": "5:4",
        "name_hr": "5:4",
        "name_fr": "5:4"
    },
    "310^prematch": {
        "name_en": "3:3",
        "name_aa": "3:3",
        "name_de": "3:3",
        "name_nl": "3:3",
        "name_pl": "3:3",
        "name_ro": "3:3",
        "name_ru": "3:3",
        "name_tr": "3:3",
        "name_se": "3:3",
        "name_el": "3:3",
        "name_es": "3:3",
        "name_hr": "3:3",
        "name_fr": "3:3"
    },
    "69^prematch": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "1357^prematch": {
        "name_en": "draw",
        "name_aa": "draw",
        "name_de": "draw",
        "name_nl": "draw",
        "name_pl": "draw",
        "name_ro": "draw",
        "name_ru": "draw",
        "name_tr": "draw",
        "name_se": "draw",
        "name_el": "draw",
        "name_es": "draw",
        "name_hr": "draw",
        "name_fr": "draw"
    },
    "222^prematch": {
        "name_en": "7:3",
        "name_aa": "7:3",
        "name_de": "7:3",
        "name_nl": "7:3",
        "name_pl": "7:3",
        "name_ro": "7:3",
        "name_ru": "7:3",
        "name_tr": "7:3",
        "name_se": "7:3",
        "name_el": "7:3",
        "name_es": "7:3",
        "name_hr": "7:3",
        "name_fr": "7:3"
    },
    "1717^prematch": {
        "name_en": "draw",
        "name_aa": "draw",
        "name_de": "unentschieden",
        "name_nl": "gelijkspel",
        "name_pl": "draw",
        "name_ro": "draw",
        "name_ru": "draw",
        "name_tr": "beraberlik",
        "name_se": "draw",
        "name_el": "draw",
        "name_es": "draw",
        "name_hr": "neodlučeno",
        "name_fr": "Match nul"
    },
    "70840000^prematch": {
        "name_en": "2-X",
        "name_aa": "2-X",
        "name_de": "2-X",
        "name_nl": "2-X",
        "name_pl": "2-X",
        "name_ro": "2-X",
        "name_ru": "2-X",
        "name_tr": "2-X",
        "name_se": "2-X",
        "name_el": "2-X",
        "name_es": "2-X",
        "name_hr": "2-X",
        "name_fr": "2-X"
    },
    "70850000^prematch": {
        "name_en": "2-2",
        "name_aa": "2-2",
        "name_de": "2-2",
        "name_nl": "2-2",
        "name_pl": "2-2",
        "name_ro": "2-2",
        "name_ru": "2-2",
        "name_tr": "2-2",
        "name_se": "2-2",
        "name_el": "2-2",
        "name_es": "2-2",
        "name_hr": "2-2",
        "name_fr": "2-2"
    },
    "352^prematch": {
        "name_en": "0:1 0:3",
        "name_aa": "0:1 0:3",
        "name_de": "0:1 0:3",
        "name_nl": "0:1 0:3",
        "name_pl": "0:1 0:3",
        "name_ro": "0:1 0:3",
        "name_ru": "0:1 0:3",
        "name_tr": "0:1 0:3",
        "name_se": "0:1 0:3",
        "name_el": "0:1 0:3",
        "name_es": "0:1 0:3",
        "name_hr": "0:1 0:3",
        "name_fr": "0:1 0:3"
    },
    "1088^prematch": {
        "name_en": "26-30",
        "name_aa": "26-30",
        "name_de": "26-30",
        "name_nl": "26-30",
        "name_pl": "26-30",
        "name_ro": "26-30",
        "name_ru": "26-30",
        "name_tr": "26-30",
        "name_se": "26-30",
        "name_el": "26-30",
        "name_es": "26-30",
        "name_hr": "26-30",
        "name_fr": "26-30"
    },
    "1349^prematch": {
        "name_en": "draw",
        "name_aa": "draw",
        "name_de": "draw",
        "name_nl": "draw",
        "name_pl": "draw",
        "name_ro": "draw",
        "name_ru": "draw",
        "name_tr": "draw",
        "name_se": "draw",
        "name_el": "draw",
        "name_es": "draw",
        "name_hr": "draw",
        "name_fr": "draw"
    },
    "303^prematch": {
        "name_en": "5:11",
        "name_aa": "5:11",
        "name_de": "5:11",
        "name_nl": "5:11",
        "name_pl": "5:11",
        "name_ro": "5:11",
        "name_ru": "5:11",
        "name_tr": "5:11",
        "name_se": "5:11",
        "name_el": "5:11",
        "name_es": "5:11",
        "name_hr": "5:11",
        "name_fr": "5:11"
    },
    "292^prematch": {
        "name_en": "11:5",
        "name_aa": "11:5",
        "name_de": "11:5",
        "name_nl": "11:5",
        "name_pl": "11:5",
        "name_ro": "11:5",
        "name_ru": "11:5",
        "name_tr": "11:5",
        "name_se": "11:5",
        "name_el": "11:5",
        "name_es": "11:5",
        "name_hr": "11:5",
        "name_fr": "11:5"
    },
    "1622^prematch": {
        "name_en": "2nd period",
        "name_aa": "2nd period",
        "name_de": "2. Periode",
        "name_nl": "2e periode",
        "name_pl": "2nd period",
        "name_ro": "2nd period",
        "name_ru": "2nd period",
        "name_tr": "İkinci periyot",
        "name_se": "2nd period",
        "name_el": "2nd period",
        "name_es": "2nd period",
        "name_hr": "2. period",
        "name_fr": "2ème période"
    },
    "418^prematch": {
        "name_en": "{$competitor1}/{$competitor1}",
        "name_aa": "{$competitor1}/{$competitor1}",
        "name_de": "{$competitor1}/{$competitor1}",
        "name_nl": "{$competitor1}/{$competitor1}",
        "name_pl": "{$competitor1}/{$competitor1}",
        "name_ro": "{$competitor1}/{$competitor1}",
        "name_ru": "{$competitor1}/{$competitor1}",
        "name_tr": "{$competitor1}/{$competitor1}",
        "name_se": "{$competitor1}/{$competitor1}",
        "name_el": "{$competitor1}/{$competitor1}",
        "name_es": "{$competitor1}/{$competitor1}",
        "name_hr": "{$competitor1}/{$competitor1}",
        "name_fr": "{$competitor1}/{$competitor1}"
    },
    "53^prematch": {
        "name_en": "0",
        "name_aa": "0",
        "name_de": "0",
        "name_nl": "0",
        "name_pl": "0",
        "name_ro": "0",
        "name_ru": "0",
        "name_tr": "0",
        "name_se": "0",
        "name_el": "0",
        "name_es": "0",
        "name_hr": "0",
        "name_fr": "0"
    },
    "338^prematch": {
        "name_en": "0:0 1:2",
        "name_aa": "0:0 1:2",
        "name_de": "0:0 1:2",
        "name_nl": "0:0 1:2",
        "name_pl": "0:0 1:2",
        "name_ro": "0:0 1:2",
        "name_ru": "0:0 1:2",
        "name_tr": "0:0 1:2",
        "name_se": "0:0 1:2",
        "name_el": "0:0 1:2",
        "name_es": "0:0 1:2",
        "name_hr": "0:0 1:2",
        "name_fr": "0:0 1:2"
    },
    "131^prematch": {
        "name_en": "{$competitor1} by 8-14",
        "name_aa": "{$competitor1} by 8-14",
        "name_de": "{$competitor1} by 8-14",
        "name_nl": "{$competitor1} by 8-14",
        "name_pl": "{$competitor1} by 8-14",
        "name_ro": "{$competitor1} by 8-14",
        "name_ru": "{$competitor1} by 8-14",
        "name_tr": "{$competitor1} by 8-14",
        "name_se": "{$competitor1} by 8-14",
        "name_el": "{$competitor1} by 8-14",
        "name_es": "{$competitor1} by 8-14",
        "name_hr": "{$competitor1} by 8-14",
        "name_fr": "{$competitor1} by 8-14"
    },
    "299^prematch": {
        "name_en": "9:11",
        "name_aa": "9:11",
        "name_de": "9:11",
        "name_nl": "9:11",
        "name_pl": "9:11",
        "name_ro": "9:11",
        "name_ru": "9:11",
        "name_tr": "9:11",
        "name_se": "9:11",
        "name_el": "9:11",
        "name_es": "9:11",
        "name_hr": "9:11",
        "name_fr": "9:11"
    },
    "1885^prematch": {
        "name_en": "draw/draw & 5+",
        "name_aa": "draw/draw & 5+",
        "name_de": "unentschieden/unentschieden& 5+",
        "name_nl": "gelijkspel/gelijkspel & 5+",
        "name_pl": "draw/draw & 5+",
        "name_ro": "draw/draw & 5+",
        "name_ru": "draw/draw & 5+",
        "name_tr": "beraberlik/beraberlik & 5+",
        "name_se": "draw/draw & 5+",
        "name_el": "draw/draw & 5+",
        "name_es": "draw/draw & 5+",
        "name_hr": "neodlučeno/neodlučeno i 5+",
        "name_fr": "Match nul/Match nul & 5 ou +"
    },
    "776^prematch": {
        "name_en": "draw",
        "name_aa": "draw",
        "name_de": "unentschieden",
        "name_nl": "gelijkspel",
        "name_pl": "draw",
        "name_ro": "draw",
        "name_ru": "draw",
        "name_tr": "beraberlik",
        "name_se": "draw",
        "name_el": "draw",
        "name_es": "draw",
        "name_hr": "neodlučeno",
        "name_fr": "Match nul"
    },
    "226^prematch": {
        "name_en": "6:7",
        "name_aa": "6:7",
        "name_de": "6:7",
        "name_nl": "6:7",
        "name_pl": "6:7",
        "name_ro": "6:7",
        "name_ru": "6:7",
        "name_tr": "6:7",
        "name_se": "6:7",
        "name_el": "6:7",
        "name_es": "6:7",
        "name_hr": "6:7",
        "name_fr": "6:7"
    },
    "191^prematch": {
        "name_en": "0:6",
        "name_aa": "0:6",
        "name_de": "0:6",
        "name_nl": "0:6",
        "name_pl": "0:6",
        "name_ro": "0:6",
        "name_ru": "0:6",
        "name_tr": "0:6",
        "name_se": "0:6",
        "name_el": "0:6",
        "name_es": "0:6",
        "name_hr": "0:6",
        "name_fr": "0:6"
    },
    "730^prematch": {
        "name_en": "0-1",
        "name_aa": "0-1",
        "name_de": "0-1",
        "name_nl": "0-1",
        "name_pl": "0-1",
        "name_ro": "0-1",
        "name_ru": "0-1",
        "name_tr": "0-1",
        "name_se": "0-1",
        "name_el": "0-1",
        "name_es": "0-1",
        "name_hr": "0-1",
        "name_fr": "0-1"
    },
    "558^prematch": {
        "name_en": "2:0",
        "name_aa": "2:0",
        "name_de": "2:0",
        "name_nl": "2:0",
        "name_pl": "2:0",
        "name_ro": "2:0",
        "name_ru": "2:0",
        "name_tr": "2:0",
        "name_se": "2:0",
        "name_el": "2:0",
        "name_es": "2:0",
        "name_hr": "2:0",
        "name_fr": "2:0"
    },
    "1415^prematch": {
        "name_en": "{$competitor1} & decision",
        "name_aa": "{$competitor1} & decision",
        "name_de": "{$competitor1} & decision",
        "name_nl": "{$competitor1} & decision",
        "name_pl": "{$competitor1} & decision",
        "name_ro": "{$competitor1} & decision",
        "name_ru": "{$competitor1} & decision",
        "name_tr": "{$competitor1} & decision",
        "name_se": "{$competitor1} & decision",
        "name_el": "{$competitor1} & decision",
        "name_es": "{$competitor1} & decision",
        "name_hr": "{$competitor1} & decision",
        "name_fr": "{$competitor1} & decision"
    },
    "802^prematch": {
        "name_en": "{$competitor2} & under {total}",
        "name_aa": "{$competitor2} & under {total}",
        "name_de": "{$competitor2} & unter {total}",
        "name_nl": "{$competitor2} & onder {total}",
        "name_pl": "{$competitor2} & under {total}",
        "name_ro": "{$competitor2} & under {total}",
        "name_ru": "{$competitor2} & under {total}",
        "name_tr": "{$competitor2} & {total} altı",
        "name_se": "{$competitor2} & under {total}",
        "name_el": "{$competitor2} & under {total}",
        "name_es": "{$competitor2} & under {total}",
        "name_hr": "{$competitor2} i manje od {total}",
        "name_fr": "{$competitor2} & moins de {total}"
    },
    "70810000^prematch": {
        "name_en": "X-X",
        "name_aa": "X-X",
        "name_de": "X-X",
        "name_nl": "X-X",
        "name_pl": "X-X",
        "name_ro": "X-X",
        "name_ru": "X-X",
        "name_tr": "X-X",
        "name_se": "X-X",
        "name_el": "X-X",
        "name_es": "X-X",
        "name_hr": "X-X",
        "name_fr": "X-X"
    },
    "1181^prematch": {
        "name_en": "3:3",
        "name_aa": "3:3",
        "name_de": "3:3",
        "name_nl": "3:3",
        "name_pl": "3:3",
        "name_ro": "3:3",
        "name_ru": "3:3",
        "name_tr": "3:3",
        "name_se": "3:3",
        "name_el": "3:3",
        "name_es": "3:3",
        "name_hr": "3:3",
        "name_fr": "3:3"
    },
    "1826^prematch": {
        "name_en": "{!inningnr} inning",
        "name_aa": "{!inningnr} inning",
        "name_de": "{!inningnr} Inning",
        "name_nl": "{!inningnr} inning",
        "name_pl": "{!inningnr} inning",
        "name_ro": "{!inningnr} inning",
        "name_ru": "{!inningnr} inning",
        "name_tr": "{!inningnr} devre",
        "name_se": "{!inningnr} inning",
        "name_el": "{!inningnr} inning",
        "name_es": "{!inningnr} inning",
        "name_hr": "{!inningnr} inning",
        "name_fr": "{!inningnr} manche"
    },
    "1124^prematch": {
        "name_en": "6+",
        "name_aa": "6+",
        "name_de": "6+",
        "name_nl": "6+",
        "name_pl": "6+",
        "name_ro": "6+",
        "name_ru": "6+",
        "name_tr": "6+",
        "name_se": "6+",
        "name_el": "6+",
        "name_es": "6+",
        "name_hr": "6+",
        "name_fr": "6+"
    },
    "159^prematch": {
        "name_en": "111-120",
        "name_aa": "111-120",
        "name_de": "111-120",
        "name_nl": "111-120",
        "name_pl": "111-120",
        "name_ro": "111-120",
        "name_ru": "111-120",
        "name_tr": "111-120",
        "name_se": "111-120",
        "name_el": "111-120",
        "name_es": "111-120",
        "name_hr": "111-120",
        "name_fr": "111-120"
    },
    "966^prematch": {
        "name_en": "5+",
        "name_aa": "5+",
        "name_de": "5+",
        "name_nl": "5+",
        "name_pl": "5+",
        "name_ro": "5+",
        "name_ru": "5+",
        "name_tr": "5+",
        "name_se": "5+",
        "name_el": "5+",
        "name_es": "5+",
        "name_hr": "5+",
        "name_fr": "5+"
    },
    "320^prematch": {
        "name_en": "3:4",
        "name_aa": "3:4",
        "name_de": "3:4",
        "name_nl": "3:4",
        "name_pl": "3:4",
        "name_ro": "3:4",
        "name_ru": "3:4",
        "name_tr": "3:4",
        "name_se": "3:4",
        "name_el": "3:4",
        "name_es": "3:4",
        "name_hr": "3:4",
        "name_fr": "3:4"
    },
    "394^prematch": {
        "name_en": "1:1 4+",
        "name_aa": "1:1 4+",
        "name_de": "1:1 4+",
        "name_nl": "1:1 4+",
        "name_pl": "1:1 4+",
        "name_ro": "1:1 4+",
        "name_ru": "1:1 4+",
        "name_tr": "1:1 4+",
        "name_se": "1:1 4+",
        "name_el": "1:1 4+",
        "name_es": "1:1 4+",
        "name_hr": "1:1 4+",
        "name_fr": "1:1 4+"
    },
    "330^prematch": {
        "name_en": "0:0 0:2",
        "name_aa": "0:0 0:2",
        "name_de": "0:0 0:2",
        "name_nl": "0:0 0:2",
        "name_pl": "0:0 0:2",
        "name_ro": "0:0 0:2",
        "name_ru": "0:0 0:2",
        "name_tr": "0:0 0:2",
        "name_se": "0:0 0:2",
        "name_el": "0:0 0:2",
        "name_es": "0:0 0:2",
        "name_hr": "0:0 0:2",
        "name_fr": "0:0 0:2"
    },
    "332^prematch": {
        "name_en": "0:0 0:3",
        "name_aa": "0:0 0:3",
        "name_de": "0:0 0:3",
        "name_nl": "0:0 0:3",
        "name_pl": "0:0 0:3",
        "name_ro": "0:0 0:3",
        "name_ru": "0:0 0:3",
        "name_tr": "0:0 0:3",
        "name_se": "0:0 0:3",
        "name_el": "0:0 0:3",
        "name_es": "0:0 0:3",
        "name_hr": "0:0 0:3",
        "name_fr": "0:0 0:3"
    },
    "1387^prematch": {
        "name_en": "{$competitor2} & 4-6",
        "name_aa": "{$competitor2} & 4-6",
        "name_de": "{$competitor2} & 4-6",
        "name_nl": "{$competitor2} & 4-6",
        "name_pl": "{$competitor2} & 4-6",
        "name_ro": "{$competitor2} & 4-6",
        "name_ru": "{$competitor2} & 4-6",
        "name_tr": "{$competitor2} & 4-6",
        "name_se": "{$competitor2} & 4-6",
        "name_el": "{$competitor2} & 4-6",
        "name_es": "{$competitor2} & 4-6",
        "name_hr": "{$competitor2} & 4-6",
        "name_fr": "{$competitor2} & 4-6"
    },
    "56170000^prematch": {
        "name_en": "6+",
        "name_aa": "6+",
        "name_de": "6+",
        "name_nl": "6+",
        "name_pl": "6+",
        "name_ro": "6+",
        "name_ru": "6+",
        "name_tr": "6+",
        "name_se": "6+",
        "name_el": "6+",
        "name_es": "6+",
        "name_hr": "6+",
        "name_fr": "6+"
    },
    "119^prematch": {
        "name_en": "draw",
        "name_aa": "draw",
        "name_de": "draw",
        "name_nl": "draw",
        "name_pl": "draw",
        "name_ro": "draw",
        "name_ru": "draw",
        "name_tr": "draw",
        "name_se": "draw",
        "name_el": "draw",
        "name_es": "draw",
        "name_hr": "draw",
        "name_fr": "draw"
    },
    "72^prematch": {
        "name_en": "even",
        "name_aa": "even",
        "name_de": "gerade",
        "name_nl": "even",
        "name_pl": "even",
        "name_ro": "even",
        "name_ru": "even",
        "name_tr": "çift",
        "name_se": "even",
        "name_el": "even",
        "name_es": "even",
        "name_hr": "nepar",
        "name_fr": "pair"
    },
    "863^prematch": {
        "name_en": "{$competitor1}/{$competitor2}",
        "name_aa": "{$competitor1}/{$competitor2}",
        "name_de": "{$competitor1}/{$competitor2}",
        "name_nl": "{$competitor1}/{$competitor2}",
        "name_pl": "{$competitor1}/{$competitor2}",
        "name_ro": "{$competitor1}/{$competitor2}",
        "name_ru": "{$competitor1}/{$competitor2}",
        "name_tr": "{$competitor1}/{$competitor2}",
        "name_se": "{$competitor1}/{$competitor2}",
        "name_el": "{$competitor1}/{$competitor2}",
        "name_es": "{$competitor1}/{$competitor2}",
        "name_hr": "{$competitor1}/{$competitor2}",
        "name_fr": "{$competitor1}/{$competitor2}"
    },
    "56180000^prematch": {
        "name_en": "0-3",
        "name_aa": "0-3",
        "name_de": "0-3",
        "name_nl": "0-3",
        "name_pl": "0-3",
        "name_ro": "0-3",
        "name_ru": "0-3",
        "name_tr": "0-3",
        "name_se": "0-3",
        "name_el": "0-3",
        "name_es": "0-3",
        "name_hr": "0-3",
        "name_fr": "0-3"
    },
    "1803^prematch": {
        "name_en": "draw",
        "name_aa": "draw",
        "name_de": "unentschieden",
        "name_nl": "gelijkspel",
        "name_pl": "draw",
        "name_ro": "draw",
        "name_ru": "draw",
        "name_tr": "beraberlik",
        "name_se": "draw",
        "name_el": "draw",
        "name_es": "draw",
        "name_hr": "neodlučeno",
        "name_fr": "Match nul"
    },
    "183^prematch": {
        "name_en": "6:3",
        "name_aa": "6:3",
        "name_de": "6:3",
        "name_nl": "6:3",
        "name_pl": "6:3",
        "name_ro": "6:3",
        "name_ru": "6:3",
        "name_tr": "6:3",
        "name_se": "6:3",
        "name_el": "6:3",
        "name_es": "6:3",
        "name_hr": "6:3",
        "name_fr": "6:3"
    },
    "360^prematch": {
        "name_en": "0:1 4+",
        "name_aa": "0:1 4+",
        "name_de": "0:1 4+",
        "name_nl": "0:1 4+",
        "name_pl": "0:1 4+",
        "name_ro": "0:1 4+",
        "name_ru": "0:1 4+",
        "name_tr": "0:1 4+",
        "name_se": "0:1 4+",
        "name_el": "0:1 4+",
        "name_es": "0:1 4+",
        "name_hr": "0:1 4+",
        "name_fr": "0:1 4+"
    },
    "1828^prematch": {
        "name_en": "{!(inningnr+1)} inning",
        "name_aa": "{!(inningnr+1)} inning",
        "name_de": "{!(inningnr+1)} Inning",
        "name_nl": "{!(inningnr+1)} inning",
        "name_pl": "{!(inningnr+1)} inning",
        "name_ro": "{!(inningnr+1)} inning",
        "name_ru": "{!(inningnr+1)} inning",
        "name_tr": "{!(inningnr+1)} devre",
        "name_se": "{!(inningnr+1)} inning",
        "name_el": "{!(inningnr+1)} inning",
        "name_es": "{!(inningnr+1)} inning",
        "name_hr": "{!(inningnr+1)} inning",
        "name_fr": "{!(inningnr+1)} manche"
    },
    "554^prematch": {
        "name_en": "1:1",
        "name_aa": "1:1",
        "name_de": "1:1",
        "name_nl": "1:1",
        "name_pl": "1:1",
        "name_ro": "1:1",
        "name_ru": "1:1",
        "name_tr": "1:1",
        "name_se": "1:1",
        "name_el": "1:1",
        "name_es": "1:1",
        "name_hr": "1:1",
        "name_fr": "1:1"
    },
    "386^prematch": {
        "name_en": "1:0 4+",
        "name_aa": "1:0 4+",
        "name_de": "1:0 4+",
        "name_nl": "1:0 4+",
        "name_pl": "1:0 4+",
        "name_ro": "1:0 4+",
        "name_ru": "1:0 4+",
        "name_tr": "1:0 4+",
        "name_se": "1:0 4+",
        "name_el": "1:0 4+",
        "name_es": "1:0 4+",
        "name_hr": "1:0 4+",
        "name_fr": "1:0 4+"
    },
    "438^prematch": {
        "name_en": "2nd half",
        "name_aa": "2nd half",
        "name_de": "2. Halbzeit",
        "name_nl": "tweede helft",
        "name_pl": "2nd half",
        "name_ro": "2nd half",
        "name_ru": "2nd half",
        "name_tr": "İkinci yarı",
        "name_se": "2nd half",
        "name_el": "2nd half",
        "name_es": "2nd half",
        "name_hr": "2. poluvrijeme",
        "name_fr": "2ème mi-temps"
    },
    "1720^prematch": {
        "name_en": "{$competitor1}/{$competitor2} & yes",
        "name_aa": "{$competitor1}/{$competitor2} & yes",
        "name_de": "{$competitor1}/{$competitor2} & ja",
        "name_nl": "{$competitor1}/{$competitor2} & ja",
        "name_pl": "{$competitor1}/{$competitor2} & yes",
        "name_ro": "{$competitor1}/{$competitor2} & yes",
        "name_ru": "{$competitor1}/{$competitor2} & yes",
        "name_tr": "{$competitor1}/{$competitor2} & var",
        "name_se": "{$competitor1}/{$competitor2} & yes",
        "name_el": "{$competitor1}/{$competitor2} & yes",
        "name_es": "{$competitor1}/{$competitor2} & yes",
        "name_hr": "{$competitor1}/{$competitor2}  i da",
        "name_fr": "{$competitor1}/{$competitor2} & oui"
    },
    "179^prematch": {
        "name_en": "6:0",
        "name_aa": "6:0",
        "name_de": "6:0",
        "name_nl": "6:0",
        "name_pl": "6:0",
        "name_ro": "6:0",
        "name_ru": "6:0",
        "name_tr": "6:0",
        "name_se": "6:0",
        "name_el": "6:0",
        "name_es": "6:0",
        "name_hr": "6:0",
        "name_fr": "6:0"
    },
    "57^prematch": {
        "name_en": "4",
        "name_aa": "4",
        "name_de": "4",
        "name_nl": "4",
        "name_pl": "4",
        "name_ro": "4",
        "name_ru": "4",
        "name_tr": "4",
        "name_se": "4",
        "name_el": "4",
        "name_es": "4",
        "name_hr": "4",
        "name_fr": "4"
    },
    "876^prematch": {
        "name_en": "4:6",
        "name_aa": "4:6",
        "name_de": "4:6",
        "name_nl": "4:6",
        "name_pl": "4:6",
        "name_ro": "4:6",
        "name_ru": "4:6",
        "name_tr": "4:6",
        "name_se": "4:6",
        "name_el": "4:6",
        "name_es": "4:6",
        "name_hr": "4:6",
        "name_fr": "4:6"
    },
    "1174^prematch": {
        "name_en": "2:2",
        "name_aa": "2:2",
        "name_de": "2:2",
        "name_nl": "2:2",
        "name_pl": "2:2",
        "name_ro": "2:2",
        "name_ru": "2:2",
        "name_tr": "2:2",
        "name_se": "2:2",
        "name_el": "2:2",
        "name_es": "2:2",
        "name_hr": "2:2",
        "name_fr": "2:2"
    },
    "1740^prematch": {
        "name_en": "3-5",
        "name_aa": "3-5",
        "name_de": "3-5",
        "name_nl": "3-5",
        "name_pl": "3-5",
        "name_ro": "3-5",
        "name_ru": "3-5",
        "name_tr": "3-5",
        "name_se": "3-5",
        "name_el": "3-5",
        "name_es": "3-5",
        "name_hr": "3-5",
        "name_fr": "3-5"
    },
    "1351^prematch": {
        "name_en": "{$competitor2} by submission",
        "name_aa": "{$competitor2} by submission",
        "name_de": "{$competitor2} by submission",
        "name_nl": "{$competitor2} by submission",
        "name_pl": "{$competitor2} by submission",
        "name_ro": "{$competitor2} by submission",
        "name_ru": "{$competitor2} by submission",
        "name_tr": "{$competitor2} by submission",
        "name_se": "{$competitor2} by submission",
        "name_el": "{$competitor2} by submission",
        "name_es": "{$competitor2} by submission",
        "name_hr": "{$competitor2} by submission",
        "name_fr": "{$competitor2} by submission"
    },
    "161^prematch": {
        "name_en": "131-140",
        "name_aa": "131-140",
        "name_de": "131-140",
        "name_nl": "131-140",
        "name_pl": "131-140",
        "name_ro": "131-140",
        "name_ru": "131-140",
        "name_tr": "131-140",
        "name_se": "131-140",
        "name_el": "131-140",
        "name_es": "131-140",
        "name_hr": "131-140",
        "name_fr": "131-140"
    },
    "1862^prematch": {
        "name_en": "draw/draw & 2",
        "name_aa": "draw/draw & 2",
        "name_de": "unentschieden/unentschieden& 2",
        "name_nl": "gelijkspel/gelijkspel & 2",
        "name_pl": "draw/draw & 2",
        "name_ro": "draw/draw & 2",
        "name_ru": "draw/draw & 2",
        "name_tr": "beraberlik/beraberlik & 2",
        "name_se": "draw/draw & 2",
        "name_el": "draw/draw & 2",
        "name_es": "draw/draw & 2",
        "name_hr": "neodlučeno/neodlučeno i 2",
        "name_fr": "Match nul/Match nul & 2"
    },
    "1491^prematch": {
        "name_en": "4:2",
        "name_aa": "4:2",
        "name_de": "4:2",
        "name_nl": "4:2",
        "name_pl": "4:2",
        "name_ro": "4:2",
        "name_ru": "4:2",
        "name_tr": "4:2",
        "name_se": "4:2",
        "name_el": "4:2",
        "name_es": "4:2",
        "name_hr": "4:2",
        "name_fr": "4:2"
    },
    "864^prematch": {
        "name_en": "{$competitor2}/{$competitor2}",
        "name_aa": "{$competitor2}/{$competitor2}",
        "name_de": "{$competitor2}/{$competitor2}",
        "name_nl": "{$competitor2}/{$competitor2}",
        "name_pl": "{$competitor2}/{$competitor2}",
        "name_ro": "{$competitor2}/{$competitor2}",
        "name_ru": "{$competitor2}/{$competitor2}",
        "name_tr": "{$competitor2}/{$competitor2}",
        "name_se": "{$competitor2}/{$competitor2}",
        "name_el": "{$competitor2}/{$competitor2}",
        "name_es": "{$competitor2}/{$competitor2}",
        "name_hr": "{$competitor2}/{$competitor2}",
        "name_fr": "{$competitor2}/{$competitor2}"
    },
    "1409^prematch": {
        "name_en": "{$competitor1} & 7",
        "name_aa": "{$competitor1} & 7",
        "name_de": "{$competitor1} & 7",
        "name_nl": "{$competitor1} & 7",
        "name_pl": "{$competitor1} & 7",
        "name_ro": "{$competitor1} & 7",
        "name_ru": "{$competitor1} & 7",
        "name_tr": "{$competitor1} & 7",
        "name_se": "{$competitor1} & 7",
        "name_el": "{$competitor1} & 7",
        "name_es": "{$competitor1} & 7",
        "name_hr": "{$competitor1} & 7",
        "name_fr": "{$competitor1} & 7"
    },
    "1763^prematch": {
        "name_en": "3",
        "name_aa": "3",
        "name_de": "3",
        "name_nl": "3",
        "name_pl": "3",
        "name_ro": "3",
        "name_ru": "3",
        "name_tr": "3",
        "name_se": "3",
        "name_el": "3",
        "name_es": "3",
        "name_hr": "3",
        "name_fr": "3"
    },
    "602^prematch": {
        "name_en": "21-30",
        "name_aa": "21-30",
        "name_de": "21-30",
        "name_nl": "21-30",
        "name_pl": "21-30",
        "name_ro": "21-30",
        "name_ru": "21-30",
        "name_tr": "21-30",
        "name_se": "21-30",
        "name_el": "21-30",
        "name_es": "21-30",
        "name_hr": "21-30",
        "name_fr": "21-30"
    },
    "1168^prematch": {
        "name_en": "1:3",
        "name_aa": "1:3",
        "name_de": "1:3",
        "name_nl": "1:3",
        "name_pl": "1:3",
        "name_ro": "1:3",
        "name_ru": "1:3",
        "name_tr": "1:3",
        "name_se": "1:3",
        "name_el": "1:3",
        "name_es": "1:3",
        "name_hr": "1:3",
        "name_fr": "1:3"
    },
    "1378^prematch": {
        "name_en": "{$competitor2} by ko",
        "name_aa": "{$competitor2} by ko",
        "name_de": "{$competitor2} by ko",
        "name_nl": "{$competitor2} by ko",
        "name_pl": "{$competitor2} by ko",
        "name_ro": "{$competitor2} by ko",
        "name_ru": "{$competitor2} by ko",
        "name_tr": "{$competitor2} by ko",
        "name_se": "{$competitor2} by ko",
        "name_el": "{$competitor2} by ko",
        "name_es": "{$competitor2} by ko",
        "name_hr": "{$competitor2} by ko",
        "name_fr": "{$competitor2} by ko"
    },
    "1301^prematch": {
        "name_en": "31+",
        "name_aa": "31+",
        "name_de": "31+",
        "name_nl": "31+",
        "name_pl": "31+",
        "name_ro": "31+",
        "name_ru": "31+",
        "name_tr": "31+",
        "name_se": "31+",
        "name_el": "31+",
        "name_es": "31+",
        "name_hr": "31+",
        "name_fr": "31+"
    },
    "30^prematch": {
        "name_en": "1:5",
        "name_aa": "1:5",
        "name_de": "1:5",
        "name_nl": "1:5",
        "name_pl": "1:5",
        "name_ro": "1:5",
        "name_ru": "1:5",
        "name_tr": "1:5",
        "name_se": "1:5",
        "name_el": "1:5",
        "name_es": "1:5",
        "name_hr": "1:5",
        "name_fr": "1:5"
    },
    "281^prematch": {
        "name_en": "5:10",
        "name_aa": "5:10",
        "name_de": "5:10",
        "name_nl": "5:10",
        "name_pl": "5:10",
        "name_ro": "5:10",
        "name_ru": "5:10",
        "name_tr": "5:10",
        "name_se": "5:10",
        "name_el": "5:10",
        "name_es": "5:10",
        "name_hr": "5:10",
        "name_fr": "5:10"
    },
    "1199^prematch": {
        "name_en": "0.5:1.5",
        "name_aa": "0.5:1.5",
        "name_de": "0.5:1.5",
        "name_nl": "0.5:1.5",
        "name_pl": "0.5:1.5",
        "name_ro": "0.5:1.5",
        "name_ru": "0.5:1.5",
        "name_tr": "0.5:1.5",
        "name_se": "0.5:1.5",
        "name_el": "0.5:1.5",
        "name_es": "0.5:1.5",
        "name_hr": "0.5:1.5",
        "name_fr": "0.5:1.5"
    },
    "235^prematch": {
        "name_en": "8:2",
        "name_aa": "8:2",
        "name_de": "8:2",
        "name_nl": "8:2",
        "name_pl": "8:2",
        "name_ro": "8:2",
        "name_ru": "8:2",
        "name_tr": "8:2",
        "name_se": "8:2",
        "name_el": "8:2",
        "name_es": "8:2",
        "name_hr": "8:2",
        "name_fr": "8:2"
    },
    "175^prematch": {
        "name_en": "221-230",
        "name_aa": "221-230",
        "name_de": "221-230",
        "name_nl": "221-230",
        "name_pl": "221-230",
        "name_ro": "221-230",
        "name_ru": "221-230",
        "name_tr": "221-230",
        "name_se": "221-230",
        "name_el": "221-230",
        "name_es": "221-230",
        "name_hr": "221-230",
        "name_fr": "221-230"
    },
    "1694430000^prematch": {
        "name_en": "{$competitor2}",
        "name_aa": "{$competitor2}",
        "name_de": "{$competitor2}",
        "name_nl": "{$competitor2}",
        "name_pl": "{$competitor2}",
        "name_ro": "{$competitor2}",
        "name_ru": "{$competitor2}",
        "name_tr": "{$competitor2}",
        "name_se": "{$competitor2}",
        "name_el": "{$competitor2}",
        "name_es": "{$competitor2}",
        "name_hr": "{$competitor2}",
        "name_fr": "{$competitor2}"
    },
    "640^prematch": {
        "name_en": "{$competitor2} by 3+",
        "name_aa": "{$competitor2} by 3+",
        "name_de": "{$competitor2} mit 3+",
        "name_nl": "{$competitor2} met 3+",
        "name_pl": "{$competitor2} by 3+",
        "name_ro": "{$competitor2} by 3+",
        "name_ru": "{$competitor2} by 3+",
        "name_tr": "{$competitor2} 3 ve üstü farkla",
        "name_se": "{$competitor2} by 3+",
        "name_el": "{$competitor2} by 3+",
        "name_es": "{$competitor2} by 3+",
        "name_hr": "{$competitor2} zа 3 i više",
        "name_fr": "{$competitor2} par 3 ou +"
    },
    "1477^prematch": {
        "name_en": "2:0",
        "name_aa": "2:0",
        "name_de": "2:0",
        "name_nl": "2:0",
        "name_pl": "2:0",
        "name_ro": "2:0",
        "name_ru": "2:0",
        "name_tr": "2:0",
        "name_se": "2:0",
        "name_el": "2:0",
        "name_es": "2:0",
        "name_hr": "2:0",
        "name_fr": "2:0"
    },
    "1252^prematch": {
        "name_en": "0-24",
        "name_aa": "0-24",
        "name_de": "0-24",
        "name_nl": "0-24",
        "name_pl": "0-24",
        "name_ro": "0-24",
        "name_ru": "0-24",
        "name_tr": "0-24",
        "name_se": "0-24",
        "name_el": "0-24",
        "name_es": "0-24",
        "name_hr": "0-24",
        "name_fr": "0-24"
    },
    "1147^prematch": {
        "name_en": "7+",
        "name_aa": "7+",
        "name_de": "7+",
        "name_nl": "7+",
        "name_pl": "7+",
        "name_ro": "7+",
        "name_ru": "7+",
        "name_tr": "7+",
        "name_se": "7+",
        "name_el": "7+",
        "name_es": "7+",
        "name_hr": "7+",
        "name_fr": "7+"
    },
    "87^prematch": {
        "name_en": "2+",
        "name_aa": "2+",
        "name_de": "2+",
        "name_nl": "2+",
        "name_pl": "2+",
        "name_ro": "2+",
        "name_ru": "2+",
        "name_tr": "2+",
        "name_se": "2+",
        "name_el": "2+",
        "name_es": "2+",
        "name_hr": "2+",
        "name_fr": "2+"
    },
    "2^prematch": {
        "name_en": "draw",
        "name_aa": "draw",
        "name_de": "unentschieden",
        "name_nl": "gelijkspel",
        "name_pl": "draw",
        "name_ro": "draw",
        "name_ru": "draw",
        "name_tr": "beraberlik",
        "name_se": "draw",
        "name_el": "draw",
        "name_es": "draw",
        "name_hr": "neodlučeno",
        "name_fr": "Match nul"
    },
    "960^prematch": {
        "name_en": "equal",
        "name_aa": "equal",
        "name_de": "gleich",
        "name_nl": "gelijk",
        "name_pl": "equal",
        "name_ro": "equal",
        "name_ru": "equal",
        "name_tr": "eşit",
        "name_se": "equal",
        "name_el": "equal",
        "name_es": "equal",
        "name_hr": "jednako",
        "name_fr": "égal"
    },
    "70880000^prematch": {
        "name_en": "{$competitor2}",
        "name_aa": "{$competitor2}",
        "name_de": "{$competitor2}",
        "name_nl": "{$competitor2}",
        "name_pl": "{$competitor2}",
        "name_ro": "{$competitor2}",
        "name_ru": "{$competitor2}",
        "name_tr": "{$competitor2}",
        "name_se": "{$competitor2}",
        "name_el": "{$competitor2}",
        "name_es": "{$competitor2}",
        "name_hr": "{$competitor2}",
        "name_fr": "{$competitor2}"
    },
    "548^prematch": {
        "name_en": "0:1",
        "name_aa": "0:1",
        "name_de": "0:1",
        "name_nl": "0:1",
        "name_pl": "0:1",
        "name_ro": "0:1",
        "name_ru": "0:1",
        "name_tr": "0:1",
        "name_se": "0:1",
        "name_el": "0:1",
        "name_es": "0:1",
        "name_hr": "0:1",
        "name_fr": "0:1"
    },
    "1837^prematch": {
        "name_en": "{$competitor1}/draw & under {total}",
        "name_aa": "{$competitor1}/draw & under {total}",
        "name_de": "{$competitor1}/unentschieden & unter {total}",
        "name_nl": "{$competitor1}/gelijkspel & onder {total}",
        "name_pl": "{$competitor1}/draw & under {total}",
        "name_ro": "{$competitor1}/draw & under {total}",
        "name_ru": "{$competitor1}/draw & under {total}",
        "name_tr": "{$competitor1}/beraberlik & {total} altı",
        "name_se": "{$competitor1}/draw & under {total}",
        "name_el": "{$competitor1}/draw & under {total}",
        "name_es": "{$competitor1}/draw & under {total}",
        "name_hr": "{$competitor1}/neodlučeno i manje {total}",
        "name_fr": "{$competitor1}/Match nul & Moins de {total}"
    },
    "70910000^prematch": {
        "name_en": "{$competitor2}",
        "name_aa": "{$competitor2}",
        "name_de": "{$competitor2}",
        "name_nl": "{$competitor2}",
        "name_pl": "{$competitor2}",
        "name_ro": "{$competitor2}",
        "name_ru": "{$competitor2}",
        "name_tr": "{$competitor2}",
        "name_se": "{$competitor2}",
        "name_el": "{$competitor2}",
        "name_es": "{$competitor2}",
        "name_hr": "{$competitor2}",
        "name_fr": "{$competitor2}"
    },
    "428^prematch": {
        "name_en": "draw/{$competitor2}",
        "name_aa": "draw/{$competitor2}",
        "name_de": "unentschieden/{$competitor2}",
        "name_nl": "gelijkspel/{$competitor2}",
        "name_pl": "draw/{$competitor2}",
        "name_ro": "draw/{$competitor2}",
        "name_ru": "draw/{$competitor2}",
        "name_tr": "beraberlik/{$competitor2}",
        "name_se": "draw/{$competitor2}",
        "name_el": "draw/{$competitor2}",
        "name_es": "draw/{$competitor2}",
        "name_hr": "neodlučeno/{$competitor2}",
        "name_fr": "Match nul/{$competitor2}"
    },
    "190^prematch": {
        "name_en": "1:6",
        "name_aa": "1:6",
        "name_de": "1:6",
        "name_nl": "1:6",
        "name_pl": "1:6",
        "name_ro": "1:6",
        "name_ru": "1:6",
        "name_tr": "1:6",
        "name_se": "1:6",
        "name_el": "1:6",
        "name_es": "1:6",
        "name_hr": "1:6",
        "name_fr": "1:6"
    },
    "1075^prematch": {
        "name_en": "150-159",
        "name_aa": "150-159",
        "name_de": "150-159",
        "name_nl": "150-159",
        "name_pl": "150-159",
        "name_ro": "150-159",
        "name_ru": "150-159",
        "name_tr": "150-159",
        "name_se": "150-159",
        "name_el": "150-159",
        "name_es": "150-159",
        "name_hr": "150-159",
        "name_fr": "150-159"
    },
    "38^prematch": {
        "name_en": "3",
        "name_aa": "3",
        "name_de": "3",
        "name_nl": "3",
        "name_pl": "3",
        "name_ro": "3",
        "name_ru": "3",
        "name_tr": "3",
        "name_se": "3",
        "name_el": "3",
        "name_es": "3",
        "name_hr": "3",
        "name_fr": "3"
    },
    "1164^prematch": {
        "name_en": "0:7",
        "name_aa": "0:7",
        "name_de": "0:7",
        "name_nl": "0:7",
        "name_pl": "0:7",
        "name_ro": "0:7",
        "name_ru": "0:7",
        "name_tr": "0:7",
        "name_se": "0:7",
        "name_el": "0:7",
        "name_es": "0:7",
        "name_hr": "0:7",
        "name_fr": "0:7"
    },
    "466^prematch": {
        "name_en": "2:2",
        "name_aa": "2:2",
        "name_de": "2:2",
        "name_nl": "2:2",
        "name_pl": "2:2",
        "name_ro": "2:2",
        "name_ru": "2:2",
        "name_tr": "2:2",
        "name_se": "2:2",
        "name_el": "2:2",
        "name_es": "2:2",
        "name_hr": "2:2",
        "name_fr": "2:2"
    },
    "9^prematch": {
        "name_en": "{$competitor1} or draw",
        "name_aa": "{$competitor1} or draw",
        "name_de": "{$competitor1} oder unentschieden",
        "name_nl": "{$competitor1} of gelijkspel",
        "name_pl": "{$competitor1} or draw",
        "name_ro": "{$competitor1} or draw",
        "name_ru": "{$competitor1} or draw",
        "name_tr": "{$competitor1} veya beraberlik",
        "name_se": "{$competitor1} or draw",
        "name_el": "{$competitor1} or draw",
        "name_es": "{$competitor1} or draw",
        "name_hr": "{$competitor1} ili neodlučeno",
        "name_fr": "{$competitor1} ou Match nul"
    },
    "227^prematch": {
        "name_en": "5:7",
        "name_aa": "5:7",
        "name_de": "5:7",
        "name_nl": "5:7",
        "name_pl": "5:7",
        "name_ro": "5:7",
        "name_ru": "5:7",
        "name_tr": "5:7",
        "name_se": "5:7",
        "name_el": "5:7",
        "name_es": "5:7",
        "name_hr": "5:7",
        "name_fr": "5:7"
    },
    "778^prematch": {
        "name_en": "{$competitor2}",
        "name_aa": "{$competitor2}",
        "name_de": "{$competitor2}",
        "name_nl": "{$competitor2}",
        "name_pl": "{$competitor2}",
        "name_ro": "{$competitor2}",
        "name_ru": "{$competitor2}",
        "name_tr": "{$competitor2}",
        "name_se": "{$competitor2}",
        "name_el": "{$competitor2}",
        "name_es": "{$competitor2}",
        "name_hr": "{$competitor2}",
        "name_fr": "{$competitor2}"
    },
    "120^prematch": {
        "name_en": "{$competitor1} by 6+",
        "name_aa": "{$competitor1} by 6+",
        "name_de": "{$competitor1} by 6+",
        "name_nl": "{$competitor1} by 6+",
        "name_pl": "{$competitor1} by 6+",
        "name_ro": "{$competitor1} by 6+",
        "name_ru": "{$competitor1} by 6+",
        "name_tr": "{$competitor1} by 6+",
        "name_se": "{$competitor1} by 6+",
        "name_el": "{$competitor1} by 6+",
        "name_es": "{$competitor1} by 6+",
        "name_hr": "{$competitor1} by 6+",
        "name_fr": "{$competitor1} by 6+"
    },
    "1748^prematch": {
        "name_en": "2-3",
        "name_aa": "2-3",
        "name_de": "2-3",
        "name_nl": "2-3",
        "name_pl": "2-3",
        "name_ro": "2-3",
        "name_ru": "2-3",
        "name_tr": "2-3",
        "name_se": "2-3",
        "name_el": "2-3",
        "name_es": "2-3",
        "name_hr": "2-3",
        "name_fr": "2-3"
    },
    "60070000^prematch": {
        "name_en": "5+/4+",
        "name_aa": "5+/4+",
        "name_de": "5+/4+",
        "name_nl": "5+/4+",
        "name_pl": "5+/4+",
        "name_ro": "5+/4+",
        "name_ru": "5+/4+",
        "name_tr": "5+/4+",
        "name_se": "5+/4+",
        "name_el": "5+/4+",
        "name_es": "5+/4+",
        "name_hr": "5+/4+",
        "name_fr": "5+/4+"
    },
    "34^prematch": {
        "name_en": "3",
        "name_aa": "3",
        "name_de": "3",
        "name_nl": "3",
        "name_pl": "3",
        "name_ro": "3",
        "name_ru": "3",
        "name_tr": "3",
        "name_se": "3",
        "name_el": "3",
        "name_es": "3",
        "name_hr": "3",
        "name_fr": "3"
    },
    "258^prematch": {
        "name_en": "8:9",
        "name_aa": "8:9",
        "name_de": "8:9",
        "name_nl": "8:9",
        "name_pl": "8:9",
        "name_ro": "8:9",
        "name_ru": "8:9",
        "name_tr": "8:9",
        "name_se": "8:9",
        "name_el": "8:9",
        "name_es": "8:9",
        "name_hr": "8:9",
        "name_fr": "8:9"
    },
    "1497^prematch": {
        "name_en": "4:3",
        "name_aa": "4:3",
        "name_de": "4:3",
        "name_nl": "4:3",
        "name_pl": "4:3",
        "name_ro": "4:3",
        "name_ru": "4:3",
        "name_tr": "4:3",
        "name_se": "4:3",
        "name_el": "4:3",
        "name_es": "4:3",
        "name_hr": "4:3",
        "name_fr": "4:3"
    },
    "254^prematch": {
        "name_en": "9:5",
        "name_aa": "9:5",
        "name_de": "9:5",
        "name_nl": "9:5",
        "name_pl": "9:5",
        "name_ro": "9:5",
        "name_ru": "9:5",
        "name_tr": "9:5",
        "name_se": "9:5",
        "name_el": "9:5",
        "name_es": "9:5",
        "name_hr": "9:5",
        "name_fr": "9:5"
    },
    "1488^prematch": {
        "name_en": "1:2",
        "name_aa": "1:2",
        "name_de": "1:2",
        "name_nl": "1:2",
        "name_pl": "1:2",
        "name_ro": "1:2",
        "name_ru": "1:2",
        "name_tr": "1:2",
        "name_se": "1:2",
        "name_el": "1:2",
        "name_es": "1:2",
        "name_hr": "1:2",
        "name_fr": "1:2"
    },
    "965^prematch": {
        "name_en": "4",
        "name_aa": "4",
        "name_de": "4",
        "name_nl": "4",
        "name_pl": "4",
        "name_ro": "4",
        "name_ru": "4",
        "name_tr": "4",
        "name_se": "4",
        "name_el": "4",
        "name_es": "4",
        "name_hr": "4",
        "name_fr": "4"
    },
    "865^prematch": {
        "name_en": "6:0",
        "name_aa": "6:0",
        "name_de": "6:0",
        "name_nl": "6:0",
        "name_pl": "6:0",
        "name_ro": "6:0",
        "name_ru": "6:0",
        "name_tr": "6:0",
        "name_se": "6:0",
        "name_el": "6:0",
        "name_es": "6:0",
        "name_hr": "6:0",
        "name_fr": "6:0"
    },
    "1250^prematch": {
        "name_en": "85-94",
        "name_aa": "85-94",
        "name_de": "85-94",
        "name_nl": "85-94",
        "name_pl": "85-94",
        "name_ro": "85-94",
        "name_ru": "85-94",
        "name_tr": "85-94",
        "name_se": "85-94",
        "name_el": "85-94",
        "name_es": "85-94",
        "name_hr": "85-94",
        "name_fr": "85-94"
    },
    "194^prematch": {
        "name_en": "7:2",
        "name_aa": "7:2",
        "name_de": "7:2",
        "name_nl": "7:2",
        "name_pl": "7:2",
        "name_ro": "7:2",
        "name_ru": "7:2",
        "name_tr": "7:2",
        "name_se": "7:2",
        "name_el": "7:2",
        "name_es": "7:2",
        "name_hr": "7:2",
        "name_fr": "7:2"
    },
    "388^prematch": {
        "name_en": "1:1 1:1",
        "name_aa": "1:1 1:1",
        "name_de": "1:1 1:1",
        "name_nl": "1:1 1:1",
        "name_pl": "1:1 1:1",
        "name_ro": "1:1 1:1",
        "name_ru": "1:1 1:1",
        "name_tr": "1:1 1:1",
        "name_se": "1:1 1:1",
        "name_el": "1:1 1:1",
        "name_es": "1:1 1:1",
        "name_hr": "1:1 1:1",
        "name_fr": "1:1 1:1"
    },
    "230^prematch": {
        "name_en": "2:7",
        "name_aa": "2:7",
        "name_de": "2:7",
        "name_nl": "2:7",
        "name_pl": "2:7",
        "name_ro": "2:7",
        "name_ru": "2:7",
        "name_tr": "2:7",
        "name_se": "2:7",
        "name_el": "2:7",
        "name_es": "2:7",
        "name_hr": "2:7",
        "name_fr": "2:7"
    },
    "312^prematch": {
        "name_en": "4:3",
        "name_aa": "4:3",
        "name_de": "4:3",
        "name_nl": "4:3",
        "name_pl": "4:3",
        "name_ro": "4:3",
        "name_ru": "4:3",
        "name_tr": "4:3",
        "name_se": "4:3",
        "name_el": "4:3",
        "name_es": "4:3",
        "name_hr": "4:3",
        "name_fr": "4:3"
    },
    "251^prematch": {
        "name_en": "9:2",
        "name_aa": "9:2",
        "name_de": "9:2",
        "name_nl": "9:2",
        "name_pl": "9:2",
        "name_ro": "9:2",
        "name_ru": "9:2",
        "name_tr": "9:2",
        "name_se": "9:2",
        "name_el": "9:2",
        "name_es": "9:2",
        "name_hr": "9:2",
        "name_fr": "9:2"
    },
    "263^prematch": {
        "name_en": "3:9",
        "name_aa": "3:9",
        "name_de": "3:9",
        "name_nl": "3:9",
        "name_pl": "3:9",
        "name_ro": "3:9",
        "name_ru": "3:9",
        "name_tr": "3:9",
        "name_se": "3:9",
        "name_el": "3:9",
        "name_es": "3:9",
        "name_hr": "3:9",
        "name_fr": "3:9"
    },
    "1258^prematch": {
        "name_en": "45-49",
        "name_aa": "45-49",
        "name_de": "45-49",
        "name_nl": "45-49",
        "name_pl": "45-49",
        "name_ro": "45-49",
        "name_ru": "45-49",
        "name_tr": "45-49",
        "name_se": "45-49",
        "name_el": "45-49",
        "name_es": "45-49",
        "name_hr": "45-49",
        "name_fr": "45-49"
    },
    "552^prematch": {
        "name_en": "1:0",
        "name_aa": "1:0",
        "name_de": "1:0",
        "name_nl": "1:0",
        "name_pl": "1:0",
        "name_ro": "1:0",
        "name_ru": "1:0",
        "name_tr": "1:0",
        "name_se": "1:0",
        "name_el": "1:0",
        "name_es": "1:0",
        "name_hr": "1:0",
        "name_fr": "1:0"
    },
    "1697240000^prematch": {
        "name_en": "Under",
        "name_aa": "Under",
        "name_de": "Under",
        "name_nl": "Under",
        "name_pl": "Under",
        "name_ro": "Under",
        "name_ru": "Under",
        "name_tr": "Under",
        "name_se": "Under",
        "name_el": "Under",
        "name_es": "Under",
        "name_hr": "Under",
        "name_fr": "Under"
    },
    "1185^prematch": {
        "name_en": "4:2",
        "name_aa": "4:2",
        "name_de": "4:2",
        "name_nl": "4:2",
        "name_pl": "4:2",
        "name_ro": "4:2",
        "name_ru": "4:2",
        "name_tr": "4:2",
        "name_se": "4:2",
        "name_el": "4:2",
        "name_es": "4:2",
        "name_hr": "4:2",
        "name_fr": "4:2"
    },
    "1712^prematch": {
        "name_en": "draw ({hcp})",
        "name_aa": "draw ({hcp})",
        "name_de": "unentschieden ({hcp})",
        "name_nl": "gelijkspel ({hcp})",
        "name_pl": "draw ({hcp})",
        "name_ro": "draw ({hcp})",
        "name_ru": "draw ({hcp})",
        "name_tr": "berabere ({hcp})",
        "name_se": "draw ({hcp})",
        "name_el": "draw ({hcp})",
        "name_es": "draw ({hcp})",
        "name_hr": "neodlučeno ({hcp})",
        "name_fr": "Match nul ({hcp})"
    },
    "328^prematch": {
        "name_en": "0:0 0:1",
        "name_aa": "0:0 0:1",
        "name_de": "0:0 0:1",
        "name_nl": "0:0 0:1",
        "name_pl": "0:0 0:1",
        "name_ro": "0:0 0:1",
        "name_ru": "0:0 0:1",
        "name_tr": "0:0 0:1",
        "name_se": "0:0 0:1",
        "name_el": "0:0 0:1",
        "name_es": "0:0 0:1",
        "name_hr": "0:0 0:1",
        "name_fr": "0:0 0:1"
    },
    "1159^prematch": {
        "name_en": "0:2",
        "name_aa": "0:2",
        "name_de": "0:2",
        "name_nl": "0:2",
        "name_pl": "0:2",
        "name_ro": "0:2",
        "name_ru": "0:2",
        "name_tr": "0:2",
        "name_se": "0:2",
        "name_el": "0:2",
        "name_es": "0:2",
        "name_hr": "0:2",
        "name_fr": "0:2"
    },
    "70960000^prematch": {
        "name_en": "Neither",
        "name_aa": "Neither",
        "name_de": "Neither",
        "name_nl": "Neither",
        "name_pl": "Neither",
        "name_ro": "Neither",
        "name_ru": "Neither",
        "name_tr": "Neither",
        "name_se": "Neither",
        "name_el": "Neither",
        "name_es": "Neither",
        "name_hr": "Neither",
        "name_fr": "Neither"
    },
    "257^prematch": {
        "name_en": "9:8",
        "name_aa": "9:8",
        "name_de": "9:8",
        "name_nl": "9:8",
        "name_pl": "9:8",
        "name_ro": "9:8",
        "name_ru": "9:8",
        "name_tr": "9:8",
        "name_se": "9:8",
        "name_el": "9:8",
        "name_es": "9:8",
        "name_hr": "9:8",
        "name_fr": "9:8"
    },
    "921^prematch": {
        "name_en": "2nd quarter",
        "name_aa": "2nd quarter",
        "name_de": "2. Viertel",
        "name_nl": "Tweede kwart",
        "name_pl": "2nd quarter",
        "name_ro": "2nd quarter",
        "name_ru": "2nd quarter",
        "name_tr": "İkinci çeyrek",
        "name_se": "2nd quarter",
        "name_el": "2nd quarter",
        "name_es": "2nd quarter",
        "name_hr": "2. četvrtina",
        "name_fr": "2ème quart-temps"
    },
    "283^prematch": {
        "name_en": "3:10",
        "name_aa": "3:10",
        "name_de": "3:10",
        "name_nl": "3:10",
        "name_pl": "3:10",
        "name_ro": "3:10",
        "name_ru": "3:10",
        "name_tr": "3:10",
        "name_se": "3:10",
        "name_el": "3:10",
        "name_es": "3:10",
        "name_hr": "3:10",
        "name_fr": "3:10"
    },
    "712^prematch": {
        "name_en": "4",
        "name_aa": "4",
        "name_de": "4",
        "name_nl": "4",
        "name_pl": "4",
        "name_ro": "4",
        "name_ru": "4",
        "name_tr": "4",
        "name_se": "4",
        "name_el": "4",
        "name_es": "4",
        "name_hr": "4",
        "name_fr": "4"
    },
    "950^prematch": {
        "name_en": "{$competitor2}",
        "name_aa": "{$competitor2}",
        "name_de": "{$competitor2}",
        "name_nl": "{$competitor2}",
        "name_pl": "{$competitor2}",
        "name_ro": "{$competitor2}",
        "name_ru": "{$competitor2}",
        "name_tr": "{$competitor2}",
        "name_se": "{$competitor2}",
        "name_el": "{$competitor2}",
        "name_es": "{$competitor2}",
        "name_hr": "{$competitor2}",
        "name_fr": "{$competitor2}"
    },
    "267^prematch": {
        "name_en": "10:0",
        "name_aa": "10:0",
        "name_de": "10:0",
        "name_nl": "10:0",
        "name_pl": "10:0",
        "name_ro": "10:0",
        "name_ru": "10:0",
        "name_tr": "10:0",
        "name_se": "10:0",
        "name_el": "10:0",
        "name_es": "10:0",
        "name_hr": "10:0",
        "name_fr": "10:0"
    },
    "1097^prematch": {
        "name_en": "28-34",
        "name_aa": "28-34",
        "name_de": "28-34",
        "name_nl": "28-34",
        "name_pl": "28-34",
        "name_ro": "28-34",
        "name_ru": "28-34",
        "name_tr": "28-34",
        "name_se": "28-34",
        "name_el": "28-34",
        "name_es": "28-34",
        "name_hr": "28-34",
        "name_fr": "28-34"
    },
    "1621^prematch": {
        "name_en": "1st period",
        "name_aa": "1st period",
        "name_de": "1. Periode",
        "name_nl": "1e periode",
        "name_pl": "1st period",
        "name_ro": "1st period",
        "name_ru": "1st period",
        "name_tr": "İlk periyot",
        "name_se": "1st period",
        "name_el": "1st period",
        "name_es": "1st period",
        "name_hr": "1. period",
        "name_fr": "1ère période"
    },
    "1694030000^prematch": {
        "name_en": "Over 7.5",
        "name_aa": "Over 7.5",
        "name_de": "Over 7.5",
        "name_nl": "Over 7.5",
        "name_pl": "Over 7.5",
        "name_ro": "Over 7.5",
        "name_ru": "Over 7.5",
        "name_tr": "Over 7.5",
        "name_se": "Over 7.5",
        "name_el": "Over 7.5",
        "name_es": "Over 7.5",
        "name_hr": "Over 7.5",
        "name_fr": "Over 7.5"
    },
    "304^prematch": {
        "name_en": "4:11",
        "name_aa": "4:11",
        "name_de": "4:11",
        "name_nl": "4:11",
        "name_pl": "4:11",
        "name_ro": "4:11",
        "name_ru": "4:11",
        "name_tr": "4:11",
        "name_se": "4:11",
        "name_el": "4:11",
        "name_es": "4:11",
        "name_hr": "4:11",
        "name_fr": "4:11"
    },
    "1694700000^prematch": {
        "name_en": "Yes",
        "name_aa": "Yes",
        "name_de": "Yes",
        "name_nl": "Yes",
        "name_pl": "Yes",
        "name_ro": "Yes",
        "name_ru": "Yes",
        "name_tr": "Yes",
        "name_se": "Yes",
        "name_el": "Yes",
        "name_es": "Yes",
        "name_hr": "Yes",
        "name_fr": "Yes"
    },
    "80^prematch": {
        "name_en": "{$competitor1} & no",
        "name_aa": "{$competitor1} & no",
        "name_de": "{$competitor1} & nein",
        "name_nl": "{$competitor1} & nee",
        "name_pl": "{$competitor1} & no",
        "name_ro": "{$competitor1} & no",
        "name_ru": "{$competitor1} & no",
        "name_tr": "{$competitor1} & yok",
        "name_se": "{$competitor1} & no",
        "name_el": "{$competitor1} & no",
        "name_es": "{$competitor1} & no",
        "name_hr": "{$competitor1} i ne",
        "name_fr": "{$competitor1} & non"
    },
    "128^prematch": {
        "name_en": "{$competitor2} by 6-10",
        "name_aa": "{$competitor2} by 6-10",
        "name_de": "{$competitor2} by 6-10",
        "name_nl": "{$competitor2} by 6-10",
        "name_pl": "{$competitor2} by 6-10",
        "name_ro": "{$competitor2} by 6-10",
        "name_ru": "{$competitor2} by 6-10",
        "name_tr": "{$competitor2} by 6-10",
        "name_se": "{$competitor2} by 6-10",
        "name_el": "{$competitor2} by 6-10",
        "name_es": "{$competitor2} by 6-10",
        "name_hr": "{$competitor2} by 6-10",
        "name_fr": "{$competitor2} by 6-10"
    },
    "11^prematch": {
        "name_en": "draw or {$competitor2}",
        "name_aa": "draw or {$competitor2}",
        "name_de": "unentschieden oder {$competitor2}",
        "name_nl": "gelijkspel of {$competitor2}",
        "name_pl": "draw or {$competitor2}",
        "name_ro": "draw or {$competitor2}",
        "name_ru": "draw or {$competitor2}",
        "name_tr": "beraberlik veya {$competitor2}",
        "name_se": "draw or {$competitor2}",
        "name_el": "draw or {$competitor2}",
        "name_es": "draw or {$competitor2}",
        "name_hr": "neodlučeno ili {$competitor2}",
        "name_fr": "Match nul ou {$competitor2}"
    },
    "1161^prematch": {
        "name_en": "0:4",
        "name_aa": "0:4",
        "name_de": "0:4",
        "name_nl": "0:4",
        "name_pl": "0:4",
        "name_ro": "0:4",
        "name_ru": "0:4",
        "name_tr": "0:4",
        "name_se": "0:4",
        "name_el": "0:4",
        "name_es": "0:4",
        "name_hr": "0:4",
        "name_fr": "0:4"
    },
    "1165^prematch": {
        "name_en": "1:0",
        "name_aa": "1:0",
        "name_de": "1:0",
        "name_nl": "1:0",
        "name_pl": "1:0",
        "name_ro": "1:0",
        "name_ru": "1:0",
        "name_tr": "1:0",
        "name_se": "1:0",
        "name_el": "1:0",
        "name_es": "1:0",
        "name_hr": "1:0",
        "name_fr": "1:0"
    },
    "588^prematch": {
        "name_en": "31-45",
        "name_aa": "31-45",
        "name_de": "31-45",
        "name_nl": "31-45",
        "name_pl": "31-45",
        "name_ro": "31-45",
        "name_ru": "31-45",
        "name_tr": "31-45",
        "name_se": "31-45",
        "name_el": "31-45",
        "name_es": "31-45",
        "name_hr": "31-45",
        "name_fr": "31-45"
    },
    "408^prematch": {
        "name_en": "2:1 2:1",
        "name_aa": "2:1 2:1",
        "name_de": "2:1 2:1",
        "name_nl": "2:1 2:1",
        "name_pl": "2:1 2:1",
        "name_ro": "2:1 2:1",
        "name_ru": "2:1 2:1",
        "name_tr": "2:1 2:1",
        "name_se": "2:1 2:1",
        "name_el": "2:1 2:1",
        "name_es": "2:1 2:1",
        "name_hr": "2:1 2:1",
        "name_fr": "2:1 2:1"
    },
    "1856^prematch": {
        "name_en": "draw/{$competitor1} & 1",
        "name_aa": "draw/{$competitor1} & 1",
        "name_de": "unentschieden/{$competitor1} & 1",
        "name_nl": "gelijkspel/{$competitor1} & 1",
        "name_pl": "draw/{$competitor1} & 1",
        "name_ro": "draw/{$competitor1} & 1",
        "name_ru": "draw/{$competitor1} & 1",
        "name_tr": "beraberlik/{$competitor1} & 1",
        "name_se": "draw/{$competitor1} & 1",
        "name_el": "draw/{$competitor1} & 1",
        "name_es": "draw/{$competitor1} & 1",
        "name_hr": "neodlučeno/{$competitor1} i 1",
        "name_fr": "Match nul/{$competitor1} & 1"
    },
    "426^prematch": {
        "name_en": "draw/draw",
        "name_aa": "draw/draw",
        "name_de": "unentschieden / unentschieden",
        "name_nl": "gelijkspel/gelijkspel",
        "name_pl": "draw/draw",
        "name_ro": "draw/draw",
        "name_ru": "draw/draw",
        "name_tr": "beraberlik/beraberlik",
        "name_se": "draw/draw",
        "name_el": "draw/draw",
        "name_es": "draw/draw",
        "name_hr": "neodlučeno/neodlučeno",
        "name_fr": "Match nul/Match nul"
    },
    "1294^prematch": {
        "name_en": "{$competitor2} by 26+",
        "name_aa": "{$competitor2} by 26+",
        "name_de": "{$competitor2} by 26+",
        "name_nl": "{$competitor2} by 26+",
        "name_pl": "{$competitor2} by 26+",
        "name_ro": "{$competitor2} by 26+",
        "name_ru": "{$competitor2} by 26+",
        "name_tr": "{$competitor2} by 26+",
        "name_se": "{$competitor2} by 26+",
        "name_el": "{$competitor2} by 26+",
        "name_es": "{$competitor2} by 26+",
        "name_hr": "{$competitor2} by 26+",
        "name_fr": "{$competitor2} by 26+"
    },
    "420^prematch": {
        "name_en": "{$competitor1}/draw",
        "name_aa": "{$competitor1}/draw",
        "name_de": "{$competitor1}/unentschieden",
        "name_nl": "{$competitor1}/gelijkspel",
        "name_pl": "{$competitor1}/draw",
        "name_ro": "{$competitor1}/draw",
        "name_ru": "{$competitor1}/draw",
        "name_tr": "{$competitor1}/beraberlik",
        "name_se": "{$competitor1}/draw",
        "name_el": "{$competitor1}/draw",
        "name_es": "{$competitor1}/draw",
        "name_hr": "{$competitor1}/neodlučeno",
        "name_fr": "{$competitor1}/Match nul"
    },
    "594^prematch": {
        "name_en": "76-90",
        "name_aa": "76-90",
        "name_de": "76-90",
        "name_nl": "76-90",
        "name_pl": "76-90",
        "name_ro": "76-90",
        "name_ru": "76-90",
        "name_tr": "76-90",
        "name_se": "76-90",
        "name_el": "76-90",
        "name_es": "76-90",
        "name_hr": "76-90",
        "name_fr": "76-90"
    },
    "294^prematch": {
        "name_en": "11:7",
        "name_aa": "11:7",
        "name_de": "11:7",
        "name_nl": "11:7",
        "name_pl": "11:7",
        "name_ro": "11:7",
        "name_ru": "11:7",
        "name_tr": "11:7",
        "name_se": "11:7",
        "name_el": "11:7",
        "name_es": "11:7",
        "name_hr": "11:7",
        "name_fr": "11:7"
    },
    "1694690000^prematch": {
        "name_en": "No",
        "name_aa": "No",
        "name_de": "No",
        "name_nl": "No",
        "name_pl": "No",
        "name_ro": "No",
        "name_ru": "No",
        "name_tr": "No",
        "name_se": "No",
        "name_el": "No",
        "name_es": "No",
        "name_hr": "No",
        "name_fr": "No"
    },
    "1498^prematch": {
        "name_en": "5:3",
        "name_aa": "5:3",
        "name_de": "5:3",
        "name_nl": "5:3",
        "name_pl": "5:3",
        "name_ro": "5:3",
        "name_ru": "5:3",
        "name_tr": "5:3",
        "name_se": "5:3",
        "name_el": "5:3",
        "name_es": "5:3",
        "name_hr": "5:3",
        "name_fr": "5:3"
    },
    "1141^prematch": {
        "name_en": "0-8",
        "name_aa": "0-8",
        "name_de": "0-8",
        "name_nl": "0-8",
        "name_pl": "0-8",
        "name_ro": "0-8",
        "name_ru": "0-8",
        "name_tr": "0-8",
        "name_se": "0-8",
        "name_el": "0-8",
        "name_es": "0-8",
        "name_hr": "0-8",
        "name_fr": "0-8"
    },
    "1670000^prematch": {
        "name_en": "{$competitor2}",
        "name_aa": "{$competitor2}",
        "name_de": "{$competitor2}",
        "name_nl": "{$competitor2}",
        "name_pl": "{$competitor2}",
        "name_ro": "{$competitor2}",
        "name_ru": "{$competitor2}",
        "name_tr": "{$competitor2}",
        "name_se": "{$competitor2}",
        "name_el": "{$competitor2}",
        "name_es": "{$competitor2}",
        "name_hr": "{$competitor2}",
        "name_fr": "{$competitor2}"
    },
    "939^prematch": {
        "name_en": "under {total}",
        "name_aa": "under {total}",
        "name_de": "unter {total}",
        "name_nl": "onder {total}",
        "name_pl": "under {total}",
        "name_ro": "under {total}",
        "name_ru": "under {total}",
        "name_tr": "{total} altında",
        "name_se": "under {total}",
        "name_el": "under {total}",
        "name_es": "under {total}",
        "name_hr": "manje od {total}",
        "name_fr": "moins de {total}"
    },
    "282^prematch": {
        "name_en": "4:10",
        "name_aa": "4:10",
        "name_de": "4:10",
        "name_nl": "4:10",
        "name_pl": "4:10",
        "name_ro": "4:10",
        "name_ru": "4:10",
        "name_tr": "4:10",
        "name_se": "4:10",
        "name_el": "4:10",
        "name_es": "4:10",
        "name_hr": "4:10",
        "name_fr": "4:10"
    },
    "1087^prematch": {
        "name_en": "21-25",
        "name_aa": "21-25",
        "name_de": "21-25",
        "name_nl": "21-25",
        "name_pl": "21-25",
        "name_ro": "21-25",
        "name_ru": "21-25",
        "name_tr": "21-25",
        "name_se": "21-25",
        "name_el": "21-25",
        "name_es": "21-25",
        "name_hr": "21-25",
        "name_fr": "21-25"
    },
    "1293^prematch": {
        "name_en": "{$competitor2} by 21-25",
        "name_aa": "{$competitor2} by 21-25",
        "name_de": "{$competitor2} by 21-25",
        "name_nl": "{$competitor2} by 21-25",
        "name_pl": "{$competitor2} by 21-25",
        "name_ro": "{$competitor2} by 21-25",
        "name_ru": "{$competitor2} by 21-25",
        "name_tr": "{$competitor2} by 21-25",
        "name_se": "{$competitor2} by 21-25",
        "name_el": "{$competitor2} by 21-25",
        "name_es": "{$competitor2} by 21-25",
        "name_hr": "{$competitor2} by 21-25",
        "name_fr": "{$competitor2} by 21-25"
    },
    "1824^prematch": {
        "name_en": "bottom of 9th inning",
        "name_aa": "bottom of 9th inning",
        "name_de": "Ende des 9. Innings",
        "name_nl": "tweede helft 9e inning",
        "name_pl": "bottom of 9th inning",
        "name_ro": "bottom of 9th inning",
        "name_ru": "bottom of 9th inning",
        "name_tr": "9. devrenin başında",
        "name_se": "bottom of 9th inning",
        "name_el": "bottom of 9th inning",
        "name_es": "bottom of 9th inning",
        "name_hr": "na početku 9. inninga",
        "name_fr": "bas de la 9ème manche"
    },
    "1722^prematch": {
        "name_en": "draw/{$competitor2} & yes",
        "name_aa": "draw/{$competitor2} & yes",
        "name_de": "unentschieden/{$competitor2} & ja",
        "name_nl": "gelijkspel/{$competitor2} & ja",
        "name_pl": "draw/{$competitor2} & yes",
        "name_ro": "draw/{$competitor2} & yes",
        "name_ru": "draw/{$competitor2} & yes",
        "name_tr": "beraberlik/{$competitor2} & var",
        "name_se": "draw/{$competitor2} & yes",
        "name_el": "draw/{$competitor2} & yes",
        "name_es": "draw/{$competitor2} & yes",
        "name_hr": "neodlučeno/{$competitor2} i da",
        "name_fr": "Match nul/{$competitor2} & oui"
    },
    "1143^prematch": {
        "name_en": "12+",
        "name_aa": "12+",
        "name_de": "12+",
        "name_nl": "12+",
        "name_pl": "12+",
        "name_ro": "12+",
        "name_ru": "12+",
        "name_tr": "12+",
        "name_se": "12+",
        "name_el": "12+",
        "name_es": "12+",
        "name_hr": "12+",
        "name_fr": "12+"
    },
    "60120000^prematch": {
        "name_en": "6+/7+",
        "name_aa": "6+/7+",
        "name_de": "6+/7+",
        "name_nl": "6+/7+",
        "name_pl": "6+/7+",
        "name_ro": "6+/7+",
        "name_ru": "6+/7+",
        "name_tr": "6+/7+",
        "name_se": "6+/7+",
        "name_el": "6+/7+",
        "name_es": "6+/7+",
        "name_hr": "6+/7+",
        "name_fr": "6+/7+"
    },
    "1725^prematch": {
        "name_en": "{$competitor1}/{$competitor2} & under {total}",
        "name_aa": "{$competitor1}/{$competitor2} & under {total}",
        "name_de": "{$competitor1}/{$competitor2} & unter {total}",
        "name_nl": "{$competitor1}/{$competitor2} & onder {total}",
        "name_pl": "{$competitor1}/{$competitor2} & under {total}",
        "name_ro": "{$competitor1}/{$competitor2} & under {total}",
        "name_ru": "{$competitor1}/{$competitor2} & under {total}",
        "name_tr": "{$competitor1}/{$competitor2} & {total} altı",
        "name_se": "{$competitor1}/{$competitor2} & under {total}",
        "name_el": "{$competitor1}/{$competitor2} & under {total}",
        "name_es": "{$competitor1}/{$competitor2} & under {total}",
        "name_hr": "{$competitor1}/{$competitor2} i manje {total}",
        "name_fr": "{$competitor1}/{$competitor2} & moins de {total}"
    },
    "1570000^prematch": {
        "name_en": "No Goal before 29:00 min.",
        "name_aa": "No Goal before 29:00 min.",
        "name_de": "No Goal before 29:00 min.",
        "name_nl": "No Goal before 29:00 min.",
        "name_pl": "No Goal before 29:00 min.",
        "name_ro": "No Goal before 29:00 min.",
        "name_ru": "No Goal before 29:00 min.",
        "name_tr": "No Goal before 29:00 min.",
        "name_se": "No Goal before 29:00 min.",
        "name_el": "No Goal before 29:00 min.",
        "name_es": "No Goal before 29:00 min.",
        "name_hr": "No Goal before 29:00 min.",
        "name_fr": "No Goal before 29:00 min."
    },
    "1874^prematch": {
        "name_en": "{$competitor1}/{$competitor2} & 4",
        "name_aa": "{$competitor1}/{$competitor2} & 4",
        "name_de": "{$competitor1}/{$competitor2} & 4",
        "name_nl": "{$competitor1}/{$competitor2} & 4",
        "name_pl": "{$competitor1}/{$competitor2} & 4",
        "name_ro": "{$competitor1}/{$competitor2} & 4",
        "name_ru": "{$competitor1}/{$competitor2} & 4",
        "name_tr": "{$competitor1}/{$competitor2} & 4",
        "name_se": "{$competitor1}/{$competitor2} & 4",
        "name_el": "{$competitor1}/{$competitor2} & 4",
        "name_es": "{$competitor1}/{$competitor2} & 4",
        "name_hr": "{$competitor1}/{$competitor2} i 4",
        "name_fr": "{$competitor1}/{$competitor2} & 4"
    },
    "47^prematch": {
        "name_en": "7",
        "name_aa": "7",
        "name_de": "7",
        "name_nl": "7",
        "name_pl": "7",
        "name_ro": "7",
        "name_ru": "7",
        "name_tr": "7",
        "name_se": "7",
        "name_el": "7",
        "name_es": "7",
        "name_hr": "7",
        "name_fr": "7"
    },
    "924^prematch": {
        "name_en": "equal",
        "name_aa": "equal",
        "name_de": "beide gleich",
        "name_nl": "gelijk",
        "name_pl": "equal",
        "name_ro": "equal",
        "name_ru": "equal",
        "name_tr": "eşit",
        "name_se": "equal",
        "name_el": "equal",
        "name_es": "equal",
        "name_hr": "jednako",
        "name_fr": "égal"
    },
    "962^prematch": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "259^prematch": {
        "name_en": "7:9",
        "name_aa": "7:9",
        "name_de": "7:9",
        "name_nl": "7:9",
        "name_pl": "7:9",
        "name_ro": "7:9",
        "name_ru": "7:9",
        "name_tr": "7:9",
        "name_se": "7:9",
        "name_el": "7:9",
        "name_es": "7:9",
        "name_hr": "7:9",
        "name_fr": "7:9"
    },
    "70940000^prematch": {
        "name_en": "{$competitor2}",
        "name_aa": "{$competitor2}",
        "name_de": "{$competitor2}",
        "name_nl": "{$competitor2}",
        "name_pl": "{$competitor2}",
        "name_ro": "{$competitor2}",
        "name_ru": "{$competitor2}",
        "name_tr": "{$competitor2}",
        "name_se": "{$competitor2}",
        "name_el": "{$competitor2}",
        "name_es": "{$competitor2}",
        "name_hr": "{$competitor2}",
        "name_fr": "{$competitor2}"
    },
    "1348^prematch": {
        "name_en": "{$competitor1} by decision",
        "name_aa": "{$competitor1} by decision",
        "name_de": "{$competitor1} by decision",
        "name_nl": "{$competitor1} by decision",
        "name_pl": "{$competitor1} by decision",
        "name_ro": "{$competitor1} by decision",
        "name_ru": "{$competitor1} by decision",
        "name_tr": "{$competitor1} by decision",
        "name_se": "{$competitor1} by decision",
        "name_el": "{$competitor1} by decision",
        "name_es": "{$competitor1} by decision",
        "name_hr": "{$competitor1} by decision",
        "name_fr": "{$competitor1} by decision"
    },
    "248^prematch": {
        "name_en": "0:8",
        "name_aa": "0:8",
        "name_de": "0:8",
        "name_nl": "0:8",
        "name_pl": "0:8",
        "name_ro": "0:8",
        "name_ru": "0:8",
        "name_tr": "0:8",
        "name_se": "0:8",
        "name_el": "0:8",
        "name_es": "0:8",
        "name_hr": "0:8",
        "name_fr": "0:8"
    },
    "58^prematch": {
        "name_en": "5",
        "name_aa": "5",
        "name_de": "5",
        "name_nl": "5",
        "name_pl": "5",
        "name_ro": "5",
        "name_ru": "5",
        "name_tr": "5",
        "name_se": "5",
        "name_el": "5",
        "name_es": "5",
        "name_hr": "5",
        "name_fr": "5"
    },
    "384^prematch": {
        "name_en": "1:0 3:0",
        "name_aa": "1:0 3:0",
        "name_de": "1:0 3:0",
        "name_nl": "1:0 3:0",
        "name_pl": "1:0 3:0",
        "name_ro": "1:0 3:0",
        "name_ru": "1:0 3:0",
        "name_tr": "1:0 3:0",
        "name_se": "1:0 3:0",
        "name_el": "1:0 3:0",
        "name_es": "1:0 3:0",
        "name_hr": "1:0 3:0",
        "name_fr": "1:0 3:0"
    },
    "1880^prematch": {
        "name_en": "{$competitor2}/{$competitor2} & 4",
        "name_aa": "{$competitor2}/{$competitor2} & 4",
        "name_de": "{$competitor2}/{$competitor2} & 4",
        "name_nl": "{$competitor2}/{$competitor2} & 4",
        "name_pl": "{$competitor2}/{$competitor2} & 4",
        "name_ro": "{$competitor2}/{$competitor2} & 4",
        "name_ru": "{$competitor2}/{$competitor2} & 4",
        "name_tr": "{$competitor2}/{$competitor2} & 4",
        "name_se": "{$competitor2}/{$competitor2} & 4",
        "name_el": "{$competitor2}/{$competitor2} & 4",
        "name_es": "{$competitor2}/{$competitor2} & 4",
        "name_hr": "{$competitor2}/{$competitor2} i 4",
        "name_fr": "{$competitor2}/{$competitor2} & 4"
    },
    "137^prematch": {
        "name_en": "{$competitor1} by 11+",
        "name_aa": "{$competitor1} by 11+",
        "name_de": "{$competitor1} by 11+",
        "name_nl": "{$competitor1} by 11+",
        "name_pl": "{$competitor1} by 11+",
        "name_ro": "{$competitor1} by 11+",
        "name_ru": "{$competitor1} by 11+",
        "name_tr": "{$competitor1} by 11+",
        "name_se": "{$competitor1} by 11+",
        "name_el": "{$competitor1} by 11+",
        "name_es": "{$competitor1} by 11+",
        "name_hr": "{$competitor1} by 11+",
        "name_fr": "{$competitor1} by 11+"
    },
    "156^prematch": {
        "name_en": "63+",
        "name_aa": "63+",
        "name_de": "63+",
        "name_nl": "63+",
        "name_pl": "63+",
        "name_ro": "63+",
        "name_ru": "63+",
        "name_tr": "63+",
        "name_se": "63+",
        "name_el": "63+",
        "name_es": "63+",
        "name_hr": "63+",
        "name_fr": "63+"
    },
    "1194^prematch": {
        "name_en": "2:0",
        "name_aa": "2:0",
        "name_de": "2:0",
        "name_nl": "2:0",
        "name_pl": "2:0",
        "name_ro": "2:0",
        "name_ru": "2:0",
        "name_tr": "2:0",
        "name_se": "2:0",
        "name_el": "2:0",
        "name_es": "2:0",
        "name_hr": "2:0",
        "name_fr": "2:0"
    },
    "1354^prematch": {
        "name_en": "{$competitor1} & 2",
        "name_aa": "{$competitor1} & 2",
        "name_de": "{$competitor1} & 2",
        "name_nl": "{$competitor1} & 2",
        "name_pl": "{$competitor1} & 2",
        "name_ro": "{$competitor1} & 2",
        "name_ru": "{$competitor1} & 2",
        "name_tr": "{$competitor1} & 2",
        "name_se": "{$competitor1} & 2",
        "name_el": "{$competitor1} & 2",
        "name_es": "{$competitor1} & 2",
        "name_hr": "{$competitor1} & 2",
        "name_fr": "{$competitor1} & 2"
    },
    "1157^prematch": {
        "name_en": "0:0",
        "name_aa": "0:0",
        "name_de": "0:0",
        "name_nl": "0:0",
        "name_pl": "0:0",
        "name_ro": "0:0",
        "name_ru": "0:0",
        "name_tr": "0:0",
        "name_se": "0:0",
        "name_el": "0:0",
        "name_es": "0:0",
        "name_hr": "0:0",
        "name_fr": "0:0"
    },
    "822^prematch": {
        "name_en": "{$competitor2} goal & draw",
        "name_aa": "{$competitor2} goal & draw",
        "name_de": "{$competitor2} Tor & unentschieden",
        "name_nl": "doelpunt {$competitor2} & gelijkspel",
        "name_pl": "{$competitor2} goal & draw",
        "name_ro": "{$competitor2} goal & draw",
        "name_ru": "{$competitor2} goal & draw",
        "name_tr": "{$competitor2} golü & beraberlik",
        "name_se": "{$competitor2} goal & draw",
        "name_el": "{$competitor2} goal & draw",
        "name_es": "{$competitor2} goal & draw",
        "name_hr": "{$competitor2} gol i neodlučeno",
        "name_fr": "but {$competitor2} & Match nul"
    },
    "1879^prematch": {
        "name_en": "{$competitor2}/draw & 4",
        "name_aa": "{$competitor2}/draw & 4",
        "name_de": "{$competitor2}/unentschieden & 4",
        "name_nl": "{$competitor2}/gelijkspel & 4",
        "name_pl": "{$competitor2}/draw & 4",
        "name_ro": "{$competitor2}/draw & 4",
        "name_ru": "{$competitor2}/draw & 4",
        "name_tr": "{$competitor2}/beraberlik & 4",
        "name_se": "{$competitor2}/draw & 4",
        "name_el": "{$competitor2}/draw & 4",
        "name_es": "{$competitor2}/draw & 4",
        "name_hr": "{$competitor2}/neodlučeno i 4",
        "name_fr": "{$competitor2}/Match nul & 4"
    },
    "1042^prematch": {
        "name_en": "{$competitor2}",
        "name_aa": "{$competitor2}",
        "name_de": "{$competitor2}",
        "name_nl": "{$competitor2}",
        "name_pl": "{$competitor2}",
        "name_ro": "{$competitor2}",
        "name_ru": "{$competitor2}",
        "name_tr": "{$competitor2}",
        "name_se": "{$competitor2}",
        "name_el": "{$competitor2}",
        "name_es": "{$competitor2}",
        "name_hr": "{$competitor2}",
        "name_fr": "{$competitor2}"
    },
    "29^prematch": {
        "name_en": "2:5",
        "name_aa": "2:5",
        "name_de": "2:5",
        "name_nl": "2:5",
        "name_pl": "2:5",
        "name_ro": "2:5",
        "name_ru": "2:5",
        "name_tr": "2:5",
        "name_se": "2:5",
        "name_el": "2:5",
        "name_es": "2:5",
        "name_hr": "2:5",
        "name_fr": "2:5"
    },
    "1888^prematch": {
        "name_en": "{$competitor2}/draw & 5+",
        "name_aa": "{$competitor2}/draw & 5+",
        "name_de": "{$competitor2}/unentschieden & 5+",
        "name_nl": "{$competitor2}/gelijkspel & 5+",
        "name_pl": "{$competitor2}/draw & 5+",
        "name_ro": "{$competitor2}/draw & 5+",
        "name_ru": "{$competitor2}/draw & 5+",
        "name_tr": "{$competitor2}/beraberlik & 5+",
        "name_se": "{$competitor2}/draw & 5+",
        "name_el": "{$competitor2}/draw & 5+",
        "name_es": "{$competitor2}/draw & 5+",
        "name_hr": "{$competitor2}/neodlučeno i 5+",
        "name_fr": "{$competitor2}/Match nul & 5 ou +"
    },
    "718^prematch": {
        "name_en": "7",
        "name_aa": "7",
        "name_de": "7",
        "name_nl": "7",
        "name_pl": "7",
        "name_ro": "7",
        "name_ru": "7",
        "name_tr": "7",
        "name_se": "7",
        "name_el": "7",
        "name_es": "7",
        "name_hr": "7",
        "name_fr": "7"
    },
    "1854^prematch": {
        "name_en": "draw/draw & 0",
        "name_aa": "draw/draw & 0",
        "name_de": "unentschieden/unentschieden & 0",
        "name_nl": "gelijkspel/gelijkspel & 0",
        "name_pl": "draw/draw & 0",
        "name_ro": "draw/draw & 0",
        "name_ru": "draw/draw & 0",
        "name_tr": "beraberlik/beraberlik & 0",
        "name_se": "draw/draw & 0",
        "name_el": "draw/draw & 0",
        "name_es": "draw/draw & 0",
        "name_hr": "neodlučeno/neodlučeno i 0",
        "name_fr": "Match nul/Match nul & 0"
    },
    "154^prematch": {
        "name_en": "49-55",
        "name_aa": "49-55",
        "name_de": "49-55",
        "name_nl": "49-55",
        "name_pl": "49-55",
        "name_ro": "49-55",
        "name_ru": "49-55",
        "name_tr": "49-55",
        "name_se": "49-55",
        "name_el": "49-55",
        "name_es": "49-55",
        "name_hr": "49-55",
        "name_fr": "49-55"
    },
    "1244^prematch": {
        "name_en": "50-59",
        "name_aa": "50-59",
        "name_de": "50-59",
        "name_nl": "50-59",
        "name_pl": "50-59",
        "name_ro": "50-59",
        "name_ru": "50-59",
        "name_tr": "50-59",
        "name_se": "50-59",
        "name_el": "50-59",
        "name_es": "50-59",
        "name_hr": "50-59",
        "name_fr": "50-59"
    },
    "70970000^prematch": {
        "name_en": "{$competitor2}",
        "name_aa": "{$competitor2}",
        "name_de": "{$competitor2}",
        "name_nl": "{$competitor2}",
        "name_pl": "{$competitor2}",
        "name_ro": "{$competitor2}",
        "name_ru": "{$competitor2}",
        "name_tr": "{$competitor2}",
        "name_se": "{$competitor2}",
        "name_el": "{$competitor2}",
        "name_es": "{$competitor2}",
        "name_hr": "{$competitor2}",
        "name_fr": "{$competitor2}"
    },
    "1079^prematch": {
        "name_en": "0-10",
        "name_aa": "0-10",
        "name_de": "0-10",
        "name_nl": "0-10",
        "name_pl": "0-10",
        "name_ro": "0-10",
        "name_ru": "0-10",
        "name_tr": "0-10",
        "name_se": "0-10",
        "name_el": "0-10",
        "name_es": "0-10",
        "name_hr": "0-10",
        "name_fr": "0-10"
    },
    "1719^prematch": {
        "name_en": "{$competitor1}/draw & no",
        "name_aa": "{$competitor1}/draw & no",
        "name_de": "{$competitor1}/unentschieden & nein",
        "name_nl": "{$competitor1}/gelijkspel & nee",
        "name_pl": "{$competitor1}/draw & no",
        "name_ro": "{$competitor1}/draw & no",
        "name_ru": "{$competitor1}/draw & no",
        "name_tr": "{$competitor1}/beraberlik & yok",
        "name_se": "{$competitor1}/draw & no",
        "name_el": "{$competitor1}/draw & no",
        "name_es": "{$competitor1}/draw & no",
        "name_hr": "{$competitor1}/neodlučeno i ne",
        "name_fr": "{$competitor1}/Match nul & non"
    },
    "1003^prematch": {
        "name_en": "{$competitor2} by 3+",
        "name_aa": "{$competitor2} by 3+",
        "name_de": "{$competitor2} mit 3+",
        "name_nl": "{$competitor2} met 3+",
        "name_pl": "{$competitor2} by 3+",
        "name_ro": "{$competitor2} by 3+",
        "name_ru": "{$competitor2} by 3+",
        "name_tr": "{$competitor2} 3 ve üstü farkla",
        "name_se": "{$competitor2} by 3+",
        "name_el": "{$competitor2} by 3+",
        "name_es": "{$competitor2} by 3+",
        "name_hr": "{$competitor2} za 3 ili više",
        "name_fr": "{$competitor2} par 3 ou +"
    },
    "43^prematch": {
        "name_en": "5",
        "name_aa": "5",
        "name_de": "5",
        "name_nl": "5",
        "name_pl": "5",
        "name_ro": "5",
        "name_ru": "5",
        "name_tr": "5",
        "name_se": "5",
        "name_el": "5",
        "name_es": "5",
        "name_hr": "5",
        "name_fr": "5"
    },
    "1251^prematch": {
        "name_en": "95+",
        "name_aa": "95+",
        "name_de": "95+",
        "name_nl": "95+",
        "name_pl": "95+",
        "name_ro": "95+",
        "name_ru": "95+",
        "name_tr": "95+",
        "name_se": "95+",
        "name_el": "95+",
        "name_es": "95+",
        "name_hr": "95+",
        "name_fr": "95+"
    },
    "195^prematch": {
        "name_en": "7:3",
        "name_aa": "7:3",
        "name_de": "7:3",
        "name_nl": "7:3",
        "name_pl": "7:3",
        "name_ro": "7:3",
        "name_ru": "7:3",
        "name_tr": "7:3",
        "name_se": "7:3",
        "name_el": "7:3",
        "name_es": "7:3",
        "name_hr": "7:3",
        "name_fr": "7:3"
    },
    "1289^prematch": {
        "name_en": "{$competitor2} by 1-5",
        "name_aa": "{$competitor2} by 1-5",
        "name_de": "{$competitor2} by 1-5",
        "name_nl": "{$competitor2} by 1-5",
        "name_pl": "{$competitor2} by 1-5",
        "name_ro": "{$competitor2} by 1-5",
        "name_ru": "{$competitor2} by 1-5",
        "name_tr": "{$competitor2} by 1-5",
        "name_se": "{$competitor2} by 1-5",
        "name_el": "{$competitor2} by 1-5",
        "name_es": "{$competitor2} by 1-5",
        "name_hr": "{$competitor2} by 1-5",
        "name_fr": "{$competitor2} by 1-5"
    },
    "1870^prematch": {
        "name_en": "{$competitor2}/{$competitor1} & 3",
        "name_aa": "{$competitor2}/{$competitor1} & 3",
        "name_de": "{$competitor2}/{$competitor1} & 3",
        "name_nl": "{$competitor2}/{$competitor1} & 3",
        "name_pl": "{$competitor2}/{$competitor1} & 3",
        "name_ro": "{$competitor2}/{$competitor1} & 3",
        "name_ru": "{$competitor2}/{$competitor1} & 3",
        "name_tr": "{$competitor2}/{$competitor1} & 3",
        "name_se": "{$competitor2}/{$competitor1} & 3",
        "name_el": "{$competitor2}/{$competitor1} & 3",
        "name_es": "{$competitor2}/{$competitor1} & 3",
        "name_hr": "{$competitor2}/{$competitor1} i 3",
        "name_fr": "{$competitor2}/{$competitor1} & 3"
    },
    "1696940000^prematch": {
        "name_en": "7+",
        "name_aa": "7+",
        "name_de": "7+",
        "name_nl": "7+",
        "name_pl": "7+",
        "name_ro": "7+",
        "name_ru": "7+",
        "name_tr": "7+",
        "name_se": "7+",
        "name_el": "7+",
        "name_es": "7+",
        "name_hr": "7+",
        "name_fr": "7+"
    },
    "1285^prematch": {
        "name_en": "{$competitor1} by 11-15",
        "name_aa": "{$competitor1} by 11-15",
        "name_de": "{$competitor1} by 11-15",
        "name_nl": "{$competitor1} by 11-15",
        "name_pl": "{$competitor1} by 11-15",
        "name_ro": "{$competitor1} by 11-15",
        "name_ru": "{$competitor1} by 11-15",
        "name_tr": "{$competitor1} by 11-15",
        "name_se": "{$competitor1} by 11-15",
        "name_el": "{$competitor1} by 11-15",
        "name_es": "{$competitor1} by 11-15",
        "name_hr": "{$competitor1} by 11-15",
        "name_fr": "{$competitor1} by 11-15"
    },
    "722^prematch": {
        "name_en": "9",
        "name_aa": "9",
        "name_de": "9",
        "name_nl": "9",
        "name_pl": "9",
        "name_ro": "9",
        "name_ru": "9",
        "name_tr": "9",
        "name_se": "9",
        "name_el": "9",
        "name_es": "9",
        "name_hr": "9",
        "name_fr": "9"
    },
    "1405^prematch": {
        "name_en": "{$competitor1} & 3",
        "name_aa": "{$competitor1} & 3",
        "name_de": "{$competitor1} & 3",
        "name_nl": "{$competitor1} & 3",
        "name_pl": "{$competitor1} & 3",
        "name_ro": "{$competitor1} & 3",
        "name_ru": "{$competitor1} & 3",
        "name_tr": "{$competitor1} & 3",
        "name_se": "{$competitor1} & 3",
        "name_el": "{$competitor1} & 3",
        "name_es": "{$competitor1} & 3",
        "name_hr": "{$competitor1} & 3",
        "name_fr": "{$competitor1} & 3"
    },
    "290^prematch": {
        "name_en": "11:3",
        "name_aa": "11:3",
        "name_de": "11:3",
        "name_nl": "11:3",
        "name_pl": "11:3",
        "name_ro": "11:3",
        "name_ru": "11:3",
        "name_tr": "11:3",
        "name_se": "11:3",
        "name_el": "11:3",
        "name_es": "11:3",
        "name_hr": "11:3",
        "name_fr": "11:3"
    },
    "1098^prematch": {
        "name_en": "35-41",
        "name_aa": "35-41",
        "name_de": "35-41",
        "name_nl": "35-41",
        "name_pl": "35-41",
        "name_ro": "35-41",
        "name_ru": "35-41",
        "name_tr": "35-41",
        "name_se": "35-41",
        "name_el": "35-41",
        "name_es": "35-41",
        "name_hr": "35-41",
        "name_fr": "35-41"
    },
    "1148^prematch": {
        "name_en": "0-4",
        "name_aa": "0-4",
        "name_de": "0-4",
        "name_nl": "0-4",
        "name_pl": "0-4",
        "name_ro": "0-4",
        "name_ru": "0-4",
        "name_tr": "0-4",
        "name_se": "0-4",
        "name_el": "0-4",
        "name_es": "0-4",
        "name_hr": "0-4",
        "name_fr": "0-4"
    },
    "720^prematch": {
        "name_en": "8",
        "name_aa": "8",
        "name_de": "8",
        "name_nl": "8",
        "name_pl": "8",
        "name_ro": "8",
        "name_ru": "8",
        "name_tr": "8",
        "name_se": "8",
        "name_el": "8",
        "name_es": "8",
        "name_hr": "8",
        "name_fr": "8"
    },
    "50^prematch": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "158^prematch": {
        "name_en": "101-110",
        "name_aa": "101-110",
        "name_de": "101-110",
        "name_nl": "101-110",
        "name_pl": "101-110",
        "name_ro": "101-110",
        "name_ru": "101-110",
        "name_tr": "101-110",
        "name_se": "101-110",
        "name_el": "101-110",
        "name_es": "101-110",
        "name_hr": "101-110",
        "name_fr": "101-110"
    },
    "236^prematch": {
        "name_en": "8:3",
        "name_aa": "8:3",
        "name_de": "8:3",
        "name_nl": "8:3",
        "name_pl": "8:3",
        "name_ro": "8:3",
        "name_ru": "8:3",
        "name_tr": "8:3",
        "name_se": "8:3",
        "name_el": "8:3",
        "name_es": "8:3",
        "name_hr": "8:3",
        "name_fr": "8:3"
    },
    "1150^prematch": {
        "name_en": "7+",
        "name_aa": "7+",
        "name_de": "7+",
        "name_nl": "7+",
        "name_pl": "7+",
        "name_ro": "7+",
        "name_ru": "7+",
        "name_tr": "7+",
        "name_se": "7+",
        "name_el": "7+",
        "name_es": "7+",
        "name_hr": "7+",
        "name_fr": "7+"
    },
    "1746^prematch": {
        "name_en": "1-2",
        "name_aa": "1-2",
        "name_de": "1-2",
        "name_nl": "1-2",
        "name_pl": "1-2",
        "name_ro": "1-2",
        "name_ru": "1-2",
        "name_tr": "1-2",
        "name_se": "1-2",
        "name_el": "1-2",
        "name_es": "1-2",
        "name_hr": "1-2",
        "name_fr": "1-2"
    },
    "249^prematch": {
        "name_en": "9:0",
        "name_aa": "9:0",
        "name_de": "9:0",
        "name_nl": "9:0",
        "name_pl": "9:0",
        "name_ro": "9:0",
        "name_ru": "9:0",
        "name_tr": "9:0",
        "name_se": "9:0",
        "name_el": "9:0",
        "name_es": "9:0",
        "name_hr": "9:0",
        "name_fr": "9:0"
    },
    "1743^prematch": {
        "name_en": "4-6",
        "name_aa": "4-6",
        "name_de": "4-6",
        "name_nl": "4-6",
        "name_pl": "4-6",
        "name_ro": "4-6",
        "name_ru": "4-6",
        "name_tr": "4-6",
        "name_se": "4-6",
        "name_el": "4-6",
        "name_es": "4-6",
        "name_hr": "4-6",
        "name_fr": "4-6"
    },
    "126^prematch": {
        "name_en": "draw",
        "name_aa": "draw",
        "name_de": "draw",
        "name_nl": "draw",
        "name_pl": "draw",
        "name_ro": "draw",
        "name_ru": "draw",
        "name_tr": "draw",
        "name_se": "draw",
        "name_el": "draw",
        "name_es": "draw",
        "name_hr": "draw",
        "name_fr": "draw"
    },
    "1261^prematch": {
        "name_en": "60+",
        "name_aa": "60+",
        "name_de": "60+",
        "name_nl": "60+",
        "name_pl": "60+",
        "name_ro": "60+",
        "name_ru": "60+",
        "name_tr": "60+",
        "name_se": "60+",
        "name_el": "60+",
        "name_es": "60+",
        "name_hr": "60+",
        "name_fr": "60+"
    },
    "171^prematch": {
        "name_en": "181-190",
        "name_aa": "181-190",
        "name_de": "181-190",
        "name_nl": "181-190",
        "name_pl": "181-190",
        "name_ro": "181-190",
        "name_ru": "181-190",
        "name_tr": "181-190",
        "name_se": "181-190",
        "name_el": "181-190",
        "name_es": "181-190",
        "name_hr": "181-190",
        "name_fr": "181-190"
    },
    "1286^prematch": {
        "name_en": "{$competitor1} by 16-20",
        "name_aa": "{$competitor1} by 16-20",
        "name_de": "{$competitor1} by 16-20",
        "name_nl": "{$competitor1} by 16-20",
        "name_pl": "{$competitor1} by 16-20",
        "name_ro": "{$competitor1} by 16-20",
        "name_ru": "{$competitor1} by 16-20",
        "name_tr": "{$competitor1} by 16-20",
        "name_se": "{$competitor1} by 16-20",
        "name_el": "{$competitor1} by 16-20",
        "name_es": "{$competitor1} by 16-20",
        "name_hr": "{$competitor1} by 16-20",
        "name_fr": "{$competitor1} by 16-20"
    },
    "1489^prematch": {
        "name_en": "2:2",
        "name_aa": "2:2",
        "name_de": "2:2",
        "name_nl": "2:2",
        "name_pl": "2:2",
        "name_ro": "2:2",
        "name_ru": "2:2",
        "name_tr": "2:2",
        "name_se": "2:2",
        "name_el": "2:2",
        "name_es": "2:2",
        "name_hr": "2:2",
        "name_fr": "2:2"
    },
    "1283^prematch": {
        "name_en": "{$competitor1} by 1-5",
        "name_aa": "{$competitor1} by 1-5",
        "name_de": "{$competitor1} by 1-5",
        "name_nl": "{$competitor1} by 1-5",
        "name_pl": "{$competitor1} by 1-5",
        "name_ro": "{$competitor1} by 1-5",
        "name_ru": "{$competitor1} by 1-5",
        "name_tr": "{$competitor1} by 1-5",
        "name_se": "{$competitor1} by 1-5",
        "name_el": "{$competitor1} by 1-5",
        "name_es": "{$competitor1} by 1-5",
        "name_hr": "{$competitor1} by 1-5",
        "name_fr": "{$competitor1} by 1-5"
    },
    "1243^prematch": {
        "name_en": "0-49",
        "name_aa": "0-49",
        "name_de": "0-49",
        "name_nl": "0-49",
        "name_pl": "0-49",
        "name_ro": "0-49",
        "name_ru": "0-49",
        "name_tr": "0-49",
        "name_se": "0-49",
        "name_el": "0-49",
        "name_es": "0-49",
        "name_hr": "0-49",
        "name_fr": "0-49"
    },
    "285^prematch": {
        "name_en": "1:10",
        "name_aa": "1:10",
        "name_de": "1:10",
        "name_nl": "1:10",
        "name_pl": "1:10",
        "name_ro": "1:10",
        "name_ru": "1:10",
        "name_tr": "1:10",
        "name_se": "1:10",
        "name_el": "1:10",
        "name_es": "1:10",
        "name_hr": "1:10",
        "name_fr": "1:10"
    },
    "1694240000^prematch": {
        "name_en": "Under 3.5",
        "name_aa": "Under 3.5",
        "name_de": "Under 3.5",
        "name_nl": "Under 3.5",
        "name_pl": "Under 3.5",
        "name_ro": "Under 3.5",
        "name_ru": "Under 3.5",
        "name_tr": "Under 3.5",
        "name_se": "Under 3.5",
        "name_el": "Under 3.5",
        "name_es": "Under 3.5",
        "name_hr": "Under 3.5",
        "name_fr": "Under 3.5"
    },
    "13^prematch": {
        "name_en": "under {total}",
        "name_aa": "under {total}",
        "name_de": "unter {total}",
        "name_nl": "onder {total}",
        "name_pl": "under {total}",
        "name_ro": "under {total}",
        "name_ru": "under {total}",
        "name_tr": "{total} altı",
        "name_se": "under {total}",
        "name_el": "under {total}",
        "name_es": "under {total}",
        "name_hr": "manje od {total}",
        "name_fr": "Moins de {total}"
    },
    "231^prematch": {
        "name_en": "1:7",
        "name_aa": "1:7",
        "name_de": "1:7",
        "name_nl": "1:7",
        "name_pl": "1:7",
        "name_ro": "1:7",
        "name_ru": "1:7",
        "name_tr": "1:7",
        "name_se": "1:7",
        "name_el": "1:7",
        "name_es": "1:7",
        "name_hr": "1:7",
        "name_fr": "1:7"
    },
    "239^prematch": {
        "name_en": "8:6",
        "name_aa": "8:6",
        "name_de": "8:6",
        "name_nl": "8:6",
        "name_pl": "8:6",
        "name_ro": "8:6",
        "name_ru": "8:6",
        "name_tr": "8:6",
        "name_se": "8:6",
        "name_el": "8:6",
        "name_es": "8:6",
        "name_hr": "8:6",
        "name_fr": "8:6"
    },
    "1502^prematch": {
        "name_en": "3:4",
        "name_aa": "3:4",
        "name_de": "3:4",
        "name_nl": "3:4",
        "name_pl": "3:4",
        "name_ro": "3:4",
        "name_ru": "3:4",
        "name_tr": "3:4",
        "name_se": "3:4",
        "name_el": "3:4",
        "name_es": "3:4",
        "name_hr": "3:4",
        "name_fr": "3:4"
    },
    "1095^prematch": {
        "name_en": "14-20",
        "name_aa": "14-20",
        "name_de": "14-20",
        "name_nl": "14-20",
        "name_pl": "14-20",
        "name_ro": "14-20",
        "name_ru": "14-20",
        "name_tr": "14-20",
        "name_se": "14-20",
        "name_el": "14-20",
        "name_es": "14-20",
        "name_hr": "14-20",
        "name_fr": "14-20"
    },
    "223^prematch": {
        "name_en": "7:4",
        "name_aa": "7:4",
        "name_de": "7:4",
        "name_nl": "7:4",
        "name_pl": "7:4",
        "name_ro": "7:4",
        "name_ru": "7:4",
        "name_tr": "7:4",
        "name_se": "7:4",
        "name_el": "7:4",
        "name_es": "7:4",
        "name_hr": "7:4",
        "name_fr": "7:4"
    },
    "118^prematch": {
        "name_en": "{$competitor2} by 3+",
        "name_aa": "{$competitor2} by 3+",
        "name_de": "{$competitor2} by 3+",
        "name_nl": "{$competitor2} by 3+",
        "name_pl": "{$competitor2} by 3+",
        "name_ro": "{$competitor2} by 3+",
        "name_ru": "{$competitor2} by 3+",
        "name_tr": "{$competitor2} by 3+",
        "name_se": "{$competitor2} by 3+",
        "name_el": "{$competitor2} by 3+",
        "name_es": "{$competitor2} by 3+",
        "name_hr": "{$competitor2} by 3+",
        "name_fr": "{$competitor2} by 3+"
    },
    "1383^prematch": {
        "name_en": "{$competitor1} & 10-12",
        "name_aa": "{$competitor1} & 10-12",
        "name_de": "{$competitor1} & 10-12",
        "name_nl": "{$competitor1} & 10-12",
        "name_pl": "{$competitor1} & 10-12",
        "name_ro": "{$competitor1} & 10-12",
        "name_ru": "{$competitor1} & 10-12",
        "name_tr": "{$competitor1} & 10-12",
        "name_se": "{$competitor1} & 10-12",
        "name_el": "{$competitor1} & 10-12",
        "name_es": "{$competitor1} & 10-12",
        "name_hr": "{$competitor1} & 10-12",
        "name_fr": "{$competitor1} & 10-12"
    },
    "1485^prematch": {
        "name_en": "4:1",
        "name_aa": "4:1",
        "name_de": "4:1",
        "name_nl": "4:1",
        "name_pl": "4:1",
        "name_ro": "4:1",
        "name_ru": "4:1",
        "name_tr": "4:1",
        "name_se": "4:1",
        "name_el": "4:1",
        "name_es": "4:1",
        "name_hr": "4:1",
        "name_fr": "4:1"
    },
    "1492^prematch": {
        "name_en": "5:2",
        "name_aa": "5:2",
        "name_de": "5:2",
        "name_nl": "5:2",
        "name_pl": "5:2",
        "name_ro": "5:2",
        "name_ru": "5:2",
        "name_tr": "5:2",
        "name_se": "5:2",
        "name_el": "5:2",
        "name_es": "5:2",
        "name_hr": "5:2",
        "name_fr": "5:2"
    },
    "1135^prematch": {
        "name_en": "61-75",
        "name_aa": "61-75",
        "name_de": "61-75",
        "name_nl": "61-75",
        "name_pl": "61-75",
        "name_ro": "61-75",
        "name_ru": "61-75",
        "name_tr": "61-75",
        "name_se": "61-75",
        "name_el": "61-75",
        "name_es": "61-75",
        "name_hr": "61-75",
        "name_fr": "61-75"
    },
    "220^prematch": {
        "name_en": "7:1",
        "name_aa": "7:1",
        "name_de": "7:1",
        "name_nl": "7:1",
        "name_pl": "7:1",
        "name_ro": "7:1",
        "name_ru": "7:1",
        "name_tr": "7:1",
        "name_se": "7:1",
        "name_el": "7:1",
        "name_es": "7:1",
        "name_hr": "7:1",
        "name_fr": "7:1"
    },
    "178^prematch": {
        "name_en": "250.5+",
        "name_aa": "250.5+",
        "name_de": "250.5+",
        "name_nl": "250.5+",
        "name_pl": "250.5+",
        "name_ro": "250.5+",
        "name_ru": "250.5+",
        "name_tr": "250.5+",
        "name_se": "250.5+",
        "name_el": "250.5+",
        "name_es": "250.5+",
        "name_hr": "250.5+",
        "name_fr": "250.5+"
    },
    "1694170000^prematch": {
        "name_en": "Over 14.5",
        "name_aa": "Over 14.5",
        "name_de": "Over 14.5",
        "name_nl": "Over 14.5",
        "name_pl": "Over 14.5",
        "name_ro": "Over 14.5",
        "name_ru": "Over 14.5",
        "name_tr": "Over 14.5",
        "name_se": "Over 14.5",
        "name_el": "Over 14.5",
        "name_es": "Over 14.5",
        "name_hr": "Over 14.5",
        "name_fr": "Over 14.5"
    },
    "596^prematch": {
        "name_en": "none",
        "name_aa": "none",
        "name_de": "garnicht",
        "name_nl": "geen",
        "name_pl": "none",
        "name_ro": "none",
        "name_ru": "none",
        "name_tr": "Gol olmaz",
        "name_se": "none",
        "name_el": "none",
        "name_es": "none",
        "name_hr": "nikad",
        "name_fr": "aucun"
    },
    "1388^prematch": {
        "name_en": "{$competitor2} & 7-9",
        "name_aa": "{$competitor2} & 7-9",
        "name_de": "{$competitor2} & 7-9",
        "name_nl": "{$competitor2} & 7-9",
        "name_pl": "{$competitor2} & 7-9",
        "name_ro": "{$competitor2} & 7-9",
        "name_ru": "{$competitor2} & 7-9",
        "name_tr": "{$competitor2} & 7-9",
        "name_se": "{$competitor2} & 7-9",
        "name_el": "{$competitor2} & 7-9",
        "name_es": "{$competitor2} & 7-9",
        "name_hr": "{$competitor2} & 7-9",
        "name_fr": "{$competitor2} & 7-9"
    },
    "1850^prematch": {
        "name_en": "draw/{$competitor2} & over {total}",
        "name_aa": "draw/{$competitor2} & over {total}",
        "name_de": "unentschieden/{$competitor2} & über {total}",
        "name_nl": "gelijkspel/{$competitor2} & boven {total}",
        "name_pl": "draw/{$competitor2} & over {total}",
        "name_ro": "draw/{$competitor2} & over {total}",
        "name_ru": "draw/{$competitor2} & over {total}",
        "name_tr": "beraberlik/{$competitor2} & {total} üstü",
        "name_se": "draw/{$competitor2} & over {total}",
        "name_el": "draw/{$competitor2} & over {total}",
        "name_es": "draw/{$competitor2} & over {total}",
        "name_hr": "neodlučeno/{$competitor2} i više {total}",
        "name_fr": "Match nul/{$competitor2} & Plus de {total}"
    },
    "70890000^prematch": {
        "name_en": "{$competitor1}",
        "name_aa": "{$competitor1}",
        "name_de": "{$competitor1}",
        "name_nl": "{$competitor1}",
        "name_pl": "{$competitor1}",
        "name_ro": "{$competitor1}",
        "name_ru": "{$competitor1}",
        "name_tr": "{$competitor1}",
        "name_se": "{$competitor1}",
        "name_el": "{$competitor1}",
        "name_es": "{$competitor1}",
        "name_hr": "{$competitor1}",
        "name_fr": "{$competitor1}"
    },
    "150^prematch": {
        "name_en": "0-27",
        "name_aa": "0-27",
        "name_de": "0-27",
        "name_nl": "0-27",
        "name_pl": "0-27",
        "name_ro": "0-27",
        "name_ru": "0-27",
        "name_tr": "0-27",
        "name_se": "0-27",
        "name_el": "0-27",
        "name_es": "0-27",
        "name_hr": "0-27",
        "name_fr": "0-27"
    },
    "1846^prematch": {
        "name_en": "{$competitor1}/draw & over {total}",
        "name_aa": "{$competitor1}/draw & over {total}",
        "name_de": "{$competitor1}/unentschieden & über {total}",
        "name_nl": "{$competitor1}/gelijkspel & boven {total}",
        "name_pl": "{$competitor1}/draw & over {total}",
        "name_ro": "{$competitor1}/draw & over {total}",
        "name_ru": "{$competitor1}/draw & over {total}",
        "name_tr": "{$competitor1}/beraberlik & {total} üstü",
        "name_se": "{$competitor1}/draw & over {total}",
        "name_el": "{$competitor1}/draw & over {total}",
        "name_es": "{$competitor1}/draw & over {total}",
        "name_hr": "{$competitor1}/neodlučeno i više {total}",
        "name_fr": "{$competitor1}/Match nul & Plus de {total}"
    },
    "269^prematch": {
        "name_en": "10:2",
        "name_aa": "10:2",
        "name_de": "10:2",
        "name_nl": "10:2",
        "name_pl": "10:2",
        "name_ro": "10:2",
        "name_ru": "10:2",
        "name_tr": "10:2",
        "name_se": "10:2",
        "name_el": "10:2",
        "name_es": "10:2",
        "name_hr": "10:2",
        "name_fr": "10:2"
    },
    "174^prematch": {
        "name_en": "211-220",
        "name_aa": "211-220",
        "name_de": "211-220",
        "name_nl": "211-220",
        "name_pl": "211-220",
        "name_ro": "211-220",
        "name_ru": "211-220",
        "name_tr": "211-220",
        "name_se": "211-220",
        "name_el": "211-220",
        "name_es": "211-220",
        "name_hr": "211-220",
        "name_fr": "211-220"
    },
    "1373^prematch": {
        "name_en": "{$competitor2} & 5",
        "name_aa": "{$competitor2} & 5",
        "name_de": "{$competitor2} & 5",
        "name_nl": "{$competitor2} & 5",
        "name_pl": "{$competitor2} & 5",
        "name_ro": "{$competitor2} & 5",
        "name_ru": "{$competitor2} & 5",
        "name_tr": "{$competitor2} & 5",
        "name_se": "{$competitor2} & 5",
        "name_el": "{$competitor2} & 5",
        "name_es": "{$competitor2} & 5",
        "name_hr": "{$competitor2} & 5",
        "name_fr": "{$competitor2} & 5"
    },
    "172^prematch": {
        "name_en": "191-200",
        "name_aa": "191-200",
        "name_de": "191-200",
        "name_nl": "191-200",
        "name_pl": "191-200",
        "name_ro": "191-200",
        "name_ru": "191-200",
        "name_tr": "191-200",
        "name_se": "191-200",
        "name_el": "191-200",
        "name_es": "191-200",
        "name_hr": "191-200",
        "name_fr": "191-200"
    },
    "1142^prematch": {
        "name_en": "9-11",
        "name_aa": "9-11",
        "name_de": "9-11",
        "name_nl": "9-11",
        "name_pl": "9-11",
        "name_ro": "9-11",
        "name_ru": "9-11",
        "name_tr": "9-11",
        "name_se": "9-11",
        "name_el": "9-11",
        "name_es": "9-11",
        "name_hr": "9-11",
        "name_fr": "9-11"
    },
    "173^prematch": {
        "name_en": "201-210",
        "name_aa": "201-210",
        "name_de": "201-210",
        "name_nl": "201-210",
        "name_pl": "201-210",
        "name_ro": "201-210",
        "name_ru": "201-210",
        "name_tr": "201-210",
        "name_se": "201-210",
        "name_el": "201-210",
        "name_es": "201-210",
        "name_hr": "201-210",
        "name_fr": "201-210"
    },
    "1884^prematch": {
        "name_en": "draw/{$competitor1} & 5+",
        "name_aa": "draw/{$competitor1} & 5+",
        "name_de": "unentschieden/{$competitor1} & 5+",
        "name_nl": "gelijkspel/{$competitor1} & 5+",
        "name_pl": "draw/{$competitor1} & 5+",
        "name_ro": "draw/{$competitor1} & 5+",
        "name_ru": "draw/{$competitor1} & 5+",
        "name_tr": "beraberlik/{$competitor1} & 5+",
        "name_se": "draw/{$competitor1} & 5+",
        "name_el": "draw/{$competitor1} & 5+",
        "name_es": "draw/{$competitor1} & 5+",
        "name_hr": "neodlučeno/{$competitor1} i 5+",
        "name_fr": "Match nul/{$competitor1} & 5 ou +"
    },
    "862^prematch": {
        "name_en": "{$competitor2}/{$competitor1}",
        "name_aa": "{$competitor2}/{$competitor1}",
        "name_de": "{$competitor2}/{$competitor1}",
        "name_nl": "{$competitor2}/{$competitor1}",
        "name_pl": "{$competitor2}/{$competitor1}",
        "name_ro": "{$competitor2}/{$competitor1}",
        "name_ru": "{$competitor2}/{$competitor1}",
        "name_tr": "{$competitor2}/{$competitor1}",
        "name_se": "{$competitor2}/{$competitor1}",
        "name_el": "{$competitor2}/{$competitor1}",
        "name_es": "{$competitor2}/{$competitor1}",
        "name_hr": "{$competitor2}/{$competitor1}",
        "name_fr": "{$competitor2}/{$competitor1}"
    },
    "478^prematch": {
        "name_en": "1:2",
        "name_aa": "1:2",
        "name_de": "1:2",
        "name_nl": "1:2",
        "name_pl": "1:2",
        "name_ro": "1:2",
        "name_ru": "1:2",
        "name_tr": "1:2",
        "name_se": "1:2",
        "name_el": "1:2",
        "name_es": "1:2",
        "name_hr": "1:2",
        "name_fr": "1:2"
    },
    "41^prematch": {
        "name_en": "5",
        "name_aa": "5",
        "name_de": "5",
        "name_nl": "5",
        "name_pl": "5",
        "name_ro": "5",
        "name_ru": "5",
        "name_tr": "5",
        "name_se": "5",
        "name_el": "5",
        "name_es": "5",
        "name_hr": "5",
        "name_fr": "5"
    },
    "1760^prematch": {
        "name_en": "0",
        "name_aa": "0",
        "name_de": "0",
        "name_nl": "0",
        "name_pl": "0",
        "name_ro": "0",
        "name_ru": "0",
        "name_tr": "0",
        "name_se": "0",
        "name_el": "0",
        "name_es": "0",
        "name_hr": "0",
        "name_fr": "0"
    },
    "1420000^prematch": {
        "name_en": "{$competitor1}",
        "name_aa": "{$competitor1}",
        "name_de": "{$competitor1}",
        "name_nl": "{$competitor1}",
        "name_pl": "{$competitor1}",
        "name_ro": "{$competitor1}",
        "name_ru": "{$competitor1}",
        "name_tr": "{$competitor1}",
        "name_se": "{$competitor1}",
        "name_el": "{$competitor1}",
        "name_es": "{$competitor1}",
        "name_hr": "{$competitor1}",
        "name_fr": "{$competitor1}"
    },
    "804^prematch": {
        "name_en": "{$competitor2} & over {total}",
        "name_aa": "{$competitor2} & over {total}",
        "name_de": "{$competitor2} & über {total}",
        "name_nl": "{$competitor2} & boven {total}",
        "name_pl": "{$competitor2} & over {total}",
        "name_ro": "{$competitor2} & over {total}",
        "name_ru": "{$competitor2} & over {total}",
        "name_tr": "{$competitor2} & {total} üstü",
        "name_se": "{$competitor2} & over {total}",
        "name_el": "{$competitor2} & over {total}",
        "name_es": "{$competitor2} & over {total}",
        "name_hr": "{$competitor2} i više od {total}",
        "name_fr": "{$competitor2} & plus de {total}"
    },
    "1493^prematch": {
        "name_en": "0:3",
        "name_aa": "0:3",
        "name_de": "0:3",
        "name_nl": "0:3",
        "name_pl": "0:3",
        "name_ro": "0:3",
        "name_ru": "0:3",
        "name_tr": "0:3",
        "name_se": "0:3",
        "name_el": "0:3",
        "name_es": "0:3",
        "name_hr": "0:3",
        "name_fr": "0:3"
    },
    "1300^prematch": {
        "name_en": "26-30",
        "name_aa": "26-30",
        "name_de": "26-30",
        "name_nl": "26-30",
        "name_pl": "26-30",
        "name_ro": "26-30",
        "name_ru": "26-30",
        "name_tr": "26-30",
        "name_se": "26-30",
        "name_el": "26-30",
        "name_es": "26-30",
        "name_hr": "26-30",
        "name_fr": "26-30"
    },
    "468^prematch": {
        "name_en": "1:0",
        "name_aa": "1:0",
        "name_de": "1:0",
        "name_nl": "1:0",
        "name_pl": "1:0",
        "name_ro": "1:0",
        "name_ru": "1:0",
        "name_tr": "1:0",
        "name_se": "1:0",
        "name_el": "1:0",
        "name_es": "1:0",
        "name_hr": "1:0",
        "name_fr": "1:0"
    },
    "1375^prematch": {
        "name_en": "{$competitor1} by ko",
        "name_aa": "{$competitor1} by ko",
        "name_de": "{$competitor1} by ko",
        "name_nl": "{$competitor1} by ko",
        "name_pl": "{$competitor1} by ko",
        "name_ro": "{$competitor1} by ko",
        "name_ru": "{$competitor1} by ko",
        "name_tr": "{$competitor1} by ko",
        "name_se": "{$competitor1} by ko",
        "name_el": "{$competitor1} by ko",
        "name_es": "{$competitor1} by ko",
        "name_hr": "{$competitor1} by ko",
        "name_fr": "{$competitor1} by ko"
    },
    "1158^prematch": {
        "name_en": "0:1",
        "name_aa": "0:1",
        "name_de": "0:1",
        "name_nl": "0:1",
        "name_pl": "0:1",
        "name_ro": "0:1",
        "name_ru": "0:1",
        "name_tr": "0:1",
        "name_se": "0:1",
        "name_el": "0:1",
        "name_es": "0:1",
        "name_hr": "0:1",
        "name_fr": "0:1"
    },
    "820^prematch": {
        "name_en": "{$competitor2} goal & {$competitor1}",
        "name_aa": "{$competitor2} goal & {$competitor1}",
        "name_de": "{$competitor2} Tor & {$competitor1}",
        "name_nl": "doelpunt {$competitor2} & {$competitor1}",
        "name_pl": "{$competitor2} goal & {$competitor1}",
        "name_ro": "{$competitor2} goal & {$competitor1}",
        "name_ru": "{$competitor2} goal & {$competitor1}",
        "name_tr": "{$competitor2} golü & {$competitor1} kazanır",
        "name_se": "{$competitor2} goal & {$competitor1}",
        "name_el": "{$competitor2} goal & {$competitor1}",
        "name_es": "{$competitor2} goal & {$competitor1}",
        "name_hr": "{$competitor2} gol i {$competitor1} pobjeda",
        "name_fr": "but {$competitor2} & {$competitor1}"
    },
    "234^prematch": {
        "name_en": "8:1",
        "name_aa": "8:1",
        "name_de": "8:1",
        "name_nl": "8:1",
        "name_pl": "8:1",
        "name_ro": "8:1",
        "name_ru": "8:1",
        "name_tr": "8:1",
        "name_se": "8:1",
        "name_el": "8:1",
        "name_es": "8:1",
        "name_hr": "8:1",
        "name_fr": "8:1"
    },
    "1381^prematch": {
        "name_en": "{$competitor1} & 4-6",
        "name_aa": "{$competitor1} & 4-6",
        "name_de": "{$competitor1} & 4-6",
        "name_nl": "{$competitor1} & 4-6",
        "name_pl": "{$competitor1} & 4-6",
        "name_ro": "{$competitor1} & 4-6",
        "name_ru": "{$competitor1} & 4-6",
        "name_tr": "{$competitor1} & 4-6",
        "name_se": "{$competitor1} & 4-6",
        "name_el": "{$competitor1} & 4-6",
        "name_es": "{$competitor1} & 4-6",
        "name_hr": "{$competitor1} & 4-6",
        "name_fr": "{$competitor1} & 4-6"
    },
    "1160^prematch": {
        "name_en": "0:3",
        "name_aa": "0:3",
        "name_de": "0:3",
        "name_nl": "0:3",
        "name_pl": "0:3",
        "name_ro": "0:3",
        "name_ru": "0:3",
        "name_tr": "0:3",
        "name_se": "0:3",
        "name_el": "0:3",
        "name_es": "0:3",
        "name_hr": "0:3",
        "name_fr": "0:3"
    },
    "1424^prematch": {
        "name_en": "{$competitor2} & 8",
        "name_aa": "{$competitor2} & 8",
        "name_de": "{$competitor2} & 8",
        "name_nl": "{$competitor2} & 8",
        "name_pl": "{$competitor2} & 8",
        "name_ro": "{$competitor2} & 8",
        "name_ru": "{$competitor2} & 8",
        "name_tr": "{$competitor2} & 8",
        "name_se": "{$competitor2} & 8",
        "name_el": "{$competitor2} & 8",
        "name_es": "{$competitor2} & 8",
        "name_hr": "{$competitor2} & 8",
        "name_fr": "{$competitor2} & 8"
    },
    "60080000^prematch": {
        "name_en": "5+/7+",
        "name_aa": "5+/7+",
        "name_de": "5+/7+",
        "name_nl": "5+/7+",
        "name_pl": "5+/7+",
        "name_ro": "5+/7+",
        "name_ru": "5+/7+",
        "name_tr": "5+/7+",
        "name_se": "5+/7+",
        "name_el": "5+/7+",
        "name_es": "5+/7+",
        "name_hr": "5+/7+",
        "name_fr": "5+/7+"
    },
    "1510^prematch": {
        "name_en": "other",
        "name_aa": "other",
        "name_de": "other",
        "name_nl": "other",
        "name_pl": "other",
        "name_ro": "other",
        "name_ru": "other",
        "name_tr": "other",
        "name_se": "other",
        "name_el": "other",
        "name_es": "other",
        "name_hr": "other",
        "name_fr": "other"
    },
    "70^prematch": {
        "name_en": "odd",
        "name_aa": "odd",
        "name_de": "ungerade",
        "name_nl": "oneven",
        "name_pl": "odd",
        "name_ro": "odd",
        "name_ru": "odd",
        "name_tr": "tek",
        "name_se": "odd",
        "name_el": "odd",
        "name_es": "odd",
        "name_hr": "par",
        "name_fr": "impair"
    },
    "374^prematch": {
        "name_en": "1:0 1:0",
        "name_aa": "1:0 1:0",
        "name_de": "1:0 1:0",
        "name_nl": "1:0 1:0",
        "name_pl": "1:0 1:0",
        "name_ro": "1:0 1:0",
        "name_ru": "1:0 1:0",
        "name_tr": "1:0 1:0",
        "name_se": "1:0 1:0",
        "name_el": "1:0 1:0",
        "name_es": "1:0 1:0",
        "name_hr": "1:0 1:0",
        "name_fr": "1:0 1:0"
    },
    "1849^prematch": {
        "name_en": "draw/draw & over {total}",
        "name_aa": "draw/draw & over {total}",
        "name_de": "unentschieden/unentschieden & über {total}",
        "name_nl": "gelijkspel/gelijkspel & boven {total}",
        "name_pl": "draw/draw & over {total}",
        "name_ro": "draw/draw & over {total}",
        "name_ru": "draw/draw & over {total}",
        "name_tr": "beraberlik/beraberlik & {total} üstü",
        "name_se": "draw/draw & over {total}",
        "name_el": "draw/draw & over {total}",
        "name_es": "draw/draw & over {total}",
        "name_hr": "neodlučeno/neodlučeno i više {total}",
        "name_fr": "Match nul/Match nul & Plus de {total}"
    },
    "1844^prematch": {
        "name_en": "{$competitor2}/{$competitor2} & under {total}",
        "name_aa": "{$competitor2}/{$competitor2} & under {total}",
        "name_de": "{$competitor2}/{$competitor2} & unter {total}",
        "name_nl": "{$competitor2}/{$competitor2} & onder {total}",
        "name_pl": "{$competitor2}/{$competitor2} & under {total}",
        "name_ro": "{$competitor2}/{$competitor2} & under {total}",
        "name_ru": "{$competitor2}/{$competitor2} & under {total}",
        "name_tr": "{$competitor2}/{$competitor2} & {total} altı",
        "name_se": "{$competitor2}/{$competitor2} & under {total}",
        "name_el": "{$competitor2}/{$competitor2} & under {total}",
        "name_es": "{$competitor2}/{$competitor2} & under {total}",
        "name_hr": "{$competitor2}/{$competitor2} i manje {total}",
        "name_fr": "{$competitor2}/{$competitor2} & Moins de {total}"
    },
    "1871^prematch": {
        "name_en": "{$competitor2}/{$competitor2} & 3",
        "name_aa": "{$competitor2}/{$competitor2} & 3",
        "name_de": "{$competitor2}/{$competitor2} & 3",
        "name_nl": "{$competitor2}/{$competitor2} & 3",
        "name_pl": "{$competitor2}/{$competitor2} & 3",
        "name_ro": "{$competitor2}/{$competitor2} & 3",
        "name_ru": "{$competitor2}/{$competitor2} & 3",
        "name_tr": "{$competitor2}/{$competitor2} & 3",
        "name_se": "{$competitor2}/{$competitor2} & 3",
        "name_el": "{$competitor2}/{$competitor2} & 3",
        "name_es": "{$competitor2}/{$competitor2} & 3",
        "name_hr": "{$competitor2}/{$competitor2} i 3",
        "name_fr": "{$competitor2}/{$competitor2} & 3"
    },
    "1093^prematch": {
        "name_en": "0-6",
        "name_aa": "0-6",
        "name_de": "0-6",
        "name_nl": "0-6",
        "name_pl": "0-6",
        "name_ro": "0-6",
        "name_ru": "0-6",
        "name_tr": "0-6",
        "name_se": "0-6",
        "name_el": "0-6",
        "name_es": "0-6",
        "name_hr": "0-6",
        "name_fr": "0-6"
    },
    "51^prematch": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "1749^prematch": {
        "name_en": "4+",
        "name_aa": "4+",
        "name_de": "4+",
        "name_nl": "4+",
        "name_pl": "4+",
        "name_ro": "4+",
        "name_ru": "4+",
        "name_tr": "4+",
        "name_se": "4+",
        "name_el": "4+",
        "name_es": "4+",
        "name_hr": "4+",
        "name_fr": "4+"
    },
    "1245^prematch": {
        "name_en": "60-64",
        "name_aa": "60-64",
        "name_de": "60-64",
        "name_nl": "60-64",
        "name_pl": "60-64",
        "name_ro": "60-64",
        "name_ru": "60-64",
        "name_tr": "60-64",
        "name_se": "60-64",
        "name_el": "60-64",
        "name_es": "60-64",
        "name_hr": "60-64",
        "name_fr": "60-64"
    },
    "2035^prematch": {
        "name_en": "{$competitor1} ({+hcp}) & over {total}",
        "name_aa": "{$competitor1} ({+hcp}) & over {total}",
        "name_de": "{$competitor1} ({+hcp}) & over {total}",
        "name_nl": "{$competitor1} ({+hcp}) & over {total}",
        "name_pl": "{$competitor1} ({+hcp}) & over {total}",
        "name_ro": "{$competitor1} ({+hcp}) & over {total}",
        "name_ru": "{$competitor1} ({+hcp}) & over {total}",
        "name_tr": "{$competitor1} ({+hcp}) & over {total}",
        "name_se": "{$competitor1} ({+hcp}) & over {total}",
        "name_el": "{$competitor1} ({+hcp}) & over {total}",
        "name_es": "{$competitor1} ({+hcp}) & over {total}",
        "name_hr": "{$competitor1} ({+hcp}) & over {total}",
        "name_fr": "{$competitor1} ({+hcp}) & over {total}"
    },
    "70740000^prematch": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "186^prematch": {
        "name_en": "5:6",
        "name_aa": "5:6",
        "name_de": "5:6",
        "name_nl": "5:6",
        "name_pl": "5:6",
        "name_ro": "5:6",
        "name_ru": "5:6",
        "name_tr": "5:6",
        "name_se": "5:6",
        "name_el": "5:6",
        "name_es": "5:6",
        "name_hr": "5:6",
        "name_fr": "5:6"
    },
    "398^prematch": {
        "name_en": "1:2 4+",
        "name_aa": "1:2 4+",
        "name_de": "1:2 4+",
        "name_nl": "1:2 4+",
        "name_pl": "1:2 4+",
        "name_ro": "1:2 4+",
        "name_ru": "1:2 4+",
        "name_tr": "1:2 4+",
        "name_se": "1:2 4+",
        "name_el": "1:2 4+",
        "name_es": "1:2 4+",
        "name_hr": "1:2 4+",
        "name_fr": "1:2 4+"
    },
    "8^prematch": {
        "name_en": "3:0",
        "name_aa": "3:0",
        "name_de": "3:0",
        "name_nl": "3:0",
        "name_pl": "3:0",
        "name_ro": "3:0",
        "name_ru": "3:0",
        "name_tr": "3:0",
        "name_se": "3:0",
        "name_el": "3:0",
        "name_es": "3:0",
        "name_hr": "3:0",
        "name_fr": "3:0"
    },
    "416^prematch": {
        "name_en": "4+ 4+",
        "name_aa": "4+ 4+",
        "name_de": "4+ 4+",
        "name_nl": "4+ 4+",
        "name_pl": "4+ 4+",
        "name_ro": "4+ 4+",
        "name_ru": "4+ 4+",
        "name_tr": "4+ 4+",
        "name_se": "4+ 4+",
        "name_el": "4+ 4+",
        "name_es": "4+ 4+",
        "name_hr": "4+ 4+",
        "name_fr": "4+ 4+"
    },
    "35^prematch": {
        "name_en": "4",
        "name_aa": "4",
        "name_de": "4",
        "name_nl": "4",
        "name_pl": "4",
        "name_ro": "4",
        "name_ru": "4",
        "name_tr": "4",
        "name_se": "4",
        "name_el": "4",
        "name_es": "4",
        "name_hr": "4",
        "name_fr": "4"
    },
    "16^prematch": {
        "name_en": "4:2",
        "name_aa": "4:2",
        "name_de": "4:2",
        "name_nl": "4:2",
        "name_pl": "4:2",
        "name_ro": "4:2",
        "name_ru": "4:2",
        "name_tr": "4:2",
        "name_se": "4:2",
        "name_el": "4:2",
        "name_es": "4:2",
        "name_hr": "4:2",
        "name_fr": "4:2"
    },
    "326^prematch": {
        "name_en": "0:0 0:0",
        "name_aa": "0:0 0:0",
        "name_de": "0:0 0:0",
        "name_nl": "0:0 0:0",
        "name_pl": "0:0 0:0",
        "name_ro": "0:0 0:0",
        "name_ru": "0:0 0:0",
        "name_tr": "0:0 0:0",
        "name_se": "0:0 0:0",
        "name_el": "0:0 0:0",
        "name_es": "0:0 0:0",
        "name_hr": "0:0 0:0",
        "name_fr": "0:0 0:0"
    },
    "1694270000^prematch": {
        "name_en": "Over 5.5",
        "name_aa": "Over 5.5",
        "name_de": "Over 5.5",
        "name_nl": "Over 5.5",
        "name_pl": "Over 5.5",
        "name_ro": "Over 5.5",
        "name_ru": "Over 5.5",
        "name_tr": "Over 5.5",
        "name_se": "Over 5.5",
        "name_el": "Over 5.5",
        "name_es": "Over 5.5",
        "name_hr": "Over 5.5",
        "name_fr": "Over 5.5"
    },
    "1694060000^prematch": {
        "name_en": "Under 8.5",
        "name_aa": "Under 8.5",
        "name_de": "Under 8.5",
        "name_nl": "Under 8.5",
        "name_pl": "Under 8.5",
        "name_ro": "Under 8.5",
        "name_ru": "Under 8.5",
        "name_tr": "Under 8.5",
        "name_se": "Under 8.5",
        "name_el": "Under 8.5",
        "name_es": "Under 8.5",
        "name_hr": "Under 8.5",
        "name_fr": "Under 8.5"
    },
    "70950000^prematch": {
        "name_en": "{$competitor1}",
        "name_aa": "{$competitor1}",
        "name_de": "{$competitor1}",
        "name_nl": "{$competitor1}",
        "name_pl": "{$competitor1}",
        "name_ro": "{$competitor1}",
        "name_ru": "{$competitor1}",
        "name_tr": "{$competitor1}",
        "name_se": "{$competitor1}",
        "name_el": "{$competitor1}",
        "name_es": "{$competitor1}",
        "name_hr": "{$competitor1}",
        "name_fr": "{$competitor1}"
    },
    "1367^prematch": {
        "name_en": "{$competitor1} & decision",
        "name_aa": "{$competitor1} & decision",
        "name_de": "{$competitor1} & decision",
        "name_nl": "{$competitor1} & decision",
        "name_pl": "{$competitor1} & decision",
        "name_ro": "{$competitor1} & decision",
        "name_ru": "{$competitor1} & decision",
        "name_tr": "{$competitor1} & decision",
        "name_se": "{$competitor1} & decision",
        "name_el": "{$competitor1} & decision",
        "name_es": "{$competitor1} & decision",
        "name_hr": "{$competitor1} & decision",
        "name_fr": "{$competitor1} & decision"
    },
    "1864^prematch": {
        "name_en": "{$competitor2}/draw & 2",
        "name_aa": "{$competitor2}/draw & 2",
        "name_de": "{$competitor2}/unentschieden & 2",
        "name_nl": "{$competitor2}/gelijkspel & 2",
        "name_pl": "{$competitor2}/draw & 2",
        "name_ro": "{$competitor2}/draw & 2",
        "name_ru": "{$competitor2}/draw & 2",
        "name_tr": "{$competitor2}/beraberlik & 2",
        "name_se": "{$competitor2}/draw & 2",
        "name_el": "{$competitor2}/draw & 2",
        "name_es": "{$competitor2}/draw & 2",
        "name_hr": "{$competitor2}/neodlučeno i 2",
        "name_fr": "{$competitor2}/Match nul & 2"
    },
    "376^prematch": {
        "name_en": "1:0 1:1",
        "name_aa": "1:0 1:1",
        "name_de": "1:0 1:1",
        "name_nl": "1:0 1:1",
        "name_pl": "1:0 1:1",
        "name_ro": "1:0 1:1",
        "name_ru": "1:0 1:1",
        "name_tr": "1:0 1:1",
        "name_se": "1:0 1:1",
        "name_el": "1:0 1:1",
        "name_es": "1:0 1:1",
        "name_hr": "1:0 1:1",
        "name_fr": "1:0 1:1"
    },
    "247^prematch": {
        "name_en": "1:8",
        "name_aa": "1:8",
        "name_de": "1:8",
        "name_nl": "1:8",
        "name_pl": "1:8",
        "name_ro": "1:8",
        "name_ru": "1:8",
        "name_tr": "1:8",
        "name_se": "1:8",
        "name_el": "1:8",
        "name_es": "1:8",
        "name_hr": "1:8",
        "name_fr": "1:8"
    },
    "1290^prematch": {
        "name_en": "{$competitor2} by 6-10",
        "name_aa": "{$competitor2} by 6-10",
        "name_de": "{$competitor2} by 6-10",
        "name_nl": "{$competitor2} by 6-10",
        "name_pl": "{$competitor2} by 6-10",
        "name_ro": "{$competitor2} by 6-10",
        "name_ru": "{$competitor2} by 6-10",
        "name_tr": "{$competitor2} by 6-10",
        "name_se": "{$competitor2} by 6-10",
        "name_el": "{$competitor2} by 6-10",
        "name_es": "{$competitor2} by 6-10",
        "name_hr": "{$competitor2} by 6-10",
        "name_fr": "{$competitor2} by 6-10"
    },
    "129^prematch": {
        "name_en": "{$competitor2} by 11+",
        "name_aa": "{$competitor2} by 11+",
        "name_de": "{$competitor2} by 11+",
        "name_nl": "{$competitor2} by 11+",
        "name_pl": "{$competitor2} by 11+",
        "name_ro": "{$competitor2} by 11+",
        "name_ru": "{$competitor2} by 11+",
        "name_tr": "{$competitor2} by 11+",
        "name_se": "{$competitor2} by 11+",
        "name_el": "{$competitor2} by 11+",
        "name_es": "{$competitor2} by 11+",
        "name_hr": "{$competitor2} by 11+",
        "name_fr": "{$competitor2} by 11+"
    },
    "1340^prematch": {
        "name_en": "4",
        "name_aa": "4",
        "name_de": "4",
        "name_nl": "4",
        "name_pl": "4",
        "name_ro": "4",
        "name_ru": "4",
        "name_tr": "4",
        "name_se": "4",
        "name_el": "4",
        "name_es": "4",
        "name_hr": "4",
        "name_fr": "4"
    },
    "201^prematch": {
        "name_en": "3:7",
        "name_aa": "3:7",
        "name_de": "3:7",
        "name_nl": "3:7",
        "name_pl": "3:7",
        "name_ro": "3:7",
        "name_ru": "3:7",
        "name_tr": "3:7",
        "name_se": "3:7",
        "name_el": "3:7",
        "name_es": "3:7",
        "name_hr": "3:7",
        "name_fr": "3:7"
    },
    "1487^prematch": {
        "name_en": "0:2",
        "name_aa": "0:2",
        "name_de": "0:2",
        "name_nl": "0:2",
        "name_pl": "0:2",
        "name_ro": "0:2",
        "name_ru": "0:2",
        "name_tr": "0:2",
        "name_se": "0:2",
        "name_el": "0:2",
        "name_es": "0:2",
        "name_hr": "0:2",
        "name_fr": "0:2"
    },
    "1371^prematch": {
        "name_en": "{$competitor2} & 3",
        "name_aa": "{$competitor2} & 3",
        "name_de": "{$competitor2} & 3",
        "name_nl": "{$competitor2} & 3",
        "name_pl": "{$competitor2} & 3",
        "name_ro": "{$competitor2} & 3",
        "name_ru": "{$competitor2} & 3",
        "name_tr": "{$competitor2} & 3",
        "name_se": "{$competitor2} & 3",
        "name_el": "{$competitor2} & 3",
        "name_es": "{$competitor2} & 3",
        "name_hr": "{$competitor2} & 3",
        "name_fr": "{$competitor2} & 3"
    },
    "1190^prematch": {
        "name_en": "6:0",
        "name_aa": "6:0",
        "name_de": "6:0",
        "name_nl": "6:0",
        "name_pl": "6:0",
        "name_ro": "6:0",
        "name_ru": "6:0",
        "name_tr": "6:0",
        "name_se": "6:0",
        "name_el": "6:0",
        "name_es": "6:0",
        "name_hr": "6:0",
        "name_fr": "6:0"
    },
    "1697230000^prematch": {
        "name_en": "Over",
        "name_aa": "Over",
        "name_de": "Over",
        "name_nl": "Over",
        "name_pl": "Over",
        "name_ro": "Over",
        "name_ru": "Over",
        "name_tr": "Over",
        "name_se": "Over",
        "name_el": "Over",
        "name_es": "Over",
        "name_hr": "Over",
        "name_fr": "Over"
    },
    "382^prematch": {
        "name_en": "1:0 2:1",
        "name_aa": "1:0 2:1",
        "name_de": "1:0 2:1",
        "name_nl": "1:0 2:1",
        "name_pl": "1:0 2:1",
        "name_ro": "1:0 2:1",
        "name_ru": "1:0 2:1",
        "name_tr": "1:0 2:1",
        "name_se": "1:0 2:1",
        "name_el": "1:0 2:1",
        "name_es": "1:0 2:1",
        "name_hr": "1:0 2:1",
        "name_fr": "1:0 2:1"
    },
    "243^prematch": {
        "name_en": "5:8",
        "name_aa": "5:8",
        "name_de": "5:8",
        "name_nl": "5:8",
        "name_pl": "5:8",
        "name_ro": "5:8",
        "name_ru": "5:8",
        "name_tr": "5:8",
        "name_se": "5:8",
        "name_el": "5:8",
        "name_es": "5:8",
        "name_hr": "5:8",
        "name_fr": "5:8"
    },
    "714^prematch": {
        "name_en": "5",
        "name_aa": "5",
        "name_de": "5",
        "name_nl": "5",
        "name_pl": "5",
        "name_ro": "5",
        "name_ru": "5",
        "name_tr": "5",
        "name_se": "5",
        "name_el": "5",
        "name_es": "5",
        "name_hr": "5",
        "name_fr": "5"
    },
    "1420^prematch": {
        "name_en": "{$competitor2} & 4",
        "name_aa": "{$competitor2} & 4",
        "name_de": "{$competitor2} & 4",
        "name_nl": "{$competitor2} & 4",
        "name_pl": "{$competitor2} & 4",
        "name_ro": "{$competitor2} & 4",
        "name_ru": "{$competitor2} & 4",
        "name_tr": "{$competitor2} & 4",
        "name_se": "{$competitor2} & 4",
        "name_el": "{$competitor2} & 4",
        "name_es": "{$competitor2} & 4",
        "name_hr": "{$competitor2} & 4",
        "name_fr": "{$competitor2} & 4"
    },
    "1739^prematch": {
        "name_en": "3-4",
        "name_aa": "3-4",
        "name_de": "3-4",
        "name_nl": "3-4",
        "name_pl": "3-4",
        "name_ro": "3-4",
        "name_ru": "3-4",
        "name_tr": "3-4",
        "name_se": "3-4",
        "name_el": "3-4",
        "name_es": "3-4",
        "name_hr": "3-4",
        "name_fr": "3-4"
    },
    "293^prematch": {
        "name_en": "11:6",
        "name_aa": "11:6",
        "name_de": "11:6",
        "name_nl": "11:6",
        "name_pl": "11:6",
        "name_ro": "11:6",
        "name_ru": "11:6",
        "name_tr": "11:6",
        "name_se": "11:6",
        "name_el": "11:6",
        "name_es": "11:6",
        "name_hr": "11:6",
        "name_fr": "11:6"
    },
    "86^prematch": {
        "name_en": "{$competitor2} & yes",
        "name_aa": "{$competitor2} & yes",
        "name_de": "{$competitor2} & ja",
        "name_nl": "{$competitor2} & ja",
        "name_pl": "{$competitor2} & yes",
        "name_ro": "{$competitor2} & yes",
        "name_ru": "{$competitor2} & yes",
        "name_tr": "{$competitor2} & var",
        "name_se": "{$competitor2} & yes",
        "name_el": "{$competitor2} & yes",
        "name_es": "{$competitor2} & yes",
        "name_hr": "{$competitor2} i da",
        "name_fr": "{$competitor2} & oui"
    },
    "1179^prematch": {
        "name_en": "3:1",
        "name_aa": "3:1",
        "name_de": "3:1",
        "name_nl": "3:1",
        "name_pl": "3:1",
        "name_ro": "3:1",
        "name_ru": "3:1",
        "name_tr": "3:1",
        "name_se": "3:1",
        "name_el": "3:1",
        "name_es": "3:1",
        "name_hr": "3:1",
        "name_fr": "3:1"
    },
    "1121^prematch": {
        "name_en": "0-1",
        "name_aa": "0-1",
        "name_de": "0-1",
        "name_nl": "0-1",
        "name_pl": "0-1",
        "name_ro": "0-1",
        "name_ru": "0-1",
        "name_tr": "0-1",
        "name_se": "0-1",
        "name_el": "0-1",
        "name_es": "0-1",
        "name_hr": "0-1",
        "name_fr": "0-1"
    },
    "1297^prematch": {
        "name_en": "11-15",
        "name_aa": "11-15",
        "name_de": "11-15",
        "name_nl": "11-15",
        "name_pl": "11-15",
        "name_ro": "11-15",
        "name_ru": "11-15",
        "name_tr": "11-15",
        "name_se": "11-15",
        "name_el": "11-15",
        "name_es": "11-15",
        "name_hr": "11-15",
        "name_fr": "11-15"
    },
    "4^prematch": {
        "name_en": "{$competitor1}",
        "name_aa": "{$competitor1}",
        "name_de": "{$competitor1}",
        "name_nl": "{$competitor1}",
        "name_pl": "{$competitor1}",
        "name_ro": "{$competitor1}",
        "name_ru": "{$competitor1}",
        "name_tr": "{$competitor1}",
        "name_se": "{$competitor1}",
        "name_el": "{$competitor1}",
        "name_es": "{$competitor1}",
        "name_hr": "{$competitor1}",
        "name_fr": "{$competitor1}"
    },
    "422^prematch": {
        "name_en": "{$competitor1}/{$competitor2}",
        "name_aa": "{$competitor1}/{$competitor2}",
        "name_de": "{$competitor1}/{$competitor2}",
        "name_nl": "{$competitor1}/{$competitor2}",
        "name_pl": "{$competitor1}/{$competitor2}",
        "name_ro": "{$competitor1}/{$competitor2}",
        "name_ru": "{$competitor1}/{$competitor2}",
        "name_tr": "{$competitor1}/{$competitor2}",
        "name_se": "{$competitor1}/{$competitor2}",
        "name_el": "{$competitor1}/{$competitor2}",
        "name_es": "{$competitor1}/{$competitor2}",
        "name_hr": "{$competitor1}/{$competitor2}",
        "name_fr": "{$competitor1}/{$competitor2}"
    },
    "940^prematch": {
        "name_en": "exact {total}",
        "name_aa": "exact {total}",
        "name_de": "genau {total}",
        "name_nl": "exact {total}",
        "name_pl": "exact {total}",
        "name_ro": "exact {total}",
        "name_ru": "exact {total}",
        "name_tr": "tam {total}",
        "name_se": "exact {total}",
        "name_el": "exact {total}",
        "name_es": "exact {total}",
        "name_hr": "točno {total}",
        "name_fr": "exact {total}"
    },
    "1694250000^prematch": {
        "name_en": "Over 4.5",
        "name_aa": "Over 4.5",
        "name_de": "Over 4.5",
        "name_nl": "Over 4.5",
        "name_pl": "Over 4.5",
        "name_ro": "Over 4.5",
        "name_ru": "Over 4.5",
        "name_tr": "Over 4.5",
        "name_se": "Over 4.5",
        "name_el": "Over 4.5",
        "name_es": "Over 4.5",
        "name_hr": "Over 4.5",
        "name_fr": "Over 4.5"
    },
    "610^prematch": {
        "name_en": "61-70",
        "name_aa": "61-70",
        "name_de": "61-70",
        "name_nl": "61-70",
        "name_pl": "61-70",
        "name_ro": "61-70",
        "name_ru": "61-70",
        "name_tr": "61-70",
        "name_se": "61-70",
        "name_el": "61-70",
        "name_es": "61-70",
        "name_hr": "61-70",
        "name_fr": "61-70"
    },
    "1198^prematch": {
        "name_en": "1.5:0.5",
        "name_aa": "1.5:0.5",
        "name_de": "1.5:0.5",
        "name_nl": "1.5:0.5",
        "name_pl": "1.5:0.5",
        "name_ro": "1.5:0.5",
        "name_ru": "1.5:0.5",
        "name_tr": "1.5:0.5",
        "name_se": "1.5:0.5",
        "name_el": "1.5:0.5",
        "name_es": "1.5:0.5",
        "name_hr": "1.5:0.5",
        "name_fr": "1.5:0.5"
    },
    "200^prematch": {
        "name_en": "4:7",
        "name_aa": "4:7",
        "name_de": "4:7",
        "name_nl": "4:7",
        "name_pl": "4:7",
        "name_ro": "4:7",
        "name_ru": "4:7",
        "name_tr": "4:7",
        "name_se": "4:7",
        "name_el": "4:7",
        "name_es": "4:7",
        "name_hr": "4:7",
        "name_fr": "4:7"
    },
    "874^prematch": {
        "name_en": "2:6",
        "name_aa": "2:6",
        "name_de": "2:6",
        "name_nl": "2:6",
        "name_pl": "2:6",
        "name_ro": "2:6",
        "name_ru": "2:6",
        "name_tr": "2:6",
        "name_se": "2:6",
        "name_el": "2:6",
        "name_es": "2:6",
        "name_hr": "2:6",
        "name_fr": "2:6"
    },
    "1731^prematch": {
        "name_en": "1-3",
        "name_aa": "1-3",
        "name_de": "1-3",
        "name_nl": "1-3",
        "name_pl": "1-3",
        "name_ro": "1-3",
        "name_ru": "1-3",
        "name_tr": "1-3",
        "name_se": "1-3",
        "name_el": "1-3",
        "name_es": "1-3",
        "name_hr": "1-3",
        "name_fr": "1-3"
    },
    "1187^prematch": {
        "name_en": "5:0",
        "name_aa": "5:0",
        "name_de": "5:0",
        "name_nl": "5:0",
        "name_pl": "5:0",
        "name_ro": "5:0",
        "name_ru": "5:0",
        "name_tr": "5:0",
        "name_se": "5:0",
        "name_el": "5:0",
        "name_es": "5:0",
        "name_hr": "5:0",
        "name_fr": "5:0"
    },
    "141^prematch": {
        "name_en": "{$competitor2} by 6-10",
        "name_aa": "{$competitor2} by 6-10",
        "name_de": "{$competitor2} by 6-10",
        "name_nl": "{$competitor2} by 6-10",
        "name_pl": "{$competitor2} by 6-10",
        "name_ro": "{$competitor2} by 6-10",
        "name_ru": "{$competitor2} by 6-10",
        "name_tr": "{$competitor2} by 6-10",
        "name_se": "{$competitor2} by 6-10",
        "name_el": "{$competitor2} by 6-10",
        "name_es": "{$competitor2} by 6-10",
        "name_hr": "{$competitor2} by 6-10",
        "name_fr": "{$competitor2} by 6-10"
    },
    "70860000^prematch": {
        "name_en": "{$competitor1}",
        "name_aa": "{$competitor1}",
        "name_de": "{$competitor1}",
        "name_nl": "{$competitor1}",
        "name_pl": "{$competitor1}",
        "name_ro": "{$competitor1}",
        "name_ru": "{$competitor1}",
        "name_tr": "{$competitor1}",
        "name_se": "{$competitor1}",
        "name_el": "{$competitor1}",
        "name_es": "{$competitor1}",
        "name_hr": "{$competitor1}",
        "name_fr": "{$competitor1}"
    },
    "406^prematch": {
        "name_en": "2:0 4+",
        "name_aa": "2:0 4+",
        "name_de": "2:0 4+",
        "name_nl": "2:0 4+",
        "name_pl": "2:0 4+",
        "name_ro": "2:0 4+",
        "name_ru": "2:0 4+",
        "name_tr": "2:0 4+",
        "name_se": "2:0 4+",
        "name_el": "2:0 4+",
        "name_es": "2:0 4+",
        "name_hr": "2:0 4+",
        "name_fr": "2:0 4+"
    },
    "240^prematch": {
        "name_en": "8:7",
        "name_aa": "8:7",
        "name_de": "8:7",
        "name_nl": "8:7",
        "name_pl": "8:7",
        "name_ro": "8:7",
        "name_ru": "8:7",
        "name_tr": "8:7",
        "name_se": "8:7",
        "name_el": "8:7",
        "name_es": "8:7",
        "name_hr": "8:7",
        "name_fr": "8:7"
    },
    "54^prematch": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "724^prematch": {
        "name_en": "10",
        "name_aa": "10",
        "name_de": "10",
        "name_nl": "10",
        "name_pl": "10",
        "name_ro": "10",
        "name_ru": "10",
        "name_tr": "10",
        "name_se": "10",
        "name_el": "10",
        "name_es": "10",
        "name_hr": "10",
        "name_fr": "10"
    },
    "814^prematch": {
        "name_en": "{$competitor1} goal & {$competitor1}",
        "name_aa": "{$competitor1} goal & {$competitor1}",
        "name_de": "{$competitor1} Tor & {$competitor1}",
        "name_nl": "doelpunt {$competitor1} & {$competitor1}",
        "name_pl": "{$competitor1} goal & {$competitor1}",
        "name_ro": "{$competitor1} goal & {$competitor1}",
        "name_ru": "{$competitor1} goal & {$competitor1}",
        "name_tr": "{$competitor1} golü & {$competitor1} kazanır",
        "name_se": "{$competitor1} goal & {$competitor1}",
        "name_el": "{$competitor1} goal & {$competitor1}",
        "name_es": "{$competitor1} goal & {$competitor1}",
        "name_hr": "{$competitor1} gol i {$competitor1} pobjeda",
        "name_fr": "but {$competitor1} & {$competitor1}"
    },
    "1729^prematch": {
        "name_en": "draw/{$competitor2} & over {total}",
        "name_aa": "draw/{$competitor2} & over {total}",
        "name_de": "unentschieden/{$competitor2} & über {total}",
        "name_nl": "gelijkspel/{$competitor2} & boven {total}",
        "name_pl": "draw/{$competitor2} & over {total}",
        "name_ro": "draw/{$competitor2} & over {total}",
        "name_ru": "draw/{$competitor2} & over {total}",
        "name_tr": "beraberlik/{$competitor2} & {total} üstü",
        "name_se": "draw/{$competitor2} & over {total}",
        "name_el": "draw/{$competitor2} & over {total}",
        "name_es": "draw/{$competitor2} & over {total}",
        "name_hr": "neodlučeno/{$competitor2} i više {total}",
        "name_fr": "Match nul/{$competitor2} & plus de {total}"
    },
    "39^prematch": {
        "name_en": "3",
        "name_aa": "3",
        "name_de": "3",
        "name_nl": "3",
        "name_pl": "3",
        "name_ro": "3",
        "name_ru": "3",
        "name_tr": "3",
        "name_se": "3",
        "name_el": "3",
        "name_es": "3",
        "name_hr": "3",
        "name_fr": "3"
    },
    "85^prematch": {
        "name_en": "0",
        "name_aa": "0",
        "name_de": "0",
        "name_nl": "0",
        "name_pl": "0",
        "name_ro": "0",
        "name_ru": "0",
        "name_tr": "0",
        "name_se": "0",
        "name_el": "0",
        "name_es": "0",
        "name_hr": "0",
        "name_fr": "0"
    },
    "17^prematch": {
        "name_en": "4:3",
        "name_aa": "4:3",
        "name_de": "4:3",
        "name_nl": "4:3",
        "name_pl": "4:3",
        "name_ro": "4:3",
        "name_ru": "4:3",
        "name_tr": "4:3",
        "name_se": "4:3",
        "name_el": "4:3",
        "name_es": "4:3",
        "name_hr": "4:3",
        "name_fr": "4:3"
    },
    "1085^prematch": {
        "name_en": "31+",
        "name_aa": "31+",
        "name_de": "31+",
        "name_nl": "31+",
        "name_pl": "31+",
        "name_ro": "31+",
        "name_ru": "31+",
        "name_tr": "31+",
        "name_se": "31+",
        "name_el": "31+",
        "name_es": "31+",
        "name_hr": "31+",
        "name_fr": "31+"
    },
    "185^prematch": {
        "name_en": "6:5",
        "name_aa": "6:5",
        "name_de": "6:5",
        "name_nl": "6:5",
        "name_pl": "6:5",
        "name_ro": "6:5",
        "name_ru": "6:5",
        "name_tr": "6:5",
        "name_se": "6:5",
        "name_el": "6:5",
        "name_es": "6:5",
        "name_hr": "6:5",
        "name_fr": "6:5"
    },
    "1694410000^prematch": {
        "name_en": "{$competitor1}",
        "name_aa": "{$competitor1}",
        "name_de": "{$competitor1}",
        "name_nl": "{$competitor1}",
        "name_pl": "{$competitor1}",
        "name_ro": "{$competitor1}",
        "name_ru": "{$competitor1}",
        "name_tr": "{$competitor1}",
        "name_se": "{$competitor1}",
        "name_el": "{$competitor1}",
        "name_es": "{$competitor1}",
        "name_hr": "{$competitor1}",
        "name_fr": "{$competitor1}"
    },
    "1139^prematch": {
        "name_en": "26-40",
        "name_aa": "26-40",
        "name_de": "26-40",
        "name_nl": "26-40",
        "name_pl": "26-40",
        "name_ro": "26-40",
        "name_ru": "26-40",
        "name_tr": "26-40",
        "name_se": "26-40",
        "name_el": "26-40",
        "name_es": "26-40",
        "name_hr": "26-40",
        "name_fr": "26-40"
    },
    "728^prematch": {
        "name_en": "12+",
        "name_aa": "12+",
        "name_de": "12+",
        "name_nl": "12+",
        "name_pl": "12+",
        "name_ro": "12+",
        "name_ru": "12+",
        "name_tr": "12+",
        "name_se": "12+",
        "name_el": "12+",
        "name_es": "12+",
        "name_hr": "12+",
        "name_fr": "12+"
    },
    "70900000^prematch": {
        "name_en": "Draw",
        "name_aa": "Draw",
        "name_de": "Draw",
        "name_nl": "Draw",
        "name_pl": "Draw",
        "name_ro": "Draw",
        "name_ru": "Draw",
        "name_tr": "Draw",
        "name_se": "Draw",
        "name_el": "Draw",
        "name_es": "Draw",
        "name_hr": "Draw",
        "name_fr": "Draw"
    },
    "1869^prematch": {
        "name_en": "draw/{$competitor2} & 3",
        "name_aa": "draw/{$competitor2} & 3",
        "name_de": "unentschieden/{$competitor2} & 3",
        "name_nl": "gelijkspel/{$competitor2} & 3",
        "name_pl": "draw/{$competitor2} & 3",
        "name_ro": "draw/{$competitor2} & 3",
        "name_ru": "draw/{$competitor2} & 3",
        "name_tr": "beraberlik/{$competitor2} & 3",
        "name_se": "draw/{$competitor2} & 3",
        "name_el": "draw/{$competitor2} & 3",
        "name_es": "draw/{$competitor2} & 3",
        "name_hr": "neodlučeno/{$competitor2} i 3",
        "name_fr": "Match nul/{$competitor2} & 3"
    },
    "800^prematch": {
        "name_en": "draw & over {total}",
        "name_aa": "draw & over {total}",
        "name_de": "unentschieden & über {total}",
        "name_nl": "gelijkspel & boven {total}",
        "name_pl": "draw & over {total}",
        "name_ro": "draw & over {total}",
        "name_ru": "draw & over {total}",
        "name_tr": "beraberlik & {total} üstü",
        "name_se": "draw & over {total}",
        "name_el": "draw & over {total}",
        "name_es": "draw & over {total}",
        "name_hr": "neodlučeno i više od {total}",
        "name_fr": "Match nul & plus de {total}"
    },
    "1875^prematch": {
        "name_en": "draw/{$competitor1} & 4",
        "name_aa": "draw/{$competitor1} & 4",
        "name_de": "unentschieden/{$competitor1} & 4",
        "name_nl": "gelijkspel/{$competitor1} & 4",
        "name_pl": "draw/{$competitor1} & 4",
        "name_ro": "draw/{$competitor1} & 4",
        "name_ru": "draw/{$competitor1} & 4",
        "name_tr": "beraberlik/{$competitor1} & 4",
        "name_se": "draw/{$competitor1} & 4",
        "name_el": "draw/{$competitor1} & 4",
        "name_es": "draw/{$competitor1} & 4",
        "name_hr": "neodlučeno/{$competitor1} i 4",
        "name_fr": "Match nul/{$competitor1} & 4"
    },
    "1857^prematch": {
        "name_en": "draw/{$competitor2} & 1",
        "name_aa": "draw/{$competitor2} & 1",
        "name_de": "unentschieden/{$competitor2} & 1",
        "name_nl": "gelijkspel/{$competitor2} & 1",
        "name_pl": "draw/{$competitor2} & 1",
        "name_ro": "draw/{$competitor2} & 1",
        "name_ru": "draw/{$competitor2} & 1",
        "name_tr": "beraberlik/{$competitor2} & 1",
        "name_se": "draw/{$competitor2} & 1",
        "name_el": "draw/{$competitor2} & 1",
        "name_es": "draw/{$competitor2} & 1",
        "name_hr": "neodlučeno/{$competitor2} i 1",
        "name_fr": "Match nul/{$competitor2} & 1"
    },
    "197^prematch": {
        "name_en": "7:5",
        "name_aa": "7:5",
        "name_de": "7:5",
        "name_nl": "7:5",
        "name_pl": "7:5",
        "name_ro": "7:5",
        "name_ru": "7:5",
        "name_tr": "7:5",
        "name_se": "7:5",
        "name_el": "7:5",
        "name_es": "7:5",
        "name_hr": "7:5",
        "name_fr": "7:5"
    },
    "1253^prematch": {
        "name_en": "25-29",
        "name_aa": "25-29",
        "name_de": "25-29",
        "name_nl": "25-29",
        "name_pl": "25-29",
        "name_ro": "25-29",
        "name_ru": "25-29",
        "name_tr": "25-29",
        "name_se": "25-29",
        "name_el": "25-29",
        "name_es": "25-29",
        "name_hr": "25-29",
        "name_fr": "25-29"
    },
    "70790000^prematch": {
        "name_en": "1-2",
        "name_aa": "1-2",
        "name_de": "1-2",
        "name_nl": "1-2",
        "name_pl": "1-2",
        "name_ro": "1-2",
        "name_ru": "1-2",
        "name_tr": "1-2",
        "name_se": "1-2",
        "name_el": "1-2",
        "name_es": "1-2",
        "name_hr": "1-2",
        "name_fr": "1-2"
    },
    "861^prematch": {
        "name_en": "{$competitor1}/{$competitor1}",
        "name_aa": "{$competitor1}/{$competitor1}",
        "name_de": "{$competitor1}/{$competitor1}",
        "name_nl": "{$competitor1}/{$competitor1}",
        "name_pl": "{$competitor1}/{$competitor1}",
        "name_ro": "{$competitor1}/{$competitor1}",
        "name_ru": "{$competitor1}/{$competitor1}",
        "name_tr": "{$competitor1}/{$competitor1}",
        "name_se": "{$competitor1}/{$competitor1}",
        "name_el": "{$competitor1}/{$competitor1}",
        "name_es": "{$competitor1}/{$competitor1}",
        "name_hr": "{$competitor1}/{$competitor1}",
        "name_fr": "{$competitor1}/{$competitor1}"
    },
    "40^prematch": {
        "name_en": "4",
        "name_aa": "4",
        "name_de": "4",
        "name_nl": "4",
        "name_pl": "4",
        "name_ro": "4",
        "name_ru": "4",
        "name_tr": "4",
        "name_se": "4",
        "name_el": "4",
        "name_es": "4",
        "name_hr": "4",
        "name_fr": "4"
    },
    "89^prematch": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "2038^prematch": {
        "name_en": "{$competitor2} ({-hcp}) & under {total}",
        "name_aa": "{$competitor2} ({-hcp}) & under {total}",
        "name_de": "{$competitor2} ({-hcp}) & under {total}",
        "name_nl": "{$competitor2} ({-hcp}) & under {total}",
        "name_pl": "{$competitor2} ({-hcp}) & under {total}",
        "name_ro": "{$competitor2} ({-hcp}) & under {total}",
        "name_ru": "{$competitor2} ({-hcp}) & under {total}",
        "name_tr": "{$competitor2} ({-hcp}) & under {total}",
        "name_se": "{$competitor2} ({-hcp}) & under {total}",
        "name_el": "{$competitor2} ({-hcp}) & under {total}",
        "name_es": "{$competitor2} ({-hcp}) & under {total}",
        "name_hr": "{$competitor2} ({-hcp}) & under {total}",
        "name_fr": "{$competitor2} ({-hcp}) & under {total}"
    },
    "949^prematch": {
        "name_en": "draw",
        "name_aa": "draw",
        "name_de": "unentschieden",
        "name_nl": "gelijkspel",
        "name_pl": "draw",
        "name_ro": "draw",
        "name_ru": "draw",
        "name_tr": "beraberlik",
        "name_se": "draw",
        "name_el": "draw",
        "name_es": "draw",
        "name_hr": "neodlučeno",
        "name_fr": "Match nul"
    },
    "1640^prematch": {
        "name_en": "{$competitor1} with try",
        "name_aa": "{$competitor1} with try",
        "name_de": "{$competitor1} mit Versuch",
        "name_nl": "{$competitor1} met try",
        "name_pl": "{$competitor1} with try",
        "name_ro": "{$competitor1} with try",
        "name_ru": "{$competitor1} with try",
        "name_tr": "{$competitor1} try ile",
        "name_se": "{$competitor1} with try",
        "name_el": "{$competitor1} with try",
        "name_es": "{$competitor1} with try",
        "name_hr": "{$competitor1} polaganjem",
        "name_fr": "{$competitor1} avec un essai"
    },
    "1360^prematch": {
        "name_en": "{$competitor2} & 3",
        "name_aa": "{$competitor2} & 3",
        "name_de": "{$competitor2} & 3",
        "name_nl": "{$competitor2} & 3",
        "name_pl": "{$competitor2} & 3",
        "name_ro": "{$competitor2} & 3",
        "name_ru": "{$competitor2} & 3",
        "name_tr": "{$competitor2} & 3",
        "name_se": "{$competitor2} & 3",
        "name_el": "{$competitor2} & 3",
        "name_es": "{$competitor2} & 3",
        "name_hr": "{$competitor2} & 3",
        "name_fr": "{$competitor2} & 3"
    },
    "1624^prematch": {
        "name_en": "equal",
        "name_aa": "equal",
        "name_de": "gleich",
        "name_nl": "gelijk",
        "name_pl": "equal",
        "name_ro": "equal",
        "name_ru": "equal",
        "name_tr": "eşit",
        "name_se": "equal",
        "name_el": "equal",
        "name_es": "equal",
        "name_hr": "jednako",
        "name_fr": "égal"
    },
    "973^prematch": {
        "name_en": "{$competitor1} & over {total}",
        "name_aa": "{$competitor1} & over {total}",
        "name_de": "{$competitor1} & over {total}",
        "name_nl": "{$competitor1} & over {total}",
        "name_pl": "{$competitor1} & over {total}",
        "name_ro": "{$competitor1} & over {total}",
        "name_ru": "{$competitor1} & over {total}",
        "name_tr": "{$competitor1} & over {total}",
        "name_se": "{$competitor1} & over {total}",
        "name_el": "{$competitor1} & over {total}",
        "name_es": "{$competitor1} & over {total}",
        "name_hr": "{$competitor1} & over {total}",
        "name_fr": "{$competitor1} & over {total}"
    },
    "1140^prematch": {
        "name_en": "41+",
        "name_aa": "41+",
        "name_de": "41+",
        "name_nl": "41+",
        "name_pl": "41+",
        "name_ro": "41+",
        "name_ru": "41+",
        "name_tr": "41+",
        "name_se": "41+",
        "name_el": "41+",
        "name_es": "41+",
        "name_hr": "41+",
        "name_fr": "41+"
    },
    "878^prematch": {
        "name_en": "6:7",
        "name_aa": "6:7",
        "name_de": "6:7",
        "name_nl": "6:7",
        "name_pl": "6:7",
        "name_ro": "6:7",
        "name_ru": "6:7",
        "name_tr": "6:7",
        "name_se": "6:7",
        "name_el": "6:7",
        "name_es": "6:7",
        "name_hr": "6:7",
        "name_fr": "6:7"
    },
    "732^prematch": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "1865^prematch": {
        "name_en": "{$competitor2}/{$competitor2} & 2",
        "name_aa": "{$competitor2}/{$competitor2} & 2",
        "name_de": "{$competitor2}/{$competitor2} & 2",
        "name_nl": "{$competitor2}/{$competitor2} & 2",
        "name_pl": "{$competitor2}/{$competitor2} & 2",
        "name_ro": "{$competitor2}/{$competitor2} & 2",
        "name_ru": "{$competitor2}/{$competitor2} & 2",
        "name_tr": "{$competitor2}/{$competitor2} & 2",
        "name_se": "{$competitor2}/{$competitor2} & 2",
        "name_el": "{$competitor2}/{$competitor2} & 2",
        "name_es": "{$competitor2}/{$competitor2} & 2",
        "name_hr": "{$competitor2}/{$competitor2} i 2",
        "name_fr": "{$competitor2}/{$competitor2} & 2"
    },
    "1173^prematch": {
        "name_en": "2:1",
        "name_aa": "2:1",
        "name_de": "2:1",
        "name_nl": "2:1",
        "name_pl": "2:1",
        "name_ro": "2:1",
        "name_ru": "2:1",
        "name_tr": "2:1",
        "name_se": "2:1",
        "name_el": "2:1",
        "name_es": "2:1",
        "name_hr": "2:1",
        "name_fr": "2:1"
    },
    "76^prematch": {
        "name_en": "no",
        "name_aa": "no",
        "name_de": "nein",
        "name_nl": "nee",
        "name_pl": "no",
        "name_ro": "no",
        "name_ru": "no",
        "name_tr": "yok",
        "name_se": "no",
        "name_el": "no",
        "name_es": "no",
        "name_hr": "ne",
        "name_fr": "non"
    },
    "824^prematch": {
        "name_en": "{$competitor2} goal & {$competitor2}",
        "name_aa": "{$competitor2} goal & {$competitor2}",
        "name_de": "{$competitor2} Tor & {$competitor2}",
        "name_nl": "doelpunt {$competitor2} & {$competitor2}",
        "name_pl": "{$competitor2} goal & {$competitor2}",
        "name_ro": "{$competitor2} goal & {$competitor2}",
        "name_ru": "{$competitor2} goal & {$competitor2}",
        "name_tr": "{$competitor2} golü & {$competitor2} kazanır",
        "name_se": "{$competitor2} goal & {$competitor2}",
        "name_el": "{$competitor2} goal & {$competitor2}",
        "name_es": "{$competitor2} goal & {$competitor2}",
        "name_hr": "{$competitor2} gol i {$competitor2} pobjeda",
        "name_fr": "but {$competitor2} & {$competitor2}"
    },
    "91^prematch": {
        "name_en": "3+",
        "name_aa": "3+",
        "name_de": "3+",
        "name_nl": "3+",
        "name_pl": "3+",
        "name_ro": "3+",
        "name_ru": "3+",
        "name_tr": "3+",
        "name_se": "3+",
        "name_el": "3+",
        "name_es": "3+",
        "name_hr": "3+",
        "name_fr": "3+"
    },
    "2037^prematch": {
        "name_en": "{$competitor2} ({-hcp}) & over {total}",
        "name_aa": "{$competitor2} ({-hcp}) & over {total}",
        "name_de": "{$competitor2} ({-hcp}) & over {total}",
        "name_nl": "{$competitor2} ({-hcp}) & over {total}",
        "name_pl": "{$competitor2} ({-hcp}) & over {total}",
        "name_ro": "{$competitor2} ({-hcp}) & over {total}",
        "name_ru": "{$competitor2} ({-hcp}) & over {total}",
        "name_tr": "{$competitor2} ({-hcp}) & over {total}",
        "name_se": "{$competitor2} ({-hcp}) & over {total}",
        "name_el": "{$competitor2} ({-hcp}) & over {total}",
        "name_es": "{$competitor2} ({-hcp}) & over {total}",
        "name_hr": "{$competitor2} ({-hcp}) & over {total}",
        "name_fr": "{$competitor2} ({-hcp}) & over {total}"
    },
    "344^prematch": {
        "name_en": "0:0 3:0",
        "name_aa": "0:0 3:0",
        "name_de": "0:0 3:0",
        "name_nl": "0:0 3:0",
        "name_pl": "0:0 3:0",
        "name_ro": "0:0 3:0",
        "name_ru": "0:0 3:0",
        "name_tr": "0:0 3:0",
        "name_se": "0:0 3:0",
        "name_el": "0:0 3:0",
        "name_es": "0:0 3:0",
        "name_hr": "0:0 3:0",
        "name_fr": "0:0 3:0"
    },
    "1138^prematch": {
        "name_en": "11-25",
        "name_aa": "11-25",
        "name_de": "11-25",
        "name_nl": "11-25",
        "name_pl": "11-25",
        "name_ro": "11-25",
        "name_ru": "11-25",
        "name_tr": "11-25",
        "name_se": "11-25",
        "name_el": "11-25",
        "name_es": "11-25",
        "name_hr": "11-25",
        "name_fr": "11-25"
    },
    "1177^prematch": {
        "name_en": "2:5",
        "name_aa": "2:5",
        "name_de": "2:5",
        "name_nl": "2:5",
        "name_pl": "2:5",
        "name_ro": "2:5",
        "name_ru": "2:5",
        "name_tr": "2:5",
        "name_se": "2:5",
        "name_el": "2:5",
        "name_es": "2:5",
        "name_hr": "2:5",
        "name_fr": "2:5"
    },
    "562^prematch": {
        "name_en": "2:2",
        "name_aa": "2:2",
        "name_de": "2:2",
        "name_nl": "2:2",
        "name_pl": "2:2",
        "name_ro": "2:2",
        "name_ru": "2:2",
        "name_tr": "2:2",
        "name_se": "2:2",
        "name_el": "2:2",
        "name_es": "2:2",
        "name_hr": "2:2",
        "name_fr": "2:2"
    },
    "202^prematch": {
        "name_en": "2:7",
        "name_aa": "2:7",
        "name_de": "2:7",
        "name_nl": "2:7",
        "name_pl": "2:7",
        "name_ro": "2:7",
        "name_ru": "2:7",
        "name_tr": "2:7",
        "name_se": "2:7",
        "name_el": "2:7",
        "name_es": "2:7",
        "name_hr": "2:7",
        "name_fr": "2:7"
    },
    "163^prematch": {
        "name_en": "151-160",
        "name_aa": "151-160",
        "name_de": "151-160",
        "name_nl": "151-160",
        "name_pl": "151-160",
        "name_ro": "151-160",
        "name_ru": "151-160",
        "name_tr": "151-160",
        "name_se": "151-160",
        "name_el": "151-160",
        "name_es": "151-160",
        "name_hr": "151-160",
        "name_fr": "151-160"
    },
    "23^prematch": {
        "name_en": "5:1",
        "name_aa": "5:1",
        "name_de": "5:1",
        "name_nl": "5:1",
        "name_pl": "5:1",
        "name_ro": "5:1",
        "name_ru": "5:1",
        "name_tr": "5:1",
        "name_se": "5:1",
        "name_el": "5:1",
        "name_es": "5:1",
        "name_hr": "5:1",
        "name_fr": "5:1"
    },
    "866^prematch": {
        "name_en": "6:1",
        "name_aa": "6:1",
        "name_de": "6:1",
        "name_nl": "6:1",
        "name_pl": "6:1",
        "name_ro": "6:1",
        "name_ru": "6:1",
        "name_tr": "6:1",
        "name_se": "6:1",
        "name_el": "6:1",
        "name_es": "6:1",
        "name_hr": "6:1",
        "name_fr": "6:1"
    },
    "70920000^prematch": {
        "name_en": "{$competitor1}",
        "name_aa": "{$competitor1}",
        "name_de": "{$competitor1}",
        "name_nl": "{$competitor1}",
        "name_pl": "{$competitor1}",
        "name_ro": "{$competitor1}",
        "name_ru": "{$competitor1}",
        "name_tr": "{$competitor1}",
        "name_se": "{$competitor1}",
        "name_el": "{$competitor1}",
        "name_es": "{$competitor1}",
        "name_hr": "{$competitor1}",
        "name_fr": "{$competitor1}"
    },
    "366^prematch": {
        "name_en": "0:2 1:2",
        "name_aa": "0:2 1:2",
        "name_de": "0:2 1:2",
        "name_nl": "0:2 1:2",
        "name_pl": "0:2 1:2",
        "name_ro": "0:2 1:2",
        "name_ru": "0:2 1:2",
        "name_tr": "0:2 1:2",
        "name_se": "0:2 1:2",
        "name_el": "0:2 1:2",
        "name_es": "0:2 1:2",
        "name_hr": "0:2 1:2",
        "name_fr": "0:2 1:2"
    },
    "117^prematch": {
        "name_en": "{$competitor2} by 2",
        "name_aa": "{$competitor2} by 2",
        "name_de": "{$competitor2} by 2",
        "name_nl": "{$competitor2} by 2",
        "name_pl": "{$competitor2} by 2",
        "name_ro": "{$competitor2} by 2",
        "name_ru": "{$competitor2} by 2",
        "name_tr": "{$competitor2} by 2",
        "name_se": "{$competitor2} by 2",
        "name_el": "{$competitor2} by 2",
        "name_es": "{$competitor2} by 2",
        "name_hr": "{$competitor2} by 2",
        "name_fr": "{$competitor2} by 2"
    },
    "318^prematch": {
        "name_en": "2:4",
        "name_aa": "2:4",
        "name_de": "2:4",
        "name_nl": "2:4",
        "name_pl": "2:4",
        "name_ro": "2:4",
        "name_ru": "2:4",
        "name_tr": "2:4",
        "name_se": "2:4",
        "name_el": "2:4",
        "name_es": "2:4",
        "name_hr": "2:4",
        "name_fr": "2:4"
    },
    "1414^prematch": {
        "name_en": "{$competitor1} & 12",
        "name_aa": "{$competitor1} & 12",
        "name_de": "{$competitor1} & 12",
        "name_nl": "{$competitor1} & 12",
        "name_pl": "{$competitor1} & 12",
        "name_ro": "{$competitor1} & 12",
        "name_ru": "{$competitor1} & 12",
        "name_tr": "{$competitor1} & 12",
        "name_se": "{$competitor1} & 12",
        "name_el": "{$competitor1} & 12",
        "name_es": "{$competitor1} & 12",
        "name_hr": "{$competitor1} & 12",
        "name_fr": "{$competitor1} & 12"
    },
    "1197^prematch": {
        "name_en": "1:2",
        "name_aa": "1:2",
        "name_de": "1:2",
        "name_nl": "1:2",
        "name_pl": "1:2",
        "name_ro": "1:2",
        "name_ru": "1:2",
        "name_tr": "1:2",
        "name_se": "1:2",
        "name_el": "1:2",
        "name_es": "1:2",
        "name_hr": "1:2",
        "name_fr": "1:2"
    },
    "434^prematch": {
        "name_en": "{$competitor2}/{$competitor2}",
        "name_aa": "{$competitor2}/{$competitor2}",
        "name_de": "{$competitor2}/{$competitor2}",
        "name_nl": "{$competitor2}/{$competitor2}",
        "name_pl": "{$competitor2}/{$competitor2}",
        "name_ro": "{$competitor2}/{$competitor2}",
        "name_ru": "{$competitor2}/{$competitor2}",
        "name_tr": "{$competitor2}/{$competitor2}",
        "name_se": "{$competitor2}/{$competitor2}",
        "name_el": "{$competitor2}/{$competitor2}",
        "name_es": "{$competitor2}/{$competitor2}",
        "name_hr": "{$competitor2}/{$competitor2}",
        "name_fr": "{$competitor2}/{$competitor2}"
    },
    "806^prematch": {
        "name_en": "no/no",
        "name_aa": "no/no",
        "name_de": "nein/nein",
        "name_nl": "nee/nee",
        "name_pl": "no/no",
        "name_ro": "no/no",
        "name_ru": "no/no",
        "name_tr": "yok/yok",
        "name_se": "no/no",
        "name_el": "no/no",
        "name_es": "no/no",
        "name_hr": "ne/ne",
        "name_fr": "non/non"
    },
    "52^prematch": {
        "name_en": "3",
        "name_aa": "3",
        "name_de": "3",
        "name_nl": "3",
        "name_pl": "3",
        "name_ro": "3",
        "name_ru": "3",
        "name_tr": "3",
        "name_se": "3",
        "name_el": "3",
        "name_es": "3",
        "name_hr": "3",
        "name_fr": "3"
    },
    "794^prematch": {
        "name_en": "{$competitor1} & under {total}",
        "name_aa": "{$competitor1} & under {total}",
        "name_de": "{$competitor1} & unter {total}",
        "name_nl": "{$competitor1} & onder {total}",
        "name_pl": "{$competitor1} & under {total}",
        "name_ro": "{$competitor1} & under {total}",
        "name_ru": "{$competitor1} & under {total}",
        "name_tr": "{$competitor1} & {total} altı",
        "name_se": "{$competitor1} & under {total}",
        "name_el": "{$competitor1} & under {total}",
        "name_es": "{$competitor1} & under {total}",
        "name_hr": "{$competitor1} i manje od {total}",
        "name_fr": "{$competitor1} & moins de {total}"
    },
    "1376^prematch": {
        "name_en": "{$competitor1} by decision",
        "name_aa": "{$competitor1} by decision",
        "name_de": "{$competitor1} by decision",
        "name_nl": "{$competitor1} by decision",
        "name_pl": "{$competitor1} by decision",
        "name_ro": "{$competitor1} by decision",
        "name_ru": "{$competitor1} by decision",
        "name_tr": "{$competitor1} by decision",
        "name_se": "{$competitor1} by decision",
        "name_el": "{$competitor1} by decision",
        "name_es": "{$competitor1} by decision",
        "name_hr": "{$competitor1} by decision",
        "name_fr": "{$competitor1} by decision"
    },
    "139^prematch": {
        "name_en": "{$competitor1} by 1-5",
        "name_aa": "{$competitor1} by 1-5",
        "name_de": "{$competitor1} by 1-5",
        "name_nl": "{$competitor1} by 1-5",
        "name_pl": "{$competitor1} by 1-5",
        "name_ro": "{$competitor1} by 1-5",
        "name_ru": "{$competitor1} by 1-5",
        "name_tr": "{$competitor1} by 1-5",
        "name_se": "{$competitor1} by 1-5",
        "name_el": "{$competitor1} by 1-5",
        "name_es": "{$competitor1} by 1-5",
        "name_hr": "{$competitor1} by 1-5",
        "name_fr": "{$competitor1} by 1-5"
    },
    "1296^prematch": {
        "name_en": "6-10",
        "name_aa": "6-10",
        "name_de": "6-10",
        "name_nl": "6-10",
        "name_pl": "6-10",
        "name_ro": "6-10",
        "name_ru": "6-10",
        "name_tr": "6-10",
        "name_se": "6-10",
        "name_el": "6-10",
        "name_es": "6-10",
        "name_hr": "6-10",
        "name_fr": "6-10"
    },
    "181^prematch": {
        "name_en": "6:1",
        "name_aa": "6:1",
        "name_de": "6:1",
        "name_nl": "6:1",
        "name_pl": "6:1",
        "name_ro": "6:1",
        "name_ru": "6:1",
        "name_tr": "6:1",
        "name_se": "6:1",
        "name_el": "6:1",
        "name_es": "6:1",
        "name_hr": "6:1",
        "name_fr": "6:1"
    },
    "476^prematch": {
        "name_en": "0:2",
        "name_aa": "0:2",
        "name_de": "0:2",
        "name_nl": "0:2",
        "name_pl": "0:2",
        "name_ro": "0:2",
        "name_ru": "0:2",
        "name_tr": "0:2",
        "name_se": "0:2",
        "name_el": "0:2",
        "name_es": "0:2",
        "name_hr": "0:2",
        "name_fr": "0:2"
    },
    "1255^prematch": {
        "name_en": "30-34",
        "name_aa": "30-34",
        "name_de": "30-34",
        "name_nl": "30-34",
        "name_pl": "30-34",
        "name_ro": "30-34",
        "name_ru": "30-34",
        "name_tr": "30-34",
        "name_se": "30-34",
        "name_el": "30-34",
        "name_es": "30-34",
        "name_hr": "30-34",
        "name_fr": "30-34"
    },
    "70800000^prematch": {
        "name_en": "X-1",
        "name_aa": "X-1",
        "name_de": "X-1",
        "name_nl": "X-1",
        "name_pl": "X-1",
        "name_ro": "X-1",
        "name_ru": "X-1",
        "name_tr": "X-1",
        "name_se": "X-1",
        "name_el": "X-1",
        "name_es": "X-1",
        "name_hr": "X-1",
        "name_fr": "X-1"
    },
    "124^prematch": {
        "name_en": "{$competitor1} by 6-10",
        "name_aa": "{$competitor1} by 6-10",
        "name_de": "{$competitor1} by 6-10",
        "name_nl": "{$competitor1} by 6-10",
        "name_pl": "{$competitor1} by 6-10",
        "name_ro": "{$competitor1} by 6-10",
        "name_ru": "{$competitor1} by 6-10",
        "name_tr": "{$competitor1} by 6-10",
        "name_se": "{$competitor1} by 6-10",
        "name_el": "{$competitor1} by 6-10",
        "name_es": "{$competitor1} by 6-10",
        "name_hr": "{$competitor1} by 6-10",
        "name_fr": "{$competitor1} by 6-10"
    },
    "1753^prematch": {
        "name_en": "0:4, 0:5 or 0:6",
        "name_aa": "0:4, 0:5 or 0:6",
        "name_de": "0:4, 0:5 oder 0:6",
        "name_nl": "0:4, 0:5 of 0:6",
        "name_pl": "0:4, 0:5 or 0:6",
        "name_ro": "0:4, 0:5 or 0:6",
        "name_ru": "0:4, 0:5 or 0:6",
        "name_tr": "0:4, 0:5 veya 0:6",
        "name_se": "0:4, 0:5 or 0:6",
        "name_el": "0:4, 0:5 or 0:6",
        "name_es": "0:4, 0:5 or 0:6",
        "name_hr": "0:4, 0:5 ili 0:6",
        "name_fr": "0:4, 0:5 ou 0:6"
    },
    "1364^prematch": {
        "name_en": "{$competitor1} & 3",
        "name_aa": "{$competitor1} & 3",
        "name_de": "{$competitor1} & 3",
        "name_nl": "{$competitor1} & 3",
        "name_pl": "{$competitor1} & 3",
        "name_ro": "{$competitor1} & 3",
        "name_ru": "{$competitor1} & 3",
        "name_tr": "{$competitor1} & 3",
        "name_se": "{$competitor1} & 3",
        "name_el": "{$competitor1} & 3",
        "name_es": "{$competitor1} & 3",
        "name_hr": "{$competitor1} & 3",
        "name_fr": "{$competitor1} & 3"
    },
    "788^prematch": {
        "name_en": "only {$competitor1}",
        "name_aa": "only {$competitor1}",
        "name_de": "nur {$competitor1}",
        "name_nl": "alleen {$competitor1}",
        "name_pl": "only {$competitor1}",
        "name_ro": "only {$competitor1}",
        "name_ru": "only {$competitor1}",
        "name_tr": "sadece {$competitor1}",
        "name_se": "only {$competitor1}",
        "name_el": "only {$competitor1}",
        "name_es": "only {$competitor1}",
        "name_hr": "samo {$competitor1}",
        "name_fr": "seulement {$competitor1}"
    },
    "1645^prematch": {
        "name_en": "{$competitor2} with drop goal",
        "name_aa": "{$competitor2} with drop goal",
        "name_de": "{$competitor2} mit Drop goal",
        "name_nl": "{$competitor2} met drop goal",
        "name_pl": "{$competitor2} with drop goal",
        "name_ro": "{$competitor2} with drop goal",
        "name_ru": "{$competitor2} with drop goal",
        "name_tr": "{$competitor2}şutla",
        "name_se": "{$competitor2} with drop goal",
        "name_el": "{$competitor2} with drop goal",
        "name_es": "{$competitor2} with drop goal",
        "name_hr": "{$competitor2} drop golom",
        "name_fr": "{$competitor2} avec un drop"
    },
    "1503^prematch": {
        "name_en": "4:4",
        "name_aa": "4:4",
        "name_de": "4:4",
        "name_nl": "4:4",
        "name_pl": "4:4",
        "name_ro": "4:4",
        "name_ru": "4:4",
        "name_tr": "4:4",
        "name_se": "4:4",
        "name_el": "4:4",
        "name_es": "4:4",
        "name_hr": "4:4",
        "name_fr": "4:4"
    },
    "1166^prematch": {
        "name_en": "1:1",
        "name_aa": "1:1",
        "name_de": "1:1",
        "name_nl": "1:1",
        "name_pl": "1:1",
        "name_ro": "1:1",
        "name_ru": "1:1",
        "name_tr": "1:1",
        "name_se": "1:1",
        "name_el": "1:1",
        "name_es": "1:1",
        "name_hr": "1:1",
        "name_fr": "1:1"
    },
    "1758^prematch": {
        "name_en": "other homewin",
        "name_aa": "other homewin",
        "name_de": "andere Sieg Heimmannschaft",
        "name_nl": "ander thuisoverwinning",
        "name_pl": "other homewin",
        "name_ro": "other homewin",
        "name_ru": "other homewin",
        "name_tr": "diğer ev sahibi galibiyeti",
        "name_se": "other homewin",
        "name_el": "other homewin",
        "name_es": "other homewin",
        "name_hr": "ostalo, pobjeda domaćina",
        "name_fr": "autre victoire domicile"
    },
    "1284^prematch": {
        "name_en": "{$competitor1} by 6-10",
        "name_aa": "{$competitor1} by 6-10",
        "name_de": "{$competitor1} by 6-10",
        "name_nl": "{$competitor1} by 6-10",
        "name_pl": "{$competitor1} by 6-10",
        "name_ro": "{$competitor1} by 6-10",
        "name_ru": "{$competitor1} by 6-10",
        "name_tr": "{$competitor1} by 6-10",
        "name_se": "{$competitor1} by 6-10",
        "name_el": "{$competitor1} by 6-10",
        "name_es": "{$competitor1} by 6-10",
        "name_hr": "{$competitor1} by 6-10",
        "name_fr": "{$competitor1} by 6-10"
    },
    "334^prematch": {
        "name_en": "0:0 1:0",
        "name_aa": "0:0 1:0",
        "name_de": "0:0 1:0",
        "name_nl": "0:0 1:0",
        "name_pl": "0:0 1:0",
        "name_ro": "0:0 1:0",
        "name_ru": "0:0 1:0",
        "name_tr": "0:0 1:0",
        "name_se": "0:0 1:0",
        "name_el": "0:0 1:0",
        "name_es": "0:0 1:0",
        "name_hr": "0:0 1:0",
        "name_fr": "0:0 1:0"
    },
    "160^prematch": {
        "name_en": "121-130",
        "name_aa": "121-130",
        "name_de": "121-130",
        "name_nl": "121-130",
        "name_pl": "121-130",
        "name_ro": "121-130",
        "name_ru": "121-130",
        "name_tr": "121-130",
        "name_se": "121-130",
        "name_el": "121-130",
        "name_es": "121-130",
        "name_hr": "121-130",
        "name_fr": "121-130"
    },
    "268^prematch": {
        "name_en": "10:1",
        "name_aa": "10:1",
        "name_de": "10:1",
        "name_nl": "10:1",
        "name_pl": "10:1",
        "name_ro": "10:1",
        "name_ru": "10:1",
        "name_tr": "10:1",
        "name_se": "10:1",
        "name_el": "10:1",
        "name_es": "10:1",
        "name_hr": "10:1",
        "name_fr": "10:1"
    },
    "1853^prematch": {
        "name_en": "{$competitor2}/{$competitor2} & over {total}",
        "name_aa": "{$competitor2}/{$competitor2} & over {total}",
        "name_de": "{$competitor2}/{$competitor2} & über {total}",
        "name_nl": "{$competitor2}/{$competitor2} & boven {total}",
        "name_pl": "{$competitor2}/{$competitor2} & over {total}",
        "name_ro": "{$competitor2}/{$competitor2} & over {total}",
        "name_ru": "{$competitor2}/{$competitor2} & over {total}",
        "name_tr": "{$competitor2}/{$competitor2} & {total} üstü",
        "name_se": "{$competitor2}/{$competitor2} & over {total}",
        "name_el": "{$competitor2}/{$competitor2} & over {total}",
        "name_es": "{$competitor2}/{$competitor2} & over {total}",
        "name_hr": "{$competitor2}/{$competitor2} i više {total}",
        "name_fr": "{$competitor2}/{$competitor2} & Plus de {total}"
    },
    "140^prematch": {
        "name_en": "{$competitor2} by 1-5",
        "name_aa": "{$competitor2} by 1-5",
        "name_de": "{$competitor2} by 1-5",
        "name_nl": "{$competitor2} by 1-5",
        "name_pl": "{$competitor2} by 1-5",
        "name_ro": "{$competitor2} by 1-5",
        "name_ru": "{$competitor2} by 1-5",
        "name_tr": "{$competitor2} by 1-5",
        "name_se": "{$competitor2} by 1-5",
        "name_el": "{$competitor2} by 1-5",
        "name_es": "{$competitor2} by 1-5",
        "name_hr": "{$competitor2} by 1-5",
        "name_fr": "{$competitor2} by 1-5"
    },
    "380^prematch": {
        "name_en": "1:0 2:0",
        "name_aa": "1:0 2:0",
        "name_de": "1:0 2:0",
        "name_nl": "1:0 2:0",
        "name_pl": "1:0 2:0",
        "name_ro": "1:0 2:0",
        "name_ru": "1:0 2:0",
        "name_tr": "1:0 2:0",
        "name_se": "1:0 2:0",
        "name_el": "1:0 2:0",
        "name_es": "1:0 2:0",
        "name_hr": "1:0 2:0",
        "name_fr": "1:0 2:0"
    },
    "79^prematch": {
        "name_en": "4",
        "name_aa": "4",
        "name_de": "4",
        "name_nl": "4",
        "name_pl": "4",
        "name_ro": "4",
        "name_ru": "4",
        "name_tr": "4",
        "name_se": "4",
        "name_el": "4",
        "name_es": "4",
        "name_hr": "4",
        "name_fr": "4"
    },
    "1494^prematch": {
        "name_en": "1:3",
        "name_aa": "1:3",
        "name_de": "1:3",
        "name_nl": "1:3",
        "name_pl": "1:3",
        "name_ro": "1:3",
        "name_ru": "1:3",
        "name_tr": "1:3",
        "name_se": "1:3",
        "name_el": "1:3",
        "name_es": "1:3",
        "name_hr": "1:3",
        "name_fr": "1:3"
    },
    "1041^prematch": {
        "name_en": "{$competitor1}",
        "name_aa": "{$competitor1}",
        "name_de": "{$competitor1}",
        "name_nl": "{$competitor1}",
        "name_pl": "{$competitor1}",
        "name_ro": "{$competitor1}",
        "name_ru": "{$competitor1}",
        "name_tr": "{$competitor1}",
        "name_se": "{$competitor1}",
        "name_el": "{$competitor1}",
        "name_es": "{$competitor1}",
        "name_hr": "{$competitor1}",
        "name_fr": "{$competitor1}"
    },
    "1260^prematch": {
        "name_en": "55-59",
        "name_aa": "55-59",
        "name_de": "55-59",
        "name_nl": "55-59",
        "name_pl": "55-59",
        "name_ro": "55-59",
        "name_ru": "55-59",
        "name_tr": "55-59",
        "name_se": "55-59",
        "name_el": "55-59",
        "name_es": "55-59",
        "name_hr": "55-59",
        "name_fr": "55-59"
    },
    "324^prematch": {
        "name_en": "other",
        "name_aa": "other",
        "name_de": "andere",
        "name_nl": "ander",
        "name_pl": "other",
        "name_ro": "other",
        "name_ru": "other",
        "name_tr": "diğer",
        "name_se": "other",
        "name_el": "other",
        "name_es": "other",
        "name_hr": "ostalo",
        "name_fr": "autre"
    },
    "1721^prematch": {
        "name_en": "{$competitor1}/{$competitor2} & no",
        "name_aa": "{$competitor1}/{$competitor2} & no",
        "name_de": "{$competitor1}/{$competitor2} & nein",
        "name_nl": "{$competitor1}/{$competitor2} & nee",
        "name_pl": "{$competitor1}/{$competitor2} & no",
        "name_ro": "{$competitor1}/{$competitor2} & no",
        "name_ru": "{$competitor1}/{$competitor2} & no",
        "name_tr": "{$competitor1}/{$competitor2} & yok",
        "name_se": "{$competitor1}/{$competitor2} & no",
        "name_el": "{$competitor1}/{$competitor2} & no",
        "name_es": "{$competitor1}/{$competitor2} & no",
        "name_hr": "{$competitor1}/{$competitor2}  i ne",
        "name_fr": "{$competitor1}/{$competitor2} & non"
    },
    "1366^prematch": {
        "name_en": "{$competitor1} & 5",
        "name_aa": "{$competitor1} & 5",
        "name_de": "{$competitor1} & 5",
        "name_nl": "{$competitor1} & 5",
        "name_pl": "{$competitor1} & 5",
        "name_ro": "{$competitor1} & 5",
        "name_ru": "{$competitor1} & 5",
        "name_tr": "{$competitor1} & 5",
        "name_se": "{$competitor1} & 5",
        "name_el": "{$competitor1} & 5",
        "name_es": "{$competitor1} & 5",
        "name_hr": "{$competitor1} & 5",
        "name_fr": "{$competitor1} & 5"
    },
    "199^prematch": {
        "name_en": "5:7",
        "name_aa": "5:7",
        "name_de": "5:7",
        "name_nl": "5:7",
        "name_pl": "5:7",
        "name_ro": "5:7",
        "name_ru": "5:7",
        "name_tr": "5:7",
        "name_se": "5:7",
        "name_el": "5:7",
        "name_es": "5:7",
        "name_hr": "5:7",
        "name_fr": "5:7"
    },
    "114^prematch": {
        "name_en": "{$competitor1} by 2",
        "name_aa": "{$competitor1} by 2",
        "name_de": "{$competitor1} by 2",
        "name_nl": "{$competitor1} by 2",
        "name_pl": "{$competitor1} by 2",
        "name_ro": "{$competitor1} by 2",
        "name_ru": "{$competitor1} by 2",
        "name_tr": "{$competitor1} by 2",
        "name_se": "{$competitor1} by 2",
        "name_el": "{$competitor1} by 2",
        "name_es": "{$competitor1} by 2",
        "name_hr": "{$competitor1} by 2",
        "name_fr": "{$competitor1} by 2"
    },
    "1805^prematch": {
        "name_en": "no goal",
        "name_aa": "no goal",
        "name_de": "kein Tor",
        "name_nl": "geen doelpunt",
        "name_pl": "no goal",
        "name_ro": "no goal",
        "name_ru": "no goal",
        "name_tr": "gol yok",
        "name_se": "no goal",
        "name_el": "no goal",
        "name_es": "no goal",
        "name_hr": "nema gola",
        "name_fr": "aucun but"
    },
    "83^prematch": {
        "name_en": "8",
        "name_aa": "8",
        "name_de": "8",
        "name_nl": "8",
        "name_pl": "8",
        "name_ro": "8",
        "name_ru": "8",
        "name_tr": "8",
        "name_se": "8",
        "name_el": "8",
        "name_es": "8",
        "name_hr": "8",
        "name_fr": "8"
    },
    "1825^prematch": {
        "name_en": "any extra inning",
        "name_aa": "any extra inning",
        "name_de": "jedes zusätzliches Inning",
        "name_nl": "extra inning(s)",
        "name_pl": "any extra inning",
        "name_ro": "any extra inning",
        "name_ru": "any extra inning",
        "name_tr": "uzatma devresinde",
        "name_se": "any extra inning",
        "name_el": "any extra inning",
        "name_es": "any extra inning",
        "name_hr": "bilo koji dodatni inning",
        "name_fr": "une manche supplémentaire"
    },
    "298^prematch": {
        "name_en": "10:11",
        "name_aa": "10:11",
        "name_de": "10:11",
        "name_nl": "10:11",
        "name_pl": "10:11",
        "name_ro": "10:11",
        "name_ru": "10:11",
        "name_tr": "10:11",
        "name_se": "10:11",
        "name_el": "10:11",
        "name_es": "10:11",
        "name_hr": "10:11",
        "name_fr": "10:11"
    },
    "1623^prematch": {
        "name_en": "3rd period",
        "name_aa": "3rd period",
        "name_de": "3. Periode",
        "name_nl": "3e periode",
        "name_pl": "3rd period",
        "name_ro": "3rd period",
        "name_ru": "3rd period",
        "name_tr": "Üçüncü periyot",
        "name_se": "3rd period",
        "name_el": "3rd period",
        "name_es": "3rd period",
        "name_hr": "3. period",
        "name_fr": "3ème période"
    },
    "1887^prematch": {
        "name_en": "{$competitor2}/{$competitor1} & 5+",
        "name_aa": "{$competitor2}/{$competitor1} & 5+",
        "name_de": "{$competitor2}/{$competitor1} & 5+",
        "name_nl": "{$competitor2}/{$competitor1} & 5+",
        "name_pl": "{$competitor2}/{$competitor1} & 5+",
        "name_ro": "{$competitor2}/{$competitor1} & 5+",
        "name_ru": "{$competitor2}/{$competitor1} & 5+",
        "name_tr": "{$competitor2}/{$competitor1} & 5+",
        "name_se": "{$competitor2}/{$competitor1} & 5+",
        "name_el": "{$competitor2}/{$competitor1} & 5+",
        "name_es": "{$competitor2}/{$competitor1} & 5+",
        "name_hr": "{$competitor2}/{$competitor1} i 5+",
        "name_fr": "{$competitor2}/{$competitor1} & 5 ou +"
    },
    "796^prematch": {
        "name_en": "{$competitor1} & over {total}",
        "name_aa": "{$competitor1} & over {total}",
        "name_de": "{$competitor1} & über {total}",
        "name_nl": "{$competitor1} & boven {total}",
        "name_pl": "{$competitor1} & over {total}",
        "name_ro": "{$competitor1} & over {total}",
        "name_ru": "{$competitor1} & over {total}",
        "name_tr": "{$competitor1} & {total} üstü",
        "name_se": "{$competitor1} & over {total}",
        "name_el": "{$competitor1} & over {total}",
        "name_es": "{$competitor1} & over {total}",
        "name_hr": "{$competitor1} i više od {total}",
        "name_fr": "{$competitor1} & plus de {total}"
    },
    "1086^prematch": {
        "name_en": "0-20",
        "name_aa": "0-20",
        "name_de": "0-20",
        "name_nl": "0-20",
        "name_pl": "0-20",
        "name_ro": "0-20",
        "name_ru": "0-20",
        "name_tr": "0-20",
        "name_se": "0-20",
        "name_el": "0-20",
        "name_es": "0-20",
        "name_hr": "0-20",
        "name_fr": "0-20"
    },
    "818^prematch": {
        "name_en": "{$competitor1} goal & {$competitor2}",
        "name_aa": "{$competitor1} goal & {$competitor2}",
        "name_de": "{$competitor1} Tor & {$competitor2}",
        "name_nl": "doelpunt {$competitor1} & {$competitor2}",
        "name_pl": "{$competitor1} goal & {$competitor2}",
        "name_ro": "{$competitor1} goal & {$competitor2}",
        "name_ru": "{$competitor1} goal & {$competitor2}",
        "name_tr": "{$competitor1} golü & {$competitor2} kazanır",
        "name_se": "{$competitor1} goal & {$competitor2}",
        "name_el": "{$competitor1} goal & {$competitor2}",
        "name_es": "{$competitor1} goal & {$competitor2}",
        "name_hr": "{$competitor1} gol i {$competitor2} pobjeda",
        "name_fr": "but {$competitor1} & {$competitor2}"
    },
    "606^prematch": {
        "name_en": "41-50",
        "name_aa": "41-50",
        "name_de": "41-50",
        "name_nl": "41-50",
        "name_pl": "41-50",
        "name_ro": "41-50",
        "name_ru": "41-50",
        "name_tr": "41-50",
        "name_se": "41-50",
        "name_el": "41-50",
        "name_es": "41-50",
        "name_hr": "41-50",
        "name_fr": "41-50"
    },
    "1840^prematch": {
        "name_en": "draw/draw & under {total}",
        "name_aa": "draw/draw & under {total}",
        "name_de": "unentschieden/unentschieden & unter {total}",
        "name_nl": "gelijkspel/gelijkspel & onder {total}",
        "name_pl": "draw/draw & under {total}",
        "name_ro": "draw/draw & under {total}",
        "name_ru": "draw/draw & under {total}",
        "name_tr": "beraberlik/beraberlik & {total} altı",
        "name_se": "draw/draw & under {total}",
        "name_el": "draw/draw & under {total}",
        "name_es": "draw/draw & under {total}",
        "name_hr": "neodlučeno/neodlučeno i manje {total}",
        "name_fr": "Match nul/Match nul & Moins de {total}"
    },
    "1295^prematch": {
        "name_en": "1-5",
        "name_aa": "1-5",
        "name_de": "1-5",
        "name_nl": "1-5",
        "name_pl": "1-5",
        "name_ro": "1-5",
        "name_ru": "1-5",
        "name_tr": "1-5",
        "name_se": "1-5",
        "name_el": "1-5",
        "name_es": "1-5",
        "name_hr": "1-5",
        "name_fr": "1-5"
    },
    "1889^prematch": {
        "name_en": "{$competitor2}/{$competitor2} & 5+",
        "name_aa": "{$competitor2}/{$competitor2} & 5+",
        "name_de": "{$competitor2}/{$competitor2} & 5+",
        "name_nl": "{$competitor2}/{$competitor2} & 5+",
        "name_pl": "{$competitor2}/{$competitor2} & 5+",
        "name_ro": "{$competitor2}/{$competitor2} & 5+",
        "name_ru": "{$competitor2}/{$competitor2} & 5+",
        "name_tr": "{$competitor2}/{$competitor2} & 5+",
        "name_se": "{$competitor2}/{$competitor2} & 5+",
        "name_el": "{$competitor2}/{$competitor2} & 5+",
        "name_es": "{$competitor2}/{$competitor2} & 5+",
        "name_hr": "{$competitor2}/{$competitor2} i 5+",
        "name_fr": "{$competitor2}/{$competitor2} & 5 ou +"
    },
    "193^prematch": {
        "name_en": "7:1",
        "name_aa": "7:1",
        "name_de": "7:1",
        "name_nl": "7:1",
        "name_pl": "7:1",
        "name_ro": "7:1",
        "name_ru": "7:1",
        "name_tr": "7:1",
        "name_se": "7:1",
        "name_el": "7:1",
        "name_es": "7:1",
        "name_hr": "7:1",
        "name_fr": "7:1"
    },
    "1407^prematch": {
        "name_en": "{$competitor1} & 5",
        "name_aa": "{$competitor1} & 5",
        "name_de": "{$competitor1} & 5",
        "name_nl": "{$competitor1} & 5",
        "name_pl": "{$competitor1} & 5",
        "name_ro": "{$competitor1} & 5",
        "name_ru": "{$competitor1} & 5",
        "name_tr": "{$competitor1} & 5",
        "name_se": "{$competitor1} & 5",
        "name_el": "{$competitor1} & 5",
        "name_es": "{$competitor1} & 5",
        "name_hr": "{$competitor1} & 5",
        "name_fr": "{$competitor1} & 5"
    },
    "96^prematch": {
        "name_en": "under {total} & no",
        "name_aa": "under {total} & no",
        "name_de": "unter {total} & nein",
        "name_nl": "onder {total} & nee",
        "name_pl": "under {total} & no",
        "name_ro": "under {total} & no",
        "name_ru": "under {total} & no",
        "name_tr": "{total} altı & yok",
        "name_se": "under {total} & no",
        "name_el": "under {total} & no",
        "name_es": "under {total} & no",
        "name_hr": "manje od {total} i ne",
        "name_fr": "moins de {total} & non"
    },
    "19^prematch": {
        "name_en": "2:4",
        "name_aa": "2:4",
        "name_de": "2:4",
        "name_nl": "2:4",
        "name_pl": "2:4",
        "name_ro": "2:4",
        "name_ru": "2:4",
        "name_tr": "2:4",
        "name_se": "2:4",
        "name_el": "2:4",
        "name_es": "2:4",
        "name_hr": "2:4",
        "name_fr": "2:4"
    },
    "60050000^prematch": {
        "name_en": "5+/5+",
        "name_aa": "5+/5+",
        "name_de": "5+/5+",
        "name_nl": "5+/5+",
        "name_pl": "5+/5+",
        "name_ro": "5+/5+",
        "name_ru": "5+/5+",
        "name_tr": "5+/5+",
        "name_se": "5+/5+",
        "name_el": "5+/5+",
        "name_es": "5+/5+",
        "name_hr": "5+/5+",
        "name_fr": "5+/5+"
    },
    "306^prematch": {
        "name_en": "2:11",
        "name_aa": "2:11",
        "name_de": "2:11",
        "name_nl": "2:11",
        "name_pl": "2:11",
        "name_ro": "2:11",
        "name_ru": "2:11",
        "name_tr": "2:11",
        "name_se": "2:11",
        "name_el": "2:11",
        "name_es": "2:11",
        "name_hr": "2:11",
        "name_fr": "2:11"
    },
    "867^prematch": {
        "name_en": "6:2",
        "name_aa": "6:2",
        "name_de": "6:2",
        "name_nl": "6:2",
        "name_pl": "6:2",
        "name_ro": "6:2",
        "name_ru": "6:2",
        "name_tr": "6:2",
        "name_se": "6:2",
        "name_el": "6:2",
        "name_es": "6:2",
        "name_hr": "6:2",
        "name_fr": "6:2"
    },
    "88^prematch": {
        "name_en": "{$competitor2} & no",
        "name_aa": "{$competitor2} & no",
        "name_de": "{$competitor2} & nein",
        "name_nl": "{$competitor2} & nee",
        "name_pl": "{$competitor2} & no",
        "name_ro": "{$competitor2} & no",
        "name_ru": "{$competitor2} & no",
        "name_tr": "{$competitor2} & yok",
        "name_se": "{$competitor2} & no",
        "name_el": "{$competitor2} & no",
        "name_es": "{$competitor2} & no",
        "name_hr": "{$competitor2} i ne",
        "name_fr": "{$competitor2} & non"
    },
    "546^prematch": {
        "name_en": "0:0",
        "name_aa": "0:0",
        "name_de": "0:0",
        "name_nl": "0:0",
        "name_pl": "0:0",
        "name_ro": "0:0",
        "name_ru": "0:0",
        "name_tr": "0:0",
        "name_se": "0:0",
        "name_el": "0:0",
        "name_es": "0:0",
        "name_hr": "0:0",
        "name_fr": "0:0"
    },
    "600^prematch": {
        "name_en": "11-20",
        "name_aa": "11-20",
        "name_de": "11-20",
        "name_nl": "11-20",
        "name_pl": "11-20",
        "name_ro": "11-20",
        "name_ru": "11-20",
        "name_tr": "11-20",
        "name_se": "11-20",
        "name_el": "11-20",
        "name_es": "11-20",
        "name_hr": "11-20",
        "name_fr": "11-20"
    },
    "1090^prematch": {
        "name_en": "36-40",
        "name_aa": "36-40",
        "name_de": "36-40",
        "name_nl": "36-40",
        "name_pl": "36-40",
        "name_ro": "36-40",
        "name_ru": "36-40",
        "name_tr": "36-40",
        "name_se": "36-40",
        "name_el": "36-40",
        "name_es": "36-40",
        "name_hr": "36-40",
        "name_fr": "36-40"
    },
    "726^prematch": {
        "name_en": "11",
        "name_aa": "11",
        "name_de": "11",
        "name_nl": "11",
        "name_pl": "11",
        "name_ro": "11",
        "name_ru": "11",
        "name_tr": "11",
        "name_se": "11",
        "name_el": "11",
        "name_es": "11",
        "name_hr": "11",
        "name_fr": "11"
    },
    "1866^prematch": {
        "name_en": "{$competitor1}/{$competitor1} & 3",
        "name_aa": "{$competitor1}/{$competitor1} & 3",
        "name_de": "{$competitor1}/{$competitor1} & 3",
        "name_nl": "{$competitor1}/{$competitor1} & 3",
        "name_pl": "{$competitor1}/{$competitor1} & 3",
        "name_ro": "{$competitor1}/{$competitor1} & 3",
        "name_ru": "{$competitor1}/{$competitor1} & 3",
        "name_tr": "{$competitor1}/{$competitor1} & 3",
        "name_se": "{$competitor1}/{$competitor1} & 3",
        "name_el": "{$competitor1}/{$competitor1} & 3",
        "name_es": "{$competitor1}/{$competitor1} & 3",
        "name_hr": "{$competitor1}/{$competitor1} i 3",
        "name_fr": "{$competitor1}/{$competitor1} & 3"
    },
    "1848^prematch": {
        "name_en": "draw/{$competitor1} & over {total}",
        "name_aa": "draw/{$competitor1} & over {total}",
        "name_de": "unentschieden/{$competitor1} & über {total}",
        "name_nl": "gelijkspel/{$competitor1} & boven {total}",
        "name_pl": "draw/{$competitor1} & over {total}",
        "name_ro": "draw/{$competitor1} & over {total}",
        "name_ru": "draw/{$competitor1} & over {total}",
        "name_tr": "beraberlik/{$competitor1} & {total} üstü",
        "name_se": "draw/{$competitor1} & over {total}",
        "name_el": "draw/{$competitor1} & over {total}",
        "name_es": "draw/{$competitor1} & over {total}",
        "name_hr": "neodlučeno/{$competitor1} i više {total}",
        "name_fr": "Match nul/{$competitor1} & Plus de {total}"
    },
    "1043^prematch": {
        "name_en": "draw",
        "name_aa": "draw",
        "name_de": "unentschieden",
        "name_nl": "gelijkspel",
        "name_pl": "draw",
        "name_ro": "draw",
        "name_ru": "draw",
        "name_tr": "beraberlik",
        "name_se": "draw",
        "name_el": "draw",
        "name_es": "draw",
        "name_hr": "neodlučeno",
        "name_fr": "Match nul"
    },
    "1745^prematch": {
        "name_en": "7+",
        "name_aa": "7+",
        "name_de": "7+",
        "name_nl": "7+",
        "name_pl": "7+",
        "name_ro": "7+",
        "name_ru": "7+",
        "name_tr": "7+",
        "name_se": "7+",
        "name_el": "7+",
        "name_es": "7+",
        "name_hr": "7+",
        "name_fr": "7+"
    },
    "270^prematch": {
        "name_en": "10:3",
        "name_aa": "10:3",
        "name_de": "10:3",
        "name_nl": "10:3",
        "name_pl": "10:3",
        "name_ro": "10:3",
        "name_ru": "10:3",
        "name_tr": "10:3",
        "name_se": "10:3",
        "name_el": "10:3",
        "name_es": "10:3",
        "name_hr": "10:3",
        "name_fr": "10:3"
    },
    "1149^prematch": {
        "name_en": "5-6",
        "name_aa": "5-6",
        "name_de": "5-6",
        "name_nl": "5-6",
        "name_pl": "5-6",
        "name_ro": "5-6",
        "name_ru": "5-6",
        "name_tr": "5-6",
        "name_se": "5-6",
        "name_el": "5-6",
        "name_es": "5-6",
        "name_hr": "5-6",
        "name_fr": "5-6"
    },
    "262^prematch": {
        "name_en": "4:9",
        "name_aa": "4:9",
        "name_de": "4:9",
        "name_nl": "4:9",
        "name_pl": "4:9",
        "name_ro": "4:9",
        "name_ru": "4:9",
        "name_tr": "4:9",
        "name_se": "4:9",
        "name_el": "4:9",
        "name_es": "4:9",
        "name_hr": "4:9",
        "name_fr": "4:9"
    },
    "1836^prematch": {
        "name_en": "{$competitor1}/{$competitor1} & under {total}",
        "name_aa": "{$competitor1}/{$competitor1} & under {total}",
        "name_de": "{$competitor1}/{$competitor1} & unter {total}",
        "name_nl": "{$competitor1}/{$competitor1} & onder {total}",
        "name_pl": "{$competitor1}/{$competitor1} & under {total}",
        "name_ro": "{$competitor1}/{$competitor1} & under {total}",
        "name_ru": "{$competitor1}/{$competitor1} & under {total}",
        "name_tr": "{$competitor1}/{$competitor1} & {total} altı",
        "name_se": "{$competitor1}/{$competitor1} & under {total}",
        "name_el": "{$competitor1}/{$competitor1} & under {total}",
        "name_es": "{$competitor1}/{$competitor1} & under {total}",
        "name_hr": "{$competitor1}/{$competitor1} i manje {total}",
        "name_fr": "{$competitor1}/{$competitor1} & Moins de {total}"
    },
    "196^prematch": {
        "name_en": "7:4",
        "name_aa": "7:4",
        "name_de": "7:4",
        "name_nl": "7:4",
        "name_pl": "7:4",
        "name_ro": "7:4",
        "name_ru": "7:4",
        "name_tr": "7:4",
        "name_se": "7:4",
        "name_el": "7:4",
        "name_es": "7:4",
        "name_hr": "7:4",
        "name_fr": "7:4"
    },
    "1744^prematch": {
        "name_en": "5-6",
        "name_aa": "5-6",
        "name_de": "5-6",
        "name_nl": "5-6",
        "name_pl": "5-6",
        "name_ro": "5-6",
        "name_ru": "5-6",
        "name_tr": "5-6",
        "name_se": "5-6",
        "name_el": "5-6",
        "name_es": "5-6",
        "name_hr": "5-6",
        "name_fr": "5-6"
    },
    "1180^prematch": {
        "name_en": "3:2",
        "name_aa": "3:2",
        "name_de": "3:2",
        "name_nl": "3:2",
        "name_pl": "3:2",
        "name_ro": "3:2",
        "name_ru": "3:2",
        "name_tr": "3:2",
        "name_se": "3:2",
        "name_el": "3:2",
        "name_es": "3:2",
        "name_hr": "3:2",
        "name_fr": "3:2"
    },
    "280^prematch": {
        "name_en": "6:10",
        "name_aa": "6:10",
        "name_de": "6:10",
        "name_nl": "6:10",
        "name_pl": "6:10",
        "name_ro": "6:10",
        "name_ru": "6:10",
        "name_tr": "6:10",
        "name_se": "6:10",
        "name_el": "6:10",
        "name_es": "6:10",
        "name_hr": "6:10",
        "name_fr": "6:10"
    },
    "1838^prematch": {
        "name_en": "{$competitor1}/{$competitor2} & under {total}",
        "name_aa": "{$competitor1}/{$competitor2} & under {total}",
        "name_de": "{$competitor1}/{$competitor2} & unter {total}",
        "name_nl": "{$competitor1}/{$competitor2} & onder {total}",
        "name_pl": "{$competitor1}/{$competitor2} & under {total}",
        "name_ro": "{$competitor1}/{$competitor2} & under {total}",
        "name_ru": "{$competitor1}/{$competitor2} & under {total}",
        "name_tr": "{$competitor1}/{$competitor2} & {total} altı",
        "name_se": "{$competitor1}/{$competitor2} & under {total}",
        "name_el": "{$competitor1}/{$competitor2} & under {total}",
        "name_es": "{$competitor1}/{$competitor2} & under {total}",
        "name_hr": "{$competitor1}/{$competitor2} i manje {total}",
        "name_fr": "{$competitor1}/{$competitor2} & Moins de {total}"
    },
    "342^prematch": {
        "name_en": "0:0 2:1",
        "name_aa": "0:0 2:1",
        "name_de": "0:0 2:1",
        "name_nl": "0:0 2:1",
        "name_pl": "0:0 2:1",
        "name_ro": "0:0 2:1",
        "name_ru": "0:0 2:1",
        "name_tr": "0:0 2:1",
        "name_se": "0:0 2:1",
        "name_el": "0:0 2:1",
        "name_es": "0:0 2:1",
        "name_hr": "0:0 2:1",
        "name_fr": "0:0 2:1"
    },
    "32^prematch": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "78^prematch": {
        "name_en": "{$competitor1} & yes",
        "name_aa": "{$competitor1} & yes",
        "name_de": "{$competitor1} & ja",
        "name_nl": "{$competitor1} & ja",
        "name_pl": "{$competitor1} & yes",
        "name_ro": "{$competitor1} & yes",
        "name_ru": "{$competitor1} & yes",
        "name_tr": "{$competitor1} & var",
        "name_se": "{$competitor1} & yes",
        "name_el": "{$competitor1} & yes",
        "name_es": "{$competitor1} & yes",
        "name_hr": "{$competitor1} i da",
        "name_fr": "{$competitor1} & oui"
    },
    "1713^prematch": {
        "name_en": "{$competitor2} ({hcp})",
        "name_aa": "{$competitor2} ({hcp})",
        "name_de": "{$competitor2} ({hcp})",
        "name_nl": "{$competitor2} ({hcp})",
        "name_pl": "{$competitor2} ({hcp})",
        "name_ro": "{$competitor2} ({hcp})",
        "name_ru": "{$competitor2} ({hcp})",
        "name_tr": "{$competitor2} ({hcp})",
        "name_se": "{$competitor2} ({hcp})",
        "name_el": "{$competitor2} ({hcp})",
        "name_es": "{$competitor2} ({hcp})",
        "name_hr": "{$competitor2} ({hcp})",
        "name_fr": "{$competitor2} ({hcp})"
    },
    "1379^prematch": {
        "name_en": "{$competitor2} by decision",
        "name_aa": "{$competitor2} by decision",
        "name_de": "{$competitor2} by decision",
        "name_nl": "{$competitor2} by decision",
        "name_pl": "{$competitor2} by decision",
        "name_ro": "{$competitor2} by decision",
        "name_ru": "{$competitor2} by decision",
        "name_tr": "{$competitor2} by decision",
        "name_se": "{$competitor2} by decision",
        "name_el": "{$competitor2} by decision",
        "name_es": "{$competitor2} by decision",
        "name_hr": "{$competitor2} by decision",
        "name_fr": "{$competitor2} by decision"
    },
    "122^prematch": {
        "name_en": "other",
        "name_aa": "other",
        "name_de": "other",
        "name_nl": "other",
        "name_pl": "other",
        "name_ro": "other",
        "name_ru": "other",
        "name_tr": "other",
        "name_se": "other",
        "name_el": "other",
        "name_es": "other",
        "name_hr": "other",
        "name_fr": "other"
    },
    "1847^prematch": {
        "name_en": "{$competitor1}/{$competitor2} & over {total}",
        "name_aa": "{$competitor1}/{$competitor2} & over {total}",
        "name_de": "{$competitor1}/{$competitor2} & über {total}",
        "name_nl": "{$competitor1}/{$competitor2} & boven {total}",
        "name_pl": "{$competitor1}/{$competitor2} & over {total}",
        "name_ro": "{$competitor1}/{$competitor2} & over {total}",
        "name_ru": "{$competitor1}/{$competitor2} & over {total}",
        "name_tr": "{$competitor1}/{$competitor2} & {total} üstü",
        "name_se": "{$competitor1}/{$competitor2} & over {total}",
        "name_el": "{$competitor1}/{$competitor2} & over {total}",
        "name_es": "{$competitor1}/{$competitor2} & over {total}",
        "name_hr": "{$competitor1}/{$competitor2} i više {total}",
        "name_fr": "{$competitor1}/{$competitor2} & Plus de {total}"
    },
    "1734^prematch": {
        "name_en": "1-6",
        "name_aa": "1-6",
        "name_de": "1-6",
        "name_nl": "1-6",
        "name_pl": "1-6",
        "name_ro": "1-6",
        "name_ru": "1-6",
        "name_tr": "1-6",
        "name_se": "1-6",
        "name_el": "1-6",
        "name_es": "1-6",
        "name_hr": "1-6",
        "name_fr": "1-6"
    },
    "948^prematch": {
        "name_en": "{$competitor1}",
        "name_aa": "{$competitor1}",
        "name_de": "{$competitor1}",
        "name_nl": "{$competitor1}",
        "name_pl": "{$competitor1}",
        "name_ro": "{$competitor1}",
        "name_ru": "{$competitor1}",
        "name_tr": "{$competitor1}",
        "name_se": "{$competitor1}",
        "name_el": "{$competitor1}",
        "name_es": "{$competitor1}",
        "name_hr": "{$competitor1}",
        "name_fr": "{$competitor1}"
    },
    "368^prematch": {
        "name_en": "0:2 4+",
        "name_aa": "0:2 4+",
        "name_de": "0:2 4+",
        "name_nl": "0:2 4+",
        "name_pl": "0:2 4+",
        "name_ro": "0:2 4+",
        "name_ru": "0:2 4+",
        "name_tr": "0:2 4+",
        "name_se": "0:2 4+",
        "name_el": "0:2 4+",
        "name_es": "0:2 4+",
        "name_hr": "0:2 4+",
        "name_fr": "0:2 4+"
    },
    "638^prematch": {
        "name_en": "{$competitor2} by 2",
        "name_aa": "{$competitor2} by 2",
        "name_de": "{$competitor2} mit 2",
        "name_nl": "{$competitor2} met 2",
        "name_pl": "{$competitor2} by 2",
        "name_ro": "{$competitor2} by 2",
        "name_ru": "{$competitor2} by 2",
        "name_tr": "{$competitor2} 2 farkla",
        "name_se": "{$competitor2} by 2",
        "name_el": "{$competitor2} by 2",
        "name_es": "{$competitor2} by 2",
        "name_hr": "{$competitor2} za 2",
        "name_fr": "{$competitor2} par 2"
    },
    "1338^prematch": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "182^prematch": {
        "name_en": "6:2",
        "name_aa": "6:2",
        "name_de": "6:2",
        "name_nl": "6:2",
        "name_pl": "6:2",
        "name_ro": "6:2",
        "name_ru": "6:2",
        "name_tr": "6:2",
        "name_se": "6:2",
        "name_el": "6:2",
        "name_es": "6:2",
        "name_hr": "6:2",
        "name_fr": "6:2"
    },
    "1246^prematch": {
        "name_en": "65-69",
        "name_aa": "65-69",
        "name_de": "65-69",
        "name_nl": "65-69",
        "name_pl": "65-69",
        "name_ro": "65-69",
        "name_ru": "65-69",
        "name_tr": "65-69",
        "name_se": "65-69",
        "name_el": "65-69",
        "name_es": "65-69",
        "name_hr": "65-69",
        "name_fr": "65-69"
    },
    "37^prematch": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "71000000^prematch": {
        "name_en": "{$competitor2}",
        "name_aa": "{$competitor2}",
        "name_de": "{$competitor2}",
        "name_nl": "{$competitor2}",
        "name_pl": "{$competitor2}",
        "name_ro": "{$competitor2}",
        "name_ru": "{$competitor2}",
        "name_tr": "{$competitor2}",
        "name_se": "{$competitor2}",
        "name_el": "{$competitor2}",
        "name_es": "{$competitor2}",
        "name_hr": "{$competitor2}",
        "name_fr": "{$competitor2}"
    },
    "144^prematch": {
        "name_en": "3:2",
        "name_aa": "3:2",
        "name_de": "3:2",
        "name_nl": "3:2",
        "name_pl": "3:2",
        "name_ro": "3:2",
        "name_ru": "3:2",
        "name_tr": "3:2",
        "name_se": "3:2",
        "name_el": "3:2",
        "name_es": "3:2",
        "name_hr": "3:2",
        "name_fr": "3:2"
    },
    "77^prematch": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2",
        "name_es": "2",
        "name_hr": "2",
        "name_fr": "2"
    },
    "1723^prematch": {
        "name_en": "draw/{$competitor2} & no",
        "name_aa": "draw/{$competitor2} & no",
        "name_de": "unentschieden/{$competitor2} & nein",
        "name_nl": "gelijkspel/{$competitor2} & nee",
        "name_pl": "draw/{$competitor2} & no",
        "name_ro": "draw/{$competitor2} & no",
        "name_ru": "draw/{$competitor2} & no",
        "name_tr": "beraberlik/{$competitor2} & yok",
        "name_se": "draw/{$competitor2} & no",
        "name_el": "draw/{$competitor2} & no",
        "name_es": "draw/{$competitor2} & no",
        "name_hr": "neodlučeno/{$competitor2} i ne",
        "name_fr": "Match nul/{$competitor2} & non"
    },
    "116^prematch": {
        "name_en": "{$competitor2} by 1",
        "name_aa": "{$competitor2} by 1",
        "name_de": "{$competitor2} by 1",
        "name_nl": "{$competitor2} by 1",
        "name_pl": "{$competitor2} by 1",
        "name_ro": "{$competitor2} by 1",
        "name_ru": "{$competitor2} by 1",
        "name_tr": "{$competitor2} by 1",
        "name_se": "{$competitor2} by 1",
        "name_el": "{$competitor2} by 1",
        "name_es": "{$competitor2} by 1",
        "name_hr": "{$competitor2} by 1",
        "name_fr": "{$competitor2} by 1"
    },
    "2036^prematch": {
        "name_en": "{$competitor1} ({+hcp}) & under {total}",
        "name_aa": "{$competitor1} ({+hcp}) & under {total}",
        "name_de": "{$competitor1} ({+hcp}) & under {total}",
        "name_nl": "{$competitor1} ({+hcp}) & under {total}",
        "name_pl": "{$competitor1} ({+hcp}) & under {total}",
        "name_ro": "{$competitor1} ({+hcp}) & under {total}",
        "name_ru": "{$competitor1} ({+hcp}) & under {total}",
        "name_tr": "{$competitor1} ({+hcp}) & under {total}",
        "name_se": "{$competitor1} ({+hcp}) & under {total}",
        "name_el": "{$competitor1} ({+hcp}) & under {total}",
        "name_es": "{$competitor1} ({+hcp}) & under {total}",
        "name_hr": "{$competitor1} ({+hcp}) & under {total}",
        "name_fr": "{$competitor1} ({+hcp}) & under {total}"
    },
    "1650000^prematch": {
        "name_en": "{$competitor1}",
        "name_aa": "{$competitor1}",
        "name_de": "{$competitor1}",
        "name_nl": "{$competitor1}",
        "name_pl": "{$competitor1}",
        "name_ro": "{$competitor1}",
        "name_ru": "{$competitor1}",
        "name_tr": "{$competitor1}",
        "name_se": "{$competitor1}",
        "name_el": "{$competitor1}",
        "name_es": "{$competitor1}",
        "name_hr": "{$competitor1}",
        "name_fr": "{$competitor1}"
    },
    "378^prematch": {
        "name_en": "1:0 1:2",
        "name_aa": "1:0 1:2",
        "name_de": "1:0 1:2",
        "name_nl": "1:0 1:2",
        "name_pl": "1:0 1:2",
        "name_ro": "1:0 1:2",
        "name_ru": "1:0 1:2",
        "name_tr": "1:0 1:2",
        "name_se": "1:0 1:2",
        "name_el": "1:0 1:2",
        "name_es": "1:0 1:2",
        "name_hr": "1:0 1:2",
        "name_fr": "1:0 1:2"
    },
    "1074^prematch": {
        "name_en": "140-149",
        "name_aa": "140-149",
        "name_de": "140-149",
        "name_nl": "140-149",
        "name_pl": "140-149",
        "name_ro": "140-149",
        "name_ru": "140-149",
        "name_tr": "140-149",
        "name_se": "140-149",
        "name_el": "140-149",
        "name_es": "140-149",
        "name_hr": "140-149",
        "name_fr": "140-149"
    },
    "264^prematch": {
        "name_en": "2:9",
        "name_aa": "2:9",
        "name_de": "2:9",
        "name_nl": "2:9",
        "name_pl": "2:9",
        "name_ro": "2:9",
        "name_ru": "2:9",
        "name_tr": "2:9",
        "name_se": "2:9",
        "name_el": "2:9",
        "name_es": "2:9",
        "name_hr": "2:9",
        "name_fr": "2:9"
    },
    "556^prematch": {
        "name_en": "1:2",
        "name_aa": "1:2",
        "name_de": "1:2",
        "name_nl": "1:2",
        "name_pl": "1:2",
        "name_ro": "1:2",
        "name_ru": "1:2",
        "name_tr": "1:2",
        "name_se": "1:2",
        "name_el": "1:2",
        "name_es": "1:2",
        "name_hr": "1:2",
        "name_fr": "1:2"
    },
    "300^prematch": {
        "name_en": "8:11",
        "name_aa": "8:11",
        "name_de": "8:11",
        "name_nl": "8:11",
        "name_pl": "8:11",
        "name_ro": "8:11",
        "name_ru": "8:11",
        "name_tr": "8:11",
        "name_se": "8:11",
        "name_el": "8:11",
        "name_es": "8:11",
        "name_hr": "8:11",
        "name_fr": "8:11"
    },
    "1195^prematch": {
        "name_en": "2:1",
        "name_aa": "2:1",
        "name_de": "2:1",
        "name_nl": "2:1",
        "name_pl": "2:1",
        "name_ro": "2:1",
        "name_ru": "2:1",
        "name_tr": "2:1",
        "name_se": "2:1",
        "name_el": "2:1",
        "name_es": "2:1",
        "name_hr": "2:1",
        "name_fr": "2:1"
    },
    "1727^prematch": {
        "name_en": "{$competitor1}/draw & over {total}",
        "name_aa": "{$competitor1}/draw & over {total}",
        "name_de": "{$competitor1}/unentschieden & über {total}",
        "name_nl": "{$competitor1}/gelijkspel & boven {total}",
        "name_pl": "{$competitor1}/draw & over {total}",
        "name_ro": "{$competitor1}/draw & over {total}",
        "name_ru": "{$competitor1}/draw & over {total}",
        "name_tr": "{$competitor1}/beraberlik & {total} üstü",
        "name_se": "{$competitor1}/draw & over {total}",
        "name_el": "{$competitor1}/draw & over {total}",
        "name_es": "{$competitor1}/draw & over {total}",
        "name_hr": "{$competitor1}/neodlučeno i manje {total}",
        "name_fr": "{$competitor1}/Match nul & plus de {total}"
    },
    "60040000^prematch": {
        "name_en": "4+/7+",
        "name_aa": "4+/7+",
        "name_de": "4+/7+",
        "name_nl": "4+/7+",
        "name_pl": "4+/7+",
        "name_ro": "4+/7+",
        "name_ru": "4+/7+",
        "name_tr": "4+/7+",
        "name_se": "4+/7+",
        "name_el": "4+/7+",
        "name_es": "4+/7+",
        "name_hr": "4+/7+",
        "name_fr": "4+/7+"
    },
    "1694110000^prematch": {
        "name_en": "Over 11.5",
        "name_aa": "Over 11.5",
        "name_de": "Over 11.5",
        "name_nl": "Over 11.5",
        "name_pl": "Over 11.5",
        "name_ro": "Over 11.5",
        "name_ru": "Over 11.5",
        "name_tr": "Over 11.5",
        "name_se": "Over 11.5",
        "name_el": "Over 11.5",
        "name_es": "Over 11.5",
        "name_hr": "Over 11.5",
        "name_fr": "Over 11.5"
    },
    "1878^prematch": {
        "name_en": "{$competitor2}/{$competitor1} & 4",
        "name_aa": "{$competitor2}/{$competitor1} & 4",
        "name_de": "{$competitor2}/{$competitor1} & 4",
        "name_nl": "{$competitor2}/{$competitor1} & 4",
        "name_pl": "{$competitor2}/{$competitor1} & 4",
        "name_ro": "{$competitor2}/{$competitor1} & 4",
        "name_ru": "{$competitor2}/{$competitor1} & 4",
        "name_tr": "{$competitor2}/{$competitor1} & 4",
        "name_se": "{$competitor2}/{$competitor1} & 4",
        "name_el": "{$competitor2}/{$competitor1} & 4",
        "name_es": "{$competitor2}/{$competitor1} & 4",
        "name_hr": "{$competitor2}/{$competitor1} i 4",
        "name_fr": "{$competitor2}/{$competitor1} & 4"
    },
    "1830^prematch": {
        "name_en": "other inning or no run",
        "name_aa": "other inning or no run",
        "name_de": "Anderes Inning oder kein Lauf",
        "name_nl": "andere inning of geen run",
        "name_pl": "other inning or no run",
        "name_ro": "other inning or no run",
        "name_ru": "other inning or no run",
        "name_tr": "diğer devre veya koşu yok",
        "name_se": "other inning or no run",
        "name_el": "other inning or no run",
        "name_es": "other inning or no run",
        "name_hr": "drugačiji inning ili bez run-a",
        "name_fr": "autre manche ou aucun run"
    },
    "68^prematch": {
        "name_en": "0",
        "name_aa": "0",
        "name_de": "0",
        "name_nl": "0",
        "name_pl": "0",
        "name_ro": "0",
        "name_ru": "0",
        "name_tr": "0",
        "name_se": "0",
        "name_el": "0",
        "name_es": "0",
        "name_hr": "0",
        "name_fr": "0"
    },
    "1852^prematch": {
        "name_en": "{$competitor2}/draw & over {total}",
        "name_aa": "{$competitor2}/draw & over {total}",
        "name_de": "{$competitor2}/unentschieden & über {total}",
        "name_nl": "{$competitor2}/gelijkspel & boven {total}",
        "name_pl": "{$competitor2}/draw & over {total}",
        "name_ro": "{$competitor2}/draw & over {total}",
        "name_ru": "{$competitor2}/draw & over {total}",
        "name_tr": "{$competitor2}/beraberlik & {total} üstü",
        "name_se": "{$competitor2}/draw & over {total}",
        "name_el": "{$competitor2}/draw & over {total}",
        "name_es": "{$competitor2}/draw & over {total}",
        "name_hr": "{$competitor2}/neodlučeno i više {total}",
        "name_fr": "{$competitor2}/Match nul & Plus de {total}"
    },
    "1298^prematch": {
        "name_en": "16-20",
        "name_aa": "16-20",
        "name_de": "16-20",
        "name_nl": "16-20",
        "name_pl": "16-20",
        "name_ro": "16-20",
        "name_ru": "16-20",
        "name_tr": "16-20",
        "name_se": "16-20",
        "name_el": "16-20",
        "name_es": "16-20",
        "name_hr": "16-20",
        "name_fr": "16-20"
    },
    "84^prematch": {
        "name_en": "draw & no",
        "name_aa": "draw & no",
        "name_de": "unentschieden & nein",
        "name_nl": "gelijkspel & nee",
        "name_pl": "draw & no",
        "name_ro": "draw & no",
        "name_ru": "draw & no",
        "name_tr": "beraberlik & yok",
        "name_se": "draw & no",
        "name_el": "draw & no",
        "name_es": "draw & no",
        "name_hr": "neodlučeno i ne",
        "name_fr": "Match nul & non"
    },
    "1361^prematch": {
        "name_en": "{$competitor2} & decision",
        "name_aa": "{$competitor2} & decision",
        "name_de": "{$competitor2} & decision",
        "name_nl": "{$competitor2} & decision",
        "name_pl": "{$competitor2} & decision",
        "name_ro": "{$competitor2} & decision",
        "name_ru": "{$competitor2} & decision",
        "name_tr": "{$competitor2} & decision",
        "name_se": "{$competitor2} & decision",
        "name_el": "{$competitor2} & decision",
        "name_es": "{$competitor2} & decision",
        "name_hr": "{$competitor2} & decision",
        "name_fr": "{$competitor2} & decision"
    },
    "256^prematch": {
        "name_en": "9:7",
        "name_aa": "9:7",
        "name_de": "9:7",
        "name_nl": "9:7",
        "name_pl": "9:7",
        "name_ro": "9:7",
        "name_ru": "9:7",
        "name_tr": "9:7",
        "name_se": "9:7",
        "name_el": "9:7",
        "name_es": "9:7",
        "name_hr": "9:7",
        "name_fr": "9:7"
    },
    "955^prematch": {
        "name_en": "5th inning",
        "name_aa": "5th inning",
        "name_de": "5. Inning",
        "name_nl": "5e inning",
        "name_pl": "5th inning",
        "name_ro": "5th inning",
        "name_ru": "5th inning",
        "name_tr": "5. devre",
        "name_se": "5th inning",
        "name_el": "5th inning",
        "name_es": "5th inning",
        "name_hr": "5. inning",
        "name_fr": "5ème manche"
    },
    "1761^prematch": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1",
        "name_es": "1",
        "name_hr": "1",
        "name_fr": "1"
    },
    "1072^prematch": {
        "name_en": "120-129",
        "name_aa": "120-129",
        "name_de": "120-129",
        "name_nl": "120-129",
        "name_pl": "120-129",
        "name_ro": "120-129",
        "name_ru": "120-129",
        "name_tr": "120-129",
        "name_se": "120-129",
        "name_el": "120-129",
        "name_es": "120-129",
        "name_hr": "120-129",
        "name_fr": "120-129"
    },
    "1134^prematch": {
        "name_en": "46-60",
        "name_aa": "46-60",
        "name_de": "46-60",
        "name_nl": "46-60",
        "name_pl": "46-60",
        "name_ro": "46-60",
        "name_ru": "46-60",
        "name_tr": "46-60",
        "name_se": "46-60",
        "name_el": "46-60",
        "name_es": "46-60",
        "name_hr": "46-60",
        "name_fr": "46-60"
    },
    "135^prematch": {
        "name_en": "{$competitor2} by 8-14",
        "name_aa": "{$competitor2} by 8-14",
        "name_de": "{$competitor2} by 8-14",
        "name_nl": "{$competitor2} by 8-14",
        "name_pl": "{$competitor2} by 8-14",
        "name_ro": "{$competitor2} by 8-14",
        "name_ru": "{$competitor2} by 8-14",
        "name_tr": "{$competitor2} by 8-14",
        "name_se": "{$competitor2} by 8-14",
        "name_el": "{$competitor2} by 8-14",
        "name_es": "{$competitor2} by 8-14",
        "name_hr": "{$competitor2} by 8-14",
        "name_fr": "{$competitor2} by 8-14"
    },
    "1423^prematch": {
        "name_en": "{$competitor2} & 7",
        "name_aa": "{$competitor2} & 7",
        "name_de": "{$competitor2} & 7",
        "name_nl": "{$competitor2} & 7",
        "name_pl": "{$competitor2} & 7",
        "name_ro": "{$competitor2} & 7",
        "name_ru": "{$competitor2} & 7",
        "name_tr": "{$competitor2} & 7",
        "name_se": "{$competitor2} & 7",
        "name_el": "{$competitor2} & 7",
        "name_es": "{$competitor2} & 7",
        "name_hr": "{$competitor2} & 7",
        "name_fr": "{$competitor2} & 7"
    },
    "184^prematch": {
        "name_en": "6:4",
        "name_aa": "6:4",
        "name_de": "6:4",
        "name_nl": "6:4",
        "name_pl": "6:4",
        "name_ro": "6:4",
        "name_ru": "6:4",
        "name_tr": "6:4",
        "name_se": "6:4",
        "name_el": "6:4",
        "name_es": "6:4",
        "name_hr": "6:4",
        "name_fr": "6:4"
    },
    "297^prematch": {
        "name_en": "11:10",
        "name_aa": "11:10",
        "name_de": "11:10",
        "name_nl": "11:10",
        "name_pl": "11:10",
        "name_ro": "11:10",
        "name_ru": "11:10",
        "name_tr": "11:10",
        "name_se": "11:10",
        "name_el": "11:10",
        "name_es": "11:10",
        "name_hr": "11:10",
        "name_fr": "11:10"
    },
    "808^prematch": {
        "name_en": "yes/no",
        "name_aa": "yes/no",
        "name_de": "ja/nein",
        "name_nl": "ja/nee",
        "name_pl": "yes/no",
        "name_ro": "yes/no",
        "name_ru": "yes/no",
        "name_tr": "var/yok",
        "name_se": "yes/no",
        "name_el": "yes/no",
        "name_es": "yes/no",
        "name_hr": "da/ne",
        "name_fr": "oui/non"
    },
    "1479^prematch": {
        "name_en": "4:0",
        "name_aa": "4:0",
        "name_de": "4:0",
        "name_nl": "4:0",
        "name_pl": "4:0",
        "name_ro": "4:0",
        "name_ru": "4:0",
        "name_tr": "4:0",
        "name_se": "4:0",
        "name_el": "4:0",
        "name_es": "4:0",
        "name_hr": "4:0",
        "name_fr": "4:0"
    },
    "288^prematch": {
        "name_en": "11:1",
        "name_aa": "11:1",
        "name_de": "11:1",
        "name_nl": "11:1",
        "name_pl": "11:1",
        "name_ro": "11:1",
        "name_ru": "11:1",
        "name_tr": "11:1",
        "name_se": "11:1",
        "name_el": "11:1",
        "name_es": "11:1",
        "name_hr": "11:1",
        "name_fr": "11:1"
    },
    "923^prematch": {
        "name_en": "4th quarter",
        "name_aa": "4th quarter",
        "name_de": "4. Viertel",
        "name_nl": "Vierde kwart",
        "name_pl": "4th quarter",
        "name_ro": "4th quarter",
        "name_ru": "4th quarter",
        "name_tr": "Dördüncü çeyrek",
        "name_se": "4th quarter",
        "name_el": "4th quarter",
        "name_es": "4th quarter",
        "name_hr": "4. četvrtina",
        "name_fr": "4ème quart-temps"
    },
    "1123^prematch": {
        "name_en": "4-5",
        "name_aa": "4-5",
        "name_de": "4-5",
        "name_nl": "4-5",
        "name_pl": "4-5",
        "name_ro": "4-5",
        "name_ru": "4-5",
        "name_tr": "4-5",
        "name_se": "4-5",
        "name_el": "4-5",
        "name_es": "4-5",
        "name_hr": "4-5",
        "name_fr": "4-5"
    },
    "424^prematch": {
        "name_en": "draw/{$competitor1}",
        "name_aa": "draw/{$competitor1}",
        "name_de": "unentschieden/{$competitor1}",
        "name_nl": "gelijkspel/{$competitor1}",
        "name_pl": "draw/{$competitor1}",
        "name_ro": "draw/{$competitor1}",
        "name_ru": "draw/{$competitor1}",
        "name_tr": "beraberlik/{$competitor1}",
        "name_se": "draw/{$competitor1}",
        "name_el": "draw/{$competitor1}",
        "name_es": "draw/{$competitor1}",
        "name_hr": "neodlučeno/{$competitor1}",
        "name_fr": "Match nul/{$competitor1}"
    },
    "1256^prematch": {
        "name_en": "35-39",
        "name_aa": "35-39",
        "name_de": "35-39",
        "name_nl": "35-39",
        "name_pl": "35-39",
        "name_ro": "35-39",
        "name_ru": "35-39",
        "name_tr": "35-39",
        "name_se": "35-39",
        "name_el": "35-39",
        "name_es": "35-39",
        "name_hr": "35-39",
        "name_fr": "35-39"
    },
    "358^prematch": {
        "name_en": "0:1 2:1",
        "name_aa": "0:1 2:1",
        "name_de": "0:1 2:1",
        "name_nl": "0:1 2:1",
        "name_pl": "0:1 2:1",
        "name_ro": "0:1 2:1",
        "name_ru": "0:1 2:1",
        "name_tr": "0:1 2:1",
        "name_se": "0:1 2:1",
        "name_el": "0:1 2:1",
        "name_es": "0:1 2:1",
        "name_hr": "0:1 2:1",
        "name_fr": "0:1 2:1"
    },
    "1099^prematch": {
        "name_en": "42+",
        "name_aa": "42+",
        "name_de": "42+",
        "name_nl": "42+",
        "name_pl": "42+",
        "name_ro": "42+",
        "name_ru": "42+",
        "name_tr": "42+",
        "name_se": "42+",
        "name_el": "42+",
        "name_es": "42+",
        "name_hr": "42+",
        "name_fr": "42+"
    },
    "1249^prematch": {
        "name_en": "80-84",
        "name_aa": "80-84",
        "name_de": "80-84",
        "name_nl": "80-84",
        "name_pl": "80-84",
        "name_ro": "80-84",
        "name_ru": "80-84",
        "name_tr": "80-84",
        "name_se": "80-84",
        "name_el": "80-84",
        "name_es": "80-84",
        "name_hr": "80-84",
        "name_fr": "80-84"
    },
    "279^prematch": {
        "name_en": "7:10",
        "name_aa": "7:10",
        "name_de": "7:10",
        "name_nl": "7:10",
        "name_pl": "7:10",
        "name_ro": "7:10",
        "name_ru": "7:10",
        "name_tr": "7:10",
        "name_se": "7:10",
        "name_el": "7:10",
        "name_es": "7:10",
        "name_hr": "7:10",
        "name_fr": "7:10"
    },
    "165^prematch": {
        "name_en": "171-180",
        "name_aa": "171-180",
        "name_de": "171-180",
        "name_nl": "171-180",
        "name_pl": "171-180",
        "name_ro": "171-180",
        "name_ru": "171-180",
        "name_tr": "171-180",
        "name_se": "171-180",
        "name_el": "171-180",
        "name_es": "171-180",
        "name_hr": "171-180",
        "name_fr": "171-180"
    },
    "1842^prematch": {
        "name_en": "{$competitor2}/{$competitor1} & under {total}",
        "name_aa": "{$competitor2}/{$competitor1} & under {total}",
        "name_de": "{$competitor2}/{$competitor1} & unter {total}",
        "name_nl": "{$competitor2}/{$competitor1} & onder {total}",
        "name_pl": "{$competitor2}/{$competitor1} & under {total}",
        "name_ro": "{$competitor2}/{$competitor1} & under {total}",
        "name_ru": "{$competitor2}/{$competitor1} & under {total}",
        "name_tr": "{$competitor2}/{$competitor1} & {total} altı",
        "name_se": "{$competitor2}/{$competitor1} & under {total}",
        "name_el": "{$competitor2}/{$competitor1} & under {total}",
        "name_es": "{$competitor2}/{$competitor1} & under {total}",
        "name_hr": "{$competitor2}/{$competitor1} i manje {total}",
        "name_fr": "{$competitor2}/{$competitor1} & Moins de {total}"
    },
    "56200000^prematch": {
        "name_en": "0-5",
        "name_aa": "0-5",
        "name_de": "0-5",
        "name_nl": "0-5",
        "name_pl": "0-5",
        "name_ro": "0-5",
        "name_ru": "0-5",
        "name_tr": "0-5",
        "name_se": "0-5",
        "name_el": "0-5",
        "name_es": "0-5",
        "name_hr": "0-5",
        "name_fr": "0-5"
    },
    "42^prematch": {
        "name_en": "4",
        "name_aa": "4",
        "name_de": "4",
        "name_nl": "4",
        "name_pl": "4",
        "name_ro": "4",
        "name_ru": "4",
        "name_tr": "4",
        "name_se": "4",
        "name_el": "4",
        "name_es": "4",
        "name_hr": "4",
        "name_fr": "4"
    },
    "1377^prematch": {
        "name_en": "draw",
        "name_aa": "draw",
        "name_de": "draw",
        "name_nl": "draw",
        "name_pl": "draw",
        "name_ro": "draw",
        "name_ru": "draw",
        "name_tr": "draw",
        "name_se": "draw",
        "name_el": "draw",
        "name_es": "draw",
        "name_hr": "draw",
        "name_fr": "draw"
    },
    "464^prematch": {
        "name_en": "1:1",
        "name_aa": "1:1",
        "name_de": "1:1",
        "name_nl": "1:1",
        "name_pl": "1:1",
        "name_ro": "1:1",
        "name_ru": "1:1",
        "name_tr": "1:1",
        "name_se": "1:1",
        "name_el": "1:1",
        "name_es": "1:1",
        "name_hr": "1:1",
        "name_fr": "1:1"
    },
    "1738^prematch": {
        "name_en": "2-6",
        "name_aa": "2-6",
        "name_de": "2-6",
        "name_nl": "2-6",
        "name_pl": "2-6",
        "name_ro": "2-6",
        "name_ru": "2-6",
        "name_tr": "2-6",
        "name_se": "2-6",
        "name_el": "2-6",
        "name_es": "2-6",
        "name_hr": "2-6",
        "name_fr": "2-6"
    },
    "1299^prematch": {
        "name_en": "21-25",
        "name_aa": "21-25",
        "name_de": "21-25",
        "name_nl": "21-25",
        "name_pl": "21-25",
        "name_ro": "21-25",
        "name_ru": "21-25",
        "name_tr": "21-25",
        "name_se": "21-25",
        "name_el": "21-25",
        "name_es": "21-25",
        "name_hr": "21-25",
        "name_fr": "21-25"
    },
    "1883^prematch": {
        "name_en": "{$competitor1}/{$competitor2} & 5+",
        "name_aa": "{$competitor1}/{$competitor2} & 5+",
        "name_de": "{$competitor1}/{$competitor2} & 5+",
        "name_nl": "{$competitor1}/{$competitor2} & 5+",
        "name_pl": "{$competitor1}/{$competitor2} & 5+",
        "name_ro": "{$competitor1}/{$competitor2} & 5+",
        "name_ru": "{$competitor1}/{$competitor2} & 5+",
        "name_tr": "{$competitor1}/{$competitor2} & 5+",
        "name_se": "{$competitor1}/{$competitor2} & 5+",
        "name_el": "{$competitor1}/{$competitor2} & 5+",
        "name_es": "{$competitor1}/{$competitor2} & 5+",
        "name_hr": "{$competitor1}/{$competitor2} i 5+",
        "name_fr": "{$competitor1}/{$competitor2} & 5 ou +"
    },
    "1823^prematch": {
        "name_en": "top of 9th inning",
        "name_aa": "top of 9th inning",
        "name_de": "Anfang des 9. Innings",
        "name_nl": "eerste helft 9e inning",
        "name_pl": "top of 9th inning",
        "name_ro": "top of 9th inning",
        "name_ru": "top of 9th inning",
        "name_tr": "9. devrenin sonunda",
        "name_se": "top of 9th inning",
        "name_el": "top of 9th inning",
        "name_es": "top of 9th inning",
        "name_hr": "na kraju 9. inninga",
        "name_fr": "haut de la 9ème manche"
    },
    "1747^prematch": {
        "name_en": "1-3",
        "name_aa": "1-3",
        "name_de": "1-3",
        "name_nl": "1-3",
        "name_pl": "1-3",
        "name_ro": "1-3",
        "name_ru": "1-3",
        "name_tr": "1-3",
        "name_se": "1-3",
        "name_el": "1-3",
        "name_es": "1-3",
        "name_hr": "1-3",
        "name_fr": "1-3"
    },
    "784^prematch": {
        "name_en": "none",
        "name_aa": "none",
        "name_de": "keiner",
        "name_nl": "geen",
        "name_pl": "none",
        "name_ro": "none",
        "name_ru": "none",
        "name_tr": "Gol olmaz",
        "name_se": "none",
        "name_el": "none",
        "name_es": "none",
        "name_hr": "nitko",
        "name_fr": "aucune"
    },
    "56150000^prematch": {
        "name_en": "4+",
        "name_aa": "4+",
        "name_de": "4+",
        "name_nl": "4+",
        "name_pl": "4+",
        "name_ro": "4+",
        "name_ru": "4+",
        "name_tr": "4+",
        "name_se": "4+",
        "name_el": "4+",
        "name_es": "4+",
        "name_hr": "4+",
        "name_fr": "4+"
    },
    "1841^prematch": {
        "name_en": "draw/{$competitor2} & under {total}",
        "name_aa": "draw/{$competitor2} & under {total}",
        "name_de": "unentschieden/{$competitor2} & unter {total}",
        "name_nl": "gelijkspel/{$competitor2} & onder {total}",
        "name_pl": "draw/{$competitor2} & under {total}",
        "name_ro": "draw/{$competitor2} & under {total}",
        "name_ru": "draw/{$competitor2} & under {total}",
        "name_tr": "beraberlik/{$competitor2} & {total} altı",
        "name_se": "draw/{$competitor2} & under {total}",
        "name_el": "draw/{$competitor2} & under {total}",
        "name_es": "draw/{$competitor2} & under {total}",
        "name_hr": "neodlučeno/{$competitor2} i manje {total}",
        "name_fr": "Match nul/{$competitor2} & Moins de {total}"
    },
    "590^prematch": {
        "name_en": "46-60",
        "name_aa": "46-60",
        "name_de": "46-60",
        "name_nl": "46-60",
        "name_pl": "46-60",
        "name_ro": "46-60",
        "name_ru": "46-60",
        "name_tr": "46-60",
        "name_se": "46-60",
        "name_el": "46-60",
        "name_es": "46-60",
        "name_hr": "46-60",
        "name_fr": "46-60"
    },
    "1372^prematch": {
        "name_en": "{$competitor2} & 4",
        "name_aa": "{$competitor2} & 4",
        "name_de": "{$competitor2} & 4",
        "name_nl": "{$competitor2} & 4",
        "name_pl": "{$competitor2} & 4",
        "name_ro": "{$competitor2} & 4",
        "name_ru": "{$competitor2} & 4",
        "name_tr": "{$competitor2} & 4",
        "name_se": "{$competitor2} & 4",
        "name_el": "{$competitor2} & 4",
        "name_es": "{$competitor2} & 4",
        "name_hr": "{$competitor2} & 4",
        "name_fr": "{$competitor2} & 4"
    },
    "250^prematch": {
        "name_en": "9:1",
        "name_aa": "9:1",
        "name_de": "9:1",
        "name_nl": "9:1",
        "name_pl": "9:1",
        "name_ro": "9:1",
        "name_ru": "9:1",
        "name_tr": "9:1",
        "name_se": "9:1",
        "name_el": "9:1",
        "name_es": "9:1",
        "name_hr": "9:1",
        "name_fr": "9:1"
    },
    "70820000^prematch": {
        "name_en": "X-2",
        "name_aa": "X-2",
        "name_de": "X-2",
        "name_nl": "X-2",
        "name_pl": "X-2",
        "name_ro": "X-2",
        "name_ru": "X-2",
        "name_tr": "X-2",
        "name_se": "X-2",
        "name_el": "X-2",
        "name_es": "X-2",
        "name_hr": "X-2",
        "name_fr": "X-2"
    },
    "252^prematch": {
        "name_en": "9:3",
        "name_aa": "9:3",
        "name_de": "9:3",
        "name_nl": "9:3",
        "name_pl": "9:3",
        "name_ro": "9:3",
        "name_ru": "9:3",
        "name_tr": "9:3",
        "name_se": "9:3",
        "name_el": "9:3",
        "name_es": "9:3",
        "name_hr": "9:3",
        "name_fr": "9:3"
    },
    "1694140000^prematch": {
        "name_en": "Under 12.5",
        "name_aa": "Under 12.5",
        "name_de": "Under 12.5",
        "name_nl": "Under 12.5",
        "name_pl": "Under 12.5",
        "name_ro": "Under 12.5",
        "name_ru": "Under 12.5",
        "name_tr": "Under 12.5",
        "name_se": "Under 12.5",
        "name_el": "Under 12.5",
        "name_es": "Under 12.5",
        "name_hr": "Under 12.5",
        "name_fr": "Under 12.5"
    },
    "75^prematch": {
        "name_en": "0",
        "name_aa": "0",
        "name_de": "0",
        "name_nl": "0",
        "name_pl": "0",
        "name_ro": "0",
        "name_ru": "0",
        "name_tr": "0",
        "name_se": "0",
        "name_el": "0",
        "name_es": "0",
        "name_hr": "0",
        "name_fr": "0"
    },
    "1855^prematch": {
        "name_en": "{$competitor1}/{$competitor1} & 1",
        "name_aa": "{$competitor1}/{$competitor1} & 1",
        "name_de": "{$competitor1}/{$competitor1} & 1",
        "name_nl": "{$competitor1}/{$competitor1} & 1",
        "name_pl": "{$competitor1}/{$competitor1} & 1",
        "name_ro": "{$competitor1}/{$competitor1} & 1",
        "name_ru": "{$competitor1}/{$competitor1} & 1",
        "name_tr": "{$competitor1}/{$competitor1} & 1",
        "name_se": "{$competitor1}/{$competitor1} & 1",
        "name_el": "{$competitor1}/{$competitor1} & 1",
        "name_es": "{$competitor1}/{$competitor1} & 1",
        "name_hr": "{$competitor1}/{$competitor1} i 1",
        "name_fr": "{$competitor1}/{$competitor1} & 1"
    },
    "1726^prematch": {
        "name_en": "draw/{$competitor2} & under {total}",
        "name_aa": "draw/{$competitor2} & under {total}",
        "name_de": "unentschieden/{$competitor2} & unter {total}",
        "name_nl": "gelijkspel/{$competitor2} & onder {total}",
        "name_pl": "draw/{$competitor2} & under {total}",
        "name_ro": "draw/{$competitor2} & under {total}",
        "name_ru": "draw/{$competitor2} & under {total}",
        "name_tr": "beraberlik/{$competitor2} & {total} altı",
        "name_se": "draw/{$competitor2} & under {total}",
        "name_el": "draw/{$competitor2} & under {total}",
        "name_es": "draw/{$competitor2} & under {total}",
        "name_hr": "neodlučeno/{$competitor2} i manje {total}",
        "name_fr": "Match nul/{$competitor2} & moins de {total}"
    },
    "1735^prematch": {
        "name_en": "2-3",
        "name_aa": "2-3",
        "name_de": "2-3",
        "name_nl": "2-3",
        "name_pl": "2-3",
        "name_ro": "2-3",
        "name_ru": "2-3",
        "name_tr": "2-3",
        "name_se": "2-3",
        "name_el": "2-3",
        "name_es": "2-3",
        "name_hr": "2-3",
        "name_fr": "2-3"
    },
    "1413^prematch": {
        "name_en": "{$competitor1} & 11",
        "name_aa": "{$competitor1} & 11",
        "name_de": "{$competitor1} & 11",
        "name_nl": "{$competitor1} & 11",
        "name_pl": "{$competitor1} & 11",
        "name_ro": "{$competitor1} & 11",
        "name_ru": "{$competitor1} & 11",
        "name_tr": "{$competitor1} & 11",
        "name_se": "{$competitor1} & 11",
        "name_el": "{$competitor1} & 11",
        "name_es": "{$competitor1} & 11",
        "name_hr": "{$competitor1} & 11",
        "name_fr": "{$competitor1} & 11"
    },
    "162^prematch": {
        "name_en": "141-150",
        "name_aa": "141-150",
        "name_de": "141-150",
        "name_nl": "141-150",
        "name_pl": "141-150",
        "name_ro": "141-150",
        "name_ru": "141-150",
        "name_tr": "141-150",
        "name_se": "141-150",
        "name_el": "141-150",
        "name_es": "141-150",
        "name_hr": "141-150",
        "name_fr": "141-150"
    },
    "586^prematch": {
        "name_en": "16-30",
        "name_aa": "16-30",
        "name_de": "16-30",
        "name_nl": "16-30",
        "name_pl": "16-30",
        "name_ro": "16-30",
        "name_ru": "16-30",
        "name_tr": "16-30",
        "name_se": "16-30",
        "name_el": "16-30",
        "name_es": "16-30",
        "name_hr": "16-30",
        "name_fr": "16-30"
    },
    "166^prematch": {
        "name_en": "180.5+",
        "name_aa": "180.5+",
        "name_de": "180.5+",
        "name_nl": "180.5+",
        "name_pl": "180.5+",
        "name_ro": "180.5+",
        "name_ru": "180.5+",
        "name_tr": "180.5+",
        "name_se": "180.5+",
        "name_el": "180.5+",
        "name_es": "180.5+",
        "name_hr": "180.5+",
        "name_fr": "180.5+"
    },
    "24^prematch": {
        "name_en": "5:2",
        "name_aa": "5:2",
        "name_de": "5:2",
        "name_nl": "5:2",
        "name_pl": "5:2",
        "name_ro": "5:2",
        "name_ru": "5:2",
        "name_tr": "5:2",
        "name_se": "5:2",
        "name_el": "5:2",
        "name_es": "5:2",
        "name_hr": "5:2",
        "name_fr": "5:2"
    },
    "1718^prematch": {
        "name_en": "{$competitor1}/draw & yes",
        "name_aa": "{$competitor1}/draw & yes",
        "name_de": "{$competitor1}/unentschieden & ja",
        "name_nl": "{$competitor1}/gelijkspel & ja",
        "name_pl": "{$competitor1}/draw & yes",
        "name_ro": "{$competitor1}/draw & yes",
        "name_ru": "{$competitor1}/draw & yes",
        "name_tr": "{$competitor1}/beraberlik & var",
        "name_se": "{$competitor1}/draw & yes",
        "name_el": "{$competitor1}/draw & yes",
        "name_es": "{$competitor1}/draw & yes",
        "name_hr": "{$competitor1}/neodlučeno i da",
        "name_fr": "{$competitor1}/Match nul & oui"
    },
    "1403^prematch": {
        "name_en": "{$competitor1} & 1",
        "name_aa": "{$competitor1} & 1",
        "name_de": "{$competitor1} & 1",
        "name_nl": "{$competitor1} & 1",
        "name_pl": "{$competitor1} & 1",
        "name_ro": "{$competitor1} & 1",
        "name_ru": "{$competitor1} & 1",
        "name_tr": "{$competitor1} & 1",
        "name_se": "{$competitor1} & 1",
        "name_el": "{$competitor1} & 1",
        "name_es": "{$competitor1} & 1",
        "name_hr": "{$competitor1} & 1",
        "name_fr": "{$competitor1} & 1"
    },
    "322^prematch": {
        "name_en": "4:4",
        "name_aa": "4:4",
        "name_de": "4:4",
        "name_nl": "4:4",
        "name_pl": "4:4",
        "name_ro": "4:4",
        "name_ru": "4:4",
        "name_tr": "4:4",
        "name_se": "4:4",
        "name_el": "4:4",
        "name_es": "4:4",
        "name_hr": "4:4",
        "name_fr": "4:4"
    },
    "3^prematch": {
        "name_en": "{$competitor2}",
        "name_aa": "{$competitor2}",
        "name_de": "{$competitor2}",
        "name_nl": "{$competitor2}",
        "name_pl": "{$competitor2}",
        "name_ro": "{$competitor2}",
        "name_ru": "{$competitor2}",
        "name_tr": "{$competitor2}",
        "name_se": "{$competitor2}",
        "name_el": "{$competitor2}",
        "name_es": "{$competitor2}",
        "name_hr": "{$competitor2}",
        "name_fr": "{$competitor2}"
    },
    "238^prematch": {
        "name_en": "8:5",
        "name_aa": "8:5",
        "name_de": "8:5",
        "name_nl": "8:5",
        "name_pl": "8:5",
        "name_ro": "8:5",
        "name_ru": "8:5",
        "name_tr": "8:5",
        "name_se": "8:5",
        "name_el": "8:5",
        "name_es": "8:5",
        "name_hr": "8:5",
        "name_fr": "8:5"
    },
    "1176^prematch": {
        "name_en": "2:4",
        "name_aa": "2:4",
        "name_de": "2:4",
        "name_nl": "2:4",
        "name_pl": "2:4",
        "name_ro": "2:4",
        "name_ru": "2:4",
        "name_tr": "2:4",
        "name_se": "2:4",
        "name_el": "2:4",
        "name_es": "2:4",
        "name_hr": "2:4",
        "name_fr": "2:4"
    },
    "121^prematch": {
        "name_en": "{$competitor2} by 6+",
        "name_aa": "{$competitor2} by 6+",
        "name_de": "{$competitor2} by 6+",
        "name_nl": "{$competitor2} by 6+",
        "name_pl": "{$competitor2} by 6+",
        "name_ro": "{$competitor2} by 6+",
        "name_ru": "{$competitor2} by 6+",
        "name_tr": "{$competitor2} by 6+",
        "name_se": "{$competitor2} by 6+",
        "name_el": "{$competitor2} by 6+",
        "name_es": "{$competitor2} by 6+",
        "name_hr": "{$competitor2} by 6+",
        "name_fr": "{$competitor2} by 6+"
    },
    "1694230000^prematch": {
        "name_en": "Over 3.5",
        "name_aa": "Over 3.5",
        "name_de": "Over 3.5",
        "name_nl": "Over 3.5",
        "name_pl": "Over 3.5",
        "name_ro": "Over 3.5",
        "name_ru": "Over 3.5",
        "name_tr": "Over 3.5",
        "name_se": "Over 3.5",
        "name_el": "Over 3.5",
        "name_es": "Over 3.5",
        "name_hr": "Over 3.5",
        "name_fr": "Over 3.5"
    },
    "964^prematch": {
        "name_en": "3",
        "name_aa": "3",
        "name_de": "3",
        "name_nl": "3",
        "name_pl": "3",
        "name_ro": "3",
        "name_ru": "3",
        "name_tr": "3",
        "name_se": "3",
        "name_el": "3",
        "name_es": "3",
        "name_hr": "3",
        "name_fr": "3"
    },
    "1426^prematch": {
        "name_en": "{$competitor2} & 10",
        "name_aa": "{$competitor2} & 10",
        "name_de": "{$competitor2} & 10",
        "name_nl": "{$competitor2} & 10",
        "name_pl": "{$competitor2} & 10",
        "name_ro": "{$competitor2} & 10",
        "name_ru": "{$competitor2} & 10",
        "name_tr": "{$competitor2} & 10",
        "name_se": "{$competitor2} & 10",
        "name_el": "{$competitor2} & 10",
        "name_es": "{$competitor2} & 10",
        "name_hr": "{$competitor2} & 10",
        "name_fr": "{$competitor2} & 10"
    },
    "260^prematch": {
        "name_en": "6:9",
        "name_aa": "6:9",
        "name_de": "6:9",
        "name_nl": "6:9",
        "name_pl": "6:9",
        "name_ro": "6:9",
        "name_ru": "6:9",
        "name_tr": "6:9",
        "name_se": "6:9",
        "name_el": "6:9",
        "name_es": "6:9",
        "name_hr": "6:9",
        "name_fr": "6:9"
    },
    "1178^prematch": {
        "name_en": "3:0",
        "name_aa": "3:0",
        "name_de": "3:0",
        "name_nl": "3:0",
        "name_pl": "3:0",
        "name_ro": "3:0",
        "name_ru": "3:0",
        "name_tr": "3:0",
        "name_se": "3:0",
        "name_el": "3:0",
        "name_es": "3:0",
        "name_hr": "3:0",
        "name_fr": "3:0"
    },
    "266^prematch": {
        "name_en": "0:9",
        "name_aa": "0:9",
        "name_de": "0:9",
        "name_nl": "0:9",
        "name_pl": "0:9",
        "name_ro": "0:9",
        "name_ru": "0:9",
        "name_tr": "0:9",
        "name_se": "0:9",
        "name_el": "0:9",
        "name_es": "0:9",
        "name_hr": "0:9",
        "name_fr": "0:9"
    },
    "177^prematch": {
        "name_en": "241-250",
        "name_aa": "241-250",
        "name_de": "241-250",
        "name_nl": "241-250",
        "name_pl": "241-250",
        "name_ro": "241-250",
        "name_ru": "241-250",
        "name_tr": "241-250",
        "name_se": "241-250",
        "name_el": "241-250",
        "name_es": "241-250",
        "name_hr": "241-250",
        "name_fr": "241-250"
    },
    "1428^prematch": {
        "name_en": "{$competitor2} & 12",
        "name_aa": "{$competitor2} & 12",
        "name_de": "{$competitor2} & 12",
        "name_nl": "{$competitor2} & 12",
        "name_pl": "{$competitor2} & 12",
        "name_ro": "{$competitor2} & 12",
        "name_ru": "{$competitor2} & 12",
        "name_tr": "{$competitor2} & 12",
        "name_se": "{$competitor2} & 12",
        "name_el": "{$competitor2} & 12",
        "name_es": "{$competitor2} & 12",
        "name_hr": "{$competitor2} & 12",
        "name_fr": "{$competitor2} & 12"
    },
    "1425^prematch": {
        "name_en": "{$competitor2} & 9",
        "name_aa": "{$competitor2} & 9",
        "name_de": "{$competitor2} & 9",
        "name_nl": "{$competitor2} & 9",
        "name_pl": "{$competitor2} & 9",
        "name_ro": "{$competitor2} & 9",
        "name_ru": "{$competitor2} & 9",
        "name_tr": "{$competitor2} & 9",
        "name_se": "{$competitor2} & 9",
        "name_el": "{$competitor2} & 9",
        "name_es": "{$competitor2} & 9",
        "name_hr": "{$competitor2} & 9",
        "name_fr": "{$competitor2} & 9"
    },
    "1482^prematch": {
        "name_en": "1:1",
        "name_aa": "1:1",
        "name_de": "1:1",
        "name_nl": "1:1",
        "name_pl": "1:1",
        "name_ro": "1:1",
        "name_ru": "1:1",
        "name_tr": "1:1",
        "name_se": "1:1",
        "name_el": "1:1",
        "name_es": "1:1",
        "name_hr": "1:1",
        "name_fr": "1:1"
    },
    "951^prematch": {
        "name_en": "1st inning",
        "name_aa": "1st inning",
        "name_de": "1. Inning",
        "name_nl": "1e inning",
        "name_pl": "1st inning",
        "name_ro": "1st inning",
        "name_ru": "1st inning",
        "name_tr": "1. devre",
        "name_se": "1st inning",
        "name_el": "1st inning",
        "name_es": "1st inning",
        "name_hr": "1. inning",
        "name_fr": "1ère manche"
    },
    "136^prematch": {
        "name_en": "{$competitor2} by 15+",
        "name_aa": "{$competitor2} by 15+",
        "name_de": "{$competitor2} by 15+",
        "name_nl": "{$competitor2} by 15+",
        "name_pl": "{$competitor2} by 15+",
        "name_ro": "{$competitor2} by 15+",
        "name_ru": "{$competitor2} by 15+",
        "name_tr": "{$competitor2} by 15+",
        "name_se": "{$competitor2} by 15+",
        "name_el": "{$competitor2} by 15+",
        "name_es": "{$competitor2} by 15+",
        "name_hr": "{$competitor2} by 15+",
        "name_fr": "{$competitor2} by 15+"
    },
    "132^prematch": {
        "name_en": "{$competitor1} by 1-7",
        "name_aa": "{$competitor1} by 1-7",
        "name_de": "{$competitor1} by 1-7",
        "name_nl": "{$competitor1} by 1-7",
        "name_pl": "{$competitor1} by 1-7",
        "name_ro": "{$competitor1} by 1-7",
        "name_ru": "{$competitor1} by 1-7",
        "name_tr": "{$competitor1} by 1-7",
        "name_se": "{$competitor1} by 1-7",
        "name_el": "{$competitor1} by 1-7",
        "name_es": "{$competitor1} by 1-7",
        "name_hr": "{$competitor1} by 1-7",
        "name_fr": "{$competitor1} by 1-7"
    },
    "146^prematch": {
        "name_en": "4:2",
        "name_aa": "4:2",
        "name_de": "4:2",
        "name_nl": "4:2",
        "name_pl": "4:2",
        "name_ro": "4:2",
        "name_ru": "4:2",
        "name_tr": "4:2",
        "name_se": "4:2",
        "name_el": "4:2",
        "name_es": "4:2",
        "name_hr": "4:2",
        "name_fr": "4:2"
    },
    "1766^prematch": {
        "name_en": "6+",
        "name_aa": "6+",
        "name_de": "6+",
        "name_nl": "6+",
        "name_pl": "6+",
        "name_ro": "6+",
        "name_ru": "6+",
        "name_tr": "6+",
        "name_se": "6+",
        "name_el": "6+",
        "name_es": "6+",
        "name_hr": "6+",
        "name_fr": "6+"
    },
    "790^prematch": {
        "name_en": "only {$competitor2}",
        "name_aa": "only {$competitor2}",
        "name_de": "nur {$competitor2}",
        "name_nl": "alleen {$competitor2}",
        "name_pl": "only {$competitor2}",
        "name_ro": "only {$competitor2}",
        "name_ru": "only {$competitor2}",
        "name_tr": "sadece {$competitor2}",
        "name_se": "only {$competitor2}",
        "name_el": "only {$competitor2}",
        "name_es": "only {$competitor2}",
        "name_hr": "samo {$competitor2}",
        "name_fr": "seulement {$competitor2}"
    },
    "1287^prematch": {
        "name_en": "{$competitor1} by 21-25",
        "name_aa": "{$competitor1} by 21-25",
        "name_de": "{$competitor1} by 21-25",
        "name_nl": "{$competitor1} by 21-25",
        "name_pl": "{$competitor1} by 21-25",
        "name_ro": "{$competitor1} by 21-25",
        "name_ru": "{$competitor1} by 21-25",
        "name_tr": "{$competitor1} by 21-25",
        "name_se": "{$competitor1} by 21-25",
        "name_el": "{$competitor1} by 21-25",
        "name_es": "{$competitor1} by 21-25",
        "name_hr": "{$competitor1} by 21-25",
        "name_fr": "{$competitor1} by 21-25"
    },
    "976^prematch": {
        "name_en": "{$competitor2} & under {total}",
        "name_aa": "{$competitor2} & under {total}",
        "name_de": "{$competitor2} & under {total}",
        "name_nl": "{$competitor2} & under {total}",
        "name_pl": "{$competitor2} & under {total}",
        "name_ro": "{$competitor2} & under {total}",
        "name_ru": "{$competitor2} & under {total}",
        "name_tr": "{$competitor2} & under {total}",
        "name_se": "{$competitor2} & under {total}",
        "name_el": "{$competitor2} & under {total}",
        "name_es": "{$competitor2} & under {total}",
        "name_hr": "{$competitor2} & under {total}",
        "name_fr": "{$competitor2} & under {total}"
    },
    "265^prematch": {
        "name_en": "1:9",
        "name_aa": "1:9",
        "name_de": "1:9",
        "name_nl": "1:9",
        "name_pl": "1:9",
        "name_ro": "1:9",
        "name_ru": "1:9",
        "name_tr": "1:9",
        "name_se": "1:9",
        "name_el": "1:9",
        "name_es": "1:9",
        "name_hr": "1:9",
        "name_fr": "1:9"
    },
    "138^prematch": {
        "name_en": "{$competitor1} by 6-10",
        "name_aa": "{$competitor1} by 6-10",
        "name_de": "{$competitor1} by 6-10",
        "name_nl": "{$competitor1} by 6-10",
        "name_pl": "{$competitor1} by 6-10",
        "name_ro": "{$competitor1} by 6-10",
        "name_ru": "{$competitor1} by 6-10",
        "name_tr": "{$competitor1} by 6-10",
        "name_se": "{$competitor1} by 6-10",
        "name_el": "{$competitor1} by 6-10",
        "name_es": "{$competitor1} by 6-10",
        "name_hr": "{$competitor1} by 6-10",
        "name_fr": "{$competitor1} by 6-10"
    }
}